/*******************************************************************************
 You are strictly prohibited to copy, disclose, distribute, modify, or use
 this program in part or as a whole without the prior written consent of
 CallTax. CallTax, owns the intellectual property rights in and to this program.
 (Copyright © 2023 CallTax. All Rights Reserved| Confidential)

 Specification
    1. Program ID    : Board.tsx
    2. Program Title : 게시판 리스트
    3. Created by    : JH Kim
    4. Create Date   : 2023.
    5. Reference     :
        - 커뮤니티 > 공지사항, 전문가커뮤니티, 질의/응답
        - 관리자시스템 > 공지사항관리, FAQ 관리
*******************************************************************************/
import { useNavigate, useLocation } from "react-router-dom";
import Util from "js/common/Util";
import MuiListFilter, { fldInfProps } from "js/common/ui/list/MuiListFilter";
import React from "react";
import ForestTableStyle from 'js/common/ui/style/ForestStyle';
import AppContext from "js/common/AppContext";
import Axios from "js/common/Axios";
import { useRecoilValue } from "recoil";
import * as Atom from "js/common/Atom";

interface BoardProps {
    boardTy: string
}
const Board = ({boardTy}: BoardProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const fldInfs: fldInfProps[] = [
        { fldNm: 'boardSn', pk: true },
        { type: 'text', fldNm: 'rowNum', width: 65, headAlign: "center", dataAlign: "center", label: 'No.' },
        { type: 'text', fldNm: 'boardTitle', wAuto: true, headAlign: "center", dataAlign: "left", label: Util.getValToCode("1033", boardTy) + ' 제목' },
        { type: 'text', fldNm: 'boardHit', width: 110, headAlign: "center", dataAlign: "center", label: '조회수' },
        { type: 'text', fldNm: 'fileCnt', width: 65, headAlign: "center", dataAlign: "center", label: '파일' },
    ];
    if (["/admin/notice", "/system/expert", "/system/qna"].indexOf(location.pathname) !== -1) {
        fldInfs.push({ type: 'text', fldNm: 'replyCnt', width: 65, headAlign: "center", dataAlign: "center", label: '댓글' });
        fldInfs.push({ type: 'el', width: 70, headAlign: "center", el: (item: any) => (
            <div className='dpFlx aiC csP' onClick={(e) => {
                e.stopPropagation();
                delBoardInfo(item.boardSn, item.regUserSn, item.replyCnt);
            }}>
                <img className="csP pr3" src={require('img/common/icon_delete20.png')} alt="icon_delete20" />
                <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">삭제</span>
            </div>
        )});
    }
    const ordFld = { name: "rowNum", direction: "asc" };
    const [dataArr, setDataArr] = React.useState<any[]>([]);
    const [boardSelTime, setBoardSelTime] = React.useState<Date>(new Date());
    const userInfo = useRecoilValue(Atom.userInfo);

    const delBoardInfo = (boardSn: number, regUserSn:number, replyCnt:number) => {
        // 공지사항(1001), FAQ(1003) - admin / 전문가커뮤니티(1002), 질의/응답(1004) - system
        let url = location.pathname.substring(1, location.pathname.indexOf('/', 1));

        // 본인 글만 삭제 가능
        if (userInfo.userSn !== regUserSn) {
            AppContext.openAlert({
                title: "게시글 삭제 불가",
                msg: "다른 사람이 작성한 게시글은 삭제할 수 없습니다.",
                icon: "check"
            });
            return;
        }

        // 댓글이 있으면 삭제 불가
        if (replyCnt > 0) {
            AppContext.openAlert({
                title: "게시글 삭제 불가",
                msg: "게시글에 댓글 [" + replyCnt + "]건이 존재하여 삭제할 수 없습니다.",
                icon: "check"
            });
            return;
        }

        AppContext.openAlert({
            title: Util.getValToCode("1033", boardTy) + " 삭제",
            msg: Util.getValToCode("1033", boardTy) + " 게시글을 삭제하시겠습니까?",
            icon: "check",
            confirmText: "삭제",
            handleConfirm: () => {
                AppContext.showSpinner();
                Axios.dataAccess({
                    url: url + "/board/delBoardInfo.do",
                    methodType: "post",
                    paramType: "object",
                    paramData: {
                        boardSn: boardSn
                    },
                    onSuccessFn: () => {
                        AppContext.hideSpinner();
                        setBoardSelTime(new Date());
                    }
                });
            }
        });
    }

    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "portal/board/selBoardList.do",
            methodType: "post",
            paramType: "json",
            paramData: {
                boardTy: boardTy
            },
            onSuccessFn: (res: any) => {
                setDataArr(res.list);
                AppContext.hideSpinner();
            }
        });
    }, [boardTy, boardSelTime]);

    return (
        <React.Fragment>
            <div className="w100p bgcF6F6F6">
                <div className="dpFlx flxdCol jcC aiC pt60 pb30">
                    <div className='taC pb30'>
                        <span className="dpBlock fcBlack fs38 ls266 SpoqaHanSansNeo-Medium">똑똑한 자산관리의 시작</span>
                    </div>
                    {[
                        "/admin/notice",
                        "/admin/faq",
                        "/system/expert",
                        "/system/qna"
                    ].indexOf(location.pathname) !== -1 ?
                        <div className="h40 bgc1EA65B bdrd40 dpFlx jcC aiC csP pl15 pr10" onClick={() => navigate(location.pathname + "/reg")}>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>
                                {Util.getValToCode("1033", boardTy) + " 등록"}
                            </span>
                            <img className="pl5" src={require('img/common/icon_add_white_20.png')} alt="icon_add_white_20" />
                        </div>
                    : <></>}
                </div>
                <div className="dpFlx jcC pt30 pb110">
                    <div className='w1200'>
                        <div className='h36 dpFlx aiE pb20'>
                            <img className="mr10" src={require('img/common/icon_step.png')} alt="icon_step" />
                            <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 pb3'>
                                {Util.getValToCode("1033", boardTy)}
                                {location.pathname.indexOf("/admin/") === 0 ? " 관리" : ""}
                            </span>
                        </div>
                        <MuiListFilter tableInfo={{
                            tableHeadVsb: true,
                            styleType: ForestTableStyle,
                            fldInfs: fldInfs,
                            ordFld: ordFld,
                            dataArr: dataArr,
                            maxRowNums: [10, 20, 30],
                            trHeight: 40,
                            remainArea: false,
                            srchVsb: true,
                            rowClickFunc: (item: any) => {
                                navigate(location.pathname + "/view/" + item.boardSn);
                            },
                            paginateClass: "pt40",
                            headerVsb: true
                        }}/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Board;