/*******************************************************************************
 *** MypageBsnm ** My Page > 사업자정보
*******************************************************************************/
import IconButton from "js/common/ui/button/IconButton";
import TextBox from "js/common/ui/input/TextBox";
import React from "react";
import { useNavigate } from 'react-router-dom';
import Modal from 'js/common/ui/modal/Modal';
import Address from "js/common/address/Address";
import Util from "js/common/Util";
import FileBox from "js/common/ui/input/FileBox";
import Axios from "js/common/Axios";
import AppContext from "js/common/AppContext";

const MypageBsnm = () => {
    const navigate = useNavigate();
    const [bsnmNm, setBsnmNm] = React.useState<string>();
    const [zip, setZip] = React.useState<string>();
    const [rdnmAdres, setRdnmAdres] = React.useState<string>();
    const [rdnmAdresDetail, setRdnmAdresDetail] = React.useState<string>();
    const [lnmAdres, setLnmAdres] = React.useState<string>();
    const [lnmAdresDetail, setLnmAdresDetail] = React.useState<string>();
    const [bsnmRegNo, setBsnmRegNo] = React.useState<string>("");
    const [bsnmFile,setBsnmFile] = React.useState<File>();
    const [bsnmFileNm, setBsnmFileNm] = React.useState<string>();
    const [fileSn, setFileSn] = React.useState<number | null>(null);
    const [addressModalOpen, setAddressModalOpen] = React.useState<boolean>(false); // 주소검색 모달 호출변수
    const [bsnmSelTime, setBsnmSelTime] = React.useState<Date>(new Date());

    // 사업자정보 저장
    const updBsnmInfo = () => {
        AppContext.openAlert({
            title: "사업자정보 수정",
            msg: "사업자정보를 수정하시겠습니까?",
            icon: "check",
            confirmText: "수정",
            handleConfirm: () => {
                AppContext.showSpinner();
                Axios.dataAccess({
                    url: "system/mypage/upsBsnmInfo.do",
                    methodType: "post",
                    isMultipart: true,
                    paramData: {
                        bsnmNm: bsnmNm,
                        zip: zip,
                        rdnmAdres: rdnmAdres,
                        rdnmAdresDetail: rdnmAdresDetail,
                        lnmAdres: lnmAdres,
                        lnmAdresDetail: lnmAdresDetail,
                        bsnmRegNo: Util.unHypen(bsnmRegNo),
                        bsnmFile: bsnmFile,
                        fileSn: fileSn
                    },
                    onSuccessFn: () => {
                        AppContext.openAlert({
                            title: "사업자정보 변경 완료",
                            msg: "사업자정보 변경이 완료되었습니다.",
                            icon: "check"
                        });
                        setBsnmSelTime(new Date());
                        AppContext.hideSpinner();
                    }
                });
            }
        });
    }

    const onFinishAddrSearch = (item: any) => {
        setZip(item.zip); // 우편번호
        setRdnmAdres(item.rdnmAdres); // 도로명주소
        setRdnmAdresDetail(item.rdnmAdresDetail); // 도로명주소_상세
        setLnmAdres(item.lnmAdres); // 지번주소
        setLnmAdresDetail(item.lnmAdresDetail); // 지번주소_상세
    }

    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/mypage/selBsnmInfo.do",
            methodType: "post",
            onSuccessFn: (res: any) => {
                AppContext.hideSpinner();
                if (Util.isEmpty(res.item)) {
                    return;
                }
                setBsnmNm(res.item.bsnmNm);
                setZip(res.item.zip);
                setRdnmAdres(res.item.rdnmAdres);
                setRdnmAdresDetail(res.item.rdnmAdresDetail);
                setLnmAdres(res.item.lnmAdres);
                setLnmAdresDetail(res.item.lnmAdresDetail);
                setBsnmRegNo(res.item.bsnmRegNo);
                setBsnmFileNm(res.item.bsnmFileNm);
                setFileSn(res.item.fileSn);
            }
        });
    }, [bsnmSelTime]);

    return (
        <div className="bgcF6F6F6 pt60 pb100">
            <div className="w1160 m0auto">
                <table className={
                    "forest-table tr-h50 " +
                    "th-pl40 th-fm-SpoqaHanSansNeo-Medium th-fs14 th-ls07 th-fc222222 th-fw500 " +
                    "td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666"
                }>
                    <colgroup>
                        <col className="w165"/>
                        <col />
                    </colgroup>
                    <tbody>
                        <tr className="h50">
                            <th className="taL">상호</th>
                            <td>
                                <TextBox
                                    value={bsnmNm}
                                    width={420}
                                    height={40}
                                    fontSize={15}
                                    fontFamily='SpoqaHanSansNeo-Regular'
                                    maxLength={30}
                                    placeholder="상호명을 입력하세요."
                                    onChangeFunc={(text: string) => setBsnmNm(text)}
                                    inputClassName='bgcWhite ls075'
                                    color="#222222"
                                    offColor="#666666"
                                    className='bd1 pl15 pr15 bgcWhite'
                                    iconEl={
                                        <img
                                            onClick={() => setBsnmNm("")}
                                            className={'csP ' + (Util.isEmpty(bsnmNm) ? "dpNone" : "")}
                                            src={require('img/common/icon_cancel_fill_18.png')}
                                            alt="icon_cancel_fill_18"
                                        />
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="taL">소재지</th>
                            <td>
                                <div className="dpFlx flxdCol">
                                    <div className="dpFlx">
                                        <TextBox
                                            value={rdnmAdres}
                                            width={420}
                                            height={40}
                                            fontSize={15}
                                            fontFamily='SpoqaHanSansNeo-Regular'
                                            placeholder="도로명주소가 표시됩니다."
                                            inputClassName='bgcWhite ls075'
                                            color="#222222"
                                            offColor="#666666"
                                            className='bd1 pl15 pr15 bgcWhite mr5'
                                            mode="readonly"
                                        />
                                        <TextBox
                                            value={rdnmAdresDetail}
                                            width={440}
                                            height={40}
                                            fontSize={15}
                                            fontFamily='SpoqaHanSansNeo-Regular'
                                            maxLength={30}
                                            placeholder="도로명 상세주소를 입력할 수 있습니다."
                                            onChangeFunc={(text: string) => setRdnmAdresDetail(text)}
                                            inputClassName='bgcWhite ls075'
                                            color="#222222"
                                            offColor="#666666"
                                            className='bd1 pl15 pr15 bgcWhite mr10'
                                            iconEl={
                                                <img
                                                    onClick={() => setRdnmAdresDetail("")}
                                                    className={'csP ' + (Util.isEmpty(rdnmAdresDetail) ? "dpNone" : "")}
                                                    src={require('img/common/icon_cancel_fill_18.png')}
                                                    alt="icon_cancel_fill_18"
                                                />
                                            }
                                        />
                                        <div className="dpFlx aiC jcC h40 bd1 bdc17A840 bdBox bgcWhite pl18 pr18 csP" onClick={() => setAddressModalOpen(true)}>
                                            <span className="fs14 fc17A840 SpoqaHanSansNeo-Medium fw500">주소 검색</span>
                                            <img className="pl4" src={require('img/common/icon_search20_green.png')} alt="icon_search20_green" />
                                        </div>
                                    </div>
                                    <div className="dpFlx mt5">
                                        <TextBox
                                            value={lnmAdres}
                                            width={420}
                                            height={40}
                                            fontSize={15}
                                            fontFamily='SpoqaHanSansNeo-Regular'
                                            placeholder="지번주소가 표시됩니다."
                                            inputClassName='bgcWhite ls075'
                                            color="#222222"
                                            offColor="#666666"
                                            className='bd1 pl15 pr15 bgcWhite mr5'
                                            mode="readonly"
                                        />
                                        <TextBox
                                            value={lnmAdresDetail}
                                            width={440}
                                            height={40}
                                            fontSize={15}
                                            fontFamily='SpoqaHanSansNeo-Regular'
                                            maxLength={30}
                                            placeholder="지번 상세주소를 입력할 수 있습니다."
                                            onChangeFunc={(text: string) => setLnmAdresDetail(text)}
                                            inputClassName='bgcWhite ls075'
                                            color="#222222"
                                            offColor="#666666"
                                            className='bd1 pl15 pr15 bgcWhite mr10'
                                            iconEl={
                                                <img
                                                    onClick={() => setLnmAdresDetail("")}
                                                    className={'csP ' + (Util.isEmpty(lnmAdresDetail) ? "dpNone" : "")}
                                                    src={require('img/common/icon_cancel_fill_18.png')}
                                                    alt="icon_cancel_fill_18"
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th className="taL">사업자등록번호</th>
                            <td>
                                <TextBox
                                    type="phone"
                                    value={Util.setBizRegNum(bsnmRegNo)}
                                    width={420}
                                    height={40}
                                    fontSize={15}
                                    fontFamily='SpoqaHanSansNeo-Regular'
                                    maxLength={30}
                                    placeholder="사업자 등록번호를 입력하세요."
                                    onChangeFunc={(text: string) => setBsnmRegNo(text)}
                                    inputClassName='bgcWhite ls075'
                                    color="#222222"
                                    offColor="#666666"
                                    className='bd1 pl15 pr15 bgcWhite'
                                    iconEl={
                                        <img
                                            onClick={() => setBsnmRegNo("")}
                                            className={'csP ' + (Util.isEmpty(bsnmRegNo) ? "dpNone" : "")}
                                            src={require('img/common/icon_cancel_fill_18.png')}
                                            alt="icon_cancel_fill_18"
                                        />
                                    }
                                />
                            </td>
                        </tr>
                        <tr className="h70">
                            <th className="taL">사업자등록증</th>
                            <td>
                                {Util.isEmpty(fileSn) ?
                                    <FileBox width={300} height={40} fontSize={15} file={bsnmFile} setFile={setBsnmFile}/> :
                                    <div className="dpFlx aiC">
                                        <span className="pl5 SpoqaHanSansNeo-Medium fs14 ls07 fc666666 pr20 csP" onClick={() => {
                                            Axios.dataAccess({
                                                url: "system/mypage/download.do",
                                                methodType: "get",
                                                isFileDown: true,
                                                paramData: {
                                                    fileSn: fileSn
                                                }
                                            });
                                        }}>{bsnmFileNm}</span>
                                        <IconButton
                                            btnName="파일삭제"
                                            iconPos="right"
                                            iconNm="file"
                                            className="h40 bgcBtnClose pl20 pr20 mr10"
                                            textClassName="fs14 fcWhite SpoqaHanSansNeo-Medium ls07 fw500 pr4"
                                            onClick={() => setFileSn(null)} />
                                    </div>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='w100p dpFlx aiC jcC pt40'>
                <div className='w210 h56 bgcWhite dpFlx aiC jcC csP' onClick={() => navigate("/system/mypage")}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>취소</span>
                </div>
                <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={updBsnmInfo}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>저장</span>
                </div>
            </div>
            <Modal modalOpen={addressModalOpen} setModalOpen={setAddressModalOpen} title="주소검색" dim={true} visible={true}>
                <Address setModalOpen={setAddressModalOpen} callBack={onFinishAddrSearch} />
            </Modal>
        </div>
    )
}
export default MypageBsnm;