import { ChangeEvent, useState, useEffect } from "react";
import styled from "styled-components";
import { useRecoilValue, useRecoilState } from "recoil";

import { MainSectionContainer } from "../common/layout";
import Util from "js/common/Util";
import SelectBox, { SelectOption } from "../common/SelectBox";
import {
  acqsInputVisibleState,
  acqsInputValueState,
  acqsInputDisabledState,
  acqsInputHelpState,
} from "js/common/Atom";
import { useAcqsInputs } from "js/common/hooks/useAcqsInputs";
import { AcqsTaxParamProps, Code } from "js/common/types/types";
import { NumericFormat } from "react-number-format";

function ExtraInfo() {
  const [inputValue, setInputValue] = useRecoilState(acqsInputValueState);
  const inputDisabled = useRecoilValue(acqsInputDisabledState);
  const inputVisible = useRecoilValue(acqsInputVisibleState);
  const inputHelp = useRecoilValue(acqsInputHelpState);

  const [shareRatioHelpText, setShareRatioHelpText] = useState<string>("");

  const {
    onUpdateMdatTrgetAre,
    onUpdateHseNumAt1,
    onUpdateHseNumAt2,
    onUpdateInheirHseNo,
    onUpdateStdMkPrice,
    onUpdateDonor,
    onUpdateDonorHseNo,
    onUpdateTaxBase,
    onUpdateShareRatio,
    onUpdatePrvuseAr,
  } = useAcqsInputs();

  useEffect(() => {
    // 지분율 default value 100으로 set
    if (inputValue.shareRatio === "") {
      // 지분율이 set 되지 않은 상태이면 set default value
      setInputValue((prev: AcqsTaxParamProps) => ({
        ...prev,
        shareRatio: "100",
      }));
    }
  }, [setInputValue, inputValue.shareRatio]);

  const handleSelectMdatTrgetAre = (selected: SelectOption) => {
    onUpdateMdatTrgetAre(selected.value);
  };

  const handleSelectHseNumAt1 = (selected: SelectOption) => {
    onUpdateHseNumAt1(selected.value);
  };

  const handleSelectHseNumAt2 = (selected: SelectOption) => {
    onUpdateHseNumAt2(selected.value);
  };

  const handleSelectInheirHseNo = (selected: SelectOption) => {
    onUpdateInheirHseNo(selected.value);
  };

  const handleSelectStdMkPrice = (selected: SelectOption) => {
    onUpdateStdMkPrice(selected.value);
  };

  const handleSelectDonor = (selected: SelectOption) => {
    onUpdateDonor(selected.value);
  };

  const handleSelectDonorHseNo = (selected: SelectOption) => {
    onUpdateDonorHseNo(selected.value);
  };

  const handleChangeTaxBase = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    const valueWithoutComma = inputValue.replace(/,/g, "");
    onUpdateTaxBase(valueWithoutComma);
  };

  const handleChangeShareRatio = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    const numberValue = parseInt(inputValue);

    if (numberValue > 100 || numberValue <= 0) {
      setShareRatioHelpText("1~100 사이의 값을 입력하세요.");
    } else {
      setShareRatioHelpText("");
    }

    onUpdateShareRatio(inputValue);
  };

  const handleSelectPrvuseAr = (selected: SelectOption) => {
    onUpdatePrvuseAr(selected.value);
  };

  return (
    <MainSectionContainer>
      <p className="desc">
        <span className="bold"> 부가정보</span>
        <span>를 선택해주세요.</span>
      </p>
      {/* 12. 조정대상지역 */}
      {inputVisible.visible12 && (
        <InputContainer>
          <p className="label">조정대상지역</p>
          <div className="input">
            <SelectBox
              placeholder="조정대상지역을 선택해주세요."
              options={Util.getCodeArray("2009").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled12}
              selectedValue={inputValue.mdatTrgetAre}
              handleSelect={handleSelectMdatTrgetAre}
            />
          </div>
        </InputContainer>
      )}
      {/* 13. 주택수 1(조정대상지역) */}
      {inputVisible.visible13 && (
        <InputContainer>
          <p className="label">주택수</p>
          <div className="input">
            <SelectBox
              placeholder="주택수를 선택해주세요."
              options={Util.getCodeArray("2010").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled13}
              selectedValue={inputValue.hseNumAt1}
              handleSelect={handleSelectHseNumAt1}
            />
          </div>
          <div className="help">{inputHelp.houseNumHelp}</div>
        </InputContainer>
      )}
      {/* 14. 주택수 2(비조정대상지역) */}
      {inputVisible.visible14 && (
        <InputContainer>
          <p className="label">주택수</p>
          <div className="input">
            <SelectBox
              placeholder="주택수를 선택해주세요."
              options={Util.getCodeArray("2011").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled14}
              selectedValue={inputValue.hseNumAt2}
              handleSelect={handleSelectHseNumAt2}
            />
          </div>
          <div className="help">{inputHelp.houseNumHelp}</div>
        </InputContainer>
      )}
      {/* 15. 상속인의 주택유무 */}
      {inputVisible.visible15 && (
        <InputContainer>
          <p className="label">상속인의 주택유무</p>
          <div className="input">
            <SelectBox
              placeholder="상속인의 주택유무를 선택해주세요."
              options={Util.getCodeArray("2013").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled15}
              selectedValue={inputValue.inheirHseNo}
              handleSelect={handleSelectInheirHseNo}
            />
          </div>
        </InputContainer>
      )}
      {/* 16. 시가표준액 */}
      {inputVisible.visible16 && (
        <InputContainer>
          <p className="label">시가표준액</p>
          <div className="input">
            <SelectBox
              placeholder="시가표준액을 선택해주세요."
              options={Util.getCodeArray("2014").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled16}
              selectedValue={inputValue.stdMkPrice}
              handleSelect={handleSelectStdMkPrice}
            />
          </div>
        </InputContainer>
      )}
      {/* 17. 증여자 */}
      {inputVisible.visible17 && (
        <InputContainer>
          <p className="label">증여자</p>
          <div className="input">
            <SelectBox
              placeholder="증여자를 선택해주세요."
              options={Util.getCodeArray("2015").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled17}
              selectedValue={inputValue.donor}
              handleSelect={handleSelectDonor}
            />
          </div>
        </InputContainer>
      )}
      {/* 18. 증여자의 주택수 */}
      {inputVisible.visible18 && (
        <InputContainer>
          <p className="label">증여자의 주택수</p>
          <div className="input">
            <SelectBox
              placeholder="증여자의 주택수를 선택해주세요."
              options={Util.getCodeArray("2016").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled18}
              selectedValue={inputValue.donorHseNo}
              handleSelect={handleSelectDonorHseNo}
            />
          </div>
        </InputContainer>
      )}
      {/* 19. 과세표준 */}
      {inputVisible.visible19 && (
        <InputContainer>
          <p className="label">과세표준</p>
          <div className="input">
            {/*<TextInput
                            name="taxBase"
                            value={inputValue.taxBase}
                            onChange={handleChangeTaxBase}
                            placeholder="과세표준을 입력해주세요."
                            unit="원"
                        />*/}
            <NumberInputContainer
              $filled={inputValue.taxBase === "" ? false : true}
            >
              <div className="inputWrap">
                <NumericFormat
                  value={inputValue.taxBase}
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  maxLength={15}
                  onChange={(e) => {
                    if (handleChangeTaxBase === undefined) {
                      return;
                    }
                    handleChangeTaxBase(e);
                  }}
                  placeholder="과세표준을 입력해주세요."
                  className="numberInput"
                  name="taxBase"
                />
                <p className="unit">원</p>
              </div>
            </NumberInputContainer>
          </div>
          <div className="help">{inputHelp.taxBaseHelp}</div>
        </InputContainer>
      )}
      {/* 20. 지분율 */}
      {inputVisible.visible20 && (
        <InputContainer>
          <p className="label">지분율</p>
          <div className="input">
            <NumberInputContainer
              $filled={inputValue.shareRatio === "" ? false : true}
            >
              <div className="inputWrap">
                <NumericFormat
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  maxLength={3}
                  name="shareRatio"
                  value={inputValue.shareRatio}
                  onChange={(e) => {
                    if (handleChangeShareRatio === undefined) {
                      return;
                    }
                    handleChangeShareRatio(e);
                  }}
                  disabled={inputDisabled.disabled20}
                  placeholder="지분율을 입력해주세요."
                  className="numberInput"
                />
                <p className="unit">%</p>
              </div>
            </NumberInputContainer>
            <div className="help warning">{shareRatioHelpText}</div>
          </div>
        </InputContainer>
      )}
      {/* 21. 면적 */}
      {inputVisible.visible21 && (
        <InputContainer>
          <p className="label">면적</p>
          <div className="input">
            <SelectBox
              placeholder="면적을 선택해주세요."
              options={Util.getCodeArray("2012").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled21}
              selectedValue={inputValue.prvuseAr}
              handleSelect={handleSelectPrvuseAr}
            />
          </div>
        </InputContainer>
      )}
    </MainSectionContainer>
  );
}

const InputContainer = styled.div`
  width: 100%;
  margin-top: 12px;

  .label {
    margin-bottom: 5px;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.29;
    letter-spacing: -0.058em;
    color: ${(props) => props.theme.color.nGray30};
  }

  .help {
    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.29;
    letter-spacing: -0.058em;
    color: ${(props) => props.theme.color.sNavyLight01};
    text-align: right;

    margin-top: 5px;

    &.warning {
      color: ${(props) => props.theme.color.sysRed100};
    }
  }
`;

const NumberInputContainer = styled.div<{ $filled: boolean }>`
  width: 100%;
  height: auto;

  ${(props) => {
    if (props.$filled) {
      return `
          padding: 5px;
          border-radius: 10px;
          background-color: ${props.theme.color.pGreenLight03};
          border: none;
        `;
    }
  }}

  .inputWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 12px 16px;
    background-color: white;
    border: 1px solid
      ${(props) =>
        props.$filled
          ? props.theme.color.pGreenLight02
          : props.theme.color.nGray70};
    border-radius: 8px;

    .unit {
      font-family: "Spoqa Han Sans Neo";
      font-weight: 500;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.sNavy};
    }

    .numberInput {
      width: 100%;
      height: 100%;
      border: none;

      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.sNavy};

      &:focus {
        outline: none;
      }
    }
    .numberInput::placeholder {
      color: ${(props) => props.theme.color.nGray50};
    }

    .numberInput::-webkit-input-placeholder {
      color: ${(props) => props.theme.color.nGray50};
    }

    .numberInput::-ms-input-placeholder {
      color: ${(props) => props.theme.color.nGray50};
    }
  }
`;

export default ExtraInfo;
