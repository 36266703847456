/***********************************************************************************
*** Law10at1 ** 조세특례제한법 97조의 3 장기일반민간임대주택 등에 대한 양도소득세의 과세특례 Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law10at1Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}

export default function Law10at1({ setModalOpen, setLawModalTitle, callBack, callMode }: Law10at1Props) {
    React.useEffect(() => {
        setLawModalTitle("조세특례제한법 97조의 3 장기일반민간임대주택 등에 대한 양도소득세의 과세특례");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className='w100p h500 ofA mb30'>
                <table className={
                    'forest-list-table bdt1 bdb1 bdcDDDDDD ' +
                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075'
                }>
                   <colgroup>
                        <col className='w80' ></col>   {/* 구분       */}
                        <col className='w80' ></col>   {/* 주택구분   */} 
                        <col className='w140'></col>   {/* 주택규모   */} 
                        <col className='w220'></col>   {/* 취득시기   */} 
                        <col className='w150'></col>   {/* 가액기준   */} 
                        <col className='w110'></col>   {/* 지자체     */} 
                        <col className='w110'></col>   {/* 세무서     */} 
                        <col className='w100'></col>   {/* 민특법분류 */} 
                        <col className='w100'></col>   {/* 임대기간   */} 
                        <col className='w80' ></col>   {/* 상한율     */} 
                        <col className='w100'></col>   {/* 8년이상    */} 
                        <col className='w100'></col>   {/* 10년이상   */}
                    </colgroup>
                    <tbody>
                        <tr className='h40'>
                            <th rowSpan={2}>구분</th>
                            <th rowSpan={2}>주택구분</th>
                            <th rowSpan={2}>주택규모</th>
                            <th rowSpan={2}>취득시기</th>
                            <th rowSpan={2}>가액기준</th>
                            <th colSpan={2}>등록</th>
                            <th rowSpan={2}>민특법 분류</th>
                            <th rowSpan={2}>임대기간</th>
                            <th rowSpan={2}>상한율</th>
                            <th colSpan={2}>혜택<br/>(장기보유특별 공제율)</th>
                        </tr>
                        <tr className='h29'>
                            <th>지자체</th>
                            <th>세무서</th>
                            <th>8년 이상</th>
                            <th>10년 이상</th>
                        </tr>
                        <tr>
                            <th rowSpan={4}>개별사항</th>
                            <td rowSpan={4}>모든주택</td>
                            <td rowSpan={4}>국민주택규모 이하<br/>(수도권 제외한 도시지역이 아닌 읍,면지역은 주거전용면적이 100㎡이하, 다가구주택은 가구당 전용면적)</td>
                            <td>2018.9.13. 이전<br/>(2018.9.13. 이전 계약체결+계약급 지급한 주택,<br/>2018.9.13. 이전 취득한 분양권, 조합원입주권에 의해 취득한 주택 포함)</td>
                            <td>-</td>
                            <td rowSpan={2}>2024.12.31까지 등록</td>
                            <td rowSpan={2}>2024.12.31까지 등록</td>
                            <td rowSpan={2}>건설<br/>임대주택</td>
                            <td rowSpan={2}>8년(10년) 이상</td>
                            <td rowSpan={2}>5% 제한</td>
                            <td rowSpan={2}>50%<br/>(공통사항 3번주의)</td>
                            <td rowSpan={2}>70%</td>
                        </tr>
                        <tr>
                            <td>2018.9.14. 이후</td>
                            <td className='bdr1-i bdcDDDDDD-i'>임대개시 당시 기준시가<br/>수도권 6억원(비수도권 3억원) 이하</td>
                        </tr>
                        <tr>
                            <td>2018.9.13. 이전<br/>(2018.9.13. 이전 계약체결+계약급 지급한 주택,<br/>2018.9.13. 이전 취득한 분양권, 조합원입주권에 의해 취득한 주택 포함)</td>
                            <td>-</td>
                            <td rowSpan={2}>2020.12.31까지 등록</td>
                            <td rowSpan={2}>2020.12.31까지 등록</td>
                            <td rowSpan={2}>매입<br/>임대주택</td>
                            <td rowSpan={2}>8년(10년) 이상</td>
                            <td rowSpan={2}>5% 제한</td>
                            <td rowSpan={2}>50%</td>
                            <td rowSpan={2}>70%</td>
                        </tr>
                        <tr>
                            <td>2018.9.14. 이후</td>
                            <td className='bdr1-i bdcDDDDDD-i'>임대개시 당시 기준시가<br/>수도권 6억원(비수도권 3억원) 이하</td>
                        </tr>
                        <tr className='h110'>
                            <th>공통사항</th>
                            <td className='taL pt15 pb15 pl15' colSpan={12}>
                                1. 2020.7.11. 이후 장기임대 등록한 아파트, 2020.7.11. 이후 단기임대를 장기임대로 변경 등록한 주택은 제외<br/>
                                2. 임대기간 계산방식 <br/>
                                가. 임대기간 개시일은 지자체 등록일, 세무서 등록일, 임대개시일중 가장 늦은날이 됨 <br/>
                                나. 단기임대주택을 장기일반민간임대주택으로 변경 등록한 경우 아래와 같이 변경전 임대기간을 추가 인정함<br/>
                                    - 19.02.11 이전 변경등록 : 단기임대기간 * 50%(최대 5년)<br/>
                                    - 19.02.12 이후 변경등록 : 단기임대기간 * 100%(최대 4년, 19.02.12. 현재 단기임대기간이 8년 초과시 최대 5년)<br/>
                                다. 피상속인의 임대주택을 상속받아 임대하는 경우에는 피상속인의 임대기간을 상속인의 임대기간에 통산함.<br/>
                                라. 도정법, 소규모정비법, 주택법상 사업으로 임대할 수 없는 경우 관리처분계획인가일 등 전후 6개월까지의 기간 동안 계속 임대한 것으로 보되. 임대기간 계산시에는 실제 임대기간만 포함한다.<br/>
                                마. 3개월이내 공실도 임대기간에 포함함<br/>
                                바. 장기일반민간임대주택 중 아파트를 임대하는 경우 의무임대기간 종료로 등록이 자동말소되는 경우 해당 주택은 8년 동안 등록 및 임대를 한 것으로 본다.(서면-2020-법령해석재산-3286, 2021.05.11.)<br/>
                                3. 23.01.01.이후 시군구 등록 + 23.01.01. 이후 양도 + 임대기간이 8년이상 10년미만 주택은 장기보유특별공제 50%를 적용하지 않음
                            </td>
                        </tr>
                        <tr className='h70'>
                            <th>기타사항</th>
                            <td className='taL pt15 pb15 pl15' colSpan={12}>
                                1. 조세특례제한법 97조의 4, 97조의 5에 따른 장기임대주택에 대한 양도소득세의 과세특례와 중복하여 적용하지 아니한다.<br/>
                                2. 장기보유특별공제 특례를 적용함에 있어서 그 적용대상은 임대기간 중에 발생하는 소득에 한정됨을 명확히 하였으며, 임대기간 중 발생한 양도소득금액은 기준시가를 기준으로 안분한다.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}