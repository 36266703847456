/*******************************************************************************
*** index ** 모바일 상속세 index(InheTaxCalc)
*******************************************************************************/
import { useState, useReducer } from "react";
import { Body, MainContainer } from "js/mobile/common/layout";
import Header from "js/mobile/common/CalcHeader";
import CalcStep from "js/mobile/common/CalcStep";
import BasicInfo from "./BasicInfo";
import ExtraInfo from "./ExtraInfo";
import CalcStepButton from "js/mobile/common/CalcStepButton";
import InheTaxCalcResult from "js/mobile/InheTaxCalcResult";
import { useInheCalcSteps } from "./useInheCalcSteps";
import { InheTaxCalResult, InheTaxParamProps, } from "js/common/types/types";
import { inheTaxStatesReducer, initialState } from "./inheTaxStatesReducer";
import Axios from "js/common/Axios";
import AppContext from "js/common/AppContext";
import GiftInfo from "./GiftInfo";
import DeduInfo from "./DeduInfo";
import Util from "js/common/Util";
import AssetsUtil from "js/common/AssetsUtil";

function InheTaxCalc() {
    const [inheTaxState, dispatch] = useReducer(inheTaxStatesReducer, initialState);
    const {
          usingStepList
        , currentInheStep
        , currentStepIndex
        , handleClickBackStep
        , handleClickNextStep
        , handleClickReset
        , backDisabled
        , nextDisabled
        , isLastStep
        , initInheStep
    } = useInheCalcSteps(inheTaxState, dispatch);

    const [showCalcResult, setShowCalcResult] = useState<boolean>(false);   // 세액 결과 표시여부
    const [calcResult    , setCalcResult    ] = useState<InheTaxCalResult | null>(null);   // 상속세 계산결과

    // Next click
    const onClickNext = () => {
        if (isLastStep) {
            inheritanceTaxCalc(inheTaxState.inputValue);
        } else {
            handleClickNextStep();
        }
    };

    const inheritanceTaxCalc = (inputValue: InheTaxParamProps) => {

        // 배우자 단독인 경우 배우자 장애인 인적공제 계산
        let personDedu = "";
        if (inputValue.spouse === "Y" && inputValue.childNumber === "0") {    // 배우자 단독상속
            if (inputValue.spouseDisabled === "Y") { 
                if (inputValue.spouseGender === "M" && Util.isNotEmpty(inputValue.spouseAge)) { // 남
                    let lifeExpectMan = AssetsUtil.getLifeExpectMan(inputValue.spouseAge);
                    personDedu = ((parseInt(lifeExpectMan) * 10000000).toString());
                } if (inputValue.spouseGender === "F" && Util.isNotEmpty(inputValue.spouseAge)) {    // 여
                    let lifeExpectWom = AssetsUtil.getLifeExpectWom(inputValue.spouseAge);
                    personDedu = ((parseInt(lifeExpectWom) * 10000000).toString());
                }
            }
        } else {
            personDedu = inputValue.personDedu;
        }

        Axios.dataAccess({
            url: "common/mobile/callApi.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                apiName: "taxcalc",
                tailUrl: "simpletax/inhetax",
                paramStr: "I" 
                        + "?inher_prop="           + inputValue.inherProp            // 01. 상속재산가액  
                        + "&esti_inher="           + inputValue.estiInher            // 02. 추정상속가액
                        + "&trust_assets="         + inputValue.trustAssets          // 03. 퇴직금/보험금/신탁자산 
                        + "&spouse="               + inputValue.spouse               // 04. 배우자 유무
                        + "&spouse_pre_gift="      + inputValue.spousePreGift        // 05. 배우자 사전증여 유무 
                        + "&spouse_pre_gift_prop=" + inputValue.spousePreGiftProp    // 06. 배우자 사전증여 재산가액 
                        + "&spouse_pre_gift_tax="  + inputValue.spousePreGiftTax     // 07. 배우자 사전증여세액 
                        + "&child_number="         + inputValue.childNumber          // 08. 자녀 수         
                        + "&pre_gift="             + inputValue.preGift              // 09. 사전증여 유무         
                        + "&pre_gift_prop="        + inputValue.preGiftProp          // 10. 사전증여 재산가액 
                        + "&pre_gift_tax="         + inputValue.preGiftTax           // 11. 사전증여세액 
                        + "&debt_amt="             + inputValue.debtAmt              // 12. 채무
                        + "&utility_bill="         + inputValue.utilityBill          // 13. 공과금 
                        + "&funeral_expen="        + inputValue.funeralExpen         // 14. 장례비용 
                        + "&dedu_cls="             + inputValue.deduCls              // 15. 공제구분
                        + "&sum_dedu="             + inputValue.sumDedu              // 16. 일괄공제
                        + "&basic_dedu="           + inputValue.basicDedu            // 17. 기초공제
                        + "&person_dedu="          + personDedu                      // 18. 인적공제
                        + "&spouse_disabled="      + inputValue.spouseDisabled       // 19. 배우자 장애인 여부 
                        + "&spouse_gender="        + inputValue.spouseGender         // 20. 배우자 성별 
                        + "&spouse_age="           + inputValue.spouseAge            // 21. 배우자 나이
                        + "&spouse_inher_prop="    + inputValue.spouseInherProp      // 22. 배우자 상속재산가액     
                        + "&spouse_suc_util_debt=" + inputValue.spouseSucUtilDebt    // 23. 배우자 승계 공과금&채무         
                        + "&family_dedu="          + inputValue.familyDedu           // 24. 가업상속공제
                        + "&farm_dedu="            + inputValue.farmDedu             // 25. 영농상속공제
                        + "&house_dedu="           + inputValue.houseDedu            // 26. 동거주택상속공제
                        + "&finan_dedu="           + inputValue.finanDedu            // 27. 금융상속공제
                        + "&direct_heir="          + inputValue.directHeir           // 22_0. 직계존속 상속인 유무

            },
            onSuccessFn: (res: any) => {
                if (res.item.rtn_list[0].inhe_tax_base === "0") {
                // 양도차익이 zero인 경우
                setShowCalcResult(false);
                AppContext.openAlert({
                    title: "과세 대상 여부",
                    msg: "상속세 납부 대상이 아닙니다.",
                    icon: "check",
                });
                return;
                }

                // 상속세 세액 계산 결과
                setCalcResult(res.item.rtn_list[0]);
                setShowCalcResult(true);
            },
            onFailedFn: () => {
                setShowCalcResult(false);
            },
        });
    };

    // 계산 다시하기(세액 결과표 하단)
    const handleClickBackInResult = () => {
        setCalcResult(null);
        setShowCalcResult(false);

        initInheStep();
        dispatch({
        type: "INIT_BASIC_INFO",
        payload: "",
        });
    };

    if (showCalcResult) {
        return (
            <InheTaxCalcResult
                calcResult={calcResult}
                inputParams={inheTaxState.inputValue}
                onClickBack={handleClickBackInResult}   // 세액 결과표 계산 다시하기 Button
            />
        );
    } else {
        return (
        <Body>
            <Header
                title="상속세 계산"
                totalStepCount={usingStepList.length}
                currentStep={currentStepIndex + 1}
                onClickBack={() => console.info(">>> back")}
            />

            <CalcStep stepInfoList={usingStepList} />

            <MainContainer>
            {currentInheStep && currentInheStep.stepId === "INHE01" && (
                <BasicInfo
                    inputValue={inheTaxState.inputValue}
                    inputVisible={inheTaxState.inputVisible}
                    inputDisabled={inheTaxState.inputDisabled}
                    onUpdateInput={dispatch}
                />
            )}
            {currentInheStep && currentInheStep.stepId === "INHE02" && (
                <GiftInfo
                    inputValue={inheTaxState.inputValue}
                    inputVisible={inheTaxState.inputVisible}
                    inputDisabled={inheTaxState.inputDisabled}
                    inputTitle={inheTaxState.inputTitle}
                    onUpdateInput={dispatch}
                />
            )}
            {currentInheStep && currentInheStep.stepId === "INHE03" && (
                <DeduInfo
                    inputValue={inheTaxState.inputValue}
                    inputVisible={inheTaxState.inputVisible}
                    inputTitle={inheTaxState.inputTitle}
                    inputDisabled={inheTaxState.inputDisabled}
                    onUpdateInput={dispatch}
                />
            )}                
            {currentInheStep && currentInheStep.stepId === "INHE04" && (
                <ExtraInfo
                    inputValue={inheTaxState.inputValue}
                    inputVisible={inheTaxState.inputVisible}
                    inputTitle={inheTaxState.inputTitle}
                    onUpdateInput={dispatch}
                />
            )}
            <CalcStepButton
                onClickBack={handleClickBackStep}
                onClickNext={onClickNext}
                onClickReset={handleClickReset}
                backDisabled={backDisabled}
                nextDisabled={nextDisabled}
                isLastStep={isLastStep}
            />
            </MainContainer>
        </Body>
        );
    }
}

export default InheTaxCalc;
