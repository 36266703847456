import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AssetsShow from "js/system/assets/show/AssetsShow";
import Axios from 'js/common/Axios';
import * as Atom from "js/common/Atom";
import { useSetRecoilState } from "recoil";
import Util from 'js/common/Util';
import AppContext from 'js/common/AppContext';

const AssetsView = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [rmstnfamNm, setRmstnfamNm] = React.useState<string>("");
    const [rmstnfamSe, setRmstnfamSe] = React.useState<string>("");
    const [estateSttus, setEstateSttus] = React.useState<string>("");
    const [stepCode, setStepCode] = React.useState<string>("");
    const setCurrFormMode = useSetRecoilState(Atom.currFormMode);

    const goAssetsList = () => {
        if (params.callPage === "Assets") {
            navigate('/system/assets');
        } else if (params.callPage === "Acqstax") {
            navigate('/system/acqstax');
        } else if (params.callPage === "TrsftaxTest") {
            navigate('/system/trsftax/test');
        }
    }
    const goAssetsEdit = () => {
        setCurrFormMode("mod");
        navigate("/system/assets/mod/" + params.callPage + "/" + params.assetsSn);
    }

    React.useEffect(() => {
        Axios.dataAccess({
            url: "system/assets/selRmstnfamInfoByAssets.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                assetsSn: Number(params.assetsSn)
            },
            onSuccessFn: (res: any) => {
                if (Util.isEmpty(res.item)) {
                    AppContext.openAlert({
                        title: "자산정보 없음",
                        msg: "자산 정보가 없습니다.",
                        icon: "warning",
                        handleClose: () => navigate('/system/assets')
                    });
                    return;
                }
                setRmstnfamNm(res.item.rmstnfamNm);
                setRmstnfamSe(res.item.rmstnfamSe);
                setEstateSttus(res.item.estateSttus);
                setStepCode(res.item.stepCode);
            }
        });
    }, [params.assetsSn, navigate]);

    return (
        <>
            <div className="h126 pt30 bdBox">
                <span className="SpoqaHanSansNeo-Medium fs38 ls266 fcBlack">
                    {estateSttus === "1002" ? "보유" : ""}
                    {estateSttus === "1003" ? "취득" : ""}
                    자산 조회
                </span>
            </div>
            <div className="dpFlx jcC pt30">
                <div className='w1200 pb50'>
                    <AssetsShow assetsSn={Number(params.assetsSn)}/>
                    <div className='w100p h56 dpFlx aiC jcC mt40 mb100'>
                        <button className="w111 h100p dpFlx aiC jcC bd1 bdcDDDDDD bdBox mr10 csP bgcTp" onClick={goAssetsList}>
                            <span className="SpoqaHanSansNeo-Medium fs16 fc222222">목록</span>
                        </button>
                        <button className={"w240 h100p bgc17A840 aiC jcC csP bdBox bdN " + (stepCode !== "1010" ? "dpFlx" : "dpNone")} onClick={goAssetsEdit}>
                            <span className="SpoqaHanSansNeo-Medium fs16 fcWhite">수정</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="w100p h120 bdt1 bdcDFE0E4 posF b0 bgcWhite aiC jcC dpFlx">
                <div className="w1210 dpFlx aiC jcSb">
                    <span className="SpoqaHanSansNeo-Regular fs18 ls1">
                        <span className="fc17A840">{rmstnfamNm}</span>
                        <span className="fc222222">
                            ({Util.getValToCode("1001", rmstnfamSe)})님의
                            {estateSttus === "1002" ? " 보유" : ""}
                            {estateSttus === "1003" ? " 취득" : ""}
                            자산
                        </span>
                    </span>
                    <div className="dpFlx aiC jcC h56">
                        <button className="w111 h100p bgcWhite bd1 bdcDDDDDD dpFlx aiC jcC csP bdBox mr10" onClick={goAssetsList}>
                            <span className="SpoqaHanSansNeo-Medium fs16 fc222222">목록</span>
                        </button>
                        <button className={"w210 h100p bgc17A840 aiC jcC csP bdBox bdN " + (stepCode !== "1010" ? "dpFlx" : "dpNone")} onClick={goAssetsEdit}>
                            <span className="SpoqaHanSansNeo-Medium fs16 fcWhite">수정</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AssetsView;