/*******************************************************************************
*** useGiftCalcSteps ** 모바일 증여세 단계별 validation
*******************************************************************************/
import { produce } from "immer";
import { Step }    from "js/common/types/types";
import Util        from "js/common/Util";
import { Dispatch, useEffect, useState } from "react";
import { GiftTaxAction, GiftTaxStates }  from "./giftTaxStatesReducer";

// 증여세 정보 입력 단계
const stepList: Step[] = [
    {
        stepId  : "GIFT01"
      , name    : "기초 정보 입력"
      , order   : 1
      , useYn   : true
      , selected: true
      , subSteps: []
    },
    {
        stepId  : "GIFT02"
      , name    : "공제 정보 입력"
      , order   : 2
      , useYn   : false
      , selected: false
      , subSteps: []
    }
];

export function useGiftCalcSteps(giftTaxState: GiftTaxStates, onUpdateGiftTaxState: Dispatch<GiftTaxAction>) {
    const [giftStepList, setGiftStepList] = useState<Step[]>(stepList); // 입력단계 List
    const [backDisabled, setBackDisabled] = useState<boolean>(true);
    const [nextDisabled, setNextDisabled] = useState<boolean>(true);  
    
    const usingStepList   = giftStepList.filter((step: Step) => step.useYn);
    const currentGiftStep = usingStepList.find((step: Step) => step.selected);
    const [isLastStep, setIsLastStep] = useState<boolean>(false);

    // 입력 단계가 바뀌면 next 버튼 비활성화  
    useEffect(() => {
        setNextDisabled(true);
    }, [currentGiftStep]);

    // 초기화
    const initGiftStep = () => {
        setGiftStepList(stepList);
    };

    // 기초정보입력 단계 완료 여부 확인
    useEffect(() => {
        if (!currentGiftStep || currentGiftStep.stepId !== "GIFT01") return;

        const inputVisible = giftTaxState.inputVisible;
        const inputValue   = giftTaxState.inputValue;

        let validateYn = true;

        // 01. 증여자
        if (inputVisible.visible01) {
            if (Util.isEmpty(inputValue.donor)) {
                validateYn = false;
            }

            // 직계존속일 경우에만 공제정보 입력 단계(GIFT02) 활성화
            if (inputValue.donor === "202") {
                setGiftStepList(
                    produce((draft: Step[]) => {
                        const step = draft.find((step) => step.stepId === "GIFT02");
                        if (step) {
                            step.useYn = true;
                        }
                    })
                );
            }            
        }        

        // 02. 증여 재산가액
        if (inputVisible.visible02) {
            if (Util.isEmpty(inputValue.giftProp)) {
                validateYn = false;
            }
        }

        // 03. 채무인수 여부 
        if (inputVisible.visible03) {
            if (Util.isEmpty(inputValue.debtAssum)) {
                validateYn = false;
            }
        }

        // 04. 채무액
        if (inputVisible.visible04) {
            if (Util.isEmpty(inputValue.debtAmt)) {
                validateYn = false;
            }
        }

        // 05. 증여자 사전증여 유무 
        if (inputVisible.visible05) {
            if (Util.isEmpty(inputValue.preGift)) {
                validateYn = false;
            }
        }

        // 06. 사전증여 재산가액
        if (inputVisible.visible06) {
            if (Util.isEmpty(inputValue.preGiftProp)) {
                validateYn = false;
            }
        }

        // 07. 사전증여 세액
        if (inputVisible.visible07) {
            if (Util.isEmpty(inputValue.preGiftTax)) {
                validateYn = false;
            }
        }
        
        // Next 버튼 활성화
        setNextDisabled(!validateYn);

    }, [giftTaxState.inputVisible, giftTaxState.inputValue, currentGiftStep]);

    // 공제정보입력 단계 완료 여부 확인
    useEffect(() => {
        if (!currentGiftStep || currentGiftStep.stepId !== "GIFT02") return;

        const inputVisible = giftTaxState.inputVisible;
        const inputValue   = giftTaxState.inputValue;

        let validateYn = true;

        // 08. 수증자 미성년자 여부
        if (inputVisible.visible08) {
            if (Util.isEmpty(inputValue.doneeMinor)) {
                validateYn = false;
            }
        }

        // 09. 기본공제
        if (inputVisible.visible09) {
            if (Util.isEmpty(inputValue.basicDedu)) {
                validateYn = false;
            }
        }

        // 10. 혼인공제
        if (inputVisible.visible10) {
            if (Util.isEmpty(inputValue.marriageDedu)) {
                validateYn = false;
            }
        }

        // 11. 출산공제
        if (inputVisible.visible11) {
            if (Util.isEmpty(inputValue.childbirthDedu)) {
                validateYn = false;
            }
        }        

        // Next 버튼 활성화
        setNextDisabled(!validateYn);

    }, [giftTaxState.inputVisible, giftTaxState.inputValue, currentGiftStep]);    

    // 기초 정보 입력 단계에서는 back 버튼 비활성화
    useEffect(() => {
        if (currentGiftStep && currentGiftStep.stepId === "GIFT01") {
          setBackDisabled(true);
        } else {
          setBackDisabled(false);
        }
    }, [currentGiftStep]);
    
    // 마지막 단계에 도달했는지 여부 확인
    useEffect(() => {
        if (!currentGiftStep) return;
        
        // 직계존속인 경우에만 2단계까지 존재
        if (currentGiftStep.stepId === "GIFT01") {
            if (giftTaxState.inputValue.donor === "202") {
                setIsLastStep(false);
            } else {
                setIsLastStep(true);
            }
        } else if (currentGiftStep.stepId === "GIFT02") {
            setIsLastStep(true);  
        }      
    }, [currentGiftStep, giftTaxState.inputValue.donor]);

    // Back(Footer Navigator)
    const handleClickBackStep = () => {
        if (!currentGiftStep) return;
    
        switch (currentGiftStep.stepId) {
            // 기초정보입력
            case "GIFT01": {
                break;
            }
            case "GIFT02": {
                moveToBackStep();   // Back 이벤트
                // 공제정보 초기화
                onUpdateGiftTaxState({
                    type: "INIT_DEDUCTION_INFO",
                    payload: "",
                });
                break;
            }
        }
    };

    // Next(Footer Navigator)
    const handleClickNextStep = () => {
        if (!currentGiftStep) return;
    
        switch (currentGiftStep.stepId) {
            case "GIFT01": {
                moveToNextStep();   // Next 이벤트
                break;
            }
            case "GIFT02": {
                break;
            }
        }
    };

    // Back 이벤트 처리
    const moveToBackStep = () => {
        setGiftStepList(
            produce((draft: Step[]) => {
                const usingSteps = draft.filter((step) => step.useYn);
                const selectedIndex = usingSteps.findIndex((step) => step.selected);
                if (selectedIndex !== -1 && selectedIndex > 0) {
                    usingSteps[selectedIndex].selected = false;
                    usingSteps[selectedIndex - 1].selected = true;
                }
            })
        );
    };

    // Next 이벤트 처리
    const moveToNextStep = () => {
        setGiftStepList(
            produce((draft: Step[]) => {
                const usingSteps = draft.filter((step) => step.useYn);
                const selectedIndex = usingSteps.findIndex((step) => step.selected);
                if (selectedIndex !== -1 && selectedIndex < usingSteps.length - 1) {
                    usingSteps[selectedIndex].selected = false;
                    usingSteps[selectedIndex + 1].selected = true;
                }
            })
        );
    };

    // 선택 초기화(Footer Navigator)
    const handleClickReset = () => {
        if (!currentGiftStep) return;

        switch (currentGiftStep.stepId) {
            case "GIFT01": {
                // 기초정보입력 초기화
                onUpdateGiftTaxState({
                    type: "INIT_BASIC_INFO",
                    payload: "",
                });
                setGiftStepList(stepList);
                break;
            }
            case "GIFT02": {
                // 공제정보 초기화
                onUpdateGiftTaxState({
                    type: "INIT_DEDUCTION_INFO",
                    payload: "",
                });
                break;
            }
        }
    };

    return {
        giftStepList            // 입력단계 List    
      , currentGiftStep         // 현재 단계
      , backDisabled            // Back 비활성화
      , nextDisabled            // Next 비활성화
      , initGiftStep            // 계산 다시하기
      , isLastStep              // 마지막 단계 확인
      , handleClickBackStep     // Back
      , handleClickNextStep     // Next
      , handleClickReset        // 선택초기화
    }    
}