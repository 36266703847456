/*******************************************************************************
*** ExtraInfo ** 모바일 증여세 공제정보 입력
*******************************************************************************/
import Util                                     from "js/common/Util";
import { GiftTaxAction }                        from "./giftTaxStatesReducer";
import SelectBox, { SelectOption }              from "../common/SelectBox";
import { InputContainer, MainSectionContainer } from "../common/layout";
import { GiftTaxParamProps, GiftTaxParamVisible, GiftTaxParamDisabled, Code } from "js/common/types/types";

type Props = {
    inputValue: GiftTaxParamProps;
    inputVisible : GiftTaxParamVisible;
    inputDisabled: GiftTaxParamDisabled;
    onUpdateInput: (action: GiftTaxAction) => void;
};

function ExtraInfo({ inputValue, inputVisible, inputDisabled, onUpdateInput }: Props) {

    // 08. 수증자 미성년자 여부
    const handleSelectDoneeMinor = (selected: SelectOption) => {
        onUpdateInput({
            type: "UPDATED_DONEEMINOR",
            payload: selected.value,
        });
    };

    // 09. 기본공제
    const handleSelectBasicDedu = (selected: SelectOption) => {
        onUpdateInput({
            type: "UPDATED_BASICDEDU",
            payload: selected.value,
        });
    };

    // 10. 혼인공제
    const handleSelectMarriageDedu = (selected: SelectOption) => {
        onUpdateInput({
            type: "UPDATED_MARRIAGEDEDU",
            payload: selected.value,
        });
    };

    // 11. 출산공제
    const handleSelectChildbirthDedu = (selected: SelectOption) => {
        onUpdateInput({
            type: "UPDATED_CHILDBIRTHDEDU",
            payload: selected.value,
        });
    };    

    return (
        <MainSectionContainer>
            <p className="desc">
                <span>자산의</span>
                <span className="bold"> 부가정보</span>
                <span>를 입력해주세요.</span>
            </p>
            {/*08. 수증자 미성년자 여부 */}
            {inputVisible.visible08 && (
                <InputContainer>
                    <p className="label">수증자 미성년자 여부</p>
                    <div className="input">
                        <SelectBox
                            placeholder="수증자 미성년자 여부를 선택해주세요."
                            options={Util.getCodeArray("2028").map((code: Code) => ({
                                value: code.code,
                                label: code.codeNm,
                            }))}
                            selectedValue={inputValue.doneeMinor}
                            handleSelect={handleSelectDoneeMinor}
                        />
                    </div>
                    <div className="help">증여를 받는 사람이 증여일 현재 만19세 미만</div>
                </InputContainer>
            )}

            {/*09. 기본공제 */}
            {inputVisible.visible09 && (
                <InputContainer>
                    <p className="label">기본공제</p>
                    <div className="input">
                        <SelectBox
                            placeholder="기본공제를 선택해주세요."
                            options={Util.getCodeArray("2029").map((code: Code) => ({
                                value: code.code,
                                label: code.codeNm,
                            }))}
                            disabled={true}
                            selectedValue={inputValue.basicDedu}
                            handleSelect={handleSelectBasicDedu}
                        />
                    </div>
                </InputContainer>
            )}

            {/* 10. 혼인공제 */}
            {inputVisible.visible10 && (
                <InputContainer>
                    <p className="label">혼인공제</p>
                    <div className="input">
                        <SelectBox
                            placeholder="혼인공제를 선택해주세요."
                            options={Util.getCodeArray("2029").map((code: Code) => ({
                                value: code.code,
                                label: code.codeNm,
                            }))}
                            selectedValue={inputValue.marriageDedu}
                            handleSelect={handleSelectMarriageDedu}
                        />
                    </div>
                    <div className="help">혼인신고일 이전 2년 ~ 이후 2년</div>
                </InputContainer>
            )}

            {/* 11. 출산공제 */}
            {inputVisible.visible11 && (
                <InputContainer>
                    <p className="label">출산공제</p>
                    <div className="input">
                        <SelectBox
                            placeholder="출산공제를 선택해주세요."
                            options={Util.getCodeArray("2029").map((code: Code) => ({
                                value: code.code,
                                label: code.codeNm,
                            }))}
                            selectedValue={inputValue.childbirthDedu}
                            handleSelect={handleSelectChildbirthDedu}
                        />
                    </div>
                    <div className="help">자녀 출생일로부터 2년 이내</div>
                </InputContainer>
            )}

        </MainSectionContainer>
    );
}

export default ExtraInfo;