import { useState, useEffect } from "react";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

import Util from "js/common/Util";
import {
  acqsInputVisibleState,
  acqsInputValueState,
  acqsInputDisabledState,
} from "js/common/Atom";
import { MainSectionContainer } from "../common/layout";
import RealEstateItem from "../common/RealEstateItem";
import { useAcqsInputs } from "js/common/hooks/useAcqsInputs";
import { Code } from "js/common/types/types";
import SelectBox, { SelectOption } from "js/mobile/common/SelectBox";

interface RealEstateType {
  estateSe: string;
  name: string;
  desc: string;
}

const realEstateDesc: Record<string, string> = {
  "201": "주택 수, 취득원인, 조정대상지역, 면적 등을 반영하여 계산됩니다.",
  "202": "조합원입주권 취득원인, 멸실여부 등을 반영하여 계산됩니다.",
  "203": "취득원인, 용도, 면적 등을 반영하여 계산됩니다.",
  "204": "비거주용 건물 취득 시 적용됩니다.",
  "205": "취득원인, 용도, 지역 등을 반영하여 계산됩니다.",
};

function AssetBasicInfo() {
  const inputValue = useRecoilValue(acqsInputValueState);
  const inputDisabled = useRecoilValue(acqsInputDisabledState);
  const inputVisible = useRecoilValue(acqsInputVisibleState);
  const {
    onUpdateEstateSe,
    onUpdateEstateFom,
    onUpdateAcqsSrc1,
    onUpdateAcqsSrc2,
    onUpdateLoseYn,
    onUpdateLandType,
    onUpdateFarmPeriod,
    onUpdateAcqsCause1,
    onUpdateAcqsCause2,
    onUpdateAcqsCause3,
    onUpdateAcqsCause4,
  } = useAcqsInputs();
  const [realEstateTypes, setRealEstateTypes] = useState<RealEstateType[]>([]);

  useEffect(() => {
    const realEstateTypeList: RealEstateType[] = Util.getCodeArray("2001").map(
      (code: Code): RealEstateType => ({
        estateSe: code.code,
        name: code.codeNm,
        desc: realEstateDesc[code.code],
      })
    );

    setRealEstateTypes(realEstateTypeList);
  }, []);

  const handleRealEstateSelect = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (inputDisabled.disabled01) {
      return;
    }

    const selected = event.target.value;
    onUpdateEstateSe(selected);
  };

  const handleClickRealEsateItem = (selected: string) => {
    if (inputDisabled.disabled01) {
      return;
    }

    onUpdateEstateSe(selected);
  };

  const handleSelectEstateFom = (selected: SelectOption) => {
    onUpdateEstateFom(selected.value);
  };

  const handleSelectAcqsSrc1 = (selected: SelectOption) => {
    onUpdateAcqsSrc1(selected.value);
  };

  const handleSelectAcqsSrc2 = (selected: SelectOption) => {
    onUpdateAcqsSrc2(selected.value);
  };

  const handleSelectLoseYn = (selected: SelectOption) => {
    onUpdateLoseYn(selected.value);
  };

  const handleSelectLandType = (selected: SelectOption) => {
    onUpdateLandType(selected.value);
  };

  const handleSelectFarmPeriod = (selected: SelectOption) => {
    onUpdateFarmPeriod(selected.value);
  };

  const handleSelectAcqsCause1 = (selected: SelectOption) => {
    onUpdateAcqsCause1(selected.value);
  };

  const handleSelectAcqsCause2 = (selected: SelectOption) => {
    onUpdateAcqsCause2(selected.value);
  };

  const handleSelectAcqsCause3 = (selected: SelectOption) => {
    onUpdateAcqsCause3(selected.value);
  };

  const handleSelectAcqsCause4 = (selected: SelectOption) => {
    onUpdateAcqsCause4(selected.value);
  };

  return (
    <MainSectionContainer>
      <p className="desc">
        <span>취득할 자산의</span>
        <span className="bold"> 기초정보</span>
        <span>를 선택해주세요.</span>
      </p>
      {/* 01. 부동산의 종류 */}
      {inputVisible.visible01 && (
        <RealEstateList>
          <p className="label">부동산의 종류</p>
          {realEstateTypes.map((type: RealEstateType) => {
            return (
              <RealEstateItem
                name={type.name}
                estateSe={type.estateSe}
                desc={type.desc}
                selectedRealEstate={inputValue.estateSe}
                onRealEstateSelect={handleRealEstateSelect}
                onClickItem={handleClickRealEsateItem}
                key={type.estateSe}
              />
            );
          })}
        </RealEstateList>
      )}
      {/* 02. 주택구분 */}
      {inputVisible.visible02 && (
        <InputContainer>
          <p className="label">주택 구분</p>
          <div className="input">
            <SelectBox
              placeholder="주택 구분을 선택해주세요."
              options={Util.getCodeArray("2002").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled02}
              selectedValue={inputValue.estateFom}
              handleSelect={handleSelectEstateFom}
            />
          </div>
        </InputContainer>
      )}
      {/* 03. 취득구분1(아파트/연립) */}
      {inputVisible.visible03 && (
        <InputContainer>
          <p className="label">취득 구분</p>
          <div className="input">
            <SelectBox
              placeholder="취득 구분을 선택해주세요."
              options={Util.getCodeArray("2003").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled03}
              selectedValue={inputValue.acqsSrc1}
              handleSelect={handleSelectAcqsSrc1}
            />
          </div>
        </InputContainer>
      )}
      {/* 04. 취득구분(오피스텔) */}
      {inputVisible.visible04 && (
        <InputContainer>
          <p className="label">취득 구분</p>
          <div className="input">
            <SelectBox
              placeholder="취득 구분을 선택해주세요."
              options={Util.getCodeArray("2004").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled04}
              selectedValue={inputValue.acqsSrc2}
              handleSelect={handleSelectAcqsSrc2}
            />
          </div>
        </InputContainer>
      )}
      {/* 05. 멸실여부 */}
      {inputVisible.visible05 && (
        <InputContainer>
          <p className="label">멸실여부</p>
          <div className="input">
            <SelectBox
              placeholder="멸실여부를 선택해주세요."
              options={Util.getCodeArray("2017").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled05}
              selectedValue={inputValue.loseYn}
              handleSelect={handleSelectLoseYn}
            />
          </div>
        </InputContainer>
      )}
      {/* 06. 토지종류 */}
      {inputVisible.visible06 && (
        <InputContainer>
          <p className="label">토지종류</p>
          <div className="input">
            <SelectBox
              placeholder="토지종류를 선택해주세요."
              options={Util.getCodeArray("2018").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled06}
              selectedValue={inputValue.landType}
              handleSelect={handleSelectLandType}
            />
          </div>
        </InputContainer>
      )}
      {/* 07. 자경기간 */}
      {inputVisible.visible07 && (
        <InputContainer>
          <p className="label">자경기간</p>
          <div className="input">
            <SelectBox
              placeholder="자경기간을 선택해주세요."
              options={Util.getCodeArray("2019").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled07}
              selectedValue={inputValue.farmPeriod}
              handleSelect={handleSelectFarmPeriod}
            />
          </div>
        </InputContainer>
      )}
      {/* 08. 취득원인 1(기존 아파트) */}
      {inputVisible.visible08 && (
        <InputContainer>
          <p className="label">취득원인</p>
          <div className="input">
            <SelectBox
              placeholder="취득원인을 선택해주세요."
              options={Util.getCodeArray("2005").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled08}
              selectedValue={inputValue.acqsCause1}
              handleSelect={handleSelectAcqsCause1}
            />
          </div>
        </InputContainer>
      )}
      {/* 09. 취득원인 2(분양권/오피스텔) */}
      {inputVisible.visible09 && (
        <InputContainer>
          <p className="label">취득원인</p>
          <div className="input">
            <SelectBox
              placeholder="취득원인을 선택해주세요."
              options={Util.getCodeArray("2006").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled09}
              selectedValue={inputValue.acqsCause2}
              handleSelect={handleSelectAcqsCause2}
            />
          </div>
        </InputContainer>
      )}
      {/* 10. 취득원인 3(조합원입주권) */}
      {inputVisible.visible10 && (
        <InputContainer>
          <p className="label">취득원인</p>
          <div className="input">
            <SelectBox
              placeholder="취득원인을 선택해주세요."
              options={Util.getCodeArray("2007").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled10}
              selectedValue={inputValue.acqsCause3}
              handleSelect={handleSelectAcqsCause3}
            />
          </div>
        </InputContainer>
      )}
      {/* 11. 취득원인 4(다세대/다가구/다중/단독주택) */}
      {inputVisible.visible11 && (
        <InputContainer>
          <p className="label">취득원인</p>
          <div className="input">
            <SelectBox
              placeholder="취득원인을 선택해주세요."
              options={Util.getCodeArray("2008").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled11}
              selectedValue={inputValue.acqsCause4}
              handleSelect={handleSelectAcqsCause4}
            />
          </div>
        </InputContainer>
      )}
    </MainSectionContainer>
  );
}

const RealEstateList = styled.ul`
  width: 100%;
  padding: 0;
  margin-top: 10px;

  .label {
    margin-bottom: 5px;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.29;
    letter-spacing: -0.058em;
    color: ${(props) => props.theme.color.nGray30};
  }
`;

const InputContainer = styled.div`
  width: 100%;
  margin-top: 12px;

  .label {
    margin-bottom: 5px;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.29;
    letter-spacing: -0.058em;
    color: ${(props) => props.theme.color.nGray30};
  }
`;

export default AssetBasicInfo;
