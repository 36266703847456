/*******************************************************************************
 You are strictly prohibited to copy, disclose, distribute, modify, or use
 this program in part or as a whole without the prior written consent of 
 CallTax. CallTax, owns the intellectual property rights in and to this program.
 (Copyright © 2023 CallTax. All Rights Reserved| Confidential)
 
 Specification
    1. Program ID    : MultiHouse.tsx
    2. Program Title : 다중주택 도움말 Modal
    3. Created by    : JH Kim
    4. Create Date   : 2023.
    5. Reference     : 
        - AssetsBase.tsx 페이지에서 다중주택 Radio 버튼 클릭 시 Modal 호출
*******************************************************************************/
import AppContext from 'js/common/AppContext';

export default function MultiHouse({ setModalOpen, setEstateFom }: any) {

    const onClickNoBtn = () => {
        AppContext.openAlert({
            title: "주택구분 선택",
            msg: "다세대주택으로 선택됩니다.",
            icon: "warning"
        });
        setModalOpen(false);
        setEstateFom("1002");
    }

    return (
        <div className='w100p h100p'>
            <table className='forest-list-table w100p th-fm-SpoqaHanSansNeo-Medium th-fw500 th-fs14 th-fc222222 th-bdcDDDDDD bdt1 bdb1 bdcDDDDDD td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-p15 lh20'>
                <colgroup>
                    <col className='w100'></col>
                    <col className='w400'></col>
                    <col className='w170'></col>
                    <col className='w170'></col>
                </colgroup>
                <tbody>
                    <tr className='h69'>
                        <th>구분</th>
                        <th>기준 (2021. 6. 16. 이후 적용)</th>
                        <th>요건 충족시</th>
                        <th>요건 미충족시</th>
                    </tr>
                    <tr>
                        <td>다중주택</td>
                        <td className='taL'>
                            ① 독립된 주거의 형태가 아닐 것 (개별취사 아닐 것)<br/>
                            ② 주택사용 층수 (지하층제외, 1층 필로티 구조 제외) 가 3개층 이하<br/>
                            ③ 1개 동의 주택사용 바닥면적의 합계가 660㎡ 이하<br/>
                            ④ 옥탑은 수평투영면적의 8분의 1 이상인 경우 별도의 층수로 봄<br/>
                        </td>
                        <td>(단독주택)<br/>다중주택</td>
                        <td>(공동주택)<br/>다세대주택</td>
                    </tr>
                    <tr>
                        <td>종전 <br/> 다중주택 <br/>정의</td>
                        <td className='taL' colSpan={3}>
                            <span className='fwB'>- 건축법 개정으로 2021.6.15. 이전 적용<br /></span>
                            다음의 요건을 모두 갖춘 주택을 다중주택이라 한다.<br />
                            ① 학생 또는 직장인 등 여러 사람이 장기간 거주할 수 있는 구조로 되어 있을 것<br />
                            ② 독립된 주거의 형태를 갖추지 아니할 것 (각 실별로 욕실을 설치할 수 있으나, 취사시설은 갖추지 아니할 것)<br />
                            ③ 연면적이 330㎡ 이하이고 층수(지하층은 제외)가 3층 이하일 것<br />
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className='dpFlx flxdCol pt30'>
                <div className='w215 dpFlx aiC jcC bdBox'>
                    <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222'>해당 주택이 다중주택 조건에 맞습니까?</span>
                </div>
                <div className='w100p dpFlx aiC jcC pt40'>
                    <div className='w210 h56 bgcF6F6F6 dpFlx aiC jcC csP' onClick={onClickNoBtn}>
                        <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>아니오</span>
                    </div>
                    <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => { setModalOpen(false) }}>
                        <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>예</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
