import { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';

import { addrSearchResultState, acquiredAssetInfoState } from 'js/common/Atom';
import { AquiredAssetInfo, Juso } from 'js/common/types/types';
import Axios from 'js/common/Axios';
import Util from 'js/common/Util';
import AppContext from 'js/common/AppContext';

export function useFinalAddrConfirm(taxFlag: string) {
    const addrSearchResult = useRecoilValue(addrSearchResultState);
    const [acquiredAssetInfo, setAcquiredAssetInfo] = useRecoilState(acquiredAssetInfoState);

    const [inputDisabled, setInputDisabled] = useState<any>({
        spfcKndDisabled: true, // 용도지역
        mdatTrgetAreaAtDisabled: true, // 조장지역여부
        pblntfPcDisabled: true, // 공동주택가격
        housePcDisabled: true, // 개별주택갸격
        shareRatioDisabled: true, // 지분율
        apprDateNewHseDisabled: true, // 사용승인일
        prvuseArDisabled: true, // 공동주택 전용면적
        houseTotarDisabled: true, // 단독주택 전용면적
    });
    const [finalAddr, setFinalAddr] = useState<string>('');
    const [selectedAddr, setSelectedAddr] = useState<Juso | null>(null);

    useEffect(() => {
        //if (!addrSearchResult.addr || addrSearchResult.dong === '' || addrSearchResult.ho === '' || taxFlag === '')
        if (!addrSearchResult.addr || taxFlag === '') return;

        const checkFinalAddrInfo = (addr: Juso, userDong: string, userHo: string, taxFlag: string) => {
            AppContext.showSpinnerDim();
            let apiParam = '';

            if (Util.isNotEmpty(userDong) && Util.isNotEmpty(userHo)) {
                // 아파트
                apiParam = addr.pnu + ',' + addr.bdKdcd + ',' + encodeURI(userDong) + ',' + encodeURI(userHo);
            } else if (Util.isEmpty(userDong) && Util.isNotEmpty(userHo)) {
                // 한개동, 다세대
                apiParam = addr.pnu + ',1,' + encodeURI(userHo);
            } else {
                // 단독주택
                apiParam = addr.pnu + ',' + addr.bdKdcd;
            }

            Axios.dataAccess({
                url: 'common/mobile/callApi.do',
                methodType: 'post',
                paramType: 'object',
                paramData: {
                    apiName: 'openapi',
                    tailUrl: 'forest/estate',
                    paramStr: apiParam,
                },
                onSuccessFn: (res: any) => {
                    if (res && res.item && res.item.rtn_list) {
                        const result = res.item.rtn_list[0];

                        // 용도지역
                        if (Util.isEmpty(result.spfcKndCd) || result.spfcKndCd === '1000') {
                            setAcquiredAssetInfo((prev: AquiredAssetInfo) => ({ ...prev, spfcKnd: '1001' }));
                        } else {
                            setAcquiredAssetInfo((prev: AquiredAssetInfo) => ({ ...prev, spfcKnd: result.spfcKndCd }));
                        }
                        // 용도지역 입력 필드 활성화 여부
                        if (Util.isEmpty(result.mdatTrgetAreaAt)) {
                            setInputDisabled((prev: any) => ({ ...prev, spfcKndDisabled: false }));
                        }

                        // 조정대상지역
                        setAcquiredAssetInfo((prev: AquiredAssetInfo) => ({
                            ...prev,
                            mdatTrgetAreaAt: result.mdatTrgetAreaAt,
                        }));
                        // 조정대상지역 입력 필드 활성화 여부
                        if (Util.isEmpty(result.mdatTrgetAreaAt)) {
                            setInputDisabled((prev: any) => ({ ...prev, mdatTrgetAreaAtDisabled: false }));
                        }

                        // 공동주택가격
                        setAcquiredAssetInfo((prev: AquiredAssetInfo) => ({ ...prev, pblntfPc: result.pblntfPc }));
                        // 공동주택가격 입력 필드 활성화 여부
                        if (Util.isEmpty(result.pblntfPc)) {
                            setInputDisabled((prev: any) => ({ ...prev, pblntfPcDisabled: false }));
                        }

                        // 개별주택가격
                        setAcquiredAssetInfo((prev: AquiredAssetInfo) => ({ ...prev, housePc: result.housePc }));
                        // 개별주택가격 입력 필드 활성화 여부
                        if (Util.isEmpty(result.housePc)) {
                            setInputDisabled((prev: any) => ({ ...prev, housePcDisabled: false }));
                        }

                        // 사용승인일
                        setAcquiredAssetInfo((prev: AquiredAssetInfo) => ({ ...prev, useAprDay: result.useAprDay }));

                        // 공동주택 전용면적
                        setAcquiredAssetInfo((prev: AquiredAssetInfo) => ({ ...prev, prvuseAr: result.prvuseAr })); // 전용면적
                        // 공동주택 전용면적 입력 필드 활성화 여부
                        if (Util.isEmpty(result.prvuseAr)) {
                            setInputDisabled((prev: any) => ({ ...prev, prvuseArDisabled: false }));
                        }

                        // 단독주택 주택연면적
                        setAcquiredAssetInfo((prev: AquiredAssetInfo) => ({ ...prev, houseTotar: result.houseTotar })); // 주택연면적
                        // 단독주택 주택연면적 입력 필드 활성화 여부
                        if (Util.isEmpty(result.houseTotar)) {
                            setInputDisabled((prev: any) => ({ ...prev, houseTotarDisabled: false }));
                        }
                    }
                    AppContext.hideSpinner();
                },
                // skipFailedAlert: true,
                onFailedFn: (error: any) => {
                    // API 장애 발생
                    AppContext.openAlert({
                        title: '최종 주소 확인',
                        msg: '입력항목을 직접 입력해 주세요.',
                        icon: 'error',
                    });

                    setInputDisabled((prev: any) => ({
                        ...prev,
                        pblntfPcDisabled: false, // 공동주택가격
                        housePcDisabled: false, // 개별주택가격
                        mdatTrgetAreaAtDisabled: false, // 조정대상지역
                        spfcKndDisabled: false, // 용도지역
                        prvuseArDisabled: false, // 공동주택 전용면적
                        houseTotarDisabled: false, // 단독주택 주택연면적
                    }));

                    AppContext.hideSpinner();
                },
            });
        };

        checkFinalAddrInfo(addrSearchResult.addr, addrSearchResult.dong, addrSearchResult.ho, taxFlag);
        setFinalAddr(
            `${addrSearchResult.addr.roadAddr} ${
                addrSearchResult.dong ? addrSearchResult.dong.replace(/동/g, '') + '동' : ''
            } ${addrSearchResult.ho ? addrSearchResult.ho.replace(/호/g, '') + '호' : ''}`,
        );

        // 지분율 default value 100으로 set
        setAcquiredAssetInfo((prev: AquiredAssetInfo) => ({
            ...prev,
            shareRatio: '100',
        }));
    }, [addrSearchResult, taxFlag, setAcquiredAssetInfo]);

    useEffect(() => {
        setSelectedAddr(addrSearchResult.addr);
    }, [addrSearchResult]);

    useEffect(() => {}, []);

    const updateAddrEtcValue = (key: string, value: string) => {
        if (Object.keys(acquiredAssetInfo).includes(key)) {
            setAcquiredAssetInfo((prev: AquiredAssetInfo) => ({ ...prev, [key]: value }));
        }
    };

    return {
        acquiredAssetInfo,
        inputDisabled,
        finalAddr,
        selectedAddr,
        updateAddrEtcValue,
    };
}
