import React from "react";
import { ThemeProvider } from "styled-components";

type Props = {
  children: React.ReactNode;
};

const theme = {
  color: {
    pGreen: "#00B739",
    pGreenLight01: "#45C25A",
    pGreenLight02: "#6DCE78",
    pGreenLight03: "#C2E9C5",
    pGreenLight04: "#E7FFED",
    pGreenDark01: "#006500",
    pGreenDark02: "#008418",
    sNavy: "#2B2B35",
    sNavyLight01: "#82818D",
    sNavyDark01: "#3E3E47",
    sGreenLight03: "#F0FAF1",
    nGray10: "#202021",
    nGray20: "#414142",
    nGray30: "#5F6061",
    nGray40: "#737475",
    nGray50: "#9C9D9E",
    nGray60: "#BBBCBD",
    nGray70: "#DEDFE0",
    nGray80: "#EDEEEF",
    nGray95: "#F9FAFB",
    bg: "#F2F3F8",
    blueGray: "#4C5874",
    sysRed100: "#E65C5C",
    sysBlue100: "#6091FE",
    sOrangeLight01: "#FF7073",
  },
  fontSize: "62.5%",
};

export type ThemeType = typeof theme;

function MobileThemeProvider({ children }: Props) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export { theme, MobileThemeProvider };
