import Util from "js/common/Util";
import React from "react";
import { NumericFormat } from "react-number-format";

interface NumberTextBoxProps {
    value: string
    onChange?: Function
    borderClass?: string
    thousandsGroupStyle?: 'thousand' | 'lakh' | 'wan' | 'none'
    thousandSeparator?: string
    maxLength?: number
    inputClass?: string
    unitClass?: string
    unitText?: string
    disabled?: boolean
    placeholder?: string
}

const NumberTextBox = ({value, onChange, borderClass, thousandsGroupStyle, thousandSeparator, maxLength, inputClass, unitClass, unitText, disabled, placeholder}: NumberTextBoxProps) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [focusState, setFocusState] = React.useState<boolean>(false);

    return (
        <div
            className={"dpFlx aiC bdBox fBd-2B2B2B " + (focusState ? "bdc2B2B2B " : "bdcDDDDDD ") + borderClass + (disabled ? " bgcF7F7F7-i" : "")}
            onClick={() => inputRef.current?.focus()}
        >
            <NumericFormat
                getInputRef={inputRef}
                onFocus={() => setFocusState(true)}
                onBlur={() => setFocusState(false)}
                thousandsGroupStyle={thousandsGroupStyle}
                thousandSeparator={thousandSeparator}
                maxLength={maxLength}
                value={value}
                onChange={(e) => {
                    if (onChange === undefined) {
                        return;
                    }
                    onChange(e.target.value)
                }}
                className={"bdN bdBox olN w100p h100p " + inputClass}
                disabled={disabled}
                placeholder={placeholder}
            />
            {Util.isNotEmpty(unitText) ?
                <span className={`fs15 SpoqaHanSansNeo-Regular ${unitClass}`}>{unitText}</span>
            : <></>}
        </div>
    )
}
export default NumberTextBox;