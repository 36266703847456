/*******************************************************************************
*** TaxExemptCautionDialog ** 양도세 비과세 주의사항 상세정보
*******************************************************************************/
import styled from "styled-components";
import Modal  from "js/common/ui/modal/Modal";

type Props = {
  	open: boolean;
  	setOpen: any;
	cautionNo: string;
	longRentNotice: string;
};

function TaxExemptCautionDialog({ open, setOpen, cautionNo, longRentNotice }: Props) {
	return (
		<Modal modalOpen={open} setModalOpen={setOpen} title={"주의사항"} dim={true} visible={true}>
		<CautionModalContainer style={{height:(longRentNotice === "Y" ? "500px" : "auto"), width: (longRentNotice === "Y" ? "730px" : "550px")}}>
			<CautionModalTitleContainer>
			<div className="title">
				<div className="iconWrap">
					<img src={require("img/common/caution_icon.png")} alt="caution icon" />
				</div>
				<h3>비과세 주의사항 안내</h3>
			</div>
			<div className="text">
				<div>
				<p>비과세 혜택이 소급적으로 철회될 수 있습니다.</p>
				<p>주의사항을 반드시 확인해주세요!</p>
				</div>
			</div>
			<div className="logo">
				<img src={require("img/common/logo_gray_bg.png")} alt="calltax logo" />
			</div>
			</CautionModalTitleContainer>
			
			{/* 종전주택 + 승계조합원입주권 or 승계조합원입주권 A : 종전주택 양도 or 종전주택 + (분양권 or 분양권 A) */}
			{(cautionNo === "1" || cautionNo === "2") && (
				<CautionContentContainer>
				<div className="title">
					<p className="green">
						이사 및 거주기간 준수
						<div className="highlight"></div>
					</p>
				</div>
				<div className="subtitle">
					{ cautionNo === "1" ?
						<p>소득세법시행령 제156조의 2 제4항 제1호</p>
					:
						<p>소득세법시행령 제156조의 3 제3항 제1호</p>    
					}  
				</div>
				<div className="desc">
					<p><mark>주택이 완성된 후 3년 이내</mark>에 그 주택으로 <mark>세대전원이 이사</mark>하여 <mark>1년 이상 계속하여 거주</mark>할 것</p>
				</div>
				</CautionContentContainer>
			)}

			{/*************** 장기임대주택 비과세 주의사항 ***************/}
			{/* 1. 생애 1회만 장기임대주택에 대한 거주주택 비과세 */}
			{longRentNotice === "Y" && (
			<>
				<CautionContentContainer>
				<div className="title">
					<p className="green">
						1. 생애 1회만 장기임대주택에 대한 거주주택 비과세
						<div className="highlight"></div>
					</p>
				</div>
				<div className="subtitle">
					<p>1) 소득세법시행령부칙 | 대통령령 제29523호</p>
				</div>
				<div className="desc">
					<p>다음 각 호의 어느 하나에 해당하는 주택에 대하여는 개정규정 및 이 조 제1항에도 불구하고 종전의 규정에 따른다.</p>
					<p>(1) 이 영 시행 당시 거주하고 있는 주택</p>
					<p>(2) 이 영 시행 전에 거주주택을 취득하기 위해 매매계약을 체결하고 계약금을 지급한 사실이 증빙서류에 의해 확인되는 주택</p>
				</div>
				<div className="subtitle">
					<p>2) 기획재정부재산-192 | 2020.2.18.</p>
				</div>
				<div className="desc">
					<p>2019년 2월 12일 이후 취득의 경우 생애 한번만 적용한다는 의미는 2019년 2월 12일 이후 취득분만을 대상으로 한 번을 의미하는 것이 아니라 종전에 특례를 적용받은 횟수까지도 포함하여 생애 한번을 의미한다.</p>
				</div>
				<div className="subtitle">
					<p>3) 사전법령해석재산-2020-1464 | 2021.3.8.</p>
				</div>
				<div className="desc">
					<p>2019년 2월 12일 전 장기임대주택을 보유하지 않은 상태에서 새로운 주택을 취득하기 위하여 매매계약을 체결하고 계약금을 납부한 주택분양권은 거주주택 비과세 종전 규정을 적용받을 수 없다고 본다.</p>
				</div>
				<div className="subtitle">
					<p>4) 서면법규재산2022-3027 | 2023.7.31.</p>
				</div>
				<div className="desc">
					<p>분양권 상태에서 임대등록하여 2019년 2월 12일 이후 완공되어 임대개시한 장기임대주택을 보유하는 경우에는 종전 규정을 적용한다.</p>
				</div>
				</CautionContentContainer>

				<CautionContentContainer>
				<div className="title">
					<p className="green">
						2. 사후관리
						<div className="highlight"></div>
					</p>
				</div>
				<div className="desc">
					<p>1) 비과세 적용을 받은 후 임대기간 요건을 충족하지 못하게 된 경우에는 비과세받은 세액을 납부하여야 한다.</p>
					<p>2) 장기임대주택을 보유하고 있는 경우 최초 거주주택을 양도하는 경우에는 비과세를 허용하여 거주주택으로 전환한 경우에는 전체 양도차익에 대해 과세한다. 다만, 최종적으로 거주주택 전환시에는 직전 거주주택양도 후 양도차익분에 대해서 비과세한다.</p>
				</div>
				</CautionContentContainer>

				<CautionContentContainer>
				<div className="title">
					<p className="green">
						3. 장기임대주택이 조합원입주권이 된 경우 장기임대주택에 대한 거주주택 비과세 적용
						<div className="highlight"></div>
					</p>
				</div>
				<div className="desc">
					<p>1) 직권말소(재개발·재건축으로 임대등록이 말소되는 경우) 후에 거주주택을 양도하는 경우에는 자진말소 및 자동말소와 달리 비과세혜택을 준다는 규정이 없어 비과세가 적용되지 않는다.</p>
				</div>
				<div className="subtitle">
					<p>2) 법규과-1143 | 2022.04.08.</p>
				</div>
				<div className="desc">
					<p>장기임대주택이 자동말소되고 조합원입주권으로 전환된 상태에서 자동말소일 이후 5년 이내 거주주택을 양도하는 경우 거주주택 비과세 특례를 적용받을 수 있다.</p>
				</div>
				<div className="subtitle">
					<p>3) 법규과-887 | 2023.04.11.</p>
				</div>
				<div className="desc">
					<p>임대중인 상태에서 임대의무기간의 1/2 이상을 충족한 상태에서 자진말소하는 경우 멸실 또는 신축된 상태로 거주주택을 양도하는 경우 자진말소일 이후 5년 이내 거주주택을 양도하면 거주주택 비과세특례를 적용받을 수 있다. </p>
				</div>
				</CautionContentContainer>

				<CautionContentContainer>
				<div className="title">
					<p className="green">
						4. 거주주택이 조합원입주권이 된 경우 장기임대주택에 대한 거주주택 비과세 적용
						<div className="highlight"></div>
					</p>
				</div>
				<div className="subtitle">
					<p>1) 조심2019서3806 | 2019.12.17.</p>
				</div>
				<div className="desc">
					<p>거주주택 비과세 특례가 적용되지 않는다.</p>
					<p>관리처분계획인가 후에는 주택의 멸실여부와 상관없이 입주권으로 전환되었으므로 특례가 적용되지 않는다.</p>
				</div>
				</CautionContentContainer>
			</>
			)}

		</CautionModalContainer>
		</Modal>
	);
}

const CautionModalContainer = styled.section`
	width: 730px;
	height: auto;
	overflow: auto;
	overflow-x: hidden;
	padding: 24px;
	background: linear-gradient(
		90deg,
		#feedee 0%,
		#fdf5f6 58.51%,
		rgba(254, 237, 238, 0) 100%
	);
	border-radius: 20px;
`;

const CautionModalTitleContainer = styled.div`
	position: relative;
	width: 100%;
	margin-bottom: 24px;

	.title {
		display: flex;
		align-items: center;

		height: 49px;

		.iconWrap {
			display: inline-block;
			width: 49px;
			height: 49px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		h3 {
			display: inline-block;

			font-family: "Spoqa Han Sans Neo";
			font-weight: 500;
			font-size: 18px;
			line-height: 1.47;
			letter-spacing: -0.016em;

			margin: 0;
		}
	}
	.text {
		margin-left: 49px;

		p {
			font-family: "Spoqa Han Sans Neo";
			font-weight: 300;
			font-size: 14px;
			line-height: 1.32;
			letter-spacing: -0.05em;

			margin: 0;
		}
	}

	.logo {
		position: absolute;
		bottom: 0;
		right: 0;

		width: 148px;
		height: 38px;

		img {
			width: 100%;
			height: 100%;
		}
	}
`;
const CautionContentContainer = styled.div`
	box-sizing: border-box;
	width: 100%;
	padding: 10px 28px;
	margin-bottom: 10px;

	&:last-child {
		margin: 0;
	}

	background-color: white;
	border-radius: 10px;

	.title {
		display: flex;
		justify-content: space-between;

		font-family: "Spoqa Han Sans Neo";
		font-weight: 400;
		font-size: 14px;
		line-height: 1.32;
		letter-spacing: -0.05em;
		color: #414142;

		p {
			position: relative;
			margin: 0;
			z-index: 2;

			&.green {
				color: #008418;
			}

			.highlight {
				position: absolute;
				top: 0px;
				left: 0px;
				z-index: -1;

				width: 100%;
				height: 100%;
				background-color: #e7ffed;
				border-radius: 2px;
			}
		}

		margin: 0 0 14px 0;
	}

	.subtitle {
		display: flex;
		justify-content: space-between;

		font-family: "Spoqa Han Sans Neo";
		font-weight: 400;
		font-size: 14px;
		line-height: 1.32;
		letter-spacing: -0.05em;
		color: #414142;

		p {
			position: relative;
			margin: 0;
			z-index: 2;
		}

		margin: 0 0 5px 0;
	}

  .desc {
    font-family: "Spoqa Han Sans Neo";
    font-weight: 300;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: -0.05em;

		margin-bottom: 10px;
        mark {
            background-color: #e7ffed;
        }
		p {
			margin: 0;
		}
	}
`;

export default TaxExemptCautionDialog;
