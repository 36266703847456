import styled from "styled-components";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { toast } from "react-toastify";
import { calculatorPcWebMaxWidth } from "js/common/const";
import AppContext from "js/common/AppContext";

function MobileHeader() {
  const onClickShare = () => {
    // Clipboard API 사용
    const copyUrl = window.location.href;

    if (navigator.clipboard && window.isSecureContext) {
      // Clipboard API 사용
      navigator.clipboard.writeText(copyUrl).then(
        function () {
          toast("URL이 클립보드에 복사되었습니다! 👋");
        },
        function (err) {
          console.error("Could not copy text: ", err);
        }
      );
    } else {
      // 구형 브라우저 지원
      const textArea = document.createElement("textarea");
      textArea.value = copyUrl;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        const successful = document.execCommand("copy");
        const msg = successful ? "successful" : "unsuccessful";
        alert("Link copy was " + msg);
      } catch (err) {
        console.error("Unable to copy text: ", err);
      }
      document.body.removeChild(textArea);
    }
  };

  const onClickConsult = () => {
    AppContext.openAlert({
      title: "추가 세무 상담",
      msg: "Coming Soon, 조금만 기다려주세요! ",
      icon: "",
    });
  };

  return (
    <Header>
      <button className="backButton">
        <ArrowBackOutlinedIcon fontSize="small" />
      </button>
      <div className="buttonGroup">
        <button onClick={onClickShare}>
          <ShareOutlinedIcon fontSize="small" />
          <span>공유하기</span>
        </button>
        <button className="consult" onClick={onClickConsult}>
          <img
            src={require("img/common/consult_icon.png")}
            alt="consult icon"
          />
          <span>세무상담</span>
        </button>
      </div>
    </Header>
  );
}

const Header = styled.header`
  width: 100%;
  height: 50px;
  padding: 15px 20px 12px 20px;

  background: ${(props) => props.theme.color.bg};

  @media (min-width: 1201px) {
    max-width: ${calculatorPcWebMaxWidth};
  }

  display: flex;
  justify-content: space-between;
  align-items: center;

  .backButton {
    color: ${(props) => props.theme.color.nGray30};
  }

  .buttonGroup {
    display: inline-flex;
    gap: 16px;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.29;
    letter-spacing: -0.058em;
    color: ${(props) => props.theme.color.sNavyDark01};

    button {
      display: flex;
      align-items: center;
      gap: 2px;

      &.consult {
        padding: 5px 10px;
        border-radius: 17px;
        background-color: #ced4da;

        img {
          width: 20px;
        }
      }
    }
  }
`;

export default MobileHeader;
