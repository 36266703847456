/*******************************************************************************
 *** AssetsAcq ** 보유 or 취득 자산 정보 입력 > 취득정보
*******************************************************************************/
import { IWizard } from "use-wizard/lib/cjs/useWizard/types/IWizard";
import { TStep } from "use-wizard/lib/cjs/useWizard/types/TStep";
import DatePickerBox from "js/common/ui/input/DatePickerBox";
import React from "react";
import Util from "js/common/Util";
import Modal from "js/common/ui/modal/Modal";
import MgshrPlanDe from "../help/MgshrPlanDe";
import AppContext from "js/common/AppContext";
import Axios from "js/common/Axios";
import ToolTip from "js/common/ui/ToolTip";
import ImageCheckBox from "js/common/ui/input/ImageCheckBox";
import ImageRadioBox from "js/common/ui/input/ImageRadioBox";
import NumberTextBox from "js/common/ui/input/NumberTextBox";
import AssetsTitle from "./ui/AssetsTitle";
import AssetsButton from "./ui/AssetsButton";
import AssetsUtil from "js/common/AssetsUtil";
import ManualCheckGuide from "../help/ManualCheckGuide";
import AssetsEditTab from "./ui/AssetsEditTab";

const AssetsAcq = (props: {
    step: TStep;
    wizard: IWizard;
    form: any;
    dispatchForm: Function;
}) => {
    // 부동산 상세정보
    const [estateSttus, setEstateSttus] = React.useState<string>("");
    const [pnu, setPnu] = React.useState<string>("");
    const [dongNm, setDongNm] = React.useState<string>("");
    const [hoNm, setHoNm] = React.useState<string>("");
    const [estateDetailSe, setEstateDetailSe] = React.useState<string>("");
    const [estateFom, setEstateFom] = React.useState<string>("");
    const [acqsSrc, setAcqsSrc] = React.useState<string>("");
    const [acqsCause, setAcqsCause] = React.useState<string>("");

    // 공통 파라미터
    const [cntrctDe, setCntrctDe] = React.useState<string>(); // 계약일, 종전주택계약일(상속)
    const [entyPymntde, setEntyPymntde] = React.useState<string>(); // 계약금지급일
    const [entyPymntdeDsb, setEntyPymntdeDsb] = React.useState<boolean>(true);
    const [surlusPymntde, setSurlusPymntde] = React.useState<string>(); // 잔금지급일
    const [fullPymntde, setFullPymntde] = React.useState<string>(); // 분양대금완납일
    const [ownshipTransrRceptDe, setOwnshipTransrRceptDe] = React.useState<string>(); // 소유권이전등기접수일
    const [ownshipTransrRceptDeDsb, setOwnshipTransrRceptDeDsb] = React.useState<boolean>(true);
    const [useConfmDe, setUseConfmDe] = React.useState<string>(""); // 사용승인일
    const [stdrMktc, setStdrMktc] = React.useState<string>(""); // 기준시가
    const [rlDlpc, setRlDlpc] = React.useState<string>(""); // 실거래가
    const [beforeEstatePrpos, setBeforeEstatePrpos] = React.useState<string>(""); // 피상속인의 주택용도
    const [manageDspsPlanDe, setManageDspsPlanDe] = React.useState<string>(); // 관리처분계획인가일

    // 상속
    const [excptInhrtncAt, setExcptInhrtncAt] = React.useState<string>(""); // 특례상속 여부
    const [inhrtncKnd1, setInhrtncKnd1] = React.useState<string>(""); // 동일세대원 여부
    const [inhrtncKnd2, setInhrtncKnd2] = React.useState<string>(""); // 상속의 종류
    const [inhrtncKnd3TrVsb, setInhrtncKnd3TrVsb] = React.useState<boolean>(false); // 지분권자의 종류 뷰히든 핸들링 변수
    const [inhrtncKnd3, setInhrtncKnd3] = React.useState<string>(""); // 지분권자의 종류
    const [beginDe, setBeginDe] = React.useState<string>(); // 상속개시(원인)일
    const [beforeEstateTy, setBeforeEstateTy] = React.useState<string>(""); // 종전부동산 유형

    // 증여
    const [donaKnd, setDonaKnd] = React.useState<string>(""); // 증여의 종류
    const [donaKnd2, setDonaKnd2] = React.useState<string>(""); // 증여자와의 관계
    const [donaCntrctDe, setDonaCntrctDe] = React.useState<string>(); // 증여계약일
    const [rgistRceptDeDsb, setRgistRceptDeDsb] = React.useState<boolean>(true); // 증여등기접수일이 다른경우
    const [rgistRceptDe, setRgistRceptDe] = React.useState<string>(); // 증여등기접수일
    const [ownshipTransrDe, setOwnshipTransrDe] = React.useState<string>(); // 소유권이전 등기접수일
    const [ownshipTransrDeDsb, setOwnshipTransrDeDsb] = React.useState<boolean>(true);
    const [rightSuccsDeDsb, setRightSuccsDeDsb] = React.useState<boolean>(true); // 권리승계일이 다른 경우
    const [rightSuccsDe, setRightSuccsDe] = React.useState<string>(); // 권리승계일
    const [manageDspsCnfmDe, setManageDspsCnfmDe] = React.useState<string>(); // 관리처분계획인가일

    // 원조합원
    const [evlamt, setEvlamt] = React.useState<string>(""); // 평가액
    const [przwinDe, setPrzwinDe] = React.useState<string>(); // 분양권당첨일
    const [bsnsImpCnfmDe, setBsnsImpCnfmDe] = React.useState<string>(); // 사업시행인가일

    const [manageDspsPlanDeModalOpen, setManageDspsPlanDeModalOpen] = React.useState<boolean>(false); // 도움말 (관리처분계획인가일)

    // 기준시가 수동조회 화면 안내
    const [manualCheckGuideModalOpen, setManualCheckGuideModalOpen] = React.useState<boolean>(false);

    const goPrevStep = () => {
        props.wizard.goToStep(1001);
    }

    const goNextStep = (callMode: string) => {
        const param = {
            assetsSn: props.form.assetsSn,
            trdeInfo: {},
            inhrtncInfo: {},
            donaInfo: {},
            lttotRightInfo: {},
            redevpRebidngInfo: {},
            selfNwccInfo: {}
        };

        let titleSubText = "";
        let titleSubText2 = "";
        if (estateDetailSe === "1001") {
            if (acqsSrc === "1001") { // 기존주택취득
                if (acqsCause !== "1002") {
                    titleSubText = "주택 ";
                    titleSubText2 = "주택";
                }
            } else if (["1002", "1006"].indexOf(acqsSrc) !== -1) { // 분양권에 의한 취득, 일반분양오피스텔취득
                titleSubText = "분양권 ";
                titleSubText2 = "분양";
            } else if (acqsSrc === "1003") { // 조합원입주권에 의한 취득
                titleSubText = "조합원입주권 ";
                titleSubText2 = "조합원입주권";
            }
        } else if (estateDetailSe === "1002") {
            titleSubText = "분양권 ";
            titleSubText2 = "분양권 ";
        } else if (estateDetailSe === "1003") {
            titleSubText = "조합원입주권 ";
            titleSubText2 = "조합원입주권 ";
        }

        const chkValidTrdeInfo = () => {
            const trdeInfoParam: any = {};
            if (Util.isEmpty(cntrctDe)) {
                return { alertMsg: titleSubText2 + "계약일을 입력하세요." }
            }
            trdeInfoParam.cntrctDe = cntrctDe;
            if (!entyPymntdeDsb) {
                if (Util.isEmpty(entyPymntde)) {
                    return { alertMsg: titleSubText + "계약금지급일을 입력하세요." }
                }
                trdeInfoParam.entyPymntde = entyPymntde;
            }
            if (Util.isEmpty(surlusPymntde)) {
                return { alertMsg: titleSubText + "잔금지급일을 입력하세요." }
            }
            trdeInfoParam.surlusPymntde = surlusPymntde;
            if (!ownshipTransrRceptDeDsb) {
                if (Util.isEmpty(ownshipTransrRceptDe)) {
                    return { alertMsg: "소유권이전등기접수일을 입력하세요." }
                }
                trdeInfoParam.ownshipTransrRceptDe = ownshipTransrRceptDe;
            }
            if (["1002", "1006"].indexOf(acqsSrc) !== -1) { // 분양권에 의한 취득, 일반분양오피스텔취득
                if (Util.isEmpty(fullPymntde)) {
                    return { alertMsg: "분양대금완납일을 입력하세요." }
                }
                trdeInfoParam.fullPymntde = fullPymntde;
            }
            if (estateDetailSe === "1001") {
                if (Util.isEmpty(useConfmDe)) {
                    return { alertMsg: "사용승인일을 입력하세요." }
                }
                trdeInfoParam.useConfmDe = useConfmDe;
                if (Util.isEmpty(stdrMktc) || Number(stdrMktc) === 0) {
                    return { alertMsg: "취득당시 기준시가(원)을 입력하세요." }
                }
                trdeInfoParam.stdrMktc = Number(Util.uncomma(stdrMktc));
            }
            if (Util.isEmpty(rlDlpc) || Number(rlDlpc) === 0) {
                return { alertMsg: getRlDlpcFldNm() + "을(를) 입력하세요." }
            }
            trdeInfoParam.rlDlpc = Number(Util.uncomma(rlDlpc));
            return trdeInfoParam;
        }

        const chkValidInhrtncInfo = () => {
            const inhrtncInfoParam: any = {};
            if (Util.isEmpty(excptInhrtncAt)) {
                return { alertMsg: "특례상속 " + titleSubText + "여부를 선택하세요." }
            }
            inhrtncInfoParam.excptInhrtncAt = excptInhrtncAt;
            if (Util.isEmpty(inhrtncKnd1)) {
                return { alertMsg: "동일세대원여부를 선택하세요." }
            }
            inhrtncInfoParam.inhrtncKnd1 = inhrtncKnd1;
            if (Util.isEmpty(inhrtncKnd2)) {
                return { alertMsg: "상속의종류를 선택하세요." }
            }
            inhrtncInfoParam.inhrtncKnd2 = inhrtncKnd2;
            if (inhrtncKnd2 === "1002") {
                if (Util.isEmpty(inhrtncKnd3)) {
                    return { alertMsg: "지분권자의 종류를 선택하세요." }
                }
                inhrtncInfoParam.inhrtncKnd3 = inhrtncKnd3;
            }
            if (Util.isEmpty(beginDe)) {
                return { alertMsg: "상속개시일을 입력하세요." }
            }
            inhrtncInfoParam.beginDe = beginDe;
            if (Util.isEmpty(cntrctDe)) {
                return { alertMsg: "피상속인의 " + titleSubText + " 계약일을 입력하세요." }
            }
            inhrtncInfoParam.cntrctDe = cntrctDe;
            if (!entyPymntdeDsb) {
                if (Util.isEmpty(entyPymntde)) {
                    return { alertMsg: "피상속인의 " + titleSubText + " 계약금지급일을 입력하세요." }
                }
                inhrtncInfoParam.entyPymntde = entyPymntde;
            }
            if (Util.isEmpty(surlusPymntde)) {
                return { alertMsg: "피상속인의 " + titleSubText + " 잔금지급일을 입력하세요." }
            }
            inhrtncInfoParam.surlusPymntde = surlusPymntde;
            if (!ownshipTransrDeDsb) {
                if (Util.isEmpty(ownshipTransrDe)) {
                    return { alertMsg: "소유권이전등기접수일을 입력하세요." }
                }
                inhrtncInfoParam.ownshipTransrDe = ownshipTransrDe;
            }
            if (["1002", "1006"].indexOf(acqsSrc) !== -1) { // 분양권에 의한 취득, 일반분양오피스텔취득
                if (Util.isEmpty(fullPymntde)) {
                    return { alertMsg: "분양대금완납일을 입력하세요." }
                }
                inhrtncInfoParam.fullPymntde = fullPymntde;
            }
            if (estateDetailSe === "1001") {
                if (acqsSrc === "1003") {
                    if (Util.isEmpty(manageDspsPlanDe)) {
                        return { alertMsg: "관리처분계획인가일을 입력하세요." }
                    }
                    inhrtncInfoParam.manageDspsPlanDe = manageDspsPlanDe;
                    if (Util.isEmpty(bsnsImpCnfmDe)) {
                        return { alertMsg: "사업시행인가일을 입력하세요." }
                    }
                    inhrtncInfoParam.bsnsImpCnfmDe = bsnsImpCnfmDe;
                }
                if (Util.isEmpty(useConfmDe)) {
                    return { alertMsg: "사용승인일을 입력하세요." }
                }
                inhrtncInfoParam.useConfmDe = useConfmDe;
                if (Util.isEmpty(stdrMktc) || Number(stdrMktc) === 0) {
                    return { alertMsg: "취득당시 기준시가를 입력하세요." }
                }
                inhrtncInfoParam.stdrMktc = Number(Util.uncomma(stdrMktc));
            } else if (estateDetailSe === "1003") {
                if (Util.isEmpty(manageDspsPlanDe)) {
                    return { alertMsg: "관리처분계획인가일을 입력하세요." }
                }
                inhrtncInfoParam.manageDspsPlanDe = manageDspsPlanDe;
                if (Util.isEmpty(bsnsImpCnfmDe)) {
                    return { alertMsg: "사업시행인가일을 입력하세요." }
                }
                inhrtncInfoParam.bsnsImpCnfmDe = bsnsImpCnfmDe;
            }
            if (Util.isEmpty(rlDlpc) || Number(rlDlpc) === 0) {
                return { alertMsg: getRlDlpcFldNm() + "을(를) 입력하세요." }
            }
            inhrtncInfoParam.rlDlpc = Number(Util.uncomma(rlDlpc));
            return inhrtncInfoParam;
        }

        const chkValidDonaInfo = () => {
            const donaInfoParam: any = {};
            if ((estateDetailSe === "1001" && acqsSrc === "1003") || estateDetailSe === "1003") {
                if (Util.isEmpty(bsnsImpCnfmDe)) {
                    return { alertMsg: "사업시행인가일을 입력하세요." }
                }
                donaInfoParam.bsnsImpCnfmDe = bsnsImpCnfmDe;
                if (Util.isEmpty(manageDspsCnfmDe)) {
                    return { alertMsg: "관리처분계획인가일을 입력하세요." }
                }
                donaInfoParam.manageDspsCnfmDe = manageDspsCnfmDe;
            }
            if ((estateDetailSe === "1001" && acqsSrc !== "1003") || estateDetailSe === "1002" || estateDetailSe === "1003") {
                if (Util.isEmpty(donaKnd)) {
                    return { alertMsg: "증여의종류를 선택하세요." }
                }
                donaInfoParam.donaKnd = donaKnd;
                if (Util.isEmpty(donaKnd2)) {
                    return { alertMsg: "증여자와의 관계를 선택하세요." }
                }
                donaInfoParam.donaKnd2 = donaKnd2;
            }
            if (Util.isEmpty(donaCntrctDe)) {
                return { alertMsg: "증여계약일을 입력하세요." }
            }
            donaInfoParam.donaCntrctDe = donaCntrctDe;
            if (!rightSuccsDeDsb) {
                if (Util.isEmpty(rightSuccsDe)) {
                    return { alertMsg: "권리승계일을 입력하세요." }
                }
                donaInfoParam.rightSuccsDe = rightSuccsDe;
            }
            if (!rgistRceptDeDsb) {
                if (Util.isEmpty(rgistRceptDe)) {
                    return { alertMsg: "증여등기접수일을 입력하세요." }
                }
                donaInfoParam.rgistRceptDe = rgistRceptDe;
            }
            if (Util.isEmpty(cntrctDe)) {
                return { alertMsg: "증여자의 " + titleSubText + " 계약일을 입력하세요." }
            }
            donaInfoParam.cntrctDe = cntrctDe;
            if (!entyPymntdeDsb) {
                if (Util.isEmpty(entyPymntde)) {
                    return { alertMsg: "증여자의 " + titleSubText + " 계약금지급일을 입력하세요." }
                }
                donaInfoParam.entyPymntde = entyPymntde;
            }
            if (Util.isEmpty(surlusPymntde)) {
                return { alertMsg: "증여자의 " + titleSubText + " 잔금지급일을 입력하세요." }
            }
            donaInfoParam.surlusPymntde = surlusPymntde;
            if (!ownshipTransrDeDsb) {
                if (Util.isEmpty(ownshipTransrDe)) {
                    return { alertMsg: "소유권이전등기접수일을 입력하세요." }
                }
                donaInfoParam.ownshipTransrDe = ownshipTransrDe;
            }
            if (estateDetailSe === "1001") {
                if (["1002", "1006"].indexOf(acqsSrc) !== -1) { // 분양권에 의한 취득, 일반분양오피스텔취득
                    if (Util.isEmpty(fullPymntde)) {
                        return { alertMsg: "분양대금완납일을 입력하세요." }
                    }
                    donaInfoParam.fullPymntde = fullPymntde;
                }
                if (Util.isEmpty(useConfmDe)) {
                    return { alertMsg: "사용승인일을 입력하세요." }
                }
                donaInfoParam.useConfmDe = useConfmDe;
                if (Util.isEmpty(stdrMktc) || Number(stdrMktc) === 0) {
                    return { alertMsg: "취득당시 기준시가를 입력하세요." }
                }
                donaInfoParam.stdrMktc = Number(Util.uncomma(stdrMktc));
            }
            if (Util.isEmpty(rlDlpc) || Number(rlDlpc) === 0) {
                return { alertMsg: getRlDlpcFldNm() + "을(를) 입력하세요." }
            }
            donaInfoParam.rlDlpc = Number(Util.uncomma(rlDlpc));
            return donaInfoParam;
        }

        const chkValidLttotRightInfo = () => {
            const lttotRightInfoParam: any = {};
            if (Util.isEmpty(cntrctDe)) {
                return { alertMsg: titleSubText2 + " 계약일을 입력하세요." }
            }
            lttotRightInfoParam.cntrctDe = cntrctDe;
            if (estateDetailSe === "1002") { // 분양권인 경우
                lttotRightInfoParam.przwinDe = przwinDe;
            }
            if (!entyPymntdeDsb) {
                if (Util.isEmpty(entyPymntde)) {
                    return { alertMsg: titleSubText + " 계약금지급일을 입력하세요." }
                }
                lttotRightInfoParam.entyPymntde = entyPymntde;
            }
            if (["1002", "1006"].indexOf(acqsSrc) !== -1) { // 분양권에 의한 취득, 일반분양오피스텔취득
                if (Util.isEmpty(fullPymntde)) {
                    return { alertMsg: "분양대금완납일을 입력하세요." }
                }
                lttotRightInfoParam.fullPymntde = fullPymntde;
            }
            if (!ownshipTransrRceptDeDsb) {
                if (Util.isEmpty(ownshipTransrRceptDe)) {
                    return { alertMsg: "소유권이전등기접수일을 입력하세요." }
                }
                lttotRightInfoParam.ownshipTransrRceptDe = ownshipTransrRceptDe;
            }
            if (estateDetailSe === "1001") {
                if (Util.isEmpty(useConfmDe)) {
                    return { alertMsg: "사용승인일을 입력하세요." }
                }
                lttotRightInfoParam.useConfmDe = useConfmDe;
                if (Util.isEmpty(stdrMktc) || Number(stdrMktc) === 0) {
                    return { alertMsg: "취득당시 기준시가를 입력하세요." }
                }
                lttotRightInfoParam.stdrMktc = Number(Util.uncomma(stdrMktc));
            }
            if (Util.isEmpty(rlDlpc) || Number(rlDlpc) === 0) {
                return { alertMsg: getRlDlpcFldNm() + "을(를) 입력하세요." }
            }
            lttotRightInfoParam.rlDlpc = Number(Util.uncomma(rlDlpc));
            return lttotRightInfoParam;
        }

        const chkValidRedevpRebidngInfo = () => {
            const redevpRebidngInfoParam: any = {};
            if (Util.isEmpty(beforeEstateTy)) {
                return { alertMsg: "종전부동산의 유형을 선택하세요." }
            }
            redevpRebidngInfoParam.beforeEstateTy = beforeEstateTy;
            if (Util.isEmpty(beforeEstatePrpos)) {
                return { alertMsg: "종전주택 용도를 선택하세요." }
            }
            redevpRebidngInfoParam.beforeEstatePrpos = beforeEstatePrpos;
            if (Util.isEmpty(cntrctDe)) {
                return { alertMsg: "종전주택 계약일을 입력하세요." }
            }
            redevpRebidngInfoParam.cntrctDe = cntrctDe;
            if (!entyPymntdeDsb) {
                if (Util.isEmpty(entyPymntde)) {
                    return { alertMsg: "종전주택 계약금지급일을 입력하세요." }
                }
                redevpRebidngInfoParam.entyPymntde = entyPymntde;
            }
            if (Util.isEmpty(surlusPymntde)) {
                return { alertMsg: "종전주택 잔금지급일을 입력하세요." }
            }
            redevpRebidngInfoParam.surlusPymntde = surlusPymntde;
            if (!rgistRceptDeDsb) {
                if (Util.isEmpty(rgistRceptDe)) {
                    return { alertMsg: "종전주택 등기접수일을 입력하세요." }
                }
                redevpRebidngInfoParam.rgistRceptDe = rgistRceptDe;
            }
            if (Util.isEmpty(manageDspsPlanDe)) {
                return { alertMsg: "관리처분계획인가일을 입력하세요." }
            }
            redevpRebidngInfoParam.manageDspsPlanDe = manageDspsPlanDe;
            if (Util.isEmpty(bsnsImpCnfmDe)) {
                return { alertMsg: "사업시행인가일을 입력하세요." }
            }
            redevpRebidngInfoParam.bsnsImpCnfmDe = bsnsImpCnfmDe;
            if (estateDetailSe === "1001") {
                if (Util.isEmpty(useConfmDe)) {
                    return { alertMsg: "사용승인일을 입력하세요." }
                }
                redevpRebidngInfoParam.useConfmDe = useConfmDe;
            }
            if (Util.isEmpty(evlamt) || Number(evlamt) === 0) {
                return { alertMsg: "종전부동산 평가액을 입력하세요." }
            }
            redevpRebidngInfoParam.evlamt = Number(Util.uncomma(evlamt));
            if (estateDetailSe === "1001") {
                if (Util.isEmpty(stdrMktc) || Number(stdrMktc) === 0) {
                    return { alertMsg: "취득당시 기준시가를 입력하세요." }
                }
                redevpRebidngInfoParam.stdrMktc = Number(Util.uncomma(stdrMktc));
            }
            if (Util.isEmpty(rlDlpc) || Number(rlDlpc) === 0) {
                return { alertMsg: getRlDlpcFldNm() + "을(를) 입력하세요." }
            }
            redevpRebidngInfoParam.rlDlpc = Number(Util.uncomma(rlDlpc));
            return redevpRebidngInfoParam;
        }

        if (acqsCause === "1001") {
            if (estateDetailSe === "1002") { // 최초분양
                const retInfo = chkValidLttotRightInfo();
                if (Util.isNotEmpty(retInfo.alertMsg)) {
                    AppContext.openAlert({
                        title: "필수입력 항목",
                        msg: retInfo.alertMsg,
                        icon: "check"
                    });
                    return;
                }
                param.lttotRightInfo = retInfo;
            } else { // 원조합원
                const retInfo = chkValidRedevpRebidngInfo();
                if (Util.isNotEmpty(retInfo.alertMsg)) {
                    AppContext.openAlert({
                        title: "필수입력 항목",
                        msg: retInfo.alertMsg,
                        icon: "check"
                    });
                    return;
                }
                param.redevpRebidngInfo = retInfo;
            }
        } else if (acqsCause === "1002") {
            const retInfo = chkValidTrdeInfo();
            if (Util.isNotEmpty(retInfo.alertMsg)) {
                AppContext.openAlert({
                    title: "필수입력 항목",
                    msg: retInfo.alertMsg,
                    icon: "check"
                });
                return;
            }
            param.trdeInfo = retInfo;
        } else if (acqsCause === "1003") {
            const retInfo = chkValidInhrtncInfo();
            if (Util.isNotEmpty(retInfo.alertMsg)) {
                AppContext.openAlert({
                    title: "필수입력 항목",
                    msg: retInfo.alertMsg,
                    icon: "check"
                });
                return;
            }
            param.inhrtncInfo = retInfo;
        } else if (acqsCause === "1004") {
            const retInfo = chkValidDonaInfo();
            if (Util.isNotEmpty(retInfo.alertMsg)) {
                AppContext.openAlert({
                    title: "필수입력 항목",
                    msg: retInfo.alertMsg,
                    icon: "check"
                });
                return;
            }
            param.donaInfo = retInfo;
        } else if (acqsCause === "1011" || acqsCause === "1012") {
            const retInfo = chkValidLttotRightInfo();
            if (Util.isNotEmpty(retInfo.alertMsg)) {
                AppContext.openAlert({
                    title: "필수입력 항목",
                    msg: retInfo.alertMsg,
                    icon: "check"
                });
                return;
            }
            param.lttotRightInfo = retInfo;
        }
        if (acqsSrc === "1004") {
            if (Util.isEmpty(useConfmDe)) {
                AppContext.openAlert({
                    title: "필수입력 항목",
                    msg: "사용승인일을 입력하세요.",
                    icon: "check"
                });
                return;
            }
            if (Util.isEmpty(stdrMktc) || Number(stdrMktc) === 0) {
                AppContext.openAlert({
                    title: "필수입력 항목",
                    msg: "취득당시 기준시가를 입력하세요.",
                    icon: "check"
                });
                return;
            }
            if (Util.isEmpty(rlDlpc) || Number(rlDlpc) === 0) {
                AppContext.openAlert({
                    title: "필수입력 항목",
                    msg: getRlDlpcFldNm() + "을(를) 입력하세요.",
                    icon: "check"
                });
                return;
            }
            param.selfNwccInfo = {
                useConfmDe: useConfmDe,
                stdrMktc: Number(Util.uncomma(stdrMktc)),
                rlDlpc: Number(Util.uncomma(rlDlpc))
            }
        }
        const retFunc = () => {
            props.dispatchForm({
                type: "UPDATE_KEY_VALUES",
                value: {
                    stepCode: "1002"
                }
            });
            if (callMode !== "next") {
                AppContext.hideSpinner();
                return;
            }
            if (estateSttus === "1002") {
                if (estateDetailSe === "1001") { // 자산구분이 주택이면 거주및임대 페이지로
                    props.wizard.nextStep();
                } else if (estateDetailSe === "1002") { // 자산구분이 분양권이면
                    if (["1003"].indexOf(acqsCause) !== -1) { // 상속
                        props.wizard.goToStep(1006); // 주택분류 페이지로
                    } else {
                        props.wizard.goToStep(1009); // 전체 미리보기
                    }
                } else if (estateDetailSe === "1003") { // 자산구분이 조합원입주권이면
                    if (acqsCause === "1001") { // 원조합원
                        props.wizard.nextStep(); // 다음페이지
                    } else if (acqsCause === "1003") { // 상속
                        props.wizard.goToStep(1006); // 주택분류 페이지로
                    } else {
                        if (estateSttus === "1002") {
                            props.wizard.goToStep(1009); // 전체 미리보기
                        } else if (estateSttus === "1003") {
                            props.wizard.goToStep(1006); // 세액계산 질문 페이지로
                        }
                    }
                }
            } else if (estateSttus === "1003") {
                if (estateDetailSe === "1001") { // 자산구분이 주택이면 거주및임대 페이지로
                    props.wizard.nextStep();
                } else if (estateDetailSe === "1002" || estateDetailSe === "1003") { // 자산구분이 분양권이면
                    props.wizard.goToStep(1006); // 세액계산 질문 페이지로
                } else {
                    props.wizard.goToStep(1009); // 전체 미리보기
                }
            }
        }
        const saveAssetsAcqInfo = () => {
            AppContext.showSpinner();
            Axios.dataAccess({
                url: "system/assets/saveAssetsAcqInfo.do",
                methodType: "post",
                paramType: "json",
                paramData: param,
                onSuccessFn: () => {
                    retFunc();
                }
            });
        }
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/assets/chkAssetsAcqInfo.do",
            methodType: "post",
            paramType: "json",
            paramData: param,
            onSuccessFn: (res: any) => {
                if (res.item === 0) { // 항목 하나라도 수정한 경우
                    AppContext.hideSpinner();
                    AppContext.openAlert({
                        title: "취득정보 저장",
                        msg: "수정된 내역이 존재합니다. 저장하시겠습니까?",
                        icon: "check",
                        confirmText: "저장",
                        handleConfirm: () => {
                            saveAssetsAcqInfo();
                        }
                    });
                } else if (res.item === 1) { // 수정내역 없으면 바로 다음 스텝으로
                    retFunc();
                } else if (res.item === 2) { // 최초등록
                    saveAssetsAcqInfo();
                }
            }
        });
    }

    React.useEffect(() => {
        props.form.setCurrStepCode(1002);
    }, [props.form]);

    React.useEffect(() => {
        // 저장한 기준시가 정보 없으면 주소검색으로 얻은 공동주택가격/개별주택가격 정보 바인딩
        const bindStdrMktc = (stdrMktc: string) => {
            if (Util.isNotEmpty(props.form.stdrMktc)) {
                return props.form.stdrMktc;
            } else {
                return stdrMktc;
            }
        }

        // 주소검색하여 연계한 정보 없으면 기존 저장값 바인딩
        const bindUseConfmDe = (useConfmDe: string) => {
            if (Util.isNotEmpty(props.form.useConfmDe)) {
                return props.form.useConfmDe;
            } else {
                return useConfmDe;
            }
        }

        // 매매테이블 바인딩
        const trdeParamSet = (trdeInfo: any) => {
            setCntrctDe(trdeInfo.cntrctDe);
            setEntyPymntde(trdeInfo.entyPymntde);
            setSurlusPymntde(trdeInfo.surlusPymntde);
            setFullPymntde(trdeInfo.fullPymntde);
            setOwnshipTransrRceptDe(trdeInfo.ownshipTransrRceptDe);
            setStdrMktc(trdeInfo.stdrMktc);
            setRlDlpc(trdeInfo.rlDlpc);
            setUseConfmDe(bindUseConfmDe(trdeInfo.useConfmDe));

            setEntyPymntdeDsb(Util.isEmpty(trdeInfo.entyPymntde));
            setOwnshipTransrRceptDeDsb(Util.isEmpty(trdeInfo.ownshipTransrRceptDe));
        }

        // 상속테이블 바인딩
        const inhrtncParamSet = (inhrtncInfo: any) => {
            setExcptInhrtncAt(inhrtncInfo.excptInhrtncAt);
            setInhrtncKnd1(inhrtncInfo.inhrtncKnd1);
            setInhrtncKnd2(inhrtncInfo.inhrtncKnd2);
            setInhrtncKnd3(inhrtncInfo.inhrtncKnd3);
            setCntrctDe(inhrtncInfo.cntrctDe);
            setEntyPymntde(inhrtncInfo.entyPymntde);
            setSurlusPymntde(inhrtncInfo.surlusPymntde);
            setFullPymntde(inhrtncInfo.fullPymntde);
            setOwnshipTransrDe(inhrtncInfo.ownshipTransrDe);
            setBeginDe(inhrtncInfo.beginDe);
            setStdrMktc(bindStdrMktc(inhrtncInfo.stdrMktc));
            setRlDlpc(inhrtncInfo.rlDlpc);
            setManageDspsPlanDe(inhrtncInfo.manageDspsPlanDe);
            setUseConfmDe(bindUseConfmDe(inhrtncInfo.useConfmDe));
            setBsnsImpCnfmDe(inhrtncInfo.bsnsImpCnfmDe);

            if (inhrtncInfo.inhrtncKnd2 === "1002") {
                setInhrtncKnd3TrVsb(true);
                setInhrtncKnd3(inhrtncInfo.inhrtncKnd3); // 지분권자의 종류
            } else {
                setInhrtncKnd3TrVsb(false);
            }
            setEntyPymntdeDsb(Util.isEmpty(inhrtncInfo.entyPymntde));
            setOwnshipTransrDeDsb(Util.isEmpty(inhrtncInfo.ownshipTransrDe));
        };

        // 증여테이블 바인딩
        const donaParamSet = (donaInfo: any) => {
            setDonaKnd(donaInfo.donaKnd);
            setDonaKnd2(donaInfo.donaKnd2);
            setCntrctDe(donaInfo.cntrctDe);
            setEntyPymntde(donaInfo.entyPymntde);
            setSurlusPymntde(donaInfo.surlusPymntde);
            setFullPymntde(donaInfo.fullPymntde);
            setOwnshipTransrDe(donaInfo.ownshipTransrDe);
            setRgistRceptDe(donaInfo.rgistRceptDe);
            setRightSuccsDe(donaInfo.rightSuccsDe);
            setDonaCntrctDe(donaInfo.donaCntrctDe);
            setManageDspsCnfmDe(donaInfo.manageDspsCnfmDe);
            setStdrMktc(bindStdrMktc(donaInfo.stdrMktc));
            setRlDlpc(donaInfo.rlDlpc);
            setUseConfmDe(bindUseConfmDe(donaInfo.useConfmDe));
            setBsnsImpCnfmDe(donaInfo.bsnsImpCnfmDe);

            setRgistRceptDeDsb(Util.isEmpty(donaInfo.rgistRceptDe));
            setRightSuccsDeDsb(Util.isEmpty(donaInfo.rightSuccsDe));
            setEntyPymntdeDsb(Util.isEmpty(donaInfo.entyPymntde));
            setOwnshipTransrDeDsb(Util.isEmpty(donaInfo.ownshipTransrDe));
        }

        // 분양권 테이블 바인딩
        const lttotRightParamSet = (lttotRightInfo: any) => {
            setPrzwinDe(lttotRightInfo.przwinDe);
            setCntrctDe(lttotRightInfo.cntrctDe);
            setEntyPymntde(lttotRightInfo.entyPymntde);
            setSurlusPymntde(lttotRightInfo.surlusPymntde);
            setFullPymntde(lttotRightInfo.fullPymntde);
            setOwnshipTransrRceptDe(lttotRightInfo.ownshipTransrRceptDe);
            setStdrMktc(lttotRightInfo.stdrMktc);
            setRlDlpc(lttotRightInfo.rlDlpc);
            setUseConfmDe(bindUseConfmDe(lttotRightInfo.useConfmDe));

            setEntyPymntdeDsb(Util.isEmpty(lttotRightInfo.entyPymntde));
            setOwnshipTransrRceptDeDsb(Util.isEmpty(lttotRightInfo.ownshipTransrRceptDe));
        }

        // 재개발재건축 테이블 바인딩
        const redevpRebidngParamSet = (redevpRebidngInfo: any) => {
            setBeforeEstateTy(redevpRebidngInfo.beforeEstateTy);
            setBeforeEstatePrpos(redevpRebidngInfo.beforeEstatePrpos);
            setManageDspsPlanDe(redevpRebidngInfo.manageDspsPlanDe);
            setCntrctDe(redevpRebidngInfo.cntrctDe);
            setEntyPymntde(redevpRebidngInfo.entyPymntde);
            setSurlusPymntde(redevpRebidngInfo.surlusPymntde);
            setRgistRceptDe(redevpRebidngInfo.rgistRceptDe);
            setStdrMktc(redevpRebidngInfo.stdrMktc);
            setRlDlpc(redevpRebidngInfo.rlDlpc);
            setEvlamt(redevpRebidngInfo.evlamt);
            setUseConfmDe(bindUseConfmDe(redevpRebidngInfo.useConfmDe));
            setBsnsImpCnfmDe(redevpRebidngInfo.bsnsImpCnfmDe);

            setEntyPymntdeDsb(Util.isEmpty(redevpRebidngInfo.entyPymntde));
            setRgistRceptDeDsb(Util.isEmpty(redevpRebidngInfo.rgistRceptDe));
        }

        // Data Mapping
        const getFormSeq = (estateDetail: any, trdeInfo: any, inhrtncInfo: any, donaInfo: any, lttotRightInfo: any, redevpRebidngInfo: any, selfNwccInfo: any) => {
            if (estateDetail.estateDetailSe === "1001") { // 주택
                if (estateDetail.acqsSrc === "1001" || estateDetail.acqsSrc === "1005") { // 기존 아파트, 오피스텔취득
                    if (estateDetail.acqsCause === "1002") {
                        trdeParamSet(trdeInfo);
                    } else if (estateDetail.acqsCause === "1003") {
                        inhrtncParamSet(inhrtncInfo);
                    } else if (estateDetail.acqsCause === "1004") {
                        donaParamSet(donaInfo);
                    }
                } else if (estateDetail.acqsSrc === "1002" || estateDetail.acqsSrc === "1006") { // 분양권에 의한 아파트, 일반분양오피스텔취득
                    if (estateDetail.acqsCause === "1011" || estateDetail.acqsCause === "1012") {
                        lttotRightParamSet(lttotRightInfo);
                    } else if (estateDetail.acqsCause === "1002") {
                        trdeParamSet(trdeInfo);
                    } else if (estateDetail.acqsCause === "1003") {
                        inhrtncParamSet(inhrtncInfo);
                    } else if (estateDetail.acqsCause === "1004") {
                        donaParamSet(donaInfo);
                    }
                } else if (estateDetail.acqsSrc === "1003") { // 조합원입주권에 의한 아파트
                    if (estateDetail.acqsCause === "1001") {
                        redevpRebidngParamSet(redevpRebidngInfo);
                    } else if (estateDetail.acqsCause === "1002") {
                        trdeParamSet(trdeInfo);
                    } else if (estateDetail.acqsCause === "1003") {
                        inhrtncParamSet(inhrtncInfo);
                    } else if (estateDetail.acqsCause === "1004") { // 증여
                        donaParamSet(donaInfo);
                    }
                } else if (estateDetail.acqsSrc === "1004") { // 자가신축주택취득
                    setUseConfmDe(bindUseConfmDe(selfNwccInfo.useConfmDe));
                    setStdrMktc(bindStdrMktc(selfNwccInfo.stdrMktc));
                    setRlDlpc(selfNwccInfo.rlDlpc);
                }
            } else if (estateDetail.estateDetailSe === "1002") { // 분양권
                if (estateDetail.acqsCause === "1001") { // 최초분양
                    lttotRightParamSet(lttotRightInfo);
                } else if (estateDetail.acqsCause === "1002") { // 전매
                    trdeParamSet(trdeInfo);
                } else if (estateDetail.acqsCause === "1003") { // 상속
                    inhrtncParamSet(inhrtncInfo);
                } else if (estateDetail.acqsCause === "1004") { // 증여
                    donaParamSet(donaInfo);
                }
            } else if (estateDetail.estateDetailSe === "1003") { // 조합원입주권
                if (estateDetail.acqsCause === "1001") { // 원조합원
                    redevpRebidngParamSet(redevpRebidngInfo);
                } else if (estateDetail.acqsCause === "1002") { // 전매
                    trdeParamSet(trdeInfo);
                } else if (estateDetail.acqsCause === "1003") { // 상속
                    inhrtncParamSet(inhrtncInfo);
                } else if (estateDetail.acqsCause === "1004") { // 증여
                    donaParamSet(donaInfo);
                }
            }
        }

        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/assets/selAssetsAcqInfo.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                assetsSn: props.form.assetsSn
            },
            onSuccessFn: (res: any) => {
                setEstateSttus(res.item.estateInfo.estateSttus);
                setPnu(res.item.estateInfo.pnu);
                setDongNm(res.item.estateInfo.dongNm);
                setHoNm(res.item.estateInfo.hoNm);
                setEstateDetailSe(res.item.estateDetail.estateDetailSe);
                setEstateFom(res.item.estateDetail.estateFom);
                setAcqsSrc(res.item.estateDetail.acqsSrc);
                setAcqsCause(res.item.estateDetail.acqsCause);

                // 취득정보 디폴트값 : 신규 작성일 때에만 세팅
                const acqDflValInfo = {
                    trdeInfo: { // 매매
                        cntrctDe: "",
                        entyPymntde: "",
                        surlusPymntde: "",
                        ownshipTransrRceptDe: "",
                        stdrMktc: "0",
                        rlDlpc: "0",
                        evlamt: "0",
                        lttotAmount: "0"
                    },
                    inhrtncInfo: {  // 상속
                        excptInhrtncAt: "Y",
                        inhrtncKnd1: "1001",
                        inhrtncKnd2: "1001",
                        inhrtncKnd3: "1001",
                        inhrtncKnd4: "1001",
                        inhrtncKnd5: "N",
                        cntrctDe: "",
                        entyPymntde: "",
                        surlusPymntde: "",
                        ownshipTransrDe: "",
                        beginDe: "",
                        stdrMktc: "0",
                        rlDlpc: "0",
                        evlamt: "0",
                        lttotAmount: "0",
                        lttotManSe: "1003",
                        beforeEstateTy: "1001",
                        beforeEstatePrpos: "1001",
                        manageDspsPlanDe: "",
                        fullPymntde: ""             // 분양대금 완납일
                    },
                    donaInfo: { // 증여
                        donaKnd: "1001",
                        donaKnd2: "1001",
                        cntrctDe: "",
                        entyPymntde: "",
                        surlusPymntde: "",
                        ownshipTransrDe: "",
                        rgistRceptDe: "",
                        rightSuccsDe: "",
                        donaCntrctDe: "",
                        beforeEstateTy: "1001",
                        beforeEstatePrpos: "1001",
                        manageDspsCnfmDe: "",
                        stdrMktc: "0",
                        rlDlpc: "0",
                        evlamt: "0",
                        lttotAmount: "0",
                        fullPymntde: ""             // 분양대금 완납일
                    },
                    lttotRightInfo: { // 분양권
                        przwinDe: "",
                        cntrctDe: "",
                        entyPymntde: "",
                        surlusPymntde: "",
                        ownshipTransrRceptDe: "",
                        stdrMktc: "0",
                        rlDlpc: "0",
                        lttotAmount: "0",
                        fullPymntde: ""            // 분양대금완납일
                    },
                    redevpRebidngInfo: { // 재개발재건축
                        beforeEstateTy: "1001",
                        beforeEstatePrpos: "1001",
                        manageDspsPlanDe: "",
                        cntrctDe: "",
                        entyPymntde: "",
                        surlusPymntde: "",
                        rgistRceptDe: "",
                        stdrMktc: "0",
                        rlDlpc: "0",
                        evlamt: "0"
                    },
                    selfNwccInfo: { // 자가신축
                        stdrMktc: "0",
                        rlDlpc: "0"
                    }
                };
                const trdeInfo = Object.keys(res.item.trdeInfo).length <= 5 ? acqDflValInfo.trdeInfo : res.item.trdeInfo;
                const inhrtncInfo = Object.keys(res.item.inhrtncInfo).length <= 5 ? acqDflValInfo.inhrtncInfo : res.item.inhrtncInfo;
                const donaInfo = Object.keys(res.item.donaInfo).length <= 5 ? acqDflValInfo.donaInfo : res.item.donaInfo;
                const lttotRightInfo = Object.keys(res.item.lttotRightInfo).length <= 5 ? acqDflValInfo.lttotRightInfo : res.item.lttotRightInfo;
                const redevpRebidngInfo = Object.keys(res.item.redevpRebidngInfo).length <= 5 ? acqDflValInfo.redevpRebidngInfo : res.item.redevpRebidngInfo;
                const selfNwccInfo = Object.keys(res.item.selfNwccInfo).length <= 5 ? acqDflValInfo.selfNwccInfo : res.item.selfNwccInfo;
                getFormSeq(res.item.estateDetail, trdeInfo, inhrtncInfo, donaInfo, lttotRightInfo, redevpRebidngInfo, selfNwccInfo);
                AppContext.hideSpinner();
            }
        });
    }, [props.form.assetsSn, props.form.stdrMktc, props.form.useConfmDe]);

    // 실거래가 필드명 분기
    const getRlDlpcFldNm = () => AssetsUtil.getRlDlpcFldNm(estateDetailSe, estateFom, acqsSrc, acqsCause);

    // 기준시가 자동조회 날짜 기준
    const getAcqsDe = () => {
        if (estateSttus === "1002") { // 보유중 자산
            if (estateDetailSe === "1001") { // 주택
                // 아파트
                if (estateFom === "1001") {
                    // 기존아파트취득
                    if (acqsSrc === "1001") {
                        if (acqsCause === "1002") { // 매매
                            if (Util.isEmpty(surlusPymntde)) {
                                return { acqsFieldAlias: "잔금지급일", acqsDe: surlusPymntde };
                            } else {
                                const fastDe = Util.compareStr(surlusPymntde, ownshipTransrRceptDe, "min");
                                if (fastDe === surlusPymntde) {
                                    return { acqsFieldAlias: "잔금지급일", acqsDe: surlusPymntde };
                                } else if (fastDe === ownshipTransrRceptDe) {
                                    if (!ownshipTransrRceptDeDsb) {  // disabled가 아닌 경우
                                        return { acqsFieldAlias: "소유권이전등기접수일", acqsDe: ownshipTransrRceptDe };
                                    } else {
                                        return { acqsFieldAlias: "잔금지급일", acqsDe: surlusPymntde };
                                    }
                                }
                            }
                        } else if (acqsCause === "1003") { // 상속
                            return { acqsFieldAlias: "상속개시일", acqsDe: beginDe };
                        } else if (acqsCause === "1004") { // 증여
                            if (!rgistRceptDeDsb) {  // 증여등기접수일 disabled 아닌 경우
                                return { acqsFieldAlias: "증여등기접수일", acqsDe: rgistRceptDe }
                            } else {
                                return { acqsFieldAlias: "증여계약일", acqsDe: donaCntrctDe };
                            }
                        }
                    // 분양권에 의한 취득
                    } else if (acqsSrc === "1002") {
                        if (["1011", "1012", "1002"].indexOf(acqsCause) !== -1) {   // 신규분양, 미분양, 전매, 증여
                            if (Util.isEmpty(fullPymntde)) {
                                return { acqsFieldAlias: "분양대금완납일", acqsDe: fullPymntde };
                            } else {
                                const fastDe = Util.compareStr(fullPymntde, ownshipTransrRceptDe, "min");
                                if (fastDe === fullPymntde) {
                                    return { acqsFieldAlias: "분양대금완납일", acqsDe: fullPymntde };
                                } else if (fastDe === ownshipTransrRceptDe) {
                                    if (!ownshipTransrRceptDeDsb) {  // disabled가 아닌 경우
                                        return { acqsFieldAlias: "소유권이전등기접수일", acqsDe: ownshipTransrRceptDe };
                                    } else {
                                        return { acqsFieldAlias: "분양대금완납일", acqsDe: fullPymntde };
                                    }
                                }
                            }
                        } else if (["1003", "1004"].indexOf(acqsCause) !== -1) {    // 상속, 증여
                            if (Util.isEmpty(fullPymntde)) {
                                return { acqsFieldAlias: "분양대금완납일", acqsDe: fullPymntde };
                            } else {
                                const fastDe = Util.compareStr(fullPymntde, ownshipTransrDe, "min");
                                if (fastDe === fullPymntde) {
                                    return { acqsFieldAlias: "분양대금완납일", acqsDe: fullPymntde };
                                } else if (fastDe === ownshipTransrDe) {
                                    if (!ownshipTransrDeDsb) {  // disabled 아닌 경우
                                        return { acqsFieldAlias: "소유권이전접수일", acqsDe: ownshipTransrDe };
                                    } else {
                                        return { acqsFieldAlias: "분양대금완납일", acqsDe: fullPymntde };
                                    }
                                }
                            }
                        }
                    // 조합원입주권에 의한 취득
                    } else if (acqsSrc === "1003") {
                        return { acqsFieldAlias: "사용승인일", acqsDe: useConfmDe };
                    }
                // 다세대주택, 다가구주택, 다중주택, 단독주택
                } else if (["1002", "1003", "1004", "1005"].indexOf(estateFom) !== -1) {
                    if (acqsSrc === "1001") {   // 기존주택 취득
                        if (acqsCause === "1002") { // 매매
                            if (Util.isEmpty(surlusPymntde)) {
                                return { acqsFieldAlias: "잔금지급일", acqsDe: surlusPymntde };
                            } else {
                                const fastDe = Util.compareStr(surlusPymntde, ownshipTransrRceptDe, "min");
                                if (fastDe === surlusPymntde) {
                                    return { acqsFieldAlias: "잔금지급일", acqsDe: surlusPymntde };
                                } else if (fastDe === ownshipTransrRceptDe) {
                                    if (!ownshipTransrRceptDeDsb) {  // disabled가 아닌 경우
                                        return { acqsFieldAlias: "소유권이전등기접수일", acqsDe: ownshipTransrRceptDe };
                                    } else {
                                        return { acqsFieldAlias: "잔금지급일", acqsDe: surlusPymntde };
                                    }
                                }
                            }
                        } else if (acqsCause === "1003") {  // 상속
                            return { acqsFieldAlias: "상속개시일", acqsDe: beginDe };
                        } else if (acqsCause === "1004") {  // 증여
                            if (!rgistRceptDeDsb) {  // 증여등기접수일 disabled 아닌 경우
                                return { acqsFieldAlias: "증여등기접수일", acqsDe: rgistRceptDe }
                            } else {
                                return { acqsFieldAlias: "증여계약일", acqsDe: donaCntrctDe };
                            }
                        }
                    } else if (acqsSrc === "1004") { // 자가신축주택취득
                        return { acqsFieldAlias: "사용승인일", acqsDe: useConfmDe };
                    }
                // 주거용 오피스텔
                } else if (estateFom === "1006") {
                    if (acqsSrc === "1005") {   // 기존오피스텔취득
                        if (acqsCause === "1002") { // 매매
                            if (Util.isEmpty(surlusPymntde)) {
                                return { acqsFieldAlias: "잔금지급일", acqsDe: surlusPymntde };
                            } else {
                                const fastDe = Util.compareStr(surlusPymntde, ownshipTransrRceptDe, "min");
                                if (fastDe === surlusPymntde) {
                                    return { acqsFieldAlias: "잔금지급일", acqsDe: surlusPymntde };
                                } else if (fastDe === ownshipTransrRceptDe) {
                                    if (!ownshipTransrRceptDeDsb) {  // disabled 아닌 경우
                                        return { acqsFieldAlias: "소유권이전등기접수일", acqsDe: ownshipTransrRceptDe };
                                    } else {
                                        return { acqsFieldAlias: "잔금지급일", acqsDe: surlusPymntde };
                                    }
                                }
                            }
                        } else if (acqsCause === "1003") {  // 상속
                            return { acqsFieldAlias: "상속개시일", acqsDe: beginDe };
                        } else if (acqsCause === "1004") {  // 증여
                            if (!rgistRceptDeDsb) {  // 증여등기접수일 disabled 아닌 경우
                                return { acqsFieldAlias: "증여등기접수일", acqsDe: rgistRceptDe }
                            } else {
                                return { acqsFieldAlias: "증여계약일", acqsDe: donaCntrctDe };
                            }
                        }
                    } else if (acqsSrc === "1006") { // 일반분양 오피스텔취득
                        if (["1011", "1012", "1002"].indexOf(acqsCause) !== -1) {   // 신규분양, 미분양, 전매, 증여
                            if (Util.isEmpty(fullPymntde)) {
                                return { acqsFieldAlias: "분양대금완납일", acqsDe: fullPymntde };
                            } else {
                                const fastDe = Util.compareStr(fullPymntde, ownshipTransrRceptDe, "min");
                                if (fastDe === fullPymntde) {
                                    return { acqsFieldAlias: "분양대금완납일", acqsDe: fullPymntde };
                                } else if (fastDe === ownshipTransrRceptDe) {
                                    if (!ownshipTransrRceptDeDsb) {  // disabled 아닌 경우
                                        return { acqsFieldAlias: "소유권이전등기접수일", acqsDe: ownshipTransrRceptDe };
                                    } else {
                                        return { acqsFieldAlias: "분양대금완납일", acqsDe: fullPymntde };
                                    }
                                }
                            }
                        } else if (["1003", "1004"].indexOf(acqsCause) !== -1) {    // 상속, 증여
                            if (Util.isEmpty(fullPymntde)) {
                                return { acqsFieldAlias: "분양대금완납일", acqsDe: fullPymntde };
                            } else {
                                const fastDe = Util.compareStr(fullPymntde, ownshipTransrDe, "min");
                                if (fastDe === fullPymntde) {
                                    return { acqsFieldAlias: "분양대금완납일", acqsDe: fullPymntde };
                                } else if (fastDe === ownshipTransrDe) {
                                    if (!ownshipTransrDeDsb) {  // disabled 아닌 경우
                                        return { acqsFieldAlias: "소유권이전접수일", acqsDe: ownshipTransrDe };
                                    } else {
                                        return { acqsFieldAlias: "분양대금완납일", acqsDe: fullPymntde };
                                    }
                                }
                            }
                        }
                    }
                }
            // 분양권 - 기준시가 자동계산 없음
            } else if (estateDetailSe === "1002") {
                if (acqsCause === "1001") {
                    return { acqsFieldAlias: "분양권계약일", acqsDe: cntrctDe };
                } else if (acqsCause === "1002") {
                    return { acqsFieldAlias: "잔금지급일", acqsDe: surlusPymntde };
                } else if (acqsCause === "1003") {
                    return { acqsFieldAlias: "상속개시일", acqsDe: beginDe };
                } else if (acqsCause === "1004") {
                    if (Util.isNotEmpty(rgistRceptDe)) {
                        return { acqsFieldAlias: "등기접수일", acqsDe: rgistRceptDe };
                    } else {
                        return { acqsFieldAlias: "증여계약일", acqsDe: donaCntrctDe };
                    }
                }
            // 조합원 입주권 - 기준시가 자동계산 없음
            } else if (estateDetailSe === "1003") {
                if (acqsCause === "1001") {
                    return { acqsFieldAlias: "관리처분계획인가일", acqsDe: manageDspsPlanDe };
                } else if (acqsCause === "1002") {
                    return { acqsFieldAlias: "잔금지급일", acqsDe: surlusPymntde };
                } else if (acqsCause === "1003") {
                    return { acqsFieldAlias: "상속개시일", acqsDe: beginDe };
                } else if (acqsCause === "1004") {
                    if (Util.isNotEmpty(rgistRceptDe)) {
                        return { acqsFieldAlias: "등기접수일", acqsDe: rgistRceptDe };
                    } else {
                        return { acqsFieldAlias: "증여계약일", acqsDe: donaCntrctDe };
                    }
                }
            }
        } else if (estateSttus === "1003") { // 취득할 자산
            if (estateDetailSe === "1001") {
                if (estateFom === "1001") {
                    if (acqsSrc === "1001") {
                        if (acqsCause === "1002") {
                            return { acqsFieldAlias: "잔금지급일", acqsDe: surlusPymntde };
                        } else if (acqsCause === "1003") {
                            return { acqsFieldAlias: "상속개시일", acqsDe: beginDe };
                        } else if (acqsCause === "1004") {
                            return { acqsFieldAlias: "증여계약일", acqsDe: donaCntrctDe };
                        }
                    } else if (acqsSrc === "1002") {
                        return { acqsFieldAlias: "분양대금완납일", acqsDe: fullPymntde };
                    } else if (acqsSrc === "1003") {
                        return { acqsFieldAlias: "사용승인일", acqsDe: useConfmDe };
                    }
                } else if (["1002", "1003", "1004", "1005"].indexOf(estateFom) !== -1) {
                    if (acqsSrc === "1001") {
                        if (acqsCause === "1002") {
                            return { acqsFieldAlias: "잔금지급일", acqsDe: surlusPymntde };
                        } else if (acqsCause === "1003") {
                            return { acqsFieldAlias: "상속개시일", acqsDe: beginDe };
                        } else if (acqsCause === "1004") {
                            return { acqsFieldAlias: "증여계약일", acqsDe: donaCntrctDe };
                        }
                    } else if (acqsSrc === "1004") {
                        return { acqsFieldAlias: "사용승인일", acqsDe: useConfmDe };
                    }
                } else if (estateFom === "1006") {
                    if (acqsSrc === "1005") {
                        if (acqsCause === "1002") {
                            return { acqsFieldAlias: "잔금지급일", acqsDe: surlusPymntde };
                        } else if (acqsCause === "1003") {
                            return { acqsFieldAlias: "상속개시일", acqsDe: beginDe };
                        } else if (acqsCause === "1004") {
                            return { acqsFieldAlias: "증여계약일", acqsDe: donaCntrctDe };
                        }
                    } else if (acqsSrc === "1006") {
                        return { acqsFieldAlias: "분양대금완납일", acqsDe: fullPymntde };
                    }
                }
            } else if (estateDetailSe === "1002") {
                if (acqsCause === "1001") {
                    return { acqsFieldAlias: "분양권계약일", acqsDe: cntrctDe };
                } else if (acqsCause === "1002") {
                    return { acqsFieldAlias: "잔금지급일", acqsDe: surlusPymntde };
                } else if (acqsCause === "1003") {
                    return { acqsFieldAlias: "상속개시일", acqsDe: beginDe };
                } else if (acqsCause === "1004") {
                    return { acqsFieldAlias: "증여계약일", acqsDe: donaCntrctDe };
                }
            } else if (estateDetailSe === "1003") {
                if (acqsCause === "1001") {
                    return { acqsFieldAlias: "관리처분계획인가일", acqsDe: manageDspsPlanDe };
                } else if (acqsCause === "1002") {
                    return { acqsFieldAlias: "잔금지급일", acqsDe: surlusPymntde };
                } else if (acqsCause === "1003") {
                    return { acqsFieldAlias: "상속개시일", acqsDe: beginDe };
                } else if (acqsCause === "1004") {
                    return { acqsFieldAlias: "증여계약일", acqsDe: donaCntrctDe };
                }
            }
        }
    }

    const BeforeEstateTyEl = () =>
        <ImageRadioBox
            codeList={Util.getCodeArray("1020")}
            value={beforeEstateTy}
            btnClass="w24 h24 pr5"
            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
            interval={20}
            onChange={(data: string) => {
                setBeforeEstateTy(data);
            }}
        />
    ;

    // 상속의 종류
    const InhrtncKnd2El = () =>
        <ImageRadioBox
            codeList={Util.getCodeArray("1017")}
            value={inhrtncKnd2}
            setValue={setInhrtncKnd2}
            btnClass="w24 h24 pr5"
            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
            interval={20}
            onChange={(data: string) => setInhrtncKnd3TrVsb(data === "1002")}
        />
    ;

    // 관리처분계획인가일
    const ManageDspsPlanDeEl = () =>
        <DatePickerBox value={manageDspsPlanDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setManageDspsPlanDe(Util.dateToString(data, ""))} />
    ;

    // 사업시행인가일
    const BsnsImpCnfmDeEl = () =>
        <DatePickerBox value={bsnsImpCnfmDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setBsnsImpCnfmDe(Util.dateToString(data, ""))} />
    ;

    const DonaKndTh = () => {
        let toolTipMsg = "";
        if (estateSttus === "1002") {
            toolTipMsg = "동일세대 여부의 기준일은 양도일을 기준으로 판정한다.";
        } else if (estateSttus === "1003") {
            toolTipMsg = "동일세대 여부 기준일은 증여계약일을 기준으로 판정한다.";
        }
        return (
            <div className="dpFlx aiC jcC">
                <img
                    src={require('img/common/icon_info_fill_20.png')}
                    alt="icon_info_fill_20"
                    className='mr3 csP'
                    data-tooltip-id="DonaKndToolTip"
                    data-tooltip-content={toolTipMsg}
                />
                <ToolTip id="DonaKndToolTip" place='top' clickable={true} className="w300 taL" />
                증여의 종류<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
            </div>
        )
    };

    // 증여의 종류
    const DonaKndEl = () =>
        <ImageRadioBox
            codeList={Util.getCodeArray("1019")}
            value={donaKnd}
            btnClass="w24 h24 pr5"
            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
            interval={20}
            onChange={(data: string) => setDonaKnd(data)}
        />
    ;

    // 증여자와의 관계
    const DonaKnd2El = () =>
        <ImageRadioBox
            codeList={Util.getCodeArray("1056")}
            value={donaKnd2}
            btnClass="w24 h24 pr5"
            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
            interval={20}
            onChange={(data: string) => setDonaKnd2(data)}
        />
    ;

    // 증여계약일
    const DonaCntrctDeEl = () =>
        <DatePickerBox value={donaCntrctDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setDonaCntrctDe(Util.dateToString(data, ""))} />
    ;

    // 권리승계일
    const RightSuccsDeEl = () =>
        <DatePickerBox value={rightSuccsDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setRightSuccsDe(Util.dateToString(data, ""))} disabled={rightSuccsDeDsb} />
    ;

    // 증여등기접수일
    const RgistRceptDeEl = () =>
        <DatePickerBox value={rgistRceptDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setRgistRceptDe(Util.dateToString(data, ""))} disabled={rgistRceptDeDsb} />
    ;

    const RgistRceptDeElCheckBox = (startFldNm: string, endFldNm: string) =>
        <ImageCheckBox
            value={!rgistRceptDeDsb}
            labelText={startFldNm + '이 ' + endFldNm + '과 다른경우'}
            btnClass='w20 h20'
            labelClass='SpoqaHanSansNeo-Regular fs14 ls07 fc666666 pl5'
            onChange={() => setRgistRceptDeDsb(!rgistRceptDeDsb)}
        />
    ;

    const RightSuccsDeElCheckBox = () =>
        <ImageCheckBox
            value={!rightSuccsDeDsb}
            labelText='권리승계일이 증여계약일과 다른경우'
            btnClass='w20 h20'
            labelClass='SpoqaHanSansNeo-Regular fs14 ls07 fc666666 pl5'
            onChange={() => setRightSuccsDeDsb(!rightSuccsDeDsb)}
        />
    ;

    // 평가액
    const EvlamtEl = () =>
        <NumberTextBox
            value={evlamt}
            onChange={(data: string) => setEvlamt(data)}
            borderClass="bgcWhite w300 h40 pr15 bd1"
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            maxLength={15}
            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
            unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
            unitText="원"
        />
    ;

    // 계약일
    const CntrctDeEl = () =>
        <DatePickerBox value={cntrctDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setCntrctDe(Util.dateToString(data, ""))} />
    ;

    // 날짜 추가 입력 체크박스
    const EntyPymntdeElCheckBox = (startFldNm: string, endFldNm: string) =>
        <ImageCheckBox
            value={!entyPymntdeDsb}
            labelText={startFldNm + '이 ' + endFldNm + '과 다른 경우'}
            btnClass='w20 h20'
            labelClass='SpoqaHanSansNeo-Regular fs14 ls07 fc666666 pl5'
            onChange={() => setEntyPymntdeDsb(!entyPymntdeDsb)}
        />
    ;

    // 계약금지급일
    const EntyPymntdeEl = () =>
        <DatePickerBox value={entyPymntde} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setEntyPymntde(Util.dateToString(data, ""))} disabled={entyPymntdeDsb} />
    ;

    // 잔금지급일
    const SurlusPymntdeEl = () =>
        <DatePickerBox value={surlusPymntde} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setSurlusPymntde(Util.dateToString(data, ""))} />
    ;

    // 분양대금완납일
    const FullPymntdeEl = () =>
        <DatePickerBox value={fullPymntde} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setFullPymntde(Util.dateToString(data, ""))} />
    ;

    // 소유권이전등기접수일
    const OwnshipTransrRceptDeEl = () =>
        <DatePickerBox value={ownshipTransrRceptDe} width={300} height={40} bgColor="#fff" fontSize={15} textAlign="left" onChange={(data: string) => setOwnshipTransrRceptDe(Util.dateToString(data, ""))} disabled={ownshipTransrRceptDeDsb} />
    ;

    // 소유권이전등기접수일 표시 여부 체크박스
    const OwnshipTransrDeElCheckBox = (startFldNm: string, endFldNm: string) =>
        <ImageCheckBox
            value={!ownshipTransrDeDsb}
            labelText={startFldNm + '이 ' + endFldNm + '보다 빠를 경우'}
            btnClass='w20 h20'
            labelClass='SpoqaHanSansNeo-Regular fs14 ls07 fc666666 pl5'
            onChange={() => setOwnshipTransrDeDsb(!ownshipTransrDeDsb)}
        />
    ;

    // 소유권이전등기접수일
    const OwnshipTransrDeEl = () =>
        <DatePickerBox value={ownshipTransrDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setOwnshipTransrDe(Util.dateToString(data, ""))} disabled={ownshipTransrDeDsb} />
    ;

    // 사용승인일
    const UseConfmDeEl = () =>
        <DatePickerBox value={useConfmDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setUseConfmDe(Util.dateToString(data, ""))} />

    // 기준시가
    const StdrMktcEl = () =>
        <div className="dpFlx aiC">
            <NumberTextBox
                value={stdrMktc}
                onChange={(data: string) => setStdrMktc(data)}
                borderClass="bgcWhite w300 h40 pr15 bd1"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                maxLength={15}
                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                unitText="원"
            />
            <div
                className="h40 bgcWhite bd1 bdc17A840 bdrd5 dpFlx jcC aiC csP ml30 pw10"
                onClick={() => {
                    const acqsInfo = getAcqsDe();
                    if (acqsInfo === undefined) {
                        AppContext.openAlert({
                            title: "취득일 판별 오류",
                            msg: "취득일자 판별에 실패하였습니다.",
                            icon: "check"
                        });
                        return;
                    }
                    if (Util.isEmpty(acqsInfo.acqsDe) || acqsInfo.acqsDe === undefined) {
                        AppContext.openAlert({
                            title: acqsInfo.acqsFieldAlias + " 입력",
                            msg: acqsInfo.acqsFieldAlias + " 입력 후 주택가격 조회가 가능합니다.",
                            icon: "check"
                        });
                        return;
                    }
                    const acqsYear = AssetsUtil.getAcqsYear(estateFom, acqsInfo.acqsDe);
                    if (Util.isEmpty(acqsYear)) {
                        AppContext.openAlert({
                            title: "고시일 없음",
                            msg: "해당 취득일에 해당하는 고시일이 없어, 기준시가 조회가 불가합니다.",
                            icon: "check"
                        });
                        return;
                    }
                    let tailUrl;
                    if (["1001", "1006"].indexOf(estateFom) !== -1) { // 공동주택
                        tailUrl = "apt/price2";
                    } else {
                        tailUrl = "land/pricehouse2";
                    }
                    AppContext.showSpinner();
                    Axios.dataAccess({
                        url: "api/system/broker/callApi.do",
                        methodType: "post",
                        paramType: "object",
                        paramData: {
                            apiName: "openapi",
                            tailUrl: tailUrl,
                            paramStr: pnu + "?stdryear=" + acqsYear + "&honm=" + encodeURI(hoNm) + "&dongnm=" + encodeURI(dongNm)
                        },
                        onSuccessFn: (res2: any) => {
                            AppContext.hideSpinner();
                            if (res2.item.rtn_list.length === 0) {
                                AppContext.openAlert({
                                    title: "기준시가 조회 불가",
                                    msg: "해당 연도의 기준시가 정보가 존재하지 않습니다.",
                                    icon: "warning"
                                });
                                return;
                            }
                            let pblntfPc = "0";
                            if (["1001", "1006"].indexOf(estateFom) !== -1) { // 공동주택
                                pblntfPc = res2.item.rtn_list[0].pblntfPc;
                            } else {
                                pblntfPc = res2.item.rtn_list[0].housePc;
                            }
                            if (Util.isEmpty(pblntfPc)) {
                                AppContext.openAlert({
                                    title: "기준시가 조회 불가",
                                    msg: "해당 연도의 기준시가 정보가 존재하지 않습니다.",
                                    icon: "warning"
                                });
                                return;
                            }
                            setStdrMktc(pblntfPc);
                            AppContext.openAlert({
                                title: "기준시가 조회 완료",
                                msg: acqsYear + "년 기준시가 정보가 연계되었습니다.",
                                icon: "warning"
                            });
                        }
                    });
                }}
                data-tooltip-id="stdrMktcLinkBtn1"
                data-tooltip-content="클릭하여 취득일의 기준시가를 가져옵니다."
            >
                <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc17A840'>기준시가 자동조회</span>
            </div>
            <ToolTip id="stdrMktcLinkBtn1" place='top' clickable={true} className="w300 taL" />
            <div
                className="h40 bgcWhite bd1 bdc17A840 bdrd5 dpFlx jcC aiC csP pw10 ml10"
                onClick={() => {
                    setManualCheckGuideModalOpen(true);
                }}
                data-tooltip-id="stdrMktcLinkBtn2"
                data-tooltip-content="기준시가 자동조회가 되지 않은 경우 사용합니다."
            >
                <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc17A840'>기준시가 수동조회</span>
            </div>
            <ToolTip id="stdrMktcLinkBtn2" place='top' clickable={true} className="w300 taL" />
        </div>
    ;

    // 실거래가
    const RlDlpcEl = () =>
        <NumberTextBox
            value={rlDlpc}
            onChange={(data: string) => setRlDlpc(data)}
            borderClass="bgcWhite w300 h40 pr15 bd1"
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            maxLength={15}
            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
            unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
            unitText="원"
        />
    ;

    // 특례상속 여부
    const ExcptInhrtncAtEl = () =>
        <ImageRadioBox
            codeList={Util.getCodeArray("1042")}
            value={excptInhrtncAt}
            btnClass="w24 h24 pr5"
            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
            interval={20}
            onChange={(data: string) => setExcptInhrtncAt(data)}
        />
    ;

    const InhrtncKnd1Th = () =>
        <div className="dpFlx aiC jcC">
            <img
                src={require('img/common/icon_info_fill_20.png')}
                alt="icon_info_fill_20"
                className='mr3 csP'
                data-tooltip-id="inhrtncKnd1ToolTip"
                data-tooltip-content={
                    "※ 동일세대원 여부 (동일세대원 여부는 상속개시일 기준으로 판정한다.)\n\n" +
                    "1세대 1주택 비과세는 세대단위로 판정하고, 배우자는 동일세대원이며, 동일세대원 간의 소유권 변동은 고려사항이 아니다.\n\n" +
                    "따라서 동일세대원으로부터 상속받은 경우 이를 주택수에서 제외하는 상속받은 주택으로 볼 수 없다.\n\n" +
                    "다만, 동일세대원 간의 상속이 이루어진 경우에는 동일세대원으로서 피상속인이 보유한 기간 및 거주기간을 통산한다."
                }
            />
            <ToolTip id="inhrtncKnd1ToolTip" place='top' clickable={true} className="w300 taL" />
            동일세대원 여부<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
        </div>
    ;

    // 동일세대원 여부
    const InhrtncKnd1El = () =>
        <ImageRadioBox
            codeList={Util.getCodeArray("1016")}
            value={inhrtncKnd1}
            setValue={setInhrtncKnd1}
            btnClass="w24 h24 pr5"
            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
            interval={20}
        />
    ;

    const InhrtncKnd3Th = () =>
        <div className="dpFlx aiC jcC">
            <img
                src={require('img/common/icon_info_fill_20.png')}
                alt="icon_info_fill_20"
                className='mr3 csP'
                data-tooltip-id="inhrtncKnd3ToolTip"
                data-tooltip-content={
                    "※ 공동상속의 종류\n\n" +
                    "공동상속주택은 다음 각 호의 선순위에 따른 자의 주택으로 본다.\n\n" +
                    "① 상속지분이 가장 큰 상속인\n" +
                    "② 당해 주택에 거주하는 자\n" +
                    "③ 최연장자"
                }
            />
            <ToolTip id="inhrtncKnd3ToolTip" place='top' clickable={true} className="w300 taL" />
            지분권자의 종류
            <span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
        </div>
    ;
    const InhrtncKnd3El = () =>
        <ImageRadioBox
            codeList={Util.getCodeArray("1018")}
            value={inhrtncKnd3}
            setValue={setInhrtncKnd3}
            btnClass="w24 h24 pr5"
            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
            interval={20}
        />
    ;

    // 피상속인의 주택용도
    const BeforeEstatePrposEl = () =>
        <ImageRadioBox
            codeList={Util.getCodeArray("1008")}
            value={beforeEstatePrpos}
            setValue={setBeforeEstatePrpos}
            btnClass="w24 h24 pr5"
            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
            interval={20}
        />
    ;

    // 상속개시일
    const BeginDeEl = () =>
        <DatePickerBox value={beginDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setBeginDe(Util.dateToString(data, ""))} />
    ;

    const ManageDspsPlanDeTh = () =>
        <div className="dpFlx aiC jcC">
            <img
                src={require('img/common/icon_info_fill_20.png')}
                alt="icon_info_fill_20"
                className='mr3 csP'
                onClick={() => setManageDspsPlanDeModalOpen(true)}
            />
            관리처분계획인가일
            <span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
        </div>
    ;

    // 전매
    const TrdeCommonEl = () => {
        let titleSubText = "";
        let titleSubText2 = "";
        if (["1002", "1006"].indexOf(acqsSrc) !== -1) { // 분양권에 의한 취득, 일반분양오피스텔취득
            titleSubText = "분양권 ";
            titleSubText2 = "분양";
        } else if (acqsSrc === "1003") { // 조합원입주권에 의한 취득
            titleSubText = "조합원입주권 ";
            titleSubText2 = "조합원입주권";
        }
        return (
            <table className="forest-form-table">
                <colgroup>
                    <col className="w250" />
                    <col />
                </colgroup>
                <tbody>
                    <tr className="h80">
                        <th>{titleSubText2}계약일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                        <td>
                            <div className="dpFlx flxdRow">
                                {CntrctDeEl()}
                                <div className="dpFlx pl25">
                                    {EntyPymntdeElCheckBox(titleSubText + "계약금지급일", titleSubText2 + "계약일")}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr className={"h80 " + (entyPymntdeDsb ? "dpNone" : "")}>
                        <th>{titleSubText}계약금지급일</th>
                        <td>{EntyPymntdeEl()}</td>
                    </tr>

                    {/* 분양권에 의한 아파트, 분양권에 의한 오피스텔인 경우 */}
                    {["1002", "1006"].indexOf(acqsSrc) !== -1 ?
                        <React.Fragment>
                        <tr className="h80">
                            <th>{titleSubText}잔금지급일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                            <td>{SurlusPymntdeEl()}</td>
                        </tr>
                        <tr className="h80">
                            <th>분양대금완납일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                            <td>
                                <div className="dpFlx flxdRow">
                                {FullPymntdeEl()}
                                    <div className="dpFlx pl25">
                                        <ImageCheckBox
                                            value={!ownshipTransrRceptDeDsb}
                                            labelText={'소유권이전등기접수일이 분양대금완납일보다 빠를 경우'}
                                            btnClass='w20 h20'
                                            labelClass='SpoqaHanSansNeo-Regular fs14 ls07 fc666666 pl5'
                                            onChange={() => setOwnshipTransrRceptDeDsb(!ownshipTransrRceptDeDsb)}
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </React.Fragment>  
                        :                     
                        <tr className="h80">
                            <th>{titleSubText}잔금지급일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                            <td>
                                <div className="dpFlx flxdRow">
                                    {SurlusPymntdeEl()}
                                    <div className="dpFlx pl25">
                                        <ImageCheckBox
                                            value={!ownshipTransrRceptDeDsb}
                                            labelText={'소유권이전등기접수일이 ' + titleSubText + '잔금지급일보다 빠를 경우'}
                                            btnClass='w20 h20'
                                            labelClass='SpoqaHanSansNeo-Regular fs14 ls07 fc666666 pl5'
                                            onChange={() => setOwnshipTransrRceptDeDsb(!ownshipTransrRceptDeDsb)}
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    }

                    <tr className={"h80 " + (ownshipTransrRceptDeDsb ? "dpNone" : "")}>
                        <th>소유권이전등기접수일</th>
                        <td>{OwnshipTransrRceptDeEl()}</td>
                    </tr>
                    <tr className="h80">
                        <th>사용승인일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                        <td>{UseConfmDeEl()}</td>
                    </tr>
                    <tr className="h80">
                        <th>취득당시 기준시가(원)<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                        <td>{StdrMktcEl()}</td>
                    </tr>
                    <tr className="h80">
                        <th>취득당시 실거래가액(원)<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                        <td>{RlDlpcEl()}</td>
                    </tr>
                </tbody>
            </table>
        )
    }

    // 상속 Element
    const InhrtncCommonEl = () => {
        let titleSubText = "";
        if (["1001", "1005"].indexOf(acqsSrc) !== -1) { // 분양권에 의한 취득, 일반분양오피스텔취득
            titleSubText = "주택 ";
        } else if (["1002", "1006"].indexOf(acqsSrc) !== -1) { // 분양권에 의한 취득, 일반분양오피스텔취득
            titleSubText = "분양권 ";
        } else if (acqsSrc === "1003") { // 조합원입주권에 의한 취득
            titleSubText = "종전주택 ";
        }
        return (
            <table className="forest-form-table">
                <colgroup>
                    <col className="w250" />
                    <col />
                </colgroup>
                <tbody>
                <tr className="h64">
                    <th className="taC posR">
                        <div className="dpFlx aiC jcC">
                            <img
                                src={require('img/common/icon_info_fill_20.png')}
                                alt="icon_info_fill_20"
                                className='mr3 csP'
                                data-tooltip-id="excptInhrtncHouseToolTip"
                                data-tooltip-content={
                                    "특례상속주택 여부\n\n" +
                                    "피상속인이 2주택 이상을 상속하는 경우 다음 각 호의 선순위에 따른 1주택을 특례상속주택으로 본다.\n\n" +
                                    "① 피상속인이 소유한 기간이 가장 긴 1주택\n" +
                                    "② 피상속인이 거주기간에 가장 긴 1주택\n" +
                                    "③ 피상속인이 상속개시 당시 거주한 1주택\n" +
                                    "④ 기준시가가 가장 높은 1주택\n" +
                                    "⑤ 상속인 선택"
                                }
                            />
                            <ToolTip id="excptInhrtncHouseToolTip" place='top' clickable={true} className="w300 taL lh20" />
                            특례상속주택여부
                            <span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
                        </div>
                    </th>
                    <td>{ExcptInhrtncAtEl()}</td>
                </tr>
                <tr className="h64">
                    <th className="taC posR">{InhrtncKnd1Th()}</th>
                    <td>{InhrtncKnd1El()}</td>
                </tr>
                <tr className="h64">
                    <th className="taC">상속의 종류<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                    <td>{InhrtncKnd2El()}</td>
                </tr>
                <tr className={"h64 " + (inhrtncKnd3TrVsb ? "" : "dpNone")}>
                    <th className="taC posR">{InhrtncKnd3Th()}</th>
                    <td>{InhrtncKnd3El()}</td>
                </tr>
                <tr className="h80">
                    <th className="taC">상속개시일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                    <td>{BeginDeEl()}</td>
                </tr>
                <tr className="h80">
                    <th className="taC">
                        피상속인의 {titleSubText} 계약일
                        <span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
                    </th>
                    <td>
                        <div className="dpFlx flxdRow">
                            {CntrctDeEl()}
                            <div className="dpFlx pl25">
                                {EntyPymntdeElCheckBox(
                                    "피상속인의 " + titleSubText + "계약금지급일",
                                    "피상속인의 " + titleSubText + "계약일"
                                )}
                            </div>
                        </div>
                    </td>
                </tr>
                <tr className={"h80 " + (entyPymntdeDsb ? "dpNone" : "")}>
                    <th className="taC">피상속인의 {titleSubText} 계약금지급일</th>
                    <td>{EntyPymntdeEl()}</td>
                </tr>

                {/* 분양권에 의한 아파트, 분양권에 의한 오피스텔인 경우 */}
                {["1002", "1006"].indexOf(acqsSrc) !== -1 ?
                    <React.Fragment>
                    <tr className="h80">
                        <th className="taC">피상속인의 {titleSubText} 잔금지급일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                        <td>{SurlusPymntdeEl()}</td>
                    </tr>                        
                    <tr className="h80">
                        <th>분양대금완납일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                        <td>
                            <div className="dpFlx flxdRow">
                                {FullPymntdeEl()}
                                <div className="dpFlx pl25">
                                    {OwnshipTransrDeElCheckBox(
                                        "소유권이전등기접수일",
                                        "분양대금완납일"
                                    )}
                                </div>
                            </div>                            
                        </td>
                    </tr>
                    </React.Fragment>
                : 
                    <tr className="h80">
                        <th className="taC">피상속인의 {titleSubText} 잔금지급일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                        <td>
                            <div className="dpFlx flxdRow">
                                {SurlusPymntdeEl()}
                                <div className="dpFlx pl25">
                                    {OwnshipTransrDeElCheckBox(
                                        "소유권이전등기접수일",
                                        "피상속인의 " + titleSubText + "잔금지급일"
                                    )}
                                </div>
                            </div>
                        </td>
                    </tr>                
                }

                <tr className={"h80 " + (ownshipTransrDeDsb ? "dpNone" : "")}>
                    <th className="taC">소유권이전등기접수일</th>
                    <td>{OwnshipTransrDeEl()}</td>
                </tr>
                
                {acqsSrc === "1003" ?
                    <React.Fragment>
                        <tr className="h80">
                            <th className="taC posR">{ManageDspsPlanDeTh()}</th>
                            <td>{ManageDspsPlanDeEl()}</td>
                        </tr>
                        <tr className="h80">
                            <th className="taC posR">사업시행인가일</th>
                            <td>{BsnsImpCnfmDeEl()}</td>
                        </tr>
                    </React.Fragment>
                : <></>}
                <tr className="h80">
                    <th className="taC" title="사용승인일">사용승인일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                    <td>{UseConfmDeEl()}</td>
                </tr>
                <tr className="h80">
                    <th className="taC">취득당시 기준시가(원)<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                    <td>{StdrMktcEl()}</td>
                </tr>
                <tr className="h80">
                    <th className="taC">
                        <div className="dpFlx aiC jcC">
                            {["1002", "1003", "1006"].indexOf(acqsSrc) !== -1 ?
                                <React.Fragment>
                                    <img
                                        src={require('img/common/icon_info_fill_20.png')}
                                        alt="icon_info_fill_20"
                                        className='csP mr3'
                                        data-tooltip-id="AssetsAcqsRlDlpcFldNm10031004"
                                        data-tooltip-content="(상속세평가액+현재까지 지출된 금액)"
                                    />
                                    <ToolTip id="AssetsAcqsRlDlpcFldNm10031004" place='top' clickable={true} />
                                </React.Fragment>
                            : <></>}
                            {getRlDlpcFldNm()}
                            <span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
                        </div>
                    </th>
                    <td>{RlDlpcEl()}</td>
                </tr>
                </tbody>
            </table>
        )
    }

    // 증여
    const DonaCommonEl = () => {
        let titleSubText = "";
        if (["1001", "1005"].indexOf(acqsSrc) !== -1) { // 분양권에 의한 취득, 일반분양오피스텔취득
            titleSubText = "주택 ";
        } else if (["1002", "1006"].indexOf(acqsSrc) !== -1) { // 분양권에 의한 취득, 일반분양오피스텔취득
            titleSubText = "분양권 ";
        } else if (acqsSrc === "1003") { // 조합원입주권에 의한 취득
            titleSubText = "종전주택 ";
        }
        return (
            <React.Fragment>
                <table className="forest-form-table">
                    <colgroup>
                        <col className="w250" />
                        <col />
                    </colgroup>
                    <tbody>
                        {acqsSrc === "1003" ?
                            <React.Fragment>
                                <tr className="h80">
                                    <th className="taC posR">사업시행인가일</th>
                                    <td>{BsnsImpCnfmDeEl()}</td>
                                </tr>
                                <tr className="h80">
                                    <th className="taC posR">관리처분계획인가일</th>
                                    <td><DatePickerBox value={manageDspsCnfmDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setManageDspsCnfmDe(Util.dateToString(data, ""))} /></td>
                                </tr>
                            </React.Fragment>
                        : <></>}
                        {["1001", "1002", "1005", "1006"].indexOf(acqsSrc) !== -1 ?
                            <React.Fragment>
                                <tr className="h64">
                                    <th className="taC">{DonaKndTh()}</th>
                                    <td>{DonaKndEl()}</td>
                                </tr>
                                <tr className="h64">
                                    <th className="taC">증여자와의 관계<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                    <td>{DonaKnd2El()}</td>
                                </tr>
                            </React.Fragment>
                        : <></>}
                        <tr className="h80">
                            <th className="taC">증여 계약일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                            <td className="taC">
                                <div className="dpFlx flxdRow">
                                    {DonaCntrctDeEl()}
                                    <div className="dpFlx pl25">
                                        {RgistRceptDeElCheckBox("증여등기접수일", "증여 계약일")}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr className={"h80 " + (rgistRceptDeDsb ? "dpNone" : "")}>
                            <th className="taC">증여등기접수일</th>
                            <td className="taC">{RgistRceptDeEl()}</td>
                        </tr>
                        <tr className="h80">
                            <th className="taC">증여자의 {titleSubText} 계약일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                            <td className="taC">
                                <div className="dpFlx flxdRow">
                                    {CntrctDeEl()}
                                    <div className="dpFlx pl25">{EntyPymntdeElCheckBox("증여자의 " + titleSubText + " 계약금지급일", "증여자의 " + titleSubText + " 계약일")}</div>
                                </div>
                            </td>
                        </tr>
                        <tr className={"h80 " + (entyPymntdeDsb ? "dpNone" : "")}>
                            <th className="taC">증여자의 {titleSubText} 계약금지급일</th>
                            <td>{EntyPymntdeEl()}</td>
                        </tr>

                        {/* 분양권에 의한 아파트, 분양권에 의한 오피스텔인 경우 */}
                        {["1002", "1006"].indexOf(acqsSrc) !== -1 ?
                            <React.Fragment>
                                <tr className="h80">
                                    <th className="taC">증여자의 {titleSubText} 잔금지급일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                    <td className="taC">{SurlusPymntdeEl()}</td>
                                </tr>
                                <tr className="h80">
                                    <th>분양대금완납일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                    <td>
                                        <div className="dpFlx flxdRow">
                                            {FullPymntdeEl()}
                                            <div className="dpFlx pl25">
                                                {OwnshipTransrDeElCheckBox("소유권이전등기접수일", "분양대금완납일")}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </React.Fragment>
                        : 
                            <tr className="h80">
                                <th className="taC">증여자의 {titleSubText} 잔금지급일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                <td className="taC">
                                    <div className="dpFlx flxdRow">
                                        {SurlusPymntdeEl()}
                                        <div className="dpFlx pl25">
                                            {OwnshipTransrDeElCheckBox("소유권이전등기접수일", "증여자의 " + titleSubText + " 잔금지급일")}
                                        </div>
                                    </div>
                                </td>
                            </tr>                        
                        }

                        <tr className={"h80 " + (ownshipTransrDeDsb ? "dpNone" : "")}>
                            <th className="taC">소유권이전등기접수일</th>
                            <td>{OwnshipTransrDeEl()}</td>
                        </tr>
                        <tr className="h80">
                            <th className="taC">사용승인일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                            <td>{UseConfmDeEl()}</td>
                        </tr>
                        <tr className="h80">
                            <th className="taC">취득당시 기준시가(원)<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                            <td>{StdrMktcEl()}</td>
                        </tr>
                        <tr className="h80">
                            <th className="taC">
                                <div className="dpFlx aiC jcC">
                                    {["1002", "1003", "1006"].indexOf(acqsSrc) !== -1 ?
                                        <React.Fragment>
                                            <img
                                                src={require('img/common/icon_info_fill_20.png')}
                                                alt="icon_info_fill_20"
                                                className='csP mr3'
                                                data-tooltip-id="AssetsAcqsRlDlpcFldNm10031004"
                                                data-tooltip-content="(증여당시 시가인정액+현재까지 지출된 금액)"
                                            />
                                            <ToolTip id="AssetsAcqsRlDlpcFldNm10031004" place='top' clickable={true} />
                                        </React.Fragment>
                                    : <></>}
                                    {getRlDlpcFldNm()}
                                    <span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
                                </div>
                            </th>
                            <td>{RlDlpcEl()}</td>
                        </tr>
                    </tbody>
                </table>
            </React.Fragment>
        )
    }

    // 신규분양, 미분양
    const LttotRightCommonEl = () => {
        return (
            <table className="forest-form-table">
                <colgroup>
                    <col className="w250" />
                    <col />
                </colgroup>
                <tbody>
                    <tr className="h80">
                        <th>분양계약일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                        <td>
                            <div className="dpFlx flxdRow">
                                {CntrctDeEl()}
                                <div className="dpFlx pl25">
                                    {EntyPymntdeElCheckBox("분양권 계약금지급일", "분양계약일")}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr className={"h80 " + (entyPymntdeDsb ? "dpNone" : "")}>
                        <th>분양권 계약금지급일</th>
                        <td>{EntyPymntdeEl()}</td>
                    </tr>
                    <tr className="h80">
                        <th>분양대금완납일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                        <td>
                            <div className="dpFlx flxdRow">
                                {FullPymntdeEl()}
                                <div className="dpFlx pl25">
                                    <ImageCheckBox
                                        value={!ownshipTransrRceptDeDsb}
                                        labelText='소유권이전등기접수일이 분양대금완납일보다 빠를 경우'
                                        btnClass='w20 h20'
                                        labelClass='SpoqaHanSansNeo-Regular fs14 ls07 fc666666 pl5'
                                        onChange={() => setOwnshipTransrRceptDeDsb(!ownshipTransrRceptDeDsb)}
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr className={"h80 " + (ownshipTransrRceptDeDsb ? "dpNone" : "")}>
                        <th>소유권이전등기접수일</th>
                        <td>{OwnshipTransrRceptDeEl()}</td>
                    </tr>
                    <tr className="h80">
                        <th>사용승인일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                        <td>{UseConfmDeEl()}</td>
                    </tr>
                    <tr className="h80">
                        <th>취득당시 기준시가(원)<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                        <td>{StdrMktcEl()}</td>
                    </tr>
                    <tr className="h80">
                        <th>{getRlDlpcFldNm()}<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                        <td>{RlDlpcEl()}</td>
                    </tr>
                </tbody>
            </table>
        )
    }

    // 최종 Return Element
    const FormEl = () => {
        // 주택
        if (estateDetailSe === "1001") {
            if (estateFom === "1001") { // 아파트(연립주택포함)
                // 기존주택취득
                if (acqsSrc === "1001") {
                    if (acqsCause === "1002") { // 매매
                        return (
                            <React.Fragment>{TrdeCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1003") {  // 상속
                        return (
                            <React.Fragment>{InhrtncCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1004") {  // 증여
                        return (
                            <React.Fragment>{DonaCommonEl()}</React.Fragment>
                        )
                    }
                // 분양권에 의한 취득
                } else if (acqsSrc === "1002") {
                    if (acqsCause === "1011" || acqsCause === "1012") { // 신규분양, 미분양
                        return (
                            <React.Fragment>{LttotRightCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1002") {  // 전매
                        return (
                            <React.Fragment>{TrdeCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1003") {  // 상속
                        return (
                            <React.Fragment>{InhrtncCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1004") {  // 증여
                        return (
                            <React.Fragment>{DonaCommonEl()}</React.Fragment>
                        )
                    }
                // 조합원입주권에 의한 취득
                } else if (acqsSrc === "1003") {
                    if (acqsCause === "1001") { // 최초취득(원조합원)
                        return (
                            <table className="forest-form-table">
                                <colgroup>
                                    <col className="w250" />
                                    <col />
                                </colgroup>
                                <tbody>
                                    <tr className="h64">
                                        <th className="taC">종전부동산 유형<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                        <td>{BeforeEstateTyEl()}</td>
                                    </tr>
                                    <tr className="h64">
                                        <th className="taC">종전주택 용도<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                        <td>{BeforeEstatePrposEl()}</td>
                                    </tr>
                                    <tr className="h80">
                                        <th className="taC">종전주택 계약일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                        <td className="taC">
                                            <div className="dpFlx flxdRow">
                                                {CntrctDeEl()}
                                                <div className="dpFlx pl25">{EntyPymntdeElCheckBox("종전주택 계약금지급일", "종전주택 계약일")}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className={"h80 " + (entyPymntdeDsb ? "dpNone" : "")}>
                                        <th className="taC">종전주택 계약금지급일</th>
                                        <td>{EntyPymntdeEl()}</td>
                                    </tr>
                                    <tr className="h80">
                                        <th className="taC">종전주택 잔금지급일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                        <td>
                                            <div className="dpFlx flxdRow">
                                                {SurlusPymntdeEl()}
                                                <div className="dpFlx pl25">
                                                    {RgistRceptDeElCheckBox("종전주택 등기접수일", "종전주택 잔금지급일")}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className={"h80 " + (rgistRceptDeDsb ? "dpNone" : "")}>
                                        <th className="taC">종전주택 등기접수일</th>
                                        <td className="taC">{RgistRceptDeEl()}</td>
                                    </tr>
                                    <tr className="h80">
                                        <th className="taC posR">{ManageDspsPlanDeTh()}</th>
                                        <td>{ManageDspsPlanDeEl()}</td>
                                    </tr>
                                    <tr className="h80">
                                        <th className="taC posR">사업시행인가일</th>
                                        <td>{BsnsImpCnfmDeEl()}</td>
                                    </tr>
                                    <tr className="h80">
                                        <th className="taC">사용승인일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                        <td>{UseConfmDeEl()}</td>
                                    </tr>
                                    <tr className="h80">
                                        <th className="taC">종전부동산 평가액<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                        <td>{EvlamtEl()}</td>
                                    </tr>
                                    <tr className="h80">
                                        <th className="taC">취득당시 기준시가(원)<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                        <td>{StdrMktcEl()}</td>
                                    </tr>
                                    <tr className="h80">
                                        <th className="taC">{getRlDlpcFldNm()}<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                        <td>{RlDlpcEl()}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )
                    } else if (acqsCause === "1002") {  // 전매
                        return (
                            <React.Fragment>{TrdeCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1003") {  // 상속
                        return (
                            <React.Fragment>{InhrtncCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1004") {  // 증여
                        return (
                            <React.Fragment>{DonaCommonEl()}</React.Fragment>
                        )
                    }
                }
            } else if (["1002", "1003", "1004", "1005"].indexOf(estateFom) !== -1) {
                if (acqsSrc === "1001") {
                    if (acqsCause === "1002") { // 매매
                        return (
                            <React.Fragment>{TrdeCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1003") {  // 상속
                        return (
                            <React.Fragment>{InhrtncCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1004") {  // 증여
                        return (
                            <React.Fragment>{DonaCommonEl()}</React.Fragment>
                        )
                    }
                } else if (acqsSrc === "1004") {
                    return (
                        <table className="forest-form-table">
                            <colgroup>
                                <col className="w250" />
                                <col />
                            </colgroup>
                            <tbody>
                                <tr className="h80">
                                    <th className="taC" title="사용승인일">사용승인일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                    <td>
                                        <div className="dpFlx aiC">
                                            {UseConfmDeEl()}
                                            <span className="pl10 fs13 SpoqaHanSansNeo-Regular ls08 fcCrimson">※ 임시사용승인일과 실제사용일 중 빠른날</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="h80">
                                    <th className="taC">취득당시 기준시가(원)<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                    <td>{StdrMktcEl()}</td>
                                </tr>
                                <tr className="h80">
                                    <th className="taC" title={getRlDlpcFldNm()}>{getRlDlpcFldNm()}<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                    <td>{RlDlpcEl()}</td>
                                </tr>
                            </tbody>
                        </table>
                    )
                }
            } else if (estateFom === "1006") {
                if (acqsSrc === "1005") {
                    if (acqsCause === "1002") { // 매매
                        return (
                            <React.Fragment>{TrdeCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1003") {  // 상속
                        return (
                            <React.Fragment>{InhrtncCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1004") {  // 증여
                        return (
                            <React.Fragment>{DonaCommonEl()}</React.Fragment>
                        )
                    }
                } else if (acqsSrc === "1006") {
                    if (acqsCause === "1011" || acqsCause === "1012") { // 신규분양, 미분양
                        return (
                            <React.Fragment>{LttotRightCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1002") {  // 매매
                        return (
                            <React.Fragment>{TrdeCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1003") {  // 상속
                        return (
                            <React.Fragment>{InhrtncCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1004") {  // 증여
                        return (
                            <React.Fragment>{DonaCommonEl()}</React.Fragment>
                        )
                    }
                }
            }
        // 분양권
        } else if (estateDetailSe === "1002") {
            if (acqsCause === "1001") {
                return (
                    <table className="forest-form-table">
                        <colgroup>
                            <col className="w250" />
                            <col />
                        </colgroup>
                        <tbody>
                            <tr className="h80">
                                <th>분양권 계약일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                <td>
                                    <div className="dpFlx flxdRow">
                                        {CntrctDeEl()}
                                        <div className="dpFlx pl25">
                                            {EntyPymntdeElCheckBox("분양권 계약금지급일", "분양권 계약일")}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr className={"h80 " + (entyPymntdeDsb ? "dpNone" : "")}>
                                <th>분양권 계약금지급일</th>
                                <td>{EntyPymntdeEl()}</td>
                            </tr>
                            <tr className="h80">
                                <th className="taC">분양권 당첨일</th>
                                <td><DatePickerBox value={przwinDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setPrzwinDe(Util.dateToString(data, ""))} /></td>
                            </tr>
                            <tr className="h80">
                                <th className="taC">
                                    <div className="dpFlx aiC jcC">
                                        <img
                                            src={require('img/common/icon_info_fill_20.png')}
                                            alt="icon_info_fill_20"
                                            className='csP mr3'
                                            data-tooltip-id="AssetsAcqsRlDlpcFldNm10021001"
                                            data-tooltip-content="현재까지 지출된 금액"
                                        />
                                        <ToolTip id="AssetsAcqsRlDlpcFldNm10021001" place='top' clickable={true} />
                                        {getRlDlpcFldNm()}
                                        <span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
                                    </div>
                                </th>
                                <td>{RlDlpcEl()}</td>
                            </tr>
                        </tbody>
                    </table>
                )
            } else if (acqsCause === "1002") {
                return (
                    <table className="forest-form-table">
                        <colgroup>
                            <col className="w250" />
                            <col />
                        </colgroup>
                        <tbody>
                            <tr className="h80">
                                <th>분양권 계약일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                <td>
                                    <div className="dpFlx flxdRow">
                                        {CntrctDeEl()}
                                        <div className="dpFlx pl25">
                                            {EntyPymntdeElCheckBox("분양권 계약금지급일", "분양권 계약일")}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr className={"h80 " + (entyPymntdeDsb ? "dpNone" : "")}>
                                <th>분양권 계약금지급일</th>
                                <td>{EntyPymntdeEl()}</td>
                            </tr>
                            <tr className="h80">
                                <th className="taC" title='분양권 잔금지급일'>분양권 잔금지급일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                <td>{SurlusPymntdeEl()}</td>
                            </tr>
                            <tr className="h80">
                                <th className="taC">
                                    <div className="dpFlx aiC jcC">
                                        <img
                                            src={require('img/common/icon_info_fill_20.png')}
                                            alt="icon_info_fill_20"
                                            className='csP mr3'
                                            data-tooltip-id="AssetsAcqsRlDlpcFldNm10021002"
                                            data-tooltip-content="현재까지 지출된 금액"
                                        />
                                        <ToolTip id="AssetsAcqsRlDlpcFldNm10021002" place='top' clickable={true} />
                                        {getRlDlpcFldNm()}
                                        <span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
                                    </div>
                                </th>
                                <td>{RlDlpcEl()}</td>
                            </tr>
                        </tbody>
                    </table>
                )
            } else if (acqsCause === "1003") {
                return (
                    <table className="forest-form-table">
                        <colgroup>
                            <col className="w250" />
                            <col />
                        </colgroup>
                        <tbody>
                        <tr className="h64">
                            <th className="taC">특례상속분양권여부<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                            <td>{ExcptInhrtncAtEl()}</td>
                        </tr>
                        <tr className="h64">
                            <th className="taC posR">{InhrtncKnd1Th()}</th>
                            <td>{InhrtncKnd1El()}</td>
                        </tr>
                        <tr className="h64">
                            <th className="taC">상속의 종류<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                            <td>{InhrtncKnd2El()}</td>
                        </tr>
                        <tr className={"h64 " + (inhrtncKnd3TrVsb ? "" : "dpNone")}>
                            <th className="taC posR">{InhrtncKnd3Th()}</th>
                            <td>{InhrtncKnd3El()}</td>
                        </tr>
                        <tr className="h80">
                            <th className="taC">상속개시일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                            <td>{BeginDeEl()}</td>
                        </tr>
                        <tr className="h80">
                            <th className="taC">피상속인의 분양권 계약일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                            <td>
                                <div className="dpFlx flxdRow">
                                    {CntrctDeEl()}
                                    <div className="dpFlx pl25">
                                        {EntyPymntdeElCheckBox(
                                            "피상속인의 분양권 계약금지급일",
                                            "피상속인의 분양권 계약일"
                                        )}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr className={"h80 " + (entyPymntdeDsb ? "dpNone" : "")}>
                            <th className="taC">피상속인의 분양권 계약금지급일</th>
                            <td>{EntyPymntdeEl()}</td>
                        </tr>
                        <tr className="h80">
                            <th className="taC">피상속인의 분양권 잔금지급일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                            <td>{SurlusPymntdeEl()}</td>
                        </tr>
                        <tr className="h80">
                            <th className="taC">
                                <div className="dpFlx aiC jcC">
                                    <img
                                        src={require('img/common/icon_info_fill_20.png')}
                                        alt="icon_info_fill_20"
                                        className='csP mr3'
                                        data-tooltip-id="AssetsAcqsRlDlpcFldNm10021003"
                                        data-tooltip-content="(상속세평가액+현재까지 지출된 금액)"
                                    />
                                    <ToolTip id="AssetsAcqsRlDlpcFldNm10021003" place='top' clickable={true} />
                                    {getRlDlpcFldNm()}
                                    <span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
                                </div>
                            </th>
                            <td>{RlDlpcEl()}</td>
                        </tr>
                        </tbody>
                    </table>
                )
            } else if (acqsCause === "1004") {
                return (
                    <React.Fragment>
                        <table className="forest-form-table">
                            <colgroup>
                                <col className="w250" />
                                <col />
                            </colgroup>
                            <tbody>
                                <tr className="h64">
                                    <th className="taC">{DonaKndTh()}</th>
                                    <td>{DonaKndEl()}</td>
                                </tr>
                                <tr className="h64">
                                    <th className="taC">증여자와의 관계<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                    <td>{DonaKnd2El()}</td>
                                </tr>
                                <tr className="h80">
                                    <th className="taC">증여계약일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                    <td className="taC">
                                        <div className="dpFlx flxdRow">
                                            {DonaCntrctDeEl()}
                                            <div className="dpFlx pl25">
                                                {RightSuccsDeElCheckBox()}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className={"h80 " + (rightSuccsDeDsb ? "dpNone" : "")}>
                                    <th className="taC">권리승계일</th>
                                    <td className="taC">{RightSuccsDeEl()}</td>
                                </tr>
                                <tr className="h80">
                                    <th className="taC">증여자의 분양권 계약일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                    <td className="taC">
                                        <div className="dpFlx flxdRow">
                                            {CntrctDeEl()}
                                            <div className="dpFlx pl25">{EntyPymntdeElCheckBox("증여자의 분양권 계약금지급일", "증여자의 분양권 계약일")}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className={"h80 " + (entyPymntdeDsb ? "dpNone" : "")}>
                                    <th className="taC">증여자의 분양권 계약금지급일</th>
                                    <td>{EntyPymntdeEl()}</td>
                                </tr>
                                <tr className="h80">
                                    <th className="taC">증여자의 분양권 잔금지급일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                    <td className="taC">{SurlusPymntdeEl()}</td>
                                </tr>
                                <tr className="h80">
                                    <th className="taC">
                                        <div className="dpFlx aiC jcC">
                                            <img
                                                src={require('img/common/icon_info_fill_20.png')}
                                                alt="icon_info_fill_20"
                                                className='csP mr3'
                                                data-tooltip-id="AssetsAcqsRlDlpcFldNm10021004"
                                                data-tooltip-content="(증여당시 시가인정액+현재까지 지출된 금액)"
                                            />
                                            <ToolTip id="AssetsAcqsRlDlpcFldNm10021004" place='top' clickable={true} />
                                            {getRlDlpcFldNm()}
                                            <span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
                                        </div>
                                    </th>
                                    <td>{RlDlpcEl()}</td>
                                </tr>
                            </tbody>
                        </table>
                    </React.Fragment>
                )
            }
        // 조합원 입주권
        } else if (estateDetailSe === "1003") {
            if (acqsCause === "1001") { // 원조합원
                return (
                    <table className="forest-form-table">
                        <colgroup>
                            <col className="w250" />
                            <col />
                        </colgroup>
                        <tbody>
                            <tr className="h64">
                                <th className="taC">종전부동산의 유형<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                <td>{BeforeEstateTyEl()}</td>
                            </tr>
                            <tr className="h64">
                                <th className="taC">종전주택 용도<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                <td>{BeforeEstatePrposEl()}</td>
                            </tr>
                            <tr className="h80">
                                <th className="taC">종전주택 계약일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                <td className="taC">
                                    <div className="dpFlx flxdRow">
                                        {CntrctDeEl()}
                                        <div className="dpFlx pl25">{EntyPymntdeElCheckBox("종전주택 계약금지급일", "종전주택 계약일")}</div>
                                    </div>
                                </td>
                            </tr>
                            <tr className={"h80 " + (entyPymntdeDsb ? "dpNone" : "")}>
                                <th className="taC">종전주택 계약금지급일</th>
                                <td>{EntyPymntdeEl()}</td>
                            </tr>
                            <tr className="h80">
                                <th className="taC">종전주택 잔금지급일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                <td>
                                    <div className="dpFlx flxdRow">
                                        {SurlusPymntdeEl()}
                                        <div className="dpFlx pl25">
                                            {RgistRceptDeElCheckBox("종전주택 등기접수일", "종전주택 잔금지급일")}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr className={"h80 " + (rgistRceptDeDsb ? "dpNone" : "")}>
                                <th className="taC">종전주택 등기접수일</th>
                                <td className="taC">{RgistRceptDeEl()}</td>
                            </tr>
                            <tr className="h80">
                                <th className="taC posR">{ManageDspsPlanDeTh()}</th>
                                <td>{ManageDspsPlanDeEl()}</td>
                            </tr>
                            <tr className="h80">
                                <th className="taC posR">사업시행인가일</th>
                                <td>{BsnsImpCnfmDeEl()}</td>
                            </tr>
                            <tr className="h80">
                                <th className="taC">종전부동산 평가액<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                <td>{EvlamtEl()}</td>
                            </tr>
                            <tr className="h80">
                                <th className="taC">
                                    <div className="dpFlx aiC jcC">
                                        <img
                                            src={require('img/common/icon_info_fill_20.png')}
                                            alt="icon_info_fill_20"
                                            className='csP mr3'
                                            data-tooltip-id="AssetsAcqsRlDlpcFldNm10031001"
                                            data-tooltip-content="현재까지 지출된 금액"
                                        />
                                        <ToolTip id="AssetsAcqsRlDlpcFldNm10031001" place='top' clickable={true} />
                                        {getRlDlpcFldNm()}
                                        <span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
                                    </div>
                                </th>
                                <td>{RlDlpcEl()}</td>
                            </tr>
                        </tbody>
                    </table>
                )
            } else if (acqsCause === "1002") {  // 전매
                return (
                    <table className="forest-form-table">
                        <colgroup>
                            <col className="w250" />
                            <col />
                        </colgroup>
                        <tbody>
                            <tr className="h80">
                                <th>조합원입주권 계약일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                <td>
                                    <div className="dpFlx flxdRow">
                                        {CntrctDeEl()}
                                        <div className="dpFlx pl25">
                                            {EntyPymntdeElCheckBox("조합원입주권 계약금지급일", "조합원입주권 계약일")}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr className={"h80 " + (entyPymntdeDsb ? "dpNone" : "")}>
                                <th>조합원입주권 계약금지급일</th>
                                <td>{EntyPymntdeEl()}</td>
                            </tr>
                            <tr className="h80">
                                <th className="taC">조합원입주권 잔금지급일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                <td>{SurlusPymntdeEl()}</td>
                            </tr>
                            <tr className="h80">
                                <th className="taC">
                                    <div className="dpFlx aiC jcC">
                                        <img
                                            src={require('img/common/icon_info_fill_20.png')}
                                            alt="icon_info_fill_20"
                                            className='csP mr3'
                                            data-tooltip-id="AssetsAcqsRlDlpcFldNm10031001"
                                            data-tooltip-content="현재까지 지출된 금액"
                                        />
                                        <ToolTip id="AssetsAcqsRlDlpcFldNm10031001" place='top' clickable={true} />
                                        {getRlDlpcFldNm()}
                                        <span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
                                    </div>
                                </th>
                                <td>{RlDlpcEl()}</td>
                            </tr>
                        </tbody>
                    </table>
                )
            } else if (acqsCause === "1003") {  // 상속
                return (
                    <table className="forest-form-table">
                        <colgroup>
                            <col className="w250" />
                            <col />
                        </colgroup>
                        <tbody>
                            <tr className="h64">
                                <th className="taC">특례상속 조합원입주권 여부<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                <td>{ExcptInhrtncAtEl()}</td>
                            </tr>
                            <tr className="h64">
                                <th className="taC posR">{InhrtncKnd1Th()}</th>
                                <td>{InhrtncKnd1El()}</td>
                            </tr>
                            <tr className="h64">
                                <th className="taC">상속의 종류<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                <td>{InhrtncKnd2El()}</td>
                            </tr>
                            <tr className={"h64 " + (inhrtncKnd3TrVsb ? "" : "dpNone")}>
                                <th className="taC posR">{InhrtncKnd3Th()}</th>
                                <td>{InhrtncKnd3El()}</td>
                            </tr>
                            <tr className="h80">
                                <th className="taC" title='상속개시일'>상속개시일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                <td>{BeginDeEl()}</td>
                            </tr>
                            <tr className="h80">
                                <th>피상속인 조합원입주권 계약일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                <td>
                                    <div className="dpFlx flxdRow">
                                        {CntrctDeEl()}
                                        <div className="dpFlx pl25">
                                            {EntyPymntdeElCheckBox("피상속인의 조합원입주권 계약금지급일", "피상속인 조합원입주권 계약일")}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr className={"h80 " + (entyPymntdeDsb ? "dpNone" : "")}>
                                <th><span className="fs13">피상속인 조합원입주권 계약금지급일</span></th>
                                <td>{EntyPymntdeEl()}</td>
                            </tr>
                            <tr className="h80">
                                <th className="taC">
                                    <span className="fs13">피상속인 조합원입주권 잔금지급일</span>
                                    <span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
                                </th>
                                <td>{SurlusPymntdeEl()}</td>
                            </tr>
                            <tr className="h80">
                                <th className="taC posR">{ManageDspsPlanDeTh()}</th>
                                <td>{ManageDspsPlanDeEl()}</td>
                            </tr>
                            <tr className="h80">
                                <th>사업시행인가일</th>
                                <td>{BsnsImpCnfmDeEl()}</td>
                            </tr>
                            <tr className="h80">
                                <th className="taC">
                                    <div className="dpFlx aiC jcC">
                                        <img
                                            src={require('img/common/icon_info_fill_20.png')}
                                            alt="icon_info_fill_20"
                                            className='csP mr3'
                                            data-tooltip-id="AssetsAcqsRlDlpcFldNm10031001"
                                            data-tooltip-content="(상속세평가액+현재까지 지출된 금액)"
                                        />
                                        <ToolTip id="AssetsAcqsRlDlpcFldNm10031001" place='top' clickable={true} />
                                        {getRlDlpcFldNm()}
                                        <span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
                                    </div>
                                </th>
                                <td>{RlDlpcEl()}</td>
                            </tr>
                        </tbody>
                    </table>
                )
            } else if (acqsCause === "1004") {  // 증여
                return (
                    <React.Fragment>
                        <table className="forest-form-table">
                            <colgroup>
                                <col className="w250" />
                                <col />
                            </colgroup>
                            <tbody>
                                <tr className="h64">
                                    <th className="taC">{DonaKndTh()}</th>
                                    <td>{DonaKndEl()}</td>
                                </tr>
                                <tr className="h64">
                                    <th className="taC">증여자와의 관계<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                    <td>{DonaKnd2El()}</td>
                                </tr>
                                <tr className="h80">
                                    <th className="taC">증여계약일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                    <td className="taC">
                                        <div className="dpFlx flxdRow">
                                            {DonaCntrctDeEl()}
                                            <div className="dpFlx pl25">
                                                {RightSuccsDeElCheckBox()}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className={"h80 " + (rightSuccsDeDsb ? "dpNone" : "")}>
                                    <th className="taC">권리승계일</th>
                                    <td className="taC">{RightSuccsDeEl()}</td>
                                </tr>
                                <tr className="h80">
                                    <th className="taC">증여자의 조합원입주권 계약일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                    <td className="taC">
                                        <div className="dpFlx flxdRow">
                                            {CntrctDeEl()}
                                            <div className="dpFlx pl25">{EntyPymntdeElCheckBox("증여자의 조합원입주권 계약금지급일", "증여자의 조합원입주권 계약일")}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr className={"h80 " + (entyPymntdeDsb ? "dpNone" : "")}>
                                    <th className="taC"><span className="fs13">증여자의 조합원입주권 계약금지급일</span></th>
                                    <td>{EntyPymntdeEl()}</td>
                                </tr>
                                <tr className="h80">
                                    <th className="taC"><span className="fs13">증여자의 조합원입주권 잔금지급일</span><span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                    <td className="taC">{SurlusPymntdeEl()}</td>
                                </tr>
                                <tr className="h80">
                                    <th className="taC posR">관리처분계획인가일</th>
                                    <td><DatePickerBox value={manageDspsCnfmDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setManageDspsCnfmDe(Util.dateToString(data, ""))} /></td>
                                </tr>
                                <tr className="h80">
                                    <th className="taC posR">사업시행인가일</th>
                                    <td>{BsnsImpCnfmDeEl()}</td>
                                </tr>
                                <tr className="h80">
                                    <th className="taC">
                                        <div className="dpFlx aiC jcC">
                                            <img
                                                src={require('img/common/icon_info_fill_20.png')}
                                                alt="icon_info_fill_20"
                                                className='csP mr3'
                                                data-tooltip-id="AssetsAcqsRlDlpcFldNm10031004"
                                                data-tooltip-content="(증여당시 시가인정액+현재까지 지출된 금액)"
                                            />
                                            <ToolTip id="AssetsAcqsRlDlpcFldNm10031004" place='top' clickable={true} />
                                            {getRlDlpcFldNm()}
                                            <span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
                                        </div>
                                    </th>
                                    <td>{RlDlpcEl()}</td>
                                </tr>
                            </tbody>
                        </table>
                    </React.Fragment>
                )
            }
        }
        return "";
    }
    return (
        <React.Fragment>
            <AssetsEditTab
                wizard={props.wizard}
                assetsSn={props.form.assetsSn}
                estateSttus={props.form.estateSttus}
                estateDetailSe={estateDetailSe}
                acqsCause={acqsCause}
                acqsSrc={acqsSrc}
                currStepCode={1002}
            />
            <div className='w1210 mh500'>
                <AssetsTitle propsForm={props.form} asterlink={true} />
                {FormEl()}
                <AssetsButton propsForm={props.form} goNextStep={goNextStep} goPrevStep={goPrevStep} />
            </div>
            {/* <AssetsButtonBar propsForm={props.form} goNextStep={goNextStep} goPrevStep={goPrevStep} /> */}
            <Modal modalOpen={manageDspsPlanDeModalOpen} setModalOpen={setManageDspsPlanDeModalOpen} title="관리처분계획인가일" dim={true} visible={true}>
                <MgshrPlanDe setModalOpen={setManageDspsPlanDeModalOpen} />
            </Modal>

            {/* 기준시가 수동조회 화면 안내 */}
            <Modal modalOpen={manualCheckGuideModalOpen} setModalOpen={setManualCheckGuideModalOpen} title="기준시가 수동조회 화면 안내" dim={true} visible={true}>
                <ManualCheckGuide setModalOpen={setManualCheckGuideModalOpen} />
            </Modal>
        </React.Fragment>
    );
};
export default AssetsAcq;