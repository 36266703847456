import { useState } from "react";
import styled from "styled-components";

import { TranTaxCalcResult } from "js/common/types/types";
import Util from "js/common/Util";

type Props = {
  calcResult: TranTaxCalcResult | null;
  shareRatio: string;
  resultType: string;
};

function CalcResult({ calcResult, shareRatio, resultType }: Props) {
  const [detailResultOpen, setDetailResultOpen] = useState<boolean>(true);

  const onClickOpen = () => {
    if (detailResultOpen) {
      setDetailResultOpen(false);
    } else {
      setDetailResultOpen(true);
    }
  };

  if (resultType === "A") {
    return (
      <>
        <TotalTaxContainer>
          <p className="title">
            양도세액합계
            <span className="highlight"></span>
          </p>
          <div className="tax">
            <span className="number">
              {calcResult
                ? Util.comma(calcResult.total_transfer_tax_amt ?? "")
                : ""}
            </span>
            <span className="unit">원</span>
          </div>
        </TotalTaxContainer>
        <DetailTaxContainer>
          <div className="title">
            <p>세부 항목 확인하기</p>
            <button
              className={`${detailResultOpen ? "open" : "closed"}`}
              onClick={onClickOpen}
            >
              <img
                src={require("img/common/icon_arrow_up_gray.png")}
                alt="logo"
              />
            </button>
          </div>
          <TaxInfoContainer>
            <div className="taxWrap">
              <div className="tax">
                <p className="name">과세 표준</p>
                <div className="value">
                  <div>
                    <span className="number">
                      {calcResult
                        ? Util.comma(calcResult.transfer_tax_base ?? "")
                        : ""}
                    </span>
                    <span className="unit">원</span>
                  </div>
                </div>
              </div>
            </div>
            <OpenAndCloseContainer>
              <div
                className={`detail-container ${
                  detailResultOpen ? "open" : "closed"
                }`}
              >
                <div className="detailTaxWrap">
                  <p className="name">양도차익</p>
                  <div className="value">
                    <div>
                      <span className="number">
                        {calcResult
                          ? Util.comma(calcResult.transfer_profit ?? "")
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
                <div className="moreDetailTaxWrap">
                  <div className="title">
                    <img src={require("img/common/icon_more.png")} alt="logo" />
                    <p className="name">양도가액</p>
                  </div>
                  <div className="value">
                    <div>
                      <span className="number">
                        {calcResult
                          ? Util.comma(calcResult.transfer_price ?? "")
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
                <div className="moreDetailTaxWrap">
                  <div className="title">
                    <img src={require("img/common/icon_more.png")} alt="logo" />
                    <p className="name">(-)취득가액</p>
                  </div>
                  <div className="value">
                    <div>
                      <span className="number blue">
                        {calcResult
                          ? Util.comma(calcResult.acqu_price ?? "")
                          : ""}
                      </span>
                      <span className="unit blue">원</span>
                    </div>
                  </div>
                </div>
                <div className="moreDetailTaxWrap">
                  <div className="title">
                    <img src={require("img/common/icon_more.png")} alt="logo" />
                    <p className="name">(-)필요경비</p>
                  </div>
                  <div className="value">
                    <div>
                      <span className="number blue">
                        {calcResult
                          ? Util.comma(calcResult.nece_expen_new_hse ?? "")
                          : ""}
                      </span>
                      <span className="unit blue">원</span>
                    </div>
                  </div>
                </div>
                <div className="detailTaxWrap">
                  <p className="name">(-)장기보유특별공제</p>
                  <div className="value">
                    <div>
                      <span className="number blue">
                        {calcResult
                          ? Util.comma(calcResult.long_term_special_deduc ?? "")
                          : ""}
                      </span>
                      <span className="unit blue">원</span>
                    </div>
                  </div>
                </div>
                <div className="detailTaxWrap">
                  <p className="name">양도소득금액(*지분율)</p>
                  <div className="value">
                    <div>
                      <span className="number">
                        {calcResult
                          ? Util.comma(calcResult.transfer_income ?? "")
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
                <div className="moreDetailTaxWrap">
                  <div className="title">
                    <img src={require("img/common/icon_more.png")} alt="logo" />
                    <p className="name">지분율</p>
                  </div>
                  <div className="value">
                    <div>
                      <span className="number">{shareRatio}</span>
                      <span className="unit">%</span>
                    </div>
                  </div>
                </div>
                <div className="detailTaxWrap">
                  <p className="name">(-)양도소득기본공제</p>
                  <div className="value">
                    <div>
                      <span className="number blue">
                        {calcResult
                          ? Util.comma(calcResult.transfer_basic_deduc ?? "")
                          : ""}
                      </span>
                      <span className="unit blue">원</span>
                    </div>
                  </div>
                </div>
              </div>
            </OpenAndCloseContainer>
            <div className="taxWrap">
              <div className="tax">
                <p className="name">(*)세율</p>
                <div className="value">
                  <div>
                    <span className="number">
                      {calcResult
                        ? Util.comma(calcResult.transfer_rate ?? "")
                        : ""}
                    </span>
                    <span className="unit">%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="taxWrap">
              <div className="tax">
                <p className="name">산출세액</p>
                <div className="value">
                  <div>
                    <span className="number">
                      {calcResult
                        ? Util.comma(calcResult.transfer_tax_amt ?? "")
                        : ""}
                    </span>
                    <span className="unit">원</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="taxWrap">
              <div className="tax ">
                <p className="name">자진납부세액</p>
                <div className="value">
                  <div>
                    <span className="number">
                      {calcResult
                        ? Util.comma(calcResult.voluntary_tax_amt ?? "")
                        : ""}
                    </span>
                    <span className="unit">원</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="taxWrap">
              <div className="tax">
                <p className="name">(+)지방소득세</p>
                <div className="value">
                  <div>
                    <span className="number red">
                      {calcResult
                        ? Util.comma(calcResult.local_income_tax ?? "")
                        : ""}
                    </span>
                    <span className="unit red">원</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="taxWrap">
              <div className="tax">
                <p className="name">합계</p>
                <div className="value">
                  <div>
                    <span className="number">
                      {calcResult
                        ? Util.comma(calcResult.total_transfer_tax_amt ?? "")
                        : ""}
                    </span>
                    <span className="unit">원</span>
                  </div>
                </div>
              </div>
            </div>
          </TaxInfoContainer>
        </DetailTaxContainer>
      </>
    );
  } else {
    return (
      <>
        <TotalTaxContainer>
          <p className="title">
            양도세액합계
            <span className="highlight"></span>
          </p>
          <div className="tax">
            <span className="number">
              {calcResult
                ? Util.comma(calcResult.total_transfer_tax_amt ?? "")
                : ""}
            </span>
            <span className="unit">원</span>
          </div>
        </TotalTaxContainer>
        <DetailTaxContainer>
          <div className="title">
            <p>세부 항목 확인하기</p>
            <button
              className={`${detailResultOpen ? "open" : "closed"}`}
              onClick={onClickOpen}
            >
              <img
                src={require("img/common/icon_arrow_up_gray.png")}
                alt="logo"
              />
            </button>
          </div>
          <TaxInfoContainer>
            <div className="taxWrap">
              <div className="tax">
                <p className="name">과세 표준</p>
                <div className="value">
                  <div>
                    <span className="number">
                      {calcResult
                        ? Util.comma(calcResult.transfer_tax_base ?? "")
                        : ""}
                    </span>
                    <span className="unit">원</span>
                  </div>
                </div>
              </div>
            </div>
            <OpenAndCloseContainer>
              <div
                className={`detail-container ${
                  detailResultOpen ? "open" : "closed"
                }`}
              >
                <div className="detailTaxWrap">
                  <p className="name">양도가액</p>
                  <div className="value">
                    <div>
                      <span className="number">
                        {calcResult
                          ? Util.comma(calcResult.transfer_price ?? "")
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
                <div className="detailTaxWrap">
                  <p className="name">취득가액</p>
                  <div className="value">
                    <div>
                      <span className="number">
                        {calcResult
                          ? Util.comma(calcResult.acqu_price ?? "")
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
                <FormerHouseInfoContainer>
                  <div className="labelWrap">
                    <p className="label"></p>
                    <p className="label">(-)필요경비</p>
                    <p className="label">양도차익</p>
                    <p className="label">(-)장특</p>
                    <p className="label">양도소득금액</p>
                  </div>
                  <div className="valueGroupWrap">
                    <p className="groupName">종전주택</p>
                    <div className="value blue">
                      <span className="number">
                        {calcResult
                          ? Util.comma(calcResult.nece_expen_pre_hse ?? "")
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                    <div className="value">
                      <span className="number">
                        {calcResult
                          ? Util.comma(
                              calcResult.transfer_profit_pre_house ?? ""
                            )
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                    <div className="value blue">
                      <span className="number">
                        {calcResult
                          ? Util.comma(
                              calcResult.long_term_special_deduc_pre_house ?? ""
                            )
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                    <div className="value">
                      <span className="number">
                        {calcResult
                          ? Util.comma(
                              calcResult.transfer_income_pre_house ?? ""
                            )
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                  <div className="valueGroupWrap">
                    <p className="groupName">{calcResult?.rtn_title}</p>
                    <div className="value blue">
                      <span className="number">
                        {calcResult
                          ? Util.comma(calcResult.nece_expen_new_hse ?? "")
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                    <div className="value">
                      <span className="number">
                        {calcResult
                          ? Util.comma(
                              calcResult.transfer_profit_new_house ?? ""
                            )
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                    <div className="value blue">
                      <span className="number">
                        {calcResult
                          ? Util.comma(
                              calcResult.long_term_special_deduc_new_house ?? ""
                            )
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                    <div className="value">
                      <span className="number">
                        {calcResult
                          ? Util.comma(
                              calcResult.transfer_income_new_house ?? ""
                            )
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </FormerHouseInfoContainer>
                <div className="moreDetailTaxWrap tableContainer">
                  <div className="title">
                    <img src={require("img/common/icon_more.png")} alt="logo" />
                    <p className="name">지분율</p>
                  </div>
                  <div className="value">
                    <div>
                      <span className="number">{shareRatio}</span>
                      <span className="unit">%</span>
                    </div>
                  </div>
                </div>
                <div className="detailTaxWrap">
                  <p className="name">(-)양도소득기본공제</p>
                  <div className="value">
                    <div>
                      <span className="number blue">
                        {calcResult
                          ? Util.comma(calcResult.transfer_basic_deduc ?? "")
                          : ""}
                      </span>
                      <span className="unit blue">원</span>
                    </div>
                  </div>
                </div>
              </div>
            </OpenAndCloseContainer>
            <div className="taxWrap">
              <div className="tax">
                <p className="name">(*)세율</p>
                <div className="value">
                  <div>
                    <span className="number">
                      {calcResult
                        ? Util.comma(calcResult.transfer_rate ?? "")
                        : ""}
                    </span>
                    <span className="unit">%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="taxWrap">
              <div className="tax">
                <p className="name">산출세액</p>
                <div className="value">
                  <div>
                    <span className="number">
                      {calcResult
                        ? Util.comma(calcResult.transfer_tax_amt ?? "")
                        : ""}
                    </span>
                    <span className="unit">원</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="taxWrap">
              <div className="tax">
                <p className="name">자진납부세액</p>
                <div className="value">
                  <div>
                    <span className="number">
                      {calcResult
                        ? Util.comma(calcResult.voluntary_tax_amt ?? "")
                        : ""}
                    </span>
                    <span className="unit">원</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="taxWrap">
              <div className="tax">
                <p className="name">(+)지방소득세</p>
                <div className="value">
                  <div>
                    <span className="number red">
                      {calcResult
                        ? Util.comma(calcResult.local_income_tax ?? "")
                        : ""}
                    </span>
                    <span className="unit red">원</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="taxWrap">
              <div className="tax">
                <p className="name">합계</p>
                <div className="value">
                  <div>
                    <span className="number">
                      {calcResult
                        ? Util.comma(calcResult.total_transfer_tax_amt ?? "")
                        : ""}
                    </span>
                    <span className="unit">원</span>
                  </div>
                </div>
              </div>
            </div>
          </TaxInfoContainer>
        </DetailTaxContainer>
      </>
    );
  }
}

const TotalTaxContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  width: 100%;
  height: auto;
  padding: 38px 20px;
  background-color: white;
  border-radius: 12px;

  .title {
    position: relative;
    display: inline-block;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.4em;
    line-height: 1.32;
    letter-spacing: -0.05em;
    color: ${(props) => props.theme.color.sNavy};
    z-index: 2;

    .highlight {
      position: absolute;
      top: 3px;
      left: 6px;
      z-index: -1;

      width: 100%;
      height: 100%;
      background-color: ${(props) => props.theme.color.pGreenLight04};
      border-radius: 2px;
    }
  }
  .tax {
    .number {
      font-family: "Spoqa Han Sans Neo";
      font-weight: 500;
      font-size: 2.4em;
      line-height: 1;
      letter-spacing: -0.02em;
    }

    .unit {
      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;

      margin-left: 3px;
    }
  }

  .icon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;

    width: 112.71px;
    height: 112.71px;

    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const DetailTaxContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 24px 20px;
  background-color: white;
  border-radius: 12px;
  margin-top: 10px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 500;
    font-size: 1.4em;
    line-height: 1.32;
    letter-spacing: -0.05em;
    color: ${(props) => props.theme.color.sNavy};

    button {
      width: 24px;
      height: 24px;
      color: ${(props) => props.theme.color.nGray60};

      display: flex;
      justify-content: center;
      align-items: center;

      transition: transform 0.3s ease;

      img {
        width: 13px;
        height: auto;
      }
    }

    button.closed {
      transform: rotate(180deg);
    }
  }
`;

const TaxInfoContainer = styled.div`
  width: 100%;
  height: auto;
  margin-top: 20px;

  .taxWrap {
    padding: 8px 0;
    border-bottom: 1px solid ${(props) => props.theme.color.nGray80};

    .tax {
      position: relative;

      display: flex;
      justify-content: space-between;
      align-items: center;

      background-color: ${(props) => props.theme.color.pGreenLight04};

      &.closed {
        background-color: white;
      }

      .name {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 500;
        font-size: 1.4em;
        line-height: 1.32;
        letter-spacing: -0.05em;
        color: ${(props) => props.theme.color.sNavy};
      }

      .value {
        flex-basis: 60%;

        display: flex;
        justify-content: end;

        .rate {
          font-family: "Spoqa Han Sans Neo";
          font-weight: 600;
          font-size: 1.6em;
          line-height: 1;
          letter-spacing: -0.03125em;
          color: ${(props) => props.theme.color.sNavyDark01};
        }

        .number {
          font-family: "Spoqa Han Sans Neo";
          font-weight: 600;
          font-size: 1.6em;
          line-height: 1;
          letter-spacing: -0.03125em;
          color: ${(props) => props.theme.color.pGreenDark01};

          margin-left: 15px;
        }

        .unit {
          font-family: "Spoqa Han Sans Neo";
          font-weight: 400;
          font-size: 1.2em;
          line-height: 1;
          letter-spacing: -0.058em;
          color: #727b8e;
          margin-left: 2px;
        }
      }

      .highlight {
        position: absolute;
        top: 0px;
        left: -3px;
        z-index: 0;

        width: calc(100% + 6px);
        height: 100%;
        background-color: ${(props) => props.theme.color.pGreenLight04};
        border-radius: 2px;
      }
    }

    .red {
      color: ${(props) => props.theme.color.sysRed100} !important;
    }
  }

  .detailWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px 0 10px 5px;

    .name {
      display: flex;
      align-items: center;

      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.nGray50};

      .icon {
        width: 16px;
        height: 16px;
        margin-right: 2px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .value {
      .number {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 600;
        font-size: 1.6em;
        line-height: 1;
        letter-spacing: -0.03125em;
        color: ${(props) => props.theme.color.nGray30};
      }

      .unit {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.2em;
        line-height: 1;
        letter-spacing: -0.058em;
        color: #727b8e;
        margin-left: 2px;
      }
    }
  }

  .detailTaxWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 8px 0;
    border-bottom: 1px solid ${(props) => props.theme.color.nGray80};

    .name {
      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.nGray50};
    }

    .value {
      .number {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 600;
        font-size: 1.6em;
        line-height: 1;
        letter-spacing: -0.03125em;
        color: ${(props) => props.theme.color.nGray30};
      }

      .unit {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.2em;
        line-height: 1;
        letter-spacing: -0.058em;
        color: #727b8e;
        margin-left: 2px;
      }

      .blue {
        color: ${(props) => props.theme.color.sysBlue100} !important;
      }
    }
  }

  .moreDetailTaxWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 39px;

    padding: 8px 0;

    &.tableContainer {
      height: auto;
      padding: 0 0 8px 8px;
      border-bottom: 1px solid ${(props) => props.theme.color.nGray80};
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;

      img {
        width: 16px;
        height: auto;
      }
    }

    .value {
      .number {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 600;
        font-size: 1.6em;
        line-height: 1;
        letter-spacing: -0.03125em;
        color: ${(props) => props.theme.color.nGray30};
      }

      .unit {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.2em;
        line-height: 1;
        letter-spacing: -0.058em;
        color: #727b8e;
        margin-left: 2px;
      }

      .blue {
        color: ${(props) => props.theme.color.sysBlue100} !important;
      }
    }
  }
`;

const OpenAndCloseContainer = styled.div`
  .detail-container {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }

  .detail-container.open {
    max-height: 500px; /* 충분히 큰 값으로 설정 */
  }
`;

const FormerHouseInfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  --row-height: 30px;

  .labelWrap {
    flex: 0 0 74px;
    width: 74px;

    .label {
      width: 100%;
      height: var(--row-height);

      text-align: left;
      vertical-align: middle;
      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.4em;
      line-height: var(--row-height);

      letter-spacing: -0.058em;
      color: ${(props) => props.theme.color.nGray50};
    }
  }

  .valueGroupWrap {
    padding: 10px 6px;
    background-color: ${(props) => props.theme.color.nGray95};
    border-radius: 6px;

    .groupName {
      display: flex;
      justify-content: center;
      align-items: center;

      margin-bottom: 6px;

      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.4em;
      line-height: 1;
      letter-spacing: -0.058em;
      color: ${(props) => props.theme.color.nGray50};
    }

    .value {
      width: 100%;
      height: var(--row-height);
      white-space: nowrap;
      min-width: calc(100% - 12px);
      overflow: hidden;

      text-align: right;
      vertical-align: middle;

      color: ${(props) => props.theme.color.nGray30};

      .number {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 600;
        font-size: 1.6em;
        line-height: var(--row-height);
        letter-spacing: -0.03125em;
      }

      .unit {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.2em;
        line-height: var(--row-height);
        letter-spacing: -0.058em;
        margin-left: 2px;
      }

      &.blue {
        color: ${(props) => props.theme.color.sysBlue100};
      }
    }
  }
`;

export default CalcResult;
