/*******************************************************************************
*** DeduInfo ** 모바일 상속세 공제정보 입력 UI
*******************************************************************************/
import { useState } from "react";
import Util   from "js/common/Util";
import styled from "styled-components";
import { InheTaxAction } from "./inheTaxStatesReducer";
import { NumericFormat } from "react-number-format";
import SelectBox, { SelectOption } from "../common/SelectBox";
import NavigateNextOutlinedIcon    from "@mui/icons-material/NavigateNextOutlined";
import { InputContainer, MainSectionContainer, NumberInputContainer } from "../common/layout";
import { Code, InheTaxParamProps, InheTaxParamTitle, InheTaxParamVisible, InheTaxParamDisabled } from "js/common/types/types";
import PersonDeduDialog from "./PersonDeduDialog";

type Props = {
    inputValue: InheTaxParamProps;
    inputVisible: InheTaxParamVisible;
    inputTitle: InheTaxParamTitle;
    inputDisabled: InheTaxParamDisabled;
    onUpdateInput: (action: InheTaxAction) => void;
};

function DeduInfo({ inputValue, inputVisible, inputTitle, inputDisabled, onUpdateInput }: Props) {
    const [personDeduDialogOpen, setPersonDeduDialogOpen] = useState<boolean>(false);
    
    const handleClickCalcPersonDedu = (personDedu: string) => {
        onUpdateInput({
            type: 'UPDATE_PERSON_DEDU',
            payload: personDedu
        })
    }

    return (
        <>
        <MainSectionContainer>
            <p className="desc">
                <span>피상속인의</span>
                <span className="bold"> 상속공제정보</span>
                <span>를 입력해주세요.</span>
            </p>

            {/* 15. 공제구분 */}
            {inputVisible.visible15 && (
                <InputContainer>
                    <p className="label">공제구분</p>
                    <div className="input">
                        <SelectBox
                            placeholder="공제구분을 선택해주세요."
                            options={Util.getCodeArray("2032").map((code: Code) => ({
                                value: code.code,
                                label: code.codeNm,
                            }))}
                            selectedValue={inputValue.deduCls}
                            disabled={inputDisabled.disabled15}
                            handleSelect={(selected: SelectOption) => {
                                onUpdateInput({
                                    type: "UPDATE_DEDU_CLS",
                                    payload: selected.value,
                                });
                            }}
                        />
                    </div>
                </InputContainer>
            )}

            {/* 16. 일괄공제 */}
            {inputVisible.visible16 && (
                <InputContainer>
                    <p className="label">일괄공제</p>
                    <div className="input">
                        <NumberInputContainer $filled={inputValue.sumDedu === "" ? false : true}>
                            <div className="inputWrap">
                                <NumericFormat
                                    name="sumDedu"
                                    value={inputValue.sumDedu}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={15}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const valueWithoutComma = inputValue.replace(/,/g, "");
                                        onUpdateInput({
                                            type: "UPDATE_SUM_DEDU",
                                            payload: valueWithoutComma,
                                        });
                                    }}
                                    disabled={true}
                                    placeholder="일괄공제 금액을 입력해주세요."
                                    className="numberInput"
                                />
                                <p className="unit">원</p>
                            </div>
                        </NumberInputContainer>
                    </div>
                </InputContainer>        
            )}

            {/* 17. 기초공제 */}
            {inputVisible.visible17 && (
                <InputContainer>
                    <p className="label">기초공제</p>
                    <div className="input">
                        <NumberInputContainer $filled={inputValue.basicDedu === "" ? false : true}>
                            <div className="inputWrap">
                                <NumericFormat
                                    name="basicDedu"
                                    value={"200,000,000"}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={15}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const valueWithoutComma = inputValue.replace(/,/g, "");
                                        onUpdateInput({
                                            type: "UPDATE_BASIC_DEDU",
                                            payload: valueWithoutComma,
                                        });
                                    }}
                                    disabled={true}
                                    placeholder="기초공제 금액을 입력해주세요."
                                    className="numberInput"
                                />
                                <p className="unit">원</p>
                            </div>
                        </NumberInputContainer>
                    </div>
                </InputContainer>
            )}

            {/* 18. 인적공제 */}
            {inputVisible.visible18 && (
                <InputContainer>
                    <p className="label">인적공제</p>
                    <div className="input">
                        <NumberInputContainer $filled={inputValue.personDedu === "" ? false : true}>
                            <div className="inputWrap">
                                <NumericFormat
                                    name="personDedu"
                                    value={inputValue.personDedu}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={15}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const valueWithoutComma = inputValue.replace(/,/g, "");
                                        onUpdateInput({
                                            type: "UPDATE_PERSON_DEDU",
                                            payload: valueWithoutComma,
                                        });
                                    }}
                                    disabled={true}
                                    placeholder="인적공제 계산하기 버튼을 클릭하세요."
                                    className="numberInput"
                                />
                                <p className="unit">원</p>
                            </div>
                        </NumberInputContainer>
                    </div>

                    {/* 인적공제 계산하기 Button */}
                    <InhePersonDeduCalButton onClick={() => setPersonDeduDialogOpen(true)}>
                        <div className="buttonTextWrap">
                            <img src={require("img/common/person_check_icon.png")} alt="person check icon" />
                            <span>인적공제 계산하기</span>
                        </div>
                        
                        <NavigateNextOutlinedIcon fontSize="small" />
                    </InhePersonDeduCalButton>                        
                </InputContainer>
            )}
            
            {/* 19. 배우자 장애인 여부 */}
            {inputVisible.visible19 && (
                <InputContainer>
                    <p className="label">배우자 장애인 여부</p>
                    <div className="input">
                        <SelectBox
                            placeholder="배우자 장애인 여부를 선택해주세요."
                            options={Util.getCodeArray("2030").map((code: Code) => ({
                                value: code.code,
                                label: code.codeNm,
                            }))}
                            selectedValue={inputValue.spouseDisabled}
                            handleSelect={(selected: SelectOption) => {
                                onUpdateInput({
                                    type: "UPDATE_SPOUSE_DISABLED",
                                    payload: selected.value,
                                });
                            }}
                        />
                    </div>
                </InputContainer>
            )}

            {/* 20. 배우자 성별 */}
            {inputVisible.visible20 && (
                <InputContainer>
                    <p className="label">배우자 성별</p>
                    <div className="input">
                        <SelectBox
                            placeholder="배우자 성별을 선택해주세요."
                            options={Util.getCodeArray("2033").map((code: Code) => ({
                                value: code.code,
                                label: code.codeNm,
                            }))}
                            selectedValue={inputValue.spouseGender}
                            handleSelect={(selected: SelectOption) => {
                                onUpdateInput({
                                    type: "UPDATE_SPOUSE_GENDER",
                                    payload: selected.value,
                                });
                            }}
                        />
                    </div>
                </InputContainer>
            )}

            {/* 21. 배우자 나이 */}
            {inputVisible.visible21 && (
                <InputContainer>
                    <p className="label">배우자 나이</p>
                    <div className="input">
                        <NumberInputContainer $filled={inputValue.spouseAge === "" ? false : true}>
                            <div className="inputWrap">
                                <NumericFormat
                                    name="spouseAge"
                                    value={inputValue.spouseAge}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={3}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const valueWithoutComma = inputValue.replace(/,/g, "");
                                        onUpdateInput({
                                            type: "UPDATE_SPOUSE_AGE",
                                            payload: valueWithoutComma,
                                        });
                                    }}
                                    placeholder="배우자 나이를 입력해주세요."
                                    className="numberInput"
                                />
                                <p className="unit">세</p>
                            </div>
                        </NumberInputContainer>
                    </div>
                </InputContainer>
            )}

            {/* 22_0. 직계존속 상속인 유무 */}
            {inputVisible.visible22_0 && (
                <InputContainer>
                    <p className="label">직계존속 상속인 유무</p>
                    <div className="input">
                        <SelectBox
                            placeholder="직계존속 상속인 유무를 선택해주세요."
                            options={Util.getCodeArray("2039").map((code: Code) => ({
                                value: code.code,
                                label: code.codeNm,
                            }))}
                            selectedValue={inputValue.directHeir}
                            handleSelect={(selected: SelectOption) => {
                                onUpdateInput({
                                    type: "UPDATE_DIRECT_HEIR",
                                    payload: selected.value
                                });
                            }}
                        />
                    </div>
                </InputContainer>
            )}

            {/* 22. 배우자 상속재산가액 */}
            {inputVisible.visible22 && (
                <InputContainer>
                    <p className="label">배우자 상속재산가액</p>
                    <div className="input">
                        <NumberInputContainer $filled={inputValue.spouseInherProp === "" ? false : true}>
                            <div className="inputWrap">
                                <NumericFormat
                                    name="spouseInherProp"
                                    value={inputValue.spouseInherProp}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={15}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const valueWithoutComma = inputValue.replace(/,/g, "");
                                        onUpdateInput({
                                            type: "UPDATE_SPOUSE_INHER_PROP",
                                            payload: valueWithoutComma,
                                        });
                                    }}
                                    placeholder="배우자 상속재산가액을 입력해주세요."
                                    className="numberInput"
                                />
                                <p className="unit">원</p>
                            </div>
                        </NumberInputContainer>
                        <div className="help">사전증여재산가액 및 추정상속재산가액 제외</div>
                    </div>
                </InputContainer>
            )}

            {/* 23. 배우자 승계 공과금&채무 */}
            {inputVisible.visible23 && (
                <InputContainer>
                    <p className="label">배우자 승계 공과금&채무</p>
                    <div className="input">
                        <NumberInputContainer $filled={inputValue.spouseSucUtilDebt === "" ? false : true}>
                            <div className="inputWrap">
                                <NumericFormat
                                    name="spouseSucUtilDebt"
                                    value={inputValue.spouseSucUtilDebt}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={15}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const valueWithoutComma = inputValue.replace(/,/g, "");
                                        onUpdateInput({
                                            type: "UPDATE_SPOUSE_SUC_UTIL_DEBT",
                                            payload: valueWithoutComma,
                                        });
                                    }}
                                    placeholder="배우자 승계 공과금&채무 금액을 입력해주세요."
                                    className="numberInput"
                                />
                                <p className="unit">원</p>
                            </div>
                        </NumberInputContainer>
                    </div>
                </InputContainer>
            )}

        </MainSectionContainer>
        <PersonDeduDialog open={personDeduDialogOpen} setOpen={setPersonDeduDialogOpen} childNumber={inputValue.childNumber} onClickCalculate={handleClickCalcPersonDedu} />
        </>
    );
}

const InhePersonDeduCalButton = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 12px 12px 12px 16px;
    margin-top: 12px;
    background-color: white;
    border-radius: 8px;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.4em;
    line-height: 1.8;
    letter-spacing: -0.028em;
    color: #727b8e;

    .buttonTextWrap {
        display: flex;
        align-items: center;
        gap: 12px;   
    
        img {
            width: 18px;
            height: 18px;
        }
    }
`;

export default DeduInfo;
