/***********************************************************************************
*** Law22at1 ** 조세특례제한법 99조 신축주택의 취득자에 대한 양도소득세의 감면 Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law22at1Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}

export default function Law22at1({ setModalOpen, setLawModalTitle, callBack, callMode }: Law22at1Props) {
    React.useEffect(() => {
        setLawModalTitle("조세특례제한법 99조 신축주택의 취득자에 대한 양도소득세의 감면");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className='w100p hAuto ofA mb30'>
                <table className={
                    'forest-list-table bdt1 bdb1 bdcDDDDDD ' +
                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075'
                }>
                   <colgroup>
                        <col className='w80' ></col>    {/* 구분       */}
                        <col className='w100'></col>    {/* 주택구분   */}
                        <col className='w120'></col>    {/* 계약금     */}
                        <col className='w250'></col>    {/* 사용승인   */}
                        <col className='w100'></col>    {/* 입주사실   */}
                        <col className='w60' ></col>    {/* 최초계약   */}
                        <col className='w250'></col>    {/* 잔여주택   */}
                        <col className='w160'></col>    {/* 혜택       */}
                    </colgroup>
                    <tbody>
                        <tr className='h40'>
                            <th rowSpan={2}>구분</th>
                            <th rowSpan={2}>주택구분</th>
                            <th rowSpan={2}>계약일+계약금<br/>완납일 귀속기간</th>
                            <th rowSpan={2}>사용승인 또는<br/>사용검사(임시승인포함)일 귀속기간</th>
                            <th colSpan={3}>추가질문</th>
                            <th rowSpan={2}>혜택</th>
                        </tr>
                        <tr>
                            <th>입주사실</th>
                            <th>최초계약</th>
                            <th className='bdr1-i bdcDDDDDD-i'>조합의 잔여주택 일반계약자(조합원외의 자)의 계약일+계약금완납일 귀속기간</th>
                        </tr>
                        <tr>
                            <th rowSpan={4}>개별사항</th>
                            <td>모든 자가신축</td>
                            <td>-</td>
                            <td>국민주택규모 외: 1998.5.22.~1999.6.30.<br/>국민주택규모: 1998.5.22~1999.12.31</td>
                            <td>-</td>
                            <td></td>
                            <td></td>
                            <td rowSpan={3}>5년이내 양도시: 양도소득세 100% 감면<br/><br/>5년초과 양도시: 최초 5년간 양도소득금액 과세제외</td>
                        </tr>
                        <tr>
                            <td rowSpan={2}>조합원입주권에 의한 아파트</td>
                            <td>-</td>
                            <td>국민주택규모 외: 1998.5.22.~1999.6.30.<br/>국민주택규모: 1998.5.22~1999.12.31</td>
                            <td>-</td>
                            <td></td>
                            <td className='bdr1-i bdcDDDDDD-i'></td>
                        </tr>
                        <tr>
                            <td>-</td>
                            <td>국민주택규모 외: 1999.07.01. 이후<br/>국민주택규모: 2000.01.01. 이후</td>
                            <td>매매계약일 당시 타인의 입주사실이 없어야 함</td>
                            <td>여</td>
                            <td className='bdr1-i bdcDDDDDD-i'>국민주택규모 외: 1998.5.22.~1999.6.30.<br/>국민주택규모: 1998.5.22~1999.12.31</td>
                        </tr>
                        <tr>
                            <td>일반분양 아파트</td>
                            <td>국민주택규모 외: 1998.5.22.~1999.6.30.<br/>국민주택규모: 1998.5.22.~1999.12.31</td>
                            <td>-</td>
                            <td>매매계약일 당시 타인의 입주사실이 없어야 함</td>
                            <td>여</td>
                            <td></td>
                            <td>5년이내 양도시: 양도소득세 100% 감면<br/><br/>5년초과 양도시: 최초 5년간 양도소득금액 과세제외</td>
                        </tr>
                        <tr className='h110'>
                            <th>공통사항</th>
                            <td className='taL pt15 pb15 pl15' colSpan={7}>
                                1. 비과세 판정시 주택수 포함<br/>
                                2. 다주택 중과배제(유예)<br/>
                                3. 고가주택은 감면제외<br/>
                                <table className={
                                    'forest-list-table bd1 bdcDDDDDD mt10 mb10 ' +
                                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075'
                                }>
                                    <colgroup>
                                        <col className='w180' />
                                        <col className='w770' />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th className='taC p10'>가액기준 (당해주택 양도시)</th>
                                            <td className='taL p10'>
                                                2002.12.31. 이전 계약분(사용승인 또는 사용검사일 포함) : 취득시점 면적기준+양도시 양도가액 12억원 초과<br/>
                                                2003.01.01. 이후 계약분(사용승인 또는 사용검사일 포함) : 양도시 양도가액 12억원 초과<br/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                4. 과세요건 갖춘 상속주택 양도시 감면 제외<br/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}
