import styled from "styled-components";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { calculatorPcWebMaxWidth } from "js/common/const";

type Props = {
  onClickBack: () => void;
  onClickSave: () => void;
  showSave: boolean;
};

function BottomButtonGroup({ onClickBack, onClickSave, showSave }: Props) {
  const handleClickBack = () => {
    onClickBack();
  };
  return (
    <ButtonContainer>
      <div className="buttonWrap">
        <button className="side" onClick={handleClickBack}>
          <span className="icon">
            <ArrowBackOutlinedIcon fontSize="small" />
          </span>
          <span className="text">계산 다시하기</span>
        </button>
        {/*<button className="side">
                    <span className="text">Next</span>
                    <span className="icon">
                        <ArrowForwardOutlined fontSize="large" />
                    </span>
                </button>*/}
        {showSave && (
          <button className="reset" onClick={onClickSave}>
            <span className="text">저장</span>
            <span className="icon">
              <FileDownloadOutlinedIcon fontSize="small" />
            </span>
          </button>
        )}
      </div>
    </ButtonContainer>
  );
}

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);

  width: 100%;
  padding: 14px 20px;

  @media (min-width: 1201px) {
    max-width: ${calculatorPcWebMaxWidth};
  }

  .buttonWrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 52px;
    padding: 6px 8px;
    background-color: rgba(43, 43, 53, 0.58);
    border-radius: 10px;

    button.side {
      display: flex;
      align-items: center;
      gap: 8px;

      height: 100%;
      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.4em;
      letter-spacing: -0.0357em;
      color: white;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 20px;
        height: 20px;
      }
    }

    button.reset {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2px;

      padding: 7px 11px;
      border-radius: 31px;
      border: 1px solid white;
      background-color: transparent;

      font-family: "Spoqa Han Sans Neo";
      font-weight: 500;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: white;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 20px;
        height: 20px;
      }
    }
  }
`;

export default BottomButtonGroup;
