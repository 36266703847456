import {
  Step,
  TranTaxParamProps,
  TranTaxParamTitle,
  TranTaxParamVisible,
} from "js/common/types/types";
import { MainSectionContainer } from "../common/layout";
import { TranTaxAction } from "./tranTaxStatesReducer";
import FormerHouseInfo from "./FormerHouseInfo";
import NewHouseInfo from "./NewHouseInfo";

type Props = {
  stepList: Step[];
  inputValue: TranTaxParamProps;
  inputVisible: TranTaxParamVisible;
  inputTitle: TranTaxParamTitle;
  onUpdateInput: (action: TranTaxAction) => void;
};

function ExtraInfo({
  stepList,
  inputValue,
  inputVisible,
  inputTitle,
  onUpdateInput,
}: Props) {
  const usingStepList = stepList.filter((step: Step) => step.useYn);
  const currentStep = usingStepList.find((step: Step) => step.selected);

  if (usingStepList.length === 0) {
    // 세부 단계 없음
    return (
      <MainSectionContainer>
        <FormerHouseInfo
          inputValue={inputValue}
          inputVisible={inputVisible}
          inputTitle={inputTitle}
          onUpdateInput={onUpdateInput}
        />
        <NewHouseInfo
          inputValue={inputValue}
          inputVisible={inputVisible}
          onUpdateInput={onUpdateInput}
        />
      </MainSectionContainer>
    );
  } else {
    // 종전주택과 신규주택으로 구분하여 입력 받음
    if (!currentStep) {
      return <></>;
    }

    return (
      <MainSectionContainer>
        <p className="desc">
          {currentStep.stepId === "TRAN0301" && (
            <span className="bold">종전주택 정보 입력 (1/2)</span>
          )}
          {currentStep.stepId === "TRAN0302" && (
            <span className="bold">신규주택 정보 입력 (2/2)</span>
          )}
        </p>
        {currentStep.stepId === "TRAN0301" && (
          <FormerHouseInfo
            inputValue={inputValue}
            inputVisible={inputVisible}
            inputTitle={inputTitle}
            onUpdateInput={onUpdateInput}
          />
        )}
        {currentStep.stepId === "TRAN0302" && (
          <NewHouseInfo
            inputValue={inputValue}
            inputVisible={inputVisible}
            onUpdateInput={onUpdateInput}
          />
        )}
      </MainSectionContainer>
    );
  }
}

export default ExtraInfo;
