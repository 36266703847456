/*******************************************************************************
 *** AcquisitionTaxRate ** 취득세 세율 도움말 Modal * 취득세 결과표에서 호출
*******************************************************************************/
const AcquisitionTaxRate = ({acqsCause}:any) => {
    return (
        <div className='w820'>
            <div className="w100p float-l pr10 bdBox">
                <div className='dpFlx jcL aiC mb10'>
                <span className="SpoqaHanSansNeo-Regular fc505050 ls075 fs16 lh20">
                {
                      (acqsCause === "1002" || acqsCause === "1011" || acqsCause === "1012") ? "▼ 매매, 신규분양, 미분양"
                    : (acqsCause === "1003") ? "▼ 상속"
                    : (acqsCause === "1004") ? "▼ 증여"
                    : "▼ 신축"
                }
                </span>
                </div>

                {/* 매매(1002), 신규분양(1011), 미분양(1012) */}
                { (acqsCause === "1002" || acqsCause === "1011" || acqsCause === "1012") ?
                    <table className={
                    "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD " +
                    "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 " +
                    "td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls1 td-fc666666 td-bdcDDDDDD td-p15"
                    }>
                <colgroup>
                    <col className="w80"></col>
                    <col className="w80"></col>
                    <col className="w100"></col>
                    <col className="w80"></col>
                    <col className="w100"></col>
                    <col className="w100"></col>
                    <col className="w100"></col>
                    <col className="w100"></col>
                    <col className="w180"></col>
                </colgroup>
                <tbody>
                    <tr className="h40">
                        <th>조정여부</th>
                        <th>주택수</th>
                        <th>취득가액</th>
                        <th>전용면적</th>
                        <th>취득세율</th>
                        <th>지방교육세</th>
                        <th>농어촌특별세</th>
                        <th>계</th>
                        <th>산식</th>
                    </tr>
                    <tr className="h40">
                        <td rowSpan={10}>조정 여</td>
                        <td rowSpan={6}>1주택</td>
                        <td rowSpan={2}>6억 이하</td>
                        <td>85이하</td>
                        <td>1.00%</td>
                        <td>0.10%</td>
                        <td>0.00%</td>
                        <td>1.10%</td>
                        <td></td>
                    </tr>
                    <tr className="h40">
                        <td>85초과</td>
                        <td>1.00%</td>
                        <td>0.10%</td>
                        <td>0.20%</td>
                        <td>1.30%</td>
                        <td></td>
                    </tr>
                    <tr className="h40">
                        <td rowSpan={2}>6억 초과~<br/>9억 이하</td>
                        <td>85이하</td>
                        <td>1.01% ~ 2.99%</td>
                        <td>0.101% ~ 0.299%</td>
                        <td>0.00%</td>
                        <td>1.11% ~ 3.289%</td>
                        <td rowSpan={2}>취득세율<br/>= (취득가액*2/3억원-3)<br/>소수점 이하<br/>5자리에서 반올림</td>
                    </tr>
                    <tr className="h40">
                        <td>85초과</td>
                        <td>1.01% ~ 2.100%</td>
                        <td>0.101% ~ 0.300%</td>
                        <td>0.20%</td>
                        <td className="bdr1-i bdcDDDDDD-i">1.32% ~ 3.489%</td>
                    </tr>
                    <tr className="h40">
                        <td rowSpan={2}>9억 초과</td>
                        <td>85이하</td>
                        <td>3.00%</td>
                        <td>0.30%</td>
                        <td>0.00%</td>
                        <td>3.30%</td>
                        <td></td>
                    </tr>
                    <tr className="h40">
                        <td>85초과</td>
                        <td>3.00%</td>
                        <td>0.30%</td>
                        <td>0.20%</td>
                        <td>3.50%</td>
                        <td></td>
                    </tr>
                    <tr className="h40">
                        <td rowSpan={2} colSpan={2}>2주택</td>
                        <td>85이하</td>
                        <td>8.00%</td>
                        <td>0.40%</td>
                        <td>0.00%</td>
                        <td>8.40%</td>
                        <td></td>
                    </tr>
                    <tr className="h40">
                        <td>85초과</td>
                        <td>8.00%</td>
                        <td>0.40%</td>
                        <td>0.60%</td>
                        <td>9.00%</td>
                        <td></td>
                    </tr>
                    <tr className="h40">
                        <td rowSpan={2} colSpan={2}>3주택 이상</td>
                        <td>85이하</td>
                        <td>12.00%</td>
                        <td>0.40%</td>
                        <td>0.00%</td>
                        <td>12.40%</td>
                        <td></td>
                    </tr>
                    <tr className="h40">
                        <td>85초과</td>
                        <td>12.00%</td>
                        <td>0.40%</td>
                        <td>1.00%</td>
                        <td>13.40%</td>
                        <td></td>
                    </tr>
                    <tr className="h40">
                        <td rowSpan={10}>조정 부</td>
                        <td rowSpan={6}>1주택/2주택</td>
                        <td rowSpan={2}>6억 이하</td>
                        <td>85이하</td>
                        <td>1.00%</td>
                        <td>0.10%</td>
                        <td>0.00%</td>
                        <td>1.10%</td>
                        <td></td>
                    </tr>
                    <tr className="h40">
                        <td>85초과</td>
                        <td>1.00%</td>
                        <td>0.10%</td>
                        <td>0.20%</td>
                        <td>1.30%</td>
                        <td></td>
                    </tr>
                    <tr className="h40">
                        <td rowSpan={2}>6억 초과~<br/>9억 이하</td>
                        <td>85이하</td>
                        <td>1.01% ~ 2.99%</td>
                        <td>0.101% ~ 0.299%</td>
                        <td>0.00%</td>
                        <td>1.11% ~ 3.289%</td>
                        <td rowSpan={2}>취득세율<br/>= (취득가액*2/3억원-3)<br/>소수점 이하<br/>5자리에서 반올림</td>
                    </tr>
                    <tr className="h40">
                        <td>85초과</td>
                        <td>1.01% ~ 2.100%</td>
                        <td>0.101% ~ 0.300%</td>
                        <td>0.20%</td>
                        <td className="bdr1-i bdcDDDDDD-i">1.32% ~ 3.489%</td>
                    </tr>
                    <tr className="h40">
                        <td rowSpan={2}>9억 초과</td>
                        <td>85이하</td>
                        <td>3.00%</td>
                        <td>0.30%</td>
                        <td>0.00%</td>
                        <td>3.30%</td>
                        <td></td>
                    </tr>
                    <tr className="h40">
                        <td>85초과</td>
                        <td>3.00%</td>
                        <td>0.30%</td>
                        <td>0.20%</td>
                        <td>3.50%</td>
                        <td></td>
                    </tr>
                    <tr className="h40">
                        <td rowSpan={2} colSpan={2}>3주택</td>
                        <td>85이하</td>
                        <td>8.00%</td>
                        <td>0.40%</td>
                        <td>0.00%</td>
                        <td>8.40%</td>
                        <td></td>
                    </tr>
                    <tr className="h40">
                        <td>85초과</td>
                        <td>8.00%</td>
                        <td>0.40%</td>
                        <td>0.60%</td>
                        <td>9.00%</td>
                        <td></td>
                    </tr>
                    <tr className="h40">
                        <td rowSpan={2} colSpan={2}>4주택 이상</td>
                        <td>85이하</td>
                        <td>12.00%</td>
                        <td>0.40%</td>
                        <td>0.00%</td>
                        <td>12.40%</td>
                        <td></td>
                    </tr>
                    <tr className="h40">
                        <td>85초과</td>
                        <td>12.00%</td>
                        <td>0.40%</td>
                        <td>1.00%</td>
                        <td>13.40%</td>
                        <td></td>
                    </tr>
                    <tr className="h40">
                        <td colSpan={4}>오피스텔</td>
                        <td>4.00%</td>
                        <td>0.40%</td>
                        <td>0.20%</td>
                        <td>4.60%</td>
                        <td></td>
                    </tr>
                    <tr className="h40">
                        <td colSpan={4}>일반건물/상가</td>
                        <td>4.00%</td>
                        <td>0.40%</td>
                        <td>0.20%</td>
                        <td>4.60%</td>
                        <td></td>
                    </tr>
                    <tr className="h40">
                        <td colSpan={4}>토지(농지외)</td>
                        <td>4.00%</td>
                        <td>0.40%</td>
                        <td>0.20%</td>
                        <td>4.60%</td>
                        <td></td>
                    </tr>
                    <tr className="h40">
                        <td rowSpan={2} colSpan={2}>토지(농지)</td>
                        <td colSpan={2}>2년이상 자경</td>
                        <td>1.50%</td>
                        <td>0.10%</td>
                        <td>0.00%</td>
                        <td>1.60%</td>
                        <td></td>
                    </tr>
                    <tr className="h40">
                        <td colSpan={2}>2년 미만 자경, 비자경</td>
                        <td>3.00%</td>
                        <td>0.20%</td>
                        <td>0.20%</td>
                        <td>3.40%</td>
                        <td></td>
                    </tr>
                </tbody>
                </table>

                // 상속(1003)
                : (acqsCause === "1003") ?
                <table className={
                    "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD " +
                    "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 " +
                    "td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls1 td-fc666666 td-bdcDDDDDD td-p15"
                    }>
                <colgroup>
                    <col className="w100"></col>
                    <col className="w160"></col>
                    <col className="w100"></col>
                    <col className="w100"></col>
                    <col className="w100"></col>
                    <col className="w100"></col>
                </colgroup>
                <tbody>
                    <tr className="h40">
                        <th colSpan={2}>구분</th>
                        <th>취득세율</th>
                        <th>지방교육세</th>
                        <th>농어촌특별세</th>
                        <th>계</th>
                    </tr>
                    <tr className="h40">
                        <td rowSpan={2}>주택</td>
                        <td>무주택자</td>
                        <td>0.80%</td>
                        <td>0.16%</td>
                        <td>0.00%</td>
                        <td>0.96%</td>
                    </tr>
                    <tr className="h40">
                        <td>유주택자</td>
                        <td>2.80%</td>
                        <td>0.16%</td>
                        <td>0.20%</td>
                        <td>3.16%</td>
                    </tr>
                    <tr className="h40">
                        <td colSpan={2}>기존오피스텔</td>
                        <td>2.80%</td>
                        <td>0.16%</td>
                        <td>0.20%</td>
                        <td>3.16%</td>
                    </tr>
                    <tr className="h40">
                        <td colSpan={2}>일반분양 오피스텔</td>
                        <td>4.00%</td>
                        <td>0.40%</td>
                        <td>0.20%</td>
                        <td>4.60%</td>
                    </tr>                    
                    <tr className="h40">
                        <td colSpan={2}>일반건물/상가</td>
                        <td>2.80%</td>
                        <td>0.16%</td>
                        <td>0.20%</td>
                        <td>3.16%</td>
                    </tr>
                    <tr className="h40">
                        <td rowSpan={2}>토지(농지)</td>
                        <td>2년 미만 자경, 비자경</td>
                        <td>2.30%</td>
                        <td>0.06%</td>
                        <td>0.20%</td>
                        <td>2.56%</td>
                    </tr>
                    <tr className="h40">
                        <td>2년 이상 자경</td>
                        <td>0.30%</td>
                        <td>0.06%</td>
                        <td>0.00%</td>
                        <td>0.36%</td>
                    </tr>
                    <tr className="h40">
                        <td colSpan={2}>토지(농지 외)</td>
                        <td>2.80%</td>
                        <td>0.16%</td>
                        <td>0.20%</td>
                        <td>3.16%</td>
                    </tr>
                </tbody>
                </table>

                // 증여(1004)
                : (acqsCause === "1004") ?
                <table className={
                    "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD " +
                    "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 " +
                    "td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls1 td-fc666666 td-bdcDDDDDD td-p15"
                    }>
                <colgroup>
                    <col className="w100"></col>
                    <col className="w100"></col>
                    <col className="w100"></col>
                    <col className="w100"></col>
                    <col className="w100"></col>
                    <col className="w100"></col>
                    <col className="w100"></col>
                    <col className="w100"></col>
                </colgroup>
                <tbody>
                    <tr className="h40">
                        <th>조정여부</th>
                        <th>취득가액</th>
                        <th>구분</th>
                        <th>전용면적</th>
                        <th>취득세율</th>
                        <th>지방교육세</th>
                        <th>농어촌특별세</th>
                        <th>계</th>
                    </tr>
                    <tr className="h40">
                        <td rowSpan={4}>조정</td>
                        <td rowSpan={2}>3억 미만</td>
                        <td></td>
                        <td>85이하</td>
                        <td>3.50%</td>
                        <td>0.30%</td>
                        <td>0.00%</td>
                        <td>3.80%</td>
                    </tr>
                    <tr className="h40">
                        <td></td>
                        <td>85초과</td>
                        <td>3.50%</td>
                        <td>0.30%</td>
                        <td>0.20%</td>
                        <td>4.00%</td>
                    </tr>
                    <tr className="h40">
                        <td rowSpan={2}>3억 이상</td>
                        <td>다주택</td>
                        <td>85이하</td>
                        <td>12.00%</td>
                        <td>0.40%</td>
                        <td>0.00%</td>
                        <td>12.40%</td>
                    </tr>
                    <tr className="h40">
                        <td>배우자<br/>직계존비속</td>
                        <td>85초과</td>
                        <td>12.00%</td>
                        <td>0.40%</td>
                        <td>1.00%</td>
                        <td>13.40%</td>
                    </tr>
                    <tr className="h40">
                        <td rowSpan={2} colSpan={3}>비조정대상지역 주택</td>
                        <td>85이하</td>
                        <td>3.50%</td>
                        <td>0.30%</td>
                        <td>0.00%</td>
                        <td>3.80%</td>
                    </tr>
                    <tr className="h40">
                        <td>85초과</td>
                        <td>3.50%</td>
                        <td>0.30%</td>
                        <td>0.20%</td>
                        <td>4.00%</td>
                    </tr>
                    <tr className="h40">
                        <td colSpan={4}>주택 외 부동산</td>
                        <td>3.50%</td>
                        <td>0.30%</td>
                        <td>0.20%</td>
                        <td>4.00%</td>
                    </tr>
                    <tr className="h40">
                        <td colSpan={4}>오피스텔</td>
                        <td>4.00%</td>
                        <td>0.40%</td>
                        <td>0.20%</td>
                        <td>4.60%</td>
                    </tr>
                </tbody>
                </table>

                // 신축(취득원인 구분 코드 없음)
                : <table className={
                    "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD " +
                    "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 " +
                    "td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls1 td-fc666666 td-bdcDDDDDD td-p15"
                    }>
                <colgroup>
                    <col className="w100"></col>
                    <col className="w100"></col>
                    <col className="w100"></col>
                    <col className="w100"></col>
                    <col className="w100"></col>
                    <col className="w100"></col>
                </colgroup>
                <tbody>
                    <tr className="h40">
                        <th>구분</th>
                        <th>전용면적</th>
                        <th>취득세율</th>
                        <th>지방교육세</th>
                        <th>농어촌특별세</th>
                        <th>계</th>
                    </tr>
                    <tr className="h40">
                        <td rowSpan={2}>주택</td>
                        <td>85이하</td>
                        <td>2.80%</td>
                        <td>0.16%</td>
                        <td>0.00%</td>
                        <td>2.96%</td>
                    </tr>
                    <tr className="h40">
                        <td>85초과</td>
                        <td>2.80%</td>
                        <td>0.16%</td>
                        <td>0.20%</td>
                        <td>3.16%</td>
                    </tr>
                    <tr className="h40">
                        <td colSpan={2}>주택 외 부동산</td>
                        <td>2.80%</td>
                        <td>0.16%</td>
                        <td>0.20%</td>
                        <td>3.16%</td>
                    </tr>
                </tbody>
                </table>
            }
            </div>

        </div>
    )
}
export default AcquisitionTaxRate;