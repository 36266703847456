/*******************************************************************************
*** ExtraInfo ** 모바일 상속세 부가정보 입력 UI
*******************************************************************************/
import { useState }      from "react";
import styled            from "styled-components";
import { InheTaxAction } from "./inheTaxStatesReducer";
import { NumericFormat } from "react-number-format";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import { InheTaxParamProps, InheTaxParamTitle, InheTaxParamVisible }  from "js/common/types/types";
import { InputContainer, MainSectionContainer, NumberInputContainer } from "../common/layout";
import FinanceDeduDialog from "./FinanceDeduDialog";

type Props = {
    inputValue: InheTaxParamProps;
    inputVisible: InheTaxParamVisible;
    inputTitle: InheTaxParamTitle;
    onUpdateInput: (action: InheTaxAction) => void;
};

function ExtraInfo({ inputValue, inputVisible, inputTitle, onUpdateInput }: Props) {
    const [financeDeduDialogOpen, setFinanceDeduDialogOpen] = useState<boolean>(false);

    const handleClickCalc = (financeDedu: number) => {
        onUpdateInput({
            type: 'UPDATE_FINAN_DEDU',
            payload: financeDedu
        })
    };

    return (
        <>
            <MainSectionContainer>
                <p className="desc">
                    <span>상속공제의</span>
                    <span className="bold"> 부가정보</span>
                    <span>를 입력해주세요.</span>
                </p>
        
                {/* 24. 가업상속공제 */}
                {inputVisible.visible24 && (
                    <InputContainer>
                        <p className="label">가업상속공제</p>
                        <div className="input">
                            <NumberInputContainer $filled={inputValue.familyDedu === "" ? false : true}>
                                <div className="inputWrap">
                                    <NumericFormat
                                        name="familyDedu"
                                        value={inputValue.familyDedu}
                                        thousandsGroupStyle="thousand"
                                        thousandSeparator=","
                                        maxLength={15}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const valueWithoutComma = inputValue.replace(/,/g, "");
                                            onUpdateInput({
                                                type: "UPDATE_FAMILY_DEDU",
                                                payload: valueWithoutComma,
                                            });
                                        }}
                                        placeholder="가업상속공제 금액을 입력해주세요."
                                        className="numberInput"
                                    />
                                    <p className="unit">원</p>
                                </div>
                            </NumberInputContainer>
                        </div>
                    </InputContainer>
                )}

                {/* 25. 영농상속공제 */}
                {inputVisible.visible25 && (
                    <InputContainer>
                        <p className="label">영농상속공제</p>
                        <div className="input">
                            <NumberInputContainer $filled={inputValue.farmDedu === "" ? false : true}>
                                <div className="inputWrap">
                                    <NumericFormat
                                        name="farmDedu"
                                        value={inputValue.farmDedu}
                                        thousandsGroupStyle="thousand"
                                        thousandSeparator=","
                                        maxLength={15}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const valueWithoutComma = inputValue.replace(/,/g, "");
                                            onUpdateInput({
                                                type: "UPDATE_FARM_DEDU",
                                                payload: valueWithoutComma,
                                            });
                                        }}
                                        placeholder="영농상속공제 금액을 입력해주세요."
                                        className="numberInput"
                                    />
                                    <p className="unit">원</p>
                                </div>
                            </NumberInputContainer>
                        </div>
                    </InputContainer>
                )}

                {/* 26. 동거주택상속공제 */}
                {inputVisible.visible26 && (
                    <InputContainer>
                        <p className="label">동거주택상속공제</p>
                        <div className="input">
                            <NumberInputContainer $filled={inputValue.houseDedu === "" ? false : true}>
                                <div className="inputWrap">
                                    <NumericFormat
                                        name="houseDedu"
                                        value={inputValue.houseDedu}
                                        thousandsGroupStyle="thousand"
                                        thousandSeparator=","
                                        maxLength={15}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const valueWithoutComma = inputValue.replace(/,/g, "");
                                            onUpdateInput({
                                                type: "UPDATE_HOUSE_DEDU",
                                                payload: valueWithoutComma,
                                            });
                                        }}
                                        placeholder="동거주택상속공제 금액을 입력해주세요."
                                        className="numberInput"
                                    />
                                    <p className="unit">원</p>
                                </div>
                            </NumberInputContainer>
                        </div>
                    </InputContainer>
                )}

                {/* 27. 금융상속공제 */}
                {inputVisible.visible27 && (
                    <InputContainer>
                        <p className="label">금융상속공제</p>
                        <div className="input">
                            <NumberInputContainer $filled={inputValue.finanDedu === "" ? false : true}>
                                <div className="inputWrap">
                                    <NumericFormat
                                        name="finanDedu"
                                        value={inputValue.finanDedu}
                                        thousandsGroupStyle="thousand"
                                        thousandSeparator=","
                                        maxLength={15}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const valueWithoutComma = inputValue.replace(/,/g, "");
                                            onUpdateInput({
                                                type: "UPDATE_FINAN_DEDU",
                                                payload: valueWithoutComma,
                                            });
                                        }}
                                        disabled={true}
                                        placeholder="금융상속공제 계산하기를 클릭하세요."
                                        className="numberInput"
                                    />
                                    <p className="unit">원</p>
                                </div>
                            </NumberInputContainer>

                            {/* <PropInfoAddButton onClick={handleOpenAddrDialog}> */}
                            <InheFinancialCalButton onClick={() => setFinanceDeduDialogOpen(true)}>
                                <div className="buttonTextWrap">
                                    <AttachMoneyOutlinedIcon fontSize="small" />
                                    <span>금융상속공제 계산하기</span>
                                </div>
                                <NavigateNextOutlinedIcon fontSize="small" />
                            </InheFinancialCalButton>                        
                        </div>
                    </InputContainer>
                )}
            </MainSectionContainer>
            <FinanceDeduDialog open={financeDeduDialogOpen} setOpen={setFinanceDeduDialogOpen} onClickCalc={handleClickCalc} />
        </>
    );
}

// 금융상속공제 계산하기 Button
const InheFinancialCalButton = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 12px 12px 12px 16px;
    margin-top: 12px;
    background-color: white;
    border-radius: 8px;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.4em;
    line-height: 1.8;
    letter-spacing: -0.028em;
    color: #727b8e;

    .buttonTextWrap {
        display: flex;
        align-items: center;
        gap: 9px;
    }
`;

export default ExtraInfo;
