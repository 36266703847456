import { SelectOption } from "js/mobile/common/SelectBox";

export const calculatorPcWebMaxWidth = "414px";

export const spfcKndOptions: SelectOption[] = [
  { label: "주거지역", value: "1001" },
  { label: "상업지역", value: "1002" },
  { label: "공업지역", value: "1003" },
  { label: "녹지지역", value: "1004" },
  { label: "관리지역", value: "1005" },
  { label: "농림지역", value: "1006" },
  { label: "자연환경보전지역", value: "1007" },
];
