// import { useNavigate } from "react-router-dom";
import AppContext from "js/common/AppContext";
// import { useRecoilValue } from "recoil";
// import * as Atom from "js/common/Atom";

interface AssetsButtonProps {
    propsForm: any;
    goNextStep?: Function;
    goPrevStep?: Function;
    goFinish?: Function;
}
const AssetsButton = ({propsForm, goNextStep, goPrevStep, goFinish}: AssetsButtonProps) => {
    // const navigate = useNavigate();
    // const currFormMode = useRecoilValue(Atom.currFormMode);

    return (
        <div className='w100p dpFlx aiC jcSb mt40 mb100'>
            <div className="h56 dpFlx">
                <button className="w111 dpFlx aiC jcC bd1 bdcDDDDDD bdBox csP bgcTp" onClick={() => {
                    AppContext.openAlert({
                        title: "알림",
                        msg: "목록으로 이동하시겠습니까?",
                        icon: "check",
                        confirmText: "목록",
                        handleConfirm: () => propsForm.goAssetsList()
                    });
                }}>
                    <span className="SpoqaHanSansNeo-Medium fs16 fc222222">목록으로</span>
                </button>
                <button className={"w111 aiC jcC bdt1 bdr1 bdb1 bdlN bdcDDDDDD bdBox csP bgcTp " + (goPrevStep === undefined ? "dpNone" : "dpFlx")} onClick={() => {
                    if (goPrevStep === undefined) {
                        return;
                    }
                    goPrevStep();
                }}>
                    <span className="SpoqaHanSansNeo-Medium fs16 fc222222">이전</span>
                </button>
                <button className={"w111 aiC jcC bdt1 bdr1 bdb1 bdlN bdcDDDDDD bdBox csP bgcTp " + (goNextStep === undefined ? "dpNone" : "dpFlx")} onClick={() => {
                    if (goNextStep === undefined) {
                        return;
                    }
                    goNextStep("next");
                }}>
                    <span className="SpoqaHanSansNeo-Medium fs16 fc222222">다음</span>
                </button>
            </div>
            <div className="h56 dpFlx">
                {/* <div className={"w210 aiC jcC bgcWhite csP " + (currFormMode === "mod" ? "dpFlx" : "dpNone")} onClick={() => {
                    if (propsForm.stepCode === "1009") {
                        navigate("/system/assets/view/" + propsForm.callPage + "/" + propsForm.assetsSn);
                    } else {
                        AppContext.openAlert({
                            title: "알림",
                            msg: "수정을 취소하시겠습니까?",
                            icon: "check",
                            confirmText: "수정취소",
                            handleConfirm: () => {
                                navigate("/system/assets/view/" + propsForm.callPage + "/" + propsForm.assetsSn);
                            }
                        });
                    }
                }}>
                    <span className="SpoqaHanSansNeo-Medium fs16 fc222222">취소</span>
                </div>
                <div className={"w210 aiC jcC bgc17A840 csP " + (currFormMode === "mod" && goNextStep !== undefined ? "dpFlx" : "dpNone")} onClick={() => {
                    if (goNextStep === undefined) {
                        return;
                    }
                    goNextStep("save");
                }}>
                    <span className="SpoqaHanSansNeo-Medium fs16 fcWhite">저장</span>
                </div> */}
                <button className={"w210 bgc17A840 aiC jcC csP bdBox bdN " + (goFinish === undefined || (propsForm.stepCode === "1009") ? "dpNone" : "dpFlx")} onClick={() => {
                    if (goFinish === undefined) {
                        return;
                    }
                    goFinish()
                }}>
                    <span className="SpoqaHanSansNeo-Medium fs16 fcWhite">등록완료</span>
                </button>
            </div>
        </div>
    )
}
export default AssetsButton;