/*******************************************************************************
*** SimGiftTax ** 증여세 간편계산기
*******************************************************************************/
import React         from "react";
import Util          from "js/common/Util";
import NumberTextBox from "js/common/ui/input/NumberTextBox";
import SelectBox     from "js/common/ui/input/SelectBox";
import AppContext from "js/common/AppContext";
import Modal from "js/common/ui/modal/Modal";
import SimGiftTaxCalc from "./SimGiftTaxCalc";

const SimGiftTax = () => {
    const[donor         , setDonor         ] = React.useState<string>("");  // 01. 증여자
    const[giftProp      , setGiftProp      ] = React.useState<string>("");  // 02. 증여 재산가액
    const[debtAssum     , setDebtAssum     ] = React.useState<string>("");  // 03. 채무인수 여부
    const[debtAmt       , setDebtAmt       ] = React.useState<string>("");  // 04. 채무액
    const[preGift       , setPreGift       ] = React.useState<string>("");  // 05. 증여자 사전증여 유무
    const[preGiftProp   , setPreGiftProp   ] = React.useState<string>("");  // 06. 사전증여 재산가액
    const[preGiftTax    , setPreGiftTax    ] = React.useState<string>("");  // 07. 사전증여 세액
    const[doneeMinor    , setDoneeMinor    ] = React.useState<string>("");  // 08. 수증자 미성년자 여부
    const[basicDedu     , setBasicDedu     ] = React.useState<string>("");  // 09. 기본공제
    const[marriageDedu  , setMarriageDedu  ] = React.useState<string>("");  // 10. 혼인공제
    const[childbirthDedu, setChildbirthDedu] = React.useState<string>("");  // 11. 출산공제

    // 입력항목 표시 여부
    const [visible01, setVisible01] = React.useState<boolean>(true ); // 01. 증여자
    const [visible02, setVisible02] = React.useState<boolean>(false); // 02. 증여 재산가액
    const [visible03, setVisible03] = React.useState<boolean>(false); // 03. 채무인수 여부
    const [visible04, setVisible04] = React.useState<boolean>(false); // 04. 채무액
    const [visible05, setVisible05] = React.useState<boolean>(false); // 05. 증여자 사전증여 유무
    const [visible06, setVisible06] = React.useState<boolean>(false); // 06. 사전증여 재산가액
    const [visible07, setVisible07] = React.useState<boolean>(false); // 07. 사전증여 세액
    const [visible08, setVisible08] = React.useState<boolean>(false); // 08. 수증자 미성년자 여부
    const [visible09, setVisible09] = React.useState<boolean>(false); // 09. 기본공제
    const [visible10, setVisible10] = React.useState<boolean>(false); // 10. 혼인공제
    const [visible11, setVisible11] = React.useState<boolean>(false); // 11. 출산공제

    // 입력항목 disabled
    const [disabled01, setDisabled01] = React.useState<boolean>(false); // 01. 증여자


    const [simGiftTaxCalcModalOpen, setSimGiftTaxCalcModalOpen] = React.useState<boolean>(false);   // 취득세 간편계산기 결과 모달
    const [simGiftTaxCalcModalVsb , setSimGiftTaxCalcModalVsb ] = React.useState<boolean>(false);   // 취득세 간편계산기 결과 표시 여부
 
     // 취득세 계산 입력정보 Setting
     interface  GiftTaxParamProps {
        taxFlag       : string; // 00. 세금 구분 Flag
        donor         : string; // 01. 증여자
        giftProp      : string; // 02. 증여 재산가액 
        debtAssum     : string; // 03. 채무인수 여부 
        debtAmt       : string; // 04. 채무액 
        preGift       : string; // 05. 증여자 사전증여 유무 
        preGiftProp   : string; // 06. 사전증여 재산가액 
        preGiftTax    : string; // 07. 사전증여 세액 
        doneeMinor    : string; // 08. 수증자 미성년자 여부 
        basicDedu     : string; // 09. 기본공제 
        marriageDedu  : string; // 10. 혼인공제 
        childbirthDedu: string; // 11. 출산공제 
    }
    const [giftTaxParam, setGiftTaxParam] = React.useState<GiftTaxParamProps[]>([]);   // 취득세 계산용 파라미터 배열


    // 초기화
    const goReset = () => {
        setVisible01(true );
        setVisible02(false);
        setVisible03(false);
        setVisible04(false);
        setVisible05(false);
        setVisible06(false);
        setVisible07(false);
        setVisible08(false);
        setVisible09(false);
        setVisible10(false);
        setVisible11(false);

        // 선택/입력 값 초기화
        setDonor         ("");
        setGiftProp      ("");
        setDebtAssum     ("");
        setDebtAmt       ("");
        setPreGift       ("");
        setPreGiftProp   ("");
        setPreGiftTax    ("");
        setDoneeMinor    ("");
        setBasicDedu     ("");
        setMarriageDedu  ("");
        setChildbirthDedu("");   
        
        // disabed 초기화
        setDisabled01(false);
    }

    // 계산하기
    const callTax = (taxFlag: string) => {
        // 입력 값 확인 메시지 처리
        if (visible01) {if (Util.isEmpty(donor    )) {AppContext.openAlert({title: "증여자", msg: "증여자를 선택하세요.", icon: "check"}); return;}}
        if (visible02) {
            if (Util.isEmpty(giftProp)) {AppContext.openAlert({title: "증여 재산가액", msg: "증여 재산가액을 입력하세요.", icon: "check"}); return;}
            if (Number(giftProp) <= 0)  {AppContext.openAlert({title: "증여 재산가액", msg: "증여 재산가액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible03) {if (Util.isEmpty(debtAssum )) {AppContext.openAlert({title: "채무인수 여부", msg: "채무인수 여부를 선택하세요.", icon: "check"}); return;}}
        if (visible04) {
            if (Util.isEmpty(debtAmt)) {AppContext.openAlert({title: "채무액", msg: "채무액을 입력하세요.", icon: "check"}); return;}
            if (Number(debtAmt) <= 0)  {AppContext.openAlert({title: "채무액", msg: "채무액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible05) {if (Util.isEmpty(preGift )) {AppContext.openAlert({title: "증여자 사전증여 유무", msg: "증여자 사전증여 유무를 선택하세요.", icon: "check"}); return;}}
        if (visible06) {
            if (Util.isEmpty(preGiftProp)) {AppContext.openAlert({title: "사전증여 재산가액", msg: "사전증여 재산가액을 입력하세요.", icon: "check"}); return;}
            if (Number(preGiftProp) <= 0)  {AppContext.openAlert({title: "사전증여 재산가액", msg: "사전증여 재산가액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible07) {
            if (Util.isEmpty(preGiftTax)) {AppContext.openAlert({title: "사전증여 세액", msg: "사전증여 세액을 입력하세요.", icon: "check"}); return;}
            if (Number(preGiftTax) <  0)  {AppContext.openAlert({title: "사전증여 세액", msg: "사전증여 세액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible08) {if (Util.isEmpty(doneeMinor     )) {AppContext.openAlert({title: "수증자 미성년자 여부", msg: "수증자 미성년자 여부를 선택하세요.", icon: "check"}); return;}}
        if (visible09) {if (Util.isEmpty(basicDedu      )) {AppContext.openAlert({title: "기본공제"            , msg: "기본공제를 선택하세요."            , icon: "check"}); return;}}
        if (visible10) {if (Util.isEmpty(marriageDedu   )) {AppContext.openAlert({title: "혼인공제"            , msg: "혼인공제를 선택하세요."            , icon: "check"}); return;}}
        if (visible11) {if (Util.isEmpty(childbirthDedu )) {AppContext.openAlert({title: "출산공제"            , msg: "출산공제를 선택하세요."            , icon: "check"}); return;}}
        
        // 입력한 사항을 배열로 생성하여 파라미터로 전달
        setGiftTaxParam([
            ...giftTaxParam,     // 배열 전개(spread operator) 사용 배열 복사본으로 새배열을 생성 : push는 배열을 변이하는 메서드로 비추천
            {
                  taxFlag       : taxFlag                      // 00. 세금 구분 Flag
                , donor         : donor                        // 01. 증여자
                , giftProp      : Util.uncomma(giftProp)       // 02. 증여 재산가액 
                , debtAssum     : debtAssum                    // 03. 채무인수 여부 
                , debtAmt       : Util.uncomma(debtAmt)        // 04. 채무액 
                , preGift       : preGift                      // 05. 증여자 사전증여 유무 
                , preGiftProp   : Util.uncomma(preGiftProp)    // 06. 사전증여 재산가액 
                , preGiftTax    : Util.uncomma(preGiftTax)     // 07. 사전증여 세액 
                , doneeMinor    : doneeMinor                   // 08. 수증자 미성년자 여부 
                , basicDedu     : basicDedu                    // 09. 기본공제 
                , marriageDedu  : marriageDedu                 // 10. 혼인공제 
                , childbirthDedu: childbirthDedu               // 11. 출산공제 
            }
          ]);

          setSimGiftTaxCalcModalOpen(true);
    }

    return (
        <div className='w940 mh500'>
            {/* 01. 증여자 */}
            <div className={(visible01 ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt30'}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>증여자</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={donor}
                        codeArray={Util.getCodeArray("2025")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        disabled={disabled01}
                        border="1px solid #dddddd"
                        onChangeFunc={(data: string) => {
                            setDonor(data);
                            setVisible02(true);
                            setVisible03(true);
                        }}
                    />
                </div>
            </div>

            {/* 02. 증여 재산가액 */}
            <div className={(visible02 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>증여 재산가액</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <NumberTextBox
                        value={giftProp}
                        onChange={(data: string) => setGiftProp(data)}
                        borderClass="bgcWhite w300 h40 pr15 bd1"
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        maxLength={15}
                        inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                        unitText="원"
                    />
                </div>
            </div>    

            {/* 03. 채무인수 여부 */}
            <div className={(visible03 ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt10'}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>채무인수 여부</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={debtAssum}
                        codeArray={Util.getCodeArray("2026")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        // disabled={disabled01}
                        border="1px solid #dddddd"
                        onChangeFunc={(data: string) => {
                            setDebtAssum(data);
                            if (data === "Y") {           // 인수함 ▶ 04.채무액, 05.증여자 사전증여 유무
                                setVisible04(true);
                                setVisible05(true);
                            } else if (data === "N") {    // 인수하지 않음 ▶ 05.증여자 사전증여 유무
                                setVisible05(true);
                                setVisible04(false); // 04.채무액 미표시
                                setDebtAmt  (""   ); // 04.채무액 초기화
                            } else {}
                        }}
                    />
                </div>
            </div>     

            {/* 04. 채무액 */}
            <div className={(visible04 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>채무액</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <NumberTextBox
                        value={debtAmt}
                        onChange={(data: string) => setDebtAmt(data)}
                        borderClass="bgcWhite w300 h40 pr15 bd1"
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        maxLength={15}
                        inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                        unitText="원"
                    />
                </div>
            </div>                    

            {/* 05. 증여자 사전증여 유무 */}
            <div className={(visible05 ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt10'}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>증여자 사전증여 유무</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={preGift}
                        codeArray={Util.getCodeArray("2027")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        // disabled={disabled01}
                        border="1px solid #dddddd"
                        onChangeFunc={(data: string) => {
                            setPreGift(data);
                            setDisabled01(true);    // 증여자 Fixed
                            if (data === "Y") {         // 증여자 사전증여 유 ▶ 06.사전증여 재산가액, 07.사전증여 세액 
                                setVisible06(true);
                                setVisible07(true); 
                            } else if (data === "N") {
                                setVisible06(false); // 06.사전증여 재산가액 미표시
                                setPreGiftProp("" ); // 06.사전증여 재산가액 초기화
                                setVisible07(false); // 07.사전증여 세액 미표시
                                setPreGiftTax(""  ); // 07.사전증여 세액 초기화
                            } else {}

                            // 수증자 확인
                            if (donor === "202") {  // 직계존속 ▶ 08.수증자 미성년자 여부, 09.기본공제
                                setVisible08(true );
                                setVisible09(true );
                                setBasicDedu("Y"  );
                            } else if (donor === "201" || donor === "203" || donor === "204") { // 배우자, 직계비속, 기타친족 ▶ 09.기본공제
                                setVisible09(true );
                                setBasicDedu("Y"  );
                                setVisible08(false); // 08.수증자 미성년자 여부 미표시
                                setDoneeMinor(""  ); // 08.수증자 미성년자 여부 초기화
                            } else if (donor === "205") {   // 기타 ▶ 09.기본공제
                                setVisible09(true );
                                setBasicDedu("N"  );
                                setVisible08(false); // 08.수증자 미성년자 여부 미표시
                                setDoneeMinor(""  ); // 08.수증자 미성년자 여부 초기화
                            } else {}
                        }}
                    />
                </div>
                <div className='dpFlx aiC jcC ml10'>
                    <span className="SpoqaHanSansNeo-Regular fs15 ls08 fc17A840">◀ 10년 이내 동일한 증여자로부터 받은 사전증여</span>
                </div>                
            </div>    

            {/* 06. 사전증여 재산가액 */}
            <div className={(visible06 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>사전증여 재산가액</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <NumberTextBox
                        value={preGiftProp}
                        onChange={(data: string) => setPreGiftProp(data)}
                        borderClass="bgcWhite w300 h40 pr15 bd1"
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        maxLength={15}
                        inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                        unitText="원"
                    />
                </div>
            </div>  

            {/* 07. 사전증여세액 */}
            <div className={(visible07 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>사전증여세액</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <NumberTextBox
                        value={preGiftTax}
                        onChange={(data: string) => setPreGiftTax(data)}
                        borderClass="bgcWhite w300 h40 pr15 bd1"
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        maxLength={15}
                        inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                        unitText="원"
                    />
                </div>
            </div> 

            {/* 08. 수증자 미성년자 여부 */}
            <div className={(visible08 ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt10'}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>수증자 미성년자 여부</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={doneeMinor}
                        codeArray={Util.getCodeArray("2028")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        // disabled={disabled01}
                        border="1px solid #dddddd"
                        onChangeFunc={(data: string) => {
                            setDoneeMinor(data); 
                            if (data === "Y") {             // 미성년자 ▶ 계산하기
                                setVisible10(false  ); // 10.혼인공제 미표시
                                setMarriageDedu(""  ); // 10.혼인공제 초기화
                                setVisible11(false  ); // 11.출산공제 미표시
                                setChildbirthDedu(""); // 11.출산공제 초기화
                            } else if (data === "N") {      // 성인 ▶ 10.혼인공제, 11.출산공제
                                setVisible10(true);
                                setVisible11(true);
                            } else {}
                        }}
                    />
                </div>
                <div className='dpFlx aiC jcC ml10'>
                    <span className="SpoqaHanSansNeo-Regular fs15 ls08 fc17A840">◀ 증여를 받는 사람이 증여일 현재 만19세 미만</span>
                </div>                
            </div>  

            {/* 09. 기본공제 */}
            <div className={(visible09 ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt10'}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>기본공제</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={basicDedu}
                        codeArray={Util.getCodeArray("2029")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        disabled={true}
                        border="1px solid #dddddd"
                        onChangeFunc={(data: string) => {setBasicDedu(data);}}
                    />
                </div>
            </div>  

            {/* 10. 혼인공제 */}
            <div className={(visible10 ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt10'}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>혼인공제</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={marriageDedu}
                        codeArray={Util.getCodeArray("2029")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        // disabled={disabled01}
                        border="1px solid #dddddd"
                        onChangeFunc={(data: string) => {
                            setMarriageDedu(data);
                        }}
                    />
                </div>
                <div className='dpFlx aiC jcC ml10'>
                    <span className="SpoqaHanSansNeo-Regular fs15 ls08 fc17A840">◀ 혼인신고일 이전 2년 ~ 이후 2년</span>
                </div>                
            </div>  

            {/* 11. 출산공제 */}
            <div className={(visible11 ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt10'}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>출산공제</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={childbirthDedu}
                        codeArray={Util.getCodeArray("2029")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        // disabled={disabled01}
                        border="1px solid #dddddd"
                        onChangeFunc={(data: string) => {
                            setChildbirthDedu(data);
                        }}
                    />
                </div>
                <div className='dpFlx aiC jcC ml10'>
                    <span className="SpoqaHanSansNeo-Regular fs15 ls08 fc17A840">◀ 자녀 출생일로부터 2년 이내</span>
                </div>                
            </div> 

            <div className='w100p dpFlx aiC jcL pt40 pb40'>
                <button className='w360 h60 bgc17A840 dpFlx aiC jcC csP bdN' onClick={() => callTax("G")}>
                    <span className='SpoqaHanSansNeo-Medium fs18 ls07 fcWhite'>계산하기</span>
                </button>
                <button className='w100 h60 bgcF6F6F6 dpFlx aiC jcC csP bd1 bdc878787' onClick={() => goReset()}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>초기화</span>
                </button>
            </div>    

            {/* 증여서 결과표 모달 */}
            <Modal modalOpen={simGiftTaxCalcModalOpen} setModalOpen={setSimGiftTaxCalcModalOpen} title="증여세 세액 산출" dim={true} visible={simGiftTaxCalcModalVsb} closeCallBack={() => setSimGiftTaxCalcModalVsb(false)}>
                <SimGiftTaxCalc setSimGiftTaxCalcModalOpen={setSimGiftTaxCalcModalOpen} setSimGiftTaxCalcModalVsb={setSimGiftTaxCalcModalVsb} giftTaxParam={giftTaxParam} />
            </Modal>
        </div>    
    )
}
export default SimGiftTax;