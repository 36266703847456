/*******************************************************************************
*** InhePersonalDedu ** 상속세 인적공제 계산
*******************************************************************************/
import AppContext       from "js/common/AppContext";
import AssetsUtil       from "js/common/AssetsUtil";
import Util             from "js/common/Util";
import ToolTip          from "js/common/ui/ToolTip";
import ImageRadioBox    from "js/common/ui/input/ImageRadioBox";
import NumberTextBox    from "js/common/ui/input/NumberTextBox";
import MuiListFilter    from "js/common/ui/list/MuiListFilter";
import ForestTableStyle from 'js/common/ui/style/ForestStyle';
import React            from "react";

interface InhePersonalDeduProps {
    setPersonalDeduModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    childNumber: string
    callBack: Function
}

const InhePersonalDedu = ({ setPersonalDeduModalOpen, childNumber, callBack}: InhePersonalDeduProps) => {
    const [under19Number   , setUnder19Number   ] = React.useState<string>("");  // 19세 미만 미성년자 수
    const [older65Number   , setOlder65Number   ] = React.useState<string>("");  // 65세 이상 연로자 수
    const [disabilityNumber, setDisabilityNumber] = React.useState<string>("");  // 장애인 수

    const [childDedu  , setChildDedu  ] = React.useState<string>("");  // 자녀공제
    const [older65Dedu, setOlder65Dedu] = React.useState<string>("");  // 연로자 공제

    const [visible01, setVisible01] = React.useState<boolean>(true ); // 인적공제 인원 입력
    const [visible02, setVisible02] = React.useState<boolean>(false); // 미성년자 공제, 장애인 공제 입력

    // 미성년자 공제 입력 정보
    interface under19InfoProps {
        kidNo  : number   // 미성년자 일련번호
        kidAge : string   // 미성년자 나이
        kidDedu: number   // 미성년자 공제금액
    }

    const [under19Infos, setUnder19Infos] = React.useState<under19InfoProps[]>([]);  // 미성년자 인적공제용 배열

    // 장애인 공제 입력 정보
    interface disabInfoProps {
        disNo    : number   // 장애인 일련번호
        disAge   : string   // 장애인 나이
        disGender: string   // 장애인 성별 공제금액
        disDedu  : number   // 장애인 공제금액
    }

    const [disabInfos, setDisabInfos] = React.useState<disabInfoProps[]>([]);  // 장애인 공제용 배열

    // 미성년자 나이 입력에 따른 공제 금액 계산 처리
    const bindUnder19Dedu = (kidNo: number, kidAge: string) => {
        const tempUnder19Info = [] as under19InfoProps[];  // 임시 배열 생성
        let calKidDedu = 0;
        under19Infos.forEach((under19Info: under19InfoProps) => {
            if (under19Info.kidNo === kidNo) {
                if (Util.isNotEmpty(kidAge)) {
                    if (Number(kidAge) >= 0 && Number(kidAge) < 19) {
                        calKidDedu = (19 - parseInt(kidAge)) * 10000000
                    } else {
                        calKidDedu = 0
                    }
                } else {
                    calKidDedu = 0;
                }
                tempUnder19Info.push({
                    kidNo  : kidNo ,
                    kidAge : kidAge,
                    kidDedu: calKidDedu
                });
            } else {
                tempUnder19Info.push(under19Info);
            }
        });
        setUnder19Infos(tempUnder19Info);
    }

    // 장애인 나이 입력에 따른 공제 금액 계산 처리
    // 남 : (79.9 – 나이) * 1천만원, 여 : (85.6 – 나이) * 1천만원
    const bindDisabDedu1 = (disNo: number, disAge: string) => {
        const tempDisabInfos = [] as disabInfoProps[];  // 임시 배열 생성
        let calDisDedu = 0;
        disabInfos.forEach((disabInfo: disabInfoProps) => {
            if (disabInfo.disNo === disNo) {
                if (Util.isNotEmpty(disAge) && Number(disAge) >= 0 && Util.isNotEmpty(disabInfo.disGender)) {
                    if (disabInfo.disGender === "1001") {   // 남
                        let lifeExpectMan = AssetsUtil.getLifeExpectMan(disAge);
                        calDisDedu = parseInt(lifeExpectMan) * 10000000;
                    } else if (disabInfo.disGender === "1002") {    // 여
                        let lifeExpectWom = AssetsUtil.getLifeExpectWom(disAge);
                        calDisDedu = parseInt(lifeExpectWom) * 10000000;
                    }
                } else {
                    calDisDedu = 0;
                }
                tempDisabInfos.push({
                      disNo    : disNo
                    , disAge   : disAge
                    , disGender: disabInfo.disGender
                    , disDedu  : calDisDedu   

                });
            } else {
                tempDisabInfos.push(disabInfo);
            }
        });
        setDisabInfos(tempDisabInfos);
    }    
    
    // 장애인 성별 입력에 따른 공제 금액 계산 처리
    // 남 : (79.9 – 나이) * 1천만원, 여 : (85.6 – 나이) * 1천만원
    const bindDisabDedu2 = (disNo: number, disGender: string) => {
        const tempDisabInfo = [] as disabInfoProps[];  // 임시 배열 생성
        let calDisDedu = 0;
        disabInfos.forEach((disabInfo: disabInfoProps) => {
            if (disabInfo.disNo === disNo) {
                if (Util.isNotEmpty(disabInfo.disAge) && Number(disabInfo.disAge) >= 0 && Util.isNotEmpty(disGender)) {
                    if (disGender === "1001") {   // 남
                        let lifeExpectMan = AssetsUtil.getLifeExpectMan(disabInfo.disAge);
                        calDisDedu = parseInt(lifeExpectMan) * 10000000;
                    } else if (disGender === "1002") {    // 여
                        let lifeExpectWom = AssetsUtil.getLifeExpectWom(disabInfo.disAge);
                        calDisDedu = parseInt(lifeExpectWom) * 10000000;
                    }
                } else {
                    calDisDedu = 0;
                }
                tempDisabInfo.push({
                      disNo    : disNo
                    , disAge   : disabInfo.disAge
                    , disGender: disGender
                    , disDedu  : calDisDedu                    
                });
            } else {
                tempDisabInfo.push(disabInfo);
            }
        });
        setDisabInfos(tempDisabInfo);
    } 

    // 인적공제 대상 인원 입력 완료
    const goNext = () => {
        if (Util.isEmpty(under19Number   )) {AppContext.openAlert({title: "19세 미만 미성년자 수 미입력", msg: "19세 미만 미성년자 수를 입력하세요.", icon: "check"}); return;}
        if (Number(under19Number) < 0     ) {AppContext.openAlert({title: "19세 미만 미성년자 수 확인"  , msg: "19세 미만 미성년자 수를 입력하세요.", icon: "check"}); return;}
        if (Number(childNumber) < Number(under19Number)) {
            AppContext.openAlert({title: "19세 미만 미성년자 수 확인", msg: "자녀 수보다 19세 미만 미성년자가 많습니다. 미성년자 수를 다시 확인하세요.", icon: "check"}); return;
        }

        if (Util.isEmpty(older65Number   )) {AppContext.openAlert({title: "65세 이상 연로자 수 미입력"  , msg: "65세 이상 연로자 수를 입력하세요."  , icon: "check"}); return;}
        if (Number(older65Number) < 0     ) {AppContext.openAlert({title: "65세 이상 연로자 수 확인"    , msg: "65세 이상 연로자 수를 입력하세요."  , icon: "check"}); return;}
        if (Util.isEmpty(disabilityNumber)) {AppContext.openAlert({title: "장애인 수 미입력"            , msg: "장애인 수를 입력하세요."            , icon: "check"}); return;}
        if (Number(disabilityNumber) < 0  ) {AppContext.openAlert({title: "장애인 수 확인"              , msg: "장애인 수를 입력하세요."            , icon: "check"}); return;}

        // 1. 미성년자 공제용 배열 생성
        const tempUnder19Info = [] as under19InfoProps[];
        for (let i = 0; i < parseInt(under19Number); i++) {
            tempUnder19Info.push({
                kidNo  : i + 1 ,
                kidAge : "",
                kidDedu: 0
            });
        }
        setUnder19Infos(tempUnder19Info);

        // 2. 장애인 공제용 배열 생성
        const tempDisabInfo = [] as disabInfoProps[];
        for (let j = 0; j < parseInt(disabilityNumber); j++) {
            tempDisabInfo.push({
                  disNo  : j + 1
                , disAge : ""
                , disGender: ""
                , disDedu: 0
            });
        }
        setDisabInfos(tempDisabInfo);

        // 3. 자녀 공제 계산
        setChildDedu((parseInt(childNumber) * 50000000).toString());
            
        // 4. 연로자 공제 계산
        setOlder65Dedu((parseInt(older65Number) * 50000000).toString());

        setVisible01(false); // 인원 입력부분 미표시
        setVisible02(true ); // 미성년자, 장애인 공제 정보 입력부분 표시
    }

    // 초기화
    const goReset = () => {
        // setFinAssets("");
        // setFinDebts ("");
        setPersonalDeduModalOpen(false);
    }

    // 이전 화면으로
    const goBefore = () => {
        setVisible01(true ); // 인원 입력부분 표시
        setVisible02(false); // 미성년자, 장애인 공제 정보 입력부분 미표시
    }    

    // 인적공제 계산하기
    const goCalTax = () => {
        // 미성년자 공제금액 합산
        let check19Cnt = 0;
        let under19Dedu = 0;
        if (under19Infos.length > 0) {
            under19Infos.forEach((under19Info:under19InfoProps) => {    
                if (Util.isEmpty(under19Info.kidAge)) check19Cnt = check19Cnt + 1;
                under19Dedu += under19Info.kidDedu;
            });

            if (check19Cnt > 0) {AppContext.openAlert({title: "미성년자 공제 나이 입력", msg: "미성년자의 나이를 입력하세요.", icon: "check"}); return;}
        }

        // 장애인 공제금액 합산
        let checkDisAgeCnt = 0;
        let checkDisGenCnt = 0;
        let disabDedu = 0;
        if (disabInfos.length > 0) {
            disabInfos.forEach((disabInfo:disabInfoProps) => {
                if (Util.isEmpty(disabInfo.disAge)) checkDisAgeCnt = checkDisAgeCnt + 1;
                if (Util.isEmpty(disabInfo.disGender)) checkDisGenCnt = checkDisGenCnt + 1;
                disabDedu += disabInfo.disDedu; 
            });

            if (checkDisAgeCnt > 0) {AppContext.openAlert({title: "장애인 공제 나이 입력", msg: "장애인의 나이를 입력하세요.", icon: "check"}); return;}
            if (checkDisGenCnt > 0) {AppContext.openAlert({title: "장애인 공제 성별 입력", msg: "장애인의 성별을 입력하세요.", icon: "check"}); return;}
        }

        // 공제금액 합산 = 자녀 공제 + 미성년자 공제 + 연로자 공제 + 장애인 공제
        let calPersonDedu = 0;
        calPersonDedu = parseInt(childDedu) + parseInt(older65Dedu) + under19Dedu + disabDedu;
        
        // callback 값
        const retParam = {
            calPersonDedu: calPersonDedu
        }
        callBack(retParam);
        setPersonalDeduModalOpen(false);     
    }


    return (
        <div className='w500 mh200'>
            {/* 인적공제 인원 수 입력 */}
            <div className={(visible01 ? "" : "dpNone-i") + " w100p mb10"}>
                {/* 01. 자녀 수 */}
                <div className={(true ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt20"}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>자녀 수</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={childNumber}
                            // onChange={(data: string) => setInherProp(data)}
                            borderClass="bgcWhite w230 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={1}
                            disabled={true}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="명"
                        />
                    </div>
                </div>

                {/* 02. 19세 미만 미성년 자녀 수 */}
                <div className={(true ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>19세 미만 미성년자 수</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={under19Number}
                            onChange={(data: string) => setUnder19Number(data)}
                            borderClass="bgcWhite w230 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={1}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="명"
                        />
                    </div>
                </div>

                {/* 03. 65세 이상 연로자 수 */}
                <div className={(true ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>
                            <div className="dpFlx aiC jcC">65세 이상 연로자 수
                                <img
                                    src={require('img/common/icon_info_fill_20.png')}
                                    alt="icon_info_fill_20"
                                    className='mr3 csP'
                                    data-tooltip-id="older65ToolTip"
                                    data-tooltip-content={
                                        "배우자 제외"
                                    }
                                />
                                <ToolTip id="older65ToolTip" place='top' clickable={true} className="w300 taL lh20" />
                            </div>                        
                        </span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={older65Number}
                            onChange={(data: string) => setOlder65Number(data)}
                            borderClass="bgcWhite w230 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={1}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="명"
                        />
                    </div>
                </div>

                {/* 04. 장애인 수 */}
                <div className={(true ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>
                            <div className="dpFlx aiC jcC">장애인 수
                                <img
                                    src={require('img/common/icon_info_fill_20.png')}
                                    alt="icon_info_fill_20"
                                    className='mr3 csP'
                                    data-tooltip-id="disabilityToolTip"
                                    data-tooltip-content={
                                        "배우자 포함"
                                    }
                                />
                                <ToolTip id="disabilityToolTip" place='top' clickable={true} className="w300 taL lh20" />
                            </div>
                        </span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={disabilityNumber}
                            onChange={(data: string) => setDisabilityNumber(data)}
                            borderClass="bgcWhite w230 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={1}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="명"
                        />
                    </div>
                </div>

                <div className="dpFlx w100p jcC pt30 pb30">
                    <button className="w100 h40 bgcF6F6F6 dpFlx aiC jcC csP bd1 bdc878787" onClick={() => goReset()}>
                        <span className="SpoqaHanSansNeo-Medium fs14 fc222222">취소</span>
                    </button>
                    <button className="w100 h40 bgc17A840 dpFlx aiC jcC csP bdN" onClick={() => goNext()}>
                        <span className="SpoqaHanSansNeo-Medium fs14 fcWhite">다음</span>
                    </button>
                </div>
            </div>


            {/* 미성년자, 장애인 공제 정보 입력 */}
            <div className={(visible02 ? "" : "dpNone-i") + " w100p"}>
                {/* 01. 자녀공제 */}
                <div className={(true ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w150 h40 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>자녀 공제</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={childDedu}
                            onChange={(data: string) => setChildDedu(data)}
                            borderClass="bgcWhite w230 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            disabled={true}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div>

                {/* 02. 연로자 공제 */}
                <div className={(true ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w150 h40 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>연로자 공제</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={older65Dedu}
                            onChange={(data: string) => setOlder65Dedu(data)}
                            borderClass="bgcWhite w230 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            disabled={true}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div>

                <div className="h20 mb10 dpFlx aiC mt30">
                    <span className="SpoqaHanSansNeo-Medium fs18 ls09 fc222222">미성년자 공제</span>
                </div>
                <div className="w100p"> 
                <MuiListFilter tableInfo={{
                    styleType: ForestTableStyle,
                    fldInfs: [
                        { type: 'text', fldNm: 'kidNo' , wAuto: true, headAlign: "center", dataAlign: "center"  , label: '인원'    }
                      , { type: 'el'                   , width: 150 , headAlign: "center"                       , label: '나이', el: (item: any) =>
                            <NumberTextBox
                                value={item.kidAge}
                                onChange={(data: string) => bindUnder19Dedu(item.kidNo, data)}
                                borderClass="bgcWhite w100p h35 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={2}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                                unitText="세"
                            />
                        }
                      , { type: 'el'                   , width: 180 , headAlign: "center"                       , label: '공제금액', el: (item: any) =>
                            <NumberTextBox
                                value={item.kidDedu}
                                // onChange={(data: string) => setKidDedu(data)}
                                borderClass="bgcWhite w100p h35 pr15 bd1 ml10"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                // maxLength={2}
                                disabled={true}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                                unitText="원"
                            />
                        }
                    ],
                    ordFld: { name: "rowNum", direction: "asc" },
                    dataArr: under19Infos,
                    maxRowNums: [10],
                    trHeight: 40,
                    remainArea: false,
                    paginateClass: "dpNone-i",
                    tableHeadVsb: true
                }}/>
                </div>

                <div className="h20 mb10 dpFlx aiC mt30">
                    <span className="SpoqaHanSansNeo-Medium fs18 ls09 fc222222">장애인 공제</span>
                </div>
                <MuiListFilter tableInfo={{
                    styleType: ForestTableStyle,
                    fldInfs: [
                        { type: 'text', fldNm: 'disNo' , wAuto: true, headAlign: "center", dataAlign: "center", label: '인원'    }
                      , { type: 'el'                   , width: 110 , headAlign: "center"                     , label: '나이', el: (item: any) =>
                            <NumberTextBox
                                value={item.disAge}
                                onChange={(data: string) => bindDisabDedu1(item.disNo, data)}
                                borderClass="bgcWhite w100p h35 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={3}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                                unitText="세"
                            />
                        }
                      , { type: 'el'                  , width: 160 , headAlign: "center"                    , label: '성별', el: (item: any) =>
                            <ImageRadioBox
                                codeList={Util.getCodeArray("1002")}
                                value={item.disGender}
                                btnClass="w24 h24 pr5 pl10"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={10}
                                onChange={(data: string) => {  
                                    bindDisabDedu2(item.disNo, data);
                                }}
                             /> 
                        }      
                      , { type: 'el'                   , width: 150 , headAlign: "center"                     , label: '공제금액', el: (item: any) =>
                            <NumberTextBox
                                value={item.disDedu}
                                // onChange={(data: string) => bindDisabDedu1(item.disNo, data)}
                                borderClass="bgcWhite w100p h35 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                // maxLength={15}
                                disabled={true}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                                unitText="원"
                            />
                        }
                    ],
                    ordFld: { name: "rowNum", direction: "asc" },
                    dataArr: disabInfos,
                    maxRowNums: [10],
                    trHeight: 40,
                    remainArea: false,
                    paginateClass: "dpNone-i",
                    tableHeadVsb: true
                }}/>

                <div className="dpFlx w100p jcC pt30 pb30">
                    <button className="w100 h40 bgcF6F6F6 dpFlx aiC jcC csP bd1 bdc878787" onClick={() => goBefore()}>
                        <span className="SpoqaHanSansNeo-Medium fs14 fc222222">이전</span>
                    </button>
                    <button className="w100 h40 bgc17A840 dpFlx aiC jcC csP bdN" onClick={() => goCalTax()}>
                        <span className="SpoqaHanSansNeo-Medium fs14 fcWhite">계산하기</span>
                    </button>
                </div>
            </div>    
        </div>    

    )
}
export default InhePersonalDedu;