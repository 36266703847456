import { produce } from "immer";

import {
  PropTaxParamProps,
  PropTaxParamVisible,
  PropTaxParamDisabled,
} from "js/common/types/types";

export interface PropTaxState {
  inputValue: PropTaxParamProps;
  inputVisible: PropTaxParamVisible;
  inputDisabled: PropTaxParamDisabled;
}

export type PropTaxAction = { type: PropTaxActionType; payload: any };

export type PropTaxActionType =
  | "UPDATED_HOUSE_NO"
  | "UPDATED_COUPLE_NAME"
  | "UPDATED_COUPLE_SPECIAL_CASE"
  | "UPDATED_HOLDING_PERIOD"
  | "UPDATED_OWNER_AGE"
  | "ADDED_HOUSING"
  | "DELETED_HOUSING"
  | "INIT_BASIC_INFO"
  | "INIT_EXTRA_INFO";

export const initialstate: PropTaxState = {
  inputValue: {
    taxFlag: "",
    houseNo: "",
    housing: [],
    coupleName: "",
    coupleSpecialCase: "",
    holdingPeriod: "",
    ownerAge: "",
  },
  inputVisible: {
    visible01: true,
    visible02: true,
    visible03: false,
    visible04: false,
    visible05: false,
    visible06: false,
    visible07: false,
  },
  inputDisabled: {
    disabled01: false,
    disabled04: false,
  },
};

export const propTaxStatesReducer = produce(
  (draft: PropTaxState, action: PropTaxAction) => {
    const inputValue = draft.inputValue;
    const inputVisible = draft.inputVisible;
    const inputDisabled = draft.inputDisabled;

    switch (action.type) {
      case "UPDATED_HOUSE_NO": {
        inputValue.houseNo = action.payload;

        if (action.payload === "201") {
          // 1주택
          inputVisible.visible03 = true; // 03.지분율 표시
        } else if (action.payload === "202") {
          // 2주택 이상
          inputVisible.visible03 = false;
        }
        break;
      }
      case "UPDATED_COUPLE_NAME": {
        inputValue.coupleName = action.payload;

        if (action.payload === "Y") {
          // 04. 부부공동명의 여 ▶ 5. 부부공동명의 특례적용 여부
          inputVisible.visible05 = true;
        } else if (action.payload === "N") {
          // 04. 부부공동명의 부 ▶ 완료(계산하기)
          inputVisible.visible05 = false; // 5.부부공동명의 특례적용 미표시
          inputValue.coupleSpecialCase = ""; // 5.부부공동명의 특례적용 초기화
        }

        break;
      }
      case "ADDED_HOUSING": {
        if (inputValue.houseNo === "201") {
          // 1주택인 경우
          if (inputValue.housing.length === 0) {
            inputDisabled.disabled01 = true; // 1.주택수 Fixed

            if (action.payload.shareRatio === "100") {
              // 3.지분율: 100% ▶ 6.보유기간
              inputVisible.visible06 = true;
            } else {
              // 3.지분율: 100% 미만 ▶ 4.부부공동명의 여부
              inputVisible.visible04 = true;
            }
          }
        } else if (inputValue.houseNo === "202") {
          // 2주택 이상
          inputDisabled.disabled01 = true; // 1.주택수 Fixed
        }

        inputValue.housing = [...inputValue.housing, action.payload];
        break;
      }
      case "UPDATED_COUPLE_SPECIAL_CASE": {
        inputValue.coupleSpecialCase = action.payload;

        if (action.payload === "Y") {
          // 5.부부공동명의 특례적용 여 ▶ 6.보유기간
          inputVisible.visible06 = true;
          inputDisabled.disabled04 = true; // 4.부부공동명의 Fixed
        } else if (action.payload === "N") {
          // 5.부부공동명의 특례적용 부 ▶ 완료(계산하기)
          inputVisible.visible06 = false; // 6.보유기간 미표시
          inputValue.holdingPeriod = ""; // 6.보유기간 초기화
          inputVisible.visible07 = false; // 7.소유자 연령 미표시
          inputValue.ownerAge = ""; // 7.소유자 연령 초기화
          inputDisabled.disabled04 = true; // 4.부부공동명의 Fixed
        }

        break;
      }
      case "UPDATED_HOLDING_PERIOD": {
        inputValue.holdingPeriod = action.payload;

        // 보유기간 선택 ▶ 7.소유자 연령 표시
        inputVisible.visible07 = true;
        break;
      }
      case "UPDATED_OWNER_AGE": {
        inputValue.ownerAge = action.payload;
        break;
      }
      case "DELETED_HOUSING": {
        inputValue.housing = inputValue.housing.filter(
          (housing) => housing.pnu !== action.payload
        );

        if (inputValue.houseNo === "201") {
          // 1주택인 경우
          inputVisible.visible04 = false;
          inputValue.coupleName = "";
          inputDisabled.disabled04 = false;
          inputVisible.visible05 = false;
          inputValue.coupleSpecialCase = "";
          inputVisible.visible06 = false;
          inputValue.holdingPeriod = "";
          inputVisible.visible07 = false;
          inputValue.ownerAge = "";
        }
        break;
      }
      case "INIT_BASIC_INFO": {
        return initialstate;
      }
      case "INIT_EXTRA_INFO": {
        inputValue.coupleName = "";
        inputValue.coupleSpecialCase = "";
        inputValue.holdingPeriod = "";
        inputValue.ownerAge = "";
      }
    }
  }
);
