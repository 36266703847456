/*******************************************************************************
 You are strictly prohibited to copy, disclose, distribute, modify, or use
 this program in part or as a whole without the prior written consent of
 CallTax. CallTax, owns the intellectual property rights in and to this program.
 (Copyright © 2023 CallTax. All Rights Reserved| Confidential)

 Specification
    1. Program ID    : AncillaryLand.tsx
    2. Program Title : 부수토지 도움말 Modal
    3. Created by    : JH Lee
    4. Create Date   : 2024.03.04.
    5. Reference     :
        - TrsftaxCalc.tsx(양도세 결과표) 페이지에서 호출
*******************************************************************************/
const AncillaryLand = () => {
    return (
        <div className="w700 h100p">
            <span className="SpoqaHanSansNeo-Regular fc666666 ls075 fs15 lh20">
                주택의 부수토지란 주택이라는 건물이 정착하고 있는 면적뿐만 아니라, 주거생활을 하는 데 필요한 공간들로서 주택과 경제적 일체를 이루고 있는 토지를 의미한다.<br/>
                부수토지는 다음과 같이 일정한 범위까지만 비과세하고 있다.
            </span>
            <table className={"forest-list-table w100p th-fm-SpoqaHanSansNeo-Medium "
                            + "th-fw500 th-fs14 th-fc222222 th-bdcDDDDDD th-bdb1 bdcDDDDDD "
                            +  "td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-p15 bdb1 tr-bdb1 mt20 mb20"
            }>
                <colgroup>
                    <col className="w200"></col>
                    <col className="w150"></col>
                    <col className="w150"></col>
                    <col className="w150"></col>
                </colgroup>
                <tbody>
                    <tr className="h40">
                        <th colSpan={3} className="bdr1-i bdcDDDDDD-i">도시지역(국토의 계획 및 이용에 관한 법률 제6조 제1호)</th>
                        <th rowSpan={3}>도시지역 밖</th>
                    </tr>
                    <tr className="h40">
                        <th colSpan={2} className="bdr1-i bdcDDDDDD-i">수도권(수도권정비계획법 제2조 제1호)</th>
                        <th rowSpan={2} className="bdr1-i bdcDDDDDD-i">수도권 밖</th>
                    </tr>
                    <tr className="h40">
                        <th className="bdr1-i bdcDDDDDD-i">주거지역, 상업지역, 공업지역</th>
                        <th className="bdr1-i bdcDDDDDD-i">녹지지역</th>
                    </tr>
                    <tr className="h40">
                        <td>3배</td>
                        <td colSpan={2}>5배</td>
                        <td>10배</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
export default AncillaryLand;