/*******************************************************************************
*** intrcn ** 서비스 소개
*******************************************************************************/
import 'css/portal/intrcn.css';

function MainPage() {
    return (
        <div className='w100p dpFlx jcC introduce'>
            <div className='w1383 mr40 mb125'>
                <div className='w100p dpFlx jcSb'>
                    <div className='w745 bdBox mt75'>
                        <div className='w419 h156 pl128'>
                            <span className='ReplicaStdBold fs130 ls65 fc222222'>ABOUT</span>
                        </div>
                        <div className='w403 h357 ml114 mt104'>
                            <div className='w100p h20'>
                                <span className='SpoqaHanSansNeo-Regular fs16 ls08 fc222222'>첫번째 장점</span>
                            </div>
                            <div className='w100p h28 mt71 mb26'>
                                <img src={require('img/portal/intrcn/icon_about_01.png')} alt="icon_about_01" />
                            </div>
                            <span className='SpoqaHanSansNeo-Medium fs42 ls294 fc222222'>공공데이터를<br/>자동반영 합니다.</span>
                            <div className='w252 h90 mt16'>
                                <span className='SpoqaHanSansNeo-Regular fs18 ls162 fc666666 lh30'>주소기반으로 공공데이터를 자동으로 입력하고 변화하는 조정대상지역을<br/>자동으로 반영합니다.</span>
                            </div>
                        </div>
                    </div>
                    <div className='w627 ml11 h677 mt129 posR'>
                        <img className='posA zIdx2' src={require('img/portal/intrcn/img_aboutkv_01.png')} alt="img_aboutkv_01" />
                        <img className='posA zIdx3 b0 l224' src={require('img/portal/intrcn/img_about_line_01.png')} alt="img_about_line_01" />
                    </div>
                </div>
                <div className='w100p dpFlx'>
                    <div className='w886'>
                        <div className='w100p h765 posR'>
                            <img className='posA zIdx3' src={require('img/portal/intrcn/img_about_line_02_plus.png')} alt="img_about_line_02_plus" />
                            <img className='posA zIdx2 b46 r0' src={require('img/portal/intrcn/img_about_line_02_minus.png')} alt="img_about_line_02_minus" />
                            <img className='posA zIdx2 t35 l15' src={require('img/portal/intrcn/img_aboutkv_02.png')} alt="img_aboutkv_02" />
                        </div>
                    </div>
                    <div className='pt160'>
                        <div className='w403 h357'>
                            <div className='w100p h20'>
                                <span className='SpoqaHanSansNeo-Regular fs16 ls08 fc222222'>두번째 장점</span>
                            </div>
                            <div className='w100p h28 mt71 mb26'>
                                <img src={require('img/portal/intrcn/icon_about_02.png')} alt="icon_about_02" />
                            </div>
                            <span className='SpoqaHanSansNeo-Medium fs42 ls294 fc222222'>복잡한 세법을<br/>자동반영 합니다.</span>
                            <div className='w289 h90 mt16'>
                                <span className='SpoqaHanSansNeo-Regular fs18 ls162 fc666666 lh30'>
                                    소득세법 특례주택<br/>
                                    장기임대주택<br/>
                                    조세특례제한법<br/>
                                    230개의 비과세 유형<br/>
                                    장기보유특별공제 자동반영
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w100p dpFlx jcSb mt33 h579'>
                    <div className='mt113 ml143'>
                        <div className='w453 h357'>
                            <div className='w100p h20'>
                                <span className='SpoqaHanSansNeo-Regular fs16 ls08 fc222222'>세번째 장점</span>
                            </div>
                            <div className='w100p h28 mt71 mb26'>
                                <img src={require('img/portal/intrcn/icon_about_03.png')} alt="icon_about_03" />
                            </div>
                            <span className='SpoqaHanSansNeo-Medium fs42 ls294 fc222222'>종합적이고 지속적인<br/>부동산 자산관리를 합니다.</span>
                            <div className='h90 mt16'>
                                <span className='SpoqaHanSansNeo-Regular fs18 ls162 fc666666 lh30'>
                                    세대보유 부동산 자산을 한눈에<br/>
                                    취득부터 보유 양도까지 부동산 세금을 한 번에<br/>
                                    예상 세액의 자동 산출 및 기존의 납부한 세액의 체크<br/>
                                    최적의 절세 시뮬레이션
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='w583 h100p posR'>
                        <img className='posA zIdx3' src={require('img/portal/intrcn/img_aboutkv_03.png')} alt="img_aboutkv_03" />
                        <img className='posA zIdx2 r0 b0' src={require('img/portal/intrcn/img_about_line_03.png')} alt="img_about_line_03" />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default MainPage;