import React from "react"

interface ImageCheckBoxProps {
    value: boolean
    setValue?: React.Dispatch<React.SetStateAction<boolean>>
    labelText: string
    btnClass: string
    labelClass: string
    onChange?: Function
    disabled?: boolean
}
const ImageCheckBox = ({value, setValue, labelText, btnClass, labelClass, onChange, disabled}: ImageCheckBoxProps) => {
    return (
        <button className="dpFlx aiC csP bdN bgcTp p0" onClick={() => {
            if (disabled) {
                return;
            }
            if (setValue !== undefined) {
                setValue(!value);
            }
            if (onChange !== undefined) {
                onChange(!value);
            }
        }}>
            <img className={btnClass} src={require('img/common/checkbox_button_' + (value ? "on" : "off") + '.png')} alt="checkbox_button_on" />
            <span className={(disabled ? "fcBDBDBD-i " : "") + labelClass}>{labelText}</span>
        </button>
    )
};
export default ImageCheckBox;