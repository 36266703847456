/*******************************************************************************
 *** InhetaxCalc ** 상속세 결과표
*******************************************************************************/
import React      from "react";
import AppContext from "js/common/AppContext";
import Axios      from "js/common/Axios";
import Util       from "js/common/Util";
import ToolTip    from "js/common/ui/ToolTip";

interface InheTaxCalcProps {
    setInheTaxCalcModalVsb: React.Dispatch<React.SetStateAction<boolean>>
    moveStep: Function

    inherProp        : string   // 01. 상속재산
    estiInher        : string   // 02. 추정상속가액
    trustAssets      : string   // 03. 퇴직금/보험금/신탁자산
    spouse           : string   // 04. 배우자 유무
    spousePreGift    : string   // 05. 배우자 사전증여 유무
    spousePreGiftProp: string   // 06. 배우자 사전증여 재산가액
    spousePreGiftTax : string   // 07. 배우자 사전증여세액
    debtAmt          : string   // 08. 채무
    utilityBill      : string   // 09. 공과금
    funeralExpen     : string   // 10. 장례비용
    childNumber      : string   // 11. 자녀 수
    preGift          : string   // 12. (자녀)사전증여 유무
    preGiftProp      : string   // 13. (자녀)사전증여 재산가액
    preGiftTax       : string   // 14. (자녀)사전증여세액
    deduCls          : string   // 15. 공제구분
    personDedu       : string   // 16. 인적공제
    spouseDisabled   : string   // 17. 배우자 장애인 여부
    spouseGender     : string   // 18. 배우자 성별
    spouseAge        : string   // 19. 배우자 나이
    spouseInherProp  : string   // 20. 배우자 상속재산가액
    spouseSucUtilDebt: string   // 21. 배우자 승계 공과금&채무
    directHeir       : string   // 22. 직계존속 상속인 유무
    familyDedu       : string   // 23. 기업상속공제
    farmDedu         : string   // 24. 영농상속공제
    houseDedu        : string   // 25. 동거주택상속공제
    finanDedu        : string   // 26. 금융상속공제
}

const InhetaxCalc = ({
    setInheTaxCalcModalVsb
    , moveStep

    , inherProp
    , estiInher
    , trustAssets
    , spouse
    , spousePreGift
    , spousePreGiftProp
    , spousePreGiftTax
    , debtAmt
    , utilityBill
    , funeralExpen
    , childNumber
    , preGift
    , preGiftProp
    , preGiftTax
    , deduCls
    , personDedu
    , spouseDisabled
    , spouseGender
    , spouseAge
    , spouseInherProp
    , spouseSucUtilDebt
    , directHeir
    , familyDedu
    , farmDedu
    , houseDedu
    , finanDedu
}: InheTaxCalcProps) => {

    const [calcInfo, setCalcInfo] = React.useState<any | undefined>();  // 상속세 계산결과
    const taxFlag = "I";

    // 증여세 파라미터 확인
    React.useEffect(() => {
        Axios.dataAccess({
            url: "api/system/broker/callApi.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                apiName : "taxcalc"
              , tailUrl : "inhetax/calctax"
              , paramStr: taxFlag + "?inher_prop="           + inherProp            // 01. 상속재산가액
                                  + "&esti_inher="           + estiInher            // 02. 추정상속가액
                                  + "&trust_assets="         + trustAssets          // 03. 퇴직금/보험금/신탁자산
                                  + "&spouse="               + spouse               // 04. 배우자 유무
                                  + "&spouse_pre_gift="      + spousePreGift        // 05. 배우자 사전증여 유무
                                  + "&spouse_pre_gift_prop=" + spousePreGiftProp    // 06. 배우자 사전증여 재산가액
                                  + "&spouse_pre_gift_tax="  + spousePreGiftTax     // 07. 배우자 사전증여세액
                                  + "&child_number="         + childNumber          // 08. 자녀 수
                                  + "&pre_gift="             + preGift              // 09. 사전증여 유무
                                  + "&pre_gift_prop="        + preGiftProp          // 10. 사전증여 재산가액
                                  + "&pre_gift_tax="         + preGiftTax           // 11. 사전증여세액
                                  + "&debt_amt="             + debtAmt              // 12. 채무
                                  + "&utility_bill="         + utilityBill          // 13. 공과금
                                  + "&funeral_expen="        + funeralExpen         // 14. 장례비용
                                  + "&dedu_cls="             + deduCls              // 15. 공제구분
                                  + "&person_dedu="          + personDedu           // 16. 인적공제
                                  + "&spouse_disabled="      + spouseDisabled       // 17. 배우자 장애인 여부
                                  + "&spouse_gender="        + spouseGender         // 18. 배우자 성별
                                  + "&spouse_age="           + spouseAge            // 19. 배우자 나이
                                  + "&spouse_inher_prop="    + spouseInherProp      // 20. 배우자 상속재산가액
                                  + "&spouse_suc_util_debt=" + spouseSucUtilDebt    // 21. 배우자 승계 공과금&채무
                                  + "&direct_heir="          + directHeir           // 22. 직계존속 상속자 유무
                                  + "&family_dedu="          + familyDedu           // 23. 가업상속공제
                                  + "&farm_dedu="            + farmDedu             // 24. 영농상속공제
                                  + "&house_dedu="           + houseDedu            // 25. 동거주택상속공제
                                  + "&finan_dedu="           + finanDedu            // 26. 금융상속공제
            },
            onSuccessFn: (res: any) => {
                if (res.item.rtn_list[0].inhe_tax_base === "0") {
                    moveStep(0);
                    AppContext.openAlert({
                        title: "과세 대상 여부",
                        msg: "상속세 납부 대상이 아닙니다.",
                        icon: "check"
                    });
                    return;
                }

                // 증여세 세액 계산 결과
                setCalcInfo(res.item.rtn_list[0]);
                setInheTaxCalcModalVsb(true);
           },
            onFailedFn: () => {
                moveStep(0);
            }
        });
    }, [setInheTaxCalcModalVsb, moveStep, inherProp, estiInher, trustAssets, spouse, spousePreGift, spousePreGiftProp, spousePreGiftTax, childNumber, preGift
      , preGiftProp, preGiftTax, debtAmt, utilityBill, funeralExpen, deduCls, personDedu, spouseDisabled, spouseGender, spouseAge, spouseInherProp, spouseSucUtilDebt
      , directHeir, familyDedu, farmDedu, houseDedu, finanDedu]);

    return (
        <div className='w900 ofA'>
            {/************************** 오른쪽 부분 **************************/}
            <div className="w50p float-l pr20 bdBox">
                <table className={
                    "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD h34 mb40 mt10 " +
                    "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 th-ls1 " +
                    "td-p20 td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls075 td-fc666666 td-bdcDDDDDD"
                }>
                    <colgroup>
                        <col className="w250" />
                        <col className="w150" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>총 상속재산가액</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.inher_prop_amt) : ""}</td>
                        </tr>
                        <tr>
                            <th>(+)사전증여재산</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.pre_gift_prop_amt) : ""}</td>
                        </tr>
                        <tr>
                            <th>(-)채무</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.debt_amt) : ""}</td>
                        </tr>
                        <tr>
                            <th>(-)공과금</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.utility_bill_amt) : ""}</td>
                        </tr>
                        <tr>
                            <th>(-)장례비용</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.funeral_expen_amt) : ""}</td>
                        </tr>
                        <tr>
                            <th>상속세 과세가액</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.inhe_taxable_val) : ""}</td>
                        </tr>
                        <tr>
                            <th>(-)상속공제</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.inhe_dedu_sum_amt) : ""}</td>
                        </tr>
                        <tr>
                            <th>과세표준</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.inhe_tax_base) : ""}</td>
                        </tr>
                        <tr>
                            <th>(*)세율</th>
                            <td className="taR">{calcInfo ? Number(calcInfo.inhe_tax_rate): ""}%</td>
                        </tr>
                        <tr>
                            <th>(-)누진공제액</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.inhe_progre_dedu_amt) : ""}</td>
                        </tr>
                        <tr>
                            <th>
                                <div className="dpFlx flxdRow jcC taL">
                                    산출 세액 (세대 생략 가산액 안내)
                                    <div className="dpFlx aiC jcC taL"
                                        data-tooltip-id="generationSkipToolTip"
                                        data-tooltip-content={
                                                "♠ 상속세 세대생략 할증과세\n\n" +
                                                "자녀를 건너 뛰어 손주에게 상속 시, 즉 세대생략 상속 시 상속세율은 30% 할증됩니다.\n" +
                                                "직계비속의 세대생략 상속 시 상속인이 미성년자로 상속재산가액이 20억을 초과하면\n" +
                                                "할증율은 40%가 적용됩니다.\n"
                                            }>
                                            <img src={require('img/common/icon_info_fill_20.png')} alt="icon_info_fill_20" className='csP pl3' />
                                            <ToolTip id="generationSkipToolTip" place='top' clickable={true} className="w500 lh20" />
                                    </div>
                                </div>
                            </th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.inhe_tax_amt) : ""}</td>
                        </tr>
                        <tr>
                            <th>(-)기납부세액공제</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.pre_inhe_tax_amt) : ""}</td>
                        </tr>
                        <tr>
                            <th>(-)신고납부세액공제</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.report_dedu_amt) : ""}</td>
                        </tr>
                        <tr>
                            <th className="h44"><span className="fs18 fc222222 SpoqaHanSansNeo-Medium ls1">납부할 상속세액</span></th>
                            <td className="taR"><span className="fs18 fc222222 SpoqaHanSansNeo-Medium ls1">{calcInfo ? Util.comma(calcInfo.total_inhe_tax_amt) : ""}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/************************** 오른쪽 부분 **************************/}
            <div className="w50p float-r pl20 bdBox">
                <table className={
                        "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD h34 mb20 mt10 " +
                        "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 th-ls1 " +
                        "td-p20 td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls075 td-fc666666 td-bdcDDDDDD"
                    }>
                    <colgroup>
                        <col className="w80" />
                        <col className="w100" />
                        <col className="w100" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th rowSpan={7}>상속공제액</th>
                            <th>기초공제 + 인적공제</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.basic_person_dedu_amt) : ""}</td>
                        </tr>
                        <tr>
                            <th>일괄공제</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.inhe_sum_dedu_amt) : ""}</td>
                        </tr>
                        <tr>
                            <th>
                                <div className="dpFlx flxdRow jcC taL">배우자공제
                                    <div className="dpFlx aiC jcC taL"
                                        data-tooltip-id="spouseDeducToolTip"
                                        data-tooltip-content={
                                                "♠ 배우자 공제\n\n"
                                              + "☞ 배우자 상속금액 5억 미만 : 5억\n"
                                              + "☞ 배우자 상속금액 5억 이상 : 아래 3개 중에서 적은 금액\n"
                                              + "1. 배우자 상속금액\n"
                                              + "2. 배우자 법정지분 초과분\n"
                                              + "3. 30억\n"
                                            }>
                                            <img src={require('img/common/icon_info_fill_20.png')} alt="icon_info_fill_20" className='csP pl3' />
                                            <ToolTip id="spouseDeducToolTip" place='top' clickable={true} className="w500 lh20" />
                                    </div>
                                </div>
                            </th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.spouse_deduc_amt) : ""}</td>
                        </tr>
                        <tr>
                            <th>가업상속공제</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.family_dedu_amt) : ""}</td>
                        </tr>
                        <tr>
                            <th>영농상속공제</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.farm_dedu_amt) : ""}</td>
                        </tr>
                        <tr>
                            <th>동거주택상속공제</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.house_dedu_amt) : ""}</td>
                        </tr>
                        <tr>
                            <th>금융상속공제</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.finan_dedu_amt) : ""}</td>
                        </tr>
                        <tr>
                            <th colSpan={2}><span className="fs16 fc222222">합계</span></th>
                            <td className="taR"><span className="fs16 fc222222">{calcInfo ? Util.comma(calcInfo.inhe_dedu_sum_amt) : ""}</span></td>
                        </tr>
                    </tbody>
                </table>
                <div className="w153 h29 bdrd4 dpFlx aiC">
                    <span className="SpoqaHanSansNeo-Regular fs14 ls08 fc2C2C2C fwB">◐ 배우자 공제 계산</span>
                </div>
                <table className={
                    "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD " +
                    "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 " +
                    "td-fs13 td-fm-SpoqaHanSansNeo-Regular td-ls1 td-fc666666 td-bdcDDDDDD"
                    }>
                <colgroup>
                    <col className="w400"></col>
                    {/* <col className="w280"></col> */}
                </colgroup>
                <tbody>
                    <tr className="h29">
                        <th>배우자 상속금액</th>
                    </tr>
                    <tr>
                        <td className="taL">&nbsp;&nbsp;
                            배우자가 상속받은 상속재산가액(사전증여재산가액 및 추정상속재산가액 제외)<br/>
                            - 배우자가 승계하기로 한 공과금 및 채무액
                        </td>
                    </tr>
                    <tr className="h10">
                        <td></td>
                    </tr>
                    <tr className="h32">
                        <th>배우자 법정지분 초과분</th>
                    </tr>
                    <tr>
                        <td className="taL">
                            (상속재산가액 + 추정상속재산 + 10년이내 증여재산가액 중 상속인수증분 - 공과금 - 채무)<br/>
                            * 배우자 법정지분<br/>
                            - 배우자의 사전증여재산에 대한 증여세 과세표준
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>

            <div className='w100p dpFlx aiC jcC'>
                <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => {
                    moveStep(0);
                }}>
                    <span className='SpoqaHanSansNeo-Medium fs16 fcWhite fw500'>확인</span>
                </div>
            </div>
        </div>
    )
}
export default InhetaxCalc;