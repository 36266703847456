import React from "react";
import { useRecoilState } from "recoil";
import * as Atom from "js/common/Atom";

const Intro = () => {
    const [introVsb, setIntroVsb] = useRecoilState(Atom.introVsb);
    const [introTabFg, setIntroTabFg] = React.useState<string>("1");

    React.useEffect(() => {
        if (introVsb) {
            setTimeout(() => {
                setIntroVsb(false);
            }, 2000);
        }
    }, [introVsb, setIntroVsb]);

    return (
        <div className="w100p dpFlx jcC">
            <div className={'intro-on w1600 ' + (introVsb ? "mxh900" : "")}>
                <div className={'posA w1600 h27 zIdx1 ' + (introVsb ? "dpFlx" : "dpNone")}>
                    <div className={'flx1 bdt2 pl20 pt5 csP ' + (introTabFg === "1" ? "bdc17A840" : "bdcOp30")} onClick={() => setIntroTabFg("1")}>
                        <span className={'fs14 ReplicaStdBold ' + (introTabFg === "1" ? "fc17A840" : "fcWhite")}>INTERVIEW</span>
                    </div>
                    <div className={'flx1 bdt2 ml5 pl20 pt5 csP ' + (introTabFg === "2" ? "bdc17A840" : "bdcOp30")} onClick={() => setIntroTabFg("2")}>
                        <span className={'fs14 ReplicaStdBold ' + (introTabFg === "2" ? "fc17A840" : "fcWhite")}>ABOUT</span>
                    </div>
                    <div className={'flx1 bdt2 ml5 pl20 pt5 csP ' + (introTabFg === "3" ? "bdc17A840" : "bdcOp30")} onClick={() => setIntroTabFg("3")}>
                        <span className={'fs14 ReplicaStdBold ' + (introTabFg === "3" ? "fc17A840" : "fcWhite")}>NEWS</span>
                    </div>
                </div>
                <div className='w100p zIdx0'>
                    <div className={"intro-bg " + (introTabFg === "1" ? "dpBlock" : "dpNone")}>
                        <div className="h132 pt524 pl195">
                            <span className="SpoqaHanSansNeo-Medium fs53 ls265 fcWhite">복잡한 특례법을<br/>반영하여 계산합니다.</span>
                        </div>
                        <div className="h28 pt46 pl195">
                            <span className="SpoqaHanSansNeo-Medium fs22 ls11 fcWhite">이기돌 세무사 인터뷰</span>
                        </div>
                        <div className="h17 pt21 pl195 dpFlx aiC csP">
                            <span className="ReplicaStdBold fs14 ls07 fcWhite">더 알아보기</span>
                            <img src={require('img/portal/index/icon_chevron_right.png')} alt="right-arrow" />
                        </div>
                    </div>
                    <div className={"intro-bg " + (introTabFg === "2" ? "dpBlock" : "dpNone")}>
                        <div className="h132 pt524 pl195">
                            <span className="SpoqaHanSansNeo-Medium fs53 ls265 fcWhite">2복잡한 특례법을<br/>반영하여 계산합니다.</span>
                        </div>
                        <div className="h28 pt46 pl195">
                            <span className="SpoqaHanSansNeo-Medium fs22 ls11 fcWhite">2이기돌 세무사 인터뷰</span>
                        </div>
                        <div className="h17 pt21 pl195 dpFlx aiC csP">
                            <span className="ReplicaStdBold fs14 ls07 fcWhite">더 알아보기</span>
                            <img src={require('img/portal/index/icon_chevron_right.png')} alt="right-arrow" />
                        </div>
                    </div>
                    <div className={"intro-bg " + (introTabFg === "3" ? "dpBlock" : "dpNone")}>
                        <div className="h132 pt524 pl195">
                            <span className="SpoqaHanSansNeo-Medium fs53 ls265 fcWhite">3복잡한 특례법을<br/>반영하여 계산합니다.</span>
                        </div>
                        <div className="h28 pt46 pl195">
                            <span className="SpoqaHanSansNeo-Medium fs22 ls11 fcWhite">3이기돌 세무사 인터뷰</span>
                        </div>
                        <div className="h17 pt21 pl195 dpFlx aiC csP">
                            <span className="ReplicaStdBold fs14 ls07 fcWhite">더 알아보기</span>
                            <img src={require('img/portal/index/icon_chevron_right.png')} alt="right-arrow" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Intro;