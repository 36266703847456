import { useState, useReducer, useEffect } from "react";
import { useSetRecoilState } from "recoil";

import { addrSearchResultState } from "js/common/Atom";
import { Body, MainContainer } from "js/mobile/common/layout";
import Header from "js/mobile/common/CalcHeader";
import CalcStep from "js/mobile/common/CalcStep";
import BasicInfo from "./BasicInfo";
import AddressInfo from "./AddressInfo";
import DirectAssetAddressInfo from "js/mobile/AddressInfo/DirectAssetAddressInfo";
import ExtraInfo from "./ExtraInfo";
import CalcStepButton from "js/mobile/common/CalcStepButton";
import TransTaxCalcResult from "js/mobile/TransTaxCalcResult";
import { useTranCalcSteps } from "./useTranCalcSteps";
import {
  AddrSearchResult,
  Step,
  TranTaxCalcResult,
  TranTaxParamProps,
} from "js/common/types/types";
import { tranTaxStatesReducer, initialState } from "./tranTaxStatesReducer";
import Axios from "js/common/Axios";
import AppContext from "js/common/AppContext";

function TranTaxCalc() {
  const setAddrSearchResult = useSetRecoilState(addrSearchResultState);
  const [tranTaxState, dispatch] = useReducer(
    tranTaxStatesReducer,
    initialState
  );
  const {
    tranStepList,
    currentTranStep,
    handleClickBackStep,
    handleClickNextStep,
    moveToNextAddrSubStep,
    handleClickReset,
    //onResetInAddresInfo,
    backDisabled,
    nextDisabled,
    isLastStep,
    initTranStep,
    addrSearchQuery,
    setAddrSearchQuery,
    addrQueryResult,
    setAddrQueryResult,
  } = useTranCalcSteps(tranTaxState, dispatch);

  const [showCalcResult, setShowCalcResult] = useState<boolean>(false);
  const [apiResult, setApiResult] = useState<string>("-1");

  const [calcResult, setCalcResult] = useState<TranTaxCalcResult | null>(null);
  const [calcResultType, setCalcResultType] = useState<string>("");

  const usingStepList = tranStepList.filter((step: Step) => step.useYn);
  const currentStepIndex = usingStepList.findIndex(
    (step: Step) => step.selected
  );
  const addrInfoSubStepList =
    usingStepList.find((step: Step) => step.stepId === "TRAN02")?.subSteps ??
    [];

  const extraInfoSubStepList =
    usingStepList.find((step: Step) => step.stepId === "TRAN03")?.subSteps ??
    [];

  useEffect(() => {
    // API 정상 호출 여부 확인
    // 주소 조회 API 확인
    Axios.dataAccess({
      url: "common/mobile/callApi.do",
      methodType: "post",
      paramType: "object",
      paramData: {
        apiName: "openapi",
        tailUrl: "addr/juso",
        paramStr: encodeURI("상암미르웰") + ",1,10",
      },
      onSuccessFn: (res: any) => {
        setApiResult(res.item.rtn_code);
        setAddrSearchResult((prev: AddrSearchResult) => ({
          ...prev,
          error: res.item.rtn_code,
          //error: "-1",
        }));
      },
      skipFailedAlert: true,
      onFailedFn: () => {
        setApiResult("-1");
        setAddrSearchResult((prev: AddrSearchResult) => ({
          ...prev,
          error: "-1",
        }));
      },
    });
  }, [setAddrSearchResult]);

  const onClickNext = () => {
    if (isLastStep) {
      transferTaxCalc(tranTaxState.inputValue);
    } else {
      handleClickNextStep();
    }
  };

  const transferTaxCalc = (inputValue: TranTaxParamProps) => {
    Axios.dataAccess({
      url: "common/mobile/callApi.do",
      methodType: "post",
      paramType: "object",
      paramData: {
        apiName: "taxcalc",
        tailUrl: "simpletax/transfertax",
        paramStr:
          "T" +
          "?estate_se=" +
          inputValue.estateSe + // 01.부동산의 종류
          "&estate_fom1=" +
          inputValue.estateFom1 + // 02.주택구분1(주택)
          "&estate_fom2=" +
          inputValue.estateFom2 + // 03.주택구분2(오피스텔)
          "&land_cls=" +
          inputValue.landCls + // 04.토지구분
          "&acqs_src1=" +
          inputValue.acqsSrc1 + // 05.취득구분1(아파트/연립)
          "&acqs_src2=" +
          inputValue.acqsSrc2 + // 06.취득구분2(오피스텔)
          "&acqs_cause1=" +
          inputValue.acqsCause1 + // 07.취득원인1(기존 아파트)
          "&acqs_cause2=" +
          inputValue.acqsCause2 + // 08.취득원인2(분양권/오피스텔)
          "&acqs_cause3=" +
          inputValue.acqsCause3 + // 09.취득원인3(조합원입주권)
          "&acqs_cause4=" +
          inputValue.acqsCause4 + // 10.취득원인4(다세대/다가구/다중/단독)
          "&hse_num=" +
          inputValue.hseNum + // 11.보유주택 수
          //   + "&rdnm_adres="            + rdnmAdres           // 12.도로명 주소
          //   + "&lnm_adres="             + lnmAdres            // 13.지번 주소
          "&mdat_trget_area=" +
          inputValue.mdatTrgetArea + // 14.조정대상지역
          "&share_ratio=" +
          inputValue.shareRatio + // 15.지분율
          "&mng_disp_app_date=" +
          inputValue.mngDispAppDate + // 16.관리처분인가일
          "&mng_disp_app_date_amt=" +
          inputValue.mngDispAppDateAmt + // 17.관리처분인가일 평가액
          "&acqu_date_preHse=" +
          inputValue.acquDatePreHse + // 18.종전주택 취득일자
          "&acqu_date_inherit=" +
          inputValue.acquDateInherit + // 18-1.피상속인 취득일자
          "&resi_period_pre_hse=" +
          inputValue.resiPeriodPreHse + // 19.종전주택 거주기간
          "&acqu_price=" +
          inputValue.acquPrice + // 20.취득가액
          "&nece_expen_pre_hse=" +
          inputValue.neceExpenPreHse + // 21.종전주택 필요경비
          "&appr_date_new_hse=" +
          inputValue.apprDateNewHse + // 22.신규주택 사용승인일
          "&resi_period_new_hse=" +
          inputValue.resiPeriodNewHse + // 23.신규주택 거주기간
          "&transfer_date=" +
          inputValue.transferDate + // 24.양도일자
          "&transfer_price=" +
          inputValue.transferPrice + // 25.양도가액
          "&nece_expen_new_hse=" +
          inputValue.neceExpenNewHse + // 26.필요경비
          "&liqud_amt=" +
          inputValue.liqudAmt + // 27.청산금 여부
          "&pay_or_rec_amt=" +
          inputValue.payOrRecAmt + // 28.납부(수령)금액
          "&legal_dong_code=" +
          inputValue.legalDongCode, // 29.법정동코드
      },
      onSuccessFn: (res: any) => {
        if (res.item.rtn_list[0].transfer_profit === "0") {
          // 양도차익이 zero인 경우
          setShowCalcResult(false);
          AppContext.openAlert({
            title: "과세 대상 여부",
            msg: "양도세 납부 대상이 아닙니다.",
            icon: "check",
          });
          return;
        }

        // 양도세 세액 계산 결과
        setCalcResult(res.item.rtn_list[0]);
        setCalcResultType(res.item.rtn_list[0].rtn_form); // 결과표 양식
        setShowCalcResult(true);
      },
      onFailedFn: () => {
        setShowCalcResult(false);
      },
    });
  };

  const handleClickBackInResult = () => {
    setCalcResult(null);
    setCalcResultType("");
    setShowCalcResult(false);

    initTranStep();
    dispatch({
      type: "INIT_BASIC_INFO",
      payload: "",
    });
  };

  if (showCalcResult) {
    return (
      <TransTaxCalcResult
        calcResult={calcResult}
        inputParams={tranTaxState.inputValue}
        resultType={calcResultType}
        onClickBack={handleClickBackInResult}
      />
    );
  } else {
    return (
      <Body>
        <Header
          title="양도세 계산"
          totalStepCount={usingStepList.length}
          currentStep={currentStepIndex + 1}
          onClickBack={() => console.info(">>> back")}
        />
        <CalcStep
          stepInfoList={usingStepList.filter((step: Step) => step.useYn)}
        />
        <MainContainer>
          {currentTranStep && currentTranStep.stepId === "TRAN01" && (
            <BasicInfo
              inputValue={tranTaxState.inputValue}
              inputVisible={tranTaxState.inputVisible}
              inputDisabled={tranTaxState.inputDisabled}
              onUpdateInput={dispatch}
            />
          )}
          {currentTranStep &&
            currentTranStep.stepId === "TRAN02" &&
            (apiResult === "0" ? (
              <AddressInfo
                stepList={addrInfoSubStepList}
                searchText={addrSearchQuery}
                setSearchText={setAddrSearchQuery}
                searchResult={addrQueryResult}
                setSearchResult={setAddrQueryResult}
                onSelectAddr={moveToNextAddrSubStep}
              />
            ) : (
              <DirectAssetAddressInfo />
            ))}
          {currentTranStep && currentTranStep.stepId === "TRAN03" && (
            <ExtraInfo
              stepList={extraInfoSubStepList}
              inputValue={tranTaxState.inputValue}
              inputVisible={tranTaxState.inputVisible}
              inputTitle={tranTaxState.inputTitle}
              onUpdateInput={dispatch}
            />
          )}
          <CalcStepButton
            onClickBack={handleClickBackStep}
            onClickNext={onClickNext}
            onClickReset={handleClickReset}
            backDisabled={backDisabled}
            nextDisabled={nextDisabled}
            isLastStep={isLastStep}
          />
        </MainContainer>
      </Body>
    );
  }
}

export default TranTaxCalc;
