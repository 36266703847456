/*******************************************************************************
*** Header ** 모바일 증여세 Header
*******************************************************************************/
import styled                      from "styled-components";
import { useNavigate }             from "react-router-dom";
import ArrowBackOutlinedIcon       from "@mui/icons-material/ArrowBackOutlined";
import { calculatorPcWebMaxWidth } from "js/common/const";

type Props = {
    totalStepCount: number;
    currentStep: number;
    onClickBack: () => void;
};

function Header({ totalStepCount, currentStep, onClickBack }: Props) {
    const navigate = useNavigate();

    // 메인 메뉴로
    const handleClickBack = () => {
        onClickBack();
        navigate("/simpletax");
    };

    return (
        <AcqsHeader>
            <button className="backButton" onClick={handleClickBack}>
                <ArrowBackOutlinedIcon fontSize="small" />
            </button>
            <div className="progress">
                <span className="number">{currentStep}</span>
                <span className="per">/</span>
                <span className="number">{totalStepCount}</span>
            </div>
            <div className="logo">
                <img src={require("img/common/logo_navy_dark.png")} alt="calltax logo" />
                <span className="title">증여세 계산</span>
            </div>
        </AcqsHeader>
    );
}

const AcqsHeader = styled.header`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 50px;
    padding: 0 20px;
    background-color: ${(props) => props.theme.color.bg};

    @media (min-width: 1201px) {
        max-width: ${calculatorPcWebMaxWidth};
    }

    .backButton {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        width: 24px;
        height: 24px;
        font-size: 2.5em;
        color: ${(props) => props.theme.color.sNavy};
    }

    .progress {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 500;
        font-size: 1.2em;
        line-height: 1.29;
        letter-spacing: -0.058em;
        color: ${(props) => props.theme.color.sNavy};

        .number {
            font-weight: 500;
        }

        .per {
            font-weight: 100;
        }
    }

    .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        img {
            width: 64px;
        }

        .title {
            font-family: "Spoqa Han Sans Neo";
            font-weight: 500;
            font-size: 1.6em;
            line-height: 0.71875;
            letter-spacing: -0.04375em;
            color: ${(props) => props.theme.color.sNavy};
        }
    }
`;

export default Header;
