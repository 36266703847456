
/***********************************************************************************
*** Law8 ** 조세특례제한법 97조 장기임대주택에 대한 양도소득세 감면 Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law8Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}
export default function Law8({ setModalOpen, setLawModalTitle, callBack, callMode }: Law8Props) {
    React.useEffect(() => {
        setLawModalTitle("조세특례제한법 97조 장기임대주택에 대한 양도소득세 감면");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className='w100p hAuto ofA mb30'>
                <table className={
                    'forest-list-table bdt1 bdb1 bdcDDDDDD ' +
                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 th-pw10 ' +
                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075'
                }>
                   <colgroup>
                        <col className='w80'></col>
                        <col className='w80'></col>
                        <col className='w160'></col>
                        <col className='w50'></col>
                        <col className='w160'></col>
                        <col className='w70'></col>
                        <col className='w70'></col>
                        <col className='w70'></col>
                        <col className='w160'></col>
                        <col className='w80'></col>
                        <col className='w80'></col>
                        <col className='w100'></col>
                        <col className='w60'></col>
                        <col className='w60'></col>
                    </colgroup>
                    <tbody>
                        <tr className='h40'>
                            <th rowSpan={2}>구분</th>
                            <th rowSpan={2}>주택구분</th>
                            <th rowSpan={2}>주택규모</th>
                            <th rowSpan={2}>취득시기</th>
                            <th rowSpan={2}>신축기간<br/>(사용승인일)</th>
                            <th colSpan={2}>등록</th>
                            <th rowSpan={2}>민특법 분류</th>
                            <th rowSpan={2}>임대개시</th>
                            <th rowSpan={2}>임대기간</th>
                            <th colSpan={2}>추가질문</th>
                            <th colSpan={2}>혜택<br />(양도세 감면)</th>
                        </tr>
                        <tr className='h29'>
                            <th>지자체</th>
                            <th>세무서</th>
                            <th>임대호수</th>
                            <th>입주사실여부</th>
                            <th>5년<br/>이상</th>
                            <th>10년<br/>이상</th>
                        </tr>
                        <tr className="h50">
                            <th rowSpan={2}>개별사항</th>
                            <td>공동주택</td>
                            <td rowSpan={2}>국민주택규모 이하<br />(건물연면적의 2배<br/> 이내의 부수토지 포함)</td>
                            <td>-</td>
                            <td>1985.12.31. 이전</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td rowSpan={2}>2000.12.31. 이전<br/>5호 이상 임대개시 완료</td>
                            <td rowSpan={2}>5년<br/>이상</td>
                            <td rowSpan={2}>5호<br/>이상</td>
                            <td>1986.1.1 현재<br/>없어야 함</td>
                            <td>50%</td>
                            <td>100%</td>
                        </tr>
                        <tr className="h50">
                            <td>모든주택</td>
                            <td>-</td>
                            <td>1986.1.1.~2000.12.31</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>50%</td>
                            <td>100%</td>
                        </tr>
                        <tr className='h110'>
                            <th>공통사항</th>
                            <td className='taL pt15 pb15 pl15' colSpan={13}>
                                1. 비과세 판정 시 주택 수에서 제외<br/>
                                2. 다주택 중과배제(유예)<br/>
                                3. 공동소유 임대주택의 경우 호수에 지분비율을 곱하여 임대호수를 산정한다.<br/>
                                4. 피상속인의 임대주택을 상속받아 임대하는 경우에는 피상속인의 임대기간을 상속인의 임대기간에 통산한다.<br/>
                                5. 임대기간중 3개월이하 공실은 임대기간으로 봄
                            </td>
                        </tr>
                        <tr className='h110'>
                            <th>기타사항</th>
                            <td className='taL pt15 pb15 pl15'colSpan={13}>
                                1. 의무임대기간 충족 전 일반주택 양도시 다주택 중과배제, 당해 의무해태가 발생한 날이 속하는 달의 말일부터 2개월 이내에 신고·납부한다.<br/>
                                2. 의무임대기간 총족 전 일반주택 양도시 비과세 규정을 적용, 의무임대기간 내에 감면주택 양도시 당초 비과세 유지한다.<br/>
                                3. 장기임대주택이 재건축 중인 입주권으로 전환된 경우에 입주권상태로 양도하는 경우에도 감면된다.<br/>
                                4. 장기임대에서 소유로 전환한 주택에 대하여 1세대 1주택 비과세 판단시 보유기간은 소유주택으로 변경한 날부터 기산한다.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}