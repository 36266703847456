/*******************************************************************************
*** Assets ** 세대원 추가/수정/삭제, 보유자산 등록/수정/삭제
*******************************************************************************/
import React from 'react';
import { useNavigate } from "react-router-dom";
import Axios from 'js/common/Axios';
import MuiListFilter from 'js/common/ui/list/MuiListFilter';
import ForestTableStyle from 'js/common/ui/style/ForestStyle';
import AppContext from 'js/common/AppContext';
import RmstnfamDialog from 'js/system/assets/dialog/RmstnfamDialog';
import HshldScope from './help/HshldScope';
import Modal from 'js/common/ui/modal/Modal';
import * as Atom from "js/common/Atom";
import { useRecoilValue, useSetRecoilState } from "recoil";

function Assets() {
    const navigate = useNavigate();
    const hshldSn = useRecoilValue(Atom.hshldSn);
    const hshldNm = useRecoilValue(Atom.hshldNm);
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [callMode, setCallMode] = React.useState<string>("");
    const [rmstnfamSn, setRmstnfamSn] = React.useState<number>(-1);
    const [hshldScopeModalOpen, setHshldScopeModalOpen] = React.useState<boolean>(false);
    const setCurrFormMode = useSetRecoilState(Atom.currFormMode);
    const [rmstnfamDataArr, setRmstnfamDataArr] = React.useState<any[]>([]);
    const [rmstnfamSelTime, setRmstnfamSelTime] = React.useState<Date>(new Date());
    const [assetsDataArr, setAssetsDataArr] = React.useState<[]>([]);
    const [assetsSelTime, setAssetsSelTime] = React.useState<Date>(new Date());

    // 자산 신규작성
    const goAssetsRegPage = (item: any) => {
        setCurrFormMode("reg");
        navigate("/system/assets/reg/Assets/1001/1002/1001/" + item.rmstnfamSn);
    }

    // 자산 수정
    const goAssetsEditPage = (item: any) => {
        setCurrFormMode("mod");
        AppContext.showSpinnerDim();
        if (item.stepCode === "1009") {
            navigate("/system/assets/view/Assets/" + item.assetsSn);
        } else {
            navigate("/system/assets/mod/Assets/" + item.assetsSn);
        }
    }

    // 자산 복사
    const copyAssetsEditPage = (item: any) => {
        AppContext.openAlert({
            title: "자산 복사",
            msg: item.rowNum + "번 자산을 복사하시겠습니까?",
            icon: "check",
            confirmText: "복사",
            handleConfirm: () => {
                Axios.dataAccess({
                    url: "system/assets/copyAssetsInfo.do",
                    methodType: "post",
                    paramType: "object",
                    paramData: {
                        trgtAssetsSn: item.assetsSn
                    },
                    onSuccessFn: () => {
                        setAssetsSelTime(new Date());
                    }
                });
            }
        });
    }

    // 세대원 삭제
    const delRmstnfamInfo = (rmstnfamSn: number) => {
        Axios.dataAccess({
            url: "system/rmstnfam/selRmstnfamAssetsCnt.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                rmstnfamSn: rmstnfamSn
            },
            onSuccessFn: (res: any) => {
                let warnMsg = "";
                if (res.item > 0) {
                    warnMsg = "입력된 자산이 " + res.item + "건 존재합니다. 삭제하시겠습니까?";
                } else {
                    warnMsg = "세대원 정보를 삭제하시겠습니까?";
                }
                AppContext.openAlert({
                    title: "세대원 삭제",
                    msg: warnMsg,
                    icon: "check",
                    confirmText: "삭제",
                    handleConfirm: () => {
                        AppContext.showSpinner();
                        Axios.dataAccess({
                            url: "system/rmstnfam/delRmstnfamInfo.do",
                            methodType: "post",
                            paramType: "object",
                            paramData: {
                                rmstnfamSn: rmstnfamSn
                            },
                            onSuccessFn: function () {
                                AppContext.hideSpinner();
                                setRmstnfamSelTime(new Date());
                            }
                        });
                    }
                });
            }
        });
    };

    // 자산 삭제
    const delAssetsInfo = (item: any) => {
        AppContext.openAlert({
            title: "자산 삭제",
            msg: item.rmstnfamNm + " 세대원의 자산을 삭제하시겠습니까?",
            icon: "check",
            confirmText: "삭제",
            handleConfirm: () => {
                AppContext.showSpinner();
                Axios.dataAccess({
                    url: "system/assets/delAssetsInfo.do",
                    methodType: "post",
                    paramType: "object",
                    paramData: {
                        assetsSn: item.assetsSn
                    },
                    onSuccessFn: () => {
                        setAssetsSelTime(new Date());
                    }
                });
            }
        });
    }

    // 세대원 팝업 (추가/수정)
    const openRmstnfamDialogPage = (callMode: string, rmstnfamSn: number) => {
        setCallMode(callMode);
        setRmstnfamSn(rmstnfamSn);
        setModalOpen(true);
    };

    // 도움말 - 세대의 범위
    const openHshldScope = () => {
        setHshldScopeModalOpen(true);
    }

    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/rmstnfam/selRmstnfamList.do",
            methodType: "post",
            paramType: "json",
            paramData: {
                hshldSn: hshldSn
            },
            onSuccessFn: (res: any) => {
                setRmstnfamDataArr(res.list);
                Axios.dataAccess({
                    url: "system/assets/selAssetsList.do",
                    methodType: "post",
                    paramType: "json",
                    paramData: {
                        hshldSn: hshldSn,
                        assetsSe: "1001",
                        estateSttus: "1002",
                        estateSe: "1001",
                        routeKey: "assets"
                    },
                    onSuccessFn: (res: any) => {
                        setAssetsDataArr(res.list);
                        AppContext.hideSpinner();
                    }
                });
            }
        });
    }, [rmstnfamSelTime, assetsSelTime, hshldSn]);

    return (
        <React.Fragment>
            <div className="w100p bgcF6F6F6">
                <div className="dpFlx flxdCol jcC aiC pt60 pb30">
                    <div className='taC pb30'>
                        <span className="dpBlock fcBlack fs38 ls266 SpoqaHanSansNeo-Medium">똑똑한 자산관리의 시작</span>
                    </div>
                    <div className="w153 h40 bgc1EA65B bdrd40 dpFlx jcC aiC csP" onClick={() => openRmstnfamDialogPage("reg", -1)}>
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>세대원 추가하기</span>
                        <img className="pl5" src={require('img/common/icon_add_white_20.png')} alt="icon_add_white_20" />
                    </div>
                </div>
                <div className="dpFlx jcC pt30 pb100">
                    <div className='w1200'>
                        <div className='h36 dpFlx aiE pb20'>
                            <img className="mr10" src={require('img/common/icon_step.png')} alt="icon_step" />
                            <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 pb3'>
                                <span className='fc17A840'>{hshldNm}</span>
                                <span className='fc222222'>님의 세대원</span>
                            </span>
                            <img className='pl3 pb4 csP' src={require('img/common/icon_info_fill_20.png')} alt="icon_info_fill_20" onClick={openHshldScope} />
                        </div>
                        <MuiListFilter tableInfo={{
                            tableHeadVsb: true,
                            styleType: ForestTableStyle,
                            fldInfs: [
                                { type: 'text', fldNm: 'rowNum', width: 58, headAlign: "center", dataAlign: "center", label: 'No.' },
                                { type: 'text', fldNm: 'rmstnfamNm', width: 116, headAlign: "center", dataAlign: "center", label: '세대원명' },
                                { type: 'code', fldNm: 'rmstnfamSe', wAuto: true, headAlign: "center", dataAlign: "center", label: '가족관계', codeTy: '1001' },
                                { type: 'code', fldNm: 'sexdstn', width: 60, headAlign: "center", dataAlign: "center", label: '성별', codeTy: '1002' },
                                { type: 'text', fldNm: 'rmstnfamAge', width: 60, headAlign: "center", dataAlign: "center", label: '나이' },
                                { type: 'text', fldNm: 'brthdy', width: 100, headAlign: "center", dataAlign: "center", label: '생년월일' },
                                { type: 'code', fldNm: 'incomeAt', width: 70, headAlign: "center", dataAlign: "center", label: '소득충족', codeTy: '1032' },
                                { type: 'code', fldNm: 'mrrgAt', width: 70, headAlign: "center", dataAlign: "center", label: '결혼여부', codeTy: '1032' },
                                { type: 'code', fldNm: 'adresAdres', width: 70, headAlign: "center", dataAlign: "center", label: '주소동일', codeTy: '1032' },
                                { type: 'code', fldNm: 'lvlhdSamenss', width: 70, headAlign: "center", dataAlign: "center", label: '생계동일', codeTy: '1032' },
                                { type: 'code', fldNm: 'acqsRmstnfamAt', width: 70, headAlign: "center", dataAlign: "center", label: '취득기준', codeTy: '1032' },
                                { type: 'code', fldNm: 'trsfRmstnfamAt', width: 70, headAlign: "center", dataAlign: "center", label: '양도기준', codeTy: '1032' },
                                { type: 'el', width: 95, headAlign: "center", el: (item: any) => (
                                    <div className="dpFlx aiC jcC w90 h24 bdrd40 bdBox bd1 bdcForest csP" onClick={() =>goAssetsRegPage(item)}>
                                        <img className="csP pr3" src={require('img/common/icon_money20.png')} alt="icon_money20" />
                                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc666666'>자산등록</span>
                                    </div>
                                )},
                                { type: 'el', width: 70, headAlign: "center", el: (item: any) => (
                                    <div className='dpFlx aiC csP' onClick={() => openRmstnfamDialogPage("mod", item.rmstnfamSn)}>
                                        <img className="csP pr3" src={require('img/common/icon_edit20.png')} alt="icon_edit20" />
                                        <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">수정</span>
                                    </div>
                                )},
                                { type: 'el', width: 70, headAlign: "center", el: (item: any) => (
                                    <div className={'aiC csP ' + (item.rmstnfamSe === "1000" ? "dpNone" : "dpFlx")} onClick={() => delRmstnfamInfo(item.rmstnfamSn)}>
                                        <img className="csP pr3" src={require('img/common/icon_delete20.png')} alt="icon_delete20" />
                                        <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">삭제</span>
                                    </div>
                                )}
                            ],
                            ordFld: { name: "rowNum", direction: "asc" },
                            dataArr: rmstnfamDataArr,
                            maxRowNums: [10, 20, 30],
                            trHeight: 48,
                            remainArea: false,
                            srchVsb: true,
                            headerVsb: true,
                            paginateClass: "pt40"
                        }}>
                        </MuiListFilter>
                        <div className='h36 dpFlx aiE pb20 pt40'>
                            <img className="mr10" src={require('img/common/icon_step_money.png')} alt="icon_step_money" />
                            <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 pb3'>
                                <span className='fc222222'>보유자산</span>
                            </span>
                        </div>
                        <MuiListFilter tableInfo={{
                            tableHeadVsb: true,
                            styleType: ForestTableStyle,
                            fldInfs: [
                                { type: 'text', fldNm: 'rowNum', width: 58, headAlign: "center", dataAlign: "center", label: 'No.' },
                                { type: 'text', fldNm: 'rmstnfamNm', width: 90, headAlign: "center", dataAlign: "center", label: '세대원명' },
                                { type: 'code', fldNm: 'rmstnfamSe', width: 90, headAlign: "center", dataAlign: "center", label: '가족관계', codeTy: '1001' },
                                { type: 'code', fldNm: 'estateDetailSe', width: 110, headAlign: "center", dataAlign: "center", label: '자산구분', codeTy: "1006" },
                                { type: 'code', fldNm: 'estatePrpos', width: 90, headAlign: "center", dataAlign: "center", label: '주택용도', codeTy: "1008" },
                                { type: 'code', fldNm: 'acqsCause', width: 100, headAlign: "center", dataAlign: "center", label: '취득원인', codeTy: "1035" },
                                { type: 'text', fldNm: 'fullRdnmAdres', wAuto: true, headAlign: "center", dataAlign: "left", label: '주소' },
                                { type: 'text', fldNm: 'acqsDe', width: 100, headAlign: "center", dataAlign: "center", label: '취득일' },
                                { type: 'el', fldNm: 'stepCode', width: 100, headAlign: "center", label: '등록상태', codeTy: "1004", el: (item: any) => (
                                    <div className={'dpFlx aiC jcC w60 h24 bdrd40 bdBox ' + (item.stepCode === "1009" ? "bgc17A840" : "bgcF1F8FF bd1 bdc007CE8")}>
                                        <span className={"SpoqaHanSansNeo-Medium fs12 ls06 " + (item.stepCode === "1009" ? "fcWhite" : "fc007CE8")}>{item.stepCode === "1009" ? "등록완료" : "진행중"}</span>
                                    </div>
                                )},
                                { type: 'el', width: 70, headAlign: "center", el: (item: any) => (
                                    <div className='dpFlx aiC csP' onClick={() => goAssetsEditPage(item)}>
                                        <img className="csP pr3" src={require('img/common/icon_edit20.png')} alt="icon_edit20" />
                                        <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">수정</span>
                                    </div>
                                )},
                                { type: 'el', width: 70, headAlign: "center", el: (item: any) => (
                                    <div className='dpFlx aiC csP' onClick={() => delAssetsInfo(item)}>
                                        <img className="csP pr3" src={require('img/common/icon_delete20.png')} alt="icon_delete20" />
                                        <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">삭제</span>
                                    </div>
                                )},
                                { type: 'el', width: 70, headAlign: "center", el: (item: any) => (
                                    <div className='dpFlx aiC csP' onClick={() => copyAssetsEditPage(item)}>
                                        <img className="csP pr3" src={require('img/common/icon_copy20.png')} alt="icon_copy20" />
                                        <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">복사</span>
                                    </div>
                                )}
                            ],
                            ordFld: { name: "rowNum", direction: "asc" },
                            dataArr: assetsDataArr,
                            maxRowNums: [10, 20, 30],
                            trHeight: 48,
                            remainArea: false,
                            srchVsb: true,
                            headerVsb: true,
                            paginateClass: "pt40"
                        }}/>
                    </div>
                </div>
            </div>
            <Modal modalOpen={modalOpen} setModalOpen={setModalOpen} title={"세대원 " + (callMode === "reg" ? "추가" : "수정")} dim={true} visible={true}>
                <RmstnfamDialog setModalOpen={setModalOpen} setRmstnfamSelTime={setRmstnfamSelTime} callMode={callMode} rmstnfamSn={rmstnfamSn} />
            </Modal>
            <Modal modalOpen={hshldScopeModalOpen} setModalOpen={setHshldScopeModalOpen} title="세대의 정의" dim={true} visible={true}>
                <HshldScope setModalOpen={setHshldScopeModalOpen} />
            </Modal>
        </React.Fragment>
    )
}
export default Assets;