/*******************************************************************************
*** index ** 홈페이지
*******************************************************************************/
import React from "react";
import AppContext from "js/common/AppContext";
import Axios from "js/common/Axios";
import Util from "js/common/Util";
import 'css/portal/index.css';
import Modal from 'js/common/ui/modal/Modal';
import ImageCheckBox from "js/common/ui/input/ImageCheckBox";
import { useNavigate } from 'react-router-dom';

const Index = () => {
    const navigate = useNavigate();
    const tokenCrtDt = localStorage.getItem("tokenCrtDt");
    interface boardProps {
        boardSn: number
        boardTy: string
        boardTitle: string
        boardCtt: string
        boardCttVsb: boolean
    }
    const [noticeBoardInfo, setNoticeBoardInfo] = React.useState<boardProps>();
    const [communityBoardInfo, setCommunityBoardInfo] = React.useState<boardProps>();
    const [qnaBoardInfo, setQnaBoardInfo] = React.useState<boardProps>();
    const [faqBoardList, setFaqBoardList] = React.useState<boardProps[]>([]);

    const [launchingModalOpen, setLaunchingModalOpen] = React.useState<boolean>(false);  // 시스템 오픈 모달
    const [saveNoShow, setSaveNoShow] = React.useState<boolean>(false); // 하루동안 안 보기

    const today   = new Date();
    const saveDay = today.getDate().toString(); // 오늘 일자

    const openFaqBoardCtt = (faqBoardSn: number) => {
        let tempArr: boardProps[] = [];
        faqBoardList.forEach((faqBoardInfo: boardProps) => {
            if (faqBoardInfo.boardSn === faqBoardSn) {
                if (faqBoardInfo.boardCttVsb) {
                    faqBoardInfo.boardCttVsb = false;
                } else {
                    faqBoardInfo.boardCttVsb = true;
                }
            }
            tempArr.push(faqBoardInfo);
        })
        setFaqBoardList(tempArr);
    }

    React.useEffect(() => {
        // 하루 동안 팝업 안 보기
        // const localDate = localStorage.getItem("today"); // 체크한 일자 확인
        // if (localDate !== saveDay) {
        //     setLaunchingModalOpen(true);
        // } else {
        //     setLaunchingModalOpen(false);
        // }

        AppContext.showSpinner();
        Axios.dataAccess({
            url: "portal/board/selHomeBoardInfo.do",
            methodType: "post",
            onSuccessFn: (res: any) => {
                if (res.item.homeBoardList.length > 0) {
                    res.item.homeBoardList.forEach((homeBoardInfo: boardProps) => {
                        if (homeBoardInfo.boardTy === "1001") {
                            setNoticeBoardInfo(homeBoardInfo);
                        } else if (homeBoardInfo.boardTy === "1002") {
                            setCommunityBoardInfo(homeBoardInfo);
                        } else if (homeBoardInfo.boardTy === "1004") {
                            setQnaBoardInfo(homeBoardInfo);
                        }
                    });
                }
                setFaqBoardList(res.item.faqBoardList);
                AppContext.hideSpinner();
            }
        });
    }, [saveDay]);

    // 하루 동안 안 보기 처리
    const setNoShowOneDay = () => {
        if (saveNoShow) {
            localStorage.setItem("today", saveDay);
        } else {
            localStorage.removeItem("today");
        }
    }

    // 간편 계산기 바로가기
    const goPage = () => {
        if (Util.isEmpty(tokenCrtDt)) {
            AppContext.openAlert({
                title: "서비스 이용 안내",
                msg: "로그인 후 사용 가능한 서비스입니다.\n로그인 페이지로 이동하시겠습니까?",
                icon: "warning",
                closeText: "닫기",
                confirmText: "이동",
                handleConfirm: () => {
                    navigate("/portal/login"); // 로그인 페이지로 이동
                }
            });
            return;
        } else {
            navigate("/system/simpletax/simpleCallTax"); // 간편계산기 페이지로 이동
        }    
    }

    return (
        <React.Fragment>
            <div className='w100p h100p dpFlx jcC flxdCol'>
                <div className="w100p bgcF6F6F6 mw1210 dpFlx jcC">
                    <div className="bdBox w1210 pt30 pb30 fs0">
                        <div className="w280 h350 float-l bgc17A840 mr30 p30 bdBox dpFlx flxdCol aiFs jcSb csP" onClick={() => AppContext.goPage("/system/hshld")}>
                            <img src={require('img/portal/index/icon_man.png')} alt="icon_man" />
                            <div className="w100p">
                                <span className="SpoqaHanSansNeo-Bold fs23 ls115 fcWhite lh29">납세자관리</span>
                                <div className="dpFlx aiC">
                                    <span className="SpoqaHanSansNeo-Bold fs23 ls115 fcWhite">납세자 등록하기</span>
                                    <img src={require('img/portal/index/icon_add_white.png')} alt="icon_add_white" />
                                </div>
                            </div>
                        </div>
                        <div className="w280 h350 float-l bgcWhite mr30 p30 bdBox dpFlx flxdCol aiFs jcSb csP" onClick={() => AppContext.goPage("/system/acqstax")}>
                            <img src={require('img/portal/index/icon_home.png')} alt="icon_home" />
                            <div className="w100p">
                                <span className="SpoqaHanSansNeo-Bold fs23 ls115 fc999999 lh29">취득자산</span>
                                <div className="dpFlx aiC">
                                    <span className="SpoqaHanSansNeo-Bold fs23 ls115 fc222222">취득자산 등록하기</span>
                                    <img src={require('img/portal/index/icon_add_black.png')} alt="icon_add_black" />
                                </div>
                            </div>
                        </div>
                        <div className="w280 h350 float-l bgcWhite mr30 p30 bdBox dpFlx flxdCol aiFs jcSb csP" onClick={() => AppContext.goPage("/system/proptax")}>
                            <img src={require('img/portal/index/icon_money.png')} alt="icon_home" />
                            <div className="w100p">
                                <span className="SpoqaHanSansNeo-Bold fs23 ls115 fc999999 lh29">종합부동산세</span>
                                <div className="dpFlx aiC">
                                    <span className="SpoqaHanSansNeo-Bold fs23 ls115 fc222222">종부세 계산하기</span>
                                    <img src={require('img/portal/index/icon_arrow_black.png')} alt="icon_arrow_black" />
                                </div>
                            </div>
                        </div>
                        <div className="w280 h350 float-l bgcWhite p30 bdBox dpFlx flxdCol aiFs jcSb csP" onClick={() => AppContext.goPage("/system/trsftax")}>
                            <img src={require('img/portal/index/icon_calculate.png')} alt="icon_calculate" />
                            <div className="w100p">
                                <span className="SpoqaHanSansNeo-Bold fs23 ls115 fc999999 lh29">처분관리</span>
                                <div className="dpFlx aiC">
                                    <span className="SpoqaHanSansNeo-Bold fs23 ls115 fc222222">양도세 계산하기</span>
                                    <img src={require('img/portal/index/icon_arrow_black.png')} alt="icon_arrow_black" />
                                </div>
                            </div>
                        </div>
                        <div className="w280 h730 float-l bgcWhite mr30 mt30 p30 bdBox dpFlx flxdCol">
                            <div className="flx1 bdb1 bdcCCCCCC csP" onClick={() => goPage()}>
                                <div className='w100p dpFlx aiC jcC'>
                                    <div className='w120 h56 bgcWhite dpFlx aiC jcL'>
                                        <img src={require('img/portal/index/icon_tax_cal.png')} alt="icon_tax_cal" />
                                    </div>
                                    <div className='w210 h56 dpFlx aiC jcL'>
                                        <span className="SpoqaHanSansNeo-Bold fs25 ls1 fc222222">콜택스 계산기</span>
                                    </div>
                                </div>
                                <div className="w100p mt30">
                                    <span className="SpoqaHanSansNeo-Medium fs20 ls115 fc999999 lh40">
                                        취득세<br/>
                                        재산세/종합부동산세<br/>
                                        양도세<br/>
                                        증여세<br/>
                                        상속세
                                    </span>
                                </div>
                            </div>

                            <div className="flx1 dpFlx flxdCol">
                                <div className="h297 bdb1 bdcCCCCCC dpFlx aiC jcC">
                                    <span className="ReplicaStdBold fs160 ls8 fcBlack">{Util.getSpecificDate("", "day", 0, "DD", "")?.substring(6, 8)}</span>
                                </div>
                                <div className="dpFlx jcSb pt12">
                                    <span className="ReplicaStdBold fs20 ls1 fc222222">{Util.getMonthName(Number(Util.getSpecificDate("", "day", 0, "DD", "")?.substring(4, 6)))}</span>
                                    <span className="ReplicaStdBold fs20 ls1 fc222222">{Util.getSpecificDate("", "day", 0, "DD", "")?.substring(0, 4)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="w590 h730 float-l bgc17A840 mr30 mt30 p30 bdBox dpFlx flxdCol jcSb">
                            <span className="SpoqaHanSansNeo-Medium fs48 ls33 fcWhite tdU tuOs10 tdTn3 lh90">
                                부동산 My Data로<br/>
                                One-Stop 부동산 자산관리!
                            </span>
                            <span className="SpoqaHanSansNeo-Medium fs28 ls33 fcWhite mb125">
                                조정대상지역 자동화!<br/><br/>
                                다양한 주택분류 시스템!<br/><br/>
                                취득세/재산세/종합부동산세/양도소득세를 한번에!
                            </span>
                            <div className="h36 bdt1 bdcCCCCCC pt12 bdBox">
                                <span className="ReplicaStdBold fs20 ls1 fcWhite">ABOUT</span>
                            </div>
                        </div>
                        <div className="w280 h350 float-l mt30 p30 bdBox dpFlx flxdCol jcSb main-card-kv csP">
                            <span className="SpoqaHanSansNeo-Bold fs23 ls115 fcWhite lh30">전문가<br/>이기돌 세무사</span>
                            <div className="h36 bdt1 bdcCCCCCC pt12 bdBox">
                                <span className="ReplicaStdBold fs20 ls1 fcWhite">INTERVIEW</span>
                            </div>
                        </div>

                        {/* 이용가이드 */}
                        <div className="w280 h350 float-l bgc17A840 mt30 p30 bdBox dpFlx flxdCol aiFs jcSb">
                            <img src={require('img/portal/index/icon_finder.png')} alt="icon_finder" />
                            <span className="SpoqaHanSansNeo-Medium fs20 ls115 fcWhite lh53 pb20 tdU tuOs10 tdTn1 csP"
                                onClick={() => AppContext.goPage("/portal/notice")}>이용가이드는 공지사항으로</span>
                            <span className="SpoqaHanSansNeo-Bold fs23 ls115 fcWhite lh40 pb30 tdU tuOs10 tdTn1 csP"
                                onClick={() => {
                                    window.open("https://www.youtube.com/@young1430", "_blank", "noopener");
                                }}>CallTax TV<br/>바로가기</span>
                            <div className="h36 bdt1 bdcCCCCCC pt12 bdBox w220">
                                <span className="SpoqaHanSansNeo-Bold fs20 ls1 fcWhite">이용가이드</span>
                            </div>
                        </div>

                        <div className="w280 h350 float-l bgc17A840 mt30 mr30 p30 bdBox dpFlx flxdCol jcSb">
                            <div className="dpFlx flxdCol jcSb h100p">
                                <img src={require('img/portal/index/icon_talk.png')} className="w50 h50" alt="icon_talk" />
                                <div className="pb19">
                                    <span className="SpoqaHanSansNeo-Bold fs15 ls075 fcWhite lh30">고객센터<br/></span>
                                    <span className="ReplicaStdRegular fs42 ls21 fcWhite lh40"></span>
                                </div>
                            </div>
                            <div className="h36 bdt1 bdcCCCCCC pt12 bdBox">
                                <span className="ReplicaStdBold fs20 ls1 fcWhite">SERVICE CENTER</span>
                            </div>
                        </div>
                        <div className="w280 h350 float-l bgcWhite mt30 mr30 p30 bdBox dpFlx flxdCol jcSb csP" onClick={() => AppContext.goPage("/portal/notice")}>
                            <div>
                                <div className="pb3">
                                    <span className="SpoqaHanSansNeo-Bold fs23 ls115 fc999999">공지사항</span>
                                </div>
                                <span className="SpoqaHanSansNeo-Bold fs23 ls115 fc222222 lh30">{noticeBoardInfo?.boardTitle}</span>
                            </div>
                            <div className="h36 bdt1 bdcCCCCCC pt12 bdBox">
                                <span className="ReplicaStdBold fs20 ls1 fc222222">NEWS</span>
                            </div>
                        </div>
                        <div className="w280 h350 float-l bgcWhite mt30 mr30 p30 bdBox dpFlx flxdCol jcSb csP" onClick={() => AppContext.goPage("/system/expert")}>
                            <div>
                                <div className="pb3">
                                    <span className="SpoqaHanSansNeo-Bold fs23 ls115 fc999999">전문가 커뮤니티</span>
                                </div>
                                <span className="SpoqaHanSansNeo-Bold fs23 ls115 fc222222 lh30">{communityBoardInfo?.boardTitle}</span>
                            </div>
                            <div className="h36 bdt1 bdcCCCCCC pt12 bdBox">
                                <span className="ReplicaStdBold fs20 ls1 fc222222">COMMUNITY</span>
                            </div>
                        </div>
                        <div className="w280 h350 float-l bgcWhite mt30 p30 bdBox dpFlx flxdCol jcSb csP" onClick={() => AppContext.goPage("/system/qna")}>
                            <div>
                                <div className="pb3">
                                    <span className="SpoqaHanSansNeo-Bold fs23 ls115 fc999999">질의/응답</span>
                                </div>
                                <span className="SpoqaHanSansNeo-Bold fs23 ls115 fc222222 lh30">{qnaBoardInfo?.boardTitle}</span>
                            </div>
                            <div className="h36 bdt1 bdcCCCCCC pt12 bdBox">
                                <span className="ReplicaStdBold fs20 ls1 fc222222">QnA</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"w100p mw1210 bgc2B2B2B jcC pt30 pb20 bdBox " + (faqBoardList.length === 0 ? "dpNone" : "dpFlx")}>
                    <div className="w1150 fs0">
                        <div className="h57 w100p dpFlx aiE bdBox pb12">
                            <span className="ReplicaStdBold fs18 ls09 fcWhite">FAQ</span>
                        </div>
                        {faqBoardList.map((faqBoardInfo, index) =>
                            <div key={index} className="bdt1 bdc555555 w100p dpFlx flxdCol pt23 pb26 csP" onClick={() => openFaqBoardCtt(faqBoardInfo.boardSn)}>
                                <div className="w100p dpFlx aiC jcSb h23">
                                    <span className="SpoqaHanSansNeo-Medium fs18 ls1 fc999999">{faqBoardInfo.boardTitle}</span>
                                    <span className="SpoqaHanSansNeo-Regular fs37 ls1 fcWhite">{faqBoardInfo.boardCttVsb ? "-" : "+"}</span>
                                </div>
                                <div className={"pt26 " + (faqBoardInfo.boardCttVsb ? "" : "dpNone")}>
                                    <span className="SpoqaHanSansNeo-Regular fs16 ls08 fcWhite lh10" dangerouslySetInnerHTML={{ __html: faqBoardInfo.boardCtt}}/>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* 콜택스 계산기 오픈 공지 팝업 */}
            <Modal modalOpen={launchingModalOpen} setModalOpen={setLaunchingModalOpen} title="콜택스(CallTax) 계산기 오픈" dim={true} visible={true}>
            <div className='w100p'>
                <div className='w100p pb30 dpFlx aiC jcC'>
                    {/* <span className="SpoqaHanSansNeo-Bold fs36 ls1 fcBlack">One-Stop 부동산 자산관리</span> */}
                    <span className="SpoqaHanSansNeo-Bold fs36 ls1 fcBlack">상담을 위한 쉽고 빠른 계산기</span>
                </div>
                <div className='w100p dpFlx aiC'>
                    <div className='w100p h100p'>
                        <img src={require('img/portal/index/calltax_cal_open.png')} width={630} height={354} alt="intro-calltax" />
                    </div>
                </div>
                {/* <div className='w50p float-l bdBox taC csP'>
                    <span className="SpoqaHanSansNeo-Medium fs16 ls115 pt10 fc505050 tdU tuOs1 tdTn1"
                        onClick={() => {
                            AppContext.goPage("/portal/notice");
                            setLaunchingModalOpen(false);
                        }}>공지사항 바로가기</span>
                </div>
                <div className='w50p float-r bdBox taC csP'>
                    <span className="SpoqaHanSansNeo-Medium fs16 ls115 pt10 fc505050 tdU tuOs1 tdTn1"
                        onClick={() => {
                            window.open("https://www.youtube.com/watch?v=Ea5lsQUWsL8", "_blank", "noopener");
                            setLaunchingModalOpen(false);
                        }}>유튜브 바로가기</span>
                </div> */}
                <div className='w100p dpFlx aiC jcSb pt10'>
                    <div className='w50p float-l bdBox pt10'>
                        <ImageCheckBox
                            value={saveNoShow}
                            setValue={setSaveNoShow}
                            labelText='오늘 하루 안 보기'
                            btnClass='w20 h20'
                            labelClass='SpoqaHanSansNeo-Regular fs14 ls07 fc666666 pl5'
                        />
                    </div>
                    <div className='w50p w100 float-r h24 bgc17A840 dpFlx aiC jcC csP mt10' onClick={() => {
                            setLaunchingModalOpen(false);
                            setNoShowOneDay();
                        }}>
                            <span className='SpoqaHanSansNeo-Regular fs14 ls07 fcWhite'>닫기</span>
                    </div>
                </div>
            </div>
            </Modal>
        </React.Fragment>
    );
}
export default Index;