import React from "react";
import { useParams } from 'react-router-dom';
import { useWizard } from "use-wizard";
import FormReducer from 'js/common/FormReducer';
import { useNavigate } from "react-router-dom";
import Util from "js/common/Util";
import Axios from "js/common/Axios";
import AssetsBase from "./edit/AssetsBase";
import AssetsAcq from "./edit/AssetsAcqs";
import AssetsRar from "./edit/AssetsRar";
import AssetsPreView from "./edit/AssetsPreView";
import AppContext from "js/common/AppContext";
import AssetsLaw from "./edit/AssetsLaw";
import { useRecoilValue } from "recoil";
import * as Atom from "js/common/Atom";
import AssetsAcqsQestn from "./edit/AssetsAcqsQestn";

function AssetsEdit() {
    const currFormMode = useRecoilValue(Atom.currFormMode);
    const navigate = useNavigate();
    const params = useParams();
    const hshldSn = useRecoilValue(Atom.hshldSn);
    const hshldNm = useRecoilValue(Atom.hshldNm);
    const [step, wizard] = useWizard();
    const [renderFlag, setRenderFlag] = React.useState<boolean>(false);
    const [currStepCode, setCurrStepCode] = React.useState<number>(1001);
    const [estateSttus, setEstateSttus] = React.useState<string>("");

    const goAssetsList = React.useCallback(() => {
        if (params.callPage === "Assets") {
            navigate('/system/assets');
        } else if (params.callPage === "Acqstax") {
            navigate('/system/acqstax');
        } else if (params.callPage === "TrsftaxTest") {
            navigate('/system/trsftax/test');
        }
        return;
    }, [navigate, params.callPage]);

    const [form, dispatchForm] = React.useReducer(FormReducer, {
        goAssetsList: goAssetsList,
        goAssetsView: () => navigate("/system/assets/view/" + params.callPage + "/" + params.assetsSn),
        currStepCode: currStepCode,
        setCurrStepCode: setCurrStepCode
    });

    const WizardStep = () => {
        switch (step) {
            case 1001:
                return <AssetsBase {...{step, wizard, form, dispatchForm}} />;
            case 1002:
                return <AssetsAcq {...{step, wizard, form, dispatchForm}} />;
            case 1003:
                return <AssetsRar {...{step, wizard, form, dispatchForm}} />;
            case 1006:
                if (estateSttus === "1002") {
                    return <AssetsLaw {...{step, wizard, form, dispatchForm}} />;
                } else if (estateSttus === "1003") {
                    return <AssetsAcqsQestn {...{step, wizard, form, dispatchForm}} />;
                } else {
                    return <>페이지 정보가 없습니다.</>
                }
            case 1009:
                return <AssetsPreView {...{step, wizard, form, dispatchForm}} />;
            default:
                return <>페이지 정보가 없습니다.</>;
        }
    }

    React.useEffect(() => {
        if (Util.isEmpty(currFormMode)) {
            goAssetsList();
        }
        if (renderFlag) {
            return;
        }
        Axios.dataAccess({
            url: "system/rmstnfam/selHshldRmstnfamList.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                hshldSn: hshldSn
            },
            onSuccessFn: (res1: any) => {
                if (Util.isEmpty(params.assetsSn)) {
                    if (Util.isEmpty(params.rmstnfamSn)) {
                        AppContext.openAlert({
                            title: "세대원 정보 오류",
                            msg: "세대원 식별 키 정보가 없습니다.",
                            icon: "error"
                        });
                        return;
                    }
                    setEstateSttus(params.estateSttus || "");
                    Axios.dataAccess({
                        url: "system/rmstnfam/selRmstnfamInfo.do",
                        methodType: "post",
                        paramType: "object",
                        paramData: {
                            rmstnfamSn: Number(params.rmstnfamSn)
                        },
                        onSuccessFn: (res2: any) => {
                            let hshldRmstnfamSn = null;
                            // 납세자의 세대원 key 추출
                            res1.list.forEach((item: any) => {
                                if (item.rmstnfamSe === "1000") {
                                    hshldRmstnfamSn = item.rmstnfamSn;
                                    return;
                                }
                            });
                            const rghpsnList = [{
                                rmstnfamSe: "1000",
                                rghpsnSn: 1,
                                rghpsnSe: "1000",
                                rmstnfamSn: hshldRmstnfamSn,
                                rghpsnNm: hshldNm,
                                qotaSe: "1001",
                                qotaRate: hshldRmstnfamSn === res2.item.rmstnfamSn ? 100 : 0
                            }];
                            const landRghpsnList = [{
                                rmstnfamSe: "1000",
                                rghpsnSn: 1,
                                rghpsnSe: "1000",
                                rmstnfamSn: hshldRmstnfamSn,
                                rghpsnNm: hshldNm,
                                qotaSe: "1002",
                                qotaRate: hshldRmstnfamSn === res2.item.rmstnfamSn ? 100 : 0
                            }];
                            if (hshldRmstnfamSn !== res2.item.rmstnfamSn) {
                                rghpsnList.push({
                                    rmstnfamSe: res2.item.rmstnfamSe,
                                    rghpsnSn: 2,
                                    rghpsnSe: "1001",
                                    rmstnfamSn: res2.item.rmstnfamSn,
                                    rghpsnNm: res2.item.rmstnfamNm,
                                    qotaSe: "1001",
                                    qotaRate: 0
                                });
                                landRghpsnList.push({
                                    rmstnfamSe: res2.item.rmstnfamSe,
                                    rghpsnSn: 2,
                                    rghpsnSe: "1001",
                                    rmstnfamSn: res2.item.rmstnfamSn,
                                    rghpsnNm: res2.item.rmstnfamNm,
                                    qotaSe: "1002",
                                    qotaRate: 0
                                });
                            }
                            dispatchForm({
                                type: "UPDATE_KEY_VALUES",
                                value: {
                                    callPage: params.callPage,
                                    rmstnfamSn: Number(params.rmstnfamSn),
                                    assetsSe: params.assetsSe,
                                    estateSttus: params.estateSttus,
                                    estateSe: params.estateSe,
                                    rghpsnList: rghpsnList,
                                    landRghpsnList: landRghpsnList,
                                    rmstnfamList: res1.list,
                                    rmstnfamSe: res2.item.rmstnfamSe,
                                    rmstnfamNm: res2.item.rmstnfamNm
                                }
                            });
                            wizard.goToStep(1001);
                            setRenderFlag(true);
                        }
                    });
                } else {
                    Axios.dataAccess({
                        url: "system/assets/selRmstnfamInfoByAssets.do",
                        methodType: "post",
                        paramType: "object",
                        paramData: {
                            assetsSn: Number(params.assetsSn)
                        },
                        onSuccessFn: (res2: any) => {
                            setEstateSttus(res2.item.estateSttus);
                            dispatchForm({
                                type: "UPDATE_KEY_VALUES",
                                value: {
                                    callPage: params.callPage,
                                    assetsSn: Number(params.assetsSn),
                                    assetsSe: res2.item.assetsSe,
                                    estateSttus: res2.item.estateSttus,
                                    estateSe: res2.item.estateSe,
                                    rmstnfamSn: res2.item.rmstnfamSn,
                                    stepCode: res2.item.stepCode,
                                    rmstnfamList: res1.list,
                                    rmstnfamSe: res2.item.rmstnfamSe,
                                    rmstnfamNm: res2.item.rmstnfamNm
                                }
                            });
                            if (res2.item.stepCode === '1009') {
                                wizard.goToStep(1001); // 작성완료면 첫 번째 스텝으로
                            } else {
                                wizard.goToStep(Number(res2.item.stepCode)); // 특정 스텝으로 이동
                            }
                            setRenderFlag(true);
                        }
                    });
                }
            }
        });
    }, [renderFlag, wizard, params, currFormMode, goAssetsList, hshldSn, hshldNm]);

    React.useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [currStepCode]);

    return (
        <React.Fragment>
            <div className="h126 pt30 bdBox">
                <span className="SpoqaHanSansNeo-Medium fs38 ls266 fcBlack">
                    {estateSttus === "1002" ? "보유 " : ""}
                    {estateSttus === "1003" ? "취득 " : ""}
                    자산등록
                </span>
            </div>
            <div className="w100p dpFlx flxdCol aiC jcC bgcF8F8F8">
                {renderFlag ? <WizardStep /> : <></>}
            </div>
        </React.Fragment>
    )
}
export default AssetsEdit;