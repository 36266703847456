import { produce } from "immer";
import Util from "js/common/Util";

import {
  TranTaxParamDisabled,
  TranTaxParamProps,
  TranTaxParamVisible,
  TranTaxParamTitle,
} from "js/common/types/types";

export interface TranTaxState {
  inputValue: TranTaxParamProps;
  inputVisible: TranTaxParamVisible;
  inputDisabled: TranTaxParamDisabled;
  inputTitle: TranTaxParamTitle;
}

export type TranTaxAction = { type: TranTaxActionType; payload: any };

export type TranTaxActionType =
  | "UPDATED_ESTATE_SE"
  | "UPDATED_ESTATE_FOM1"
  | "UPDATED_ESTATE_FOM2"
  | "UPDATED_LANDCLS"
  | "UPDATED_ACQS_SRC1"
  | "UPDATED_ACQS_SRC2"
  | "UPDATED_ACQS_CAUSE1"
  | "UPDATED_ACQS_CAUSE2"
  | "UPDATED_ACQS_CAUSE3"
  | "UPDATED_ACQS_CAUSE4"
  | "UPDATED_HSE_NUM"
  | "UPDATED_ADDR_INFO"
  | "UPDATED_SHARE_RATIO"
  | "UPDATED_MNG_DISP_APP_DATE"
  | "UPDATED_MNG_DISP_APP_DATE_AMT"
  | "UPDATED_ACQU_DATE_PRE_HSE"
  | "UPDATED_ACQU_DATE_INHERIT"
  | "UPDATED_RESI_PERIOD_PRE_HSE"
  | "UPDATED_ACQU_PRICE"
  | "UPDATED_NECE_EXPEN_PRE_HSE"
  | "UPDATED_APPR_DATE_NEW_HSE"
  | "UPDATED_RESI_PERIOD_NEW_HSE"
  | "UPDATED_TRANSFER_DATE"
  | "UPDATED_TRANSFER_PRICE"
  | "UPDATED_NECE_EXPEN_NEW_HSE"
  | "UPDATED_LIQUD_AMT"
  | "UPDATED_PAY_OR_REC_AMT"
  | "INIT_BASIC_INFO"
  | "INIT_FORMER_HOUSE_INFO"
  | "INIT_NEW_HOUSE_INFO";

export const initialState: TranTaxState = {
  inputValue: {
    taxFlag: "", // 00.세금구분 Flag
    estateSe: "", // 01.부동산의 종류
    estateFom1: "", // 02.주택구분1(주택)
    estateFom2: "", // 03.주택구분2(오피스텔)
    landCls: "", // 04.토지구분
    acqsSrc1: "", // 05.취득구분1(아파트/연립)
    acqsSrc2: "", // 06.취득구분2(오피스텔)
    acqsCause1: "", // 07.취득원인1(기존 아파트)
    acqsCause2: "", // 08.취득원인2(분양권/오피스텔)
    acqsCause3: "", // 09.취득원인3(조합원입주권)
    acqsCause4: "", // 10.취득원인4(다세대/다가구/다중/단독)
    hseNum: "", // 11.보유주택 수
    rdnmAdres: "", // 12.도로명 주소
    lnmAdres: "", // 13.지번 주소
    mdatTrgetArea: "", // 14.조정대상지역
    shareRatio: "", // 15.지분율
    mngDispAppDate: "", // 16.관리처분인가일
    mngDispAppDateAmt: "", // 17.관리처분인가일 평가액
    acquDatePreHse: "", // 18.종전주택 취득일자
    acquDateInherit: "", // 18-1.피상속인 취득일자
    resiPeriodPreHse: "", // 19.종전주택 거주기간
    acquPrice: "", // 20.취득가액
    neceExpenPreHse: "", // 21.종전주택 필요경비
    apprDateNewHse: "", // 22.신규주택 사용승인일
    resiPeriodNewHse: "", // 23.신규주택 거주기간
    transferDate: "", // 24.양도일자
    transferPrice: "", // 25.양도가액
    neceExpenNewHse: "", // 26.필요경비
    liqudAmt: "", // 27.청산금 여부
    payOrRecAmt: "", // 28.납부(수령)금액
    legalDongCode: "", // 29.법정도코드
  },
  inputVisible: {
    visible01: true, // 01.부동산의 종류
    visible02: false, // 02.주택구분1(주택)
    visible03: false, // 03.주택구분2(오피스텔)
    visible04: false, // 04.토지구분
    visible05: false, // 05.취득구분1(아파트/연립)
    visible06: false, // 06.취득구분2(오피스텔)
    visible07: false, // 07.취득원인1(기존 아파트)
    visible08: false, // 08.취득원인2(분양권/오피스텔)
    visible09: false, // 09.취득원인3(조합원입주권)
    visible10: false, // 10.취득원인4(다세대/다가구/다중
    visible11: false, // 11.보유주택 수
    visible12: false, // 12.양도할 자산정보
    visible13: false, // 13.양도할 주택 추가 버튼
    visible14: false, // 14.자산정보 Table
    visible15: false, // 15.지분율
    visible16: false, // 16.관리처분인가일
    visible17: false, // 17.관리처분인가일 평가액
    visible18: false, // 18.종전주택 취득일자
    visible18_1: false, // 18-1.피상속인 취득일자
    visible19: false, // 19.종전주택 거주기간
    visible20: false, // 20.취득가액
    visible21: false, // 21.종전주택 필요경비
    visible22: false, // 22.신규주택 사용승인일
    visible23: false, // 23.신규주택 거주기간
    visible24: false, // 24.양도일자
    visible25: false, // 25.양도가액
    visible26: false, // 26.필요경비
    visible27: false, // 27.청산금 여부
    visible28: false, // 28.납부(수령)금액
  },
  inputDisabled: {
    disabled01: false,
    disabled02: false,
    disabled03: false,
    disabled05: false,
    disabled06: false,
    disabled09: false,
  },
  inputTitle: {
    title18: "종전주택 취득일자",
    title19: "종전주택 거주기간",
  },
};

export const tranTaxStatesReducer = produce(
  (draft: TranTaxState, action: TranTaxAction) => {
    const inputValue = draft.inputValue;
    const inputVisible = draft.inputVisible;
    const inputDisabled = draft.inputDisabled;
    const inputTitle = draft.inputTitle;

    switch (action.type) {
      // 01.부동산의 종류
      case "UPDATED_ESTATE_SE": {
        inputValue.estateSe = action.payload;

        switch (action.payload) {
          case "201": {
            // 주택 ▶ 02.주택구분
            inputVisible.visible02 = true;

            inputVisible.visible03 = false;
            inputValue.estateFom2 = "";
            inputVisible.visible04 = false;
            inputValue.landCls = "";
            inputVisible.visible07 = false;
            inputValue.acqsCause1 = "";
            inputVisible.visible09 = false;
            inputValue.acqsCause3 = "";
            break;
          }
          case "202": {
            // 분양권 ▶ 07.취득원인1(기존 아파트)
            inputVisible.visible07 = true;
            inputVisible.visible02 = false;
            inputValue.estateFom1 = "";
            inputVisible.visible03 = false;
            inputValue.estateFom2 = "";
            inputVisible.visible04 = false;
            inputValue.landCls = "";
            inputVisible.visible09 = false;
            inputValue.acqsCause3 = "";
            break;
          }
          case "203": {
            // 조합원입주권 ▶ 09.취득원인3(조합원입주권)
            inputVisible.visible09 = true;

            inputVisible.visible02 = false;
            inputValue.estateFom1 = "";
            inputVisible.visible03 = false;
            inputValue.estateFom2 = "";
            inputVisible.visible04 = false;
            inputValue.landCls = "";
            inputVisible.visible07 = false;
            inputValue.acqsCause1 = "";
            break;
          }
          case "204": {
            // 오피스텔 ▶ 03.주택구분2(오피스텔)
            inputVisible.visible03 = true;

            inputVisible.visible02 = false;
            inputValue.estateFom1 = "";
            inputVisible.visible04 = false;
            inputValue.landCls = "";
            inputVisible.visible07 = false;
            inputValue.acqsCause1 = "";
            inputVisible.visible09 = false;
            inputValue.acqsCause3 = "";
            break;
          }
          case "205": {
            // 일반건물/상가 ▶ 07.취득원인1(기존 아파트)
            inputVisible.visible07 = true;

            inputVisible.visible02 = false;
            inputValue.estateFom1 = "";
            inputVisible.visible04 = false;
            inputValue.landCls = "";
            inputVisible.visible03 = false;
            inputValue.estateFom2 = "";
            inputVisible.visible09 = false;
            inputValue.acqsCause3 = "";
            break;
          }
          case "206": {
            // 토지 ▶ 04.토지구분
            inputVisible.visible04 = true;

            inputVisible.visible02 = false;
            inputValue.estateFom1 = "";
            inputVisible.visible03 = false;
            inputValue.estateFom2 = "";
            inputVisible.visible07 = false;
            inputValue.acqsCause1 = "";
            inputVisible.visible09 = false;
            inputValue.acqsCause3 = "";
            break;
          }
        }

        break;
      }
      // 02.주택구분1(주택)
      case "UPDATED_ESTATE_FOM1": {
        inputValue.estateFom1 = action.payload;

        switch (action.payload) {
          case "201": {
            // 아파트/연립 ▶ 05.취득구분1(아파트/연립)
            inputVisible.visible05 = true;
            inputDisabled.disabled01 = true;

            inputVisible.visible10 = false;
            inputValue.acqsCause4 = "";

            break;
          }
          case "202": {
            // 다세대/다가구/다중/단독 ▶ 10.취득원인4(다세대/다가구/다중/단독)
            inputVisible.visible10 = true;
            inputDisabled.disabled01 = true;

            inputVisible.visible05 = false;
            inputValue.acqsSrc1 = "";

            break;
          }
        }

        break;
      }
      //03. 주택구분2(오피스텔)
      case "UPDATED_ESTATE_FOM2": {
        inputValue.estateFom2 = action.payload;

        switch (action.payload) {
          case "201": {
            // 주거용 오피스텔 ▶ 06.취득구분2(오피스텔)
            inputVisible.visible06 = true;
            inputDisabled.disabled01 = true;

            inputVisible.visible08 = false;
            inputValue.acqsCause2 = "";
            break;
          }
          case "202": {
            // 업무용 오피스텔 ▶ 08.취득원인2(분양권/오피스텔)
            inputVisible.visible08 = true;
            inputDisabled.disabled01 = true;

            inputVisible.visible06 = false;
            inputValue.acqsSrc2 = "";
            break;
          }
        }

        break;
      }
      //04. 토지구분
      case "UPDATED_LANDCLS": {
        inputValue.landCls = action.payload;
        inputVisible.visible07 = true;
        inputDisabled.disabled01 = true;
        break;
      }
      // 05.취득구분1(아파트/연립)
      case "UPDATED_ACQS_SRC1": {
        inputValue.acqsSrc1 = action.payload;

        switch (action.payload) {
          case "201": {
            // 기존 아파트 ▶ 07.취득원인1(기존 아파트)
            inputVisible.visible07 = true;
            inputDisabled.disabled02 = true;

            inputVisible.visible08 = false;
            inputValue.acqsCause2 = "";
            inputVisible.visible09 = false;
            inputValue.acqsCause3 = "";

            break;
          }
          case "202": {
            // 분양권에 의한 아파트 ▶ 08.취득원인2(분양권/오피스텔)
            inputVisible.visible08 = true;
            inputDisabled.disabled02 = true;

            inputVisible.visible07 = false;
            inputValue.acqsCause1 = "";
            inputVisible.visible09 = false;
            inputValue.acqsCause3 = "";

            break;
          }
          case "203": {
            // 조합원입주권에 의한 아파트 ▶ 09.취득원인3(조합원입주권)
            inputVisible.visible09 = true;
            inputDisabled.disabled02 = true;

            inputVisible.visible07 = false;
            inputValue.acqsCause1 = "";
            inputVisible.visible08 = false;
            inputValue.acqsCause2 = "";

            break;
          }
          default: {
            inputVisible.visible07 = false;
            inputValue.acqsCause1 = "";
            inputVisible.visible08 = false;
            inputValue.acqsCause2 = "";
            inputVisible.visible09 = false;
            inputValue.acqsCause3 = "";
          }
        }

        break;
      }
      //06.취득구분2(오피스텔)
      case "UPDATED_ACQS_SRC2": {
        inputValue.acqsSrc2 = action.payload;

        switch (action.payload) {
          case "201": {
            // 기존 오피스텔 ▶ 07.취득원인1(기존 아파트)
            inputVisible.visible07 = true;
            inputDisabled.disabled03 = true;

            inputVisible.visible08 = false;
            inputValue.acqsCause2 = "";

            break;
          }
          case "202": {
            inputVisible.visible08 = true;
            inputDisabled.disabled03 = true;

            inputVisible.visible07 = false;
            inputValue.acqsCause1 = "";

            break;
          }
        }

        break;
      }
      // 07.취득원인1(기존 아파트)
      case "UPDATED_ACQS_CAUSE1": {
        inputValue.acqsCause1 = action.payload;

        // 주택 > 아파트/연립 > 기존 아파트
        if (inputValue.acqsSrc1 === "201") {
          inputVisible.visible11 = true; // 11.보유주택 수 표시
          inputDisabled.disabled05 = true; // 05.취득구분1(아파트/연립) Fixed

          // 주소 정보가 있을 경우
          if (inputVisible.visible14) {
            if (action.payload === "204") {
              // 상속
              inputVisible.visible18_1 = true; // 18-1.피상속인 취득일자
            } else {
              inputVisible.visible18_1 = false; // 18-1.피상속인 취득일자 미표시
              inputValue.acquDateInherit = ""; // 18-1.피상속인 취득일자 초기화
            }
          }
        }

        // 분양권 ▶ 15.지분율, 18.(종전주택)취득일자, 20.취득가액, 24.양도일자, 25.양도가액, 26.필요경비
        // 일반건물/상가 ▶ 15.지분율, 18.(종전주택)취득일자, 20.취득가액, 24.양도일자, 25.양도가액, 26.필요경비
        // 토지 ▶ 15.지분율, 18.(종전주택)취득일자, 20.취득가액, 24.양도일자, 25.양도가액, 26.필요경비
        if (
          inputValue.estateSe === "202" ||
          inputValue.estateSe === "205" ||
          inputValue.estateSe === "206"
        ) {
          inputTitle.title18 = "취득일자";

          inputVisible.visible15 = true;
          inputVisible.visible18 = true;
          inputVisible.visible20 = true;
          inputVisible.visible24 = true;
          inputVisible.visible25 = true;
          inputVisible.visible26 = true;
          inputDisabled.disabled01 = true;

          if (action.payload === "204") {
            // 상속
            inputVisible.visible18_1 = true; // 18-1.피상속인 취득일자
          } else {
            inputVisible.visible18_1 = false; // 18-1.피상속인 취득일자 미표시
            inputValue.acquDateInherit = ""; // 18-1.피상속인 취득일자 초기화
          }
        }

        // 오피스텔 > 주거용 오피스텔 > 기존 오피스텔 ▶ 11.보유주택 수
        if (inputValue.acqsSrc2 === "201") {
          inputVisible.visible11 = true;
          inputDisabled.disabled06 = true; // 06.취득구분2(오피스텔) Fixed

          // 주소 정보가 있을 경우
          if (inputVisible.visible14) {
            if (action.payload === "204") {
              // 상속
              inputVisible.visible18_1 = true; // 18-1.피상속인 취득일자
            } else {
              inputVisible.visible18_1 = false; // 18-1.피상속인 취득일자 미표시
              inputValue.acquDateInherit = ""; // 18-1.피상속인 취득일자 초기화
            }
          }
        }

        break;
      }
      // 08.취득원인2(분양권/오피스텔)
      case "UPDATED_ACQS_CAUSE2": {
        inputValue.acqsCause2 = action.payload;

        // 주택 > 아파트/연립 > 분양권에 의한 아파트
        if (inputValue.acqsSrc1 === "202") {
          inputVisible.visible11 = true; // 11.보유주택 수 표시
          inputDisabled.disabled05 = true; // 05.취득구분1(아파트/연립) Fixed
        }

        // 오피스텔 > 주거용 오피스텔 > 분양 오피스텔
        if (inputValue.acqsSrc2 === "202") {
          inputVisible.visible11 = true;
          inputDisabled.disabled06 = true;

          if (inputVisible.visible14) {
            if (action.payload === "204") {
              // 상속
              inputVisible.visible18_1 = true; // 18-1.피상속인 취득일자
            } else {
              inputVisible.visible18_1 = false; // 18-1.피상속인 취득일자 미표시
              inputValue.acquDateInherit = ""; // 18-1.피상속인 취득일자 초기화
            }
          }
        }

        // 오피스텔 > 업무용 오피스텔 ▶ 15.지분율, 18.취득일자, 20.취득가액, 24.양도일자, 25.양도가액, 26.필요경비
        if (inputValue.estateFom2 === "202") {
          inputTitle.title18 = "취득일자";

          inputVisible.visible15 = true;
          inputVisible.visible18 = true;
          inputVisible.visible20 = true;
          inputVisible.visible24 = true;
          inputVisible.visible25 = true;
          inputVisible.visible26 = true;

          inputDisabled.disabled03 = true; // 03.주택구분2(오피스텔) Fixed

          if (action.payload === "204") {
            // 상속
            inputVisible.visible18_1 = true; // 18-1.피상속인 취득일자
          } else {
            inputVisible.visible18_1 = false; // 18-1.피상속인 취득일자 미표시
            inputValue.acquDateInherit = ""; // 18-1.피상속인 취득일자 초기화
          }
        }

        break;
      }
      // 09.취득원인3(조합원입주권)
      case "UPDATED_ACQS_CAUSE3": {
        inputValue.acqsCause3 = action.payload;

        // 주택 > 아파트/연립 > 조합원입주권에 의한 아파트
        if (inputValue.acqsSrc1 === "203") {
          inputVisible.visible11 = true; // 11.보유주택 수 표시
          inputDisabled.disabled05 = true; // 05.취득구분1(아파트/연립) Fixed
        }

        // 조합원입주권
        if (inputValue.estateSe === "203") {
          if (action.payload === "202") {
            // 원조합원 ▶ 11.보유주택 수
            inputVisible.visible11 = true;
            inputDisabled.disabled01 = true;

            inputVisible.visible15 = false;
            inputValue.shareRatio = "";
            inputVisible.visible18 = false;
            inputValue.acquDatePreHse = "";
            inputVisible.visible18_1 = false;
            inputValue.acquDateInherit = "";
            inputVisible.visible20 = false;
            inputValue.acquPrice = "";
            inputVisible.visible24 = false;
            inputValue.transferDate = "";
            inputVisible.visible25 = false;
            inputValue.transferPrice = "";
            inputVisible.visible26 = false;
            inputValue.neceExpenNewHse = "";
          } else if (action.payload === "204") {
            // 상속 ▶ 15.지분율, 18.취득일자, 18-1.피상속인 취득일자, 20.취득가액, 24.양도일자, 25.양도가액, 26.필요경비
            inputTitle.title18 = "취득일자";

            inputVisible.visible15 = true;
            inputVisible.visible18 = true;
            inputVisible.visible18_1 = true;
            inputVisible.visible20 = true;
            inputVisible.visible24 = true;
            inputVisible.visible25 = true;
            inputVisible.visible26 = true;
            inputDisabled.disabled01 = true;

            inputVisible.visible11 = false;
            inputValue.hseNum = "";
          } else {
            // 전매(승계조합원), 증여 ▶ 15.지분율, 18.취득일자, 20.취득가액, 24.양도일자, 25.양도가액, 26.필요경비
            inputTitle.title18 = "취득일자";

            inputVisible.visible15 = true;
            inputVisible.visible18 = true;
            inputVisible.visible20 = true;
            inputVisible.visible24 = true;
            inputVisible.visible25 = true;
            inputVisible.visible26 = true;
            inputDisabled.disabled01 = true;

            inputVisible.visible11 = false;
            inputValue.hseNum = "";
            inputVisible.visible18_1 = false;
            inputValue.acquDateInherit = "";
          }
        }

        break;
      }
      // 10.취득원인4(다세대/다가구/다중/단독)
      case "UPDATED_ACQS_CAUSE4": {
        inputValue.acqsCause4 = action.payload;

        // 주택 > 다세대/다가구/다중/단독주택
        if (inputValue.estateFom1 === "202") {
          inputVisible.visible11 = true;
          inputDisabled.disabled02 = true;

          // 주소 정보가 있을 경우
          if (inputVisible.visible14) {
            if (action.payload === "204") {
              // 상속
              inputVisible.visible18_1 = true; // 18-1.피상속인 취득일자
            } else {
              inputVisible.visible18_1 = false; // 18-1.피상속인 취득일자 미표시
              inputValue.acquDateInherit = ""; // 18-1.피상속인 취득일자 초기화
            }
          }
        }

        break;
      }
      // 11.보유주택 수
      case "UPDATED_HSE_NUM": {
        inputValue.hseNum = action.payload;

        inputVisible.visible12 = true;

        // 주택 > 아파트/연립 > 조합원입주권에 의한 아파트 : 취득원인 Fix
        // 조합원입주권 : : 취득원인 Fix
        if (Util.isNotEmpty(inputValue.acqsCause3)) {
          // 09.취득원인3(조합원입주권)
          inputDisabled.disabled09 = true; // 09.취득원인3(조합원입주권) Fixed
        }

        // 주택 > 아파트/연립 > 기존 아파트
        if (
          inputValue.estateSe === "201" &&
          inputValue.estateFom1 === "201" &&
          inputValue.acqsSrc1 === "201"
        ) {
          // 양도할 자산정보가 있을 경우에만
          if (inputVisible.visible14) {
            // 매매/상속/증여
            if (action.payload === "201") {
              // 1주택(대체취득 포함)
              inputVisible.visible19 = true; // 19.(종전주택) 거주기간
            } else {
              // 2주택, 3주택 이상
              inputVisible.visible19 = false; // 19.(종전주택) 거주기간 미표시
              inputValue.resiPeriodPreHse = ""; // 19.(종전주택) 거주기간 초기화
            }
          }
        }
        // 주택 > 아파트/연립 > 분양권에 의한 아파트
        else if (
          inputValue.estateSe === "201" &&
          inputValue.estateFom1 === "201" &&
          inputValue.acqsSrc1 === "202"
        ) {
          // 양도할 자산정보가 있을 경우에만
          if (inputVisible.visible14) {
            // 매매/상속/증여
            if (action.payload === "201") {
              // 1주택(대체취득 포함)
              inputVisible.visible19 = true; // 19.(종전주택) 거주기간
            } else {
              // 2주택, 3주택 이상
              inputVisible.visible19 = false; // 19.(종전주택) 거주기간 미표시
              inputValue.resiPeriodPreHse = ""; // 19.(종전주택) 거주기간 초기화
            }
          }
        }
        // 주택 > 아파트/연립 > 조합원입주권에 의한 아파트
        else if (
          inputValue.estateSe === "201" &&
          inputValue.estateFom1 === "201" &&
          inputValue.acqsSrc1 === "203"
        ) {
          // 양도할 자산정보가 있을 경우에만
          if (inputVisible.visible14) {
            //  09.취득원인3(조합원입주권) : 원조합원
            if (inputValue.acqsCause3 === "202") {
              if (action.payload === "201") {
                // 1주택(대체취득 포함) ▶ 16 ~ 27 : 주택정보 callback에서 처리
                inputVisible.visible19 = false;
                inputVisible.visible23 = false;
              } else {
                // 2주택, 3주택 이상 ▶ 16~18, 20~22, 24~27 : 주택정보 callback에서 처리
                inputVisible.visible19 = false;
                inputVisible.visible23 = false;
                inputValue.resiPeriodPreHse = ""; // 19.종전주택 거주기간 초기화
                inputValue.resiPeriodNewHse = ""; // 23.신규주택 거주기간 초기화
              }
            } else {
              // 전매(승계조합원), 상속, 증여
              if (action.payload === "201") {
                // 1주택(대체취득 포함) ▶ 18 ~ 20, 24 ~ 26
                inputVisible.visible19 = true; // 19.종전주택 거주기간
              } else {
                // 2주택, 3주택 이상 ▶ 18, 20, 24 ~ 26
                inputVisible.visible19 = false; // 19.(종전주택) 거주기간 미표시
                inputValue.resiPeriodPreHse = ""; // 19.종전주택 거주기간 초기화
              }
            }
          }
        }

        // 주택 > 다세대/다가구/다중/단독주택
        if (inputValue.estateFom1 === "202") {
          // 양도할 자산정보가 있을 경우에만
          if (inputVisible.visible14) {
            // 매매/상속/증여
            if (action.payload === "201") {
              // 1주택(대체취득 포함)
              inputVisible.visible19 = true; // 19.(종전주택) 거주기간
            } else {
              // 2주택, 3주택 이상
              inputVisible.visible19 = false; // 19.(종전주택) 거주기간 미표시
              inputValue.resiPeriodPreHse = ""; // 19.(종전주택) 거주기간 초기화
            }
          }
        }

        // 조합원입주권
        if (inputValue.estateSe === "203") {
          // 양도할 자산정보가 있을 경우에만
          if (inputVisible.visible14) {
            //  09.취득원인3(조합원입주권) : 원조합원
            if (inputValue.acqsCause3 === "202") {
              if (action.payload === "201") {
                // 1주택(대체취득 포함) ▶ 16 ~ 21, 24 : 주택정보 callback에서 처리
                inputVisible.visible19 = true;
              } else {
                // 2주택, 3주택 이상 ▶ 16~18, 20~22, 24~27 : 주택정보 callback에서 처리
                inputVisible.visible19 = false;
                inputValue.resiPeriodPreHse = ""; // 19.종전주택 거주기간 초기화
              }
            }
          }
        }

        // 오피스텔 > 주거용 오피스텔 > 기존 오피스텔 or 분양 오피스텔
        if (inputValue.estateSe === "204" && inputValue.estateFom2 === "201") {
          // 양도할 자산정보가 있을 경우에만
          if (inputVisible.visible14) {
            // 매매/상속/증여
            if (action.payload === "201") {
              // 1주택(대체취득 포함)
              inputVisible.visible19 = true;
            } else {
              // 2주택, 3주택 이상
              inputVisible.visible19 = false;
              inputValue.resiPeriodPreHse = ""; // 19.종전주택 거주기간 초기화
            }
          }
        }

        break;
      }
      // 주소정보입력 단계 완료 후
      case "UPDATED_ADDR_INFO": {
        inputValue.mdatTrgetArea = action.payload.mdatTrgetArea; // 조정대상지역
        inputValue.shareRatio = action.payload.shareRatio; // 지분율
        inputValue.legalDongCode = action.payload.legalDongCode; // 법정동 코드

        // 주택 > 아파트/연립 > 기존 아파트
        if (
          inputValue.estateSe === "201" &&
          inputValue.estateFom1 === "201" &&
          inputValue.acqsSrc1 === "201"
        ) {
          inputTitle.title18 = "취득일자";
          inputTitle.title19 = "거주기간";

          if (inputValue.acqsCause1 === "204") {
            inputVisible.visible18_1 = true; // 상속 : 18-1.피상속인 취득일자
          }

          // 매매/상속/증여
          if (inputValue.hseNum === "201") {
            // 11.보유주택 수 : 1주택(대체취득 포함)
            inputVisible.visible18 = true; // 18.(종전주택)취득일자
            inputVisible.visible19 = true; // 19.(종전주택)거주기간
            inputVisible.visible20 = true; // 20.취득가액
            inputVisible.visible24 = true; // 24.양도일자
            inputVisible.visible25 = true; // 25.양도가액
            inputVisible.visible26 = true; // 26.필요경비
          } else {
            // 2주택, 3주택 이상
            inputVisible.visible18 = true; // 18.(종전주택)취득일자
            inputVisible.visible20 = true; // 20.취득가액
            inputVisible.visible24 = true; // 24.양도일자
            inputVisible.visible25 = true; // 25.양도가액
            inputVisible.visible26 = true; // 26.필요경비

            inputVisible.visible19 = false; // 19.(종전주택) 거주기간 미표시
            inputValue.resiPeriodPreHse = ""; // 19.(종전주택) 거주기간 초기화
          }
        }
        // 주택 > 아파트/연립 > 분양권에 의한 아파트
        else if (
          inputValue.estateSe === "201" &&
          inputValue.estateFom1 === "201" &&
          inputValue.acqsSrc1 === "202"
        ) {
          inputTitle.title18 = "취득일자";
          inputTitle.title19 = "거주기간";

          // 매매/상속/증여
          if (inputValue.hseNum === "201") {
            // 11.보유주택 수 : 1주택(대체취득 포함)
            inputVisible.visible18 = true; // 18.(종전주택)취득일자
            inputVisible.visible19 = true; // 19.(종전주택)거주기간
            inputVisible.visible20 = true; // 20.취득가액
            inputVisible.visible24 = true; // 24.양도일자
            inputVisible.visible25 = true; // 25.양도가액
            inputVisible.visible26 = true; // 26.필요경비
          } else {
            // 2주택, 3주택 이상
            inputVisible.visible18 = true; // 18.(종전주택)취득일자
            inputVisible.visible20 = true; // 20.취득가액
            inputVisible.visible24 = true; // 24.양도일자
            inputVisible.visible25 = true; // 25.양도가액
            inputVisible.visible26 = true; // 26.필요경비

            inputVisible.visible19 = false; // 19.(종전주택) 거주기간 미표시
            inputValue.resiPeriodPreHse = ""; // 19.(종전주택) 거주기간 초기화
          }
        }
        // 주택 > 아파트/연립 > 조합원입주권에 의한 아파트
        else if (
          inputValue.estateSe === "201" &&
          inputValue.estateFom1 === "201" &&
          inputValue.acqsSrc1 === "203"
        ) {
          //  09.취득원인3(조합원입주권) : 원조합원
          if (inputValue.acqsCause3 === "202") {
            inputTitle.title18 = "종전주택 취득일자";
            inputTitle.title19 = "종전주택 거주기간";

            if (inputValue.hseNum === "201") {
              // 11.보유주택 수 : 1주택(대체취득 포함)
              inputVisible.visible16 = true; // 16.관리처분인가일
              inputVisible.visible17 = true; // 17.관리처분인가일 평가액
              inputVisible.visible18 = true; // 18.종전주택 취득일자
              inputVisible.visible19 = true; // 19.종전주택 거주기간
              inputVisible.visible20 = true; // 20.취득가액
              inputVisible.visible21 = true; // 21.종전주택 필요경비
              inputVisible.visible22 = true; // 22.신규주택 사용승인일
              inputVisible.visible23 = true; // 23.신규주택 거주기간
              inputVisible.visible24 = true; // 24.양도일자
              inputVisible.visible25 = true; // 25.양도가액
              inputVisible.visible26 = true; // 26.필요경비
              inputVisible.visible27 = true; // 27.청산금 여부
            } else {
              // 2주택, 3주택 이상
              inputVisible.visible16 = true; // 16.관리처분인가일
              inputVisible.visible17 = true; // 17.관리처분인가일 평가액
              inputVisible.visible18 = true; // 18.종전주택 취득일자
              inputVisible.visible20 = true; // 20.취득가액
              inputVisible.visible21 = true; // 21.종전주택 필요경비
              inputVisible.visible22 = true; // 22.신규주택 사용승인일
              inputVisible.visible24 = true; // 24.양도일자
              inputVisible.visible25 = true; // 25.양도가액
              inputVisible.visible26 = true; // 26.필요경비
              inputVisible.visible27 = true; // 27.청산금 여부

              inputVisible.visible19 = false;
              inputValue.resiPeriodPreHse = "";
              inputVisible.visible23 = false;
              inputValue.resiPeriodNewHse = "";
            }
          } else {
            // 전매(승계조합원), 상속, 증여
            inputTitle.title18 = "취득일자";
            inputTitle.title19 = "거주기간";

            if (inputValue.acqsCause1 === "204") {
              inputVisible.visible18_1 = true; // 상속 : 18-1.피상속인 취득일자
            }

            if (inputValue.hseNum === "201") {
              // 11.보유주택 수 : 1주택(대체취득 포함)
              inputVisible.visible18 = true; // 18.종전주택 취득일자
              inputVisible.visible19 = true; // 19.종전주택 거주기간
              inputVisible.visible20 = true; // 20.취득가액
              inputVisible.visible24 = true; // 24.양도일자
              inputVisible.visible25 = true; // 25.양도가액
              inputVisible.visible26 = true; // 26.필요경비
            } else {
              // 2주택, 3주택 이상
              inputVisible.visible18 = true; // 18.종전주택 취득일자
              inputVisible.visible20 = true; // 20.취득가액
              inputVisible.visible24 = true; // 24.양도일자
              inputVisible.visible25 = true; // 25.양도가액
              inputVisible.visible26 = true; // 26.필요경비

              inputVisible.visible19 = false; // 19.(종전주택) 거주기간 미표시
              inputValue.resiPeriodPreHse = ""; // 19.(종전주택) 거주기간 초기화
            }
          }
        }

        // 주택 > 다세대/다가구/다중/단독주택
        if (inputValue.estateFom1 === "202") {
          inputTitle.title18 = "취득일자";
          inputTitle.title19 = "거주기간";

          if (inputValue.acqsCause4 === "204") {
            inputVisible.visible18_1 = true; // 상속 : 18-1.피상속인 취득일자
          }

          // 매매, 상속, 증여, 자가신축
          if (inputValue.hseNum === "201") {
            // 11.보유주택 수 : 1주택(대체취득 포함)
            inputVisible.visible18 = true; // 18.종전주택 취득일자
            inputVisible.visible19 = true; // 19.종전주택 거주기간
            inputVisible.visible20 = true; // 20.취득가액
            inputVisible.visible24 = true; // 24.양도일자
            inputVisible.visible25 = true; // 25.양도가액
            inputVisible.visible26 = true; // 26.필요경비
          } else {
            // 2주택, 3주택 이상
            inputVisible.visible18 = true; // 18.종전주택 취득일자
            inputVisible.visible20 = true; // 20.취득가액
            inputVisible.visible24 = true; // 24.양도일자
            inputVisible.visible25 = true; // 25.양도가액
            inputVisible.visible26 = true; // 26.필요경비

            inputVisible.visible19 = false; // 19.(종전주택) 거주기간 미표시
            inputValue.resiPeriodPreHse = ""; // 19.(종전주택) 거주기간 초기화
          }
        }

        // 조합원입주권
        if (inputValue.estateSe === "203") {
          //  09.취득원인3(조합원입주권) : 원조합원
          if (inputValue.acqsCause3 === "202") {
            inputTitle.title18 = "종전주택 취득일자";
            inputTitle.title19 = "종전주택 거주기간";

            if (inputValue.hseNum === "201") {
              // 11.보유주택 수 : 1주택(대체취득 포함)
              inputVisible.visible16 = true; // 16.관리처분인가일
              inputVisible.visible17 = true; // 17.관리처분인가일 평가액
              inputVisible.visible18 = true; // 18.종전주택 취득일자
              inputVisible.visible19 = true; // 19.종전주택 거주기간
              inputVisible.visible20 = true; // 20.취득가액
              inputVisible.visible21 = true; // 21.종전주택 필요경비
              inputVisible.visible24 = true; // 24.양도일자
              inputVisible.visible25 = true; // 25.양도가액
              inputVisible.visible26 = true; // 26.필요경비
              inputVisible.visible27 = true; // 27.청산금 여부
            } else {
              // 2주택, 3주택 이상
              inputVisible.visible16 = true; // 16.관리처분인가일
              inputVisible.visible17 = true; // 17.관리처분인가일 평가액
              inputVisible.visible18 = true; // 18.종전주택 취득일자
              inputVisible.visible20 = true; // 20.취득가액
              inputVisible.visible21 = true; // 21.종전주택 필요경비
              inputVisible.visible24 = true; // 24.양도일자
              inputVisible.visible25 = true; // 25.양도가액
              inputVisible.visible26 = true; // 26.필요경비
              inputVisible.visible27 = true; // 27.청산금 여부

              inputVisible.visible19 = false; // 19.(종전주택) 거주기간 미표시
              inputValue.resiPeriodPreHse = ""; // 19.(종전주택) 거주기간 초기화
            }
          }
        }

        // 오피스텔 > 주거용 오피스텔 > 기존 오피스텔 or 분양 오피스텔
        if (inputValue.estateSe === "204" && inputValue.estateFom2 === "201") {
          inputTitle.title18 = "취득일자";
          inputTitle.title19 = "거주기간";

          // 기존오피스텔 or 분양 오피스텔 상속
          if (
            inputValue.acqsCause1 === "204" ||
            inputValue.acqsCause2 === "204"
          ) {
            inputVisible.visible18_1 = true; // 상속 : 18-1.피상속인 취득일자
          }

          // 매매/상속/증여
          if (inputValue.hseNum === "201") {
            inputVisible.visible18 = true; // 18.종전주택 취득일자
            inputVisible.visible19 = true; // 19.종전주택 거주기간
            inputVisible.visible20 = true; // 20.취득가액
            inputVisible.visible24 = true; // 24.양도일자
            inputVisible.visible25 = true; // 25.양도가액
            inputVisible.visible26 = true; // 26.필요경비
          } else {
            // 2주택, 3주택 이상
            inputVisible.visible18 = true; // 18.종전주택 취득일자
            inputVisible.visible20 = true; // 20.취득가액
            inputVisible.visible24 = true; // 24.양도일자
            inputVisible.visible25 = true; // 25.양도가액
            inputVisible.visible26 = true; // 26.필요경비

            inputVisible.visible19 = false; // 19.(종전주택) 거주기간 미표시
            inputValue.resiPeriodPreHse = ""; // 19.(종전주택) 거주기간 초기화
          }
        }

        break;
      }
      // 15. 지분율
      case "UPDATED_SHARE_RATIO": {
        inputValue.shareRatio = action.payload;
        break;
      }
      // 16. 관리처분인가일
      case "UPDATED_MNG_DISP_APP_DATE": {
        inputValue.mngDispAppDate = action.payload;
        break;
      }
      // 17. 관리처분인가일 평가액
      case "UPDATED_MNG_DISP_APP_DATE_AMT": {
        inputValue.mngDispAppDateAmt = action.payload;
        break;
      }
      // 18.종전주택 취득일자
      case "UPDATED_ACQU_DATE_PRE_HSE": {
        inputValue.acquDatePreHse = action.payload;
        break;
      }
      // 18-1.피상속인 취득일자
      case "UPDATED_ACQU_DATE_INHERIT": {
        inputValue.acquDateInherit = action.payload;
        break;
      }
      // 19. 종전주택 거주기간
      case "UPDATED_RESI_PERIOD_PRE_HSE": {
        inputValue.resiPeriodPreHse = action.payload;
        break;
      }
      // 20. 취득가액
      case "UPDATED_ACQU_PRICE": {
        inputValue.acquPrice = action.payload;
        break;
      }
      // 21. 종전주택 필요경비
      case "UPDATED_NECE_EXPEN_PRE_HSE": {
        inputValue.neceExpenPreHse = action.payload;
        break;
      }
      // 22. 신규주택 사용승인일
      case "UPDATED_APPR_DATE_NEW_HSE": {
        inputValue.apprDateNewHse = action.payload;
        break;
      }
      // 23.신규주택 거주기간
      case "UPDATED_RESI_PERIOD_NEW_HSE": {
        inputValue.resiPeriodNewHse = action.payload;
        break;
      }
      // 24. 양도일자
      case "UPDATED_TRANSFER_DATE": {
        inputValue.transferDate = action.payload;
        break;
      }
      // 25. 양도가액
      case "UPDATED_TRANSFER_PRICE": {
        inputValue.transferPrice = action.payload;
        break;
      }
      // 26. 필요경비
      case "UPDATED_NECE_EXPEN_NEW_HSE": {
        inputValue.neceExpenNewHse = action.payload;
        break;
      }
      // 27.청산금 여부
      case "UPDATED_LIQUD_AMT": {
        inputValue.liqudAmt = action.payload;

        if (action.payload === "202" || action.payload === "203") {
          // 청산금 납부/수령 ▶ 28.납부(수령)금액
          inputVisible.visible28 = true;
        } else {
          inputVisible.visible28 = false; // 28.납부(수령)금액 미표시
          inputValue.payOrRecAmt = ""; // 28.납부(수령)금액 초기화
        }

        break;
      }
      // 28.납부(수령)금액
      case "UPDATED_PAY_OR_REC_AMT": {
        inputValue.payOrRecAmt = action.payload;
        break;
      }
      // 기초정보입력 초기화
      case "INIT_BASIC_INFO": {
        return initialState;
      }
      // 부가정보입력 > 종전주택정보 초기화
      case "INIT_FORMER_HOUSE_INFO": {
        inputValue.shareRatio = "";
        inputValue.mngDispAppDate = "";
        inputValue.mngDispAppDateAmt = "";
        inputValue.acquDatePreHse = "";
        inputValue.acquDateInherit = "";
        inputValue.resiPeriodPreHse = "";
        inputValue.acquPrice = "";
        inputValue.neceExpenPreHse = "";

        break;
      }
      // 부가정보입력 > 신규주택정보 초기화
      case "INIT_NEW_HOUSE_INFO": {
        inputValue.apprDateNewHse = "";
        inputValue.resiPeriodNewHse = "";
        inputValue.transferDate = "";
        inputValue.transferPrice = "";
        inputValue.neceExpenNewHse = "";
        inputValue.liqudAmt = "";
        inputValue.payOrRecAmt = "";

        inputVisible.visible28 = false;

        break;
      }
    }
  }
);
