/*******************************************************************************
 *** SimAddressEtc ** 간편계산기 주소검색 3단계 * 최종 주소 확인
 *******************************************************************************/
import React from 'react';
import { IWizard } from 'use-wizard/lib/cjs/useWizard/types/IWizard';
import { TStep } from 'use-wizard/lib/cjs/useWizard/types/TStep';
import Axios from 'js/common/Axios';
import Util from 'js/common/Util';
import AppContext from 'js/common/AppContext';
// import { NumericFormat } from "react-number-format";
import ImageRadioBox from 'js/common/ui/input/ImageRadioBox';
import NumberTextBox from '../ui/input/NumberTextBox';

const SimAddressEtc = (props: {
    step: TStep;
    wizard: IWizard;
    form: any;
    dispatchForm: Function;
    callBack: Function;
    setModalOpen: Function;
}) => {
    const [spfcKnd, setSpfcKnd] = React.useState<string>(); // 용도지역
    const [spfcKndDisabled, setSpfcKndDisabled] = React.useState<boolean>(true); // 용도지역 입력 활성화 여부
    const [mdatTrgetAreaAt, setMdatTrgetAreaAt] = React.useState<string>(''); // 조정지역여부
    const [mdatTrgetAreaAtDis, setMdatTrgetAreaAtDis] = React.useState<boolean>(true); // 조정지역여부 입력 활성화 여부

    const [pblntfPc, setPblntfPc] = React.useState<string>(''); // 공동주택가격
    const [pblntfPcDis, setPblntfPcDis] = React.useState<boolean>(true); // 공동주택 입력가능 여부
    const [housePc, setHousePc] = React.useState<string>(''); // 개별주택가격
    const [housePcDis, setHousePcDis] = React.useState<boolean>(true); // 개별주택가격 입력가능 여부

    const [shareRatio, setShareRatio] = React.useState<string>(''); // 지분율
    const [apprDateNewHse, setApprDateNewHse] = React.useState<string>(''); // 사용승인일

    // 공동주택 면적
    const [prvuseAr, setPrvuseAr] = React.useState<string>(''); // 공동주택 전용면적
    const [prvuseArDis, setPrvuseArDis] = React.useState<boolean>(true); // 공동주택 전용면적 입력가능 여부

    // // 단독주택 면적
    const [houseTotar, setHouseTotar] = React.useState<string>(''); // 단독주택 주택연면적
    const [houseTotarDis, setHouseTotarDis] = React.useState<boolean>(true); // 단독주택 주택연면적 입력가능 여부

    // 최종 주소 및 정보 확인 요청
    React.useEffect(() => {
        AppContext.showSpinnerDim();
        let apiParam;

        if (Util.isNotEmpty(props.form.userDong) && Util.isNotEmpty(props.form.userHo)) {
            // 아파트
            apiParam =
                props.form.selAddrItem.pnu +
                ',' +
                props.form.selAddrItem.bdKdcd +
                ',' +
                encodeURI(props.form.userDong) +
                ',' +
                encodeURI(props.form.userHo);
        } else if (Util.isEmpty(props.form.userDong) && Util.isNotEmpty(props.form.userHo)) {
            // 한개동, 다세대
            apiParam = props.form.selAddrItem.pnu + ',1,' + encodeURI(props.form.userHo);
        } else {
            // 단독주택
            apiParam = props.form.selAddrItem.pnu + ',' + props.form.selAddrItem.bdKdcd;
        }

        Axios.dataAccess({
            url: 'api/system/broker/callApi.do',
            methodType: 'post',
            paramType: 'object',
            paramData: {
                apiName: 'openapi',
                tailUrl: 'forest/estate',
                paramStr: apiParam,
            },
            onSuccessFn: (res: any) => {
                if (res && res.item && res.item.rtn_list) {
                    // 용도지역
                    if (Util.isEmpty(res.item.rtn_list[0].spfcKndCd) || res.item.rtn_list[0].spfcKndCd === '1000') {
                        setSpfcKnd('1001');
                    } else {
                        setSpfcKnd(res.item.rtn_list[0].spfcKndCd);
                    }
                    // 용도지역 입력 필드 활성화 여부
                    if (Util.isEmpty(res.item.rtn_list[0].mdatTrgetAreaAt)) {
                        setSpfcKndDisabled(false);
                    }

                    setMdatTrgetAreaAt(res.item.rtn_list[0].mdatTrgetAreaAt); // 조정대상지역
                    // 조정대상지역 입력 필드 활성화 여부
                    if (Util.isEmpty(res.item.rtn_list[0].mdatTrgetAreaAt)) {
                        setMdatTrgetAreaAtDis(false);
                    }

                    setPblntfPc(res.item.rtn_list[0].pblntfPc); // 공동주택가격
                    // 공동주택가격 입력 필드 활성화 여부
                    if (Util.isEmpty(res.item.rtn_list[0].pblntfPc)) {
                        setPblntfPcDis(false);
                    }

                    setHousePc(res.item.rtn_list[0].housePc); // 개별주택가격
                    // 개별주택가격 입력 필드 활성화 여부
                    if (Util.isEmpty(res.item.rtn_list[0].housePc)) {
                        setHousePcDis(false);
                    }

                    // 사용승인일
                    setApprDateNewHse(res.item.rtn_list[0].useAprDay);

                    // 공동주택 전용면적
                    setPrvuseAr(res.item.rtn_list[0].prvuseAr); // 전용면적
                    if (Util.isEmpty(res.item.rtn_list[0].prvuseAr)) {
                        setPrvuseArDis(false); // 입력 필드 활성화
                    }

                    // 단독주택 주택연면적
                    setHouseTotar(res.item.rtn_list[0].houseTotar); // 주택연면적
                    if (Util.isEmpty(res.item.rtn_list[0].houseTotar)) {
                        setHouseTotarDis(false); // 입력 필드 활성화
                    }
                }
                AppContext.hideSpinner();
            },
            // skipFailedAlert: true,
            onFailedFn: (error: any) => {
                // API 장애 발생
                AppContext.openAlert({
                    title: 'API 장애 발생',
                    msg: '입력항목을 직접 입력해 주세요.',
                    icon: 'error',
                });
                setPblntfPcDis(false); // 공동주택가격
                setHousePcDis(false); // 개별주택가격
                setMdatTrgetAreaAtDis(false); // 조정대상지역
                setSpfcKndDisabled(false); // 용도지역

                setPrvuseArDis(false); // 공동주택 전용면적
                setHouseTotarDis(false); // 단독주택 주택연면적
                AppContext.hideSpinner();
            },
        });
    }, [
        props.form.selAddrItem.pnu,
        props.form.selAddrItem.bdKdcd,
        props.form.userDong,
        props.form.userHo,
        props.form.taxFlag,
    ]);

    // 이전버튼
    const goPrevPage = () => {
        props.dispatchForm({
            type: 'UPDATE_KEY_VALUES',
            value: {
                userBdNm: props.form.userBdNm,
                userDong: props.form.userDong,
                userHo: props.form.userHo,
                taxFlag: props.form.taxFlag,
            },
        });
        props.wizard.previousStep();
    };

    // 완료버튼 (자산 폼 셋팅)
    const goFinish = () => {
        // const bdNm = Util.nvl(props.form.userBdNm, "");
        const dongNm = Util.nvl(props.form.userDong, '');
        const hoNm = Util.nvl(props.form.userHo, '');

        const taxFlag = props.form.taxFlag;

        // 재산세/종합부동산세 자산 검색 시 확인
        if (taxFlag === 'P') {
            if (props.form.selAddrItem.bdKdcd === '0') {
                // 단독주택
                if (Util.isEmpty(housePc)) {
                    AppContext.openAlert({
                        title: '개별주택가격',
                        msg: '개별주택가격을 입력하세요.',
                        icon: 'check',
                    });
                    return;
                }
            } else if (props.form.selAddrItem.bdKdcd === '1') {
                // 공동주택
                if (Util.isEmpty(pblntfPc)) {
                    AppContext.openAlert({
                        title: '공동주택가격',
                        msg: '공동주택가격을 입력하세요.',
                        icon: 'check',
                    });
                    return;
                }
            } else {
            }

            if (Util.isEmpty(spfcKnd)) {
                AppContext.openAlert({
                    title: '용도지역',
                    msg: '용도지역을 선택하세요.',
                    icon: 'check',
                });
                return;
            }
        }

        // 취득세 주소검색 입력사항 확인
        if (taxFlag === 'A') {
            if (props.form.selAddrItem.bdKdcd === '0') {
                // 단독주택
                if (Util.isEmpty(housePc)) {
                    AppContext.openAlert({
                        title: '개별주택가격',
                        msg: '개별주택가격을 입력하세요.',
                        icon: 'check',
                    });
                    return;
                }

                if (Util.isEmpty(houseTotar)) {
                    AppContext.openAlert({
                        title: '단독주택 주택연면적',
                        msg: '주택연면적을 입력하세요.',
                        icon: 'check',
                    });
                    return;
                }
            } else if (props.form.selAddrItem.bdKdcd === '1') {
                // 공동주택
                if (Util.isEmpty(pblntfPc)) {
                    AppContext.openAlert({
                        title: '공동주택가격',
                        msg: '공동주택가격을 입력하세요.',
                        icon: 'check',
                    });
                    return;
                }

                if (Util.isEmpty(prvuseAr)) {
                    AppContext.openAlert({
                        title: '공동주택 전용면적',
                        msg: '전용면적을 입력하세요.',
                        icon: 'check',
                    });
                    return;
                }
            } else {
            }
        }

        if (Util.isEmpty(mdatTrgetAreaAt)) {
            AppContext.openAlert({
                title: '조정대상지역',
                msg: '조정대상지역을 선택하세요.',
                icon: 'check',
            });
            return;
        }

        if (Util.isEmpty(shareRatio)) {
            AppContext.openAlert({
                title: '지분율',
                msg: '지분율을 입력하세요.',
                icon: 'check',
            });
            return;
        }

        if (Number(shareRatio) <= 0 || Number(Util.uncomma(shareRatio)) > 100) {
            AppContext.openAlert({
                title: '지분율',
                msg: '지분율을 1~100으로 입력하세요.',
                icon: 'check',
            });
            return;
        }

        const retParam = {
            bdKnd: props.form.selAddrItem.bdKdcd, // 공동주택 여부
            rdnmAdres: props.form.selAddrItem.roadAddr, // 도로주소명
            rdnmAdresDetail: dongNm + ' ' + hoNm, // 도로 상세주소
            lnmAdres: props.form.selAddrItem.jibunAddr, // 지번
            lnmAdresDetail: dongNm + ' ' + hoNm, // 지번 상세주소
            pnu: props.form.selAddrItem.pnu, // 토지고유코드
            spfcKnd: spfcKnd, // 용도지역
            mdatTrgetAreaAt: mdatTrgetAreaAt, // 조정대상지역
            pblntfPc: pblntfPc, // 공동주택 가격
            housePc: housePc, // 개별주택 가격
            shareRatio: shareRatio, // 지분율
            apprDateNewHse: apprDateNewHse, // 사용승인일
            legaldongCode: props.form.selAddrItem.admCd, // 법정동코드
            prvuseAr: prvuseAr, // 공동주택 전용면적
            houseTotar: houseTotar, // 단독주택 주택연면적
        };
        props.callBack(retParam);
        props.setModalOpen(false);
    };

    return (
        <React.Fragment>
            <div className="dpFlx aiC">
                <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc222222">최종 주소 확인 ({props.step}/3)</span>
            </div>
            <div className="h40 dpFlx aiC pt5">
                <div className="w50 h24 bd1 bdc3C89CD bdrd4 dpFlx aiC jcC bdBox mr10">
                    <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc3C89CD">도로명</span>
                </div>
                <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc666666">
                    {props.form.selAddrItem.roadAddr} {props.form.userDong} {props.form.userHo}
                </span>
            </div>
            <div
                className={(props.form.taxFlag === 'P' || props.form.taxFlag === 'A' ? '' : 'dpNone-i') + ' pt10 pb10'}
            >
                {props.form.selAddrItem.bdKdcd === '0' ? ( // 단독주택
                    <div className="aiC">
                        <div className="h40 dpFlx aiC">
                            <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">개별주택가격(원)</span>
                            <NumberTextBox
                                value={housePc}
                                onChange={(data: string) => setHousePc(data)}
                                borderClass="bgcWhite w300 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                // placeholder="개별주택가격을 입력해주세요"
                                maxLength={15}
                                disabled={housePcDis}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitText="원"
                            />
                        </div>
                        <div className={props.form.taxFlag === 'P' ? 'dpNone-i' : ' h40 dpFlx aiC mt10'}>
                            <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">주택연면적</span>
                            <NumberTextBox
                                value={houseTotar}
                                onChange={(data: string) => setHouseTotar(data)}
                                borderClass="bgcWhite w300 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                // placeholder="주택연면적을 입력해주세요."
                                maxLength={10}
                                disabled={houseTotarDis}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitText="㎡"
                            />
                        </div>
                    </div>
                ) : props.form.selAddrItem.bdKdcd === '1' ? ( // 공동주택
                    <div>
                        <div className="h40 dpFlx aiC">
                            <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">공동주택가격(원)</span>
                            <NumberTextBox
                                value={pblntfPc}
                                onChange={(data: string) => setPblntfPc(data)}
                                borderClass="bgcWhite w300 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                // placeholder="공동주택가격을 입력해주세요."
                                maxLength={15}
                                disabled={pblntfPcDis}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitText="원"
                            />
                        </div>
                        <div className={props.form.taxFlag === 'P' ? 'dpNone-i' : ' h40 dpFlx aiC mt10'}>
                            <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">전용면적</span>
                            <NumberTextBox
                                value={prvuseAr}
                                onChange={(data: string) => setPrvuseAr(data)}
                                borderClass="bgcWhite w300 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                // placeholder="전용면적을 입력해주세요."
                                maxLength={10}
                                disabled={prvuseArDis}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitText="㎡"
                            />
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <div className={(props.form.taxFlag === 'P' ? '' : 'dpNone-i') + ' dpFlx h78 mb10'}>
                <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">용도지역</span>
                <div className="w360 aiB">
                    <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc222222">도시지역</span>
                    <div className="dpFlx aiC pb10 mt2">
                        <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc222222 mr2">(</span>
                        <div
                            className="dpFlx aiC csP mr2"
                            onClick={() => {
                                if (!spfcKndDisabled) setSpfcKnd('1001');
                            }}
                        >
                            <img
                                className="w24 h24 pr5"
                                src={require('img/common/radiobutton_base_' +
                                    (spfcKnd === '1001' ? 'on' : 'off') +
                                    '.png')}
                                alt="radiobutton_base"
                            />
                            <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">주거지역</span>
                        </div>
                        <div
                            className="dpFlx aiC csP mr2"
                            onClick={() => {
                                if (!spfcKndDisabled) setSpfcKnd('1002');
                            }}
                        >
                            <img
                                className="w24 h24 pr5"
                                src={require('img/common/radiobutton_base_' +
                                    (spfcKnd === '1002' ? 'on' : 'off') +
                                    '.png')}
                                alt="radiobutton_base"
                            />
                            <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">상업지역</span>
                        </div>
                        <div
                            className="dpFlx aiC csP mr2"
                            onClick={() => {
                                if (!spfcKndDisabled) setSpfcKnd('1003');
                            }}
                        >
                            <img
                                className="w24 h24 pr5"
                                src={require('img/common/radiobutton_base_' +
                                    (spfcKnd === '1003' ? 'on' : 'off') +
                                    '.png')}
                                alt="radiobutton_base"
                            />
                            <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">공업지역</span>
                        </div>
                        <div
                            className="dpFlx aiC csP"
                            onClick={() => {
                                if (!spfcKndDisabled) setSpfcKnd('1004');
                            }}
                        >
                            <img
                                className="w24 h24 pr5"
                                src={require('img/common/radiobutton_base_' +
                                    (spfcKnd === '1004' ? 'on' : 'off') +
                                    '.png')}
                                alt="radiobutton_base"
                            />
                            <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">녹지지역</span>
                        </div>
                        <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc222222 ml2">)</span>
                    </div>
                    <div className="dpFlx aiC">
                        <div
                            className="dpFlx aiC csP mr10"
                            onClick={() => {
                                if (!spfcKndDisabled) setSpfcKnd('1005');
                            }}
                        >
                            <img
                                className="w24 h24 pr5 pl5"
                                src={require('img/common/radiobutton_base_' +
                                    (spfcKnd === '1005' ? 'on' : 'off') +
                                    '.png')}
                                alt="radiobutton_base"
                            />
                            <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">관리지역</span>
                        </div>
                        <div
                            className="dpFlx aiC csP mr10"
                            onClick={() => {
                                if (!spfcKndDisabled) setSpfcKnd('1006');
                            }}
                        >
                            <img
                                className="w24 h24 pr5"
                                src={require('img/common/radiobutton_base_' +
                                    (spfcKnd === '1006' ? 'on' : 'off') +
                                    '.png')}
                                alt="radiobutton_base"
                            />
                            <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">농림지역</span>
                        </div>
                        <div
                            className="dpFlx aiC csP mr10"
                            onClick={() => {
                                if (!spfcKndDisabled) setSpfcKnd('1007');
                            }}
                        >
                            <img
                                className="w24 h24 pr5"
                                src={require('img/common/radiobutton_base_' +
                                    (spfcKnd === '1007' ? 'on' : 'off') +
                                    '.png')}
                                alt="radiobutton_base"
                            />
                            <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">자연환경보전지역</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dpFlx h35 aiC mb10">
                <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">조정대상지역</span>
                <ImageRadioBox
                    codeList={Util.getCodeArray('1036')}
                    value={mdatTrgetAreaAt}
                    setValue={setMdatTrgetAreaAt}
                    btnClass="w24 h24 pr5"
                    disabled={mdatTrgetAreaAtDis}
                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                    interval={20}
                />
            </div>
            <div className="dpFlx h35 aiC">
                <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">지분율(%)</span>
                <div className="dpFlx aiC jcC bgcWhite">
                    <NumberTextBox
                        value={shareRatio}
                        onChange={(data: string) => setShareRatio(data)}
                        borderClass="bgcWhite w300 h40 pr15 bd1"
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        maxLength={3}
                        // placeholder="지분율을 1~100 값으로 입력하세요."
                        inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                        unitText="%"
                    />
                </div>
            </div>

            <div className="w100p dpFlx aiC jcC pt40">
                <div className="w210 h56 bgcF6F6F6 dpFlx aiC jcC csP" onClick={goPrevPage}>
                    <span className="SpoqaHanSansNeo-Medium fs16 ls07 fc222222">이전</span>
                </div>
                <div className="w210 h56 bgc17A840 dpFlx aiC jcC csP" onClick={goFinish}>
                    <span className="SpoqaHanSansNeo-Medium fs16 ls07 fcWhite">완료</span>
                </div>
            </div>
        </React.Fragment>
    );
};
export default SimAddressEtc;
