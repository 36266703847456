import { ChangeEvent, useState, useEffect } from "react";
import styled from "styled-components";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

import Util from "js/common/Util";
import { useFinalAddrConfirm } from "js/common/hooks/useFinalAddrConfirm";
import { Code } from "js/common/types/types";
import { spfcKndOptions } from "js/common/const";
import SelectBox, { SelectOption } from "js/mobile/common/SelectBox";
import { NumericFormat } from "react-number-format";

type Props = {
  taxFlag: string;
};

function FinalAddrInfo({ taxFlag }: Props) {
  const {
    acquiredAssetInfo,
    inputDisabled,
    updateAddrEtcValue,
    finalAddr,
    selectedAddr,
  } = useFinalAddrConfirm(taxFlag);
  const [showBuildingInfo, setShowBuildingInfo] = useState<boolean>(false);
  const [showRegionInfo, setShowRegionInfo] = useState<boolean>(false);
  const [shareRatioHelpText, setShareRatioHelpText] = useState<string>("");

  useEffect(() => {
    if (["P", "A"].includes(taxFlag)) {
      setShowBuildingInfo(true);
    } else {
      setShowBuildingInfo(false);
    }
  }, [taxFlag]);

  useEffect(() => {
    if (taxFlag === "P") {
      setShowRegionInfo(true);
    } else {
      setShowRegionInfo(false);
    }
  }, [taxFlag]);

  const handleSelectMdatTrgetAreaAt = (selected: SelectOption) => {
    updateAddrEtcValue("mdatTrgetAreaAt", selected.value);
  };

  const handleSelectSpfcKnd = (selected: SelectOption) => {
    updateAddrEtcValue("spfcKnd", selected.value);
  };

  const handleChangeTextInput = (event: ChangeEvent<HTMLInputElement>) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    const valueWithoutComma = inputValue.replace(/,/g, "");
    const numberValue = parseInt(valueWithoutComma);

    if (inputName === "shareRatio") {
      if (numberValue > 100 || numberValue <= 0) {
        setShareRatioHelpText("1~100 사이의 값을 입력하세요.");
      } else {
        setShareRatioHelpText("");
      }
    }

    updateAddrEtcValue(inputName, valueWithoutComma);
  };

  if (selectedAddr) {
    return (
      <>
        <InputContainer>
          <p className="label">취득주소</p>
          <TextInputContainer>
            <div className="inputBox">
              <span className="text">{finalAddr}</span>
              <span className="icon">
                <CheckOutlinedIcon />
              </span>
            </div>
          </TextInputContainer>
        </InputContainer>
        {showBuildingInfo &&
          selectedAddr.bdKdcd === "0" && ( // 단독주택
            <>
              <InputContainer>
                <p className="label">개별주택가격(원)</p>
                <div className="input">
                  <NumberInputContainer
                    $filled={!acquiredAssetInfo.housePc ? false : true}
                  >
                    <div className="inputWrap">
                      <NumericFormat
                        value={acquiredAssetInfo.housePc}
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        maxLength={15}
                        onChange={(e) => {
                          if (handleChangeTextInput === undefined) {
                            return;
                          }
                          handleChangeTextInput(e);
                        }}
                        disabled={inputDisabled.housePcDisabled}
                        placeholder="개별주택가격을 입력해주세요."
                        className="numberInput"
                        name="housePc"
                      />
                      <p className="unit">원</p>
                    </div>
                  </NumberInputContainer>
                </div>
              </InputContainer>
              {taxFlag !== "P" && (
                <InputContainer>
                  <p className="label">주택연면적(㎡)</p>
                  <div className="input">
                    <NumberInputContainer
                      $filled={!acquiredAssetInfo.houseTotar ? false : true}
                    >
                      <div className="inputWrap">
                        <NumericFormat
                          value={acquiredAssetInfo.houseTotar}
                          maxLength={7}
                          decimalScale={2}
                          onChange={(e) => {
                            if (handleChangeTextInput === undefined) {
                              return;
                            }
                            handleChangeTextInput(e);
                          }}
                          disabled={inputDisabled.houseTotarDisabled}
                          placeholder="주택연면적을 입력해주세요."
                          className="numberInput"
                          name="houseTotar"
                        />
                        <p className="unit">㎡</p>
                      </div>
                    </NumberInputContainer>
                  </div>
                </InputContainer>
              )}
            </>
          )}
        {showBuildingInfo &&
          selectedAddr.bdKdcd === "1" && ( // 공동주택
            <>
              <InputContainer>
                <p className="label">공동주택가격(원)</p>
                <div className="input">
                  <NumberInputContainer
                    $filled={!acquiredAssetInfo.pblntfPc ? false : true}
                  >
                    <div className="inputWrap">
                      <NumericFormat
                        value={acquiredAssetInfo.pblntfPc}
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        maxLength={15}
                        onChange={(e) => {
                          if (handleChangeTextInput === undefined) {
                            return;
                          }
                          handleChangeTextInput(e);
                        }}
                        disabled={inputDisabled.pblntfPcDisabled}
                        placeholder="공동주택가격을 입력해주세요."
                        className="numberInput"
                        name="pblntfPc"
                      />
                      <p className="unit">원</p>
                    </div>
                  </NumberInputContainer>
                </div>
              </InputContainer>

              {taxFlag !== "P" && (
                <InputContainer>
                  <p className="label">전용면적(㎡)</p>
                  <div className="input">
                    <NumberInputContainer
                      $filled={!acquiredAssetInfo.prvuseAr ? false : true}
                    >
                      <div className="inputWrap">
                        <NumericFormat
                          value={acquiredAssetInfo.prvuseAr}
                          maxLength={7}
                          decimalScale={2}
                          onChange={(e) => {
                            if (handleChangeTextInput === undefined) {
                              return;
                            }
                            handleChangeTextInput(e);
                          }}
                          disabled={inputDisabled.prvuseArDisabled}
                          placeholder="전용면적을 입력해주세요."
                          className="numberInput"
                          name="prvuseAr"
                        />
                        <p className="unit">㎡</p>
                      </div>
                    </NumberInputContainer>
                  </div>
                </InputContainer>
              )}
            </>
          )}
        {showRegionInfo && (
          <InputContainer>
            <p className="label">용도지역</p>
            <div className="input">
              <SelectBox
                placeholder="용도지역을 선택해주세요."
                options={spfcKndOptions}
                disabled={inputDisabled.spfcKndDisabled}
                selectedValue={acquiredAssetInfo.spfcKnd}
                handleSelect={handleSelectSpfcKnd}
              />
            </div>
          </InputContainer>
        )}
        <InputContainer>
          <p className="label">조정대상지역</p>
          <div className="input">
            <SelectBox
              placeholder="조정대상지역을 선택해주세요."
              options={Util.getCodeArray("1036").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.mdatTrgetAreaAtDisabled}
              selectedValue={acquiredAssetInfo.mdatTrgetAreaAt}
              handleSelect={handleSelectMdatTrgetAreaAt}
            />
          </div>
        </InputContainer>
        <InputContainer>
          <p className="label">지분율(%)</p>
          <div className="input">
            <NumberInputContainer
              $filled={!acquiredAssetInfo.shareRatio ? false : true}
            >
              <div className="inputWrap">
                <NumericFormat
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  maxLength={3}
                  name="shareRatio"
                  value={acquiredAssetInfo.shareRatio}
                  onChange={(e) => {
                    if (handleChangeTextInput === undefined) {
                      return;
                    }
                    handleChangeTextInput(e);
                  }}
                  disabled={inputDisabled.disabled20}
                  placeholder="지분율을 입력해주세요."
                  className="numberInput"
                />
                <p className="unit">%</p>
              </div>
            </NumberInputContainer>
            <div className="help warning">{shareRatioHelpText}</div>
          </div>
        </InputContainer>
      </>
    );
  } else {
    return null;
  }
}

const InputContainer = styled.div`
  width: 100%;
  margin-top: 12px;

  .label {
    margin-bottom: 5px;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.29;
    letter-spacing: -0.058em;
    color: ${(props) => props.theme.color.nGray30};
  }

  .help {
    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.29;
    letter-spacing: -0.058em;
    color: ${(props) => props.theme.color.sNavyLight01};
    text-align: right;

    margin-top: 5px;

    &.warning {
      color: ${(props) => props.theme.color.sysRed100};
    }
  }
`;

const TextInputContainer = styled.div`
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  border: none;
  background-color: ${(props) => props.theme.color.pGreenLight03};

  font-family: "Spoqa Han Sans Neo";
  font-weight: 400;
  font-size: 1.4em;
  line-height: 1.32;
  letter-spacing: -0.05em;
  color: ${(props) => props.theme.color.nGray50};

  .inputBox {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 12px 16px;
    border: 1px solid ${(props) => props.theme.color.pGreenLight02};
    border-radius: 8px;
    color: ${(props) => props.theme.color.sNavy};
    background-color: white;

    .text {
      width: calc(100% - 12px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
    }

    .icon {
      width: 12px;
      color: ${(props) => props.theme.color.pGreen};
    }
  }
`;

const NumberInputContainer = styled.div<{ $filled: boolean }>`
  width: 100%;
  height: auto;

  ${(props) => {
    if (props.$filled) {
      return `
          padding: 5px;
          border-radius: 10px;
          background-color: ${props.theme.color.pGreenLight03};
          border: none;
        `;
    }
  }}

  .inputWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 12px 16px;
    background-color: white;
    border: 1px solid
      ${(props) =>
        props.$filled
          ? props.theme.color.pGreenLight02
          : props.theme.color.nGray70};
    border-radius: 8px;

    .unit {
      font-family: "Spoqa Han Sans Neo";
      font-weight: 500;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.sNavy};
    }

    .numberInput {
      width: 100%;
      height: 100%;
      border: none;

      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.sNavy};

      &:focus {
        outline: none;
      }
    }
    .numberInput::placeholder {
      color: ${(props) => props.theme.color.nGray50};
    }

    .numberInput::-webkit-input-placeholder {
      color: ${(props) => props.theme.color.nGray50};
    }

    .numberInput::-ms-input-placeholder {
      color: ${(props) => props.theme.color.nGray50};
    }
  }
`;

export default FinalAddrInfo;
