import React from "react";
import { useRecoilValue } from "recoil";
import * as Atom from "js/common/Atom";
import Util from "js/common/Util";
import LinesEllipsis from 'react-lines-ellipsis';
import ToolTip from "js/common/ui/ToolTip";
import Axios from "js/common/Axios";
import AppContext from "js/common/AppContext";

interface ComptaxCalcProps {
    setComptaxCalcModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    lstyrProptax: string
    lstyrComptax: string
    compTax: any
    setCompTax: React.Dispatch<React.SetStateAction<any>>
    propTaxInCompTax: any[] | undefined
    setPropTaxInCompTax: React.Dispatch<React.SetStateAction<any[] | undefined>>
}
const ComptaxCalc = ({setComptaxCalcModalOpen, lstyrProptax, lstyrComptax, compTax, setCompTax, propTaxInCompTax, setPropTaxInCompTax}: ComptaxCalcProps) => {
    const hshldSn = useRecoilValue(Atom.hshldSn);
    const [poptaxStdrMktc, setPoptaxStdrMktc] = React.useState<string>();
    const [poptaxStdAmt, setPoptaxStdAmt] = React.useState<string>();
    const [proptaxAmt, setProptaxAmt] = React.useState<string>();
    const [urbanTaxAmt, setUrbanTaxAmt] = React.useState<string>();
    const [eduTaxAmt, setEduTaxAmt] = React.useState<string>();
    const [equipTaxAmt, setEquipTaxAmt] = React.useState<string>();
    const [sumAmt, setSumAmt] = React.useState<string>();

    const selComptaxCalc = () => {
        let layoutOpt = "";
        if (compTax.layout_type === "1") {
            layoutOpt = "6";
        } else {
            layoutOpt = "1";
        }
        Axios.dataAccess({
            url: "api/system/broker/callApi.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                apiName: "taxcalc",
                tailUrl: "comptax/calctax",
                paramStr: hshldSn + "?layout_opt=" + layoutOpt + (Util.isNotEmpty(lstyrProptax) && Util.isNotEmpty(lstyrComptax) ? "&lstyr_proptax=" + Util.uncomma(lstyrProptax) + "&lstyr_comptax=" + Util.uncomma(lstyrComptax) : "")
            },
            onSuccessFn: (res: any) => {
                if (Util.isEmpty(res.item.rtn_list) || Util.isEmpty(res.item.rtn_proptax_list)) {
                    setComptaxCalcModalOpen(false);
                    AppContext.openAlert({
                        title: "산출내역 없음",
                        msg: "산출된 내역이 없습니다.",
                        icon: "check",
                    });
                    return;
                }
                setCompTax(res.item.rtn_list[0]);
                setPropTaxInCompTax(res.item.rtn_proptax_list);
                setComptaxCalcModalOpen(true);
            },
            onFailedFn: () => {
                setComptaxCalcModalOpen(false);
            }
        });
    }

    React.useEffect(() => {
        let poptaxStdrMktc = 0;
        let poptaxStdAmt = 0;
        let proptaxAmt = 0;
        let urbanTaxAmt = 0;
        let eduTaxAmt = 0;
        let equipTaxAmt = 0;
        let sumAmt = 0;
        if (propTaxInCompTax !== undefined) {
            for (const rtnProptaxList of propTaxInCompTax) {
                poptaxStdrMktc = poptaxStdrMktc + Number(rtnProptaxList.poptax_stdr_mktc);
                poptaxStdAmt = poptaxStdAmt + Number(rtnProptaxList.poptax_std_amt);
                proptaxAmt = proptaxAmt + Number(rtnProptaxList.proptax_amt);
                urbanTaxAmt = urbanTaxAmt + Number(rtnProptaxList.urban_tax_amt);
                eduTaxAmt = eduTaxAmt + Number(rtnProptaxList.edu_tax_amt);
                equipTaxAmt = equipTaxAmt + Number(rtnProptaxList.equip_tax_amt);
                sumAmt = sumAmt + Number(rtnProptaxList.proptax_amt) + Number(rtnProptaxList.urban_tax_amt) + Number(rtnProptaxList.edu_tax_amt) + Number(rtnProptaxList.equip_tax_amt);
            }
        }
        setPoptaxStdrMktc(Util.comma(poptaxStdrMktc.toString()));
        setPoptaxStdAmt(Util.comma(poptaxStdAmt.toString()));
        setProptaxAmt(Util.comma(proptaxAmt.toString()));
        setUrbanTaxAmt(Util.comma(urbanTaxAmt.toString()));
        setEduTaxAmt(Util.comma(eduTaxAmt.toString()));
        setEquipTaxAmt(Util.comma(equipTaxAmt.toString()));
        setSumAmt(Util.comma(sumAmt.toString()));
    }, [setComptaxCalcModalOpen, hshldSn, propTaxInCompTax]);

    const DupProptaxFldNmEl = () =>
        <div className="dpFlx aiC jcC">
             재산세중복분(지분율)
            <img
                src={require('img/common/icon_info_fill_20.png')}
                alt="icon_info_fill_20"
                className='pl3 csP'
                data-tooltip-id="comptaxCalcDupProptaxToolTip"
                data-tooltip-content="재산세중복분은 종합부동산세 과세표준에 상당하는 재산세를 공제하는 것이므로 실제 납부한 재산세와 다를 수 있습니다."
            />
            <ToolTip id="comptaxCalcDupProptaxToolTip" place='top' clickable={true} className="w300 taL lh20" />
        </div>
    ;

    const CompTaxTableEl = () => {
        if (Util.isEmpty(compTax)) {
            return (
                <div className="w100p h200 bgcDEDEDE dpFlx aiC jcC">
                    <span className="fs19 fc222222">종합부동산세 과세 대상이 아닙니다.</span>
                </div>
            )
        }
        if (compTax.house_sn === "1") {
            return (
                <table className={
                    "forest-form-table tr-h30 " +
                    "th-p0 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-ls07 th-fc222222 th-fw500 " +
                    "td-p15 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-ls075"
                }>
                    <colgroup>
                        <col className="w200" />
                        <col className="w200" />
                        <col className="w400" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th colSpan={2} title='세대주택수'>세대주택수</th>
                            <td className='taR'>{compTax.house_type}</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='주택공시가격합계(지분율)'>주택공시가격합계(지분율)</th>
                            <td className='taR'>{Util.comma(compTax.stdr_mktc_sum)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='공제금액(12억) 차감 후 금액'>공제금액(12억) 차감 후 금액</th>
                            <td className='taR'>{Util.comma(compTax.basic_rdc_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='공정시장가액비율'>공정시장가액비율</th>
                            <td className='taR'>{Number(compTax.fare_market_rate)} %</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='과세표준'>과세표준</th>
                            <td className='taR'>{Util.comma(compTax.std_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='세율(기본세율)'>세율(기본세율)</th>
                            <td className='taR'>{Number(compTax.comptax_rate)} %</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='누진공제액'>누진공제액</th>
                            <td className='taR'>{Util.comma(compTax.rdc_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 A (과세표준 * 세율 - 누진공제액)'>종합부동산세 A (과세표준 * 세율 - 누진공제액)</th>
                            <td className='taR'>{Util.comma(compTax.comptax_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='재산세중복분(지분율)'>{DupProptaxFldNmEl()}</th>
                            <td className='taR'>{Util.comma(compTax.dup_proptax)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 B (A - 재산세중복분)'>종합부동산세 B (A - 재산세중복분)</th>
                            <td className='taR'>{Util.comma(compTax.comptax_mnus_prop_amt)} 원</td>
                        </tr>
                        <tr>
                            <th rowSpan={3} title='세액공제 (1세대 1주택자)'>세액공제 (1세대 1주택자)</th>
                            <th title='고령자세액공제'>고령자세액공제</th>
                            <td className='taR'>{Number(compTax.age_rdc_rate)} %</td>
                        </tr>
                        <tr>
                            <th title='장기보유세액공제'>장기보유세액공제</th>
                            <td className='taR'>{Number(compTax.hold_rdc_rate)} %</td>
                        </tr>
                        <tr>
                            <th title='합계(80%한도)'>합계(80%한도)</th>
                            <td className='taR'>{Number(compTax.one_hse_rdc_rate)} %</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 C (B - 세액공제)'>종합부동산세 C (B - 세액공제)</th>
                            <td className='taR'>{Util.comma(compTax.comptax_rdc_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 (세부담상한 적용)'>종합부동산세 (세부담상한 적용)</th>
                            <td className='taR'>{Util.comma(compTax.real_comptax_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='농어촌특별세'>농어촌특별세</th>
                            <td className='taR'>{Util.comma(compTax.cntry_tax_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='합계'><span className="SpoqaHanSansNeo-Medium fs20 ls1 fc222222 fw500">합계</span></th>
                            <td className='taR'><span className='SpoqaHanSansNeo-Medium fs20 ls1 fc222222 fw500'>{Util.comma(compTax.total_comptax_amt)} 원</span></td>
                        </tr>
                    </tbody>
                </table>
            )
        } else if (compTax.house_sn === "2" && compTax.layout_type === "1") {
            return (
                <table className={
                    "forest-form-table tr-h30 " +
                    "th-p0 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-ls07 th-fc222222 th-fw500 " +
                    "td-p15 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-ls075"
                }>
                    <colgroup>
                        <col className="w200" />
                        <col className="w200" />
                        <col className="w400" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th colSpan={2} title='세대주택수'>세대주택수</th>
                            <td className='taR'>{compTax.house_type}</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='주택공시가격합계'>주택공시가격합계</th>
                            <td className='taR'>{Util.comma(compTax.stdr_mktc_sum)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='공제금액(12억) 차감 후 금액'>공제금액(12억) 차감 후 금액</th>
                            <td className='taR'>{Util.comma(compTax.basic_rdc_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='공정시장가액비율'>공정시장가액비율</th>
                            <td className='taR'>{Number(compTax.fare_market_rate)} %</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='과세표준'>과세표준</th>
                            <td className='taR'>{Util.comma(compTax.std_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='세율(기본세율)'>세율(기본세율)</th>
                            <td className='taR'>{Number(compTax.comptax_rate)} %</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='누진공제액'>누진공제액</th>
                            <td className='taR'>{Util.comma(compTax.rdc_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 A (과세표준 * 세율 - 누진공제액)'>종합부동산세 A (과세표준 * 세율 - 누진공제액)</th>
                            <td className='taR'>{Util.comma(compTax.comptax_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='재산세중복분'>{DupProptaxFldNmEl()}</th>
                            <td className='taR'>{Util.comma(compTax.dup_proptax)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 B (A - 재산세중복분)'>종합부동산세 B (A - 재산세중복분)</th>
                            <td className='taR'>{Util.comma(compTax.comptax_mnus_prop_amt)} 원</td>
                        </tr>
                        <tr>
                            <th rowSpan={3} title='세액공제 (1세대 1주택자)'>세액공제 (1세대 1주택자)</th>
                            <th title='고령자세액공제'>고령자세액공제</th>
                            <td className='taR'>{Number(compTax.age_rdc_rate)} %</td>
                        </tr>
                        <tr>
                            <th title='장기보유세액공제'>장기보유세액공제</th>
                            <td className='taR'>{Number(compTax.hold_rdc_rate)} %</td>
                        </tr>
                        <tr>
                            <th title='합계(80%한도)'>합계(80%한도)</th>
                            <td className='taR'>{Number(compTax.one_hse_rdc_rate)} %</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 C (B - 세액공제)'>종합부동산세 C (B - 세액공제)</th>
                            <td className='taR'>{Util.comma(compTax.comptax_rdc_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 (세부담상한 적용)'>종합부동산세 (세부담상한 적용)</th>
                            <td className='taR'>{Util.comma(compTax.real_comptax_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='농어촌특별세'>농어촌특별세</th>
                            <td className='taR'>{Util.comma(compTax.cntry_tax_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='합계'><span className="SpoqaHanSansNeo-Medium fs20 ls1 fc222222 fw500">합계</span></th>
                            <td className='taR'><span className='SpoqaHanSansNeo-Medium fs20 ls1 fc222222 fw500'>{Util.comma(compTax.total_comptax_amt)} 원</span></td>
                        </tr>
                    </tbody>
                </table>
            )
        } else if (compTax.house_sn === "2" && compTax.layer_type === "6") {
            return (
                <table className={
                    "forest-form-table tr-h30 " +
                    "th-p0 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-ls07 th-fc222222 th-fw500 " +
                    "td-p15 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-ls075"
                }>
                    <colgroup>
                        <col className="w200" />
                        <col className="w200" />
                        <col className="w130" />
                        <col className="w130" />
                        <col className="w130" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th colSpan={2} title='부부 각각 소유로 계산'>부부 각각 소유로 계산</th>
                            <th>본인 종합부동산세</th>
                            <th>배우자 종합부동산세</th>
                            <th>부부합계</th>
                        </tr>
                        <tr>
                            <th colSpan={2} title='세대주택수'>세대주택수</th>
                            <td className='taR'>{compTax[0].house_type}</td>
                            <td className='taR'>{compTax[1].house_type}</td>
                            <td className='taR'>{compTax[2].house_type}</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='주택공시가격합계'>주택공시가격합계</th>
                            <td className='taR'>{Util.comma(compTax[0].stdr_mktc_sum)} 원</td>
                            <td className='taR'>{Util.comma(compTax[1].stdr_mktc_sum)} 원</td>
                            <td className='taR'>{Util.comma(compTax[2].stdr_mktc_sum)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='공제금액(각각 9억)'>공제금액(각각 9억)</th>
                            <td className='taR'>{Util.comma(compTax[0].basic_rdc_amt)} 원</td>
                            <td className='taR'>{Util.comma(compTax[1].basic_rdc_amt)} 원</td>
                            <td className='taR'>{Util.comma(compTax[2].basic_rdc_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='공정시장가액비율ㅍ'>공정시장가액비율ㅊ</th>
                            <td className='taR'>{Number(compTax[0].fare_market_rate)} %</td>
                            <td className='taR'>{Number(compTax[1].fare_market_rate)} %</td>
                            <td className='taR'>{Number(compTax[2].fare_market_rate)} %</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='과세표준'>과세표준</th>
                            <td className='taR'>{Util.comma(compTax[0].std_amt)} 원</td>
                            <td className='taR'>{Util.comma(compTax[1].std_amt)} 원</td>
                            <td className='taR'>{Util.comma(compTax[2].std_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='세율(기본세율)'>세율(기본세율)</th>
                            <td className='taR'>{Number(compTax[0].comptax_rate)} %</td>
                            <td className='taR'>{Number(compTax[1].comptax_rate)} %</td>
                            <td className='taR'>{Number(compTax[2].comptax_rate)} %</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='누진공제액'>누진공제액</th>
                            <td className='taR'>{Util.comma(compTax[0].rdc_amt)} 원</td>
                            <td className='taR'>{Util.comma(compTax[1].rdc_amt)} 원</td>
                            <td className='taR'>{Util.comma(compTax[2].rdc_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 A (과세표준 * 세율 - 누진공제액)'>종합부동산세 A (과세표준 * 세율 - 누진공제액)</th>
                            <td className='taR'>{Util.comma(compTax[0].comptax_amt)} 원</td>
                            <td className='taR'>{Util.comma(compTax[1].comptax_amt)} 원</td>
                            <td className='taR'>{Util.comma(compTax[2].comptax_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='재산세 중복분'>재산세 중복분</th>
                            <td className='taR'>{Util.comma(compTax[0].dup_proptax)} 원</td>
                            <td className='taR'>{Util.comma(compTax[1].dup_proptax)} 원</td>
                            <td className='taR'>{Util.comma(compTax[2].dup_proptax)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 B (A - 재산세중복분)'>종합부동산세 B (A - 재산세중복분)</th>
                            <td className='taR'>{Util.comma(compTax[0].comptax_mnus_prop_amt)} 원</td>
                            <td className='taR'>{Util.comma(compTax[1].comptax_mnus_prop_amt)} 원</td>
                            <td className='taR'>{Util.comma(compTax[2].comptax_mnus_prop_amt)} 원</td>
                        </tr>
                        <tr>
                            <th rowSpan={3} title='세액공제 (1세대 1주택자)'>세액공제 (1세대 1주택자)</th>
                            <th title='고령자세액공제'>고령자세액공제</th>
                            <td className='taR'>{Number(compTax[0].age_rdc_rate)} %</td>
                            <td className='taR'>{Number(compTax[1].age_rdc_rate)} %</td>
                            <td className='taR'>{Number(compTax[2].age_rdc_rate)} %</td>
                        </tr>
                        <tr>
                            <th title='장기보유세액공제'>장기보유세액공제</th>
                            <td className='taR'>{Number(compTax[0].hold_rdc_rate)} %</td>
                            <td className='taR'>{Number(compTax[1].hold_rdc_rate)} %</td>
                            <td className='taR'>{Number(compTax[2].hold_rdc_rate)} %</td>
                        </tr>
                        <tr>
                            <th title='합계(80%한도)'>합계(80%한도)</th>
                            <td className='taR'>{Number(compTax[0].one_hse_rdc_rate)} %</td>
                            <td className='taR'>{Number(compTax[1].one_hse_rdc_rate)} %</td>
                            <td className='taR'>{Number(compTax[2].one_hse_rdc_rate)} %</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 C (B - 세액공제)'>종합부동산세 C (B - 세액공제)</th>
                            <td className='taR'>{Util.comma(compTax[0].comptax_rdc_amt)} 원</td>
                            <td className='taR'>{Util.comma(compTax[1].comptax_rdc_amt)} 원</td>
                            <td className='taR'>{Util.comma(compTax[2].comptax_rdc_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 (세부담상한 적용)'>종합부동산세 (세부담상한 적용)</th>
                            <td className='taR'>{Util.comma(compTax[0].real_comptax_amt)} 원</td>
                            <td className='taR'>{Util.comma(compTax[1].real_comptax_amt)} 원</td>
                            <td className='taR'>{Util.comma(compTax[2].real_comptax_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='농어촌특별세'>농어촌특별세</th>
                            <td className='taR'>{Util.comma(compTax[0].cntry_tax_amt)} 원</td>
                            <td className='taR'>{Util.comma(compTax[1].cntry_tax_amt)} 원</td>
                            <td className='taR'>{Util.comma(compTax[2].cntry_tax_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='합계'><span className="SpoqaHanSansNeo-Medium fs20 ls1 fc222222 fw500">합계</span></th>
                            <td className='taR'><span className='SpoqaHanSansNeo-Medium fs20 ls1 fc222222 fw500'>{Util.comma(compTax.total_comptax_amt)} 원</span></td>
                        </tr>
                    </tbody>
                </table>
            )
        } else if (compTax.house_sn === "3") {
            return (
                <table className={
                    "forest-form-table tr-h30 " +
                    "th-p0 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-ls07 th-fc222222 th-fw500 " +
                    "td-p15 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-ls075"
                }>
                    <colgroup>
                        <col className="w200" />
                        <col className="w200" />
                        <col className="w400" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th colSpan={2} title='그 밖의 가족 공동소유 1주택 경우'>그 밖의 가족 공동소유 1주택 경우</th>
                            <th title='금액'>금액</th>
                        </tr>
                        <tr>
                            <th colSpan={2} title='세대주택수'>세대주택수</th>
                            <td className='taR'>{compTax.house_type}</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='주택공시가격합계'>주택공시가격합계</th>
                            <td className='taR'>{Util.comma(compTax.stdr_mktc_sum)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='공제금액(9억) 차감 후 금액'>공제금액(9억) 차감 후 금액</th>
                            <td className='taR'>{Util.comma(compTax.basic_rdc_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='공정시장가액비율(60%)'>공정시장가액비율(60%)</th>
                            <td className='taR'>{Number(compTax.fare_market_rate)} %</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='과세표준'>과세표준</th>
                            <td className='taR'>{Util.comma(compTax.std_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='세율(기본세율)'>세율(기본세율)</th>
                            <td className='taR'>{Number(compTax.comptax_rate)} %</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='누진공제액'>누진공제액</th>
                            <td className='taR'>{Util.comma(compTax.rdc_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 A (과세표준 * 세율 - 누진공제액)'>종합부동산세 A (과세표준 * 세율 - 누진공제액)</th>
                            <td className='taR'>{Util.comma(compTax.comptax_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='재산세중복분'>{DupProptaxFldNmEl()}</th>
                            <td className='taR'>{Util.comma(compTax.dup_proptax)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 B (A - 재산세중복분)'>종합부동산세 B (A - 재산세중복분)</th>
                            <td className='taR'>{Util.comma(compTax.comptax_mnus_prop_amt)} 원</td>
                        </tr>
                        <tr>
                            <th rowSpan={3} title='세액공제 (1세대 1주택자)'>세액공제 (1세대 1주택자)</th>
                            <th title='고령자세액공제'>고령자세액공제</th>
                            <td className='taR'>{Number(compTax.age_rdc_rate)} %</td>
                        </tr>
                        <tr>
                            <th title='장기보유세액공제'>장기보유세액공제</th>
                            <td className='taR'>{Number(compTax.hold_rdc_rate)} %</td>
                        </tr>
                        <tr>
                            <th title='합계(80%한도)'>합계(80%한도)</th>
                            <td className='taR'>{Number(compTax.one_hse_rdc_rate)} %</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 C (B - 세액공제)'>종합부동산세 C (B - 세액공제)</th>
                            <td className='taR'>{Util.comma(compTax.comptax_rdc_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 (세부담상한 적용)'>종합부동산세 (세부담상한 적용)</th>
                            <td className='taR'>{Util.comma(compTax.real_comptax_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='농어촌특별세'>농어촌특별세</th>
                            <td className='taR'>{Util.comma(compTax.cntry_tax_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='합계'><span className="SpoqaHanSansNeo-Medium fs20 ls1 fc222222 fw500">합계</span></th>
                            <td className='taR'><span className='SpoqaHanSansNeo-Medium fs20 ls1 fc222222 fw500'>{Util.comma(compTax.total_comptax_amt)} 원</span></td>
                        </tr>
                    </tbody>
                </table>
            )
        } else if (compTax.house_sn === "4") {
            return (
                <table className={
                    "forest-form-table tr-h30 " +
                    "th-p0 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-ls07 th-fc222222 th-fw500 " +
                    "td-p15 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-ls075"
                }>
                    <colgroup>
                        <col className="w200" />
                        <col className="w200" />
                        <col className="w400" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th colSpan={2} title='2주택 이하'>2주택 이하</th>
                            <th title='금액'>금액</th>
                        </tr>
                        <tr>
                            <th colSpan={2} title='세대주택수'>세대주택수</th>
                            <td className='taR'>{compTax.house_type}</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='주택공시가격합계'>주택공시가격합계</th>
                            <td className='taR'>{Util.comma(compTax.stdr_mktc_sum)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='공제금액(9억) 차감 후 금액'>공제금액(9억) 차감 후 금액</th>
                            <td className='taR'>{Util.comma(compTax.basic_rdc_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='공정시장가액비율(60%)'>공정시장가액비율(60%)</th>
                            <td className='taR'>{Number(compTax.fare_market_rate)} %</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='과세표준'>과세표준</th>
                            <td className='taR'>{Util.comma(compTax.std_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='세율(기본세율)'>세율(기본세율)</th>
                            <td className='taR'>{Number(compTax.comptax_rate)} %</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='누진공제액'>누진공제액</th>
                            <td className='taR'>{Util.comma(compTax.rdc_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 A (과세표준 * 세율 - 누진공제액)'>종합부동산세 A (과세표준 * 세율 - 누진공제액)</th>
                            <td className='taR'>{Util.comma(compTax.comptax_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='재산세중복분'>{DupProptaxFldNmEl()}</th>
                            <td className='taR'>{Util.comma(compTax.dup_proptax)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 B (A - 재산세중복분)'>종합부동산세 B (A - 재산세중복분)</th>
                            <td className='taR'>{Util.comma(compTax.comptax_mnus_prop_amt)} 원</td>
                        </tr>
                        <tr>
                            <th rowSpan={3} title='세액공제 (1세대 1주택자) - 적용안함'>세액공제 (1세대 1주택자) - 적용안함</th>
                            <th title='고령자세액공제'>고령자세액공제</th>
                            <td className='taR'>{Number(compTax.age_rdc_rate)} %</td>
                        </tr>
                        <tr>
                            <th title='장기보유세액공제'>장기보유세액공제</th>
                            <td className='taR'>{Number(compTax.hold_rdc_rate)} %</td>
                        </tr>
                        <tr>
                            <th title='합계(80%한도)'>합계(80%한도)</th>
                            <td className='taR'>{Number(compTax.one_hse_rdc_rate)} %</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 C (B - 세액공제)'>종합부동산세 C (B - 세액공제)</th>
                            <td className='taR'>{Util.comma(compTax.comptax_rdc_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 (세부담상한 적용)'>종합부동산세 (세부담상한 적용)</th>
                            <td className='taR'>{Util.comma(compTax.real_comptax_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='농어촌특별세'>농어촌특별세</th>
                            <td className='taR'>{Util.comma(compTax.cntry_tax_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='합계'><span className="SpoqaHanSansNeo-Medium fs20 ls1 fc222222 fw500">합계</span></th>
                            <td className='taR'><span className='SpoqaHanSansNeo-Medium fs20 ls1 fc222222 fw500'>{Util.comma(compTax.total_comptax_amt)} 원</span></td>
                        </tr>
                    </tbody>
                </table>
            )
        } else if (compTax.house_sn === "5") {
            return (
                <table className={
                    "forest-form-table tr-h30 " +
                    "th-p0 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-ls07 th-fc222222 th-fw500 " +
                    "td-p15 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-ls075"
                }>
                    <colgroup>
                        <col className="w200" />
                        <col className="w200" />
                        <col className="w400" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th colSpan={2} title='3주택 이상'>3주택 이상</th>
                            <th title='금액'>금액</th>
                        </tr>
                        <tr>
                            <th colSpan={2} title='세대주택수'>세대주택수</th>
                            <td className='taR'>{compTax.house_type}</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='주택공시가격합계'>주택공시가격합계</th>
                            <td className='taR'>{Util.comma(compTax.stdr_mktc_sum)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='공제금액(9억) 차감 후 금액'>공제금액(9억) 차감 후 금액</th>
                            <td className='taR'>{Util.comma(compTax.basic_rdc_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='공정시장가액비율(60%)'>공정시장가액비율(60%)</th>
                            <td className='taR'>{Number(compTax.fare_market_rate)} %</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='과세표준'>과세표준</th>
                            <td className='taR'>{Util.comma(compTax.std_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='세율(기본세율)'>세율(기본세율)</th>
                            <td className='taR'>{Number(compTax.comptax_rate)} %</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='누진공제액'>누진공제액</th>
                            <td className='taR'>{Util.comma(compTax.rdc_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 A (과세표준 * 세율 - 누진공제액)'>종합부동산세 A (과세표준 * 세율 - 누진공제액)</th>
                            <td className='taR'>{Util.comma(compTax.comptax_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='재산세중복분'>{DupProptaxFldNmEl()}</th>
                            <td className='taR'>{Util.comma(compTax.dup_proptax)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 B (A - 재산세중복분)'>종합부동산세 B (A - 재산세중복분)</th>
                            <td className='taR'>{Util.comma(compTax.comptax_mnus_prop_amt)} 원</td>
                        </tr>
                        <tr>
                            <th rowSpan={3} title='세액공제 (1세대 1주택자) - 적용안함'>세액공제 (1세대 1주택자) - 적용안함</th>
                            <th title='고령자세액공제'>고령자세액공제</th>
                            <td className='taR'>{Number(compTax.age_rdc_rate)} %</td>
                        </tr>
                        <tr>
                            <th title='장기보유세액공제'>장기보유세액공제</th>
                            <td className='taR'>{Number(compTax.hold_rdc_rate)} %</td>
                        </tr>
                        <tr>
                            <th title='합계(80%한도)'>합계(80%한도)</th>
                            <td className='taR'>{Number(compTax.one_hse_rdc_rate)} %</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 C (B - 세액공제)'>종합부동산세 C (B - 세액공제)</th>
                            <td className='taR'>{Util.comma(compTax.comptax_rdc_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='종합부동산세 (세부담상한 적용)'>종합부동산세 (세부담상한 적용)</th>
                            <td className='taR'>{Util.comma(compTax.real_comptax_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='농어촌특별세'>농어촌특별세</th>
                            <td className='taR'>{Util.comma(compTax.cntry_tax_amt)} 원</td>
                        </tr>
                        <tr>
                            <th colSpan={2} title='합계'><span className="SpoqaHanSansNeo-Medium fs20 ls1 fc222222 fw500">합계</span></th>
                            <td className='taR'><span className='SpoqaHanSansNeo-Medium fs20 ls1 fc222222 fw500'>{Util.comma(compTax.total_comptax_amt)} 원</span></td>
                        </tr>
                    </tbody>
                </table>
            )
        } else {
            return (<></>)
        }
    }

    return (
        <div className='w1200 mxh600 ofA'>
            <div className='dpFlx jcSb aiC mb10'>
                <div className='dpFlx aiC'>
                    <img className="pr10" src={require('img/system/assets/icon_propertytax.png')} alt="icon_propertytax" />
                    <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 fw500'>재산세 정보</span>
                </div>
                <div className="aiC mr10">
                    <span className="SpoqaHanSansNeo-Regular fs14 ls1">
                        <span className='fc999999'>세액 단위 :</span>
                        <span className='fc222222'> 원</span>
                    </span>
                </div>
            </div>
            {Util.isNotEmpty(propTaxInCompTax) ?
                <table className={
                    "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD tr-h30 mb20 " +
                    "th-bdb1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 th-ls1 th-fc222222 " +
                    "td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls075 td-fc666666 td-bdcDDDDDD td-p10"
                }>
                    <colgroup>
                        <col />
                        <col className="w124" />
                        <col className="w70" />
                        <col className="w124" />
                        <col className="w124" />
                        <col className="w124" />
                        <col className="w124" />
                        <col className="w124" />
                        <col className="w124" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>소재지</th>
                            <th>공시가격</th>
                            <th>지분율</th>
                            <th>과세표준</th>
                            <th>재산세</th>
                            <th>도시지역분</th>
                            <th>지방교육세</th>
                            <th>지역지원시설세</th>
                            <th>합계</th>
                        </tr>
                        {propTaxInCompTax && propTaxInCompTax.map((item: any, idx: number) => {
                            const sumAmt = Util.comma((
                                Number(item.proptax_amt) +
                                Number(item.urban_tax_amt) +
                                Number(item.edu_tax_amt) +
                                Number(item.equip_tax_amt)
                            ).toString());

                            return (
                                <tr key={idx}>
                                    <td data-tooltip-id={"ComptaxCalc_propTaxInCompTax_rdnm_adres" + idx} data-tooltip-content={item.rdnm_adres}>
                                        <LinesEllipsis text={item.rdnm_adres.toString()} maxLine='1' ellipsis='...' trimRight />
                                        <ToolTip id={"ComptaxCalc_propTaxInCompTax_rdnm_adres" + idx} place='top' />
                                    </td>
                                    <td className="taR">{Util.comma(item.poptax_stdr_mktc)}</td>
                                    <td className="taR">{item.proptax_qota_rate} %</td>
                                    <td className="taR">{Util.comma(item.poptax_std_amt)}</td>
                                    <td className="taR">{Util.comma(item.proptax_amt)}</td>
                                    <td className="taR">{Util.comma(item.urban_tax_amt)}</td>
                                    <td className="taR">{Util.comma(item.edu_tax_amt)}</td>
                                    <td className="taR">{Util.comma(item.equip_tax_amt)}</td>
                                    <td className="taR">{sumAmt}</td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td><span className="SpoqaHanSansNeo-Medium fs15 ls075 fc222222 fw500">합계</span></td>
                            <td className="taR"><span className="SpoqaHanSansNeo-Medium fs15 ls075 fc222222 fw500">{poptaxStdrMktc ? Util.comma(poptaxStdrMktc) : ""}</span></td>
                            <td className="taR"></td>
                            <td className="taR"><span className="SpoqaHanSansNeo-Medium fs15 ls075 fc222222 fw500">{poptaxStdAmt ? Util.comma(poptaxStdAmt) : ""}</span></td>
                            <td className="taR"><span className="SpoqaHanSansNeo-Medium fs15 ls075 fc222222 fw500">{proptaxAmt ? Util.comma(proptaxAmt) : ""}</span></td>
                            <td className="taR"><span className="SpoqaHanSansNeo-Medium fs15 ls075 fc222222 fw500">{urbanTaxAmt ? Util.comma(urbanTaxAmt) : ""}</span></td>
                            <td className="taR"><span className="SpoqaHanSansNeo-Medium fs15 ls075 fc222222 fw500">{eduTaxAmt ? Util.comma(eduTaxAmt) : ""}</span></td>
                            <td className="taR"><span className="SpoqaHanSansNeo-Medium fs15 ls075 fc222222 fw500">{equipTaxAmt ? Util.comma(equipTaxAmt) : ""}</span></td>
                            <td className="taR"><span className="SpoqaHanSansNeo-Medium fs15 ls075 fc222222 fw500">{sumAmt ? Util.comma(sumAmt) : ""}</span></td>
                        </tr>
                    </tbody>
                </table>
            : <></>}
            <div className='dpFlx aiC jcSb mt20 mb10'>
                <div className='dpFlx aiC'>
                    <img className="pr10" src={require('img/system/assets/icon_listup.png')} alt="icon_listup" />
                    <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 fw500'>종합부동산세 내역</span>
                </div>
                {Util.isNotEmpty(compTax) ?
                    <div className={'jcC aiC w147 h36 bd1 bdc17A840 bdrd40 csP ' + (compTax.house_sn === "2" && compTax.rtn_code === "0" ? "dpFlx" : "dpNone")} onClick={selComptaxCalc}>
                        <span className='SpoqaHanSansNeo-Regular fs14 ls07 fc17A840'>
                            {compTax.layout_type === "1" ? "각각 소유권 계산" : "1인 소유권 계산"}
                        </span>
                        <img className="pl5" src={require('img/system/assets/icon_cal20_green.png')} alt="icon_cal20_green" />
                    </div>
                : <></>}
            </div>
            {CompTaxTableEl()}
            <div className='w100p dpFlx aiC jcC mt40'>
                <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => setComptaxCalcModalOpen(false)}>
                    <span className='SpoqaHanSansNeo-Medium fs16 fcWhite fw500'>확인</span>
                </div>
            </div>
        </div>
    )
}
export default ComptaxCalc;