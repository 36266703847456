import { useNavigate } from "react-router-dom";

import ModalPortal from "js/common/ui/modal/ModalPortal";
import Header from "./Header";
import StepButton from "./StepButton";
import { MainSectionContainer, DialogBody, DialogMainSection } from "js/mobile/common/layout";
import { useAddressInfo } from "./useAddressInfo";
import AddressSearch from "js/mobile/AddressInfo/AddressSearch";
import DongHoSelect from "js/mobile/AddressInfo/DongHoSelect";
import FinalAddrInfo from "js/mobile/AddressInfo/FinalAddrInfo";
import DirectAssetAddressInfo from "js/mobile/AddressInfo/DirectAssetAddressInfo";
import { Housing } from "js/common/types/types";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onAddHousing: (housing: Housing) => void;
  apiStatus: string;
};

function AddressInfoDialog({ open, setOpen, onAddHousing, apiStatus }: Props) {
  const navigate = useNavigate();
  const {
    currentStep,
    searchText,
    searchResult,
    nextDisabled,
    isLastStep,
    setSearchText,
    setSearchResult,
    handleClickBackStep,
    handleClickNextStep,
    handleClickReset,
    initAddrDialog,
  } = useAddressInfo(onAddHousing, apiStatus);

  const handleClickClose = () => {
    setOpen(false);
    initAddrDialog();

    // 주택정보추가 dialog에서 뒤로가기 버튼 클릭 시 모바일 계산기 메뉴화면으로 이동
    navigate("/simpletax");
  };

  const handleSelectAddr = () => {
    handleClickNextStep();
  };

  const handleClickBack = () => {
    if (currentStep && currentStep.stepId === "ADDR01") {
      // 첫번째 단계에서 Back 버튼 클릭 시 Diaolg 닫기
      setOpen(false);
      initAddrDialog();
    } else {
      handleClickBackStep();
    }
  };

  const handleClickNext = () => {
    if (isLastStep) {
      setOpen(false);
    }
    handleClickNextStep();
  };

  if (open) {
    if (!currentStep) {
      return <></>;
    } else {
      return (
        <ModalPortal>
          <DialogBody>
            <Header onClickBack={handleClickClose} />
            <DialogMainSection>
              {apiStatus === "0" ? (
                <MainSectionContainer>
                  <p className="desc">
                    {currentStep.stepId === "ADDR01" && (
                      <span className="bold">주소 입력 (1/3)</span>
                    )}
                    {currentStep.stepId === "ADDR02" && (
                      <span className="bold">상세 주소 입력 (2/3)</span>
                    )}
                    {currentStep.stepId === "ADDR03" && (
                      <span className="bold">최종 주소 확인 (3/3)</span>
                    )}
                  </p>
                  {(currentStep.stepId === "ADDR01" ||
                    currentStep.stepId === "ADDR02") && (
                    <AddressSearch
                      searchText={searchText}
                      setSearchText={setSearchText}
                      searchResult={searchResult}
                      setSearchResult={setSearchResult}
                      updateSelectedAddr={handleSelectAddr}
                    />
                  )}
                  {currentStep.stepId === "ADDR02" && <DongHoSelect />}
                  {currentStep.stepId === "ADDR03" && (
                    <FinalAddrInfo taxFlag="P" />
                  )}
                </MainSectionContainer>
              ) : (
                <DirectAssetAddressInfo />
              )}

              <StepButton
                onClickBack={handleClickBack}
                onClickNext={handleClickNext}
                onClickReset={handleClickReset}
                backDisabled={false}
                nextDisabled={nextDisabled}
                isLastStep={isLastStep}
              />
            </DialogMainSection>
          </DialogBody>
        </ModalPortal>
      );
    }
  } else {
    return <></>;
  }
}



export default AddressInfoDialog;
