/*******************************************************************************
*** SimAddressSearch ** 간편계산기 주소검색 1단계 * 주소 검색
*******************************************************************************/
import React from 'react';
import { IWizard } from "use-wizard/lib/cjs/useWizard/types/IWizard";
import { TStep } from "use-wizard/lib/cjs/useWizard/types/TStep";
import MuiListFilter from 'js/common/ui/list/MuiListFilter';
import ForestTableStyle from 'js/common/ui/style/ForestStyle';
import Axios from "js/common/Axios";
import AppContext from 'js/common/AppContext';
import Util from 'js/common/Util';

const SimAddressSearch = (props: {
    step: TStep;
    wizard: IWizard;
    form: any;
    dispatchForm: Function;
}) => {
    const addrSrchTextEl = React.useRef<HTMLInputElement>(null);
    const addrSrchOrdFld = { name: "roadAddr", direction: "asc" };
    const [addrSrchDataArr, setAddrSrchDataArr] = React.useState<Array<string>>(props.form.addrSrchDataArr); // FldInfs 셋팅데이터 서버조회결과
    const [addrSrchText, setAddrSrchText] = React.useState<string>(props.form.addrSrchText); // 검색키워드

    // 주소검색 삭제아이콘
    const onClickDelBtnFunc = () => {
        setAddrSrchText("");
        setAddrSrchDataArr([]);
    }

    // 주소검색 클릭
    const onClickFunc = () => {
        if (Util.isEmpty(addrSrchText)) {
            AppContext.openAlert({
                title: "검색어 확인",
                msg: "검색어를 입력해주세요.",
                icon: "warning"
            });
            return;
        }
        selAddrSrchList();
    }

    // 주소검색결과 목록 내 엘리먼트
    const AddrItem = (item: any) => {
        return (
            <div key={item.buldMnnm} className='taL csP w100p pt10 pb10 bdBox dpFlx flxdCol'>
                <div className='w100p dpFlx aiC'>
                    <div className='w50 h24 bd1 bdc3C89CD bdrd4 dpFlx aiC jcC bdBox mr10'>
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc3C89CD'>도로명</span>
                    </div>
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc666666'>{item.roadAddr}</span>
                </div>
                <div className='w100p dpFlx aiC pt3'>
                    <div className='w50 h24 bd1 bdc3C89CD bdrd4 dpFlx aiC jcC bdBox mr10'>
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc3C89CD'>지 번</span>
                    </div>
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc666666'>{item.jibunAddr}</span>
                </div>
            </div>
        )
    }

    // 주소 목록 조회
    const selAddrSrchList = React.useCallback(() => {
        if (Util.isEmpty(addrSrchText) || addrSrchText.length < 2) {
            return;
        }
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "api/system/broker/callApi.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                apiName: "openapi",
                tailUrl: "addr/juso",
                paramStr: encodeURI(addrSrchText) + ",1,100"
            },
            onSuccessFn: (res: any) => {
                setAddrSrchDataArr(res.item.rtn_list);
                AppContext.hideSpinner();
            }
        })
    }, [addrSrchText]);

    const addrSrchFldInfs = [
        { fldNm: 'pnu', label: 'pnu' },
        { fldNm: 'bdKdcd', label: '공동주택여부' },
        { fldNm: 'roadAddr', label: '도로명' },
        { fldNm: 'jibunAddr', label: '지번' },
        { fldNm: 'bdNm', label: '건물명' },
        { fldNm: 'detBdNmList', label: '상세건물명' },
        { fldNm: 'admCd', label: '행정구역코드' },
        { fldNm: 'udrtYn', label: '지하여부' },
        { fldNm: 'rnMgtSn', label: '도로명코드' },
        { fldNm: 'buldMnnm', pk: true, label: '건물본번' },
        { fldNm: 'buldSlno', label: '건물부번' },
        { type: 'el', el: AddrItem }
    ];

    React.useEffect(() => {
        addrSrchTextEl.current?.focus();
    }, []);

    return (
        <React.Fragment>
            <div className="dpFlx aiC">
                <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>주소 입력 ({props.step}/3)</span>
            </div>
            <div className='dpFlx aiC pt5 mb20'>
                <div className='dpFlx aiC jcSb w352 h40 bdb1 bdcDDDDDD bgcWhite ofH bdBox pb10 pt10 mr10'>
                    <input
                        ref={addrSrchTextEl}
                        type="text"
                        value={addrSrchText}
                        maxLength={100}
                        className="SpoqaHanSansNeo-Regular fc222222 lh20 bd0 olN w100p h100p fs15 fcPhBDBDBD p0"
                        onChange={(e) => {
                            setAddrSrchText(e.target.value);
                            setAddrSrchDataArr([]);
                        }}
                        onKeyPress={(e) => {
                            if (["Enter", "NumpadEnter"].indexOf(e.code) !== -1) {
                                onClickFunc();
                            }
                        }}
                        placeholder="건물명 또는 도로명 주소를 입력해주세요."
                    />
                    <img className={"csP " + (Util.isEmpty(addrSrchText) ? "dpNone" : "")} onClick={() => onClickDelBtnFunc()} src={require('img/common/icon_cancel_fill_18.png')} alt="icon_cancel_fill_18" />
                </div>
                <div className='dpFlx aiC jcSb w113 h36 bd1 bdc17A840 bdrd40 pl20 pr20 bdBox csP' onClick={onClickFunc}>
                    <span className='SpoqaHanSansNeo-Regular fs13 ls065 fc17A840'>주소 검색</span>
                    <img src={require('img/common/icon_search20_green.png')} alt="icon_search20_green" />
                </div>
            </div>
            <div className='mb5'>
                <span className='fs14 ls1'>
                    <span className='SpoqaHanSansNeo-Regular fc222222 pr5'>검색결과</span>
                    <span className='ReplicaStdBold fc17A840'>{addrSrchDataArr.length}</span>
                    <span className='SpoqaHanSansNeo-Regular fc222222'>건</span>
                </span>
            </div>
            <MuiListFilter tableInfo={{
                tableHeadVsb: false,
                headerVsb: false,
                styleType: ForestTableStyle,
                fldInfs: addrSrchFldInfs,
                ordFld: addrSrchOrdFld,
                dataArr: addrSrchDataArr,
                maxRowNums: [5],
                trHeight: 71,
                rowClickFunc: (item: any) => {
                    if (Util.isNotEmpty(item.bdNm)) {
                        item.bdKdcd = "1";
                    }
                    props.dispatchForm({
                        type: "UPDATE_KEY_VALUES",
                        value: {
                            addrSrchText: addrSrchText,
                            addrSrchDataArr: addrSrchDataArr,
                            apiParam: item.admCd + "," + item.rnMgtSn + "," + item.udrtYn + "," + item.buldMnnm + "," + item.buldSlno,
                            selAddrItem: item,
                            userBdNm: '',
                            userDong: '',
                            userHo: '',
                            taxFlag: props.form.taxFlag
                        }
                    });
                    props.wizard.nextStep();
                },
                remainArea: true,
                paginateClass: "pt20"
            }} />
        </React.Fragment>
    );
};
export default SimAddressSearch;