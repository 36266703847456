import { ChangeEvent } from "react";
import dayjs from "dayjs";
import { NumericFormat } from "react-number-format";

import {
  Code,
  TranTaxParamProps,
  TranTaxParamVisible,
} from "js/common/types/types";
import { TranTaxAction } from "./tranTaxStatesReducer";
import { InputContainer, NumberInputContainer } from "../common/layout";
import DateInput from "../common/DateInput";
import SelectBox, { SelectOption } from "../common/SelectBox";
import Util from "js/common/Util";

type Props = {
  inputValue: TranTaxParamProps;
  inputVisible: TranTaxParamVisible;
  onUpdateInput: (action: TranTaxAction) => void;
};

function NewHouseInfo({ inputValue, inputVisible, onUpdateInput }: Props) {
  const handleChangeApprDateNewHse = (date: dayjs.Dayjs | null) => {
    onUpdateInput({
      type: "UPDATED_APPR_DATE_NEW_HSE",
      payload: Util.dateToString(date, ""),
    });
  };

  const handleChangeResiPeriodNewHse = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;

    onUpdateInput({
      type: "UPDATED_RESI_PERIOD_NEW_HSE",
      payload: inputValue,
    });
  };

  const handleChangeTransferDate = (date: dayjs.Dayjs | null) => {
    onUpdateInput({
      type: "UPDATED_TRANSFER_DATE",
      payload: Util.dateToString(date, ""),
    });
  };

  const handleChangeTransferPrice = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    const valueWithoutComma = inputValue.replace(/,/g, "");
    onUpdateInput({
      type: "UPDATED_TRANSFER_PRICE",
      payload: valueWithoutComma,
    });
  };

  const handleChangeNeceExpenNewHse = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;

    const valueWithoutComma = inputValue.replace(/,/g, "");
    onUpdateInput({
      type: "UPDATED_NECE_EXPEN_NEW_HSE",
      payload: valueWithoutComma,
    });
  };

  const handleSelectLiqudAmt = (selected: SelectOption) => {
    onUpdateInput({
      type: "UPDATED_LIQUD_AMT",
      payload: selected.value,
    });
  };

  const handleChangePayOrRecAmt = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    const valueWithoutComma = inputValue.replace(/,/g, "");
    onUpdateInput({
      type: "UPDATED_PAY_OR_REC_AMT",
      payload: valueWithoutComma,
    });
  };

  return (
    <>
      {/* 22. 신규주택 사용승인일 */}
      {inputVisible.visible22 && (
        <InputContainer>
          <p className="label">신규주택 사용승인일</p>
          <div className="input">
            <DateInput
              value={inputValue.apprDateNewHse}
              onChangeInput={handleChangeApprDateNewHse}
              isFilled={inputValue.apprDateNewHse ? true : false}
            />
          </div>
        </InputContainer>
      )}
      {/* 23.신규주택 거주기간 */}
      {inputVisible.visible23 && (
        <InputContainer>
          <p className="label">신규주택 거주기간</p>
          <div className="input">
            <NumberInputContainer
              $filled={inputValue.resiPeriodNewHse === "" ? false : true}
            >
              <div className="inputWrap">
                <NumericFormat
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  maxLength={3}
                  name="resiPeriodNewHse"
                  value={inputValue.resiPeriodNewHse}
                  onChange={(e) => {
                    if (handleChangeResiPeriodNewHse === undefined) {
                      return;
                    }
                    handleChangeResiPeriodNewHse(e);
                  }}
                  placeholder="신규주택 거주기간을 입력해주세요."
                  className="numberInput"
                />
                <p className="unit">개월</p>
              </div>
            </NumberInputContainer>
          </div>
        </InputContainer>
      )}
      {/* 24. 양도일자 */}
      {inputVisible.visible24 && (
        <InputContainer>
          <p className="label">양도일자</p>
          <div className="input">
            <DateInput
              value={inputValue.transferDate}
              onChangeInput={handleChangeTransferDate}
              isFilled={inputValue.transferDate ? true : false}
            />
          </div>
        </InputContainer>
      )}
      {/* 25. 양도가액 */}
      {inputVisible.visible25 && (
        <InputContainer>
          <p className="label">양도가액</p>
          <div className="input">
            <NumberInputContainer
              $filled={inputValue.transferPrice === "" ? false : true}
            >
              <div className="inputWrap">
                <NumericFormat
                  value={inputValue.transferPrice}
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  maxLength={15}
                  onChange={(e) => {
                    if (handleChangeTransferPrice === undefined) {
                      return;
                    }
                    handleChangeTransferPrice(e);
                  }}
                  placeholder="양도가액을 입력해주세요."
                  className="numberInput"
                  name="transferPrice"
                />
                <p className="unit">원</p>
              </div>
            </NumberInputContainer>
          </div>
        </InputContainer>
      )}
      {/* 26. 필요경비 */}
      {inputVisible.visible26 && (
        <InputContainer>
          <p className="label">필요경비</p>
          <div className="input">
            <NumberInputContainer
              $filled={inputValue.neceExpenNewHse === "" ? false : true}
            >
              <div className="inputWrap">
                <NumericFormat
                  value={inputValue.neceExpenNewHse}
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  maxLength={15}
                  onChange={(e) => {
                    if (handleChangeNeceExpenNewHse === undefined) {
                      return;
                    }
                    handleChangeNeceExpenNewHse(e);
                  }}
                  placeholder="필요경비를 입력해주세요."
                  className="numberInput"
                  name="neceExpenNewHse"
                />
                <p className="unit">원</p>
              </div>
            </NumberInputContainer>
          </div>
        </InputContainer>
      )}
      {/* 27.청산금 여부 */}
      {inputVisible.visible27 && (
        <InputContainer>
          <p className="label">청산금 여부</p>
          <div className="input">
            <SelectBox
              placeholder="청산금 여부를 선택해주세요."
              options={Util.getCodeArray("2038").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              selectedValue={inputValue.liqudAmt}
              handleSelect={handleSelectLiqudAmt}
            />
          </div>
        </InputContainer>
      )}
      {/* 28.납부(수령)금액 */}
      {inputVisible.visible28 && (
        <InputContainer>
          <p className="label">납부(수령)금액</p>
          <div className="input">
            <NumberInputContainer
              $filled={inputValue.payOrRecAmt === "" ? false : true}
            >
              <div className="inputWrap">
                <NumericFormat
                  value={inputValue.payOrRecAmt}
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  maxLength={15}
                  onChange={(e) => {
                    if (handleChangePayOrRecAmt === undefined) {
                      return;
                    }
                    handleChangePayOrRecAmt(e);
                  }}
                  placeholder="납부(수령)금액을 입력해주세요."
                  className="numberInput"
                  name="payOrRecAmt"
                />
                <p className="unit">원</p>
              </div>
            </NumberInputContainer>
          </div>
        </InputContainer>
      )}
    </>
  );
}

export default NewHouseInfo;
