interface assetsQestnProps {
    assetsQestnSn: number
    assetsQestnTy: string
    sortOrdr: number
    qestnText: string
    answerTySn: number
    answerText: string
    answerCode: string
    answerCodeValue: string
}
const AssetsUtil = {
    getRlDlpcFldNm: (estateDetailSe: string, estateFom: string, acqsSrc: string, acqsCause: string) => {
        /*
        1001	아파트(연립주택포함)
        1002	다세대주택
        1003	다가구주택
        1004	다중주택
        1005	단독주택
        1006	주거용오피스텔

        1001	기존주택취득
        1002	분양권에 의한 취득
        1003	조합원입주권에 의한 취득
        1004	자가신축주택취득
        1005	기존오피스텔취득
        1006	일반분양오피스텔취득

        1001	최초취득(원조합원)
        1002	매매(전매)
        1003	상속
        1004	증여
        1011	신규분양
        1012	미분양
        */
        if (estateDetailSe === "1001") {
            if (estateFom === "1001") {
                if (acqsSrc === "1001") {
                    if (acqsCause === "1002") {
                        return "취득당시 실거래가액(원)";
                    } else if (acqsCause === "1003") {
                        return "상속세법상 평가액";
                    } else if (acqsCause === "1004") {
                        return "시가인정액(원)";
                    }
                } else if (acqsSrc === "1002") {
                    if (acqsCause === "1011") {
                        return "취득당시 실거래가액(원)";
                    } else if (acqsCause === "1012") {
                        return "취득당시 실거래가액(원)";
                    } else if (acqsCause === "1002") {
                        return "취득당시 실거래가액(원)";
                    } else if (acqsCause === "1003") {
                        return "취득당시 실거래가액(원)";
                    } else if (acqsCause === "1004") {
                        return "취득당시 실거래가액(원)";
                    }
                } else if (acqsSrc === "1003") {
                    if (acqsCause === "1001") {
                        return "취득당시 실거래가액(원)";
                    } else if (acqsCause === "1002") {
                        return "취득당시 실거래가액(원)";
                    } else if (acqsCause === "1003") {
                        return "취득당시 실거래가액(원)";
                    } else if (acqsCause === "1004") {
                        return "취득당시 실거래가액(원)";
                    }
                }
            } else if (["1002", "1003", "1004", "1005"].indexOf(estateFom) !== -1) {
                if (acqsSrc === "1001") {
                    if (acqsCause === "1002") {
                        return "취득당시 실거래가액(원)";
                    } else if (acqsCause === "1003") {
                        return "상속세법상 평가액";
                    } else if (acqsCause === "1004") {
                        return "시가인정액(원)";
                    }
                } else if (acqsSrc === "1004") {
                    return "취득당시 실거래가액(원)";
                }
            } else if (estateFom === "1006") {
                if (acqsSrc === "1005") {
                    if (acqsCause === "1002") {
                        return "취득당시 실거래가액(원)";
                    } else if (acqsCause === "1003") {
                        return "상속세법상 평가액(원)";
                    } else if (acqsCause === "1004") {
                        return "시가인정액(원)";
                    }
                } else if (acqsSrc === "1006") {
                    if (acqsCause === "1011") {
                        return "취득당시 실거래가액(원)";
                    } else if (acqsCause === "1012") {
                        return "취득당시 실거래가액(원)";
                    } else if (acqsCause === "1002") {
                        return "취득당시 실거래가액(원)";
                    } else if (acqsCause === "1003") {
                        return "취득당시 실거래가액(원)";
                    } else if (acqsCause === "1004") {
                        return "취득당시 실거래가액(원)";
                    }
                }
            }
        } else if (["1002", "1003"].indexOf(estateDetailSe) !== -1) {
            return "취득당시 실거래가액(원)";
        }
        return "ERROR";
    },
    getAnswerValue: (assetsQestnList: assetsQestnProps[], assetsQestnSn: number) => {
        if (assetsQestnList.length === 0) {
            return "";
        }
        for (const assetsQestnInfo of assetsQestnList) {
            if (assetsQestnInfo.assetsQestnSn === assetsQestnSn) {
                return assetsQestnInfo.answerText;
            }
        }
        return "";
    },
    getAcqsYear: (estateFom: string, acqsDe: string) => {
        if (["1001", "1006"].indexOf(estateFom) !== -1) { // 공동주택
            if (acqsDe >= "19830218" && acqsDe < "19840101") {
                return "1983";
            } else if (acqsDe < "19850101") {
                return "1984";
            } else if (acqsDe < "19860101") {
                return "1985";
            } else if (acqsDe < "19870101") {
                return "1986";
            } else if (acqsDe < "19880101") {
                return "1987";
            } else if (acqsDe < "19890101") {
                return "1988";
            } else if (acqsDe < "19900901") {
                return "1989";
            } else if (acqsDe < "19920101") {
                return "1991";
            } else if (acqsDe < "19930201") {
                return "1992";
            } else if (acqsDe < "19940701") {
                return "1993";
            } else if (acqsDe < "19950401") {
                return "1994";
            } else if (acqsDe < "19960701") {
                return "1995";
            } else if (acqsDe < "19970501") {
                return "1996";
            } else if (acqsDe < "19980701") {
                return "1997";
            } else if (acqsDe < "19990701") {
                return "1998";
            } else if (acqsDe < "20000630") {
                return "1999";
            } else if (acqsDe < "20010629") {
                return "2000";
            } else if (acqsDe < "20020404") {
                return "2001";
            } else if (acqsDe < "20030430") {
                return "2002";
            } else if (acqsDe < "20040430") {
                return "2003";
            } else if (acqsDe < "20050502") {
                return "2004";
            } else if (acqsDe < "20060428") {
                return "2005";
            } else if (acqsDe < "20070430") {
                return "2006";
            } else if (acqsDe < "20080430") {
                return "2007";
            } else if (acqsDe < "20090430") {
                return "2008";
            } else if (acqsDe < "20100430") {
                return "2009";
            } else if (acqsDe < "20110429") {
                return "2010";
            } else if (acqsDe < "20120430") {
                return "2011";
            } else if (acqsDe < "20130430") {
                return "2012";
            } else if (acqsDe < "20140430") {
                return "2013";
            } else if (acqsDe < "20150430") {
                return "2014";
            } else if (acqsDe < "20160429") {
                return "2015";
            } else if (acqsDe < "20170428") {
                return "2016";
            } else if (acqsDe < "20180430") {
                return "2017";
            } else if (acqsDe < "20190430") {
                return "2018";
            } else if (acqsDe < "20200429") {
                return "2019";
            } else if (acqsDe < "20210429") {
                return "2020";
            } else if (acqsDe < "20220429") {
                return "2021";
            } else if (acqsDe < "20230428") {
                return "2022";
            } else if (acqsDe < "20240430") {   // 2024년 공시 이전으로 임의로 4월 30일 설정: 공시이후 수정 필요!
                return "2023";
            // 연도 바뀐 후 고시될때마다 조건 추가 필요
            } else {
                return new Date().getFullYear();
            }
        } else { // 단독주택
            if (acqsDe >= "20050430" && acqsDe < "20060428") {
                return "2005";
            } else if (acqsDe < "20070430") {
                return "2006";
            } else if (acqsDe < "20080430") {
                return "2007";
            } else if (acqsDe < "20090430") {
                return "2008";
            } else if (acqsDe < "20100430") {
                return "2009";
            } else if (acqsDe < "20110429") {
                return "2010";
            } else if (acqsDe < "20120430") {
                return "2011";
            } else if (acqsDe < "20130430") {
                return "2012";
            } else if (acqsDe < "20140430") {
                return "2013";
            } else if (acqsDe < "20150430") {
                return "2014";
            } else if (acqsDe < "20160429") {
                return "2015";
            } else if (acqsDe < "20170428") {
                return "2016";
            } else if (acqsDe < "20180430") {
                return "2017";
            } else if (acqsDe < "20190430") {
                return "2018";
            } else if (acqsDe < "20200429") {
                return "2019";
            } else if (acqsDe < "20210429") {
                return "2020";
            } else if (acqsDe < "20220429") {
                return "2021";
            } else if (acqsDe < "20230428") {
                return "2022";
            } else if (acqsDe < "20240430") {   // 2024년 공시 이전으로 임의로 4월 30일 설정: 공시 이후 수정 필요!
                return "2023";
            // 연도 바뀐 후 고시될때마다 조건 추가 필요
            } else {
                return new Date().getFullYear();
            }
        }
    },

    // 남자 기대여명 : 상속세 인적공제 계산(2022년 통계)
    getLifeExpectMan: (nowAge: string) => {   
        switch (nowAge) {
            case '0' : 
                return '80';
            case '1' : 
                return '80';
            case '2' : 
                return '79';
            case '3' : 
                return '78';
            case '4' : 
                return '77';
            case '5' : 
                return '76';
            case '6' : 
                return '75';
            case '7' : 
                return '74';
            case '8' : 
                return '73';
            case '9' : 
                return '72';
            case '10': 
                return '71';
            case '11': 
                return '70';
            case '12': 
                return '69';
            case '13': 
                return '68';
            case '14': 
                return '67';
            case '15': 
                return '66';
            case '16': 
                return '65';
            case '17': 
                return '64';
            case '18': 
                return '63';
            case '19': 
                return '62';
            case '20': 
                return '61';
            case '21': 
                return '60';
            case '22': 
                return '59';
            case '23': 
                return '58';
            case '24': 
                return '57';
            case '25': 
                return '56';
            case '26': 
                return '55';
            case '27': 
                return '54';
            case '28': 
                return '53';
            case '29': 
                return '52';
            case '30': 
                return '51';
            case '31': 
                return '50';
            case '32': 
                return '49';
            case '33': 
                return '48';
            case '34': 
                return '47';
            case '35': 
                return '46';
            case '36': 
                return '45';
            case '37': 
                return '44';
            case '38': 
                return '43';
            case '39': 
                return '42';
            case '40': 
                return '41';
            case '41': 
                return '40';
            case '42': 
                return '39';
            case '43': 
                return '39';
            case '44': 
                return '38';
            case '45': 
                return '37';
            case '46': 
                return '36';
            case '47': 
                return '35';
            case '48': 
                return '34';
            case '49': 
                return '33';
            case '50': 
                return '32';
            case '51': 
                return '31';
            case '52': 
                return '30';
            case '53': 
                return '29';
            case '54': 
                return '28';
            case '55': 
                return '28';
            case '56': 
                return '27';
            case '57': 
                return '26';
            case '58': 
                return '25';
            case '59': 
                return '24';
            case '60': 
                return '23';
            case '61': 
                return '22';
            case '62': 
                return '22';
            case '63': 
                return '21';
            case '64': 
                return '20';
            case '65': 
                return '19';
            case '66': 
                return '18';
            case '67': 
                return '17';
            case '68': 
                return '17';
            case '69': 
                return '16';
            case '70': 
                return '15';
            case '71': 
                return '14';
            case '72': 
                return '14';
            case '73': 
                return '13';
            case '74': 
                return '12';
            case '75': 
                return '11';
            case '76': 
                return '11';
            case '77': 
                return '10';
            case '78': 
                return '9';
            case '79': 
                return '9';
            case '80': 
                return '8';
            case '81': 
                return '8';
            case '82': 
                return '7';
            case '83': 
                return '7';
            case '84': 
                return '6';
            case '85': 
                return '6';
            case '86':    
                return '5';
            case '87':    
                return '5';
            case '88':    
                return '5';
            case '89':    
                return '4';
            case '90':    
                return '4';
            case '91':    
                return '4';
            case '92': 
                return '4';
            case '93':    
                return '3';
            case '94':    
                return '3';
            case '95':    
                return '3';
            case '96':    
                return '3';
            case '97':    
                return '3';
            case '98':    
                return '2';
            case '99': 
                return '2';
            default  : 
                return '2';
        }        
    },

    // 여자 기대여명 : 상속세 인적공제 계산(2022년 통계)
    getLifeExpectWom: (nowAge: string) => {  
        switch (nowAge) {
            case '0' : 
                return '86';
            case '1' :     
                return '85';
            case '2' :     
                return '84';
            case '3' :     
                return '83';
            case '4' :     
                return '82';
            case '5' :     
                return '81';
            case '6' :     
                return '80';
            case '7' : 
                return '79';
            case '8' :     
                return '78';
            case '9' :     
                return '77';
            case '10':     
                return '76';
            case '11':     
                return '75';
            case '12':     
                return '74';
            case '13':     
                return '73';
            case '14': 
                return '72';
            case '15':     
                return '71';
            case '16':     
                return '70';
            case '17':     
                return '69';
            case '18':     
                return '68';
            case '19':     
                return '67';
            case '20':     
                return '66';
            case '21':     
                return '65';
            case '22':     
                return '64';
            case '23':     
                return '63';
            case '24':     
                return '62';
            case '25':     
                return '62';
            case '26':     
                return '61';
            case '27':     
                return '60';
            case '28': 
                return '59';
            case '29':     
                return '58';
            case '30':     
                return '57';
            case '31':     
                return '56';
            case '32':     
                return '55';
            case '33':     
                return '54';
            case '34':     
                return '53';
            case '35':     
                return '52';
            case '36':     
                return '51';
            case '37':     
                return '50';
            case '38':     
                return '49';
            case '39':     
                return '48';
            case '40':     
                return '47';
            case '41':     
                return '46';
            case '42': 
                return '45';
            case '43':     
                return '44';
            case '44':     
                return '43';
            case '45':     
                return '42';
            case '46':     
                return '41';
            case '47':     
                return '40';
            case '48':     
                return '39';
            case '49':     
                return '38';
            case '50':     
                return '37';
            case '51':     
                return '36';
            case '52':     
                return '35';
            case '53':     
                return '34';
            case '54':     
                return '33';
            case '55':     
                return '33';
            case '56': 
                return '32';
            case '57':     
                return '31';
            case '58':     
                return '30';
            case '59':     
                return '29';
            case '60':     
                return '28';
            case '61':     
                return '27';
            case '62':     
                return '26';
            case '63':     
                return '25';
            case '64':     
                return '24';
            case '65':     
                return '23';
            case '66':     
                return '22';
            case '67':     
                return '21';
            case '68':     
                return '20';
            case '69':     
                return '20';
            case '70': 
                return '19';
            case '71':     
                return '18';
            case '72':     
                return '17';
            case '73':     
                return '16';
            case '74':     
                return '15';
            case '75':     
                return '14';
            case '76':     
                return '14';
            case '77':     
                return '13';
            case '78':     
                return '12';
            case '79':     
                return '11';
            case '80':     
                return '11';
            case '81':     
                return '10';
            case '82':     
                return '9';
            case '83':     
                return '9';
            case '84': 
                return '8';
            case '85':    
                return '7';
            case '86':    
                return '7';
            case '87':    
                return '6';
            case '88':    
                return '6';
            case '89':    
                return '5';
            case '90':    
                return '5';
            case '91':    
                return '5';
            case '92':    
                return '4';
            case '93':    
                return '4';
            case '94':    
                return '4';
            case '95':    
                return '4';
            case '96':    
                return '3';
            case '97':    
                return '3';
            case '98': 
                return '3';
            case '99': 
                return '3';
            default  : 
                return '3';
        }        
    }    
};
export default AssetsUtil;