/*******************************************************************************
*** FindIdResult ** ID 찾기 결과
*******************************************************************************/
import AppContext from "js/common/AppContext";
import Axios from "js/common/Axios";
import React from "react";
import { useLocation, useNavigate} from "react-router-dom"; // useLocation

function FindIdResult() {
    const location = useLocation();
    const navigate = useNavigate();
    const emailAdres = location.state.emailAdres;
    const userNm     = location.state.userNm;
    const mbtlnum    = location.state.mbtlnum;

    const [userId, setUserId] = React.useState<string>();

    React.useEffect(() => {
        AppContext.showSpinnerDim();
        Axios.dataAccess({
            url: "portal/auth/selfindUserIdResult.do",
            methodType: "post",
            paramType: "json",
            paramData: {
                  userNm    : userNm
                , emailAdres: emailAdres
                , mbtlnum   : mbtlnum
            },
            onSuccessFn: (res: any) => {
                AppContext.hideSpinner();
                setUserId(res.item);
            }
        });

    }, [userNm, emailAdres, mbtlnum]);

    return (
        <div className='w460'>
            <div className='w100p h160 dpFlx aiC jcC'>
                <span className="SpoqaHanSansNeo-Bold fs36 ls1 fcBlack">아이디 찾기</span>
            </div>
            <div className="w460 m0auto dpFlx pt40 mb15 aiC jcC">
                <span className="w460 h25 SpoqaHanSansNeo-Medium fs20 ls1 taC">아이디를 확인해주세요.</span>
            </div>
            <div className="w460 m0auto dpFlx aiC jcC">
                <span className="w460 h30 SpoqaHanSansNeo-Regular fs18 ls09 taC fc666666">
                    이제호{/* {userNm} */} 회원님의 아이디는 <span className="fcBlack">{userId}</span>입니다.
                </span>
            </div>
            <div className='w460 m0auto h62 bgc17A840 mt40 dpFlx aiC jcC csP' onClick={() => AppContext.goPage("/portal/login")}>
                <span className="SpoqaHanSansNeo-Bold fs18 ls08 fcWhite">로그인</span>
            </div>
            <div className='w100p h16 mt40 mb100 dpFlx aiC jcC'>
                <span onClick={() => navigate('/portal/findPasswd')} className="SpoqaHanSansNeo-Regular fs13 ls065 fc666666 csP">비밀번호 찾기</span>
            </div>
        </div>
    )
}
export default FindIdResult;