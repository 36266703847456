/*******************************************************************************
 *** SimTranTaxCalc ** 간편계산기 양도세 결과표
*******************************************************************************/
import AppContext from "js/common/AppContext";
import Axios      from "js/common/Axios";
import Util       from "js/common/Util";
import React      from "react";

interface SimTranTaxCalcProps {
    setSimTranTaxCalcModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setSimTranTaxCalcModalVsb: React.Dispatch<React.SetStateAction<boolean>>
    tranTaxParam: any[]
}

const SimTranTaxCalc = ({ setSimTranTaxCalcModalOpen, setSimTranTaxCalcModalVsb, tranTaxParam}: SimTranTaxCalcProps) => {
    const [calcInfo, setCalcInfo] = React.useState<any | undefined>();  // 양도세 계산결과
    const [rtnForm , setRtnForm ] = React.useState<string>('A');  // 양도세 계산결과표 양식(A, B)

    // 양도세 파라미터 확인
    React.useEffect(() => {
        let taxFlag           = "";  // 00.세금구분 Flag 
        let estateSe          = "";  // 01.부동산의 종류
        let estateFom1        = "";  // 02.주택구분1(주택)
        let estateFom2        = "";  // 03.주택구분2(오피스텔)
        let landCls           = "";  // 04.토지구분
        let acqsSrc1          = "";  // 05.취득구분1(아파트/연립)
        let acqsSrc2          = "";  // 06.취득구분2(오피스텔)
        let acqsCause1        = "";  // 07.취득원인1(기존 아파트)
        let acqsCause2        = "";  // 08.취득원인2(분양권/오피스텔)
        let acqsCause3        = "";  // 09.취득원인3(조합원입주권)
        let acqsCause4        = "";  // 10.취득원인4(다세대/다가구/다중/단독)
        let hseNum            = "";  // 11.보유주택 수
        // let rdnmAdres         = "";  // 12.도로명 주소
        // let lnmAdres          = "";  // 13.지번 주소
        let mdatTrgetArea     = "";  // 14.조정대상지역
        let shareRatio        = "";  // 15.지분율
        let mngDispAppDate    = "";  // 16.관리처분인가일
        let mngDispAppDateAmt = "";  // 17.관리처분인가일 평가액
        let acquDatePreHse    = "";  // 18.종전주택 취득일자
        let acquDateInherit   = "";  // 18-1.피상속인 취득일자
        let resiPeriodPreHse  = "";  // 19.종전주택 거주기간
        let acquPrice         = "";  // 20.취득가액
        let neceExpenPreHse   = "";  // 21.종전주택 필요경비
        let apprDateNewHse    = "";  // 22.신규주택 사용승인일
        let resiPeriodNewHse  = "";  // 23.신규주택 거주기간
        let transferDate      = "";  // 24.양도일자
        let transferPrice     = "";  // 25.양도가액
        let neceExpenNewHse   = "";  // 26.필요경비
        let liqudAmt          = "";  // 27.청산금 여부
        let payOrRecAmt       = "";  // 28.납부(수령)금액
        let legalDongCode     = "";  // 29.법정동코드

        for (const taxParam of tranTaxParam) {
            taxFlag           = taxParam.taxFlag          ;  // 00.세금구분 Flag
            estateSe          = taxParam.estateSe         ;  // 01.부동산의 종류
            estateFom1        = taxParam.estateFom1       ;  // 02.주택구분1(주택)
            estateFom2        = taxParam.estateFom2       ;  // 03.주택구분2(오피스텔)
            landCls           = taxParam.landCls          ;  // 04.토지구분
            acqsSrc1          = taxParam.acqsSrc1         ;  // 05.취득구분1(아파트/연립)
            acqsSrc2          = taxParam.acqsSrc2         ;  // 06.취득구분2(오피스텔)
            acqsCause1        = taxParam.acqsCause1       ;  // 07.취득원인1(기존 아파트)
            acqsCause2        = taxParam.acqsCause2       ;  // 08.취득원인2(분양권/오피스텔)
            acqsCause3        = taxParam.acqsCause3       ;  // 09.취득원인3(조합원입주권)
            acqsCause4        = taxParam.acqsCause4       ;  // 10.취득원인4(다세대/다가구/다중/단독)
            hseNum            = taxParam.hseNum           ;  // 11.보유주택 수
            // rdnmAdres         = taxParam.rdnmAdres        ;  // 12.도로명 주소
            // lnmAdres          = taxParam.lnmAdres         ;  // 13.지번 주소
            mdatTrgetArea     = taxParam.mdatTrgetArea    ;  // 14.조정대상지역
            shareRatio        = taxParam.shareRatio       ;  // 15.지분율
            mngDispAppDate    = taxParam.mngDispAppDate   ;  // 16.관리처분인가일
            mngDispAppDateAmt = taxParam.mngDispAppDateAmt;  // 17.관리처분인가일 평가액
            acquDatePreHse    = taxParam.acquDatePreHse   ;  // 18.종전주택 취득일자
            acquDateInherit   = taxParam.acquDateInherit  ;  // 18-1.피상속인 취득일자
            resiPeriodPreHse  = taxParam.resiPeriodPreHse ;  // 19.종전주택 거주기간
            acquPrice         = taxParam.acquPrice        ;  // 20.취득가액
            neceExpenPreHse   = taxParam.neceExpenPreHse  ;  // 21.종전주택 필요경비
            apprDateNewHse    = taxParam.apprDateNewHse   ;  // 22.신규주택 사용승인일
            resiPeriodNewHse  = taxParam.resiPeriodNewHse ;  // 23.신규주택 거주기간
            transferDate      = taxParam.transferDate     ;  // 24.양도일자
            transferPrice     = taxParam.transferPrice    ;  // 25.양도가액
            neceExpenNewHse   = taxParam.neceExpenNewHse  ;  // 26.필요경비
            liqudAmt          = taxParam.liqudAmt         ;  // 27.청산금 여부
            payOrRecAmt       = taxParam.payOrRecAmt      ;  // 28.납부(수령)금액    
            legalDongCode     = taxParam.legalDongCode    ;  // 29.법정동코드      
        }

        Axios.dataAccess({
            url: "api/system/broker/callApi.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                apiName : "taxcalc"
              , tailUrl : "simpletax/transfertax"
              , paramStr: taxFlag + "?estate_se="             + estateSe            // 01.부동산의 종류 
                                  + "&estate_fom1="           + estateFom1          // 02.주택구분1(주택)  
                                  + "&estate_fom2="           + estateFom2          // 03.주택구분2(오피스텔)  
                                  + "&land_cls="              + landCls             // 04.토지구분  
                                  + "&acqs_src1="             + acqsSrc1            // 05.취득구분1(아파트/연립)  
                                  + "&acqs_src2="             + acqsSrc2            // 06.취득구분2(오피스텔)  
                                  + "&acqs_cause1="           + acqsCause1          // 07.취득원인1(기존 아파트)  
                                  + "&acqs_cause2="           + acqsCause2          // 08.취득원인2(분양권/오피스텔)  
                                  + "&acqs_cause3="           + acqsCause3          // 09.취득원인3(조합원입주권)  
                                  + "&acqs_cause4="           + acqsCause4          // 10.취득원인4(다세대/다가구/다중/단독)  
                                  + "&hse_num="               + hseNum              // 11.보유주택 수  
                                //   + "&rdnm_adres="            + rdnmAdres           // 12.도로명 주소  
                                //   + "&lnm_adres="             + lnmAdres            // 13.지번 주소  
                                  + "&mdat_trget_area="       + mdatTrgetArea       // 14.조정대상지역  
                                  + "&share_ratio="           + shareRatio          // 15.지분율  
                                  + "&mng_disp_app_date="     + mngDispAppDate      // 16.관리처분인가일  
                                  + "&mng_disp_app_date_amt=" + mngDispAppDateAmt   // 17.관리처분인가일 평가액  
                                  + "&acqu_date_preHse="      + acquDatePreHse      // 18.종전주택 취득일자 
                                  + "&acqu_date_inherit="     + acquDateInherit     // 18-1.피상속인 취득일자 
                                  + "&resi_period_pre_hse="   + resiPeriodPreHse    // 19.종전주택 거주기간  
                                  + "&acqu_price="            + acquPrice           // 20.취득가액  
                                  + "&nece_expen_pre_hse="    + neceExpenPreHse     // 21.종전주택 필요경비  
                                  + "&appr_date_new_hse="     + apprDateNewHse      // 22.신규주택 사용승인일  
                                  + "&resi_period_new_hse="   + resiPeriodNewHse    // 23.신규주택 거주기간  
                                  + "&transfer_date="         + transferDate        // 24.양도일자  
                                  + "&transfer_price="        + transferPrice       // 25.양도가액  
                                  + "&nece_expen_new_hse="    + neceExpenNewHse     // 26.필요경비  
                                  + "&liqud_amt="             + liqudAmt            // 27.청산금 여부  
                                  + "&pay_or_rec_amt="        + payOrRecAmt         // 28.납부(수령)금액   
                                  + "&legal_dong_code="        + legalDongCode       // 29.법정동코드             
            },
            onSuccessFn: (res: any) => {
                if (res.item.rtn_list[0].transfer_profit === "0") {     // 양도차익이 zero인 경우
                    setSimTranTaxCalcModalOpen(false);
                    AppContext.openAlert({
                        title: "과세 대상 여부",
                        msg: "양도세 납부 대상이 아닙니다.",
                        icon: "check"
                    });
                    return;
                }

                // 양도세 세액 계산 결과
                setRtnForm(res.item.rtn_list[0].rtn_form);  // 결과표 양식
                setCalcInfo(res.item.rtn_list[0]);
                setSimTranTaxCalcModalVsb(true);
            },
            onFailedFn: () => {
                setSimTranTaxCalcModalOpen(false);
            }
        });
    }, [tranTaxParam, setSimTranTaxCalcModalVsb, setSimTranTaxCalcModalOpen]);

    return (
        <div className='w600 ofA'>
            {rtnForm === "A" ? 
                <table className={
                    "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD h40 mb40 mt10 " +
                    "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 th-ls1 " +
                    "td-p20 td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls075 td-fc666666 td-bdcDDDDDD"
                }>
                    <colgroup>
                        <col className="w250" />
                        <col className="w150" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>양도가액</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.transfer_price) : ""}</td>
                        </tr>
                        <tr>
                            <th>(-)취득가액</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.acqu_price) : ""}</td>
                        </tr>
                        <tr>
                            <th>(-)필요경비</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.nece_expen_new_hse) : ""}</td>
                        </tr>
                        <tr>
                            <th>양도차익</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.transfer_profit) : ""}</td>
                        </tr>
                        <tr>
                            <th>(-)장기보유특별공제</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.long_term_special_deduc) : ""}</td>
                        </tr>
                        <tr>
                            <th>양도소득금액(*지분율)</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.transfer_income) : ""}</td>
                        </tr>
                        <tr>
                            <th>(-)양도소득기본공제</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.transfer_basic_deduc): ""}</td>
                        </tr>
                        <tr>
                            <th>과세표준</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.transfer_tax_base) : ""}</td>
                        </tr>
                        <tr>
                            <th>(*)세율</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.transfer_rate) : ""}%</td>
                        </tr>
                        <tr>
                            <th>산출 세액</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.transfer_tax_amt) : ""}</td>
                        </tr>
                        <tr>
                            <th>자진납부세액</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.voluntary_tax_amt) : ""}</td>
                        </tr>
                        <tr>
                            <th>(+)지방소득세</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.local_income_tax) : ""}</td>
                        </tr>
                        <tr>
                            <th><span className="fs18 fc222222 SpoqaHanSansNeo-Medium ls1">납부할 양도세액</span></th>
                            <td className="taR"><span className="fs18 fc222222 SpoqaHanSansNeo-Medium ls1">{calcInfo ? Util.comma(calcInfo.total_transfer_tax_amt) : ""}</span></td>
                        </tr>
                    </tbody>
                </table>
            : 
                <table className={
                    "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD h40 mb40 mt10 " +
                    "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 th-ls1 " +
                    "td-p20 td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls075 td-fc666666 td-bdcDDDDDD"
                }>
                    <colgroup>
                        <col className="w200" />
                        <col className="w150" />
                        <col className="w150" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>구분</th>
                            <th>종전주택</th>
                            <th>{calcInfo.rtn_title}</th>
                        </tr>                        
                        <tr>
                            <th>양도가액</th>
                            <td className="taR" colSpan={2}>{calcInfo ? Util.comma(calcInfo.transfer_price) : ""}</td>
                        </tr>
                        <tr>
                            <th>(-)취득가액</th>
                            <td className="taR" colSpan={2}>{calcInfo ? Util.comma(calcInfo.cal_acqu_price) : ""}</td>
                        </tr>
                        <tr>
                            <th>(-)필요경비</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.nece_expen_pre_hse) : ""}</td>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.nece_expen_new_hse) : ""}</td>
                        </tr>
                        <tr>
                            <th>양도차익</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.transfer_profit_pre_house) : ""}</td>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.transfer_profit_new_house) : ""}</td>
                        </tr>
                        <tr>
                            <th>(-)장기보유특별공제</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.long_term_special_deduc_pre_house) : ""}</td>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.long_term_special_deduc_new_house) : ""}</td>
                        </tr>
                        <tr>
                            <th>양도소득금액(*지분율)</th>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.transfer_income_pre_house) : ""}</td>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.transfer_income_new_house) : ""}</td>
                        </tr>
                        <tr>
                            <th>(-)양도소득기본공제</th>
                            <td className="taR" colSpan={2}>{calcInfo ? Util.comma(calcInfo.transfer_basic_deduc): ""}</td>
                        </tr>
                        <tr>
                            <th>과세표준</th>
                            <td className="taR" colSpan={2}>{calcInfo ? Util.comma(calcInfo.transfer_tax_base) : ""}</td>
                        </tr>
                        <tr>
                            <th>(*)세율</th>
                            <td className="taR" colSpan={2}>{calcInfo ? Util.comma(calcInfo.transfer_rate) : ""}%</td>
                        </tr>
                        <tr>
                            <th>산출 세액</th>
                            <td className="taR" colSpan={2}>{calcInfo ? Util.comma(calcInfo.transfer_tax_amt) : ""}</td>
                        </tr>
                        <tr>
                            <th>자진납부세액</th>
                            <td className="taR" colSpan={2}>{calcInfo ? Util.comma(calcInfo.voluntary_tax_amt) : ""}</td>
                        </tr>
                        <tr>
                            <th>(+)지방소득세</th>
                            <td className="taR" colSpan={2}>{calcInfo ? Util.comma(calcInfo.local_income_tax) : ""}</td>
                        </tr>
                        <tr>
                            <th><span className="fs18 fc222222 SpoqaHanSansNeo-Medium ls1">납부할 양도세액</span></th>
                            <td className="taR" colSpan={2}><span className="fs18 fc222222 SpoqaHanSansNeo-Medium ls1">{calcInfo ? Util.comma(calcInfo.total_transfer_tax_amt) : ""}</span></td>
                        </tr>
                    </tbody>
                </table>
            }

            <div className='w100p dpFlx aiC jcC'>
                <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => {
                    setSimTranTaxCalcModalOpen(false);
                }}>
                    <span className='SpoqaHanSansNeo-Medium fs16 fcWhite fw500'>확인</span>
                </div>
            </div>
        </div>
    )
}
export default SimTranTaxCalc;