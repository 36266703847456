import { useState } from "react";
import styled       from "styled-components";
import TaxExemptCautionDialog from "./TaxExemptCautionDialog";

type Props = {
    cautionNo: string;
    longRentNotice: string;
}

function TaxExemptCaution({cautionNo, longRentNotice}: Props) {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const onClickDetail = () => {
        setDialogOpen(true);
    };

    return (
        <>
        <CautionWrapper>
            <CautionContainer>
            <div className="guide">
                <div className="title">
                <div className="iconWrap">
                    <img
                    src={require("img/common/caution_icon.png")}
                    alt="caution icon"
                    />
                </div>
                <span className="text">비과세 주의사항 안내</span>
                </div>
            </div>
            <div className="detail">
                <button onClick={onClickDetail}>
                <div className="iconWrap">
                    <img
                    src={require("img/common/caution_tax_icon.png")}
                    alt="caution icon"
                    />
                </div>
                <span>주의사항 자세히보기</span>
                </button>
            </div>
            </CautionContainer>
        </CautionWrapper>
        <TaxExemptCautionDialog open={dialogOpen} setOpen={setDialogOpen} cautionNo={cautionNo} longRentNotice={longRentNotice}/>
        </>
    );
}

const CautionWrapper = styled.div`
    width: 380px;
    height: auto;
    padding: 4px;
    border-radius: 20px;
    background-color: white;

    box-sizing: border-box;
`;

const CautionContainer = styled.div`
    display: flex;
    justify-content: space-between;

    width: 100%;
    height: 100%;
    padding: 16px;
    border-radius: 5px;
    background-color: #f9fafb;
    box-sizing: border-box;

    .guide {
        .title {
        display: flex;
        align-items: center;

        .iconWrap {
            display: inline-block;
            width: 19px;
            height: 19px;
            margin-right: 10px;
            box-shadow: 2px 2px 8px rgba(255, 62, 62, 0.14);

            img {
                width: 100%;
                height: 100%;
            }
        }

        .text {
            font-family: "Spoqa Han Sans Neo";
            font-weight: 500;
            font-size: 12px;
            line-height: 1;
            letter-spacing: -0.058em;
        }
        }
    }

    .detail {
        button {
            display: flex;
            align-items: center;
            gap: 4px;

            padding: 3px 13px;
            border-radius: 4px;
            background-color: #B36666;

            font-family: "Spoqa Han Sans Neo";
            font-weight: 400;
            font-size: 12px;
            line-height: 1.4;
            letter-spacing: -0.058em;
            color: white;

            .iconWrap {
                display: inline-block;
                width: 13px;
                height: 15.68px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
`;

export default TaxExemptCaution;
