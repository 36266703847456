export default function HouseInfo({setModalOpen}: any) {
  return (
    <div className="w100p aiC">
        <table className="forest-list-table w100p th-fm-SpoqaHanSansNeo-Medium th-fw500 th-fs14 th-fc222222 th-bdcDDDDDD bdt1 bdb1 bdcDDDDDD td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD">
            <colgroup>
                <col className="w100"></col>
                <col className="w100"></col>
                <col className="w45p"></col>
                <col className="w100"></col>
                <col className="w100"></col>
                <col className="w160"></col>
                <col className="w100"></col>
            </colgroup>
            <tbody>
                <tr className="h69">
                    <th colSpan={2}>세법상 구분</th>
                    <th>기준</th>
                    <th colSpan={3}>주택여부</th>
                    <th>주택수 계산</th>
                </tr>
                <tr className="h26">
                    <td rowSpan={3}>단독주택</td>
                    <td>단독주택</td>
                    <td>-</td>
                    <td colSpan={3}>단독주택</td>
                    <td>O</td>
                </tr>
                <tr className="h26">
                    <td rowSpan={2}>다중주택</td>
                    <td rowSpan={2}>주택사용층수가 3개층 이하이고, 연면적이 330㎡이하</td>
                    <td rowSpan={2}>요건충족</td>
                    <td>O</td>
                    <td>단독주택</td>
                    <td>O</td>
                </tr>
                <tr className="h26">
                    <td>X</td>
                    <td>공동주택</td>
                    <td>O</td>
                </tr>
                <tr className="h26">
                    <td rowSpan={5}>공동주택</td>
                    <td>아파트</td>
                    <td>주택사용층수가 5개층 이상</td>
                    <td colSpan={3}>공동주택</td>
                    <td>O</td>
                </tr>
                <tr className="h26">
                    <td>연립</td>
                    <td>주택사용 1개동의 연면적이 660㎡초과, 주택사용층수가 4개층 이하</td>
                    <td colSpan={3}>공동주택</td>
                    <td>O</td>
                </tr>
                <tr className="h26">
                    <td>다세대</td>
                    <td>주택사용 1개동의 연면적이 660㎡이하, 주택사용층수가 4개층 이하</td>
                    <td colSpan={3}>공동주택</td>
                    <td>O</td>
                </tr>
                <tr className="h26">
                    <td rowSpan={2}>다가구주택</td>
                    <td rowSpan={2}>주택사용 바닥면적 합계가 660㎡이하, 주택사용층수가 3개층 이하, 19세대 이하</td>
                    <td rowSpan={2}>요건충족</td>
                    <td>O</td>
                    <td>단독주택(공동주택)</td>
                    <td>O</td>
                </tr>
                <tr className="h26">
                    <td>X</td>
                    <td>공동주택</td>
                    <td>O</td>
                </tr>
                <tr className="h26">
                    <td rowSpan={2} colSpan={2}>근생시설 중 고시원</td>
                    <td rowSpan={2}>주방과 화장실 공동으로 사용해야 함</td>
                    <td rowSpan={2}>요건충족</td>
                    <td>O</td>
                    <td>주택으로 보지 아니함</td>
                    <td>X</td>
                </tr>
                <tr className="h26">
                    <td>X</td>
                    <td>다중주택으로 봄</td>
                    <td>O</td>
                </tr>
                <tr className="h26">
                    <td colSpan={2}>무허가주택</td>
                    <td colSpan={3}></td>
                    <td>주택</td>
                    <td>O</td>
                </tr>
                <tr className="h26">
                    <td colSpan={2}>공가(빈집)</td>
                    <td colSpan={3}></td>
                    <td>주택</td>
                    <td>O</td>
                </tr>
                <tr className="h26">
                    <td colSpan={2}>폐가</td>
                    <td colSpan={3}>사실상 거주가 가능한 경우</td>
                    <td>주택</td>
                    <td>O</td>
                </tr>
                <tr className="h26">
                    <td colSpan={2}>신축중인 주택</td>
                    <td colSpan={3}>사용승인, 임시사용승인, 실제 사용시</td>
                    <td>주택</td>
                    <td>O</td>
                </tr>
                <tr className="h26">
                    <td rowSpan={2}>권리</td>
                    <td>분양권</td>
                    <td colSpan={3}>2021.1.1 이후 양도분부터는 주택수계산에 포함</td>
                    <td>주택으로 보지 아니함</td>
                    <td>X</td>
                </tr>
                <tr className="h26">
                    <td>조합원입주권</td>
                    <td colSpan={3}></td>
                    <td>주택으로 보지 아니함</td>
                    <td>X</td>
                </tr>
                <tr className="h26">
                    <td colSpan={2}>오피스텔</td>
                    <td colSpan={3}>주거용으로 사용하고 있는 경우</td>
                    <td>주택</td>
                    <td>O</td>
                </tr>
                <tr className="h26">
                    <td colSpan={2} rowSpan={2}>펜션</td>
                    <td rowSpan={2}>관리인, 주인이 거주하고 아니하여야 한다</td>
                    <td rowSpan={2}>요건충족</td>
                    <td>O</td>
                    <td>단독주택</td>
                    <td>O</td>
                </tr>
                <tr className="h26">
                    <td>X</td>
                    <td>공동주택</td>
                    <td>O</td>
                </tr>
                <tr className="h26">
                    <td colSpan={2}>가정보육시설용 주택</td>
                    <td colSpan={3}></td>
                    <td>주택</td>
                    <td>O</td>
                </tr>
                <tr className="h26">
                    <td colSpan={2}>주택신축판매업</td>
                    <td colSpan={3}>사업용주택</td>
                    <td>제외</td>
                    <td>X</td>
                </tr>
                <tr className="h26">
                    <td colSpan={2}>부동산매매업</td>
                    <td colSpan={3}>사업용주택</td>
                    <td>제외</td>
                    <td>X</td>
                </tr>
                <tr className="h26">
                    <td colSpan={2}>공동상속주택</td>
                    <td colSpan={3}></td>
                    <td>지분 큰 상속인</td>
                    <td>O</td>
                </tr>
                <tr className="h26">
                    <td colSpan={2}>공동소유주택</td>
                    <td colSpan={3}></td>
                    <td>각자에 포함</td>
                    <td>O</td>
                </tr>
            </tbody>
        </table>
        <div className="dpFlx aiC jcC pt20">
              <div className="w210 h56 bgc17A840 dpFlx aiC jcC csP bdBox" onClick={() => { setModalOpen(false) }}>
                <span className="SpoqaHanSansNeo-Medium fs14 fcWhite">닫기</span>
            </div>
        </div>
    </div>
  )
}