import React from 'react';
import { useRecoilValue } from "recoil";
import * as Atom from "js/common/Atom";
import AppContext from 'js/common/AppContext';
import Axios from 'js/common/Axios';
import MuiListFilter from 'js/common/ui/list/MuiListFilter';
import ForestTableStyle from 'js/common/ui/style/ForestStyle';
import Modal from 'js/common/ui/modal/Modal';
import TrsftaxInput from './dialog/TrsftaxInput';
import Util from 'js/common/Util';
import TrsftaxCalc from './TrsftaxCalc';
import TrsftaxChbtQestn from './dialog/TrsftaxChbtQestn';
import TrsftaxMrrgQestn from './dialog/TrsftaxMrrgQestn';
import AssetsShow from '../assets/show/AssetsShow';

const Trsftax = () => {
    const hshldSn = useRecoilValue(Atom.hshldSn);
    const hshldNm = useRecoilValue(Atom.hshldNm);
    const [hshldMrrgAt, setHshldMrrgAt] = React.useState<string>(""); // 납세자 결혼여부
    const [hshldChbtSptAt, setHshldChbtSptAt] = React.useState<string>(""); // 납세자 동거봉양합가여부

    const [rmstnfamDataArr, setRmstnfamDataArr] = React.useState<any[]>([]);
    const [assetsDataArr, setAssetsDataArr] = React.useState<[]>([]);
    const [assetsSn, setAssetsSn] = React.useState<number>(); // 자산key
    const [estateDetailSe, setEstateDetailSe] = React.useState<string>(""); // 자산상세구분
    const [acqsSrc, setAcqsSrc] = React.useState<string>(""); // 취득구분
    const [acqsCause, setAcqsCause] = React.useState<string>(""); // 취득원인

    const [taxInputModalOpen, setTaxInputModalOpen] = React.useState<boolean>(false); // 양도세 추가정보 입력 모달
    const [trsftaxMrrgQestnModalOpen, setTrsftaxMrrgQestnModalOpen] = React.useState<boolean>(false); // 양도세 혼인합가 질문 조회 모달
    const [trsftaxChbtQestnModalOpen, setTrsftaxChbtQestnModalOpen] = React.useState<boolean>(false); // 양도세 동거봉양합가 질문 조회 모달
    const [trsftaxCalcModalOpen, setTrsftaxCalcModalOpen] = React.useState<boolean>(false); // 양도세 결과 조회 모달
    const [trsftaxCalcModalVisible, setTrsftaxCalcModalVisible] = React.useState<boolean>(false); // 양도세 결과 조회 모달 visible

    const [trsfAmt, setTrsfAmt] = React.useState<string>(""); // 양도금액
    const [trsfDe, setTrsfDe] = React.useState<string | undefined>(Util.getSpecificDate("", "day", 0, "DD", "")); // 양도일자
    const [isPay, setIsPay] = React.useState<string>("N"); // 청산금납부여부
    const [payAmt, setPayAmt] = React.useState<string>(""); // 청산금액
    const [ncssryAmtBf, setNcssryAmtBf] = React.useState<string>(""); // 기타경비
    const [ncssryAmt, setNcssryAmt] = React.useState<string>(""); // 기타경비(신규)

    const [assetsModalOpen, setAssetsModalOpen] = React.useState<boolean>(false); // 보유자산 조회 모달

    const goInputTrsftaxAddInfo = (item: any) => {
        if (item.rmstnfamSe !== "1000") {
            AppContext.openAlert({
                title: "세액계산 불가",
                msg: "본인(납세자)의 자산만 세액계산이 가능합니다.",
                icon: "check"
            });
            return;
        }
        if (item.stepCode !== "1009") {
            AppContext.openAlert({
                title: "세액계산 불가",
                msg: "등록완료 상태의 자산만 세액계산이 가능합니다.",
                icon: "check"
            });
            return;
        }
        setAssetsSn(item.assetsSn);
        setEstateDetailSe(item.estateDetailSe);
        setAcqsSrc(item.acqsSrc);
        setAcqsCause(item.acqsCause);

        if (item.acqsCause === "1004") {
            AppContext.openAlert({
                title: "취득가액 이월과세 및 부당행위계산부인 확인",
                msg:
                    "소득세법 제97조의 2 ‘취득가액 이월과세’\n" +
                    "거주자가 양도일로부터 소급하여 10년(2022.12.31. 이전 증여분은 5년) 이내에 그 배우자 또는 직계존비속으로부터 증여받은\n" +
                    "토지·건물 및 특정시설물이용권·부동산을 취득할 수 있는 권리의 양도차익을 계산함에 있어서 양도가액에서 공제할 취득가액은\n" +
                    "당해 증여자의 취득 당시 금액으로 한다. 이 경우 거주자가 증여받은 자산에 대하여 납부하였거나 납부할 증여세 상당액이 있는\n" +
                    "경우에는 필요경비에 산입한다.\n\n" +
                    "또한 별도 세대원인 직계존비속(무주택자)에게 주택을 증여하는 방법으로 양도세 부담을 회피하는 사례를 방지하기 위하여\n" +
                    "양도세 이월과세가 적용되어 수증자가 1세대 1주택 비과세대상이 되는 경우 이월과세적용대상에서 제외하여 부당행위계산부인대상에\n" +
                    "해당되는 것으로 한다.\n\n" +
                    "이에 해당합니까?"
                ,
                icon: "check",
                confirmText: "예",
                handleConfirm: () => {
                    AppContext.openAlert({
                        title: "안내",
                        msg: "별도의 계산이 필요합니다.",
                        icon: "check"
                    });
                    return;
                },
                closeText: "아니오",
                handleClose: () => {
                    AppContext.openAlert({
                        title: "‘부당행위계산부인",
                        msg:
                            "소득세법 제101조 제2항 ‘부당행위계산부인’\n" +
                            "양도소득세를 부당히 감소시키기 위하여 특수관계자에게 자산을 증여\n" +
                            "(소득세법 제97조의 2의 규정을 적용받는 배우자 및 직계존비속의 경우를 제외한다)\n" +
                            "한 후 그 자산을 증여받은 자가 그 증여일부터 10년(2022.12.31. 이전 증여분은 5년) 이내에\n" +
                            "다시 이를 타인에게 양도한 경우에는 증여자가 그 자산을 직접 양도한 것으로 본다.\n" +
                            "이 경우 당초 증여받은 자산에 대하여는 상속세 및 증여세법의 규정에 불구하고 증여세를 부과하지 아니한다.\n\n" +
                            "이에 해당합니까?"
                        ,
                        icon: "check",
                        confirmText: "예",
                        handleConfirm: () => {
                            AppContext.openAlert({
                                title: "안내",
                                msg: "별도의 계산이 필요합니다.",
                                icon: "check"
                            });
                            return;
                        },
                        closeText: "아니오",
                        handleClose: () => {
                            moveStep(1);
                        }
                    });
                }
            });
            return;
        } else {
            moveStep(1);
        }
    }

    const moveStep = (step: number) => {
        setTaxInputModalOpen(false);
        setTrsftaxMrrgQestnModalOpen(false);
        setTrsftaxChbtQestnModalOpen(false);
        setTrsftaxCalcModalOpen(false);
        if (step === 1) {
            setTaxInputModalOpen(true);
        } else if (step === 2) {
            setTrsftaxMrrgQestnModalOpen(true);
        } else if (step === 3) {
            setTrsftaxChbtQestnModalOpen(true);
        } else if (step === 4) {
            setTrsftaxCalcModalOpen(true);
        }
    }

    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/rmstnfam/selRmstnfamList.do",
            methodType: "post",
            paramType: "json",
            paramData: {
                hshldSn: hshldSn
            },
            onSuccessFn: (res: any) => {
                setRmstnfamDataArr(res.list);
                res.list.forEach((item: any) => {
                    if (item.rmstnfamSe === "1000") {
                        setHshldMrrgAt(item.mrrgAt);
                        setHshldChbtSptAt(item.chbtSptAt);
                    }
                });
                Axios.dataAccess({
                    url: "system/assets/selAssetsList.do",
                    methodType: "post",
                    paramType: "json",
                    paramData: {
                        hshldSn: hshldSn,
                        assetsSe: "1001",
                        estateSttus: "1002",
                        estateSe: "1001",
                        routeKey: "trsftax"
                    },
                    onSuccessFn: (res: any) => {
                        setAssetsDataArr(res.list);
                        AppContext.hideSpinner();
                    }
                });
            }
        });
    }, [hshldSn]);

    return (
        <React.Fragment>
            <div className="w100p bgcF6F6F6">
                <div className="dpFlx flxdCol jcC aiC pt60 pb30">
                    <div className='taC pb30'>
                        <span className="dpBlock fcBlack fs38 ls266 SpoqaHanSansNeo-Medium">양도세 계산</span>
                    </div>
                </div>
                <div className="dpFlx jcC pt30 pb100">
                    <div className='w1200'>
                        <div className='h36 dpFlx aiE pb20'>
                            <img className="mr10" src={require('img/common/icon_step.png')} alt="icon_step" />
                            <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 pb3'>
                                <span className='fc17A840'>{hshldNm}</span>
                                <span className='fc222222'>님의 세대원</span>
                            </span>
                        </div>
                        <MuiListFilter tableInfo={{
                            tableHeadVsb: true,
                            styleType: ForestTableStyle,
                            fldInfs: [
                                { type: 'text', fldNm: 'rowNum', width: 65, headAlign: "center", dataAlign: "center", label: 'No.' },
                                { type: 'text', fldNm: 'rmstnfamNm', width: 90, headAlign: "center", dataAlign: "center", label: '세대원명' },
                                { type: 'code', fldNm: 'rmstnfamSe', wAuto: true, headAlign: "center", dataAlign: "center", label: '가족관계', codeTy: '1001' },
                                { type: 'code', fldNm: 'sexdstn', width: 60, headAlign: "center", dataAlign: "center", label: '성별', codeTy: '1002' },
                                { type: 'text', fldNm: 'rmstnfamAge', width: 60, headAlign: "center", dataAlign: "center", label: '나이' },
                                { type: 'text', fldNm: 'brthdy', width: 100, headAlign: "center", dataAlign: "center", label: '생년월일' },
                                { type: 'code', fldNm: 'incomeAt', width: 80, headAlign: "center", dataAlign: "center", label: '소득충족', codeTy: '1032' },
                                { type: 'code', fldNm: 'mrrgAt', width: 80, headAlign: "center", dataAlign: "center", label: '결혼여부', codeTy: '1032' },
                                { type: 'code', fldNm: 'adresAdres', width: 80, headAlign: "center", dataAlign: "center", label: '주소동일', codeTy: '1032' },
                                { type: 'code', fldNm: 'lvlhdSamenss', width: 80, headAlign: "center", dataAlign: "center", label: '생계동일', codeTy: '1032' },
                                { type: 'code', fldNm: 'acqsRmstnfamAt', width: 80, headAlign: "center", dataAlign: "center", label: '취득기준', codeTy: '1032' },
                                { type: 'code', fldNm: 'trsfRmstnfamAt', width: 80, headAlign: "center", dataAlign: "center", label: '양도기준', codeTy: '1032' }
                            ],
                            ordFld: { name: "rowNum", direction: "asc" },
                            dataArr: rmstnfamDataArr,
                            maxRowNums: [10, 20, 30],
                            trHeight: 48,
                            remainArea: false,
                            srchVsb: true,
                            paginateClass: "pt40",
                            headerVsb: true
                        }}/>
                        <div className='h36 dpFlx aiE pb20 pt40'>
                            <img className="mr10" src={require('img/common/icon_step_money.png')} alt="icon_step_money" />
                            <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 pb3'>
                                <span className='fc222222'>보유자산</span>
                            </span>
                        </div>
                        <MuiListFilter tableInfo={{
                            tableHeadVsb: true,
                            styleType: ForestTableStyle,
                            fldInfs: [
                                { type: 'text', fldNm: 'rowNum', width: 65, headAlign: "center", dataAlign: "center", label: 'No.' },
                                { type: 'text', fldNm: 'rmstnfamNm', width: 90, headAlign: "center", dataAlign: "center", label: '세대원명' },
                                { type: 'code', fldNm: 'estateDetailSe', width: 110, headAlign: "center", dataAlign: "center", label: '자산구분', codeTy: "1006" },
                                { type: 'code', fldNm: 'estatePrpos', width: 90, headAlign: "center", dataAlign: "center", label: '주택용도', codeTy: "1008" },
                                { type: 'code', fldNm: 'acqsCause', width: 100, headAlign: "center", dataAlign: "center", label: '취득원인', codeTy: "1035" },
                                { type: 'text', fldNm: 'fullRdnmAdres', wAuto: true, headAlign: "center", dataAlign: "left", label: '주소' },
                                { type: 'text', fldNm: 'trsfAcqsDe', width: 100, headAlign: "center", dataAlign: "center", label: '취득일' },
                                { type: 'el', fldNm: 'stepCode', width: 100, headAlign: "center", label: '등록상태', el: (item: any) =>
                                    <div className={'dpFlx aiC jcC w60 h24 bdrd40 bdBox ' + (item.stepCode === "1009" ? "bgc17A840" : "bgcF1F8FF bd1 bdc007CE8")}>
                                        <span className={"SpoqaHanSansNeo-Medium fs12 ls06 " + (item.stepCode === "1009" ? "fcWhite" : "fc007CE8")}>{item.stepCode === "1009" ? "등록완료" : "진행중"}</span>
                                    </div>
                                },
                                { type: 'el', width: 70, headAlign: "center", el: (item: any) => (
                                    <div className='dpFlx aiC csP' onClick={() => goInputTrsftaxAddInfo(item)}>
                                        <img className="csP pr3" src={require('img/common/icon_cal20.png')} alt="icon_cal20" />
                                        <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">계산</span>
                                    </div>
                                )},
                                { type: 'el', width: 70, headAlign: "center", el: (item: any) => (
                                    <div className='dpFlx aiC csP' onClick={() => {
                                        setAssetsSn(item.assetsSn);
                                        setAssetsModalOpen(true);
                                    }}>
                                        <img className="csP pr3" src={require('img/common/icon_copy20.png')} alt="icon_copy20" />
                                        <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">조회</span>
                                    </div>
                                )}
                            ],
                            ordFld: { name: "rowNum", direction: "asc" },
                            dataArr: assetsDataArr,
                            maxRowNums: [10, 20, 30],
                            trHeight: 48,
                            remainArea: false,
                            srchVsb: true,
                            paginateClass: "pt40",
                            headerVsb: true
                        }}/>
                    </div>
                </div>
            </div>
            <Modal modalOpen={taxInputModalOpen} setModalOpen={setTaxInputModalOpen} title="양도세 계산 - 양도정보 입력" dim={true} closeCallBack={() => moveStep(0)}>
                <TrsftaxInput
                    trsfAmt={trsfAmt}
                    trsfDe={trsfDe}
                    isPay={isPay}
                    payAmt={payAmt}
                    ncssryAmtBf={ncssryAmtBf}
                    ncssryAmt={ncssryAmt}
                    setTrsfAmt={setTrsfAmt}
                    setTrsfDe={setTrsfDe}
                    setIsPay={setIsPay}
                    setPayAmt={setPayAmt}
                    setNcssryAmtBf={setNcssryAmtBf}
                    setNcssryAmt={setNcssryAmt}
                    estateDetailSe={estateDetailSe}
                    acqsSrc={acqsSrc}
                    acqsCause={acqsCause}
                    moveStep={moveStep}
                    hshldMrrgAt={hshldMrrgAt}
                    hshldChbtSptAt={hshldChbtSptAt}
                />
            </Modal>
            <Modal modalOpen={trsftaxMrrgQestnModalOpen} setModalOpen={setTrsftaxMrrgQestnModalOpen} title="양도세 계산 - 혼인합가" dim={true} closeCallBack={() => moveStep(0)}>
                <TrsftaxMrrgQestn
                    moveStep={moveStep}
                    hshldChbtSptAt={hshldChbtSptAt}
                />
            </Modal>
            <Modal modalOpen={trsftaxChbtQestnModalOpen} setModalOpen={setTrsftaxChbtQestnModalOpen} title="양도세 계산 - 동거봉양합가" dim={true} closeCallBack={() => moveStep(0)}>
                <TrsftaxChbtQestn
                    moveStep={moveStep}
                    hshldMrrgAt={hshldMrrgAt}
                />
            </Modal>
            <Modal modalOpen={trsftaxCalcModalOpen} setModalOpen={setTrsftaxCalcModalOpen} title="양도세 세액 산출" dim={true} closeCallBack={() => moveStep(0)} visible={trsftaxCalcModalVisible}>
                <TrsftaxCalc
                    assetsSn={assetsSn}
                    trsfAmt={trsfAmt}
                    trsfDe={trsfDe}
                    isPay={isPay}
                    payAmt={payAmt}
                    ncssryAmtBf={ncssryAmtBf}
                    ncssryAmt={ncssryAmt}
                    setTrsftaxCalcModalOpen={setTrsftaxCalcModalOpen}
                    setTrsftaxCalcModalVisible={setTrsftaxCalcModalVisible}
                />
            </Modal>
            <Modal modalOpen={assetsModalOpen} setModalOpen={setAssetsModalOpen} title="보유자산 조회" dim={true} closeCallBack={() => {
                setAssetsSn(undefined);
                setAssetsModalOpen(false);
            }}>
                <div className="w1200 h500 ofA">
                    <AssetsShow assetsSn={assetsSn} />
                </div>
                <div className='w100p dpFlx aiC jcC pt40'>
                    <button className='w210 h56 bgcF6F6F6 dpFlx aiC jcC csP bdN' onClick={() => {
                        setAssetsSn(undefined);
                        setAssetsModalOpen(false);
                    }}>
                        <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>닫기</span>
                    </button>
                </div>
            </Modal>
        </React.Fragment>
    )
}
export default Trsftax;