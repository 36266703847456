import { useEffect, useState } from 'react';

const UseCountdown = (targetDate: string, retType: "sec" | "min" | "hour" | "day", state: boolean) => {
    const countDownDate = new Date(targetDate).getTime();
    const [countDown, setCountDown] = useState<number>(0);

    useEffect(() => {
        if (!state) {
            return;
        }
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
            if (countDownDate - new Date().getTime() < 1000) clearInterval(interval); // 0초에 타이머 종료
        }, 1000);
        // return () => clearInterval(interval);
    }, [countDownDate, state]);

    return getReturnValues(countDown, retType);
};

const getReturnValues = (countDown: number, retType: "sec" | "min" | "hour" | "day") => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    if (retType === "min") {
        return [countDown === null ? "" : minutes, countDown === null ? "" : seconds];
    } else {
        return [days, hours, minutes, seconds];
    }
};

export { UseCountdown };