import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { taxFlagState } from "js/common/Atom";
import MobileMain from "js/mobile/Menu/MobileMain";
import MobileBody from "js/mobile/Menu/MobileBody";
import MobileHeader from "js/mobile/Menu/MobileHeader";

function MobileCalcMenu() {
  const navigate = useNavigate();
  const setTaxFlag = useSetRecoilState(taxFlagState);

  const onClickAcquire = () => {
    // 취득세 계산기 선택
    setTaxFlag("A");
    navigate("/simpletax/acquisition");
  };

  const onClickTransfer = () => {
    // 양도세 계산기 선택
    setTaxFlag("F");
    navigate("/simpletax/transfer");
  };

  const onClickProperty = () => {
    // 재산세/종합부동산세 계산기 선택
    setTaxFlag("P");
    navigate("/simpletax/property");
  };

  const onClickGift = () => {
    // 증여세 계산기 선택
    navigate("/simpletax/gift");
  };

  const onClickInheritance = () => {
    // 상속세 계산기 선택
    navigate("/simpletax/inheritance");
  };

  return (
    <MobileBody>
      <MobileHeader />
      <MobileMain>
        <TitleContainer>
          <div className="logoWrap">
            <img src={require("img/common/logo_dark_green.png")} alt="logo" />
            <h1>계산기</h1>
          </div>
          <div className="desc">
            <p>조정대상지역 자동화</p>
            <p>조합원입주권(재개발/재건축) 자동 분석 가능</p>
            <p>주소 기반 공공데이터 자동입력</p>
          </div>
        </TitleContainer>
        <MenuContainer>
          <div className="menuItem" onClick={onClickTransfer}>
            <div className="icon">
              <img src={require("img/common/transfer.png")} alt="logo" />
              <span className="circle"></span>
            </div>
            <div className="desc">
              <p className="thick">양도세 계산기</p>
              <p className="thin">내 집 팔면 세금 얼마?</p>
            </div>
          </div>
          <div className="menuItem" onClick={onClickAcquire}>
            <div className="icon">
              <img src={require("img/common/acquire.png")} alt="logo" />
              <span className="circle"></span>
            </div>
            <div className="desc">
              <p className="thick">취득세 계산기</p>
              <p className="thin">내 집 사면 세금 얼마?</p>
            </div>
          </div>
          <div className="menuItem" onClick={onClickProperty}>
            <div className="icon">
              <img src={require("img/common/property.png")} alt="logo" />
              <span className="circle"></span>
            </div>
            <div className="desc">
              <p className="thick">재산세·종합부동산세 계산기</p>
              <p className="thin">내 집 보유 세금 얼마?</p>
            </div>
            {/*<div className="dim">
              <div>
                <p className="bold">Coming soon</p>
                <p className="regular">조금만 기다려주세요!</p>
              </div>
            </div>*/}
          </div>
          <div className="menuItem" onClick={onClickGift}>
            <div className="icon">
              <img src={require("img/common/gift.png")} alt="logo" />
              <span className="circle"></span>
            </div>
            <div className="desc">
              <p className="thick">증여세 계산기</p>
              <p className="thin">재산 받으면 세금 얼마?</p>
            </div>
          </div>
          <div className="menuItem" onClick={onClickInheritance}>
            <div className="icon">
              <img src={require("img/common/inheritance.png")} alt="logo" />
              <span className="circle"></span>
            </div>
            <div className="desc">
              <p className="thick">상속세 계산기</p>
              <p className="thin">재산 남기면 세금 얼마?</p>
            </div>
          </div>
        </MenuContainer>
      </MobileMain>
    </MobileBody>
  );
}

const TitleContainer = styled.div`
  padding: 10px 20px;
  margin-top: 10px;

  color: white;

  .logoWrap {
    img {
      width: 84px;
      display: inline-block;
    }

    h1 {
      display: inline-block;
      font-family: "Spoqa Han Sans Neo", sans-serif;
      font-weight: 500;
      font-size: 1.8em;
      line-height: 0.8;
      letter-spacing: -0.027em;
      color: ${(props) => props.theme.color.sNavyDark01};
      margin-bottom: 14px;
      transform: translateX(-5px);
    }
  }

  .desc {
    font-family: "Spoqa Han Sans Neo", sans-serif;
    font-weight: 300;
    font-size: 1.4em;
    line-height: 1.32;
    letter-spacing: -0.05em;
    color: ${(props) => props.theme.color.sNavyDark01};

    p {
      margin: 0;
    }
  }
`;

const MenuContainer = styled.section`
  width: 100%;
  padding: 10px 20px;

  .menuItem {
    position: relative;
    display: flex;
    align-items: center;
    gap: 14px;

    padding: 17px 20px;
    margin-bottom: 16px;
    border-radius: 20px;
    background-color: white;

    .icon {
      position: relative;
      width: 81px;
      height: 72px;
      z-index: 2;

      img {
        width: 100%;
        height: 100%;
      }

      .circle {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        width: 72px;
        height: 72px;
        border-radius: 50%;
        background-color: ${(props) => props.theme.color.sGreenLight03};
      }
    }

    .desc {
      p {
        margin: 0;
      }

      .thick {
        font-family: "Spoqa Han Sans Neo", sans-serif;
        font-weight: 400;
        font-size: 1.4em;
        line-height: 1.32;
        letter-spacing: -0.05em;
        color: black;
      }

      .thin {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.2em;
        line-height: 1.29;
        letter-spacing: -0.058em;
        color: ${(props) => props.theme.color.nGray50};
      }
    }

    .dim {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;

      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 100%;
      border-radius: 20px;

      background-color: rgba(0, 0, 0, 0.68);

      p {
        margin: 0;
      }

      .bold {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 500;
        font-size: 2em;
        line-height: 1.35;
        letter-spacing: -0.028em;
        color: ${(props) => props.theme.color.pGreenLight04};
      }
      .regular {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 300;
        font-size: 1.4em;
        line-height: 1.8;
        letter-spacing: -0.02em;
        color: ${(props) => props.theme.color.pGreenLight03};
      }
    }
  }
`;

export default MobileCalcMenu;
