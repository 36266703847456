/***********************************************************************************
*** Law27at10 ** 종합부동산세 시행령 제3조 제1항 제3호 (기존 임대주택) - 보유주택이 임대주택인 경우 Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law27at10Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}

export default function Law27at10({ setModalOpen, setLawModalTitle, callBack, callMode }: Law27at10Props) {
    React.useEffect(() => {
        setLawModalTitle("종합부동산세 시행령 제3조 제1항 제3호 (기존 임대주택) - 보유주택이 임대주택인 경우");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className='w100p hAuto ofA mb30'>
                <table className={
                    'forest-list-table bdt1 bdb1 bdcDDDDDD ' +
                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075'
                }>
                   <colgroup>
                        <col className='w100'></col>
                        <col className='w130'></col>
                        <col className='w130'></col>
                        <col className='w160'></col>
                        <col className='w130'></col>
                        <col className='w110'></col>
                        <col className='w130'></col>
                        <col className='w130'></col>
                        <col className='w110'></col>
                        <col className='w130'></col>
                    </colgroup>
                    <tbody>
                        <tr className='h40'>
                            <th rowSpan={2}>구분</th>
                            <th rowSpan={2}>주택구분</th>
                            <th rowSpan={2}>주택규모</th>
                            <th rowSpan={2}>가액기준</th>
                            <th colSpan={2}>등록</th>
                            <th rowSpan={2}>민특법 분류</th>
                            <th rowSpan={2}>임대기간</th>
                            <th rowSpan={2}>상한율</th>
                            <th>혜택</th>
                        </tr>
                        <tr className='h29'>
                            <th>지자체</th>
                            <th>세무서</th>
                            <th>종부세 합산여부</th>
                        </tr>
                        <tr className='h110'>
                            <th>개별사항</th>
                            <td>모든주택</td>
                            <td>국민주택규모 이하</td>
                            <td>2005년도 과세기준일<br/>현재 공시가격<br/>3억원 이하</td>
                            <td>2005.01.05. 이전<br/>단기 등록</td>
                            <td>-</td>
                            <td>임대주택<br/>(민특법 시행 전)</td>
                            <td>5년 이상</td>
                            <td>-</td>
                            <td>배제</td>
                        </tr>
                        <tr className='h110'>
                            <th>공통사항</th>
                            <td className='taL pt15 pb15 pl15'colSpan={9}>
                                <ul className='lsN m0 p0'>
                                    <li>① 합산배제 신고</li>
                                    <li className='pl17'>합산배제 적용대상 주택을 보유한 자가 합산배제 임대주택의 규정을 적용받으려는 때에는 매년 9.16. ~ 9.30. 까지 합산배제 신고서를 납세지 관할세무서장에게 제출하여야 한다.</li>
                                    <li className='pl17'>다만, 최초의 합산배제신고를 한 연도의 다음 연도부터는 그 신고한 내용 중 소유권 또는 전용면적 등의 변동이 없는 경우에는 신고하지 아니할 수 있다.</li>
                                </ul>
                            </td>
                        </tr>
                        <tr className='h70'>
                            <th>추가<br/>확인사항</th>
                            <td className='taL pt15 pb15 pl15'colSpan={9}>
                                (1) 임대호수 : 2호 이상<br />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}
