/*******************************************************************************
*** SimpleCallTax ** 간편계산기 TAB
*******************************************************************************/
import React          from "react";
import SimGiftTax     from "./SimGiftTax";
import SimInheTax     from "./SimInheTax";
import Util           from "js/common/Util";
import Axios          from "js/common/Axios";
import SimAcqsAutoTax from "./SimAcqsAutoTax";
import SimPropAutoTax from "./SimPropAutoTax";
import SimTranAutoTax from "./SimTranAutoTax";

const SimpleCallTax = () => {
    const [taxFlag   , setTaxFlag   ] = React.useState<string>("A");
    const [searchText, setSearchText] = React.useState<string>("상암미르웰");
    const [apiResult , setApiResult ] = React.useState<string>("");

    // API 정상 호출 여부 확인
    React.useEffect(() => {
        if (Util.isNotEmpty(searchText)) {
            // 주소 조회 API 확인
            Axios.dataAccess({
                url: "api/system/broker/callApi.do",
                methodType: "post",
                paramType: "object",
                paramData: {
                    apiName: "openapi",
                    tailUrl: "addr/juso",
                    paramStr: encodeURI(searchText) + ",1,10"
                },
                onSuccessFn: (res: any) => {
                    setApiResult(res.item.rtn_code);
                },
                skipFailedAlert: true,
                onFailedFn: () => {
                    setApiResult("-1");
                }
            });
        } else {
            setApiResult("-1");
        }
    }, [searchText]);

    return (
        <div className='w100p'>
            <div className='w100p dpFlx jcC pt30 pb60'>
                <span className="SpoqaHanSansNeo-Medium fs38 ls266 fcBlack fw500">
                    {taxFlag === "A" ? "취득세 계산" : ""}
                    {taxFlag === "P" ? "재산세/종합부동산세 계산" : ""}
                    {taxFlag === "T" ? "양도세 계산" : ""}
                    {taxFlag === "G" ? "증여세 계산" : ""}
                    {taxFlag === "I" ? "상속세 계산" : ""}
                </span>
            </div>
            <div className="w1208 m0auto">
                <div className="dpFlx w100p h56 bdBox aiC">
                    {/* 취득세 */}
                    <div className={'flx1 w130 h100p dpFlx aiC jcC bdb2 ' + (taxFlag === "A" ? "bdc17A840" : "bdcWhite")} onClick={() => {
                        setTaxFlag("A");
                        setSearchText("상암미르웰");
                    }}>
                        <div className='dpFlx aiC jcC w150 pb10 csP'>
                            <span className={'SpoqaHanSansNeo-Medium fs16 fw500 ls08 ' + (taxFlag === "A" ? "fc17A840" : "fc999999")}>취득세</span>
                        </div>
                    </div>

                    {/* 재산세/종합부동산세 */}
                    <div className={'flx1 w130 h100p dpFlx aiC jcC bdb2 ' + (taxFlag === "P" ? "bdc17A840" : "bdcWhite")} onClick={() => {
                        setTaxFlag("P");
                        setSearchText("상암미르웰");
                    }}>
                        <div className='dpFlx aiC jcC w150 pb10 csP'>
                            <span className={'SpoqaHanSansNeo-Medium fs16 fw500 ls08 ' + (taxFlag === "P" ? "fc17A840" : "fc999999")}>재산세/종합부동산세</span>
                        </div>
                    </div>

                    {/* 양도세 */}
                    <div className={'flx1 h100p dpFlx aiC jcC bdb2 ' + (taxFlag === "T" ? "bdc17A840" : "bdcWhite")} onClick={() => {
                        setTaxFlag("T");
                        setSearchText("상암미르웰");
                    }}>
                        <div className='dpFlx aiC jcC w150 pb10 csP'>
                            <span className={'SpoqaHanSansNeo-Medium fs16 fw500 ls08 ' + (taxFlag === "T" ? "fc17A840" : "fc999999")}>양도세</span>
                        </div>
                    </div>

                    {/* 증여세 */}
                    <div className={'flx1 h100p dpFlx aiC jcC bdb2 ' + (taxFlag === "G" ? "bdc17A840" : "bdcWhite")} onClick={() => {
                        setTaxFlag("G");
                        setSearchText("");
                    }}>
                        <div className='dpFlx aiC jcC w150 pb10 csP'>
                            <span className={'SpoqaHanSansNeo-Medium fs16 fw500 ls08 ' + (taxFlag === "G" ? "fc17A840" : "fc999999")}>증여세</span>
                        </div>
                    </div>

                    {/* 상속세 */}
                    <div className={'flx1 h100p dpFlx aiC jcC bdb2 ' + (taxFlag === "I" ? "bdc17A840" : "bdcWhite")} onClick={() => {
                        setTaxFlag("I");
                        setSearchText("");
                    }}>
                        <div className='dpFlx aiC jcC w150 pb10 csP'>
                            <span className={'SpoqaHanSansNeo-Medium fs16 fw500 ls08 ' + (taxFlag === "I" ? "fc17A840" : "fc999999")}>상속세</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bgcF6F6F6">
                <div className="w1200 m0auto">
                {taxFlag === "A" ? <SimAcqsAutoTax apiResult={apiResult} /> : <></>}  {/* 취득세 계산         */}
                {taxFlag === "P" ? <SimPropAutoTax apiResult={apiResult} /> : <></>}  {/* 재산세/종합부동산세 */}
                {taxFlag === "T" ? <SimTranAutoTax apiResult={apiResult} /> : <></>}  {/* 양도세 계산         */}
                {taxFlag === "G" ? <SimGiftTax /> : <></>}  {/* 증여세 계산         */}
                {taxFlag === "I" ? <SimInheTax /> : <></>}  {/* 상속세 계산         */}
                </div>
            </div>
        </div>
    )

}
export default SimpleCallTax;