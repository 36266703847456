/*******************************************************************************
 You are strictly prohibited to copy, disclose, distribute, modify, or use
 this program in part or as a whole without the prior written consent of 
 CallTax. CallTax, owns the intellectual property rights in and to this program.
 (Copyright © 2023 CallTax. All Rights Reserved| Confidential)
 
 Specification
    1. Program ID    : LawordMainDialog.tsx
    2. Program Title : 법령 Main 생성, 수정 Modal
    3. Created by    : JH Lee
    4. Create Date   : 2024.02.14
    5. Reference     : 
        - LawordMng.tsx 페이지에서 Modal 호출
*******************************************************************************/
import React      from 'react';
import Axios      from 'js/common/Axios';
import Util       from 'js/common/Util';
import AppContext from 'js/common/AppContext';
import TextBox    from 'js/common/ui/input/TextBox';
import SelectBox  from 'js/common/ui/input/SelectBox';

interface LawordMainDialogProps {
    setModalMainOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setLawordMainSelTime: React.Dispatch<React.SetStateAction<Date>>;
    callMode: string;
    lawordSn: number;
}

function LawordMainDialog({setModalMainOpen, setLawordMainSelTime, callMode, lawordSn}: LawordMainDialogProps) {
    const [lawordTy  , setLawordTy  ] = React.useState<string>();   // 법령 유형
    const [lawordNm  , setLawordNm  ] = React.useState<string>();   // 법령명
    const [lawordGbn , setLawordGbn ] = React.useState<string>();   // 법령 구분
    const [lawordJo	 , setLawordJo	] = React.useState<string>();   // 법령 조
    const [lawordHo	 , setLawordHo	] = React.useState<string>();   // 법령 호
    const [lawordHang, setLawordHang] = React.useState<string>();   // 법령 항
    const [lawordMok , setLawordMok ] = React.useState<string>();   // 법령 목
    const [lawordDc  , setLawordDc  ] = React.useState<string>();   // 법령 설명

    // 저장 버튼 클릭
    const onSubmit = () => {
        // 법령 유형
        if (Util.isEmpty(lawordTy)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "법령 유형을 선택해 주세요.",
                icon: "warning"
            });
            return;
        }

        // 법령명
        if (Util.isEmpty(lawordNm)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "법령명을 입력해 주세요.",
                icon: "warning"
            });
            return;
        }

        // 법령 구분
        if (Util.isEmpty(lawordGbn)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "법령 구분을 입력해 주세요.",
                icon: "check"
            });
            return;
        }

        // 법령 조
        if (Util.isEmpty(lawordJo)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "법령이 몇 조인지 입력해 주세요.",
                icon: "check"
            });
            return;
        }

        let modName = "";
        let actUrl = "";
        if (callMode === "ins") {
            modName = "추가";
            actUrl  = "insLawordInfo.do";
        } else if (callMode === "upd") {
            modName = "수정";
            actUrl  = "updLawordInfo.do";
        } else {
            AppContext.openAlert({
                title: "시스템 오류",
                msg: "호출 구분 값이 없습니다.",
                icon: "warning"
            });
            return;
        }

        AppContext.openAlert({
            title: "법령 Main " + modName,
            msg: "법령 Main을 " + modName + "하시겠습니까?",
            icon: "check",
            confirmText: modName,
            handleConfirm: () => {
                Axios.dataAccess({
                    url: "admin/sysmng/laword/" + actUrl,
                    methodType: "post",
                    paramType: "json",
                    paramData: {
                        lawordSn  : lawordSn  , // 법령 일련번호
                        lawordTy  : lawordTy  , // 법령 유형
                        lawordNm  : lawordNm  , // 법령명
                        lawordGbn : lawordGbn , // 법령 구분
                        lawordJo  : lawordJo  , // 법령 조
                        lawordHo  : lawordHo  , // 법령 호
                        lawordHang: lawordHang, // 법령 항
                        lawordMok : lawordMok , // 법령 목
                        lawordDc  : lawordDc    // 법령 설명
                    },
                    onSuccessFn: () => {
                        setModalMainOpen(false);
                        setLawordMainSelTime(new Date());   // 법령 메인 리스트 재조회 처리용
                    }
                });
            }
        });
    };

    // 법령 메인 Update 정보 셋팅
    React.useEffect(() => {
        if (callMode === "upd") {
            Axios.dataAccess({
                url: "admin/sysmng/laword/selLawordInfo.do",
                methodType: "post",
                paramType: "object",
                paramData: { lawordSn: lawordSn },
                onSuccessFn: (res: any) => {
                    setLawordTy  (res.item.lawordTy  );   // 법령 유형
                    setLawordNm  (res.item.lawordNm  );   // 법령명
                    setLawordGbn (res.item.lawordGbn );   // 법령 구분
                    setLawordJo	 (res.item.lawordJo  );   // 법령 조
                    setLawordHo	 (res.item.lawordHo  );   // 법령 호
                    setLawordHang(res.item.lawordHang);   // 법령 항
                    setLawordMok (res.item.lawordMok );   // 법령 목
                    setLawordDc  (res.item.lawordDc  );   // 법령 설명
                }
            });
        }
    }, [callMode, setModalMainOpen, lawordSn]);

    return (
        <React.Fragment>
            <div className='w860 h100p bdBox dpFlx aiC'>
                
                {/* 왼쪽 입력부분 Start */}
                <div className='w50p'>
                    {/* 법령유형 : selectbox */}
                    <div className='dpFlx h40 pb10'>
                        <div className='w100 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>법령유형</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <SelectBox
                                value={lawordTy}
                                codeArray={Util.getCodeArray("1049")}
                                placeholder="법령 유형을 선택하세요."
                                width={300}
                                height={40}
                                fontSize={15}
                                paddingRight={5}
                                onChangeFunc={(data: string) => {
                                    setLawordTy(data);
                                }}
                                border="1px solid #dddddd"
                            />
                        </div>  
                    </div>
                    
                    {/* 법령구분 */}
                    <div className='dpFlx h40 pb10'>
                        <div className='w100 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>법령 구분</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={lawordGbn}
                                width={300}
                                height={40}
                                fontSize={15}
                                maxLength={30}
                                placeholder="법령 구분을 입력해 주세요."
                                onChangeFunc={(text: string) => setLawordGbn(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                            />
                        </div>
                    </div>

                    {/* 조, 호 */}
                    <div className='dpFlx h40 pb10'>                        
                        <div className='w50 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>조</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={lawordJo}
                                width={110}
                                height={40}
                                fontSize={15}
                                maxLength={10}
                                placeholder="법령 조 입력"
                                onChangeFunc={(text: string) => setLawordJo(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                            />
                        </div>
                        
                        <div className='w130 dpFlx aiC bdBox pl50'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>호</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={lawordHo}
                                width={110}
                                height={40}
                                fontSize={15}
                                maxLength={10}
                                placeholder="법령 호 입력"
                                onChangeFunc={(text: string) => setLawordHo(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                            />
                        </div>
                    </div>                        
                </div>
                {/* 왼쪽 입력부분 End */}

                {/* 오른쪽 입력부분 Start */}
                <div className='w50p pl30'>
                    {/* 법령명 */}
                    <div className='h40 dpFlx pb10'>
                        <div className='w100 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>법령명</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={lawordNm}
                                width={300}
                                height={40}
                                fontSize={15}
                                maxLength={30}
                                placeholder="법령명을 입력해 주세요."
                                onChangeFunc={(text: string) => setLawordNm(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                            />
                        </div>
                    </div>

                    {/* 법령 설명 */}
                    <div className='dpFlx h40 pb10'>
                        <div className='w100 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>법령 설명</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={lawordDc}
                                width={300}
                                height={40}
                                fontSize={15}
                                maxLength={30}
                                placeholder="법령 설명을 입력해 주세요."
                                onChangeFunc={(text: string) => setLawordDc(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                            />
                        </div>
                    </div>

                    {/* 항, 목 */}
                    <div className='dpFlx h40 pb10'>
                        <div className='w50 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>항</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={lawordHang}
                                width={110}
                                height={40}
                                fontSize={15}
                                maxLength={10}
                                placeholder="법령 항 입력"
                                onChangeFunc={(text: string) => setLawordHang(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                            />
                        </div>

                        <div className='w130 dpFlx aiC bdBox pl80'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>목</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={lawordMok}
                                width={110}
                                height={40}
                                fontSize={15}
                                maxLength={10}
                                placeholder="법령 목 입력"
                                onChangeFunc={(text: string) => setLawordMok(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                            />
                        </div>
                    </div>    
                </div>
                {/* 오른쪽 입력부분 End */}
            </div>

            <div className='w100p dpFlx aiC jcC pt40'>
                <div className='w210 h56 bgcF6F6F6 dpFlx aiC jcC csP' onClick={() => setModalMainOpen(false)}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>취소</span>
                </div>
                <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={onSubmit}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>저장</span>
                </div>
            </div>
        </React.Fragment>
    )
}
export default LawordMainDialog;