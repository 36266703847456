/*******************************************************************************
*** HshldDialog ** 고객/납세자 추가/수정
*******************************************************************************/
import React         from 'react';
import Axios         from 'js/common/Axios';
import Util          from 'js/common/Util';
import AppContext    from 'js/common/AppContext';
import TextBox       from 'js/common/ui/input/TextBox';
import DatePickerBox from 'js/common/ui/input/DatePickerBox';
import ImageRadioBox from 'js/common/ui/input/ImageRadioBox';

interface HshldDialogProps {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setHshldSelTime: React.Dispatch<React.SetStateAction<Date>>
    callMode: string
    hshldSn: number
}
function HshldDialog({ setModalOpen, setHshldSelTime, callMode, hshldSn }: HshldDialogProps) {
    const [rmstnfamSn  , setRmstnfamSn  ] = React.useState<number>();   // 세대원 일련번호
    const [rmstnfamNm  , setRmstnfamNm  ] = React.useState<string>();   // 세대원명
    const [sexdstn     , setSexdstn     ] = React.useState<string>(""); // 성별
    const [brthdy      , setBrthdy      ] = React.useState<string>();   // 생일
    const [mrrgAt      , setMrrgAt      ] = React.useState<string>(""); // 결혼여부
    const [mrrgDe      , setMrrgDe      ] = React.useState<string>();   // 결혼일자
    const [mrrgDeDsb   , setMrrgDeDsb   ] = React.useState<boolean>(true); // 결혼일자 Disabled 여부
    const [chbtSptAt   , setChbtSptAt   ] = React.useState<string>("");    // 동거봉양합가여부
    const [chbtSptDe   , setChbtSptDe   ] = React.useState<string>();      // 동거봉양합가여부 일자
    const [chbtSptDeDsb, setChbtSptDeDsb] = React.useState<boolean>(true); // 동거봉양합가여부 Disabled 여부

    const onSubmit = () => {
        if (Util.isEmpty(rmstnfamNm)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "고객/납세자의 이름을 입력해주세요.",
                icon: "warning"
            });
            return;
        }
        if (Util.isEmpty(brthdy)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "고객/납세자의 생년월일을 입력해주세요.",
                icon: "warning"
            });
            return;
        }
        if (Util.isEmpty(sexdstn)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "고객/납세자의 성별을 선택해주세요.",
                icon: "check"
            });
            return;
        }
        if (Util.isEmpty(mrrgAt)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "고객/납세자의 결혼여부를 선택해주세요.",
                icon: "check"
            });
            return;
        }
        if (Util.isEmpty(chbtSptAt)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "고객/납세자의 동거봉양합가여부를 선택해주세요.",
                icon: "check"
            });
            return;
        }

        if (mrrgAt === "Y") {
            if (Util.isEmpty(mrrgDe)) {
                AppContext.openAlert({
                    title: "필수입력",
                    msg: "납세자의 혼일일을 입력해주세요.",
                    icon: "warning"
                });
                return;
            }
        }

        if (chbtSptAt === "Y") {
            if (Util.isEmpty(chbtSptDe)) {
                AppContext.openAlert({
                    title: "필수입력",
                    msg: "납세자의 동거봉양합가일을 입력해주세요.",
                    icon: "warning"
                });
                return;
            }
        }

        let modNm = "";
        let actUrl = "";
        if (callMode === "reg") {
            modNm = "추가";
            actUrl = "insHshldInfo.do";
        } else if (callMode === "mod") {
            modNm = "수정";
            actUrl = "updHshldInfo.do";
        } else {
            AppContext.openAlert({
                title: "시스템 오류",
                msg: "호출 구분 값이 없습니다.",
                icon: "warning"
            });
            return;
        }
        AppContext.openAlert({
            title: "고객/납세자 " + modNm,
            msg: "고객/납세자를 " + modNm + "하시겠습니까?",
            icon: "check",
            confirmText: modNm,
            handleConfirm: () => {
                Axios.dataAccess({
                    url: "system/hshld/" + actUrl,
                    methodType: "post",
                    paramType: "json",
                    paramData: {
                        hshldSn: hshldSn,
                        rmstnfamSn: rmstnfamSn,
                        rmstnfamNm: rmstnfamNm,
                        sexdstn: sexdstn,
                        brthdy: brthdy,
                        mrrgAt: mrrgAt,
                        mrrgDe: mrrgAt === "Y" ? mrrgDe : null,
                        chbtSptAt: chbtSptAt,
                        chbtSptDe: chbtSptAt === "Y" ? chbtSptDe : null
                    },
                    onSuccessFn: () => {
                        setModalOpen(false);
                        setHshldSelTime(new Date());
                    }
                });
            }
        });
    };

    // 세대원 초기값 셋팅
    React.useEffect(() => {
        if (callMode === "reg") {
            setSexdstn("1001");
            setMrrgAt("N");
            setChbtSptAt("N");
        } else if (callMode === "mod") {
            Axios.dataAccess({
                url: "system/hshld/selHshldInfo.do",
                methodType: "post",
                paramType: "object",
                paramData: {
                    hshldSn: hshldSn
                },
                onSuccessFn: (res: any) => {
                    setRmstnfamSn(res.item.rmstnfamSn);
                    setRmstnfamNm(res.item.rmstnfamNm);
                    setSexdstn(res.item.sexdstn);
                    setBrthdy(res.item.brthdy);
                    setMrrgAt(res.item.mrrgAt);
                    setMrrgDe(res.item.mrrgDe);
                    setChbtSptAt(res.item.chbtSptAt);
                    setChbtSptDe(res.item.chbtSptDe);
                    setMrrgDeDsb(res.item.mrrgAt === "N");
                    setChbtSptDeDsb(res.item.chbtSptAt === "N");
                }
            });
        }
    }, [callMode, setModalOpen, hshldSn]);

    // 배우자가 있을 경우 결혼여부 수정불가
    const chkMrrgAtVal = (mrrgAt:string) => {
        setMrrgDeDsb(mrrgAt === "N");
        if (mrrgAt === "N") {
            Axios.dataAccess({
                url: "system/rmstnfam/cntExistSpouseRmstnfam.do",
                methodType: "post",
                paramType: "object",
                paramData: {
                    hshldSn: hshldSn
                },
                onSuccessFn: (res: any) => {
                    if (res.item === 1) {
                        AppContext.openAlert({
                            title: "알림",
                            msg: "추가된 배우자 세대원 정보가 존재하여 결혼여부 변경이 불가합니다.",
                            icon: "warning"
                        });
                        setMrrgAt("Y");
                    }
                }
            });
        }
    }

    return (
        <React.Fragment>
            <div className='w860 h100p bdBox dpFlx aiC'>
                <div className='w50p'>
                    <div className='dpFlx h40 pb10'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>고객/납세자명</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={rmstnfamNm}
                                width={300}
                                height={40}
                                fontSize={15}
                                maxLength={10}
                                placeholder="고객/납세자 이름을 입력해 주세요."
                                onChangeFunc={(text: string) => setRmstnfamNm(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                            />
                        </div>
                    </div>
                    <div className='dpFlx h40 pb10'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>성별</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <ImageRadioBox
                                codeList={Util.getCodeArray("1002")}
                                value={sexdstn}
                                setValue={setSexdstn}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                            />
                        </div>
                    </div>
                    <div className='dpFlx h40 pb10'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>결혼여부</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <ImageRadioBox
                                codeList={Util.getCodeArray("1036")}
                                value={mrrgAt}
                                setValue={setMrrgAt}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                                onChange={(data: string) => {
                                    chkMrrgAtVal(data);
                                    setMrrgDeDsb(data === "N");
                                }}
                            />
                        </div>
                    </div>

                    <div className='dpFlx h40'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>동거봉양합가여부</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <ImageRadioBox
                                codeList={Util.getCodeArray("1036")}
                                value={chbtSptAt}
                                setValue={setChbtSptAt}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                                onChange={(value: string) => {
                                    setChbtSptDeDsb(value === "N")
                                }}
                            />
                        </div>
                    </div>
                </div>

                {/* 오른쪽 */}
                <div className='w50p pl30'>
                    <div className='h40 pb10'></div>
                    <div className='h40 dpFlx pb10'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>생년월일</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <DatePickerBox value={brthdy} width={300} height={40} fontSize={15} textAlign="left" onChange={(data: string) => {
                                setBrthdy(Util.dateToString(data, "")) }}
                            />
                        </div>
                    </div>

                    <div className='h40 dpFlx pb10'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>혼인일</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <DatePickerBox value={mrrgDe} width={300} height={40} fontSize={15} textAlign="left" disabled={mrrgDeDsb} onChange={(data: string) => {
                                setMrrgDe(Util.dateToString(data, "")) }}
                            />
                        </div>
                    </div>
                    <div className='h40 dpFlx'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>동거봉양합가일</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <DatePickerBox value={chbtSptDe} width={300} height={40} fontSize={15} textAlign="left" disabled={chbtSptDeDsb} onChange={(data: string) => {
                                setChbtSptDe(Util.dateToString(data, "")) }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='w100p dpFlx aiC jcC pt40'>
                <button className='w210 h56 bgcF6F6F6 dpFlx aiC jcC csP bdN' onClick={() => setModalOpen(false)}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>취소</span>
                </button>
                <button className='w210 h56 bgc17A840 dpFlx aiC jcC csP bdN' onClick={onSubmit}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>저장</span>
                </button>
            </div>
        </React.Fragment>
    )
}
export default HshldDialog;