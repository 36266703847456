import { MainSectionContainer } from "../common/layout";
import AddressSearch from "js/mobile/AddressInfo/AddressSearch";
import DongHoSelect from "js/mobile/AddressInfo/DongHoSelect";
import FinalAddrInfo from "js/mobile/AddressInfo/FinalAddrInfo";
import { Juso, Step } from "js/common/types/types";

type Props = {
  stepList: any[];
  searchText: string;
  setSearchText: (text: string) => void;
  searchResult: Juso[];
  setSearchResult: (result: Juso[]) => void;
  onSelectAddr: () => void;
};

function AddressInfo({
  stepList,
  searchText,
  setSearchText,
  searchResult,
  setSearchResult,
  onSelectAddr,
}: Props) {
  const currentStep = stepList.find((step: Step) => step.selected);

  const handleSelectAddr = () => {
    onSelectAddr();
  };

  if (!currentStep) {
    return <></>;
  } else {
    return (
      <MainSectionContainer>
        <p className="desc">
          {currentStep.stepId === "ACQS0201" && (
            <span className="bold">주소 입력 (1/3)</span>
          )}
          {currentStep.stepId === "ACQS0202" && (
            <span className="bold">상세 주소 입력 (2/3)</span>
          )}
          {currentStep.stepId === "ACQS0203" && (
            <span className="bold">최종 주소 확인 (3/3)</span>
          )}
        </p>
        {(currentStep.stepId === "ACQS0201" ||
          currentStep.stepId === "ACQS0202") && (
          <AddressSearch
            searchText={searchText}
            setSearchText={setSearchText}
            searchResult={searchResult}
            setSearchResult={setSearchResult}
            updateSelectedAddr={handleSelectAddr}
          />
        )}
        {currentStep.stepId === "ACQS0202" && <DongHoSelect />}
        {currentStep.stepId === "ACQS0203" && <FinalAddrInfo taxFlag="A" />}
      </MainSectionContainer>
    );
  }
}

export default AddressInfo;
