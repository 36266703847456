/***********************************************************************************
*** Law37at10 ** 소득세법 155조 제3항 공동상속주택-주된지분자 Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law37at10Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}

export default function Law37at10({ setModalOpen, setLawModalTitle, callBack, callMode } : Law37at10Props) {
    React.useEffect(() => {
        setLawModalTitle("소득세법 155조 제3항 공동상속주택-주된지분자");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className="m0 mt15 mb30">
                <span className="fs15 lh25 ls06 SpoqaHanSansNeo-Regular">※ 추가 확인사항<br /></span>
                <span className="lh20 fs14">
                    상속주택 → 공동상속주택-주된지분자<br />
                </span>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}
