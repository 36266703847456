interface Qestn47ModalProps {
    setQestn47Open: React.Dispatch<React.SetStateAction<boolean>>
}
const Qestn47Modal = ({setQestn47Open}: Qestn47ModalProps) => {
    return (
        <div className="w100p hAuto ofA">
            <span className="SpoqaHanSansNeo-Regular fs15 ls07">
                일시적 2주택이란? <br /> 국내에 주택, 조합원입주권, 주택분양권 또는 오피스텔을 1개 (중과제외주택은 제외) 소유한 1세대가 그 주택, 조합원입주권, 주택분양권 또는 오피스텔 (이하 “종전 주택등”이라 한다) 을 소유한 상태에서<br/>
                다른 1주택 (이하 “신규 주택”이라 한다) 을 추가로 취득한 후 3년 (이하 “일시적 2주택 기간”이라 한다) 이내에 종전 주택 등 (신규 주택이 조합원입주권 또는 주택분양권에 의한 주택이거나<br/>
                종전 주택 등이 조합원입주권 또는 주택분양권인 경우에는 신규 주택을 포함한다) 을 처분 (매매, 증여, 멸실) 하는 경우 해당 신규 주택을 말한다.<br/>
            </span>
            <table className={
                'forest-list-table bdt1 bdcDDDDDD mt20 ' +
                'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075 td-p15'
            }>
                <colgroup>
                    <col className='w130'></col>
                    <col className='w130'></col>
                    <col className='w160'></col>
                    <col className='w160'></col>
                    <col className='w225'></col>
                    <col className='w130'></col>
                    <col className='w250'></col>
                </colgroup>
                <tbody>
                    <tr className='h69'>
                        <th>종전부동산</th>
                        <th>신규부동산</th>
                        <th>완공주택</th>
                        <th>처분하는주택</th>
                        <th>기간 기산일</th>
                        <th>처분기한</th>
                        <th>추징사유</th>
                    </tr>
                    <tr className='h70'>
                        <td>주택</td>
                        <td>주택</td>
                        <td>-</td>
                        <td>종전주택</td>
                        <td>신규주택 취득일</td>
                        <td rowSpan={5}>3년</td>
                        <td className="taL" rowSpan={5}>
                            <span className="lh20">지방세법 제21조 부족세액의 추징 및<br/>가산세 일시적 2주택으로 신고하였으나 기산일로부터 3년 이내에 종전주택 등을 처분하지 못하여 1주택으로 되지 아니한 경우에 산출세액 + 가산세를 보통징수의 방법으로 징수한다.</span>
                        </td>
                    </tr>
                    <tr className='h70'>
                        <td>주택</td>
                        <td>분양권</td>
                        <td>분양권 → 주택</td>
                        <td>종전주택, 완공주택</td>
                        <td className="bdr1">분양권에 의한 주택 취득일</td>
                    </tr>
                    <tr className='h70'>
                        <td>주택</td>
                        <td>조합원입주권</td>
                        <td>조합원입주권 → 주택</td>
                        <td>종전주택, 완공주택</td>
                        <td className="bdr1">조합원입주권에 의한 주택 취득일</td>
                    </tr>
                    <tr className='h70'>
                        <td>분양권</td>
                        <td>주택</td>
                        <td>분양권 → 주택</td>
                        <td>종전주택, 완공주택</td>
                        <td className="bdr1">분양권에 의한 주택 취득일</td>
                    </tr>
                    <tr className='h70'>
                        <td className="bdb1">조합원입주권</td>
                        <td className="bdb1">주택</td>
                        <td className="bdb1">조합원입주권 → 주택</td>
                        <td className="bdb1">종전주택, 완공주택</td>
                        <td className="bdb1 bdr1">조합원입주권에 의한 주택 취득일</td>
                    </tr>
                </tbody>
            </table>
            <div className='w215 dpFlx aiC bdBox pt30'>
                <span className="SpoqaHanSansNeo-Medium fs15 ls07 fc222222">종전 주택이 관리처분계획인가 또는 사업시행계획인가 당시 해당 사업구역에 거주하는 세대가 신규 주택을 취득하여 그 신규 주택으로 이주한 경우에는 그 이주한 날에 종전 주택 등을 처분한 것으로 본다.</span>
            </div>
            <div className='w100p dpFlx aiC jcC pt40'>
                <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => setQestn47Open(false)}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>닫기</span>
                </div>
            </div>
        </div>
    )
}
export default Qestn47Modal;