import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import Util from 'js/common/Util';
import ReactPaginate from 'react-paginate';
import LinesEllipsis from 'react-lines-ellipsis';
import 'css/common/ui/react-paginate.css';
import ToolTip from '../ToolTip';
import Select from 'react-select';
import { CustomDropdownIndicator } from '../input/CustomDropdownIndicator';

export type fldInfProps = {
    type?: string
    pk?: boolean
    fldNm?: string
    width?: number
    wAuto?: boolean
    headAlign?: 'left' | 'center' | 'right'
    dataAlign?: 'left' | 'center' | 'right'
    label?: string
    codeTy?: string
    el?: Function
    dfltVal?: string
    class?: string
    func?: Function
}

type Order = 'asc' | 'desc';
function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (Util.nvl(b[orderBy], "") < Util.nvl(a[orderBy], "")) {
        return -1;
    }
    if (Util.nvl(b[orderBy], "") > Util.nvl(a[orderBy], "")) {
        return 1;
    }
    return 0;
}

const chkSetupInfoValidation = (tableInfo: any) => {
    if (Util.isEmpty(tableInfo)) {
        alert("테이블 설정 정보가 없습니다.");
        return false;
    }
    if (Util.isEmpty(tableInfo["ordFld"])) {
        alert("테이블 리스트를 정렬할 컬럼 정보가 없습니다.");
        return false;
    }
    if (Util.isEmpty(tableInfo["maxRowNums"])) {
        alert("테이블 항목수 배열 정보가 없습니다.");
        return false;
    }
    return true;
};
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
    chkBoxExist: boolean; // 체크박스 배치 여부
    numSelected: number; // 체크한 row count
    rowCount: number; // 전체 row count
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void; // 전체 체크 이벤트
    order: Order;
    orderBy: string;
    onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
    tableInfo: any;
    headCells: any;
    visible: boolean;
}
function EnhancedTableHead(props: EnhancedTableProps) {
    const { chkBoxExist, numSelected, rowCount, onSelectAllClick, order, orderBy, onRequestSort, tableInfo, headCells, visible } = props;
    const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    let left = 0;
    return (
        <TableHead style={{ visibility: (visible ? "visible" : "collapse") }}>
            <TableRow sx={[
                tableInfo["styleType"].tableHeadRow,
                { height: Util.isEmpty(tableInfo["trHeight"]) ? "" : tableInfo["trHeight"] + "px" },
            ]}>
                {chkBoxExist
                    ?
                        <TableCell
                            padding="checkbox"
                            sx={[tableInfo["styleType"].tableHeadChkBoxCell]}
                        >
                            <Checkbox
                                color="primary"
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={rowCount > 0 && numSelected === rowCount}
                                onChange={onSelectAllClick}
                                inputProps={{
                                    'aria-label': 'select all desserts',
                                }}
                            />
                        </TableCell>
                    : <></>
                }
                {headCells.map((headCell: any, idx: number) => {
                    if (Util.isNotEmpty(headCell.type)) {
                        const currLeft = left;
                        left = left + headCell.width;
                        let tableSortLabel;
                        if (headCell.type === "text" || headCell.type === "code" || headCell.type === "comma" || headCell.type === "phone" || (headCell.type === "el" && Util.isNotEmpty(headCell.fldNm))) {
                            tableSortLabel =
                                <TableSortLabel
                                    active={orderBy === headCell.fldNm}
                                    direction={orderBy === headCell.fldNm ? order : 'asc'}
                                    onClick={createSortHandler(headCell.fldNm)}
                                    sx={tableInfo["styleType"].tableHeadSortLabel}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.fldNm ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                        } else {
                            tableSortLabel = headCell.label;
                        }
                        return (
                            <TableCell
                                key={idx}
                                align={headCell.headAlign}
                                sortDirection={orderBy === headCell.fldNm ? order : false}
                                sx={[
                                    tableInfo["styleType"].tableHeadCell,
                                    {
                                        width: headCell.wAuto ? null : headCell.width,
                                        minWidth: headCell.wAuto ? headCell.width : null,
                                        position: idx <= tableInfo["fixFldCnt"] ? "sticky" : null,
                                        left: currLeft
                                    }
                                ]}
                            >
                                {tableSortLabel}
                            </TableCell>
                        )
                    } else {
                        return null;
                    }
                })}
            </TableRow>
        </TableHead>
    );
}

const MuiListFilter = ({ tableInfo }: any) => {
    const tableKey = Math.random().toString(36).substring(2, 11);
    const validationResult = chkSetupInfoValidation(tableInfo);
    const [order, setOrder] = React.useState<Order>(tableInfo["ordFld"]["direction"]);
    const [orderBy, setOrderBy] = React.useState<string>(tableInfo["ordFld"]["name"]);
    const chkBoxExist = Util.isNotEmpty(tableInfo["checkedHookParam"]); // 체크박스 표출여부 : 체크 핸들러 훅이 파라미터로 존재할 경우에 표출
    const [srchBoxVsb, setSrchBoxVsb] = React.useState<boolean>(false);
    const [srchText, setSrchText] = React.useState<string>("");
    const [filterDataArr, setFilterDataArr]: any[] = React.useState<any[]>([]);
    interface maxRowNumListProps {
        codeNm: string
        code: number
    }
    const [maxRowNumList, setMaxRowNumList] = React.useState<maxRowNumListProps[]>([]);

    let colSpan = chkBoxExist ? 1 : 0; // td colspan, 체크박스 표출 시 + 1
    tableInfo["fldInfs"].forEach((item: any) => {
        if (Util.isNotEmpty(item.type)) {
            colSpan = colSpan + 1;
        }
    });

    if (!Array.isArray(tableInfo["dataArr"])) {
        setFilterDataArr([]);
    }

    const headCells: readonly fldInfProps[] = tableInfo["fldInfs"];
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: any,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const [itemsPerPage, setItemsPerPage] = React.useState<number>(tableInfo["maxRowNums"][0]); // 한페이지에 포함시킬 ROW 수
    const [itemOffset, setItemOffset] = React.useState<number>(0); // 페이지 번호
    const [emptyRowCnt, setEmptyRowCnt] = React.useState<number>(0); // 남은 행 갯수
    const pageCount = Math.ceil(filterDataArr.length / itemsPerPage); // 페이지 수
    const [currentItems, setCurrentItems] = React.useState<any[]>([]);// 현재 페이지에 표출할 데이터 배열
    const [focusIndex, setFocusIndex] = React.useState<string>(""); // 포커싱 row 인덱스

    const handlePageClick = (event: any) => { // 하단 버튼 이벤트 함수 : 이전/다음, 1/2/3/4/5...
        // const newOffset = (event.selected * itemsPerPage) % filterDataArr.length;
        setItemOffset(event.selected);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = filterDataArr.map((n: any) => n);
            tableInfo["checkedHookParam"]["setCheckDataArr"](newSelected);
            return;
        }
        tableInfo["checkedHookParam"]["setCheckDataArr"]([]);
    };

    const handleClick = (row: any) => {
        const selectedIndex = tableInfo["checkedHookParam"]["checkDataArr"].indexOf(row);
        let newSelected: readonly any[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(tableInfo["checkedHookParam"]["checkDataArr"], row);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(tableInfo["checkedHookParam"]["checkDataArr"].slice(1));
        } else if (selectedIndex === tableInfo["checkedHookParam"]["checkDataArr"].length - 1) {
            newSelected = newSelected.concat(tableInfo["checkedHookParam"]["checkDataArr"].slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                tableInfo["checkedHookParam"]["checkDataArr"].slice(0, selectedIndex),
                tableInfo["checkedHookParam"]["checkDataArr"].slice(selectedIndex + 1),
            );
        }
        tableInfo["checkedHookParam"]["setCheckDataArr"](newSelected);
    };

    const optBoxBgColor = (isDisabled: boolean, isSelected: boolean, isFocused: boolean) => {
        if (isDisabled) {
            return "#E8E8E8";
        }
        if (isSelected) {
            return "#f9fafb";
        }
        if (isFocused) {
            return "#f9fafb";
        }
    }
    const optBoxFontColor = (isDisabled: boolean, isSelected: boolean, isFocused: boolean) => {
        if (isDisabled) {
            return "#ffffff";
        }
        if (isSelected) {
            return "#222222";
        }
        if (isFocused) {
            return "#222222";
        }
    }

    React.useEffect(() => {
        setFilterDataArr(tableInfo["dataArr"]);
        const maxRowNums: maxRowNumListProps[] = [];
        tableInfo["maxRowNums"].forEach((maxRowNum: number) => {
            maxRowNums.push({codeNm: "목록 " + maxRowNum + "개", code: maxRowNum});
        });
        setMaxRowNumList(maxRowNums);
    }, [tableInfo]);

    React.useEffect(() => {
        const currItems = stableSort(
            filterDataArr,
            getComparator(order, orderBy)
        ).slice(
            itemOffset * itemsPerPage,
            itemOffset * itemsPerPage + itemsPerPage
        );
        setEmptyRowCnt(itemsPerPage - currItems.length);
        setCurrentItems(currItems);
    }, [filterDataArr, order, orderBy, itemOffset, itemsPerPage]);

    if (!validationResult) {
        return (
            <></>
        );
    } else {
        return (
            <Box sx={{ width: '100%' }}>
                <div className={'w100p h44 jcSb aiC pb3 ' + (tableInfo["headerVsb"] ? "dpFlx" : "dpNone")}>
                    <div className='dpFlx aiE jcC'>
                        <span className='SpoqaHanSansNeo-Regular fs14 ls1 fc222222 pr3'>총</span>
                        <span className='ReplicaStdBold fs14 ls1 fc17A840'>{filterDataArr.length}</span>
                        <span className='SpoqaHanSansNeo-Regular fs14 ls1 fc222222'>건</span>
                    </div>
                    <div className='dpFlx aiC jcC'>
                        <div className={'aiC jcC mr18 ' + (tableInfo["srchVsb"] ? "dpFlx" : "dpNone")}>
                            <div className={'aiC jcC csP ' + (srchBoxVsb ? "dpNone" : "dpFlx")} onClick={() => setSrchBoxVsb(true)}>
                                <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc999999'>검색</span>
                                <img className="ml5" src={require('img/common/icon_search20.png')} alt="icon_search20" />
                            </div>
                            <div className={'aiC jcC ' + (srchBoxVsb ? "dpFlx" : "dpNone")}>
                                <div className='dpFlx aiC jcSb w482 h36 bd1 bdcE3E3E8 bdrd4 bgcWhite ofH pl8 pr8 pt4 pb4 bdBox mr10'>
                                    <input
                                        type="text"
                                        value={srchText}
                                        maxLength={100}
                                        className="SpoqaHanSansNeo-Medium fc505050 bd0 ls07 olN w100p h100p fs14 fcPhBDBDBD"
                                        onChange={(e) => {
                                            setSrchText(e.target.value);
                                        }}
                                        onKeyPress={(e) => {
                                            if (["Enter", "NumpadEnter"].indexOf(e.code) !== -1) {
                                                setFilterDataArr(Util.filterData(tableInfo["dataArr"], tableInfo["fldInfs"], srchText));
                                            }
                                        }}
                                        placeholder="검색어를 입력하세요."
                                    />
                                    <div className={'aiC jcC ' + (Util.isEmpty(srchText) ? "dpNone" : "dpFlx")}>
                                        <img className="csP mr4" src={require('img/common/icon_cancel_fill_18.png')} alt="icon_cancel_fill_18"
                                            onClick={() => {
                                                setSrchText("");
                                                setFilterDataArr(Util.filterData(tableInfo["dataArr"], tableInfo["fldInfs"], ""));
                                            }}
                                        />
                                        <img onClick={() => setFilterDataArr(Util.filterData(tableInfo["dataArr"], tableInfo["fldInfs"], srchText))} className='csP' src={require('img/common/icon_search20_black.png')} alt="icon_search20_black" />
                                    </div>
                                </div>
                                <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222 csP' onClick={() => {
                                    setSrchBoxVsb(false);
                                    setSrchText("");
                                    setFilterDataArr(Util.filterData(tableInfo["dataArr"], tableInfo["fldInfs"], ""));
                                }}>취소</span>
                            </div>
                        </div>
                        {tableInfo["maxRowNums"].length === 1 ? <div/> :
                            <Select
                                options={maxRowNumList}
                                value={maxRowNumList.find((c: any) => c.code === itemsPerPage)}
                                onChange={(item: any) => {
                                    setItemsPerPage(item.code);
                                }}
                                getOptionValue={(option) => option.code.toString()}
                                getOptionLabel={(option) => option.codeNm}
                                noOptionsMessage={() => "검색된 항목이 없습니다."}
                                maxMenuHeight={170}
                                menuPlacement="auto"
                                className='h28 fs14 ls1 SpoqaHanSansNeo-Medium dpFlx csP'
                                styles={{
                                    singleValue: (provided) => ({
                                        ...provided,
                                        color: "#999999",
                                    }),
                                    control: (state) => ({
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: state.menuIsOpen ? "#222222" : "#999999",
                                    }),
                                    noOptionsMessage: base => ({ ...base, fontSize: "13px" }),
                                    option: (styles: any, {data, isDisabled, isSelected, isFocused }) => ({
                                        ...styles,
                                        height: "49px",
                                        fontSize: "14px",
                                        color: optBoxFontColor(isDisabled, isSelected, isFocused),
                                        letterSpacing: "-1.1px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        // zIndex: 1,
                                        backgroundColor: optBoxBgColor(isDisabled, isSelected, isFocused),
                                        ":active": {
                                            backgroundColor: "#D7D7D7",
                                        }
                                    }),
                                    input: (base: any) => ({
                                        ...base,
                                        'input:focus': {
                                            boxShadow: 'none',
                                            border: '1px solid #60B3D1'
                                        }
                                    }),
                                    dropdownIndicator: base => ({
                                        ...base,
                                        padding: 0
                                    })
                                }}
                                isSearchable={false}
                                classNamePrefix="select"
                                components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator: CustomDropdownIndicator
                                }}
                            />
                        }
                    </div>
                </div>
                <Paper sx={[tableInfo["styleType"]["paper"], tableInfo["styleType"]["tableBorder"]]}>
                    <div className='ofxA'>
                        <TableContainer sx={{ display: !tableInfo["tableHeadVsb"] ? "none" : "block", overflow: "visible", ".MuiTable-root": { tableLayout: "fixed" }}}>
                            <Table aria-labelledby="tableTitle">
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    tableInfo={tableInfo}
                                    headCells={headCells}
                                    visible={true}
                                    chkBoxExist={chkBoxExist}
                                    rowCount={filterDataArr.length}
                                    numSelected={chkBoxExist ? tableInfo["checkedHookParam"]["checkDataArr"].length : 0}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                            </Table>
                        </TableContainer>
                        <TableContainer sx={{ height: tableInfo["bodyHeight"], overflow: "visible", ".MuiTable-root": { tableLayout: "fixed" }}}>
                            <Table aria-labelledby="tableTitle">
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    tableInfo={tableInfo}
                                    headCells={headCells}
                                    visible={false}
                                    chkBoxExist={chkBoxExist}
                                    rowCount={filterDataArr.length}
                                    numSelected={chkBoxExist ? tableInfo["checkedHookParam"]["checkDataArr"].length : 0}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody sx={tableInfo["styleType"]["tableBody"]}>
                                    {currentItems.map((row: any, index: any) => {
                                        const rendering = () => {
                                            const resEls: any[] = [];
                                            let left = 0;
                                            headCells.map((item: fldInfProps, idx: number) => {
                                                const toolTipId = tableKey + "-" + index + "-" + idx;
                                                const currLeft = left;
                                                left = (chkBoxExist ? 42 : 0) + left + (item.width || 0);
                                                if (Util.isNotEmpty(item.type)) {
                                                    if (["text", "comma", "phone", "code"].indexOf(item.type || "") !== -1) {
                                                        if (item.fldNm === undefined) {
                                                            return null;
                                                        }
                                                        let fldTxt = "";
                                                        if (item.type === "text") {
                                                            fldTxt = Util.nvl(row[item.fldNm], Util.nvl(item.dfltVal, ""));
                                                        } else if (item.type === "comma") {
                                                            fldTxt = Util.nvl(Util.comma(row[item.fldNm]), Util.nvl(item.dfltVal, ""));
                                                        } else if (item.type === "phone") {
                                                            fldTxt = Util.nvl(Util.cvtPhoneStrForm(row[item.fldNm], "-"), Util.nvl(item.dfltVal, ""));
                                                        } else if (item.type === "code") {
                                                            if (item.fldNm === undefined) {
                                                                return null;
                                                            }
                                                            if (item.codeTy === undefined) {
                                                                return null;
                                                            }
                                                            fldTxt = Util.nvl(Util.isEmpty(row[item.fldNm]) ? item.dfltVal : Util.getValToCode(item.codeTy, row[item.fldNm].toString()), "");
                                                        }
                                                        return resEls.push(
                                                            <TableCell
                                                                key={index + "-" + idx}
                                                                align={item.dataAlign as 'inherit' | 'left' | 'center' | 'right' | 'justify'}
                                                                sx={[
                                                                    tableInfo["styleType"]["tableBodyCell"],
                                                                    {
                                                                        position: idx + (chkBoxExist ? 1 : 0) <= tableInfo["fixFldCnt"] ? "sticky" : null,
                                                                        left: currLeft,
                                                                        backgroundColor: idx + (chkBoxExist ? 1 : 0) <= tableInfo["fixFldCnt"] ? "rgb(244, 248, 249)" : null
                                                                    }
                                                                ]}
                                                            >
                                                                <span
                                                                    className={item.class || ''}
                                                                    onClick={() => {
                                                                        if (item.func === undefined) {
                                                                            return;
                                                                        }
                                                                        item.func(row);
                                                                    }}
                                                                    data-tooltip-id={toolTipId}
                                                                    data-tooltip-content={fldTxt}
                                                                >
                                                                    <LinesEllipsis text={fldTxt.toString()} maxLine='1' ellipsis='...' trimRight />
                                                                    <ToolTip id={toolTipId} place='top' />
                                                                </span>
                                                            </TableCell>
                                                        );
                                                    } else if (item.type === "el") {
                                                        if (item.el === undefined) {
                                                            return null;
                                                        }
                                                        return resEls.push(
                                                            <TableCell
                                                                key={index + "-" + idx}
                                                                align={'center'}
                                                                sx={[
                                                                    tableInfo["styleType"]["tableBodyCell"],
                                                                    {
                                                                        position: idx + (chkBoxExist ? 1 : 0) <= tableInfo["fixFldCnt"] ? "sticky" : null,
                                                                        left: currLeft,
                                                                        backgroundColor: idx + (chkBoxExist ? 1 : 0) <= tableInfo["fixFldCnt"] ? "rgb(244, 248, 249)" : null
                                                                    }
                                                                ]}
                                                            >
                                                                <div className='dpFlx jcC'>
                                                                    {item.el(row)}
                                                                </div>
                                                            </TableCell>
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                } else {
                                                    return null;
                                                }
                                            });
                                            return resEls;
                                        };
                                        const isItemSelected = chkBoxExist ? tableInfo["checkedHookParam"]["checkDataArr"].indexOf(row) !== -1 : false;
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <TableRow
                                                tabIndex={-1}
                                                key={index}
                                                sx={[
                                                    { height: Util.isEmpty(tableInfo["trHeight"]) ? "" : tableInfo["trHeight"] + "px" },
                                                    Util.isNotEmpty(tableInfo["rowClickFunc"]) ? tableInfo["styleType"]["tableBodyRowHover"] : {},
                                                    focusIndex === itemOffset + "-" +index ? {backgroundColor: "rgba(0, 0, 0, 0.01)"} : {}
                                                ]}
                                                onClick={
                                                    () => {
                                                        if (Util.isNotEmpty(tableInfo["rowClickFunc"])) {
                                                            tableInfo["rowClickFunc"](row);
                                                        }
                                                        if (tableInfo["rowClickBgcFix"]) {
                                                            setFocusIndex(itemOffset + "-" +index);
                                                        }
                                                    }
                                                }
                                                // role="checkbox"
                                                aria-checked={isItemSelected}
                                                selected={isItemSelected}
                                            >
                                                {chkBoxExist ?
                                                    <TableCell padding="checkbox" sx={[
                                                        tableInfo["styleType"].tableHeadChkBoxCell,
                                                        {
                                                            position: tableInfo["fixFldCnt"] > 0 ? "sticky" : null,
                                                            left: 0,
                                                            backgroundColor: tableInfo["fixFldCnt"] > 0 ? "rgb(244, 248, 249)" : null
                                                        }
                                                    ]}>
                                                        <Checkbox
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                            onChange={() => {handleClick(row)}}
                                                        />
                                                    </TableCell>
                                                : <></>}
                                                {rendering()}
                                            </TableRow>
                                        );
                                    })}
                                    {(filterDataArr.length === 0) && (
                                        <TableRow sx={{
                                            height: Util.isEmpty(tableInfo["trHeight"]) ? "" : (tableInfo["remainArea"] ? (tableInfo["trHeight"] + 1) * itemsPerPage : tableInfo["trHeight"]) + "px"
                                        }}>
                                            <TableCell colSpan={colSpan} align='center' sx={tableInfo["styleType"]["tableBodyCell"]}>
                                                <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc666666'>조회할 자료가 없습니다.</span>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {(filterDataArr.length > 0 && emptyRowCnt > 0 && tableInfo["remainArea"]) && (
                                        <TableRow style={{ height: Util.isEmpty(tableInfo["trHeight"]) ? "" : ((tableInfo["trHeight"] + 1) * emptyRowCnt) }}>
                                            <TableCell colSpan={colSpan} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className={"dpFlx jcC h40 " + tableInfo["paginateClass"]}>
                        {/* pageCount - 총 게시글의 개수(총 row 수)
                        pageRangeDisplayed - 한 페이지에 표시할 게시글의 수
                        marginPagesDisplayed -
                        breakLabel - 페이지 수가 많을 경우 건너뛸 수 있는 버튼
                        previousLabel - 이전페이지로 가는 버튼의 value값
                        nextLabel - 다음페이지로 가는 버튼의 value값
                        onPageChange - 페이지 버튼을 눌렀을 때 일어나는 이벤트 이를 이용해 페이지 증감
                        containerClassName - css적용할 때 사용
                        activeClassName - 현재 페이지에 css처리해주기 위한 클래스명을 적으면 됨
                        previousClassName/NextClassName - 이전/다음버튼 css적용위한 클래스명을 적으면 됨 */}
                        <ReactPaginate
                            breakLabel="..."
                            previousLabel={<img className="csP" src={require('img/common/icon_chevron_pre.png')} alt="icon_chevron_pre" />}
                            nextLabel={<img className="csP" src={require('img/common/icon_chevron_next.png')} alt="icon_chevron_next" />}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            pageCount={pageCount}
                            renderOnZeroPageCount={null}
                            containerClassName='react-paginate'
                        // activeClassName={"currentPage"}
                        // previousClassName={"pageLabel-btn"}
                        // nextClassName={"pageLabel-btn"}
                        />
                    </div>
                </Paper>
            </Box>
        );
    }
}
export default MuiListFilter;