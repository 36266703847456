/*******************************************************************************
*** FindPasswd ** 패스워드 찾기
*******************************************************************************/
import React            from 'react';
import Util             from 'js/common/Util';
import TextBox          from 'js/common/ui/input/TextBox';
import NumberTextBox    from 'js/common/ui/input/NumberTextBox';
import AppContext       from 'js/common/AppContext';
import Axios            from 'js/common/Axios';
import { UseCountdown } from 'js/common/UseCountdown';
import { useNavigate }  from 'react-router-dom';

function FindPasswd() {
    const navigate  = useNavigate();
    const [findFlag  , setFindFlag  ] = React.useState<string>("E");
    const [userId    , setUserId    ] = React.useState<string>("");
    const [userNm    , setUserNm    ] = React.useState<string>("");
    const [emailAdres, setEmailAdres] = React.useState<string>("");
    const [mbtlnum   , setMbtlnum   ] = React.useState<string>("");

    const [emailCertNum      , setEmailCertNum      ] = React.useState<string>("");     // 이메일 인증번호
    const [emailCertStt      , setEmailCertStt      ] = React.useState<string>("1001"); // 이메일 인증 진행상태
    const [emailCertNumChkMsg, setEmailCertNumChkMsg] = React.useState<string>("");     // 이메일 발송완료 메시지
    const [emailCertNumElVsb , setEmailCertNumElVsb ] = React.useState<boolean>(false); // 이메일 count down 표시
    const [emailCertNumCount , setEmailCertNumCount ] = React.useState<string>("");     // 이메일 count down 시간 설정

    const [emailCertMin, emailCertSec] = UseCountdown(emailCertNumCount, "min", emailCertNumElVsb); // count down 진행

    const [phoneCertNum      , setPhoneCertNum      ] = React.useState<string>("");     // 휴대폰 인증번호
    const [phoneCertStt      , setPhoneCertStt      ] = React.useState<string>("1001"); // 휴대폰 인증 진행상태
    const [phoneCertNumChkMsg, setPhoneCertNumChkMsg] = React.useState<string>("");     // 휴대폰 발송완료 메시지
    const [phoneCertNumElVsb , setPhoneCertNumElVsb ] = React.useState<boolean>(false); // 휴대폰 count down 표시
    const [phoneCertNumCount , setPhoneCertNumCount ] = React.useState<string>("");     // 휴대폰 count down 시간 설정

    const [phoneCertMin, phoneCertSec] = UseCountdown(phoneCertNumCount, "min", phoneCertNumElVsb); // count down 진행

    // 이메일/핸드폰 인증번호 발송
    const sendCertNum = (findFlag:string) => {
        // 이름 확인
        if (Util.isEmpty(userNm)) {
            AppContext.openAlert({
                title: "이름 미입력",
                msg: "이름을 입력하세요.",
                icon: "check"
            });
            return;
        }

        // 아이디 확인
        if (Util.isEmpty(userId)) {
            AppContext.openAlert({
                title: "아이디 미입력",
                msg: "아이디를 입력하세요.",
                icon: "check"
            });
            return;
        }

        if (findFlag === "E") {
            // 이메일주소 확인
            if (Util.isEmpty(emailAdres)) {
                AppContext.openAlert({
                    title: "이메일 주소 미입력",
                    msg: "이메일을 입력하세요.",
                    icon: "check"
                });
                return;
            }

            // 이메일 주소 패턴 체크
            if (!Util.chkEmailPtn(emailAdres)) {
                AppContext.openAlert({
                    title: "이메일 주소 확인",
                    msg: "이메일 주소 형식이 맞지 않습니다.",
                    icon: "check"
                });
                return;
            }

            // 인증번호 발송 여부 체크
            if (emailCertStt === "1002") {
                AppContext.openAlert({
                    title: "이메일 인증번호 발송",
                    msg: "이메일 인증번호가 이미 발송되었습니다.",
                    icon: "check"
                });
                return;
            }
        } else if (findFlag === "H") {
            // 휴대폰 미입력
            if (Util.isEmpty(mbtlnum)) {
                AppContext.openAlert({
                    title: "휴대폰 번호 미입력",
                    msg: "휴대폰 번호를 입력하세요.",
                    icon: "check"
                });
                return;
            }

            // 휴대폰 번호 패턴 체크
            if (!Util.chkPhonePtn(Util.unHypen(mbtlnum)) || Util.unHypen(mbtlnum) === "01000000000") {  // 위케이크 임시 핸드폰 번호 제외 처리(010-0000-0000)
                AppContext.openAlert({
                    title: "휴대폰 번호 확인",
                    msg: "휴대폰 번호 형식이 맞지 않습니다.",
                    icon: "check"
                });
                return;
            }

            // 인증번호 발송 여부 체크
            if (phoneCertStt === "1002") {
                AppContext.openAlert({
                    title: "휴대폰 인증번호 발송",
                    msg: "휴대폰 인증번호가 이미 발송되었습니다.",
                    icon: "check"
                });
                return;
            }
        }

        AppContext.showSpinnerDim();
        Axios.dataAccess({
            url: "portal/auth/findUserPasswd.do",   // User 확인 후 인증번호 발송
            methodType: "post",
            paramType: "json",
            paramData: {
                  findFlag  : findFlag
                , userNm    : userNm
                , userId    : userId
                , emailAdres: emailAdres
                , mbtlnum   : mbtlnum
            },
            onSuccessFn: (res: any) => {
                AppContext.hideSpinner();
                if (res.item.rstVal !== "O") {
                    AppContext.openAlert({
                        title: "회원정보 재확인",
                        msg: res.item.rstMsg,
                        icon: "check"
                    });
                    return;
                }

                if (findFlag === "E") {
                    setEmailCertNumElVsb(true); // 카운트 다운 표시
                    setEmailCertNumChkMsg("인증번호를 발송했습니다. (유효시간 5분)\n인증번호가 오지 않으면 입력하신 정보가 정확한지 확인해주세요.");
                    const currDateStr = Util.getSpecificDate("-", "sec", 300, "SS", "");
                    if (currDateStr === undefined) {
                        return;
                    }
                    setEmailCertNumCount(currDateStr);  // 유효시간 셋팅
                    setEmailCertStt("1002"); // 인증 진행상태 : 인증번호 발송(1002)
                } else if (findFlag === "H") {
                    setPhoneCertNumElVsb(true); // 카운트 다운 표시
                    setPhoneCertNumChkMsg("인증번호를 발송했습니다. (유효시간 5분)\n인증번호가 오지 않으면 입력하신 정보가 정확한지 확인해주세요.");
                    const currDateStr = Util.getSpecificDate("-", "sec", 300, "SS", "");
                    if (currDateStr === undefined) {
                        return;
                    }
                    setPhoneCertNumCount(currDateStr); // 유효시간 셋팅
                    setPhoneCertStt("1002"); // 인증 진행상태 : 인증번호 발송(1002)
                }
            }
        });
    }

    // 확인 - 인증번호 확인 후 비밀번호 입력 페이지로
    const chkCertNum = (certTypeCode: string) => {
        if (certTypeCode === "1001") {  // 핸드폰 체크
            // 인증번호 발송 확인
            if (phoneCertStt === "1001") {
                AppContext.openAlert({
                    title: "인증번호 미발송",
                    msg: "휴대폰 인증번호를 발송한 후 인증번호를 입력하세요.",
                    icon: "check"
                });
                return;
            }

            // 핸드폰 인증번호 입력 시간 확인
            if ((phoneCertMin === 0 || phoneCertMin === "0") && (phoneCertSec === 0 || phoneCertSec === "0")) {
                AppContext.openAlert({
                    title: "인증번호 입력 시간 초과",
                    msg: "인증번호 입력시간이 초과하였습니다.\n새로고침 후에 다시 시도하시기 바랍니다.",
                    icon: "check"
                });
                return;
            }

            // 핸드폰 인증번호 입력
            if (Util.isEmpty(phoneCertNum) || (phoneCertNum.length !== 6)) {
                AppContext.openAlert({
                    title: "휴대폰 인증번호 확인",
                    msg: "인증번호를 6자리로 입력하세요.",
                    icon: "check"
                });
                return;
            }
        } else if (certTypeCode === "1002") {   // 이메일 체크
            // 인증번호 발송 확인
            if (emailCertStt === "1001") {
                AppContext.openAlert({
                    title: "인증번호 미발송",
                    msg: "이메일 인증번호를 발송한 후 인증번호를 입력하세요.",
                    icon: "check"
                });
                return;
            }

            // 이메일 인증번호 입력 시간 확인
            if ((emailCertMin === 0 || emailCertMin === "0") && (emailCertSec === 0 || emailCertSec === "0")) {
                AppContext.openAlert({
                    title: "인증번호 입력 시간 초과",
                    msg: "인증번호 입력시간이 초과하였습니다.\n새로고침 후에 다시 시도하시기 바랍니다.",
                    icon: "check"
                });
                return;
            }

            // 이메일 인증번호 입력
            if (Util.isEmpty(emailCertNum) || (emailCertNum.length !== 6)) {
                AppContext.openAlert({
                    title: "이메일 인증번호 확인",
                    msg: "인증번호를 6자리로 입력하세요.",
                    icon: "check"
                });
                return;
            }
        }

        AppContext.showSpinnerDim();
        Axios.dataAccess({
            url: "portal/join/chkCertNumber.do",
            methodType: "post",
            paramType: "json",
            paramData: {
                    certTypeCode: certTypeCode                                            // 인증 구분
                  , certRcvNm   : certTypeCode === "1002" ? emailAdres   : mbtlnum        // 받는 사람 메일/핸드폰
                  , certNumber  : certTypeCode === "1002" ? emailCertNum : phoneCertNum   // 인증번호
            },
            onSuccessFn: (res: any) => {
                AppContext.hideSpinner();
                if (res.item !== 1) {
                    if (certTypeCode === "1001") {
                        AppContext.openAlert({
                            title: "휴대폰 인증번호 미일치",
                            msg  : "휴대폰 인증번호가 일치하지 않습니다.",
                            icon : "check"
                        });
                        return;
                    } else if (certTypeCode === "1002") {
                        AppContext.openAlert({
                            title: "이메일 인증번호 미일치",
                            msg: "이메일 인증번호가 일치하지 않습니다.",
                            icon: "check"
                        });
                        return;
                    }
                    return;
                }

                if (certTypeCode === "1001") {
                    navigate("/portal/inNewPasswd", {
                        state: {
                            mbtlnum: mbtlnum
                          , userNm : userNm
                          , userId : userId
                        }
                    });
                } else if (certTypeCode === "1002") {
                    navigate("/portal/inNewPasswd", {
                        state: {
                            emailAdres: emailAdres
                          , userNm    : userNm
                          , userId    : userId
                        }
                    });
                }
            }
        });
    }

    return (
        <div className='w500'>
            <div className='w100p h160 dpFlx aiC jcC'>
                <span className="SpoqaHanSansNeo-Bold fs36 ls1 fcBlack">비밀번호 찾기</span>
            </div>
            <div className="w600 m0auto pb40">
                <div className="dpFlx w100p h56 bdBox aiC">
                    <div className={'flx1 h100p dpFlx aiC jcC bdb2 ' + (findFlag === "E" ? "bdc17A840" : "bdcWhite")} onClick={() => {
                        setFindFlag("E");
                    }}>
                        <div className='dpFlx aiC jcC w130 pb10 csP'>
                            <span className={'SpoqaHanSansNeo-Medium fs16 fw500 ls08 ' + (findFlag === "E" ? "fc17A840" : "fc999999")}>이메일로 찾기</span>
                        </div>
                    </div>
                    <div className={'flx1 h100p dpFlx aiC jcC bdb2 ' + (findFlag === "H" ? "bdc17A840" : "bdcWhite")} onClick={() => {
                        setFindFlag("H");
                    }}>
                        <div className='dpFlx aiC jcC w130 pb10 csP'>
                            <span className={'SpoqaHanSansNeo-Medium fs16 fw500 ls08 ' + (findFlag === "H" ? "fc17A840" : "fc999999")}>휴대폰으로 찾기</span>
                        </div>
                    </div>
                </div>
            </div>

            {   // 이메일로 찾기
                findFlag === "E" ?
                    <div className='w100p dpFlx aiC jcC mb100'>
                        <div className='w460'>

                            <div className='h45 dpFlx aiE pb5 bdBox'>
                                <span className="SpoqaHanSansNeo-Bold fs16 ls08 fc222222">이름</span>
                            </div>
                            <div className='dpFlx aiC jcSb'>
                                <TextBox
                                    value={userNm}
                                    height={60}
                                    fontSize={16}
                                    fontFamily='SpoqaHanSansNeo-Regular'
                                    maxLength={30}
                                    placeholder="이름을 입력하세요"
                                    onChangeFunc={(text: string) => setUserNm(text)}
                                    inputClassName='bgcWhite ls08'
                                    color="#222222"
                                    offColor="#999999"
                                    className='bd1 pl15 pr20'
                                    mode={emailCertNumElVsb || phoneCertNumElVsb ? "disabled" : ""}
                                    iconEl={
                                        <img
                                            onClick={() => setUserNm("")}
                                            className={'csP ' + (Util.isEmpty(userNm) || emailCertNumElVsb || phoneCertNumElVsb ? "dpNone" : "")}
                                            src={require('img/common/icon_cancel_fill_18.png')}
                                            alt="icon_cancel_fill_18"
                                        />
                                    }
                                />
                            </div>

                            <div className='h45 dpFlx aiE pb5 bdBox'>
                                <span className="SpoqaHanSansNeo-Bold fs16 ls08 fc222222">아이디</span>
                            </div>
                            <div className='dpFlx aiC jcSb'>
                                <TextBox
                                    value={userId}
                                    height={60}
                                    fontSize={16}
                                    fontFamily='SpoqaHanSansNeo-Regular'
                                    maxLength={30}
                                    placeholder="아이디를 입력하세요"
                                    onChangeFunc={(text: string) => setUserId(text)}
                                    inputClassName='bgcWhite ls08'
                                    color="#222222"
                                    offColor="#999999"
                                    className='bd1 pl15 pr20'
                                    mode={emailCertNumElVsb || phoneCertNumElVsb ? "disabled" : ""}
                                    iconEl={
                                        <img
                                            onClick={() => setUserId("")}
                                            className={'csP ' + (Util.isEmpty(userId) || emailCertNumElVsb || phoneCertNumElVsb ? "dpNone" : "")}
                                            src={require('img/common/icon_cancel_fill_18.png')}
                                            alt="icon_cancel_fill_18"
                                        />
                                    }
                                />
                            </div>

                            <div className='h45 dpFlx aiE pb5 bdBox'>
                                <span className="SpoqaHanSansNeo-Bold fs16 ls08 fc222222">이메일 주소</span>
                            </div>
                            <div className='dpFlx aiC jcSb'>
                                <TextBox
                                    type="email"
                                    value={emailAdres}
                                    width={330}
                                    height={60}
                                    fontSize={16}
                                    fontFamily='SpoqaHanSansNeo-Regular'
                                    maxLength={30}
                                    placeholder="이메일주소를 입력하세요."
                                    onChangeFunc={(text: string) => setEmailAdres(text)}
                                    inputClassName='bgcWhite ls08'
                                    color="#222222"
                                    offColor="#999999"
                                    className='bd1 pl14 pr20'
                                    mode={emailCertNumElVsb ? "disabled" : ""}
                                    iconEl={
                                        <img
                                            onClick={() => setEmailAdres("")}
                                            className={'csP ' + (Util.isEmpty(emailAdres) || emailCertNumElVsb ? "dpNone" : "")}
                                            src={require('img/common/icon_cancel_fill_18.png')}
                                            alt="icon_cancel_fill_18"
                                        />
                                    }
                                />
                                <div className='w120 h60 dpFlx aiC jcC bgc17A840 csP' onClick={() => sendCertNum("E")}>
                                    <span className="SpoqaHanSansNeo-Medium fs16 fcWhite">인증번호 발송</span>
                                </div>
                            </div>

                            <div className='h45 dpFlx aiE pb5 bdBox'>
                                <span className="SpoqaHanSansNeo-Bold fs16 ls08 fc222222">인증번호</span>
                            </div>
                            <div className='w100p h60 bd1 bdcDDDDDD ofH fBd-2B2B2B dpFlx aiC jcSb bdBox pl15 pr15 mb5'>
                                <NumberTextBox
                                    value={emailCertNum}
                                    onChange={(data: string) => {
                                        setEmailCertNum(data);
                                    }}
                                    borderClass="bd0 bgcWhite w384 h100p"
                                    maxLength={6}
                                    inputClass="fs16 SpoqaHanSansNeo-Regular fc222222"
                                    placeholder="인증번호를 입력하세요."
                                />
                                <img
                                    onClick={() => setEmailCertNum("")}
                                    className={'mr10 csP ' + (Util.isEmpty(emailCertNum) ? "dpNone" : "")}
                                    src={require('img/common/icon_cancel_fill_18.png')}
                                    alt="icon_cancel_fill_18"
                                />
                                <span className={"SpoqaHanSansNeo-Medium fs16 ls08 fc222222 " + (!emailCertNumElVsb ? "dpNone" : "")}>{emailCertMin}:{emailCertSec}</span>
                            </div>
                            <span className="SpoqaHanSansNeo-Bold fs13 ls065 fc17A840 wsPw">{emailCertNumChkMsg}</span>
                            <div className='w100p h62 bgc17A840 mt30 dpFlx aiC jcC csP' onClick={() => chkCertNum("1002")}>
                                <span className="SpoqaHanSansNeo-Bold fs18 ls08 fcWhite">확인</span>
                            </div>
                        </div>
                    </div>

                // 핸드폰으로 찾기
                : findFlag === "H" ?
                    <div className='w100p dpFlx aiC jcC mb100'>
                        <div className='w460'>

                            <div className='h45 dpFlx aiE pb5 bdBox'>
                                <span className="SpoqaHanSansNeo-Bold fs16 ls08 fc222222">이름</span>
                            </div>
                            <div className='dpFlx aiC jcSb'>
                                <TextBox
                                    value={userNm}
                                    height={60}
                                    fontSize={16}
                                    fontFamily='SpoqaHanSansNeo-Regular'
                                    maxLength={30}
                                    placeholder="이름을 입력하세요"
                                    onChangeFunc={(text: string) => setUserNm(text)}
                                    inputClassName='bgcWhite ls08'
                                    color="#222222"
                                    offColor="#999999"
                                    className='bd1 pl15 pr20'
                                    mode={emailCertNumElVsb || phoneCertNumElVsb ? "disabled" : ""}
                                    iconEl={
                                        <img
                                            onClick={() => setUserNm("")}
                                            className={'csP ' + (Util.isEmpty(userNm) || emailCertNumElVsb || phoneCertNumElVsb ? "dpNone" : "")}
                                            src={require('img/common/icon_cancel_fill_18.png')}
                                            alt="icon_cancel_fill_18"
                                        />
                                    }
                                />
                            </div>

                            <div className='h45 dpFlx aiE pb5 bdBox'>
                                <span className="SpoqaHanSansNeo-Bold fs16 ls08 fc222222">아이디</span>
                            </div>
                            <div className='dpFlx aiC jcSb'>
                                <TextBox
                                    value={userId}
                                    height={60}
                                    fontSize={16}
                                    fontFamily='SpoqaHanSansNeo-Regular'
                                    maxLength={30}
                                    placeholder="아이디를 입력하세요"
                                    onChangeFunc={(text: string) => setUserId(text)}
                                    inputClassName='bgcWhite ls08'
                                    color="#222222"
                                    offColor="#999999"
                                    className='bd1 pl15 pr20'
                                    mode={emailCertNumElVsb || phoneCertNumElVsb ? "disabled" : ""}
                                    iconEl={
                                        <img
                                            onClick={() => setUserId("")}
                                            className={'csP ' + (Util.isEmpty(userId) || emailCertNumElVsb || phoneCertNumElVsb ? "dpNone" : "")}
                                            src={require('img/common/icon_cancel_fill_18.png')}
                                            alt="icon_cancel_fill_18"
                                        />
                                    }
                                />
                            </div>

                            <div className='h45 dpFlx aiE pb5 bdBox'>
                                <span className="SpoqaHanSansNeo-Bold fs16 ls08 fc222222">휴대폰</span>
                            </div>
                            <div className='dpFlx aiC jcSb'>
                                <TextBox
                                    type="phone"
                                    value={Util.setPhoneNum(mbtlnum)}
                                    width={330}
                                    height={60}
                                    fontSize={16}
                                    fontFamily='SpoqaHanSansNeo-Regular'
                                    maxLength={30}
                                    placeholder="휴대폰번호를 입력하세요."
                                    onChangeFunc={(text: string) => setMbtlnum(Util.unHypen(text))}
                                    inputClassName='bgcWhite ls08'
                                    color="#222222"
                                    offColor="#999999"
                                    className='bd1 pl14 pr20'
                                    mode={phoneCertNumElVsb ? "disabled" : ""}
                                    iconEl={
                                        <img
                                            onClick={() => setMbtlnum("")}
                                            className={'csP ' + (Util.isEmpty(mbtlnum) || phoneCertNumElVsb ? "dpNone" : "")}
                                            src={require('img/common/icon_cancel_fill_18.png')}
                                            alt="icon_cancel_fill_18"
                                        />
                                    }
                                />
                                <div className='w120 h60 dpFlx aiC jcC bgc17A840 csP' onClick={() => sendCertNum("H")}>
                                    <span className="SpoqaHanSansNeo-Medium fs16 fcWhite">인증번호 발송</span>
                                </div>
                            </div>

                            <div className='h45 dpFlx aiE pb5 bdBox'>
                                <span className="SpoqaHanSansNeo-Bold fs16 ls08 fc222222">인증번호</span>
                            </div>
                            <div className='w100p h60 bd1 bdcDDDDDD ofH fBd-2B2B2B dpFlx aiC jcSb bdBox pl15 pr15 mb5'>
                                <NumberTextBox
                                    value={phoneCertNum}
                                    onChange={(data: string) => {
                                        setPhoneCertNum(data);
                                    }}
                                    borderClass="bd0 bgcWhite w384 h100p"
                                    maxLength={6}
                                    inputClass="fs16 SpoqaHanSansNeo-Regular fc222222"
                                    placeholder="인증번호를 입력하세요."
                                />
                                <img
                                    onClick={() => setPhoneCertNum("")}
                                    className={'mr10 csP ' + (Util.isEmpty(phoneCertNum) ? "dpNone" : "")}
                                    src={require('img/common/icon_cancel_fill_18.png')}
                                    alt="icon_cancel_fill_18"
                                />
                                <span className={"SpoqaHanSansNeo-Medium fs16 ls08 fc222222 " + (!phoneCertNumElVsb ? "dpNone" : "")}>{phoneCertMin}:{phoneCertSec}</span>
                            </div>
                            <span className="SpoqaHanSansNeo-Bold fs13 ls065 fc17A840 wsPw">{phoneCertNumChkMsg}</span>
                            <div className='w100p h62 bgc17A840 mt30 dpFlx aiC jcC csP' onClick={() => chkCertNum("1001")}>
                                <span className="SpoqaHanSansNeo-Bold fs18 ls08 fcWhite">확인</span>
                            </div>
                        </div>
                    </div>

                : <></>
            }
        </div>
    )
}
export default FindPasswd;