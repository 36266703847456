import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { addrSearchResultState } from 'js/common/Atom';
import Axios from 'js/common/Axios';
import AppContext from 'js/common/AppContext';
import Util from 'js/common/Util';
import { AddrSearchResult } from 'js/common/types/types';

export function useDongHoSelect() {
    const [addrSearchResult, setAddrSearchResult] = useRecoilState(addrSearchResultState);

    const [aptDongList, setAptDongList] = useState<string[]>([]);
    const [aptHoList, setAptHoList] = useState<any[]>([]);
    const [multiHoList, setMultiHoList] = useState<string[]>([]); // 한개동-다세대 호 목록 조회결과
    //const [selectedAptDong, setSelectedAptDong] = useState<string>('');
    //const [selectedAptHo, setSelectedAptHo] = useState<string>('');
    //const [dongDataLength, setDongDataLength] = useState<number>(0);

    useEffect(() => {
        if (!addrSearchResult.addr) return;

        // 선택한 아파트의 동 목록 조회
        getAptDong(
            addrSearchResult.addr.admCd +
                ',' +
                addrSearchResult.addr.rnMgtSn +
                ',' +
                addrSearchResult.addr.udrtYn +
                ',' +
                addrSearchResult.addr.buldMnnm +
                ',' +
                addrSearchResult.addr.buldSlno,
        );
    }, [addrSearchResult.addr]);

    useEffect(() => {
        //if (!addrSearchResult.addr || addrSearchResult.dong === '') return;
        if (!addrSearchResult.addr) return;

        // 선택한 아파트 동의 호수 목록 조회
        const apiParam: string =
            addrSearchResult.addr.admCd +
            ',' +
            addrSearchResult.addr.rnMgtSn +
            ',' +
            addrSearchResult.addr.udrtYn +
            ',' +
            addrSearchResult.addr.buldMnnm +
            ',' +
            addrSearchResult.addr.buldSlno;

        /*if (addrSearchResult.addr.bdKdcd !== '0') {
            if (addrSearchResult.bdType === 'dongHo' && addrSearchResult.dong !== '') {
                getAptHo(addrSearchResult.dong, apiParam);
            } else if (addrSearchResult.bdType === 'multiHo') {
                getMultiHoList(apiParam);
            }
        }*/

        // 선택한 주소의 bdKdcd와 관계 없이 동/호수 목록 조회
        if (aptDongList.length < 1) {
            // 동 목록이 없으면
            getMultiHoList(apiParam);
        } else {
            // 동 목록이 있으면 동 정보 선택되었을 때 호 목록 가져오기
            if (addrSearchResult.dong !== '') {
                getAptHo(addrSearchResult.dong, apiParam);
            }
        }
    }, [addrSearchResult.dong, addrSearchResult.addr, addrSearchResult.bdType, aptDongList, setAddrSearchResult]);

    useEffect(() => {
        if (!addrSearchResult.addr) return;

        // 동호수 입력 분기를 위한 건물타입 분류
        if (addrSearchResult.addr.bdKdcd === '1' && aptDongList.length >= 1) {
            setAddrSearchResult((prev: AddrSearchResult) => ({ ...prev, bdType: 'dongHo' }));
        } else if (addrSearchResult.addr.bdKdcd === '1' && aptDongList.length < 1) {
            setAddrSearchResult((prev: AddrSearchResult) => ({ ...prev, bdType: 'multiHo' }));
        } else if (addrSearchResult.addr.bdKdcd === '0') {
            setAddrSearchResult((prev: AddrSearchResult) => ({ ...prev, bdType: 'none' }));
        } else {
            setAddrSearchResult((prev: AddrSearchResult) => ({ ...prev, bdType: '' }));
        }
    }, [addrSearchResult.addr, aptDongList, setAddrSearchResult]);

    /*useEffect(() => {
        if (!addrSearchResult.addr) return;

        const apiParam =
            addrSearchResult.addr.admCd +
            ',' +
            addrSearchResult.addr.rnMgtSn +
            ',' +
            addrSearchResult.addr.udrtYn +
            ',' +
            addrSearchResult.addr.buldMnnm +
            ',' +
            addrSearchResult.addr.buldSlno;

        AppContext.showSpinner();
        Axios.dataAccess({
            url: 'common/mobile/callApi.do',
            methodType: 'post',
            paramType: 'object',
            paramData: {
                apiName: 'openapi',
                tailUrl: 'addr/donglist',
                paramStr: apiParam,
            },
            onSuccessFn: (res: any) => {
                setDongDataLength(res.item.rtn_list.length);
                getAptDong(apiParam);
                getAptHo(addrSearchResult.dong, apiParam);
                getMultiHoList(apiParam);
                AppContext.hideSpinner();
            },
        });
    }, []);*/

    // [아파트] 동 선택 목록 조회
    const getAptDong = (param: string) => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: 'common/mobile/callApi.do',
            methodType: 'post',
            paramType: 'object',
            paramData: {
                apiName: 'openapi',
                tailUrl: 'addr/donglist',
                paramStr: param,
            },
            onSuccessFn: (res: any) => {
                if (Util.isEmpty(res.item.rtn_list)) {
                    AppContext.hideSpinner();
                    return;
                }
                setAptDongList(res.item.rtn_list);
                AppContext.hideSpinner();
            },
        });
    };

    // [아파트] 호 선택 목록 조회
    const getAptHo = (userDong: string, apiParam: string) => {
        let paramStr = '';
        if (userDong === undefined) {
            paramStr = apiParam;
        } else {
            paramStr = apiParam + ',' + encodeURI(userDong);
        }
        AppContext.showSpinner();
        Axios.dataAccess({
            url: 'common/mobile/callApi.do',
            methodType: 'post',
            paramType: 'object',
            paramData: {
                apiName: 'openapi',
                tailUrl: 'addr/holist',
                paramStr: paramStr,
            },
            onSuccessFn: (res: any) => {
                if (Util.isEmpty(res.item.rtn_list)) {
                    AppContext.hideSpinner();
                    return;
                }
                setAptHoList(res.item.rtn_list);
                AppContext.hideSpinner();
            },
        });
    };

    // [다세대] 호 선택 목록 조회
    const getMultiHoList = (apiParam: string) => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: 'common/mobile/callApi.do',
            methodType: 'post',
            paramType: 'object',
            paramData: {
                apiName: 'openapi',
                tailUrl: 'addr/holist',
                paramStr: apiParam,
            },
            onSuccessFn: (res: any) => {
                if (Util.isEmpty(res.item.rtn_list[0])) {
                    AppContext.hideSpinner();
                    return;
                }
                setMultiHoList(res.item.rtn_list);
                AppContext.hideSpinner();
            },
        });
    };

    const onSelectAptDong = (selected: string) => {
        //setSelectedAptDong(selected);
        setAddrSearchResult((prev: AddrSearchResult) => ({
            ...prev,
            dong: selected,
        }));
    };

    const onSelectAptHo = (selected: string) => {
        //setSelectedAptHo(selected);
        setAddrSearchResult((prev: AddrSearchResult) => ({
            ...prev,
            ho: selected,
        }));
    };

    const onChangeBuildingName = (name: string) => {
        setAddrSearchResult((prev: AddrSearchResult) => ({
            ...prev,
            bdNm: name,
        }));
    };

    return {
        aptDongList,
        aptHoList,
        multiHoList,
        getMultiHoList,
        onSelectAptDong,
        onSelectAptHo,
        onChangeBuildingName,
    };
}
