import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

type Props = {
  placeholder: string;
  options: SelectOption[];
  selectedValue: string;
  disabled?: boolean;
  handleSelect: (selected: SelectOption) => void;
};

export interface SelectOption {
  value: string;
  label: string;
}

function SelectBox({
  placeholder,
  options,
  selectedValue,
  disabled,
  handleSelect,
}: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(
    null
  );

  useEffect(() => {
    if (selectedValue === "") {
      setSelectedOption(null);
    } else {
      const option = options.find(
        (element: SelectOption) => element.value === selectedValue
      );
      if (option) {
        setSelectedOption(option);
      } else {
        setSelectedOption(null);
      }
    }
  }, [selectedValue, options]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    } else {
      return;
    }
  };

  const handleClickOption = (option: SelectOption) => {
    handleSelect(option);
    setIsOpen(false);
  };

  return (
    <SelectBoxContainer ref={dropdownRef}>
      <SelectButton
        onClick={handleOpen}
        $selected={selectedOption ? true : false}
      >
        {selectedOption ? (
          <div className="selected">
            <span>{selectedOption.label}</span>
            <span className="icon">
              <CheckOutlinedIcon fontSize="small" />
            </span>
          </div>
        ) : (
          <>
            <span>{placeholder}</span>
            <KeyboardArrowDownOutlinedIcon fontSize="small" />
          </>
        )}
      </SelectButton>
      {!disabled && isOpen && (
        <OptionList>
          {options.map((option: SelectOption) => {
            return (
              <li
                className={
                  selectedOption
                    ? selectedOption.value === option.value
                      ? "selected"
                      : ""
                    : ""
                }
                onClick={() => handleClickOption(option)}
                key={option.value}
              >
                <span>{option.label}</span>
                <span className="icon">
                  <CheckOutlinedIcon fontSize="small" />
                </span>
              </li>
            );
          })}
        </OptionList>
      )}
    </SelectBoxContainer>
  );
}

const SelectBoxContainer = styled.div`
  width: 100%;
  height: auto;
`;

const SelectButton = styled.button<{ $selected: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: ${(props) => (props.$selected ? "5px" : "12px 16px")};
  border-radius: ${(props) => (props.$selected ? "10px" : "8px")};
  border: ${(props) =>
    props.$selected ? "none" : `1px solid ${props.theme.color.nGray70}`};
  background-color: ${(props) =>
    props.$selected ? props.theme.color.pGreenLight03 : "white"};

  font-family: "Spoqa Han Sans Neo";
  font-weight: 400;
  font-size: 1.4em;
  line-height: 1.32;
  letter-spacing: -0.05em;
  color: ${(props) => props.theme.color.nGray50};

  .selected {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 12px 16px;
    border: 1px solid ${(props) => props.theme.color.pGreenLight02};
    border-radius: 8px;
    color: ${(props) => props.theme.color.sNavy};
    background-color: white;

    .icon {
      color: ${(props) => props.theme.color.pGreen};
      height: 14px;
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const OptionList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100%;
  margin: 5px 0;
  padding: 12px 16px;
  border: 1px solid ${(props) => props.theme.color.nGray70};
  border-radius: 8px;
  background-color: white;

  font-family: "Spoqa Han Sans Neo";
  font-weight: 400;
  font-size: 1.4em;
  line-height: 1.32;
  letter-spacing: -0.05em;
  color: ${(props) => props.theme.color.sNavy};

  li {
    width: 100%;
    padding: 8px 15px;
    border-radius: 8px;

    .icon {
      display: none;
    }

    &.selected {
      display: flex;
      justify-content: space-between;
      align-items: center;

      background-color: ${(props) => props.theme.color.pGreenLight04};
      color: ${(props) => props.theme.color.pGreenDark02};

      .icon {
        display: block;
        line-height: 1.32;
      }
    }
  }
`;

export default SelectBox;
