/*******************************************************************************
 *** AcqstaxCalc ** 취득세 결과표 * 취득세 세액 산출
*******************************************************************************/
import React from "react";
import { useRecoilValue } from "recoil";
import * as Atom from "js/common/Atom";
import Axios from "js/common/Axios";
import Util from "js/common/Util";
import AppContext from "js/common/AppContext";
import Modal from "js/common/ui/modal/Modal";
import AcquisitionTaxRate from "./help/AcquisitionTaxRate";

interface AcqstaxCalcProps {
    setAcqstaxCalcModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    assetsSn: number | undefined
    setAcqstaxCalcModalVsb: React.Dispatch<React.SetStateAction<boolean>>
}

const AcqstaxCalc = ({ setAcqstaxCalcModalOpen, assetsSn, setAcqstaxCalcModalVsb }: AcqstaxCalcProps) => {
    const hshldSn = useRecoilValue(Atom.hshldSn);
    const [calcInfo, setCalcInfo] = React.useState<any | undefined>();

    const [acquisitionTaxRateModalOpen, setAcquisitionTaxRateModalOpen] = React.useState<boolean>(false);
    const [acqsCause, setAcqsCause] = React.useState<any>();

    React.useEffect(() => {
        Axios.dataAccess({
            url: "api/system/broker/callApi.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                apiName: "taxcalc",
                tailUrl: "acqstax/calctax",
                paramStr: hshldSn + "," + assetsSn
            },
            onSuccessFn: (res: any) => {
                if (Util.isEmpty(res.item.rtn_list)) {
                    AppContext.openAlert({
                        title: "비과세 대상",
                        msg: "취득세 과세대상이 아닙니다.",
                        icon: "check"
                    });
                    setAcqstaxCalcModalOpen(false);
                    return;
                }
                setCalcInfo(res.item.rtn_list[0]);
                setAcqstaxCalcModalVsb(true);
            },
            onFailedFn: () => {
                setAcqstaxCalcModalOpen(false);
            }
        });
    }, [setAcqstaxCalcModalOpen, hshldSn, assetsSn, setAcqstaxCalcModalVsb]);

    return (
        <React.Fragment>
            <div className='w1200 ofA'>
                <div className='dpFlx aiC mb10'>
                    <img className="pr10" src={require('img/system/assets/icon_listup.png')} alt="icon_listup" />
                    <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 fw500'>취득세 과세대상 내역</span>
                </div>
                {Util.isNotEmpty(calcInfo) ?
                    <React.Fragment>
                        <table className="forest-form-table td-fs15 td-fm-SpoqaHanSansNeo-Regular td-fc666666 td-ls075 mb20">
                            <colgroup>
                                <col className="w220" />
                                <col />
                            </colgroup>
                            <tbody>
                                <tr className="h44">
                                    <th>소재지</th>
                                    <td>{calcInfo.rdnm_adres}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className={
                            "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD h44 mb20 " +
                            "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 " +
                            "td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls1 td-fc666666 td-bdcDDDDDD"
                        }>
                            <colgroup>
                                <col className="w160" />
                                <col className="w120" />
                                <col className="w120" />
                                <col />
                                <col className="w160" />
                                <col className="w160" />
                                <col className="w160" />
                                <col className="w80" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>취득물건</th>
                                    <th>취득일</th>
                                    <th>면적(㎡)</th>
                                    <th>종류</th>
                                    <th>용도</th>
                                    <th>취득원인</th>
                                    <th>과세표준(원)</th>
                                    <th>지분율</th>
                                </tr>
                                <tr>
                                    <td>{Util.getValToCode("1006", calcInfo.estate_detail_se)}</td>
                                    <td>{Util.cvtDateStrForm(calcInfo.acqs_de, "-")}</td>
                                    <td>{Number(calcInfo.ar).toFixed(2)}</td>
                                    <td>{Util.getValToCode("1007", calcInfo.estate_fom)}</td>
                                    <td>{Util.getValToCode("1008", calcInfo.estate_prpos)}</td>
                                    <td>{Util.getValToCode("1035", calcInfo.acqs_cause)}</td>
                                    <td>{Util.comma(calcInfo.aqcs_amt)}</td>
                                    <td>{calcInfo.qota_rate} %</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="forest-form-table h44 td-fs15 td-fm-SpoqaHanSansNeo-Regular td-fc666666 td-ls075 mb20">
                            <colgroup>
                                <col className="w150" />
                                <col className="w150" />
                                <col className="w150" />
                                <col />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>보유주택수</th>
                                    <td className="taC">{calcInfo.house_num}</td>
                                    <th>취득세구분</th>
                                    <td>{calcInfo.tax_desc}</td>
                                </tr>
                            </tbody>
                        </table>
                    </React.Fragment>
                : <></>}
                {Util.isNotEmpty(calcInfo) ?
                    <table className={
                        "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD h44 mb40 " +
                        "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 th-ls1 " +
                        "td-p20 td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls075 td-fc666666 td-bdcDDDDDD"
                    }>
                        <colgroup>
                            <col className="w180" />
                            <col className="w180" />
                            <col className="w140" />
                            <col className="w120" />
                            <col className="w120" />
                            <col className="w180" />
                            <col className="w180" />
                            <col className="w180" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th colSpan={2} className="bdr1">세목</th>
                                <th>과세표준(원)</th>
                                <th>지분율</th>
                                <th>
                                    <div className="dpFlx aiC jcC"
                                        onClick={()=> {
                                            setAcqsCause(calcInfo.acqs_cause);
                                            setAcquisitionTaxRateModalOpen(true);
                                        }}
                                    >
                                        세율(%)
                                        <img src={require('img/common/icon_info_fill_20.png')} alt="icon_info_fill_20" className='ml5 csP'
                                        />
                                    </div>
                                </th>
                                <th>산출세액(원)</th>
                                <th>감면세액(원)</th>
                                <th>신고세액합계(원)</th>
                            </tr>
                            <tr>
                                <th rowSpan={3} className="bdr1">신고세액</th>
                                <th className="bdr1">취득세</th>
                                <td rowSpan={3} className="taR">{Util.comma(calcInfo.aqcs_amt)}</td>
                                <td rowSpan={3} className="taR">{(calcInfo.qota_rate)}%</td>
                                <td className="taC">{Number(calcInfo.aqs_tax_rate)}%</td>
                                <td className="taR">{Util.comma(calcInfo.aqs_tax_amt)}</td>
                                <td className="taR">{Util.comma(calcInfo.rdc_aqs_tax_amt)}</td>
                                <td className="taR">{Util.comma(calcInfo.real_aqs_tax_amt)}</td>
                            </tr>
                            <tr>
                                <th className="bdr1">지방교육세</th>
                                <td className="taC">{Number(calcInfo.edu_tax_rate)}%</td>
                                <td className="taR">{Util.comma(calcInfo.edu_tax_amt)}</td>
                                <td className="taR">{Util.comma(calcInfo.rdc_edu_tax_amt)}</td>
                                <td className="taR">{Util.comma(calcInfo.real_edu_tax_amt)}</td>
                            </tr>
                            <tr>
                                <th className="bdr1">농어촌특별세</th>
                                <td className="taC">{Number(calcInfo.farm_tax_rate)}%</td>
                                <td className="taR">{Util.comma(calcInfo.farm_tax_amt)}</td>
                                <td className="taR">{Util.comma(calcInfo.rdc_farm_tax_amt)}</td>
                                <td className="taR">{Util.comma(calcInfo.real_farm_tax_amt)}</td>
                            </tr>
                            <tr>
                                <th colSpan={2} className="bdr1"><span className="fs20">합계</span></th>
                                <td colSpan={2} className="taC"><span className="fs18 fc222222 SpoqaHanSansNeo-Medium ls1">{Util.comma(calcInfo.aqcs_rate_amt)}</span></td>
                                <td className="taC"><span className="fs18 fc222222 SpoqaHanSansNeo-Medium ls1">{(Number(calcInfo.aqs_tax_rate) + Number(calcInfo.edu_tax_rate) + Number(calcInfo.farm_tax_rate)).toFixed(3)}</span></td>
                                <td className="taR"><span className="fs20 fc222222 SpoqaHanSansNeo-Medium ls1">{Util.comma(calcInfo.total_tax_amt)}</span></td>
                                <td className="taR"><span className="fs20 fc222222 SpoqaHanSansNeo-Medium ls1">{Util.comma(calcInfo.total_rdc_amt)}</span></td>
                                <td className="taR"><span className="fs20 fc222222 SpoqaHanSansNeo-Medium ls1">{Util.comma(calcInfo.total_aqs_tax_amt)}</span></td>
                            </tr>
                        </tbody>
                    </table>
                : <></>}
                <div className='w100p dpFlx aiC jcC'>
                    <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => {
                        setAcqstaxCalcModalOpen(false);
                        setAcqstaxCalcModalVsb(false);
                    }}>
                        <span className='SpoqaHanSansNeo-Medium fs16 fcWhite fw500'>확인</span>
                    </div>
                </div>
            </div>

            {/* 양도세 세율 도움말 */}
            <Modal modalOpen={acquisitionTaxRateModalOpen} setModalOpen={setAcquisitionTaxRateModalOpen} title="취득세 세율" dim={true} visible={true}>
                <div className={'ofA ofxH ' + ((acqsCause === "1002" || acqsCause === "1011" || acqsCause === "1012") ? "h500" : "")}>
                    <AcquisitionTaxRate acqsCause={acqsCause} />
                </div>
            </Modal>
        </React.Fragment>
    )
}
export default AcqstaxCalc;