/*******************************************************************************
 You are strictly prohibited to copy, disclose, distribute, modify, or use
 this program in part or as a whole without the prior written consent of
 CallTax. CallTax, owns the intellectual property rights in and to this program.
 (Copyright © 2023 CallTax. All Rights Reserved| Confidential)

 Specification
    1. Program ID    : UserDialog.tsx
    2. Program Title : 사용자 정보 조회 Modal
    3. Created by    : JH Kim
    4. Create Date   : 2023.
    5. Reference     :
*******************************************************************************/
import AppContext from "js/common/AppContext";
import Axios from "js/common/Axios";
import Util from "js/common/Util";
import SelectBox from "js/common/ui/input/SelectBox";
import TextBox from "js/common/ui/input/TextBox";
import React from "react";

interface UserDialogProps {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    userSn?: number
    setUserSelTime: React.Dispatch<React.SetStateAction<Date>>
}
const UserDialog = ({setModalOpen, userSn, setUserSelTime}: UserDialogProps) => {
    const [emailAdres, setEmailAdres] = React.useState<string>();
    const [userNm, setUserNm] = React.useState<string>();
    const [mbtlnum, setMbtlnum] = React.useState<string>();
    const [userSttCode, setUserSttCode] = React.useState<string>("");
    const [isAdmin, setIsAdmin] = React.useState<string>();
    const [userId, setUserId] = React.useState<string>();   // 아이디

    // 회원정보 저장
    const updUserInfo = () => {
        AppContext.openAlert({
            title: "회원정보 저장",
            msg: "회원정보를 저장하시겠습니까?",
            icon: "check",
            confirmText: "저장",
            handleConfirm: () => {
                AppContext.showSpinner();
                Axios.dataAccess({
                    url: "admin/user/updUserInfo.do",
                    methodType: "post",
                    paramType: "json",
                    paramData: {
                        userSn: userSn,
                        emailAdres: emailAdres,
                        userNm: userNm,
                        mbtlnum: mbtlnum,
                        isAdmin: Number(isAdmin)
                    },
                    onSuccessFn: () => {
                        setModalOpen(false);
                        setUserSelTime(new Date());
                        AppContext.hideSpinner();
                        AppContext.openAlert({
                            title: "회원정보 저장완료",
                            msg: "회원정보가 저장되었습니다.",
                            icon: "check"
                        });
                        return;
                    }
                });
            }
        });
    }

    // 비밀번호 초기화
    const resetUserPassword = () => {
        AppContext.openAlert({
            title: "비밀번호 초기화",
            msg: "회원의 비밀번호를 초기화하시겠습니까?",
            icon: "check",
            confirmText: "초기화",
            handleConfirm: () => {
                AppContext.showSpinner();
                Axios.dataAccess({
                    url: "admin/user/resetUserPassword.do",
                    methodType: "post",
                    paramData: {
                        userSn: userSn
                    },
                    onSuccessFn: () => {
                        setModalOpen(false);
                        setUserSelTime(new Date());
                        AppContext.hideSpinner();
                        AppContext.openAlert({
                            title: "비밀번호 초기화 완료",
                            msg: "회원의 비밀번호가 초기화 되었습니다.",
                            icon: "check"
                        });
                        return;
                    }
                });
            }
        });
    }

    // 사용승인 처리
    const pmsUserInfo = () => {
        AppContext.openAlert({
            title: "회원 사용승인",
            msg: userNm + " 회원을 사용승인 하시겠습니까?",
            icon: "check",
            confirmText: "사용승인",
            handleConfirm: () => {
                AppContext.showSpinner();
                Axios.dataAccess({
                    url: "admin/user/pmsUserInfo.do",
                    methodType: "post",
                    paramData: {
                        userSn: userSn
                    },
                    onSuccessFn: () => {
                        setModalOpen(false);
                        setUserSelTime(new Date());
                        AppContext.hideSpinner();
                        AppContext.openAlert({
                            title: "사용승인 완료",
                            msg: userNm + " 회원이 사용승인 되었습니다.",
                            icon: "check"
                        });
                        return;
                    }
                });
            }
        });
    }

    // 사용정지 처리
    const banUserInfo = () => {
        AppContext.openAlert({
            title: "회원 사용정지",
            msg: userNm + " 회원을 사용정지 하시겠습니까?",
            icon: "check",
            confirmText: "사용정지",
            handleConfirm: () => {
                AppContext.showSpinner();
                Axios.dataAccess({
                    url: "admin/user/banUserInfo.do",
                    methodType: "post",
                    paramData: {
                        userSn: userSn
                    },
                    onSuccessFn: () => {
                        setModalOpen(false);
                        setUserSelTime(new Date());
                        AppContext.hideSpinner();
                        AppContext.openAlert({
                            title: "사용정지 완료",
                            msg: userNm + " 회원이 사용정지 처리되었습니다.",
                            icon: "check"
                        });
                        return;
                    }
                });
            }
        });
    }

    // 회원 상세정보 조회
    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "admin/user/selUserInfo.do",
            methodType: "post",
            paramData: {
                userSn: userSn
            },
            onSuccessFn: (res: any) => {
                setUserId(res.item.userId);
                setEmailAdres(res.item.emailAdres);
                setUserNm(res.item.userNm);
                setMbtlnum(res.item.mbtlnum);
                setUserSttCode(res.item.userSttCode);
                setIsAdmin(res.item.isAdmin ? "1" : "0");
                AppContext.hideSpinner();
            }
        });
    }, [userSn]);

    return (
        <React.Fragment>
            <div className='w700 h100p bdBox dpFlx aiC'>
                {/* Left Part */}
                <div className='w50p'>
                    <div className='h40 dpFlx pb10'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>아이디</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <span className="SpoqaHanSansNeo-Regular fs15 fc666666">{userId}</span>
                        </div>
                    </div>
                    <div className='dpFlx h40 pb10'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>이름</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={userNm}
                                width={200}
                                height={40}
                                fontSize={15}
                                maxLength={10}
                                placeholder="사용자 이름을 입력하세요."
                                onChangeFunc={(text: string) => setUserNm(text)}
                                className="bd1"
                                inputClassName='pl10 pr10 bgcWhite'
                            />
                        </div>
                    </div>
                    <div className='h40 dpFlx pb10'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>휴대폰번호</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                type="phone"
                                value={Util.setPhoneNum(mbtlnum)}
                                width={200}
                                height={40}
                                fontSize={14}
                                maxLength={13}
                                placeholder="휴대폰 번호를 입력하세요."
                                onChangeFunc={(text: string) => setMbtlnum(text)}
                                inputClassName='pl10 pr10 bgcWhite'
                            />
                        </div>
                    </div>
                </div>

                {/* Right Part */}
                <div className='w50p pl30'>
                    <div className='h40 dpFlx pb10'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>회원상태</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <span className="SpoqaHanSansNeo-Regular fs15 fc666666">{Util.getValToCode("1031", userSttCode)}</span>
                        </div>
                    </div>
                    <div className='h40 dpFlx pb10'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>사용자유형</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <SelectBox
                                value={isAdmin}
                                codeArray={Util.getCodeArray("1024")}
                                placeholder="선택"
                                width={200}
                                height={40}
                                fontSize={15}
                                paddingRight={5}
                                onChangeFunc={(data: string) => setIsAdmin(data)}
                                border="1px solid #dddddd"
                            />
                        </div>
                    </div>
                    <div className='h40 dpFlx pb10'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>이메일 주소</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <span className="SpoqaHanSansNeo-Regular fs15 fc666666">{emailAdres}</span>
                        </div>
                    </div>

                </div>
            </div>
            <div className='w100p dpFlx aiC jcSb mt40'>
                <div className="h56 dpFlx">
                    <div className={"w111 aiC jcC bd1 bdcDDDDDD bdBox csP " + (userSttCode === "1001" || userSttCode === "1010" ? "dpFlx" : "dpNone")} onClick={pmsUserInfo}>
                        <span className="SpoqaHanSansNeo-Medium fs16 fc222222">사용승인</span>
                    </div>
                    <div className={"w111 aiC jcC bd1 bdcDDDDDD bdBox csP " + (userSttCode === "1002" ? "dpFlx" : "dpNone")} onClick={banUserInfo}>
                        <span className="SpoqaHanSansNeo-Medium fs16 fc222222">사용정지</span>
                    </div>
                    <div className="w150 aiC jcC bdt1 bdr1 bdb1 bdcDDDDDD bdBox csP dpFlx" onClick={resetUserPassword}>
                        <span className="SpoqaHanSansNeo-Medium fs16 fc222222">비밀번호 초기화</span>
                    </div>
                </div>
                <div className="h56 dpFlx">
                    <div className="w210 aiC jcC bgc17A840 csP dpFlx" onClick={updUserInfo}>
                        <span className="SpoqaHanSansNeo-Medium fs16 fcWhite">저장</span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default UserDialog;