import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UseInterval from 'js/common/UseInterval';
import { useRecoilState, useSetRecoilState } from 'recoil';
import * as Atom from 'js/common/Atom';
import Util from 'js/common/Util';
import Axios from 'js/common/Axios';
import AppContext from 'js/common/AppContext';
import Modal from 'js/common/ui/modal/Modal';

const TokenCheck = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [hshldSn, setHshldSn] = useRecoilState(Atom.hshldSn);
    const setHshldNm = useSetRecoilState(Atom.hshldNm);
    const setIsAdmin = useSetRecoilState(Atom.isAdmin);
    const setIsSysAdmin = useSetRecoilState(Atom.isSysAdmin);
    const setHshldCnt = useSetRecoilState(Atom.hshldCnt);
    const [userInfo, setUserInfo] = useRecoilState(Atom.userInfo);
    const [remainTimeText, setRemainTimeText] = React.useState<string>('');
    const [timeOutModalOpen, setTimeOutModalOpen] = React.useState<boolean>(false);

    UseInterval(() => {
        const tokenCrtDt = localStorage.getItem('tokenCrtDt');
        if (tokenCrtDt === null) {
            // 토큰정보 없으면
            if (
                location.pathname !== '/' &&
                !location.pathname.startsWith('/portal', 0) &&
                !location.pathname.startsWith('/api', 0) &&
                !location.pathname.startsWith('/simpletax', 0)
            ) {
                // 전체권한 페이지 아닐 때에만
                AppContext.goLogout(); // 로그인페이지로 리다이렉트
            }
            return;
        }
        if (location.pathname.startsWith('/portal/login', 0)) {
            // 토큰 발급 후에도 계속 로그인 페이지면
            if (Util.isNotEmpty(userInfo.userSn)) {
                navigate('/system/hshld'); // 납세자 페이지로 이동
            }
        }

        // wecake 로그인
        if (location.pathname.startsWith('/portal/wecake', 0)) {
            // 토큰 발급 후에도 계속 wecaek 페이지면
            if (Util.isNotEmpty(userInfo.userSn)) {
                navigate('/system/hshld'); // 납세자 페이지로 이동
            }
        }

        const storeHshldSn = localStorage.getItem('hshldSn');
        if (Util.isNotEmpty(storeHshldSn)) {
            // 스토리지에 납세자 키가 존재하면
            if (hshldSn !== storeHshldSn) {
                // 전역변수 값과 스토리지 값이 상이하면
                setHshldSn(Number(storeHshldSn)); // state 세팅
                setHshldNm(localStorage.getItem('hshldNm'));
            }
        } else {
            setHshldSn(null);
            setHshldNm(null);
        }
        if (Util.isEmpty(userInfo.userSn)) {
            Axios.dataAccess({
                url: 'system/home/get-base-info.do',
                methodType: 'post',
                onSuccessFn: (res: any) => {
                    localStorage.setItem('isAdmin', res.item.userInfo.isAdmin ? '1' : '0');
                    setUserInfo(res.item.userInfo);
                    setIsAdmin(res.item.userInfo.isAdmin);
                    setIsSysAdmin(res.item.userInfo.isSysAdmin);
                    setHshldCnt(res.item.hshldCnt);
                },
            });
        }
        const nowDt = new Date(Util.getSpecificDate('-', 'day', 0, 'SS', '') as string);
        const calTime = +nowDt - +new Date(tokenCrtDt);
        const remainTime = AppContext.refreshTokenExpireTime * 60000 - calTime; // 접근토큰 만료 계산
        if (remainTime < 600000 && remainTime > 0) {
            // 로그아웃 임박
            console.log('로그아웃까지 ' + Util.cvtSecToTime(remainTime / 1000) + '초 남음..');
            setRemainTimeText(Util.cvtSecToTime(remainTime / 1000));
            setTimeOutModalOpen(true);
        } else if (remainTime <= 0) {
            // 만료
            Axios.removeAuthInfo();
            setTimeOutModalOpen(false);
            navigate('/portal/login'); // 로그인 페이지로 이동
        } else {
            setTimeOutModalOpen(false);
        }
    }, 1000);

    return (
        <Modal
            modalOpen={timeOutModalOpen}
            setModalOpen={setTimeOutModalOpen}
            title="접속시간 만료"
            dim={true}
            visible={true}
        >
            <span className="SpoqaHanSansNeo-Regular fs17 ls09 fc222222">
                <span className="fwB">{remainTimeText}</span> 후 로그아웃 됩니다.
            </span>
            <br />
            <br />
            <span className="SpoqaHanSansNeo-Regular fs17 ls09 fc222222">
                사용 시간을 연장하시려면 하단의 사용시간 연장 버튼을 클릭하세요.
            </span>
            <div className="w100p dpFlx aiC jcC pt40">
                <button
                    className="w210 h56 bgc17A840 dpFlx aiC jcC csP bdN"
                    onClick={() => {
                        AppContext.showSpinnerDim();
                        Axios.tokenReIssuance({});
                    }}
                >
                    <span className="SpoqaHanSansNeo-Medium fs16 ls07 fcWhite">사용시간 연장</span>
                </button>
            </div>
        </Modal>
    );
};
export default TokenCheck;
