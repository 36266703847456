/***********************************************************************************
*** Law13 ** 조세특례제한법 98조 미분양주택에 대한 과세특례 Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law13Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}

export default function Law13({ setModalOpen, setLawModalTitle, callBack, callMode }: Law13Props) {
    React.useEffect(() => {
        setLawModalTitle("조세특례제한법 98조 미분양주택에 대한 과세특례");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className='w100p hAuto ofA mb30'>
                <table className={
                    'forest-list-table bdt1 bdb1 bdcDDDDDD ' +
                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075'
                }>
                   <colgroup>
                        <col className='w60'></col>
                        <col className='w100'></col>
                        <col className='w100'></col>
                        <col className='w160'></col>
                        <col className='w80'></col>
                        <col className='w60'></col>
                        <col className='w100'></col>
                        <col className='w120'></col>
                        <col className='w120'></col>
                        <col className='w120'></col>
                        <col className='w120'></col>
                    </colgroup>
                    <tbody>
                        <tr className='h40'>
                            <th rowSpan={2}>구분</th>
                            <th rowSpan={2}>주택구분</th>
                            <th rowSpan={2}>주택규모</th>
                            <th rowSpan={2}>취득시기</th>
                            <th rowSpan={2}>지역기준</th>
                            <th colSpan={5}>추가질문</th>
                            <th rowSpan={2}>혜택</th>
                        </tr>
                        <tr>
                            <th>최초계약</th>
                            <th>보유기간 및 임대기간</th>
                            <th>미분양시점</th>
                            <th>입주사실</th>
                            <th className='bdr1-i bdcDDDDDD-i'>필수서류</th>
                        </tr>
                        <tr>
                            <th rowSpan={2}>개별사항</th>
                            <td rowSpan={2}>분양권에 의한 아파트 중 미분양</td>
                            <td rowSpan={2}>국민주택규모 이하</td>
                            <td>1995.11.1. ~ 1997.12.31.<br/>(계약체결 + 계약금지급포함)</td>
                            <td rowSpan={2}>서울특별시 외</td>
                            <td rowSpan={2}>여</td>
                            <td rowSpan={2}>보유 5년, 임대 5년 이상</td>
                            <td>1995.10.31. 현재 미분양주택</td>
                            <td rowSpan={2}>주택이 완공된 후 다른 자가 입주한 사실이 없는 주택</td>
                            <td rowSpan={2}>지자체 확인서 및 지자체 날인 매매계약서</td>
                            <td rowSpan={2}>양도소득세율 20%와 종합소득세율 중 선택 가능</td>
                        </tr>
                        <tr>
                            <td>1998.3.1.~1998.12.31. (계약체결+계약금지급포함)</td>
                            <td className='bdr1-i bdcDDDDDD-i'>1998.2.28. 현재 미분양주택</td>
                        </tr>
                        <tr className='h110'>
                            <th>공통사항</th>
                            <td className='taL pt15 pb15 pl15' colSpan={10}>
                                1. 비과세 판정시 주택수 제외<br/>
                                2. 다주택 중과배제(유예)<br/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}