import React from 'react';
import AppContext from 'js/common/AppContext';
import Util from 'js/common/Util';
import ImageRadioBox from 'js/common/ui/input/ImageRadioBox';
import Axios from 'js/common/Axios';
import { useRecoilValue } from 'recoil';
import * as Atom from "js/common/Atom";

interface TrsftaxChbtQestnProps {
    moveStep: Function
    hshldMrrgAt: string
}
const TrsftaxChbtQestn = ({moveStep, hshldMrrgAt}: TrsftaxChbtQestnProps) => {
    const hshldSn = useRecoilValue(Atom.hshldSn);

    interface QestnProps {
        assetsQestnSn: number
        assetsQestnTy: string
        qestnText: string
        answerTySn: number
        answerCodeTy: string
        answerText: string
        sortOrdr: number
    }
    const [hshldQestnList, setHshldQestnList] = React.useState<QestnProps[]>([]); // 양도세 납세자 질문

    const getAnswerValue = React.useCallback((assetsQestnSn: number) => {
        if (hshldQestnList.length === 0) {
            return "";
        }
        for (const hshldQestnInfo of hshldQestnList) {
            if (hshldQestnInfo.assetsQestnSn === assetsQestnSn) {
                return hshldQestnInfo.answerText;
            }
        }
        return "";
    }, [hshldQestnList]);

    const setAnswerValue = React.useCallback((assetsQestnSn: number, answerText: string) => {
        const tempHshldQestnList: QestnProps[] = [];
        for (const hshldQestnInfo of hshldQestnList) {
            if (hshldQestnInfo.assetsQestnSn === assetsQestnSn) {
                hshldQestnInfo.answerText = answerText;
            }
            tempHshldQestnList.push(hshldQestnInfo);
        }
        setHshldQestnList(tempHshldQestnList);
    }, [hshldQestnList]);

    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/qestn/selHshldQestnList.do",
            methodType: "post",
            paramType: "json",
            paramData: {
                hshldSn: hshldSn,
                assetsQestnTys: ["1002"]
            },
            onSuccessFn: (res: any) => {
                setHshldQestnList(res.list);
                AppContext.hideSpinner();
            }
        });
    }, [hshldSn]);

    return (
        <div className='w800'>
            <div className='dpFlx aiL flxdCol'>
                {hshldQestnList && hshldQestnList.map((hshldQestnInfo: any, idx: number) => {
                    let viewFg = false;
                    if (hshldQestnInfo.assetsQestnSn === 58) {
                        viewFg = true;
                    } else if (hshldQestnInfo.assetsQestnSn === 59) {
                        if (getAnswerValue(58) === "Y") {
                            viewFg = true;
                        }
                    } else if ([76, 77].indexOf(hshldQestnInfo.assetsQestnSn) !== -1) {
                        if (getAnswerValue(58) === "Y" && getAnswerValue(59) === "Y") {
                            viewFg = true;
                        }
                    } else if (hshldQestnInfo.assetsQestnSn === 78) {
                        if (getAnswerValue(58) === "Y" && getAnswerValue(59) === "Y" && getAnswerValue(76) === "1001" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(77)) !== -1) {
                            viewFg = true;
                        }
                    } else if (hshldQestnInfo.assetsQestnSn === 79) {
                        if (getAnswerValue(58) === "Y" && getAnswerValue(59) === "Y" && getAnswerValue(76) === "1002" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(77)) !== -1) {
                            viewFg = true;
                        }
                    } else if (hshldQestnInfo.assetsQestnSn === 80) {
                        if (getAnswerValue(58) === "Y" && getAnswerValue(59) === "Y" && getAnswerValue(76) === "1003" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(77)) !== -1) {
                            viewFg = true;
                        }
                    } else if (hshldQestnInfo.assetsQestnSn === 81) {
                        if (getAnswerValue(58) === "Y" && getAnswerValue(59) === "Y" && getAnswerValue(76) === "1003" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(77)) !== -1 && getAnswerValue(80) === "1001") {
                            viewFg = true;
                        }
                    } else if (hshldQestnInfo.assetsQestnSn === 82) {
                        if (getAnswerValue(58) === "Y" && getAnswerValue(59) === "Y" && getAnswerValue(76) === "1003" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(77)) !== -1 && getAnswerValue(80) === "1002") {
                            viewFg = true;
                        }
                    } else if (hshldQestnInfo.assetsQestnSn === 83) {
                        if (getAnswerValue(58) === "Y" && getAnswerValue(59) === "Y" && getAnswerValue(76) === "1004" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(77)) !== -1) {
                            viewFg = true;
                        }
                    } else if (hshldQestnInfo.assetsQestnSn === 84) {
                        if (getAnswerValue(58) === "Y" && getAnswerValue(59) === "Y" && getAnswerValue(76) === "1005" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(77)) !== -1) {
                            viewFg = true;
                        }
                    }
                    if (!viewFg) {
                        return (<div key={idx}></div>)
                    }
                    return (
                        <div key={idx} className={idx === 0 ? '' : 'mt10'}>
                            <span className='fs14 ls07 SpoqaHanSansNeo-Medium fc222222'>{hshldQestnInfo.qestnText}</span>
                            <div className='h40 dpFlx aiC'>
                                <ImageRadioBox
                                    codeList={Util.getCodeArray(hshldQestnInfo.answerCodeTy)}
                                    value={hshldQestnInfo.answerText}
                                    btnClass="w24 h24 pr5"
                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                    interval={20}
                                    onChange={(data: string) => {
                                        setAnswerValue(hshldQestnInfo.assetsQestnSn, data);
                                    }}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="dpFlx w100p jcC mt40">
                <div className="w210 csP dpFlx aiC jcC bgcF6F6F6 h56" onClick={() => {
                    if (hshldMrrgAt === "Y") {
                        moveStep(2);
                    } else {
                        moveStep(1);
                    }
                }}>
                    <span className="SpoqaHanSansNeo-Medium fs16 fc222222">이전</span>
                </div>
                <div className="w210 bgc17A840 csP dpFlx aiC jcC h56" onClick={() => {
                    interface qestnAnswersProps {
                        assetsQestnSn: number
                        answerText: string
                    }
                    const qestnAnswers: qestnAnswersProps[] = [];
                    hshldQestnList.forEach((hshldQestnInfo: QestnProps) => {
                        if (
                            hshldQestnInfo.assetsQestnSn === 58 ||
                            (hshldQestnInfo.assetsQestnSn === 59 && getAnswerValue(58) === "Y") ||
                            ([76, 77].indexOf(hshldQestnInfo.assetsQestnSn) !== -1 && getAnswerValue(58) === "Y" && getAnswerValue(59) === "Y") ||
                            (hshldQestnInfo.assetsQestnSn === 78 && getAnswerValue(58) === "Y" && getAnswerValue(59) === "Y" && getAnswerValue(76) === "1001" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(77)) !== -1) ||
                            (hshldQestnInfo.assetsQestnSn === 79 && getAnswerValue(58) === "Y" && getAnswerValue(59) === "Y" && getAnswerValue(76) === "1002" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(77)) !== -1) ||
                            (hshldQestnInfo.assetsQestnSn === 80 && getAnswerValue(58) === "Y" && getAnswerValue(59) === "Y" && getAnswerValue(76) === "1003" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(77)) !== -1) ||
                            (hshldQestnInfo.assetsQestnSn === 81 && getAnswerValue(58) === "Y" && getAnswerValue(59) === "Y" && getAnswerValue(76) === "1003" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(77)) !== -1 && getAnswerValue(80) === "1001") ||
                            (hshldQestnInfo.assetsQestnSn === 82 && getAnswerValue(58) === "Y" && getAnswerValue(59) === "Y" && getAnswerValue(76) === "1003" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(77)) !== -1 && getAnswerValue(80) === "1002") ||
                            (hshldQestnInfo.assetsQestnSn === 83 && getAnswerValue(58) === "Y" && getAnswerValue(59) === "Y" && getAnswerValue(76) === "1004" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(77)) !== -1) ||
                            (hshldQestnInfo.assetsQestnSn === 84 && getAnswerValue(58) === "Y" && getAnswerValue(59) === "Y" && getAnswerValue(76) === "1005" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(77)) !== -1)
                        ) {
                            if (Util.isEmpty(hshldQestnInfo.answerText)) {
                                AppContext.openAlert({
                                    title: "답변 선택",
                                    msg: "답변을 선택하세요.",
                                    icon: "check"
                                });
                                return;
                            }
                            qestnAnswers.push({assetsQestnSn: hshldQestnInfo.assetsQestnSn, answerText: hshldQestnInfo.answerText});
                        }
                    });
                    Axios.dataAccess({
                        url: "system/qestn/insHshldQestnList.do",
                        methodType: "post",
                        paramType: "json",
                        paramData: {
                            hshldSn: hshldSn,
                            assetsQestnTys: ["1002"],
                            qestnAnswers: qestnAnswers
                        },
                        onSuccessFn: () => {
                            moveStep(4);
                        }
                    });
                }}>
                    <span className="SpoqaHanSansNeo-Medium fs16 fcWhite">세액 계산하기</span>
                </div>
            </div>
        </div>
    )
}
export default TrsftaxChbtQestn;