import React from "react";
import HomeIcon from '@mui/icons-material/Home';
import Util from "js/common/Util";
import AppContext from "js/common/AppContext";
import DatePickerBox from "js/common/ui/input/DatePickerBox";
import ImageRadioBox from "js/common/ui/input/ImageRadioBox";

interface Qestn10Param {
    setQestn10Open: React.Dispatch<React.SetStateAction<boolean>>
    setAcqsHouseAt: React.Dispatch<React.SetStateAction<string>>
    qestn10AnswerText: string
    setQestn10AnswerText: React.Dispatch<React.SetStateAction<string>>
    qestn12AnswerText: string
    setQestn12AnswerText: React.Dispatch<React.SetStateAction<string>>
}
const Qestn10Modal = ({ setQestn10Open, setAcqsHouseAt, qestn10AnswerText, setQestn10AnswerText, qestn12AnswerText, setQestn12AnswerText }: Qestn10Param) => {
    const [qestn12Vsb, setQestn12Vsb] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (qestn10AnswerText === "Y") {
            setQestn12Vsb(true);
        } else {
            setQestn12Vsb(false);
        }
    }, [qestn10AnswerText]);

    return (
        <div className="w600 SpoqaHanSansNeo-Regular">
            <span className="fs15 lh30">1. 다음의 부득이한 사유의 발생으로 취득한 주택</span><br/>
            <span className="fs13 ls1">
                ① 「초·중등교육법」에 의한 학교(유치원, 초등학교, 중학교를 제외한다) 및 고등교육법에 의한 학교에의 취학<br/>
                ② 직장의 변경이나 전근 등 근무상 형편<br/>
                ③ 1년 이상의 치료나 요양을 필요로 하는 질병의 치료 또는 요양<br/>
                ④ 「학교폭력예방 및 대책에 관한 법률」에 따른 학교폭력으로 인한 전학(같은 법에 따른 학교 폭력대책자치위원회가 피해학생에게 전학이 필요하다고 인정하는 경우에 한한다)<br/><br/>
            </span>
            <span className="fs15 lh30">2. 세대전원의 주거이전</span><br/>
            <span className="fs13 ls1">
                세대전원(세대일부가 취학, 근무 등으로 함께 이전하지 못하는 경우는 인전됨)이 부득이한 사유가 발생한 다른 시·군으로 주거를 이전하여야 한다.<br/><br/>
            </span>
            <span className="fs15 lh30">3. 비수도권 소재</span><br/><br/>
            <span className="fs15 lh30">4. 양도기한</span><br/>
            <span className="fs13 ls1">부득이한 사유가 해소된 날부터 3년 이내 일반주택을 양도하는 경우에 한한다.</span><br/><br/>
            <span className="fs15 lh30">5. 제출서류</span><br/>
            <span className="fs13 ls1">재학증명서·재직증명서·요양증명서 등 해당사실을 증명하는 서류를 제출하여야 한다.</span><br/><br/>
            <div className="dpFlx aiC mt20 mb10">
                <HomeIcon style={{ fontSize: 17, color: 'black', paddingRight: 5 }} />
                <span className="fs14 ls1">위의 조건을 만족하는 주택입니까?</span>
            </div>
            <ImageRadioBox
                codeList={Util.getCodeArray("1036")}
                value={qestn10AnswerText}
                btnClass="w24 h24 pr5"
                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                interval={20}
                onChange={(item: string) => {
                    setQestn10AnswerText(item);
                    if (item === "Y") {
                        setQestn12Vsb(true);
                    } else {
                        setQestn12Vsb(false);
                    }
                }}
            />
            <div className={qestn12Vsb ? "mt20" : "dpNone"}>
                <div className="dpFlx aiC mb10 mb5">
                    <HomeIcon style={{ fontSize: 17, color: 'black', paddingRight: 5 }} />
                    <span className="fs14 ls1">부득이한 사유가 해소된 시기는 언제입니까?</span>
                </div>
                <DatePickerBox value={qestn12AnswerText} width={250} height={40} fontSize={15} textAlign="left" onChange={(data: string) => setQestn12AnswerText(Util.dateToString(data, ""))} />
            </div>
            <div className='w100p dpFlx aiC jcC pt40'>
                <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => {
                    if (qestn10AnswerText === "Y") {
                        if (Util.isEmpty(qestn12AnswerText)) {
                            AppContext.openAlert({
                                title: "해소일자 선택",
                                msg: "부득이한 사유로 취득한 주택에 해당하는 경우 해소일자를 선택해야 합니다.",
                                icon: "check"
                            });
                            return;
                        }
                    } else {
                        setAcqsHouseAt("N");
                    }
                    setQestn10Open(false);
                }}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>확인</span>
                </div>
            </div>
        </div>
    )
}
export default Qestn10Modal;