import AppContext from "js/common/AppContext";
import Axios from "js/common/Axios";
import { GiftTaxParamProps, Step } from "js/common/types/types";
import {useState, useReducer } from "react";
import GiftTaxCalcResult from "../GiftTaxCalcResult";
import { giftTaxStatesReducer, initialState } from "./giftTaxStatesReducer";
import { useGiftCalcSteps } from "./useGiftCalcSteps";
import { Body, MainContainer } from "js/mobile/common/layout";
import Header from "./header";
import BasicInfo from "./BasicInfo";
import CalcStepButton from "js/mobile/common/CalcStepButton";
import CalcStep from "js/mobile/common/CalcStep";
import ExtraInfo from "./ExtraInfo";

function GiftTaxCalc() {
    const [showCalcResult, setShowCalcResult] = useState<boolean>(false);
    const [calcResult, setCalcResult] = useState<any | undefined>();  // 증여세 계산결과
    const [giftTaxState, dispatch] = useReducer(giftTaxStatesReducer, initialState);

    const { 
          giftStepList
        , currentGiftStep
        , handleClickBackStep
        , handleClickNextStep
        , handleClickReset
        , backDisabled
        , nextDisabled
        , isLastStep
        , initGiftStep
    } = useGiftCalcSteps(giftTaxState, dispatch);    
      
    const usingStepList = giftStepList.filter((step: Step) => step.useYn);
    const currentStepIndex = usingStepList.findIndex((step: Step) => step.selected);

    // Next Button
    const onClickNext = () => {
        if (isLastStep) {
          transferTaxCalc(giftTaxState.inputValue);
        } else {
          handleClickNextStep();
        }
    };

    // 증여세 세액 계산
    const transferTaxCalc = (inputValue: GiftTaxParamProps) => {
        Axios.dataAccess({
            url: "common/mobile/callApi.do",
            methodType: "post",
            paramType: "object",
            paramData: {
            apiName: "taxcalc",
            tailUrl: "simpletax/gifttax",
            paramStr: "G" 
                    + "?donor="           + inputValue.donor              // 01. 증여자
                    + "&gift_prop="       + inputValue.giftProp           // 02. 증여 재산가액 
                    + "&debt_assum="      + inputValue.debtAssum          // 03. 채무인수 여부 
                    + "&debt_amt="        + inputValue.debtAmt            // 04. 채무액 
                    + "&pre_gift="        + inputValue.preGift            // 05. 증여자 사전증여 유무 
                    + "&pre_gift_prop="   + inputValue.preGiftProp        // 06. 사전증여 재산가액 
                    + "&pre_gift_tax="    + inputValue.preGiftTax         // 07. 사전증여 세액 
                    + "&donee_minor="     + inputValue.doneeMinor         // 08. 수증자 미성년자 여부 
                    + "&basic_dedu="      + inputValue.basicDedu          // 09. 기본공제 
                    + "&marriage_dedu="   + inputValue.marriageDedu       // 10. 혼인공제 
                    + "&childbirth_dedu=" + inputValue.childbirthDedu     // 11. 출산공제                
            },
            onSuccessFn: (res: any) => {
                if (res.item.rtn_list[0].gift_tax_base === "0") {
                    // 양도차익이 zero인 경우
                    setShowCalcResult(false);
                    AppContext.openAlert({
                        title: "과세 대상 여부",
                        msg: "증여세 납부 대상이 아닙니다.",
                        icon: "check"
                    });
                    return;
                }

                //증여세 세액 계산 결과
                setCalcResult(res.item.rtn_list[0]);
                setShowCalcResult(true);
            },
            onFailedFn: () => {
                setShowCalcResult(false);
            },
        });
    };

    // 계산 다시하기(세액 결과표 하단)
    const handleClickBackInResult = () => {
        setCalcResult(null);
        setShowCalcResult(false);
    
        initGiftStep();
        dispatch({
            type: "INIT_BASIC_INFO"
          , payload: ""
        });
      };    

    if (showCalcResult) {
        return (
            <GiftTaxCalcResult
                calcResult={calcResult}
                inputParams={giftTaxState.inputValue}
                onClickBack={handleClickBackInResult}   // 세액 결과표 계산 다시하기
            />
        );
    } else {
        return (
            <Body>
                <Header
                    totalStepCount={usingStepList.length}
                    currentStep={currentStepIndex + 1}
                    onClickBack={() => console.info(">>> back")}
                />

                <CalcStep stepInfoList={usingStepList.filter((step: Step) => step.useYn)} />
                
                <MainContainer>
                    {currentGiftStep && currentGiftStep.stepId === "GIFT01" && (
                        <BasicInfo
                            inputValue    = {giftTaxState.inputValue}
                            inputVisible  = {giftTaxState.inputVisible}
                            inputDisabled = {giftTaxState.inputDisabled}
                            onUpdateInput = {dispatch}
                        />
                    )}
                    {currentGiftStep && currentGiftStep.stepId === "GIFT02" && (
                        <ExtraInfo
                            inputValue    = {giftTaxState.inputValue}
                            inputVisible  = {giftTaxState.inputVisible}
                            inputDisabled = {giftTaxState.inputDisabled}
                            onUpdateInput = {dispatch}
                        />
                    )}
                    <CalcStepButton
                        onClickBack  = {handleClickBackStep}    // Back Button
                        onClickNext  = {onClickNext}            // Next Button
                        onClickReset = {handleClickReset}       // 선택 최기화
                        backDisabled = {backDisabled}           // Back Button 비활성화
                        nextDisabled = {nextDisabled}           // Next Button 비활성화
                        isLastStep   = {isLastStep}             // 마지막 단계인가?
                    />
                </MainContainer>
            </Body>
        );
    }
}    
export default GiftTaxCalc;