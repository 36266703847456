import styled from "styled-components";

import {
  TranTaxParamProps,
  TranTaxParamVisible,
  Code,
  TranTaxParamDisabled,
} from "js/common/types/types";
import { MainSectionContainer, InputContainer } from "js/mobile/common/layout";
import RealEstateItem from "js/mobile/common/RealEstateItem";
import Util from "js/common/Util";
import { TranTaxAction } from "./tranTaxStatesReducer";
import SelectBox, { SelectOption } from "../common/SelectBox";

interface RealEstateType {
  estateSe: string;
  name: string;
  desc: string;
}

const realEstateDesc: Record<string, string> = {
  "201": "주택별 취득원인, 공공데이터, 조정대상지역 등을 반영하여 계산합니다.",
  "202": "분양권의 취득정보, 양도정보를 반영하여 계산합니다.",
  "203": "재개발 재건축의 취득원인, 청산금 여부 등을 반영하여 계산합니다.",
  "204": "업무용과 주거용으로 구분하여 계산합니다.",
  "205": "비거주용 건물, 상가의 정보를 반영하여 계산합니다.",
  "206": "사업용과 비사업용으로 구분하여 계산합니다.",
};

type Props = {
  inputValue: TranTaxParamProps;
  inputVisible: TranTaxParamVisible;
  inputDisabled: TranTaxParamDisabled;
  onUpdateInput: (action: TranTaxAction) => void;
};

function BasicInfo({
  inputValue,
  inputVisible,
  inputDisabled,
  onUpdateInput,
}: Props) {
  const realEstateTypes: RealEstateType[] = Util.getCodeArray("2035").map(
    (code: Code): RealEstateType => ({
      estateSe: code.code,
      name: code.codeNm,
      desc: realEstateDesc[code.code],
    })
  );

  const handleRealEstateSelect = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onUpdateInput({
      type: "UPDATED_ESTATE_SE",
      payload: event.target.value,
    });
  };

  const handleClickRealEsateItem = (selected: string) => {
    onUpdateInput({
      type: "UPDATED_ESTATE_SE",
      payload: selected,
    });
  };

  const handleSelectEstateFom1 = (selected: SelectOption) => {
    onUpdateInput({
      type: "UPDATED_ESTATE_FOM1",
      payload: selected.value,
    });
  };

  const handleSelectEstateFom2 = (selected: SelectOption) => {
    onUpdateInput({
      type: "UPDATED_ESTATE_FOM2",
      payload: selected.value,
    });
  };

  const handleSelectLandCls = (selected: SelectOption) => {
    onUpdateInput({
      type: "UPDATED_LANDCLS",
      payload: selected.value,
    });
  };

  const handleSelectAcqsSrc1 = (selected: SelectOption) => {
    onUpdateInput({
      type: "UPDATED_ACQS_SRC1",
      payload: selected.value,
    });
  };

  const handleSelectAcqsSrc2 = (selected: SelectOption) => {
    onUpdateInput({
      type: "UPDATED_ACQS_SRC2",
      payload: selected.value,
    });
  };

  const handleSelectAcqsCause1 = (selected: SelectOption) => {
    onUpdateInput({
      type: "UPDATED_ACQS_CAUSE1",
      payload: selected.value,
    });
  };

  const handleSelectAcqsCause2 = (seledted: SelectOption) => {
    onUpdateInput({
      type: "UPDATED_ACQS_CAUSE2",
      payload: seledted.value,
    });
  };

  const handleSelectAcqsCause3 = (selected: SelectOption) => {
    onUpdateInput({
      type: "UPDATED_ACQS_CAUSE3",
      payload: selected.value,
    });
  };

  const handleSelectAcqsCause4 = (selected: SelectOption) => {
    onUpdateInput({
      type: "UPDATED_ACQS_CAUSE4",
      payload: selected.value,
    });
  };

  const handleSelectHseNum = (selected: SelectOption) => {
    onUpdateInput({
      type: "UPDATED_HSE_NUM",
      payload: selected.value,
    });
  };

  return (
    <MainSectionContainer>
      <p className="desc">
        <span>양도할 자산의</span>
        <span className="bold"> 기초정보</span>
        <span>를 선택해주세요.</span>
      </p>
      {/* 01. 부동산의 종류 */}
      {inputVisible.visible01 && (
        <RealEstateList>
          <p className="label">부동산의 종류</p>
          {realEstateTypes.map((type: RealEstateType) => {
            return (
              <RealEstateItem
                name={type.name}
                estateSe={type.estateSe}
                desc={type.desc}
                selectedRealEstate={inputValue.estateSe}
                onRealEstateSelect={handleRealEstateSelect}
                onClickItem={handleClickRealEsateItem}
                key={type.estateSe}
              />
            );
          })}
        </RealEstateList>
      )}
      {/* 02. 주택구분1(주택) */}
      {inputVisible.visible02 && (
        <InputContainer>
          <p className="label">주택 구분</p>
          <div className="input">
            <SelectBox
              placeholder="주택 구분을 선택해주세요."
              options={Util.getCodeArray("2002").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled02}
              selectedValue={inputValue.estateFom1}
              handleSelect={handleSelectEstateFom1}
            />
          </div>
        </InputContainer>
      )}
      {/* 03. 주택구분2(오피스텔) */}
      {inputVisible.visible03 && (
        <InputContainer>
          <p className="label">오피스텔 구분</p>
          <div className="input">
            <SelectBox
              placeholder="오피스텔 구분을 선택해주세요."
              options={Util.getCodeArray("2037").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled03}
              selectedValue={inputValue.estateFom2}
              handleSelect={handleSelectEstateFom2}
            />
          </div>
        </InputContainer>
      )}
      {/* 04. 토지구분 */}
      {inputVisible.visible04 && (
        <InputContainer>
          <p className="label">토지구분</p>
          <div className="input">
            <SelectBox
              placeholder="토지구분을 선택해주세요."
              options={Util.getCodeArray("2036").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              selectedValue={inputValue.landCls}
              handleSelect={handleSelectLandCls}
            />
          </div>
        </InputContainer>
      )}
      {/* 05.취득구분1(아파트/연립) */}
      {inputVisible.visible05 && (
        <InputContainer>
          <p className="label">취득구분</p>
          <div className="input">
            <SelectBox
              placeholder="취득구분을 선택해주세요."
              options={Util.getCodeArray("2003").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled05}
              selectedValue={inputValue.acqsSrc1}
              handleSelect={handleSelectAcqsSrc1}
            />
          </div>
        </InputContainer>
      )}
      {/* 06.취득구분2(오피스텔) */}
      {inputVisible.visible06 && (
        <InputContainer>
          <p className="label">취득구분</p>
          <div className="input">
            <SelectBox
              placeholder="취득구분을 선택해주세요."
              options={Util.getCodeArray("2004").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled06}
              selectedValue={inputValue.acqsSrc2}
              handleSelect={handleSelectAcqsSrc2}
            />
          </div>
        </InputContainer>
      )}
      {/* 07.취득원인1(기존 아파트) */}
      {inputVisible.visible07 && (
        <InputContainer>
          <p className="label">취득원인</p>
          <div className="input">
            <SelectBox
              placeholder="취득원인을 선택해주세요."
              options={Util.getCodeArray("2005").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              selectedValue={inputValue.acqsCause1}
              handleSelect={handleSelectAcqsCause1}
            />
          </div>
        </InputContainer>
      )}
      {/* 08.취득원인2(분양권/오피스텔) */}
      {inputVisible.visible08 && (
        <InputContainer>
          <p className="label">취득원인</p>
          <div className="input">
            <SelectBox
              placeholder="취득원인을 선택해주세요."
              options={Util.getCodeArray("2006").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              selectedValue={inputValue.acqsCause2}
              handleSelect={handleSelectAcqsCause2}
            />
          </div>
        </InputContainer>
      )}
      {/* 09.취득원인3(조합원입주권) */}
      {inputVisible.visible09 && (
        <InputContainer>
          <p className="label">취득원인</p>
          <div className="input">
            <SelectBox
              placeholder="취득원인을 선택해주세요."
              options={Util.getCodeArray("2007").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled09}
              selectedValue={inputValue.acqsCause3}
              handleSelect={handleSelectAcqsCause3}
            />
          </div>
        </InputContainer>
      )}
      {/* 10.취득원인4(다세대/다가구/다중/단독) */}
      {inputVisible.visible10 && (
        <InputContainer>
          <p className="label">취득원인</p>
          <div className="input">
            <SelectBox
              placeholder="취득원인을 선택해주세요."
              options={Util.getCodeArray("2008").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              selectedValue={inputValue.acqsCause4}
              handleSelect={handleSelectAcqsCause4}
            />
          </div>
        </InputContainer>
      )}
      {/* 11.보유주택 수 */}
      {inputVisible.visible11 && (
        <InputContainer>
          <p className="label">보유주택 수</p>
          <div className="input">
            <SelectBox
              placeholder="보유주택 수를 선택해주세요."
              options={Util.getCodeArray("2010").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              selectedValue={inputValue.hseNum}
              handleSelect={handleSelectHseNum}
            />
          </div>
        </InputContainer>
      )}
    </MainSectionContainer>
  );
}

const RealEstateList = styled.ul`
  width: 100%;
  padding: 0;
  margin-top: 10px;

  .label {
    margin-bottom: 5px;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.29;
    letter-spacing: -0.058em;
    color: ${(props) => props.theme.color.nGray30};
  }
`;

export default BasicInfo;
