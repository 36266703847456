/***********************************************************************************
*** Law24at10 ** 조세특례제한법 99조의 3 신축주택의 취득자에 대한 양도소득세의 과세특례 Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law24at10Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}

export default function Law24at10({ setModalOpen, setLawModalTitle, callBack, callMode }: Law24at10Props) {
    React.useEffect(() => {
        setLawModalTitle("조세특례제한법 99조의 3 신축주택의 취득자에 대한 양도소득세의 과세특례");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className='w100p h500 ofA mb30'>
                <table className={
                    'forest-list-table bdt1 bdb1 bdcDDDDDD ' +
                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075'
                }>
                   <colgroup>
                        <col className='w80' ></col>    {/* 구분            */}
                        <col className='w100'></col>    {/* 주택구분        */}
                        <col className='w80' ></col>    {/* 규모기준        */}
                        <col className='w160'></col>    {/* 계약일          */}
                        <col className='w140'></col>    {/* 사용승인        */}
                        <col className='w200'></col>    {/* 지역기준        */}
                        <col className='w100'></col>    {/* 최초계약        */}
                        <col className='w100'></col>    {/* 입주사실        */}
                        <col className='w200'></col>    {/* 조합의 잔여주택 */}
                        <col className='w180'></col>    {/* 혜택            */}
                    </colgroup>
                    <tbody>
                        <tr className='h40'>
                            <th rowSpan={2}>구분</th>
                            <th rowSpan={2}>주택구분</th>
                            <th rowSpan={2}>규모기준</th>
                            <th rowSpan={2}>계약일+계약금 완납일<br/>귀속기간</th>
                            <th rowSpan={2}>사용승인 또는<br/>사용검사(임시승인포함)일 귀속기간</th>
                            <th rowSpan={2}>지역기준</th>
                            <th colSpan={3}>추가질문</th>
                            <th rowSpan={2}>혜택</th>
                        </tr>
                        <tr className='h29'>
                            <th>최초계약</th>
                            <th>입주사실</th>
                            <th className='bdr1-i bdcDDDDDD-i'>조합의 잔여주택 일반계약자(조합원외의 자)의 계약금+계약금완납일 귀속기간</th>
                        </tr>
                        <tr className='h32'>
                            <th rowSpan={12}>개별사항</th>
                            <td rowSpan={3}>분양권에 의한 아파트</td>
                            <td>국민주택규모 이하</td>
                            <td>2000.11.1.~2001.5.22.</td>
                            <td rowSpan={3}>-</td>
                            <td>비수도권</td>
                            <td rowSpan={3}>최초계약체결+계약금지급</td>
                            <td rowSpan={3}>계약일 현재 입주사실 없어야 함</td>
                            <td rowSpan={3}>-</td>
                            <td rowSpan={12}>5년이내 양도시 : 양도소득세 100% 감면<br/><br/>5년초과 양도시 : 최초 5년간 양도소득금액 과세제외</td>
                        </tr>
                        <tr className='h32'>
                            <td>-</td>
                            <td>2001.5.23.~2002.12.31.</td>
                            <td className='bdr1-i bdcDDDDDD-i'>-</td>
                        </tr>
                        <tr className='h32'>
                            <td>-</td>
                            <td>2003.1.1.~2003.6.30.</td>
                            <td className='bdr1-i bdcDDDDDD-i'>서울, 과천, 분당, 일산, 평촌, 산본, 중동 제외한 모든 지역</td>
                        </tr>
                        <tr className='h32'>
                            <td>자가신축</td>
                            <td>국민주택규모이하</td>
                            <td>-</td>
                            <td>2000.11.1.~2001.5.22.</td>
                            <td>비수도권</td>
                            <td>-</td>
                            <td>-</td>
                            <td className='bdr1-i bdcDDDDDD-i'>-</td>
                        </tr>
                        <tr className='h32'>
                            <td rowSpan={2}>조합원입주권에 의한 아파트 중 원조합원</td>
                            <td rowSpan={2}>국민주택규모이하</td>
                            <td rowSpan={2}>-</td>
                            <td>2000.11.1.~2001.5.22.</td>
                            <td rowSpan={2}>비수도권</td>
                            <td rowSpan={2}>-</td>
                            <td>-</td>
                            <td className='bdr1-i bdcDDDDDD-i'>-</td>
                        </tr>    
                        <tr className='h32'>
                            <td>2001.05.23. 이후</td>
                            <td>여</td>
                            <td className='bdr1-i bdcDDDDDD-i'>2000.11.1.~2001.5.22.</td>
                        </tr>
                        <tr className='h32'>
                            <td>자가신축</td>
                            <td>-</td>
                            <td>-</td>
                            <td>2001.5.23.~2002.12.31.</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td className='bdr1-i bdcDDDDDD-i'>-</td>
                        </tr>
                        <tr className='h32'>
                            <td rowSpan={2}>조합원입주권에 의한 아파트 중 원조합원</td>
                            <td rowSpan={2}>-</td>
                            <td rowSpan={2}>-</td>
                            <td>2001.5.23.~2002.12.31.</td>
                            <td rowSpan={2}>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td className='bdr1-i bdcDDDDDD-i'>-</td>
                        </tr>    
                        <tr className='h32'>
                            <td>2003.01.01. 이후</td>
                            <td>여</td>
                            <td>-</td>
                            <td className='bdr1-i bdcDDDDDD-i'>2001.5.23.~2002.12.31.</td>
                        </tr>
                        <tr>
                            <td>자가신축</td>
                            <td>-</td>
                            <td>-</td>
                            <td>2003.1.1.~2003.6.30.</td>
                            <td>서울, 과천, 분당, 일산, 평촌, 산본, 중동 제외한 모든 지역<br/>다만, 경과규정으로 2002.12.31. 이전 착공하고 2003.6.30. 이전 사용승인된 경우 서울, 과천 5대 신도시 포함</td>
                            <td>-</td>
                            <td>-</td>
                            <td className='bdr1-i bdcDDDDDD-i'>-</td>
                        </tr>
                        <tr>
                            <td rowSpan={2}>조합원입주권에 의한 아파트 중 원조합원</td>    
                            <td rowSpan={2}>-</td>
                            <td rowSpan={2}>-</td>
                            <td>2003.1.1.~2003.6.30.</td>
                            <td>서울, 과천, 분당, 일산, 평촌, 산본, 중동 제외한 모든 지역<br/>다만, 경과규정으로 2002.12.31. 이전 착공하고 2003.6.30. 이전 사용승인된 경우 서울, 과천 5대 신도시 포함</td>
                            <td>-</td>
                            <td>-</td>
                            <td className='bdr1-i bdcDDDDDD-i'>-</td>
                        </tr>    
                        <tr className='h32'>
                            <td>2003.07.01. 이후</td>
                            <td>-</td>
                            <td>여</td>
                            <td>-</td>
                            <td className='bdr1-i bdcDDDDDD-i'>2003.1.1.~2003.6.30.</td>
                        </tr>
                        <tr className='h110'>
                            <th>공통사항</th>
                            <td className='taL pt15 pb15 pl15'colSpan={8}>
                                1. 비과세 판정시 주택수 포함<br/>
                                2. 다주택 중과배제(유예)<br/>
                                3. 고가주택은 감면 제외<br/>
                                <table className={
                                    'forest-list-table bd1 bdcDDDDDD mt10 mb10 ' +
                                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075'
                                }>
                                    <colgroup>
                                        <col className='w20p' />
                                        <col className='w80p' />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th className='taC p10'>가액기준(당해주택 양도시)</th>
                                            <td className='taL p10'>
                                                2002.12.31. 이전 계약분(사용승인 또는 사용검사일 포함) : 취득시점 면적기준+양도시 양도가액 12억원 초과<br/>
                                                2003.01.01. 이후 계약분(사용승인 또는 사용검사일 포함) : 양도시 양도가액 12억원 초과<br/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}
