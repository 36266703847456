/*******************************************************************************
*** SimAddressDong ** 간편계산기 주소검색 2단계 * 동, 호수 선택
*******************************************************************************/
import React from "react";
import { IWizard } from "use-wizard/lib/cjs/useWizard/types/IWizard";
import { TStep } from "use-wizard/lib/cjs/useWizard/types/TStep";
import Axios from "js/common/Axios";
import AppContext from "js/common/AppContext";
import SelectBox from "js/common/ui/input/SelectBox";
import TextBox from "js/common/ui/input/TextBox";
import Util from "js/common/Util";

const SimAddressDong = (props: {
    step: TStep;
    wizard: IWizard;
    form: any;
    dispatchForm: Function;
}) => {
    const [userBdNm, setUserBdNm] = React.useState<string>(props.form.selAddrItem.bdNm);
    const [userDong, setUserDong] = React.useState<string>();
    const [userHo, setUserHo] = React.useState<string>();
    const [aptDongDataArr, setAptDongDataArr] = React.useState<string[]>([]); // 아파트 동 목록 조회결과
    const [aptHoDataArr, setAptHoDataArr] = React.useState<string[]>([]); // 아파트 호 목록 조회결과
    const [multiHoDataArr, setMultiHoDataArr] = React.useState<string[]>([]); // 한개동-다세대 호 목록 조회결과
    const [dongDataLength, setDongDataLength] = React.useState<number>(0);

    // 이전버튼
    const goPrevPage = () => {
        props.dispatchForm({
            type: "UPDATE_KEY_VALUES",
            value: {
                userBdNm: userBdNm,
                userDong: userDong,
                userHo: userHo,
                addrSrchText: props.form.addrSrchText,
                beforePage: "addrDong",
                taxFlag: props.form.taxFlag
            }
        })
        props.wizard.previousStep();
    }

    // 다음버튼
    const goNextPage = () => {
        if (Util.isEmpty(userDong) && Util.isNotEmpty(aptDongDataArr)) {
            AppContext.openAlert({
                title: "공동주택 동 확인",
                msg: "아파트 동을 선택해주세요.",
                icon: "warning"
            });
            return;
        }
        if (Util.isEmpty(userHo) && Util.isNotEmpty(aptHoDataArr) && props.form.selAddrItem.bdKdcd === '1') {
            AppContext.openAlert({
                title: "공동주택 호 확인",
                msg: "아파트 호를 선택해주세요.",
                icon: "warning"
            });
            return;
        }
        if (Util.isEmpty(userHo) && Util.isNotEmpty(multiHoDataArr) && props.form.selAddrItem.bdKdcd === '1') {
            AppContext.openAlert({
                title: "공동주택 호 확인",
                msg: "호를 선택해 주세요.",
                icon: "warning"
            });
            return;
        }
        props.dispatchForm({
            type: "UPDATE_KEY_VALUES",
            value: {
                userBdNm: userBdNm,
                userDong: userDong,
                userHo: userHo,
                taxFlag: props.form.taxFlag
            }
        })
        props.wizard.nextStep();
    }

    React.useEffect(() => {
        // [아파트] 동 선택 목록 조회
        const selAptDongList = () => {
            AppContext.showSpinner();
            Axios.dataAccess({
                url: "api/system/broker/callApi.do",
                methodType: "post",
                paramType: "object",
                paramData: {
                    apiName: "openapi",
                    tailUrl: "addr/donglist",
                    paramStr: props.form.apiParam
                },
                onSuccessFn: (res: any) => {
                    if (Util.isEmpty(res.item.rtn_list)) {
                        return;
                    }
                    setAptDongDataArr(res.item.rtn_list);
                    AppContext.hideSpinner();
                }
            })
        };

        // [아파트] 호 선택 목록 조회
        const selAptHoList = () => {
            let paramStr = "";
            if (userDong === undefined) {
                paramStr = props.form.apiParam;
            } else {
                paramStr = props.form.apiParam + "," + encodeURI(userDong)
            }
            AppContext.showSpinner();
            Axios.dataAccess({
                url: "api/system/broker/callApi.do",
                methodType: "post",
                paramType: "object",
                paramData: {
                    apiName: "openapi",
                    tailUrl: "addr/holist",
                    paramStr: paramStr
                },
                onSuccessFn: (res: any) => {
                    if (Util.isEmpty(res.item.rtn_list)) {
                        return;
                    }
                    setAptHoDataArr(res.item.rtn_list);
                    AppContext.hideSpinner();
                }
            });
        };

        // [다세대] 호 선택 목록 조회
        const selMultiHoList = () => {
            AppContext.showSpinner();
            Axios.dataAccess({
                url: "api/system/broker/callApi.do",
                methodType: "post",
                paramType: "object",
                paramData: {
                    apiName: "openapi",
                    tailUrl: "addr/holist",
                    paramStr: props.form.apiParam
                },
                onSuccessFn: (res: any) => {
                    if (Util.isEmpty(res.item.rtn_list[0])) {
                        return;
                    }
                    setMultiHoDataArr(res.item.rtn_list);
                    AppContext.hideSpinner();
                }
            });
        };
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "api/system/broker/callApi.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                apiName: "openapi",
                tailUrl: "addr/donglist",
                paramStr: props.form.apiParam
            },
            onSuccessFn: (res: any) => {
                setDongDataLength(res.item.rtn_list.length);
                selAptDongList();
                selAptHoList();
                selMultiHoList();
                AppContext.hideSpinner();
            }
        });
    }, [props.form.apiParam, userDong]);

    return (
        <React.Fragment>
            <div className="dpFlx aiC">
                <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>상세 주소 입력 ({props.step}/3)</span>
            </div>
            <div className='h40 dpFlx aiC pt5'>
                <div className='w50 h24 bd1 bdc3C89CD bdrd4 dpFlx aiC jcC bdBox mr10'>
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc3C89CD'>도로명</span>
                </div>
                <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc666666'>{props.form.selAddrItem.roadAddr}</span>
            </div>
            <div className="mt20">
                {props.form.selAddrItem.bdKdcd === "1" && dongDataLength >= 1 ?
                    <React.Fragment>
                        <div className="dpFlx aiC mb10">
                            <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">건물명</span>
                            <TextBox value={userBdNm} onChangeFunc={(item: string) => setUserBdNm(item)} placeholder="건물명을 입력해주세요" width={355} height={40} fontSize={15} className="bd1" inputClassName='pl15 pr15 bgcWhite' />
                        </div>
                        <div className="dpFlx aiC mb10">
                            <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">동</span>
                            {Util.isNotEmpty(aptDongDataArr)
                                ? <SelectBox value={userDong} codeArray={aptDongDataArr.map((item: string) => ({ code: item, codeNm: item }))} onChangeFunc={(item: string) => { setUserDong(item); setAptHoDataArr([]); }} placeholder="동 정보를 선택해주세요" width={355} height={40} fontSize={15} paddingRight={10} border="1px solid #dddddd" />
                                : <TextBox value={userDong} onChangeFunc={(item: string) => setUserDong(item)} placeholder="동 정보를 입력해주세요" width={355} height={40} fontSize={15} className="bd1" inputClassName='pl15 pr15 bgcWhite' />
                            }
                        </div>
                        <div className="dpFlx aiC mb10">
                            <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">호수</span>
                            {Util.isNotEmpty(aptHoDataArr)
                                ? <SelectBox value={userHo} codeArray={aptHoDataArr.map((item: string) => ({ code: item, codeNm: item }))} onChangeFunc={(item: string) => setUserHo(item)} placeholder="호 정보를 선택해주세요" width={355} height={40} fontSize={15} paddingRight={10} border="1px solid #dddddd" />
                                : <TextBox value={userHo} onChangeFunc={(item: string) => setUserHo(item)} placeholder="호 정보를 입력해주세요" width={355} height={40} fontSize={15} className="bd1" inputClassName='pl15 pr15 bgcWhite' />
                            }
                        </div>
                    </React.Fragment>
                : <></>}
                {props.form.selAddrItem.bdKdcd === "1" && dongDataLength < 1 ?
                    <React.Fragment>
                        <div className="dpFlx aiC mb10">
                            <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">건물명</span>
                            <TextBox value={userBdNm} onChangeFunc={(item: string) => setUserBdNm(item)} placeholder="건물명을 입력해주세요" width={355} height={40} fontSize={15} className="bd1 pl5" />
                        </div>
                        <div className="dpFlx aiC mb10">
                            <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">호수</span>
                            {Util.isNotEmpty(multiHoDataArr)
                                ? <SelectBox value={userHo} codeArray={multiHoDataArr.map((item: string) => ({ code: item, codeNm: item }))} onChangeFunc={(item: string) => setUserHo(item)} placeholder="호 정보를 선택해주세요" width={355} height={40} fontSize={15} paddingRight={10} border="1px solid #dddddd" />
                                : <TextBox value={userHo} onChangeFunc={(item: string) => setUserHo(item)} placeholder="호 정보를 입력해주세요" width={355} height={40} fontSize={15} className="bd1 pl5" />
                            }
                        </div>
                    </React.Fragment>
                : <></>}
                {props.form.selAddrItem.bdKdcd === '0' ?
                    <React.Fragment>
                        <div className="dpFlx aiC mb10">
                            <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">건물명 (선택)</span>
                            <TextBox value={userBdNm} onChangeFunc={(item: string) => setUserBdNm(item)} placeholder="건물명을 입력해주세요" width={355} height={40} fontSize={15} className="bd1" inputClassName='pl15 pr15 bgcWhite' />
                        </div>
                        <div className="dpFlx aiC mb10">
                            <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">동 (선택)</span>
                            <TextBox value={userDong} onChangeFunc={(item: string) => setUserDong(item)} placeholder="동 정보를 입력해주세요" width={355} height={40} fontSize={15} className="bd1" inputClassName='pl15 pr15 bgcWhite' />
                        </div>
                        <div className="dpFlx aiC mb10">
                            <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">호 (선택)</span>
                            <TextBox value={userHo} onChangeFunc={(item: string) => setUserHo(item)} placeholder="호 정보를 입력해주세요" width={355} height={40} fontSize={15} className="bd1" inputClassName='pl15 pr15 bgcWhite' />
                        </div>
                    </React.Fragment>
                : <></>}
            </div>
            <div className='w100p dpFlx aiC jcC pt40'>
                <div className='w210 h56 bgcF6F6F6 dpFlx aiC jcC csP' onClick={goPrevPage}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>이전</span>
                </div>
                <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={goNextPage}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>다음</span>
                </div>
            </div>
        </React.Fragment>
    );
};
export default SimAddressDong;