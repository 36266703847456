import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { addrSearchResultState } from 'js/common/Atom';
import Axios from 'js/common/Axios';
import AppContext from 'js/common/AppContext';
import Util from 'js/common/Util';
import { AddrSearchResult, Juso } from 'js/common/types/types';

export function useAddressSearch(
    addrSrchText: string,
    setAddrSrchText: (text: string) => void,
    addrSrchResultList: Juso[],
    setAddrSrchResultList: (result: Juso[]) => void,
) {
    const [addrSearchResult, setAddrSearchResult] = useRecoilState(addrSearchResultState);

    //const [addrSrchText, setAddrSrchText] = useState<string>(searchText);
    //const [addrSrchResultList, setAddrSrchResultList] = useState<Juso[]>([]); // FldInfs 셋팅데이터 서버조회결과
    //const [selectedAddr, setSelectedAddr] = useState<Juso | null>(null);
    const selectedAddr = addrSearchResult.addr;

    useEffect(() => {
        if (!addrSrchText) {
            setAddrSrchResultList([]);
        }
    }, [addrSrchText, setAddrSrchResultList]);

    const onSearchAddr = () => {
        if (Util.isEmpty(addrSrchText) || addrSrchText.length < 2) {
            return;
        }
        AppContext.showSpinner();
        Axios.dataAccess({
            url: 'common/mobile/callApi.do',
            methodType: 'post',
            paramType: 'object',
            paramData: {
                apiName: 'openapi',
                tailUrl: 'addr/juso',
                paramStr: encodeURI(addrSrchText) + ',1,100',
            },
            onSuccessFn: (res: any) => {
                setAddrSrchResultList(res.item.rtn_list);
                AppContext.hideSpinner();
            },
        });
    };

    const onSelectAddr = (addr: Juso) => {
        setAddrSearchResult((prev: AddrSearchResult) => ({
            ...prev,
            addr: addr,
        }));

        //setAddrSrchText('');
        //setAddrSrchResultList([]);
    };

    return {
        addrSrchText,
        setAddrSrchText,
        addrSrchResultList,
        onSearchAddr,
        selectedAddr,
        onSelectAddr,
    };
}
