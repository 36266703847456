/***********************************************************************************
*** Law2 ** 소득세법 시행령 제155조 제20항 나목(거주주택비과세) Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law2Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}

export default function Law2({ setModalOpen, setLawModalTitle, callBack, callMode }: Law2Props) {
    React.useEffect(() => {
        setLawModalTitle("소득세법 시행령 제155조 제20항 나목(거주주택비과세)");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className='w100p hAuto ofA mb30'>
                <table className={
                    'forest-list-table bdt1 bdb1 bdcDDDDDD ' +
                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075'
                }>
                   <colgroup>
                        <col className='w100'></col>
                        <col className='w110'></col>
                        <col className='w140'></col>
                        <col className='w140'></col>
                        <col className='w90'></col>
                        <col className='w90'></col>
                        <col className='w110'></col>
                        <col className='w130'></col>
                        <col className='w130'></col>
                        <col className='w130'></col>
                    </colgroup>
                    <tbody>
                        <tr className='h40'>
                            <th rowSpan={2}>구분</th>
                            <th rowSpan={2}>주택구분</th>
                            <th rowSpan={2}>주택규모</th>
                            <th rowSpan={2}>지역기준</th>
                            <th colSpan={2}>등록</th>
                            <th rowSpan={2}>민특법 분류</th>
                            <th rowSpan={2}>임대유형</th>
                            <th rowSpan={2}>임대기간</th>
                            <th>추가질문</th>
                        </tr>
                        <tr className='h29'>
                            <th>지자체</th>
                            <th>세무서</th>
                            <th>임대호수</th>
                        </tr>
                        <tr className='h70'>
                            <th>개별사항</th>
                            <td>기존매입주택</td>
                            <td>국민주택규모 이하</td>
                            <td>-</td>
                            <td colSpan={2}>2003.10.29. 이전 등록</td>
                            <td>-</td>
                            <td>-</td>
                            <td>5년 이상</td>
                            <td>2호 이상</td>
                        </tr>
                        <tr className='h70'>
                            <th>기타사항</th>
                            <td className='taL pt15 pb15 pl15 SpoqaHanSansNeo-Regular fs15 ls1 lh20 fc666666' colSpan={9}></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}