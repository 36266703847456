import AppContext from "js/common/AppContext"
import Axios from "js/common/Axios"
import Util from "js/common/Util"
import { IWizard } from "use-wizard/lib/cjs/useWizard/types/IWizard"

interface AssetsEditTabProps {
    wizard: IWizard
    assetsSn: number
    estateSttus: string
    estateDetailSe: string
    acqsCause: string
    acqsSrc: string
    currStepCode: number
}
const AssetsEditTab = ({wizard, assetsSn, estateSttus, estateDetailSe, acqsCause, acqsSrc, currStepCode}: AssetsEditTabProps) => {
    const goToStep = (stepCode: number) => {
        if (currStepCode === stepCode) {
            return;
        }
        if (Util.isEmpty(assetsSn)) {
            return;
        }
        Axios.dataAccess({
            url: "system/assets/selAssetsInfo.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                assetsSn: assetsSn
            },
            onSuccessFn: (res: any) => {
                Axios.dataAccess({
                    url: "system/assets/selAssetsEstateDetail.do",
                    methodType: "post",
                    paramType: "object",
                    paramData: {
                        assetsSn: assetsSn
                    },
                    onSuccessFn: (res2: any) => {
                        if (res2.item.estateDetailSe !== estateDetailSe) {
                            AppContext.openAlert({
                                title: "링크이동 불가",
                                msg: "자산구분 변경 시 탭 이동이 불가합니다.\n\n다음 버튼을 클릭하여 정보를 먼저 저장하시기 바랍니다.",
                                icon: "check"
                            });
                            return;
                        }
                        if (res2.item.acqsCause !== acqsCause) {
                            AppContext.openAlert({
                                title: "링크이동 불가",
                                msg: "취득원인 변경 시 탭 이동이 불가합니다. 다음 버튼을 클릭하여 정보를 먼저 저장하시기 바랍니다.",
                                icon: "check"
                            });
                            return;
                        }
                        if (res.item.stepCode !== "1009") {
                            AppContext.openAlert({
                                title: "링크이동 불가",
                                msg: "수정 중인 상태에서는 링크 이동이 불가합니다.",
                                icon: "check"
                            });
                            return;
                        }
                        AppContext.showSpinner();
                        wizard.goToStep(stepCode);
                    }
                });
            }
        });
    }

    /*
        * 위저드 스텝 표출 조건 정리
        1. 자산구분 : 주택
            - 6step
        2. 자산구분 : 분양권
            - 2step
        3. 자산구분 : 조합원입주권
            1) 원조합원, 전매, 상속
                - 3step
            2) 증여
                - 2step
    */
    if ((Util.isEmpty(estateSttus) || Util.isEmpty(estateDetailSe) || Util.isEmpty(acqsCause)) && acqsSrc !== "1004") {
        return (<div className="w100p h56 dpFlx aiC jcC posSk t0 bgcWhite zIdx1"></div>)
    }
    return (
        <div className="w100p h56 dpFlx aiC jcC posSk t0 bgcWhite zIdx1">
            <div className="flx1A h100p dpFlx aiC jcR bdBox bdb2 bdc17A840">
                <div className="dpFlx aiC csP" onClick={() => goToStep(1001)}>
                    <img className="pr10" src={require('img/system/assets/icon_assets_step_base_on.png')} alt="icon_assets_step_base_on" />
                    <span className="SpoqaHanSansNeo-Medium fs16 ls08 pr33 fc17A840">자산 기본정보</span>
                </div>
            </div>
            <div className={"w171 h100p dpFlx aiC jcC bdBox bdb2 csP " + (currStepCode >= 1002 ? "bdc17A840" : "bdcTp")} onClick={() => goToStep(1002)}>
                <img className="pr10" src={require('img/system/assets/icon_assets_step_acqs_' + (currStepCode >= 1002 ? 'on' : 'off') + '.png')} alt="icon_assets_step_acqs_off" />
                <span className={"SpoqaHanSansNeo-Medium fs16 ls08 pr33 " + (currStepCode >= 1002 ? "fc17A840" : "fc999999")}>취득정보</span>
            </div>
            {(estateSttus === "1002" && (estateDetailSe === "1001" || (estateDetailSe === "1003" && acqsCause === "1001"))) || (estateSttus === "1003" && estateDetailSe === "1001") ?
                <div className={"w221 h100p aiC jcC bdBox bdb2 csP dpFlx " + (currStepCode >= 1003 ? "bdc17A840" : "bdcTp")} onClick={() => goToStep(1003)}>
                    <img className="pr10" src={require('img/system/assets/icon_assets_step_rar_' + (currStepCode >= 1003 ? 'on' : 'off') + '.png')} alt="icon_assets_step_rar_off" />
                    <span className={"SpoqaHanSansNeo-Medium fs16 ls08 pr33 " + (currStepCode >= 1003 ? "fc17A840" : "fc999999")}>거주 및 임대정보</span>
                </div>
            : <></>}
            {(estateSttus === "1002" && (estateDetailSe === "1001" || (estateDetailSe === "1002" && acqsCause === "1003") || (estateDetailSe === "1003" && ["1001", "1003"].indexOf(acqsCause) !== -1))) || estateSttus === "1003" ?
                <div className={"w171 h100p aiC jcC bdBox bdb2 csP dpFlx" + (currStepCode >= 1006 ? " bdc17A840" : " bdcTp")} onClick={() => goToStep(1006)}>
                    <img className="pr10" src={require('img/system/assets/icon_assets_step_law_' + (currStepCode >= 1006 ? 'on' : 'off') + '.png')} alt="icon_assets_step_law_off" />
                    <span className={"SpoqaHanSansNeo-Medium fs16 ls08 pr33 " + (currStepCode >= 1006 ? "fc17A840" : "fc999999")}>
                        {estateSttus === "1002" ? "주택분류" : ""}
                        {estateSttus === "1003" ? "세액계산 질문" : ""}
                    </span>
                </div>
            : <></>}
            <div className={"flx1A h100p dpFlx aiC bdBox bdb2 " + (currStepCode >= 1009 ? "bdc17A840" : "bdcTp")}>
                <div className="dpFlx aiC csP" onClick={() => goToStep(1009)}>
                    <img className="pl34 pr10" src={require('img/system/assets/icon_assets_step_preview_' + (Number(currStepCode) >= 1009 ? 'on' : 'off') + '.png')} alt="icon_assets_step_preview_off" />
                    <span className={"SpoqaHanSansNeo-Medium fs16 ls08 pr33 " + ((Number(currStepCode) >= 1009 ? "fc17A840" : "fc999999"))}>등록완료</span>
                </div>
            </div>
        </div>
    )
}
export default AssetsEditTab;