/*******************************************************************************
*** ProptaxCalc ** 재산세 세액 산출 결과표
*******************************************************************************/
import React from 'react';
import Axios from 'js/common/Axios';
import Util from 'js/common/Util';
import AppContext from 'js/common/AppContext';
import ToolTip from 'js/common/ui/ToolTip';

interface ProptaxCalcProps {
    setProptaxCalcModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setProptaxCalcModalVsb: React.Dispatch<React.SetStateAction<boolean>>
    assetsSn: number | undefined
    lstyrProptax: string
    lstyrUrbantax: string
    pblntfPc: string
}
const ProptaxCalc = ({setProptaxCalcModalOpen, setProptaxCalcModalVsb, assetsSn, lstyrProptax, lstyrUrbantax, pblntfPc}: ProptaxCalcProps) => {
    const [calcInfo, setCalcInfo] = React.useState<any | undefined>();

    React.useEffect(() => {
        Axios.dataAccess({
            url: "api/system/broker/callApi.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                apiName: "taxcalc",
                tailUrl: "proptax/calctax",
                paramStr: assetsSn + (Util.isNotEmpty(lstyrProptax) && Util.isNotEmpty(lstyrUrbantax) ? "?price=" + Util.uncomma(pblntfPc) + "&lstyr_proptax=" + Util.uncomma(lstyrProptax) + "&lstyr_urbantax=" + Util.uncomma(lstyrUrbantax) : "")
            },
            onSuccessFn: (res: any) => {
                if (Util.isEmpty(res.item.rtn_list)) {
                    setProptaxCalcModalOpen(false);
                    AppContext.openAlert({
                        title: "산출내역 없음",
                        msg: "산출된 내역이 없습니다.",
                        icon: "check",
                    });
                    return;
                }
                setCalcInfo(res.item.rtn_list[0]);
                setProptaxCalcModalVsb(true);
            },
            skipFailedAlert: true,
            onFailedFn: (res: any) => {
                AppContext.openAlert({
                    title: "과세 대상 안내",
                    msg: res._msg_,
                    icon: "check",
                });
                setProptaxCalcModalOpen(false);
            }
        });
    }, [setProptaxCalcModalOpen, setProptaxCalcModalVsb, assetsSn, lstyrProptax, lstyrUrbantax, pblntfPc]);

    return (
        <div className='w1000'>
            <div className='dpFlx aiC mb10'>
                <img className="pr10" src={require('img/system/assets/icon_propertytax.png')} alt="icon_propertytax" />
                <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 fw500'>자산정보</span>
            </div>
            {Util.isNotEmpty(calcInfo) ?
                <table className={
                    "forest-form-table tr-h44 " +
                    "th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-ls07 th-fc222222 th-fw500 " +
                    "td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-ls1"
                }>
                    <colgroup>
                        <col className="w200" />
                        <col className="w300" />
                        <col className="w200" />
                        <col className="w300" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="taC" title='소재지'>소재지</th>
                            <td colSpan={3}>{calcInfo.rdnm_adres}</td>
                        </tr>
                        <tr>
                            <th className="taC" title='취득물건'>취득물건</th>
                            <td className='taC'>{Util.getValToCode("1005", calcInfo.estate_se)}</td>
                            <th className="taC bdl1" title='취득일'>취득일</th>
                            <td className='taC'>{Util.cvtDateStrForm(calcInfo.acqs_de, "-")}</td>
                        </tr>
                        <tr>
                            <th className="taC" title='면적'>면적</th>
                            <td className='taC'>{Number(calcInfo.ar).toFixed(2)} ㎡</td>
                            <th className="taC bdl1" title='취득원인'>취득원인</th>
                            <td className='taC'>{Util.getValToCode("1035", calcInfo.acqs_cause)}</td>
                        </tr>
                        <tr>
                            <th className="taC" title='자산종류'>자산종류</th>
                            <td className='taC'>{Util.getValToCode("1007", calcInfo.estate_fom)}</td>
                            <th className="taC bdl1" title='자산용도'>자산용도</th>
                            <td className='taC'>{Util.getValToCode("1008", calcInfo.estate_prpos)}</td>
                        </tr>
                        <tr>
                            <th className="taC" title='세대주택수'>세대주택수</th>
                            <td className='taC'>{calcInfo.house_num} 주택</td>
                            <th className="taC bdl1" title='지분율'>지분율</th>
                            <td className='taC'>{calcInfo.qota_rate} %</td>
                        </tr>
                    </tbody>
                </table>
            : <></>}
            <div className='dpFlx aiC mt20 mb10'>
                <img className="pr10" src={require('img/system/assets/icon_listup.png')} alt="icon_listup" />
                <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 fw500'>재산세 내역</span>
                <span className="SpoqaHanSansNeo-Regular fs13 ls065 pl195 wsPw fcFF0000">{calcInfo?.stdr_mktc === "0" ? "공시가격 API 장애로 재산세/종합부동산세 세액을 산출할 수 없습니다." : ""}</span>
            </div>
            {Util.isNotEmpty(calcInfo) ?
                <table className={
                    "forest-form-table tr-h44 " +
                    "th-p0 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-ls07 th-fc222222 th-fw500 " +
                    "td-p15 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-ls1"
                }>
                    <colgroup>
                        <col className="w200" />
                        <col className="w300" />
                        <col className="w200" />
                        <col className="w300" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th title='과세년도'>과세년도</th>
                            <td className='taR'>{Util.getSpecificDate("", "day", 0, "YYYY", "")} 년</td>
                            <th title='공시가격'>공시가격</th>
                            <td className='taR'>{Util.comma(calcInfo.stdr_mktc)} 원</td>
                        </tr>
                        <tr>
                            <th className="taC" title='공정시장가액비율'>공정시장가액비율</th>
                            <td className="taR">{Number(calcInfo.mktc_rate)}%</td>
                            <th className="taC" title='과세표준 * 지분율'>과세표준 * 지분율</th>
                            <td className='taR'>{Util.comma(calcInfo.std_amt)} 원</td>
                        </tr>
                        <tr>
                            <th className="taC" title='세율'>세율</th>
                            <td className='taR'>{Number(calcInfo.tax_rate)} %</td>
                            <th className="taC" title='재산세액(세부담상한 적용)'>재산세액(세부담상한 적용)</th>
                            <td className='taR'>{Util.comma(calcInfo.prop_tax_amt)} 원</td>
                        </tr>
                        <tr>
                            <th className="taC" title='도시지역분세액(세부담상한 적용)'>도시지역분세액(세부담상한 적용)</th>
                            <td className='taR'>{Util.comma(calcInfo.urban_tax_amt)} 원</td>
                            <th className="taC" title='지방교육세'>지방교육세</th>
                            <td className='taR'>{Util.comma(calcInfo.edu_tax_amt)} 원</td>
                        </tr>
                        <tr>
                            <th className="taC">
                                <div
                                    className="dpFlx aiC jcC pl10 csP"
                                    data-tooltip-id="houseCommentToolTip"
                                    data-tooltip-content={
                                        "주택분 재산세 납세의무자는 소방사무에 소요되는 제반비용을 충당하기 위하여 부과하는 소방분 지역자원시설세를 납부해야 한다.\n" +
                                        "주택의 건축물 부분에 한하여 재산세와 동일하게 시가표준액과 공정시장가액비율(60%)를 적용하여 과세표준을 구하고, 세율을 곱하여 세액을 산출한다.\n" +
                                        "현재 주택 전체의 가액으로 계산하였으므로 실제 지역자원시설세와 차이가 발생합니다. 참고바랍니다."
                                    }
                                >
                                    <span>지역자원시설세</span>
                                    <img src={require('img/common/icon_info_fill_20.png')} alt="icon_info_fill_20" className='csP pl3' />
                                    <ToolTip id="houseCommentToolTip" place='top' clickable={true} className="lh20 fs13" />
                                </div>
                            </th>
                            <td className='taR'>{Util.comma(calcInfo.equip_tax_amt)} 원</td>
                            <th className="taC" title='감면세액'>감면세액</th>
                            <td className='taR'>{Util.comma(calcInfo.total_rdc_amt)} 원</td>
                        </tr>
                        <tr>
                            <th className="taC" title='합계'><span className='fs20 ls1 fw500'>합계</span></th>
                            <td colSpan={3} className='taR'><span className='SpoqaHanSansNeo-Medium fs20 ls1 fc222222 fw500'>{Util.comma(calcInfo.total_proptax_amt)} 원</span></td>
                        </tr>
                    </tbody>
                </table>
            : <></>}
            <div className='w100p dpFlx aiC jcC mt40'>
                <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => {
                    setProptaxCalcModalOpen(false);
                    setProptaxCalcModalVsb(false);
                }}>
                    <span className='SpoqaHanSansNeo-Medium fs16 fcWhite fw500'>확인</span>
                </div>
            </div>
        </div>
    )
}
export default ProptaxCalc;