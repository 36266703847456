/*******************************************************************************
*** SubHeader ** sub menu
*******************************************************************************/
import { useLocation } from 'react-router-dom';
import AppContext from "js/common/AppContext";
import Util from 'js/common/Util';
import * as Atom from "js/common/Atom";
import { useRecoilValue } from "recoil";
import React from 'react';

const SubHeader = () => {
    const location = useLocation();
    const isSysAdmin = useRecoilValue(Atom.isSysAdmin);

    return (
        <div className={'w1210 ' + (Util.isEmpty(AppContext.getMenuName(location.pathname)) ? "dpNone" : "")}>
            <div className="dpFlx w100p h56 aiC jcSb">
                <span className="SpoqaHanSansNeo-Medium fs23 ls115 fc222222">{AppContext.getMenuName(location.pathname)}</span>
                <div className="dpFlx">
                    {AppContext.getSubMenuList(location.pathname).map((subMenuInfo, idx) => {
                        if ((subMenuInfo.role === "sysAdmin" && !isSysAdmin)) {
                            return (
                                <React.Fragment key={idx}></React.Fragment>
                            )
                        } else {
                            // 납세자 관리 메뉴인 경우 서브 메뉴 표시 제어
                            if (location.pathname === "/system/hshld" || location.pathname === "/system/assets") {
                                if (location.pathname === subMenuInfo.url) {
                                    return (
                                        <div key={idx} className={"csP " + (idx !== 0 ? "ml40" : "")} onClick={() => AppContext.goPage(subMenuInfo.url)}>
                                            <span className={"SpoqaHanSansNeo-Medium fs16 ls08 " + (location.pathname === subMenuInfo.url ? "fc17A840 tdU tuOs2" : Util.isNotEmpty(subMenuInfo.url) ? "fc222222" : "fcBDBDBD")}>{subMenuInfo.name}</span>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <React.Fragment key={idx}></React.Fragment>
                                    )
                                }
                            } else {
                                return (
                                    <div key={idx} className={"csP " + (idx !== 0 ? "ml40" : "")} onClick={() => AppContext.goPage(subMenuInfo.url)}>
                                        <span className={"SpoqaHanSansNeo-Medium fs16 ls08 " + (location.pathname === subMenuInfo.url ? "fc17A840 tdU tuOs2" : Util.isNotEmpty(subMenuInfo.url) ? "fc222222" : "fcBDBDBD")}>{subMenuInfo.name}</span>
                                    </div>
                                )
                            }
                        }
                    })}
                </div>
            </div>
        </div>
    )
}
export default SubHeader;