/*******************************************************************************
 You are strictly prohibited to copy, disclose, distribute, modify, or use
 this program in part or as a whole without the prior written consent of
 CallTax. CallTax, owns the intellectual property rights in and to this program.
 (Copyright © 2023 CallTax. All Rights Reserved| Confidential)

 Specification
    1. Program ID    : LawordDetailDialog.tsx
    2. Program Title : 법령 Detail 생성, 수정 Modal
    3. Created by    : JH Lee
    4. Create Date   : 2024.02.15
    5. Reference     :
        - LawordMng.tsx 페이지에서 Modal 호출
*******************************************************************************/
import React         from 'react';
import Axios         from 'js/common/Axios';
import Util          from 'js/common/Util';
import AppContext    from 'js/common/AppContext';
import TextBox       from 'js/common/ui/input/TextBox';
import ImageRadioBox from 'js/common/ui/input/ImageRadioBox';
import NumberTextBox from 'js/common/ui/input/NumberTextBox';

interface LawordDetailDialogProps {
    setModalDetailOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setLawordDetailSelTime: React.Dispatch<React.SetStateAction<Date>>;
    callMode: string;
    lawordSn: number;
    lawordNm: string;
    lawordDetailSn:number;
}

function LawordDetailDialog({ setModalDetailOpen, setLawordDetailSelTime, callMode, lawordSn, lawordNm, lawordDetailSn}: LawordDetailDialogProps) {
    const [lawordDetailNm, setLawordDetailNm] = React.useState<string>(  ); // 법령상세명
    const [lawordDetailDc, setLawordDetailDc] = React.useState<string>(  ); // 법령상세설명
    const [excptAt       , setExcptAt       ] = React.useState<string>(""); // 특례여부(radio)
    const [validYearNum  , setValidYearNum  ] = React.useState<string>(""); // 유효연도햇수
    const [rdcRate       , setRdcRate       ] = React.useState<string>(""); // 감면율
    const [hseExcptAt    , setHseExcptAt    ] = React.useState<string>(""); // 주택수제외여부(radio)
    const [useAt         , setUseAt         ] = React.useState<string>(""); // 사용여부(radio)

    // 저장 버튼 클릭
    const onSubmit = () => {
        // 법령 상세명
        if (Util.isEmpty(lawordDetailNm)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "법령 상세명을 입력해 주세요.",
                icon: "warning"
            });
            return;
        }

        // 특례여부
        if (Util.isEmpty(excptAt)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "특례여부를 선택해 주세요.",
                icon: "warning"
            });
            return;
        }

        // 유효연도햇수
        if (Util.isEmpty(validYearNum)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "유효연도햇수를 입력해 주세요.",
                icon: "warning"
            });
            return;
        }

        // 감면율
        if (Util.isEmpty(rdcRate)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "감면율을 입력해 주세요.",
                icon: "check"
            });
            return;
        }

        // 주택수 제외여부
        if (Util.isEmpty(hseExcptAt)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "주택수 제외여부를 선택해 주세요.",
                icon: "check"
            });
            return;
        }

        // 사용여부
        if (Util.isEmpty(useAt)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "사용여부를 선택해 주세요.",
                icon: "check"
            });
            return;
        }

        let modName = "";
        let actUrl = "";
        if (callMode === "ins") {
            modName = "추가";
            actUrl  = "insLawordDetail.do";
        } else if (callMode === "upd") {
            modName = "수정";
            actUrl  = "updLawordDetail.do";
        } else {
            AppContext.openAlert({
                title: "시스템 오류",
                msg: "호출 구분 값이 없습니다.",
                icon: "warning"
            });
            return;
        }

        AppContext.openAlert({
            title: "법령 Detail " + modName,
            msg: "법령 Detail을 " + modName + "하시겠습니까?",
            icon: "check",
            confirmText: modName,
            handleConfirm: () => {
                Axios.dataAccess({
                    url: "admin/sysmng/laword/" + actUrl,
                    methodType: "post",
                    paramType: "json",
                    paramData: {
                        lawordSn      : lawordSn            , // 법령 일련번호
                        lawordDetailSn: lawordDetailSn      , // 법령상세일련번호
                        lawordDetailNm: lawordDetailNm      , // 법령 상세명
                        lawordDetailDc: lawordDetailDc      , // 법령상세설명
                        excptAt       : excptAt             , // 특례여부
                        validYearNum  : Number(validYearNum), // 유효연도햇수
                        rdcRate       : Number(rdcRate)     , // 감면율
                        hseExcptAt    : hseExcptAt          , // 주택수제외여부
                        useAt         : useAt               , // 사용여부
                    },
                    onSuccessFn: () => {
                        setModalDetailOpen(false);
                        setLawordDetailSelTime(new Date());   // 법령 메인 리스트 재조회 처리용
                    }
                });
            }
        });
    };

    // 법령 Detail 정보 셋팅
    React.useEffect(() => {
        if (callMode === "upd") {
            Axios.dataAccess({
                url: "admin/sysmng/laword/selLawordDetail.do",
                methodType: "post",
                paramType: "object",
                paramData: { lawordSn      : lawordSn      , // 법령 일련번호
                             lawordDetailSn: lawordDetailSn  // 법령상세일련번호
                },
                onSuccessFn: (res: any) => {
                    setLawordDetailNm(res.item.lawordDetailNm); // 법령상세명
                    setLawordDetailDc(res.item.lawordDetailDc); // 법령상세설명
                    setExcptAt       (res.item.excptAt       ); // 특례여부
                    setValidYearNum  (res.item.validYearNum  ); // 유효연도햇수
                    setRdcRate       (res.item.rdcRate       ); // 감면율
                    setHseExcptAt    (res.item.hseExcptAt    ); // 주택수제외여부
                    setUseAt         (res.item.useAt         ); // 사용여부
                }
            });
        }
    }, [callMode, setModalDetailOpen, lawordSn, lawordDetailSn]);

    return (
        <React.Fragment>
            {/* 왼쪽, 오른쪽으로 구분 */}
            <div className='w860 h100p bdBox dpFlx aiC'>

                {/* 왼쪽 입력부분 Start */}
                <div className='w50p'>
                    {/* 법령 일련번호 */}
                    <div className='dpFlx h40 pb10'>
                        <div className='w100 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>일련번호</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={lawordSn.toString()}
                                width={300}
                                height={40}
                                fontSize={15}
                                maxLength={30}
                                // placeholder="감면율을 입력해 주세요."
                                // onChangeFunc={(text: string) => setLawordSn(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                                mode="disabled"
                            />
                        </div>
                    </div>

                    {/* 법령 상세일련번호 */}
                    <div className='dpFlx h40 pb10'>
                        <div className='w100 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>상세 일련번호</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={callMode === "ins" ? "자동입력" : lawordDetailSn.toString()}
                                width={300}
                                height={40}
                                fontSize={15}
                                maxLength={30}
                                placeholder="상세 일련번호을 입력해 주세요."
                                // onChangeFunc={(text: string) => setLawordDetailSn(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                                mode="disabled"
                            />
                        </div>
                    </div>
                </div>
                {/* 왼쪽 입력부분 End */}

                {/* 오른쪽 입력부분 Start */}
                <div className='w50p pl30'>
                    {/* 법령명 */}
                    <div className='h40 dpFlx pb10'>
                        <div className='w100 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>법령명</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={lawordNm}
                                width={300}
                                height={40}
                                fontSize={15}
                                maxLength={30}
                                // placeholder="유효연도햇수를 입력해 주세요."
                                // onChangeFunc={(text: string) => setLawordNm(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                                mode="disabled"
                            />
                        </div>
                    </div>

                    {/* 법령 상세명 */}
                    <div className='h40 dpFlx pb10'>
                        <div className='w100 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>법령 상세명</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={lawordDetailNm}
                                width={300}
                                height={40}
                                fontSize={15}
                                maxLength={30}
                                placeholder="법령 상세명을 입력해 주세요."
                                onChangeFunc={(text: string) => setLawordDetailNm(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                            />
                        </div>
                    </div>
                </div>
                {/* 오른쪽 입력부분 End */}
            </div>

            {/* 한 row에 1개씩 element */}
            <div className='w860 h100p bdBox dpFlx aiC'>
                <div className='w100p'>
                    {/* 법령 상세설명 */}
                    <div className='dpFlx h40 pb10'>
                        <div className='w100 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>법령 상세설명</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={lawordDetailDc}
                                width={745}
                                height={40}
                                fontSize={15}
                                maxLength={30}
                                placeholder="법령 상세설명을 입력해 주세요."
                                onChangeFunc={(text: string) => setLawordDetailDc(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* 왼쪽, 오른쪽으로 구분 */}
            <div className='w860 h100p bdBox dpFlx aiC'>

                {/* 왼쪽 입력부분 Start */}
                <div className='w50p'>
                    {/* 특례 여부 */}
                    <div className='dpFlx h40 pb10'>
                        <div className='w100 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>특례 여부</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <ImageRadioBox
                                codeList={Util.getCodeArray("1036")}
                                value={excptAt}
                                setValue={setExcptAt}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                            />
                        </div>
                    </div>

                    {/* 감면율 */}
                    <div className='dpFlx h40 pb10'>
                        <div className='w100 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>감면율</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <NumberTextBox
                                value={rdcRate}
                                onChange={(data: string) => setRdcRate(data)}
                                borderClass="bgcWhite w300 h40 pr15 bd1"
                                // thousandsGroupStyle="thousand"
                                // thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                                // unitText="원"
                            />
                        </div>
                    </div>

                    {/* 사용 여부 */}
                    <div className='dpFlx h40 pb10'>
                        <div className='w100 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>사용 여부</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <ImageRadioBox
                                codeList={Util.getCodeArray("1036")}
                                value={useAt}
                                setValue={setUseAt}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                            />
                        </div>
                    </div>
                </div>
                {/* 왼쪽 입력부분 End */}

                {/* 오른쪽 입력부분 Start */}
                <div className='w50p pl30'>
                    {/* 유효연도햇수 */}
                    <div className='h40 dpFlx pb10'>
                        <div className='w100 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>유효연도햇수</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                        <NumberTextBox
                            value={validYearNum}
                            onChange={(data: string) => setValidYearNum(data)}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            // thousandsGroupStyle="thousand"
                            // thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                            // unitText="원"
                        />
                        </div>
                    </div>

                    {/* 주택수 제외여부 */}
                    <div className='h40 dpFlx pb10'>
                        <div className='w100 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>주택수 제외여부</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <ImageRadioBox
                                codeList={Util.getCodeArray("1036")}
                                value={hseExcptAt}
                                setValue={setHseExcptAt}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                            />
                        </div>
                    </div>
                    {/* 빈 자리 */}
                    <div className='dpFlx h40 pb10'></div>
                </div>
                {/* 오른쪽 입력부분 End */}
            </div>

            <div className='w100p dpFlx aiC jcC pt40'>
                <div className='w210 h56 bgcF6F6F6 dpFlx aiC jcC csP' onClick={() => setModalDetailOpen(false)}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>취소</span>
                </div>
                <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={onSubmit}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>저장</span>
                </div>
            </div>
        </React.Fragment>
    )
}
export default LawordDetailDialog;