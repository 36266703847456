/*******************************************************************************
*** ChangePwResult ** 비밀번호 변경 결과
*******************************************************************************/
import AppContext from "js/common/AppContext";

function ChangePwResult() {
    return (
        <div className='w460'>
            <div className='w100p h160 dpFlx aiC jcC'>
                <span className="SpoqaHanSansNeo-Bold fs36 ls1 fcBlack">비밀번호 재설정</span>
            </div>
            <div className="w460 m0auto dpFlx pt40 mb15 aiC jcC">
                <span className="w460 h25 SpoqaHanSansNeo-Medium fs20 ls1 taC">비밀번호 변경 완료</span>
            </div>
            <div className="w460 m0auto dpFlx aiC jcC">
                <span className="w460 h30 SpoqaHanSansNeo-Regular fs18 ls09 taC fc666666">
                    새로운 비밀번호로 로그인해 주세요.
                </span>
            </div>
            <div className='w460 m0auto h62 bgc17A840 mt40 mb100 dpFlx aiC jcC csP' onClick={() => AppContext.goPage("/portal/login")}>
                <span className="SpoqaHanSansNeo-Bold fs18 ls08 fcWhite">로그인</span>
            </div>
        </div>
    )
}
export default ChangePwResult;