import { useState } from "react";
import styled from "styled-components";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import {
  Code,
  Housing,
  PropTaxParamDisabled,
  PropTaxParamProps,
  PropTaxParamVisible,
} from "js/common/types/types";
import { MainSectionContainer, InputContainer } from "js/mobile/common/layout";
import { PropTaxAction, PropTaxActionType } from "./propTaxStatesReducer";
import SelectBox, { SelectOption } from "js/mobile/common/SelectBox";
import AddressInfoDialog from "./AddressInfoDialog";
import PropInfoSwiper from "./PropInfoSwiper";
import Util from "js/common/Util";
import AppContext from "js/common/AppContext";

type Props = {
  inputValue: PropTaxParamProps;
  inputVisible: PropTaxParamVisible;
  inputDisabled: PropTaxParamDisabled;
  onUpdateInput: (action: PropTaxAction) => void;
  apiStatus: string;
};

function BasicInfo({
  inputValue,
  inputVisible,
  inputDisabled,
  onUpdateInput,
  apiStatus,
}: Props) {
  const [addrDialogOpen, setAddrDialogOpen] = useState<boolean>(false);

  const createSelectHandleFunc = (
    selected: SelectOption,
    actionType: PropTaxActionType
  ) => {
    return onUpdateInput({
      type: actionType,
      payload: selected.value,
    });
  };

  const handleAddHousing = (housing: Housing) => {
    onUpdateInput({
      type: "ADDED_HOUSING",
      payload: housing,
    });
  };

  const handleOpenAddrDialog = () => {
    if (inputValue.houseNo === "") {
      AppContext.openAlert({
        title: "주택수",
        msg: "주택수를 선택하세요.",
        icon: "check",
      });
      return;
    }
    if (inputValue.houseNo === "201") {
      // 1주택
      if (inputValue.housing.length === 1) {
        AppContext.openAlert({
          title: "주택정보 추가 완료",
          msg: "1주택의 정보가 추가되어 더 이상 추가할 수 없습니다.",
          icon: "check",
        });
      } else {
        setAddrDialogOpen(true);
      }
    } else {
      // 2주택 이상
      setAddrDialogOpen(true);
    }
  };

  const handleDeleteHousing = (housing: Housing) => {
    onUpdateInput({
      type: "DELETED_HOUSING",
      payload: housing.pnu,
    });
  };

  return (
    <MainSectionContainer>
      <p className="desc">
        <span>자산의</span>
        <span className="bold"> 기초정보</span>
        <span>를 입력해주세요.</span>
      </p>
      {/* 01. 주택수 */}
      {inputVisible.visible01 && (
        <InputContainer>
          <p className="label">주택수</p>
          <div className="input">
            <SelectBox
              placeholder="주택수를 선택해주세요."
              options={Util.getCodeArray("2020").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled01}
              selectedValue={inputValue.houseNo}
              handleSelect={(selected: SelectOption) =>
                createSelectHandleFunc(selected, "UPDATED_HOUSE_NO")
              }
            />
          </div>
        </InputContainer>
      )}
      {/* 02. 주택정보 */}
      {inputVisible.visible02 && (
        <InputContainer>
          <p className="label">
            주택정보
            <PropCount>{inputValue.housing.length}</PropCount>
          </p>
          <div className="input">
            {inputValue.housing.length > 0 && (
              <PropInfoSwiper
                housings={inputValue.housing}
                onDelete={handleDeleteHousing}
              />
            )}
            <PropInfoAddButton onClick={handleOpenAddrDialog}>
              <div className="buttonTextWrap">
                <HomeWorkOutlinedIcon fontSize="small" />
                <span>주택 정보 추가</span>
              </div>
              <AddOutlinedIcon fontSize="small" />
            </PropInfoAddButton>
          </div>
        </InputContainer>
      )}
      <AddressInfoDialog
        open={addrDialogOpen}
        setOpen={setAddrDialogOpen}
        onAddHousing={handleAddHousing}
        apiStatus={apiStatus}
      />
    </MainSectionContainer>
  );
}

const PropInfoAddButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 12px 12px 12px 16px;
  margin-top: 12px;
  background-color: white;
  border-radius: 8px;

  font-family: "Spoqa Han Sans Neo";
  font-weight: 400;
  font-size: 1.4em;
  line-height: 1.8;
  letter-spacing: -0.028em;
  color: #727b8e;

  .buttonTextWrap {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`;

const PropCount = styled.span`
  padding: 4px 17px;
  margin-left: 9px;
  border-radius: 17px;
  background-color: ${(props) => props.theme.color.sOrangeLight01};

  font-family: "Spoqa Han Sans Neo";
  font-weight: 400;
  font-size: 14px;
  line-height: 1.8;
  letter-spacing: -0.028em;
  color: white;
`;

export default BasicInfo;
