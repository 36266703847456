import { useState, useEffect, Dispatch, useCallback } from "react";
import { produce } from "immer";
import { useRecoilState } from "recoil";

import {
  addrSearchResultState,
  acquiredAssetInfoState,
  directAddrInfoState,
} from "js/common/Atom";
import { AddrSearchResult, Step, Juso } from "js/common/types/types";
import { TranTaxAction, TranTaxState } from "./tranTaxStatesReducer";
import Util from "js/common/Util";
import AppContext from "js/common/AppContext";

const stepList: Step[] = [
  {
    stepId: "TRAN01",
    name: "기초 정보 입력",
    order: 1,
    useYn: true,
    selected: true,
    subSteps: [],
  },
  {
    stepId: "TRAN02",
    name: "주소 정보 입력",
    order: 2,
    useYn: false,
    selected: false,
    subSteps: [
      {
        stepId: "TRAN0201",
        name: "주소 입력",
        order: 1,
        useYn: true,
        selected: true,
        subSteps: [],
      },
      {
        stepId: "TRAN0202",
        name: "상세 주소 입력",
        order: 2,
        useYn: true,
        selected: false,
        subSteps: [],
      },
      {
        stepId: "TRAN0203",
        name: "최종 주소 확인",
        order: 3,
        useYn: true,
        selected: false,
        subSteps: [],
      },
    ],
  },
  {
    stepId: "TRAN03",
    name: "부가 정보 입력",
    order: 3,
    useYn: true,
    selected: false,
    subSteps: [
      {
        stepId: "TRAN0301",
        name: "종전주택 정보 입력",
        order: 1,
        useYn: false,
        selected: true,
        subSteps: [],
      },
      {
        stepId: "TRAN0302",
        name: "신규주택 정보 입력",
        order: 2,
        useYn: false,
        selected: false,
        subSteps: [],
      },
    ],
  },
];

export function useTranCalcSteps(
  tranTaxState: TranTaxState,
  onUpdateTranTaxState: Dispatch<TranTaxAction>
) {
  const [addrSearchResult, setAddrSearchResult] = useRecoilState(
    addrSearchResultState
  );
  const [acquiredAssetInfo, setAcquiredAssetInfo] = useRecoilState(
    acquiredAssetInfoState
  );
  const [directAddrInfo, setDirectAddrInfo] =
    useRecoilState(directAddrInfoState);

  const [tranStepList, setTranStepList] = useState<Step[]>(stepList);
  const [backDisabled, setBackDisabled] = useState<boolean>(true);
  const [nextDisabled, setNextDisabled] = useState<boolean>(true);
  const [isLastStep, setIsLastStep] = useState<boolean>(false);
  const [addrSearchQuery, setAddrSearchQuery] = useState<string>("");
  const [addrQueryResult, setAddrQueryResult] = useState<Juso[]>([]);

  const usingStepList = tranStepList.filter((step: Step) => step.useYn);
  const currentTranStep = usingStepList.find((step: Step) => step.selected);

  const checkFormerHouseInfo = useCallback((): boolean => {
    const inputVisible = tranTaxState.inputVisible;
    const inputValue = tranTaxState.inputValue;

    let validateYn = true;

    if (inputVisible.visible16) {
      if (Util.isEmpty(inputValue.mngDispAppDate)) {
        validateYn = false;
      }
    }

    if (inputVisible.visible17) {
      if (
        Util.isEmpty(inputValue.mngDispAppDateAmt) ||
        Number(inputValue.mngDispAppDateAmt) <= 0
      ) {
        validateYn = false;
      }
    }

    if (inputVisible.visible18) {
      if (Util.isEmpty(inputValue.acquDatePreHse)) {
        validateYn = false;
      }
    }

    if (inputVisible.visible18_1) {
      if (Util.isEmpty(inputValue.acquDateInherit)) {
        validateYn = false;
      }
    }

    if (inputVisible.visible19) {
      if (Util.isEmpty(inputValue.resiPeriodPreHse)) {
        validateYn = false;
      }
    }

    if (inputVisible.visible20) {
      if (
        Util.isEmpty(inputValue.acquPrice) ||
        Number(inputValue.acquPrice) <= 0
      ) {
        validateYn = false;
      }
    }

    if (inputVisible.visible21) {
      if (Util.isEmpty(inputValue.neceExpenPreHse)) {
        validateYn = false;
      }
    }

    return validateYn;
  }, [tranTaxState.inputValue, tranTaxState.inputVisible]);

  const checkNewHouseInfo = useCallback((): boolean => {
    const inputVisible = tranTaxState.inputVisible;
    const inputValue = tranTaxState.inputValue;

    let validateYn = true;

    if (inputVisible.visible22) {
      if (Util.isEmpty(inputValue.apprDateNewHse)) {
        validateYn = false;
      }
    }

    if (inputVisible.visible23) {
      if (
        Util.isEmpty(inputValue.resiPeriodNewHse) ||
        Number(inputValue.resiPeriodNewHse) < 0
      ) {
        validateYn = false;
      }
    }

    if (inputVisible.visible24) {
      if (Util.isEmpty(inputValue.transferDate)) {
        validateYn = false;
      }
    }

    // 취득일자 & 양도일자 체크
    if (
      inputVisible.visible24 &&
      inputVisible.visible18 &&
      !Util.isEmpty(inputValue.acquDatePreHse) &&
      !Util.isEmpty(inputValue.transferDate)
    ) {
      if (Number(inputValue.acquDatePreHse) > Number(inputValue.transferDate)) {
        validateYn = false;
        AppContext.openAlert({
          title: "양도일자",
          msg: "양도일자가 취득일자보다 빠릅니다. 양도일자를 확인하세요.",
          icon: "check",
        });
      }
    }

    if (inputVisible.visible25) {
      if (
        Util.isEmpty(inputValue.transferPrice) ||
        Number(inputValue.transferPrice) <= 0
      ) {
        validateYn = false;
      }
    }

    if (inputVisible.visible26) {
      if (
        Util.isEmpty(inputValue.neceExpenNewHse) ||
        Number(inputValue.neceExpenNewHse) < 0
      ) {
        validateYn = false;
      }
    }

    if (inputVisible.visible27) {
      if (Util.isEmpty(inputValue.liqudAmt)) {
        validateYn = false;
      }
    }

    if (inputVisible.visible28) {
      if (
        Util.isEmpty(inputValue.payOrRecAmt) ||
        Number(inputValue.payOrRecAmt) <= 0
      ) {
        validateYn = false;
      }
    }

    return validateYn;
  }, [tranTaxState.inputValue, tranTaxState.inputVisible]);

  useEffect(() => {
    // TranTaxCalc 컴포넌트 unmount 시 cleanup 함수 실행 -> 상태 초기화
    return () => {
      setTranStepList(stepList);
      setAddrSearchQuery("");

      setAddrSearchResult((prev: AddrSearchResult) => ({
        ...prev,
        addr: null,
        dong: "",
        ho: "",
        bdType: "",
      }));

      setAcquiredAssetInfo({
        spfcKnd: "", // 용도지역
        mdatTrgetAreaAt: "", // 조장지역여부
        pblntfPc: "", // 공동주택가격
        housePc: "", // 개별주택갸격
        shareRatio: "", // 지분율
        apprDateNewHse: "", // 사용승인일
        prvuseAr: "", // 공동주택 전용면적
        houseTotar: "", // 단독주택 전용면적
      });

      setDirectAddrInfo({
        bdKnd: "1",
        sidoCode: "", // 시도정보
        sggCode: "", // 시군구정보
        sggCodeElVsb: true, // 시군구정보 표시여부
        emdCode: "", // 읍면동 정보
        sidoName: "", // 시도
        sggName: "", //시군구
        emdName: "", //읍면동
        sggCodeInfoList: [],
        emdCodeInfoList: [],
        rdnmAdresDetail: "", // 상세주소
        spfcKnd: "", // 용도지역
        mdatTrgetAreaAt: "", // 조장지역여부
        pblntfPc: "", // 공동주택가격
        shareRatio: "", // 지분율
        prvuseAr: "", // 공동주택 전용면적
      });
    };
  }, [setAddrSearchResult, setAcquiredAssetInfo, setDirectAddrInfo]);

  useEffect(() => {
    // 양도할 자산정보(12 ~ 15)가 visible 이면 주소정보입력 단계 활성화
    if (tranTaxState.inputVisible.visible12) {
      setTranStepList(
        produce((draft: Step[]) => {
          const step = draft.find((step) => step.stepId === "TRAN02");
          if (step) {
            step.useYn = true;
          }
        })
      );
    } else {
      setTranStepList(
        produce((draft: Step[]) => {
          const step = draft.find((step) => step.stepId === "TRAN02");
          if (step) {
            step.useYn = false;
          }
        })
      );
    }
  }, [tranTaxState.inputVisible.visible12]);

  useEffect(() => {
    // 단계가 바뀌면 next 버튼 비활성화
    setNextDisabled(true);
  }, [currentTranStep]);

  useEffect(() => {
    if (currentTranStep && currentTranStep.stepId === "TRAN01") {
      setBackDisabled(true);
    } else {
      setBackDisabled(false);
    }
  }, [currentTranStep]);

  useEffect(() => {
    // 마지막 단계에 도달했는지 여부 확인
    if (!currentTranStep) return;

    if (currentTranStep.stepId === "TRAN03") {
      const extraInfoSubSteps = currentTranStep.subSteps.filter(
        (step: Step) => step.useYn
      );

      if (extraInfoSubSteps.length === 0) {
        // 세부 단계 없음
        setIsLastStep(true);
      } else {
        // 종전주택과 신규주택으로 구분하여 입력 받음
        const currentExtraSubStep = extraInfoSubSteps.find(
          (step: Step) => step.selected
        );

        if (!currentExtraSubStep) return;

        if (currentExtraSubStep.stepId === "TRAN0302") {
          setIsLastStep(true);
        } else {
          setIsLastStep(false);
        }
      }
    } else {
      setIsLastStep(false);
    }
  }, [currentTranStep]);

  useEffect(() => {
    // 종전주택과 신규주택으로 구분하여 입력값 받는 case 확인
    const inputValue = tranTaxState.inputValue;
    if (
      inputValue.estateSe === "201" &&
      inputValue.estateFom1 === "201" &&
      inputValue.acqsSrc1 === "203" &&
      inputValue.acqsCause3 === "202"
    ) {
      // 주택 > 아파트/연립 > 조합원입주권에 의한 아파트 > 원조합원
      setTranStepList(
        produce((draft: Step[]) => {
          const extraInfoStep = draft.find(
            (step: Step) => step.stepId === "TRAN03"
          );
          if (extraInfoStep) {
            const subSteps = extraInfoStep.subSteps;
            subSteps.forEach((step) => (step.useYn = true));
          }
        })
      );
    } else if (
      inputValue.estateSe === "203" &&
      inputValue.acqsCause3 === "202"
    ) {
      // 조합원입주권 > 원조합원
      setTranStepList(
        produce((draft: Step[]) => {
          const extraInfoStep = draft.find(
            (step: Step) => step.stepId === "TRAN03"
          );
          if (extraInfoStep) {
            const subSteps = extraInfoStep.subSteps;
            subSteps.forEach((step) => (step.useYn = true));
          }
        })
      );
    } else {
      // 위 두가지 경우가 아니라면 부가정보입력 단계에서 세부단계 사용하지 않음.
      setTranStepList(
        produce((draft: Step[]) => {
          const extraInfoStep = draft.find(
            (step: Step) => step.stepId === "TRAN03"
          );
          if (extraInfoStep) {
            const subSteps = extraInfoStep.subSteps;
            subSteps.forEach((step) => (step.useYn = false));
          }
        })
      );
    }
  }, [tranTaxState.inputValue]);

  useEffect(() => {
    // 기초정보입력 단계 완료 여부 확인
    if (!currentTranStep || currentTranStep.stepId !== "TRAN01") return;

    console.info(">>> 기초정보입력 단계 완료 여부 확인");
    const inputVisible = tranTaxState.inputVisible;
    const inputValue = tranTaxState.inputValue;

    let validateYn = true;
    if (inputVisible.visible01) {
      if (Util.isEmpty(inputValue.estateSe)) {
        validateYn = false;
      }
    }
    if (inputVisible.visible02) {
      if (Util.isEmpty(inputValue.estateFom1)) {
        validateYn = false;
      }
    }
    if (inputVisible.visible03) {
      if (Util.isEmpty(inputValue.estateFom2)) {
        validateYn = false;
      }
    }
    if (inputVisible.visible04) {
      if (Util.isEmpty(inputValue.landCls)) {
        validateYn = false;
      }
    }
    if (inputVisible.visible05) {
      if (Util.isEmpty(inputValue.acqsSrc1)) {
        validateYn = false;
      }
    }
    if (inputVisible.visible06) {
      if (Util.isEmpty(inputValue.acqsSrc2)) {
        validateYn = false;
      }
    }
    if (inputVisible.visible07) {
      if (Util.isEmpty(inputValue.acqsCause1)) {
        validateYn = false;
      }
    }
    if (inputVisible.visible08) {
      if (Util.isEmpty(inputValue.acqsCause2)) {
        validateYn = false;
      }
    }
    if (inputVisible.visible09) {
      if (Util.isEmpty(inputValue.acqsCause3)) {
        validateYn = false;
      }
    }
    if (inputVisible.visible10) {
      if (Util.isEmpty(inputValue.acqsCause4)) {
        validateYn = false;
      }
    }
    if (inputVisible.visible11) {
      if (Util.isEmpty(inputValue.hseNum)) {
        validateYn = false;
      }
    }

    setNextDisabled(!validateYn);
  }, [tranTaxState.inputVisible, tranTaxState.inputValue, currentTranStep]);

  useEffect(() => {
    // 주소정보입력 단계 완료 여부 확인
    if (
      !currentTranStep ||
      currentTranStep.stepId !== "TRAN02" ||
      addrSearchResult.error === "-1"
    )
      return;

    const currentAddrStep = currentTranStep.subSteps.find(
      (step: Step) => step.selected
    );

    if (!currentAddrStep) return;

    switch (currentAddrStep.stepId) {
      case "TRAN0201": {
        // 주소검색
        setNextDisabled(true);
        break;
      }
      case "TRAN0202": {
        // 동/호 정보 입력
        if (addrSearchResult.addr) {
          if (addrSearchResult.bdType === "dongHo") {
            if (addrSearchResult.dong !== "" && addrSearchResult.ho !== "") {
              setNextDisabled(false);
            } else {
              setNextDisabled(true);
            }
          } else if (addrSearchResult.bdType === "multiHo") {
            if (addrSearchResult.ho !== "") {
              setNextDisabled(false);
            } else {
              setNextDisabled(true);
            }
          } else if (addrSearchResult.bdType === "none") {
            setNextDisabled(false);
          } else {
            setNextDisabled(true);
          }
        } else {
          setNextDisabled(true);
        }
        break;
      }
      case "TRAN0203": {
        if (
          acquiredAssetInfo.mdatTrgetAreaAt !== "" &&
          acquiredAssetInfo.shareRatio !== "" &&
          parseInt(acquiredAssetInfo.shareRatio) > 0 &&
          parseInt(acquiredAssetInfo.shareRatio) <= 100
        ) {
          setNextDisabled(false);
        } else {
          setNextDisabled(true);
        }

        break;
      }
    }
  }, [currentTranStep, addrSearchResult, acquiredAssetInfo]);

  useEffect(() => {
    // 주소정보입력 단계 완료 여부 확인 -> 주소 직접입력
    if (
      !currentTranStep ||
      currentTranStep.stepId !== "TRAN02" ||
      addrSearchResult.error !== "-1"
    )
      return;

    /* 주소 직접입력 단계 확인*/
    const checkDirectAddrInfo = (): boolean => {
      let checkResult = true;

      if (Util.isEmpty(directAddrInfo.sidoCode)) {
        checkResult = false;
      }

      if (Util.isEmpty(directAddrInfo.sggCode)) {
        checkResult = false;
      }

      if (Util.isEmpty(directAddrInfo.emdCode)) {
        checkResult = false;
      }

      if (Util.isEmpty(directAddrInfo.rdnmAdresDetail)) {
        checkResult = false;
      }

      if (Util.isEmpty(directAddrInfo.mdatTrgetAreaAt)) {
        checkResult = false;
      }

      if (Util.isEmpty(directAddrInfo.shareRatio)) {
        checkResult = false;
      }

      if (
        Number(directAddrInfo.shareRatio) <= 0 ||
        Number(Util.uncomma(directAddrInfo.shareRatio)) > 100
      ) {
        checkResult = false;
      }

      return checkResult;
    };

    const checkResult: boolean = checkDirectAddrInfo();
    // checkResult true이면 주소정보 입력 완료
    setNextDisabled(!checkResult);
  }, [addrSearchResult.error, directAddrInfo, currentTranStep]);

  useEffect(() => {
    // 부가정보입력 단계 완료 여부 확인
    if (!currentTranStep || currentTranStep.stepId !== "TRAN03") return;

    const extraInfoSubSteps = currentTranStep.subSteps.filter(
      (step: Step) => step.useYn
    );

    if (extraInfoSubSteps.length === 0) {
      // 세부 단계 없음

      const isFormerHouseInfoFilled = checkFormerHouseInfo();
      const isNewHouseInfoFilled = checkNewHouseInfo();

      if (isFormerHouseInfoFilled && isNewHouseInfoFilled) {
        setNextDisabled(false);
      } else {
        setNextDisabled(true);
      }
    } else {
      // 종전주택과 신규주택으로 구분하여 입력 받음
      const currentExtraSubStep = extraInfoSubSteps.find(
        (step: Step) => step.selected
      );

      if (!currentExtraSubStep) return;

      switch (currentExtraSubStep.stepId) {
        case "TRAN0301": {
          // 종전주택정보입력
          const isFormerHouseInfoFilled = checkFormerHouseInfo();
          setNextDisabled(!isFormerHouseInfoFilled);
          break;
        }
        case "TRAN0302": {
          // 신규주택정보입력
          const isNewHouseInfoFilled = checkNewHouseInfo();
          setNextDisabled(!isNewHouseInfoFilled);
          break;
        }
      }
    }
  }, [currentTranStep, checkFormerHouseInfo, checkNewHouseInfo]);

  const handleClickNextStep = () => {
    if (!currentTranStep) return;

    switch (currentTranStep.stepId) {
      case "TRAN01": {
        // 기초정보입력
        moveToNextStep();
        break;
      }
      case "TRAN02": {
        // 주소정보입력
        if (addrSearchResult.error === "-1") {
          onNextInAddressInfo(
            directAddrInfo.mdatTrgetAreaAt,
            directAddrInfo.shareRatio,
            directAddrInfo.emdCode
          );
          moveToNextStep();
        } else {
          const currentAddrStep = currentTranStep.subSteps.find(
            (step: Step) => step.selected
          );
          if (currentAddrStep) {
            if (currentAddrStep.stepId === "TRAN0203") {
              addrSearchResult.addr &&
                onNextInAddressInfo(
                  acquiredAssetInfo.mdatTrgetAreaAt,
                  acquiredAssetInfo.shareRatio,
                  addrSearchResult.addr ? addrSearchResult.addr.admCd : ""
                );
              moveToNextStep();
            } else {
              moveToNextAddrSubStep();
            }
          }
        }
        break;
      }
      case "TRAN03": {
        // 부가정보입력
        const extraInfoSubStepList = currentTranStep.subSteps.filter(
          (step: Step) => step.useYn
        );

        if (extraInfoSubStepList.length === 0) {
          // 세부 단계 없음
        } else {
          // 종전주택과 신규주택으로 구분하여 입력 받음
          const currentExtraStep = extraInfoSubStepList.find(
            (step) => step.selected
          );
          if (currentExtraStep) {
            if (currentExtraStep.stepId === "TRAN0301") {
              // 종전주택정보입력
              moveToNextExtraSubStep();
            }
          }
        }
      }
    }
  };

  const moveToNextStep = () => {
    setTranStepList(
      produce((draft: Step[]) => {
        const usingSteps = draft.filter((step) => step.useYn);
        const selectedIndex = usingSteps.findIndex((step) => step.selected);
        if (selectedIndex !== -1 && selectedIndex < usingSteps.length - 1) {
          usingSteps[selectedIndex].selected = false;
          usingSteps[selectedIndex + 1].selected = true;
        }
      })
    );
  };

  const moveToNextAddrSubStep = () => {
    setTranStepList(
      produce((draft: Step[]) => {
        const addrInfoStep = draft.find(
          (step: Step) => step.stepId === "TRAN02"
        );
        if (addrInfoStep) {
          const subSteps = addrInfoStep.subSteps;
          const selectedIndex = addrInfoStep.subSteps.findIndex(
            (step) => step.selected
          );
          if (selectedIndex !== -1 && selectedIndex < subSteps.length - 1) {
            subSteps[selectedIndex].selected = false;
            subSteps[selectedIndex + 1].selected = true;
          }
        }
      })
    );
  };

  const moveToNextExtraSubStep = () => {
    setTranStepList(
      produce((draft: Step[]) => {
        const extraInfoStep = draft.find(
          (step: Step) => step.stepId === "TRAN03"
        );
        if (extraInfoStep) {
          const subSteps = extraInfoStep.subSteps;
          const selectedIndex = extraInfoStep.subSteps.findIndex(
            (step) => step.selected
          );
          if (selectedIndex !== -1 && selectedIndex < subSteps.length - 1) {
            subSteps[selectedIndex].selected = false;
            subSteps[selectedIndex + 1].selected = true;
          }
        }
      })
    );
  };

  const onNextInAddressInfo = (
    mdatTrgetArea: string,
    shareRatio: string,
    legalDongCode: string
  ) => {
    // 주소정보 입력 완료 시
    onUpdateTranTaxState({
      type: "UPDATED_ADDR_INFO",
      payload: {
        mdatTrgetArea: mdatTrgetArea,
        shareRatio: shareRatio,
        legalDongCode: legalDongCode,
      },
    });
  };

  const handleClickReset = () => {
    if (!currentTranStep) return;

    switch (currentTranStep.stepId) {
      case "TRAN01": {
        // 기초정보입력
        onUpdateTranTaxState({
          type: "INIT_BASIC_INFO",
          payload: "",
        });
        break;
      }
      case "TRAN02": {
        // 주소정보입력
        onResetInAddresInfo();

        // 주소정보입력 세부 1단계에서 reset 버튼 누르면 검색어/검색결과 초기화
        const currentAddrSubStep = currentTranStep.subSteps.find(
          (step: Step) => step.selected
        );
        if (currentAddrSubStep && currentAddrSubStep.stepId === "TRAN0201") {
          setAddrSearchQuery("");
        }

        // 주소정보입력 세부 1단계로 이동
        setTranStepList(
          produce((draft: Step[]) => {
            const addrInfoStep = draft.find(
              (step: Step) => step.stepId === "TRAN02"
            );
            if (addrInfoStep) {
              const subSteps = addrInfoStep.subSteps;
              const selectedIndex = addrInfoStep.subSteps.findIndex(
                (step) => step.selected
              );
              if (selectedIndex !== -1 && selectedIndex < subSteps.length - 1) {
                subSteps[selectedIndex].selected = false;
                subSteps[0].selected = true;
              }
            }
          })
        );

        break;
      }
      case "TRAN03": {
        // 부가정보입력
        const extraInfoSubStepList = currentTranStep.subSteps.filter(
          (step: Step) => step.useYn
        );

        if (extraInfoSubStepList.length === 0) {
          // 세부 단계 없음
          onUpdateTranTaxState({
            type: "INIT_FORMER_HOUSE_INFO",
            payload: "",
          });

          onUpdateTranTaxState({
            type: "INIT_NEW_HOUSE_INFO",
            payload: "",
          });
        } else {
          // 종전주택과 신규주택으로 구분하여 입력 받음
          const currentExtraStep = extraInfoSubStepList.find(
            (step) => step.selected
          );
          if (currentExtraStep) {
            if (currentExtraStep.stepId === "TRAN0301") {
              // 종전주택정보입력
              onUpdateTranTaxState({
                type: "INIT_FORMER_HOUSE_INFO",
                payload: "",
              });
            } else if (currentExtraStep.stepId === "TRAN0302") {
              onUpdateTranTaxState({
                type: "INIT_NEW_HOUSE_INFO",
                payload: "",
              });
            }
          }
        }
      }
    }
  };

  const onResetInAddresInfo = () => {
    setAddrSearchResult((prev: AddrSearchResult) => ({
      ...prev,
      addr: null,
      dong: "",
      ho: "",
      bdType: "",
    }));

    setAcquiredAssetInfo({
      spfcKnd: "", // 용도지역
      mdatTrgetAreaAt: "", // 조장지역여부
      pblntfPc: "", // 공동주택가격
      housePc: "", // 개별주택갸격
      shareRatio: "", // 지분율
      apprDateNewHse: "", // 사용승인일
      prvuseAr: "", // 공동주택 전용면적
      houseTotar: "", // 단독주택 전용면적
    });

    setDirectAddrInfo({
      bdKnd: "1",
      sidoCode: "", // 시도정보
      sggCode: "", // 시군구정보
      sggCodeElVsb: true, // 시군구정보 표시여부
      emdCode: "", // 읍면동 정보
      sidoName: "", // 시도
      sggName: "", //시군구
      emdName: "", //읍면동
      sggCodeInfoList: [],
      emdCodeInfoList: [],
      rdnmAdresDetail: "", // 상세주소
      spfcKnd: "", // 용도지역
      mdatTrgetAreaAt: "", // 조장지역여부
      pblntfPc: "", // 공동주택가격
      shareRatio: "", // 지분율
      prvuseAr: "", // 공동주택 전용면적
    });
  };

  const onResetInDongHoSelect = () => {
    // 상세주소입력 단계에서 선택 초기화 -> 상세주소입력, 최종주소입력 단계의 input 모두 초기화
    setAddrSearchResult((prev: AddrSearchResult) => ({
      ...prev,
      addr: null,
      dong: "",
      ho: "",
      bdType: "",
    }));

    setAcquiredAssetInfo({
      spfcKnd: "", // 용도지역
      mdatTrgetAreaAt: "", // 조장지역여부
      pblntfPc: "", // 공동주택가격
      housePc: "", // 개별주택갸격
      shareRatio: "", // 지분율
      apprDateNewHse: "", // 사용승인일
      prvuseAr: "", // 공동주택 전용면적
      houseTotar: "", // 단독주택 전용면적
    });
  };

  const onResetInFinalAddrInfo = () => {
    // 최종주소입력 단계에서 초기화
    setAcquiredAssetInfo({
      spfcKnd: "", // 용도지역
      mdatTrgetAreaAt: "", // 조장지역여부
      pblntfPc: "", // 공동주택가격
      housePc: "", // 개별주택갸격
      shareRatio: "", // 지분율
      apprDateNewHse: "", // 사용승인일
      prvuseAr: "", // 공동주택 전용면적
      houseTotar: "", // 단독주택 전용면적
    });
  };

  const handleClickBackStep = () => {
    if (!currentTranStep) return;

    switch (currentTranStep.stepId) {
      case "TRAN01": {
        // 기초정보입력
        break;
      }
      case "TRAN02": {
        // 주소정보입력
        if (addrSearchResult.error === "-1") {
          onResetInAddresInfo();
          moveToBackStep();
        } else {
          const currentAddrStep = currentTranStep.subSteps.find(
            (step: Step) => step.selected
          );
          if (currentAddrStep) {
            if (currentAddrStep.stepId === "TRAN0201") {
              onResetInAddresInfo();
              setAddrSearchQuery(""); // 주소검색 -> 검색어/검색결과 초기화
              moveToBackStep();
            } else if (currentAddrStep.stepId === "TRAN0202") {
              onResetInDongHoSelect();
              moveToBackAddrSubStep();
            } else {
              onResetInFinalAddrInfo();
              moveToBackAddrSubStep();
            }
          }
        }
        break;
      }
      case "TRAN03": {
        // 부가정보입력
        const extraInfoSubStepList = currentTranStep.subSteps.filter(
          (step: Step) => step.useYn
        );

        if (extraInfoSubStepList.length === 0) {
          // 세부 단계 없음
          moveToBackStep();
          onUpdateTranTaxState({
            type: "INIT_FORMER_HOUSE_INFO",
            payload: "",
          });

          onUpdateTranTaxState({
            type: "INIT_NEW_HOUSE_INFO",
            payload: "",
          });
        } else {
          // 종전주택과 신규주택으로 구분하여 입력 받음
          const currentExtraStep = extraInfoSubStepList.find(
            (step) => step.selected
          );
          if (currentExtraStep) {
            if (currentExtraStep.stepId === "TRAN0301") {
              // 종전주택정보입력
              onUpdateTranTaxState({
                type: "INIT_FORMER_HOUSE_INFO",
                payload: "",
              });
              moveToBackStep();
            } else if (currentExtraStep.stepId === "TRAN0302") {
              onUpdateTranTaxState({
                type: "INIT_NEW_HOUSE_INFO",
                payload: "",
              });
              moveToBackExtraSubStep();
            }
          }
        }
      }
    }
  };

  const moveToBackStep = () => {
    setTranStepList(
      produce((draft: Step[]) => {
        const usingSteps = draft.filter((step) => step.useYn);
        const selectedIndex = usingSteps.findIndex((step) => step.selected);
        if (selectedIndex !== -1 && selectedIndex > 0) {
          usingSteps[selectedIndex].selected = false;
          usingSteps[selectedIndex - 1].selected = true;
        }
      })
    );
  };

  const moveToBackAddrSubStep = () => {
    setTranStepList(
      produce((draft: Step[]) => {
        const addrInfoStep = draft.find(
          (step: Step) => step.stepId === "TRAN02"
        );
        if (addrInfoStep) {
          const subSteps = addrInfoStep.subSteps;
          const selectedIndex = addrInfoStep.subSteps.findIndex(
            (step) => step.selected
          );
          if (selectedIndex !== -1 && selectedIndex > 0) {
            subSteps[selectedIndex].selected = false;
            subSteps[selectedIndex - 1].selected = true;
          }
        }
      })
    );
  };

  const moveToBackExtraSubStep = () => {
    setTranStepList(
      produce((draft: Step[]) => {
        const addrInfoStep = draft.find(
          (step: Step) => step.stepId === "TRAN03"
        );
        if (addrInfoStep) {
          const subSteps = addrInfoStep.subSteps;
          const selectedIndex = addrInfoStep.subSteps.findIndex(
            (step) => step.selected
          );
          if (selectedIndex !== -1 && selectedIndex > 0) {
            subSteps[selectedIndex].selected = false;
            subSteps[selectedIndex - 1].selected = true;
          }
        }
      })
    );
  };

  const initTranStep = () => {
    setTranStepList(stepList);
    onResetInAddresInfo();
    setAddrSearchQuery("");
  };

  return {
    tranStepList,
    currentTranStep,
    handleClickNextStep,
    handleClickBackStep,
    moveToNextAddrSubStep,
    handleClickReset,
    onResetInAddresInfo,
    backDisabled,
    nextDisabled,
    isLastStep,
    initTranStep,
    addrSearchQuery,
    setAddrSearchQuery,
    addrQueryResult,
    setAddrQueryResult,
  };
}
