import React from 'react';
import { clearInterval, setInterval } from 'worker-timers';

interface IUseInterval {
    (callback: () => void, interval: number): void;
}

const UseInterval: IUseInterval = (callback, interval) => {
    const savedCallback = React.useRef<(() => void) | null>(null);
    React.useEffect(() => {
        savedCallback.current = callback;
    });
    React.useEffect(() => {
        function tick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }

        let id = setInterval(tick, interval);
        return () => clearInterval(id);
    }, [interval]);
};
export default UseInterval;