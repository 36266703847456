/*******************************************************************************
*** Acqstax ** 취득관리 > 취득자산 등록/보유전환/취득세 계산
*******************************************************************************/
import React from 'react';
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import * as Atom from "js/common/Atom";
import AppContext from 'js/common/AppContext';
import Axios from 'js/common/Axios';
import MuiListFilter from 'js/common/ui/list/MuiListFilter';
import ForestTableStyle from 'js/common/ui/style/ForestStyle';
import Modal from 'js/common/ui/modal/Modal';
import AcqstaxCalc from './AcqstaxCalc';
import AssetsShow from '../assets/show/AssetsShow';

const Acqstax = () => {
    const navigate = useNavigate();
    const hshldSn = useRecoilValue(Atom.hshldSn);
    const hshldNm = useRecoilValue(Atom.hshldNm);
    const rmstnfamOrdFld = { name: "rmstnfamSe", direction: "asc" };
    const [rmstnfamDataArr, setRmstnfamDataArr] = React.useState<any[]>([]);
    const [assetsSn, setAssetsSn] = React.useState<number>();
    const [acqsAssetsDataArr, setAcqsAssetsDataArr] = React.useState<[]>([]);
    const [acqsAssetsSelTime, setAcqsAssetsSelTime] = React.useState<Date>(new Date());
    const setCurrFormMode = useSetRecoilState(Atom.currFormMode);
    const [acqstaxCalcModalOpen, setAcqstaxCalcModalOpen] = React.useState<boolean>(false); // 취득세 결과 조회 모달
    const [assetsDataArr, setAssetsDataArr] = React.useState<[]>([]);
    const [assetsSelTime, setAssetsSelTime] = React.useState<Date>(new Date());
    const [acqstaxCalcModalVsb, setAcqstaxCalcModalVsb] = React.useState<boolean>(false); // 취득세 결과 조회 표출
    const [assetsModalOpen, setAssetsModalOpen] = React.useState<boolean>(false); // 보유자산 조회 모달

    // 자산 수정
    const goAssetsEditPage = (item: any) => {
        AppContext.showSpinnerDim();
        setCurrFormMode("mod");
        if (["1009", "1010"].indexOf(item.stepCode) !== -1) {
            navigate("/system/assets/view/Acqstax/" + item.assetsSn);
        } else {
            navigate("/system/assets/mod/Acqstax/" + item.assetsSn);
        }
    }

    const goAssetsCalcPage = (item: any) => {
        if (item.estateSttus !== "1003") {
            AppContext.openAlert({
                title: "취득할 자산이 아닌 자산",
                msg: "취득할 자산을 대상으로 취득세 계산이 가능합니다.",
                icon: "check"
            });
            return;
        }
        if (item.stepCode !== "1009" && item.stepCode !== "1010") {
            AppContext.openAlert({
                title: "등록 진행 중 자산",
                msg: "등록완료된 자산을 대상으로 취득세 계산이 가능합니다.",
                icon: "check"
            });
            return;
        }
        setAssetsSn(item.assetsSn);
        setAcqstaxCalcModalOpen(true);
    }

    // 자산 복사
    const copyAssetsEditPage = (item: any) => {
        if (item.estateSttus !== "1003") {
            AppContext.openAlert({
                title: "취득할 자산이 아닌 자산",
                msg: "취득할 자산만 복사가 가능합니다.",
                icon: "check"
            });
            return;
        }
        AppContext.openAlert({
            title: "자산 복사",
            msg: item.rowNum + "번 자산을 복사하시겠습니까?",
            icon: "check",
            confirmText: "복사",
            handleConfirm: () => {
                Axios.dataAccess({
                    url: "system/assets/copyAssetsInfo.do",
                    methodType: "post",
                    paramType: "object",
                    paramData: {
                        trgtAssetsSn: item.assetsSn
                    },
                    onSuccessFn: () => {
                        setAcqsAssetsSelTime(new Date());
                        setAssetsSelTime(new Date());
                    }
                });
            }
        });
    }

    // 자산 보유
    const holdAcqsAssets = (item: any) => {
        if (item.estateSttus !== "1003") {
            AppContext.openAlert({
                title: "취득할 자산이 아닌 자산",
                msg: "취득할 자산만 보유전환이 가능합니다.",
                icon: "check"
            });
            return;
        }
        if (item.stepCode === "1010") {
            AppContext.openAlert({
                title: "보유처리된 자산",
                msg: "이미 보유 처리된 취득자산은 처리 불가합니다.",
                icon: "check"
            });
            return;
        }
        if (item.stepCode !== "1009") {
            AppContext.openAlert({
                title: "보유불가 상태",
                msg: "등록완료된 취득자산만 보유처리 가능합니다.",
                icon: "check"
            });
            return;
        }
        AppContext.openAlert({
            title: "자산 보유",
            msg: item.assetsSn + "번 취득자산을 보유하시겠습니까?",
            icon: "check",
            confirmText: "보유",
            handleConfirm: () => {
                Axios.dataAccess({
                    url: "system/acqstax/holdAcqsAssets.do",
                    methodType: "post",
                    paramType: "object",
                    paramData: {
                        trgtAssetsSn: item.assetsSn
                    },
                    onSuccessFn: () => {
                        setAcqsAssetsSelTime(new Date());
                        setAssetsSelTime(new Date());
                        AppContext.openAlert({
                            title: "보유자산으로 확정",
                            msg: "보유전환된 상단의 ‘보유 중 자산’의 수정 버튼을 클릭하여\n입력된 자산정보의 변동이 없는 지 확인하고\n등록완료를 눌러 보유자산으로 확정합니다.",
                            icon: "check"
                        });
                    }
                });
            }
        });
    }

    // 자산 삭제
    const delAssetsInfo = (item: any) => {
        if (item.estateSttus !== "1003") {
            AppContext.openAlert({
                title: "취득할 자산이 아닌 자산",
                msg: "취득할 자산만 삭제가 가능합니다.",
                icon: "check"
            });
            return;
        }
        AppContext.openAlert({
            title: "취득자산 삭제",
            msg: item.rmstnfamNm + " 세대원의 취득자산을 삭제하시겠습니까?",
            icon: "check",
            confirmText: "삭제",
            handleConfirm: () => {
                AppContext.showSpinner();
                Axios.dataAccess({
                    url: "system/assets/delAssetsInfo.do",
                    methodType: "post",
                    paramType: "object",
                    paramData: {
                        assetsSn: item.assetsSn
                    },
                    onSuccessFn: () => {
                        setAcqsAssetsSelTime(new Date());
                        setAssetsSelTime(new Date());
                    }
                });
            }
        });
    }

    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/rmstnfam/selRmstnfamList.do",
            methodType: "post",
            paramType: "json",
            paramData: {
                hshldSn: hshldSn
            },
            onSuccessFn: (res: any) => {
                setRmstnfamDataArr(res.list);
                Axios.dataAccess({
                    url: "system/assets/selAssetsList.do",
                    methodType: "post",
                    paramType: "json",
                    paramData: {
                        hshldSn: hshldSn,
                        assetsSe: "1001",
                        estateSttus: "1003",
                        estateSe: "1001",
                        routeKey: "acqstax"
                    },
                    onSuccessFn: (res: any) => {
                        setAcqsAssetsDataArr(res.list);
                        Axios.dataAccess({
                            url: "system/assets/selAssetsList.do",
                            methodType: "post",
                            paramType: "json",
                            paramData: {
                                hshldSn: hshldSn,
                                assetsSe: "1001",
                                routeKey: "acqsRmstnfamAssets"
                            },
                            onSuccessFn: (res: any) => {
                                setAssetsDataArr(res.list);
                                AppContext.hideSpinner();
                            }
                        });
                    }
                });
            }
        });
    }, [acqsAssetsSelTime, assetsSelTime, hshldSn]);

    return (
        <div className="w100p bgcF6F6F6">
            <div className="dpFlx flxdCol jcC aiC pt60 pb30">
                <div className='taC pb30'>
                    <span className="dpBlock fcBlack fs38 ls266 SpoqaHanSansNeo-Medium">취득세 관리</span>
                </div>
                <div className="w165 h40 bgc1EA65B bdrd40 dpFlx jcC aiC csP" onClick={() => {
                    Axios.dataAccess({
                        url: "system/rmstnfam/selHshldRmstnfamSn.do",
                        methodType: "post",
                        paramType: "object",
                        paramData: {
                            hshldSn: hshldSn
                        },
                        onSuccessFn: (res: any) => {
                            setCurrFormMode("reg");
                            navigate("/system/assets/reg/Acqstax/1001/1003/1001/" + res.item);
                        }
                    });
                }}>
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>취득할 자산 등록</span>
                    <img className="pl5" src={require('img/common/icon_add_white_20.png')} alt="icon_add_white_20" />
                </div>
            </div>
            <div className="dpFlx jcC pt30 pb100">
                <div className='w1200'>
                    <div className='h36 dpFlx aiE pb20'>
                        <img className="mr10" src={require('img/common/icon_step.png')} alt="icon_step" />
                        <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 pb3'>
                            <span className='fc17A840'>{hshldNm}</span>
                            <span className='fc222222'>님의 세대원</span>
                        </span>
                    </div>
                    <MuiListFilter tableInfo={{
                        tableHeadVsb: true,
                        styleType: ForestTableStyle,
                        fldInfs: [
                            { type: 'text', fldNm: 'rowNum', width: 65, headAlign: "center", dataAlign: "center", label: 'No.' },
                            { type: 'text', fldNm: 'rmstnfamNm', width: 90, headAlign: "center", dataAlign: "center", label: '세대원명' },
                            { type: 'code', fldNm: 'rmstnfamSe', wAuto: true, headAlign: "center", dataAlign: "center", label: '가족관계', codeTy: '1001' },
                            { type: 'code', fldNm: 'sexdstn', width: 60, headAlign: "center", dataAlign: "center", label: '성별', codeTy: '1002' },
                            { type: 'text', fldNm: 'rmstnfamAge', width: 60, headAlign: "center", dataAlign: "center", label: '나이' },
                            { type: 'text', fldNm: 'brthdy', width: 100, headAlign: "center", dataAlign: "center", label: '생년월일' },
                            { type: 'code', fldNm: 'incomeAt', width: 70, headAlign: "center", dataAlign: "center", label: '소득충족', codeTy: '1032' },
                            { type: 'code', fldNm: 'mrrgAt', width: 70, headAlign: "center", dataAlign: "center", label: '결혼여부', codeTy: '1032' },
                            { type: 'code', fldNm: 'adresAdres', width: 70, headAlign: "center", dataAlign: "center", label: '주소동일', codeTy: '1032' },
                            { type: 'code', fldNm: 'lvlhdSamenss', width: 70, headAlign: "center", dataAlign: "center", label: '생계동일', codeTy: '1032' },
                            { type: 'code', fldNm: 'acqsRmstnfamAt', width: 70, headAlign: "center", dataAlign: "center", label: '취득기준', codeTy: '1032' },
                            { type: 'code', fldNm: 'trsfRmstnfamAt', width: 70, headAlign: "center", dataAlign: "center", label: '양도기준', codeTy: '1032' }
                        ],
                        ordFld: rmstnfamOrdFld,
                        dataArr: rmstnfamDataArr,
                        maxRowNums: [10, 20, 30],
                        trHeight: 48,
                        remainArea: false,
                        srchVsb: true,
                        paginateClass: "pt40",
                        headerVsb: true
                    }}/>
                    <div className='h36 dpFlx aiE pb20 pt40'>
                        <img className="mr10" src={require('img/common/icon_step_money.png')} alt="icon_step_money" />
                        <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 pb3'>
                            <span className='fc222222'>보유중 자산</span>
                        </span>
                    </div>
                    <MuiListFilter tableInfo={{
                        tableHeadVsb: true,
                        styleType: ForestTableStyle,
                        fldInfs: [
                            { type: 'text', fldNm: 'rowNum', width: 65, headAlign: "center", dataAlign: "center", label: 'No.' },
                            { type: 'text', fldNm: 'rmstnfamNm', width: 90, headAlign: "center", dataAlign: "center", label: '세대원명' },
                            { type: 'code', fldNm: 'rmstnfamSe', width: 90, headAlign: "center", dataAlign: "center", label: '가족관계', codeTy: '1001' },
                            { type: 'code', fldNm: 'estateDetailSe', width: 110, headAlign: "center", dataAlign: "center", label: '자산구분', codeTy: "1006" },
                            { type: 'code', fldNm: 'estatePrpos', width: 90, headAlign: "center", dataAlign: "center", label: '주택용도', codeTy: "1008" },
                            { type: 'code', fldNm: 'acqsCause', width: 100, headAlign: "center", dataAlign: "center", label: '취득원인', codeTy: "1035" },
                            { type: 'text', fldNm: 'fullRdnmAdres', wAuto: true, headAlign: "center", dataAlign: "left", label: '주소' },
                            { type: 'text', fldNm: 'acqsDe', width: 100, headAlign: "center", dataAlign: "center", label: '취득일' },
                            { type: 'el', fldNm: 'stepCode', width: 100, headAlign: "center", label: '등록상태', codeTy: "1004", el: (item: any) => (
                                <div className={'dpFlx aiC jcC w60 h24 bdrd40 bdBox ' + (item.stepCode === "1009" ? "bgc17A840" : "bgcF1F8FF bd1 bdc007CE8")}>
                                    <span className={"SpoqaHanSansNeo-Medium fs12 ls06 " + (item.stepCode === "1009" ? "fcWhite" : "fc007CE8")}>{item.stepCode === "1009" ? "등록완료" : "진행중"}</span>
                                </div>
                            )},
                            { type: 'el', width: 70, headAlign: "center", el: (item: any) => (
                                <div className='dpFlx aiC csP' onClick={() => goAssetsEditPage(item)}>
                                    <img className="csP pr3" src={require('img/common/icon_edit20.png')} alt="icon_edit20" />
                                    <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">수정</span>
                                </div>
                            )},
                            { type: 'el', width: 70, headAlign: "center", el: (item: any) => (
                                <div className={'aiC csP ' + (item.stepCode === "1009" ? "dpFlx" : "dpNone")} onClick={() => {
                                    setAssetsSn(item.assetsSn);
                                    setAssetsModalOpen(true);
                                }}>
                                    <img className="csP pr3" src={require('img/common/icon_copy20.png')} alt="icon_copy20" />
                                    <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">조회</span>
                                </div>
                            )}
                        ],
                        ordFld: { name: "rowNum", direction: "asc" },
                        dataArr: assetsDataArr,
                        maxRowNums: [10, 20, 30],
                        trHeight: 48,
                        remainArea: false,
                        srchVsb: true,
                        paginateClass: "pt40",
                        headerVsb: true
                    }}/>
                    <div className='h36 dpFlx aiE pb20 pt40'>
                        <img className="mr10" src={require('img/common/icon_step_money.png')} alt="icon_step_money" />
                        <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 pb3'>
                            <span className='fc222222'>취득할 자산</span>
                        </span>
                    </div>
                    <MuiListFilter tableInfo={{
                        tableHeadVsb: true,
                        styleType: ForestTableStyle,
                        fldInfs: [
                            { type: 'text', fldNm: 'rowNum', width: 65, headAlign: "center", dataAlign: "center", label: 'No.' },
                            { type: 'text', fldNm: 'rmstnfamNm', width: 90, headAlign: "center", dataAlign: "center", label: '세대원명' },
                            { type: 'code', fldNm: 'estateDetailSe', width: 110, headAlign: "center", dataAlign: "center", label: '자산구분', codeTy: "1006" },
                            { type: 'code', fldNm: 'estatePrpos', width: 90, headAlign: "center", dataAlign: "center", label: '주택용도', codeTy: "1008" },
                            { type: 'code', fldNm: 'acqsCause', width: 100, headAlign: "center", dataAlign: "center", label: '취득원인', codeTy: "1035" },
                            { type: 'text', fldNm: 'fullRdnmAdres', wAuto: true, headAlign: "center", dataAlign: "left", label: '주소' },
                            { type: 'text', fldNm: 'acqsDe', width: 100, headAlign: "center", dataAlign: "center", label: '취득일' },
                            { type: 'el', fldNm: 'stepCode', width: 100, headAlign: "center", label: '등록상태', el: (item: any) => (
                                <div className={'dpFlx aiC jcC w60 h24 bdrd40 bdBox ' + (["1009", "1010"].indexOf(item.stepCode) !== -1 ? "bgc17A840" : "bgcF1F8FF bd1 bdc007CE8")}>
                                    <span className={"SpoqaHanSansNeo-Medium fs12 ls06 " + (["1009", "1010"].indexOf(item.stepCode) !== -1 ? "fcWhite" : "fc007CE8")}>
                                        {item.stepCode === "1009" ? "등록완료" : ""}
                                        {item.stepCode === "1010" ? "보유전환" : ""}
                                        {["1009", "1010"].indexOf(item.stepCode) === -1 ? "진행중" : ""}
                                    </span>
                                </div>
                            )},
                            { type: 'el', width: 70, headAlign: "center", el: (item: any) => (
                                <div className='dpFlx aiC csP' onClick={() => goAssetsEditPage(item)}>
                                    <img className="csP pr3" src={require('img/common/icon_edit20.png')} alt="icon_edit20" />
                                    <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">수정</span>
                                </div>
                            )},
                            { type: 'el', width: 70, headAlign: "center", el: (item: any) => (
                                <div className='dpFlx aiC csP' onClick={() => delAssetsInfo(item)}>
                                    <img className="csP pr3" src={require('img/common/icon_delete20.png')} alt="icon_delete20" />
                                    <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">삭제</span>
                                </div>
                            )},
                            { type: 'el', width: 70, headAlign: "center", el: (item: any) => (
                                <div className='dpFlx aiC csP' onClick={() => copyAssetsEditPage(item)}>
                                    <img className="csP pr3" src={require('img/common/icon_copy20.png')} alt="icon_copy20" />
                                    <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">복사</span>
                                </div>
                            )},
                            { type: 'el', width: 70, headAlign: "center", el: (item: any) => (
                                <div className='dpFlx aiC csP' onClick={() => goAssetsCalcPage(item)}>
                                    <img className="csP pr3" src={require('img/common/icon_cal20.png')} alt="icon_cal20" />
                                    <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">계산</span>
                                </div>
                            )},
                            { type: 'el', width: 70, headAlign: "center", el: (item: any) => (
                                <div className='dpFlx aiC csP' onClick={() => holdAcqsAssets(item)}>
                                    <img className="csP pr3" src={require('img/common/icon_cal20.png')} alt="icon_cal20" />
                                    <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">보유</span>
                                </div>
                            )},
                            { type: 'el', width: 70, headAlign: "center", el: (item: any) => (
                                <div className={'aiC csP ' + (item.stepCode === "1009" ? "dpFlx" : "dpNone")} onClick={() => {
                                    setAssetsSn(item.assetsSn);
                                    setAssetsModalOpen(true);
                                }}>
                                    <img className="csP pr3" src={require('img/common/icon_copy20.png')} alt="icon_copy20" />
                                    <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">조회</span>
                                </div>
                            )}
                        ],
                        ordFld: { name: "rowNum", direction: "asc" },
                        dataArr: acqsAssetsDataArr,
                        maxRowNums: [10, 20, 30],
                        trHeight: 48,
                        remainArea: false,
                        srchVsb: true,
                        paginateClass: "pt40",
                        headerVsb: true
                    }}/>
                </div>
            </div>
            <Modal modalOpen={acqstaxCalcModalOpen} setModalOpen={setAcqstaxCalcModalOpen} title="취득세 세액 산출" dim={true} visible={acqstaxCalcModalVsb} closeCallBack={() => setAcqstaxCalcModalVsb(false)}>
                <AcqstaxCalc setAcqstaxCalcModalOpen={setAcqstaxCalcModalOpen} assetsSn={assetsSn} setAcqstaxCalcModalVsb={setAcqstaxCalcModalVsb} />
            </Modal>
            <Modal modalOpen={assetsModalOpen} setModalOpen={setAssetsModalOpen} title="자산 조회" dim={true} closeCallBack={() => {
                setAssetsSn(undefined);
                setAssetsModalOpen(false);
            }}>
                <div className="w1200 h500 ofA">
                    <AssetsShow assetsSn={assetsSn} />
                </div>
                <div className='w100p dpFlx aiC jcC pt40'>
                    <button className='w210 h56 bgcF6F6F6 dpFlx aiC jcC csP bdN' onClick={() => {
                        setAssetsSn(undefined);
                        setAssetsModalOpen(false);
                    }}>
                        <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>닫기</span>
                    </button>
                </div>
            </Modal>
        </div>
    )
}
export default Acqstax;