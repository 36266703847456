import { calculatorPcWebMaxWidth } from 'js/common/const';
import React from 'react';
import styled from 'styled-components';

type Props = {
    children: React.ReactNode;
};

const MainContainer = styled.main`
    width: 100%;
    height: calc(100% - 50px);
    overflow-y: scroll;
    background: ${(props) => props.theme.color.bg};

    @media (min-width: 1201px) {
        max-width: ${calculatorPcWebMaxWidth};
    }

    /* 아래의 모든 코드는 영역::코드로 사용 */
    &::-webkit-scrollbar {
        display: none;
    }
`;

function MobileMain({ children }: Props) {
    return <MainContainer>{children}</MainContainer>;
}

export default MobileMain;
