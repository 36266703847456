/*******************************************************************************
 You are strictly prohibited to copy, disclose, distribute, modify, or use
 this program in part or as a whole without the prior written consent of
 CallTax. CallTax, owns the intellectual property rights in and to this program.
 (Copyright © 2023 CallTax. All Rights Reserved| Confidential)

 Specification
    1. Program ID    : AssetsAcq.tsx
    2. Program Title : 보유 or 취득 자산 정보 입력 > 등록완료
    3. Created by    : JH Kim
    4. Create Date   : 2023.
    5. Reference     :
*******************************************************************************/
import AppContext from "js/common/AppContext";
import Axios from "js/common/Axios";
import React from "react";
import Util from "js/common/Util";
import Modal from "js/common/ui/modal/Modal";
import MgshrPlanDe from "../help/MgshrPlanDe";
import LawDialog from '../dialog/LawDialog';
import AssetsUtil from "js/common/AssetsUtil";
import ToolTip from "js/common/ui/ToolTip";

interface AssetsViewProps {
    assetsSn: number | undefined
}
const AssetsShow = ({assetsSn}: AssetsViewProps) => {
    const [estateSttus, setEstateSttus] = React.useState<string>("");
    const [rarFormSeq, setRarFormSeq] = React.useState<number>(); // 양식 구분 : 구분에 따른 form 랜더링
    const [lawModalTitle, setLawModalTitle] = React.useState<string>("");

    interface lawProp {
        lawordSn: number
        lawordDetailSn: number
        lawordDc: string
        lawordNm: string
        lawordTy: string
        dcsnAt: string
        popupFlag: boolean
    };
    const [lawList, setLawList] = React.useState<lawProp[]>([]); // 법령목록
    const [lawordSn, setLawordSn] = React.useState<number>(0);
    const [lawordDetailSn, setLawordDetailSn] = React.useState<number>(0);
    const [lawModalOpen, setLawModalOpen] = React.useState<boolean>(false);

    interface acqsAnswerProp {
        assetsQestnSn: number
        qestnText: string
        answerText: string
    };
    const [acqsAnswerList, setAcqsAnswerList] = React.useState<acqsAnswerProp[]>([]); // 취득세 질문 목록

    // 부동산 상세정보
    const [estateDetailSe, setEstateDetailSe] = React.useState<string>("");
    const [hseNumAt, setHseNumAt] = React.useState<string>("");
    const [estateFom, setEstateFom] = React.useState<string>("");
    const [proptaxLevyAt, setProptaxLevyAt] = React.useState<string>("");
    const [acqsSrc, setAcqsSrc] = React.useState<string>("");
    const [acqsCause, setAcqsCause] = React.useState<string>("");
    const [estatePrpos, setEstatePrpos] = React.useState<string>("");
    interface rghpsnProp {
        rmstnfamSe: string | null
        rghpsnSn: number
        rghpsnSe: string
        rmstnfamSn: number | null
        rghpsnNm: string
        qotaSe: string
        qotaRate: number
    };
    const [rghpsnList, setRghpsnList] = React.useState<rghpsnProp[]>([]);
    const [landRghpsnList, setLandRghpsnList] = React.useState<rghpsnProp[]>([]);
    const [rdnmAdres, setRdnmAdres] = React.useState<string>(""); // 도로명주소
    const [rdnmAdresDetail, setRdnmAdresDetail] = React.useState<string>(""); // 지번
    const [lnmAdres, setLnmAdres] = React.useState<string>(""); // 도로명주소 상세주소
    const [plotRight, setPlotRight] = React.useState<string>(); // 대지권
    const [prvuseAr, setPrvuseAr] = React.useState<string>(); // 전용면적
    const [cnrsAr, setCnrsAr] = React.useState<string>(); // 공유면적
    const [plotAr, setPlotAr] = React.useState<string>(); // 대지면적
    const [houseTotar, setHouseTotar] = React.useState<string>(); // 주택연면적
    const [sopsrtTotar, setSopsrtTotar] = React.useState<string>(); // 상가연면적
    const [spfcKnd, setSpfcKnd] = React.useState<string>(""); // 용도지역
    const [ovpalotAt, setOvpalotAt] = React.useState<string>(""); // 과밀억제권역
    const [mdatTrgetAreaAt, setMdatTrgetAreaAt] = React.useState<string>(""); // 조정대상지역
    const [adresDrctInputAt, setAdresDrctInputAt] = React.useState<string>(""); // 주소 직접입력 여부
    const [adresDrct, setAdresDrct] = React.useState<string>(""); // 직접입력한 법정읍면주소

    // 공통 파라미터
    const [cntrctDe, setCntrctDe] = React.useState<string>(); // 계약일, 종전주택계약일(상속)
    const [entyPymntde, setEntyPymntde] = React.useState<string>(); // 계약금지급일
    const [surlusPymntde, setSurlusPymntde] = React.useState<string>(); // 잔금지급일
    const [fullPymntde, setFullPymntde] = React.useState<string>(); // 분양대금완납일
    const [ownshipTransrRceptDe, setOwnshipTransrRceptDe] = React.useState<string>(); // 소유권이전접수일
    const [useConfmDe, setUseConfmDe] = React.useState<string>(""); // 사용승인일
    const [stdrMktc, setStdrMktc] = React.useState<string>(""); // 기준시가
    const [rlDlpc, setRlDlpc] = React.useState<string>(""); // 실거래가
    const [beforeEstatePrpos, setBeforeEstatePrpos] = React.useState<string>(""); // 피상속인의 주택용도
    const [manageDspsPlanDe, setManageDspsPlanDe] = React.useState<string>(); // 관리처분계획인가일
    const [bsnsImpCnfmDe, setBsnsImpCnfmDe] = React.useState<string>(); // 사업시행인가일

    // 상속
    const [excptInhrtncAt, setExcptInhrtncAt] = React.useState<string>(""); // 특례상속 여부
    const [inhrtncKnd1, setInhrtncKnd1] = React.useState<string>(""); // 동일세대원 여부
    const [inhrtncKnd2, setInhrtncKnd2] = React.useState<string>(""); // 상속의 종류
    const [inhrtncKnd3, setInhrtncKnd3] = React.useState<string>(""); // 지분권자의 종류
    const [beginDe, setBeginDe] = React.useState<string>(); // 상속개시(원인)일
    const [beforeEstateTy, setBeforeEstateTy] = React.useState<string>(""); // 종전부동산 유형

    // 증여
    const [donaKnd, setDonaKnd] = React.useState<string>(""); // 증여의 종류
    const [donaKnd2, setDonaKnd2] = React.useState<string>(""); // 증여자와의 관계
    const [donaCntrctDe, setDonaCntrctDe] = React.useState<string>(); // 증여계약일
    const [rgistRceptDe, setRgistRceptDe] = React.useState<string>(); // 증여등기접수일
    const [ownshipTransrDe, setOwnshipTransrDe] = React.useState<string>(); // 소유권이전 등기접수일
    const [rightSuccsDe, setRightSuccsDe] = React.useState<string>(); // 권리승계일
    const [manageDspsCnfmDe, setManageDspsCnfmDe] = React.useState<string>(); // 관리처분계획인가일

    // 원조합원
    const [evlamt, setEvlamt] = React.useState<string>(""); // 평가액
    const [przwinDe, setPrzwinDe] = React.useState<string>(); // 분양권당첨일

    const [manageDspsPlanDeModalOpen, setManageDspsPlanDeModalOpen] = React.useState<boolean>(false); // 도움말 (관리처분계획인가일)

    // 거주 및 임대정보
    const [titleText1, setTitleText1] = React.useState<string>("");
    const [titleText2, setTitleText2] = React.useState<string>("");

    const [trnsfrnDe, setTrnsfrnDe] = React.useState<string>(); // 전입일
    const [lvhsDe, setLvhsDe] = React.useState<string>(); // 전출일
    const [trnsfrnDe2, setTrnsfrnDe2] = React.useState<string>(); // 전입일: 피상속인
    const [lvhsDe2, setLvhsDe2] = React.useState<string>(); // 전출일: 피상속인

    const [locgovRegistAt, setLocgovRegistAt] = React.useState<string>(""); // 지자체 등록여부
    const [locgovRegistDe, setLocgovRegistDe] = React.useState<string>(""); // 지자체 등록일
    const [locgovRqstde, setLocgovRqstde] = React.useState<string>(""); // 지자체 신청일
    const [rentTy, setRentTy] = React.useState<string>(""); // 임대유형
    const [rentTyCnvrsDe, setRentTyCnvrsDe] = React.useState<string>(""); // 장기등록 전환신청일
    const [estateRentTy, setEstateRentTy] = React.useState<string>(""); // 임대주택 종류
    const [ersrAt, setErsrAt] = React.useState<string>(""); // 말소여부
    const [ersrKnd, setErsrKnd] = React.useState<string>(""); // 말소종류
    const [ersrDe, setErsrDe] = React.useState<string>(""); // 말소일

    const [taxofcRegistAt, setTaxofcRegistAt] = React.useState<string>(""); // 세무서 등록여부
    const [taxofcRegistDe, setTaxofcRegistDe] = React.useState<string>(""); // 세무서 등록일
    const [taxofcRqstde, setTaxofcRqstde] = React.useState<string>(""); // 세무서 신청일
    const [bizqitAt, setBizqitAt] = React.useState<string>(""); // 폐업여부
    const [bizqitDe, setBizqitDe] = React.useState<string>(""); // 폐업일자
    const [rentBgnde, setRentBgnde] = React.useState<string>(""); // 임대개시일
    const [stdrMktc1, setStdrMktc1] = React.useState<string>(""); // 임대개시일기준시가
    const [rntchrgUplmtFlflAt, setRntchrgUplmtFlflAt] = React.useState<string>(""); // 5% 상한 이행여부

    const [locgovRegistAt2, setLocgovRegistAt2] = React.useState<string>(""); // 지자체 등록여부
    const [locgovRegistDe2, setLocgovRegistDe2] = React.useState<string>(""); // 지자체 등록일
    const [locgovRqstde2, setLocgovRqstde2] = React.useState<string>(""); // 지자체 신청일
    const [rentTy2, setRentTy2] = React.useState<string>(""); // 임대유형
    const [rentTyCnvrsDe2, setRentTyCnvrsDe2] = React.useState<string>(""); // 장기등록 전환신청일
    const [estateRentTy2, setEstateRentTy2] = React.useState<string>(""); // 임대주택 종류
    const [ersrAt2, setErsrAt2] = React.useState<string>(""); // 말소여부
    const [ersrKnd2, setErsrKnd2] = React.useState<string>(""); // 말소종류
    const [ersrDe2, setErsrDe2] = React.useState<string>(""); // 말소일

    const [taxofcRegistAt2, setTaxofcRegistAt2] = React.useState<string>(""); // 세무서 등록여부
    const [taxofcRegistDe2, setTaxofcRegistDe2] = React.useState<string>(""); // 세무서 등록일
    const [taxofcRqstde2, setTaxofcRqstde2] = React.useState<string>(""); // 세무서 신청일
    const [bizqitAt2, setBizqitAt2] = React.useState<string>(""); // 폐업여부
    const [bizqitDe2, setBizqitDe2] = React.useState<string>(""); // 폐업일자
    const [rentBgnde2, setRentBgnde2] = React.useState<string>(""); // 임대개시일
    const [stdrMktc2, setStdrMktc2] = React.useState<string>(""); // 임대개시일기준시가
    const [rntchrgUplmtFlflAt2, setRntchrgUplmtFlflAt2] = React.useState<string>(""); // 5% 상한 이행여부

    const [wwinHouseAt, setWwinHouseAt] = React.useState<string>("");
    const [acqsHouseAt, setAcqsHouseAt] = React.useState<string>("");
    const [frhlHouseAt, setFrhlHouseAt] = React.useState<string>("");
    const [crltsHouseAt, setCrltsHouseAt] = React.useState<string>("");
    const [mrtgHouseAt, setMrtgHouseAt] = React.useState<string>("");
    const [childHouseAt, setChildHouseAt] = React.useState<string>("");
    const [salesHouseAt, setSalesHouseAt] = React.useState<string>("");
    const [estateHouseAt, setEstateHouseAt] = React.useState<string>("");

    const getRlDlpcFldNm = () => AssetsUtil.getRlDlpcFldNm(estateDetailSe, estateFom, acqsSrc, acqsCause);

    React.useEffect(() => {
        const inhrtncParamSet = (inhrtncInfo: any) => {
            setExcptInhrtncAt(inhrtncInfo.excptInhrtncAt); // 특례상속 여부
            setBeforeEstateTy(inhrtncInfo.beforeEstateTy); // 종전부동산 유형
            setInhrtncKnd1(inhrtncInfo.inhrtncKnd1); // 동일세대원 여부
            setInhrtncKnd2(inhrtncInfo.inhrtncKnd2); // 상속의 종류
            if (inhrtncInfo.inhrtncKnd2 === "1002") {
                setInhrtncKnd3(inhrtncInfo.inhrtncKnd3); // 지분권자의 종류
            }
            setBeforeEstatePrpos(inhrtncInfo.beforeEstatePrpos);
            setBeginDe(Util.cvtDateStrForm(inhrtncInfo.beginDe, ". "));
            setCntrctDe(Util.cvtDateStrForm(inhrtncInfo.cntrctDe, ". "));
            setEntyPymntde(Util.cvtDateStrForm(inhrtncInfo.entyPymntde, ". "));
            setSurlusPymntde(Util.cvtDateStrForm(inhrtncInfo.surlusPymntde, ". "));
            setFullPymntde(Util.cvtDateStrForm(inhrtncInfo.fullPymntde, ". "));
            setOwnshipTransrDe(Util.cvtDateStrForm(inhrtncInfo.ownshipTransrDe, ". "));
            setManageDspsPlanDe(Util.cvtDateStrForm(inhrtncInfo.manageDspsPlanDe, ". "));
            setStdrMktc(Util.comma(inhrtncInfo.stdrMktc));
            setRlDlpc(Util.comma(inhrtncInfo.rlDlpc));
            setUseConfmDe(Util.cvtDateStrForm(inhrtncInfo.useConfmDe, ". "));
            setBsnsImpCnfmDe(Util.cvtDateStrForm(inhrtncInfo.bsnsImpCnfmDe, ". "));
        };
        const getAcqsFormSeq = (estateDetail: any, trdeInfo: any, inhrtncInfo: any, donaInfo: any, lttotRightInfo: any, redevpRebidngInfo: any, selfNwccInfo: any) => {
            if (estateDetail.estateDetailSe === "1001") { // 주택
                if (estateDetail.acqsSrc === "1001" || estateDetail.acqsSrc === "1005") { // 기존 아파트, 오피스텔취득
                    if (estateDetail.acqsCause === "1002") {
                        setCntrctDe(Util.cvtDateStrForm(trdeInfo.cntrctDe, ". "));
                        setEntyPymntde(Util.cvtDateStrForm(trdeInfo.entyPymntde, ". "));
                        setSurlusPymntde(Util.cvtDateStrForm(trdeInfo.surlusPymntde, ". "));
                        setFullPymntde(Util.cvtDateStrForm(trdeInfo.fullPymntde, ". "));
                        setOwnshipTransrRceptDe(Util.cvtDateStrForm(trdeInfo.ownshipTransrRceptDe, ". "));
                        setStdrMktc(Util.comma(trdeInfo.stdrMktc));
                        setRlDlpc(Util.comma(trdeInfo.rlDlpc));
                        setUseConfmDe(Util.cvtDateStrForm(trdeInfo.useConfmDe, ". "));
                    } else if (estateDetail.acqsCause === "1003") {
                        inhrtncParamSet(inhrtncInfo);
                    } else if (estateDetail.acqsCause === "1004") {
                        setDonaKnd(donaInfo.donaKnd);
                        setDonaKnd2(donaInfo.donaKnd2);
                        setDonaCntrctDe(Util.cvtDateStrForm(donaInfo.donaCntrctDe, ". "));
                        setRgistRceptDe(Util.cvtDateStrForm(donaInfo.rgistRceptDe, ". "));
                        setCntrctDe(Util.cvtDateStrForm(donaInfo.cntrctDe, ". "));
                        setEntyPymntde(Util.cvtDateStrForm(donaInfo.entyPymntde, ". "));
                        setSurlusPymntde(Util.cvtDateStrForm(donaInfo.surlusPymntde, ". "));
                        setFullPymntde(Util.cvtDateStrForm(donaInfo.fullPymntde, ". "));
                        setOwnshipTransrDe(Util.cvtDateStrForm(donaInfo.ownshipTransrDe, ". "));
                        setStdrMktc(Util.comma(donaInfo.stdrMktc));
                        setRlDlpc(Util.comma(donaInfo.rlDlpc));
                        setUseConfmDe(Util.cvtDateStrForm(donaInfo.useConfmDe, ". "));
                    }
                } else if (estateDetail.acqsSrc === "1002" || estateDetail.acqsSrc === "1006") { // 분양권에 의한 아파트, 일반분양오피스텔취득
                    if (estateDetail.acqsCause === "1011" || estateDetail.acqsCause === "1012") {
                        setCntrctDe(Util.cvtDateStrForm(lttotRightInfo.cntrctDe, ". "));
                        setEntyPymntde(Util.cvtDateStrForm(lttotRightInfo.entyPymntde, ". "));
                        setSurlusPymntde(Util.cvtDateStrForm(lttotRightInfo.surlusPymntde, ". "));
                        setFullPymntde(Util.cvtDateStrForm(lttotRightInfo.fullPymntde, ". "));
                        setOwnshipTransrRceptDe(Util.cvtDateStrForm(lttotRightInfo.ownshipTransrRceptDe, ". "));
                        setStdrMktc(Util.comma(lttotRightInfo.stdrMktc));
                        setRlDlpc(Util.comma(lttotRightInfo.rlDlpc));
                        setUseConfmDe(Util.cvtDateStrForm(lttotRightInfo.useConfmDe, ". "));
                    } else if (estateDetail.acqsCause === "1002") {
                        setCntrctDe(Util.cvtDateStrForm(trdeInfo.cntrctDe, ". "));
                        setEntyPymntde(Util.cvtDateStrForm(trdeInfo.entyPymntde, ". "));
                        setSurlusPymntde(Util.cvtDateStrForm(trdeInfo.surlusPymntde, ". "));
                        setFullPymntde(Util.cvtDateStrForm(trdeInfo.fullPymntde, ". "));
                        setOwnshipTransrRceptDe(Util.cvtDateStrForm(trdeInfo.ownshipTransrRceptDe, ". "));
                        setStdrMktc(Util.comma(trdeInfo.stdrMktc));
                        setRlDlpc(Util.comma(trdeInfo.rlDlpc));
                        setUseConfmDe(Util.cvtDateStrForm(trdeInfo.useConfmDe, ". "));
                    } else if (estateDetail.acqsCause === "1003") {
                        inhrtncParamSet(inhrtncInfo);
                    } else if (estateDetail.acqsCause === "1004") {
                        setDonaKnd(donaInfo.donaKnd);
                        setDonaKnd2(donaInfo.donaKnd2);
                        setStdrMktc(Util.comma(donaInfo.stdrMktc));
                        setRlDlpc(Util.comma(donaInfo.rlDlpc));
                        setDonaCntrctDe(Util.cvtDateStrForm(donaInfo.donaCntrctDe, ". "));
                        setRightSuccsDe(Util.cvtDateStrForm(donaInfo.rightSuccsDe, ". "));
                        setCntrctDe(Util.cvtDateStrForm(donaInfo.cntrctDe, ". "));
                        setEntyPymntde(Util.cvtDateStrForm(donaInfo.entyPymntde, ". "));
                        setSurlusPymntde(Util.cvtDateStrForm(donaInfo.surlusPymntde, ". "));
                        setFullPymntde(Util.cvtDateStrForm(donaInfo.fullPymntde, ". "));
                        setUseConfmDe(Util.cvtDateStrForm(donaInfo.useConfmDe, ". "));
                    }
                } else if (estateDetail.acqsSrc === "1003") { // 조합원입주권에 의한 아파트
                    if (estateDetail.acqsCause === "1001") {
                        setBeforeEstateTy(redevpRebidngInfo.beforeEstateTy);
                        setBeforeEstatePrpos(redevpRebidngInfo.beforeEstatePrpos);
                        setCntrctDe(Util.cvtDateStrForm(redevpRebidngInfo.cntrctDe, ". "));
                        setEntyPymntde(Util.cvtDateStrForm(redevpRebidngInfo.entyPymntde, ". "));
                        setSurlusPymntde(Util.cvtDateStrForm(redevpRebidngInfo.surlusPymntde, ". "));
                        setRgistRceptDe(Util.cvtDateStrForm(redevpRebidngInfo.rgistRceptDe, ". "));
                        setManageDspsPlanDe(Util.cvtDateStrForm(redevpRebidngInfo.manageDspsPlanDe, ". "));
                        setEvlamt(Util.comma(redevpRebidngInfo.evlamt));
                        setStdrMktc(Util.comma(redevpRebidngInfo.stdrMktc));
                        setRlDlpc(Util.comma(redevpRebidngInfo.rlDlpc));
                        setUseConfmDe(Util.cvtDateStrForm(redevpRebidngInfo.useConfmDe, ". "));
                        setBsnsImpCnfmDe(Util.cvtDateStrForm(redevpRebidngInfo.bsnsImpCnfmDe, ". "));
                    } else if (estateDetail.acqsCause === "1002") {
                        setCntrctDe(Util.cvtDateStrForm(trdeInfo.cntrctDe, ". "));
                        setEntyPymntde(Util.cvtDateStrForm(trdeInfo.entyPymntde, ". "));
                        setSurlusPymntde(Util.cvtDateStrForm(trdeInfo.surlusPymntde, ". "));
                        setOwnshipTransrRceptDe(Util.cvtDateStrForm(trdeInfo.ownshipTransrRceptDe, ". "));
                        setStdrMktc(Util.comma(trdeInfo.stdrMktc));
                        setRlDlpc(Util.comma(trdeInfo.rlDlpc));
                        setUseConfmDe(Util.cvtDateStrForm(trdeInfo.useConfmDe, ". "));
                    } else if (estateDetail.acqsCause === "1003") {
                        inhrtncParamSet(inhrtncInfo);
                    } else if (estateDetail.acqsCause === "1004") { // 증여
                        setStdrMktc(Util.comma(donaInfo.stdrMktc));
                        setRlDlpc(Util.comma(donaInfo.rlDlpc));
                        setCntrctDe(Util.cvtDateStrForm(donaInfo.cntrctDe, ". "));
                        setEntyPymntde(Util.cvtDateStrForm(donaInfo.entyPymntde, ". "));
                        setSurlusPymntde(Util.cvtDateStrForm(donaInfo.surlusPymntde, ". "));
                        setDonaCntrctDe(Util.cvtDateStrForm(donaInfo.donaCntrctDe, ". "));
                        setManageDspsCnfmDe(Util.cvtDateStrForm(donaInfo.manageDspsCnfmDe, ". "));
                        setRightSuccsDe(Util.cvtDateStrForm(donaInfo.rightSuccsDe, ". "));
                        setUseConfmDe(Util.cvtDateStrForm(donaInfo.useConfmDe, ". "));
                        setBsnsImpCnfmDe(Util.cvtDateStrForm(donaInfo.bsnsImpCnfmDe, ". "));
                    }
                } else if (estateDetail.acqsSrc === "1004") { // 자가신축주택취득
                    setUseConfmDe(Util.cvtDateStrForm(selfNwccInfo.useConfmDe, ". "));
                    setStdrMktc(Util.comma(selfNwccInfo.stdrMktc));
                    setRlDlpc(Util.comma(selfNwccInfo.rlDlpc));
                }
            } else if (estateDetail.estateDetailSe === "1002") { // 분양권
                if (estateDetail.acqsCause === "1001") { // 최초분양
                    setCntrctDe(Util.cvtDateStrForm(lttotRightInfo.cntrctDe, ". "));
                    setEntyPymntde(Util.cvtDateStrForm(lttotRightInfo.entyPymntde, ". "));
                    setSurlusPymntde(Util.cvtDateStrForm(lttotRightInfo.surlusPymntde, ". "));
                    setRlDlpc(Util.comma(lttotRightInfo.rlDlpc));
                    setPrzwinDe(Util.cvtDateStrForm(lttotRightInfo.przwinDe, ". "));
                } else if (estateDetail.acqsCause === "1002") { // 전매
                    setCntrctDe(Util.cvtDateStrForm(trdeInfo.cntrctDe, ". "));
                    setEntyPymntde(Util.cvtDateStrForm(trdeInfo.entyPymntde, ". "));
                    setSurlusPymntde(Util.cvtDateStrForm(trdeInfo.surlusPymntde, ". "));
                    setRlDlpc(Util.comma(trdeInfo.rlDlpc));
                } else if (estateDetail.acqsCause === "1003") { // 상속
                    inhrtncParamSet(inhrtncInfo);
                } else if (estateDetail.acqsCause === "1004") { // 증여
                    setRlDlpc(Util.comma(donaInfo.rlDlpc));
                    setDonaCntrctDe(Util.cvtDateStrForm(donaInfo.donaCntrctDe, ". "));
                    setRightSuccsDe(Util.cvtDateStrForm(donaInfo.rightSuccsDe, ". "));
                    setCntrctDe(Util.cvtDateStrForm(donaInfo.cntrctDe, ". "));
                    setEntyPymntde(Util.cvtDateStrForm(donaInfo.entyPymntde, ". "));
                    setSurlusPymntde(Util.cvtDateStrForm(donaInfo.surlusPymntde, ". "));
                }
            } else if (estateDetail.estateDetailSe === "1003") { // 조합원입주권
                if (estateDetail.acqsCause === "1001") { // 원조합원
                    setBeforeEstateTy(redevpRebidngInfo.beforeEstateTy);
                    setCntrctDe(Util.cvtDateStrForm(redevpRebidngInfo.cntrctDe, ". "));
                    setEntyPymntde(Util.cvtDateStrForm(redevpRebidngInfo.entyPymntde, ". "));
                    setSurlusPymntde(Util.cvtDateStrForm(redevpRebidngInfo.surlusPymntde, ". "));
                    setRgistRceptDe(Util.cvtDateStrForm(redevpRebidngInfo.rgistRceptDe, ". "));
                    setManageDspsPlanDe(Util.cvtDateStrForm(redevpRebidngInfo.manageDspsPlanDe, ". "));
                    setEvlamt(Util.comma(redevpRebidngInfo.evlamt));
                    setRlDlpc(Util.comma(redevpRebidngInfo.rlDlpc));
                    setBsnsImpCnfmDe(Util.cvtDateStrForm(redevpRebidngInfo.bsnsImpCnfmDe, ". "));
                } else if (estateDetail.acqsCause === "1002") { // 전매
                    setCntrctDe(Util.cvtDateStrForm(trdeInfo.cntrctDe, ". "));
                    setEntyPymntde(Util.cvtDateStrForm(trdeInfo.entyPymntde, ". "));
                    setSurlusPymntde(Util.cvtDateStrForm(trdeInfo.surlusPymntde, ". "));
                    setRlDlpc(Util.comma(trdeInfo.rlDlpc));
                } else if (estateDetail.acqsCause === "1003") { // 상속
                    inhrtncParamSet(inhrtncInfo);
                } else if (estateDetail.acqsCause === "1004") { // 증여
                    setDonaKnd(donaInfo.donaKnd);
                    setDonaKnd2(donaInfo.donaKnd2);
                    setStdrMktc(Util.comma(donaInfo.stdrMktc));
                    setRlDlpc(Util.comma(donaInfo.rlDlpc));
                    setCntrctDe(Util.cvtDateStrForm(donaInfo.cntrctDe, ". "));
                    setEntyPymntde(Util.cvtDateStrForm(donaInfo.entyPymntde, ". "));
                    setSurlusPymntde(Util.cvtDateStrForm(donaInfo.surlusPymntde, ". "));
                    setDonaCntrctDe(Util.cvtDateStrForm(donaInfo.donaCntrctDe, ". "));
                    setManageDspsCnfmDe(Util.cvtDateStrForm(donaInfo.manageDspsCnfmDe, ". "));
                    // setEvlamt(Util.comma(donaInfo.evlamt));
                    setRightSuccsDe(Util.cvtDateStrForm(donaInfo.rightSuccsDe, ". "));
                    setBsnsImpCnfmDe(Util.cvtDateStrForm(donaInfo.bsnsImpCnfmDe, ". "));
                }
            }
        }

        const getRarFormSeq = (estateDetail: any, inhrtncInfo: any, donaInfo: any, redevpRebidngInfo: any, estateRentInfo1: any, estateRentInfo2: any, estateResideInfo1: any, estateResideInfo2: any) => {
            const setEstateResideInfo1 = () => {
                setTrnsfrnDe(Util.cvtDateStrForm(estateResideInfo1.trnsfrnDe, ". "));
                setLvhsDe(Util.cvtDateStrForm(estateResideInfo1.lvhsDe, ". "));
            }
            const setEstateResideInfo2 = () => {
                setTrnsfrnDe2(Util.cvtDateStrForm(estateResideInfo2.trnsfrnDe, ". "));
                setLvhsDe2(Util.cvtDateStrForm(estateResideInfo2.lvhsDe, ". "));
            }
            const setEstateRentInfo1 = () => {
                setEstateRentTy(estateRentInfo1.estateRentTy);
                setRentTy(estateRentInfo1.rentTy);
                setLocgovRegistAt(estateRentInfo1.locgovRegistAt);
                setLocgovRegistDe(Util.cvtDateStrForm(estateRentInfo1.locgovRegistDe, ". "));
                setLocgovRqstde(Util.cvtDateStrForm(estateRentInfo1.locgovRqstde, ". "));
                setTaxofcRegistAt(estateRentInfo1.taxofcRegistAt);
                setTaxofcRegistDe(Util.cvtDateStrForm(estateRentInfo1.taxofcRegistDe, ". "));
                setTaxofcRqstde(Util.cvtDateStrForm(estateRentInfo1.taxofcRqstde, ". "));
                setRentBgnde(Util.cvtDateStrForm(estateRentInfo1.rentBgnde, ". "));
                setErsrAt(estateRentInfo1.ersrAt);
                setErsrKnd(estateRentInfo1.ersrKnd);
                setErsrDe(Util.cvtDateStrForm(estateRentInfo1.ersrDe, ". "));
                setBizqitAt(estateRentInfo1.bizqitAt);
                setBizqitDe(Util.cvtDateStrForm(estateRentInfo1.bizqitDe, ". "));
                setStdrMktc1(Util.comma(estateRentInfo1.stdrMktc));
                setTrnsfrnDe(Util.cvtDateStrForm(estateRentInfo1.trnsfrnDe, ". "));
                setLvhsDe(Util.cvtDateStrForm(estateRentInfo1.lvhsDe, ". "));
                setRntchrgUplmtFlflAt(estateRentInfo1.rntchrgUplmtFlflAt);
                setRentTyCnvrsDe(Util.cvtDateStrForm(estateRentInfo1.rentTyCnvrsDe, ". "));
            }
            const setEstateRentInfo2 = () => {
                setEstateRentTy2(estateRentInfo2.estateRentTy);
                setRentTy2(estateRentInfo2.rentTy);
                setLocgovRegistAt2(estateRentInfo2.locgovRegistAt);
                setLocgovRegistDe2(Util.cvtDateStrForm(estateRentInfo2.locgovRegistDe, ". "));
                setLocgovRqstde2(Util.cvtDateStrForm(estateRentInfo2.locgovRqstde, ". "));
                setTaxofcRegistAt2(estateRentInfo2.taxofcRegistAt);
                setTaxofcRegistDe2(Util.cvtDateStrForm(estateRentInfo2.taxofcRegistDe, ". "));
                setTaxofcRqstde2(Util.cvtDateStrForm(estateRentInfo2.taxofcRqstde, ". "));
                setRentBgnde2(Util.cvtDateStrForm(estateRentInfo2.rentBgnde, ". "));
                setErsrAt2(estateRentInfo2.ersrAt);
                setErsrKnd2(estateRentInfo2.ersrKnd);
                setErsrDe2(Util.cvtDateStrForm(estateRentInfo2.ersrDe, ". "));
                setBizqitAt2(estateRentInfo2.bizqitAt);
                setBizqitDe2(Util.cvtDateStrForm(estateRentInfo2.bizqitDe, ". "));
                setStdrMktc2(Util.comma(estateRentInfo2.stdrMktc));
                setTrnsfrnDe2(Util.cvtDateStrForm(estateRentInfo2.trnsfrnDe, ". "));
                setLvhsDe2(Util.cvtDateStrForm(estateRentInfo2.lvhsDe, ". "));
                setRntchrgUplmtFlflAt2(estateRentInfo2.rntchrgUplmtFlflAt);
                setRentTyCnvrsDe2(Util.cvtDateStrForm(estateRentInfo2.rentTyCnvrsDe, ". "));
            }
            if (estateDetail.estateDetailSe === "1001") { // 주택
                if (estateDetail.estatePrpos === "1001") { // 주거전용(주택용도)
                    if (estateDetail.acqsSrc === "1001" || estateDetail.acqsSrc === "1005") { // 기존 아파트, 오피스텔취득
                        if (estateDetail.acqsCause === "1002") { // 매매
                            setEstateResideInfo1();
                            return 1;
                        } else if (estateDetail.acqsCause === "1003") { // 상속
                            setTitleText1("상속인");
                            setTitleText2("피상속인");
                            setBeforeEstatePrpos(inhrtncInfo.beforeEstatePrpos);
                            setEstateResideInfo1();
                            setEstateResideInfo2();
                            return 2;
                        } else if (estateDetail.acqsCause === "1004") { // 증여
                            setTitleText1("수증자");
                            setTitleText2("증여자");
                            setBeforeEstatePrpos(donaInfo.beforeEstatePrpos);
                            setEstateResideInfo1();
                            setEstateResideInfo2();
                            return 2;
                        }
                    } else if (estateDetail.acqsSrc === "1002") { // 분양권에 의한 아파트
                        if (["1011", "1012", "1002", "1003", "1004"].indexOf(estateDetail.acqsCause) !== -1) {
                            setEstateResideInfo1();
                            return 1;
                        }
                    } else if (estateDetail.acqsSrc === "1006") { // 일반분양오피스텔취득
                        if (["1011", "1012", "1002", "1003"].indexOf(estateDetail.acqsCause) !== -1) {
                            setEstateResideInfo1();
                            return 1;
                        } else if (estateDetail.acqsCause === "1004") {
                            setTitleText1("수증자");
                            setTitleText2("증여자");
                            setBeforeEstatePrpos(donaInfo.beforeEstatePrpos);
                            setEstateResideInfo1();
                            setEstateResideInfo2();
                            return 2;
                        }
                    } else if (estateDetail.acqsSrc === "1003") { // 조합원입주권에 의한 아파트
                        if (estateDetail.acqsCause === "1001") { // 원조합원
                            setTitleText1("신규주택");
                            setTitleText2("종전주택");
                            setBeforeEstatePrpos(redevpRebidngInfo.beforeEstatePrpos);
                            setEstateResideInfo1();
                            setEstateResideInfo2();
                            return 2;
                        } else if (estateDetail.acqsCause === "1002") { // 전매
                            setEstateResideInfo1();
                            return 1;
                        } else if (estateDetail.acqsCause === "1003") { // 상속
                            setTitleText1("상속인");
                            setTitleText2("피상속인");
                            setBeforeEstatePrpos(inhrtncInfo.beforeEstatePrpos);
                            setEstateResideInfo1();
                            setEstateResideInfo2();
                            return 2;
                        } else if (estateDetail.acqsCause === "1004") { // 증여
                            setEstateResideInfo1();
                            return 1;
                        }
                    } else if (estateDetail.acqsSrc === "1004") { // 자가신축주택취득
                        setEstateResideInfo1();
                        return 1;
                    }
                } else if (estateDetail.estatePrpos === "1002") { // 임대전용
                    if (estateDetail.estateDetailSe === "1001") { // 주택
                        if (estateDetail.acqsSrc === "1001" || estateDetail.acqsSrc === "1005") { // 기존 아파트, 오피스텔 취득
                            if (estateDetail.acqsCause === "1002") { // 매매
                                setEstateRentInfo1();
                                return 3;
                            } else if (estateDetail.acqsCause === "1003" || estateDetail.acqsCause === "1004") { // 상속, 증여
                                setBeforeEstatePrpos(inhrtncInfo.beforeEstatePrpos);
                                setEstateRentInfo1();
                                setEstateRentInfo2();
                                return 4;
                            }
                        } else if (estateDetail.acqsSrc === "1002") { // 분양권에 의한 아파트
                            setEstateRentInfo1();
                            return 3;
                        } else if (estateDetail.acqsSrc === "1003") { // 조합원입주권에 의한 아파트
                            if (estateDetail.acqsCause === "1001") { // 원조합원
                                setBeforeEstatePrpos(redevpRebidngInfo.beforeEstatePrpos);
                                setEstateRentInfo1();
                                setEstateRentInfo2();
                                return 4;
                            } else if (estateDetail.acqsCause === "1002") { // 전매
                                setEstateRentInfo1();
                                return 3;
                            } else if (estateDetail.acqsCause === "1003") { // 상속
                                setEstateRentInfo1();
                                setEstateRentInfo2();
                                return 4;
                            } else if (estateDetail.acqsCause === "1004") { // 증여
                                setEstateRentInfo1();
                                return 3;
                            }
                        } else if (estateDetail.acqsSrc === "1004") { // 자가신축주택취득
                            setEstateRentInfo1();
                            return 3;
                        } else if (estateDetail.acqsSrc === "1006") {
                            if (["1011", "1012", "1002", "1003"].indexOf(estateDetail.acqsCause) !== -1) { // 신규분양, 미분양, 전매, 상속
                                setEstateRentInfo1();
                                return 3;
                            } else if (estateDetail.acqsCause === "1004") { // 증여
                                setEstateRentInfo1();
                                setEstateRentInfo2();
                                return 4;
                            }
                        }
                    }
                }
            } else if (estateDetail.estateDetailSe === "1003") { // 조합원입주권
                if (estateDetail.acqsCause === "1001") {
                    setBeforeEstatePrpos(redevpRebidngInfo.beforeEstatePrpos);
                    if (redevpRebidngInfo.beforeEstatePrpos === "1001") {
                        setEstateResideInfo1();
                        return 1;
                    } else if (redevpRebidngInfo.beforeEstatePrpos === "1002") {
                        setEstateRentInfo1();
                        return 3;
                    }
                } else if (estateDetail.acqsCause === "1003") {
                    setBeforeEstatePrpos(inhrtncInfo.beforeEstatePrpos);
                    if (inhrtncInfo.beforeEstatePrpos === "1001") {
                        setTitleText1("상속인");
                        setTitleText2("피상속인");
                        setEstateResideInfo1();
                        setEstateResideInfo2();
                        return 2;
                    } else if (inhrtncInfo.beforeEstatePrpos === "1002") {
                        setEstateResideInfo1();
                        setEstateRentInfo2();
                        return 5;
                    }
                }
            }
        }

        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/assets/selAssetsTotalInfo.do",
            methodType: "post",
            paramData: {
                assetsSn: assetsSn
            },
            onSuccessFn: (res: any) => {
                setEstateSttus(res.item.estateInfo.estateSttus);
                setRdnmAdres(res.item.estateInfo.rdnmAdres);
                setRdnmAdresDetail(res.item.estateInfo.rdnmAdresDetail);
                setLnmAdres(res.item.estateInfo.lnmAdres);
                setSpfcKnd(res.item.estateInfo.spfcKnd);
                setOvpalotAt(res.item.estateInfo.ovpalotAt);
                setMdatTrgetAreaAt(res.item.estateInfo.mdatTrgetAreaAt);
                setAdresDrctInputAt(res.item.estateInfo.adresDrctInputAt);
                setAdresDrct(res.item.estateInfo.adresDrct);

                setPlotRight(res.item.estateArInfo.plotRight) // 대지권
                setPrvuseAr(res.item.estateArInfo.prvuseAr) // 전용면적
                setCnrsAr(res.item.estateArInfo.cnrsAr) // 공유면적
                setPlotAr(res.item.estateArInfo.plotAr) // 대지연면적
                setHouseTotar(res.item.estateArInfo.houseTotar) // 주택연면적
                setSopsrtTotar(res.item.estateArInfo.sopsrtTotar) // 상가연면적

                setEstateDetailSe(res.item.estateDetail.estateDetailSe); // 자산상세구분
                setHseNumAt(res.item.estateDetail.hseNumAt); // 주택수포함여부
                setEstateFom(res.item.estateDetail.estateFom); // 주택구분
                setProptaxLevyAt(res.item.estateDetail.proptaxLevyAt); // 재산세부과구분
                setAcqsSrc(res.item.estateDetail.acqsSrc); // 취득구분
                setAcqsCause(res.item.estateDetail.acqsCause); // 취득원인
                setEstatePrpos(res.item.estateDetail.estatePrpos); // 주택용도
                setRghpsnList(res.item.rghpsnList); // 소유지분율
                setLandRghpsnList(res.item.landRghpsnList); // 토지 소유지분율

                getAcqsFormSeq(res.item.estateDetail, res.item.trdeInfo, res.item.inhrtncInfo, res.item.donaInfo, res.item.lttotRightInfo, res.item.redevpRebidngInfo, res.item.selfNwccInfo);

                const rarFormSeq = getRarFormSeq(
                    res.item.estateDetail,
                    res.item.inhrtncInfo,
                    res.item.donaInfo,
                    res.item.redevpRebidngInfo,
                    res.item.estateRentInfo1,
                    res.item.estateRentInfo2,
                    res.item.estateResideInfo1,
                    res.item.estateResideInfo2
                );
                if (Util.isNotEmpty(rarFormSeq)) {
                    setRarFormSeq(rarFormSeq);
                }
                setWwinHouseAt(res.item.excptHouseInfo.wwinHouseAt);
                setAcqsHouseAt(res.item.excptHouseInfo.acqsHouseAt);
                setFrhlHouseAt(res.item.excptHouseInfo.frhlHouseAt);
                setCrltsHouseAt(res.item.excptHouseInfo.crltsHouseAt);
                setMrtgHouseAt(res.item.excptHouseInfo.mrtgHouseAt);
                setChildHouseAt(res.item.excptHouseInfo.childHouseAt);
                setSalesHouseAt(res.item.excptHouseInfo.salesHouseAt);
                setEstateHouseAt(res.item.excptHouseInfo.estateHouseAt);

                if (res.item.estateInfo.estateSttus === "1002") {
                    let tempLawList = [];
                    for (const lawInfo of res.item.lawList) {
                        if (lawInfo.dcsnAt === "Y" && Util.isNotEmpty(lawInfo.lawordSn)) {
                            lawInfo.popupFlag = true;
                            tempLawList.push(lawInfo);
                        }
                    }

                    const lawNms: string[] = [];

                    if (res.item.excptHouseInfo.wwinHouseAt === "Y") {
                        lawNms.push("상생임대주택 - 소득세법시행령 제155조의 3");
                    }
                    if (res.item.excptHouseInfo.acqsHouseAt === "Y") {
                        lawNms.push("부득이한 사유로 취득한 주택 - 소득세법시행령 제155조 제8항");
                    }
                    if (res.item.excptHouseInfo.frhlHouseAt === "Y") {
                        if (AssetsUtil.getAnswerValue(res.item.assetsQestnList, 62) === "Y") {
                            lawNms.push("농어촌 주택 - 소득세법시행령 제155조 제7항 상속받은 주택");
                        }
                        if (AssetsUtil.getAnswerValue(res.item.assetsQestnList, 63) === "Y") {
                            lawNms.push("농어촌 주택 - 소득세법시행령 제155조 제7항 이농주택");
                        }
                        if (AssetsUtil.getAnswerValue(res.item.assetsQestnList, 64) === "Y") {
                            lawNms.push("농어촌 주택 - 소득세법시행령 제155조 제7항 귀농주택");
                        }
                        if (AssetsUtil.getAnswerValue(res.item.assetsQestnList, 65) === "Y") {
                            lawNms.push("농어촌 주택 - 조세특례제한법 제99조의 4 농어촌주택 등");
                        }
                        if (AssetsUtil.getAnswerValue(res.item.assetsQestnList, 66) === "Y") {
                            lawNms.push("농어촌 주택 - 조세특례제한법 제99조의 4 고향주택 등");
                        }
                    }
                    if (res.item.excptHouseInfo.crltsHouseAt === "Y") {
                        lawNms.push("문화재 주택 - 소득세법시행령 제155조 제6항");
                    }
                    if (res.item.excptHouseInfo.mrtgHouseAt === "Y") {
                        lawNms.push("장기저당담보주택 - 소득세법시행령 제155조의 2");
                    }
                    if (res.item.excptHouseInfo.childHouseAt === "Y") {
                        lawNms.push("장기어린이집주택 - 소득세법시행령 제155조 제20항");
                    }
                    if (res.item.excptHouseInfo.salesHouseAt === "Y") {
                        lawNms.push("주택 신축 판매업용 주택 - 소득세법 제19조");
                    }
                    if (res.item.excptHouseInfo.estateHouseAt === "Y") {
                        lawNms.push("부동산 매매업용 주택 - 소득세법 제19조");
                    }
                    for (const lawNm of lawNms) {
                        tempLawList.push({
                            lawordSn: -1,
                            lawordDetailSn: -1,
                            lawordDetailDc: "",
                            lawordDetailNm: lawNm,
                            lawordTy: "1009",
                            dcsnAt: "",
                            popupFlag: false
                        });
                    }
                    setLawList(tempLawList);
                } else if (res.item.estateInfo.estateSttus === "1003") {
                    setAcqsAnswerList(res.item.acqsAnswerList);
                }
                AppContext.hideSpinner();
            }
        })
    }, [assetsSn]);

    const TrdeCommonEl = () => {
        let titleSubText = "";
        let titleSubText2 = "";
        if (["1002", "1006"].indexOf(acqsSrc) !== -1) { // 분양권에 의한 취득, 일반분양오피스텔취득
            titleSubText = "분양권 ";
            titleSubText2 = "분양";
        } else if (acqsSrc === "1003") { // 조합원입주권에 의한 취득
            titleSubText = "조합원입주권 ";
            titleSubText2 = "조합원입주권";
        }
        return (
            <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                <colgroup>
                    <col className="w250" />
                    <col />
                </colgroup>
                <tbody>
                    <tr>
                        <th>{titleSubText2}계약일</th>
                        <td>{cntrctDe}</td>
                    </tr>
                    <tr>
                        <th>{titleSubText}계약금지급일</th>
                        <td>{entyPymntde}</td>
                    </tr>
                    <tr>
                        <th>{titleSubText}잔금지급일</th>
                        <td>{surlusPymntde}</td>
                    </tr>
                    <tr>
                        <th>{titleSubText}소유권이전접수일</th>
                        <td>{ownshipTransrRceptDe}</td>
                    </tr>
                    {["1002", "1006"].indexOf(acqsSrc) !== -1 ?
                        <tr>
                            <th>분양대금완납일</th>
                            <td>{fullPymntde}</td>
                        </tr>
                    : <></>}
                    <tr>
                        <th>사용승인일</th>
                        <td>{useConfmDe}</td>
                    </tr>
                    <tr>
                        <th>취득당시 기준시가(원)</th>
                        <td>{stdrMktc} <span className="fc999999">원</span></td>
                    </tr>
                    <tr>
                        <th>취득당시 실거래가액(원)</th>
                        <td>{rlDlpc} <span className="fc999999">원</span></td>
                    </tr>
                </tbody>
            </table>
        )
    }

    const InhrtncCommonEl = () => {
        let titleSubText = "";
        if (["1001", "1005"].indexOf(acqsSrc) !== -1) { // 분양권에 의한 취득, 일반분양오피스텔취득
            titleSubText = "주택 ";
        } else if (["1002", "1006"].indexOf(acqsSrc) !== -1) { // 분양권에 의한 취득, 일반분양오피스텔취득
            titleSubText = "분양권 ";
        } else if (acqsSrc === "1003") { // 조합원입주권에 의한 취득
            titleSubText = "종전주택 ";
        }
        return (
            <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                <colgroup>
                    <col className="w250" />
                    <col />
                </colgroup>
                <tbody>
                <tr>
                    <th className="taC posR">특례상속주택여부</th>
                    <td>{Util.getValToCode("1042", excptInhrtncAt)}</td>
                </tr>
                <tr>
                    <th className="taC posR">동일세대원 여부</th>
                    <td>{Util.getValToCode("1016", inhrtncKnd1)}</td>
                </tr>
                <tr>
                    <th className="taC">상속의 종류</th>
                    <td>{Util.getValToCode("1017", inhrtncKnd2)}</td>
                </tr>
                <tr>
                    <th className="taC" title='지분권자의 종류'>지분권자의 종류</th>
                    <td>{Util.getValToCode("1018", inhrtncKnd3)}</td>
                </tr>
                <tr>
                    <th className="taC">상속개시일</th>
                    <td>{beginDe}</td>
                </tr>
                <tr>
                    <th className="taC">
                        피상속인의 {titleSubText} 계약일
                    </th>
                    <td>{cntrctDe}</td>
                </tr>
                <tr>
                    <th className="taC">피상속인의 {titleSubText} 계약금지급일</th>
                    <td>{entyPymntde}</td>
                </tr>
                <tr>
                    <th className="taC">피상속인의 {titleSubText} 잔금지급일</th>
                    <td>{surlusPymntde}</td>
                </tr>
                <tr>
                    <th className="taC">피상속인의 {titleSubText} 소유권이전일</th>
                    <td>{ownshipTransrDe}</td>
                </tr>
                {["1002", "1006"].indexOf(acqsSrc) !== -1 ?
                    <tr>
                        <th>분양대금완납일</th>
                        <td>{fullPymntde}</td>
                    </tr>
                : <></>}
                {acqsSrc === "1003" ?
                    <React.Fragment>
                        <tr>
                            <th className="taC posR">관리처분계획인가일</th>
                            <td>{manageDspsPlanDe}</td>
                        </tr>
                        <tr>
                            <th className="taC posR">사업시행인가일</th>
                            <td>{bsnsImpCnfmDe}</td>
                        </tr>
                    </React.Fragment>
                : <></>}
                <tr>
                    <th className="taC" title="사용승인일">사용승인일</th>
                    <td>{useConfmDe}</td>
                </tr>
                <tr>
                    <th className="taC">취득당시 기준시가(원)</th>
                    <td>{stdrMktc} <span className="fc999999">원</span></td>
                </tr>
                <tr>
                    <th className="taC">{getRlDlpcFldNm()}</th>
                    <td>{rlDlpc} <span className="fc999999">원</span></td>
                </tr>
                </tbody>
            </table>
        )
    }

    const DonaCommonEl = () => {
        let titleSubText = "";
        if (["1001", "1005"].indexOf(acqsSrc) !== -1) { // 분양권에 의한 취득, 일반분양오피스텔취득
            titleSubText = "주택 ";
        } else if (["1002", "1006"].indexOf(acqsSrc) !== -1) { // 분양권에 의한 취득, 일반분양오피스텔취득
            titleSubText = "분양권 ";
        } else if (acqsSrc === "1003") { // 조합원입주권에 의한 취득
            titleSubText = "종전주택 ";
        }
        return (
            <React.Fragment>
                <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                    <colgroup>
                        <col className="w250" />
                        <col />
                    </colgroup>
                    <tbody>
                        {acqsSrc === "1003" ?
                            <React.Fragment>
                                <tr>
                                    <th className="taC posR">사업시행인가일</th>
                                    <td>{bsnsImpCnfmDe}</td>
                                </tr>
                                <tr>
                                    <th className="taC posR">관리처분계획인가일</th>
                                    <td>{manageDspsCnfmDe}</td>
                                </tr>
                            </React.Fragment>
                        : <></>}
                        {["1001", "1002", "1005", "1006"].indexOf(acqsSrc) !== -1 ?
                            <React.Fragment>
                                <tr>
                                    <th className="taC">증여의 종류</th>
                                    <td>{Util.getValToCode("1019", donaKnd)}</td>
                                </tr>
                                <tr>
                                    <th className="taC">증여자와의 관계</th>
                                    <td>{Util.getValToCode("1056", donaKnd2)}</td>
                                </tr>
                            </React.Fragment>
                        : <></>}
                        <tr>
                            <th className="taC">증여 계약일</th>
                            <td>{donaCntrctDe}</td>
                        </tr>
                        <tr>
                            <th className="taC">증여등기접수일</th>
                            <td>{rgistRceptDe}</td>
                        </tr>
                        <tr>
                            <th className="taC">증여자의 {titleSubText} 계약일</th>
                            <td>{cntrctDe}</td>
                        </tr>
                        <tr>
                            <th className="taC">증여자의 {titleSubText} 계약금지급일</th>
                            <td>{entyPymntde}</td>
                        </tr>
                        <tr>
                            <th className="taC">증여자의 {titleSubText} 잔금지급일</th>
                            <td>{surlusPymntde}</td>
                        </tr>
                        <tr>
                            <th className="taC">증여자의 {titleSubText} 소유권이전일</th>
                            <td>{ownshipTransrDe}</td>
                        </tr>
                        {["1002", "1006"].indexOf(acqsSrc) !== -1 ?
                            <tr>
                                <th>분양대금완납일</th>
                                <td>{fullPymntde}</td>
                            </tr>
                        : <></>}
                        <tr>
                            <th className="taC">사용승인일</th>
                            <td>{useConfmDe}</td>
                        </tr>
                        <tr>
                            <th className="taC">취득당시 기준시가(원)</th>
                            <td>{stdrMktc} <span className="fc999999">원</span></td>
                        </tr>
                        <tr>
                            <th className="taC">{getRlDlpcFldNm()}</th>
                            <td>{rlDlpc} <span className="fc999999">원</span></td>
                        </tr>
                    </tbody>
                </table>
            </React.Fragment>
        )
    }

    const LttotRightCommonEl = () => {
        return (
            <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                <colgroup>
                    <col className="w250" />
                    <col />
                </colgroup>
                <tbody>
                    <tr>
                        <th>분양계약일</th>
                        <td>{cntrctDe}</td>
                    </tr>
                    <tr>
                        <th>분양권 계약금지급일</th>
                        <td>{entyPymntde}</td>
                    </tr>
                    <tr>
                        <th>분양대금완납일</th>
                        <td>{fullPymntde}</td>
                    </tr>
                    <tr>
                        <th>사용승인일</th>
                        <td>{useConfmDe}</td>
                    </tr>
                    <tr>
                        <th>취득당시 기준시가(원)</th>
                        <td>{stdrMktc} <span className="fc999999">원</span></td>
                    </tr>
                    <tr>
                        <th>{getRlDlpcFldNm()}</th>
                        <td>{rlDlpc} <span className="fc999999">원</span></td>
                    </tr>
                </tbody>
            </table>
        )
    }
    const AcqsFormEl = () => {
        if (estateDetailSe === "1001") {
            if (estateFom === "1001") {
                if (acqsSrc === "1001") {
                    if (acqsCause === "1002") {
                        return (
                            <React.Fragment>{TrdeCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1003") {
                        return (
                            <React.Fragment>{InhrtncCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1004") {
                        return (
                            <React.Fragment>{DonaCommonEl()}</React.Fragment>
                        )
                    }
                } else if (acqsSrc === "1002") {
                    if (acqsCause === "1011" || acqsCause === "1012") {
                        return (
                            <React.Fragment>{LttotRightCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1002") {
                        return (
                            <React.Fragment>{TrdeCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1003") {
                        return (
                            <React.Fragment>{InhrtncCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1004") {
                        return (
                            <React.Fragment>{DonaCommonEl()}</React.Fragment>
                        )
                    }
                } else if (acqsSrc === "1003") {
                    if (acqsCause === "1001") {
                        return (
                            <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                                <colgroup>
                                    <col className="w250" />
                                    <col />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th className="taC">종전부동산 유형</th>
                                        <td>{Util.getValToCode("1020", beforeEstateTy)}</td>
                                    </tr>
                                    <tr>
                                        <th className="taC">종전주택 용도</th>
                                        <td>{Util.getValToCode("1008", beforeEstatePrpos)}</td>
                                    </tr>
                                    <tr>
                                        <th className="taC">종전주택 계약일</th>
                                        <td>{cntrctDe}</td>
                                    </tr>
                                    <tr>
                                        <th className="taC">종전주택 계약금지급일</th>
                                        <td>{entyPymntde}</td>
                                    </tr>
                                    <tr>
                                        <th className="taC">종전주택 잔금지급일</th>
                                        <td>{surlusPymntde}</td>
                                    </tr>
                                    <tr>
                                        <th className="taC">종전주택 등기접수일</th>
                                        <td>{rgistRceptDe}</td>
                                    </tr>
                                    <tr>
                                        <th className="taC posR">관리처분계획인가일</th>
                                        <td>{manageDspsPlanDe}</td>
                                    </tr>
                                    <tr>
                                        <th className="taC posR">사업시행인가일</th>
                                        <td>{bsnsImpCnfmDe}</td>
                                    </tr>
                                    <tr>
                                        <th className="taC">사용승인일</th>
                                        <td>{useConfmDe}</td>
                                    </tr>
                                    <tr>
                                        <th className="taC">종전부동산 평가액</th>
                                        <td>{evlamt}</td>
                                    </tr>
                                    <tr>
                                        <th className="taC">취득당시 기준시가(원)</th>
                                        <td>{stdrMktc} <span className="fc999999">원</span></td>
                                    </tr>
                                    <tr>
                                        <th className="taC">{getRlDlpcFldNm()}</th>
                                        <td>{rlDlpc} <span className="fc999999">원</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        )
                    } else if (acqsCause === "1002") {
                        return (
                            <React.Fragment>{TrdeCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1003") {
                        return (
                            <React.Fragment>{InhrtncCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1004") {
                        return (
                            <React.Fragment>{DonaCommonEl()}</React.Fragment>
                        )
                    }
                }
            } else if (["1002", "1003", "1004", "1005"].indexOf(estateFom) !== -1) {
                if (acqsSrc === "1001") {
                    if (acqsCause === "1002") {
                        return (
                            <React.Fragment>{TrdeCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1003") {
                        return (
                            <React.Fragment>{InhrtncCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1004") {
                        return (
                            <React.Fragment>{DonaCommonEl()}</React.Fragment>
                        )
                    }
                } else if (acqsSrc === "1004") {
                    return (
                        <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                            <colgroup>
                                <col className="w250" />
                                <col />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th className="taC" title="사용승인일">사용승인일</th>
                                    <td>{useConfmDe}</td>
                                </tr>
                                <tr>
                                    <th className="taC">취득당시 기준시가(원)</th>
                                    <td>{stdrMktc} <span className="fc999999">원</span></td>
                                </tr>
                                <tr>
                                    <th className="taC">{getRlDlpcFldNm()}</th>
                                    <td>{rlDlpc} <span className="fc999999">원</span></td>
                                </tr>
                            </tbody>
                        </table>
                    )
                }
            } else if (estateFom === "1006") {
                if (acqsSrc === "1005") {
                    if (acqsCause === "1002") {
                        return (
                            <React.Fragment>{TrdeCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1003") {
                        return (
                            <React.Fragment>{InhrtncCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1004") {
                        return (
                            <React.Fragment>{DonaCommonEl()}</React.Fragment>
                        )
                    }
                } else if (acqsSrc === "1006") {
                    if (acqsCause === "1011" || acqsCause === "1012") {
                        return (
                            <React.Fragment>{LttotRightCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1002") {
                        return (
                            <React.Fragment>{TrdeCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1003") {
                        return (
                            <React.Fragment>{InhrtncCommonEl()}</React.Fragment>
                        )
                    } else if (acqsCause === "1004") {
                        return (
                            <React.Fragment>{DonaCommonEl()}</React.Fragment>
                        )
                    }
                }
            }
        } else if (estateDetailSe === "1002") {
            if (acqsCause === "1001") {
                return (
                    <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                        <colgroup>
                            <col className="w250" />
                            <col />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>분양권 계약일</th>
                                <td>{cntrctDe}</td>
                            </tr>
                            <tr>
                                <th>분양권 계약금지급일</th>
                                <td>{entyPymntde}</td>
                            </tr>
                            <tr>
                                <th className="taC">분양권 당첨일</th>
                                <td>{przwinDe}</td>
                            </tr>
                            <tr>
                                <th className="taC">{getRlDlpcFldNm()}</th>
                                <td>{rlDlpc} <span className="fc999999">원</span></td>
                            </tr>
                        </tbody>
                    </table>
                )
            } else if (acqsCause === "1002") {
                return (
                    <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                        <colgroup>
                            <col className="w250" />
                            <col />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>분양권 계약일</th>
                                <td>{cntrctDe}</td>
                            </tr>
                            <tr>
                                <th>분양권 계약금지급일</th>
                                <td>{entyPymntde}</td>
                            </tr>
                            <tr>
                                <th className="taC">분양권 잔금지급일</th>
                                <td>{surlusPymntde}</td>
                            </tr>
                            <tr>
                                <th className="taC">{getRlDlpcFldNm()}</th>
                                <td>{rlDlpc} <span className="fc999999">원</span></td>
                            </tr>
                        </tbody>
                    </table>
                )
            } else if (acqsCause === "1003") {
                return (
                    <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                        <colgroup>
                            <col className="w250" />
                            <col />
                        </colgroup>
                        <tbody>
                        <tr>
                            <th className="taC">특례상속분양권여부</th>
                            <td>{Util.getValToCode("1042", excptInhrtncAt)}</td>
                        </tr>
                        <tr>
                            <th className="taC posR">동일세대원 여부</th>
                            <td>{Util.getValToCode("1016", inhrtncKnd1)}</td>
                        </tr>
                        <tr>
                            <th className="taC">상속의 종류</th>
                            <td>{Util.getValToCode("1017", inhrtncKnd2)}</td>
                        </tr>
                        <tr>
                            <th className="taC">지분권자의 종류</th>
                            <td>{Util.getValToCode("1018", inhrtncKnd3)}</td>
                        </tr>
                        <tr>
                            <th className="taC">상속개시일</th>
                            <td>{beginDe}</td>
                        </tr>
                        <tr>
                            <th className="taC">피상속인의 분양권 계약일</th>
                            <td>{cntrctDe}</td>
                        </tr>
                        <tr>
                            <th className="taC">피상속인의 분양권 계약금지급일</th>
                            <td>{entyPymntde}</td>
                        </tr>
                        <tr>
                            <th className="taC">피상속인의 분양권 잔금지급일</th>
                            <td>{surlusPymntde}</td>
                        </tr>
                        <tr>
                            <th className="taC">{getRlDlpcFldNm()}</th>
                            <td>{rlDlpc} <span className="fc999999">원</span></td>
                        </tr>
                        </tbody>
                    </table>
                )
            } else if (acqsCause === "1004") {
                return (
                    <React.Fragment>
                        <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                            <colgroup>
                                <col className="w250" />
                                <col />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th className="taC">증여의 종류</th>
                                    <td>{Util.getValToCode("1019", donaKnd)}</td>
                                </tr>
                                <tr>
                                    <th className="taC">증여자와의 관계</th>
                                    <td>{Util.getValToCode("1056", donaKnd2)}</td>
                                </tr>
                                <tr>
                                    <th className="taC">증여계약일</th>
                                    <td>{donaCntrctDe}</td>
                                </tr>
                                <tr>
                                    <th className="taC">권리승계일</th>
                                    <td>{rightSuccsDe}</td>
                                </tr>
                                <tr>
                                    <th className="taC">증여자의 분양권 계약일</th>
                                    <td>{cntrctDe}</td>
                                </tr>
                                <tr>
                                    <th className="taC">증여자의 분양권 계약금지급일</th>
                                    <td>{entyPymntde}</td>
                                </tr>
                                <tr>
                                    <th className="taC">증여자의 분양권 잔금지급일</th>
                                    <td>{surlusPymntde}</td>
                                </tr>
                                <tr>
                                    <th className="taC">{getRlDlpcFldNm()}</th>
                                    <td>{rlDlpc} <span className="fc999999">원</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </React.Fragment>
                )
            }
        } else if (estateDetailSe === "1003") {
            if (acqsCause === "1001") {
                return (
                    <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                        <colgroup>
                            <col className="w250" />
                            <col />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th className="taC">종전부동산의 유형</th>
                                <td>{Util.getValToCode("1020", beforeEstateTy)}</td>
                            </tr>
                            <tr>
                                <th className="taC">종전주택 용도</th>
                                <td>{Util.getValToCode("1008", beforeEstatePrpos)}</td>
                            </tr>
                            <tr>
                                <th className="taC">종전주택 계약일</th>
                                <td>{cntrctDe}</td>
                            </tr>
                            <tr>
                                <th className="taC">종전주택 계약금지급일</th>
                                <td>{entyPymntde}</td>
                            </tr>
                            <tr>
                                <th className="taC">종전주택 잔금지급일</th>
                                <td>{surlusPymntde}</td>
                            </tr>
                            <tr>
                                <th className="taC">종전주택 등기접수일</th>
                                <td>{rgistRceptDe}</td>
                            </tr>
                            <tr>
                                <th className="taC posR">관리처분계획인가일</th>
                                <td>{manageDspsPlanDe}</td>
                            </tr>
                            <tr>
                                <th className="taC posR">사업시행인가일</th>
                                <td>{bsnsImpCnfmDe}</td>
                            </tr>
                            <tr>
                                <th className="taC">종전부동산 평가액</th>
                                <td>{evlamt} <span className="fc999999">원</span></td>
                            </tr>
                            <tr>
                                <th className="taC">{getRlDlpcFldNm()}</th>
                                <td>{rlDlpc} <span className="fc999999">원</span></td>
                            </tr>
                        </tbody>
                    </table>
                )
            } else if (acqsCause === "1002") {
                return (
                    <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                        <colgroup>
                            <col className="w250" />
                            <col />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>조합원입주권 계약일</th>
                                <td>{cntrctDe}</td>
                            </tr>
                            <tr>
                                <th>조합원입주권 계약금지급일</th>
                                <td>{entyPymntde}</td>
                            </tr>
                            <tr>
                                <th className="taC">조합원입주권 잔금지급일</th>
                                <td>{surlusPymntde}</td>
                            </tr>
                            <tr>
                                <th className="taC">{getRlDlpcFldNm()}</th>
                                <td>{rlDlpc} <span className="fc999999">원</span></td>
                            </tr>
                        </tbody>
                    </table>
                )
            } else if (acqsCause === "1003") {
                return (
                    <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                        <colgroup>
                            <col className="w250" />
                            <col />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th className="taC">특례상속 조합원입주권 여부</th>
                                <td>{excptInhrtncAt}</td>
                            </tr>
                            <tr>
                                <th className="taC posR">동일세대원 여부</th>
                                <td>{Util.getValToCode("1016", inhrtncKnd1)}</td>
                            </tr>
                            <tr>
                                <th className="taC" title='상속의 종류'>상속의 종류</th>
                                <td>{Util.getValToCode("1017", inhrtncKnd2)}</td>
                            </tr>
                            <tr>
                                <th className="taC" title='지분권자의 종류'>지분권자의 종류</th>
                                <td>{Util.getValToCode("1018", inhrtncKnd3)}</td>
                            </tr>
                            <tr>
                                <th className="taC" title='상속개시일'>상속개시일</th>
                                <td>{beginDe}</td>
                            </tr>
                            <tr>
                                <th>피상속인 조합원입주권 계약일</th>
                                <td>{cntrctDe}</td>
                            </tr>
                            <tr>
                                <th><span className="fs13">피상속인 조합원입주권 계약금지급일</span></th>
                                <td>{entyPymntde}</td>
                            </tr>
                            <tr>
                                <th className="taC"><span className="fs13">피상속인 조합원입주권 잔금지급일</span></th>
                                <td>{surlusPymntde}</td>
                            </tr>
                            <tr>
                                <th className="taC posR">관리처분계획인가일</th>
                                <td>{manageDspsPlanDe}</td>
                            </tr>
                            <tr>
                                <th className="taC posR">사업시행인가일</th>
                                <td>{bsnsImpCnfmDe}</td>
                            </tr>
                            <tr>
                                <th className="taC">{getRlDlpcFldNm()}</th>
                                <td>{rlDlpc} <span className="fc999999">원</span></td>
                            </tr>
                        </tbody>
                    </table>
                )
            } else if (acqsCause === "1004") {
                return (
                    <React.Fragment>
                        <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                            <colgroup>
                                <col className="w250" />
                                <col />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th className="taC">증여의 종류</th>
                                    <td>{Util.getValToCode("1019", donaKnd)}</td>
                                </tr>
                                <tr>
                                    <th className="taC">증여자와의 관계</th>
                                    <td>{Util.getValToCode("1056", donaKnd2)}</td>
                                </tr>
                                <tr>
                                    <th className="taC">증여계약일</th>
                                    <td>{donaCntrctDe}</td>
                                </tr>
                                <tr>
                                    <th className="taC">권리승계일</th>
                                    <td>{rightSuccsDe}</td>
                                </tr>
                                <tr>
                                    <th className="taC">증여자의 조합원입주권 계약일</th>
                                    <td>{cntrctDe}</td>
                                </tr>
                                <tr>
                                    <th className="taC"><span className="fs13">증여자의 조합원입주권 계약금지급일</span></th>
                                    <td>{entyPymntde}</td>
                                </tr>
                                <tr>
                                    <th className="taC"><span className="fs13">증여자의 조합원입주권 잔금지급일</span></th>
                                    <td>{surlusPymntde}</td>
                                </tr>
                                <tr>
                                    <th className="taC posR">관리처분계획인가일</th>
                                    <td>{manageDspsCnfmDe}</td>
                                </tr>
                                <tr>
                                    <th className="taC posR">사업시행인가일</th>
                                    <td>{bsnsImpCnfmDe}</td>
                                </tr>
                                <tr>
                                    <th className="taC">{getRlDlpcFldNm()}</th>
                                    <td>{rlDlpc} <span className="fc999999">원</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </React.Fragment>
                )
            }
        }
        return "";
    }

    return (
        <React.Fragment>
            {estateSttus === "1002" ?
                <React.Fragment>
                    <div className="h40 dpFlx aiC">
                        <span className="SpoqaHanSansNeo-Medium fs18 ls09 fc222222">정보 및 의견</span>
                    </div>
                    <table className={
                        "forest-list-table w100p h48 mt10 mb40 " +
                        "bdcDDDDDD bdt1 bdb1 " +
                        "th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-ls07 " +
                        "td-bdcDDDDDD td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-ls1 td-p15"
                    }>
                        <colgroup>
                            <col className="w170" />
                            <col />
                            <col className="w74" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>구분</th>
                                <th>적용 근거법</th>
                                <th>내용</th>
                            </tr>
                            {lawList && lawList.map((item: any, idx: number) => {
                                if (Util.isNotEmpty(item.lawordSn)) {
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                <span className="SpoqaHanSansNeo-Medium fs16 ls08 fc666666">{Util.getValToCode("1049", item.lawordTy)}</span>
                                            </td>
                                            <td className="taL">
                                                <span className="SpoqaHanSansNeo-Medium fs16 ls08 fc666666">
                                                    {item.lawordDetailNm}
                                                    {Util.isNotEmpty(item.lawordDetailDc) ? " - " + item.lawordDetailDc : ""}
                                                </span>
                                            </td>
                                            <td>
                                                {item.popupFlag ?
                                                    <div className="w100p h100p dpFlx aiC jcC">
                                                        <div className="w50 h20 dpFlx aiC jcSb csP" onClick={() => {
                                                            setLawordSn(item.lawordSn);
                                                            setLawordDetailSn(item.lawordDetailSn);
                                                            setLawModalOpen(true);
                                                        }}>
                                                            <img className="" src={require('img/system/assets/icon_file20.png')} alt="icon_file20" />
                                                            <span className="SpoqaHanSansNeo-Medium fs14 ls07 tdU fc999999">내용</span>
                                                        </div>
                                                    </div>
                                                : "-"}
                                            </td>
                                        </tr>
                                    )
                                } else {
                                    return (
                                        <tr key={idx}>
                                            <td colSpan={3}>
                                                <span className="SpoqaHanSansNeo-Medium fs16 ls08 fc666666">조회된 법령 정보가 없습니다.</span>
                                            </td>
                                        </tr>
                                    )
                                }
                            })}
                            {lawList && lawList.length === 0 ?
                                <tr>
                                    <td colSpan={3}>
                                        <span className="SpoqaHanSansNeo-Medium fs16 ls08 fc666666">조회된 법령 정보가 없습니다.</span>
                                    </td>
                                </tr>
                            : <></>}
                        </tbody>
                    </table>
                </React.Fragment> : <></>
            }
            {estateSttus === "1003" ?
                <React.Fragment>
                    <div className="h40 dpFlx aiC">
                        <span className="SpoqaHanSansNeo-Medium fs18 ls09 fc222222">추가질문</span>
                    </div>
                    <table className={
                        "forest-list-table w100p h48 mt10 mb40 " +
                        "bdcDDDDDD bdt1 bdb1 " +
                        "th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-ls07 th-bdb1 " +
                        "td-bdcDDDDDD td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-ls1"
                    }>
                        <colgroup>
                            <col />
                            <col className="w300" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>취득세액 계산 관련 추가질문</th>
                                <th>답변</th>
                            </tr>
                            {acqsAnswerList && acqsAnswerList.map((item: any, idx: number) => {
                                let codeTy = "";
                                if (item.assetsQestnSn === 44) {
                                    codeTy="1036";
                                } else if (item.assetsQestnSn === 45) {
                                    codeTy="1051";
                                } else if (item.assetsQestnSn === 46) {
                                    codeTy="1052";
                                } else if (item.assetsQestnSn === 47) {
                                    codeTy="1036";
                                } else if (item.assetsQestnSn === 48) {
                                    codeTy="1036";
                                } else if (item.assetsQestnSn === 49) {
                                    codeTy="1036";
                                } else if (item.assetsQestnSn === 50) {
                                    codeTy="1053";
                                } else if (item.assetsQestnSn === 51) {
                                    codeTy="1054";
                                } else if (item.assetsQestnSn === 56) {
                                    codeTy="1064";
                                } else if (item.assetsQestnSn === 85) {
                                    codeTy="1069";
                                } else if (item.assetsQestnSn === 86) {
                                    codeTy="1077";
                                }
                                return (
                                    <tr key={idx}>
                                        <td><span className="SpoqaHanSansNeo-Medium fs16 ls08 fc666666">{item.qestnText}</span></td>
                                        <td><span className="SpoqaHanSansNeo-Medium fs16 ls08 fc666666">{Util.isEmpty(codeTy) ? Util.comma(item.answerCodeValue) : Util.getValToCode(codeTy, item.answerCodeValue)}</span></td>
                                    </tr>
                                )
                            })}
                            {acqsAnswerList && acqsAnswerList.length === 0 ?
                                <tr>
                                    <td colSpan={3}>
                                        <span className="SpoqaHanSansNeo-Medium fs16 ls08 fc666666">해당하는 질문 정보가 없습니다.</span>
                                    </td>
                                </tr> : <></>
                            }
                        </tbody>
                    </table>
                </React.Fragment> : <></>
            }
            <div className="h40 dpFlx aiC">
                <span className="SpoqaHanSansNeo-Medium fs18 ls09 fc222222">자산 기본정보</span>
            </div>
            <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                <colgroup>
                    <col className="w250" />
                    <col className="w180" />
                    <col />
                </colgroup>
                <tbody>
                <tr>
                    <th className="taC">자산구분</th>
                    <td colSpan={2}>
                        {Util.getValToCode("1006", estateDetailSe)}
                    </td>
                </tr>
                <tr className={(estateDetailSe !== "1001" ? "" : "dpNone")}>
                    <th className="taC">주택수포함여부</th>
                    <td colSpan={2}>
                        {Util.getValToCode("1058", hseNumAt)}
                    </td>
                </tr>
                {adresDrctInputAt === "Y" ?
                    <tr>
                        <th className="taC">주소</th>
                        <td colSpan={2}>{adresDrct} {rdnmAdresDetail}</td>
                    </tr>
                    :
                    <React.Fragment>
                        <tr>
                            <th className="taC" rowSpan={2}>주소</th>
                            <th className="taL">도로명</th>
                            <td>{rdnmAdres} {rdnmAdresDetail}</td>
                        </tr>
                        <tr>
                            <th className="taL">지번</th>
                            <td>{lnmAdres}</td>
                        </tr>
                    </React.Fragment>
                }
                <tr className={(estateDetailSe !== "1001" ? "dpNone" : "")}>
                    <th className="taC">주택구분</th>
                    <td colSpan={2}>{Util.getValToCode("1007", estateFom)}</td>
                </tr>
                <tr className={(estateDetailSe === "1001" && estateFom === "1006" ? "" : "dpNone")}>
                    <th className="taC">재산세부과구분</th>
                    <td colSpan={2}>
                        {Util.getValToCode("1059", proptaxLevyAt)}
                    </td>
                </tr>
                <tr className={(estateDetailSe !== "1001" ? "dpNone" : "")}>
                    <th className="taC">취득구분</th>
                    <td colSpan={2}>{Util.getValToCode(estateFom === "1001" ? "1055" : "1009", acqsSrc)}</td>
                </tr>
                <tr className={(acqsSrc === "1004" ? "dpNone" : "")}>
                    <th className="taC">취득원인</th>
                    <td colSpan={2}>
                        {estateDetailSe === "1001" && (acqsSrc === "1001" || acqsSrc === "1005") ? Util.getValToCode(("1039"), acqsCause) : ""}
                        {estateDetailSe === "1001" && (acqsSrc === "1002" || acqsSrc === "1006") ? Util.getValToCode(("1040"), acqsCause) : ""}
                        {estateDetailSe === "1001" && acqsSrc === "1003" ? Util.getValToCode(("1041"), acqsCause) : ""}
                        {estateDetailSe === "1002" ? Util.getValToCode(("1043"), acqsCause) : ""}
                        {estateDetailSe === "1003" ? Util.getValToCode(("1041"), acqsCause) : ""}
                    </td>
                </tr>
                <tr className={(estateDetailSe !== "1001" ? "dpNone" : "")}>
                    <th className="taC">주택용도</th>
                    <td colSpan={2}>{Util.getValToCode("1008", estatePrpos)}</td>
                </tr>
                <tr>
                    <th className="taC" rowSpan={3}>정보표시사항</th>
                    <th className="taL">용도지역</th>
                    <td>{Util.getValToCode("1025", spfcKnd)}</td>
                </tr>
                <tr>
                    <th className="taL">과밀억제권역</th>
                    <td>{Util.getValToCode("1042", ovpalotAt)}</td>
                </tr>
                <tr>
                    <th className="taL">조정대상지역</th>
                    <td>{Util.getValToCode("1042", mdatTrgetAreaAt)}</td>
                </tr>
                <tr className={(estateDetailSe === "1001" && ["1001", "1002", "1006"].indexOf(estateFom) !== -1 ? "" : "dpNone")}>
                    <th className="taC" rowSpan={3}>공동주택</th>
                    <th className="taL">대지권</th>
                    <td>{plotRight}㎡</td>
                </tr>
                <tr className={(estateDetailSe === "1001" && ["1001", "1002", "1006"].indexOf(estateFom) !== -1 ? "" : "dpNone")}>
                    <th className="taL">전용면적</th>
                    <td>{prvuseAr}㎡</td>
                </tr>
                <tr className={(estateDetailSe === "1001" && ["1001", "1002", "1006"].indexOf(estateFom) !== -1 ? "" : "dpNone")}>
                    <th className="taL">공유면적</th>
                    <td>{cnrsAr}㎡</td>
                </tr>
                <tr className={(estateDetailSe === "1001" && ["1003", "1004", "1005"].indexOf(estateFom) !== -1 ? "" : "dpNone")}>
                    <th className="taC" rowSpan={3}>단독주택 및 겸용주택</th>
                    <th className="taL">대지면적</th>
                    <td>{plotAr}㎡</td>
                </tr>
                <tr className={(estateDetailSe === "1001" && ["1003", "1004", "1005"].indexOf(estateFom) !== -1 ? "" : "dpNone")}>
                    <th className="taL">주택연면적</th>
                    <td>{houseTotar}㎡</td>
                </tr>
                <tr className={(estateDetailSe === "1001" && ["1003", "1004", "1005"].indexOf(estateFom) !== -1 ? "" : "dpNone")}>
                    <th className="taL">상가연면적</th>
                    <td>{sopsrtTotar}㎡</td>
                </tr>
                {(estateDetailSe === "1001" && (estateFom === "1001" || estateFom === "1002" || estateFom === "1006")) || ["1002", "1003"].indexOf(estateDetailSe) !== -1 ?
                    <tr className={(estateDetailSe !== "1001" ? "dpNone" : "")}>
                        <th colSpan={2} className="taC">소유지분율</th>
                        <td className="p0-i">
                            <table className="forest-list-table bgcWhite w100p th-bdb1 th-bdr1 td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls1 td-fc666666 td-bdcDDDDDD th-bdcDDDDDD">
                                <colgroup>
                                    <col className="w200" />
                                    <col />
                                    <col className="w200" />
                                </colgroup>
                                <tbody>
                                    <tr className="h40">
                                        <th>구분</th>
                                        <th>권리자명</th>
                                        <th>지분율(%)</th>
                                    </tr>
                                    {rghpsnList && rghpsnList.map((item: any, idx: number) => {
                                        return (
                                            <tr key={idx} className="h40">
                                                <td>{Util.getValToCode("1060", item.rghpsnSe)}</td>
                                                <td>{item.rghpsnNm}</td>
                                                <td>{item.qotaRate}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                : <></>}
                {estateDetailSe === "1001" && (estateFom === "1003" || estateFom === "1004" || estateFom === "1005") ?
                    <React.Fragment>
                        <tr>
                            <th rowSpan={2} className="taC" title='소유지분율'>소유지분율</th>
                            <th className="taC" title='건물'>건물</th>
                            <td className="p0-i">
                                <table className="forest-list-table h40 bgcWhite w100p th-bdb1 th-bdr1 td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls1 td-fc666666 td-bdcDDDDDD th-bdcDDDDDD">
                                    <colgroup>
                                        <col className="w200" />
                                        <col />
                                        <col className="w200" />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>구분</th>
                                            <th>권리자명</th>
                                            <th>지분율(%)</th>
                                        </tr>
                                        {rghpsnList && rghpsnList.map((item: any, idx: number) => {
                                            return (
                                                <tr key={idx}>
                                                    <td>{Util.getValToCode("1060", item.rghpsnSe)}</td>
                                                    <td>{item.rghpsnNm}</td>
                                                    <td>{item.qotaRate}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <th>토지</th>
                            <td className="p0-i">
                                <table className="forest-list-table h40 bgcWhite w100p th-bdb1 th-bdr1 td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls1 td-fc666666 td-bdcDDDDDD th-bdcDDDDDD">
                                    <colgroup>
                                        <col className="w200" />
                                        <col />
                                        <col className="w200" />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>구분</th>
                                            <th>권리자명</th>
                                            <th>지분율(%)</th>
                                        </tr>
                                        {landRghpsnList && landRghpsnList.map((item: any, idx: number) => {
                                            return (
                                                <tr key={idx}>
                                                    <td>{Util.getValToCode("1060", item.rghpsnSe)}</td>
                                                    <td>{item.rghpsnNm}</td>
                                                    <td>{item.qotaRate}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </React.Fragment>
                : <></>}
                </tbody>
            </table>
            <div className="h40 dpFlx aiC pt40">
                <span className="SpoqaHanSansNeo-Medium fs18 ls09 fc222222">취득정보</span>
            </div>
            {AcqsFormEl()}
            <div className={"h40 aiC pt40 " + (Util.isEmpty(rarFormSeq) ? "dpNone" : "dpFlx")}>
                <span className="SpoqaHanSansNeo-Medium fs18 ls09 fc222222">거주 및 임대정보</span>
            </div>
            {rarFormSeq === 1 ?
                <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                    <colgroup>
                        <col className="w250" />
                        <col />
                    </colgroup>
                    <tbody>
                    <tr>
                        <th className="taC" title="전입일">전입일</th>
                        <td>{trnsfrnDe}</td>
                    </tr>
                    <tr>
                        <th className="taC" title="전출일">전출일</th>
                        <td>{lvhsDe}</td>
                    </tr>
                    </tbody>
                </table>
            : <></>}
            {rarFormSeq === 2 ?
                <React.Fragment>
                    <div className='dpFlx jcL aiC mt30 mb20'>
                        <span className='SpoqaHanSansNeo-Bold fs15 ls075 fc222222'>1. {titleText1}</span>
                    </div>
                    <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                        <colgroup>
                            <col className="w250" />
                            <col />
                        </colgroup>
                        <tbody>
                        <tr>
                            <th className="taC" title="전입일">전입일</th>
                            <td>{trnsfrnDe}</td>
                        </tr>
                        <tr>
                            <th className="taC" title="전출일">전출일</th>
                            <td>{lvhsDe}</td>
                        </tr>
                        </tbody>
                    </table>
                    <div className='dpFlx jcL aiC mt30 mb20'>
                        <span className='SpoqaHanSansNeo-Bold fs15 ls075 fc222222'>2. {titleText2}</span>
                    </div>
                    <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                        <colgroup>
                            <col className="w250" />
                            <col />
                        </colgroup>
                        <tbody>
                        <tr>
                            <th className="taC" title="전입일">전입일</th>
                            <td>{trnsfrnDe2}</td>
                        </tr>
                        <tr>
                            <th className="taC" title="전출일">전출일</th>
                            <td>{lvhsDe2}</td>
                        </tr>
                        </tbody>
                    </table>
                </React.Fragment>
            : <></>}
            {rarFormSeq === 3 ?
                <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                    <colgroup>
                        <col className="w250" />
                        <col />
                    </colgroup>
                    <tbody>
                    <tr>
                        <th className="taC" title='지자체 등록여부'>지자체 등록여부</th>
                        <td>{Util.getValToCode("1044", locgovRegistAt)}</td>
                    </tr>
                    <tr className={locgovRegistAt === "Y" ? "" : "dpNone"}>
                        <th className="taC" title="지자체등록일">지자체등록일</th>
                        <td>{locgovRegistDe}</td>
                    </tr>
                    <tr className={locgovRegistAt === "Y" ? "" : "dpNone"}>
                        <th className="taC" title="지자체신청일">지자체신청일</th>
                        <td>{locgovRqstde}</td>
                    </tr>
                    <tr className={locgovRegistAt === "Y" ? "" : "dpNone"}>
                        <th className="taC" title='임대유형'>임대유형</th>
                        <td>{Util.getValToCode("1013", rentTy)}</td>
                    </tr>
                    <tr className={locgovRegistAt === "Y" && rentTy === "1003" ? "" : "dpNone"}>
                        <th className="taC" title="장기등록 전환신청일">장기등록 전환신청일</th>
                        <td>{rentTyCnvrsDe}</td>
                    </tr>
                    <tr className={locgovRegistAt === "Y" ? "" : "dpNone"}>
                        <th className="taC" title='임대주택 종류'>임대주택 종류</th>
                        <td>{Util.getValToCode("1012", estateRentTy)}</td>
                    </tr>
                    <tr className={locgovRegistAt === "Y" ? "" : "dpNone"}>
                        <th className="taC" title='말소여부'>말소여부</th>
                        <td>{Util.getValToCode("1042", ersrAt)}</td>
                    </tr>
                    <tr className={locgovRegistAt === "Y" && ersrAt === "Y" ? "" : "dpNone"}>
                        <th className="taC" title='말소종류'>말소종류</th>
                        <td>{Util.getValToCode("1028", ersrKnd)}</td>
                    </tr>
                    <tr className={locgovRegistAt === "Y" && ersrAt === "Y" ? "" : "dpNone"}>
                        <th className="taC" title="말소일">말소일</th>
                        <td>{ersrDe}</td>
                    </tr>
                    <tr>
                        <th className="taC" title='세무서 등록여부'>세무서 등록여부</th>
                        <td>{Util.getValToCode("1044", taxofcRegistAt)}</td>
                    </tr>
                    <tr className={taxofcRegistAt === "Y" ? "" : "dpNone"}>
                        <th className="taC" title="세무서등록일">세무서등록일</th>
                        <td>{taxofcRegistDe}</td>
                    </tr>
                    <tr className={taxofcRegistAt === "Y" ? "" : "dpNone"}>
                        <th className="taC" title="세무서신청일">세무서신청일</th>
                        <td>{taxofcRqstde}</td>
                    </tr>
                    <tr className={taxofcRegistAt === "Y" ? "" : "dpNone"}>
                        <th className="taC" title='폐업여부'>폐업여부</th>
                        <td>{Util.getValToCode("1042", bizqitAt)}</td>
                    </tr>
                    <tr className={taxofcRegistAt === "Y" && bizqitAt === "Y" ? "" : "dpNone"}>
                        <th className="taC" title="폐업일자">폐업일자</th>
                        <td>{bizqitDe}</td>
                    </tr>
                    <tr>
                        <th className="taC" title="임대개시일">임대개시일</th>
                        <td>{rentBgnde}</td>
                    </tr>
                    <tr>
                        <th className="taC" title='임대개시일 기준시가'>임대개시일 기준시가(원)</th>
                        <td>{stdrMktc1}</td>
                    </tr>
                    <tr>
                        <th className="taC" title='5% 상한 이행여부'>5% 상한 이행여부</th>
                        <td>{Util.getValToCode("1042", rntchrgUplmtFlflAt)}</td>
                    </tr>
                    <tr>
                        <th className="taC" title="전입일">전입일</th>
                        <td>{trnsfrnDe}</td>
                    </tr>
                    <tr>
                        <th className="taC" title="전출일">전출일</th>
                        <td>{lvhsDe}</td>
                    </tr>
                    </tbody>
                </table>
            : <></>}
            {rarFormSeq === 4 ?
                <div className="dpFlx jcSb">
                    <div className="w49p">
                        <div className='dpFlx jcL aiC mt30 mb20'>
                            <span className='SpoqaHanSansNeo-Bold fs15 ls075 fc222222'>
                                1.
                                {acqsCause === "1001" ? " 신규주택" : ""}
                                {acqsCause === "1003" ? " 상속인" : ""}
                                {acqsCause === "1004" ? " 수증자" : ""}
                            </span>
                        </div>
                        <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                            <colgroup>
                                <col className="w250" />
                                <col />
                            </colgroup>
                            <tbody>
                            <tr>
                                <th className="taC" title='지자체 등록여부'>지자체 등록여부</th>
                                <td>{Util.getValToCode("1044", locgovRegistAt)}</td>
                            </tr>
                            <tr className={locgovRegistAt === "Y" ? "" : "dpNone"}>
                                <th className="taC" title="지자체등록일">지자체등록일</th>
                                <td>{locgovRegistDe}</td>
                            </tr>
                            <tr className={locgovRegistAt === "Y" ? "" : "dpNone"}>
                                <th className="taC" title="지자체신청일">지자체신청일</th>
                                <td>{locgovRqstde}</td>
                            </tr>
                            <tr className={locgovRegistAt === "Y" ? "" : "dpNone"}>
                                <th className="taC" title='임대유형'>임대유형</th>
                                <td>{Util.getValToCode("1013", rentTy)}</td>
                            </tr>
                            <tr className={locgovRegistAt === "Y" && rentTy === "1003" ? "" : "dpNone"}>
                                <th className="taC" title="장기등록 전환신청일">장기등록 전환신청일</th>
                                <td>{rentTyCnvrsDe}</td>
                            </tr>
                            <tr className={locgovRegistAt === "Y" ? "" : "dpNone"}>
                                <th className="taC" title='임대주택 종류'>임대주택 종류</th>
                                <td>{Util.getValToCode("1012", estateRentTy)}</td>
                            </tr>
                            <tr className={locgovRegistAt === "Y" ? "" : "dpNone"}>
                                <th className="taC" title='말소여부'>말소여부</th>
                                <td>{Util.getValToCode("1042", ersrAt)}</td>
                            </tr>
                            <tr className={locgovRegistAt === "Y" && ersrAt === "Y" ? "" : "dpNone"}>
                                <th className="taC" title='말소종류'>말소종류</th>
                                <td>{Util.getValToCode("1028", ersrKnd)}</td>
                            </tr>
                            <tr className={locgovRegistAt === "Y" && ersrAt === "Y" ? "" : "dpNone"}>
                                <th className="taC" title="말소일">말소일</th>
                                <td>{ersrDe}</td>
                            </tr>
                            <tr>
                                <th className="taC" title='세무서 등록여부'>세무서 등록여부</th>
                                <td>{Util.getValToCode("1044", taxofcRegistAt)}</td>
                            </tr>
                            <tr className={taxofcRegistAt === "Y" ? "" : "dpNone"}>
                                <th className="taC" title="세무서등록일">세무서등록일</th>
                                <td>{taxofcRegistDe}</td>
                            </tr>
                            <tr className={taxofcRegistAt === "Y" ? "" : "dpNone"}>
                                <th className="taC" title="세무서신청일">세무서신청일</th>
                                <td>{taxofcRqstde}</td>
                            </tr>
                            <tr className={taxofcRegistAt === "Y" ? "" : "dpNone"}>
                                <th className="taC" title='폐업여부'>폐업여부</th>
                                <td>{Util.getValToCode("1042", bizqitAt)}</td>
                            </tr>
                            <tr className={taxofcRegistAt === "Y" && bizqitAt === "Y" ? "" : "dpNone"}>
                                <th className="taC" title="폐업일자">폐업일자</th>
                                <td>{bizqitDe}</td>
                            </tr>
                            <tr>
                                <th className="taC" title="임대개시일">임대개시일</th>
                                <td>{rentBgnde}</td>
                            </tr>
                            <tr>
                                <th className="taC" title='임대개시일 기준시가'>임대개시일 기준시가(원)</th>
                                <td>{stdrMktc1}</td>
                            </tr>
                            <tr>
                                <th className="taC" title='5% 상한 이행여부'>5% 상한 이행여부</th>
                                <td>{Util.getValToCode("1042", rntchrgUplmtFlflAt)}</td>
                            </tr>
                            <tr>
                                <th className="taC" title="전입일">전입일</th>
                                <td>{trnsfrnDe}</td>
                            </tr>
                            <tr>
                                <th className="taC" title="전출일">전출일</th>
                                <td>{lvhsDe}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="w49p">
                        <div className='dpFlx jcL aiC mt30 mb20'>
                            <span className='SpoqaHanSansNeo-Bold fs15 ls075 fc222222'>
                                2.
                                {acqsCause === "1001" ? "종전주택" : ""}
                                {acqsCause === "1003" ? "피상속인" : ""}
                                {acqsCause === "1004" ? "증여자" : ""}
                            </span>
                        </div>
                        <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                            <colgroup>
                                <col className="w250" />
                                <col />
                            </colgroup>
                            <tbody>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") ? "" : "dpNone"}>
                                <th className="taC" title='지자체 등록여부'>지자체 등록여부</th>
                                <td>{Util.getValToCode("1044", locgovRegistAt2)}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title="지자체등록일">지자체등록일</th>
                                <td>{locgovRegistDe2}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title="지자체신청일">지자체신청일</th>
                                <td>{locgovRqstde2}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title='임대유형'>임대유형</th>
                                <td>{Util.getValToCode("1013", rentTy2)}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" && rentTy2 === "1003" ? "" : "dpNone"}>
                                <th className="taC" title="장기등록 전환신청일">장기등록 전환신청일</th>
                                <td>{rentTyCnvrsDe2}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title='임대주택 종류'>임대주택 종류</th>
                                <td>{Util.getValToCode("1012", estateRentTy2)}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title='말소여부'>말소여부</th>
                                <td>{Util.getValToCode("1042", ersrAt2)}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" && ersrAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title='말소종류'>말소종류</th>
                                <td>{Util.getValToCode("1028", ersrKnd2)}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" && ersrAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title="말소일">말소일</th>
                                <td>{ersrDe2}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") ? "" : "dpNone"}>
                                <th className="taC" title='세무서 등록여부'>세무서 등록여부</th>
                                <td>{Util.getValToCode("1044", taxofcRegistAt2)}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && taxofcRegistAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title="세무서등록일">세무서등록일</th>
                                <td>{taxofcRegistDe2}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && taxofcRegistAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title="세무서신청일">세무서신청일</th>
                                <td>{taxofcRqstde2}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && taxofcRegistAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title='폐업여부'>폐업여부</th>
                                <td>{Util.getValToCode("1042", bizqitAt2)}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && taxofcRegistAt2 === "Y" && bizqitAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title="폐업일자">폐업일자</th>
                                <td>{bizqitDe2}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") ? "" : "dpNone"}>
                                <th className="taC" title="임대개시일">임대개시일</th>
                                <td>{rentBgnde2}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") ? "" : "dpNone"}>
                                <th className="taC" title='임대개시일 기준시가'>임대개시일 기준시가(원)</th>
                                <td>{stdrMktc2}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") ? "" : "dpNone"}>
                                <th className="taC" title='5% 상한 이행여부'>5% 상한 이행여부</th>
                                <td>{Util.getValToCode("1042", rntchrgUplmtFlflAt2)}</td>
                            </tr>
                            <tr>
                                <th className="taC" title="전입일">
                                <img
                                src={require('img/common/icon_info_fill_20.png')}
                                alt="icon_info_fill_20"
                                className='mr3 csP'
                                data-tooltip-id="excptInhrtncHouseToolTip"
                                data-tooltip-content={
                                    "특례상속주택 여부\n\n" +
                                    "피상속인이 2주택 이상을 상속하는 경우 다음 각 호의 선순위에 따른 1주택을 특례상속주택으로 본다.\n\n" +
                                    "① 피상속인이 소유한 기간이 가장 긴 1주택\n" +
                                    "② 피상속인이 거주기간에 가장 긴 1주택\n" +
                                    "③ 피상속인이 상속개시 당시 거주한 1주택\n" +
                                    "④ 기준시가가 가장 높은 1주택\n" +
                                    "⑤ 상속인 선택"
                                }
                            />
                            <ToolTip id="excptInhrtncHouseToolTip" place='top' clickable={true} className="w300 taL lh20" />
                                    전입일
                                </th>
                                <td>{trnsfrnDe2}</td>
                            </tr>
                            <tr>
                                <th className="taC" title="전출일">전출일</th>
                                <td>{lvhsDe2}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            : <></>}
            {rarFormSeq === 5 ?
                <div className="dpFlx jcSb">
                    <div className="w49p">
                        <div className='dpFlx jcL aiC mt30 mb20'>
                            <span className='SpoqaHanSansNeo-Bold fs15 ls075 fc222222'>1. 상속인</span>
                        </div>
                        <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                            <colgroup>
                                <col className="w250" />
                                <col />
                            </colgroup>
                            <tbody>
                            <tr>
                                <th className="taC" title="전입일">전입일</th>
                                <td>{trnsfrnDe}</td>
                            </tr>
                            <tr>
                                <th className="taC" title="전출일">전출일</th>
                                <td>{lvhsDe}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="w49p">
                        <div className='dpFlx jcL aiC mt30 mb20'>
                            <span className='SpoqaHanSansNeo-Bold fs15 ls075 fc222222'>2. 피상속인</span>
                        </div>
                        <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                            <colgroup>
                                <col className="w250" />
                                <col />
                            </colgroup>
                            <tbody>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") ? "" : "dpNone"}>
                                <th className="taC" title='지자체 등록여부'>지자체 등록여부</th>
                                <td>{Util.getValToCode("1044", locgovRegistAt2)}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title="지자체등록일">지자체등록일</th>
                                <td>{locgovRegistDe2}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title="지자체신청일">지자체신청일</th>
                                <td>{locgovRqstde2}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title='임대유형'>임대유형</th>
                                <td>{Util.getValToCode("1013", rentTy2)}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" && rentTy2 === "1003" ? "" : "dpNone"}>
                                <th className="taC" title="장기등록 전환신청일">장기등록 전환신청일</th>
                                <td>{rentTyCnvrsDe2}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title='임대주택 종류'>임대주택 종류</th>
                                <td>{Util.getValToCode("1012", estateRentTy2)}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title='말소여부'>말소여부</th>
                                <td>{Util.getValToCode("1042", ersrAt2)}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" && ersrAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title='말소종류'>말소종류</th>
                                <td>{Util.getValToCode("1028", ersrKnd2)}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" && ersrAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title="말소일">말소일</th>
                                <td>{ersrDe2}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") ? "" : "dpNone"}>
                                <th className="taC" title='세무서 등록여부'>세무서 등록여부</th>
                                <td>{Util.getValToCode("1044", taxofcRegistAt2)}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && taxofcRegistAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title="세무서등록일">세무서등록일</th>
                                <td>{taxofcRegistDe2}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && taxofcRegistAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title="세무서신청일">세무서신청일</th>
                                <td>{taxofcRqstde2}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && taxofcRegistAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title='폐업여부'>폐업여부</th>
                                <td>{Util.getValToCode("1042", bizqitAt2)}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && taxofcRegistAt2 === "Y" && bizqitAt2 === "Y" ? "" : "dpNone"}>
                                <th className="taC" title="폐업일자">폐업일자</th>
                                <td>{bizqitDe2}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") ? "" : "dpNone"}>
                                <th className="taC" title="임대개시일">임대개시일</th>
                                <td>{rentBgnde2}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") ? "" : "dpNone"}>
                                <th className="taC" title='임대개시일 기준시가'>임대개시일 기준시가(원)</th>
                                <td>{stdrMktc2}</td>
                            </tr>
                            <tr className={beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") ? "" : "dpNone"}>
                                <th className="taC" title='5% 상한 이행여부'>5% 상한 이행여부</th>
                                <td>{Util.getValToCode("1042", rntchrgUplmtFlflAt2)}</td>
                            </tr>
                            <tr>
                                <th className="taC" title="전입일">전입일</th>
                                <td>{trnsfrnDe2}</td>
                            </tr>
                            <tr>
                                <th className="taC" title="전출일">전출일</th>
                                <td>{lvhsDe2}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            : <></>}
            {estateDetailSe === "1001" ?
                <>
                    <div className="h40 pt40 dpFlx aiC">
                        <span className="SpoqaHanSansNeo-Medium fs18 ls09 fc222222">1세대 1주택 기타 정보</span>
                    </div>
                    <table className="forest-form-table td-plr30 tr-h44 mt10 td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666">
                        <colgroup>
                            <col className="w250" />
                            <col />
                        </colgroup>
                        <tbody>
                        <tr>
                            <th className="taC">상생임대주택</th>
                            <td>
                                {Util.getValToCode("1042", wwinHouseAt)}
                                <span className="fs15 ls075 fc999999 pl30">(소득세법시행령 제155조의 3)</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="taC">부득이한 사유로 취득한 주택</th>
                            <td>
                                {Util.getValToCode("1042", acqsHouseAt)}
                                <span className="fs15 ls075 fc999999 pl30">(소득세법시행령 제155조 제8항)</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="taC">농어촌 주택</th>
                            <td>
                                {Util.getValToCode("1042", frhlHouseAt)}
                                <span className="fs15 ls075 fc999999 pl30">(소득세법시행령 제155조 제7항, 조세특례제한법 제99조의 4)</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="taC">문화재 주택</th>
                            <td>
                                {Util.getValToCode("1042", crltsHouseAt)}
                                <span className="fs15 ls075 fc999999 pl30">(소득세법시행령 제155조 제6항)</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="taC">장기저당담보주택</th>
                            <td>
                                {Util.getValToCode("1042", mrtgHouseAt)}
                                <span className="fs15 ls075 fc999999 pl30">(소득세법시행령 제155조의 2)</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="taC">장기어린이집주택</th>
                            <td>
                                {Util.getValToCode("1042", childHouseAt)}
                                <span className="fs15 ls075 fc999999 pl30">(소득세법시행령 제155조 제20항)</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="taC">주택 신축 판매업용 주택</th>
                            <td>
                                {Util.getValToCode("1042", salesHouseAt)}
                                <span className="fs15 ls075 fc999999 pl30">(소득세법 제19조)</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="taC">부동산 매매업용 주택</th>
                            <td>
                                {Util.getValToCode("1042", estateHouseAt)}
                                <span className="fs15 ls075 fc999999 pl30">(소득세법 제19조)</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </>
            : <></>}
            <Modal modalOpen={manageDspsPlanDeModalOpen} setModalOpen={setManageDspsPlanDeModalOpen} title="관리처분계획인가일" dim={true} visible={true}>
                <MgshrPlanDe setModalOpen={setManageDspsPlanDeModalOpen} />
            </Modal>
            <Modal modalOpen={lawModalOpen} setModalOpen={setLawModalOpen} title={lawModalTitle} dim={true} visible={true} closeCallBack={() => setLawModalTitle("")}>
                <LawDialog lawordSn={lawordSn} lawordDetailSn={lawordDetailSn} setModalOpen={setLawModalOpen} setLawModalTitle={setLawModalTitle} callMode="view" callBack={() => setLawModalTitle("")} />
            </Modal>
        </React.Fragment>
    )
}
export default AssetsShow;