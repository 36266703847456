import { calculatorPcWebMaxWidth } from "js/common/const";
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";

type Props = {
  stepInfoList: any[];
};

function CalcStep({ stepInfoList }: Props) {
  const [indicatorLength, setIndicatorLength] = useState<string>("");
  const stepRefs = useRef<HTMLLIElement[]>([]);

  useEffect(() => {
    const totalStepCount = stepInfoList.length;
    const currentStepCount = stepInfoList.findIndex(
      (element) => element.selected
    );

    if (totalStepCount <= 0 || currentStepCount < 0) {
      return;
    }

    // 진행 progress bar 업데이트
    const widthPercentage = ((currentStepCount + 1) / totalStepCount) * 100;
    setIndicatorLength(widthPercentage + "%");

    // 현재 단계 표시가 화면 안으로 들어올 수 있도록 scroll
    // 요소가 화면 안에만 있을 수 있도록 최소한의 스크롤만 발생
    stepRefs.current[currentStepCount].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest' 
    })
  }, [stepInfoList]);

  return (
    <StepWrap>
      <StepIndicator $indicatorWidth={indicatorLength}>
        <div className="line"></div>
        <div className="indicator"></div>
      </StepIndicator>
      <StepNameContainter>
        {stepInfoList.map((step: any, index: number) => {
          return (
            <li
              className={`stepName ${step.selected ? "selected" : ""} `}
              ref={(el) => (stepRefs.current[index] = el!)}
              key={step.stepId}
            >
              <span>{step.name}</span>
              {/*selected && <span className="subStep">1/1</span>*/}
            </li>
          );
        })}
      </StepNameContainter>
    </StepWrap>
  );
}

const StepWrap = styled.div`
  display: block;
  width: 100%;
  height: 50px;

  @media (min-width: 1201px) {
    max-width: ${calculatorPcWebMaxWidth};
  }
`;

const StepIndicator = styled.div<{ $indicatorWidth?: string }>`
  position: relative;
  width: 100%;
  height: 3px;

  display: flex;
  justify-content: center;
  align-items: center;

  .line {
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.color.nGray60};
  }

  .indicator {
    position: absolute;
    top: 0;
    left: 0;

    width: ${(props) => props.$indicatorWidth || "0px"};
    height: 100%;
    background-color: ${(props) => props.theme.color.pGreen};
    border-top-right-radius: 1.5px;
    border-bottom-right-radius: 1.5px;
  }
`;

const StepNameContainter = styled.ul`
  display: flex;
  align-items: center;
  gap: 6px;

  width: 100%;
  height: 100%;
  padding: 16px 0 4px 20px;
  margin: 0;
  background-color: ${(props) => props.theme.color.bg};
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  li {
    &.stepName {
      flex-grow: 0;
      flex-shrink: 0;

      font-family: "Spoqa Han Sans Neo";
      font-weight: 500;
      font-size: 1.2em;
      line-height: 1.29;
      letter-spacing: -0.058em;

      padding: 5px 15px;
      border: 1px solid ${(props) => props.theme.color.nGray70};
      border-radius: 30px;
      background-color: white;

      &.selected {
        color: white;
        background-color: ${(props) => props.theme.color.pGreenLight01};

        .subStep {
          color: ${(props) => props.theme.color.nGray70};
          margin-left: 6px;
        }
      }
    }
  }
`;

export default CalcStep;
