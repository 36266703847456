/*******************************************************************************
*** AssetsBase ** 보유 or 취득 자산 등록 > 자산기본정보
*******************************************************************************/
import { IWizard } from "use-wizard/lib/cjs/useWizard/types/IWizard";
import { TStep } from "use-wizard/lib/cjs/useWizard/types/TStep";
import React from "react";
import TextBox from "js/common/ui/input/TextBox";
import Address from "js/common/address/Address";
import { NumericFormat } from 'react-number-format';
import Util from "js/common/Util";
import Modal from 'js/common/ui/modal/Modal';
import { useNavigate } from "react-router-dom";
import AppContext from "js/common/AppContext";
import HouseInfo from "../help/HouseInfo";
import MltdwlHouse from "../help/MltdwHouse";
import MultiHouse from "../help/MultiHouse";
import CmbnatHouse from "../help/CmbnatHouse";
import Axios from "js/common/Axios";
import { useRecoilValue } from "recoil";
import * as Atom from "js/common/Atom";
import SelectBox, { codeProps } from "js/common/ui/input/SelectBox";
import ToolTip from 'js/common/ui/ToolTip';
import LttotRight from "../help/LttotRight";
import OccupancyRight from "../help/OccupancyRight";
import ImageRadioBox from "js/common/ui/input/ImageRadioBox";
import NumberTextBox from "js/common/ui/input/NumberTextBox";
import AssetsButton from "./ui/AssetsButton";
import AssetsTitle from "./ui/AssetsTitle";
import ImageCheckBox from "js/common/ui/input/ImageCheckBox";
import AssetsEditTab from "./ui/AssetsEditTab";

const AssetsBase = (props: {
    step: TStep;
    wizard: IWizard;
    form: any;
    dispatchForm: Function;
}) => {
    const navigate = useNavigate();

    // 납세자 정보
    const hshldSn = useRecoilValue(Atom.hshldSn);
    const hshldNm = useRecoilValue(Atom.hshldNm);

    // 부동산정보
    const [sidoCode, setSidoCode] = React.useState<string>("");
    const [sggCode, setSggCode] = React.useState<string>("");
    const [sggCodeElVsb, setSggCodeElVsb] = React.useState<boolean>(true);
    const [sggCodeInfoList, setSggCodeInfoList] = React.useState<codeProps[]>([]);
    const [emdCode, setEmdCode] = React.useState<string>("");
    const [emdCodeInfoList, setEmdCodeInfoList] = React.useState<codeProps[]>([]);
    const [legaldongCode, setLegaldongCode] = React.useState<string>();
    const [bdMgtSn, setBdMgtSn] = React.useState<string>();
    const [pnu, setPnu] = React.useState<string>();
    const [zip, setZip] = React.useState<string>();
    const [rdnmAdres, setRdnmAdres] = React.useState<string>(""); // 도로명주소
    const [rdnmAdresDetail, setRdnmAdresDetail] = React.useState<string>(""); // 지번
    const [lnmAdres, setLnmAdres] = React.useState<string>(""); // 도로명주소 상세주소
    const [lnmAdresDetail, setLnmAdresDetail] = React.useState<string>();
    const [bdNm, setBdNm] = React.useState<string>();
    const [dongNm, setDongNm] = React.useState<string>();
    const [hoNm, setHoNm] = React.useState<string>();
    const [bdKnd, setBdKnd] = React.useState<string>();
    const [spfcKnd, setSpfcKnd] = React.useState<string>(); // 용도지역
    const [ovpalotAt, setOvpalotAt] = React.useState<string>(""); // 과밀억제권역
    const [mdatTrgetAreaAt, setMdatTrgetAreaAt] = React.useState<string>(""); // 조정대상지역
    const [landTradePermAt, setLandTradePermAt] = React.useState<string>();
    const [countryAreaAt, setCountryAreaAt] = React.useState<string>();
    const [touristCmplxAt, setTouristCmplxAt] = React.useState<string>();
    const [spcltnAreaAt, setSpcltnAreaAt] = React.useState<string>();
    const [adresDrctInputAt, setAdresDrctInputAt] = React.useState<string>("N");

    // 부동산상세정보
    const [estateDetailSe, setEstateDetailSe] = React.useState<string>(""); // 자산상세구분
    const [estateFomDsbVals, setEstateFomDsbVals] = React.useState<string[]>([]); // 주택구분 비활성화 항목들 : 공동주택 여부에 따른 분기
    const [estateFom, setEstateFom] = React.useState<string>(""); // 주택구분
    const [estatePrpos, setEstatePrpos] = React.useState<string>(""); // 주택용도
    const [acqsSrc, setAcqsSrc] = React.useState<string>(""); // 취득구분
    const [acqsCause, setAcqsCause] = React.useState<string>(""); // 취득원인
    const [hseNumAt, setHseNumAt] = React.useState<string>("");
    const [proptaxLevyAt, setProptaxLevyAt] = React.useState<string>("");

    const [acqsSrcVals, setAcqsSrcVals] = React.useState<string[]>([]); // 취득구분 필터링 항목
    const [acqsSrcCodeTy, setAcqsSrcCodeTy] = React.useState<string>(""); // 취득구분 코드타입
    const [acqsCauseCodeTy, setAcqsCauseCodeTy] = React.useState<string>(""); // 취득원인 코드타입
    const [estateFomTrVsb, setEstateFomTrVsb] = React.useState<boolean>(false); // 주택구분 입력박스 표출여부
    const [acqsSrcTrVsb, setAcqsSrcTrVsb] = React.useState<boolean>(false); // 취득구분 입력박스 표출여부
    const [acqsCauseTrVsb, setAcqsCauseTrVsb] = React.useState<boolean>(false); // 취득원인 박스 표출여부
    const [estatePrposTrVsb, setEstatePrposTrVsb] = React.useState<boolean>(false); // 주택용도 입력박스 표출여부
    const [hseNumAtTrVsb, setHseNumAtTrVsb] = React.useState<boolean>(false); // 주택수포함여부 입력박스 표출여부
    const [proptaxLevyAtTrVsb, setProptaxLevyAtTrVsb] = React.useState<boolean>(false); // 재산세부과여부 입력박스 표출여부

    // 부동산면적정보
    // 공동주택 면적
    const [plotRight, setPlotRight] = React.useState<string>(""); // 대지권
    const [prvuseAr, setPrvuseAr] = React.useState<string>(""); // 전용면적
    const [cnrsAr, setCnrsAr] = React.useState<string>(""); // 공유면적
    // 단독주택 면적
    const [plotAr, setPlotAr] = React.useState<string>(""); // 대지면적
    const [houseTotar, setHouseTotar] = React.useState<string>(""); // 주택연면적
    const [sopsrtTotar, setSopsrtTotar] = React.useState<string>(""); // 상가연면적

    const [slhTrVsb, setSlhTrVsb] = React.useState<boolean>(false); // 단독주택 면적 입력박스 표출여부
    const [aphusTrVsb, setAphusTrVsb] = React.useState<boolean>(false); // 공동주택 면적 입력박스 표출여부

    interface rmstnfamProps {
        rmstnfamSn: number
        rmstnfamNm: string
    }
    const [rmstnfamList, setRmstnfamList] = React.useState<rmstnfamProps[]>([]);

    interface rghpsnProp {
        rmstnfamSe: string | null
        rghpsnSn: number
        rghpsnSe: string
        rmstnfamSn: number | null
        rghpsnNm: string
        qotaSe: string
        qotaRate: number
    };
    const [rghpsnList, setRghpsnList] = React.useState<rghpsnProp[]>([]); // 건물 소유지분율
    const [dsbRmstnfamSns, setDsbRmstnfamSns] = React.useState<string[]>([]); // selectbox 옵션리스트 내 비활성화할 건물 권리자
    const [landRghpsnList, setLandRghpsnList] = React.useState<rghpsnProp[]>([]); // 토지 소유지분율
    const [dsbLandRmstnfamSns, setDsbLandRmstnfamSns] = React.useState<string[]>([]); // selectbox 옵션리스트 내 비활성화할 토지 권리자
    const [stdrMktc, setStdrMktc] = React.useState<string>(props.form.stdrMktc); // 기준시가 : 취득정보 입력 시 활용할 정보
    const [addressModalOpen, setAddressModalOpen] = React.useState<boolean>(false); // 주소검색 모달 호출변수
    const [lttotRightModalOpen, setLttotRightModalOpen] = React.useState<boolean>(false); // 분양권 도움말 모달
    const [occupancyRightModalOpen, setOccupancyRightModalOpen] = React.useState<boolean>(false); // 조합원입주권 도움말 모달
    const [houseInfoModalOpen, setHouseInfoModalOpen] = React.useState<boolean>(false); // 도움말 (주택의정의)
    const [mltdwlHouseModalOpen, setMltdwlHouseModalOpen] = React.useState<boolean>(false); // 도움말 (다가구주택)
    const [multiHouseModalOpen, setMultiHouseModalOpen] = React.useState<boolean>(false); // 도움말 (다중주택)
    const [cmbnatHouseModalOpen, setCmbnatHouseModalOpen] = React.useState<boolean>(false); // 도움말 (겸용주택)

    const [useConfmDe, setUseConfmDe] = React.useState<string>(""); // 사용승인일(연계정보)

    const goNextStep = (callMode: string) => {
        const param = {
            assetsSn: props.form.assetsSn,
            rmstnfamSn: props.form.rmstnfamSn,
            assetsInfo: {
                assetsSe: props.form.assetsSe
            },
            estateInfo: {
                estateSttus: props.form.estateSttus,
                estateSe: props.form.estateSe,
                legaldongCode: legaldongCode,
                bdMgtSn: bdMgtSn,
                pnu: pnu,
                zip: zip,
                rdnmAdres: rdnmAdres,
                rdnmAdresDetail: rdnmAdresDetail,
                lnmAdres: lnmAdres,
                lnmAdresDetail: lnmAdresDetail,
                bdNm: bdNm,
                dongNm: dongNm,
                hoNm: hoNm,
                bdKnd: bdKnd,
                spfcKnd: spfcKnd,
                ovpalotAt: ovpalotAt,
                mdatTrgetAreaAt: mdatTrgetAreaAt,
                landTradePermAt: landTradePermAt,
                countryAreaAt: countryAreaAt,
                touristCmplxAt: touristCmplxAt,
                spcltnAreaAt: spcltnAreaAt,
                adresDrctInputAt: Util.isEmpty(adresDrctInputAt) ? "N" : adresDrctInputAt
            },
            estateDetail: {
                estateDetailSe: estateDetailSe,
                estateFom: "",
                acqsSrc: "",
                estatePrpos: "",
                acqsCause: "",
                hseNumAt: "",
                proptaxLevyAt: ""
            },
            estateArInfo: {
                plotRight: Number(plotRight),
                prvuseAr: Number(prvuseAr),
                cnrsAr: Number(cnrsAr),
                plotAr: Number(plotAr),
                houseTotar: Number(houseTotar),
                sopsrtTotar: Number(sopsrtTotar)
            },
            rghpsnList: [] as rghpsnProp[],
            landRghpsnList: [] as rghpsnProp[],
        };
        if (adresDrctInputAt === "Y") {
            if (Util.isEmpty(sidoCode) || Util.isEmpty(sggCode) || Util.isEmpty(emdCode)) {
                AppContext.openAlert({
                    title: "주소정보 확인",
                    msg: "주소를 입력하여 주시기 바랍니다.",
                    icon: "check"
                });
                return;
            }
        } else {
            if (Util.isEmpty(pnu)) {
                AppContext.openAlert({
                    title: "주소정보 확인",
                    msg: "주소를 입력하여 주시기 바랍니다.",
                    icon: "check"
                });
                return;
            }
        }
        const chkValidRghpsnInfo = (rghpsnList: rghpsnProp[]) => {
            let sumQotaRate = 0;
            for (const rghpsnInfo of rghpsnList) {
                if (Util.isEmpty(rghpsnInfo.rghpsnSe)) {
                    AppContext.openAlert({
                        title: "구분 선택",
                        msg: rghpsnInfo.rghpsnNm + " 권리자의 구분을 선택하시기 바랍니다.",
                        icon: "check"
                    });
                    return false;
                }
                if (Util.isEmpty(rghpsnInfo.rghpsnNm)) {
                    AppContext.openAlert({
                        title: "이름 입력",
                        msg: rghpsnInfo.rghpsnNm + " 권리자의 이름을 입력하시기 바랍니다.",
                        icon: "check"
                    });
                    return false;
                }
                const qotaRate = Number(rghpsnInfo.qotaRate);
                /*
                    1. 본인 자산
                        1) 본인 세대원
                            - 지분율 체크
                        2) 본인 외 세대원
                            - 지분율 체크
                    2. 세대원 자산
                        1) 본인 세대원
                            - 지분율 미체크
                        2) 본인 외 세대원
                            - 지분율 체크
                */
                if (props.form.assetsRmstnfamSe === "1000" || (props.form.assetsRmstnfamSe !== "1000" && rghpsnInfo.rmstnfamSe !== "1000")) {
                    if (Util.isEmpty(qotaRate) || qotaRate === 0) {
                        AppContext.openAlert({
                            title: "지분율 정보 누락",
                            msg: rghpsnInfo.rghpsnNm + " 권리자의 지분율 정보를 입력하시기 바랍니다.",
                            icon: "check"
                        });
                        return false;
                    }
                }
                sumQotaRate = sumQotaRate + qotaRate; // 미체크
            };
            if (sumQotaRate !== 100) {
                AppContext.openAlert({
                    title: "지분율 정보 합산",
                    msg: "권리자 지분율의 합산을 100%로 맞춰서 입력하시기 바랍니다.",
                    icon: "check"
                });
                return false;
            }
            return true;
        }
        if (!chkValidRghpsnInfo(rghpsnList)) {
            return;
        }
        param.rghpsnList = rghpsnList;
        if (estateDetailSe === "1001") {
            param.estateDetail.estateFom = estateFom;
            param.estateDetail.acqsSrc = acqsSrc;
            param.estateDetail.estatePrpos = estatePrpos;
            param.estateDetail.hseNumAt = "Y";
            if (acqsSrc !== "1004") { // 자가신축주택취득은 취득원인값 null 처리
                param.estateDetail.acqsCause = acqsCause;
            }
            if (estateFom === "1006") { // 주거용오피스텔일 때에만
                if (Util.isEmpty(proptaxLevyAt)) {
                    AppContext.openAlert({
                        title: "재산세부과구분 확인",
                        msg: "주거용오피스텔일 경우 재산세부과구분 정보를 선택하여 주시기 바랍니다.",
                        icon: "check"
                    });
                    return;
                }
                param.estateDetail.proptaxLevyAt = proptaxLevyAt; // 재산세부과여부 선택값 바인딩
            }
            if (estateFom === "1001" || estateFom === "1002" || estateFom === "1006") {
                if (Util.isEmpty(prvuseAr) || Number(prvuseAr) === 0) {
                    AppContext.openAlert({
                        title: "전용면적 확인",
                        msg: "전용면적 정보를 입력하여 주시기 바랍니다.",
                        icon: "check"
                    });
                    return;
                }
            } else if (estateFom === "1003" || estateFom === "1004" || estateFom === "1005") {
                // 대지면적 필수사항 체크
                if (Util.isEmpty(plotAr) || Number(plotAr) === 0) {
                    AppContext.openAlert({
                        title: "대지면적 확인",
                        msg: "대지면적 정보를 입력하여 주시기 바랍니다.",
                        icon: "check"
                    });
                    return;
                }

                // 주택연면적 필수사항 체크
                if (Util.isEmpty(houseTotar) || Number(houseTotar) === 0) {
                    AppContext.openAlert({
                        title: "주택연면적 확인",
                        msg: "주택연면적 정보를 입력하여 주시기 바랍니다.",
                        icon: "check"
                    });
                    return;
                }
            }
            if (["1003", "1004", "1005"].indexOf(estateFom) !== -1) {
                if (!chkValidRghpsnInfo(landRghpsnList)) {
                    return;
                }
                param.landRghpsnList = landRghpsnList;
            }
        } else if (["1002", "1003"].indexOf(estateDetailSe) !== -1) {
            if (Util.isEmpty(hseNumAt)) {
                AppContext.openAlert({
                    title: "주택수포함여부 확인",
                    msg: "자산구분이 분양권/조합원입주권인 경우 주택수포함여부 정보를 선택하여 주시기 바랍니다.",
                    icon: "check"
                });
                return;
            }
            if (adresDrctInputAt === "Y") {
                if (Util.isEmpty(sidoCode)) {
                    AppContext.openAlert({
                        title: "시/도 선택",
                        msg: "시/도 구분을 선택하세요.",
                        icon: "check"
                    });
                    return;
                }
                if (Util.isEmpty(sggCode)) {
                    AppContext.openAlert({
                        title: "시군구 선택",
                        msg: "시군구 구분을 선택하세요.",
                        icon: "check"
                    });
                    return;
                }
                if (Util.isEmpty(emdCode)) {
                    AppContext.openAlert({
                        title: "읍면동 선택",
                        msg: "읍면동 구분을 선택하세요.",
                        icon: "check"
                    });
                    return;
                }
                param.estateInfo.legaldongCode = emdCode;
            }
            param.estateDetail.acqsCause = acqsCause;
            param.estateDetail.estateFom = "";
            param.estateDetail.acqsSrc = "";
            param.estateDetail.estatePrpos = "";
            param.estateDetail.hseNumAt = hseNumAt;
        } else {
            AppContext.openAlert({
                title: "자산구분 누락",
                msg: "자산구분 정보 오류입니다.",
                icon: "error"
            });
        }
        const retFunc = (assetsSn: number) => {
            props.dispatchForm({
                type: "UPDATE_KEY_VALUES",
                value: {
                    assetsSn: assetsSn,
                    acqsCause: acqsCause,
                    stdrMktc: stdrMktc,
                    useConfmDe: useConfmDe,
                    stepCode: "1001"
                }
            });
            if (Util.isEmpty(props.form.assetsSn)) {
                navigate("/system/assets/mod/" + props.form.callPage + "/" + assetsSn);
                props.wizard.nextStep();
            } else {
                props.wizard.nextStep();
            }
        }
        const saveAssetsBaseInfo = (assetsSn: number) => {
            Axios.dataAccess({
                url: "system/assets/saveAssetsBaseInfo.do",
                methodType: "post",
                paramType: "json",
                paramData: param,
                onSuccessFn: () => {
                    if (callMode === "next") {
                        retFunc(assetsSn);
                    } else {
                        AppContext.hideSpinner();
                    }
                }
            });
        }
        if (Util.isEmpty(props.form.assetsSn)) { // 최초작성
            Axios.dataAccess({
                url: "system/assets/insAssetsTotalInfo.do",
                methodType: "post",
                paramType: "json",
                paramData: param,
                onSuccessFn: (res: any) => {
                    AppContext.showSpinner();
                    param.assetsSn = res.item;
                    saveAssetsBaseInfo(res.item); // 저장함수 바로 호출
                }
            });
        } else { // 수정모드
            AppContext.showSpinner();
            Axios.dataAccess({
                url: "system/assets/chkAssetsBaseInfo.do",
                methodType: "post",
                paramType: "json",
                paramData: param,
                onSuccessFn: (res: any) => {
                    if (res.item === 0) { // 항목 하나라도 수정한 경우
                        AppContext.hideSpinner();
                        AppContext.openAlert({
                            title: "기본정보 저장",
                            msg: "수정된 내역이 존재합니다. 저장하시겠습니까?",
                            icon: "check",
                            confirmText: "저장",
                            handleConfirm: () => {
                                AppContext.showSpinner();
                                saveAssetsBaseInfo(props.form.assetsSn);
                            }
                        });
                    } else if (res.item === 1) { // 수정내역 없으면 바로 다음 스텝으로
                        if (callMode === "next") {
                            retFunc(props.form.assetsSn);
                        } else {
                            AppContext.hideSpinner();
                        }
                    }
                }
            });
        }
    }

    // 부동산정보 세팅
    const setEstateInfo = React.useCallback((estateInfo: any, estateFom: string, landRghpsnList: rghpsnProp[], addressInfo: any) => {
        if (Util.isNotEmpty(addressInfo)) {
            setSidoCode(addressInfo.sidoCode);
            setSggCode(addressInfo.sggCode);
            setEmdCode(estateInfo.legaldongCode);
            setSggCodeInfoList(addressInfo.sggCodeInfoList);
            if (addressInfo.sggCodeInfoList.length === 1) {
                setSggCodeElVsb(false);
            } else {
                setSggCodeElVsb(true);
            }
            setEmdCodeInfoList(addressInfo.emdCodeInfoList);
        }
        setAdresDrctInputAt(estateInfo.adresDrctInputAt); // 주소 직접입력 여부
        setLegaldongCode(estateInfo.legaldongCode); // 법정동코드
        setBdMgtSn(estateInfo.bdMgtSn); // 건물관리번호
        setPnu(estateInfo.pnu); // 필지고유번호
        setZip(estateInfo.zip); // 우편번호
        setRdnmAdres(estateInfo.rdnmAdres); // 도로명주소
        setRdnmAdresDetail(estateInfo.rdnmAdresDetail); // 도로명주소_상세
        setLnmAdres(estateInfo.lnmAdres); // 지번주소
        setLnmAdresDetail(estateInfo.lnmAdresDetail); // 지번주소_상세
        setBdNm(estateInfo.bdNm); // 건물명
        setDongNm(estateInfo.dongNm); // 동
        setHoNm(estateInfo.hoNm); // 호
        setBdKnd(estateInfo.bdKnd); // 공동주택여부
        if (estateInfo.bdKnd === "1") {
            setEstateFomDsbVals(["1003", "1004", "1005"]);
            if (Util.isEmpty(estateFom)) {
                setEstateFom("1001");
            }
        } else if (estateInfo.bdKnd === "0") {
            setEstateFomDsbVals(["1001", "1002", "1006"]);
            if (Util.isEmpty(estateFom)) {
                setEstateFom("1005");
            }
            if (landRghpsnList.length === 0) {
                Axios.dataAccess({
                    url: "system/rmstnfam/selHshldRmstnfamList.do",
                    methodType: "post",
                    paramType: "object",
                    paramData: {
                        hshldSn: hshldSn
                    },
                    onSuccessFn: (res1: any) => {
                        let actUrl = "";
                        let actParam = {};
                        if (Util.isEmpty(props.form.assetsSn)) {
                            actUrl = "system/rmstnfam/selRmstnfamInfo.do";
                            actParam = {
                                rmstnfamSn: props.form.rmstnfamSn
                            }
                        } else {
                            actUrl = "system/assets/selRmstnfamInfoByAssets.do";
                            actParam = {
                                assetsSn: props.form.assetsSn
                            }
                        }
                        Axios.dataAccess({
                            url: actUrl,
                            methodType: "post",
                            paramType: "object",
                            paramData: actParam,
                            onSuccessFn: (res2: any) => {
                                let hshldRmstnfamSn = null;
                                // 납세자의 세대원 key 추출
                                res1.list.forEach((item: any) => {
                                    if (item.rmstnfamSe === "1000") {
                                        hshldRmstnfamSn = item.rmstnfamSn;
                                        return;
                                    }
                                });
                                const landRghpsnList: rghpsnProp[] = [{
                                    rmstnfamSe: "1000",
                                    rghpsnSn: 1,
                                    rghpsnSe: "1000",
                                    rmstnfamSn: hshldRmstnfamSn,
                                    rghpsnNm: hshldNm || "",
                                    qotaSe: "1002",
                                    qotaRate: 0
                                }];
                                if (hshldRmstnfamSn !== res2.item.rmstnfamSn) {
                                    landRghpsnList.push({
                                        rmstnfamSe: res2.item.rmstnfamSe,
                                        rghpsnSn: 2,
                                        rghpsnSe: "1001",
                                        rmstnfamSn: res2.item.rmstnfamSn,
                                        rghpsnNm: res2.item.rmstnfamNm,
                                        qotaSe: "1002",
                                        qotaRate: 0
                                    });
                                }
                                setLandRghpsnList(landRghpsnList);
                            }
                        });
                    }
                });
            }
        }
        setSpfcKnd(estateInfo.spfcKnd); // 용도지역종류[025]
        setOvpalotAt(estateInfo.ovpalotAt); // 과밀억제지역여부
        setMdatTrgetAreaAt(estateInfo.mdatTrgetAreaAt); // 조정대상지역여부
        setLandTradePermAt(estateInfo.landTradePermAt); // 토지거래허가구역여부
        setCountryAreaAt(estateInfo.countryAreaAt); // 읍면지역여부
        setTouristCmplxAt(estateInfo.touristCmplxAt); // 관광단지여부
        setSpcltnAreaAt(estateInfo.spcltnAreaAt); // 투기지역여부
    }, [props.form.assetsSn, props.form.rmstnfamSn, hshldSn, hshldNm]);

    // 부동산면적정보
    const setEstateArInfo = (estateArInfo: any) => {
        setPlotRight(estateArInfo.plotRight) // 대지권
        setPrvuseAr(estateArInfo.prvuseAr) // 전용면적
        setCnrsAr(estateArInfo.cnrsAr) // 공유면적
        setPlotAr(estateArInfo.plotAr) // 대지연면적
        setHouseTotar(estateArInfo.houseTotar) // 주택연면적
        setSopsrtTotar(estateArInfo.sopsrtTotar) // 상가연면적
    }

    // 주소 검색 결과
    const onFinishAddrSearch = (item: any) => {
        setEstateInfo(item, "", landRghpsnList, null) // 부동산정보
        setEstateArInfo(item); // 부동산면적정보
        if (item.bdKnd === "1") {
            setStdrMktc(item.pblntfPc);
        } else if (item.bdKnd === "0") {
            setStdrMktc(item.housePc);
        }
        setUseConfmDe(item.useConfmDe);
    }

    // 주택구분 변경으로 인한 팝업 분기
    const onChgModalOpen = (val: string) => {
        if (val === "1003") {
            setMltdwlHouseModalOpen(true);
        } else if (val === "1004") {
            setMultiHouseModalOpen(true);
        }
    }

    // 주택구분 변경으로 인한 취득구분 디폴트값 세팅
    const onChgEstateFomAcqsSrcDfVal = (val: string) => {
        if (val === "1001") {
            setAcqsSrcCodeTy("1055");
        } else {
            setAcqsSrcCodeTy("1009");
        }
        if (["1001", "1002", "1003", "1004", "1005"].indexOf(val) !== -1) { // 아파트, 다세대, 다가구, 다중, 단독
            setAcqsSrc("1001");
            onChgAcqsSrcAcqsCauseDfVal("1001");
        } else if (val === "1006") { // 오피스텔
            setAcqsSrc("1005");
            onChgAcqsSrcAcqsCauseDfVal("1005");
        }
    }

    const onChgAcqsSrcAcqsCauseDfVal = (val: string) => {
        if (val === "1001") { // 기존주택취득
            setAcqsCause("1002");
        } else if (val === "1002") { // 분양권에 의한 취득
            setAcqsCause("1011");
        } else if (val === "1003") { // 조합원입주권에 의한 취득
            setAcqsCause("1001");
        } else if (val === "1004") { // 자가신축주택취득
            setAcqsCause("");
        } else if (val === "1005") { // 오피스텔취득
            setAcqsCause("1002");
        } else if (val === "1006") { // 일반분양오피스텔취득
            setAcqsCause("1011");
        }
    }

    const setDsbRmstnfamSn = React.useCallback((rghpsnList: rghpsnProp[], qotaSe: string) => {
        // 권리자 : 이미 추가한 세대원 select opt 박스 disabled 세팅
        const dsbRmstnfamSns: string[] = [];
        const dsbLandRmstnfamSns: string[] = [];
        for (const rghpsnInfo of rghpsnList) {
            if (rghpsnInfo.rmstnfamSn !== null && rghpsnInfo.rmstnfamSn !== undefined) {
                if (qotaSe === "1001") {
                    dsbRmstnfamSns.push(rghpsnInfo.rmstnfamSn.toString());
                } else if (qotaSe === "1002") {
                    dsbLandRmstnfamSns.push(rghpsnInfo.rmstnfamSn.toString());
                }
            }
        }
        if (qotaSe === "1001") {
            setDsbRmstnfamSns(dsbRmstnfamSns);
        } else if (qotaSe === "1002") {
            setDsbLandRmstnfamSns(dsbLandRmstnfamSns);
        }
    }, []);

    const resetEstateInfo = () => {
        setSidoCode("");
        setSggCode("");
        setSggCodeElVsb(true);
        setSggCodeInfoList([]);
        setEmdCode("");
        setEmdCodeInfoList([]);
        setLegaldongCode("");
        setBdMgtSn("");
        setPnu("");
        setZip("");
        setRdnmAdres("");
        setRdnmAdresDetail("");
        setLnmAdres("");
        setLnmAdresDetail("");
        setBdNm("");
        setDongNm("");
        setHoNm("");
        setBdKnd("");
        setSpfcKnd("");
        setOvpalotAt("");
        setMdatTrgetAreaAt("");
        setLandTradePermAt("");
        setCountryAreaAt("");
        setTouristCmplxAt("");
        setSpcltnAreaAt("");
        setAdresDrctInputAt("");
    }

    React.useEffect(() => {
        if (estateDetailSe === "1001") {
            if (Util.isEmpty(estateFom)) {
                setEstateFom("1001");
            }
            if (Util.isEmpty(acqsSrc)) {
                setAcqsSrc("1001");
            }
            if (Util.isEmpty(estatePrpos)) {
                setEstatePrpos("1001");
            }
            setEstateFomTrVsb(true);
            setAcqsSrcTrVsb(true);
            setEstatePrposTrVsb(true);

            // 취득원인 선택항목 핸들링
            if (estateFom === "1001") { // 아파트
                setAcqsSrcVals(["1001", "1002", "1003"]);
            } else if (["1002", "1003", "1004", "1005"].indexOf(estateFom) !== -1) { // 다세대, 다가구, 다중, 단독
                setAcqsSrcVals(["1001", "1004"]);
            } else if (estateFom === "1006") { // 오피스텔
                setAcqsSrcVals(["1005", "1006"]);
            }
            if (estateFom === "1006") { // 주거용오피스텔
                setProptaxLevyAtTrVsb(true);
            } else {
                setProptaxLevyAtTrVsb(false);
            }

            // 면적 입력박스 핸들링
            if (["1001", "1002", "1006"].indexOf(estateFom) !== -1) { // 공동주택
                setAphusTrVsb(true);
                setSlhTrVsb(false);
            } else if (["1003", "1004", "1005"].indexOf(estateFom) !== -1) { // 단독주택
                setAphusTrVsb(false);
                setSlhTrVsb(true);
            }

            if (estateFom === "1001") { // 아파트일때
                setAcqsSrcCodeTy("1055"); // 아파트취득구분 코드 바인딩
            } else {
                setAcqsSrcCodeTy("1009");
            }

            if (acqsSrc === "1001") { // 기존주택취득
                setAcqsCauseTrVsb(true);
                setAcqsCauseCodeTy("1039");
            } else if (acqsSrc === "1002") { // 분양권에 의한 취득
                setAcqsCauseTrVsb(true);
                setAcqsCauseCodeTy("1040");
            } else if (acqsSrc === "1003") { // 조합원입주권에 의한 취득
                setAcqsCauseTrVsb(true);
                setAcqsCauseCodeTy("1041");
            } else if (acqsSrc === "1004") { // 자가신축주택취득
                setAcqsCauseTrVsb(false);
            } else if (acqsSrc === "1005") { // 오피스텔취득
                setAcqsCauseTrVsb(true);
                setAcqsCauseCodeTy("1039");
            } else if (acqsSrc === "1006") { // 일반분양오피스텔취득
                setAcqsCauseTrVsb(true);
                setAcqsCauseCodeTy("1040");
            }
            setHseNumAtTrVsb(false);
            if (adresDrctInputAt === "Y") {
                resetEstateInfo();
            }
        } else if (["1002", "1003"].indexOf(estateDetailSe) !== -1) {
            setEstateFomTrVsb(false);
            setAcqsSrcTrVsb(false);
            setEstatePrposTrVsb(false);
            setAcqsCauseTrVsb(true);
            if (estateDetailSe === "1002") {
                setAcqsCauseCodeTy("1043");
                if (Util.isEmpty(acqsCause)) { // 취득원인 값 없으면
                    setAcqsCause("1001"); // 디폴트 세팅
                }
            } else if (estateDetailSe === "1003") {
                setAcqsCauseCodeTy("1041");
                if (Util.isEmpty(acqsCause)) { // 취득원인 값 없으면
                    setAcqsCause("1001"); // 디폴트 세팅
                }
            }
            setAphusTrVsb(false);
            setSlhTrVsb(false);
            setHseNumAtTrVsb(true);
            setHseNumAt("Y");
        }
    }, [estateDetailSe, estateFom, acqsSrc, estatePrpos, acqsCause, adresDrctInputAt]);

    React.useEffect(() => {
        props.form.setCurrStepCode(1001);
    }, [props.form]);

    React.useEffect(() => {
        const dflSetup = (estateInfo: any, estateDetail: any, estateArInfo: any, rghpsnList: rghpsnProp[], landRghpsnList: rghpsnProp[], addressInfo: any) => {
            setEstateInfo(estateInfo, estateDetail.estateFom, landRghpsnList, addressInfo); // 부동산정보
            setHseNumAt(estateDetail.hseNumAt); // 주택수포함여부
            setEstateDetailSe(estateDetail.estateDetailSe); // 자산상세구분
            setEstateFom(estateDetail.estateFom); // 주택구분
            setAcqsSrc(estateDetail.acqsSrc); // 취득구분
            setAcqsCause(estateDetail.acqsCause); // 취득원인
            setEstatePrpos(estateDetail.estatePrpos); // 주택용도
            setProptaxLevyAt(estateDetail.proptaxLevyAt); // 재산세부과여부
            setEstateArInfo(estateArInfo); // 부동산면적정보
            setRghpsnList(rghpsnList); // 건물 소유지분율
            setLandRghpsnList(landRghpsnList); // 토지 소유지분율
            setDsbRmstnfamSn(rghpsnList, "1001");
            setDsbRmstnfamSn(landRghpsnList, "1002");
            setRmstnfamList(props.form.rmstnfamList);
            AppContext.hideSpinner();
        }
        if (Util.isEmpty(props.form.assetsSn)) { // 신규작성
            // 1. 기본정보
            const estateInfo = {
                spfcKnd: "1001",
                ovpalotAt: "N",
                mdatTrgetAreaAt: "N"
            };
            const estateDetail = {
                estateDetailSe: "1001",
                estateFom: "1001",
                estatePrpos: "1001",
                acqsSrc: "1001",
                acqsCause: "1002",
                hseNumAt: "N",
                proptaxLevyAt: "N"
            };
            const estateArInfo = {
                plotRight: "0",
                prvuseAr: "0",
                cnrsAr: "0",
                plotAr: "0",
                houseTotar: "0",
                sopsrtTotar: "0"
            };
            dflSetup(estateInfo, estateDetail, estateArInfo, props.form.rghpsnList, props.form.landRghpsnList, null);
        } else {
            AppContext.showSpinner();
            Axios.dataAccess({
                url: "system/assets/selAssetsBaseInfo.do",
                methodType: "post",
                paramType: "object",
                paramData: {
                    assetsSn: props.form.assetsSn
                },
                onSuccessFn: (res: any) => {
                    dflSetup(res.item.estateInfo, res.item.estateDetail, res.item.estateArInfo, res.item.rghpsnList, res.item.landRghpsnList, res.item.addressInfo);
                }
            });
        }
    }, [props.form.assetsSn, props.form.rghpsnList, props.form.rmstnfamList, props.form.landRghpsnList, setEstateInfo, setDsbRmstnfamSn]);

    const RghpsnListBox = (targetList: rghpsnProp[], setTargetList: React.Dispatch<React.SetStateAction<rghpsnProp[]>>, qotaSe: string) => {
        // 권리자 편집
        const bindChgRghpsnData = (rghpsnSn: number, fldNm: string, data: string) => {
            let tempArr = [] as any;
            if (targetList === undefined) {
                return;
            }
            for (const rghpsnInfo of targetList) {
                if (rghpsnInfo["rghpsnSn"] === rghpsnSn) {
                    let rghpsnNm = fldNm === "rghpsnNm" ? data : rghpsnInfo["rghpsnNm"];
                    if (fldNm === "rmstnfamSn") {
                        for (const rmstnfamInfo of rmstnfamList) {
                            if (rmstnfamInfo.rmstnfamSn === Number(data)) {
                                rghpsnNm = rmstnfamInfo.rmstnfamNm;
                                break;
                            }
                        }
                    }
                    tempArr.push({
                        rmstnfamSe: rghpsnInfo["rmstnfamSe"],
                        rghpsnSn: rghpsnInfo["rghpsnSn"],
                        rghpsnSe: fldNm === "rghpsnSe" ? data : rghpsnInfo["rghpsnSe"],
                        rmstnfamSn: fldNm === "rmstnfamSn" ? Number(data) : rghpsnInfo["rmstnfamSn"],
                        rghpsnNm: rghpsnNm,
                        qotaSe: qotaSe,
                        qotaRate: fldNm === "qotaRate" ? Number(data) : rghpsnInfo["qotaRate"]
                    });
                } else {
                    tempArr.push(rghpsnInfo);
                }
            }
            setTargetList(tempArr);
            setDsbRmstnfamSn(tempArr, qotaSe); // 권리자 : 이미 추가한 세대원 select opt 박스 disabled 세팅
        };
        // 권리자 삭제
        const delRghpsnRow = (item: rghpsnProp) => {
            let tempArr = [] as any;
            if (targetList === undefined) {
                return;
            }
            for (const rghpsnInfo of targetList) {
                if (rghpsnInfo["rghpsnSn"] !== item.rghpsnSn) {
                    tempArr.push(rghpsnInfo);
                }
            }
            setTargetList(tempArr);
        }
        return (
            <React.Fragment>
                <td colSpan={2} className="p0-i">
                    <div className="dpFlx aiC jcSb">
                        <table className={
                            'forest-list-table w580 bgcWhite ' +
                            'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-ls1 th-bdcDDDDDD th-bdr1 th-bdb1 ' +
                            'td-bdcDDDDDD td-fm-SpoqaHanSansNeo-Regular td-ls1 td-fc666666 td-fs15'
                        }>
                            <colgroup>
                                <col className="w190" />
                                <col className="w190" />
                                <col className="w100" />
                                <col className="w100" />
                            </colgroup>
                            <tbody>
                                <tr className="h40">
                                    <th>구분</th>
                                    <th>권리자</th>
                                    <th>지분율(%)</th>
                                    <th className="bdr1 bdcE6E6E6">삭제</th>
                                </tr>
                                {targetList && targetList.map((item: any, idx: number) => {
                                    return (
                                        <tr key={idx} className="h40">
                                            <td>
                                                <SelectBox
                                                    value={item.rghpsnSe}
                                                    codeArray={Util.getCodeArray("1060")}
                                                    fontSize={15}
                                                    paddingRight={10}
                                                    onChangeFunc={(data: string) => bindChgRghpsnData(item.rghpsnSn, "rghpsnSe", data)}
                                                    disabled={item.rmstnfamSn === props.form.rmstnfamSn || item.rghpsnSe === "1000"}
                                                    dsbOptVals={["1000"]}
                                                    textAlign="left"
                                                />
                                            </td>
                                            <td>
                                                {item.rmstnfamSn === props.form.rmstnfamSn || item.rghpsnSe === "1000" ?
                                                    <input
                                                        type="text"
                                                        value={item.rghpsnNm}
                                                        className="w100p bd0 SpoqaHanSansNeo-Regular fs15 taL ls008 fc222222 bgcWhite bdBox pl17 pr17"
                                                        disabled
                                                    />
                                                : <></>}
                                                {item.rmstnfamSn !== props.form.rmstnfamSn && item.rghpsnSe === "1001" ?
                                                    <SelectBox
                                                        value={item.rmstnfamSn.toString()}
                                                        fontSize={15}
                                                        paddingRight={10}
                                                        codeArray={rmstnfamList.map((item: any) => ({ code: item.rmstnfamSn.toString(), codeNm: item.rmstnfamNm }))}
                                                        disabled={item.rmstnfamSe === "1000"}
                                                        onChangeFunc={(selItem: any) => {
                                                            bindChgRghpsnData(item.rghpsnSn, "rmstnfamSn", selItem);
                                                        }}
                                                        placeholder="세대원 선택"
                                                        textAlign="left"
                                                        dsbOptVals={item.qotaSe === "1001" ? dsbRmstnfamSns : dsbLandRmstnfamSns}
                                                    />
                                                : <></>}
                                                {item.rmstnfamSn !== props.form.rmstnfamSn && item.rghpsnSe === "1002" ?
                                                    <input
                                                        type="text"
                                                        value={item.rghpsnNm}
                                                        className="w100p bd0 SpoqaHanSansNeo-Regular fs15 taL ls008 fc222222 bgcWhite bdBox olN pl17 pr17"
                                                        placeholder="권리자명을 입력하세요."
                                                        onChange={(e) => bindChgRghpsnData(item.rghpsnSn, "rghpsnNm", e.target.value)}
                                                        maxLength={20}
                                                    />
                                                : <></>}
                                            </td>
                                            <td>
                                                <NumericFormat
                                                    maxLength={3}
                                                    value={item.qotaRate}
                                                    className="w100p h38 bd0 SpoqaHanSansNeo-Regular fs15 taC ls008 fc222222 bgcWhite bdBox olN"
                                                    onChange={(e) => bindChgRghpsnData(item.rghpsnSn, "qotaRate", e.target.value)}
                                                />
                                            </td>
                                            <td className="bdr1 bdcE6E6E6">
                                                {item.rmstnfamSn !== props.form.rmstnfamSn && item.rghpsnSe !== "1000" ?
                                                    <div className="dpFlx aiC jcC">
                                                        <button className="dpFlx aiC jcC csP bdN bgcTp" onClick={() => delRghpsnRow(item)}>
                                                            <img className="csP pr3" src={require('img/common/icon_delete20.png')} alt="icon_delete20" />
                                                            <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">삭제</span>
                                                        </button>
                                                    </div>
                                                : <></>}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div className="dpFlx aiC jcC w200">
                            <button className='dpFlx aiC jcSb w153 h36 bgcWhite bd1 bdc17A840 bdrd40 pl20 pr20 bdBox csP bdN' onClick={() => {
                                let tempArr: rghpsnProp[] = [];
                                if (targetList === undefined) {
                                    return;
                                }
                                for (const rghpsnInfo of targetList) {
                                    tempArr.push(rghpsnInfo);
                                }
                                tempArr.push({
                                    rmstnfamSe: null,
                                    rghpsnSn: targetList.length + 1,
                                    rghpsnSe: "",
                                    rmstnfamSn: 0,
                                    rghpsnNm: "",
                                    qotaSe: qotaSe,
                                    qotaRate: 0
                                });
                                setTargetList(tempArr);
                            }}>
                                <span className='SpoqaHanSansNeo-Regular fs13 ls065 fc17A840'>권리자 추가하기</span>
                                <img src={require('img/common/icon_add.png')} alt="icon_add" />
                            </button>
                        </div>
                    </div>
                </td>
            </React.Fragment>
        )
    };

    return (
        <React.Fragment>
            <AssetsEditTab
                wizard={props.wizard}
                assetsSn={props.form.assetsSn}
                estateSttus={props.form.estateSttus}
                estateDetailSe={estateDetailSe}
                acqsCause={acqsCause}
                acqsSrc={acqsSrc}
                currStepCode={1001}
            />
            <div className='w1210 mh500'>
                <AssetsTitle propsForm={props.form} asterlink={true} />
                <table className="forest-form-table">
                    <colgroup>
                        <col className="w250" />
                        <col className="w180" />
                        <col />
                    </colgroup>
                    <tbody>
                    <tr className="h80">
                        <th className="taC" title='자산구분'>자산구분<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                        <td colSpan={2}>
                            <div className="dpFlx aiC">
                                <div className="bgcWhite">
                                    <SelectBox
                                        value={estateDetailSe}
                                        codeArray={Util.getCodeArray("1006")}
                                        placeholder="자산구분을 선택해주세요"
                                        width={300}
                                        height={40}
                                        fontSize={15}
                                        paddingRight={5}
                                        onChangeFunc={(data: string) => setEstateDetailSe(data)}
                                        border="1px solid #dddddd"
                                    />
                                </div>
                                {estateDetailSe === "1001" ?
                                    <div
                                        className="dpFlx aiC pl10 csP"
                                        data-tooltip-id="houseCommentToolTip"
                                        data-tooltip-content={
                                            "주택이란 허가 여부나 공부상의 용도구분과 관계없이 세대의 구성원이 독립된 주거생활을 할 수 있는 구조로서\n" +
                                            "대통령령이 정하는 구조를 갖추어 사실상 주거용으로 사용하는 건물을 말한다.\n\n" +
                                            "이 경우 그 용도가 분명하지 아니하면 공부상의 용도에 따른다.(소득세법 제88조 제 7호)\n" +
                                            "따라서 무허가 주택도 당연히 주택에 해댕하므로 무허가 1주택만을 소유하고 있는 경우 비과세 규정을 적용하며,\n" +
                                            "다른 주택을 양도한 경우 주택수로 계산한다."
                                        }
                                    >
                                        <img src={require('img/common/icon_info_fill_20.png')} alt="icon_info_fill_20" className='csP pl3' />
                                        <span className="SpoqaHanSansNeo-Regular fs13 ls08 pl3">주택이란?</span>
                                        <ToolTip id="houseCommentToolTip" place='top' clickable={true} className="lh20 fs13" />
                                    </div>
                                : <></>}
                                {estateDetailSe === "1002" ?
                                    <div
                                        className="dpFlx aiC pl10 csP"
                                        data-tooltip-id="lttotRightCommentToolTip"
                                        data-tooltip-content={
                                            "※ 분양권의 범위\n\n" +
                                            "소득세법 제88조 제10호에서 주택법 등 대통령령으로 정하는 법률이란 다음 각 호의 법률을 말한다(2021.2.17. 신설)\n\n" +
                                            "1.「건축물의 분양에 관한 법률」\n" +
                                            "2.「공공주택 특별법」\n" +
                                            "3.「도시개발법」\n" +
                                            "4.「도시 및 주거환경정비법」\n" +
                                            "5.「빈집 및 소규모주택 정비에 관한 특례법」\n" +
                                            "6.「산업입지 및 개발에 관한 법률」\n" +
                                            "7.「주택법」\n" +
                                            "8.「택지개발촉진법」\n\n" +
                                            "위 범위에 해당하는 분양권은 주택수에 포함되고 주거용오피스텔 분양권, 생활형숙박시설 분양권은 주택수에 포함되지 아니한다."
                                        }
                                    >
                                        <img src={require('img/common/icon_info_fill_20.png')} alt="icon_info_fill_20" className='csP pl3' />
                                        <span className="fs13 SpoqaHanSansNeo-Regular ls08 pl3">분양권이란?</span>
                                        <ToolTip id="lttotRightCommentToolTip" place='top' clickable={true} className="w500 lh20" />
                                    </div>
                                : <></>}
                                {estateDetailSe === "1003" ?
                                    <div className="dpFlx aiC pl10 csP" onClick={() => {
                                        setOccupancyRightModalOpen(true);
                                    }}>
                                        <img src={require('img/common/icon_info_fill_20.png')} alt="icon_info_fill_20" className='csP pl3' />
                                        <span className="fs13 SpoqaHanSansNeo-Regular ls08 pl3">조합원입주권이란?</span>
                                    </div>
                                : <></>}
                            </div>
                        </td>
                    </tr>
                    <tr className={(hseNumAtTrVsb ? "" : "dpNone") + " h64"}>
                        <th className="taC" title='주택수포함여부'>주택수포함여부<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                        <td colSpan={2}>
                            <ImageRadioBox
                                codeList={Util.getCodeArray("1058")}
                                value={hseNumAt}
                                setValue={setHseNumAt}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                            />
                        </td>
                    </tr>
                    <tr className="h126">
                        <th className="taC" title='주소'>
                            <div className="dpFlx aiC jcC">
                                주소<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2 mr10">*</span>
                                {estateDetailSe !== "1001" ?
                                    <ImageCheckBox
                                        value={adresDrctInputAt === "Y"}
                                        labelText='직접입력'
                                        btnClass='w20 h20'
                                        labelClass='SpoqaHanSansNeo-Regular fs14 ls07 fc666666 pl5'
                                        onChange={(flag: boolean) => {
                                            resetEstateInfo()
                                            if (flag) {
                                                setAdresDrctInputAt("Y");
                                            } else {
                                                setAdresDrctInputAt("N");
                                            }
                                        }}
                                    />
                                : <></>}
                            </div>
                        </th>
                        <td colSpan={2}>
                            {adresDrctInputAt === "Y" && ["1002", "1003"].indexOf(estateDetailSe) !== -1 ?
                                <React.Fragment>
                                    <div className="dpFlx">
                                        <div className="bgcWhite dpFlx mr5">
                                            <SelectBox
                                                value={sidoCode}
                                                codeArray={AppContext.sidoCodeInfoList}
                                                placeholder="시/도를 선택해주세요"
                                                width={200}
                                                height={40}
                                                fontSize={15}
                                                paddingRight={5}
                                                onChangeFunc={(data: string) => {
                                                    setSidoCode(data);
                                                    AppContext.showSpinner();
                                                    Axios.dataAccess({
                                                        url: "common/getSggCodeInfoList.do",
                                                        methodType: "post",
                                                        paramType: "object",
                                                        paramData: {
                                                            sidoCode: data
                                                        },
                                                        onSuccessFn: (res: any) => {
                                                            setSggCodeInfoList(res.list);
                                                            setEmdCodeInfoList([]);
                                                            if (res.list.length === 1) {
                                                                setSggCode(res.list[0].code);
                                                                setSggCodeElVsb(false);
                                                                Axios.dataAccess({
                                                                    url: "common/getEmdCodeInfoList.do",
                                                                    methodType: "post",
                                                                    paramType: "object",
                                                                    paramData: {
                                                                        sggCode: res.list[0].code
                                                                    },
                                                                    onSuccessFn: (res: any) => {
                                                                        setEmdCodeInfoList(res.list);
                                                                        setEmdCode("");
                                                                        AppContext.hideSpinner();
                                                                    }
                                                                });
                                                            } else {
                                                                setSggCodeElVsb(true);
                                                                setSggCode("");
                                                                setEmdCode("");
                                                                AppContext.hideSpinner();
                                                            }
                                                        }
                                                    });
                                                }}
                                                border="1px solid #dddddd"
                                            />
                                        </div>
                                        <div className={"bgcWhite mr5 " + (sggCodeElVsb ? "dpFlx" : "dpNone")}>
                                            <SelectBox
                                                value={sggCode}
                                                codeArray={sggCodeInfoList}
                                                placeholder="시군구를 선택해주세요"
                                                width={200}
                                                height={40}
                                                fontSize={15}
                                                paddingRight={5}
                                                onChangeFunc={(data: string) => {
                                                    setSggCode(data);
                                                    AppContext.showSpinner();
                                                    Axios.dataAccess({
                                                        url: "common/getEmdCodeInfoList.do",
                                                        methodType: "post",
                                                        paramType: "object",
                                                        paramData: {
                                                            sggCode: data
                                                        },
                                                        onSuccessFn: (res: any) => {
                                                            setEmdCodeInfoList(res.list);
                                                            setEmdCode("");
                                                            AppContext.hideSpinner();
                                                        }
                                                    });
                                                }}
                                                border="1px solid #dddddd"
                                            />
                                        </div>
                                        <div className="bgcWhite dpFlx mr5">
                                            <SelectBox
                                                value={emdCode}
                                                codeArray={emdCodeInfoList}
                                                placeholder="읍면동을 선택해주세요"
                                                width={200}
                                                height={40}
                                                fontSize={15}
                                                paddingRight={5}
                                                onChangeFunc={(data: string) => {
                                                    setEmdCode(data);
                                                    Axios.dataAccess({
                                                        url: "system/assets/chkAjstRgnInfo.do",
                                                        methodType: "post",
                                                        paramType: "object",
                                                        paramData: {
                                                            pnu: data
                                                        },
                                                        onSuccessFn: (res: any) => {
                                                            if (res.item === 0) {
                                                                setMdatTrgetAreaAt("N");
                                                            } else {
                                                                AppContext.openAlert({
                                                                    title: "조정대상지역 자동선택",
                                                                    msg: "해당 주소가 조정대상지역으로 판별되었습니다.",
                                                                    icon: "check"
                                                                });
                                                                setMdatTrgetAreaAt("Y");
                                                            }
                                                            AppContext.hideSpinner();
                                                        }
                                                    });
                                                }}
                                                border="1px solid #dddddd"
                                            />
                                        </div>
                                    </div>
                                    <TextBox
                                        className="bd1 bgcWhite mt5"
                                        inputClassName='pl10 pr10'
                                        value={rdnmAdresDetail}
                                        width={610}
                                        height={40}
                                        fontSize={15}
                                        maxLength={500}
                                        placeholder="상세주소를 입력할 수 있습니다."
                                        onChangeFunc={(text: string) => setRdnmAdresDetail(text)}
                                    />
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <div className="dpFlx mb5">
                                        <TextBox
                                            value={rdnmAdres}
                                            width={400}
                                            height={40}
                                            fontSize={15}
                                            className="bd1 pr5 pl5 bgcWhite mr5"
                                            inputClassName='pl10 pr10'
                                            mode="readonly"
                                            placeholder="도로명 주소가 표시됩니다."
                                            iconEl={<React.Fragment>
                                                <img
                                                    src={require('img/common/icon_search20.png')}
                                                    className="csP"
                                                    alt="icon_search20"
                                                    data-tooltip-id="assetsAddressSearchBtnTip"
                                                    data-tooltip-content="주소를 검색할 수 있습니다."
                                                    onClick={() => {
                                                        setAddressModalOpen(true);
                                                    }}
                                                />
                                                <ToolTip id="assetsAddressSearchBtnTip" place='top' clickable={true} />
                                            </React.Fragment>}
                                        />
                                        <TextBox
                                            className="bd1 pr5 pl5 bgcWhite mr5"
                                            inputClassName='pl10 pr10'
                                            value={rdnmAdresDetail}
                                            width={400}
                                            height={40}
                                            fontSize={15}
                                            maxLength={500}
                                            placeholder="상세주소를 입력할 수 있습니다."
                                            onChangeFunc={(text: string) => setRdnmAdresDetail(text)}
                                        />
                                    </div>
                                    <TextBox className="bd1 pr5 pl5 bgcWhite mr5" inputClassName='pl10 pr10' value={lnmAdres} width={400} height={40} fontSize={15} mode="readonly" placeholder="지번주소가 표시됩니다." />
                                </React.Fragment>
                            }
                        </td>
                    </tr>
                    <tr className={(estateFomTrVsb ? "" : "dpNone") + " h66"}>
                        <th className="taC" title='주택구분'>
                            <div className="dpFlx aiC jcC">
                                주택구분
                                <span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
                            </div>
                        </th>
                        <td colSpan={2}>
                            <ImageRadioBox
                                codeList={Util.getCodeArray("1007")}
                                value={estateFom}
                                setValue={setEstateFom}
                                disabledVals={estateFomDsbVals}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                                onChange={(data: string) => {
                                    onChgModalOpen(data);
                                    onChgEstateFomAcqsSrcDfVal(data);
                                }}
                            />
                        </td>
                    </tr>
                    <tr className={(proptaxLevyAtTrVsb ? "" : "dpNone") + " h64"}>
                        <th className="taC" title='재산세부과구분'>재산세부과구분<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                        <td colSpan={2}>
                            <ImageRadioBox
                                codeList={Util.getCodeArray("1059")}
                                value={proptaxLevyAt}
                                setValue={setProptaxLevyAt}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                            />
                        </td>
                    </tr>
                    <tr className={(acqsSrcTrVsb ? "" : "dpNone") + " h64"}>
                        <th className="taC" title='취득구분'>취득구분<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                        <td colSpan={2}>
                            <ImageRadioBox
                                codeList={Util.getCodeArray(acqsSrcCodeTy)}
                                value={acqsSrc}
                                setValue={setAcqsSrc}
                                filterVals={acqsSrcVals}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                                onChange={(data: string) => onChgAcqsSrcAcqsCauseDfVal(data)}
                            />
                        </td>
                    </tr>
                    <tr className={(acqsCauseTrVsb ? "" : "dpNone") + " h64"}>
                        <th className="taC" title='취득원인'>취득원인<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                        <td colSpan={2}>
                            <ImageRadioBox
                                codeList={Util.getCodeArray(acqsCauseCodeTy)}
                                value={acqsCause}
                                setValue={setAcqsCause}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                            />
                        </td>
                    </tr>
                    <tr className={(estatePrposTrVsb ? "" : "dpNone") + " h64"}>
                        <th className="taC" title='주택용도'>주택용도<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                        <td colSpan={2}>
                            <div className="dpFlx flxdRow">
                                <ImageRadioBox
                                    codeList={Util.getCodeArray("1008")}
                                    value={estatePrpos}
                                    setValue={setEstatePrpos}
                                    btnClass="w24 h24 pr5"
                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                    interval={20}
                                />
                                <div
                                    className="dpFlx aiC"
                                    data-tooltip-id="rentalCommentToolTip"
                                    data-tooltip-content={ "임대이력이 있는 주택을 포함" }
                                >
                                    <img src={require('img/common/icon_info_fill_20.png')} alt="icon_info_fill_20" className='csP' />
                                    <ToolTip id="rentalCommentToolTip" place='top' clickable={true} className="lh20 fs13" />
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr className="h98">
                        <th rowSpan={3} className="taC" title="정보표시사항">정보표시사항</th>
                        <th className="taL" title="용도지역">용도지역</th>
                        <td>
                            <div className="dpFlx aiC pb10">
                                <span className="SpoqaHanSansNeo-Medium fs14 ls1 fc666666 pr12 w49">도시지역</span>
                                <button className="dpFlx aiC csP bdN bgcTp p0 mr20" onClick={() => setSpfcKnd("1001")}>
                                    <span className="SpoqaHanSansNeo-Medium fs14 ls1 fc666666 pr5">(</span>
                                    <img className="w24 h24 pr5" src={require('img/common/radiobutton_base_' + (spfcKnd === "1001" ? 'on' : 'off') + '.png')} alt="radiobutton_base" />
                                    <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">주거지역</span>
                                </button>
                                <button className="dpFlx aiC csP bdN bgcTp p0 mr20" onClick={() => setSpfcKnd("1002")}>
                                    <img className="w24 h24 pr5" src={require('img/common/radiobutton_base_' + (spfcKnd === "1002" ? 'on' : 'off') + '.png')} alt="radiobutton_base" />
                                    <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">상업지역</span>
                                </button>
                                <button className="dpFlx aiC csP bdN bgcTp p0 mr20" onClick={() => setSpfcKnd("1003")}>
                                    <img className="w24 h24 pr5" src={require('img/common/radiobutton_base_' + (spfcKnd === "1003" ? 'on' : 'off') + '.png')} alt="radiobutton_base" />
                                    <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">공업지역</span>
                                </button>
                                <button className="dpFlx aiC csP bdN bgcTp p0 mr20" onClick={() => setSpfcKnd("1004")}>
                                    <img className="w24 h24 pr5" src={require('img/common/radiobutton_base_' + (spfcKnd === "1004" ? 'on' : 'off') + '.png')} alt="radiobutton_base" />
                                    <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">녹지지역</span>
                                    <span className="SpoqaHanSansNeo-Medium fs14 ls1 fc666666 pl5">)</span>
                                </button>
                            </div>
                            <div className="dpFlx aiC">
                                <span className="SpoqaHanSansNeo-Medium fs14 ls1 fc666666 pr12 w49"></span>
                                <button className="dpFlx aiC csP bdN bgcTp p0 mr20" onClick={() => setSpfcKnd("1005")}>
                                    <img className="w24 h24 pr5" src={require('img/common/radiobutton_base_' + (spfcKnd === "1005" ? 'on' : 'off') + '.png')} alt="radiobutton_base" />
                                    <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">관리지역</span>
                                </button>
                                <button className="dpFlx aiC csP bdN bgcTp p0 mr20" onClick={() => setSpfcKnd("1006")}>
                                    <img className="w24 h24 pr5" src={require('img/common/radiobutton_base_' + (spfcKnd === "1006" ? 'on' : 'off') + '.png')} alt="radiobutton_base" />
                                    <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">농림지역</span>
                                </button>
                                <button className="dpFlx aiC csP bdN bgcTp p0 mr20" onClick={() => setSpfcKnd("1007")}>
                                    <img className="w24 h24 pr5" src={require('img/common/radiobutton_base_' + (spfcKnd === "1007" ? 'on' : 'off') + '.png')} alt="radiobutton_base" />
                                    <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">자연환경보전지역</span>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr className="h64">
                        <th className="taL" title="과밀억제권역">과밀억제권역</th>
                        <td>
                            <ImageRadioBox
                                codeList={Util.getCodeArray("1042")}
                                value={ovpalotAt}
                                setValue={setOvpalotAt}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                            />
                        </td>
                    </tr>
                    <tr className="h64">
                        <th className="taL" title="조정대상지역">조정대상지역</th>
                        <td>
                            <ImageRadioBox
                                codeList={Util.getCodeArray("1042")}
                                value={mdatTrgetAreaAt}
                                setValue={setMdatTrgetAreaAt}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                            />
                        </td>
                    </tr>
                    <tr className={(aphusTrVsb ? "" : "dpNone") + " h80"}>
                        <th rowSpan={3} className="taC" title="공동주택">공동주택</th>
                        <th className="taL" title="대지권">대지권</th>
                        <td>
                            <div className="dpFlx aiE">
                                <NumberTextBox
                                    value={plotRight}
                                    onChange={(data: string) => setPlotRight(data)}
                                    borderClass="bgcWhite w300 h40 pr15 bd1"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={10}
                                    inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                />
                                <span className="SpoqaHanSansNeo-Regular fs14 ls014 fc666666 pl10 pb2">㎡(대지권은 공동주택)</span>
                            </div>
                        </td>
                    </tr>
                    <tr className={(aphusTrVsb ? "" : "dpNone") + " h80"}>
                        <th className="taL" title='전용면적'>전용면적<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                        <td>
                            <div className="dpFlx aiE">
                                <NumberTextBox
                                    value={prvuseAr}
                                    onChange={(data: string) => setPrvuseAr(data)}
                                    borderClass="bgcWhite w300 h40 pr15 bd1"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={10}
                                    inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                />
                                <span className="SpoqaHanSansNeo-Regular fs14 ls014 fc666666 pl10 pb2">㎡(공동주택)</span>
                            </div>
                        </td>
                    </tr>
                    <tr className={(aphusTrVsb ? "" : "dpNone") + " h80"}>
                        <th className="taL" title="공유면적">공유면적</th>
                        <td>
                            <div className="dpFlx aiE">
                                <NumberTextBox
                                    value={cnrsAr}
                                    onChange={(data: string) => setCnrsAr(data)}
                                    borderClass="bgcWhite w300 h40 pr15 bd1"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={10}
                                    inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                />
                                <span className="SpoqaHanSansNeo-Regular fs14 ls014 fc666666 pl10 pb2">㎡(공동주택)</span>
                            </div>
                        </td>
                    </tr>
                    <tr className={(slhTrVsb ? "" : "dpNone") + " h80"}>
                        <th rowSpan={3} className="taC" title="단독주택 및 겸용주택">
                            <div className="dpFlx aiC jcC">
                                단독주택 및<br />겸용주택
                                <span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
                                <img src={require('img/common/icon_info_fill_20.png')} alt="icon_info_fill_20" className='pl3 csP' onClick={() => setCmbnatHouseModalOpen(true)} />
                            </div>
                        </th>
                        <th className="taL" title="대지면적">대지면적<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                        <td>
                            <div className="dpFlx aiE">
                                <NumberTextBox
                                    value={plotAr}
                                    onChange={(data: string) => setPlotAr(data)}
                                    borderClass="bgcWhite w300 h40 pr15 bd1"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={10}
                                    inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                />
                                <span className="SpoqaHanSansNeo-Regular fs14 ls014 fc666666 pl10 pb2">㎡(단독주택)</span>
                            </div>
                        </td>
                    </tr>
                    <tr className={(slhTrVsb ? "" : "dpNone") + " h80"}>
                        <th className="taL" title='주택연면적'>주택연면적<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                        <td>
                            <div className="dpFlx aiE">
                                <NumberTextBox
                                    value={houseTotar}
                                    onChange={(data: string) => setHouseTotar(data)}
                                    borderClass="bgcWhite w300 h40 pr15 bd1"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={10}
                                    inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                />
                                <span className="SpoqaHanSansNeo-Regular fs14 ls014 fc666666 pl10 pb2">㎡(단독주택)</span>
                            </div>
                        </td>
                    </tr>
                    <tr className={(slhTrVsb ? "" : "dpNone") + " h80"}>
                        <th className="taL" title="상가연면적">상가연면적</th>
                        <td>
                            <div className="dpFlx aiE">
                                <NumberTextBox
                                    value={sopsrtTotar}
                                    onChange={(data: string) => setSopsrtTotar(data)}
                                    borderClass="bgcWhite w300 h40 pr15 bd1"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={10}
                                    inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                />
                                <span className="SpoqaHanSansNeo-Regular fs14 ls014 fc666666 pl10 pb2">㎡(겸용주택)</span>
                            </div>
                        </td>
                    </tr>
                    {(estateDetailSe === "1001" && (estateFom === "1001" || estateFom === "1002" || estateFom === "1006")) || ["1002", "1003"].indexOf(estateDetailSe) !== -1 ?
                        <tr>
                            <th colSpan={2} className="taC" title='소유지분율'>소유지분율<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                            {RghpsnListBox(rghpsnList, setRghpsnList, "1001")}
                        </tr>
                    : <></>}
                    {estateDetailSe === "1001" && (estateFom === "1003" || estateFom === "1004" || estateFom === "1005") ?
                        <React.Fragment>
                            <tr>
                                <th rowSpan={2} className="taC" title='소유지분율'>소유지분율<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                <th className="taL" title='건물'>건물</th>
                                {RghpsnListBox(rghpsnList, setRghpsnList, "1001")}
                            </tr>
                            <tr>
                                <th className="taL">토지</th>
                                {RghpsnListBox(landRghpsnList, setLandRghpsnList, "1002")}
                            </tr>
                        </React.Fragment>
                    : <></>}
                    </tbody>
                </table>
                <AssetsButton propsForm={props.form} goNextStep={goNextStep} />
            </div>
            {/* <AssetsButtonBar propsForm={props.form} goNextStep={goNextStep} /> */}
            <Modal modalOpen={lttotRightModalOpen} setModalOpen={setLttotRightModalOpen} title="분양권" dim={true} visible={true}>
                <LttotRight />
            </Modal>
            <Modal modalOpen={occupancyRightModalOpen} setModalOpen={setOccupancyRightModalOpen} title="조합원입주권" dim={true} visible={true}>
                <OccupancyRight />
            </Modal>
            <Modal modalOpen={houseInfoModalOpen} setModalOpen={setHouseInfoModalOpen} title="주택의 정의" dim={true} visible={true}>
                <HouseInfo setModalOpen={setHouseInfoModalOpen} />
            </Modal>
            <Modal modalOpen={mltdwlHouseModalOpen} setModalOpen={setMltdwlHouseModalOpen} title="다가구주택" dim={true} visible={true}>
                <MltdwlHouse setModalOpen={setMltdwlHouseModalOpen} setEstateFom={setEstateFom} />
            </Modal>
            <Modal modalOpen={multiHouseModalOpen} setModalOpen={setMultiHouseModalOpen} title="다중주택" dim={true} visible={true}>
                <MultiHouse setModalOpen={setMultiHouseModalOpen} setEstateFom={setEstateFom} />
            </Modal>
            <Modal modalOpen={cmbnatHouseModalOpen} setModalOpen={setCmbnatHouseModalOpen} title="겸용주택" dim={true} visible={true}>
                <CmbnatHouse setModalOpen={setCmbnatHouseModalOpen} />
            </Modal>
            <Modal modalOpen={addressModalOpen} setModalOpen={setAddressModalOpen} title="주소검색" dim={true} visible={true}>
                <Address setModalOpen={setAddressModalOpen} callBack={onFinishAddrSearch} />
            </Modal>
        </React.Fragment>
    );
}
export default AssetsBase;