import React from "react";
import ImageRadioBox from "js/common/ui/input/ImageRadioBox";
import Util from "js/common/Util";

interface Qestn13Param {
    setQestn13Open: React.Dispatch<React.SetStateAction<boolean>>
    setFrhlHouseAt: React.Dispatch<React.SetStateAction<string>>
    qestn62AnswerText: string
    setQestn62AnswerText: React.Dispatch<React.SetStateAction<string>>
    qestn63AnswerText: string
    setQestn63AnswerText: React.Dispatch<React.SetStateAction<string>>
    qestn64AnswerText: string
    setQestn64AnswerText: React.Dispatch<React.SetStateAction<string>>
    qestn65AnswerText: string
    setQestn65AnswerText: React.Dispatch<React.SetStateAction<string>>
    qestn66AnswerText: string
    setQestn66AnswerText: React.Dispatch<React.SetStateAction<string>>
}
const Qestn13Modal = ({
    setQestn13Open,
    setFrhlHouseAt,
    qestn62AnswerText,
    setQestn62AnswerText,
    qestn63AnswerText,
    setQestn63AnswerText,
    qestn64AnswerText,
    setQestn64AnswerText,
    qestn65AnswerText,
    setQestn65AnswerText,
    qestn66AnswerText,
    setQestn66AnswerText
}: Qestn13Param) => {
    return (
        <div className="w1300">
            <table className="forest-table bdcDDDDDD th-bgcF0F0F0 th-fm-SpoqaHanSansNeo-Medium th-fs14 th-ls07 th-fc222222 th-fw500 td-fm-SpoqaHanSansNeo-Regular td-fs12">
                <colgroup>
                    <col className="w5p"></col>
                    <col className="w15p"></col>
                    <col className="w15p"></col>
                    <col className="w25p"></col>
                    <col className="w20p"></col>
                    <col className="w20p"></col>
                </colgroup>
                <tbody>
                    <tr className="h30">
                        <th className="bdr1 bdt1 bdb1" rowSpan={2}>구분</th>
                        <th className="bdb1 bdt1" colSpan={3}>소득세법시행령 제155조 제7항 농어촌주택</th>
                        <th className="bdb1 bdt1" colSpan={2}>조세특례제한법 제99조이 4 농어촌주택</th>
                    </tr>
                    <tr className="h50">
                        <th className="bdr1 bdb1">상속받은 주택(피상속인이 취득 후<br/>5년 이상 거주한 주택에 한함)</th>
                        <th className="bdr1 bdb1">이농인(어업인 포함)이 취득 후<br/>5년 이상 거주한 이농주택</th>
                        <th className="bdr1 bdb1">영농 또는 영어의 목적으로<br/>취득한 귀농주택</th>
                        <th className="bdr1 bdb1">농어촌주택을 취득하여 3년 이상 보유(2003.8.1.~2025.12.31. 취득)</th>
                        <th className="bdb1">고향주택을 취득하여 3년 이상 보유(2009.1.1.~2025.12.31. 취득)</th>
                    </tr>
                    <tr>
                        <th className="bdb1 bdr1">지역<br/>기준</th>
                        <td className="bdr1 bdb1 taC" colSpan={3}>수도권 밖의 지역 중 읍지역(도시지역안의 지역을 제외한다) 또는 면지역</td>
                        <td className="bdr1 bdb1 p10">
                            읍 또는 면, 동으로 하되 다음 지역은 제외<br/><br/>
                            1. 수도권 및 광역시<br/>
                            2. 도시지역<br/>
                            3. 토지거래허가구역<br/>
                            4. 조정대상지역<br/>
                            5. 관광단지
                        </td>
                        <td className="bdb1 p10">
                            다음의 요건을 충족한 시지역으로 수도권 및 광역시, 조정대상지역, 관광단지 제외한다.<br/>
                            1. 10년 이상 등록된 본적지 및 원적지<br/>
                            2. 10년 이상 거주한 사실이 있는 지역
                        </td>
                    </tr>
                    <tr>
                        <th className="bdb1 bdr1">기타<br/>요건</th>
                        <td className="bdr1 bdb1 taC">-</td>
                        <td className="bdr1 bdb1 taC">-</td>
                        <td className="bdr1 bdb1 p10">
                            1. 2016.2.16. 이전 귀농주택을 취득한 경우에는 본적지 또는 연고지에 소재할 것<br/>
                            2. 고가주택에 해당하지 아니할 것<br/>
                            3. 대지면적이 660㎡ 이내일 것<br/>
                            4. 1,000㎡ 이상의 농지를 소유하는 자가 농지의 소재지에서 취득하는 주택일 것 또는 어업인이 취득하는 주택일 것<br/>
                            5. 세대전원(세대일부가 취학, 근무 등으로 함께 이전하지 못하는 경우는 인전됨)이 이사하여 거주할 것
                        </td>
                        <td className="bdr1 bdb1 p10">
                            1. 2023.1.1. 이후 취득한 농어촌주택의 경우 취득 당시 기준시가 3억원(한옥은 4억원) 이하일 것<br/>
                            2. 일반주택이 소재한 읍·면 지역(인접지역)이 아닌 곳에서 농어촌주택을 취득할 것
                        </td>
                        <td className="bdb1 p10">
                            1. 취득 당시 기준시가 3억원(2014.1.1. 이후 취득한 한옥은 4억원)이하일 것<br/>
                            2. 일반주택이 같은 시 또는 연접한 시에 소재하지 않을 것
                        </td>
                    </tr>
                    <tr>
                        <th className="bdb1 bdr1">양도<br/>기한</th>
                        <td className="bdr1 bdb1 taC">-</td>
                        <td className="bdr1 bdb1 taC">-</td>
                        <td className="bdr1 bdb1 p10">귀농주택을 취득한 날부터 5년 이내에 일반주택을 양도한 경우에 한정한다.</td>
                        <td className="bdr1 bdb1 taC">-</td>
                        <td className="bdb1 taC">-</td>
                    </tr>
                    <tr>
                        <th className="bdb1 bdr1">일반<br/>주택<br/>요건</th>
                        <td className="bdb1 bdr1 p10" colSpan={2}>일반주택을 수차례 취득·양도하더라도 비과세 적용 가능</td>
                        <td className="bdb1 bdr1 p10">귀농 후 최초로 양도하는 일반주택에 한한다.</td>
                        <td className="bdb1 bdr1 p10">당해 농어촌주택 취득 전에 보유하던 일반주택에 한함</td>
                        <td className="bdb1 p10">당해 고향주택 취득 전에 보유하던 일반주택 양도에 한함</td>
                    </tr>
                    <tr>
                        <th className="bdb1 bdr1">비과세<br/>배제</th>
                        <td className="bdb1 bdr1 taC">-</td>
                        <td className="bdb1 bdr1 taC">-</td>
                        <td className="bdb1 bdr1 p10">귀농일부터 계속하여 3년 이상 영농 또는 영어에 종사하지 아니하거나 그 기간 동안 당해 주택에 거주하지 아니한 경우</td>
                        <td className="bdb1 bdr1 taC">-</td>
                        <td className="bdb1 taC">-</td>
                    </tr>
                    <tr>
                        <th className="bdr1 bdb1">적용<br/>신청</th>
                        <td className="bdr1 bdb1 taC">-</td>
                        <td className="bdr1 bdb1 taC">-</td>
                        <td className="bdr1 bdb1 taC">-</td>
                        <td className="bdb1 p10" colSpan={2}>일반주택 양도신고기한 내에 과세특례적용신고서 제출</td>
                    </tr>
                    <tr className="h50">
                        <th className="bdr1 bdb1"/>
                        <td className="bdr1 bdb1">
                            <div className="dpFlx aiC jcC">
                                <ImageRadioBox
                                    codeList={Util.getCodeArray("1042")}
                                    value={qestn62AnswerText}
                                    btnClass="w24 h24 pr5"
                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                    interval={20}
                                    onChange={(item: string) => setQestn62AnswerText(item)}
                                />
                            </div>
                        </td>
                        <td className="bdr1 bdb1">
                            <div className="dpFlx aiC jcC">
                                <ImageRadioBox
                                    codeList={Util.getCodeArray("1042")}
                                    value={qestn63AnswerText}
                                    btnClass="w24 h24 pr5"
                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                    interval={20}
                                    onChange={(item: string) => setQestn63AnswerText(item)}
                                />
                            </div>
                        </td>
                        <td className="bdr1 bdb1">
                            <div className="dpFlx aiC jcC">
                                <ImageRadioBox
                                    codeList={Util.getCodeArray("1042")}
                                    value={qestn64AnswerText}
                                    btnClass="w24 h24 pr5"
                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                    interval={20}
                                    onChange={(item: string) => setQestn64AnswerText(item)}
                                />
                            </div>
                        </td>
                        <td className="bdr1 bdb1">
                            <div className="dpFlx aiC jcC">
                                <ImageRadioBox
                                    codeList={Util.getCodeArray("1042")}
                                    value={qestn65AnswerText}
                                    btnClass="w24 h24 pr5"
                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                    interval={20}
                                    onChange={(item: string) => setQestn65AnswerText(item)}
                                />
                            </div>
                        </td>
                        <td className="bdr1 bdb1">
                            <div className="dpFlx aiC jcC">
                                <ImageRadioBox
                                    codeList={Util.getCodeArray("1042")}
                                    value={qestn66AnswerText}
                                    btnClass="w24 h24 pr5"
                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                    interval={20}
                                    onChange={(item: string) => setQestn66AnswerText(item)}
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className='w100p dpFlx aiC jcC pt40'>
                <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => {
                    if ([qestn62AnswerText, qestn63AnswerText, qestn64AnswerText, qestn65AnswerText, qestn66AnswerText].indexOf("Y") === -1) {
                        setFrhlHouseAt("N");
                    }
                    setQestn13Open(false);
                }}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>확인</span>
                </div>
            </div>
        </div>
    )
}
export default Qestn13Modal;