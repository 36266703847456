import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import useAlert from "./UseAlert";
import ForestStyle from "js/common/ui/style/ForestStyle";
import Util from "js/common/Util";

export interface AlertProps {
  title?: string;
  message: string;
  closeText?: string;
  confirmText?: string;
  handleClose?: (...arg: any[]) => any;
  handleConfirm?: (...arg: any[]) => any;
}

const Alert = ({
  title,
  message,
  closeText,
  confirmText,
  handleClose,
  handleConfirm,
}: AlertProps) => {
  const { hideAlert } = useAlert();

  const onClose = () => {
    hideAlert();
    if (handleClose) {
      handleClose();
    }
  };

  const onConfirm = async () => {
    hideAlert();
    if (handleConfirm) {
      await handleConfirm();
    }
  };

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={ForestStyle.alert}
    >
      <DialogTitle id="alert-header">
        <div className="pt40 pl40 pr40">
          <span>{title}</span>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="pl40 pr40 pb40 pt30 mw200 dpFlx aiC">
          <span className="SpoqaHanSansNeo-Medium fs16 ls08 fc666666 lh22">
            {message}
          </span>
        </div>
      </DialogContent>
      <DialogActions>
        <button
          className={
            "flx1 h100p csP dpFlx aiC jcC bdN " +
            (Util.isNotEmpty(confirmText) ? "bgcF6F6F6" : "bgc17A840")
          }
          onClick={onClose}
        >
          <span
            className={
              "SpoqaHanSansNeo-Medium fs16 " +
              (Util.isNotEmpty(confirmText) ? "fc222222" : "fcWhite")
            }
          >
            {Util.isEmpty(closeText) ? "닫기" : closeText}
          </span>
        </button>
        {Util.isNotEmpty(confirmText) ? (
          <button
            className="flx1 h100p bgc17A840 csP dpFlx aiC jcC bdN"
            onClick={onConfirm}
          >
            <span className="SpoqaHanSansNeo-Medium fs16 fcWhite">
              {confirmText}
            </span>
          </button>
        ) : (
          <></>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default Alert;
