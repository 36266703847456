import React from "react";
import MypageUser from "js/system/mypage/edit/MypageUser";
import MypageBsnm from "js/system/mypage/edit/MypageBsnm";
import MypageSubId from "js/system/mypage/edit/MypageSubId";
import MypageTicketMng from "js/system/mypage/edit/MypageTicketMng";

const MypageEdit = () => {
    const [menuFlag, setMenuFlag] = React.useState<string>("userInfo");

    return (
        <div className='w100p'>
            <div className='w100p dpFlx jcC pt30 pb60'>
                <span className="SpoqaHanSansNeo-Medium fs38 ls266 fcBlack fw500">
                    {menuFlag === "userInfo" ? "회원 기본정보 수정" : ""}
                    {menuFlag === "bsnmInfo" ? "사업자정보 수정" : ""}
                    {menuFlag === "subId" ? "서브아이디 관리" : ""}
                    {menuFlag === "ticketMng" ? "이용권 관리" : ""}
                </span>
            </div>
            <div className="w1208 m0auto">
                <div className="dpFlx w100p h56 bdBox aiC">
                    <div className={'flx1 h100p dpFlx aiC jcC bdb2 ' + (menuFlag === "userInfo" ? "bdc17A840" : "bdcWhite")} onClick={() => {
                        setMenuFlag("userInfo");
                    }}>
                        <div className='dpFlx aiC jcC w130 pb10 csP'>
                            <span className={'SpoqaHanSansNeo-Medium fs16 fw500 ls08 ' + (menuFlag === "userInfo" ? "fc17A840" : "fc999999")}>기본정보</span>
                        </div>
                    </div>
                    <div className={'flx1 h100p dpFlx aiC jcC bdb2 ' + (menuFlag === "bsnmInfo" ? "bdc17A840" : "bdcWhite")} onClick={() => {
                        setMenuFlag("bsnmInfo");
                    }}>
                        <div className='dpFlx aiC jcC w130 pb10 csP'>
                            <span className={'SpoqaHanSansNeo-Medium fs16 fw500 ls08 ' + (menuFlag === "bsnmInfo" ? "fc17A840" : "fc999999")}>사업자정보</span>
                        </div>
                    </div>
                    <div className={'flx1 h100p dpFlx aiC jcC bdb2 ' + (menuFlag === "subId" ? "bdc17A840" : "bdcWhite")} onClick={() => {
                        setMenuFlag("subId");
                    }}>
                        <div className='dpFlx aiC jcC w130 pb10 csP'>
                            <span className={'SpoqaHanSansNeo-Medium fs16 fw500 ls08 ' + (menuFlag === "subId" ? "fc17A840" : "fc999999")}>서브아이디</span>
                        </div>
                    </div>
                    <div className={'flx1 h100p dpFlx aiC jcC bdb2 ' + (menuFlag === "ticketMng" ? "bdc17A840" : "bdcWhite")} onClick={() => {
                        setMenuFlag("ticketMng");
                    }}>
                        <div className='dpFlx aiC jcC w130 pb10 csP'>
                            <span className={'SpoqaHanSansNeo-Medium fs16 fw500 ls08 ' + (menuFlag === "ticketMng" ? "fc17A840" : "fc999999")}>이용권관리</span>
                        </div>
                    </div>
                </div>
            </div>
            {menuFlag === "userInfo" ? <MypageUser /> : <></>}
            {menuFlag === "bsnmInfo" ? <MypageBsnm /> : <></>}
            {menuFlag === "subId" ? <MypageSubId /> : <></>}
            {menuFlag === "ticketMng" ? <MypageTicketMng /> : <></>}
        </div>
    )
}
export default MypageEdit;