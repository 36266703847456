/*******************************************************************************
 You are strictly prohibited to copy, disclose, distribute, modify, or use
 this program in part or as a whole without the prior written consent of
 CallTax. CallTax, owns the intellectual property rights in and to this program.
 (Copyright © 2023 CallTax. All Rights Reserved| Confidential)

 Specification
    1. Program ID    : LawordMng.tsx
    2. Program Title : 법령 Main, Detail, 조건 조회
    3. Created by    : JH Lee
    4. Create Date   : 2024.02.13
    5. Reference     :
*******************************************************************************/
import React              from "react";
import Axios              from 'js/common/Axios';
import AppContext         from "js/common/AppContext";
import MuiListFilter      from "js/common/ui/list/MuiListFilter";
import ForestTableStyle   from 'js/common/ui/style/ForestStyle';
import Modal              from 'js/common/ui/modal/Modal';
import Util               from "js/common/Util";
import LawordMainDialog   from 'js/admin/system/dialog/LawordMainDialog';
import LawordDetailDialog from "js/admin/system/dialog/LawordDetailDialog";
import LawordCndDialog    from "./dialog/LawordCndDialog";

function LawordMng() {
    const [mainDataArr  , setMainDataArr  ] = React.useState<any[]>([]);
    const [detailDataArr, setDetailDataArr] = React.useState<any[]>([]);
    const [cndDataArr   , setCndDataArr   ] = React.useState<any[]>([]);

    const [lawordSn      , setLawordSn      ] = React.useState<number>(0);
    const [lawordNm      , setLawordNm      ] = React.useState<string>("");
    const [lawordDetailSn, setLawordDetailSn] = React.useState<number>(0);
    const [lawordDetailNm, setLawordDetailNm] = React.useState<string>("");

    // 법령 조건 Detail 일련번호
    const [lawordCndDetailSn, setLawordCndDetailSn] = React.useState<number>(0);

    // 법령 Main Dialog 호출
    const [modalMainOpen    , setModalMainOpen] = React.useState<boolean>(false);
    const [lawordMainSelTime, setLawordMainSelTime] = React.useState<Date>(new Date());

    // 법령 Detail Dialog 호출
    const [modalDetailOpen    , setModalDetailOpen    ] = React.useState<boolean>(false);
    const [lawordDetailSelTime, setLawordDetailSelTime] = React.useState<Date>(new Date());

    // 법령 조건 Dialog 호출
    const [modalCndOpen    , setModalCndOpen    ] = React.useState<boolean>(false);
    const [lawordCndSelTime, setLawordCndSelTime] = React.useState<Date>(new Date());

    // 호출 구분 : 생성(ins)/수정(upd)
    const [callMode, setCallMode] = React.useState<string>("");

    // 법령 Main 생성(ins)/수정(upd) Modal Open
    const openLawordMainDialog = (callMode: string, lawordSn: number) => {
        setCallMode(callMode);
        setModalMainOpen(true);
        if (callMode === "udp") {
            setLawordSn(lawordSn);
        }
    }

    // 법령 Detail 생성(ins)/수정(upd) Modal Open
    const openLawordDetailDialog = (callMode: string, lawordSn: number, lawordNm:string, lawordDetailSn: number) => {
        setCallMode(callMode);
        setModalDetailOpen(true);
        setLawordSn(lawordSn);
        setLawordNm(lawordNm);
        if (callMode === "udp") {
            setLawordDetailSn(lawordDetailSn);
        }
    }

    // 법령 조건 Detail 생성(ins)/수정(upd) Modal Open
    const openLawordCndDialog = (callMode: string, lawordSn: number, lawordNm:string, lawordDetailSn: number, lawordDetailNm:string, lawordCndDetailSn:number) => {
        setCallMode(callMode);
        setModalCndOpen(true);
        setLawordSn(lawordSn);
        setLawordNm(lawordNm);
        setLawordDetailSn(lawordDetailSn);
        setLawordDetailNm(lawordDetailNm);
        setLawordCndDetailSn(lawordCndDetailSn);
    }

    // 법령 Main 조회
    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "admin/sysmng/laword/selLawMainList.do",
            methodType: "post",
            paramType: "object",
            onSuccessFn: (res: any) => {    // 리스트 호출 성공, 실패한 경우에는 공통으로 처리
                setMainDataArr(res.list);
                AppContext.hideSpinner();
            }
        });
    }, [lawordMainSelTime]);    // 메인 법령 입력 후 rendering 처리

    // 법령 Detail 조회
    React.useEffect(() => {
        if (Util.isEmpty(lawordSn)) {
            return;
        }
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "admin/sysmng/laword/selLawSubList.do",
            methodType: "post",
            paramType: "object",
            paramData: { lawordSn: lawordSn },
            onSuccessFn: (res: any) => {    // 리스트 호출 성공, 실패한 경우에는 공통으로 처리
                setDetailDataArr(res.list);
                AppContext.hideSpinner();
            }
        });
    }, [lawordSn, lawordDetailSelTime]);

    // 법령 조건 조회
    React.useEffect(() => {
        if (Util.isEmpty(lawordSn) || Util.isEmpty(lawordDetailSn)) {
            return;
        }
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "admin/sysmng/laword/selLawCndList.do",
            methodType: "post",
            paramType: "object",
            paramData: { lawordSn: lawordSn,  lawordDetailSn: lawordDetailSn},
            onSuccessFn: (res: any) => {    // 리스트 호출 성공, 실패한 경우에는 공통으로 처리
                setCndDataArr(res.list);
                AppContext.hideSpinner();
            }
        });
    }, [lawordSn, lawordDetailSn, lawordCndSelTime]);

    // 법령 메인 삭제
    const delLawordInfo = (lawordSn: number, lawordNm: string) => {
        Axios.dataAccess({
            url: "admin/sysmng/laword/selLawordDetailCnt.do",   // Detail 건수 확인
            methodType: "post",
            paramType: "object",
            paramData: { lawordSn: lawordSn },
            onSuccessFn: (res: any) => {
                // 법령 Detail 존재할 경우 삭제 불가
                if (res.item > 0) {
                    AppContext.openAlert({
                        title: "알림",
                        msg: "법령 Detail 정보가 [" + res.item + "]건 존재하여 법령 Main 정보를 삭제할 수 없습니다.",
                        icon: "warning"
                    });
                    return;
                }
                AppContext.openAlert({
                    title: "법령 Main 삭제",
                    msg: "[" + lawordNm + "] 법령 Main 정보를 삭제하시겠습니까?",
                    icon: "check",
                    confirmText: "삭제",
                    handleConfirm: () => {
                        AppContext.showSpinner();
                        Axios.dataAccess({
                            url: "admin/sysmng/laword/delLawordInfo.do",
                            methodType: "post",
                            paramType: "object",
                            paramData: { lawordSn: lawordSn },
                            onSuccessFn: () => {
                                AppContext.hideSpinner();
                                setLawordMainSelTime(new Date());   // 법령 메인 리스트 재조회 처리용
                            }
                        });
                     }
                });
            }
        });
    }

    // 법령 Detail 삭제 처리
    const delLawordDetail = (lawordSn: number, lawordDetailNm: string, lawordDetailSn:number) => {
        Axios.dataAccess({
            url: "admin/sysmng/laword/selLawordCndCnt.do",   // Detail 건수 확인
            methodType: "post",
            paramType: "object",
            paramData: { lawordSn      : lawordSn
                       , lawordDetailSn: lawordDetailSn
            },
            onSuccessFn: (res: any) => {
                // 법령 조건이 존재할 경우 삭제 불가
                if (res.item > 0) {
                    AppContext.openAlert({
                        title: "알림",
                        msg: "[" + lawordDetailNm + "] 법령 조건 정보가 [" + res.item + "]건 존재하여 법령 Detail 정보를 삭제할 수 없습니다.",
                        icon: "warning"
                    });
                    return;
                }

                // 법령 Detail 삭제 처리
                AppContext.openAlert({
                    title: "법령 Main 삭제",
                    msg: "[" + lawordDetailNm + "] 법령 Detail 정보를 삭제하시겠습니까?",
                    icon: "check",
                    confirmText: "삭제",
                    handleConfirm: () => {
                        AppContext.showSpinner();
                        Axios.dataAccess({
                            url: "admin/sysmng/laword/delLawordDetail.do",
                            methodType: "post",
                            paramType: "object",
                            paramData: { lawordSn      : lawordSn
                                       , lawordDetailSn: lawordDetailSn
                            },
                            onSuccessFn: (res: any) => {
                                AppContext.hideSpinner();
                                setLawordDetailSelTime(new Date());   // 법령 Detail 리스트 재조회 처리용
                            }
                        });
                    }
                });
            }
        });
    }

    // 법령 조건 Detail 삭제
    const delLawordCndDetail = (lawordSn: number, lawordDetailSn:number, lawordCndDetailSn: number) => {
        AppContext.openAlert({
            title: "법령 Main 삭제",
            msg: "[" + lawordDetailNm + "] 법령 조건 Detail 정보를 삭제하시겠습니까?",
            icon: "check",
            confirmText: "삭제",
            handleConfirm: () => {
                AppContext.showSpinner();
                Axios.dataAccess({
                    url: "admin/sysmng/laword/delLawordCndDetail.do",   // 조건 Detail 삭제
                    methodType: "post",
                    paramType: "json", 
                    paramData: { lawordSn         : lawordSn           // 법령 일련번호
                               , lawordDetailSn   : lawordDetailSn     // 법령상세 일련번호
                               , lawordCndDetailSn: lawordCndDetailSn  // 법령조건 상세일련번호
                    },
                    onSuccessFn: (res: any) => {
                        AppContext.hideSpinner();
                        setLawordCndSelTime(new Date());
                    }
                });
            }
        });    
    }

    return (
        <React.Fragment>
            <div className="w100p bgcF6F6F6">
                <div className="dpFlx flxdCol jcC aiC pt60 pb30">
                    <div className='taC pb30'>
                        <span className="dpBlock fcBlack fs38 ls266 SpoqaHanSansNeo-Medium">똑똑한 자산관리의 시작</span>
                    </div>
                </div>
                <div className="dpFlx jcC pt30 pb110">
                    <div className='w1200'>
                        <div className='h36 dpFlx aiE pb20 jcSb'>
                            <div className='h36 dpFlx aiE'>
                                <img className="mr10" src={require('img/common/icon_step.png')} alt="icon_step" />
                                <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 pb3'>법령 관리</span>
                            </div>
                            <div className="w153 h40 bgc1EA65B bdrd40 dpFlx jcC aiC csP" onClick={() => openLawordMainDialog("ins", 0)}>
                                <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>법령 Main 등록</span>
                                <img className="pl5" src={require('img/common/icon_add_white_20.png')} alt="icon_add_white_20" />
                            </div>
                        </div>
                        <MuiListFilter tableInfo={{
                            tableHeadVsb: true, // 헤드 표시 여부
                            styleType: ForestTableStyle, // 테이블 스타일
                            fldInfs: [   // 데이터 필드 setting
                                {               fldNm: 'lawordSn'  , pk: true                                                                 },
                                { type: 'text', fldNm: 'rowNum'    , width:  65, headAlign: "center", dataAlign: "center", label: 'No.'       },
                                { type: 'text', fldNm: 'lawordTy'  , width:  65, headAlign: "center", dataAlign: "center", label: '법령유형'  },
                                { type: 'text', fldNm: 'lawordNm'  , width: 200, headAlign: "center", dataAlign: "center", label: '법령명'    },
                                { type: 'text', fldNm: 'lawordGbn' , width: 100, headAlign: "center", dataAlign: "center", label: '법령구분'  },
                                { type: 'text', fldNm: 'lawordJo'  , width:  80, headAlign: "center", dataAlign: "center", label: '조'        },
                                { type: 'text', fldNm: 'lawordHo'  , width:  80, headAlign: "center", dataAlign: "center", label: '호'        },
                                { type: 'text', fldNm: 'lawordHang', width:  80, headAlign: "center", dataAlign: "center", label: '항'        },
                                { type: 'text', fldNm: 'lawordMok' , width:  80, headAlign: "center", dataAlign: "center", label: '목'        },
                                { type: 'text', fldNm: 'lawordDc'  , width: 200, headAlign: "center", dataAlign: "center", label: '법령 설명' },
                                { type: 'el'                       , width:  74, headAlign: "center", el: (item: any) => (
                                    <div className='dpFlx aiC csP' onClick={() => openLawordMainDialog("upd", item.lawordSn)}>
                                        <img className="csP pr3" src={require('img/common/icon_edit20.png')} alt="icon_edit20" />
                                        <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">수정</span>
                                    </div>
                                )},
                                { type: 'el', width: 74, headAlign: "center", el: (item: any) => (
                                    <div className='dpFlx aiC csP' onClick={() => delLawordInfo(item.lawordSn, item.lawordNm)}>
                                        <img className="csP pr3" src={require('img/common/icon_delete20.png')} alt="icon_delete20" />
                                        <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">삭제</span>
                                    </div>
                                )}
                            ],       // 필드 setting
                            ordFld: { name: "rowNum", direction: "asc" },
                            dataArr: mainDataArr,
                            maxRowNums: [5],   // row 갯수
                            trHeight: 48,
                            remainArea: false,      // 설정 row만큼 height 차지
                            srchVsb: true,          // 검색창
                            headerVsb: true,        // header 표시
                            rowClickFunc: (item: any) => {
                                setLawordSn(item.lawordSn); // 법령 Main 일련번호
                                setLawordNm(item.lawordNm); // 법령명
                                setLawordDetailSn(0); // 법령 Deatil 일련번호 초기화 → 조건 리스트 초기화
                            },
                            rowClickBgcFix: true,
                            paginateClass: "pt40"   // page controller 디자인
                        }}/>

                        {/* Law Detail */}
                        <div className={(Util.isEmpty(lawordSn) || lawordSn === 0) ? "dpNone" : ""}>
                            <div className='h36 dpFlx aiE pb20 pt40 jcSb'>
                                <div className='h36 dpFlx aiE'>
                                    <img className="mr10" src={require('img/common/icon_step_money.png')} alt="icon_step_money" />
                                    <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 pb3'>
                                        <span className='fc17A840'>{lawordNm}</span>
                                    </span>
                                </div>
                                <div className="w153 h40 bgc1EA65B bdrd40 dpFlx jcC aiC csP" onClick={() => openLawordDetailDialog("ins", lawordSn, lawordNm, 0)}>
                                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>법령 Detail 등록</span>
                                    <img className="pl5" src={require('img/common/icon_add_white_20.png')} alt="icon_add_white_20" />
                                </div>
                            </div>

                            <MuiListFilter tableInfo={{
                                tableHeadVsb: true, // 헤드 표시 여부
                                styleType: ForestTableStyle, // 테이블 스타일
                                fldInfs: [   // 데이터 필드 setting
                                    {               fldNm: 'lawordSn', pk: true },
                                    { type: 'text', fldNm: 'rowNum'        , width:  58, headAlign: "center", dataAlign: "center", label: 'No.'              },
                                    { type: 'text', fldNm: 'lawordDetailSn', width:  90, headAlign: "center", dataAlign: "center", label: '법령상세일련번호' },
                                    { type: 'text', fldNm: 'lawordDetailNm', width: 180, headAlign: "center", dataAlign: "center", label: '법령상세명'       },
                                    { type: 'text', fldNm: 'lawordDetailDc', width: 180, headAlign: "center", dataAlign: "center", label: '법령상세설명'     },
                                    { type: 'text', fldNm: 'excptAt'       , width:  80, headAlign: "center", dataAlign: "center", label: '특례여부'         },
                                    { type: 'text', fldNm: 'validYearNum'  , width: 100, headAlign: "center", dataAlign: "center", label: '유효연도햇수'     },
                                    { type: 'text', fldNm: 'rdcRate'       , width: 100, headAlign: "center", dataAlign: "center", label: '감면율'           },
                                    { type: 'text', fldNm: 'hseExcptAt'    , width: 100, headAlign: "center", dataAlign: "center", label: '주택수제외여부'   },
                                    { type: 'text', fldNm: 'useAt'         , width: 100, headAlign: "center", dataAlign: "center", label: '사용여부'         },
                                    { type: 'el'                           , width:  74, headAlign: "center", el: (item: any) => (
                                        <div className='dpFlx aiC csP' onClick={() => openLawordDetailDialog("upd", item.lawordSn, lawordNm, item.lawordDetailSn)}>
                                            <img className="csP pr3" src={require('img/common/icon_edit20.png')} alt="icon_edit20" />
                                            <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">수정</span>
                                        </div>
                                    )},
                                    { type: 'el'                           , width:  74, headAlign: "center", el: (item: any) => (
                                        <div className='dpFlx aiC csP' onClick={() => delLawordDetail(item.lawordSn, item.lawordDetailNm, item.lawordDetailSn)}>
                                            <img className="csP pr3" src={require('img/common/icon_edit20.png')} alt="icon_edit20" />
                                            <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">삭제</span>
                                        </div>
                                    )}
                                ],       // 필드 setting
                                ordFld: { name: "rowNum", direction: "asc" },
                                dataArr: detailDataArr,
                                maxRowNums: [5],   // row 갯수
                                trHeight: 48,
                                remainArea: false,      // 설정 row만큼 height 차지
                                srchVsb: true,          // 검색창
                                headerVsb: true,        // header 표시
                                rowClickFunc: (item: any) => {
                                    // setLwordSn(item.lawordSn); // 법령 Main 일련번호
                                    setLawordDetailSn(item.lawordDetailSn); // 법령 Detail 일련번호
                                    setLawordDetailNm(item.lawordDetailNm); // 법령 Detail 명
                                },
                                rowClickBgcFix: true,
                                paginateClass: "pt40"   // page controller 디자인
                            }}/>
                        </div>

                        {/* 법령 조건 */}
                        <div className={(Util.isEmpty(lawordSn) || Util.isEmpty(lawordDetailSn) || lawordDetailSn === 0) ? "dpNone" : ""}>
                            <div className='h36 dpFlx aiE pb20 pt40 jcSb'>
                                <div className='h36 dpFlx aiE'>
                                    <img className="mr10" src={require('img/common/icon_step_money.png')} alt="icon_step_money" />
                                    <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 pb3'>
                                        <span className='fc17A840'>법령조건</span>
                                    </span>
                                </div>
                                <div className="w153 h40 bgc1EA65B bdrd40 dpFlx jcC aiC csP" onClick={() => openLawordCndDialog("ins", lawordSn, lawordNm, lawordDetailSn, lawordDetailNm, 0)}>
                                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>법령 조건 등록</span>
                                    <img className="pl5" src={require('img/common/icon_add_white_20.png')} alt="icon_add_white_20" />
                                </div>
                            </div>    

                            <MuiListFilter tableInfo={{
                                tableHeadVsb: true, // 헤드 표시 여부
                                styleType: ForestTableStyle, // 테이블 스타일
                                fldInfs: [   // 데이터 필드 setting
                                    {               fldNm: 'lawordSn', pk: true },
                                    { type: 'text', fldNm: 'rowNum'         , width:  58, headAlign: "center", dataAlign: "center", label: 'No.'     },
                                    { type: 'text', fldNm: 'lawordCndNm'    , width: 100, headAlign: "center", dataAlign: "center", label: '조건명'  },
                                    { type: 'text', fldNm: 'lawordCndDc'    , width: 100, headAlign: "center", dataAlign: "center", label: '조건설명'},
                                    { type: 'text', fldNm: 'lawordCndTy'    , width:  70, headAlign: "center", dataAlign: "center", label: '조건유형'},
                                    { type: 'text', fldNm: 'lawordCndColumn', width: 120, headAlign: "center", dataAlign: "center", label: '조건컬럼'},
                                    { type: 'text', fldNm: 'lawordCndCodeTy', width:  70, headAlign: "center", dataAlign: "center", label: '조건코드'},
                                    { type: 'text', fldNm: 'lawordSn'       , width:  58, headAlign: "center", dataAlign: "center", label: '번호'    },
                                    { type: 'text', fldNm: 'lawordDetailSn' , width:  70, headAlign: "center", dataAlign: "center", label: '상세번호'},
                                    { type: 'text', fldNm: 'lawordCndValue1', width: 100, headAlign: "center", dataAlign: "center", label: '조건값1' },
                                    { type: 'text', fldNm: 'lawordCndValue2', width: 100, headAlign: "center", dataAlign: "center", label: '조건값2' },
                                    { type: 'text', fldNm: 'cndDesc'        , width: 190, headAlign: "center", dataAlign: "left"  , label: '조건설명'},
                                    { type: 'el'                            , width:  74, headAlign: "center", el: (item: any) => (
                                        <div className='dpFlx aiC csP' onClick={() => openLawordCndDialog("upd", lawordSn, lawordNm, lawordDetailSn, lawordDetailNm, item.lawordCndDetailSn)}>
                                            <img className="csP pr3" src={require('img/common/icon_edit20.png')} alt="icon_edit20" />
                                            <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">수정</span>
                                        </div>
                                    )},
                                    { type: 'el'                            , width:  74, headAlign: "center", el: (item: any) => (
                                        <div className='dpFlx aiC csP' onClick={() => delLawordCndDetail(lawordSn, lawordDetailSn, item.lawordCndDetailSn)}>
                                            <img className="csP pr3" src={require('img/common/icon_edit20.png')} alt="icon_edit20" />
                                            <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">삭제</span>
                                        </div>
                                    )}
                                ],       // 필드 setting
                                ordFld: { name: "rowNum", direction: "asc" },
                                dataArr: cndDataArr,
                                maxRowNums: [5],   // row 갯수
                                trHeight: 48,
                                remainArea: false,      // 설정 row만큼 height 차지
                                srchVsb: true,          // 검색창
                                headerVsb: true,        // header 표시
                                paginateClass: "pt40"   // page controller 디자인
                            }}/>
                        </div>
                    </div>
                </div>
            </div>
            {/* 법령 Main 추가/수정 Modal */}
            <Modal modalOpen={modalMainOpen} setModalOpen={setModalMainOpen} title={"법령 Main " + (callMode === "ins" ? "추가" : "수정")} dim={true} visible={true}>
                <LawordMainDialog setModalMainOpen={setModalMainOpen} setLawordMainSelTime={setLawordMainSelTime} callMode={callMode} lawordSn={lawordSn} />
            </Modal>

            {/* 법령 Detail 추가/수정 Modal */}
            <Modal modalOpen={modalDetailOpen} setModalOpen={setModalDetailOpen} title={"법령 Detail " + (callMode === "ins" ? "추가" : "수정")} dim={true} visible={true}>
                <LawordDetailDialog setModalDetailOpen={setModalDetailOpen} setLawordDetailSelTime={setLawordDetailSelTime} callMode={callMode} lawordSn={lawordSn} lawordNm={lawordNm} lawordDetailSn={lawordDetailSn} />
            </Modal>

            {/* 법령 상세조건 추가/수정 Modal */}
            <Modal modalOpen={modalCndOpen} setModalOpen={setModalCndOpen} title={"법령 상세 조건 " + (callMode === "ins" ? "추가" : "수정")} dim={true} visible={true}>
                <LawordCndDialog setModalCndOpen={setModalCndOpen} setLawordCndSelTime={setLawordCndSelTime} callMode={callMode} lawordSn={lawordSn} lawordNm={lawordNm} lawordDetailSn={lawordDetailSn} lawordDetailNm={lawordDetailNm} lawordCndDetailSn={lawordCndDetailSn} />
            </Modal>
            
        </React.Fragment>
    )
}
export default LawordMng;