import React from 'react';
import AppContext from 'js/common/AppContext';
import Util from 'js/common/Util';
import ImageRadioBox from 'js/common/ui/input/ImageRadioBox';
import Axios from 'js/common/Axios';
import { useRecoilValue } from 'recoil';
import * as Atom from "js/common/Atom";

interface TrsftaxMrrgQestnProps {
    moveStep: Function
    hshldChbtSptAt: string
}
const TrsftaxMrrgQestn = ({
    moveStep,
    hshldChbtSptAt
}: TrsftaxMrrgQestnProps) => {
    const hshldSn = useRecoilValue(Atom.hshldSn);

    interface QestnProps {
        assetsQestnSn: number
        assetsQestnTy: string
        qestnText: string
        answerTySn: number
        answerCodeTy: string
        answerText: string
        sortOrdr: number
    }
    const [hshldQestnList, setHshldQestnList] = React.useState<QestnProps[]>([]); // 양도세 납세자 질문

    const getAnswerValue = React.useCallback((assetsQestnSn: number) => {
        if (hshldQestnList.length === 0) {
            return "";
        }
        for (const hshldQestnInfo of hshldQestnList) {
            if (hshldQestnInfo.assetsQestnSn === assetsQestnSn) {
                return hshldQestnInfo.answerText; 
            }
        }
        return "";
    }, [hshldQestnList]);

    const setAnswerValue = React.useCallback((assetsQestnSn: number, answerText: string) => {
        const tempHshldQestnList: QestnProps[] = [];
        for (const hshldQestnInfo of hshldQestnList) {
            if (hshldQestnInfo.assetsQestnSn === assetsQestnSn) {
                hshldQestnInfo.answerText = answerText;
            }
            tempHshldQestnList.push(hshldQestnInfo);
        }
        setHshldQestnList(tempHshldQestnList);
    }, [hshldQestnList]);

    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/qestn/selHshldQestnList.do",
            methodType: "post",
            paramType: "json",
            paramData: {
                hshldSn: hshldSn,
                assetsQestnTys: ["1003"]
            },
            onSuccessFn: (res: any) => {
                setHshldQestnList(res.list);
                AppContext.hideSpinner();
            }
        });
    }, [hshldSn]);

    return (
        <div className='w800'>
            <div className='dpFlx aiL flxdCol'>
                {hshldQestnList && hshldQestnList.map((hshldQestnInfo: any, idx: number) => {
                    let viewFg = false;
                    // 혼인합가
                    if (hshldQestnInfo.assetsQestnSn === 60) {
                        viewFg = true;
                    } else if (hshldQestnInfo.assetsQestnSn === 61) {
                        if (getAnswerValue(60) === "Y") {
                            viewFg = true;
                        }
                    } else if ([67, 68].indexOf(hshldQestnInfo.assetsQestnSn) !== -1) {
                        if (getAnswerValue(60) === "Y" && getAnswerValue(61) === "Y") {
                            viewFg = true;
                        }
                    } else if (hshldQestnInfo.assetsQestnSn === 69) {
                        if (getAnswerValue(60) === "Y" && getAnswerValue(61) === "Y" && getAnswerValue(67) === "1001" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(68)) !== -1) {
                            viewFg = true;
                        }
                    } else if (hshldQestnInfo.assetsQestnSn === 70) {
                        if (getAnswerValue(60) === "Y" && getAnswerValue(61) === "Y" && getAnswerValue(67) === "1002" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(68)) !== -1) {
                            viewFg = true;
                        }
                    } else if (hshldQestnInfo.assetsQestnSn === 71) {
                        if (
                            getAnswerValue(60) === "Y" && getAnswerValue(61) === "Y" && getAnswerValue(67) === "1003" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(68)) !== -1) {
                            viewFg = true;
                        }
                    } else if (hshldQestnInfo.assetsQestnSn === 72) {
                        if (getAnswerValue(60) === "Y" && getAnswerValue(61) === "Y" && getAnswerValue(67) === "1003" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(68)) !== -1 && getAnswerValue(71) === "1001") {
                            viewFg = true;
                        }
                    } else if (hshldQestnInfo.assetsQestnSn === 73) {
                        if (getAnswerValue(60) === "Y" && getAnswerValue(61) === "Y" && getAnswerValue(67) === "1003" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(68)) !== -1 && getAnswerValue(71) === "1002") {
                            viewFg = true;
                        }
                    } else if (hshldQestnInfo.assetsQestnSn === 74) {
                        if (getAnswerValue(60) === "Y" && getAnswerValue(61) === "Y" && getAnswerValue(67) === "1004" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(68)) !== -1) {
                            viewFg = true;
                        }
                    } else if (hshldQestnInfo.assetsQestnSn === 75) {
                        if (getAnswerValue(60) === "Y" && getAnswerValue(61) === "Y" && getAnswerValue(67) === "1005" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(68)) !== -1) {
                            viewFg = true;
                        }
                    }
                    if (!viewFg) {
                        return (<div key={idx}></div>)
                    }
                    return (
                        <div key={idx} className={idx === 0 ? '' : 'mt10'}>
                            <span className='fs14 ls07 SpoqaHanSansNeo-Medium fc222222'>{hshldQestnInfo.qestnText}</span>
                            <div className='h40 dpFlx aiC'>
                                <ImageRadioBox
                                    codeList={Util.getCodeArray(hshldQestnInfo.answerCodeTy)}
                                    value={hshldQestnInfo.answerText}
                                    btnClass="w24 h24 pr5"
                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                    interval={20}
                                    onChange={(data: string) => {
                                        setAnswerValue(hshldQestnInfo.assetsQestnSn, data);
                                    }}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="dpFlx w100p jcC mt40">
                <div className="w210 csP dpFlx aiC jcC bgcF6F6F6 h56" onClick={() => {
                    moveStep(1);
                }}>
                    <span className="SpoqaHanSansNeo-Medium fs16 fc222222">이전</span>
                </div>
                <div className="w210 bgc17A840 csP dpFlx aiC jcC h56" onClick={() => {
                    interface qestnAnswersProps {
                        assetsQestnSn: number
                        answerText: string
                    }
                    const qestnAnswers: qestnAnswersProps[] = [];
                    hshldQestnList.forEach((hshldQestnInfo: QestnProps) => {
                        if (
                            hshldQestnInfo.assetsQestnSn === 60 ||
                            (hshldQestnInfo.assetsQestnSn === 61 && getAnswerValue(60) === "Y") ||
                            ([67, 68].indexOf(hshldQestnInfo.assetsQestnSn) !== -1 && getAnswerValue(60) === "Y" && getAnswerValue(61) === "Y") ||
                            (hshldQestnInfo.assetsQestnSn === 69 && getAnswerValue(60) === "Y" && getAnswerValue(61) === "Y" && getAnswerValue(67) === "1001" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(68)) !== -1) ||
                            (hshldQestnInfo.assetsQestnSn === 70 && getAnswerValue(60) === "Y" && getAnswerValue(61) === "Y" && getAnswerValue(67) === "1002" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(68)) !== -1) ||
                            (hshldQestnInfo.assetsQestnSn === 71 && getAnswerValue(60) === "Y" && getAnswerValue(61) === "Y" && getAnswerValue(67) === "1003" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(68)) !== -1) ||
                            (hshldQestnInfo.assetsQestnSn === 72 && getAnswerValue(60) === "Y" && getAnswerValue(61) === "Y" && getAnswerValue(67) === "1003" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(68)) !== -1 && getAnswerValue(71) === "1001") ||
                            (hshldQestnInfo.assetsQestnSn === 73 && getAnswerValue(60) === "Y" && getAnswerValue(61) === "Y" && getAnswerValue(67) === "1003" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(68)) !== -1 && getAnswerValue(71) === "1002") ||
                            (hshldQestnInfo.assetsQestnSn === 74 && getAnswerValue(60) === "Y" && getAnswerValue(61) === "Y" && getAnswerValue(67) === "1004" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(68)) !== -1) ||
                            (hshldQestnInfo.assetsQestnSn === 75 && getAnswerValue(60) === "Y" && getAnswerValue(61) === "Y" && getAnswerValue(67) === "1005" && ["1001", "1002", "1003", "1004", "1005"].indexOf(getAnswerValue(68)) !== -1)
                        ) {
                            if (Util.isEmpty(hshldQestnInfo.answerText)) {
                                AppContext.openAlert({
                                    title: "답변 선택",
                                    msg: "답변을 선택하세요.",
                                    icon: "check"
                                });
                                return;
                            }
                            qestnAnswers.push({assetsQestnSn: hshldQestnInfo.assetsQestnSn, answerText: hshldQestnInfo.answerText});
                        }
                    });
                    Axios.dataAccess({
                        url: "system/qestn/insHshldQestnList.do",
                        methodType: "post",
                        paramType: "json",
                        paramData: {
                            hshldSn: hshldSn,
                            assetsQestnTys: ["1003"],
                            qestnAnswers: qestnAnswers
                        },
                        onSuccessFn: () => {
                            if (hshldChbtSptAt === "Y") {
                                moveStep(3);
                            } else {
                                moveStep(4);
                            }
                        }
                    });
                }}>
                    <span className="SpoqaHanSansNeo-Medium fs16 fcWhite">
                        {hshldChbtSptAt !== "Y" ? "세액 계산하기" : "다음"}
                    </span>
                </div>
            </div>
        </div>
    )
}
export default TrsftaxMrrgQestn;