import Draggable from "react-draggable";
import ModalPortal from "./ModalPortal";
import Util from "js/common/Util";
import { ReactNode } from "react";

interface ModalProps {
    modalOpen: boolean
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    title: string
    dim: boolean
    children: ReactNode
    closeCallBack?: Function
    visible?: boolean
}
function Modal({modalOpen, setModalOpen, title, dim, children, closeCallBack, visible}: ModalProps) {
    if (Util.isEmpty(visible)) {
        visible = true;
    }
    const modalId = "modal_" + Util.getSpecificDate("", "day", 0, "SS", "");

    if (modalOpen) {
        return (
            <ModalPortal>
                <div className={'posF t0 dpFlx zIdx1 w100p h100p jcC aiC ' + (dim && visible ? 'bgcDim' : '')}>
                    <Draggable handle={"#" + modalId} bounds="body">
                        <div className={'bgcWhite bdrd8 pb40 ' + (!visible ? " dpNone" : " dpBlock")}>
                            <div className="dpFlx aiC jcSb pl30 pr30">
                                <div id={modalId} className='w100p h99 aiC jcSb csP bdrdtl8 bdrdtr8 bdBox dpFlx'>
                                    <span className="fcBlack fs23 ls115 SpoqaHanSansNeo-Medium pl10 pt10">{title}</span>
                                </div>
                                <img className="csP" src={require('img/common/btn_close32.png')} alt="right-arrow" onClick={() => {
                                    setModalOpen(false);
                                    if (closeCallBack !== undefined) {
                                        closeCallBack();
                                    }
                                }}/>
                            </div>
                            <div className="pl40 pr40">
                                {children}
                            </div>
                        </div>
                    </Draggable>
                </div>
            </ModalPortal>
        )
    } else {
        return (
            <></>
        )
    }
}
export default Modal;