import React from 'react';
import AppContext from 'js/common/AppContext';
import Util from 'js/common/Util';
import DatePickerBox from "js/common/ui/input/DatePickerBox";
import NumberTextBox from 'js/common/ui/input/NumberTextBox';
import ImageRadioBox from 'js/common/ui/input/ImageRadioBox';

interface TrsftaxInputProps {
    trsfAmt: string
    trsfDe: string | undefined
    isPay: string
    payAmt: string
    ncssryAmtBf: string
    ncssryAmt: string
    setTrsfAmt: React.Dispatch<React.SetStateAction<string>>
    setTrsfDe: React.Dispatch<React.SetStateAction<string | undefined>>
    setIsPay: React.Dispatch<React.SetStateAction<string>>
    setPayAmt: React.Dispatch<React.SetStateAction<string>>
    setNcssryAmtBf: React.Dispatch<React.SetStateAction<string>>
    setNcssryAmt: React.Dispatch<React.SetStateAction<string>>
    estateDetailSe: string
    acqsSrc: string
    acqsCause: string
    moveStep: Function
    hshldMrrgAt: string
    hshldChbtSptAt: string
}
const TrsftaxInput = ({
    trsfAmt,
    trsfDe,
    isPay,
    payAmt,
    ncssryAmtBf,
    ncssryAmt,
    setTrsfAmt,
    setTrsfDe,
    setIsPay,
    setPayAmt,
    setNcssryAmtBf,
    setNcssryAmt,
    estateDetailSe,
    acqsSrc,
    acqsCause,
    moveStep,
    hshldMrrgAt,
    hshldChbtSptAt
}: TrsftaxInputProps) => {
    const FooterEl = () => {
        return (
            <React.Fragment>
                <div className='dpFlx aiC h20 mt10'>
                    <span className='SpoqaHanSansNeo-Regular fs14 ls07'>
                        <span className="fcFF0000 pr2">*</span>
                        <span className='fc666666'>필수 입력 항목입니다.</span>
                    </span>
                </div>
                <div className="dpFlx w100p jcC mt40">
                    <div className="w210 csP dpFlx aiC jcC bgcF6F6F6 h56" onClick={() => moveStep(0)}>
                        <span className="SpoqaHanSansNeo-Medium fs16 fc222222">취소</span>
                    </div>
                    <div className="w210 bgc17A840 csP dpFlx aiC jcC h56" onClick={() => {
                        if (Util.isEmpty(trsfAmt)) {
                            AppContext.openAlert({
                                title: "양도금액 입력",
                                msg: "양도할 자산의 양도금액을 입력하세요.",
                                icon: "check"
                            });
                            return;
                        }
                        if (Util.isEmpty(trsfDe)) {
                            AppContext.openAlert({
                                title: "양도일자 입력",
                                msg: "양도할 자산의 양도일자를 입력하세요.",
                                icon: "check"
                            });
                            return;
                        }
                        if ((estateDetailSe === "1001" && acqsSrc === "1003" && acqsCause === "1001") || (estateDetailSe === "1003" && acqsCause === "1001")) {
                            if (Util.isEmpty(isPay)) {
                                AppContext.openAlert({
                                    title: "청산금납부여부 입력",
                                    msg: "청산금납부여부를 선택하세요.",
                                    icon: "check"
                                });
                                return;
                            }
                        }
                        if (hshldMrrgAt === "Y") {
                            moveStep(2);
                        } else {
                            if (hshldChbtSptAt === "Y") {
                                moveStep(3);
                            } else {
                                moveStep(4);
                            }
                        }
                    }}>
                        <span className="SpoqaHanSansNeo-Medium fs16 fcWhite">
                            {hshldMrrgAt !== "Y" && hshldChbtSptAt !== "Y" ? "세액 계산하기" : "다음"}
                        </span>
                    </div>
                </div>
            </React.Fragment>
        )
    }
    if ((estateDetailSe === "1001" && acqsSrc === "1003" && acqsCause === "1001") || (estateDetailSe === "1003" && acqsCause === "1001")) {
        return (
            <React.Fragment>
                <div className='dpFlx'>
                    <div className='w400'>
                        <div className='dpFlx aiC jcSb mb10'>
                            <span className='fs14 ls07'>
                                <span className='SpoqaHanSansNeo-Medium fc222222'>양도금액(원)</span>
                                <span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
                            </span>
                            <NumberTextBox
                                value={trsfAmt}
                                onChange={(data: string) => setTrsfAmt(data)}
                                borderClass="bgcWhite w230 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                                unitText="원"
                            />
                        </div>
                        <div className='dpFlx aiC jcSb mb10'>
                            <span className='fs14 ls07'>
                                <span className='SpoqaHanSansNeo-Medium fc222222'>양도일자</span>
                                <span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
                            </span>
                            <DatePickerBox value={trsfDe} width={230} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setTrsfDe(Util.dateToString(data, ""))} />
                        </div>
                        <div className='dpFlx aiC jcSb mb10'>
                            <span className='fs14 ls07'>
                                <span className='SpoqaHanSansNeo-Medium fc222222'>종전부동산 필요경비(원)</span>
                            </span>
                            <NumberTextBox
                                value={ncssryAmtBf}
                                onChange={(data: string) => setNcssryAmtBf(data)}
                                borderClass="bgcWhite w230 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                                unitText="원"
                            />
                        </div>
                    </div>
                    <div className='w400 ml30'>
                        <div className='dpFlx aiC jcSb mb10'>
                            <span className='fs14 ls07'>
                                <span className='SpoqaHanSansNeo-Medium fc222222'>청산금납부여부</span>
                            </span>
                            <div className='w230 h40 dpFlx aiC'>
                                <ImageRadioBox
                                    codeList={Util.getCodeArray("1066")}
                                    value={isPay}
                                    btnClass="w24 h24 pr5"
                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                    interval={20}
                                    onChange={(data: string) => {
                                        setIsPay(data);
                                        if (data === "N") {
                                            setPayAmt("");
                                            setNcssryAmtBf("");
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className='dpFlx aiC jcSb mb10'>
                            <span className='fs14 ls07'>
                                <span className='SpoqaHanSansNeo-Medium fc222222'>청산금액(원)</span>
                            </span>
                            <NumberTextBox
                                value={payAmt}
                                onChange={(data: string) => setPayAmt(data)}
                                borderClass="bgcWhite w230 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                                unitText="원"
                            />
                        </div>
                        <div className='dpFlx aiC jcSb mb10'>
                            <span className='fs14 ls07'>
                                <span className='SpoqaHanSansNeo-Medium fc222222'>조합원입주권 필요경비(원)</span>
                            </span>
                            <NumberTextBox
                                value={ncssryAmt}
                                onChange={(data: string) => setNcssryAmt(data)}
                                borderClass="bgcWhite w230 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                                unitText="원"
                            />
                        </div>
                    </div>
                </div>
                {FooterEl()}
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                <div className='dpFlx'>
                    <div className='w400'>
                        <div className='dpFlx aiC jcSb mb10'>
                            <span className='fs14 ls07'>
                                <span className='SpoqaHanSansNeo-Medium fc222222'>양도금액(원)</span>
                                <span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
                            </span>
                            <NumberTextBox
                                value={trsfAmt}
                                onChange={(data: string) => setTrsfAmt(data)}
                                borderClass="bgcWhite w230 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                                unitText="원"
                            />
                        </div>
                        <div className='dpFlx aiC jcSb mb10'>
                            <span className='fs14 ls07'>
                                <span className='SpoqaHanSansNeo-Medium fc222222'>양도일자</span>
                                <span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span>
                            </span>
                            <DatePickerBox value={trsfDe} width={230} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setTrsfDe(Util.dateToString(data, ""))} />
                        </div>
                        <div className='dpFlx aiC jcSb mb10'>
                            <span className='fs14 ls07'>
                                <span className='SpoqaHanSansNeo-Medium fc222222'>필요경비(원)</span>
                            </span>
                            <NumberTextBox
                                value={ncssryAmt}
                                onChange={(data: string) => setNcssryAmt(data)}
                                borderClass="bgcWhite w230 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                                unitText="원"
                            />
                        </div>
                    </div>
                </div>
                {FooterEl()}
            </React.Fragment>
        )
    }
}
export default TrsftaxInput;