/*******************************************************************************
*** InheTaxInfoTwo ** 상속세 세율 산출 정보 2단계 입력
*******************************************************************************/
import Util              from "js/common/Util";
import ImageRadioBox     from "js/common/ui/input/ImageRadioBox";
import NumberTextBox     from "js/common/ui/input/NumberTextBox";
import Modal             from "js/common/ui/modal/Modal";
import React             from "react";
import InhePersonalDedu  from "./InhePersonalDedu";
import InheFinanInheDedu from "./InheFinanInheDedu";
import SelectBox         from "js/common/ui/input/SelectBox";
import ToolTip           from "js/common/ui/ToolTip";
import AppContext        from "js/common/AppContext";
import AssetsUtil        from "js/common/AssetsUtil";

interface InheTaxCalcProps {
    moveStep: Function
    
    spouse           : string   // 04. 배우자 유무
    childNumber      : string   // 11. 자녀수
    deduCls          : string   // 15. 공제구분
    personDedu       : string   // 16. 인적공제
    spouseDisabled   : string   // 17. 배우자 장애인 여부
    spouseGender     : string   // 18. 배우자 성별
    spouseAge        : string   // 19. 배우자 나이
    spouseInherProp  : string   // 20. 배우자 상속재산가액
    spouseSucUtilDebt: string   // 21. 배우자 승계 공과금&채무
    directHeir       : string   // 22. 직계존속 상속인 유무
    familyDedu       : string   // 23. 기업상속공제
    farmDedu         : string   // 24. 영농상속공제
    houseDedu        : string   // 25. 동거주택상속공제
    finanDedu        : string   // 26. 금융상속공제

    setDeduCls          : React.Dispatch<React.SetStateAction<string>>
    setPersonDedu       : React.Dispatch<React.SetStateAction<string>>
    setSpouseDisabled   : React.Dispatch<React.SetStateAction<string>>
    setSpouseGender     : React.Dispatch<React.SetStateAction<string>>
    setSpouseAge        : React.Dispatch<React.SetStateAction<string>>
    setSpouseInherProp  : React.Dispatch<React.SetStateAction<string>>
    setSpouseSucUtilDebt: React.Dispatch<React.SetStateAction<string>>
    setDirectHeir       : React.Dispatch<React.SetStateAction<string>>
    setFamilyDedu       : React.Dispatch<React.SetStateAction<string>>
    setFarmDedu         : React.Dispatch<React.SetStateAction<string>>
    setHouseDedu        : React.Dispatch<React.SetStateAction<string>>
    setFinanDedu        : React.Dispatch<React.SetStateAction<string>>    

}

const InheTaxInfoTwo = ({ 
      moveStep
    , spouse              // 04. 배우자 유무  
    , childNumber         // 11. 자녀수  
    , deduCls             // 15. 공제구분
    , personDedu          // 16. 인적공제
    , spouseDisabled      // 17. 배우자 장애인 여부
    , spouseGender        // 18. 배우자 성별
    , spouseAge           // 19. 배우자 나이
    , spouseInherProp     // 20. 배우자 상속재산가액
    , spouseSucUtilDebt   // 21. 배우자 승계 공과금&채무
    , directHeir          // 22. 직계존속 상속인 유무
    , familyDedu          // 23. 기업상속공제
    , farmDedu            // 24. 영농상속공제
    , houseDedu           // 25. 동거주택상속공제
    , finanDedu           // 26. 금융상속공제

    , setDeduCls          
    , setPersonDedu       
    , setSpouseDisabled   
    , setSpouseGender     
    , setSpouseAge        
    , setSpouseInherProp  
    , setSpouseSucUtilDebt
    , setDirectHeir       
    , setFamilyDedu       
    , setFarmDedu         
    , setHouseDedu        
    , setFinanDedu          
}: InheTaxCalcProps) => {
   
    // 입력항목 표시 여부
    const [visible16  , setVisible16  ] = React.useState<boolean>(false); // 16. 인적공제
    const [visible16_1, setVisible16_1] = React.useState<boolean>(false); // 16-1. 기초공제
    const [visible17  , setVisible17  ] = React.useState<boolean>(false); // 17. 배우자 장애인 여부
    const [visible18  , setVisible18  ] = React.useState<boolean>(false); // 18. 배우자 성별
    const [visible19  , setVisible19  ] = React.useState<boolean>(false); // 19. 배우자 나이
    const [visible20  , setVisible20  ] = React.useState<boolean>(false); // 20. 배우자 상속재산가액
    const [visible21  , setVisible21  ] = React.useState<boolean>(false); // 21. 배우자 승계 공과금 & 채무액
    const [visible22  , setVisible22  ] = React.useState<boolean>(false); // 22. 직계존속 상속인 유무

    // 입력항목 disabled
    const [disabled15, setDisabled15] = React.useState<boolean>(false); // 15. 공제구분

    // 기초공제
    const basicDedu = "20,000,000";

    const [personalDeduModalOpen , setPersonalDeduModalOpen ] = React.useState<boolean>(false); // 인적공제 계산 모달
    const [finanInheDeduModalOpen, setFinanInheDeduModalOpen] = React.useState<boolean>(false); // 금융상속공제 계산 모달


    // 보유 자산 리스트 조회
    React.useEffect(() => {
        if (spouse === "Y") {
            if (childNumber === "0") {    // 배우자만 있는 경우 인적공제를 적용
                setDeduCls("1002"); // 기초공제 + 인적공제
                setDisabled15(true);

                setVisible17(true); // 17. 배우자 장애인 여부
                setVisible20(true); // 20. 배우자 상속재산가액
                setVisible22(true); // 22. 직계존속 상속인 유무
            } else {    // 배우자 & 자녀가 있는 경우
                setVisible20(true); // 20. 배우자 상속재산가액
            }
        } else if (spouse === "N") {
            if (childNumber === "0") { // 배우자 & 자녀가 없는 경우
                setDeduCls("1001" ); // 일괄공제
                setDisabled15(true);
            }  
        } 

        // 기초공제 + 인적공제 ▶ 16.인적공제
        if (deduCls === "1002") {  
            if (spouse === "Y" && childNumber === "0") {    // 배우자만 있는 경우 기초공제 표시
                setVisible16_1(true);
            } else {
                setVisible16(true);
            }            
        }    
        
        // 배우자 장애인 예 ▶ 18.배우자 성별, 19.배우자 나이 
        if (spouseDisabled === "Y") {     
            setVisible18(true);
            setVisible19(true);
        }                                    
        // 배우자 상속재산가액 5억 이상 ▶ 21.배우자 승계 공과금 & 채무
        if (Util.isNotEmpty(spouseInherProp) && Number(Util.uncomma(spouseInherProp)) >= 500000000) {    
            setVisible21(true);
        }

    }, [spouse, setDeduCls, childNumber, deduCls, spouseDisabled, spouseInherProp]);


    // 금융상속공제 금액 계산 callback
    const onFinishCalFin = (item: any) => {
        setFinanDedu(item.calFinDedu);
    }

    // 인적공제 계산금액 callback
    const onFinishCalPerson = (item: any) => {
        setPersonDedu(item.calPersonDedu);
    }
    
    // 상속세 계산하기 
    const selGittaxCalc = () => {
        // 입력 값 확인 메시지 처리
        if (Util.isEmpty(deduCls)) {AppContext.openAlert({title: "공제구분"      , msg: "공제구분을 선택하세요."      , icon: "check"}); return;}
        if (visible16) {
            if (Util.isEmpty(personDedu)) {AppContext.openAlert({title: "인적공제", msg: "인적공제 금액을 계산하세요.", icon: "check"}); return;}
        }
        if (visible17) {if (Util.isEmpty(spouseDisabled)) {AppContext.openAlert({title: "배우자 장애인 여부", msg: "상속받는 배우자의 장애인 여부를 선택하세요.", icon: "check"}); return;}}
        if (visible18) {if (Util.isEmpty(spouseGender  )) {AppContext.openAlert({title: "배우자 성별"       , msg: "상속받는 배우자의 성별을 선택하세요."       , icon: "check"}); return;}}
        if (visible19) {
            if (Util.isEmpty(spouseAge)) {AppContext.openAlert({title: "배우자 나이", msg: "상속받는 배우자 나이를 입력하세요.", icon: "check"}); return;}
            if (Number(spouseAge) <= 0 ) {AppContext.openAlert({title: "배우자 나이", msg: "상속받는 배우자 나이를 입력하세요.", icon: "check"}); return;}
        }
        if (visible20) {
            if (Util.isEmpty(spouseInherProp)) {AppContext.openAlert({title: "배우자 상속재산가액", msg: "배우자가 상속받은 상속재산가액을 입력하세요.", icon: "check"}); return;}
            if (Number(spouseInherProp) < 0  ) {AppContext.openAlert({title: "배우자 상속재산가액", msg: "배우자가 상속받은 상속재산가액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible21) {
            if (Util.isEmpty(spouseSucUtilDebt)) {AppContext.openAlert({title: "배우자 승계 공과금&채무액", msg: "상속받는 배우자가 승계한 공과금 및 채무금액를 입력하세요.", icon: "check"}); return;}
            if (Number(spouseSucUtilDebt) < 0  ) {AppContext.openAlert({title: "배우자 승계 공과금&채무액", msg: "상속받는 배우자가 승계한 공과금 및 채무금액를 입력하세요.", icon: "check"}); return;}
        }
        if (visible22) {
            if (Util.isEmpty(directHeir)) {AppContext.openAlert({title: "직계존속 상속인 유무", msg: "직계존속 상속인 유무를 선택하세요.", icon: "check"}); return;}
        }

        if (Util.isEmpty(familyDedu)) {AppContext.openAlert({title: "가업상속공제"    , msg: "기업상속공제 금액을 입력하세요."    , icon: "check"}); return;}
        if (Number(familyDedu) < 0  ) {AppContext.openAlert({title: "가업상속공제"    , msg: "기업상속공제 금액을 입력하세요."    , icon: "check"}); return;}
        if (Util.isEmpty(farmDedu  )) {AppContext.openAlert({title: "영농상속공제"    , msg: "영농상속공제 금액을 입력하세요."    , icon: "check"}); return;}
        if (Number(farmDedu) < 0    ) {AppContext.openAlert({title: "영농상속공제"    , msg: "영농상속공제 금액을 입력하세요."    , icon: "check"}); return;}
        if (Util.isEmpty(houseDedu )) {AppContext.openAlert({title: "동거주택상속공제", msg: "동거주택상속공제 금액을 입력하세요.", icon: "check"}); return;}
        if (Number(houseDedu) < 0   ) {AppContext.openAlert({title: "동거주택상속공제", msg: "동거주택상속공제 금액을 입력하세요.", icon: "check"}); return;}
        if (Util.isEmpty(finanDedu )) {AppContext.openAlert({title: "금융상속공제"    , msg: "금융상속공제 금액을 입력하세요."    , icon: "check"}); return;}
        if (Number(finanDedu) < 0   ) {AppContext.openAlert({title: "금융상속공제"    , msg: "금융상속공제 금액을 입력하세요."    , icon: "check"}); return;}

        // 배우자 단독인 경우 배우자 장애인 인적공제 계산
        if (spouse === "Y" && childNumber === "0") {    // 배우자 단독상속
            if (spouseDisabled === "Y") { 
                if (spouseGender === "1001") { // 남
                    let lifeExpectMan = AssetsUtil.getLifeExpectMan(spouseAge);
                    setPersonDedu((parseInt(lifeExpectMan) * 10000000).toString());
                } if (spouseGender === "1002") {    // 여
                    let lifeExpectWom = AssetsUtil.getLifeExpectWom(spouseAge);
                    setPersonDedu((parseInt(lifeExpectWom) * 10000000).toString());
                }
            }
        }        

        moveStep(3);
    }

    return (
        <React.Fragment>
            <div className='w870'>
                <div className="h20 mt30 dpFlx aiC">
                    <span className="SpoqaHanSansNeo-Medium fs18 ls09 fc222222">◎ 상속세 산출 정보 입력</span>
                </div>

                {/************************** 왼쪽 부분 **************************/}    
                <div className="w50p float-l pr10 bdBox pl10">  
                    {/* 15. 공제구분 */}
                    <div className={(true ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>공제구분</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <SelectBox
                                value={deduCls}
                                codeArray={Util.getCodeArray("1075")}
                                placeholder="선택해주세요"
                                width={230}
                                height={40}
                                fontSize={15}
                                paddingRight={5}
                                disabled={disabled15}
                                border="1px solid #dddddd"
                                onChangeFunc={(data: string) => {
                                    setDeduCls(data);
                                    if (data === "1001") {         // 일괄공제
                                        setVisible16(false);
                                        setPersonDedu(""  );
                                    } else if (data === "1002") {  // 기초공제 + 인적공제 ▶ 16.인적공제
                                        setVisible16(true);
                                    } else {}
                                }}
                            />                        
                        </div>
                    </div> 

                    {/* 16. 인적공제 */}
                    <div className={(visible16 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>인적공제</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <NumberTextBox
                                value={personDedu}
                                onChange={(data: string) => setPersonDedu(data)}
                                borderClass="bgcWhite w170 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                disabled={true}
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitText="원"
                            />
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <div className="w60 h35 bgc17A840 bdrd40 dpFlx jcC aiC csP" onClick={() => {
                                setPersonalDeduModalOpen(true);
                            }}>
                                <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>계산</span>
                            </div>
                        </div>
                    </div> 

                    {/* 16-1. 기초공제 */}
                    <div className={(visible16_1 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>기초공제</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <NumberTextBox
                                value={basicDedu}
                                // onChange={(data: string) => setBasicDedu(data)}
                                borderClass="bgcWhite w230 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                disabled={true}
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitText="원"
                            />
                        </div>
                    </div> 

                    {/* 17. 배우자 장애인 여부 */}
                    <div className={(visible17 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>배우자 장애인 여부</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <ImageRadioBox
                                codeList={Util.getCodeArray("1036")}
                                value={spouseDisabled}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                                onChange={(data: string) => {       
                                    setSpouseDisabled(data);

                                    if (data === "Y") {     // 배우자 장애인 예 ▶ 18.배우자 성별, 19.배우자 나이
                                        setVisible18(true);
                                        setVisible19(true);
                                    } else if (data === "N") {  // 배우자 장애인 아니오
                                        setVisible18(false); // 18.배우자 성별 미표시
                                        setSpouseGender(""); // 18.배우자 성별 초기화
                                        setVisible19(false); // 19.배우자 나이 미표시
                                        setSpouseAge(""   ); // 19.배우자 나이 초기화
                                    } else {}
                                }}
                            />
                        </div>
                    </div> 

                    {/* 18. 배우자 성별 */}
                    <div className={(visible18 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>배우자 성별</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <ImageRadioBox
                                codeList={Util.getCodeArray("1002")}
                                value={spouseGender}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                                onChange={(data: string) => {  
                                    setSpouseGender(data);
                                }}
                            />
                        </div>
                    </div> 

                    {/* 19. 배우자 나이 */}
                    <div className={(visible19 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>배우자 나이</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <NumberTextBox
                                value={spouseAge}
                                onChange={(data: string) => {
                                    setSpouseAge(data);
                                }}
                                borderClass="bgcWhite w230 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={2}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitText="세"
                            />
                        </div>
                    </div> 

                    {/* 20. 배우자 상속재산가액 */}
                    <div className={(visible20 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w153 h40 bdrd4 dpFlx jcL aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>
                                <div className="dpFlx aiC jcC">배우자 상속재산가액
                                    <img
                                        src={require('img/common/icon_info_fill_20.png')}
                                        alt="icon_info_fill_20"
                                        className='mr3 csP'
                                        data-tooltip-id="disabilityToolTip"
                                        data-tooltip-content={
                                            "사전증여재산가액 및 추정상속재산가액 제외"
                                        }
                                    />
                                    <ToolTip id="disabilityToolTip" place='top' clickable={true} className="w300 taL lh20" />
                                </div>
                            </span>
                        </div>
                        <div className='dpFlx aiC jcC ml8 bgcWhite'>
                            <NumberTextBox
                                value={spouseInherProp}
                                onChange={(data: string) => {
                                    setSpouseInherProp(data);
                                    
                                    // 배우자 상속재산가액 5억 이상 ▶ 21.배우자 승계 공과금 & 채무
                                    if (Util.isNotEmpty(data) && Number(Util.uncomma(data)) >= 500000000) {    
                                        setVisible21(true);
                                    } else {    // 배우자 상속재산가액 5억 미만
                                        setVisible21(false     ); // 21.배우자 승계 공과금 & 채무 미표시
                                        setSpouseSucUtilDebt(""); // 21.배우자 승계 공과금 & 채무 초기화
                                    }
                                }}
                                borderClass="bgcWhite w230 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitText="원"
                            />
                        </div>
                    </div>

                    {/* 21. 배우자 승계 공과금&채무 */}
                    <div className={(visible21 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w153 h40 bdrd4 dpFlx jcL aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>배우자 승계 공과금&채무</span>
                        </div>
                        <div className='dpFlx aiC jcC ml8 bgcWhite'>
                            <NumberTextBox
                                value={spouseSucUtilDebt}
                                onChange={(data: string) => {
                                    setSpouseSucUtilDebt(data);
                                }}
                                borderClass="bgcWhite w230 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitText="원"
                            />
                        </div>
                    </div>
                </div>

                {/************************** 오른쪽 부분 **************************/}
                <div className="w50p float-r pl20 bdBox">
                    
                    {/* 23. 가업상속공제 */}
                    <div className={(true ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>가업상속공제</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <NumberTextBox
                                value={familyDedu}
                                onChange={(data: string) => setFamilyDedu(data)}
                                borderClass="bgcWhite w230 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitText="원"
                            />
                        </div>
                    </div>

                    {/* 24. 영농상속공제 */}
                    <div className={(true ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>영농상속공제</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <NumberTextBox
                                value={farmDedu}
                                onChange={(data: string) => setFarmDedu(data)}
                                borderClass="bgcWhite w230 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitText="원"
                            />
                        </div>
                    </div>

                    {/* 25. 동거주택상속공제 */}
                    <div className={(true ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>동거주택상속공제</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <NumberTextBox
                                value={houseDedu}
                                onChange={(data: string) => setHouseDedu(data)}
                                borderClass="bgcWhite w230 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitText="원"
                            />
                        </div>
                    </div>

                    {/* 26. 금융상속공제 */}
                    <div className={(true ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>금융상속공제</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <NumberTextBox
                                value={finanDedu}
                                onChange={(data: string) => setFinanDedu(data)}
                                borderClass="bgcWhite w170 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                disabled={true}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitText="원"
                            />
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <div className="w60 h35 bgc17A840 bdrd40 dpFlx jcC aiC csP" onClick={() => {
                                setFinanInheDeduModalOpen(true);
                            }}>
                                <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>계산</span>
                            </div>
                        </div>                    
                    </div>
                </div>  

                {/* 22. 직계존속 상속인 유무 */}
                <div className={(visible22 ? "" : "dpNone-i") + " w80p dpFlx aiC jcL pt10 pl10"}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>직계존속 상속인 유무</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <ImageRadioBox
                            codeList={Util.getCodeArray("1076")}
                            value={directHeir}
                            btnClass="w24 h24 pr5"
                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                            interval={20}
                            onChange={(data: string) => {  
                                setDirectHeir(data);
                            }}
                        />                        
                    </div>
                </div>                               
            </div>

            <div className="dpFlx w100p jcC pt40">
                <div className="w210 csP dpFlx aiC jcC bgcF6F6F6 h56" onClick={() => moveStep(1)}>
                    <span className="SpoqaHanSansNeo-Medium fs16 fc222222">이전</span>
                </div>
                <div className="w210 bgc17A840 csP dpFlx aiC jcC h56" onClick={() => {
                    selGittaxCalc();
                }}>
                    <span className="SpoqaHanSansNeo-Medium fs16 fcWhite">상속세 계산하기</span>
                </div>
            </div>              

            {/* 인적공제 계산 모달 */}
            <Modal modalOpen={personalDeduModalOpen} setModalOpen={setPersonalDeduModalOpen} title="인적공제 계산" dim={true} visible={true}>
                <InhePersonalDedu setPersonalDeduModalOpen={setPersonalDeduModalOpen} childNumber={childNumber} callBack={onFinishCalPerson} />
            </Modal>

            {/* 금융상속공제 계산 모달 */}
            <Modal modalOpen={finanInheDeduModalOpen} setModalOpen={setFinanInheDeduModalOpen} title="금융상속공제 계산" dim={true} visible={true}>
                <InheFinanInheDedu setFinanInheDeduModalOpen={setFinanInheDeduModalOpen} callBack={onFinishCalFin} />
            </Modal>            
        </React.Fragment>    
    )
}
export default InheTaxInfoTwo;