import React from "react";

interface Qestn56ModalProps {
    setQestn56Open: React.Dispatch<React.SetStateAction<boolean>>
}
const Qestn56Modal = ({setQestn56Open}: Qestn56ModalProps) => {
    return (
        <div className="w850">
            <span className="fs15 ls06 fm-SpoqaHanSansNeo-Regular">
                분양권에 의하여 취득하는 주택의 경우에는 주택분양권의 취득일(분양사업자로부터 주택분양권을 취득하는 경우에는 분양계약일)을
                기준으로 세대별 주택 수를 산정한다.(지방세법시행령 제28조의 4)
                취득세의 산정기준일 주택 수는 다음과 같이 계산한다.<br/><br/>
                주택분양권과 조합원입주권은 주택 수에 포함된다.(지방세법 제13조의 3 제2호)
            </span>
            <table className={
                'forest-list-table bdt1 bdcDDDDDD tr-h36 w100p mt20 ' +
                'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075 td-p10'
            }>
                <colgroup>
                    <col className='w40p'></col>
                    <col className='w60p'></col>
                </colgroup>
                <tbody>
                    <tr>
                        <th colSpan={2}>조택 조합원입주권과 주택 분양권의 취득일</th>
                    </tr>
                    <tr>
                        <td>2020.08.11. 이전</td>
                        <td>2020.08.12. 이후 </td>
                    </tr>
                    <tr>
                        <td rowSpan={2}>주택 수 불포함</td>
                        <td>주택 수 포함</td>
                    </tr>
                    <tr>
                        <td className="bdb1">2020.08.11. 이전에 매매계약을 체결한 경우에는 주택 수 불포함</td>
                    </tr>
                </tbody>
            </table>
            <div className='w100p dpFlx aiC jcC pt40'>
                <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => setQestn56Open(false)}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>닫기</span>
                </div>
            </div>
        </div>
    )
}
export default Qestn56Modal;