/***********************************************************************************
*** Law4 ** 소득세법 시행령 제155조 제20항 라목(거주주택 비과세) Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law4Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}

export default function Law4({ setModalOpen, setLawModalTitle, callBack, callMode }: Law4Props) {
    React.useEffect(() => {
        setLawModalTitle("소득세법 시행령 제155조 제20항 라목(거주주택 비과세)");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className='w100p hAuto ofA mb30'>
                <table className={
                    'forest-list-table bdt1 bdb1 bdcDDDDDD ' +
                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075'
                }>
                    <colgroup>
                        <col className='w70'></col>
                        <col className='w130'></col>
                        <col className='w160'></col>
                        <col className='w160'></col>
                        <col className='w100'></col>
                        <col className='w130'></col>
                        <col className='w90'></col>
                        <col className='w90'></col>
                        <col className='w130'></col>
                        <col className='w170'></col>
                        <col className='w170'></col>
                        <col className='w140'></col>
                    </colgroup>
                    <tbody>
                        <tr className='h40'>
                            <th rowSpan={2}>구분</th>
                            <th rowSpan={2}>주택구분</th>
                            <th rowSpan={2}>주택규모</th>
                            <th rowSpan={2}>취득기간</th>
                            <th rowSpan={2}>지역기준</th>
                            <th rowSpan={2}>가액기준</th>
                            <th colSpan={2}>등록</th>
                            <th rowSpan={2}>민특법 분류</th>
                            <th rowSpan={2}>임대기간</th>
                            <th colSpan={2}>추가질문</th>
                        </tr>
                        <tr className='h29'>
                            <th>지자체</th>
                            <th>세무서</th>
                            <th>임대호수</th>
                            <th>필수서류</th>
                        </tr>
                        <tr className='h110'>
                            <th>개별사항</th>
                            <td>분양권에 의한<br/>아파트 중<br/>미분양</td>
                            <td>대지 298㎡,<br/>주택면적 149㎡<br/>(단독주택은 연면적, 공동주택은 전용면적)</td>
                            <td>2008.6.11.~2009.6.30.<br/>까지 최초로<br/>분양계약 + 계약금 납부</td>
                            <td>비수도권</td>
                            <td>취득당시<br/>기준시가<br/>3억원 이하</td>
                            <td colSpan={2}>2018.3.31. 까지<br/>등록한 주택에 한함</td>
                            <td>매입임대주택</td>
                            <td>5년 이상 임대 또는<br/>분양전환(임대 사업자에게 매각 포함)</td>
                            <td>같은 시, 군 5호 이상(매입임대주택,기존 매입임대주택과 합산하여 5호 이상)</td>
                            <td>미분양주택 확인서 사본 및 지자체날인 매매계약서</td>
                        </tr>
                        <tr className='h70'>
                            <th>기타사항</th>
                            <td className='taL pt15 pb15 pl15'colSpan={11}>
                                1. 자동말소 : 2020.8.18. 이후 자동말소 시 임대기간요건을 갖춘 것으로 본다. 단기임대주택과 장기임대주택 중 아파트, 거주주택을 등록말소 후 5년 이내 양도시 비과세<br/>
                                2. 자진말소 : 2020.8.18. 이후 자진말소 시 말소 후 5년 이내 양도시 비과세(민특법상 의무임대기간 1/2 이상 임대한 경우에 한함), 단기임대주택과 장기임대주택 중 아파트<br/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}