/*******************************************************************************
*** BasicInfo ** 모바일 증여세 기본정보 입력
*******************************************************************************/
import styled                      from "styled-components";
import { NumericFormat }           from "react-number-format";
import { ChangeEvent }             from "react";
import Util                        from "js/common/Util";
import RealEstateItem              from "js/mobile/common/RealEstateItem";
import { GiftTaxAction }           from "./giftTaxStatesReducer";
import SelectBox, { SelectOption } from "../common/SelectBox";
import { GiftTaxParamProps, GiftTaxParamVisible, Code, GiftTaxParamDisabled } from "js/common/types/types";
import { MainSectionContainer, InputContainer, NumberInputContainer }         from "js/mobile/common/layout";

interface RealDonorType {
    estateSe: string;
    name    : string;
    desc    : string;
}

// 증여자 설명
const realDonorDesc: Record<string, string> = {
    "201": "남편 또는 아내",
    "202": "부모 또는 조부모",
    "203": "자녀 또는 손자녀",
    "204": "6촌이내 혈족, 4촌 이내 인척"
};

type Props = {
    inputValue   : GiftTaxParamProps;
    inputVisible : GiftTaxParamVisible;
    inputDisabled: GiftTaxParamDisabled;
    onUpdateInput: (action: GiftTaxAction) => void;
};

function BasicInfo({ inputValue, inputVisible, inputDisabled, onUpdateInput }: Props) {
    // 증여자 설명 Mapping
    const realDonorTypes: RealDonorType[] = Util.getCodeArray("2025").map(
        (code: Code): RealDonorType => ({
            estateSe: code.code,
            name    : code.codeNm,
            desc    : realDonorDesc[code.code],
        })
    );

    // 01. 증여자
    const handleRealEstateSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        onUpdateInput({
        type: "UPDATED_DONOR",
            payload: event.target.value,
        });
    };

    // 01. 증여자
    const handleClickRealEsateItem = (selected: string) => {
        onUpdateInput({
            type: "UPDATED_DONOR",
            payload: selected,
        });
    };

    // 02. 증여재산가액
    const handleChangeGiftProp = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
    
        const valueWithoutComma = inputValue.replace(/,/g, "");
        onUpdateInput({
            type: "UPDATED_GIFTPROP",
            payload: valueWithoutComma,
        });
    };

    // 03. 채무인수 여부  
    const handleSelectDebtAssum = (selected: SelectOption) => {
        onUpdateInput({
            type: "UPDATED_DEBTASSUM",
            payload: selected.value,
        });
    };

    // 04. 채무액
    const handleChangeDebtAmt = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
    
        const valueWithoutComma = inputValue.replace(/,/g, "");
        onUpdateInput({
            type: "UPDATED_DEBTAMT",
            payload: valueWithoutComma,
        });
    };    
    
    // 05. 증여자 사전증여 유무 
    const handleSelectPreGift = (selected: SelectOption) => {
        onUpdateInput({
            type: "UPDATED_PREGIFT",
            payload: selected.value,
        });
    };

    // 06. 사전증여 재산가액
    const handleChangePreGiftProp = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
    
        const valueWithoutComma = inputValue.replace(/,/g, "");
        onUpdateInput({
            type: "UPDATED_PREGIFTPROP",
            payload: valueWithoutComma,
        });
    };    

    // 07. 사전증여세액
    const handleChangePreGiftTax = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
    
        const valueWithoutComma = inputValue.replace(/,/g, "");
        onUpdateInput({
            type: "UPDATED_PREGIFTTAX",
            payload: valueWithoutComma,
        });
    };

    return (
        <MainSectionContainer>
            <p className="desc">
                <span>증여할 자산의</span>
                <span className="bold"> 기초정보</span>
                <span>를 선택해주세요.</span>
            </p>

            {/* 01. 증여자 */}
            {inputVisible.visible01 && (
                <RealEstateList>
                <p className="label">증여자</p>
                {realDonorTypes.map((type: RealDonorType) => {
                    return (
                        <RealEstateItem
                            name={type.name}
                            estateSe={type.estateSe}
                            desc={type.desc}
                            selectedRealEstate={inputValue.donor}
                            onRealEstateSelect={handleRealEstateSelect}
                            onClickItem={handleClickRealEsateItem}
                            key={type.estateSe}
                        />
                    );
                })}
                </RealEstateList>
            )}

            {/* 02. 증여 재산가액  */}
            {inputVisible.visible02 && (
                <InputContainer>
                    <p className="label">증여 재산가액 </p>
                    <div className="input">
                    <NumberInputContainer
                    $filled={inputValue.giftProp === "" ? false : true}
                    >
                    <div className="inputWrap">
                        <NumericFormat
                            value={inputValue.giftProp}
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            onChange={(e) => {
                                if (handleChangeGiftProp === undefined) {
                                    return;
                                }
                                handleChangeGiftProp(e);
                            }}
                            placeholder="증여 재산가액을 입력해주세요."
                            className="numberInput"
                            name="giftProp"
                        />
                        <p className="unit">원</p>
                    </div>
                    </NumberInputContainer>
                </div>
                </InputContainer>
            )}

            {/* 03. 채무인수 여부 */}
            {inputVisible.visible03 && (
                <InputContainer>
                    <p className="label">채무인수 여부</p>
                    <div className="input">
                        <SelectBox
                            placeholder="채무인수 여부를 선택해주세요."
                            options={Util.getCodeArray("2026").map((code: Code) => ({
                                value: code.code,
                                label: code.codeNm,
                            }))}
                            selectedValue={inputValue.debtAssum}
                            handleSelect={handleSelectDebtAssum}
                        />
                    </div>
                </InputContainer>
            )}

            {/* 04. 채무액  */}
            {inputVisible.visible04 && (
                <InputContainer>
                    <p className="label">채무액</p>
                    <div className="input">
                        <NumberInputContainer $filled={inputValue.debtAmt === "" ? false : true}>
                            <div className="inputWrap">
                                <NumericFormat
                                    value={inputValue.debtAmt}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={15}
                                    onChange={(e) => {
                                        if (handleChangeDebtAmt === undefined) {
                                            return;
                                        }
                                        handleChangeDebtAmt(e);
                                    }}
                                    placeholder="채무액을 입력해주세요."
                                    className="numberInput"
                                    name="debtAmt"
                                />
                                <p className="unit">원</p>
                            </div>
                        </NumberInputContainer>
                    </div>
                </InputContainer>
            )}

            {/* 05. 증여자 사전증여 유무 */}
            {inputVisible.visible05 && (
                <InputContainer>
                    <p className="label">증여자 사전증여 유무</p>
                    <div className="input">
                        <SelectBox
                            placeholder="증여자 사전증여 유무를 선택해주세요."
                            options={Util.getCodeArray("2027").map((code: Code) => ({
                                value: code.code,
                                label: code.codeNm,
                            }))}
                            selectedValue={inputValue.preGift}
                            handleSelect={handleSelectPreGift}
                        />
                    </div>
                    <div className="help">10년 이내 동일한 증여자로부터 받은 사전증여</div>
                </InputContainer>
            )}

            {/* 06. 사전증여 재산가액  */}
            {inputVisible.visible06 && (
                <InputContainer>
                    <p className="label">사전증여 재산가액</p>
                    <div className="input">
                        <NumberInputContainer $filled={inputValue.preGiftProp === "" ? false : true}>
                            <div className="inputWrap">
                                <NumericFormat
                                    value={inputValue.preGiftProp}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={15}
                                    onChange={(e) => {
                                        if (handleChangePreGiftProp === undefined) {
                                            return;
                                        }
                                        handleChangePreGiftProp(e);
                                    }}
                                    placeholder="사전증여 재산가액을 입력해주세요."
                                    className="numberInput"
                                    name="preGiftProp"
                                />
                                <p className="unit">원</p>
                            </div>
                        </NumberInputContainer>
                    </div>
                </InputContainer>
            )}

            {/* 07. 사전증여세액 */}
            {inputVisible.visible06 && (
                <InputContainer>
                    <p className="label">사전증여세액</p>
                    <div className="input">
                        <NumberInputContainer $filled={inputValue.preGiftTax === "" ? false : true}>
                            <div className="inputWrap">
                                <NumericFormat
                                    value={inputValue.preGiftTax}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={15}
                                    onChange={(e) => {
                                        if (handleChangePreGiftTax === undefined) {
                                            return;
                                        }
                                        handleChangePreGiftTax(e);
                                    }}
                                    placeholder="사전증여세액을 입력해주세요."
                                    className="numberInput"
                                    name="preGiftTax"
                                />
                                <p className="unit">원</p>
                            </div>
                        </NumberInputContainer>
                    </div>
                </InputContainer>
            )}

        </MainSectionContainer>
    );
}

const RealEstateList = styled.ul`
    width: 100%;
    padding: 0;
    margin-top: 10px;

    .label {
        margin-bottom: 5px;

        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.2em;
        line-height: 1.29;
        letter-spacing: -0.058em;
        color: ${(props) => props.theme.color.nGray30};
    }
`;

export default BasicInfo;
