/***********************************************************************************
*** Law23 ** 조세특례제한법 99조의 2 신축주택 등 취득자에 대한 양도소득세의 과세특례 Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law23Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}
export default function Law23({ setModalOpen, setLawModalTitle, callBack, callMode }: Law23Props) {
    React.useEffect(() => {
        setLawModalTitle("조세특례제한법 99조의 2 신축주택 등 취득자에 대한 양도소득세의 과세특례");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className='w100p hAuto ofA mb30'>
                <table className={
                    'forest-list-table bdt1 bdb1 bdcDDDDDD ' +
                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075'
                }>
                   <colgroup>
                        <col className='w80'></col>
                        <col className='w140'></col>
                        <col className='w120'></col>
                        <col className='w160'></col>
                        <col className='w200'></col>
                        <col className='w80'></col>
                        <col className='w80'></col>
                        <col className='w80'></col>
                        <col className='w80'></col>
                        <col className='w180'></col>
                    </colgroup>
                    <tbody>
                        <tr className='h40'>
                            <th rowSpan={2}>구분</th>
                            <th rowSpan={2}>주택구분</th>
                            <th rowSpan={2}>규모 또는<br/>가액기준</th>
                            <th rowSpan={2}>계약일+계약금 완납일<br/>귀속기간</th>
                            <th rowSpan={2}>사용승인 또는<br/>사용검사 (임시승인포함)일<br/>귀속기간</th>
                            <th colSpan={4}>추가질문</th>
                            <th rowSpan={2}>혜택</th>
                        </tr>
                        <tr className='h40'>
                            <th>필수서류</th>
                            <th>미분양시점</th>
                            <th>모집공고<br/>계약일</th>
                            <th className='bdr1-i bdcDDDDDD-i'>최초계약</th>
                        </tr>
                        <tr className='h40'>
                            <th rowSpan={4}>개별사항</th>
                            <td>분양권에 의한 아파트(오피스텔포함) 중 미분양</td>
                            <td>국민주택규모 이하 또는 취득가액 6억원 이하</td>
                            <td>2013.4.1.~2013.12.31.</td>
                            <td>-</td>
                            <td rowSpan={4}>지자체 확인서 및 지자체 날인</td>
                            <td>13.03.31</td>
                            <td>-</td>
                            <td rowSpan={4}>여</td>
                            <td rowSpan={4}>5년이내 양도시 : 양도소득세 100% 감면<br/><br/>5년초과 양도시 : 최초 5년간 양도소득금액 과세제외</td>
                        </tr>
                        <tr className='h40'>
                            <td>분양권에 의한 아파트(오피스텔포함) 중 신규분양</td>
                            <td>국민주택규모 이하 또는 취득가액 6억원 이하</td>
                            <td>2013.4.1.~2013.12.31.</td>
                            <td>-</td>
                            <td>-</td>
                            <td className='bdr1-i bdcDDDDDD-i'>13.04.01 이후</td>
                        </tr>
                        <tr className='h40'>
                            <td>1세대 1주택자로부터 취득한 주택(오피스텔포함)</td>
                            <td>국민주택규모 이하 또는 취득가액 6억원 이하</td>
                            <td>2013.4.1.~2013.12.31.</td>
                            <td>-</td>
                            <td>-</td>
                            <td className='bdr1-i bdcDDDDDD-i'>-</td>
                        </tr>
                        <tr className='h40'>
                            <td>상기외 건축주로부터 취득하는 주택</td>
                            <td>국민주택규모 이하 또는 취득가액 6억원 이하</td>
                            <td>2013.4.1.~2013.12.31.</td>
                            <td>2013.4.1.~2013.12.31.</td>
                            <td>-</td>
                            <td className='bdr1-i bdcDDDDDD-i'>-</td>
                        </tr>
                        <tr className='h110'>
                            <th>공통사항</th>
                            <td className='taL pt15 pb15 pl15'colSpan={9}>
                                1. 비과세 판정시 주택수 제외<br/>
                                2. 다주택 중과배제(유예)<br/>
                                3. 과세요건 갖춘 상속주택 양도시 감면제외<br/>
                                4. 오피스텔은 아래 하나에 해당하고 상기표의 조건을 모두 충족할 경우 감면혜택부여<br/>
                                    &nbsp;&nbsp;가. 취득일로부터 60일이후 부터 양도일까지 본인 또는 임차인 주민등록이 존재(6개월 공실은 임차인 존재로 취급)<br/>
                                    &nbsp;&nbsp;나. 취득일로부터 60일이내 지자체 주택임대사업자 등록<br/>
                                5. 건축주로부터 취득하는 주택중 조합원이 취득하는 주택 및 보유중 소실, 붕괴, 노후로 인하여 멸실되어 재건축한 주택은 감면혜택 제외<br/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}