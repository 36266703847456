/*******************************************************************************
*** Header ** 모바일 증여세 세액결과 Header
*******************************************************************************/
import styled                      from "styled-components";
import ArrowBackOutlinedIcon       from "@mui/icons-material/ArrowBackOutlined";
import { calculatorPcWebMaxWidth } from "js/common/const";

type Props = {
    onClickBack: () => void;
};

function Header({ onClickBack }: Props) {
    const handleClickBack = () => {
      onClickBack();
    };

    return (
        <GiftHeader>
            <button className="backButton" onClick={handleClickBack}>
                <ArrowBackOutlinedIcon fontSize="small" />
            </button>
            <div className="logo">
                <img src={require("img/common/logo_navy_dark.png")} alt="calltax logo" />
                <span className="title">증여세 산출 결과</span>
            </div>
        </GiftHeader>
    );
}

const GiftHeader = styled.header`
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;
    z-index: 11;

    width: 100%;
    height: 50px;
    padding: 0 20px;
    background-color: ${(props) => props.theme.color.bg};

    @media (min-width: 1201px) {
        max-width: ${calculatorPcWebMaxWidth};
    }

    .backButton {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        width: 24px;
        height: 24px;
        font-size: 2.5em;
        color: ${(props) => props.theme.color.sNavy};
    }

    .logo {
        img {
            width: 64px;
        }

        .title {
            font-family: "Spoqa Han Sans Neo";
            font-weight: 500;
            font-size: 1.6em;
            line-height: 0.71875;
            letter-spacing: -0.04375em;
            color: ${(props) => props.theme.color.sNavy};
        }
    }
`;

export default Header;
