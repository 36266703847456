/*******************************************************************************
*** useGiftCalcSteps ** 모바일 상속세 단계별 validation
*******************************************************************************/
import { produce }  from "immer";
import { Step }     from "js/common/types/types";
import Util         from "js/common/Util";
import { useState, useEffect, Dispatch } from "react";
import { InheTaxAction, InheTaxState }   from "./inheTaxStatesReducer";

// 상속세 정보 입력 단계
const stepList: Step[] = [
    {
        stepId: "INHE01",
        name: "기초 정보 입력",
        order: 1,
        useYn: true,
        selected: true,
        subSteps: [],
    },
    {
        stepId: "INHE02",
        name: "증여 정보 입력",
        order: 2,
        useYn: true,
        selected: false,
        subSteps: [],
    },    
    {
        stepId: "INHE03",
        name: "공제 정보 입력",
        order: 3,
        useYn: true,
        selected: false,
        subSteps: [],
    },
    {
        stepId: "INHE04",
        name: "부가 정보 입력",
        order: 4,
        useYn: true,
        selected: false,
        subSteps: [],
    }
];

export function useInheCalcSteps( inheTaxState: InheTaxState, onUpdateInheTaxState: Dispatch<InheTaxAction>) {
    const [inheStepList, setInheStepList] = useState<Step[]>(stepList); // 입력단계 List
    const [backDisabled, setBackDisabled] = useState<boolean>(true);    // Back Button 활성화
    const [nextDisabled, setNextDisabled] = useState<boolean>(true);    // Next Button 활성화

    const usingStepList    = inheStepList.filter((step: Step) => step.useYn);
    const currentInheStep  = usingStepList.find((step: Step) => step.selected);
    const currentStepIndex = usingStepList.findIndex((step: Step) => step.selected);

    // 초기화
    const initInheStep = () => { 
        setInheStepList(stepList); 
    };

    // 마지막 단계 확인
    const isLastStep = usingStepList.length - 1 === currentStepIndex ? true : false;

    // 단계가 바뀌면 next 버튼 비활성화
    useEffect(() => {
        setNextDisabled(true);
    }, [currentInheStep]);

    // 1단계 Back 버튼 활성화 여부 설정
    useEffect(() => {
        if (currentInheStep && currentInheStep.stepId === "INHE01") {
            setBackDisabled(true);
        } else {
            setBackDisabled(false);
        }
    }, [currentInheStep]);

    // 기초정보입력 단계 완료 여부 확인(1단계)
    useEffect(() => {
        if (!currentInheStep || currentInheStep.stepId !== "INHE01") return;

        const inputVisible = inheTaxState.inputVisible;
        const inputValue   = inheTaxState.inputValue;

        let validateYn = true;
        // 01. 상속재산가액
        if (inputVisible.visible01) {
            if (Util.isEmpty(inputValue.inherProp)) {
                validateYn = false;
            }
        }
        // 02. 추정상속가액
        if (inputVisible.visible02) {
            if (Util.isEmpty(inputValue.estiInher)) {
                validateYn = false;
            }
        }
        // 03. 퇴직금/보험금/신탁자산
        if (inputVisible.visible03) {
            if (Util.isEmpty(inputValue.trustAssets)) {
                validateYn = false;
            }
        }
        // 12. 채무
        if (inputVisible.visible12) {
            if (Util.isEmpty(inputValue.debtAmt)) {
                validateYn = false;
            }
        }
        // 13. 공과금
        if (inputVisible.visible13) {
            if (Util.isEmpty(inputValue.utilityBill)) {
                validateYn = false;
            }
        }
        // 14. 장례비용
        if (inputVisible.visible14) {
            if (Util.isEmpty(inputValue.funeralExpen)) {
                validateYn = false;
            }
            if (Number(inputValue.funeralExpen) < 0 || Number(Util.uncomma(inputValue.funeralExpen)) > 15000000) {
                validateYn = false;
            }
        }

        setNextDisabled(!validateYn);
    }, [inheTaxState.inputVisible, inheTaxState.inputValue, currentInheStep]);

    // 증여정보입력 단계 완료 여부 확인(2단계)
    useEffect(() => {
        if (!currentInheStep || currentInheStep.stepId !== "INHE02") return;

        const inputVisible = inheTaxState.inputVisible;
        const inputValue   = inheTaxState.inputValue;

        let validateYn = true;

        // 04. 배우자 유무
        if (inputVisible.visible04) {
            if (Util.isEmpty(inputValue.spouse)) {
                validateYn = false;
            }
        }        

        // 05. 배우자 사전증여 유무
        if (inputVisible.visible05) {
            if (Util.isEmpty(inputValue.spousePreGift)) {
                validateYn = false;
            }
        }   

        // 06. 배우자 사전증여 재산가액
        if (inputVisible.visible06) {
            if (Util.isEmpty(inputValue.spousePreGiftProp)) {
                validateYn = false;
            }
        }   

        // 07. 배우자 사전증여세액
        if (inputVisible.visible07) {
            if (Util.isEmpty(inputValue.spousePreGiftTax)) {
                validateYn = false;
            }
        }   

        // 08. 자녀 수
        if (inputVisible.visible08) {
            if (Util.isEmpty(inputValue.childNumber)) {
                validateYn = false;
            }
        }   

        // 09. 사전증여 유무
        if (inputVisible.visible09) {
            if (Util.isEmpty(inputValue.preGift)) {
                validateYn = false;
            }
        }   

        // 10. 사전증여 재산가액
        if (inputVisible.visible10) {
            if (Util.isEmpty(inputValue.preGiftProp)) {
                validateYn = false;
            }
        }   

        // 11. 사전증여세액
        if (inputVisible.visible11) {
            if (Util.isEmpty(inputValue.preGiftTax)) {
                validateYn = false;
            }
        }   

        setNextDisabled(!validateYn);
    }, [currentInheStep, inheTaxState.inputValue, inheTaxState.inputVisible]);

    // 공제정보입력 단계 완료 여부 확인(3단계)
    useEffect(() => {
        if (!currentInheStep || currentInheStep.stepId !== "INHE03") return;

        const inputVisible = inheTaxState.inputVisible;
        const inputValue   = inheTaxState.inputValue;
        
        let validateYn = true;

        // 15. 공제구분
        if (inputVisible.visible15) {
            if (Util.isEmpty(inputValue.deduCls)) {
                validateYn = false;
            }
        }

        // 16. 일괄공제
        if (inputVisible.visible16) {
            if (Util.isEmpty(inputValue.sumDedu)) {
                validateYn = false;
            }
        }

        // 17. 기초공제
        if (inputVisible.visible17) {
            if (Util.isEmpty(inputValue.basicDedu)) {
                validateYn = false;
            }
        }

        // 18. 인적공제
        if (inputVisible.visible18) {
            if (Util.isEmpty(inputValue.personDedu)) {
                validateYn = false;
            }
        }

        // 19. 배우자 장애인 여부
        if (inputVisible.visible19) {
            if (Util.isEmpty(inputValue.spouseDisabled)) {
                validateYn = false;
            }
        }

        // 20. 배우자 성별
        if (inputVisible.visible20) {
            if (Util.isEmpty(inputValue.spouseGender)) {
                validateYn = false;
            }
        }

        // 21. 배우자 나이
        if (inputVisible.visible21) {
            if (Util.isEmpty(inputValue.spouseAge)) {
                validateYn = false;
            }
        }

        // 22_0. 직계존속 상속인 유무
        if (inputVisible.visible22_0) {
            if (Util.isEmpty(inputValue.directHeir)) {
                validateYn = false;
            }
        }

        // 22. 배우자 상속재산가액
        if (inputVisible.visible22) {
            if (Util.isEmpty(inputValue.spouseInherProp)) {
                validateYn = false;
            }
        }

        // 23. 배우자 승계 공과금&채무
        if (inputVisible.visible23) {
            if (Util.isEmpty(inputValue.spouseSucUtilDebt)) {
                validateYn = false;
            }
        }

        setNextDisabled(!validateYn);
    }, [currentInheStep, inheTaxState.inputValue, inheTaxState.inputVisible]);


    // 부가정보입력 단계 완료 여부 확인(4단계)
    useEffect(() => {
        if (!currentInheStep || currentInheStep.stepId !== "INHE04") return;

        const inputVisible = inheTaxState.inputVisible;
        const inputValue   = inheTaxState.inputValue;

        let validateYn = true;

        // 24. 기업상속공제
        if (inputVisible.visible24) {
            if (Util.isEmpty(inputValue.familyDedu)) {
                validateYn = false;
            }
        }

        // 25. 영농상속공제
        if (inputVisible.visible25) {
            if (Util.isEmpty(inputValue.farmDedu)) {
                validateYn = false;
            }
        }

        // 26. 동거주택상속공제
        if (inputVisible.visible26) {
            if (Util.isEmpty(inputValue.houseDedu)) {
                validateYn = false;
            }
        }

        // 27. 금융상속공제
        if (inputVisible.visible27) {
            if (Util.isEmpty(inputValue.finanDedu)) {
                validateYn = false;
            }
        }

        setNextDisabled(!validateYn);        
    }, [currentInheStep, inheTaxState.inputValue, inheTaxState.inputVisible]);

    // 선택 초기화(Footer Navigator)
    const handleClickReset = () => {
        if (!currentInheStep) return;

        switch (currentInheStep.stepId) {
            // 기초정보입력
            case "INHE01": {
                onUpdateInheTaxState({
                    type: "INIT_BASIC_INFO",
                    payload: "",
                });
                break;
            }

            // 증여 정보 입력 초기화
            case "INHE02": {
                onUpdateInheTaxState({
                    type: "INIT_GIFT_INFO",
                    payload: "",
                });
                break;
            }
            
            // 공제정보 입력 초기화
            case "INHE03": {
                onUpdateInheTaxState({
                    type: "INIT_DEDU_INFO",
                    payload: "",
                });
                break;
            }
            
            // 추가정보 입력 초기화
            case "INHE04": {
                onUpdateInheTaxState({
                    type: "INIT_EXTRA_INFO",
                    payload: "",
                });
                break;
            }               
        }
    };

    // Back(Footer Navigator)
    const handleClickBackStep = () => {
        if (!currentInheStep) return;

        switch (currentInheStep.stepId) {
            // 기초정보입력
            case "INHE01": {
                break;
            }
            // 증여정보 입력
            case "INHE02": {
                moveToBackStep();   // Back 이벤트
                // 증여정보 입력 초기화
                onUpdateInheTaxState({
                    type: "INIT_GIFT_INFO",
                    payload: "",
                });
                break;
            }
            // 공제정보 입력
            case "INHE03": {
                moveToBackStep();   // Back 이벤트
                // 공제정보 입력 초기화
                onUpdateInheTaxState({
                    type: "INIT_DEDU_INFO",
                    payload: "",
                });
                break;
            }
            // 부가정보 입력
            case "INHE04": {
                moveToBackStep();   // Back 이벤트
                // 부가정보 입력 초기화
                onUpdateInheTaxState({
                    type: "INIT_EXTRA_INFO",
                    payload: "",
                });
                break;
            }
        }
    };

    // Next(Footer Navigator) 클릭
    const handleClickNextStep = () => {
        if (!currentInheStep) return;

        switch (currentInheStep.stepId) {
            // 기초정보입력
            case "INHE01": {
                moveToNextStep();   // Next 이벤트
                break;
            }
            case "INHE02": {
                moveToNextStep();   // Next 이벤트
                break;
            }
            case "INHE03": {
                moveToNextStep();   // Next 이벤트
                break;
            }
            case "INHE04": {
                break;
            }
        }
    };

    // Back 이벤트 처리
    const moveToBackStep = () => {
        setInheStepList(
            produce((draft: Step[]) => {
                const usingSteps = draft.filter((step) => step.useYn);
                const selectedIndex = usingSteps.findIndex((step) => step.selected);
                if (selectedIndex !== -1 && selectedIndex > 0) {
                usingSteps[selectedIndex].selected = false;
                usingSteps[selectedIndex - 1].selected = true;
                }
            })
        );
    };
  
    // Next 이벤트 처리
    const moveToNextStep = () => {
        setInheStepList(
            produce((draft: Step[]) => {
                const usingSteps = draft.filter((step) => step.useYn);
                console.log("usingSteps >>>" + usingSteps);
                const selectedIndex = usingSteps.findIndex((step) => step.selected);
                if (selectedIndex !== -1 && selectedIndex < usingSteps.length - 1) {
                usingSteps[selectedIndex].selected = false;
                usingSteps[selectedIndex + 1].selected = true;
                }
            })
        );
    };    

    return {
        usingStepList,
        currentInheStep,
        currentStepIndex,
        handleClickNextStep,
        handleClickBackStep,
        handleClickReset,
        backDisabled,
        nextDisabled,
        isLastStep,
        initInheStep,
    };
}
