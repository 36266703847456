/*******************************************************************************
*** Gifttax ** 증여세 계산 자산리스트
*******************************************************************************/
import AppContext           from "js/common/AppContext";
import Axios                from "js/common/Axios";
import React                from "react";
import { useRecoilValue }   from "recoil";
import * as Atom            from "js/common/Atom";
import MuiListFilter        from "js/common/ui/list/MuiListFilter";
import ForestTableStyle     from 'js/common/ui/style/ForestStyle';
import Modal                from "js/common/ui/modal/Modal";
import AssetsShow           from "../assets/show/AssetsShow";
import GiftInfoIn           from "./GiftInfoIn";
import GiftTaxCalc          from "./GiftTaxCalc";

function Gifttax() {
    const hshldSn = useRecoilValue(Atom.hshldSn); // 고객(납세자) 일련번호
    const hshldNm = useRecoilValue(Atom.hshldNm); // 고객(납세자) 이름

    const [assetsDataArr, setAssetsDataArr] = React.useState<any[]>([]); // 자산 List Arrary
    const [assetsSn     , setAssetsSn     ] = React.useState<number>();  // 자산key

    const [assetsModalOpen    , setAssetsModalOpen    ] = React.useState<boolean>(false); // 보유자산 조회 모달
    const [giftInfoInModalOpen, setGiftInfoInModalOpen] = React.useState<boolean>(false); // 증여관련 정보 입력 모달

    const [giftTaxCalcModalOpen, setGiftTaxCalcModalOpen] = React.useState<boolean>(false);  // 증여세 결과표 모달
    const [giftTaxCalcModalVsb , setGiftTaxCalcModalVsb] = React.useState<boolean>(false);   // 증여세 간편계산기 결과 표시 여부

    // checkbox 관련
    const [checkDataArr, setCheckDataArr] = React.useState<any[]>([]);  // 체크된 자산 정보 배열
    const pickType = "check";

    // 증여세 관련 정보 입력항목
    const[donee         , setDonee         ] = React.useState<string>("");  // 01. 수증자
    const[giftProp      , setGiftProp      ] = React.useState<string>("");  // 02. 증여 재산가액
    const[debtAssum     , setDebtAssum     ] = React.useState<string>("");  // 03. 채무인수 여부
    const[debtAmt       , setDebtAmt       ] = React.useState<string>("");  // 04. 채무액
    const[preGift       , setPreGift       ] = React.useState<string>("");  // 05. 증여자 사전증여 유무
    const[preGiftProp   , setPreGiftProp   ] = React.useState<string>("");  // 06. 사전증여 재산가액
    const[preGiftTax    , setPreGiftTax    ] = React.useState<string>("");  // 07. 사전증여 세액
    const[doneeMinor    , setDoneeMinor    ] = React.useState<string>("");  // 08. 수증자 미성년자 여부
    const[marriageDedu  , setMarriageDedu  ] = React.useState<string>("");  // 09. 혼인공제
    const[childbirthDedu, setChildbirthDedu] = React.useState<string>("");  // 10. 출산공제    

    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/assets/selAssetsList.do",
            methodType: "post",
            paramType: "json",
            paramData: {
                hshldSn: hshldSn,
                assetsSe: "1001",
                estateSttus: "1002",
                estateSe: "1001",
                routeKey: "trsftax"
            },
            onSuccessFn: (res: any) => {
                setAssetsDataArr(res.list);
                AppContext.hideSpinner();
            }
        });
    }, [hshldSn]);  

    const assetsDataFieldInfo = [
        { type: 'text', fldNm: 'rowNum'        , width:  65,  headAlign: "center", dataAlign: "center", label: 'No.'      },
        { type: 'text', fldNm: 'rmstnfamNm'    , width:  90,  headAlign: "center", dataAlign: "center", label: '세대원명' },
        { type: 'code', fldNm: 'estateDetailSe', width: 110,  headAlign: "center", dataAlign: "center", label: '자산구분', codeTy: "1006" },
        { type: 'code', fldNm: 'estatePrpos'   , width:  90,  headAlign: "center", dataAlign: "center", label: '주택용도', codeTy: "1008" },
        { type: 'code', fldNm: 'acqsCause'     , width: 100,  headAlign: "center", dataAlign: "center", label: '취득원인', codeTy: "1035" },
        { type: 'text', fldNm: 'fullRdnmAdres' , wAuto: true, headAlign: "center", dataAlign: "left"  , label: '주소'   },
        { type: 'text', fldNm: 'trsfAcqsDe'    , width: 100,  headAlign: "center", dataAlign: "center", label: '취득일' },
        { type: 'el', width: 70, headAlign: "center", el: (item: any) => (
            <div className='dpFlx aiC csP' onClick={() => {
                setAssetsSn(item.assetsSn);
                setAssetsModalOpen(true);
            }}>
                <img className="csP pr3" src={require('img/common/icon_copy20.png')} alt="icon_copy20" />
                <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">조회</span>
            </div>
        )}
    ]

    // 증여세 계산
    const openCalcInputModal = () => {
        // 증여 자산 선택 여부 확인
        if (checkDataArr.length === 0) {
            AppContext.openAlert({
                title: "증여할 자산 미선택",
                msg: "증여할 자산을 선택하세요.",
                icon: "check"
            });
            return;            
        }
        moveStep(1);
    }


    // 모달창 이동
    const moveStep = (step: number) => {
        setGiftInfoInModalOpen (false);  // 증여세 관련 정보 입력
        setGiftTaxCalcModalOpen(false);  // 증여세 결과표

        if (step === 1) {
            setGiftInfoInModalOpen(true);
        } else if (step === 2) {
            setGiftTaxCalcModalOpen(true);
        } else if (step === 0) {
            goReset();
        }
    }

    // 초기화
    const goReset = () => {
        setDonee         ("");
        setGiftProp      ("");
        setDebtAssum     ("");
        setDebtAmt       ("");
        setPreGift       ("");
        setPreGiftProp   ("");
        setPreGiftTax    ("");
        setDoneeMinor    ("");
        setMarriageDedu  ("");
        setChildbirthDedu("");
    }    

  return (
    <React.Fragment>
        <div className="w100p bgcF6F6F6">
            <div className="dpFlx flxdCol jcC aiC pt60 pb30">
                <div className='taC pb30'>
                    <span className="dpBlock fcBlack fs38 ls266 SpoqaHanSansNeo-Medium">증여세 계산</span>
                </div>
            </div>
            <div className="dpFlx jcC pt30 pb100">
                <div className='w1200'>
                    <div className='dpFlx h36 jcSb pb20'>
                        <div className='dpFlx aiE'>
                            <img className="mr10" src={require('img/common/icon_step.png')} alt="icon_step" />
                            <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 pb3'>
                                <span className='fc17A840'>{hshldNm}</span>
                                <span className='fc222222'>님의 보유자산</span>
                            </span>
                        </div>
                        <div className="w100 h40 bgcWhite bdrd40 dpFlx jcC aiC csP pl5 bd1 bdc17A840" onClick={openCalcInputModal}>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc17A840'>세액 계산</span>
                            <img className="pl5" src={require('img/system/assets/icon_cal20_green.png')} alt="icon_cal20_green" />
                        </div>
                    </div>
                    <MuiListFilter tableInfo={{
                        tableHeadVsb: true,
                        styleType: ForestTableStyle,
                        fldInfs: assetsDataFieldInfo,
                        ordFld: { name: "rowNum", direction: "asc" },
                        dataArr: assetsDataArr,
                        maxRowNums: [10, 20, 30],
                        trHeight: 48,
                        remainArea: false,
                        // srchVsb: true,
                        paginateClass: "pt40",
                        headerVsb: true,
                        checkedHookParam: pickType === "check" ? {
                            checkDataArr: checkDataArr,
                            setCheckDataArr: setCheckDataArr
                        } : null,
                        // rowClickFunc: pickType === "select" ? (item: any) => {
                        //     chFunc(item);
                        // } : null                        
                    }}/>
                </div>
            </div>
        </div>

        {/* 보유자산 조회 모달 */}
        <Modal modalOpen={assetsModalOpen} setModalOpen={setAssetsModalOpen} title="보유자산 조회" dim={true} closeCallBack={() => {
            setAssetsSn(undefined);
            setAssetsModalOpen(false);
        }}>
            <div className="w1200 h500 ofA">
                <AssetsShow assetsSn={assetsSn} />
            </div>
            <div className='w100p dpFlx aiC jcC pt40'>
                <button className='w210 h56 bgcF6F6F6 dpFlx aiC jcC csP bdN' onClick={() => {
                    setAssetsSn(undefined);
                    setAssetsModalOpen(false);
                }}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>닫기</span>
                </button>
            </div>
        </Modal>

        {/* 증여세 관련 정보 입력 모달 */}
        <Modal modalOpen={giftInfoInModalOpen} setModalOpen={setGiftInfoInModalOpen} title="증여세 계산 - 정보 입력" dim={true} closeCallBack={() => moveStep(0)}>
            <GiftInfoIn 
                giftTaxParam      = {checkDataArr  } 
                moveStep          = {moveStep      }

                donee             = {donee         }  // 01. 수증자
                giftProp          = {giftProp      }  // 02. 증여 재산가액
                debtAssum         = {debtAssum     }  // 03. 채무인수 여부
                debtAmt           = {debtAmt       }  // 04. 채무액
                preGift           = {preGift       }  // 05. 증여자 사전증여 유무
                preGiftProp       = {preGiftProp   }  // 06. 사전증여 재산가액
                preGiftTax        = {preGiftTax    }  // 07. 사전증여 세액
                doneeMinor        = {doneeMinor    }  // 08. 수증자 미성년자 여부
                marriageDedu      = {marriageDedu  }  // 09. 혼인공제
                childbirthDedu    = {childbirthDedu}  // 10. 출산공제    

                setDonee          = {setDonee         }
                setGiftProp       = {setGiftProp      }
                setDebtAssum      = {setDebtAssum     }
                setDebtAmt        = {setDebtAmt       }
                setPreGift        = {setPreGift       }
                setPreGiftProp    = {setPreGiftProp   }
                setPreGiftTax     = {setPreGiftTax    }
                setDoneeMinor     = {setDoneeMinor    }
                setMarriageDedu   = {setMarriageDedu  }
                setChildbirthDedu = {setChildbirthDedu}
            />
        </Modal>

        {/* 증여세 결과표 모달 */}
        <Modal modalOpen={giftTaxCalcModalOpen} setModalOpen={setGiftTaxCalcModalOpen} title="증여세 세액 산출" dim={true} visible={giftTaxCalcModalVsb} closeCallBack={() => {
            setGiftTaxCalcModalVsb(false);
            moveStep(0);
        }}>
            <GiftTaxCalc 
                setGiftTaxCalcModalVsb  = {setGiftTaxCalcModalVsb} 
                moveStep        = {moveStep       }

                donee             = {donee         }  // 01. 수증자
                giftProp          = {giftProp      }  // 02. 증여 재산가액
                debtAssum         = {debtAssum     }  // 03. 채무인수 여부
                debtAmt           = {debtAmt       }  // 04. 채무액
                preGift           = {preGift       }  // 05. 증여자 사전증여 유무
                preGiftProp       = {preGiftProp   }  // 06. 사전증여 재산가액
                preGiftTax        = {preGiftTax    }  // 07. 사전증여 세액
                doneeMinor        = {doneeMinor    }  // 08. 수증자 미성년자 여부
                marriageDedu      = {marriageDedu  }  // 09. 혼인공제
                childbirthDedu    = {childbirthDedu}  // 10. 출산공제    
            />
        </Modal>        

    </React.Fragment>    
    )
}
export default Gifttax