/*******************************************************************************
*** AppContext ** 대메뉴
*******************************************************************************/
const AppContext = {
    showSpinner: () => {},
    hideSpinner: () => {},
    showSpinnerDim: () => {},
    openAlert: ({title, msg, icon, closeText, handleClose, confirmText, handleConfirm}: any) => {},
    goLogout: () => {},
    codeList: [],
    menuList: [
        {
            role: "system",
            url: "/system/hshld",
            name: "고객/납세자 관리",
            vsb: true,
            subMenuList: [
                { url: "/system/hshld", name: "고객/납세자 등록" },
                { url: "/system/assets", name: "세대/자산 등록" }
            ]
        },
        {
            role: "system",
            url: "/system/acqstax",
            name: "취득관리",
            vsb: true,
            subMenuList: [
                { url: "/system/acqstax", name: "취득세" }
            ]
        },
        {
            role: "system",
            url: "/system/proptax",
            name: "보유관리",
            vsb: true,
            subMenuList: [
                { url: "/system/proptax", name: "재산세"      , murl:"/system/proptax"},
                { url: "/system/comptax", name: "종합부동산세", murl:"/system/proptax"},
                // { name: "주택임대소득세" }
            ]
        },
        {
            role: "system",
            url: "/system/trsftax",
            name: "처분관리",
            vsb: true,
            subMenuList: [
                { url: "/system/trsftax", name: "양도세" },
                { url: "/system/gifttax", name: "증여세" },
                { url: "/system/inhetax" , name: "상속세" }
                // { url: "/system/trsftax/test", name: "양도세(테스트)" }
            ]
        },
        {
            role: "portal",
            url: "/portal/cowork",
            name: "협력업체"
        },
        {
            role: "portal",
            url: "/portal/notice",
            name: "커뮤니티",
            vsb: true,
            subMenuList: [
                { url: "/portal/notice", name: "공지사항" },
                { url: "/system/expert", name: "전문가커뮤니티" },
                { url: "/system/qna", name: "질의/응답" }
            ]
        },
        {
            role: "admin",
            url: "/admin/user",
            name: "관리자시스템",
            vsb: true,
            subMenuList: [
                { url: "/admin/user", name: "회원관리" },
                { url: "/admin/notice", name: "공지사항관리" },
                { url: "/admin/faq", name: "FAQ관리" },
                { url: "/admin/system", name: "법령관리", role: "sysAdmin" }
            ]
        },
        {
            role: "portal",
            url: "/portal/intrcn",
            name: "서비스소개",
            vsb: true
        }
    ],
    getMenuName: (url: string) => {
        let mainMenuName: string = "";
        AppContext.menuList.forEach((menuInfo) => {
            menuInfo.subMenuList?.forEach((subMenuInfo) => {
                if (subMenuInfo.url === url) {
                    mainMenuName = menuInfo.name;
                    return;
                }
            });
        });
        return mainMenuName;
    },
    getSubMenuList: (url: string) => {
        let subMenuList: any[] = [];
        AppContext.menuList.forEach((menuInfo) => {
            menuInfo.subMenuList?.forEach((subMenuInfo) => {
                if (subMenuInfo.url === url) {
                    subMenuList = menuInfo.subMenuList;
                }
            });
        });
        return subMenuList;
    },
    hshldNeedMenuUrl: ["/system/assets", "/system/acqstax", "/system/proptax", "/system/comptax", "/system/trsftax", "/system/trsftax/test"],
    fileWhiteExt: [""],
    maxfileSize: 0,
    accessTokenExpireTime: 0,
    refreshTokenExpireTime: 0,
    goPage: (url: string) => {},
    backendUrl: "",
    sidoCodeInfoList: []
};
export default AppContext;