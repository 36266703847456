/*******************************************************************************
*** AddressEtc ** 주소검색 3단계 * 최종 주소 확인
*******************************************************************************/
import React from "react";
import { IWizard } from "use-wizard/lib/cjs/useWizard/types/IWizard";
import { TStep } from "use-wizard/lib/cjs/useWizard/types/TStep";
import Axios from "js/common/Axios";
import Util from "js/common/Util";
import AppContext from "js/common/AppContext";
import { NumericFormat } from "react-number-format";
import ImageRadioBox from "js/common/ui/input/ImageRadioBox";
import DatePickerBox from "js/common/ui/input/DatePickerBox";

const AddressEtc = (props: {
    step: TStep;
    wizard: IWizard;
    form: any;
    dispatchForm: Function;
    callBack: Function;
    setModalOpen: Function;
}) => {
    // 공동주택 면적
    const [plotRight, setPlotRight] = React.useState<string>(); // 대지권
    const [prvuseAr, setPrvuseAr] = React.useState<string>(); // 전용면적
    const [cnrsAr, setCnrsAr] = React.useState<string>(); // 공유면적

    // 단독주택 면적
    const [plotAr, setPlotAr] = React.useState<string>(); // 대지연면적
    const [houseTotar, setHouseTotar] = React.useState<string>(); // 주택연면적
    const [sopsrtTotar, setSopsrtTotar] = React.useState<string>(); // 상가연면적

    // 공통 필드
    const [spfcKnd, setSpfcKnd] = React.useState<string>(); // 용도지역
    const [ovpalotAt, setOvpalotAt] = React.useState<string>(""); // 과밀억제권역여부
    const [mdatTrgetAreaAt, setMdatTrgetAreaAt] = React.useState<string>(""); // 조정지역여부
    const [landTradePermAt, setLandTradePermAt] = React.useState<string>(""); // 토지거래허가구역여부
    const [countryAreaAt, setCountryAreaAt] = React.useState<string>(""); // 읍면지역여부
    const [touristCmplxAt, setTouristCmplxAt] = React.useState<string>(""); // 관광단지여부
    const [spcltnAreaAt, setSpcltnAreaAt] = React.useState<string>(""); // 투기지역여부

    // 가격
    const [pblntfPc, setPblntfPc] = React.useState<string>();  // 공동주택가격
    const [housePc, setHousePc] = React.useState<string>(); // 개별주택가격

    const [useConfmDe, setUseConfmDe] = React.useState<string>(""); // 사용승인일

    const [apiErrorFlag, setApiErrorFlag] = React.useState<boolean>(false); // API 오류 여부

    // 이전버튼
    const goPrevPage = () => {
        props.dispatchForm({
            type: "UPDATE_KEY_VALUES",
            value: {
                userBdNm: props.form.userBdNm,
                userDong: props.form.userDong,
                userHo: props.form.userHo
            }
        })
        props.wizard.previousStep();
    }

    // 완료버튼 (자산 폼 셋팅)
    const goFinish = () => {
        const bdNm = Util.nvl(props.form.userBdNm, "");
        const dongNm = Util.nvl(props.form.userDong, "");
        const hoNm = Util.nvl(props.form.userHo, "");

        const retParam = {
            bdKnd: props.form.selAddrItem.bdKdcd,
            rdnmAdres: props.form.selAddrItem.roadAddr,
            rdnmAdresDetail: bdNm + " " + dongNm + " " + hoNm,
            lnmAdres: props.form.selAddrItem.jibunAddr,
            lnmAdresDetail: bdNm + " " + dongNm + " " + hoNm,
            bdNm: bdNm,
            dongNm: dongNm,
            hoNm: hoNm,
            legaldongCode: props.form.selAddrItem.admCd,
            bdMgtSn: props.form.selAddrItem.bdMgtSn,
            pnu: props.form.selAddrItem.pnu,
            zip: props.form.selAddrItem.zipNo,
            plotRight: plotRight,
            prvuseAr: prvuseAr,
            cnrsAr: cnrsAr,
            plotAr: plotAr,
            houseTotar: houseTotar,
            sopsrtTotar: sopsrtTotar,
            spfcKnd: spfcKnd,
            ovpalotAt: ovpalotAt,
            mdatTrgetAreaAt: mdatTrgetAreaAt,
            landTradePermAt: landTradePermAt,
            countryAreaAt: countryAreaAt,
            touristCmplxAt: touristCmplxAt,
            spcltnAreaAt: spcltnAreaAt,
            pblntfPc: pblntfPc,
            housePc: housePc,
            ar: "",
            useConfmDe: useConfmDe
        };
        if (props.form.selAddrItem.bdKdcd === "1") {
            retParam.ar = prvuseAr || "";
        } else if (props.form.selAddrItem.bdKdcd === "0") {
            retParam.ar = houseTotar || "";
        }
        props.callBack(retParam);
        props.setModalOpen(false);
    }

    // 주택별 면적 필드
    const HouseAreaField = () => {
        if (props.form.selAddrItem.bdKdcd === '1') { // 공동주택
            return (
                <React.Fragment>
                    <div className={"h40 aiC mb10 jcSb " + (Util.isEmpty(prvuseAr) ? "dpNone" : "dpFlx")}>
                        <div className="dpFlx aiC jcc pr20 csP">
                            <span className="SpoqaHanSansNeo-Medium w80 fs14 ls07 fc222222">전용면적(㎡)</span>
                        </div>
                        <NumericFormat
                            value={prvuseAr}
                            maxLength={7}
                            decimalScale={2}
                            onValueChange={(values) => setPrvuseAr(values.value)}
                            placeholder="전용면적을 입력해주세요."
                            className="w355 h40 pl15 pr15 SpoqaHanSansNeo-Regular fs15 bdBox bd1 bdcCECECE fBd-2B2B2B fc222222"
                            disabled={true}
                        />
                    </div>
                    <div className={"h40 aiC jcSb " + (Util.isEmpty(pblntfPc) ? "dpNone" : "dpFlx")}>
                        <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">공동주택가격(원)</span>
                        <NumericFormat
                            value={pblntfPc}
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            onValueChange={(values) => setPblntfPc(values.value)}
                            placeholder="공동주택가격을 입력해주세요."
                            className="w355 h40 pl15 pr15 SpoqaHanSansNeo-Regular fs15 bdBox bd1 bdcCECECE fBd-2B2B2B fc222222"
                            disabled={true}
                        />
                    </div>
                </React.Fragment>
            )
        } else if (props.form.selAddrItem.bdKdcd === '0') { // 단독주택
            return (
                <React.Fragment>
                    <div className={"h40 aiC mb10 jcSb " + (Util.isEmpty(plotAr) ? "dpNone" : "dpFlx")}>
                        <div className="dpFlx aiC jcc pr10 csP">
                            <span className="SpoqaHanSansNeo-Medium w90 fs14 ls07 fc222222">대지면적(㎡)</span>
                        </div>
                        <NumericFormat
                            value={plotAr}
                            maxLength={7}
                            decimalScale={2}
                            onValueChange={(values) => setPlotAr(values.value)}
                            placeholder="대지면적을 입력해주세요."
                            className="w355 h40 pl15 pr15 SpoqaHanSansNeo-Regular fs15 bdBox bd1 bdcCECECE fBd-2B2B2B fc222222"
                            disabled={true}
                        />
                    </div>
                    <div className={"h40 aiC jcSb mb10 " + (Util.isEmpty(houseTotar) ? "dpNone" : "dpFlx")}>
                        <div className="dpFlx aiC pr10 csP">
                            <span className="SpoqaHanSansNeo-Medium w90 fs14 ls07 fc222222">주택연면적(㎡)</span>
                        </div>
                        <NumericFormat
                            value={houseTotar}
                            maxLength={7}
                            decimalScale={2}
                            onValueChange={(values) => setHouseTotar(values.value)}
                            placeholder="주택연면적을 입력해주세요"
                            className="w355 h40 pl15 pr15 SpoqaHanSansNeo-Regular fs15 bdBox bd1 bdcCECECE fBd-2B2B2B fc222222"
                            disabled={true}
                        />
                    </div>
                    <div className={"h40 aiC jcSb " + (Util.isEmpty(housePc) ? "dpNone" : "dpFlx")}>
                        <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">개별주택가격(원)</span>
                        <NumericFormat
                            value={housePc}
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            onValueChange={(values) => setHousePc(values.value)}
                            placeholder="개별주택가격을 입력해주세요"
                            className="w355 h40 pl15 pr15 SpoqaHanSansNeo-Regular fs15 bdBox bd1 bdcCECECE fBd-2B2B2B fc222222"
                            disabled={true}
                        />
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <></>
            )
        }
    }

    // 최종 주소 및 정보 확인 요청
    React.useEffect(() => {
        AppContext.showSpinnerDim();
        let apiParam;

        if (Util.isNotEmpty(props.form.userDong) && Util.isNotEmpty(props.form.userHo)) { // 아파트
            apiParam = props.form.selAddrItem.pnu + "," + props.form.selAddrItem.bdKdcd + "," + encodeURI(props.form.userDong) + "," + encodeURI(props.form.userHo);
        } else if (Util.isEmpty(props.form.userDong) && Util.isNotEmpty(props.form.userHo)) { // 한개동, 다세대
            apiParam = props.form.selAddrItem.pnu + ",1," + encodeURI(props.form.userHo);
        } else { // 단독주택
            apiParam = props.form.selAddrItem.pnu + "," + props.form.selAddrItem.bdKdcd
        }
        Axios.dataAccess({
            url: "api/system/broker/callApi.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                apiName: "openapi",
                tailUrl: "forest/estate",
                paramStr: apiParam
            },
            onSuccessFn: (res: any) => {
                setApiErrorFlag(false);
                if (res && res.item && res.item.rtn_list) {
                    // 공동주택 면적
                    setPlotRight(res.item.rtn_list[0].plotRight);
                    setPrvuseAr(res.item.rtn_list[0].prvuseAr);
                    setCnrsAr(res.item.rtn_list[0].cnrsAr);
                    // 단독주택 면적
                    setPlotAr(res.item.rtn_list[0].plotAr);
                    setHouseTotar(res.item.rtn_list[0].houseTotar);
                    setSopsrtTotar(res.item.rtn_list[0].sopsrtTotar);
                    // 공통 필드
                    if (Util.isEmpty(res.item.rtn_list[0].spfcKndCd) || res.item.rtn_list[0].spfcKndCd === "1000") {
                        setSpfcKnd("1001");
                    } else {
                        setSpfcKnd(res.item.rtn_list[0].spfcKndCd);
                    }

                    // 과밀억제권역 Hard Coding
                    let chkVal1 = props.form.selAddrItem.admCd.substr(0, 2);
                    let chkVal2 = props.form.selAddrItem.admCd.substr(0, 3);
                    let chkVal3 = props.form.selAddrItem.admCd.substr(0, 4);
                    const admcodeArray = ["11", "4115", "4131", "4145", "4128", "4111", "4113", "4117", "4119", "4121", "4129", "4143", "4141", "281", "282", "4136", "4139"];
                    
                    const find1 = admcodeArray.indexOf(chkVal1);
                    const find2 = admcodeArray.indexOf(chkVal2);
                    const find3 = admcodeArray.indexOf(chkVal3);

                    if (find1 >= 0 || find2 > 0 || find3 > 0) {
                        setOvpalotAt("Y");
                    } else {
                        setOvpalotAt(res.item.rtn_list[0].ovpalotAt);
                    }
                    
                    setMdatTrgetAreaAt(res.item.rtn_list[0].mdatTrgetAreaAt);
                    setLandTradePermAt(res.item.rtn_list[0].landTradePermAt);
                    setCountryAreaAt(res.item.rtn_list[0].countryAreaAt);
                    setTouristCmplxAt(res.item.rtn_list[0].touristCmplxAt);
                    setSpcltnAreaAt(res.item.rtn_list[0].spcltnAreaAt);
                    setPblntfPc(res.item.rtn_list[0].pblntfPc);
                    setHousePc(res.item.rtn_list[0].housePc);
                    setUseConfmDe(res.item.rtn_list[0].useAprDay);
                }
                AppContext.hideSpinner();
            },
            skipFailedAlert: true,
            onFailedFn: () => {
                setApiErrorFlag(true);
                Axios.dataAccess({
                    url: "system/assets/chkAjstRgnInfo.do",
                    methodType: "post",
                    paramType: "object",
                    paramData: {
                        pnu: props.form.selAddrItem.pnu
                    },
                    onSuccessFn: (res: any) => {
                        if (res.item === 0) {
                            setMdatTrgetAreaAt("N");
                        } else {
                            setMdatTrgetAreaAt("Y");
                        }
                        AppContext.hideSpinner();
                    }
                });
            }
        });
    }, [props.form.selAddrItem.pnu, props.form.selAddrItem.bdKdcd, props.form.userDong, props.form.userHo, props.form.selAddrItem.admCd]);

    return (
        <React.Fragment>
            <div className="dpFlx aiC">
                <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>최종 주소 확인 ({props.step}/3)</span>
            </div>
            <div className='h40 dpFlx aiC pt5'>
                <div className='w50 h24 bd1 bdc3C89CD bdrd4 dpFlx aiC jcC bdBox mr10'>
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc3C89CD'>도로명</span>
                </div>
                <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc666666'>{props.form.selAddrItem.roadAddr} {props.form.userDong} {props.form.userHo}</span>
            </div>
            <div className={'pt10 ' + (apiErrorFlag ? "dpNone" : "")}>
                {HouseAreaField()}
            </div>
            <div className={"h78 mt30 " + (apiErrorFlag ? "dpNone" : "dpFlx")}>
                <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">용도지역지구</span>
                <div className="w360 aiB">
                    {/* <fieldset className="bd0 dpFlx aiC p0 pl5" style={{ height: "35px" }}>
                        <span className="fmForest-b fs13 ls08 pr5">도시지역 (</span>
                        <Radio interval={10} fontSize={13} defaultChecked={spfcKnd === "1001"} onChange={() => { setSpfcKnd("1001") }}>주거지역</Radio>
                        <Radio interval={10} fontSize={13} defaultChecked={spfcKnd === "1002"} onChange={() => { setSpfcKnd("1002") }}>상업지역</Radio>
                        <Radio interval={10} fontSize={13} defaultChecked={spfcKnd === "1003"} onChange={() => { setSpfcKnd("1003") }}>공업지역</Radio>
                        <Radio interval={0} fontSize={13} defaultChecked={spfcKnd === "1004"} onChange={() => { setSpfcKnd("1004") }}>녹지지역</Radio>
                        <span className="fmForest-b fs13 ls08 pl5 pr10">)</span>
                        <Radio interval={10} fontSize={13} defaultChecked={spfcKnd === "1005"} onChange={() => { setSpfcKnd("1005") }}>관리지역</Radio>
                        <Radio interval={10} fontSize={13} defaultChecked={spfcKnd === "1006"} onChange={() => { setSpfcKnd("1006") }}>농림지역</Radio>
                        <Radio fontSize={13} defaultChecked={spfcKnd === "1007"} onChange={() => { setSpfcKnd("1007") }}>자연환경보전지역</Radio>
                    </fieldset> */}
                    <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc222222">도시지역</span>
                    <div className="dpFlx aiC pb10 mt2">
                        <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc222222 mr2">(</span>
                        <div className="dpFlx aiC csP mr2">
                            <img className="w24 h24 pr5" src={require('img/common/radiobutton_base_' + (spfcKnd === "1001" ? 'on' : 'off') + '.png')} alt="radiobutton_base" />
                            <span className="SpoqaHanSansNeo-Regular fs15 ls015 fcBDBDBD-i lh20">주거지역</span>
                        </div>
                        <div className="dpFlx aiC csP mr2">
                            <img className="w24 h24 pr5" src={require('img/common/radiobutton_base_' + (spfcKnd === "1002" ? 'on' : 'off') + '.png')} alt="radiobutton_base" />
                            <span className="SpoqaHanSansNeo-Regular fs15 ls015 fcBDBDBD-i lh20">상업지역</span>
                        </div>
                        <div className="dpFlx aiC csP mr2">
                            <img className="w24 h24 pr5" src={require('img/common/radiobutton_base_' + (spfcKnd === "1003" ? 'on' : 'off') + '.png')} alt="radiobutton_base" />
                            <span className="SpoqaHanSansNeo-Regular fs15 ls015 fcBDBDBD-i lh20">공업지역</span>
                        </div>
                        <div className="dpFlx aiC csP">
                            <img className="w24 h24 pr5" src={require('img/common/radiobutton_base_' + (spfcKnd === "1004" ? 'on' : 'off') + '.png')} alt="radiobutton_base" />
                            <span className="SpoqaHanSansNeo-Regular fs15 ls015 fcBDBDBD-i lh20">녹지지역</span>
                        </div>
                        <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc222222 ml2">)</span>
                    </div>
                    <div className="dpFlx aiC">
                        <div className="dpFlx aiC csP mr10">
                            <img className="w24 h24 pr5" src={require('img/common/radiobutton_base_' + (spfcKnd === "1005" ? 'on' : 'off') + '.png')} alt="radiobutton_base" />
                            <span className="SpoqaHanSansNeo-Regular fs15 ls015 fcBDBDBD-i lh20">관리지역</span>
                        </div>
                        <div className="dpFlx aiC csP mr10">
                            <img className="w24 h24 pr5" src={require('img/common/radiobutton_base_' + (spfcKnd === "1006" ? 'on' : 'off') + '.png')} alt="radiobutton_base" />
                            <span className="SpoqaHanSansNeo-Regular fs15 ls015 fcBDBDBD-i lh20">농림지역</span>
                        </div>
                        <div className="dpFlx aiC csP mr10">
                            <img className="w24 h24 pr5" src={require('img/common/radiobutton_base_' + (spfcKnd === "1007" ? 'on' : 'off') + '.png')} alt="radiobutton_base" />
                            <span className="SpoqaHanSansNeo-Regular fs15 ls015 fcBDBDBD-i lh20">자연환경보전지역</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"h40 aiC pt22 pb10 " + (apiErrorFlag ? "dpNone" : "dpFlx")}>
                <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">과밀억제권역</span>
                <ImageRadioBox
                    codeList={Util.getCodeArray("1036")}
                    value={ovpalotAt}
                    setValue={setOvpalotAt}
                    btnClass="w24 h24 pr5"
                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                    interval={20}
                    disabled={true}
                />
            </div>
            <div className={"dpFlx h35 aiC pb10 " + (apiErrorFlag ? "mt20" : "")}>
                <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">조정대상지역</span>
                <ImageRadioBox
                    codeList={Util.getCodeArray("1036")}
                    value={mdatTrgetAreaAt}
                    setValue={setMdatTrgetAreaAt}
                    btnClass="w24 h24 pr5"
                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                    interval={20}
                    disabled={true}
                />
            </div>
            <div className={"h35 aiC pb10 " + (apiErrorFlag || Util.isEmpty(useConfmDe) ? "dpNone" : "dpFlx")}>
                <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">사용승인일</span>
                <DatePickerBox
                    value={useConfmDe}
                    width={300}
                    height={40}
                    fontSize={15}
                    bgColor="#fff"
                    textAlign="left"
                    onChange={(data: string) => setUseConfmDe(Util.dateToString(data, ""))}
                    disabled={true}
                />
            </div>
            <div className='w100p dpFlx aiC jcC pt40'>
                <div className='w210 h56 bgcF6F6F6 dpFlx aiC jcC csP' onClick={goPrevPage}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>이전</span>
                </div>
                <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={goFinish}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>완료</span>
                </div>
            </div>
        </React.Fragment>
    );
};
export default AddressEtc;
