/***********************************************************************************
*** AssetsLawTest ** 법령 Modal 호출 TEST
************************************************************************************/
import React from 'react';
import Modal from 'js/common/ui/modal/Modal';
import IconButton from 'js/common/ui/button/IconButton';
import LawDialog from './dialog/LawDialog';

const AssetsLawTest = () => {
    const [lawModalOpen, setLawModalOpen] = React.useState<boolean>(false);
    const [lawordSn, setLawordSn] = React.useState<number>(0);
    const [lawordDetailSn, setLawordDetailSn] = React.useState<number>(0);
    const [lawModalTitle, setLawModalTitle] = React.useState<string>("");

    const openLawPopup = (lawordSn: number, lawordDetailSn: number) => {
        setLawordSn(lawordSn);
        setLawordDetailSn(lawordDetailSn);
        setLawModalOpen(true);
    }

    return (
        <React.Fragment>
            <div className="dpFlx jcC pt30 pb30">
                <div className='w1200'>
                    <div className='mb50'>
                        <p>소득세법 시행령 제155조</p>
                        <div className='dpFlx'>
                            <IconButton btnName="제155조 제20항 가목" iconPos="right" iconNm="list" onClick={() => { openLawPopup(1, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제155조 제20항 나목" iconPos="right" iconNm="list" onClick={() => { openLawPopup(2, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제155조 제20항 다목" iconPos="right" iconNm="list" onClick={() => { openLawPopup(3, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제155조 제20항 라목" iconPos="right" iconNm="list" onClick={() => { openLawPopup(4, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제155조 제20항 마목" iconPos="right" iconNm="list" onClick={() => { openLawPopup(5, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제155조 제20항 바목" iconPos="right" iconNm="list" onClick={() => { openLawPopup(6, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                        </div>
                    </div>

                    <div className='mb50'>
                        <p>조세특례제한법 제97조</p>
                        <div className='dpFlx mt10'>
                            <IconButton btnName="제97조 단서" iconPos="right" iconNm="list" onClick={() => { openLawPopup( 7, 70) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제97조 본문" iconPos="right" iconNm="list" onClick={() => { openLawPopup( 8, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제97조 2"    iconPos="right" iconNm="list" onClick={() => { openLawPopup( 9, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제97조 3"    iconPos="right" iconNm="list" onClick={() => { openLawPopup(10, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제97조 4"    iconPos="right" iconNm="list" onClick={() => { openLawPopup(11, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제97조 5"    iconPos="right" iconNm="list" onClick={() => { openLawPopup(12, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                        </div>
                    </div>

                    <div className='mb50'>
                        <p>조세특례제한법 제98조</p>
                        <div className='dpFlx mt10'>
                            <IconButton btnName="제98조"   iconPos="right" iconNm="list" onClick={() => { openLawPopup(13, 10) }} className="w150 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제98조 2" iconPos="right" iconNm="list" onClick={() => { openLawPopup(14, 10) }} className="w150 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제98조 3" iconPos="right" iconNm="list" onClick={() => { openLawPopup(15, 10) }} className="w150 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제98조 5" iconPos="right" iconNm="list" onClick={() => { openLawPopup(16, 10) }} className="w150 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제98조 6" iconPos="right" iconNm="list" onClick={() => { openLawPopup(19, 10) }} className="w150 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제98조 7" iconPos="right" iconNm="list" onClick={() => { openLawPopup(20, 10) }} className="w150 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제98조 8" iconPos="right" iconNm="list" onClick={() => { openLawPopup(21, 10) }} className="w150 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                        </div>
                    </div>

                    <div className='mb50'>
                        <p>조세특례제한법 제99조</p>
                        <div className='dpFlx mt10'>
                            <IconButton btnName="제99조"   iconPos="right" iconNm="list" onClick={() => { openLawPopup(22, 10) }} className="w150 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제99조 2" iconPos="right" iconNm="list" onClick={() => { openLawPopup(23, 10) }} className="w150 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제99조 3" iconPos="right" iconNm="list" onClick={() => { openLawPopup(24, 10) }} className="w150 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                        </div>
                    </div>

                    <div className='mb50'>
                        <p>종합부동산세 시행령 제3조</p>
                        <div className='dpFlx mt10'>
                            <IconButton btnName="제3조 제1항 제1호" iconPos="right" iconNm="list" onClick={() => { openLawPopup(25, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제3조 제1항 제2호" iconPos="right" iconNm="list" onClick={() => { openLawPopup(26, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제3조 제1항 제3호" iconPos="right" iconNm="list" onClick={() => { openLawPopup(27, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제3조 제1항 제4호" iconPos="right" iconNm="list" onClick={() => { openLawPopup(28, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제3조 제1항 제6호" iconPos="right" iconNm="list" onClick={() => { openLawPopup(29, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제3조 제1항 제7호" iconPos="right" iconNm="list" onClick={() => { openLawPopup(30, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제3조 제1항 제8호" iconPos="right" iconNm="list" onClick={() => { openLawPopup(31, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                        </div>
                    </div>

                    <div className='mb50'>
                        <p>지방세특례제한법 제31조</p>
                        <div className='dpFlx mt10'>
                            <IconButton btnName="제31조의 4항" iconPos="right" iconNm="list" onClick={() => { openLawPopup(32, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제31조의 3"   iconPos="right" iconNm="list" onClick={() => { openLawPopup(33, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                        </div>
                    </div>

                    <div className='mb50'>
                        <p>소득세법 제155조/제156조</p>
                        <div className='dpFlx mt10'>
                            <IconButton btnName="제155조 제2항"   iconPos="right" iconNm="list" onClick={() => { openLawPopup(34, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제156조 2 제6항" iconPos="right" iconNm="list" onClick={() => { openLawPopup(35, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제156조 3 제4항" iconPos="right" iconNm="list" onClick={() => { openLawPopup(36, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제155조 제3항"   iconPos="right" iconNm="list" onClick={() => { openLawPopup(37, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제156조 2 제7항" iconPos="right" iconNm="list" onClick={() => { openLawPopup(38, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                            <IconButton btnName="제156조 3 제4항" iconPos="right" iconNm="list" onClick={() => { openLawPopup(39, 10) }} className="w170 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                        </div>
                    </div>

                    <div className='mb50'>
                        <p>종전 소득세법시행령 제154조</p>
                        <div className='dpFlx mt10'>
                            <IconButton btnName="제154조 제1항 제4호" iconPos="right" iconNm="list" onClick={() => { openLawPopup(40, 10) }} className="w180 h35 mr10 bdcBlack fs14 fcBlack bgcWhite bd1"></IconButton>
                        </div>
                    </div>

                </div>
            </div>
            <div>
                <Modal modalOpen={lawModalOpen} setModalOpen={setLawModalOpen} title={lawModalTitle} dim={true} visible={true} closeCallBack={() => setLawModalTitle("")}>
                    <LawDialog lawordSn={lawordSn} lawordDetailSn={lawordDetailSn} setModalOpen={setLawModalOpen} setLawModalTitle={setLawModalTitle} callMode="modify" callBack={() => setLawModalTitle("")} />
                </Modal>
            </div>
        </React.Fragment >
    )
}
export default AssetsLawTest;