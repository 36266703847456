/***********************************************************************************
*** Law1 ** 소득세법 시행령 제155조 제20항 가목(거주주택비과세) Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law1Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}

export default function Law1({ setModalOpen, setLawModalTitle, callBack, callMode }: Law1Props) {
    React.useEffect(() => {
        setLawModalTitle("소득세법 시행령 제155조 제20항 가목(거주주택비과세)");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className='w100p hAuto ofA mb30'>
                <table className={
                    'forest-list-table bdt1 bdb1 bdcDDDDDD ' +
                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075'
                }>
                    <colgroup>
                        <col className='w80'></col>
                        <col className='w80'></col>
                        <col className='w80'></col>
                        <col className='w110'></col>
                        <col className='w80'></col>
                        <col className='w90'></col>
                        <col className='w90'></col>
                        <col className='w110'></col>
                        <col className='w110'></col>
                        <col className='w110'></col>
                        <col className='w200'></col>
                    </colgroup>
                    <tbody>
                        <tr className='h40'>
                            <th rowSpan={2}>구분</th>
                            <th rowSpan={2}>주택구분</th>
                            <th rowSpan={2}>주택규모</th>
                            <th rowSpan={2}>가액기준</th>
                            <th rowSpan={2}>지역기준</th>
                            <th colSpan={2}>등록</th>
                            <th rowSpan={2}>민특법 분류</th>
                            <th rowSpan={2}>임대유형</th>
                            <th rowSpan={2}>임대기간</th>
                            <th rowSpan={2}>상한율</th>
                        </tr>
                        <tr className='h29'>
                            <th>지자체</th>
                            <th className='bdr1-i bdcDDDDDD-i'>세무서</th>
                        </tr>
                        <tr className='h69'>
                            <th rowSpan={2}>개별사항</th>
                            <td rowSpan={2}>모든주택</td>
                            <td>-</td>
                            <td>임대개시일<br/>현재 기준시가<br/>6억원 이하</td>
                            <td>수도권</td>
                            <td rowSpan={2} colSpan={2}>2020.7.10. 까지 등록 신청한 매입임대주택분에 적용함</td>
                            <td rowSpan={2}>매입임대주택</td>
                            <td rowSpan={2}>단기 / 장기</td>
                            <td rowSpan={2}>5년 이상</td>
                            <td rowSpan={2}>5% 이내(2019.2.12 이후 임대차 계약을 갱신하거나 새로 취득하는 분부터 적용)</td>
                        </tr>
                        <tr className='h69'>
                            <td>-</td>
                            <td>임대개시일<br/>현재 기준시가<br/>3억원 이하</td>
                            <td className='bdr1-i bdcDDDDDD-i'>비수도권</td>
                        </tr>
                        <tr className='h110'>
                            <th>기타사항</th>
                            <td className='taL pt15 pb15 pl15'colSpan={10}>
                                1. 자동말소 : 2020.8.18. 이후 자동말소 시 임대기간 요건을 갖춘 것으로 본다. 단기임대주택과 장기임대주택 중 아파트, 거주주택을 등록말소 후 5년 이내 양도시 비과세<br />
                                2. 자진말소 : 2020.8.18. 이후 자진말소 시 말소 후  5년 이내 양도시 비과세(민특법상 의무임대기간 1/2 이상 임대한 경우에 한함), 단기임대주택과 장기임대주택 중 아파트<br/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}