import { useState, useEffect } from "react";
import styled from "styled-components";
import { useSetRecoilState } from "recoil";

import { addrSearchResultState } from "js/common/Atom";
import Header from "./header";
import AssetBasicInfo from "./AssetBasicInfo";
import DirectAssetAddressInfo from "./DirectAssetAddressInfo";
import ExtraInfo from "./ExtraInfo";
import Axios from "js/common/Axios";
import { AddrSearchResult } from "js/common/types/types";
import AddressInfo from "js/mobile/AddressInfo/AddressInfo";

import CalcStep from "../common/CalcStep";
import CalcStepButton from "./CalcStepButton";
import { useAcqsCalcStep } from "js/common/hooks/useAcqsCalcStep";
import { calculatorPcWebMaxWidth } from "js/common/const";

interface Step {
  stepId: string;
  name: string;
  order: number;
  useYn: boolean;
  selected: boolean;
  subSteps: Step[];
}

function AcqsTaxCalc() {
  const setAddrSearchResult = useSetRecoilState(addrSearchResultState);
  const {
    acqsStepList,
    currentCalcStep,
    backDisabled,
    nextDisabled,
    handleClickNextStep,
    handleClickBackStep,
    handleClickReset,
    addrInfoSubStepList,
    moveToNextAddrSubStep,
    onClickBackToCalcMenu,
    addrSearchQuery,
    setAddrSearchQuery,
    addrQueryResult,
    setAddrQueryResult,
    isLastStep,
  } = useAcqsCalcStep();

  const [apiResult, setApiResult] = useState<string>("");

  const usingStepList = acqsStepList.filter((step: Step) => step.useYn);
  const currentStepIndex = usingStepList.findIndex(
    (step: Step) => step.selected
  );

  useEffect(() => {
    // API 정상 호출 여부 확인
    // 주소 조회 API 확인
    Axios.dataAccess({
      url: "common/mobile/callApi.do",
      methodType: "post",
      paramType: "object",
      paramData: {
        apiName: "openapi",
        tailUrl: "addr/juso",
        paramStr: encodeURI("상암미르웰") + ",1,10",
      },
      onSuccessFn: (res: any) => {
        // 테스트를 위해 체크박스 추가함
        setApiResult(res.item.rtn_code);
        setAddrSearchResult((prev: AddrSearchResult) => ({
          ...prev,
          error: res.item.rtn_code,
        }));
      },
      skipFailedAlert: true,
      onFailedFn: () => {
        setApiResult("-1");
        setAddrSearchResult((prev: AddrSearchResult) => ({
          ...prev,
          error: "-1",
        }));
      },
    });
  }, [setAddrSearchResult]);

  /*const handleClickInputReset = () => {
        handleClickReset();

        if (!currentCalcStep || apiResult === '-1') return;

        const currentStepId = currentCalcStep.stepId;
        if (currentStepId === 'ACQS02') {
            setAddrSearchQuery('');
        }
    };*/

  return (
    <Body>
      <Header
        totalStep={usingStepList.length}
        currentStep={currentStepIndex + 1}
        onClickBack={onClickBackToCalcMenu}
      />
      <CalcStep
        stepInfoList={acqsStepList.filter((step: Step) => step.useYn)}
      />
      <MainContainer>
        {currentCalcStep && currentCalcStep.stepId === "ACQS01" && (
          <AssetBasicInfo />
        )}
        {currentCalcStep &&
          currentCalcStep.stepId === "ACQS02" &&
          (apiResult === "0" ? (
            <AddressInfo
              stepList={addrInfoSubStepList}
              searchText={addrSearchQuery}
              setSearchText={setAddrSearchQuery}
              searchResult={addrQueryResult}
              setSearchResult={setAddrQueryResult}
              onSelectAddr={moveToNextAddrSubStep}
            />
          ) : (
            <DirectAssetAddressInfo />
          ))}
        {currentCalcStep && currentCalcStep.stepId === "ACQS03" && (
          <ExtraInfo />
        )}
        <CalcStepButton
          onClickBack={handleClickBackStep}
          onClickNext={handleClickNextStep}
          onClickReset={handleClickReset}
          backDisabled={backDisabled}
          nextDisabled={nextDisabled}
          isLastStep={isLastStep}
        />
      </MainContainer>
    </Body>
  );
}

const Body = styled.div`
  font-size: ${({ theme }) => theme.fontSize}; /* 1rem = 10px */
  position: relative;
  width: 100dvw;
  height: 100dvh;

  /*display: grid;
    grid-template-rows: 50px 50px 1fr;*/

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${(props) => props.theme.color.pGreenLight04};

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  p {
    margin: 0;
  }

  li {
    list-style: none;
  }
`;

const MainContainer = styled.main`
  position: relative;
  width: 100%;
  height: calc(100% - 100px);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  /*flex: 1 1 0;*/

  background: ${(props) => props.theme.color.bg};

  @media (min-width: 1201px) {
    max-width: ${calculatorPcWebMaxWidth};
  }

  .tempCheckbox {
    padding: 20px;
    font-size: 1.6em;
  }
`;

export default AcqsTaxCalc;
