/*******************************************************************************
 *** index ** 모바일 상속세 - 인적공제계산 Dialog index(PersonalDeductionDialog)
 *******************************************************************************/
import { useState, useEffect } from "react";
import { produce } from "immer";

import ModalPortal from "js/common/ui/modal/ModalPortal";
import {
  DialogBody,
  DialogMainSection,
  MainSectionContainer,
} from "js/mobile/common/layout";
import {
  DisableInfoProps,
  PersonDeduInputValue,
  Step,
  Under19InfoProps,
} from "js/common/types/types";
import Header from "./Header";
import PersonCountInfo from "./PersonCountInfo";
import PersonDeduInfo from "./PersonDeduInfo";
import StepButton from "./StepButton";
import Util from "js/common/Util";

const stepList: Step[] = [
  {
    stepId: "DEDU01",
    name: "인적공제 인원 수 입력",
    order: 1,
    useYn: true,
    selected: true,
    subSteps: [],
  },
  {
    stepId: "DEDU02",
    name: "미성년자, 장애인 공제 정보 입력",
    order: 2,
    useYn: true,
    selected: false,
    subSteps: [],
  },
];

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  childNumber: string;
  onClickCalculate: (personDedu: string) => void;
};

function PersonDeduDialog({
  open,
  setOpen,
  childNumber,
  onClickCalculate,
}: Props) {
  const [inputValue, setInputValue] = useState<PersonDeduInputValue>({
    under19Number: "",
    under19Infos: [],
    older65Number: "",
    disabilityNumber: "",
    disableInfos: [],
    childDedu: "",
    older65Dedu: "",
  });
  const [deduStepList, setDeduStepList] = useState<Step[]>(stepList);
  const [nextDisabled, setNextDisabled] = useState<boolean>(true);

  const currentStep = deduStepList.find((step) => step.selected);
  const currentStepIndex = deduStepList.findIndex(
    (step: Step) => step.selected
  );
  const isLastStep =
    deduStepList.length - 1 === currentStepIndex ? true : false;

  // 단계가 바뀌면 next 버튼 비활성화
  useEffect(() => {
    setNextDisabled(true);
  }, [currentStep]);

  // 1단계 완료 여부 확인
  useEffect(() => {
    if (!currentStep || currentStep.stepId !== "DEDU01") return;

    if (
      Util.isEmpty(inputValue.under19Number) ||
      Number(inputValue.under19Number) < 0 ||
      Number(childNumber) < Number(inputValue.under19Number) ||
      Util.isEmpty(inputValue.older65Number) ||
      Number(inputValue.older65Number) < 0 ||
      Util.isEmpty(inputValue.disabilityNumber) ||
      Number(inputValue.disabilityNumber) < 0
    ) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, [currentStep, inputValue, childNumber]);

  // 2단계 완료 여부 확인
  useEffect(() => {
    if (!currentStep || currentStep.stepId !== "DEDU02") return;

    let validateYn = true;

    // 미성년자 공제 입력여부 확인
    if (inputValue.under19Infos.length > 0) {
      let check19Cnt = 0;
      inputValue.under19Infos.forEach((under19Info: Under19InfoProps) => {
        if (Util.isEmpty(under19Info.kidAge)) check19Cnt = check19Cnt + 1;
      });

      if (check19Cnt > 0) {
        validateYn = false;
      }
    }

    // 장애인 공제 입력여부 확인
    if (inputValue.disableInfos.length > 0) {
      let checkDisCnt = 0;
      let checkDisGenCnt = 0;
      inputValue.disableInfos.forEach((disableInfo: DisableInfoProps) => {
        if (Util.isEmpty(disableInfo.disAge)) checkDisCnt = checkDisCnt + 1;
        if (Util.isEmpty(disableInfo.disGender))
          checkDisGenCnt = checkDisGenCnt + 1;
      });

      if (checkDisCnt > 0) {
        validateYn = false;
      }
      if (checkDisGenCnt > 0) {
        validateYn = false;
      }
    }

    setNextDisabled(!validateYn);
  }, [currentStep, inputValue]);

  const handleClickBack = () => {
    setOpen(false);

    // 인적 공제 계산 dialog 초기화
    setInputValue(
      produce((draft: PersonDeduInputValue) => {
        draft.under19Number = "";
        draft.older65Number = "";
        draft.disabilityNumber = "";

        draft.under19Infos = draft.under19Infos.map(
          (info: Under19InfoProps) => ({
            kidNo: info.kidNo,
            kidAge: "",
            kidDedu: 0,
          })
        );

        draft.disableInfos = draft.disableInfos.map(
          (info: DisableInfoProps) => ({
            disNo: info.disNo,
            disGender: "",
            disAge: "",
            disDedu: 0,
          })
        );
      })
    );

    setDeduStepList(stepList);
  };

  const handleClickBackStep = () => {
    if (!currentStep) return;

    if (currentStep.stepId === "DEDU01") {
      handleClickBack();
    } else {
      setDeduStepList(
        produce((draft: Step[]) => {
          const selectedIndex = draft.findIndex((step) => step.selected);
          if (selectedIndex !== -1 && selectedIndex > 0) {
            draft[selectedIndex].selected = false;
            draft[selectedIndex - 1].selected = true;
          }
        })
      );
    }
  };

  const handleClickNextStep = () => {
    if (!currentStep) return;

    switch (currentStep.stepId) {
      case "DEDU01": {
        setDeduStepList(
          produce((draft: Step[]) => {
            const selectedIndex = draft.findIndex((step) => step.selected);
            if (selectedIndex !== -1 && selectedIndex < draft.length - 1) {
              draft[selectedIndex].selected = false;
              draft[selectedIndex + 1].selected = true;
            }
          })
        );

        // 1. 미성년자 공제용 배열 생성
        const tmpUnder19Infos: Under19InfoProps[] = [];
        for (let i = 0; i < parseInt(inputValue.under19Number); i++) {
          tmpUnder19Infos.push({
            kidNo: i + 1,
            kidAge: "",
            kidDedu: 0,
          });
        }

        // 2. 장애인 공제용 배열 생성
        const tmpDisableInfos: DisableInfoProps[] = [];
        for (let i = 0; i < parseInt(inputValue.disabilityNumber); i++) {
          tmpDisableInfos.push({
            disNo: i + 1,
            disAge: "",
            disGender: "",
            disDedu: 0,
          });
        }

        // 미성년자 공제용 배열 set, 장애인 공제용 배열 set, 자녀 공제 계산, 연로자 공제 계산
        setInputValue((prev) => ({
          ...prev,
          under19Infos: tmpUnder19Infos,
          disableInfos: tmpDisableInfos,
          childDedu: (parseInt(childNumber) * 50000000).toString(),
          older65Dedu: (parseInt(prev.older65Number) * 50000000).toString(),
        }));

        return;
      }
      case "DEDU02": {
        // 인적공제 계산하기
        // 미성년자 공제금액 합산
        let under19Dedu = 0;
        if (inputValue.under19Infos.length > 0) {
          inputValue.under19Infos.forEach((under19Info: Under19InfoProps) => {
            under19Dedu += under19Info.kidDedu;
          });
        }

        // 장애인 공제 입력여부 확인
        let disabDedu = 0;
        if (inputValue.disableInfos.length > 0) {
          inputValue.disableInfos.forEach((disableInfo: DisableInfoProps) => {
            disabDedu += disableInfo.disDedu;
          });
        }

        // 공제금액 합산 = 자녀 공제 + 미성년자 공제 + 연로자 공제 + 장애인 공제
        const calPersonDedu =
          parseInt(inputValue.childDedu) +
          parseInt(inputValue.older65Dedu) +
          under19Dedu +
          disabDedu;
        onClickCalculate(calPersonDedu.toString());
        handleClickBack();
      }
    }
  };

  const handleClickReset = () => {
    if (!currentStep) return;

    switch (currentStep.stepId) {
      case "DEDU01": {
        setInputValue(
          produce((draft: PersonDeduInputValue) => {
            draft.under19Number = "";
            draft.older65Number = "";
            draft.disabilityNumber = "";

            draft.under19Infos = draft.under19Infos.map(
              (info: Under19InfoProps) => ({
                kidNo: info.kidNo,
                kidAge: "",
                kidDedu: 0,
              })
            );

            draft.disableInfos = draft.disableInfos.map(
              (info: DisableInfoProps) => ({
                disNo: info.disNo,
                disGender: "",
                disAge: "",
                disDedu: 0,
              })
            );
          })
        );
        return;
      }
      case "DEDU02": {
        setInputValue(
          produce((draft: PersonDeduInputValue) => {
            draft.under19Infos = draft.under19Infos.map(
              (info: Under19InfoProps) => ({
                kidNo: info.kidNo,
                kidAge: "",
                kidDedu: 0,
              })
            );

            draft.disableInfos = draft.disableInfos.map(
              (info: DisableInfoProps) => ({
                disNo: info.disNo,
                disGender: "",
                disAge: "",
                disDedu: 0,
              })
            );
          })
        );

        return;
      }
    }
  };

  if (open) {
    if (!currentStep) {
      return <></>;
    } else {
      return (
        <ModalPortal>
          <DialogBody>
            <Header onClickBack={handleClickBack} />
            <DialogMainSection>
              <MainSectionContainer>
                <p className="desc">
                  <span className="bold">
                    {currentStep.name} ({currentStep.order}/2)
                  </span>
                </p>
                {currentStep.stepId === "DEDU01" && (
                  <PersonCountInfo
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    childNumber={childNumber}
                  />
                )}
                {currentStep.stepId === "DEDU02" && (
                  <PersonDeduInfo
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                  />
                )}
              </MainSectionContainer>
              <StepButton
                backDisabled={false}
                nextDisabled={nextDisabled}
                isLastStep={isLastStep}
                onClickBack={handleClickBackStep}
                onClickNext={handleClickNextStep}
                onClickReset={handleClickReset}
              />
            </DialogMainSection>
          </DialogBody>
        </ModalPortal>
      );
    }
  } else {
    return <></>;
  }
}

export default PersonDeduDialog;
