import axios from "axios";
import AppContext from 'js/common/AppContext';
import Util from 'js/common/Util';

interface AxiosProps {
    url?: string
    methodType?: string
    paramType?: string
    paramData?: {}
    isMultipart?: boolean
    isFileDown?: boolean
    onSuccessFn?: Function
    onFailedFn?: Function
    skipFailedAlert?: boolean
}
const Axios = {
    dataAccess: (_opt: AxiosProps) => {
        const chkMsg = Axios.chkAccessPsb(_opt.url);
        if (Util.isNotEmpty(chkMsg)) {
            AppContext.openAlert({
                title: "알림",
                msg: chkMsg,
                icon: "check"
            });
            AppContext.hideSpinner();
            return;
        }
        if (Util.isEmpty(_opt.url)) {
            AppContext.openAlert({
                title: "알림",
                msg: "요청 주소 정보가 없습니다.",
                icon: "check"
            });
            AppContext.hideSpinner();
            return;
        }
        if (Util.isEmpty(_opt.methodType)) {
            AppContext.openAlert({
                title: "알림",
                msg: "요청 타입 정보가 없습니다.",
                icon: "check"
            });
            AppContext.hideSpinner();
            return;
        }
        const callServer = () => {
            const paramData = Util.nvl(_opt.paramData, {});
            if (_opt.methodType === "get") {
                axios.get(
                    `${AppContext.backendUrl}/${_opt.url}`,
                    {
                        headers: {
                            'Authorization': localStorage.getItem("grantType") + ' ' + localStorage.getItem("accessToken")
                        },
                        responseType: _opt.isFileDown ? 'blob' : 'json',
                        params: paramData
                    }
                ).then((res) => {
                    Axios.successFunc(_opt, res);
                }).catch((error) => {
                    Axios.catchHandler(error, _opt);
                });
            } else if (_opt.methodType === "post") {
                if (_opt.isMultipart) { // 멀티파트인 경우
                    const formData = new FormData(); // 폼 데이터 준비
                    for (let key in paramData) {
                        if (Array.isArray(paramData[key])) { // 배열이면
                            paramData[key].forEach((item: any) => {
                                formData.append(key, item); // 반복문을 활용하여 파일들을 formData 객체에 추가한다
                            });
                        } else {
                            if (Util.isEmpty(paramData[key])) {
                                formData.append(key, "");
                            } else {
                                formData.append(key, paramData[key]);
                            }
                        }
                    }
                    axios.post(
                        `${AppContext.backendUrl}/${_opt.url}`,
                        formData,
                        {
                            headers: {
                                'Authorization': localStorage.getItem("grantType") + ' ' + localStorage.getItem("accessToken"),
                                'Content-type': 'multipart/form-data'
                            },
                            transformRequest: (data, headers) => {
                                return data;
                            },
                        }
                    ).then((res) => {
                        Axios.successFunc(_opt, res);
                    }).catch(error => {
                        Axios.catchHandler(error, _opt);
                    });
                } else {
                    let contentType = "";
                    if (_opt.paramType === "object") {
                        contentType = "application/x-www-form-urlencoded";
                    } else if (_opt.paramType === "json") {
                        contentType = "application/json";
                    } else {
                        contentType = "application/x-www-form-urlencoded";
                    }
                    axios.post(
                        `${AppContext.backendUrl}/${_opt.url}`,
                        paramData,
                        {
                            headers: {
                                'Authorization': localStorage.getItem("grantType") + ' ' + localStorage.getItem("accessToken"),
                                'Content-type': contentType
                            }
                        }
                    ).then((res) => {
                        Axios.successFunc(_opt, res);
                    }).catch(error => {
                        Axios.catchHandler(error, _opt);
                    });
                }
            }
        }
        if (_opt.url === undefined) {
            alert("url 정보가 없습니다.");
            return;
        }
        const grantType = _opt.url.split("/")[0];
        const tokenCrtDt = localStorage.getItem("tokenCrtDt");
        if (["portal", "common"].indexOf(grantType) !== -1) {
            callServer();
        } else if (["system", "admin", "api"].indexOf(grantType) !== -1) {
            if (tokenCrtDt === null) {
                AppContext.openAlert({
                    title: "인증정보 없음",
                    msg: "인증 정보가 없습니다.",
                    icon: "error",
                    handleClose: () => {
                        window.location.href = "/";
                    }
                });
                return;
            }
            const nowDtStr = Util.getSpecificDate("-", "day", 0, "SS", "");
            if (nowDtStr === undefined) {
                alert("시간정보 로딩 도중 오류가 발생하였습니다.");
                return;
            }
            const nowDt = new Date(nowDtStr);
            const calTime = (+nowDt) - (+new Date(tokenCrtDt));
            const accessTokenRemainTime = (AppContext.accessTokenExpireTime * 60000) - calTime;
            const refreshTokenRemainTime = (AppContext.refreshTokenExpireTime * 60000) - calTime;
            if (refreshTokenRemainTime < 60000) {
                Axios.removeAuthInfo();
                window.location.href = "/";
                return;
            }
            if (accessTokenRemainTime < 120000) {
                Axios.tokenReIssuance(_opt);
                return;
            }
            callServer();
        } else {
            AppContext.openAlert({
                title: "권한 오류",
                msg: "권한 타입 정보가 없습니다.",
                icon: "check"
            });
            return;
        }
    },
    successFunc: (_opt: AxiosProps, res: any) => {
        if (_opt.isFileDown) {
            const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }));
            const link = document.createElement('a');
            const dispos = res.headers["content-disposition"];
            if (Util.isEmpty(dispos)) {
                AppContext.openAlert({
                    title: "파일 없음",
                    msg: "파일이 존재하지 않습니다.",
                    icon: "error"
                });
                AppContext.hideSpinner();
                return;
            }
            const name = dispos.split("fileName=")[1].replace(/"/g, "");
            link.href = url;
            link.setAttribute('download', decodeURI(name));
            document.body.appendChild(link);
            link.click();
            return;
        }
        const resData = res.data;
        var resultCode = resData._resultCode_;
        if (resultCode === "success") {
            if (_opt.onSuccessFn && typeof _opt.onSuccessFn == 'function') {
                _opt.onSuccessFn.call(this, resData);
            }
        } else if (resultCode === "invalid") {
            if (_opt.onFailedFn) {
                _opt.onFailedFn.call(this, resData);
            }
        } else if (resultCode === "failed") {
            if (_opt.skipFailedAlert) {
                if (_opt.onFailedFn) {
                    _opt.onFailedFn.call(this, resData);
                }
            } else {
                AppContext.openAlert({
                    title: "시스템 오류",
                    msg: resData._msg_,
                    icon: "error"
                });
                AppContext.hideSpinner();
                if (_opt.onFailedFn) {
                    _opt.onFailedFn.call(this, resData);
                }
            }
        }
    },
    failedFunc: (_opt: AxiosProps, error: any) => {
        if (_opt.onFailedFn) {
            _opt.onFailedFn.call(this, error);
        }
    },
    catchHandler: (error: any, _opt: AxiosProps) => {
        const errInfo = error.toJSON();
        if (Util.isEmpty(errInfo.status)) {
            AppContext.openAlert({
                title: "http 접근 오류",
                msg: "http 통신 오류입니다.",
                icon: "error"
            });
            AppContext.hideSpinner();
            return;
        }
        if (errInfo.status === 401) { // 401 발생 시 토큰 만료
            Axios.removeAuthInfo();
            AppContext.openAlert({
                title: "인증정보 만료",
                msg: "인증정보가 만료되어 접근이 불가합니다.",
                icon: "error",
                handleClose: () => {
                    window.location.href = "/";
                }
            });
            AppContext.hideSpinner();
            return;
        } else if (errInfo.status === 403) { // 403 발생 시 권한 불충분
            AppContext.openAlert({
                title: "접근권한 없음",
                msg: "권한이 불충분하여 접근이 불가합니다.",
                icon: "error"
            });
            AppContext.hideSpinner();
            return;
        } else {
            AppContext.hideSpinner();
            Axios.failedFunc(_opt, error);
        }
    },
    // 토큰 재발급
    tokenReIssuance: (_opt: AxiosProps | undefined) => {
        const accessToken = localStorage.getItem("accessToken");
        const refreshToken = localStorage.getItem("refreshToken");
        axios.post(
            `${AppContext.backendUrl}/portal/auth/reissue.do`,
            {
                "accessToken": accessToken,
                "refreshToken": refreshToken
            }
        ).then((res) => {
            const resData = res.data;
            Axios.removeAuthInfo();
            if (resData._resultCode_ === "success") { // 발급 성공 시
                const tokenCrtDt = Util.getSpecificDate("-", "day", 0, "SS", "");
                if (tokenCrtDt !== undefined) {
                    localStorage.setItem("tokenCrtDt", tokenCrtDt);
                }
                localStorage.setItem("grantType", resData.item.grantType);
                localStorage.setItem("accessToken", resData.item.accessToken);
                localStorage.setItem("refreshToken", resData.item.refreshToken);
                if (_opt?.url !== undefined) { // 요청주소 정보가 존재하면
                    Axios.dataAccess(_opt); // 최초 호출정보 재호출
                } else {
                    AppContext.hideSpinner();
                }
            } else { // 토큰 만료
                window.location.href = "/";
            }
        }).catch(error => {
            AppContext.openAlert({
                title: "인증정보 발급 실패",
                msg: "인증정보 재발급에 실패하였습니다.",
                icon: "error",
                handleClose: () => {
                    Axios.tokenReIssuance(_opt);
                }
            });
            AppContext.hideSpinner();
        });
    },
    // 접근 가능한 url 여부 체크
    chkAccessPsb: (url: string | undefined) => {
        if (url === undefined) {
            return "호출주소 정보가 없습니다.";
        }
        const tokenCrtDt = localStorage.getItem('tokenCrtDt');
        if (url.startsWith("system", 0)) { // 사용자 인증 후 접근 가능
            if (Util.isEmpty(tokenCrtDt)) {
                return "로그인 후 이용 가능합니다.";
            }
            if (AppContext.hshldNeedMenuUrl.indexOf(url) !== -1) { // 납세자 관련 호출, 유저 관련 호출 아닐 시 모두 납세자를 선택해야 하는 메뉴
                if (Util.isEmpty(localStorage.getItem('hshldSn'))) { // 납세자 키 체크
                    return "납세자 선택 후 이용 가능합니다.";
                }
            }
        } else if (url.startsWith("admin", 0)) { // 관리자 인증 후 접근 가능
            if (Util.isEmpty(tokenCrtDt)) {
                return "로그인 후 이용 가능합니다.";
            }
            if (localStorage.getItem('isAdmin') !== "1") {
                return "관리자만 이용 가능합니다.";
            }
        }
        return "";
    },
    fileDown: (fileSn: number | null) => {
        if (fileSn === null) {
            AppContext.openAlert({
                title: "파일 식별 키 없음",
                msg: "파일 식별 키가 없습니다.",
                icon: "error"
            });
            return;
        }
        Axios.dataAccess({
            url: "system/assets/download.do",
            methodType: "get",
            isFileDown: true,
            paramData: {
                fileSn: fileSn
            }
        });
    },
    removeAuthInfo: () => {
        localStorage.removeItem('tokenCrtDt'  );
        localStorage.removeItem('grantType'   );
        localStorage.removeItem('accessToken' );
        localStorage.removeItem('refreshToken');
    }
};
export default Axios;