import AppContext from "js/common/AppContext";
import Axios from "js/common/Axios";
import React from "react";
import { IWizard } from "use-wizard/lib/cjs/useWizard/types/IWizard";
import { TStep } from "use-wizard/lib/cjs/useWizard/types/TStep";
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';
import Modal from 'js/common/ui/modal/Modal';
import LawDialog from '../dialog/LawDialog';
import Util from "js/common/Util";
import AssetsTitle from "./ui/AssetsTitle";
import AssetsButton from "./ui/AssetsButton";
// import AssetsButtonBar from "./ui/AssetsButtonBar";
import AssetsUtil from "js/common/AssetsUtil";
import AssetsEditTab from "./ui/AssetsEditTab";

const AssetsLaw = (props: {
    step: TStep;
    wizard: IWizard;
    form: any;
    dispatchForm: Function;
}) => {
    const [estateDetailSe, setEstateDetailSe] = React.useState<string>("");
    const [acqsCause, setAcqsCause] = React.useState<string>("");
    const [acqsSrc, setAcqsSrc] = React.useState<string>(""); // 취득구분
    interface lawProp {
        lawordSn: number | null
        lawordDetailSn: number | null
        lawordTy: string | null
        dcsnAt: string | null
        lawordDetailNm: string | null
        lawordDetailDc: string | null
    };
    const [lawList, setLawList] = React.useState<lawProp[]>([]); // 법령목록
    const [excptHouseLawList, setExcptHouseLawList] = React.useState<lawProp[]>([]); // 1세대 1주택 법령목록
    const [lawModalOpen, setLawModalOpen] = React.useState<boolean>(false);
    const [lawordSn, setLawordSn] = React.useState<number>(0);
    const [lawordDetailSn, setLawordDetailSn] = React.useState<number>(0);
    const [lawModalTitle, setLawModalTitle] = React.useState<string>("");

    const goPrevStep = () => {
        if (estateDetailSe === "1001") {
            props.wizard.goToStep(1003);
        } else if (estateDetailSe === "1002") {
            props.wizard.goToStep(1002); // 취득정보 스텝으로
        } else if (estateDetailSe === "1003") {
            if (acqsCause === "1001") {
                props.wizard.goToStep(1003); // 거주/임대 스텝으로
            } else {
                props.wizard.goToStep(1002); // 취득정보 스텝으로
            }
        }
    }
    const goNextStep = (callMode: string) => {
        for (const lawInfo of lawList) {
            if (Util.isEmpty(lawInfo.dcsnAt)) {
                AppContext.openAlert({
                    title: "해당주택 여부 확인",
                    msg: "적용된 근거법의 내용 버튼을 클릭하여 세부 정보를 확인 후 저장해 주시기 바랍니다.",
                    icon: "check"
                });
                return;
            }
        }
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/assets/chkAssetsLawordInfo.do",
            methodType: "post",
            paramType: "json",
            paramData: {
                assetsSn: props.form.assetsSn,
                lawList: lawList
            },
            onSuccessFn: (res: any) => {
                const saveAssetsLawordInfo = () => {
                    AppContext.showSpinner();
                    Axios.dataAccess({
                        url: "system/assets/saveAssetsLawordInfo.do",
                        methodType: "post",
                        paramType: "json",
                        paramData: {
                            assetsSn: props.form.assetsSn,
                            lawList: lawList
                        },
                        onSuccessFn: () => {
                            props.dispatchForm({
                                type: "UPDATE_KEY_VALUES",
                                value: {
                                    stepCode: "1006"
                                }
                            });
                            if (callMode !== "next") {
                                AppContext.hideSpinner();
                                return;
                            }
                            props.dispatchForm({
                                type: "UPDATE_KEY_VALUES",
                                value: { stepCode: "1006" }
                            });
                            props.wizard.goToStep(1009);
                        }
                    });
                }
                if (res.item === 0) { // 항목 하나라도 수정한 경우
                    AppContext.hideSpinner();
                    AppContext.openAlert({
                        title: "주택분류 정보 저장",
                        msg: "수정된 내역이 존재합니다. 저장하시겠습니까?",
                        icon: "check",
                        confirmText: "저장",
                        handleConfirm: () => {
                            saveAssetsLawordInfo();
                        }
                    });
                } else if (res.item === 1) { // 수정내역 없으면 바로 다음 스텝으로
                    if (callMode !== "next") {
                        AppContext.hideSpinner();
                        return;
                    }
                    props.wizard.goToStep(1009);
                } else if (res.item === 2) { // 최초등록이면 바로 저장
                    saveAssetsLawordInfo();
                }
            }
        });
    }

    const keepDcsnAtData = (dcsnAt: string) => {
        let tempArr = [] as any;
        if (lawList === undefined) {
            return;
        }
        for (const lawInfo of lawList) {
            if (lawInfo["lawordSn"] === lawordSn && lawInfo["lawordDetailSn"] === lawordDetailSn) {
                tempArr.push({
                    lawordSn: lawInfo["lawordSn"],
                    lawordDetailSn: lawInfo["lawordDetailSn"],
                    lawordDetailDc: lawInfo["lawordDetailDc"],
                    lawordDetailNm: lawInfo["lawordDetailNm"],
                    lawordTy: lawInfo["lawordTy"],
                    dcsnAt: dcsnAt
                });
            } else {
                tempArr.push(lawInfo);
            }
        }
        setLawList(tempArr);
    }

    const calcLaword = React.useCallback(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "api/system/broker/callApi.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                apiName: "taxcalc",
                tailUrl: "clshouse",
                paramStr: props.form.assetsSn
            },
            onSuccessFn: (res: any) => {
                const rtnList: lawProp[] = [];
                if (Util.isNotEmpty(res.item.rtn_list) && Array.isArray(res.item.rtn_list)) {
                    for (const rtnInfo of res.item.rtn_list) {
                        rtnList.push({
                            lawordSn: rtnInfo.law_sn,
                            lawordDetailSn: rtnInfo.law_detail_sn,
                            lawordDetailDc: rtnInfo.laword_dc,
                            lawordDetailNm: rtnInfo.laword_nm,
                            lawordTy: rtnInfo.laword_ty,
                            dcsnAt: ""
                        });
                    }
                } else {
                    // 더미 처리
                    rtnList.push({
                        lawordSn: null,
                        lawordDetailSn: null,
                        lawordDetailDc: null,
                        lawordDetailNm: null,
                        lawordTy: null,
                        dcsnAt: "N"
                    });
                }
                setLawList(rtnList);
                AppContext.hideSpinner();
            }
        });
    }, [props.form.assetsSn]);

    React.useEffect(() => {
        props.form.setCurrStepCode(1006);
    }, [props.form]);

    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/assets/selAssetsLawSortInfo.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                assetsSn: props.form.assetsSn
            },
            onSuccessFn: (res: any) => {
                Axios.dataAccess({
                    url: "system/rmstnfam/selRmstnfamInfo.do",
                    methodType: "post",
                    paramType: "object",
                    paramData: {
                        rmstnfamSn: props.form.rmstnfamSn
                    },
                    onSuccessFn: (res2: any) => {
                        setEstateDetailSe(res.item.estateDetail.estateDetailSe);
                        setAcqsCause(res.item.estateDetail.acqsCause);
                        setAcqsSrc(res.item.estateDetail.acqsSrc);
                        setLawList(res.item.lawList);

                        Axios.dataAccess({
                            url: "system/assets/selAssetsQestnInfo.do",
                            methodType: "post",
                            paramType: "object",
                            paramData: {
                                assetsSn: props.form.assetsSn
                            },
                            onSuccessFn: (res2: any) => {
                                const rtnHouseLawList: lawProp[] = [];
                                const lawNms: string[] = [];

                                if (res2.item.excptHouseInfo.wwinHouseAt === "Y") {
                                    lawNms.push("상생임대주택 - 소득세법시행령 제155조의 3");
                                }
                                if (res2.item.excptHouseInfo.acqsHouseAt === "Y") {
                                    lawNms.push("부득이한 사유로 취득한 주택 - 소득세법시행령 제155조 제8항");
                                }
                                if (res2.item.excptHouseInfo.frhlHouseAt === "Y") {
                                    if (AssetsUtil.getAnswerValue(res2.item.assetsQestnList, 62) === "Y") {
                                        lawNms.push("농어촌 주택 - 소득세법시행령 제155조 제7항 상속받은 주택");
                                    }
                                    if (AssetsUtil.getAnswerValue(res2.item.assetsQestnList, 63) === "Y") {
                                        lawNms.push("농어촌 주택 - 소득세법시행령 제155조 제7항 이농주택");
                                    }
                                    if (AssetsUtil.getAnswerValue(res2.item.assetsQestnList, 64) === "Y") {
                                        lawNms.push("농어촌 주택 - 소득세법시행령 제155조 제7항 귀농주택");
                                    }
                                    if (AssetsUtil.getAnswerValue(res2.item.assetsQestnList, 65) === "Y") {
                                        lawNms.push("농어촌 주택 - 조세특례제한법 제99조의 4 농어촌주택 등");
                                    }
                                    if (AssetsUtil.getAnswerValue(res2.item.assetsQestnList, 66) === "Y") {
                                        lawNms.push("농어촌 주택 - 조세특례제한법 제99조의 4 고향주택 등");
                                    }
                                }
                                if (res2.item.excptHouseInfo.crltsHouseAt === "Y") {
                                    lawNms.push("문화재 주택 - 소득세법시행령 제155조 제6항");
                                }
                                if (res2.item.excptHouseInfo.mrtgHouseAt === "Y") {
                                    lawNms.push("장기저당담보주택 - 소득세법시행령 제155조의 2");
                                }
                                if (res2.item.excptHouseInfo.childHouseAt === "Y") {
                                    lawNms.push("장기어린이집주택 - 소득세법시행령 제155조 제20항");
                                }
                                if (res2.item.excptHouseInfo.salesHouseAt === "Y") {
                                    lawNms.push("주택 신축 판매업용 주택 - 소득세법 제19조");
                                }
                                if (res2.item.excptHouseInfo.estateHouseAt === "Y") {
                                    lawNms.push("부동산 매매업용 주택 - 소득세법 제19조");
                                }
                                for (const lawNm of lawNms) {
                                    rtnHouseLawList.push({
                                        lawordSn: -1,
                                        lawordDetailSn: -1,
                                        lawordDetailDc: "",
                                        lawordDetailNm: lawNm,
                                        lawordTy: "",
                                        dcsnAt: ""
                                    });
                                }
                                setExcptHouseLawList(rtnHouseLawList);

                                if (res.item.lawList.length > 0) {
                                    AppContext.hideSpinner();
                                    return;
                                }
                                calcLaword();
                            }
                        });
                    }
                });
            }
        });
    }, [props.form.rmstnfamSn, props.form.assetsSn, calcLaword, props.form.rmstnfamSe]);

    return (
        <React.Fragment>
            <AssetsEditTab
                wizard={props.wizard}
                assetsSn={props.form.assetsSn}
                estateSttus={props.form.estateSttus}
                estateDetailSe={estateDetailSe}
                acqsCause={acqsCause}
                acqsSrc={acqsSrc}
                currStepCode={1006}
             />
            <div className='w1210 mh500'>
                <AssetsTitle propsForm={props.form} asterlink={false} />
                <div className="dpFlx aiC jcSb mb20">
                    <div className='dpFlx jcL aiC'>
                        <span className='SpoqaHanSansNeo-Bold fs15 ls075 fc222222'>근거법령</span>
                    </div>
                    <div className='dpFlx jcL aiC csP' onClick={calcLaword}>
                        <span className='SpoqaHanSansNeo-Regular fs14 ls07 fc17A840'>주택분류 다시하기</span>
                        <img className="pl5" src={require('img/system/assets/icon_refresh20.png')} alt="icon_refresh20" />
                    </div>
                </div>
                <table className={
                    "forest-list-table w100p h48 mb40 " +
                    "bdcDDDDDD bdt1 bdb1 " +
                    "th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc2B2B2B th-ls07 " +
                    "td-bdcDDDDDD td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-ls1"
                }>
                    <colgroup>
                        <col className="w120" />
                        <col />
                        <col className="w80" />
                        <col className="w120" />
                    </colgroup>
                    <tbody>
                        <tr className="h48">
                            <th>구분</th>
                            <th>적용 근거법</th>
                            <th>내용</th>
                            <th>해당주택 여부</th>
                        </tr>
                        {excptHouseLawList && excptHouseLawList.map((item: any, idx: number) =>
                            <tr key={idx}>
                                <td>기타정보</td>
                                <td className="taL pl20">{item.lawordDetailNm}</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                        )}
                        {lawList && lawList.map((item: any, idx: number) => {
                            if (Util.isNotEmpty(item.lawordSn)) {
                                return (
                                    <tr key={idx}>
                                        <td>{Util.getValToCode("1049", item.lawordTy)}</td>
                                        <td className="taL pl20">{item.lawordDetailNm} - {item.lawordDetailDc}</td>
                                        <td>
                                            <PlagiarismOutlinedIcon className="csP" onClick={() => {
                                                setLawordSn(item.lawordSn);
                                                setLawordDetailSn(item.lawordDetailSn);
                                                setLawModalOpen(true);
                                            }}/>
                                        </td>
                                        <td>{Util.isEmpty(item.dcsnAt) ? "내용확인필요" : Util.getValToCode("1032", item.dcsnAt)}</td>
                                    </tr>
                                )
                            } else {
                                if (excptHouseLawList.length > 0) {
                                    return (<></>);
                                }
                                return (
                                    <tr className="h236" key={idx}>
                                        <td colSpan={4}>
                                            <span className="SpoqaHanSansNeo-Medium fs16 ls08 fc666666">주택분류 정보가 없습니다.</span>
                                        </td>
                                    </tr>
                                )
                            }
                        })}
                        {excptHouseLawList.length === 0 && lawList && lawList.length === 0 ?
                            <tr className="h236">
                                <td colSpan={4}>
                                    <span className="SpoqaHanSansNeo-Medium fs16 ls08 fc666666">주택분류 정보가 없습니다.</span>
                                </td>
                            </tr> : <></>
                        }
                    </tbody>
                </table>
                <AssetsButton propsForm={props.form} goNextStep={goNextStep} goPrevStep={goPrevStep} />
            </div>
            {/* <AssetsButtonBar propsForm={props.form} goNextStep={goNextStep} goPrevStep={goPrevStep} /> */}
            <Modal modalOpen={lawModalOpen} setModalOpen={setLawModalOpen} title={lawModalTitle} dim={true} visible={true} closeCallBack={() => setLawModalTitle("")}>
                <LawDialog lawordSn={lawordSn} lawordDetailSn={lawordDetailSn} setModalOpen={setLawModalOpen} setLawModalTitle={setLawModalTitle} callMode="modify" callBack={(dcsnAt: string) => {
                    keepDcsnAtData(dcsnAt);
                    setLawModalTitle("")
                }} />
            </Modal>
        </React.Fragment>
    )
}
export default AssetsLaw;