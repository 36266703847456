/*******************************************************************************
*** SimTranAutoTax ** 양도세 간편계산기 * 주소 API/직접 입력 자동 분기
*******************************************************************************/
import AppContext          from "js/common/AppContext";
import Util                from "js/common/Util";
import SimAddress          from "js/common/address/SimAddress";
import NumberTextBox       from "js/common/ui/input/NumberTextBox";
import ImageRadioBox       from "js/common/ui/input/ImageRadioBox";
import SelectBox           from "js/common/ui/input/SelectBox";
import Modal               from "js/common/ui/modal/Modal";
import React, { useState } from "react";
import DatePickerBox       from "js/common/ui/input/DatePickerBox";
import SimTranTaxCalc      from "./SimTranTaxCalc";
import ToolTip             from "js/common/ui/ToolTip";
import DirectAddressIn     from "js/common/address/DirectAddressIn";

interface SimAcqsAutoTaxProps {
    apiResult: string;
}

const SimTranAutoTax = ({apiResult}: SimAcqsAutoTaxProps) => {
    const [estateSe         , setEstateSe         ] = useState<string>("");   // 01.부동산의 종류
    const [estateFom1       , setEstateFom1       ] = useState<string>("");   // 02.주택구분1(주택)
    const [estateFom2       , setEstateFom2       ] = useState<string>("");   // 03.주택구분2(오피스텔)
    const [landCls          , setLandCls          ] = useState<string>("");   // 04.토지구분
    const [acqsSrc1         , setAcqsSrc1         ] = useState<string>("");   // 05.취득구분1(아파트/연립)
    const [acqsSrc2         , setAcqsSrc2         ] = useState<string>("");   // 06.취득구분2(오피스텔)
    const [acqsCause1       , setAcqsCause1       ] = useState<string>("");   // 07.취득원인1(기존 아파트)
    const [acqsCause2       , setAcqsCause2       ] = useState<string>("");   // 08.취득원인2(분양권/오피스텔)
    const [acqsCause3       , setAcqsCause3       ] = useState<string>("");   // 09.취득원인3(조합원입주권)
    const [acqsCause4       , setAcqsCause4       ] = useState<string>("");   // 10.취득원인4(다세대/다가구/다중/단독)
    const [hseNum           , setHseNum           ] = useState<string>("");   // 11.보유주택 수
    const [rdnmAdres        , setRdnmAdres        ] = useState<string>("");   // 12.도로명 주소
    const [lnmAdres         , setLnmAdres         ] = useState<string>("");   // 13.지번 주소
    const [mdatTrgetArea    , setMdatTrgetArea    ] = useState<string>("");   // 14.조정대상지역
    const [shareRatio       , setShareRatio       ] = useState<string>("");   // 15.지분율
    const [mngDispAppDate   , setMngDispAppDate   ] = useState<string>("");   // 16.관리처분인가일
    const [mngDispAppDateAmt, setMngDispAppDateAmt] = useState<string>("");   // 17.관리처분인가일 평가액
    const [acquDatePreHse   , setAcquDatePreHse   ] = useState<string>("");   // 18.종전주택 취득일자
    const [acquDateInherit  , setAcquDateInherit  ] = useState<string>("");   // 18-1.피상속인 취득일자
    const [resiPeriodPreHse , setResiPeriodPreHse ] = useState<string>("");   // 19.종전주택 거주기간
    const [acquPrice        , setAcquPrice        ] = useState<string>("");   // 20.취득가액
    const [neceExpenPreHse  , setNeceExpenPreHse  ] = useState<string>("");   // 21.종전주택 필요경비
    const [apprDateNewHse   , setApprDateNewHse   ] = useState<string>("");   // 22.신규주택 사용승인일
    const [resiPeriodNewHse , setResiPeriodNewHse ] = useState<string>("");   // 23.신규주택 거주기간
    const [transferDate     , setTransferDate     ] = useState<string>("");   // 24.양도일자
    const [transferPrice    , setTransferPrice    ] = useState<string>("");   // 25.양도가액
    const [neceExpenNewHse  , setNeceExpenNewHse  ] = useState<string>("");   // 26.필요경비
    const [liqudAmt         , setLiqudAmt         ] = useState<string>("");   // 27.청산금 여부
    const [payOrRecAmt      , setPayOrRecAmt      ] = useState<string>("");   // 28.납부(수령)금액
    const [legalDongCode    , setLegalDongCode    ] = useState<string>("");   // 29.법정동코드
    

    // 입력항목 표시 여부
    const [visible01  , setVisible01  ] = React.useState<boolean>(true ); // 01.부동산의 종류
    const [visible02  , setVisible02  ] = React.useState<boolean>(false); // 02.주택구분1(주택)
    const [visible03  , setVisible03  ] = React.useState<boolean>(false); // 03.주택구분2(오피스텔)
    const [visible04  , setVisible04  ] = React.useState<boolean>(false); // 04.토지구분
    const [visible05  , setVisible05  ] = React.useState<boolean>(false); // 05.취득구분1(아파트/연립)
    const [visible06  , setVisible06  ] = React.useState<boolean>(false); // 06.취득구분2(오피스텔)
    const [visible07  , setVisible07  ] = React.useState<boolean>(false); // 07.취득원인1(기존 아파트)
    const [visible08  , setVisible08  ] = React.useState<boolean>(false); // 08.취득원인2(분양권/오피스텔)
    const [visible09  , setVisible09  ] = React.useState<boolean>(false); // 09.취득원인3(조합원입주권)
    const [visible10  , setVisible10  ] = React.useState<boolean>(false); // 10.취득원인4(다세대/다가구/다중/단독)
    const [visible11  , setVisible11  ] = React.useState<boolean>(false); // 11.보유주택 수
    const [visible12  , setVisible12  ] = React.useState<boolean>(false); // 12.양도할 자산정보
    const [visible13  , setVisible13  ] = React.useState<boolean>(true ); // 13.양도할 주택 추가 버튼
    const [visible14  , setVisible14  ] = React.useState<boolean>(false); // 14.자산정보 Table
    const [visible15  , setVisible15  ] = React.useState<boolean>(false); // 15.지분율
    const [visible16  , setVisible16  ] = React.useState<boolean>(false); // 16.관리처분인가일
    const [visible17  , setVisible17  ] = React.useState<boolean>(false); // 17.관리처분인가일 평가액
    const [visible18  , setVisible18  ] = React.useState<boolean>(false); // 18.종전주택 취득일자
    const [visible18_1, setVisible18_1] = React.useState<boolean>(false); // 18-1.피상속인 취득일자
    const [visible19  , setVisible19  ] = React.useState<boolean>(false); // 19.종전주택 거주기간
    const [visible20  , setVisible20  ] = React.useState<boolean>(false); // 20.취득가액
    const [visible21  , setVisible21  ] = React.useState<boolean>(false); // 21.종전주택 필요경비
    const [visible22  , setVisible22  ] = React.useState<boolean>(false); // 22.신규주택 사용승인일
    const [visible23  , setVisible23  ] = React.useState<boolean>(false); // 23.신규주택 거주기간
    const [visible24  , setVisible24  ] = React.useState<boolean>(false); // 24.양도일자
    const [visible25  , setVisible25  ] = React.useState<boolean>(false); // 25.양도가액
    const [visible26  , setVisible26  ] = React.useState<boolean>(false); // 26.필요경비
    const [visible27  , setVisible27  ] = React.useState<boolean>(false); // 27.청산금 여부
    const [visible28  , setVisible28  ] = React.useState<boolean>(false); // 28.납부(수령)금액

    // 입력항목 입력 가능여부
    const [disabled01, setDisabled01] = React.useState<boolean>(false); // 01. 부동산의 종류(상세구분)
    const [disabled02, setDisabled02] = React.useState<boolean>(false); // 02. 주택구분
    const [disabled03, setDisabled03] = React.useState<boolean>(false); // 03.주택구분2(오피스텔)
    const [disabled05, setDisabled05] = React.useState<boolean>(false); // 05.취득구분1(아파트/연립)
    const [disabled06, setDisabled06] = React.useState<boolean>(false); // 06.취득구분2(오피스텔)
    const [disabled09, setDisabled09] = React.useState<boolean>(false); // 09.취득원인3(조합원입주권)

    // 입력항목 TEXT 변경
    const [title18, setTitle18] = React.useState<string>("종전주택 취득일자"); // 18.(종전주택)취득일자
    const [title19, setTitle19] = React.useState<string>("종전주택 거주기간"); // 19.(종전주택)거주기간

    const [simTranTaxCalcModalOpen, setSimTranTaxCalcModalOpen] = React.useState<boolean>(false); // 재산세/종합부동산세 계산 결과 모달
    const [simTranTaxCalcModalVsb , setSimTranTaxCalcModalVsb ] = React.useState<boolean>(false); // 재산세/종합부동산세 결과 모달 표시 여부
    const [tranTaxParam           , setTranTaxParam           ] = React.useState<TranTaxParamProps[]>([]); // 양도세 파라미터 배열

    const [addressModalOpen     , setAddressModalOpen     ] = React.useState<boolean>(false); // 주소 API 검색 모달 호출변수
    const [dirAddressInModalOpen, setDirAddressInModalOpen] = React.useState<boolean>(false); // 주소 직접검색 모달 호출변수

    // 취득세 계산 입력정보 Setting
    interface  TranTaxParamProps {
        taxFlag          : string;  // 00.세금구분 Flag
        estateSe         : string;  // 01.부동산의 종류
        estateFom1       : string;  // 02.주택구분1(주택)
        estateFom2       : string;  // 03.주택구분2(오피스텔)
        landCls          : string;  // 04.토지구분
        acqsSrc1         : string;  // 05.취득구분1(아파트/연립)
        acqsSrc2         : string;  // 06.취득구분2(오피스텔)
        acqsCause1       : string;  // 07.취득원인1(기존 아파트)
        acqsCause2       : string;  // 08.취득원인2(분양권/오피스텔)
        acqsCause3       : string;  // 09.취득원인3(조합원입주권)
        acqsCause4       : string;  // 10.취득원인4(다세대/다가구/다중/단독)
        hseNum           : string;  // 11.보유주택 수
        rdnmAdres        : string;  // 12.도로명 주소
        lnmAdres         : string;  // 13.지번 주소
        mdatTrgetArea    : string;  // 14.조정대상지역
        shareRatio       : string;  // 15.지분율
        mngDispAppDate   : string;  // 16.관리처분인가일
        mngDispAppDateAmt: string;  // 17.관리처분인가일 평가액
        acquDatePreHse   : string;  // 18.종전주택 취득일자
        acquDateInherit  : string;  // 18-1.피상속인 취득일자
        resiPeriodPreHse : string;  // 19.종전주택 거주기간
        acquPrice        : string;  // 20.취득가액
        neceExpenPreHse  : string;  // 21.종전주택 필요경비
        apprDateNewHse   : string;  // 22.신규주택 사용승인일
        resiPeriodNewHse : string;  // 23.신규주택 거주기간
        transferDate     : string;  // 24.양도일자
        transferPrice    : string;  // 25.양도가액
        neceExpenNewHse  : string;  // 26.필요경비
        liqudAmt         : string;  // 27.청산금 여부
        payOrRecAmt      : string;  // 28.납부(수령)금액
        legalDongCode    : string;  // 29.법정도코드
    }

    // 주택 추가 callback
    const onFinishAddrSearch = (item: any) => {
        setRdnmAdres(item.rdnmAdres + " " + item.rdnmAdresDetail);  // 도로명 주소
        if (item.lnmAdres !== undefined) {
            setLnmAdres(item.lnmAdres  + " " + item.lnmAdresDetail ); // 지번 주소
        }        
        setMdatTrgetArea (item.mdatTrgetAreaAt);    // 조정대상지역
        setShareRatio    (item.shareRatio     );    // 지분율
        setApprDateNewHse(item.apprDateNewHse );    // 사용승인일
        setLegalDongCode (item.legaldongCode  );    // 법정동 코드

        setVisible13(false); // 양도할 자산정보 추가 버튼 미표시
        setVisible14(true ); // 자산정보 Table 표시

        // 주택 > 아파트/연립 > 기존 아파트 
        if (estateSe === "201" && estateFom1 === "201" && acqsSrc1 === "201") {
            setTitle18("취득일자");
            setTitle19("거주기간");

            if (acqsCause1 === "204") setVisible18_1(true);    // 상속 : 18-1.피상속인 취득일자

            // 매매/상속/증여
            if (hseNum === "201") {     // 11.보유주택 수 : 1주택(대체취득 포함)
                setVisible18(true);  // 18.(종전주택)취득일자
                setVisible19(true);  // 19.(종전주택)거주기간
                setVisible20(true);  // 20.취득가액
                setVisible24(true);  // 24.양도일자
                setVisible25(true);  // 25.양도가액
                setVisible26(true);  // 26.필요경비
            } else {    // 2주택, 3주택 이상
                setVisible18(true ); // 18.(종전주택) 취득일자
                setVisible20(true ); // 20.취득가액
                setVisible24(true ); // 24.양도일자
                setVisible25(true ); // 25.양도가액
                setVisible26(true ); // 26.필요경비

                setVisible19(false    ); // 19.(종전주택) 거주기간 미표시
                setResiPeriodPreHse(""); // 19.(종전주택) 거주기간 초기화
            }

        // 주택 > 아파트/연립 > 분양권에 의한 아파트 
        } else if (estateSe === "201" && estateFom1 === "201" && acqsSrc1 === "202") {
            setTitle18("취득일자");
            setTitle19("거주기간");

            // 매매/상속/증여
            if (hseNum === "201") {     // 11.보유주택 수 : 1주택(대체취득 포함)
                setVisible18(true);  // 18.(종전주택)취득일자
                setVisible19(true);  // 19.(종전주택)거주기간
                setVisible20(true);  // 20.취득가액
                setVisible24(true);  // 24.양도일자
                setVisible25(true);  // 25.양도가액
                setVisible26(true);  // 26.필요경비
            } else {    // 2주택, 3주택 이상
                setVisible18(true ); // 18.(종전주택) 취득일자
                setVisible20(true ); // 20.취득가액
                setVisible24(true ); // 24.양도일자
                setVisible25(true ); // 25.양도가액
                setVisible26(true ); // 26.필요경비

                setVisible19(false    ); // 19.(종전주택) 거주기간 미표시
                setResiPeriodPreHse(""); // 19.(종전주택) 거주기간 초기화
            }

        // 주택 > 아파트/연립 > 조합원입주권에 의한 아파트
        } else if (estateSe === "201" && estateFom1 === "201" && acqsSrc1 === "203") {
            //  09.취득원인3(조합원입주권) : 원조합원
            if (acqsCause3 === "202") {
                setTitle18("종전주택 취득일자");
                setTitle19("종전주택 거주기간");

                if (hseNum === "201") {  // 11.보유주택 수 : 1주택(대체취득 포함)
                    setVisible16(true);  // 16.관리처분인가일
                    setVisible17(true);  // 17.관리처분인가일 평가액
                    setVisible18(true);  // 18.종전주택 취득일자
                    setVisible19(true);  // 19.종전주택 거주기간
                    setVisible20(true);  // 20.취득가액
                    setVisible21(true);  // 21.종전주택 필요경비
                    setVisible22(true);  // 22.신규주택 사용승인일
                    setVisible23(true);  // 23.신규주택 거주기간
                    setVisible24(true);  // 24.양도일자
                    setVisible25(true);  // 25.양도가액
                    setVisible26(true);  // 26.필요경비
                    setVisible27(true);  // 27.청산금 여부
                } else {    // 2주택, 3주택 이상
                    setVisible16(true ); // 16.관리처분인가일
                    setVisible17(true ); // 17.관리처분인가일 평가액
                    setVisible18(true ); // 18.종전주택 취득일자
                    setVisible20(true ); // 20.취득가액
                    setVisible21(true ); // 21.종전주택 필요경비
                    setVisible22(true ); // 22.신규주택 사용승인일
                    setVisible24(true ); // 24.양도일자
                    setVisible25(true ); // 25.양도가액
                    setVisible26(true ); // 26.필요경비
                    setVisible27(true ); // 27.청산금 여부

                    setVisible19(false    ); // 19.종전주택 거주기간 미표시
                    setResiPeriodPreHse(""); // 19.종전주택 거주기간 초기화
                    setVisible23(false    ); // 23.신규주택 거주기간 미표시
                    setResiPeriodNewHse(""); // 23.신규주택 거주기간 초기화
                }
            } else {    // 전매(승계조합원), 상속, 증여
                setTitle18("취득일자");
                setTitle19("거주기간");

                if (acqsCause1 === "204") setVisible18_1(true);    // 상속 : 18-1.피상속인 취득일자

                if (hseNum === "201") {     // 11.보유주택 수 : 1주택(대체취득 포함)
                    setVisible18(true);  // 18.종전주택 취득일자
                    setVisible19(true);  // 19.종전주택 거주기간
                    setVisible20(true);  // 20.취득가액
                    setVisible24(true);  // 24.양도일자
                    setVisible25(true);  // 25.양도가액
                    setVisible26(true);  // 26.필요경비
                } else {    // 2주택, 3주택 이상
                    setVisible18(true ); // 18.(종전주택) 취득일자
                    setVisible20(true ); // 20.취득가액
                    setVisible24(true ); // 24.양도일자
                    setVisible25(true ); // 25.양도가액
                    setVisible26(true ); // 26.필요경비

                    setVisible19(false    ); // 19.(종전주택) 거주기간 미표시
                    setResiPeriodPreHse(""); // 19.종전주택 거주기간 초기화
                }
            }
        } else {}

        // 주택 > 다세대/다가구/다중/단독주택
        if (estateFom1 === "202") {
            setTitle18("취득일자");
            setTitle19("거주기간");

            if (acqsCause4 === "204") setVisible18_1(true);    // 상속 : 18-1.피상속인 취득일자

            // 매매, 상속, 증여, 자가신축
            if (hseNum === "201") {     // 11.보유주택 수 : 1주택(대체취득 포함)
                setVisible18(true);  // 18.종전주택 취득일자
                setVisible19(true);  // 19.종전주택 거주기간
                setVisible20(true);  // 20.취득가액
                setVisible24(true);  // 24.양도일자
                setVisible25(true);  // 25.양도가액
                setVisible26(true);  // 26.필요경비
            } else {    // 2주택, 3주택 이상
                setVisible18(true ); // 18.(종전주택) 취득일자
                setVisible20(true ); // 20.취득가액
                setVisible24(true ); // 24.양도일자
                setVisible25(true ); // 25.양도가액
                setVisible26(true ); // 26.필요경비

                setVisible19(false    ); // 19.(종전주택) 거주기간 미표시
                setResiPeriodPreHse(""); // 19.종전주택 거주기간 초기화
            }            
        }

        // 조합원입주권
        if (estateSe === "203") {
            //  09.취득원인3(조합원입주권) : 원조합원
            if (acqsCause3 === "202") {
                setTitle18("종전주택 취득일자");
                setTitle19("종전주택 거주기간");

                if (hseNum === "201") {  // 11.보유주택 수 : 1주택(대체취득 포함)
                    setVisible16(true);  // 16.관리처분인가일
                    setVisible17(true);  // 17.관리처분인가일 평가액
                    setVisible18(true);  // 18.종전주택 취득일자
                    setVisible19(true);  // 19.종전주택 거주기간
                    setVisible20(true);  // 20.취득가액
                    setVisible21(true);  // 21.종전주택 필요경비
                    setVisible24(true);  // 24.양도일자
                    setVisible25(true);  // 25.양도가액
                    setVisible26(true);  // 26.필요경비
                    setVisible27(true);  // 27.청산금 여부
                } else {    // 2주택, 3주택 이상
                    setVisible16(true ); // 16.관리처분인가일
                    setVisible17(true ); // 17.관리처분인가일 평가액
                    setVisible18(true ); // 18.종전주택 취득일자
                    setVisible20(true ); // 20.취득가액
                    setVisible21(true ); // 21.종전주택 필요경비
                    setVisible24(true ); // 24.양도일자
                    setVisible25(true ); // 25.양도가액
                    setVisible26(true ); // 26.필요경비
                    setVisible27(true ); // 27.청산금 여부

                    setVisible19(false    ); // 19.종전주택 거주기간 미표시
                    setResiPeriodPreHse(""); // 19.종전주택 거주기간 초기화
                }
            }
        }
        
        // 오피스텔 > 주거용 오피스텔 > 기존 오피스텔 or 분양 오피스텔
        if (estateSe === "204" && estateFom2 === "201") {
            setTitle18("취득일자");
            setTitle19("거주기간");
            
            // 기존오피스텔 or 분양 오피스텔 상속
            if (acqsCause1 === "204" || acqsCause2 === "204") setVisible18_1(true);    // 상속 : 18-1.피상속인 취득일자

            // 매매/상속/증여
            if (hseNum === "201") {     // 11.보유주택 수 : 1주택(대체취득 포함)
                setVisible18(true);  // 18.(종전주택)취득일자
                setVisible19(true);  // 19.(종전주택)거주기간
                setVisible20(true);  // 20.취득가액
                setVisible24(true);  // 24.양도일자
                setVisible25(true);  // 25.양도가액
                setVisible26(true);  // 26.필요경비
            } else {    // 2주택, 3주택 이상
                setVisible18(true ); // 18.(종전주택) 취득일자
                setVisible20(true ); // 20.취득가액
                setVisible24(true ); // 24.양도일자
                setVisible25(true ); // 25.양도가액
                setVisible26(true ); // 26.필요경비

                setVisible19(false    ); // 19.(종전주택) 거주기간 미표시
                setResiPeriodPreHse(""); // 19.(종전주택) 거주기간 초기화
            }
        }

    }

    // 주택(부동산) 정보 삭제
    const delHousing = () => {
        setRdnmAdres     ("");  // 도로명 주소
        setLnmAdres      ("");  // 지번 주소
        setMdatTrgetArea ("");  // 조정대상지역
        setShareRatio    ("");  // 지분율  
        setApprDateNewHse("");  // 사용승인일
        
        setVisible13(true ); // 양도할 자산정보 추가 버튼 표시
        setVisible14(false); // 자산정보 Table 미표시    
        
        setVisible15(false);  // 15.지분율
        setVisible16(false);  // 16.관리처분인가일
        setVisible17(false);  // 17.관리처분인가일 평가액
        setVisible18(false);  // 18.종전주택 취득일자
        setVisible18_1(false);  // 18-1.피상속인 취득일자
        setVisible19(false);  // 19.종전주택 거주기간
        setVisible20(false);  // 20.취득가액
        setVisible21(false);  // 21.종전주택 필요경비
        setVisible22(false);  // 22.신규주택 사용승인일
        setVisible23(false);  // 23.신규주택 거주기간
        setVisible24(false);  // 24.양도일자
        setVisible25(false);  // 25.양도가액
        setVisible26(false);  // 26.필요경비
        setVisible27(false);  // 27.청산금 여부
        setVisible28(false);  // 28.청산금 납부(수령)금액

        setMngDispAppDate   (""); // 16.관리처분인가일
        setMngDispAppDateAmt(""); // 17.관리처분인가일 평가액
        setAcquDatePreHse   (""); // 18.종전주택 취득일자
        setAcquDateInherit  (""); // 18-1.피상속인 취득일자
        setResiPeriodPreHse (""); // 19.종전주택 거주기간
        setAcquPrice        (""); // 20.취득가액
        setNeceExpenPreHse  (""); // 21.종전주택 필요경비
        // setApprDateNewHse   (""); // 22.신규주택 사용승인일
        setResiPeriodNewHse (""); // 23.신규주택 거주기간
        setTransferDate     (""); // 24.양도일자
        setTransferPrice    (""); // 25.양도가액
        setNeceExpenNewHse  (""); // 26.필요경비
        setLiqudAmt         (""); // 27.청산금 여부
        setPayOrRecAmt      (""); // 28.청산금 납부(수령)금액
    }

    // 초기화
    const goReset = () => {
        setEstateSe         ("");
        setEstateFom1       ("");
        setEstateFom2       ("");
        setLandCls          ("");
        setAcqsSrc1         ("");
        setAcqsSrc2         ("");
        setAcqsCause1       ("");
        setAcqsCause2       ("");
        setAcqsCause3       ("");
        setAcqsCause4       ("");
        setHseNum           ("");
        setRdnmAdres        ("");
        setLnmAdres         ("");
        setMdatTrgetArea    ("");
        setShareRatio       ("");
        setMngDispAppDate   ("");
        setMngDispAppDateAmt("");
        setAcquDatePreHse   ("");
        setAcquDateInherit  ("");
        setResiPeriodPreHse ("");
        setAcquPrice        ("");
        setNeceExpenPreHse  ("");
        setApprDateNewHse   ("");
        setResiPeriodNewHse ("");
        setTransferDate     ("");
        setTransferPrice    ("");
        setNeceExpenNewHse  ("");
        setLiqudAmt         ("");
        setPayOrRecAmt      ("");

        setVisible01(true );
        setVisible02(false);
        setVisible03(false);
        setVisible04(false);
        setVisible05(false);
        setVisible06(false);
        setVisible07(false);
        setVisible08(false);
        setVisible09(false);
        setVisible10(false);
        setVisible11(false);
        setVisible12(false);
        setVisible13(true ); // 양도할 자산정보 추가 Button
        setVisible14(false); 
        setVisible15(false);
        setVisible16(false);
        setVisible17(false);
        setVisible18(false);
        setVisible18_1(false);
        setVisible19(false);
        setVisible20(false);
        setVisible21(false);
        setVisible22(false);
        setVisible23(false);
        setVisible24(false);
        setVisible25(false);
        setVisible26(false);
        setVisible27(false);
        setVisible28(false); 
        
        setDisabled01(false);
        setDisabled02(false);
        setDisabled03(false);
        setDisabled05(false);
        setDisabled06(false);
        setDisabled09(false);

        setTranTaxParam([]);
    }

    const callTax = (taxFlag:string) => {
        // 입력 값 확인 메시지
        if (visible01) {if (Util.isEmpty(estateSe  )) {AppContext.openAlert({title: "부동산의 종류"            , msg: "부동산의 종류를 선택하세요.", icon: "check"}); return;}}
        if (visible02) {if (Util.isEmpty(estateFom1)) {AppContext.openAlert({title: "주택구분(주택)"           , msg: "주택구분을 선택하세요."     , icon: "check"}); return;}}
        if (visible03) {if (Util.isEmpty(estateFom2)) {AppContext.openAlert({title: "오피스텔 구분"            , msg: "오피스텔 구분을 선택하세요.", icon: "check"}); return;}}
        if (visible04) {if (Util.isEmpty(landCls   )) {AppContext.openAlert({title: "토지구분"                 , msg: "토지구분을 선택하세요."     , icon: "check"}); return;}}
        if (visible05) {if (Util.isEmpty(acqsSrc1  )) {AppContext.openAlert({title: "취득구분(아파트/연립)"    , msg: "취득구분을 선택하세요."     , icon: "check"}); return;}}
        if (visible06) {if (Util.isEmpty(acqsSrc2  )) {AppContext.openAlert({title: "취득구분(오피스텔)"       , msg: "취득구분을 선택하세요."     , icon: "check"}); return;}}
        if (visible07) {if (Util.isEmpty(acqsCause1)) {AppContext.openAlert({title: "취득원인(기존 아파트)"    , msg: "취득원인을 선택하세요."     , icon: "check"}); return;}}
        if (visible08) {if (Util.isEmpty(acqsCause2)) {AppContext.openAlert({title: "취득원인(분양권/오피스텔)", msg: "취득원인을 선택하세요."     , icon: "check"}); return;}}
        if (visible09) {if (Util.isEmpty(acqsCause3)) {AppContext.openAlert({title: "취득원인(조합원입주권)"   , msg: "취득원인을 선택하세요."     , icon: "check"}); return;}}
        if (visible10) {if (Util.isEmpty(acqsCause4)) {AppContext.openAlert({title: "취득원인(다/단독)"        , msg: "취득원인을 선택하세요."     , icon: "check"}); return;}}
        if (visible11) {if (Util.isEmpty(hseNum    )) {AppContext.openAlert({title: "보유주택 수"              , msg: "보유주택 수를 선택하세요."  , icon: "check"}); return;}}
        
        // 주소 입력 자산 정보
        if (visible12) {if (Util.isEmpty(rdnmAdres)) {AppContext.openAlert({title: "양도할 자산정보", msg: "양도할 자산정보를 추가하세요.", icon: "check"}); return;}}

        if (visible15) {
            if (Util.isEmpty(shareRatio  )) {AppContext.openAlert({title: "지분율"                          , msg: "지분율을 입력하세요."          , icon: "check"}); return;}
            if (Number(shareRatio) <= 0 || Number(shareRatio) > 100)  {AppContext.openAlert({title: "지분율", msg: "지분율을 1~100으로 입력하세요.", icon: "check"}); return;}
        }

        if (visible16) {if (Util.isEmpty(mngDispAppDate)) {AppContext.openAlert({title: "관리처분인가일", msg: "관리처분인가일를 입력하세요.", icon: "check"}); return;}}

        if (visible17) {
            if (Util.isEmpty(mngDispAppDateAmt)) {AppContext.openAlert({title: "관리처분인가일 평가액", msg: "관리처분인가일 평가액을 입력하세요.", icon: "check"}); return;}
            if (Number(mngDispAppDateAmt) <= 0 ) {AppContext.openAlert({title: "관리처분인가일 평가액", msg: "관리처분인가일 평가액을 입력하세요.", icon: "check"}); return;}
        }
        
        if (visible18)   {if (Util.isEmpty(acquDatePreHse  )) {AppContext.openAlert({title: title18            , msg: title18 + "를 입력하세요."       , icon: "check"}); return;}}  // 취득일자
        if (visible18_1) {if (Util.isEmpty(acquDateInherit )) {AppContext.openAlert({title: "피상속인 취득일자", msg: "피상속인 취득일자를 입력하세요.", icon: "check"}); return;}}  // 피상속인 취득일자
        if (visible19)   {if (Util.isEmpty(resiPeriodPreHse)) {AppContext.openAlert({title: title19            , msg: title19 + "을 입력하세요."       , icon: "check"}); return;}}  // 거주기간
        
        if (visible20) {
            if (Util.isEmpty(acquPrice)) {AppContext.openAlert({title: "취득가액", msg: "취득가액을 입력하세요.", icon: "check"}); return;}
            if (Number(acquPrice) <= 0 ) {AppContext.openAlert({title: "취득가액", msg: "취득가액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible21) {
            if (Util.isEmpty(neceExpenPreHse)) {AppContext.openAlert({title: "종전주택 필요경비", msg: "종전주택 필요경비를 입력하세요.", icon: "check"}); return;}
            if (Number(neceExpenPreHse) <= 0 ) {AppContext.openAlert({title: "종전주택 필요경비", msg: "종전주택 필요경비를 입력하세요.", icon: "check"}); return;}
        }

        if (visible22) {if (Util.isEmpty(apprDateNewHse)) {AppContext.openAlert({title: "신규주택 사용승인일", msg: "신규주택 사용승인일을 입력하세요.", icon: "check"}); return;}}

        if (visible23) {
            if (Util.isEmpty(resiPeriodNewHse)) {AppContext.openAlert({title: "신규주택 거주기간", msg: "신규주택 거주기간을 입력하세요.", icon: "check"}); return;}
            if (Number(resiPeriodNewHse) < 0  ) {AppContext.openAlert({title: "신규주택 거주기간", msg: "신규주택 거주기간을 입력하세요.", icon: "check"}); return;}
        }

        if (visible24) {if (Util.isEmpty(transferDate)) {AppContext.openAlert({title: "양도일자", msg: "양도일자를 입력하세요.", icon: "check"}); return;}}

        // 취득일자 & 양도일자 체크
        if (Number(acquDatePreHse) > Number(transferDate)) {AppContext.openAlert({title: "양도일자", msg: "양도일자가 취득일자보다 빠릅니다. 양도일자를 확인하세요.", icon: "check"}); return;}

        if (visible25) {
            if (Util.isEmpty(transferPrice)) {AppContext.openAlert({title: "양도가액", msg: "양도가액을 입력하세요.", icon: "check"}); return;}
            if (Number(transferPrice) <= 0 ) {AppContext.openAlert({title: "양도가액", msg: "양도가액을 입력하세요.", icon: "check"}); return;}
        }       

        if (visible26) {
            if (Util.isEmpty(neceExpenNewHse)) {AppContext.openAlert({title: "필요경비", msg: "필요경비를 입력하세요.", icon: "check"}); return;}
            if (Number(neceExpenNewHse) < 0  ) {AppContext.openAlert({title: "필요경비", msg: "필요경비를 입력하세요.", icon: "check"}); return;}
        }
        
        if (visible27) {if (Util.isEmpty(liqudAmt)) {AppContext.openAlert({title: "청산금 여부", msg: "청산금 여부를 선택하세요.", icon: "check"}); return;}}
        
        if (visible28) {
            if (Util.isEmpty(payOrRecAmt)) {AppContext.openAlert({title: "납부(수령)금액", msg: "납부(수령)금액을 입력하세요.", icon: "check"}); return;}
            if (Number(payOrRecAmt) <= 0 ) {AppContext.openAlert({title: "납부(수령)금액", msg: "납부(수령)금액을 입력하세요.", icon: "check"}); return;}
        }

        // 입력한 사항을 배열로 생성하여 파라미터로 전달
        setTranTaxParam([
            ...tranTaxParam,     // 배열 전개(spread operator) 사용 배열 복사본으로 새배열을 생성 : push는 배열을 변이하는 메서드로 비추천
            {
                  taxFlag          : taxFlag
                , estateSe         : estateSe                           // 01.부동산의 종류
                , estateFom1       : estateFom1                         // 02.주택구분1(주택)
                , estateFom2       : estateFom2                         // 03.주택구분2(오피스텔)
                , landCls          : landCls                            // 04.토지구분
                , acqsSrc1         : acqsSrc1                           // 05.취득구분1(아파트/연립)
                , acqsSrc2         : acqsSrc2                           // 06.취득구분2(오피스텔)
                , acqsCause1       : acqsCause1                         // 07.취득원인1(기존 아파트)
                , acqsCause2       : acqsCause2                         // 08.취득원인2(분양권/오피스텔)
                , acqsCause3       : acqsCause3                         // 09.취득원인3(조합원입주권)
                , acqsCause4       : acqsCause4                         // 10.취득원인4(다세대/다가구/다중/단독)
                , hseNum           : hseNum                             // 11.보유주택 수
                , rdnmAdres        : rdnmAdres                          // 12.도로명 주소
                , lnmAdres         : lnmAdres                           // 13.지번 주소
                , mdatTrgetArea    : mdatTrgetArea                      // 14.조정대상지역
                , shareRatio       : shareRatio                         // 15.지분율
                , mngDispAppDate   : mngDispAppDate                     // 16.관리처분인가일
                , mngDispAppDateAmt: Util.uncomma(mngDispAppDateAmt)    // 17.관리처분인가일 평가액
                , acquDatePreHse   : acquDatePreHse                     // 18.종전주택 취득일자
                , acquDateInherit  : acquDateInherit                    // 18-1.피상속인 취득일자
                , resiPeriodPreHse : resiPeriodPreHse                   // 19.종전주택 거주기간
                , acquPrice        : Util.uncomma(acquPrice)            // 20.취득가액
                , neceExpenPreHse  : Util.uncomma(neceExpenPreHse)      // 21.종전주택 필요경비
                , apprDateNewHse   : apprDateNewHse                     // 22.신규주택 사용승인일
                , resiPeriodNewHse : resiPeriodNewHse                   // 23.신규주택 거주기간
                , transferDate     : transferDate                       // 24.양도일자
                , transferPrice    : Util.uncomma(transferPrice)        // 25.양도가액
                , neceExpenNewHse  : Util.uncomma(neceExpenNewHse)      // 26.필요경비
                , liqudAmt         : liqudAmt                           // 27.청산금 여부
                , payOrRecAmt      : Util.uncomma(payOrRecAmt)          // 28.납부(수령)금액
                , legalDongCode    : legalDongCode                      // 29.법정동코드           
            }
          ]);        
          
        setSimTranTaxCalcModalOpen(true);
    }

    return (
            <div className='w940 mh500'>
                {/* 01. 부동산의 종류 */}
                <div className={(visible01 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt30"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>부동산의 종류</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={estateSe}
                            codeArray={Util.getCodeArray("2035")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            border="1px solid #dddddd"
                            disabled={disabled01}
                            onChangeFunc={(data: string) => {
                                setEstateSe(data);

                                if (data === "201") {   // 주택 ▶ 02.주택구분
                                    setVisible02(true);

                                    setVisible03(false); // 03.주택구분2(오피스텔) 미표시
                                    setEstateFom2(""  ); // 03.주택구분2(오피스텔) 초기화
                                    setVisible04(false); // 04.토지구분 미표시
                                    setLandCls  (""   ); // 04.토지구분 초기화
                                    setVisible07(false); // 07.취득원인1(기존 아파트) 미표시
                                    setAcqsCause1(""  ); // 07.취득원인1(기존 아파트) 초기화
                                    setVisible09(false); // 09.취득원인3(조합원입주권) 미표시
                                    setAcqsCause3(""  ); // 09.취득원인3(조합원입주권) 초기화
                                } else if (data === "202") { // 분양권 ▶ 07.취득원인1(기존 아파트)
                                    setVisible07(true);

                                    setVisible02(false); // 02.주택구분 미표시
                                    setEstateFom1(""  ); // 02.주택구분 초기화
                                    setVisible03(false); // 03.주택구분2(오피스텔) 미표시
                                    setEstateFom2(""  ); // 03.주택구분2(오피스텔) 초기화
                                    setVisible04(false); // 04.토지구분 미표시
                                    setLandCls  (""   ); // 04.토지구분 초기화
                                    setVisible09(false); // 09.취득원인3(조합원입주권) 미표시
                                    setAcqsCause3(""  ); // 09.취득원인3(조합원입주권) 초기화
                                } else if (data === "203") { // 조합원입주권 ▶ 09.취득원인3(조합원입주권) 
                                    setVisible09(true);

                                    setVisible02(false); // 02.주택구분 미표시
                                    setEstateFom1(""  ); // 02.주택구분 초기화
                                    setVisible03(false); // 03.주택구분2(오피스텔) 미표시
                                    setEstateFom2(""  ); // 03.주택구분2(오피스텔) 초기화
                                    setVisible04(false); // 04.토지구분 미표시
                                    setLandCls  (""   ); // 04.토지구분 초기화
                                    setVisible07(false); // 07.취득원인1(기존 아파트) 미표시
                                    setAcqsCause1(""  ); // 07.취득원인1(기존 아파트) 초기화
                                } else if (data === "204") { // 오피스텔 ▶ 03.주택구분2(오피스텔) 
                                    setVisible03(true);

                                    setVisible02(false); // 02.주택구분 미표시
                                    setEstateFom1(""  ); // 02.주택구분 초기화
                                    setVisible04(false); // 04.토지구분 미표시
                                    setLandCls  (""   ); // 04.토지구분 초기화
                                    setVisible07(false); // 07.취득원인1(기존 아파트) 미표시
                                    setAcqsCause1(""  ); // 07.취득원인1(기존 아파트) 초기화
                                    setVisible09(false); // 09.취득원인3(조합원입주권) 미표시
                                    setAcqsCause3(""  ); // 09.취득원인3(조합원입주권) 초기화
                                } else if (data === "205") { // 일반건물/상가 ▶ 07.취득원인1(기존 아파트)
                                    setVisible07(true);

                                    setVisible02(false); // 02.주택구분 미표시
                                    setEstateFom1(""  ); // 02.주택구분 초기화
                                    setVisible04(false); // 04.토지구분 미표시
                                    setLandCls  (""   ); // 04.토지구분 초기화
                                    setVisible03(false); // 03.주택구분2(오피스텔) 미표시
                                    setEstateFom2(""  ); // 03.주택구분2(오피스텔) 초기화
                                    setVisible09(false); // 09.취득원인3(조합원입주권) 미표시
                                    setAcqsCause3(""  ); // 09.취득원인3(조합원입주권) 초기화
                                } else if (data === "206") { // 토지 ▶ 04.토지구분
                                    setVisible04(true);

                                    setVisible02(false); // 02.주택구분 미표시
                                    setEstateFom1(""  ); // 02.주택구분 초기화
                                    setVisible03(false); // 03.주택구분2(오피스텔) 미표시
                                    setEstateFom2(""  ); // 03.주택구분2(오피스텔) 초기화
                                    setVisible07(false); // 07.취득원인1(기존 아파트) 미표시
                                    setAcqsCause1(""  ); // 07.취득원인1(기존 아파트) 초기화
                                    setVisible09(false); // 09.취득원인3(조합원입주권) 미표시
                                    setAcqsCause3(""  ); // 09.취득원인3(조합원입주권) 초기화
                                }
                            }} />
                    </div>
                </div>

                {/* 02. 주택구분1(주택) */}
                <div className={(visible02 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>주택구분</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={estateFom1}
                            codeArray={Util.getCodeArray("2002")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            border="1px solid #dddddd"
                            disabled={disabled02}
                            onChangeFunc={(data: string) => {
                                setEstateFom1(data);

                                if (data === "201") {   // 아파트/연립 ▶ 05.취득구분1(아파트/연립)
                                    setVisible05(true );
                                    setDisabled01(true);    // 01.부동산의 종류 Fixed

                                    setVisible10(false); // 10.취득원인4(다세대/다가구/다중/단독) 미표시
                                    setAcqsCause4(""  ); // 10.취득원인4(다세대/다가구/다중/단독) 초기화
                                } else if (data === "202") {    // 다세대/다가구/다중/단독 ▶ 10.취득원인4(다세대/다가구/다중/단독)
                                    setVisible10(true );
                                    setDisabled01(true);    // 01.부동산의 종류 Fixed

                                    setVisible05(false); // 05.취득구분1(아파트/연립) 미표시
                                    setAcqsSrc1 (""   ); // 05.취득구분1(아파트/연립) 초기화
                                }
                            }} />
                    </div>
                </div>

                {/* 03. 주택구분2(오피스텔) */}
                <div className={(visible03 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>오피스텔 구분</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={estateFom2}
                            codeArray={Util.getCodeArray("2037")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            border="1px solid #dddddd"
                            disabled={disabled03}
                            onChangeFunc={(data: string) => {
                                setEstateFom2(data);

                                if (data === "201") {   // 주거용 오피스텔 ▶ 06.취득구분2(오피스텔)
                                    setVisible06(true );
                                    setDisabled01(true); // 01.부동산의 종류 Fixed

                                    setVisible08(false); // 08.취득원인2(분양권/오피스텔) 미표시
                                    setAcqsCause2(""  ); // 08.취득원인2(분양권/오피스텔) 초기화
                                } else if (data === "202") {   // 업무용 오피스텔 ▶ 08.취득원인2(분양권/오피스텔)
                                    setVisible08(true );
                                    setDisabled01(true); // 01.부동산의 종류 Fixed
 
                                    setVisible06(false); // 06.취득구분2(오피스텔) 미표시
                                    setAcqsSrc2(""    ); // 06.취득구분2(오피스텔) 초기화
                                }
                            }} />
                    </div>
                </div>

                {/* 04. 토지구분 */}
                <div className={(visible04 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>토지구분</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={landCls}
                            codeArray={Util.getCodeArray("2036")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            border="1px solid #dddddd"
                            // disabled={disabled01}
                            onChangeFunc={(data: string) => {
                                setLandCls(data);

                                setVisible07(true ); // 07.취득원인1(기존 아파트) 표시
                                setDisabled01(true); // 01.부동산의 종류 Fixed
                            }} />
                    </div>
                </div>

                {/* 05.취득구분1(아파트/연립) */}
                <div className={(visible05 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>취득구분</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={acqsSrc1}
                            codeArray={Util.getCodeArray("2003")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            border="1px solid #dddddd"
                            disabled={disabled05}
                            onChangeFunc={(data: string) => {
                                setAcqsSrc1(data);

                                if (data === "201") {   // 기존 아파트 ▶ 07.취득원인1(기존 아파트)
                                    setVisible07(true );
                                    setDisabled02(true);    // 02.주택구분1(주택) Fixed

                                    setVisible08(false); // 08.취득원인2(분양권/오피스텔) 미표시
                                    setAcqsCause2(""  ); // 08.취득원인2(분양권/오피스텔) 초기화
                                    setVisible09(false); // 09.취득원인3(조합원입주권) 미표시   
                                    setAcqsCause3(""  ); // 09.취득원인3(조합원입주권) 초기화
                                } else if (data === "202") {   // 분양권에 의한 아파트 ▶ 08.취득원인2(분양권/오피스텔)
                                    setVisible08(true );
                                    setDisabled02(true);    // 02.주택구분1(주택) Fixed

                                    setVisible07(false); // 07.취득원인1(기존 아파트) 미표시
                                    setAcqsCause1(""  ); // 07.취득원인1(기존 아파트) 초기화
                                    setVisible09(false); // 09.취득원인3(조합원입주권) 미표시   
                                    setAcqsCause3(""  ); // 09.취득원인3(조합원입주권) 초기화    
                                } else if (data === "203") {       // 조합원입주권에 의한 아파트 ▶ 09.취득원인3(조합원입주권)
                                    setVisible09(true );
                                    setDisabled02(true);    // 02.주택구분1(주택) Fixed

                                    setVisible07(false); // 07.취득원인1(기존 아파트) 미표시
                                    setAcqsCause1(""  ); // 07.취득원인1(기존 아파트) 초기화
                                    setVisible08(false); // 08.취득원인2(분양권/오피스텔) 미표시
                                    setAcqsCause2(""  ); // 08.취득원인2(분양권/오피스텔) 초기화
                                } else {
                                    setVisible07(false); // 07.취득원인1(기존 아파트) 미표시
                                    setAcqsCause1(""  ); // 07.취득원인1(기존 아파트) 초기화
                                    setVisible08(false); // 08.취득원인2(분양권/오피스텔) 미표시
                                    setAcqsCause2(""  ); // 08.취득원인2(분양권/오피스텔) 초기화
                                    setVisible09(false); // 09.취득원인3(조합원입주권) 미표시   
                                    setAcqsCause3(""  ); // 09.취득원인3(조합원입주권) 초기화
                                }
                            }} />
                    </div>
                </div>

                {/* 06.취득구분2(오피스텔) */}
                <div className={(visible06 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>취득구분</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={acqsSrc2}
                            codeArray={Util.getCodeArray("2004")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            border="1px solid #dddddd"
                            disabled={disabled06}
                            onChangeFunc={(data: string) => {
                                setAcqsSrc2(data);

                                if (data === "201") { // 기존 오피스텔 ▶ 07.취득원인1(기존 아파트) 
                                    setVisible07(true );
                                    setDisabled03(true); // 03.주택구분2(오피스텔) Fixed
                                    
                                    setVisible08(false); // 08.취득원인2(분양권/오피스텔) 미표시
                                    setAcqsCause2(""  ); // 08.취득원인2(분양권/오피스텔) 초기화
                                } else if (data === "202") { // 분양 오피스텔 ▶ 08.취득원인2(분양권/오피스텔)
                                    setVisible08(true );
                                    setDisabled03(true); // 03.주택구분2(오피스텔) Fixed

                                    setVisible07(false); // 07.취득원인1(기존 아파트) 미표시
                                    setAcqsCause1(""  ); // 07.취득원인1(기존 아파트) 초기화
                                }
                            }} />
                    </div>
                </div>

                {/* 07.취득원인1(기존 아파트) */}
                <div className={(visible07 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>취득원인</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={acqsCause1}
                            codeArray={Util.getCodeArray("2005")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            border="1px solid #dddddd"
                            // disabled={disabled01}
                            onChangeFunc={(data: string) => {
                                setAcqsCause1(data);

                                // 주택 > 아파트/연립 > 기존 아파트
                                if (acqsSrc1 === "201") {
                                    setVisible11(true ); // 11.보유주택 수 표시
                                    setDisabled05(true); // 05.취득구분1(아파트/연립) Fixed

                                    // 주소 정보가 있을 경우
                                    if (visible14) {
                                        if (data === "204") { // 상속
                                            setVisible18_1(true);    // 18-1.피상속인 취득일자 
                                        } else {
                                            setVisible18_1(false ); // 18-1.피상속인 취득일자 미표시
                                            setAcquDateInherit(""); // 18-1.피상속인 취득일자 초기화
                                        }
                                    }                                        
                                }

                                // 분양권 ▶ 15.지분율, 18.(종전주택)취득일자, 20.취득가액, 24.양도일자, 25.양도가액, 26.필요경비
                                // 일반건물/상가 ▶ 15.지분율, 18.(종전주택)취득일자, 20.취득가액, 24.양도일자, 25.양도가액, 26.필요경비    
                                // 토지 ▶ 15.지분율, 18.(종전주택)취득일자, 20.취득가액, 24.양도일자, 25.양도가액, 26.필요경비    
                                if (estateSe === "202" || estateSe === "205" || estateSe === "206") {  
                                    setTitle18("취득일자");

                                    setVisible15(true );
                                    setVisible18(true );
                                    setVisible20(true );
                                    setVisible24(true );
                                    setVisible25(true );
                                    setVisible26(true );
                                    setDisabled01(true);    // 01.부동산의 종류 Fixed
                                    
                                    if (data === "204") { // 상속
                                        setVisible18_1(true);    // 18-1.피상속인 취득일자 
                                    } else {
                                        setVisible18_1(false ); // 18-1.피상속인 취득일자 미표시
                                        setAcquDateInherit(""); // 18-1.피상속인 취득일자 초기화
                                    }
                                }

                                // 오피스텔 > 주거용 오피스텔 > 기존 오피스텔 ▶ 11.보유주택 수
                                if (acqsSrc2 === "201") {
                                    setVisible11(true );
                                    setDisabled06(true); // 06.취득구분2(오피스텔) Fixed

                                    // 주소 정보가 있을 경우
                                    if (visible14) {
                                        if (data === "204") { // 상속
                                            setVisible18_1(true);    // 18-1.피상속인 취득일자 
                                        } else {
                                            setVisible18_1(false ); // 18-1.피상속인 취득일자 미표시
                                            setAcquDateInherit(""); // 18-1.피상속인 취득일자 초기화
                                        }   
                                    }                                     
                                }
                            }} />
                    </div>
                </div>

                {/* 08.취득원인2(분양권/오피스텔) */}
                <div className={(visible08 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>취득원인</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={acqsCause2}
                            codeArray={Util.getCodeArray("2006")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            border="1px solid #dddddd"
                            // disabled={disabled01}
                            onChangeFunc={(data: string) => {
                                setAcqsCause2(data);

                                // 주택 > 아파트/연립 > 분양권에 의한 아파트
                                if (acqsSrc1 === "202") {
                                    setVisible11(true ); // 11.보유주택 수 표시
                                    setDisabled05(true); // 05.취득구분1(아파트/연립) Fixed
                                }  
                                
                                // 오피스텔 > 주거용 오피스텔 > 분양 오피스텔
                                if (acqsSrc2 === "202") {
                                    setVisible11(true ); // 11.보유주택 수 표시
                                    setDisabled06(true); // 06.취득구분2(오피스텔) Fixed

                                    // 주소 정보가 있을 경우
                                    if (visible14) {                                    
                                        if (data === "204") { // 상속
                                            setVisible18_1(true);    // 18-1.피상속인 취득일자 
                                        } else {
                                            setVisible18_1(false ); // 18-1.피상속인 취득일자 미표시
                                            setAcquDateInherit(""); // 18-1.피상속인 취득일자 초기화
                                        }   
                                    }                                     
                                }

                                // 오피스텔 > 업무용 오피스텔 ▶ 15.지분율, 18.취득일자, 20.취득가액, 24.양도일자, 25.양도가액, 26.필요경비
                                if (estateFom2 === "202") {
                                    setTitle18("취득일자");

                                    setVisible15(true);
                                    setVisible18(true); 
                                    setVisible20(true);
                                    setVisible24(true);
                                    setVisible25(true);
                                    setVisible26(true);

                                    setDisabled03(true); // 03.주택구분2(오피스텔) Fixed

                                    if (data === "204") { // 상속
                                        setVisible18_1(true);    // 18-1.피상속인 취득일자 
                                    } else {
                                        setVisible18_1(false ); // 18-1.피상속인 취득일자 미표시
                                        setAcquDateInherit(""); // 18-1.피상속인 취득일자 초기화
                                    }                                    
                                }
                            }} />
                    </div>
                </div>

                {/* 09.취득원인3(조합원입주권) */}
                <div className={(visible09 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>취득원인</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={acqsCause3}
                            codeArray={Util.getCodeArray("2007")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            border="1px solid #dddddd"
                            disabled={disabled09}
                            onChangeFunc={(data: string) => {
                                setAcqsCause3(data);

                                // 주택 > 아파트/연립 > 조합원입주권에 의한 아파트
                                if (acqsSrc1 === "203") {
                                    setVisible11(true ); // 11.보유주택 수 표시
                                    setDisabled05(true); // 05.취득구분1(아파트/연립) Fixed
                                }

                                // 조합원입주권
                                if (estateSe === "203") {
                                    if (data === "202") {   // 원조합원 ▶ 11.보유주택 수 
                                        setVisible11(true ); // 11.보유주택 수 표시
                                        setDisabled01(true); // 01.부동산의 종류 Fixed

                                        setVisible15(false   ); // 15.지분율 미표시
                                        setShareRatio(""     ); // 15.지분율 초기화
                                        setVisible18(false   ); // 18.(종전주택) 취득일자 미표시
                                        setAcquDatePreHse("" ); // 18.(종전주택) 취득일자 초기화
                                        setVisible18_1(false ); // 18-1.피상속인 취득일자 미표시
                                        setAcquDateInherit(""); // 18-1.피상속인 취득일자 초기화
                                        setVisible20(false   ); // 20.취득가액 미표시
                                        setAcquPrice(""      ); // 20.취득가액 초기화
                                        setVisible24(false   ); // 24.양도일자 미표시
                                        setTransferDate(""   ); // 24.양도일자 초기화
                                        setVisible25(false   ); // 25.양도가액 미표시
                                        setTransferPrice(""  ); // 25.양도가액 초기화
                                        setVisible26(false   ); // 26.필요경비 미표시
                                        setNeceExpenNewHse(""); // 26.필요경비 초기화

                                    } else if (data === "204") {    // 상속 ▶ 15.지분율, 18.취득일자, 18-1.피상속인 취득일자, 20.취득가액, 24.양도일자, 25.양도가액, 26.필요경비
                                        setTitle18("취득일자");

                                        setVisible15(true  );
                                        setVisible18(true  );
                                        setVisible18_1(true);
                                        setVisible20(true  );
                                        setVisible24(true  );
                                        setVisible25(true  );
                                        setVisible26(true  );
                                        setDisabled01(true ); // 01.부동산의 종류 Fixed

                                        setVisible11(false); // 11.보유주택 수 미표시
                                        setHseNum   (""   ); // 11.보유주택 수 초기화
                                    } else {    // 전매(승계조합원), 증여 ▶ 15.지분율, 18.취득일자, 20.취득가액, 24.양도일자, 25.양도가액, 26.필요경비
                                        setTitle18("취득일자");

                                        setVisible15(true );
                                        setVisible18(true ); 
                                        setVisible20(true );
                                        setVisible24(true );
                                        setVisible25(true );
                                        setVisible26(true );
                                        setDisabled01(true); // 01.부동산의 종류 Fixed

                                        setVisible11(false   ); // 11.보유주택 수 미표시
                                        setHseNum   (""      ); // 11.보유주택 수 초기화
                                        setVisible18_1(false ); // 18-1.피상속인 취득일자 미표시
                                        setAcquDateInherit(""); // 18-1.피상속인 취득일자 초기화
                                    } 
                                }
                            }} />
                    </div>
                </div>

                {/* 10.취득원인4(다세대/다가구/다중/단독) */}
                <div className={(visible10 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>취득원인</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={acqsCause4}
                            codeArray={Util.getCodeArray("2008")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            border="1px solid #dddddd"
                            // disabled={disabled01}
                            onChangeFunc={(data: string) => {
                                setAcqsCause4(data);
                                
                                // 주택 > 다세대/다가구/다중/단독주택
                                if (estateFom1 === "202") {
                                    setVisible11(true ); // 11.보유주택 수 표시
                                    setDisabled02(true); // 02.주택구분1(주택) Fixed
                                    
                                    // 주소 정보가 있을 경우
                                    if (visible14) {
                                        if (data === "204") { // 상속
                                            setVisible18_1(true);    // 18-1.피상속인 취득일자 
                                        } else {
                                            setVisible18_1(false ); // 18-1.피상속인 취득일자 미표시
                                            setAcquDateInherit(""); // 18-1.피상속인 취득일자 초기화
                                        }                                         
                                    }
                                } 
                            }} />
                    </div>
                </div>

                {/* 11.보유주택 수 */}
                <div className={(visible11 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>보유주택 수</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={hseNum}
                            codeArray={Util.getCodeArray("2010")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            border="1px solid #dddddd"
                            // disabled={disabled01}
                            onChangeFunc={(data: string) => {
                                setHseNum(data);

                                setVisible12(true );    // 양도할 자산정보 표시

                                // 주택 > 아파트/연립 > 조합원입주권에 의한 아파트 : 취득원인 Fix
                                // 조합원입주권 : : 취득원인 Fix
                                if (Util.isNotEmpty(acqsCause3)) { // 09.취득원인3(조합원입주권)
                                    setDisabled09(true); // 09.취득원인3(조합원입주권) Fixed
                                }

                                // 주택 > 아파트/연립 > 기존 아파트
                                if (estateSe === "201" && estateFom1 === "201" && acqsSrc1 === "201") {
                                    // 양도할 자산정보가 있을 경우에만
                                    if (visible14) { 
                                        // 매매/상속/증여
                                        if (data === "201") {     // 1주택(대체취득 포함)
                                            setVisible19(true);  // 19.(종전주택) 거주기간
                                        } else {    // 2주택, 3주택 이상
                                            setVisible19(false    ); // 19.(종전주택) 거주기간 미표시
                                            setResiPeriodPreHse(""); // 19.(종전주택) 거주기간 초기화
                                        }
                                    }    

                                // 주택 > 아파트/연립 > 분양권에 의한 아파트
                                } else if (estateSe === "201" && estateFom1 === "201" && acqsSrc1 === "202") {
                                    // 양도할 자산정보가 있을 경우에만
                                    if (visible14) { 
                                        // 매매/상속/증여
                                        if (data === "201") {     // 1주택(대체취득 포함)
                                            setVisible19(true);  // 19.(종전주택) 거주기간
                                        } else {    // 2주택, 3주택 이상
                                            setVisible19(false    ); // 19.(종전주택) 거주기간 미표시
                                            setResiPeriodPreHse(""); // 19.(종전주택) 거주기간 초기화
                                        }
                                    }

                                // 주택 > 아파트/연립 > 조합원입주권에 의한 아파트
                                } else if (estateSe === "201" && estateFom1 === "201" && acqsSrc1 === "203") {
                                    // 양도할 자산정보가 있을 경우에만
                                    if (visible14) {
                                        //  09.취득원인3(조합원입주권) : 원조합원
                                        if (acqsCause3 === "202") { 
                                            if (data === "201") {   // 1주택(대체취득 포함) ▶ 16 ~ 27 : 주택정보 callback에서 처리
                                                setVisible19(true);
                                                setVisible23(true);
                                            } else {    // 2주택, 3주택 이상 ▶ 16~18, 20~22, 24~27 : 주택정보 callback에서 처리
                                                setVisible19(false);
                                                setVisible23(false);
                                                setResiPeriodPreHse(""); // 19.종전주택 거주기간 초기화
                                                setResiPeriodNewHse(""); // 23.신규주택 거주기간 초기화                                            
                                            }
                                        } else {    // 전매(승계조합원), 상속, 증여
                                            if (data === "201") {     // 1주택(대체취득 포함) ▶ 18 ~ 20, 24 ~ 26
                                                setVisible19(true);  // 19.종전주택 거주기간
                                            } else {    // 2주택, 3주택 이상 ▶ 18, 20, 24 ~ 26
                                                setVisible19(false    ); // 19.(종전주택) 거주기간 미표시
                                                setResiPeriodPreHse(""); // 19.종전주택 거주기간 초기화
                                            }                                        
                                        } 
                                    }                             
                                } else {}
                                
                                // 주택 > 다세대/다가구/다중/단독주택
                                if (estateFom1 === "202") {
                                    // 양도할 자산정보가 있을 경우에만
                                    if (visible14) { 
                                        // 매매/상속/증여
                                        if (data === "201") {     // 1주택(대체취득 포함)
                                            setVisible19(true);  // 19.(종전주택) 거주기간
                                        } else {    // 2주택, 3주택 이상
                                            setVisible19(false    ); // 19.(종전주택) 거주기간 미표시
                                            setResiPeriodPreHse(""); // 19.(종전주택) 거주기간 초기화
                                        }
                                    }                                    
                                }

                                // 조합원입주권
                                if (estateSe === "203") {
                                    // 양도할 자산정보가 있을 경우에만
                                    if (visible14) { 
                                        //  09.취득원인3(조합원입주권) : 원조합원
                                        if (acqsCause3 === "202") { 
                                            if (data === "201") {   // 1주택(대체취득 포함) ▶ 16 ~ 21, 24 : 주택정보 callback에서 처리
                                                setVisible19(true);
                                            } else {    // 2주택, 3주택 이상 ▶ 16~18, 20~22, 24~27 : 주택정보 callback에서 처리
                                                setVisible19(false);
                                                setResiPeriodPreHse(""); // 19.종전주택 거주기간 초기화
                                            }
                                        }
                                    }                                    
                                }

                                // 오피스텔 > 주거용 오피스텔 > 기존 오피스텔 or 분양 오피스텔
                                if (estateSe === "204" && estateFom2 === "201") {
                                    // 양도할 자산정보가 있을 경우에만
                                    if (visible14) { 
                                        // 매매/상속/증여
                                        if (data === "201") {     // 1주택(대체취득 포함)
                                            setVisible19(true);  // 19.(종전주택) 거주기간
                                        } else {    // 2주택, 3주택 이상
                                            setVisible19(false    ); // 19.(종전주택) 거주기간 미표시
                                            setResiPeriodPreHse(""); // 19.(종전주택) 거주기간 초기화
                                        }
                                    }                                
                                }
                            }} 
                        />
                    </div>
                </div>

                {/* 양도할 자산정보(12~15) */}
                <div className={(visible12 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>양도할 자산정보</span>
                    </div>
                    <div className={(visible13 ? "" : "dpNone-i") + ' dpFlx aiC jcC ml20 bgcWhite'}>
                        <div className="w80 h30 bgc17A840 bdrd40 dpFlx jcC aiC csP" onClick={() => {
                            if (Util.isNotEmpty(rdnmAdres)) {
                                AppContext.openAlert({
                                    title: "양도할 자산정보 추가 완료"
                                    , msg  : "양도할 자산정보가 이미 추가되었습니다."
                                    , icon: "check"
                                });
                            } else {
                                if (apiResult === "0") {
                                    setAddressModalOpen(true);
                                } else {
                                    setDirAddressInModalOpen(true);
                                }
                            }
                        }}>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>추가</span>
                        </div>
                    </div>
                </div>

                {/* 주택 상세정보 - 12.도로명주소, 13.지번주소, 14.조정대상지역 15.지분율 */}
                <div className={(visible14 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className='dpFlx aiC jcC bgcWhite'>
                        <table className="forest-form-table th-p0 td-fs15 td-fm-SpoqaHanSansNeo-Regular td-fc666666 td-ls075">
                            <colgroup>
                                <col className="w150" />
                                <col className="w280" />
                                <col className="w150" />
                                <col className="w140" />
                                <col className="w140" />
                            </colgroup>
                            <tbody>
                                <tr className={lnmAdres === "" ? "h40" : "h60"}>
                                    <th>주소</th>
                                    <td colSpan={3}><span className="lh25">{rdnmAdres}<br/>{lnmAdres}</span></td>
                                    <td>
                                        <div className="w80 h30 bgcB36666 bdrd4 dpFlx jcC aiC csP" onClick={() => delHousing()}>
                                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>삭제</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="h35">
                                    <th>조정대상지역</th>
                                    <td className="bdr1-i bdcDDDDDD-i">
                                        <ImageRadioBox
                                            codeList={Util.getCodeArray("2034")}
                                            value={mdatTrgetArea}
                                            setValue={setMdatTrgetArea}
                                            btnClass="w24 h24 pr5"
                                            disabled={true}
                                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                            interval={20} />
                                    </td>
                                    <th>지분율(%)</th>
                                    <td colSpan={2}>{shareRatio}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            
                {/************************** 주소 하단 왼쪽 부분(종전주택) **************************/}
                <div className="w50p float-l pr10 bdBox">
                    {/* 15. 지분율 */}
                    <div className={(visible15 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>지분율(%)</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <NumberTextBox
                                value={shareRatio}
                                onChange={(data: string) => setShareRatio(data)}
                                borderClass="bgcWhite w300 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={3}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            />
                        </div>
                    </div>

                    {/* 16. 관리처분인가일 */}
                    <div className={(visible16 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>관리처분인가일</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <DatePickerBox 
                                value={mngDispAppDate} 
                                width={300} height={40} fontSize={15} 
                                bgColor="#fff" textAlign="left" 
                                onChange={(data: string) => setMngDispAppDate(Util.dateToString(data, ""))} 
                                // disabled={false} 
                            />
                        </div>
                    </div>

                    {/* 17. 관리처분인가일 평가액 */}
                    <div className={(visible17 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>관리처분인가일 평가액</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <NumberTextBox
                                value={mngDispAppDateAmt}
                                onChange={(data: string) => setMngDispAppDateAmt(data)}
                                borderClass="bgcWhite w300 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitText="원"
                            />
                        </div>
                    </div>

                    {/* 18.종전주택 취득일자 */}
                    <div className={(visible18 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>{title18}</span>
                            <div className="dpFlx aiC jcC taL"
                                data-tooltip-id="acqusitionDateToolTip"
                                data-tooltip-content={
                                        "♠ 취득일자\n\n"
                                        + "매매 - 잔급지급일, 소유권 이전 등기접수일 중 빠른 날\n" 
                                        + "상속 - 상속개시일\n" 
                                        + "증여 - 증여등기접수일\n"
                                        + "자가신축 - 사용승인일\n"
                                        + "조합원입주권에 의한 아파트 - 사용승인일\n"
                                        + "분양권에 의한 아파트 - 분양대금완납일\n"
                                    }>
                                    <img src={require('img/common/icon_search20_black.png')} alt="icon_info_fill_20" className='csP pl3' />
                                    <ToolTip id="acqusitionDateToolTip" place='top' clickable={true} className="w200 lh20" />
                            </div>  
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <DatePickerBox 
                                value={acquDatePreHse} 
                                width={300} height={40} fontSize={15} 
                                bgColor="#fff" textAlign="left" 
                                onChange={(data: string) => setAcquDatePreHse(Util.dateToString(data, ""))} 
                                // disabled={false} 
                            />
                        </div>
                    </div>

                    {/* 18-1.피상속인 취득일자 */}
                    <div className={(visible18_1 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>피상속인 취득일자</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <DatePickerBox 
                                value={acquDateInherit} 
                                width={300} height={40} fontSize={15} 
                                bgColor="#fff" textAlign="left" 
                                onChange={(data: string) => setAcquDateInherit(Util.dateToString(data, ""))} 
                                // disabled={false} 
                            />
                        </div>
                    </div>

                    {/* 19. 종전주택 거주기간 */}
                    <div className={(visible19 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>{title19}</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <NumberTextBox
                                value={resiPeriodPreHse}
                                onChange={(data: string) => setResiPeriodPreHse(data)}
                                borderClass="bgcWhite w300 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={3}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitClass="w50 taR"
                                unitText="개월"
                            />
                        </div>
                    </div>

                    {/* 20. 취득가액 */}
                    <div className={(visible20 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>취득가액</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <NumberTextBox
                                value={acquPrice}
                                onChange={(data: string) => setAcquPrice(data)}
                                borderClass="bgcWhite w300 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitText="원"
                            />
                        </div>
                    </div>

                    {/* 21. 종전주택 필요경비 */}
                    <div className={(visible21 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>종전주택 필요경비</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <NumberTextBox
                                value={neceExpenPreHse}
                                onChange={(data: string) => setNeceExpenPreHse(data)}
                                borderClass="bgcWhite w300 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitText="원"
                            />
                        </div>
                    </div>
                </div>

                {/************************** 주소 하단 오른쪽 부분(신규주택) **************************/}
                <div className="w50p float-r pl10 bdBox">
                    {/* 22. 신규주택 사용승인일 */}
                    <div className={(visible22 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>신규주택 사용승인일</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <DatePickerBox 
                                value={apprDateNewHse} 
                                width={300} height={40} fontSize={15} 
                                bgColor="#fff" textAlign="left" 
                                onChange={(data: string) => setApprDateNewHse(Util.dateToString(data, ""))} 
                                // disabled={false} 
                            />
                        </div>
                    </div>   

                    {/* 23.신규주택 거주기간 */}
                    <div className={(visible23 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>신규주택 거주기간</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <NumberTextBox
                                value={resiPeriodNewHse}
                                onChange={(data: string) => setResiPeriodNewHse(data)}
                                borderClass="bgcWhite w300 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={3}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitClass="w50 taR"
                                unitText="개월"
                            />
                        </div>
                    </div>

                    {/* 24. 양도일자 */}
                    <div className={(visible24 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>양도일자</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <DatePickerBox 
                                value={transferDate} 
                                width={300} height={40} fontSize={15} 
                                bgColor="#fff" textAlign="left" 
                                onChange={(data: string) => setTransferDate(Util.dateToString(data, ""))} 
                                // disabled={false} 
                            />
                        </div>
                    </div> 

                    {/* 25. 양도가액 */}
                    <div className={(visible25 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>양도가액</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <NumberTextBox
                                value={transferPrice}
                                onChange={(data: string) => setTransferPrice(data)}
                                borderClass="bgcWhite w300 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitText="원"
                            />
                        </div>
                    </div>

                    {/* 26. 필요경비 */}
                    <div className={(visible26 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>필요경비</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <NumberTextBox
                                value={neceExpenNewHse}
                                onChange={(data: string) => setNeceExpenNewHse(data)}
                                borderClass="bgcWhite w300 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitText="원"
                            />
                        </div>
                    </div>

                    {/* 27.청산금 여부 */}
                    <div className={(visible27 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>청산금 여부</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <SelectBox
                                value={liqudAmt}
                                codeArray={Util.getCodeArray("2038")}
                                placeholder="선택해주세요"
                                width={300}
                                height={40}
                                fontSize={15}
                                paddingRight={5}
                                border="1px solid #dddddd"
                                // disabled={disabled01}
                                onChangeFunc={(data: string) => {
                                    setLiqudAmt(data);

                                    if (data === "202" || data === "203") { // 청산금 납부/수령 ▶ 28.납부(수령)금액
                                        setVisible28(true);
                                    } else {
                                        setVisible28(false); // 28.납부(수령)금액 미표시
                                        setPayOrRecAmt("" ); // 28.납부(수령)금액 초기화
                                    }
                                }} />
                        </div>
                    </div>

                    {/* 28.납부(수령)금액 */}
                    <div className={(visible28 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                        <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>납부(수령)금액</span>
                        </div>
                        <div className='dpFlx aiC jcC ml10 bgcWhite'>
                            <NumberTextBox
                                value={payOrRecAmt}
                                onChange={(data: string) => setPayOrRecAmt(data)}
                                borderClass="bgcWhite w300 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitText="원"
                            />
                        </div>
                    </div>
                </div>
            
                <div className='w100p dpFlx aiC jcC pt40 pb40'>
                    <button className='w360 h60 bgc17A840 dpFlx aiC jcC csP bdN' onClick={() => callTax("T")}>
                        <span className='SpoqaHanSansNeo-Medium fs18 ls07 fcWhite'>계산하기</span>
                    </button>
                    <button className='w100 h60 bgcF6F6F6 dpFlx aiC jcC csP bd1 bdc878787' onClick={() => goReset()}>
                        <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>초기화</span>
                    </button>
                </div>

                {/* 주소 API 검색 모달 */}
                <Modal modalOpen={addressModalOpen} setModalOpen={setAddressModalOpen} title="주소검색" dim={true} visible={true}>
                    <SimAddress setModalOpen={setAddressModalOpen} taxFlag="T" callBack={onFinishAddrSearch} />
                </Modal>

                {/* 주소 직접입력 모달 */}
                <Modal modalOpen={dirAddressInModalOpen} setModalOpen={setDirAddressInModalOpen} title="양도자산 정보 입력" dim={true} visible={true}>
                    <DirectAddressIn setModalOpen={setDirAddressInModalOpen} taxFlag="T" callBack={onFinishAddrSearch} />
                </Modal>

                {/* 양도세 결과표 모달 */}
                <Modal modalOpen={simTranTaxCalcModalOpen} setModalOpen={setSimTranTaxCalcModalOpen} title="양도세 세액 산출" dim={true} visible={simTranTaxCalcModalVsb} closeCallBack={() => setSimTranTaxCalcModalVsb(false)}>
                    <SimTranTaxCalc setSimTranTaxCalcModalOpen={setSimTranTaxCalcModalOpen} setSimTranTaxCalcModalVsb={setSimTranTaxCalcModalVsb} tranTaxParam={tranTaxParam} />
                </Modal>  
        </div>                    
    )
}
export default SimTranAutoTax;