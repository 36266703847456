/*******************************************************************************
*** GiftInfo ** 모바일 상속세 사전증여정보 입력 UI
*******************************************************************************/
import { Code, InheTaxParamDisabled, InheTaxParamProps, InheTaxParamTitle, InheTaxParamVisible } from "js/common/types/types";
import { InputContainer, MainSectionContainer, NumberInputContainer } from "../common/layout";
import { InheTaxAction } from "./inheTaxStatesReducer";
import { NumericFormat } from "react-number-format";
import SelectBox, { SelectOption } from "../common/SelectBox";
import Util from "js/common/Util";

type Props = {
    inputValue: InheTaxParamProps;
    inputVisible: InheTaxParamVisible;
    inputDisabled: InheTaxParamDisabled;
    inputTitle: InheTaxParamTitle;
    onUpdateInput: (action: InheTaxAction) => void;
};

function GiftInfo({ inputValue, inputVisible, inputDisabled, inputTitle, onUpdateInput }: Props) {

    return (
        <MainSectionContainer>
            <p className="desc">
                <span>피상속인의</span>
                <span className="bold"> 사전증여정보</span>
                <span>를 입력해주세요.</span>
            </p>    
            {/* 04. 배우자 유무 */}
            {inputVisible.visible04 && (
                <InputContainer>
                    <p className="label">배우자 유무</p>
                    <div className="input">
                        <SelectBox
                            placeholder="배우자 유무를 선택해주세요."
                            options={Util.getCodeArray("2031").map((code: Code) => ({
                                value: code.code,
                                label: code.codeNm,
                            }))}
                            selectedValue={inputValue.spouse}
                            disabled={inputDisabled.disabled04}
                            handleSelect={(selected: SelectOption) => {
                                onUpdateInput({
                                    type: "UPDATE_SPOUSE",
                                    payload: selected.value,
                                });
                            }}
                        />
                    </div>
                </InputContainer>
            )}

            {/* 05. 배우자 사전증여 유무 */}
            {inputVisible.visible05 && (
                <InputContainer>
                    <p className="label">배우자 사전증여 유무</p>
                    <div className="input">
                        <SelectBox
                            placeholder="배우자 사전증여 유무를 선택해주세요."
                            options={Util.getCodeArray("2031").map((code: Code) => ({
                                value: code.code,
                                label: code.codeNm,
                            }))}
                            selectedValue={inputValue.spousePreGift}
                            handleSelect={(selected: SelectOption) => {
                                onUpdateInput({
                                    type: "UPDATE_SPOUSE_PRE_GIFT",
                                    payload: selected.value,
                                });
                            }}
                        />
                    </div>
                    <div className="help">10년 이내 동일한 증여자로부터 받은 사전증여</div>
                </InputContainer>
            )}
                        
            {/* 06. 배우자 사전증여 재산가액 */}
            {inputVisible.visible06 && (
                <InputContainer>
                    <p className="label">배우자 사전증여 재산가액</p>
                    <div className="input">
                        <NumberInputContainer $filled={inputValue.spousePreGiftProp === "" ? false : true}>
                            <div className="inputWrap">
                                <NumericFormat
                                    name="spousePreGiftProp"
                                    value={inputValue.spousePreGiftProp}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={15}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const valueWithoutComma = inputValue.replace(/,/g, "");
                                        onUpdateInput({
                                            type: "UPDATE_SPOUSE_PRE_GIFT_PROP",
                                            payload: valueWithoutComma,
                                        });
                                    }}
                                    placeholder="배우자 사전증여 재산가액을 입력해주세요."
                                    className="numberInput"
                                />
                                <p className="unit">원</p>
                            </div>
                        </NumberInputContainer>
                    </div>
                </InputContainer>
            )}

            {/* 07. 배우자 사전증여세액 */}
            {inputVisible.visible07 && (
                <InputContainer>
                    <p className="label">배우자 사전증여세액</p>
                    <div className="input">
                        <NumberInputContainer $filled={inputValue.spousePreGiftTax === "" ? false : true}>
                            <div className="inputWrap">
                                <NumericFormat
                                    name="spousePreGiftTax"
                                    value={inputValue.spousePreGiftTax}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={15}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const valueWithoutComma = inputValue.replace(/,/g, "");
                                        onUpdateInput({
                                            type: "UPDATE_SPOUSE_PRE_GIFT_TAX",
                                            payload: valueWithoutComma,
                                        });
                                    }}
                                    placeholder="배우자 사전증여세액을 입력해주세요."
                                    className="numberInput"
                                />
                                <p className="unit">원</p>
                            </div>
                        </NumberInputContainer>
                    </div>
                </InputContainer>
            )}

            {/* 08. 자녀 수 */}
            {inputVisible.visible08 && (
                <InputContainer>
                    <p className="label">자녀 수</p>
                    <div className="input">
                        <NumberInputContainer $filled={inputValue.childNumber === "" ? false : true}>
                            <div className="inputWrap">
                                <NumericFormat
                                    name="childNumber"
                                    value={inputValue.childNumber}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={15}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const valueWithoutComma = inputValue.replace(/,/g, "");
                                        onUpdateInput({
                                            type: "UPDATE_CHILD_NUMBER",
                                            payload: valueWithoutComma,
                                        });
                                    }}
                                    placeholder="자녀 수를 입력해주세요."
                                    className="numberInput"
                                />
                                <p className="unit">명</p>
                            </div>
                        </NumberInputContainer>
                    </div>
                </InputContainer>
            )}

            {/* 09. 사전증여 유무 */}
            {inputVisible.visible09 && (
                <InputContainer>
                    <p className="label">{inputTitle.title09}</p>
                    <div className="input">
                        <SelectBox
                            placeholder="사전증여 유무를 선택해주세요."
                            options={Util.getCodeArray("2031").map((code: Code) => ({
                                value: code.code,
                                label: code.codeNm,
                            }))}
                            selectedValue={inputValue.preGift}
                            handleSelect={(selected: SelectOption) => {
                                onUpdateInput({
                                    type: "UPDATE_PRE_GIFT",
                                    payload: selected.value,
                                });
                            }}
                        />
                    </div>
                    <div className="help">10년 이내 동일한 증여자로부터 받은 사전증여</div>
                </InputContainer>
            )}
            
            {/* 10. 사전증여 재산가액 */}
            {inputVisible.visible10 && (
                <InputContainer>
                    <p className="label">{inputTitle.title10}</p>
                    <div className="input">
                        <NumberInputContainer $filled={inputValue.preGiftProp === "" ? false : true}>
                            <div className="inputWrap">
                                <NumericFormat
                                    name="preGiftProp"
                                    value={inputValue.preGiftProp}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={15}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const valueWithoutComma = inputValue.replace(/,/g, "");
                                        onUpdateInput({
                                            type: "UPDATE_PRE_GIFT_PROP",
                                            payload: valueWithoutComma,
                                        });
                                    }}
                                    placeholder="사전증여 재산가액을 입력해주세요."
                                    className="numberInput"
                                />
                                <p className="unit">원</p>
                            </div>
                        </NumberInputContainer>
                    </div>
                </InputContainer>
            )}

            {/* 11. 사전증여세액 */}
            {inputVisible.visible11 && (
                <InputContainer>
                    <p className="label">{inputTitle.title11}</p>
                    <div className="input">
                        <NumberInputContainer $filled={inputValue.preGiftTax === "" ? false : true}>
                            <div className="inputWrap">
                                <NumericFormat
                                    name="preGiftTax"
                                    value={inputValue.preGiftTax}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={15}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const valueWithoutComma = inputValue.replace(/,/g, "");
                                        onUpdateInput({
                                            type: "UPDATE_PRE_GIFT_TAX",
                                            payload: valueWithoutComma,
                                        });
                                    }}
                                    placeholder="사전증여세액을 입력해주세요."
                                    className="numberInput"
                                />
                                <p className="unit">원</p>
                            </div>
                        </NumberInputContainer>
                    </div>
                </InputContainer>
            )}    
        </MainSectionContainer>
    );
}

export default GiftInfo;
