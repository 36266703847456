interface Qestn45ModalProps {
    setQestn45Open: React.Dispatch<React.SetStateAction<boolean>>
}
const Qestn45Modal = ({setQestn45Open}: Qestn45ModalProps) => {
    return (
        <div className="w100p hAuto ofA">
            <table className={
                'forest-list-table bdt1 bdb1 bdcDDDDDD ' +
                'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075 td-p5'
            }>
                <colgroup>
                    <col className='w100'></col>
                    <col className='w250'></col>
                    <col className='w250'></col>
                    <col className='w250'></col>
                    <col className='w250'></col>
                </colgroup>
                <tbody>
                    <tr className='h40'>
                        <th rowSpan={2}>구분</th>
                        <th colSpan={2}>임대주택(전용면적 60㎡ 이하)</th>
                        <th colSpan={2}>장기임대주택(전용면적 60㎡ 초과 ~ 85㎡ 이하)</th>
                    </tr>
                    <tr className='h29'>
                        <th>2020.8.11. 이전</th>
                        <th>2020.8.12. 이후</th>
                        <th>2020.8.11. 이전</th>
                        <th>2020.8.12. 이후</th>
                    </tr>
                    <tr className='h32'>
                        <th>대상주택</th>
                        <td colSpan={2}>공동주택 신축, 공동주택 또는 오피스텔을 최초로 분양받은 경우<br/>(2020.8.18. 이후 아파트 제외)</td>
                        <td colSpan={2}>공동주택 신축, 공동주택 또는 오피스텔을 최초로 분양받은 경우<br/>(2020.8.18. 이후 아파트 제외)</td>
                    </tr>
                    <tr className='h32'>
                        <th>의무임대</th>
                        <td>(단기4년, 장기8년)</td>
                        <td>장기10년 이상</td>
                        <td>장기 8년 이상</td>
                        <td>장기 10년 이상</td>
                    </tr>
                    <tr className='h32'>
                        <th>임대호수</th>
                        <td>1호 이상</td>
                        <td>1호 이상</td>
                        <td>20호 이상</td>
                        <td>20호 이상</td>
                    </tr>
                    <tr className='h32'>
                        <th>가액기준</th>
                        <td>-</td>
                        <td>취득 당시 가액 6억원 이하 (수도권밖 3억원)</td>
                        <td>-</td>
                        <td>취득 당시 가액 6억원 이하 (수도권밖 3억원)</td>
                    </tr>
                    <tr className='h32'>
                        <th>상한율</th>
                        <td colSpan={2}>임대료(임대보증금) 5% 증액제한</td>
                        <td colSpan={2}>임대료(임대보증금) 5% 증액제한</td>
                    </tr>
                    <tr className='h32'>
                        <th>등록요건</th>
                        <td colSpan={2}>취득일로부터 60일 이내에 임대사업자 등록</td>
                        <td colSpan={2}>취득일로부터 60일 이내에 임대사업자 등록</td>
                    </tr>
                    <tr className='h32'>
                        <th>적용기간</th>
                        <td colSpan={2}>2024.12.31. 까지 감면함</td>
                        <td colSpan={2}>2024.12.31. 까지</td>
                    </tr>
                    <tr className='h32'>
                        <th>감면율</th>
                        <td colSpan={2}>100%감면 (취득세 200만원 초과시 15% 납부)</td>
                        <td colSpan={2}>50%감면 (취득세 200만원 초과시 15% 납부)</td>
                    </tr>
                    <tr className='h32'>
                        <th>사후관리</th>
                        <td colSpan={2}>추징사유 규정 있음</td>
                        <td colSpan={2}>추징사유 규정 있음</td>
                    </tr>
                    <tr className='h32'>
                        <th>추징사유</th>
                        <td colSpan={4}>
                            <div className="taL lh20">
                                <span>의무임대기간 내에 의무 이행하지 아니한 경우 감면받은 취득세를 추징한다.<br /></span>
                                <span className="pl5">임대사업자 등록말소 사유 (민간임대주택법 제6조)<br/></span>
                                <span className="pl15">① 거짓이나 그 밖의 부정한 방법으로 등록한 경우<br/></span>
                                <span className="pl15">② 임대사업자가 일정 기간 안에 민간임대주택을 취득하지 아니한 경우<br/></span>
                                <span className="pl15">③ 등록한 날부터 1개월이 경과하기 전 또는 의무임대기간이 경과한 후 등록 말소를 신청하는 경우<br/></span>
                                <span className="pl15">④ 임대주택 등록기준을 갖추지 못한 경우<br/></span>
                                <span className="pl15">⑤ 포괄·양도 양수로 민간임대주택을 양도한 경우<br/></span>
                                <span className="pl15">⑥ 양도허가를 받아 민간임대주택을 양도한 경우 - 예외적으로 추징되지 않는다.<br/></span>
                                <span className="pl15">⑦ 임대조건을 위반한 경우<br/></span>
                                <span className="pl15">⑧ 임대차계약을 해제·해지하거나 재계약을 거절한 경우<br/></span>
                                <span className="pl15">⑨ 준주택에 대한 용도제한을 위반한 경우<br/></span>
                                <span className="pl15">※ 임대사업자 등록이 말소된 경우 (민특법에 따른 자진말소의 경우 추징 배제)</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className='w215 dpFlx aiC jcC bdBox pt30'>
                <span className="SpoqaHanSansNeo-Medium fs18 ls09 fc222222">취득할 주택이 위의 임대주택에 해당하나요?</span>
            </div>
            <div className='w100p dpFlx aiC jcC pt20'>
                <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => setQestn45Open(false)}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>닫기</span>
                </div>
            </div>
        </div>
    )
}
export default Qestn45Modal;