/*******************************************************************************
*** TaxExemptCaution ** 양도세 절세가이드 안내
*******************************************************************************/
import { useState } from "react";
import styled       from "styled-components";
import TaxSavingGuideDialog from "./TaxSavingGuideDialog";

type Props = {
	transferTaxGuide: any
}

function TaxSavingGuideDesc({transferTaxGuide}: Props) {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const onClickDetail = () => {
        setDialogOpen(true);
    };

    return (
        <>
        <GuideWrapper>
            <GuideContainer>
            <div className="guide">
                <div className="title">
                <div className="iconWrap">
                    <img src={require("img/common/tax_saving_guide_icon.png")} alt="caution icon" />
                </div>
                <span className="text">비과세 절세가이드</span>
                </div>
                <div className="desc">
                <p>비과세 혜택을 적용받을 수 있는 기준을</p>
                <p>절세가이드에서 확인해주세요!</p>
                </div>
            </div>
            <div className="detail">
                <button onClick={onClickDetail}>
                <div className="iconWrap">
                    <img src={require("img/common/tax_saving_guide_detail_icon.png")} alt="caution icon" />
                </div>
                <span>절세 가이드</span>
                </button>
            </div>
            </GuideContainer>
        </GuideWrapper>
        <TaxSavingGuideDialog open={dialogOpen} setOpen={setDialogOpen} transferTaxGuide={transferTaxGuide} />
        </>
    );
}

const GuideWrapper = styled.div`
    width: 380px;
    height: auto;
    padding: 4px;
    border-radius: 20px;
    background-color: white;

    box-sizing: border-box;
`;

const GuideContainer = styled.div`
    display: flex;
    justify-content: space-between;

    width: 100%;
    height: 100%;
    padding: 16px;
    border-radius: 5px;
    background-color: #e7ffed;  /* f9fafb */
    box-sizing: border-box;

    --iconWidth: 26px;

    .guide {
        .title {
            display: flex;
            align-items: center;

            .iconWrap {
                display: inline-block;
                width: var(--iconWidth);
                height: var(--iconWidth);
                img {
                    width: 100%;
                    height: 100%;
                 }
            }

      .text {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 500;
        font-size: 12px;
        line-height: 1;
        letter-spacing: -0.058em;
      }
    }

    .desc {
      font-family: "Spoqa Han Sans Neo";
      font-weight: 300;
      font-size: 12px;
      line-height: 1.4;
      letter-spacing: -0.058em;
      color: #414142;

            padding-left: var(--iconWidth);

            p {
                margin: 0;
            }
        }
    }

    .detail {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            display: flex;
            align-items: center;
            gap: 4px;
            width: 100px;

            padding: 3px 13px;
            border-radius: 4px;
            background-color: #45c25a;

      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 12px;
      line-height: 1.4;
      letter-spacing: -0.058em;
      color: white;

            .iconWrap {
                display: inline-block;
                width: 11px;
                height: 14.56px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
`;

export default TaxSavingGuideDesc;
