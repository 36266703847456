function HshldScopeInfo({ setModalOpen }: any) {
    return (
        <div className="w800 h100p">
            <div className="wbBA SpoqaHanSansNeo-Regular fc2C2C2C fs14 ls06 lh20 bdBox">
                <span>1세대란 양도시점을 기준으로 거주자 및 그 배우자가 그들과 동일한 주소 또는 거소에서 생계를 같이하는 가족과 함께 구성하는 1세대를 말한다.<br />
                      이 경우 가족이란 거주자와 그 배우자의 직계존비속(그 배우자를 포함한다) 및 형제자매를 말하며, 취학, 질병의 요양, 근무상 또는 사업상의 형편으로 본래의 주소 또는 거소를 일시퇴거한 자를 포함한다.<br /></span>
                <ul className="m0 pt5 pl10 pb20 fs13 lsN">
                    <li>① 부부가 각각 세대를 달리 구성하는 경우에도 동일한 세대로 본다.</li>
                    <li>② 법률상 이혼을 하였으나 생계를 같이하는 등 사실상 이혼한 것으로 보기 어려운 경우에는 동일한 세대로 본다.</li>
                </ul>
                <span>다음의 요건에 해당하는 경우에는 배우자가 없는 경우에도 별도 세대를 인정한다.<br /></span>
                <ul className="m0 pt5 pl10 pb20 fs13 lsN">
                    <li>① 거주자의 연령이 30세 이상인 경우</li>
                    <li>② 배우자가 사망하거나 이혼한 경우</li>
                    <li className="pl7 ti-7">
                        ③ 거주자의 소득세법 제4조의 소득(종합소득, 퇴직소득, 양도소득)이 「국민기초생활보장법」 제2조 제11호에 따른 기준 중위소득의 100분의 40 수준 이상으로서 소유하고 있는 주택 또는 토지를 관리·유지하면서 독립된 생계를 유지할 수 있는 경우.
                        다만, 미성년자의 경우를 제외하되, 미성년자의 결혼, 가족의 사망 기획재정부령이 정하는 사유로 1세대의 구성이 불가피한 경우에는 그러하지 아니하다.
                    </li>
                </ul>
                <span>동일세대원 여부의 판정은 형식상의 주민등록 내용에 불구하고 실질적인 생계를 같이하는 지를 말하는 것으로 실질적으로 생계를 같이하는지 여부는 세대별 생활관계 등을 종합하여 판단하는 것이다.<br /></span>
                <ul className="m0 pt15 pl10 pb20 fs13 lsN">
                    <li>* 위장전입 및 전출로 인한 세대원은 실제 생계를 같이하는 세대의 세대원으로 볼 수 있음.</li>
                    <li>* 양도자가 독립세대요건을 충족하지 못했을 경우 부모세대에 대한 정보를 추가로 받아야함.</li>
                </ul>
            </div>
            <div className="dpFlx aiC jcC">
                <div className="w210 h56 bgc17A840 dpFlx aiC jcC csP bdBox" onClick={() => { setModalOpen(false) }}>
                    <span className="SpoqaHanSansNeo-Medium fs14 fcWhite">닫기</span>
                </div>
            </div>
        </div>
    )
}
export default HshldScopeInfo;