/*******************************************************************************
*** CalcResult ** 모바일 상속세 세액결과 UI
*******************************************************************************/
import { useState }         from "react";
import styled               from "styled-components";
import Util                 from "js/common/Util";
import { InheTaxCalResult } from "js/common/types/types";
import ToolTip              from "js/common/ui/ToolTip";

type Props = {
    calcResult: InheTaxCalResult | null;
};

function CalcResult({ calcResult }: Props) {
    const [detailResultOpen, setDetailResultOpen] = useState<boolean>(true);

    // 세부 세액 접기/펼치기(과세표준 아코디언 UI)
    const onClickOpen = () => {
        if (detailResultOpen) {
            setDetailResultOpen(false);
        } else {
            setDetailResultOpen(true);
        }
    };

    return (
        <>
            <TotalTaxContainer>
                <p className="title">
                    상속세액 합계
                    <span className="highlight"></span>
                </p>
                <div className="tax">
                    <span className="number">
                        {calcResult ? Util.comma(calcResult.total_inhe_tax_amt ?? "") : ""}
                    </span>
                    <span className="unit">원</span>
                </div>
            </TotalTaxContainer>

            <DetailTaxContainer>
                <div className="title">
                    <p>세부 항목 확인하기</p>
                    <button className={`${detailResultOpen ? "open" : "closed"}`} onClick={onClickOpen}>
                        <img src={require("img/common/icon_arrow_up_gray.png")} alt="logo" />
                    </button>
                </div>

                <TaxInfoContainer>
                    <div className="taxWrap">
                        <div className="tax">
                            <p className="name">상속세 과세가액</p>
                            <div className="value">
                                <div>
                                    <span className="number">
                                    {calcResult ? Util.comma(calcResult.inhe_taxable_val ?? "") : ""}
                                    </span>
                                    <span className="unit">원</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 상속세 과세가액 상세 내역 */}
                    <OpenAndCloseContainer>
                    <div className={`detail-container ${detailResultOpen ? "open" : "closed"}`}>
                        
                        {/* 총 상속재산가액 */}
                        <div className="moreDetailTaxWrap">
                            <div className="title">
                                {/* <img src={require("img/common/icon_more.png")} alt="logo" /> */}
                                <p className="name">총 상속재산가액</p>
                            </div>
                            <div className="value">
                                <div>
                                    <span className="number">
                                        {calcResult ? Util.comma(calcResult.inher_prop_amt ?? "") : ""}
                                    </span>
                                    <span className="unit">원</span>
                                </div>
                            </div>
                        </div>

                        {/* 사전증여재산 */}
                        <div className="moreDetailTaxWrap">
                            <div className="title">
                                <img src={require("img/common/icon_more.png")} alt="logo" />
                                <p className="name">(+)사전증여재산</p>
                            </div>
                            <div className="value">
                                <div>
                                    <span className="number">
                                        {calcResult ? Util.comma(calcResult.pre_gift_prop_amt ?? "") : ""}
                                    </span>
                                    <span className="unit">원</span>
                                </div>
                            </div>
                        </div>

                        {/* 채무액 */}
                        <div className="moreDetailTaxWrap">
                            <div className="title">
                                <img src={require("img/common/icon_more.png")} alt="logo" />
                                <p className="name">(-)채무</p>
                            </div>
                            <div className="value">
                                <div>
                                    <span className="number blue">
                                        {calcResult ? Util.comma(calcResult.debt_amt ?? "") : ""}
                                    </span>
                                    <span className="unit blue">원</span>
                                </div>
                            </div>
                        </div>

                        {/* 공과금 */}
                        <div className="moreDetailTaxWrap">
                            <div className="title">
                                <img src={require("img/common/icon_more.png")} alt="logo" />
                                <p className="name blue">(-)공과금</p>
                            </div>
                            <div className="value">
                                <div>
                                    <span className="number blue">
                                        {calcResult ? Util.comma(calcResult.utility_bill_amt ?? "") : ""}
                                    </span>
                                    <span className="unit">원</span>
                                </div>
                            </div>
                        </div>

                        {/* 장례비용 */}
                        <div className="moreDetailTaxWrap">
                            <div className="title">
                                <img src={require("img/common/icon_more.png")} alt="logo" />
                                <p className="name blue">(-)공과금</p>
                            </div>
                            <div className="value">
                                <div>
                                    <span className="number blue">
                                        {calcResult ? Util.comma(calcResult.funeral_expen_amt ?? "") : ""}
                                    </span>
                                    <span className="unit">원</span>
                                </div>
                            </div>
                        </div>

                    </div>    
                    </OpenAndCloseContainer>

                    <div className="taxWrap">
                        <div className="tax">
                            <p className="name">(-)상속공제</p>
                            <div className="value">
                                <div>
                                    <span className="number">
                                    {calcResult ? Util.comma(calcResult.inhe_dedu_sum_amt ?? "") : ""}
                                    </span>
                                    <span className="unit">원</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 상속공제 상세 내역 */}
                    <OpenAndCloseContainer>
                    <div className={`detail-container ${detailResultOpen ? "open" : "closed"}`}>

                        {/* 상속공제 */}
                        <div className="moreDetailTaxWrap">
                            <div className="title">
                                <img src={require("img/common/icon_more.png")} alt="logo" />
                                <p className="name">기초공제 + 인적공제</p>
                            </div>
                            <div className="value">
                                <div>
                                    <span className="number blue">
                                        {calcResult ? Util.comma(calcResult.basic_person_dedu_amt ?? "") : ""}
                                    </span>
                                    <span className="unit">원</span>
                                </div>
                            </div>
                        </div>

                        {/* 일괄공제 */}
                        <div className="moreDetailTaxWrap">
                            <div className="title">
                                <img src={require("img/common/icon_more.png")} alt="logo" />
                                <p className="name">일괄공제</p>
                            </div>
                            <div className="value">
                                <div>
                                    <span className="number blue">
                                        {calcResult ? Util.comma(calcResult.inhe_sum_dedu_amt ?? "") : ""}
                                    </span>
                                    <span className="unit">원</span>
                                </div>
                            </div>
                        </div>

                        {/* 배우자 공제 */}
                        <div className="moreDetailTaxWrap">
                            <div className="title">
                                <img src={require("img/common/icon_more.png")} alt="logo" />
                                <p className="name blue">배우자 공제</p>

                                {/* 배우자 공제 계산법 도움말 */}
                                <div className="dpFlx aiC jcC taL tooltipWrap"
                                    data-tooltip-id="spouseDeducToolTip"
                                    data-tooltip-content={
                                            "♠ 배우자 공제\n\n"
                                            + "☞ 배우자 상속금액 5억 미만 : 5억\n"
                                            + "☞ 배우자 상속금액 5억 이상 : 아래 3개 중에서 적은 금액\n"
                                            + "1. 배우자 상속금액\n" 
                                            + "2. 배우자 법정지분 초과분\n"
                                            + "3. 30억\n"
                                        }>
                                        <img src={require('img/common/icon_info_fill_20.png')} alt="icon_info_fill_20" className='csP pl3' />
                                        <ToolTip id="spouseDeducToolTip" place='top' clickable={true} className="w500 lh20" />
                                </div>

                            </div>
                            <div className="value">
                                <div>
                                    <span className="number blue">
                                        {calcResult ? Util.comma(calcResult.spouse_deduc_amt ?? "") : ""}
                                    </span>
                                    <span className="unit">원</span>
                                </div>
                            </div>
                        </div>

                        {/* 가업상속공제 */}
                        <div className="moreDetailTaxWrap">
                            <div className="title">
                                <img src={require("img/common/icon_more.png")} alt="logo" />
                                <p className="name blue">가업상속공제</p>
                            </div>
                            <div className="value">
                                <div>
                                    <span className="number blue">
                                        {calcResult ? Util.comma(calcResult.family_dedu_amt ?? "") : ""}
                                    </span>
                                    <span className="unit">원</span>
                                </div>
                            </div>
                        </div>

                        {/* 영농상속공제 */}
                        <div className="moreDetailTaxWrap">
                            <div className="title">
                                <img src={require("img/common/icon_more.png")} alt="logo" />
                                <p className="name blue">영농상속공제</p>
                            </div>
                            <div className="value">
                                <div>
                                    <span className="number blue">
                                        {calcResult ? Util.comma(calcResult.family_dedu_amt ?? "") : ""}
                                    </span>
                                    <span className="unit">원</span>
                                </div>
                            </div>
                        </div>

                        {/* 동거주택상속공제 */}
                        <div className="moreDetailTaxWrap">
                            <div className="title">
                                <img src={require("img/common/icon_more.png")} alt="logo" />
                                <p className="name blue">동거주택상속공제</p>
                            </div>
                            <div className="value">
                                <div>
                                    <span className="number blue">
                                        {calcResult ? Util.comma(calcResult.house_dedu_amt ?? "") : ""}
                                    </span>
                                    <span className="unit">원</span>
                                </div>
                            </div>
                        </div>

                        {/* 금융상속공제 */}
                        <div className="moreDetailTaxWrap">
                            <div className="title">
                                <img src={require("img/common/icon_more.png")} alt="logo" />
                                <p className="name">금융상속공제</p>
                            </div>
                            <div className="value">
                                <div>
                                    <span className="number blue">
                                        {calcResult ? Util.comma(calcResult.finan_dedu_amt ?? "") : ""}
                                    </span>
                                    <span className="unit">원</span>
                                </div>
                            </div>
                        </div>

                    </div>   
                    </OpenAndCloseContainer> 
                    
                    {/* 과세표준 */}
                    <div className="taxWrap">
                        <div className="tax">
                            <p className="name">과세 표준</p>
                            <div className="value">
                                <div>
                                    <span className="number">
                                    {calcResult ? Util.comma(calcResult.inhe_tax_base ?? "") : ""}
                                    </span>
                                    <span className="unit">원</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* 증여세율 */}
                    <div className="taxWrap">
                        <div className="tax">
                            <p className="name">(*)세율</p>
                            <div className="value">
                                <div>
                                    <span className="number">
                                    {calcResult ? Util.comma(calcResult.inhe_tax_rate ?? "") : ""}
                                    </span>
                                    <span className="unit">%</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 누진공제금액 */}
                    <div className="taxWrap">
                        <div className="tax">
                            <p className="name">(-)누진공제금액</p>
                            <div className="value">
                                <div>
                                    <span className="number blue">
                                        {calcResult ? Util.comma(calcResult.inhe_progre_dedu_amt ?? "") : ""}
                                    </span>
                                    <span className="unit blue">원</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 산출세액 */}
                    <div className="taxWrap">
                        <div className="tax ">
                            <div>
                                <span className="name">산출세액(세대생략 가산액 안내)</span>

                                {/* 세대생략 가산액 도움말 Tooltip */}
                                <div
                                    className="tootipWrap"
                                    data-tooltip-id="generationSkipToolTip"
                                    data-tooltip-content={
                                        "♠ 증여세 세대 생략 할증과세\n\n" +
                                        "자녀를 건너 뛰어 손주에게 증여 시, 즉 세대 생략 증여 시 증여세율은 30% 할증됩니다.\n직계비속의 세대 생략 증여 시 수증자가 미성년자로 증여재산 가액이 20억을 초과하면 할증율은 40%가 적용됩니다."
                                    }
                                >
                                    <img src={require("img/common/icon_info_fill_20.png")} alt="icon_info_fill_20" className="csP pl3" />
                                    <ToolTip id="generationSkipToolTip" place="top" clickable={true} className="mxw278 lh20 wsPw wwBw owBw" />
                                </div>
                            </div>

                            <div className="value">
                                <div>
                                    <span className="number">
                                        {calcResult ? Util.comma(calcResult.inhe_tax_amt ?? "") : ""}
                                    </span>
                                    <span className="unit">원</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 기납부세액공제 */}
                    <div className="taxWrap">
                        <div className="tax">
                            <p className="name">(-)기납부세액공제</p>
                            <div className="value">
                                <div>
                                    <span className="number blue">
                                        {calcResult ? Util.comma(calcResult.pre_inhe_tax_amt ?? "") : ""}
                                    </span>
                                    <span className="unit blue">원</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 신고납부세액공제 */}
                    <div className="taxWrap">
                        <div className="tax">
                            <p className="name">(-)신고납부세액공제</p>
                            <div className="value">
                                <div>
                                    <span className="number blue">
                                        {calcResult ? Util.comma(calcResult.report_dedu_amt ?? "") : ""}
                                    </span>
                                    <span className="unit blue">원</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 합계 */}
                    <div className="taxWrap">
                        <div className="tax">
                            <p className="name">합계</p>
                            <div className="value">
                                <div>
                                    <span className="number">
                                        {calcResult ? Util.comma(calcResult.total_inhe_tax_amt ?? "") : ""}
                                    </span>
                                    <span className="unit">원</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </TaxInfoContainer>
            </DetailTaxContainer>
        </>
    );
}

const TotalTaxContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  width: 100%;
  height: auto;
  padding: 38px 20px;
  background-color: white;
  border-radius: 12px;

    .title {
        position: relative;
        display: inline-block;

        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.4em;
        line-height: 1.32;
        letter-spacing: -0.05em;
        color: ${(props) => props.theme.color.sNavy};
        z-index: 2;

        .highlight {
            position: absolute;
            top: 3px;
            left: 6px;
            z-index: -1;

            width: 100%;
            height: 100%;
            background-color: ${(props) => props.theme.color.pGreenLight04};
            border-radius: 2px;
        }
    }
    .tax {
        .number {
            font-family: "Spoqa Han Sans Neo";
            font-weight: 500;
            font-size: 2.4em;
            line-height: 1;
            letter-spacing: -0.02em;
        }

        .unit {
            font-family: "Spoqa Han Sans Neo";
            font-weight: 400;
            font-size: 1.4em;
            line-height: 1.32;
            letter-spacing: -0.05em;

            margin-left: 3px;
        }
    } 

    .icon {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;

        width: 112.71px;
        height: 112.71px;

        img {
            width: 100%;
            height: 100%;
        }
    }
`;

const DetailTaxContainer = styled.div`
    width: 100%;
    height: auto;
    padding: 24px 20px;
    background-color: white;
    border-radius: 12px;
    margin-top: 10px;
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-family: "Spoqa Han Sans Neo";
        font-weight: 500;
        font-size: 1.4em;
        line-height: 1.32;
        letter-spacing: -0.05em;
        color: ${(props) => props.theme.color.sNavy};

        button {
            width: 24px;
            height: 24px;
            color: ${(props) => props.theme.color.nGray60};

            display: flex;
            justify-content: center;
            align-items: center;

            transition: transform 0.3s ease;

            img {
                width: 13px;
                height: auto;
            }
        }

        button.closed {
            transform: rotate(180deg);
        }
    }
`;

const TaxInfoContainer = styled.div`
    width: 100%;
    height: auto;
    margin-top: 20px;

    .taxWrap {
        padding: 8px 0;
        border-bottom: 1px solid ${(props) => props.theme.color.nGray80};

        .tootipWrap {
            display: inline-block;
            vertical-align: middle;
            
            img {
                width: auto;
                height: 18px;
            }
        }

        .tax {
            position: relative;

            display: flex;
            justify-content: space-between;
            align-items: center;

            background-color: ${(props) => props.theme.color.pGreenLight04};

            &.closed {
                background-color: white;
            }

            .name {
                font-family: "Spoqa Han Sans Neo";
                font-weight: 500;
                font-size: 1.4em;
                line-height: 1.32;
                letter-spacing: -0.05em;
                color: ${(props) => props.theme.color.sNavy};
            }

            .value {
                flex-basis: auto;

                display: flex;
                justify-content: end;

                .rate {
                    font-family: "Spoqa Han Sans Neo";
                    font-weight: 600;
                    font-size: 1.6em;
                    line-height: 1;
                    letter-spacing: -0.03125em;
                    color: ${(props) => props.theme.color.sNavyDark01};
                }

                .number {
                    font-family: "Spoqa Han Sans Neo";
                    font-weight: 600;
                    font-size: 1.6em;
                    line-height: 1;
                    letter-spacing: -0.03125em;
                    color: ${(props) => props.theme.color.pGreenDark01};

                    margin-left: 15px;
                }

                .unit {
                    font-family: "Spoqa Han Sans Neo";
                    font-weight: 400;
                    font-size: 1.2em;
                    line-height: 1;
                    letter-spacing: -0.058em;
                    color: #727b8e;
                    margin-left: 2px;
                }
            }

            .highlight {
                position: absolute;
                top: 0px;
                left: -3px;
                z-index: 0;

                width: calc(100% + 6px);
                height: 100%;
                background-color: ${(props) => props.theme.color.pGreenLight04};
                border-radius: 2px;
            }
        }

        .red {
            color: ${(props) => props.theme.color.sysRed100} !important;
        }
    }

    .detailWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 10px 0 10px 5px;

        .name {
            display: flex;
            align-items: center;

            font-family: "Spoqa Han Sans Neo";
            font-weight: 400;
            font-size: 1.4em;
            line-height: 1.32;
            letter-spacing: -0.05em;
            color: ${(props) => props.theme.color.nGray50};

            .icon {
                width: 16px;
                height: 16px;
                margin-right: 2px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .value {
            .number {
                font-family: "Spoqa Han Sans Neo";
                font-weight: 600;
                font-size: 1.6em;
                line-height: 1;
                letter-spacing: -0.03125em;
                color: ${(props) => props.theme.color.nGray30};
            }

            .unit {
                font-family: "Spoqa Han Sans Neo";
                font-weight: 400;
                font-size: 1.2em;
                line-height: 1;
                letter-spacing: -0.058em;
                color: #727b8e;
                margin-left: 2px;
            }
        }
    }

    .detailTaxWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 8px 0;
        border-bottom: 1px solid ${(props) => props.theme.color.nGray80};

        .name {
            font-family: "Spoqa Han Sans Neo";
            font-weight: 400;
            font-size: 1.4em;
            line-height: 1.32;
            letter-spacing: -0.05em;
            color: ${(props) => props.theme.color.nGray50};
        }

        .value {
            .number {
                font-family: "Spoqa Han Sans Neo";
                font-weight: 600;
                font-size: 1.6em;
                line-height: 1;
                letter-spacing: -0.03125em;
                color: ${(props) => props.theme.color.nGray30};
            }

            .unit {
                font-family: "Spoqa Han Sans Neo";
                font-weight: 400;
                font-size: 1.2em;
                line-height: 1;
                letter-spacing: -0.058em;
                color: #727b8e;
                margin-left: 2px;
            }

            .blue {
                color: ${(props) => props.theme.color.sysBlue100} !important;
            }
        }
    }

    .moreDetailTaxWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 39px;

        padding: 8px 0;

        &.tableContainer {
            height: auto;
            padding: 0 0 8px 8px;
            border-bottom: 1px solid ${(props) => props.theme.color.nGray80};
        }

        .title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 5px;
            font-family: "Spoqa Han Sans Neo";
            font-weight: 500;
            font-size: 1.4em;
            line-height: 1.32;
            letter-spacing: -0.05em;
            color: ${(props) => props.theme.color.nGray50};
            img {
                width: 16px;
                height: auto;
            }

            .tooltipWrap {
                img {
                    width: auto;
                    height: 18px;
                }
            }
        }

        .value {
            .number {
                font-family: "Spoqa Han Sans Neo";
                font-weight: 600;
                font-size: 1.6em;
                line-height: 1;
                letter-spacing: -0.03125em;
                color: ${(props) => props.theme.color.nGray30};
            }

            .unit {
                font-family: "Spoqa Han Sans Neo";
                font-weight: 400;
                font-size: 1.2em;
                line-height: 1;
                letter-spacing: -0.058em;
                color: #727b8e;
                margin-left: 2px;
            }

            .blue {
                color: ${(props) => props.theme.color.sysBlue100} !important;
            }
        }
    }
`;

const OpenAndCloseContainer = styled.div`
    .detail-container {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
    }

    .detail-container.open {
        max-height: 500px; /* 충분히 큰 값으로 설정 */
    }
`;

export default CalcResult;
