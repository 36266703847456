import { useState, useEffect, useCallback } from "react";
import { useSetRecoilState, useRecoilState } from "recoil";
import {
  acqsInputVisibleState,
  acqsInputValueState,
  acqsInputDisabledState,
  acqsInputHelpState,
  addrSearchResultState,
  acquiredAssetInfoState,
  directAddrInfoState,
} from "js/common/Atom";
import { AcqsTaxParamProps, AddrSearchResult } from "js/common/types/types";
import Util from "js/common/Util";

export function useAcqsInputs() {
  const setAcqsInputDisabled = useSetRecoilState(acqsInputDisabledState);
  const setAcqsInputHelp = useSetRecoilState(acqsInputHelpState);
  const [addrSearchResult, setAddrSearchResult] = useRecoilState(
    addrSearchResultState
  );
  const [acquiredAssetInfo, setAcquiredAssetInfo] = useRecoilState(
    acquiredAssetInfoState
  );
  const [acqsInputValue, setAcqsInputValue] =
    useRecoilState(acqsInputValueState);
  const [acqsInputVisible, setAcqsInputVisible] = useRecoilState(
    acqsInputVisibleState
  );
  const [directAddrInfo, setDirectAddrInfo] =
    useRecoilState(directAddrInfoState);

  const [useAddrSearch, setUseAddrSearch] = useState<boolean>(false);

  useEffect(() => {
    setUseAddrSearch(acqsInputVisible.visible23);
  }, [acqsInputVisible]);

  const initializeInputs = useCallback(() => {
    // 모든 input의 값을 초기화
    setAcqsInputVisible({
      visible01: true, // 01. 부동산의 종류(상세구분)
      visible02: false, // 02. 주택구분
      visible03: false, // 03. 취득구분 1(아파트/연립)
      visible04: false, // 04. 취득구분 2(오피스텔)
      visible05: false, // 05. 멸실여부
      visible06: false, // 06. 토지종류
      visible07: false, // 07. 자경기간
      visible08: false, // 08. 취득원인 1(기존 아파트)
      visible09: false, // 09. 취득원인 2(분양권/오피스텔)
      visible10: false, // 10. 취득원인 3(조합원입주권)
      visible11: false, // 11. 취득원인 4(다세대/다가구/다중/단독주택)
      visible12: false, // 12. 조정대상지역
      visible13: false, // 13. 주택수 1(조정대상지역)
      visible14: false, // 14. 주택수 2(비조정대상지역)
      visible15: false, // 15. 상속인의 주택유무
      visible16: false, // 16. 시가표준액 기준
      visible17: false, // 17. 증여자
      visible18: false, // 18. 증여자의 주택수
      visible19: false, // 19. 과세표준
      visible20: false, // 20. 지분율
      visible21: false, // 21. 면적
      visible22: false, // 22. 취득할 자산정보
      visible23: false, // 23. 추가 버튼
      visible24: false, // 24. 자산정보 Table
    });
    setAcqsInputValue({
      taxFlag: "",
      estateSe: "",
      estateFom: "",
      acqsSrc1: "",
      acqsSrc2: "",
      loseYn: "",
      landType: "",
      farmPeriod: "",
      acqsCause1: "",
      acqsCause2: "",
      acqsCause3: "",
      acqsCause4: "",
      mdatTrgetAre: "",
      hseNumAt1: "",
      hseNumAt2: "",
      inheirHseNo: "",
      stdMkPrice: "",
      donor: "",
      donorHseNo: "",
      taxBase: "",
      shareRatio: "",
      prvuseAr: "",
    });
    setAcqsInputDisabled({
      disabled01: false, // 01. 부동산의 종류(상세구분)
      disabled02: false, // 02. 주택구분
      disabled03: false, // 03. 취득구분 1(아파트/연립)
      disabled04: false, // 04. 취득구분 2(오피스텔)
      disabled05: false, // 05. 멸실여부
      disabled06: false, // 06. 토지종류
      disabled07: false, // 07. 자경기간
      disabled08: false, // 08. 취득원인 1(기존 아파트)
      disabled09: false, // 09. 취득원인 2(분양권/오피스텔)
      disabled10: false, // 10. 취득원인 3(조합원입주권)
      disabled11: false, // 11. 취득원인 4(다세대/다가구/다중/단독주택)
      disabled12: false, // 12. 조정대상지역
      disabled16: false, // 16. 시가표준액
      disabled20: false, // 20. 지분율
      disabled21: false, // 21. 면적
    });
    setAddrSearchResult({
      addr: null,
      dong: "",
      ho: "",
      bdNm: "",
      error: "",
    });

    setAcquiredAssetInfo({
      spfcKnd: "", // 용도지역
      mdatTrgetAreaAt: "", // 조장지역여부
      pblntfPc: "", // 공동주택가격
      housePc: "", // 개별주택갸격
      shareRatio: "", // 지분율
      apprDateNewHse: "", // 사용승인일
      prvuseAr: "", // 공동주택 전용면적
      houseTotar: "", // 단독주택 전용면적
    });

    setDirectAddrInfo({
      bdKnd: "1",
      sidoCode: "", // 시도정보
      sggCode: "", // 시군구정보
      sggCodeElVsb: true, // 시군구정보 표시여부
      emdCode: "", // 읍면동 정보
      sidoName: "", // 시도
      sggName: "", //시군구
      emdName: "", //읍면동
      sggCodeInfoList: [],
      emdCodeInfoList: [],
      rdnmAdresDetail: "", // 상세주소
      spfcKnd: "", // 용도지역
      mdatTrgetAreaAt: "", // 조장지역여부
      pblntfPc: "", // 공동주택가격
      shareRatio: "", // 지분율
      prvuseAr: "", // 공동주택 전용면적
    });
  }, [
    setAcqsInputVisible,
    setAcqsInputValue,
    setAcqsInputDisabled,
    setAddrSearchResult,
    setAcquiredAssetInfo,
    setDirectAddrInfo,
  ]);

  /* 01. 부동산의 종류 */
  const onUpdateEstateSe = (realEstateType: string) => {
    switch (realEstateType) {
      case "201": {
        // 주택 ▶ 02.주택구분
        setAcqsInputVisible((prev: any) => {
          return {
            ...prev,
            visible02: true,
            visible05: false, // 05.멸실여부 미표시
            visible04: false, // 04.취득구분2(오피스텔) 미표시
            visible11: false, // 11.취득원인 4(다세대/다가구/다중/단독주택) 미표시
            visible06: false, // 06.토지종류 미표시
          };
        });

        setAcqsInputValue((prev: AcqsTaxParamProps) => {
          return {
            ...prev,
            estateSe: realEstateType,
            loseYn: "", // 05.멸실여부 초기화
            acqsSrc2: "", // 04.취득구분2(오피스텔) 초기화
            acqsCause4: "", // 11.취득원인 4(다세대/다가구/다중/단독주택) 초기화
            landType: "", // 06.토지종류 초기화
          };
        });

        return;
      }
      case "202": {
        // 조합원입주권 ▶ 05. 멸실여부
        setAcqsInputVisible((prev: any) => {
          return {
            ...prev,
            visible05: true,
            visible02: false, // 02.주택구분 미표시
            visible04: false, // 04.취득구분2(오피스텔) 미표시
            visible11: false, // 11.취득원인 4(다세대/다가구/다중/단독주택) 미표시
            visible06: false, // 06.토지종류 미표시
          };
        });

        setAcqsInputValue((prev: AcqsTaxParamProps) => {
          return {
            ...prev,
            estateSe: realEstateType,
            estateFom: "", // 02.주택구분 초기화
            acqsSrc2: "", // 04.취득구분2(오피스텔) 초기화
            acqsCause4: "", // 11.취득원인 4(다세대/다가구/다중/단독주택) 초기화
            landType: "", // 06.토지종류 초기화
          };
        });

        return;
      }
      case "203": {
        // 오피스텔 ▶ 04.취득구분2(오피스텔)
        setAcqsInputVisible((prev: any) => {
          return {
            ...prev,
            visible04: true,
            visible02: false, // 02.주택구분 미표시
            visible05: false, // 05.멸실여부 미표시
            visible11: false, // 11.취득원인 4(다세대/다가구/다중/단독주택) 미표시
            visible06: false, // 06.토지종류 미표시
          };
        });

        setAcqsInputValue((prev: AcqsTaxParamProps) => {
          return {
            ...prev,
            estateSe: realEstateType,
            estateFom: "", // 02.주택구분 초기화
            loseYn: "", // 05.멸실여부 초기화
            acqsCause4: "", // 11.취득원인 4(다세대/다가구/다중/단독주택) 초기화
            landType: "", // 06.토지종류 초기화
          };
        });

        return;
      }
      case "204": {
        // 일반건물/상가 ▶ 11.취득원인 4(다세대/다가구/다중/단독주택)
        setAcqsInputVisible((prev: any) => {
          return {
            ...prev,
            visible11: true,
            visible02: false, // 02.주택구분 미표시
            visible05: false, // 05.멸실여부 미표시
            visible04: false, // 04.취득구분2(오피스텔) 미표시
            visible06: false, // 06.토지종류 미표시
          };
        });

        setAcqsInputValue((prev: AcqsTaxParamProps) => {
          return {
            ...prev,
            estateSe: realEstateType,
            estateFom: "", // 02.주택구분 초기화
            loseYn: "", // 05.멸실여부 초기화
            acqsSrc2: "", // 04.취득구분2(오피스텔) 초기화
            landType: "", // 06.토지종류 초기화
          };
        });

        return;
      }
      case "205": {
        // 토지 ▶ 06.토지종류
        setAcqsInputVisible((prev: any) => {
          return {
            ...prev,
            visible06: true,
            visible02: false, // 02.주택구분 미표시
            visible05: false, // 05.멸실여부 미표시
            visible04: false, // 04.취득구분2(오피스텔) 미표시
            visible11: false, // 11.취득원인 4(다세대/다가구/다중/단독주택) 미표시
          };
        });

        setAcqsInputValue((prev: AcqsTaxParamProps) => {
          return {
            ...prev,
            estateSe: realEstateType,
            estateFom: "", // 02.주택구분 초기화
            loseYn: "", // 05.멸실여부 초기화
            acqsSrc2: "", // 04.취득구분2(오피스텔) 초기화
            acqsCause4: "", // 11.취득원인 4(다세대/다가구/다중/단독주택) 초기화
          };
        });

        return;
      }
    }
  };

  /* 02. 주택구분 */
  const onUpdateEstateFom = (estateFom: string) => {
    switch (estateFom) {
      case "201": {
        setAcqsInputVisible((prev: any) => {
          // 아파트/연립 ▶ 03.취득구분1(아파트/연립)
          return {
            ...prev,
            visible03: true,
            visible11: false, // 11.취득원인(다세대/다가구/다중/단독주택) 미표시
          };
        });

        setAcqsInputValue((prev: AcqsTaxParamProps) => {
          return {
            ...prev,
            estateFom: estateFom,
            acqsCause4: "", // 11.취득원인(다세대/다가구/다중/단독주택) 초기화
          };
        });
        setAcqsInputDisabled((prev: any) => {
          return {
            ...prev,
            disabled01: true, // 01.부동산의 종류 fixed
          };
        });

        return;
      }
      case "202": {
        setAcqsInputVisible((prev: any) => {
          // 다세대/다가구/다중/단독주택 ▶ 11.취득원인(다세대/다가구/다중/단독주택)
          return {
            ...prev,
            visible11: true,
            visible03: false, // 03.취득구분(아파트/연립) 미표시
          };
        });

        setAcqsInputValue((prev: AcqsTaxParamProps) => {
          return {
            ...prev,
            estateFom: estateFom,
            acqsSrc1: "", // 03.취득구분(아파트/연립) 초기화
          };
        });

        setAcqsInputDisabled((prev: any) => {
          return {
            ...prev,
            disabled01: true, // 01.부동산의 종류 fixed
          };
        });
        return;
      }
    }
  };

  /* 03. 취득구분1(아파트/연립) */
  const onUpdateAcqsSrc1 = (acqsSrc1: string) => {
    switch (acqsSrc1) {
      case "201": {
        setAcqsInputVisible((prev: any) => {
          // 기존 아파트 ▶ 08.취득원인(기존 아파트)
          return {
            ...prev,
            visible08: true,
            visible09: false, // 09.취득원인(분양권/오피스텔) 미표시
            visible10: false, // 10. 취득원인(조합원입주권) 미표시
          };
        });
        setAcqsInputValue((prev: AcqsTaxParamProps) => {
          return {
            ...prev,
            acqsSrc1: acqsSrc1,
            acqsCause2: "", // 09.취득원인(분양권/오피스텔) 초기화
            acqsCause3: "", // 10. 취득원인(조합원입주권) 초기화
          };
        });
        setAcqsInputDisabled((prev: any) => {
          return {
            ...prev,
            disabled02: true, // 02. 주택구분 Fixed
          };
        });

        return;
      }
      case "202": {
        setAcqsInputVisible((prev: any) => {
          // 분양권에 의한 아파트 ▶ 09.취득원인(분양권/오피스텔)
          return {
            ...prev,
            visible09: true,
            visible08: false, // 08.취득원인(아파트) 미표시
            visible10: false, // 10. 취득원인(조합원입주권) 미표시
          };
        });

        setAcqsInputValue((prev: AcqsTaxParamProps) => {
          return {
            ...prev,
            acqsSrc1: acqsSrc1,
            acqsCause1: "", // 08.취득원인(아파트) 초기화
            acqsCause3: "", // 10. 취득원인(조합원입주권) 초기화
          };
        });
        setAcqsInputDisabled((prev: any) => {
          return {
            ...prev,
            disabled02: true, // 02. 주택구분 Fixed
          };
        });

        return;
      }
      case "203": {
        setAcqsInputVisible((prev: any) => {
          // 조합원입주권에 의한 아파트 ▶ 10.취득원인(조합원입주권)
          return {
            ...prev,
            visible10: true,
            visible08: false, // 08.취득원인(아파트) 미표시
            visible09: false, // 09.취득원인(분양권/오피스텔) 미표시
          };
        });

        setAcqsInputValue((prev: AcqsTaxParamProps) => {
          return {
            ...prev,
            acqsSrc1: acqsSrc1,
            acqsCause1: "", // 08.취득원인(아파트) 초기화
            acqsCause2: "", // 09.취득원인(분양권/오피스텔) 초기화
          };
        });
        setAcqsInputDisabled((prev: any) => {
          return {
            ...prev,
            disabled02: true, // 02. 주택구분 Fixed
          };
        });

        return;
      }
    }
  };

  /* 04. 취득구분(오피스텔) */
  const onUpdateAcqsSrc2 = (acqsSrc2: string) => {
    switch (acqsSrc2) {
      case "201": {
        setAcqsInputVisible((prev: any) => {
          // 조합원입주권에 의한 아파트 ▶ 10.취득원인(조합원입주권)
          return {
            ...prev,
            visible08: true,
            visible09: false, // 09.취득원인2(분양권/오피스텔) 미표시
          };
        });

        setAcqsInputValue((prev: AcqsTaxParamProps) => {
          return {
            ...prev,
            acqsSrc2: acqsSrc2,
            acqsCause2: "", // 09.취득원인2(분양권/오피스텔) 초기화
          };
        });

        return;
      }
      case "202": {
        setAcqsInputVisible((prev: any) => {
          // 조합원입주권에 의한 아파트 ▶ 10.취득원인(조합원입주권)
          return {
            ...prev,
            visible09: true,
            visible08: false, // 08.취득원인1(기존 아파트) 미표시
          };
        });

        setAcqsInputValue((prev: AcqsTaxParamProps) => {
          return {
            ...prev,
            acqsSrc2: acqsSrc2,
            acqsCause1: "", // 08.취득원인1(기존 아파트) 초기화
          };
        });

        return;
      }
    }
  };

  /* 05. 멸실여부 */
  const onUpdateLoseYn = (loseYn: string) => {
    // 조합원입주권
    // 멸실 전(201), 멸실 후(202) ▶ 10.취득원인(조합원입주권)
    setAcqsInputVisible((prev: any) => {
      return {
        ...prev,
        visible10: true,
      };
    });

    setAcqsInputValue((prev: AcqsTaxParamProps) => {
      return {
        ...prev,
        loseYn: loseYn,
      };
    });

    return;
  };

  /* 06. 토지종류 */
  const onUpdateLandType = (landType: string) => {
    switch (landType) {
      case "201": {
        setAcqsInputVisible((prev: any) => {
          // 농지 ▶ 07.자경기간
          return {
            ...prev,
            visible07: true,
            visible08: false, // 08.취득원인(기존 아파트) 미표시
          };
        });

        setAcqsInputValue((prev: AcqsTaxParamProps) => {
          return {
            ...prev,
            landType: landType,
            acqsCause1: "", // 08.취득원인(기존 아파트) 초기화
          };
        });

        return;
      }
      case "202": {
        setAcqsInputVisible((prev: any) => {
          // 농지 외 ▶ 08.취득원인(기존 아파트)
          return {
            ...prev,
            visible08: true,
            visible07: false, // 07.자경기간 미표시
          };
        });

        setAcqsInputValue((prev: AcqsTaxParamProps) => {
          return {
            ...prev,
            landType: landType,
            farmPeriod: "", // 07.자경기간 초기화
          };
        });

        return;
      }
    }
  };

  /* 07. 자경기간 */
  const onUpdateFarmPeriod = (farmPeriod: string) => {
    // 토지 > 농지
    // 2년미만(비자경), 2년이상 ▶ 08.취득원인(기존 아파트)
    setAcqsInputVisible((prev: any) => {
      return {
        ...prev,
        visible08: true,
      };
    });

    setAcqsInputValue((prev: AcqsTaxParamProps) => {
      return {
        ...prev,
        farmPeriod: farmPeriod,
      };
    });

    return;
  };

  /* 08. 취득원인 1(기존 아파트) */
  const onUpdateAcqsCause1 = (acqsCause1: string) => {
    // 과세표준 도움말 Map
    const helpText: Record<string, string> = {
      "203": "실제 취득가액",
      "204": "시가표준액",
      "205": "시가인정액",
    };

    setAcqsInputValue((prev: AcqsTaxParamProps) => {
      return {
        ...prev,
        acqsCause1: acqsCause1,
      };
    });

    // acqsCase1에 따른 과세표준 도움말 set
    setAcqsInputHelp((prev: any) => {
      return {
        ...prev,
        taxBaseHelp: helpText[acqsCause1],
      };
    });

    // 주택 > 아파트/연립 > 기존 아파트
    if (acqsInputValue.acqsSrc1 === "201") {
      setAcqsInputVisible((prev: any) => {
        return {
          ...prev,
          visible22: true,
          visible23: true,
        };
      });
      setAcqsInputDisabled((prev: any) => {
        return {
          ...prev,
          disabled03: true, // 03. 취득구분 Fixed
        };
      });
    }

    // 오피스텔 > 기존 오피스텔
    // 매매(203), 상속(204), 증여(205) ▶ 19.과세표준, 20.지분율
    if (acqsInputValue.acqsSrc2 === "201") {
      setAcqsInputVisible((prev: any) => {
        return {
          ...prev,
          visible19: true,
          visible20: true,
        };
      });
      setAcqsInputDisabled((prev: any) => {
        return {
          ...prev,
          disabled04: true, // 04.취득원인1(기존 아파트) Fixed
        };
      });
    }

    // 토지 > 농지 > 자경기간
    // 매매(203), 상속(204), 증여(205) ▶ 19.과세표준, 20.지분율
    if (acqsInputValue.landType === "201") {
      setAcqsInputVisible((prev: any) => {
        return {
          ...prev,
          visible19: true,
          visible20: true,
        };
      });
      setAcqsInputDisabled((prev: any) => {
        return {
          ...prev,
          disabled07: true, // 07.자경기간 Fixed
        };
      });
    } else if (acqsInputValue.landType === "202") {
      setAcqsInputVisible((prev: any) => {
        return {
          ...prev,
          visible19: true,
          visible20: true,
        };
      });
      setAcqsInputDisabled((prev: any) => {
        return {
          ...prev,
          disabled06: true, // 06.토지종류 Fixed
        };
      });
    }
  };

  /* 09. 취득원인 2(분양권/오피스텔) */
  const onUpdateAcqsCause2 = (acqsCause2: string) => {
    // 과세표준 도움말 Map
    const helpText: Record<string, string> = {
      "201": "실제 취득가액",
      "203": "실제 취득가액",
      "204": "시가표준액",
      "205": "시가인정액",
    };

    // acqsCause2에 따른 과세표준 도움말 set
    setAcqsInputHelp((prev: any) => {
      return {
        ...prev,
        taxBaseHelp: helpText[acqsCause2],
      };
    });

    setAcqsInputValue((prev: AcqsTaxParamProps) => {
      return {
        ...prev,
        acqsCause2: acqsCause2,
      };
    });
    // 주택 > 아파트/연립 > 분양권에 의한 아파트 > 분양(신규, 미분양)(201), 전매(203), 상속(204), 증여(205) ▶ 22. 취득할 주택정보 추가
    // 분양권에 의한 아파트
    if (acqsInputValue.acqsSrc1 === "202") {
      setAcqsInputVisible((prev: any) => {
        return {
          ...prev,
          visible22: true,
          visible23: true,
        };
      });
      setAcqsInputDisabled((prev: any) => {
        return {
          ...prev,
          disabled03: true, // 03.취득구분1(아파트/연립) Fixed
        };
      });
    }

    // 오피스텔 > 일반분양 오피스텔
    // 분양(신규, 미분양)(201), 전매(203), 상속(204), 증여(205) ▶ 19.과세표준, 20.지분율
    if (acqsInputValue.acqsSrc2 === "202") {
      setAcqsInputVisible((prev: any) => {
        return {
          ...prev,
          visible19: true,
          visible20: true,
        };
      });
      setAcqsInputDisabled((prev: any) => {
        return {
          ...prev,
          disabled04: true, // 04.취득구분2(오피스텔) Fixed
        };
      });
    }
  };

  /* 10. 취득원인 3(조합원입주권) */
  const onUpdateAcqsCause3 = (acqsCause3: string) => {
    setAcqsInputValue((prev: AcqsTaxParamProps) => {
      return {
        ...prev,
        acqsCause3: acqsCause3,
      };
    });

    // 주택 > 아파트/연립 > 조합원입주권에 의한 아파트 > 원조합원(202), 전매(203), 상속(204), 증여(205) ▶ 22. 취득할 주택정보 추가
    if (acqsInputValue.acqsSrc1 === "203") {
      setAcqsInputVisible((prev: any) => {
        return {
          ...prev,
          visible22: true,
          visible23: true,
        };
      });
      setAcqsInputDisabled((prev: any) => {
        return {
          ...prev,
          disabled03: true, // 03. 취득구분 Fixed
        };
      });
      setAcqsInputHelp((prev: any) => {
        return {
          ...prev,
          taxBaseHelp: "건축비 상당액",
        };
      });
    }

    // 조합원입주권 > 멸실여부
    if (acqsInputValue.loseYn === "201") {
      // 멸실 전
      switch (acqsCause3) {
        case "202": {
          // 원조합원 ▶ 과세 대상 아님
          setAcqsInputVisible((prev: any) => {
            return {
              ...prev,
              visible12: false, // 12.조정대상지역 미표시
              visible15: false, // 15.상속인의 주택유무 미표시
            };
          });
          setAcqsInputValue((prev: AcqsTaxParamProps) => {
            return {
              ...prev,
              mdatTrgetAre: "", // 12.조정대상지역 초기화
              inheirHseNo: "", // 15.상속인의 주택유무 초기화
            };
          });
          setAcqsInputDisabled((prev: any) => {
            return {
              ...prev,
              disabled05: true, // 05.멸실여부 Fixed
            };
          });

          return;
        }
        case "203": {
          // 전매 ▶ 12.조정대상지역
          setAcqsInputVisible((prev: any) => {
            return {
              ...prev,
              visible12: true,
              visible15: false, // 15.상속인의 주택유무 미표시
            };
          });
          setAcqsInputValue((prev: AcqsTaxParamProps) => {
            return {
              ...prev,
              inheirHseNo: "", // 15.상속인의 주택유무 초기화
            };
          });
          setAcqsInputDisabled((prev: any) => {
            return {
              ...prev,
              disabled05: true, // 05.멸실여부 Fixed
            };
          });
          setAcqsInputHelp((prev: any) => {
            return {
              ...prev,
              taxBaseHelp: "실제 취득가액",
            };
          });

          return;
        }
        case "204": {
          // 상속 ▶ 15.상속인의 주택유무
          setAcqsInputVisible((prev: any) => {
            return {
              ...prev,
              visible15: true,
              visible12: false, // 12.조정대상지역 미표시
            };
          });
          setAcqsInputValue((prev: AcqsTaxParamProps) => {
            return {
              ...prev,
              mdatTrgetAre: "", // 12.조정대상지역 초기화
            };
          });
          setAcqsInputDisabled((prev: any) => {
            return {
              ...prev,
              disabled05: true, // 05.멸실여부 Fixed
            };
          });
          setAcqsInputHelp((prev: any) => {
            return {
              ...prev,
              taxBaseHelp: "시가표준액",
            };
          });

          return;
        }
        case "205": {
          // 증여 ▶ 12.조정대상지역
          setAcqsInputVisible((prev: any) => {
            return {
              ...prev,
              visible12: true,
              visible15: false, // 15.상속인의 주택유무 미표시
            };
          });
          setAcqsInputValue((prev: AcqsTaxParamProps) => {
            return {
              ...prev,
              inheirHseNo: "", // 15.상속인의 주택유무 초기화
            };
          });
          setAcqsInputDisabled((prev: any) => {
            return {
              ...prev,
              disabled05: true, // 05.멸실여부 Fixed
            };
          });
          setAcqsInputHelp((prev: any) => {
            return {
              ...prev,
              taxBaseHelp: "시가인정액",
            };
          });

          return;
        }
      }
    } else if (acqsInputValue.loseYn === "202") {
      // 멸실 후
      if (acqsCause3 === "202") {
        setAcqsInputVisible((prev: any) => {
          return {
            ...prev,
            visible19: false, // 19.과세표준 미표시
            visible20: false, // 20.지분율 미표시
          };
        });
        setAcqsInputValue((prev: AcqsTaxParamProps) => {
          return {
            ...prev,
            taxBase: "", // 19.과세표준 초기화
            shareRatio: "", // 20.지분율 초기화
          };
        });
        setAcqsInputDisabled((prev: any) => {
          return {
            ...prev,
            disabled05: true, // 05.멸실여부 Fixed
          };
        });
      } else if (
        acqsCause3 === "203" ||
        acqsCause3 === "204" ||
        acqsCause3 === "205"
      ) {
        setAcqsInputVisible((prev: any) => {
          return {
            ...prev,
            visible19: true,
            visible20: true,
          };
        });
        setAcqsInputDisabled((prev: any) => {
          return {
            ...prev,
            disabled05: true, // 05.멸실여부 Fixed
          };
        });

        if (acqsCause3 === "203")
          setAcqsInputHelp((prev: any) => ({
            ...prev,
            taxBaseHelp: "실제 취득가액",
          }));
        if (acqsCause3 === "204")
          setAcqsInputHelp((prev: any) => ({
            ...prev,
            taxBaseHelp: "시가표준액",
          }));
        if (acqsCause3 === "205")
          setAcqsInputHelp((prev: any) => ({
            ...prev,
            taxBaseHelp: "시가인정액",
          }));
      }
    }
  };

  /* 11. 취득원인 4(다세대/다가구/다중/단독주택) */
  const onUpdateAcqsCause4 = (acqsCause4: string) => {
    // 과세표준 도움말 Map
    const helpText: Record<string, string> = {
      "203": "실제 취득가액",
      "204": "시가표준액",
      "205": "시가인정액",
      "206": "실제 취득가액",
    };

    setAcqsInputValue((prev: AcqsTaxParamProps) => {
      return {
        ...prev,
        acqsCause4: acqsCause4,
      };
    });

    // 주택 > 다세대/다가구/단독
    if (acqsInputValue.estateFom === "202") {
      setAcqsInputVisible((prev: any) => {
        return {
          ...prev,
          visible22: true,
          visible23: true,
        };
      });
      setAcqsInputDisabled((prev: any) => {
        return {
          ...prev,
          disabled02: true, // 02.주택구분 Fixed
        };
      });
    }

    // 일반건물/상가
    // 취득원인4 : 매매(203), 상속(204), 증여(205), 신축(206) ▶ 19.과세표준, 20.지분율
    if (acqsInputValue.estateSe === "204") {
      setAcqsInputVisible((prev: any) => {
        return {
          ...prev,
          visible19: true,
          visible20: true,
        };
      });
      setAcqsInputDisabled((prev: any) => {
        return {
          ...prev,
          disabled01: true, // 01. 부동산의 종류 Fixed
        };
      });
      setAcqsInputHelp((prev: any) => {
        return {
          ...prev,
          taxBaseHelp: helpText[acqsCause4],
        };
      });
    }
  };

  /* 취득할 자산정보(22, 23) -> 모바일에서는 주소 검색을 통한 자산정보 입력 단계가 있음.*/

  /* 12. 조정대상지역 */
  const onUpdateMdatTrgetAre = (mdatTrgetAre: string) => {
    setAcqsInputValue((prev: AcqsTaxParamProps) => ({
      ...prev,
      mdatTrgetAre: mdatTrgetAre,
    }));

    // 조합원입주권 > 멸실전
    // 10. 취득원인 - 전매(203) : acqsCause3
    if (acqsInputValue.loseYn === "201") {
      // 전매(203)
      if (acqsInputValue.acqsCause3 === "203") {
        if (mdatTrgetAre === "201") {
          // 조정대상지역 ▶ 13.주택수(조정), 19.과세표준, 20.지분율, 21.면적
          setAcqsInputVisible((prev: any) => ({
            ...prev,
            visible13: true,
            visible19: true,
            visible20: true,
            visible21: true,
            visible14: false, // 14.주택수(비조정) 미표시
          }));

          setAcqsInputValue((prev: AcqsTaxParamProps) => ({
            ...prev,
            hseNumAt2: "",
          })); // 14.주택수(비조정) 초기화
          setAcqsInputDisabled((prev: any) => ({ ...prev, disabled10: true })); // 10.취득원인(조합원입주권) Fixed
        } else if (mdatTrgetAre === "202") {
          // 비조정대상지역 ▶ 14.주택수(비조정), 19.과세표준, 20.지분율, 21.면적
          setAcqsInputVisible((prev: any) => ({
            ...prev,
            visible14: true,
            visible19: true,
            visible20: true,
            visible21: true,
            visible13: false, // 13.주택수(조정) 미표시
          }));

          setAcqsInputValue((prev: AcqsTaxParamProps) => ({
            ...prev,
            hseNumAt1: "",
          })); // 13.주택수(조정) 미표시
          setAcqsInputDisabled((prev: any) => ({ ...prev, disabled10: true })); // 10.취득원인(조합원입주권) Fixed
        }

        setAcqsInputHelp((prev: any) => ({
          ...prev,
          houseNumHelp: "입주권 취득 당시 보유 주택 수(멸실 전 입주권 포함)",
        })); // 주택수 선택 도움말
      } else if (acqsInputValue.acqsCause3 === "205") {
        // 증여(205)
        if (mdatTrgetAre === "201") {
          // 조정대상지역   → 16.시가표준액
          setAcqsInputVisible((prev: any) => ({ ...prev, visible16: true }));
          setAcqsInputDisabled((prev: any) => ({ ...prev, disabled10: true })); // 10.취득원인(조합원입주권) Fixed
        } else if (mdatTrgetAre === "202") {
          // 비조정대상지역 → 19.과세표준, 20.지분율, 21.면적
          setAcqsInputVisible((prev: any) => ({
            ...prev,
            visible19: true,
            visible20: true,
            visible21: true,
            visible16: false, // 16.시가표준액 미표시
            visible17: false, // 17.증여자 미표시
            visible18: false, // 18.증여자 주택수 미표시
          }));
          setAcqsInputValue((prev: AcqsTaxParamProps) => ({
            ...prev,
            stdMkPrice: "", // 16.시가표준액 초기화
            donor: "", // 17.증여자 초기화
            donorHseNo: "", // 18.증여자 주택수 초기화
          }));
          setAcqsInputDisabled((prev: any) => ({ ...prev, disabled10: true })); // 10.취득원인(조합원입주권) Fixed
        }
      }
    }

    // 주택 > 다세대/다가구/다중/단독주택
    // 11. 취득원인 - 매매(203) : acqsCause4
    if (acqsInputValue.acqsCause4 === "203") {
      if (mdatTrgetAre === "201") {
        // 조정대상지역   → 13.주택수(조정), 19.과세표준, 20.지분율, 21.면적
        setAcqsInputVisible((prev: any) => ({
          ...prev,
          visible13: true,
          visible19: true,
          visible20: true,
          visible21: true,
          visible14: false, // 14.주택수(비조정) 미표시
        }));
        setAcqsInputValue((prev: AcqsTaxParamProps) => ({
          ...prev,
          hseNumAt2: "",
        })); // 14.주택수(비조정) 초기화
        setAcqsInputDisabled((prev: any) => ({ ...prev, disabled11: true })); // 11. 취득원인(다세대/다가구/단독) Fixed
      } else if (mdatTrgetAre === "202") {
        // 비조정대상지역 → 14. 주택수(비조정), 19.과세표준, 20.지분율, 21.면적
        setAcqsInputVisible((prev: any) => ({
          ...prev,
          visible14: true,
          visible19: true,
          visible20: true,
          visible21: true,
          visible13: false, // 13.주택수(조정) 미표시
        }));

        setAcqsInputValue((prev: AcqsTaxParamProps) => ({
          ...prev,
          hseNumAt1: "",
        })); // 13.주택수(조정) 미표시
        setAcqsInputDisabled((prev: any) => ({ ...prev, disabled11: true })); // 11. 취득원인(다세대/다가구/단독) Fixed
      }

      setAcqsInputHelp((prev: any) => ({
        ...prev,
        houseNumHelp:
          "다세대/다가구/다중/단독 취득 당시 보유 주택 수(취득할 주택 포함)",
      })); // 주택수 선택 도움말
    } else if (acqsInputValue.acqsCause4 === "205") {
      // 11. 취득원인 - 증여(205) : acqsCause4
      if (mdatTrgetAre === "201") {
        // 조정대상지역   → 16.시가표준액
        setAcqsInputVisible((prev: any) => ({ ...prev, visible16: true }));
        setAcqsInputDisabled((prev: any) => ({ ...prev, disabled11: true })); // 11. 취득원인(다세대/다가구/단독) Fixed
      } else if (mdatTrgetAre === "202") {
        // 비조정대상지역 → 19.과세표준, 20.지분율, 21.면적
        setAcqsInputVisible((prev: any) => ({
          ...prev,
          visible19: true,
          visible20: true,
          visible21: true,
          visible16: false, // 16.시가표준액 미표시
          visible17: false, // 17.증여자 미표시
          visible18: false, // 18.증여자 주택수 미표시
        }));
        setAcqsInputValue((prev: AcqsTaxParamProps) => ({
          ...prev,
          stdMkPrice: "", // 16.시가표준액 초기화
          donor: "", // 17.증여자 초기화
          donorHseNo: "", // 18.증여자 주택수 초기화
        }));
        setAcqsInputDisabled((prev: any) => ({ ...prev, disabled11: true })); // 11. 취득원인(다세대/다가구/단독) Fixed
      }
    }
  };

  /* 13. 주택수 1(조정대상지역) */
  const onUpdateHseNumAt1 = (hseNumAt1: string) => {
    setAcqsInputValue((prev: AcqsTaxParamProps) => ({
      ...prev,
      hseNumAt1: hseNumAt1,
    }));
  };

  /* 14. 주택수 2(비조정대상지역) */
  const onUpdateHseNumAt2 = (hseNumAt2: string) => {
    setAcqsInputValue((prev: AcqsTaxParamProps) => ({
      ...prev,
      hseNumAt2: hseNumAt2,
    }));
  };

  /* 15. 상속인의 주택유무 */
  const onUpdateInheirHseNo = (inheirHseNo: string) => {
    setAcqsInputValue((prev: AcqsTaxParamProps) => ({
      ...prev,
      inheirHseNo: inheirHseNo,
    }));

    // 주택 > 아파트/연립 > 기존 아파트 > 상속
    // 무주택자(201), 유주택자(201) ▶ 19.과세표준, 20.지분율
    if (acqsInputValue.acqsSrc1 === "201") {
      setAcqsInputVisible((prev: any) => ({
        ...prev,
        visible19: true,
        visible20: true,
      }));
      setAcqsInputDisabled((prev: any) => ({ ...prev, disabled08: true })); // 08.취득원인(기존 아파트) Fixed
    }

    // 주택 > 다세대/다가구/단독 > 상속
    // 무주택자(201), 유주택자(201) ▶ 19.과세표준, 20.지분율
    if (acqsInputValue.estateFom === "202") {
      setAcqsInputVisible((prev: any) => ({
        ...prev,
        visible19: true,
        visible20: true,
      }));
      setAcqsInputDisabled((prev: any) => ({ ...prev, disabled11: true })); // 11.취득원인(다세대/다가구/단독) Fixed
    }

    // 조합원입주권 > 멸실 전 > 상속
    // 무주택자(201), 유주택자(201) ▶ 19.과세표준, 20.지분율
    if (acqsInputValue.loseYn === "201") {
      setAcqsInputVisible((prev: any) => ({
        ...prev,
        visible19: true,
        visible20: true,
      }));
      setAcqsInputDisabled((prev: any) => ({ ...prev, disabled10: true })); // 10.취득원인3(조합원입주권) Fixed
    }
  };

  /* 16. 시가표준액 */
  const onUpdateStdMkPrice = (stdMkPrice: string) => {
    setAcqsInputValue((prev: AcqsTaxParamProps) => ({
      ...prev,
      stdMkPrice: stdMkPrice,
    }));
    setAcqsInputDisabled((prev: any) => ({ ...prev, disabled11: true })); // 11. 조정대상지역 Fixed

    // 주택 > 아파트/연립 > 기존아파트 > 증여 > 조정대상
    // 주택 > 다세대/다가구/다중/단독주택 > 증여 > 조정대상
    // 조합원입주권 > 멸실 전 > 증여 > 조정대상
    if (stdMkPrice === "201") {
      setAcqsInputVisible((prev: any) => ({
        ...prev,
        visible19: true,
        visible20: true,
        visible21: true,
        visible17: false, // 17.증여자 미표시
        visible18: false, // 18.증여자 주택수 미표시
      }));
      setAcqsInputValue((prev: AcqsTaxParamProps) => ({
        ...prev,
        donor: "", // 17.증여자 초기화
        donorHseNo: "", // 18.증여자 주택수 초기화
      }));
    } else if (stdMkPrice === "202") {
      // 3억 이상 ▶ 17. 증여자
      setAcqsInputVisible((prev: any) => ({
        ...prev,
        visible17: true,
      }));
    }
  };

  /* 17. 증여자 */
  const onUpdateDonor = (donor: string) => {
    setAcqsInputValue((prev: AcqsTaxParamProps) => ({ ...prev, donor: donor }));

    // 주택 > 아파트/연립 > 기존아파트 > 증여 > 조정대상 > 3억이상
    // 조합원입주권 > 멸실 전 > 증여 > 조정대상 > 3억 이상
    if (donor === "201" || donor === "202" || donor === "203") {
      // 배우자, 직계존속, 직계비속 ▶ 18.증여자의 주택수
      setAcqsInputVisible((prev: any) => ({
        ...prev,
        visible18: true,
      }));
    } else if (donor === "204") {
      // 기타 ▶ 19.과세표준, 20.지분율, 21.면적
      setAcqsInputVisible((prev: any) => ({
        ...prev,
        visible19: true,
        visible20: true,
        visible21: true,
        visible18: false, // 18.증여자 주택수 미표시
      }));
      setAcqsInputValue((prev: AcqsTaxParamProps) => ({
        ...prev,
        donorHseNo: "",
      })); // 18.증여자의 주택수 초기화
    }
  };

  /* 18. 증여자의 주택수 */
  const onUpdateDonorHseNo = (donorHseNo: string) => {
    setAcqsInputValue((prev: AcqsTaxParamProps) => ({
      ...prev,
      donorHseNo: donorHseNo,
    }));

    // 주택 > 아파트/연립 > 기존아파트 > 증여 > 조정대상 > 3억이상 > 배우자, 직계존속, 직계비속
    // 조합원입주권 > 멸실 전 > 증여 > 조정대상 > 3억 이상 > 배우자, 직계존속, 직계비속
    // 1주택(201), 2주택이상(202) ▶ 19.과세표준, 20.지분율, 21.면적
    setAcqsInputVisible((prev: any) => ({
      ...prev,
      visible19: true,
      visible20: true,
      visible21: true,
    }));
  };

  /* 19. 과세표준 */
  const onUpdateTaxBase = (taxBase: string) => {
    setAcqsInputValue((prev: AcqsTaxParamProps) => ({
      ...prev,
      taxBase: taxBase,
    }));
  };

  /* 20. 지분율 */
  const onUpdateShareRatio = (shareRatio: string) => {
    setAcqsInputValue((prev: AcqsTaxParamProps) => ({
      ...prev,
      shareRatio: shareRatio,
    }));
  };

  /* 21. 면적 */
  const onUpdatePrvuseAr = (prvuseAr: string) => {
    setAcqsInputValue((prev: AcqsTaxParamProps) => ({
      ...prev,
      prvuseAr: prvuseAr,
    }));
  };

  const onResetInBasicInfo = () => {
    // 모든 input의 값을 초기화
    setAcqsInputVisible({
      visible01: true, // 01. 부동산의 종류(상세구분)
      visible02: false, // 02. 주택구분
      visible03: false, // 03. 취득구분 1(아파트/연립)
      visible04: false, // 04. 취득구분 2(오피스텔)
      visible05: false, // 05. 멸실여부
      visible06: false, // 06. 토지종류
      visible07: false, // 07. 자경기간
      visible08: false, // 08. 취득원인 1(기존 아파트)
      visible09: false, // 09. 취득원인 2(분양권/오피스텔)
      visible10: false, // 10. 취득원인 3(조합원입주권)
      visible11: false, // 11. 취득원인 4(다세대/다가구/다중/단독주택)
      visible12: false, // 12. 조정대상지역
      visible13: false, // 13. 주택수 1(조정대상지역)
      visible14: false, // 14. 주택수 2(비조정대상지역)
      visible15: false, // 15. 상속인의 주택유무
      visible16: false, // 16. 시가표준액 기준
      visible17: false, // 17. 증여자
      visible18: false, // 18. 증여자의 주택수
      visible19: false, // 19. 과세표준
      visible20: false, // 20. 지분율
      visible21: false, // 21. 면적
      visible22: false, // 22. 취득할 자산정보
      visible23: false, // 23. 추가 버튼
      visible24: false, // 24. 자산정보 Table
    });
    setAcqsInputValue({
      taxFlag: "",
      estateSe: "",
      estateFom: "",
      acqsSrc1: "",
      acqsSrc2: "",
      loseYn: "",
      landType: "",
      farmPeriod: "",
      acqsCause1: "",
      acqsCause2: "",
      acqsCause3: "",
      acqsCause4: "",
      mdatTrgetAre: "",
      hseNumAt1: "",
      hseNumAt2: "",
      inheirHseNo: "",
      stdMkPrice: "",
      donor: "",
      donorHseNo: "",
      taxBase: "",
      shareRatio: "",
      prvuseAr: "",
    });
    setAcqsInputDisabled({
      disabled01: false, // 01. 부동산의 종류(상세구분)
      disabled02: false, // 02. 주택구분
      disabled03: false, // 03. 취득구분 1(아파트/연립)
      disabled04: false, // 04. 취득구분 2(오피스텔)
      disabled05: false, // 05. 멸실여부
      disabled06: false, // 06. 토지종류
      disabled07: false, // 07. 자경기간
      disabled08: false, // 08. 취득원인 1(기존 아파트)
      disabled09: false, // 09. 취득원인 2(분양권/오피스텔)
      disabled10: false, // 10. 취득원인 3(조합원입주권)
      disabled11: false, // 11. 취득원인 4(다세대/다가구/다중/단독주택)
      disabled12: false, // 12. 조정대상지역
      disabled16: false, // 16. 시가표준액
      disabled20: false, // 20. 지분율
      disabled21: false, // 21. 면적
    });
  };

  const onResetInAddresInfo = () => {
    setAddrSearchResult((prev: AddrSearchResult) => ({
      ...prev,
      addr: null,
      dong: "",
      ho: "",
      bdType: "",
    }));

    setAcquiredAssetInfo({
      spfcKnd: "", // 용도지역
      mdatTrgetAreaAt: "", // 조장지역여부
      pblntfPc: "", // 공동주택가격
      housePc: "", // 개별주택갸격
      shareRatio: "", // 지분율
      apprDateNewHse: "", // 사용승인일
      prvuseAr: "", // 공동주택 전용면적
      houseTotar: "", // 단독주택 전용면적
    });

    setDirectAddrInfo({
      bdKnd: "1",
      sidoCode: "", // 시도정보
      sggCode: "", // 시군구정보
      sggCodeElVsb: true, // 시군구정보 표시여부
      emdCode: "", // 읍면동 정보
      sidoName: "", // 시도
      sggName: "", //시군구
      emdName: "", //읍면동
      sggCodeInfoList: [],
      emdCodeInfoList: [],
      rdnmAdresDetail: "", // 상세주소
      spfcKnd: "", // 용도지역
      mdatTrgetAreaAt: "", // 조장지역여부
      pblntfPc: "", // 공동주택가격
      shareRatio: "", // 지분율
      prvuseAr: "", // 공동주택 전용면적
    });
  };

  const onResetInDongHoSelect = () => {
    // 상세주소입력 단계에서 선택 초기화 -> 상세주소입력, 최종주소입력 단계의 input 모두 초기화
    setAddrSearchResult((prev: AddrSearchResult) => ({
      ...prev,
      addr: null,
      dong: "",
      ho: "",
      bdType: "",
    }));

    setAcquiredAssetInfo({
      spfcKnd: "", // 용도지역
      mdatTrgetAreaAt: "", // 조장지역여부
      pblntfPc: "", // 공동주택가격
      housePc: "", // 개별주택갸격
      shareRatio: "", // 지분율
      apprDateNewHse: "", // 사용승인일
      prvuseAr: "", // 공동주택 전용면적
      houseTotar: "", // 단독주택 전용면적
    });
  };

  const onResetInFinalAddrInfo = () => {
    // 최종주소입력 단계에서 초기화
    setAcquiredAssetInfo({
      spfcKnd: "", // 용도지역
      mdatTrgetAreaAt: "", // 조장지역여부
      pblntfPc: "", // 공동주택가격
      housePc: "", // 개별주택갸격
      shareRatio: "", // 지분율
      apprDateNewHse: "", // 사용승인일
      prvuseAr: "", // 공동주택 전용면적
      houseTotar: "", // 단독주택 전용면적
    });
  };

  const onResetInExtraInfo = () => {
    setAcqsInputValue((prev: AcqsTaxParamProps) => ({
      ...prev,
      mdatTrgetAre: "",
      hseNumAt1: "",
      hseNumAt2: "",
      inheirHseNo: "",
      stdMkPrice: "",
      donor: "",
      donorHseNo: "",
      taxBase: "",
      shareRatio: "",
      prvuseAr: "",
    }));

    setAcqsInputVisible((prev: any) => ({
      ...prev,
      visible12: false,
      visible13: false,
      visible14: false,
      visible15: false,
      visible16: false,
      visible17: false,
      visible18: false,
      visible19: false,
      visible20: false,
      visible21: false,
    }));
  };

  const onNextInAddressInfo = () => {
    // 주소정보 입력 완료 후 next 버튼 눌렀을 때
    if (!addrSearchResult.addr) return; // 선택된 주소가 없으면 return

    const item = {
      bdKnd: addrSearchResult.addr.bdKdcd, // 공동주택 여부
      rdnmAdres: addrSearchResult.addr.roadAddr, // 도로주소명
      rdnmAdresDetail: addrSearchResult.dong + " " + addrSearchResult.ho, // 도로 상세주소
      lnmAdres: addrSearchResult.addr.jibunAddr, // 지번
      lnmAdresDetail: addrSearchResult.dong + " " + addrSearchResult.ho, // 지번 상세주소
      pnu: addrSearchResult.addr.pnu, // 토지고유코드
      spfcKnd: acquiredAssetInfo.spfcKnd, // 용도지역
      mdatTrgetAreaAt: acquiredAssetInfo.mdatTrgetAreaAt, // 조정대상지역
      pblntfPc: acquiredAssetInfo.pblntfPc, // 공동주택 가격
      housePc: acquiredAssetInfo.housePc, // 개별주택 가격
      shareRatio: acquiredAssetInfo.shareRatio, // 지분율
      apprDateNewHse: acquiredAssetInfo.apprDateNewHse, // 사용승인일
      legaldongCode: addrSearchResult.addr.admCd, // 법정동코드
      prvuseAr: acquiredAssetInfo.prvuseAr, // 공동주택 전용면적
      houseTotar: acquiredAssetInfo.houseTotar, // 단독주택 주택연면적
    };

    // 자산정보 표 표시?
    // 면적정보, 시가표준액
    let areaInfo = ""; // 면적
    let housePrice = ""; // 시가표준액(기준시가)
    if (item.bdKnd === "1") {
      // 공동주택
      areaInfo = item.prvuseAr;
      housePrice = item.pblntfPc;
    } else if (item.bdKnd === "0") {
      // 단독주택
      areaInfo = item.houseTotar;
      housePrice = item.housePc;
    } else {
    }
    // 면적정보 코드로 변환
    let prvuseAr = "";
    if (Number(areaInfo) <= 85) {
      prvuseAr = "201"; // 85이하
    } else if (Number(areaInfo) > 85) {
      prvuseAr = "202"; // 85이하
    }

    // 증여 시가표준액 3억이상 여부 코드로 변환
    let standardPrice = "";
    if (Number(Util.uncomma(housePrice)) < 300000000) {
      standardPrice = "201";
    } else if (Number(Util.uncomma(housePrice)) >= 300000000) {
      standardPrice = "202";
    }

    // 조정대상지역
    let targetArea = "";
    if (item.mdatTrgetAreaAt === "Y") {
      targetArea = "201";
    } else if (item.mdatTrgetAreaAt === "N") {
      targetArea = "202";
    }

    // 주택 > 아파트/연립 > 기존아파트
    if (
      acqsInputValue.estateSe === "201" &&
      acqsInputValue.estateFom === "201" &&
      acqsInputValue.acqsSrc1 === "201"
    ) {
      setAcqsInputDisabled((prev: any) => ({ ...prev, disabled08: true })); // 08. 취득원인 1(기존 아파트) Fixed

      // 매매 ▶ 12.조정대상지역, 13.주택수(조정) or 14.주택수(비조정), 19.과세표준, 20.지분율, 21.면적
      if (acqsInputValue.acqsCause1 === "203") {
        setAcqsInputValue((prev: AcqsTaxParamProps) => ({
          ...prev,
          mdatTrgetAre: targetArea, // 12.조정대상지역
          shareRatio: item.shareRatio, // 20. 지분율
          prvuseAr: prvuseAr, // 21. 면적
        }));
        setAcqsInputVisible((prev: any) => ({
          ...prev,
          visible12: true,
          visible19: true, // 19. 과세표준
          visible20: true,
          visible21: true,
        }));
        setAcqsInputDisabled((prev: any) => ({
          ...prev,
          disabled12: true,
          disabled20: true,
          disabled21: true,
        }));
        setAcqsInputHelp((prev: any) => ({
          ...prev,
          houseNumHelp: "아파트 취득 당시 보유 주택 수(취득할 주택 포함)", // 주택수 선택 도움말-기존아파트
        }));

        if (targetArea === "201") {
          setAcqsInputVisible((prev: any) => ({ ...prev, visible13: true })); // 13. 주택수 1(조정대상지역)
        } else if (targetArea === "202") {
          setAcqsInputVisible((prev: any) => ({ ...prev, visible14: true })); // 14. 주택수 2(비조정대상지역)
        } else {
        }
      }
      // 상속 ▶ 15.상속인의 주택유무, 19.과세표준, 20.지분율
      else if (acqsInputValue.acqsCause1 === "204") {
        setAcqsInputValue((prev: AcqsTaxParamProps) => ({
          ...prev,
          shareRatio: item.shareRatio, // 20. 지분율
        }));
        setAcqsInputVisible((prev: any) => ({
          ...prev,
          visible15: true, // 15.상속인의 주택유무
          visible19: true, // 19. 과세표준
          visible20: true, // 20.지분율
        }));
        setAcqsInputDisabled((prev: any) => ({
          ...prev,
          disabled20: true,
        }));
      }
      // 증여 ▶ 12.조정대상지역, 16.시가표준액, 17.증여자
      else if (acqsInputValue.acqsCause1 === "205") {
        setAcqsInputValue((prev: AcqsTaxParamProps) => ({
          ...prev,
          mdatTrgetAre: targetArea,
        })); // 12.조정대상지역
        setAcqsInputVisible((prev: any) => ({ ...prev, visible12: true }));
        setAcqsInputDisabled((prev: any) => ({ ...prev, disabled12: true }));

        // 조정대상지역
        if (targetArea === "201") {
          // 시가표준액
          setAcqsInputValue((prev: AcqsTaxParamProps) => ({
            ...prev,
            stdMkPrice: standardPrice,
          })); // 16.시가표준액 기준
          setAcqsInputVisible((prev: any) => ({ ...prev, visible16: true }));
          setAcqsInputDisabled((prev: any) => ({ ...prev, disabled16: true }));

          if (standardPrice === "201") {
            // 3억 미만 ▶ 19.과세표준, 20.지분율, 21.면적
            setAcqsInputVisible((prev: any) => ({
              ...prev,
              visible19: true, // 19. 과세표준
              visible20: true, // 20.지분율
              visible21: true, // 21.면적
            }));
            setAcqsInputValue((prev: AcqsTaxParamProps) => ({
              ...prev,
              shareRatio: item.shareRatio,
              prvuseAr: prvuseAr,
            }));
            setAcqsInputDisabled((prev: any) => ({
              ...prev,
              disabled20: true,
              disabled21: true,
            }));
          } else if (standardPrice === "202") {
            // 3억 이상 ▶ 17.증여자
            setAcqsInputVisible((prev: any) => ({ ...prev, visible17: true })); // 17.증여자
            setAcqsInputValue((prev: AcqsTaxParamProps) => ({
              ...prev,
              shareRatio: item.shareRatio,
              prvuseAr: prvuseAr,
            }));
            setAcqsInputDisabled((prev: any) => ({
              ...prev,
              disabled20: true,
              disabled21: true,
            })); // 20. 지분율 // 21. 면적
          }
        }
        // 비조정대상지역
        else if (targetArea === "202") {
          setAcqsInputVisible((prev: any) => ({
            ...prev,
            visible19: true, // 19. 과세표준
            visible20: true, // 20.지분율
            visible21: true, // 21.면적
          }));
          setAcqsInputValue((prev: AcqsTaxParamProps) => ({
            ...prev,
            shareRatio: item.shareRatio,
            prvuseAr: prvuseAr,
          }));
          setAcqsInputDisabled((prev: any) => ({
            ...prev,
            disabled20: true,
            disabled21: true,
          })); // 20. 지분율 // 21. 면적
        } else {
        }
      }
    }
    // 주택 > 아파트/연립 > 분양권에 의한 아파트 > 분양(신규, 미분양)(201), 전매(203), 상속(204), 증여(205) ▶ 12.조정대상지역, 13.주택수(조정) or 14.주택수(비조정), 19.과세표준, 20.지분율, 21.면적
    else if (
      acqsInputValue.estateSe === "201" &&
      acqsInputValue.estateFom === "201" &&
      acqsInputValue.acqsSrc1 === "202"
    ) {
      setAcqsInputValue((prev: AcqsTaxParamProps) => ({
        ...prev,
        mdatTrgetAre: targetArea,
        shareRatio: item.shareRatio,
        prvuseAr: prvuseAr,
      }));
      setAcqsInputVisible((prev: any) => ({
        ...prev,
        visible12: true,
        visible19: true,
        visible20: true,
        visible21: true,
      }));
      setAcqsInputDisabled((prev: any) => ({
        ...prev,
        disabled12: true,
        disabled20: true,
        disabled21: true,
      }));
      setAcqsInputHelp((prev: any) => ({
        ...prev,
        //houseNumHelp: '아파트 취득 당시 보유 주택 수(취득할 아파트 포함)', // 주택수 선택 도움말-분양권에 의한 아파트
        houseNumHelp: "분양권 취득 당시 보유 수(분양권 포함)", // 주택수 선택 도움말-분양권에 의한 아파트
      }));

      if (targetArea === "201") {
        setAcqsInputVisible((prev: any) => ({ ...prev, visible13: true })); // 13. 주택수 1(조정대상지역)
      } else if (targetArea === "202") {
        setAcqsInputVisible((prev: any) => ({ ...prev, visible14: true })); // 14. 주택수 2(비조정대상지역)
      } else {
      }
    }
    // 주택 > 아파트/연립 > 조합원입주권에 의한 아파트 > 분양(신규, 미분양)(201), 전매(203), 상속(204), 증여(205) ▶ 12.조정대상지역, 13.주택수(조정) or 14.주택수(비조정), 19.과세표준, 20.지분율, 21.면적
    else if (
      acqsInputValue.estateSe === "201" &&
      acqsInputValue.estateFom === "201" &&
      acqsInputValue.acqsSrc1 === "203"
    ) {
      setAcqsInputVisible((prev: any) => ({
        ...prev,
        visible19: true, // 19. 과세표준
        visible20: true, // 20.지분율
        visible21: true, // 21.면적
      }));
      setAcqsInputValue((prev: AcqsTaxParamProps) => ({
        ...prev,
        shareRatio: item.shareRatio,
        prvuseAr: prvuseAr,
      }));
      setAcqsInputDisabled((prev: any) => ({
        ...prev,
        disabled20: true,
        disabled21: true,
      })); // 20. 지분율 // 21. 면적
    }

    // 주택 > 다세대/다가구/다중/단독주택 > 매매(203)
    if (
      acqsInputValue.estateSe === "201" &&
      acqsInputValue.estateFom === "202"
    ) {
      setAcqsInputDisabled((prev: any) => ({ ...prev, disabled11: true })); // 11. 취득원인(다세대/다가구/단독) Fixed

      // 매매 ▶ 12.조정대상지역, 13.주택수(조정) or 14.주택수(비조정), 19.과세표준, 20.지분율, 21.면적
      if (acqsInputValue.acqsCause4 === "203") {
        setAcqsInputValue((prev: AcqsTaxParamProps) => ({
          ...prev,
          mdatTrgetAre: targetArea,
          shareRatio: item.shareRatio,
          prvuseAr: prvuseAr,
        }));
        setAcqsInputVisible((prev: any) => ({
          ...prev,
          visible12: true,
          visible19: true,
          visible20: true,
          visible21: true,
        }));
        setAcqsInputDisabled((prev: any) => ({
          ...prev,
          disabled12: true,
          disabled20: true,
          disabled21: true,
        }));
        setAcqsInputHelp((prev: any) => ({
          ...prev,
          houseNumHelp: "주택 취득 당시 보유 주택 수(취득할 주택 포함)", // 주택수 선택 도움말
          taxBaseHelp: "실제 취득가액", // 과세표준 도움말
        }));

        if (targetArea === "201") {
          setAcqsInputVisible((prev: any) => ({ ...prev, visible13: true })); // 13. 주택수 1(조정대상지역)
        } else if (targetArea === "202") {
          setAcqsInputVisible((prev: any) => ({ ...prev, visible14: true })); // 14. 주택수 2(비조정대상지역)
        } else {
        }
      }
      // 상속 ▶ 15.상속인의 주택유무, 19.과세표준, 20.지분율
      else if (acqsInputValue.acqsCause4 === "204") {
        setAcqsInputValue((prev: AcqsTaxParamProps) => ({
          ...prev,
          shareRatio: item.shareRatio, // 20. 지분율
        }));
        setAcqsInputVisible((prev: any) => ({
          ...prev,
          visible15: true, // 15.상속인의 주택유무
          visible19: true, // 19. 과세표준
          visible20: true, // 20.지분율
        }));
        setAcqsInputDisabled((prev: any) => ({
          ...prev,
          disabled20: true,
        }));
        setAcqsInputHelp((prev: any) => ({
          ...prev,
          taxBaseHelp: "시가표준액", // 과세표준 도움말
        }));
      }
      // 증여 ▶ 12.조정대상지역, 16.시가표준액, 17.증여자
      else if (acqsInputValue.acqsCause4 === "205") {
        setAcqsInputValue((prev: AcqsTaxParamProps) => ({
          ...prev,
          mdatTrgetAre: targetArea,
        })); // 12.조정대상지역
        setAcqsInputVisible((prev: any) => ({ ...prev, visible12: true }));
        setAcqsInputDisabled((prev: any) => ({ ...prev, disabled12: true }));
        setAcqsInputHelp((prev: any) => ({
          ...prev,
          taxBaseHelp: "시가인정액", // 과세표준 도움말
        }));

        // 조정대상지역
        if (targetArea === "201") {
          setAcqsInputValue((prev: AcqsTaxParamProps) => ({
            ...prev,
            stdMkPrice: standardPrice,
          })); // 16.시가표준액 기준
          setAcqsInputVisible((prev: any) => ({ ...prev, visible16: true }));
          setAcqsInputDisabled((prev: any) => ({ ...prev, disabled16: true }));

          if (standardPrice === "201") {
            // 3억 미만 ▶ 19.과세표준, 20.지분율, 21.면적
            setAcqsInputVisible((prev: any) => ({
              ...prev,
              visible19: true, // 19. 과세표준
              visible20: true, // 20.지분율
              visible21: true, // 21.면적
            }));
            setAcqsInputValue((prev: AcqsTaxParamProps) => ({
              ...prev,
              shareRatio: item.shareRatio,
              prvuseAr: prvuseAr,
            }));
            setAcqsInputDisabled((prev: any) => ({
              ...prev,
              disabled20: true,
              disabled21: true,
            })); // 20. 지분율 // 21. 면적
          } else if (standardPrice === "202") {
            // 3억 이상 ▶ 17.증여자
            setAcqsInputVisible((prev: any) => ({ ...prev, visible17: true }));
            setAcqsInputValue((prev: AcqsTaxParamProps) => ({
              ...prev,
              shareRatio: item.shareRatio,
              prvuseAr: prvuseAr,
            }));
            setAcqsInputDisabled((prev: any) => ({
              ...prev,
              disabled20: true,
              disabled21: true,
            })); // 20. 지분율 // 21. 면적
          }
        }
        // 비조정대상지역
        else if (targetArea === "202") {
          setAcqsInputVisible((prev: any) => ({
            ...prev,
            visible19: true, // 19. 과세표준
            visible20: true, // 20.지분율
            visible21: true, // 21.면적
          }));
          setAcqsInputValue((prev: AcqsTaxParamProps) => ({
            ...prev,
            shareRatio: item.shareRatio,
            prvuseAr: prvuseAr,
          }));
          setAcqsInputDisabled((prev: any) => ({
            ...prev,
            disabled20: true,
            disabled21: true,
          })); // 20. 지분율 // 21. 면적
        } else {
        }
      }
      // 자가신축 ▶ 19.과세표준, 20.지분율, 21.면적
      else if (acqsInputValue.acqsCause4 === "206") {
        setAcqsInputVisible((prev: any) => ({
          ...prev,
          visible19: true, // 19. 과세표준
          visible20: true, // 20.지분율
          visible21: true, // 21.면적
        }));
        setAcqsInputValue((prev: AcqsTaxParamProps) => ({
          ...prev,
          shareRatio: item.shareRatio,
          prvuseAr: prvuseAr,
        }));
        setAcqsInputDisabled((prev: any) => ({
          ...prev,
          disabled20: true,
          disabled21: true,
        })); // 20. 지분율 // 21. 면적
        setAcqsInputHelp((prev: any) => ({
          ...prev,
          taxBaseHelp: "실제 취득가액", // 과세표준 도움말
        }));
      } else {
      }
    }
  };

  const onNextInDirectAddressInfo = () => {
    // 주소정보 직접 입력 완료 후 next 버튼 눌렀을 때

    const item = {
      bdKnd: "1", // 공동주택 여부(Defalut 1로 설정)
      rdnmAdres:
        directAddrInfo.sidoName +
        " " +
        directAddrInfo.sggName +
        " " +
        directAddrInfo.emdName, // 주소
      rdnmAdresDetail: directAddrInfo.rdnmAdresDetail, // 상세주소
      pnu:
        directAddrInfo.sidoCode +
        directAddrInfo.sggCode +
        directAddrInfo.emdCode, // 토지고유코드
      spfcKnd: directAddrInfo.spfcKnd, // 용도지역
      mdatTrgetAreaAt: directAddrInfo.mdatTrgetAreaAt, // 조정대상지역
      pblntfPc: directAddrInfo.pblntfPc, // 공동주택 가격
      prvuseAr: directAddrInfo.prvuseAr, // 공동주택 전용면적
      shareRatio: directAddrInfo.shareRatio, // 지분율
      //    , apprDateNewHse: apprDateNewHse                  // 사용승인일
      legaldongCode: directAddrInfo.emdCode, // 법정동코드
    };
    // 자산정보 표 표시?
    // 면적정보, 시가표준액
    let areaInfo = ""; // 면적
    let housePrice = ""; // 시가표준액(기준시가)
    if (item.bdKnd === "1") {
      // 공동주택
      areaInfo = item.prvuseAr;
      housePrice = item.pblntfPc;
    }
    // 면적정보 코드로 변환
    let prvuseAr = "";
    if (Number(areaInfo) <= 85) {
      prvuseAr = "201"; // 85이하
    } else if (Number(areaInfo) > 85) {
      prvuseAr = "202"; // 85이하
    }

    // 증여 시가표준액 3억이상 여부 코드로 변환
    let standardPrice = "";
    if (Number(Util.uncomma(housePrice)) < 300000000) {
      standardPrice = "201";
    } else if (Number(Util.uncomma(housePrice)) >= 300000000) {
      standardPrice = "202";
    }

    // 조정대상지역
    let targetArea = "";
    if (item.mdatTrgetAreaAt === "Y") {
      targetArea = "201";
    } else if (item.mdatTrgetAreaAt === "N") {
      targetArea = "202";
    }

    // 주택 > 아파트/연립 > 기존아파트
    if (
      acqsInputValue.estateSe === "201" &&
      acqsInputValue.estateFom === "201" &&
      acqsInputValue.acqsSrc1 === "201"
    ) {
      setAcqsInputDisabled((prev: any) => ({ ...prev, disabled08: true })); // 08. 취득원인 1(기존 아파트) Fixed

      // 매매 ▶ 12.조정대상지역, 13.주택수(조정) or 14.주택수(비조정), 19.과세표준, 20.지분율, 21.면적
      if (acqsInputValue.acqsCause1 === "203") {
        setAcqsInputValue((prev: AcqsTaxParamProps) => ({
          ...prev,
          mdatTrgetAre: targetArea, // 12.조정대상지역
          shareRatio: item.shareRatio, // 20. 지분율
          prvuseAr: prvuseAr, // 21. 면적
        }));
        setAcqsInputVisible((prev: any) => ({
          ...prev,
          visible12: true,
          visible19: true, // 19. 과세표준
          visible20: true,
          visible21: true,
        }));
        setAcqsInputDisabled((prev: any) => ({
          ...prev,
          disabled12: true,
          disabled20: true,
          disabled21: true,
        }));
        setAcqsInputHelp((prev: any) => ({
          ...prev,
          houseNumHelp: "아파트 취득 당시 보유 주택 수(취득할 주택 포함)", // 주택수 선택 도움말-기존아파트
        }));

        if (targetArea === "201") {
          setAcqsInputVisible((prev: any) => ({ ...prev, visible13: true })); // 13. 주택수 1(조정대상지역)
        } else if (targetArea === "202") {
          setAcqsInputVisible((prev: any) => ({ ...prev, visible14: true })); // 14. 주택수 2(비조정대상지역)
        } else {
        }
      }
      // 상속 ▶ 15.상속인의 주택유무, 19.과세표준, 20.지분율
      else if (acqsInputValue.acqsCause1 === "204") {
        setAcqsInputValue((prev: AcqsTaxParamProps) => ({
          ...prev,
          shareRatio: item.shareRatio, // 20. 지분율
        }));
        setAcqsInputVisible((prev: any) => ({
          ...prev,
          visible15: true, // 15.상속인의 주택유무
          visible19: true, // 19. 과세표준
          visible20: true, // 20.지분율
        }));
        setAcqsInputDisabled((prev: any) => ({
          ...prev,
          disabled20: true,
        }));
      }
      // 증여 ▶ 12.조정대상지역, 16.시가표준액, 17.증여자
      else if (acqsInputValue.acqsCause1 === "205") {
        setAcqsInputValue((prev: AcqsTaxParamProps) => ({
          ...prev,
          mdatTrgetAre: targetArea,
        })); // 12.조정대상지역
        setAcqsInputVisible((prev: any) => ({ ...prev, visible12: true }));
        setAcqsInputDisabled((prev: any) => ({ ...prev, disabled12: true }));

        // 조정대상지역
        if (targetArea === "201") {
          // 시가표준액
          setAcqsInputValue((prev: AcqsTaxParamProps) => ({
            ...prev,
            stdMkPrice: standardPrice,
          })); // 16.시가표준액 기준
          setAcqsInputVisible((prev: any) => ({ ...prev, visible16: true }));
          setAcqsInputDisabled((prev: any) => ({ ...prev, disabled16: true }));

          if (standardPrice === "201") {
            // 3억 미만 ▶ 19.과세표준, 20.지분율, 21.면적
            setAcqsInputVisible((prev: any) => ({
              ...prev,
              visible19: true, // 19. 과세표준
              visible20: true, // 20.지분율
              visible21: true, // 21.면적
            }));
            setAcqsInputValue((prev: AcqsTaxParamProps) => ({
              ...prev,
              shareRatio: item.shareRatio,
              prvuseAr: prvuseAr,
            }));
            setAcqsInputDisabled((prev: any) => ({
              ...prev,
              disabled20: true,
              disabled21: true,
            }));
          } else if (standardPrice === "202") {
            // 3억 이상 ▶ 17.증여자
            setAcqsInputVisible((prev: any) => ({ ...prev, visible17: true })); // 17.증여자
            setAcqsInputValue((prev: AcqsTaxParamProps) => ({
              ...prev,
              shareRatio: item.shareRatio,
              prvuseAr: prvuseAr,
            }));
            setAcqsInputDisabled((prev: any) => ({
              ...prev,
              disabled20: true,
              disabled21: true,
            })); // 20. 지분율 // 21. 면적
          }
        }
        // 비조정대상지역
        else if (targetArea === "202") {
          setAcqsInputVisible((prev: any) => ({
            ...prev,
            visible19: true, // 19. 과세표준
            visible20: true, // 20.지분율
            visible21: true, // 21.면적
          }));
          setAcqsInputValue((prev: AcqsTaxParamProps) => ({
            ...prev,
            shareRatio: item.shareRatio,
            prvuseAr: prvuseAr,
          }));
          setAcqsInputDisabled((prev: any) => ({
            ...prev,
            disabled20: true,
            disabled21: true,
          })); // 20. 지분율 // 21. 면적
        } else {
        }
      }
    }
    // 주택 > 아파트/연립 > 분양권에 의한 아파트 > 분양(신규, 미분양)(201), 전매(203), 상속(204), 증여(205) ▶ 12.조정대상지역, 13.주택수(조정) or 14.주택수(비조정), 19.과세표준, 20.지분율, 21.면적
    else if (
      acqsInputValue.estateSe === "201" &&
      acqsInputValue.estateFom === "201" &&
      acqsInputValue.acqsSrc1 === "202"
    ) {
      setAcqsInputValue((prev: AcqsTaxParamProps) => ({
        ...prev,
        mdatTrgetAre: targetArea,
        shareRatio: item.shareRatio,
        prvuseAr: prvuseAr,
      }));
      setAcqsInputVisible((prev: any) => ({
        ...prev,
        visible12: true,
        visible19: true,
        visible20: true,
        visible21: true,
      }));
      setAcqsInputDisabled((prev: any) => ({
        ...prev,
        disabled12: true,
        disabled20: true,
        disabled21: true,
      }));
      setAcqsInputHelp((prev: any) => ({
        ...prev,
        //houseNumHelp: '아파트 취득 당시 보유 주택 수(취득할 아파트 포함)', // 주택수 선택 도움말-분양권에 의한 아파트
        houseNumHelp: "분양권 취득 당시 보유 수(분양권 포함)", // 주택수 선택 도움말-분양권에 의한 아파트
      }));

      if (targetArea === "201") {
        setAcqsInputVisible((prev: any) => ({ ...prev, visible13: true })); // 13. 주택수 1(조정대상지역)
      } else if (targetArea === "202") {
        setAcqsInputVisible((prev: any) => ({ ...prev, visible14: true })); // 14. 주택수 2(비조정대상지역)
      } else {
      }
    }
    // 주택 > 아파트/연립 > 조합원입주권에 의한 아파트 > 분양(신규, 미분양)(201), 전매(203), 상속(204), 증여(205) ▶ 12.조정대상지역, 13.주택수(조정) or 14.주택수(비조정), 19.과세표준, 20.지분율, 21.면적
    else if (
      acqsInputValue.estateSe === "201" &&
      acqsInputValue.estateFom === "201" &&
      acqsInputValue.acqsSrc1 === "203"
    ) {
      setAcqsInputVisible((prev: any) => ({
        ...prev,
        visible19: true, // 19. 과세표준
        visible20: true, // 20.지분율
        visible21: true, // 21.면적
      }));
      setAcqsInputValue((prev: AcqsTaxParamProps) => ({
        ...prev,
        shareRatio: item.shareRatio,
        prvuseAr: prvuseAr,
      }));
      setAcqsInputDisabled((prev: any) => ({
        ...prev,
        disabled20: true,
        disabled21: true,
      })); // 20. 지분율 // 21. 면적
    }

    // 주택 > 다세대/다가구/다중/단독주택 > 매매(203)
    if (
      acqsInputValue.estateSe === "201" &&
      acqsInputValue.estateFom === "202"
    ) {
      setAcqsInputDisabled((prev: any) => ({ ...prev, disabled11: true })); // 11. 취득원인(다세대/다가구/단독) Fixed

      // 매매 ▶ 12.조정대상지역, 13.주택수(조정) or 14.주택수(비조정), 19.과세표준, 20.지분율, 21.면적
      if (acqsInputValue.acqsCause4 === "203") {
        setAcqsInputValue((prev: AcqsTaxParamProps) => ({
          ...prev,
          mdatTrgetAre: targetArea,
          shareRatio: item.shareRatio,
          prvuseAr: prvuseAr,
        }));
        setAcqsInputVisible((prev: any) => ({
          ...prev,
          visible12: true,
          visible19: true,
          visible20: true,
          visible21: true,
        }));
        setAcqsInputDisabled((prev: any) => ({
          ...prev,
          disabled12: true,
          disabled20: true,
          disabled21: true,
        }));
        setAcqsInputHelp((prev: any) => ({
          ...prev,
          houseNumHelp: "주택 취득 당시 보유 주택 수(취득할 주택 포함)", // 주택수 선택 도움말
          taxBaseHelp: "실제 취득가액", // 과세표준 도움말
        }));

        if (targetArea === "201") {
          setAcqsInputVisible((prev: any) => ({ ...prev, visible13: true })); // 13. 주택수 1(조정대상지역)
        } else if (targetArea === "202") {
          setAcqsInputVisible((prev: any) => ({ ...prev, visible14: true })); // 14. 주택수 2(비조정대상지역)
        } else {
        }
      }
      // 상속 ▶ 15.상속인의 주택유무, 19.과세표준, 20.지분율
      else if (acqsInputValue.acqsCause4 === "204") {
        setAcqsInputValue((prev: AcqsTaxParamProps) => ({
          ...prev,
          shareRatio: item.shareRatio, // 20. 지분율
        }));
        setAcqsInputVisible((prev: any) => ({
          ...prev,
          visible15: true, // 15.상속인의 주택유무
          visible19: true, // 19. 과세표준
          visible20: true, // 20.지분율
        }));
        setAcqsInputDisabled((prev: any) => ({
          ...prev,
          disabled20: true,
        }));
        setAcqsInputHelp((prev: any) => ({
          ...prev,
          taxBaseHelp: "시가표준액", // 과세표준 도움말
        }));
      }
      // 증여 ▶ 12.조정대상지역, 16.시가표준액, 17.증여자
      else if (acqsInputValue.acqsCause4 === "205") {
        setAcqsInputValue((prev: AcqsTaxParamProps) => ({
          ...prev,
          mdatTrgetAre: targetArea,
        })); // 12.조정대상지역
        setAcqsInputVisible((prev: any) => ({ ...prev, visible12: true }));
        setAcqsInputDisabled((prev: any) => ({ ...prev, disabled12: true }));
        setAcqsInputHelp((prev: any) => ({
          ...prev,
          taxBaseHelp: "시가인정액", // 과세표준 도움말
        }));

        // 조정대상지역
        if (targetArea === "201") {
          setAcqsInputValue((prev: AcqsTaxParamProps) => ({
            ...prev,
            stdMkPrice: standardPrice,
          })); // 16.시가표준액 기준
          setAcqsInputVisible((prev: any) => ({ ...prev, visible16: true }));
          setAcqsInputDisabled((prev: any) => ({ ...prev, disabled16: true }));

          if (standardPrice === "201") {
            // 3억 미만 ▶ 19.과세표준, 20.지분율, 21.면적
            setAcqsInputVisible((prev: any) => ({
              ...prev,
              visible19: true, // 19. 과세표준
              visible20: true, // 20.지분율
              visible21: true, // 21.면적
            }));
            setAcqsInputValue((prev: AcqsTaxParamProps) => ({
              ...prev,
              shareRatio: item.shareRatio,
              prvuseAr: prvuseAr,
            }));
            setAcqsInputDisabled((prev: any) => ({
              ...prev,
              disabled20: true,
              disabled21: true,
            })); // 20. 지분율 // 21. 면적
          } else if (standardPrice === "202") {
            // 3억 이상 ▶ 17.증여자
            setAcqsInputVisible((prev: any) => ({ ...prev, visible17: true }));
            setAcqsInputValue((prev: AcqsTaxParamProps) => ({
              ...prev,
              shareRatio: item.shareRatio,
              prvuseAr: prvuseAr,
            }));
            setAcqsInputDisabled((prev: any) => ({
              ...prev,
              disabled20: true,
              disabled21: true,
            })); // 20. 지분율 // 21. 면적
          }
        }
        // 비조정대상지역
        else if (targetArea === "202") {
          setAcqsInputVisible((prev: any) => ({
            ...prev,
            visible19: true, // 19. 과세표준
            visible20: true, // 20.지분율
            visible21: true, // 21.면적
          }));
          setAcqsInputValue((prev: AcqsTaxParamProps) => ({
            ...prev,
            shareRatio: item.shareRatio,
            prvuseAr: prvuseAr,
          }));
          setAcqsInputDisabled((prev: any) => ({
            ...prev,
            disabled20: true,
            disabled21: true,
          })); // 20. 지분율 // 21. 면적
        } else {
        }
      }
      // 자가신축 ▶ 19.과세표준, 20.지분율, 21.면적
      else if (acqsInputValue.acqsCause4 === "206") {
        setAcqsInputVisible((prev: any) => ({
          ...prev,
          visible19: true, // 19. 과세표준
          visible20: true, // 20.지분율
          visible21: true, // 21.면적
        }));
        setAcqsInputValue((prev: AcqsTaxParamProps) => ({
          ...prev,
          shareRatio: item.shareRatio,
          prvuseAr: prvuseAr,
        }));
        setAcqsInputDisabled((prev: any) => ({
          ...prev,
          disabled20: true,
          disabled21: true,
        })); // 20. 지분율 // 21. 면적
        setAcqsInputHelp((prev: any) => ({
          ...prev,
          taxBaseHelp: "실제 취득가액", // 과세표준 도움말
        }));
      } else {
      }
    }
  };

  return {
    onUpdateEstateSe,
    onUpdateEstateFom,
    onUpdateAcqsSrc1,
    onUpdateAcqsSrc2,
    onUpdateLoseYn,
    onUpdateLandType,
    onUpdateFarmPeriod,
    onUpdateAcqsCause1,
    onUpdateAcqsCause2,
    onUpdateAcqsCause3,
    onUpdateAcqsCause4,
    onUpdateMdatTrgetAre,
    onUpdateHseNumAt1,
    onUpdateHseNumAt2,
    onUpdateInheirHseNo,
    onUpdateStdMkPrice,
    onUpdateDonor,
    onUpdateDonorHseNo,
    onUpdateTaxBase,
    onUpdateShareRatio,
    onUpdatePrvuseAr,
    onResetInBasicInfo,
    onResetInAddresInfo,
    onResetInDongHoSelect,
    onResetInFinalAddrInfo,
    onResetInExtraInfo,
    onNextInAddressInfo,
    onNextInDirectAddressInfo,
    initializeInputs,
    useAddrSearch,
  };
}
