const LttotRight = () => {
    return (
        <div className="w800 h500 ofA p20 lh20 ">
            <span className="fs13 fmForest-b ls08">
                <span className="fmForest-c fs15 ls08 lh30">1. 분양권</span><br/>
                분양권의 범위<br/>
                소득세법 제88조 제10호에서 주택법 등 대통령령으로 정하는 법률이란 다음 각 호의 법률을 말한다(2021.2.17. 신설)<br/>
                1.「건축물의 분양에 관한 법률」<br/>
                2.「공공주택 특별법」<br/>
                3.「도시개발법」<br/>
                4.「도시 및 주거환경정비법」<br/>
                5.「빈집 및 소규모주택 정비에 관한 특례법」<br/>
                6.「산업입지 및 개발에 관한 법률」<br/>
                7.「주택법」<br/>
                8.「택지개발촉진법」<br/><br/>
                분양권의 범위에 속하는 분양권은 주택 수에 포함되는 분양권이나 주거용오피스텔 분양권, 생활형 숙박시설 분양권은 주택 수에 포함되는 분양권에 해당하지 아니한다.<br/><br/>
                <span className="fmForest-c fs15 ls08 lh30">2. 상속분양권</span><br/>
                상속분양권이란 다음의 경우에 해당되어야 한다.<br/>
                ① 피상속인이 상속개시 당시 주택 또는 조합원입주권을 소유하지 않은 경우의 상속받은 분양권만을 말한다. 조합원입주권과 분양권만 소유한 경우에는 상속인이 분양권을 선택한 경우 상속분양권으로 본다.<br/>
                ② 상속개시 당시 동일세대가 아니어야 한다.<br/>
                상속인과 피상속인이 상속개시 당시 1세대인 경우에는 1주택을 보유하고 1세대를 구성하는 자가 1주택을 보유하고 있는 직계존속을 동거봉양하기 위해 세대를 합침에 따라 2주택을 보유하게 되는 경우로써 합치기 이전부터 보유하고 있었던 분양권만 상속받은 분양권으로 본다.<br/>
                ③ 피상속인이 상속개시 당시 2 이상의 분양권을 소유한 경우에는 다음의 순서에 따른 1분양권만 해당한다.<br/>
                가. 피상속인이 소유한 기간이 가장 긴 1분양권<br/>
                나. 상속인이 선택하는 1분양권<br/><br/>
                <span className="fmForest-c fs15 ls08 lh30">3. 공동상속분양권</span><br/>
                공동상속분양권이란 상속분양권에 추가하여 다음에 해당하는 사람이 그 공동상속분양권을 소유한 것으로 본다.<br/>
                가. 상속지분이 가장 큰 상속인<br/>
                나. 최연장자<br/><br/>
                <span className="fmForest-c fs15 ls08 lh30">4. 상속당시 평가액</span><br/>
                상속개시일까지 불입한 금액 + 상속개시일 현재의 프리미엄<br/><br/>
                <span className="fmForest-c fs15 ls08 lh30">5. 증여당시 평가액</span><br/>
                권리승계일까지 불입한 금액 + 권리승계일 현재의 프리미엄
            </span>
        </div>
    )
}
export default LttotRight;