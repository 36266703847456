import Util from "js/common/Util"
import React from "react"

interface codeInfoProps {
    codeNm: string
    code: string
}
interface ImageRadioBoxProps {
    codeList: codeInfoProps[]
    value: string
    setValue?: React.Dispatch<React.SetStateAction<string>>
    btnClass: string
    labelClass: string
    singleWidth?: number
    interval: number
    onChange?: Function
    disabled?: boolean
    disabledVals?: string[]
    filterVals?: string[]
}
const ImageRadioBox = ({codeList, value, setValue, btnClass, labelClass, interval, singleWidth, onChange, disabled, disabledVals, filterVals}: ImageRadioBoxProps) => {
    return (
        <div className="dpFlx aiC">
            {codeList.map((codeInfo: any, i: number) => {
                if (Util.isNotEmpty(filterVals)) {
                    if (Array.isArray(filterVals)) {
                        if (filterVals.indexOf(codeInfo.code) === -1) {
                            return <div key={i}></div>
                        }
                    }
                }
                let dsbFlag = false;
                if (disabledVals !== undefined) {
                    for (const disabledVal of disabledVals) {
                        if (disabledVal === codeInfo.code) {
                            dsbFlag = true;
                            break;
                        }
                    }
                }
                return (
                    <button key={i} style={{marginRight: interval, width: (Util.isEmpty(singleWidth) ? "auto" : singleWidth)}} className="dpFlx aiC csP bdN bgcTp p0" onClick={() => {
                        if (disabled || dsbFlag) {
                            return;
                        }
                        if (setValue !== undefined) {
                            setValue(codeInfo.code);
                        }
                        if (onChange !== undefined) {
                            onChange(codeInfo.code);
                        }
                    }}>
                        <img className={btnClass} src={require('img/common/radiobutton_base_' + (value === codeInfo.code ? 'on' : 'off') + '.png')} alt="radiobutton_base" />
                        <span className={(disabled || dsbFlag ? "fcBDBDBD-i " : "") + labelClass}>{codeInfo.codeNm}</span>
                    </button>
                )
            })}
        </div>
    )
};
export default ImageRadioBox;