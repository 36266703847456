/*******************************************************************************
 You are strictly prohibited to copy, disclose, distribute, modify, or use
 this program in part or as a whole without the prior written consent of
 CallTax. CallTax, owns the intellectual property rights in and to this program.
 (Copyright © 2023 CallTax. All Rights Reserved| Confidential)

 Specification
    1. Program ID    : BoardView.tsx
    2. Program Title : 게시판 상세보기
    3. Created by    : JH Kim
    4. Create Date   : 2023.
    5. Reference     :
        - 커뮤니티 > 공지사항 : 조회만 가능
        - 커뮤니티 > 전문가커뮤니티, 질의/응답 : 로그인 회원, 관리자
        - 관리자시스템 > 공지사항관리, FAQ 관리 : 관리자
*******************************************************************************/
import { useNavigate, useLocation } from "react-router-dom";
import Axios from "js/common/Axios";
import React from "react";
import { useParams } from "react-router-dom";
import Util from "js/common/Util";
import AppContext from "js/common/AppContext";
import MuiListFilter from "js/common/ui/list/MuiListFilter";
import ForestTableStyle from "js/common/ui/style/ForestStyle";
import { useRecoilValue } from "recoil";
import * as Atom from "js/common/Atom";

interface BoardProps {
  boardTy: string;
}
const BoardView = ({ boardTy }: BoardProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const userInfo = useRecoilValue(Atom.userInfo);

  const [boardTitle, setBoardTitle] = React.useState<string>("");
  const [regUserId, setRegUserId] = React.useState<string>("");
  // const [regUserEmail, setRegUserEmail] = React.useState<string>("");
  const [regYmd, setRegYmd] = React.useState<string>("");
  const [boardHit, setBoardHit] = React.useState<string>("");
  const [replyCnt, setReplyCnt] = React.useState<string>("");
  const [boardCtt, setBoardCtt] = React.useState<string>("");
  const [boardFiles, setBoardFiles] = React.useState<boardFileProps[]>([]);

  interface boardFileProps {
    fileSn: number;
    fileNm: string;
    fileExt: string;
    fileSize: number;
  }
  const [replyCtt, setReplyCtt] = React.useState<string>("");
  const [regUserSn, setRegUserSn] = React.useState<number>();
  const [boardSelTime, setBoardSelTime] = React.useState<Date>(new Date());
  const [filterDataArr, setFilterDataArr] = React.useState<any[]>([]);

  const goBack = () => {
    navigate(
      location.pathname.substring(0, location.pathname.indexOf("/view"))
    );
  };

  const goModify = () => {
    navigate(
      location.pathname.substring(0, location.pathname.indexOf("/view")) +
        "/mod/" +
        params.boardSn
    );
  };

  React.useEffect(() => {
    Axios.dataAccess({
      url: "portal/board/selBoardInfo.do",
      methodType: "post",
      paramType: "object",
      paramData: {
        boardSn: Number(params.boardSn),
      },
      onSuccessFn: (res: any) => {
        setBoardTitle(res.item.boardInfo.boardTitle);
        setRegUserId(res.item.boardInfo.regUserId);
        // setRegUserEmail (res.item.boardInfo.regUserEmail);
        setRegYmd(res.item.boardInfo.regYmd);
        setBoardHit(res.item.boardInfo.boardHit);
        setReplyCnt(res.item.boardInfo.replyCnt);
        setBoardCtt(res.item.boardInfo.boardCtt);
        setBoardFiles(res.item.boardFileList);
        setFilterDataArr(res.item.boardReplyList);
        setRegUserSn(res.item.boardInfo.regUserSn);
      },
    });
  }, [params.boardSn, boardSelTime]);

  return (
    <div className="dpFlx jcC">
      <div className="w1210">
        <div className="dpFlx flxdCol jcC pt60">
          <div className="taC pb30">
            <span className="dpBlock fcBlack fs38 ls266 SpoqaHanSansNeo-Medium">
              {Util.getValToCode("1033", boardTy)} 조회
            </span>
          </div>
          <div className="w100p h110 bdt1 bdtc333333 bdb1 bdbcE8E8ED pt20 pl10 pr10 bdBox">
            <span className="SpoqaHanSansNeo-Medium fs23 ls115 fc2B2B2B">
              {boardTitle}
            </span>
            <div className="w100p h30 mt10 dpFlx aiC">
              <span className="SpoqaHanSansNeo-Medium fs16 ls08 fw500">
                <span className="fc222222 mr20">{regUserId}</span>
                <span className="fc666666 mr20">
                  {Util.cvtDateStrForm(regYmd, ". ")}
                </span>
                <span className="fc222222 mr4">조회수</span>
                <span className="fc666666 mr20">{boardHit}</span>
                <span className="fc222222 mr4">댓글</span>
                <span className="fc17A840">{replyCnt}</span>
              </span>
            </div>
          </div>
          <div
            className="view ql-editor ls08"
            dangerouslySetInnerHTML={{ __html: boardCtt }}
          />
          {boardFiles.length > 0 ? (
            <div className="w100p mt30 mb36 bdBox pl10">
              {boardFiles.map((item: any, idx: number) => {
                return (
                  <div
                    key={idx}
                    className="dpFlx aiC csP mt10"
                    onClick={(e) => {
                      Axios.dataAccess({
                        url: "common/fileDownload.do",
                        methodType: "get",
                        isFileDown: true,
                        paramData: {
                          callType: "board",
                          fileName:
                            params.boardSn +
                            "-" +
                            item.fileSn +
                            "." +
                            item.fileExt,
                          orgFileName: item.fileNm,
                        },
                      });
                    }}
                  >
                    <img
                      src={require("img/common/icon_attach_file.png")}
                      alt="icon_attach_file"
                      className="mr5"
                    />
                    <span className="SpoqaHanSansNeo-Medium fs15 ls075 fc17A840">
                      {item.fileNm}
                    </span>
                  </div>
                );
              })}
            </div>
          ) : (
            <></>
          )}
          {["/portal/notice", "/system/expert", "/system/qna"].indexOf(
            location.pathname.substring(0, location.pathname.indexOf("/view"))
          ) !== -1 ? (
            <React.Fragment>
              <div className="w100p h60 pl10 bdBox dpFlx aiC bdt1 bdcE8E8ED">
                <span className="SpoqaHanSansNeo-Bold fs16 fc222222 mr40">
                  댓글
                </span>
                <span className="SpoqaHanSansNeo-Bold fs13 fc222222 mr20">
                  등록순
                </span>
                <span className="SpoqaHanSansNeo-Medium fs13 fc666666">
                  최신순
                </span>
              </div>
              <MuiListFilter
                tableInfo={{
                  tableHeadVsb: false,
                  headerVsb: false,
                  styleType: ForestTableStyle,
                  fldInfs: [
                    {
                      type: "el",
                      el: (item: any) => (
                        <div
                          key={item.boardSn}
                          className="w100p h100 p10 bdBox"
                        >
                          <div className="w100p h30 dpFlx jcSb">
                            <div className="dpFlx aiC">
                              <span className="lh30 SpoqaHanSansNeo-Medium fs14 ls07 fc2B2B2B fw500 mr10">
                                {item.regUserId}
                              </span>
                              <span className="lh30 SpoqaHanSansNeo-Medium fs13 ls065 fc666666 fw500">
                                {Util.cvtDateStrForm(item.regYmd, ". ")}
                              </span>
                            </div>
                            <div className="dpFlx aiC">
                              {/* <span className="SpoqaHanSansNeo-Medium fs13 ls065 fc666666 fw500 lh20">답글</span> */}
                              {/* <img
                                                        src={require('img/common/icon_more_vert20.png')}
                                                        alt="icon_more_vert20"
                                                        className={'ml10' + (userInfo.userSn !== item.regUserSn ? "dpNone" : "")}
                                                    /> */}
                              <span
                                className={
                                  "SpoqaHanSansNeo-Medium fs13 ls065 fc666666 fw500 lh20 csP " +
                                  (userInfo.userSn === item.regUserSn
                                    ? ""
                                    : "dpNone")
                                }
                                onClick={() => {
                                  AppContext.openAlert({
                                    title: "댓글 삭제",
                                    msg: "댓글을 삭제하시겠습니까?",
                                    icon: "check",
                                    confirmText: "댓글삭제",
                                    handleConfirm: () => {
                                      Axios.dataAccess({
                                        url: "system/board/delBoardReplyInfo.do",
                                        methodType: "post",
                                        paramType: "json",
                                        paramData: {
                                          boardSn: item.boardSn,
                                          replySn: item.replySn,
                                        },
                                        onSuccessFn: () => {
                                          setBoardSelTime(new Date());
                                        },
                                      });
                                    },
                                  });
                                }}
                              >
                                삭제
                              </span>
                            </div>
                          </div>
                          <div className="w100p dpFlx">
                            <span className="SpoqaHanSansNeo-Regular fs14 ls07">
                              {item.replyCtt}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                  ],
                  ordFld: { name: "boardSn", direction: "desc" },
                  dataArr: filterDataArr,
                  maxRowNums: [10],
                  trHeight: 100,
                  remainArea: false,
                  paginateClass: "mt40 mb40",
                }}
              />
              <div className="posR w100p h121 bd1 bdcCECECE bdBox dpFlx jcSb p10 mb20">
                <textarea
                  className="w100p h100p rszN olN bd0 bdBox SpoqaHanSansNeo-Regular fs14 fc2B2B2B ls07"
                  value={replyCtt}
                  placeholder="댓글을 입력하세요."
                  onChange={(e) => setReplyCtt(e.target.value)}
                  maxLength={600}
                />
                <div className="posA b0 r0 dpFlx aiC mb15 mr15">
                  <span className="SpoqaHanSansNeo-Regular fs12 lh18 ls06 mr10">
                    <span className="fc222222 mr3">
                      {Util.getLength(replyCtt)}
                    </span>
                    <span className="fc666666">/ 600</span>
                  </span>
                  <div
                    className="dpFlx aiC jcC bdBox h28 bd1 bdc17A840 bgcWhite bdrd28 pl12 pr12 csP"
                    onClick={() => {
                      if (Util.isEmpty(userInfo.userSn)) {
                        AppContext.openAlert({
                          title: "로그인 필요",
                          msg: "로그인 후 댓글 작성이 가능합니다.",
                          icon: "check",
                        });
                        return;
                      }
                      if (Util.isEmpty(replyCtt)) {
                        AppContext.openAlert({
                          title: "댓글 미입력",
                          msg: "댓글 내용을 작성하세요.",
                          icon: "check",
                        });
                        return;
                      }
                      AppContext.openAlert({
                        title: "댓글 작성",
                        msg: "댓글을 작성하시겠습니까?",
                        icon: "check",
                        confirmText: "댓글작성",
                        handleConfirm: () => {
                          Axios.dataAccess({
                            url: "system/board/insBoardReplyInfo.do",
                            methodType: "post",
                            paramType: "json",
                            paramData: {
                              boardSn: Number(params.boardSn),
                              replyCtt: replyCtt,
                            },
                            onSuccessFn: () => {
                              setReplyCtt("");
                              setBoardSelTime(new Date());
                            },
                          });
                        },
                      });
                    }}
                  >
                    <span className="fs12 fc17A840 SpoqaHanSansNeo-Medium fw500 ls06 lh18">
                      등록
                    </span>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <></>
          )}
        </div>
        <div className="dpFlx jcSb aiC w100p h56 mb100 mt40">
          <div
            className="w111 h100p bgcWhite bd1 bdcDDDDDD dpFlx aiC jcC csP bdBox"
            onClick={() => goBack()}
          >
            <span className="SpoqaHanSansNeo-Medium fs16 fc2B2B2B">
              목록으로
            </span>
          </div>
          {["/admin/notice", "/admin/faq"].indexOf(
            location.pathname.substring(0, location.pathname.indexOf("/view"))
          ) !== -1 ||
          (["/system/expert", "/system/qna"].indexOf(
            location.pathname.substring(0, location.pathname.indexOf("/view"))
          ) !== -1 &&
            regUserSn === userInfo.userSn) ? (
            <div
              className="w210 h56 bgc17A840 dpFlx aiC jcC csP"
              onClick={() => goModify()}
            >
              <span className="SpoqaHanSansNeo-Medium fs16 ls07 fcWhite">
                수정
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
export default BoardView;
