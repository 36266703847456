/*******************************************************************************
*** RmstnfamDialog ** 세대원 추가/수정
*******************************************************************************/
import React              from 'react';
import { useRecoilValue } from "recoil";
import * as Atom          from "js/common/Atom";
import Axios              from 'js/common/Axios';
import Util               from 'js/common/Util';
import AppContext         from 'js/common/AppContext';
import ToolTip            from 'js/common/ui/ToolTip';
import ImageRadioBox      from 'js/common/ui/input/ImageRadioBox';
import TextBox            from 'js/common/ui/input/TextBox';
import DatePickerBox      from 'js/common/ui/input/DatePickerBox';
import SelectBox          from 'js/common/ui/input/SelectBox';

interface RmstnfamDialogProps {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setRmstnfamSelTime: React.Dispatch<React.SetStateAction<Date>>
    callMode: string
    rmstnfamSn: number
}
function RmstnfamDialog({ setModalOpen, setRmstnfamSelTime, callMode, rmstnfamSn }: RmstnfamDialogProps) {
    const hshldSn = useRecoilValue(Atom.hshldSn);

    const [rmstnfamNm  , setRmstnfamNm  ] = React.useState<string>(""); // 세대원명
    const [rmstnfamSe  , setRmstnfamSe  ] = React.useState<string>(""); // 가족관계
    const [sexdstn     , setSexdstn     ] = React.useState<string>(""); // 성별
    const [brthdy      , setBrthdy      ] = React.useState<string>(""); // 생일
    const [incomeAt    , setIncomeAt    ] = React.useState<string>(""); // 소득충족여부
    const [adresAdres  , setAdresAdres  ] = React.useState<string>(""); // 주소동일여부
    const [lvlhdSamenss, setLvlhdSamenss] = React.useState<string>(""); // 생계동일여부
    const [mrrgAt      , setMrrgAt      ] = React.useState<string>(""); // 결혼여부
    const [mrrgDe      , setMrrgDe      ] = React.useState<string>(""); // 결혼일자
    const [mrrgAtDsb   , setMrrgAtDsb   ] = React.useState<boolean>(false); // 결혼여부 disabled
    const [mrrgDeDsb   , setMrrgDeDsb   ] = React.useState<boolean>(true ); // 결혼일자 disabled
    const [chbtSptAt   , setChbtSptAt   ] = React.useState<string>("");     // 동거봉양합가 여부
    const [chbtSptVsb  , setChbtSptVsb  ] = React.useState<boolean>(false); // 동거봉양합가여부 disabled
    const [chbtSptDe   , setChbtSptDe   ] = React.useState<string>("");     // 동거봉양합가 일자
    const [chbtSptDeDsb, setChbtSptDeDsb] = React.useState<boolean>(true ); // 동거봉양합가 일자 disabled
    const [dsbOptVals  , setDsbOptVals  ] = React.useState<string[]>([]);

    const onSubmit = () => {
        const paramData = {
            hshldSn: hshldSn,
            rmstnfamSn: -1,
            rmstnfamNm: "",
            rmstnfamSe: "",
            sexdstn: sexdstn,
            brthdy: "",
            incomeAt: incomeAt,
            adresAdres: adresAdres,
            lvlhdSamenss: lvlhdSamenss,
            mrrgAt: mrrgAt,
            mrrgDe: "",
            chbtSptAt: chbtSptAt,
            chbtSptDe: ""
        };
        if (Util.isEmpty(rmstnfamNm)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "세대원의 이름을 입력해주세요.",
                icon: "warning"
            });
            return;
        }
        paramData.rmstnfamNm = rmstnfamNm;

        if (Util.isEmpty(brthdy)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "세대원의 생년월일을 입력해주세요.",
                icon: "warning"
            });
            return;
        }
        paramData.brthdy = brthdy.replace(/-/g, "");
        if (Util.isEmpty(rmstnfamSe)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "세대원의 가족관계를 선택해주세요.",
                icon: "warning"
            });
            return;
        }
        paramData.rmstnfamSe = rmstnfamSe;

        if (Util.isEmpty(sexdstn)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "세대원의 성별을 선택해주세요.",
                icon: "check"
            });
            return;
        }
        if (Util.isEmpty(incomeAt)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "세대원의 소득충족여부를 선택해주세요.",
                icon: "check"
            });
            return;
        }
        if (Util.isEmpty(lvlhdSamenss)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "세대원의 생계동일여부를 선택해주세요.",
                icon: "check"
            });
            return;
        }
        if (Util.isEmpty(adresAdres)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "세대원의 주소동일여부를 선택해주세요.",
                icon: "check"
            });
            return;
        }
        if (Util.isEmpty(mrrgAt)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "세대원의 결혼여부를 선택해주세요.",
                icon: "check"
            });
            return;
        }
        
        if (Util.isEmpty(chbtSptAt)) { // 동거봉양여부 체크
            AppContext.openAlert({
                title: "필수입력",
                msg: "세대원의 동거봉양합가여부를 선택해주세요.",
                icon: "check"
            });
            return;
        }

        if (mrrgAt === "Y") {
            if (Util.isEmpty(mrrgDe)) {
                AppContext.openAlert({
                    title: "필수입력",
                    msg: "혼일일을 입력해주세요.",
                    icon: "warning"
                });
                return;
            }
            paramData.mrrgDe = mrrgDe.replace(/-/g, "");
        }

        if (chbtSptAt === "Y") {
            if (Util.isEmpty(chbtSptDe)) {
                AppContext.openAlert({
                    title: "필수입력",
                    msg: "동거봉양합가일을 입력해주세요.",
                    icon: "warning"
                });
                return;
            }
            paramData.chbtSptDe = chbtSptDe.replace(/-/g, "");
        }

        let modNm = "";
        let actUrl = "";
        if (callMode === "reg") {
            modNm = "추가";
            actUrl = "insRmstnfamInfo.do";
        } else if (callMode === "mod") {
            paramData.rmstnfamSn = rmstnfamSn;
            modNm = "수정";
            actUrl = "updRmstnfamInfo.do";
        } else {
            AppContext.openAlert({
                title: "시스템 오류",
                msg: "호출 구분 값이 없습니다.",
                icon: "warning"
            });
            return;
        }
        AppContext.openAlert({
            title: "세대원 " + modNm,
            msg: "세대원을 " + modNm + "하시겠습니까?",
            icon: "check",
            confirmText: modNm,
            handleConfirm: () => {
                Axios.dataAccess({
                    url: "system/rmstnfam/" + actUrl,
                    methodType: "post",
                    paramType: "json",
                    paramData: paramData,
                    onSuccessFn: () => {
                        setModalOpen(false);
                        setRmstnfamSelTime(new Date());
                    }
                });
            }
        });
    };

    // 세대원 초기값 셋팅
    React.useEffect(() => {
        const dsbOptVals: string[] = [];
        dsbOptVals.push("1000"); // 본인은 수동등록 불가
        if (callMode === "reg") {
            setRmstnfamNm("");
            setRmstnfamSe("");
            setSexdstn("1001");
            setBrthdy("");
            setIncomeAt("N");
            setAdresAdres("N");
            setLvlhdSamenss("N");
            setMrrgAt("N");
            setMrrgDe("");
            setChbtSptVsb(true);
            setChbtSptAt("N");
            setChbtSptDe("");
            setDsbOptVals(dsbOptVals);
        } else if (callMode === "mod") {
            Axios.dataAccess({
                url: "system/rmstnfam/selRmstnfamInfo.do",
                methodType: "post",
                paramType: "object",
                paramData: {
                    rmstnfamSn: rmstnfamSn
                },
                onSuccessFn: (res: any) => {
                    const tempDsbOptVals: string[] = [];
                    for (const dsbOptVal of dsbOptVals) {
                        if (dsbOptVal !== res.item.rmstnfamSe) { // 현재 세대원의 구분은 비활성화 배열에서 배제처리
                            tempDsbOptVals.push(dsbOptVal);
                        }
                    }
                    setDsbOptVals(tempDsbOptVals);
                    setRmstnfamSe(res.item.rmstnfamSe);
                    setChbtSptVsb(true);
                    setChbtSptDeDsb(res.item.chbtSptAt === "N");

                    let mrrgAt = res.item.mrrgAt;
                    if (res.item.rmstnfamSe === "1001") { // 세대원이 배우자인 경우
                        mrrgAt = "Y";
                        setMrrgAtDsb(true);
                        setMrrgDeDsb(true);
                    } else {
                        setMrrgAtDsb(false);
                        setMrrgDeDsb(res.item.mrrgAt === "N");
                    }
                    setRmstnfamNm(Util.nvl(res.item.rmstnfamNm, ""));
                    setRmstnfamSe(res.item.rmstnfamSe);
                    setSexdstn(res.item.sexdstn);
                    setBrthdy(res.item.brthdy);
                    setIncomeAt(res.item.incomeAt);
                    setAdresAdres(res.item.adresAdres);
                    setLvlhdSamenss(res.item.lvlhdSamenss);
                    setMrrgAt(mrrgAt);
                    setMrrgDe(res.item.mrrgDe);
                    setChbtSptAt(res.item.chbtSptAt);
                    setChbtSptDe(res.item.chbtSptDe);
                }
            });
        } else {
            AppContext.openAlert({
                title: "시스템 오류",
                msg: "호출 구분 값이 없습니다.",
                icon: "warning",
                handleClose: () => {
                    setModalOpen(false);
                }
            });
            return;
        }
    }, [callMode, rmstnfamSn, setModalOpen, hshldSn]);

    React.useEffect(() => {
        setMrrgDeDsb(mrrgAt === "N");
        if (rmstnfamSe === "1000") {    // 납세자 본인
            if (mrrgAt === "N") {
                Axios.dataAccess({
                    url: "system/rmstnfam/cntExistSpouseRmstnfam.do",
                    methodType: "post",
                    paramType: "object",
                    paramData: {
                        hshldSn: hshldSn
                    },
                    onSuccessFn: (res: any) => {
                        if (res.item === 1) {   // 배우자가 있을 경우
                            setMrrgAtDsb(true);
                            if (mrrgAt === "N") {
                                AppContext.openAlert({
                                    title: "알림",
                                    msg: "추가된 배우자 세대원 정보가 존재하여 결혼여부 변경이 불가합니다.",
                                    icon: "warning"
                                });

                                // 납세자의 결혼여부 업데이트(Y)
                                Axios.dataAccess({
                                    url: "system/rmstnfam/updRmstnfamMrrgAt.do",
                                    methodType: "post",
                                    paramType: "json",
                                    paramData: {
                                          hshldSn   : hshldSn
                                        , rmstnfamSn: rmstnfamSn
                                    },
                                    onSuccessFn: (res: any) => {
                                        if (res.item === 1) {
                                            setMrrgAt("Y");
                                            setRmstnfamSelTime(new Date());
                                        }
                                    }
                                });
                            }
                        }
                    }
                });
            }    
        }
    }, [mrrgAt, hshldSn, rmstnfamSe, rmstnfamSn, setRmstnfamSelTime]);

    return (
        <React.Fragment>
            <div className='w860 h100p bdBox dpFlx aiC'>
                <div className='w50p'>
                    <div className='dpFlx h40 pb10'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>세대원명</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={rmstnfamNm}
                                width={300}
                                height={40}
                                fontSize={15}
                                maxLength={10}
                                placeholder="세대원 이름을 입력해 주세요."
                                onChangeFunc={(text: string) => setRmstnfamNm(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                            />
                        </div>
                    </div>
                    <div className='h40 dpFlx pb10'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>생년월일</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <DatePickerBox value={brthdy} width={300} height={40} fontSize={15} textAlign="left" onChange={(data: string) => {
                                setBrthdy(Util.dateToString(data, "")) }}
                            />
                        </div>
                    </div>
                    <div className='dpFlx h40 pb10'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>성별</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <ImageRadioBox
                                codeList={Util.getCodeArray("1002")}
                                value={sexdstn}
                                setValue={setSexdstn}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                            />
                        </div>
                    </div>
                    <div className='dpFlx h40 pb10'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>생계 동일 여부</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <ImageRadioBox
                                codeList={Util.getCodeArray("1036")}
                                value={lvlhdSamenss}
                                setValue={setLvlhdSamenss}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                                disabled={rmstnfamSe === "1000"}
                            />
                        </div>
                    </div>
                    <div className='dpFlx h40 pb10'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>결혼 여부</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <ImageRadioBox
                                codeList={Util.getCodeArray("1036")}
                                value={mrrgAt}
                                setValue={setMrrgAt}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                                disabled={mrrgAtDsb}
                            />
                        </div>
                    </div>
                    <div className={'h40 ' + (chbtSptVsb ? "dpFlx" : "dpNone")}>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>동거봉양 합가 여부</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <ImageRadioBox
                                codeList={Util.getCodeArray("1036")}
                                value={chbtSptAt}
                                setValue={setChbtSptAt}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                                onChange={(data: string) => setChbtSptDeDsb(data === "N")}
                            />
                        </div>
                    </div>
                </div>

                {/* 오른쪽 */}
                <div className='w50p pl30'>
                    <div className='dpFlx h40 pb10'></div>
                    <div className='dpFlx h40 pb10'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>가족관계</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <SelectBox
                                value={rmstnfamSe}
                                codeArray={Util.getCodeArray("1001")}
                                placeholder="가족관계가 어떻게되나요?"
                                width={300}
                                height={40}
                                fontSize={15}
                                paddingRight={5}
                                onChangeFunc={(data: string) => {
                                    setRmstnfamSe(data);
                                    if (data === "1001") {
                                        setMrrgAtDsb(true);
                                        Axios.dataAccess({
                                            url: "system/rmstnfam/selHshldRmstnfamInfo.do",
                                            methodType: "post",
                                            paramType: "object",
                                            paramData: {
                                                hshldSn: hshldSn
                                            },
                                            onSuccessFn: (res: any) => {
                                                setMrrgAt("Y");
                                                setMrrgDe(res.item.mrrgDe);
                                            }
                                        });
                                    } else {
                                        setMrrgAt("N");
                                        setMrrgDe("");
                                        setMrrgAtDsb(false);
                                    }
                                }}
                                border="1px solid #dddddd"
                                disabled={rmstnfamSe === "1000"}
                                dsbOptVals={dsbOptVals}
                            />
                        </div>
                    </div>
                    <div className='dpFlx h40 pb10'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>소득 충족 여부</span>
                            <img
                                src={require('img/common/icon_info_fill_20.png')}
                                data-tooltip-id="rmstnfamDialogIncomeAt"
                                data-tooltip-content={"2024년 기준으로 직전 12개월(일시적으로 휴직, 휴업을 하는 경우 전 24개월) 동안\n 발생한 소득(사업, 근로, 기타소득 경상적, 반복적소득을 의미함)으로 월 891,378원 이상을 의미합니다."}
                                alt="icon_info_fill_20"
                                className='pl3 csP'
                            />
                            <ToolTip id="rmstnfamDialogIncomeAt" place='top' clickable={true} className='w400 lh20' />
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <ImageRadioBox
                                codeList={Util.getCodeArray("1036")}
                                value={incomeAt}
                                setValue={setIncomeAt}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                            />
                        </div>
                    </div>
                    <div className='dpFlx h40 pb10'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>주소 동일 여부</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <ImageRadioBox
                                codeList={Util.getCodeArray("1036")}
                                value={adresAdres}
                                setValue={setAdresAdres}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                                disabled={rmstnfamSe === "1000"}
                            />
                        </div>
                    </div>
                    <div className='h40 dpFlx pb10'>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>혼인일</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <DatePickerBox value={mrrgDe} width={300} height={40} fontSize={15} textAlign="left" disabled={mrrgDeDsb} onChange={(data: string) => {
                                setMrrgDe(Util.dateToString(data, "")) }}
                            />
                        </div>
                    </div>
                    <div className={'h40 ' + (chbtSptVsb ? "dpFlx" : "dpNone")}>
                        <div className='w120 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>동거봉양 합가일</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <DatePickerBox value={chbtSptDe} width={300} height={40} fontSize={15} textAlign="left" disabled={chbtSptDeDsb} onChange={(data: string) => {
                                setChbtSptDe(Util.dateToString(data, "")) }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='w100p dpFlx aiC jcC pt40'>
                <button className='w210 h56 bgcF6F6F6 dpFlx aiC jcC csP bdN' onClick={() => setModalOpen(false)}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>취소</span>
                </button>
                <button className='w210 h56 bgc17A840 dpFlx aiC jcC csP bdN' onClick={onSubmit}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>저장</span>
                </button>
            </div>
        </React.Fragment>
    )
}
export default RmstnfamDialog;