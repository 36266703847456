import AppContext from "./AppContext";

const Util = {
    /**
     * @description Null & undefined 확인
     * @param {*} str 확인할 값
     * @returns {boolean}
     */
    isNull: function (str: any): boolean {
        return str == null || str === undefined;
    },
    /**
     * @description Null & undefined & 공백 확인
     * @param {*} 확인할 값
     * @returns {boolean}
     */
    isEmpty: function (str: any): boolean {
        var returnCheck = this.isNull(str);
        if (!returnCheck) {
            if (String(str).trim().length === 0) returnCheck = true;
        }
        return returnCheck;
    },
    isNotEmpty: function (str: any) {
        return !this.isEmpty(str);
    },
    getLength: (str: string) => {
        return str.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
    },
    nvl: function(str: any, changeStr: any) {
        if (this.isEmpty(str)) {
            str = changeStr;
        }
        return str;
    },
    getDayNm: function(dayFlag: any) {
        if (this.isEmpty(dayFlag)) {
            alert("날짜 flag 정보가 없습니다.");
            return;
        }
        if (dayFlag < 1 || dayFlag > 7) {
            alert("유효한 날짜 flag 정보가 아닙니다.[1(월) ~ 7(금) 사이로 바인딩 가능]");
            return;
        }
        if (dayFlag === 1) {
            return "월";
        } else if (dayFlag === 2) {
            return "화";
        } else if (dayFlag === 3) {
            return "수";
        } else if (dayFlag === 4) {
            return "목";
        } else if (dayFlag === 5) {
            return "금";
        } else if (dayFlag === 6) {
            return "토";
        } else if (dayFlag === 7) {
            return "일";
        }
    },
    /**
     * @description 숫자를 문자로 변환
     * @param {number} val 변경할 숫자
     * @returns {string}
     */
    convertNumToString: (val: any): string => {
        var returnVal = '';
        if(typeof val === 'string') {
            returnVal = val;
        } else if(typeof val === 'number' && val.toString() !== 'NaN' && val !== Infinity && val !== -Infinity) {
            returnVal = val.toString();
        }
        return returnVal;
    },
    comma: (str: string) => {
        if (Util.isEmpty(str)) {
            return "";
        } else {
            return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },
    /**
     * @description 콤마 지우기
     * @param {string} str 문자열
     * @returns {string}
     */
    uncomma: function(str: string): string {
        if (this.isNull(str)) return '';
        str = String(str);
        return str.replace(/,/g, '');
    },
    /**
     * @description 왼쪽 채우기
     * @param {string} str 문자열
     * @param {number} nLength 자리수
     * @param {string} padStr 채울 문자
     * @returns {string}
     */
    lpad: function(str: string, nLength: number, padStr: string): string {
        if (this.isNull(str)) {
            return '';
        }
        str = this.convertNumToString(str);
        while(str.length < nLength) {
            str = padStr + str;
        }
        return str;
    },
    /**
     * 특정날짜 가져오기
     * @param {string} str 구분자
     * @param {string} calFg 계산 대상 년, 월, 일 구분
     * @param {string} calVal 계산할 수치 ( + / - )
     * @param {string} format 출력 형식(YYYY(YYYY), MM(YYYY-MM), DD(YYYY-MM-DD), HH(YYYY-MM-DD HH), MI(YYYY-MM-DD HH24:MM), SS(YYYY-MM-DD HH24:MM:SS))
     * @param {string} baseYmd(YYYY-MM-DD) 계산대상 일자, 값 없을 시 디폴트로 현재일자 세팅
     */
    getSpecificDate: function(str: string, calFg: "year" | "month" | "day" | "hour" | "min" | "sec", calVal: number, format: string, baseYmd: string) {
        let now = new Date();

        if (this.isEmpty(baseYmd)) {
            now = new Date();
        } else {
            now = new Date(baseYmd);
        }

        let setDt = new Date();

        calVal = calVal === undefined ? 0 : calVal; // 값 없을 시 계산안함

        if (calVal !== undefined) {
            // 구분에 따른 시간 계산 : 년, 월, 일, 시간, 분, 초
            if (calFg === "year") {
                setDt = new Date(now.setFullYear(now.getFullYear() + calVal));
            } else if (calFg === "month") {
                setDt = new Date(now.setMonth(now.getMonth() + calVal));
            } else if (calFg === "day") {
                setDt = new Date(now.setDate(now.getDate() + calVal));
            } else if (calFg === "hour") {
                setDt = new Date(now.setHours(now.getHours() + calVal));
            } else if (calFg === "min") {
                setDt = new Date(now.setMinutes(now.getMinutes() + calVal));
            } else if (calFg === "sec") {
                setDt = new Date(now.setSeconds(now.getSeconds() + calVal));
            } else {
                alert('계산할 구분 대상이 없습니다.');
                return;
            }
        } else {
            alert('계산할 변수 수치가 없습니다.');
            return;
        }

        var year = setDt.getFullYear();
        var month = this.lpad((setDt.getMonth() + 1).toString(), 2, "0");
        var date = this.lpad(setDt.getDate().toString(), 2, "0");
        var hour = this.lpad(setDt.getHours().toString(), 2, "0");
        var min  = this.lpad(setDt.getMinutes().toString(), 2, "0");
        var sec  = this.lpad(setDt.getSeconds().toString(), 2, "0");

        str = (str == null ? '' : str);

        let nowStr: string = year + str + month + str + date;

        if (format === "YYYY") {
            nowStr = year.toString();
        } else if (format === "MM") {
            nowStr = year + str + month;
        } else if (format === "DD") {
            nowStr = year + str + month + str + date;
        } else if (format === "YYMMDD") {
            nowStr = year + str + month + str + date;
            nowStr = nowStr.substring(2, 8);
        } else if (format === "HH") {
            nowStr = year + str + month + str + date + " " + hour;
        } else if (format === "MI") {
            nowStr = year + str + month + str + date + " " + hour + ":" + min;
        } else if (format === "SS") {
            if (this.isEmpty(str)) {
                nowStr = year + month + date + hour + min + sec;
            } else {
                nowStr = year + str + month + str + date + "T" + hour + ":" + min + ":" + sec;
            }
        } else {
            nowStr = year + str + month + str + date;
        }
        return nowStr.toString();
    },
    cvtSecToTime: (sec: number) => {
        // 분 : 1 * 60
        // 시간 : 분 * 60
        let retText = "";
        if (sec / (60 * 60) >= 1) {
            const hour = Math.floor(sec / (60 * 60));
            retText = Util.lpad(hour.toString(), 2, "0") + ":"
        }
        if (sec % (60 * 60) / 60 >= 1) {
            const min = Math.floor(sec % (60 * 60) / 60);
            retText = retText + Util.lpad(min.toString(), 2, "0") + ":"
        }
        retText = retText + Util.lpad(Math.floor(sec % (60 * 60) % 60).toString(), 2, "0");
        return retText;
    },
    getMonthName: (month: number) => {
        let retData: string = "";
        if (month === 1) retData = "JANUARY";
        else if (month === 2) retData = "FEBRUARY";
        else if (month === 3) retData = "MARCH";
        else if (month === 4) retData = "APRIL";
        else if (month === 5) retData = "MAY";
        else if (month === 6) retData = "JUNE";
        else if (month === 7) retData = "JULY";
        else if (month === 8) retData = "AUGUST";
        else if (month === 9) retData = "SEPTEMBER";
        else if (month === 10) retData = "OCTOBER";
        else if (month === 11) retData = "NOVEMBER";
        else if (month === 12) retData = "DECEMBER";
        return retData;
    },
    dateToString: function (source: any, format: string) {
        let date = new Date(source);
        if (!isFinite(Number(date))) {
            return "";
        }
        let month = "";
        let day = "";
        const year = date.getFullYear();
        if (date.getMonth() + 1 >= 10) {
            month = (date.getMonth() + 1).toString();
        } else {
            month = this.lpad((date.getMonth() + 1).toString(), 2, "0");
        }
        if (date.getDate() >= 10) {
            day = date.getDate().toString();
        } else {
            day = this.lpad(date.getDate().toString(), 2, "0");
        }
        if ((year + month + day).length !== 8) { // 날짜가 완전히 입력되지 않으면 빈 값 리턴
            return "";
        }
        if (format === "YYYY-MM-DD") {
            return [year, month, day].join('-');
        } else if (format === "YYYY.MM.DD") {
            return [year, month, day].join('.');
        } else {
            return year + month + day;
        }
    },
    cvtDateStrForm: (dateStr: string, separetor: string) => {
        if (Util.isEmpty(dateStr)) {
            return "";
        } else {
            return dateStr.substring(0, 4) + separetor + dateStr.substring(4, 6) + separetor + dateStr.substring(6, 8);
        }
    },
    compareStr: (data1: string | undefined, data2: string | undefined, mode: "min" | "max") => {
        if (data1 === undefined) {
            data1 = "";
        }
        if (data2 === undefined) {
            data2 = "";
        }
        if (Util.isEmpty(data1) && Util.isNotEmpty(data2)) {
            return data2;
        } else if (Util.isNotEmpty(data1) && Util.isEmpty(data2)) {
            return data1;
        } else if (Util.isEmpty(data1) && Util.isEmpty(data2)) {
            return "";
        } else if (Util.isNotEmpty(data1) && Util.isNotEmpty(data2)) {
            if (data1 > data2) {
                if (mode === "min") {
                    return data2;
                } else if (mode === "max") {
                    return data1;
                }
            } else if (data1 < data2) {
                if (mode === "min") {
                    return data1;
                } else if (mode === "max") {
                    return data2;
                }
            } else {
                return data1;
            }
        } else {
            return "";
        }
    },
    setPhoneNum: (inputValue?: string) => {
        if (Util.isEmpty(inputValue) || inputValue === undefined) {
            return "";
        }
        inputValue = inputValue.replace(/-/g, "");;
        if (isNaN(Number((inputValue)))) {
            return inputValue.slice(0, -1);
        }
        return inputValue.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    },
    cvtPhoneStrForm: (phoneStr: string, separetor: string) => {
        if (Util.isEmpty(phoneStr)) {
            return "";
        } else {
            return phoneStr.substring(0, 3) + separetor + phoneStr.substring(3, 7) + separetor + phoneStr.substring(7, 11);
        }
    },
    getValToCode: function (codeTy: string, code: string) {
        let retVal = "";
        for (let codeInfo of AppContext.codeList) {
            if (codeInfo["codeTy"] === codeTy && codeInfo["code"] === code) {
                retVal = codeInfo["codeNm"];
                break;
            }
        }
        return retVal;
    },
    getCodeArray: function (codeTy: string) {
        let codeArr: any[] = [];
        AppContext.codeList.forEach((item: any) => {
            if (item.codeTy === codeTy) {
                codeArr.push(item);
            }
        });
        return codeArr;
    },
    filterData: (dataArr: any, trgtFlds: any[], query: string) => {
        let retDataArr: any[] = [];
        for (let item of dataArr) { // 전체 데이터 탐색
            for (let trgtFld of trgtFlds) { // 모든 키 탐색
                let trgtVal = "";
                if (trgtFld.type === "text") {
                    trgtVal = Util.convertNumToString(item[trgtFld.fldNm]);
                } else if (trgtFld.type === "phone") {
                    trgtVal = Util.cvtPhoneStrForm(item[trgtFld.fldNm], "-");
                } else if (trgtFld.type === "comma") {
                    trgtVal = Util.comma(item[trgtFld.fldNm]);
                } else if (trgtFld.type === "code" || trgtFld.type === "el") {
                    trgtVal = Util.getValToCode(trgtFld.codeTy, item[trgtFld.fldNm]);
                } else {
                    continue; // text, code가 아니면 skip
                }
                // 영문일 경우 소문자로 일관화
                trgtVal = trgtVal.toLowerCase();
                query = query.toLowerCase();

                if (trgtVal.includes(query)) {
                    retDataArr.push(item);
                    break; // 이미 포함된 행이므로 남은 필드는 계산 불필요
                }
            }
        }
        return retDataArr;
    },

    // 비밀번호 패턴 체크
    chkPasswdPtn: (inputString: string) => {
        // 8자이상 20자이하로 영문, 숫자, 특수문자를 1자이상 반드시 포함
        const chkReg = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&#.~_-])[A-Za-z\d@$!%*?&#.~_-]{8,20}$/;

        let chkPasswd = chkReg.test(inputString);

        return chkPasswd;
    },

    // 사업자등록번호 입력 변환
    setBizRegNum: (inputValue?: string) => {
        if (Util.isEmpty(inputValue) || inputValue === undefined) {
            return "";
        }
        inputValue = inputValue.replace(/-/g, "");;
        if (isNaN(Number((inputValue)))) {
            return inputValue.slice(0, -1);
        }
        return inputValue.replace(/-/g, '').replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
    },

    // 사업자등록번호 표시 처리
    cvtBizRegNumForm: (BizNumStr: string, separetor: string) => {
        if (Util.isEmpty(BizNumStr)) {
            return "";
        } else {
            return BizNumStr.substring(0, 3) + separetor + BizNumStr.substring(3, 5) + separetor + BizNumStr.substring(5, 10);
        }
    },

    // 문자열에서 하이픈() 제거
    unHypen: (inputValue:string): string => {
        let sResult = "";
        sResult = inputValue.replace(/[-]/gi, "");
        return sResult;
    },

    // 이메일 패턴 체크
    chkEmailPtn: (inputValue: string): boolean => {
        // 영문,숫자 + 특수문자(-_.) + 영문, 숫자 + @ + 영문,숫자 + 특수문자(-_.) + . + 영문 2~3자
        const chkReg = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;

        let chkEmail = chkReg.test(inputValue);

        return chkEmail;
    },

    // 핸드폰 패턴 체크
    chkPhonePtn: (inputValue:string): boolean => {
        const chkReg =/^(010)[0-9]{3,4}[0-9]{4}$/;

        let chkPhone = chkReg.test(inputValue);

        return chkPhone;
    },

    // 문자에 공백 포함 유무 확인
    isBlank: (inputValue:string): boolean => {
        const blankPattern = /[\s]/g;   // 공백 확인
        let isBlank = blankPattern.test(inputValue);
        return isBlank;
    },

    // 문자의 왼쪽 공백 삭제
    lTrim: (inputValue:string): string => {
        let newVal = inputValue.replace(/^\s*/, "");
        return newVal;
    },

    // 문자의 오른쪽 공백 제거
    rTrim: (inputValue:string): string => {
        let newVal = inputValue.replace(/\s*$/, "");
        return newVal;
    },

    // 모든 공백 제거
    aTrim: (inputValue:string): string => {
        let newVal = inputValue.replace(/(\s*)/g, "");
        return newVal;
    }
};
export default Util;