import styled from "styled-components";

type Props = {
  children: React.ReactNode;
};

const Body = styled.div`
  font-size: ${({ theme }) => theme.fontSize}; /* 1rem = 10px */

  position: relative;
  width: 100dvw;
  height: 100dvh;
  background: ${(props) => props.theme.color.pGreenLight04};

  @media (min-width: 1201px) {
    display: flex;
    flex-direction: column;
    justif-content: center;
    align-items: center;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
`;

function MobileBody({ children }: Props) {
  return <Body>{children}</Body>;
}

export default MobileBody;
