/*******************************************************************************
*** ManualCheckGuide ** 취득당시 기준시가 수동조회 안내 Modal
*******************************************************************************/
import React from "react";

const ManualCheckGuide = ({setModalOpen}: any) => {
    return (
        <React.Fragment>
            <div className="w100p">
                <table className="forest-list-table w100p th-fm-SpoqaHanSansNeo-Medium th-fw500 th-fs14 th-fc222222 th-bdcDDDDDD bdt1 bdcDDDDDD td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-p15 mt10 mb10">
                    <colgroup>
                        <col className="w200"></col>
                        <col className="w200"></col>
                        <col className="w300"></col>
                    </colgroup>
                    <tbody>
                        <tr className="h50">
                            <th>주택종류</th>
                            <th>조회할 고시가액</th>
                            <th>기준시가 조회화면</th>
                        </tr>
                        <tr className="h40">
                            <td rowSpan={2}>아파트, 연립주택, 다세대</td>
                            <td>06.04.27. 이전</td>
                            <td>홈택스 [아파트/연립주택(다세대포함)]</td>
                        </tr>
                        <tr className="h50">
                            <td>06.04.28. 이후</td>
                            <td>부동산알리미</td>
                        </tr>
                        <tr className="h40">
                            <td>다가구, 다중, 단독</td>
                            <td>05.04.30. 이후</td>
                            <td>부동산알리미</td>
                        </tr>
                        <tr className="h40">
                            <td>오피스텔</td>
                            <td>06.01.01. 이후</td>
                            <td>홈택스 [오피스텔]</td>
                        </tr>
                        <tr className="h40">
                            <td className="bdb1">고시이력 없는 주택</td>
                            <td className="bdb1"></td>
                            <td className="bdb1">홈택스 [건물기준시가(양도)]</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='w100p bdBox aiC'>
                <div className='dpFlx pb10 pt20'>
                    <div className='w50p dpFlx flxdCol aiC csP'
                        onClick={() => {
                            window.open("https://www.hometax.go.kr/websquare/websquare.wq?w2xPath=/ui/pp/index_pp.xml&tmIdx=47&tm2lIdx=4712090000&tm3lIdx=4712090200", "_blank", "noopener");
                        }}
                    >
                        <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 pb10'>
                            [홈택스 / 상담ㆍ불복ㆍ고충 제보ㆍ기타/ 기준시가 조회]
                        </span>
                        <img className="mr10 w472" src={require('img/system/assets/hometax_price.png')} alt="icon_step" />
                    </div>
                    <div className='w50p dpFlx flxdCol pl30 aiC csP'
                        //  className="h40 bgcWhite bd1 bdc17A840 bdrd5 dpFlx jcC aiC csP pw10 ml10"
                        onClick={() => {
                            window.open("https://www.realtyprice.kr/notice/main/mainBody.htm", "_blank", "noopener");
                        }}
                    >
                        <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 pb10'>[부동산알리미]</span>
                        <img className="mr10 w420" src={require('img/system/assets/reality_price.png')} alt="icon_step" />
                    </div>
                </div>
            </div>
            <div className="dpFlx aiC jcC pt10">
                <div className="w210 h56 bgc17A840 dpFlx aiC jcC csP bdBox" onClick={() => { setModalOpen(false) }}>
                    <span className="SpoqaHanSansNeo-Medium fs14 fcWhite">닫기</span>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ManualCheckGuide;