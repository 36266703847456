/***********************************************************************************
*** Law34at10 ** 소득세법 시행령 제155조 제2항 상속주택 Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law34at10Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}

export default function Law34at10({ setModalOpen, setLawModalTitle, callBack, callMode }: Law34at10Props) {
    React.useEffect(() => {
        setLawModalTitle("소득세법시행령 제155조 제2항 상속주택");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className="m0 mt15">
                <span className="fs15 lh25 ls06 SpoqaHanSansNeo-Regular">
                    상속받은 주택과(피상속인이 상속개시 당시 2 이상의 주택을 소유한 경우에는 법정순위에 따른 1주택을 말한다)과 그 밖의 주택(상속받는 시점에서 상속인의 1세대 1주택을 말한다)을<br/>
                    국내에 각각 1개씩 소유하고 있는 1세대가 일반주택을 양도하는 경우에는 국내에 1개의 주택을 소유하고 있는 것으로 비과세 규정을 적용한다.<br/><br/>
                </span>
                <span className="fs15 lh25 ls06 SpoqaHanSansNeo-Regular">상속주택에는<br/></span>
                <span className="lh20 fs14">
                    1. 조합원입주권을 상속받아 사업시행 완료 후 취득한 신축주택도 포함한다(2008.2.22. 이후 양도분부터 적용).<br/>
                    2. 분양권(피상속인이 2021.1.1. 이후 취득한 분양권에 한함)을 상속받아 사업시행 완료 후 취득한 신축주택도 포함한다(2021.1.1. 이후 양도분부터 적용).<br/>
                    3. 상속인과 피상속인이 상속개시 당시 1세대인 경우에는 1주택을 보유하고 1세대를 구성하는 자가 직계존속<br/>
                    (배우자의 직계존속을 포함하며, 세대를 합친 날 현재 직계존속 중 어느 한 사람 또는 모두가 60세 이상으로서 1주택을 보유하고 있는 경우만 해당한다)<br/>
                    을 동거봉양하기 위해 세대를 합침에 따라 2주택을 보유하게 되는 경우로서 합치기 이전부터 보유하고 있었던 주택을 상속받은 경우에만 상속주택으로 본다.<br/><br/>
                </span>
                <span className="fs15 lh25 ls06 SpoqaHanSansNeo-Regular">
                    본 프로그램에서 다루지 못하고 있어 주의를 요하는 상속주택 관련 일반주택의 내용<br/>
                </span>
                <span className="lh20 fs14">
                    1. 2013.2.14. 이전 취득한 일반주택의 경우<br/>
                    - 2013.2.14. 이전 상속주택을 보유한 상태에서 취득한 2013.2.14. 이전 취득한 일반주택은 비과세규정을 적용한다.<br/>
                    2. 2018.2.13. 이후 증여받는 분부터 상속개시일부터 소급하여 2년 이내에 피상속인으로부터 증여받은 주택 또는 증여받은 조합원입주권을 상속받아 사업시행 완료 후<br/>
                    취득한 신축주택은 비과세되는 일반주택에서 제외한다.<br/>
                    또한 2021.1.1. 이후 양도하는 분부터 상속개시일부터 소급하여 2년 이내에 피상속인으로부터 증여받은 분양권(피상속인이 2021.1.1. 이후 취득한 분양권에 한함)에<br/>
                    의하여 사업시행 완료 후 취득한 신축주택도 비과세되는 일반주택에서 제외한다.<br/><br/>
                </span>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}
