import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Housing, PropTaxCalcRsltParam, RtnProp } from "js/common/types/types";
import Util from "js/common/Util";
import PropInfoSwiper from "./PropInfoSwiper";

type Props = {
  calcResult: PropTaxCalcRsltParam | null;
  housings: Housing[] | null;
};

function CalcResult({ calcResult, housings }: Props) {
  const [detailPropResultOpen, setDetailPropResultOpen] =
    useState<boolean>(true);
  const [detailCompResultOpen, setDetailCompResultOpen] =
    useState<boolean>(true);

  const propInfoRef = useRef<HTMLDivElement>(null);
  const compInfoRef = useRef<HTMLDivElement>(null);
  const compContentRef = useRef<HTMLDivElement>(null);
  const [isPropInfoIntersecting, setIsPropInfoIntersecting] =
    useState<boolean>(false);
  const [isCompContentIntersecting, setIsCompContentIntersecting] =
    useState<boolean>(false);
  //const [active, setActive] = useState<"propInfo" | "compInfo" | "">("");

  const [calcPropInfo, setCalcPropInfo] = useState<RtnProp[] | null>(); // 재산세 계산결과
  const [calcCompInfo, setCalcCompInfo] = useState<any | undefined>(); // 종합소득세 계산결과

  const [sumStdPrice, setSumStdPrice] = useState<string>();
  const [sumTaxBase, setSumTaxBase] = useState<string>();
  const [sumPropertyAmt, setSumPropertyAmt] = useState<string>();
  const [sumUrbanAmt, setSumUrbanAmt] = useState<string>();
  const [sumLocalEduAmt, setSumLocalEduAmt] = useState<string>();
  const [sumLocalResAmt, setSumLocalResAmt] = useState<string>();
  const [sumTotalAmt, setSumTotalAmt] = useState<string>();

  const onClickPropOpen = () => {
    if (detailPropResultOpen) {
      setDetailPropResultOpen(false);
    } else {
      setDetailPropResultOpen(true);
    }
  };

  const onClickCompOpen = () => {
    if (detailCompResultOpen) {
      setDetailCompResultOpen(false);
    } else {
      setDetailCompResultOpen(true);
    }
  };

  const scrollTo = (id: string) => () => {
    const frame = document.getElementById("layoutFrame");

    const propInfoY = propInfoRef.current?.offsetTop! - 30;
    const compInfoY = compInfoRef.current?.offsetTop! - 30;

    if (id === "propInfo") {
      frame?.scrollTo({
        top: propInfoY + 1,
        behavior: "smooth",
      });
    } else if (id === "compInfo") {
      frame?.scrollTo({
        top: compInfoY + 1,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    // intersectionObserver 사용하여 재산세 결과 div 감지
    if (!compContentRef.current || !propInfoRef.current) return;

    const propInfoDiv = propInfoRef.current;
    const compContentDiv = compContentRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.target === propInfoDiv) {
            setIsPropInfoIntersecting(entry.isIntersecting);
          }
          if (entry.target === compContentDiv) {
            if (entry.intersectionRatio > 0.5) {
              setIsCompContentIntersecting(true);
            } else {
              setIsCompContentIntersecting(false);
            }
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: Array.from({ length: 101 }, (_, i) => i / 100),
      }
    );

    observer.observe(propInfoDiv);
    observer.observe(compContentDiv);

    return () => {
      if (propInfoDiv) {
        observer.unobserve(propInfoDiv);
      }

      if (compContentDiv) {
        observer.unobserve(compContentDiv);
      }
    };
  }, []);

  useEffect(() => {
    // 재산세/종합소득세 계산 결과
    setCalcPropInfo(calcResult?.rtn_prop_list); // 재산세 계산 결과 List
    setCalcCompInfo(calcResult?.rtn_comp_tax[0]); // 종합부동산세 결과
  }, [calcResult]);

  // 세금 합계 계산
  useEffect(() => {
    let sumStdPrice = 0; // 공시가격
    let sumTaxBase = 0; // 과세표준
    let sumPropertyAmt = 0; // 재산세
    let sumUrbanAmt = 0; // 도시지역분
    let sumLocalEduAmt = 0; // 지방교육세
    let sumLocalResAmt = 0; // 지역자원시설세
    let sumTotalAmt = 0; // 합계

    if (calcPropInfo) {
      for (const proptaxList of calcPropInfo) {
        sumStdPrice = sumStdPrice + Number(proptaxList.std_price);
        sumTaxBase = sumTaxBase + Number(proptaxList.tax_base);
        sumPropertyAmt = sumPropertyAmt + Number(proptaxList.property_amt);
        sumUrbanAmt = sumUrbanAmt + Number(proptaxList.urban_amt);
        sumLocalEduAmt = sumLocalEduAmt + Number(proptaxList.local_edu_amt);
        sumLocalResAmt = sumLocalResAmt + Number(proptaxList.local_res_amt);
        sumTotalAmt =
          sumTotalAmt +
          Number(proptaxList.property_amt) +
          Number(proptaxList.urban_amt) +
          Number(proptaxList.local_edu_amt) +
          Number(proptaxList.local_res_amt);
      }
    }
    setSumStdPrice(Util.comma(sumStdPrice.toString()));
    setSumTaxBase(Util.comma(sumTaxBase.toString()));
    setSumPropertyAmt(Util.comma(sumPropertyAmt.toString()));
    setSumUrbanAmt(Util.comma(sumUrbanAmt.toString()));
    setSumLocalEduAmt(Util.comma(sumLocalEduAmt.toString()));
    setSumLocalResAmt(Util.comma(sumLocalResAmt.toString()));
    setSumTotalAmt(Util.comma(sumTotalAmt.toString()));
  }, [calcPropInfo]);

  return (
    <>
      <TaxContainer>
        <NavContainer>
          <div className="nav">
            <button
              className={
                "item " +
                ((isPropInfoIntersecting || !isCompContentIntersecting) &&
                  "active")
              }
              onClick={scrollTo("propInfo")}
            >
              재산세 정보
            </button>
            <button
              className={"item " + (isCompContentIntersecting && "active")}
              onClick={scrollTo("compInfo")}
            >
              종합부동산세 정보
            </button>
            <div className="line"></div>
          </div>
        </NavContainer>
        <DetailTaxContainer ref={propInfoRef}>
          <div className="title">
            <p>재산세 정보</p>
            <button
              className={`${detailPropResultOpen ? "open" : "closed"}`}
              onClick={onClickPropOpen}
            >
              <img
                src={require("img/common/icon_arrow_up_gray.png")}
                alt="logo"
              />
            </button>
          </div>
        </DetailTaxContainer>
        <TaxInfoContainer>
          <OpenAndCloseContainer $maxHeight={800}>
            <div
              className={`detail-container ${
                detailPropResultOpen ? "open" : "closed"
              }`}
            >
              <div className="detailTaxWrap">
                <p className="name">공시가격</p>
                <div className="value">
                  <div>
                    <span className="number">
                      {calcResult ? Util.comma(sumStdPrice ?? "") : ""}
                    </span>
                    <span className="unit">원</span>
                  </div>
                </div>
              </div>
              <div className="detailTaxWrap">
                <p className="name">과세표준</p>
                <div className="value">
                  <div>
                    <span className="number">
                      {calcResult ? Util.comma(sumTaxBase ?? "") : ""}
                    </span>
                    <span className="unit">원</span>
                  </div>
                </div>
              </div>
              <div className="detailTaxWrap">
                <p className="name">재산세</p>
                <div className="value">
                  <div>
                    <span className="number">
                      {calcResult ? Util.comma(sumPropertyAmt ?? "") : ""}
                    </span>
                    <span className="unit">원</span>
                  </div>
                </div>
              </div>
              <div className="detailTaxWrap">
                <p className="name">도시지역분</p>
                <div className="value">
                  <div>
                    <span className="number">
                      {calcResult ? Util.comma(sumUrbanAmt ?? "") : ""}
                    </span>
                    <span className="unit">원</span>
                  </div>
                </div>
              </div>
              <div className="detailTaxWrap">
                <p className="name">지방교육세</p>
                <div className="value">
                  <div>
                    <span className="number">
                      {calcResult ? Util.comma(sumLocalEduAmt ?? "") : ""}
                    </span>
                    <span className="unit">원</span>
                  </div>
                </div>
              </div>
              <div className="detailTaxWrap">
                <p className="name">지역자원시설세</p>
                <div className="value">
                  <div>
                    <span className="number">
                      {calcResult ? Util.comma(sumLocalResAmt ?? "") : ""}
                    </span>
                    <span className="unit">원</span>
                  </div>
                </div>
              </div>
              <div className="taxWrap" style={{ borderBottomColor: "#fff" }}>
                <div className="tax">
                  <p className="name">합계</p>
                  <div className="value">
                    <div>
                      <span className="number">
                        {calcResult ? Util.comma(sumTotalAmt ?? "") : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
              </div>
              <PropInfoSwiper
                rtnPropList={calcPropInfo ?? []}
                housings={housings}
              />
            </div>
          </OpenAndCloseContainer>
        </TaxInfoContainer>
        <DetailTaxContainer ref={compInfoRef}>
          <div className="title">
            <p>종합부동산세 정보</p>
            <button
              className={`${detailCompResultOpen ? "open" : "closed"}`}
              onClick={onClickCompOpen}
            >
              <img
                src={require("img/common/icon_arrow_up_gray.png")}
                alt="logo"
              />
            </button>
          </div>
        </DetailTaxContainer>
        <TaxInfoContainer id="aaa" ref={compContentRef}>
          <OpenAndCloseContainer $maxHeight={600}>
            {calcCompInfo && Util.isNull(calcCompInfo.house_info) ? (
              <EmptyContainer>
                <div className="text">종합부동산세 과세 대상이 아닙니다.</div>
              </EmptyContainer>
            ) : (
              <>
                <div
                  className={`detail-container ${
                    detailCompResultOpen ? "open" : "closed"
                  }`}
                >
                  <div className="detailTaxWrap">
                    <p className="name">주택정보</p>
                    <div className="value">
                      <div>
                        <span className="number">
                          {calcCompInfo ? calcCompInfo.house_info : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="detailTaxWrap">
                    <p className="name">과세표준</p>
                    <div className="value">
                      <div>
                        <span className="number">
                          {calcCompInfo
                            ? Util.comma(calcCompInfo.comp_tax_base)
                            : ""}
                        </span>
                        <span className="unit">원</span>
                      </div>
                    </div>
                  </div>
                  <div className="moreDetailTaxWrap">
                    <div className="title">
                      <img
                        src={require("img/common/icon_more.png")}
                        alt="logo"
                      />
                      <p className="name">주택공시사격 합계</p>
                    </div>
                    <div className="value">
                      <div>
                        <span className="number">
                          {calcCompInfo
                            ? Util.comma(calcCompInfo.comp_sum_std_price)
                            : ""}
                        </span>
                        <span className="unit">원</span>
                      </div>
                    </div>
                  </div>
                  <div className="moreDetailTaxWrap">
                    <div className="title">
                      <img
                        src={require("img/common/icon_more.png")}
                        alt="logo"
                      />
                      <p className="name">공제금액</p>
                    </div>
                    <div className="value">
                      <div>
                        <span className="number">
                          {calcCompInfo
                            ? Util.comma(calcCompInfo.deduct_amt)
                            : ""}
                        </span>
                        <span className="unit">원</span>
                      </div>
                    </div>
                  </div>
                  <div className="moreDetailTaxWrap">
                    <div className="title">
                      <img
                        src={require("img/common/icon_more.png")}
                        alt="logo"
                      />
                      <p className="name">공제금액 차감 후 금액</p>
                    </div>
                    <div className="value">
                      <div>
                        <span className="number">
                          {calcCompInfo
                            ? Util.comma(calcCompInfo.comp_tax_base_pre)
                            : ""}
                        </span>
                        <span className="unit">원</span>
                      </div>
                    </div>
                  </div>
                  <div className="moreDetailTaxWrap">
                    <div className="title">
                      <img
                        src={require("img/common/icon_more.png")}
                        alt="logo"
                      />
                      <p className="name">(*)공정시장가액 비율</p>
                    </div>
                    <div className="value">
                      <div>
                        <span className="number">
                          {calcCompInfo
                            ? Number(calcCompInfo.fair_market_ratio)
                            : ""}
                        </span>
                        <span className="unit">%</span>
                      </div>
                    </div>
                  </div>
                  <div className="detailTaxWrap">
                    <p className="name">(*)세율</p>
                    <div className="value">
                      <div>
                        <span className="number">
                          {calcCompInfo
                            ? Number(calcCompInfo.comp_basic_tax_rate)
                            : ""}
                        </span>
                        <span className="unit">%</span>
                      </div>
                    </div>
                  </div>
                  <div className="detailTaxWrap">
                    <p className="name">(-)누진공제액</p>
                    <div className="value">
                      <div>
                        <span className="number">
                          {calcCompInfo
                            ? Util.comma(calcCompInfo.comp_progre_deduct_amt)
                            : ""}
                        </span>
                        <span className="unit">원</span>
                      </div>
                    </div>
                  </div>
                  <div className="detailTaxWrap">
                    <p className="name">종합부동산세 A</p>
                    <div className="value">
                      <div>
                        <span className="number">
                          {calcCompInfo
                            ? Util.comma(calcCompInfo.comp_tax_A)
                            : ""}
                        </span>
                        <span className="unit">원</span>
                      </div>
                    </div>
                  </div>
                  <div className="moreDetailTaxWrap">
                    <div className="title">
                      <img
                        src={require("img/common/icon_more.png")}
                        alt="logo"
                      />
                      <p className="name">(-)재산세 중복분</p>
                    </div>
                    <div className="value">
                      <div>
                        <span className="number">
                          {calcCompInfo
                            ? Util.comma(calcCompInfo.comp_dupl_prop_tax)
                            : ""}
                        </span>
                        <span className="unit">원</span>
                      </div>
                    </div>
                  </div>
                  <div className="detailTaxWrap">
                    <p className="name">종합부동산세 B</p>
                    <div className="value">
                      <div>
                        <span className="number">
                          {calcCompInfo
                            ? Util.comma(calcCompInfo.comp_tax_B)
                            : ""}
                        </span>
                        <span className="unit">원</span>
                      </div>
                    </div>
                  </div>
                  <div className="moreDetailTaxWrap">
                    <div className="title">
                      <img
                        src={require("img/common/icon_more.png")}
                        alt="logo"
                      />
                      <p className="name">(-)세액 공제(80%한도)</p>
                    </div>
                    <div className="value">
                      <div>
                        <span className="number">
                          {calcCompInfo
                            ? Util.comma(calcCompInfo.tax_credit_amt)
                            : ""}
                        </span>
                        <span className="unit">원</span>
                      </div>
                    </div>
                  </div>
                  <div className="detailTaxWrap">
                    <p className="name">종합부동산세 C</p>
                    <div className="value">
                      <div>
                        <span className="number">
                          {calcCompInfo
                            ? Util.comma(calcCompInfo.comp_tax_C)
                            : ""}
                        </span>
                        <span className="unit">원</span>
                      </div>
                    </div>
                  </div>
                  <div className="moreDetailTaxWrap">
                    <div className="title">
                      <img
                        src={require("img/common/icon_more.png")}
                        alt="logo"
                      />
                      <p className="name">(+)농어촌 특별세</p>
                    </div>
                    <div className="value">
                      <div>
                        <span className="number">
                          {calcCompInfo
                            ? Util.comma(calcCompInfo.farm_fish_tax)
                            : ""}
                        </span>
                        <span className="unit">원</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="taxWrap"
                    style={{ borderBottomColor: "#fff" }}
                  >
                    <div className="tax">
                      <p className="name">납부세액</p>
                      <div className="value">
                        <div>
                          <span className="number">
                            {calcCompInfo
                              ? Util.comma(calcCompInfo.comp_total_tax)
                              : ""}
                          </span>
                          <span className="unit">원</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </OpenAndCloseContainer>
        </TaxInfoContainer>
      </TaxContainer>
    </>
  );
}

const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  justify-content: center;

  width: 100%;
  height: 73px;

  background: ${(props) => props.theme.color.bg};
  border-radius: 8px;

  .text {
    text-align: center;
    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.4em;
    line-height: 1.32;
    letter-spacing: -0.05em;
    color: ${(props) => props.theme.color.nGray20};
  }
`;

const OpenAndCloseContainer = styled.div<{ $maxHeight: number }>`
  .detail-container {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }

  .detail-container.open {
    max-height: ${({ $maxHeight }) =>
      $maxHeight}px; /* 전달된 props에 따라 max-height 설정 */
  }
`;

const TaxInfoContainer = styled.div`
  width: 100%;
  height: auto;
  margin-top: 20px;

  .taxWrap {
    padding: 8px 0;
    border-bottom: 1px solid ${(props) => props.theme.color.nGray80};

    .tax {
      position: relative;

      display: flex;
      justify-content: space-between;
      align-items: center;

      background-color: ${(props) => props.theme.color.pGreenLight04};

      &.closed {
        background-color: white;
      }

      .name {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 500;
        font-size: 1.4em;
        line-height: 1.32;
        letter-spacing: -0.05em;
        color: ${(props) => props.theme.color.sNavy};
      }

      .value {
        flex-basis: 60%;

        display: flex;
        justify-content: end;

        .rate {
          font-family: "Spoqa Han Sans Neo";
          font-weight: 600;
          font-size: 1.6em;
          line-height: 1;
          letter-spacing: -0.03125em;
          color: ${(props) => props.theme.color.sNavyDark01};
        }

        .number {
          font-family: "Spoqa Han Sans Neo";
          font-weight: 600;
          font-size: 1.6em;
          line-height: 1;
          letter-spacing: -0.03125em;
          color: ${(props) => props.theme.color.pGreenDark01};

          margin-left: 15px;
        }

        .unit {
          font-family: "Spoqa Han Sans Neo";
          font-weight: 400;
          font-size: 1.2em;
          line-height: 1;
          letter-spacing: -0.058em;
          color: #727b8e;
          margin-left: 2px;
        }
      }

      .highlight {
        position: absolute;
        top: 0px;
        left: -3px;
        z-index: 0;

        width: calc(100% + 6px);
        height: 100%;
        background-color: ${(props) => props.theme.color.pGreenLight04};
        border-radius: 2px;
      }
    }

    .red {
      color: ${(props) => props.theme.color.sysRed100} !important;
    }
  }

  .detailWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px 0 10px 5px;

    .name {
      display: flex;
      align-items: center;

      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.nGray50};

      .icon {
        width: 16px;
        height: 16px;
        margin-right: 2px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .value {
      .number {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 600;
        font-size: 1.6em;
        line-height: 1;
        letter-spacing: -0.03125em;
        color: ${(props) => props.theme.color.nGray30};
      }

      .unit {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.2em;
        line-height: 1;
        letter-spacing: -0.058em;
        color: #727b8e;
        margin-left: 2px;
      }
    }
  }

  .detailTaxWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 8px 0;
    border-bottom: 1px solid ${(props) => props.theme.color.nGray80};

    .name {
      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.sNavy};
    }

    .value {
      .number {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 600;
        font-size: 1.6em;
        line-height: 1;
        letter-spacing: -0.03125em;
        color: ${(props) => props.theme.color.nGray30};
      }

      .unit {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.2em;
        line-height: 1;
        letter-spacing: -0.058em;
        color: #727b8e;
        margin-left: 2px;
      }

      .blue {
        color: ${(props) => props.theme.color.sysBlue100} !important;
      }
    }
  }

  .moreDetailTaxWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 39px;

    padding: 8px 0;

    &.tableContainer {
      height: auto;
      padding: 0 0 8px 8px;
      border-bottom: 1px solid ${(props) => props.theme.color.nGray80};
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      font-family: "Spoqa Han Sans Neo";
      font-weight: 500;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.nGray50};

      img {
        width: 16px;
        height: auto;
      }
    }

    .value {
      .number {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 600;
        font-size: 1.6em;
        line-height: 1;
        letter-spacing: -0.03125em;
        color: ${(props) => props.theme.color.nGray30};
      }

      .unit {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.2em;
        line-height: 1;
        letter-spacing: -0.058em;
        color: #727b8e;
        margin-left: 2px;
      }

      .blue {
        color: ${(props) => props.theme.color.sysBlue100} !important;
      }
    }
  }
`;

const TaxContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: gap;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 0px 20px 24px 20px;
  background-color: white;
  border-radius: 12px;
  overflow-y: visible;
`;

const NavContainer = styled.div`
  position: sticky;
  top: 0px;
  width: 100%;
  height: 50px;
  z-index: 10;
  background-color: white;
  display: flex;
  align-items: end;
  .nav {
    display: flex;
    align-items: center;
    gap: 24px;
    .item {
      display: flex;
      align-items: center;
      width: auto;
      height: 100%;
      border: 1px solid transparent;
      font-family: "Spoqa Han Sans Neo";
      font-weight: 500;
      font-size: 1.4em;
      line-height: 1.5;
      letter-spacing: -0.04em;
      color: ${(props) => props.theme.color.sNavy};
      z-index: 1;
      border-bottom: 2px solid transparent;
      transition: all 0.1s ease;
      padding-left: 0px;
      padding-right: 0px;
      &.active {
        color: ${(props) => props.theme.color.pGreen};
        border-bottom: 2px solid ${(props) => props.theme.color.pGreen};
      }
    }
    .line {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 1px;
      background-color: ${(props) => props.theme.color.nGray70};
    }
  }
`;

const DetailTaxContainer = styled.div`
  width: 100%;
  height: auto;
  padding-top: 20px;
  background-color: white;
  border-radius: 12px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 500;
    font-size: 1.4em;
    line-height: 1.32;
    letter-spacing: -0.05em;
    color: ${(props) => props.theme.color.sNavy};

    button {
      width: 24px;
      height: 24px;
      color: ${(props) => props.theme.color.nGray60};

      display: flex;
      justify-content: center;
      align-items: center;

      transition: transform 0.3s ease;

      img {
        width: 13px;
        height: auto;
      }
    }

    button.closed {
      transform: rotate(180deg);
    }
  }
`;

export default CalcResult;
