import { useState } from "react";
import styled from "styled-components";

import TaxSavingGuideDialog from "./TaxSavingGuideDialog";

interface TaxSavingGuideProps {
	transferTaxGuide: any
}

function TaxSavingGuide({transferTaxGuide}:TaxSavingGuideProps) {
	const [dialogOpen, setDialogOpen] = useState<boolean>(false);

	const onClickDetail = () => {
		setDialogOpen(true);
	};

  return (
    <>
      	<GuideWrapper>
			<GuideContainer>
				<div className="guide">
					<div className="title">
					<div className="iconWrap">
						<img
						src={require("img/common/tax_saving_guide_icon.png")}
						alt="caution icon"
						/>
					</div>
					<span className="text">비과세 절세가이드</span>
					</div>
				</div>
				<div className="detail">
					<button onClick={onClickDetail}>
					<div className="iconWrap">
						<img
						src={require("img/common/tax_saving_guide_detail_icon.png")}
						alt="caution icon"
						/>
					</div>
					<span>절세 가이드 자세히보기</span>
					</button>
				</div>
			</GuideContainer>
      	</GuideWrapper>
      	<TaxSavingGuideDialog open={dialogOpen} setOpen={setDialogOpen} transferTaxGuide={transferTaxGuide} />
    </>
  );
}

const GuideWrapper = styled.div`
	width: 380px;
	height: auto;
	padding: 4px;
	border-radius: 20px;
	background-color: white;

	box-sizing: border-box;
`;

const GuideContainer = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 5px;
  background-color: #e7ffed;	// #f9fafb
  box-sizing: border-box;
	 
  .guide {
    .title {
      display: flex;
      align-items: center;

      .iconWrap {
        display: inline-block;
        width: 19px;
        height: 19px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .text {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 500;
        font-size: 12px;
        line-height: 1;
        letter-spacing: -0.058em;
      }
    }
  }

  .detail {
    button {
      display: flex;
      align-items: center;
      gap: 4px;

      padding: 3px 13px;
      border-radius: 4px;
      background-color: #45c25a;

      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 12px;
      line-height: 1.4;
      letter-spacing: -0.058em;
      color: white;

      .iconWrap {
        display: inline-block;
        width: 11px;
        height: 14.56px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;

export default TaxSavingGuide;
