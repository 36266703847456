/*******************************************************************************
*** AssetsRar ** 보유 or 취득 자산 정보 입력 > 거주 및 임대정보 입력
*******************************************************************************/
import { IWizard } from "use-wizard/lib/cjs/useWizard/types/IWizard";
import { TStep } from "use-wizard/lib/cjs/useWizard/types/TStep";
import React from "react";
import Util from "js/common/Util";
import DatePickerBox from "js/common/ui/input/DatePickerBox";
import Axios from "js/common/Axios";
import AppContext from "js/common/AppContext";
import ImageRadioBox from "js/common/ui/input/ImageRadioBox";
import ImageCheckBox from "js/common/ui/input/ImageCheckBox";
import NumberTextBox from "js/common/ui/input/NumberTextBox";
import AssetsTitle from "./ui/AssetsTitle";
import AssetsButton from "./ui/AssetsButton";
import AssetsUtil from "js/common/AssetsUtil";
import ToolTip from "js/common/ui/ToolTip";
import Modal from "js/common/ui/modal/Modal";
import ManualCheckGuide from "../help/ManualCheckGuide";
import { assetsAnswerInfoProp } from "./AssetsQestn";
import Qestn13Modal from "./qestn/Qestn13Modal";
import Qestn18Modal from "./qestn/Qestn18Modal";
import Qestn10Modal from "./qestn/Qestn10Modal";
import AssetsEditTab from "./ui/AssetsEditTab";
// import AssetsButtonBar from "./ui/AssetsButtonBar";

const AssetsEditRar = (props: {
    step: TStep;
    wizard: IWizard;
    form: any;
    dispatchForm: Function;
}) => {
    const [rarFormSeq, setRarFormSeq] = React.useState<number>(); // 양식 구분 : 구분에 따른 form 랜더링

    // 부동산 상세정보
    const [pnu, setPnu] = React.useState<string>("");
    const [dongNm, setDongNm] = React.useState<string>("");
    const [hoNm, setHoNm] = React.useState<string>("");
    const [estatePrpos, setEstatePrpos] = React.useState<string>("");
    const [estateFom, setEstateFom] = React.useState<string>("");
    const [estateDetailSe, setEstateDetailSe] = React.useState<string>("");
    const [acqsSrc, setAcqsSrc] = React.useState<string>("");
    const [acqsCause, setAcqsCause] = React.useState<string>("");
    const [beforeEstatePrpos, setBeforeEstatePrpos] = React.useState<string>("");

    const [titleText1, setTitleText1] = React.useState<string>("");
    const [titleText2, setTitleText2] = React.useState<string>("");

    const [trnsfrnDe, setTrnsfrnDe] = React.useState<string>(); // 전입일
    const [lvhsDe, setLvhsDe] = React.useState<string>(); // 전출일
    const [trnsfrnDe2, setTrnsfrnDe2] = React.useState<string>(); // 전입일: 피상속인
    const [lvhsDe2, setLvhsDe2] = React.useState<string>(); // 전출일: 피상속인

    const [locgovRegistAt, setLocgovRegistAt] = React.useState<string>(""); // 지자체 등록여부
    const [locgovRegistDe, setLocgovRegistDe] = React.useState<string>(); // 지자체 등록일
    const [locgovRqstdeDsb, setLocgovRqstdeDsb] = React.useState<boolean>(true); // 지자체 신청일 핸들 파라미터
    const [locgovRqstde, setLocgovRqstde] = React.useState<string>(); // 지자체 신청일
    const [rentTy, setRentTy] = React.useState<string>(""); // 임대유형
    const [rentTyCnvrsDe, setRentTyCnvrsDe] = React.useState<string>(); // 장기등록 전환신청일
    const [estateRentTy, setEstateRentTy] = React.useState<string>(""); // 임대주택 종류
    const [ersrAt, setErsrAt] = React.useState<string>(""); // 말소여부
    const [ersrKnd, setErsrKnd] = React.useState<string>(""); // 말소종류
    const [ersrDe, setErsrDe] = React.useState<string>(); // 말소일

    const [taxofcRegistAt, setTaxofcRegistAt] = React.useState<string>(""); // 세무서 등록여부
    const [taxofcRegistDe, setTaxofcRegistDe] = React.useState<string>(); // 세무서 등록일
    const [taxofcRqstdeDsb, setTaxofcRqstdeDsb] = React.useState<boolean>(true); // 세무서 신청일 핸들 파라미터
    const [taxofcRqstde, setTaxofcRqstde] = React.useState<string>(); // 세무서 신청일
    const [bizqitAt, setBizqitAt] = React.useState<string>(""); // 폐업여부
    const [bizqitDe, setBizqitDe] = React.useState<string>(); // 폐업일자
    const [rentBgnde, setRentBgnde] = React.useState<string>(); // 임대개시일
    const [stdrMktc, setStdrMktc] = React.useState<string>(""); // 임대개시일기준시가
    const [rntchrgUplmtFlflAt, setRntchrgUplmtFlflAt] = React.useState<string>(""); // 5% 상한 이행여부

    const [locgovRegistAt2, setLocgovRegistAt2] = React.useState<string>(""); // 지자체 등록여부
    const [locgovRegistDe2, setLocgovRegistDe2] = React.useState<string>(); // 지자체 등록일
    const [locgovRqstdeDsb2, setLocgovRqstdeDsb2] = React.useState<boolean>(true); // 지자체 신청일 핸들 파라미터
    const [locgovRqstde2, setLocgovRqstde2] = React.useState<string>(); // 지자체 신청일
    const [rentTy2, setRentTy2] = React.useState<string>(""); // 임대유형
    const [rentTyCnvrsDe2, setRentTyCnvrsDe2] = React.useState<string>(); // 장기등록 전환신청일
    const [estateRentTy2, setEstateRentTy2] = React.useState<string>(""); // 임대주택 종류
    const [ersrAt2, setErsrAt2] = React.useState<string>(""); // 말소여부
    const [ersrKnd2, setErsrKnd2] = React.useState<string>(""); // 말소종류
    const [ersrDe2, setErsrDe2] = React.useState<string>(); // 말소일

    const [taxofcRegistAt2, setTaxofcRegistAt2] = React.useState<string>(""); // 세무서 등록여부
    const [taxofcRegistDe2, setTaxofcRegistDe2] = React.useState<string>(); // 세무서 등록일
    const [taxofcRqstdeDsb2, setTaxofcRqstdeDsb2] = React.useState<boolean>(true); // 2세무서 신청일 핸들 파라미터
    const [taxofcRqstde2, setTaxofcRqstde2] = React.useState<string>(); // 세무서 신청일
    const [bizqitAt2, setBizqitAt2] = React.useState<string>(""); // 폐업여부
    const [bizqitDe2, setBizqitDe2] = React.useState<string>(); // 폐업일자
    const [rentBgnde2, setRentBgnde2] = React.useState<string>(); // 임대개시일
    const [stdrMktc2, setStdrMktc2] = React.useState<string>(""); // 임대개시일기준시가
    const [rntchrgUplmtFlflAt2, setRntchrgUplmtFlflAt2] = React.useState<string>(""); // 5% 상한 이행여부

    // 기준시가 수동조회 화면 안내
    const [manualCheckGuideModalOpen, setManualCheckGuideModalOpen] = React.useState<boolean>(false);

    const [excptHouseInfoVsbYn, setExcptHouseInfoVsbYn] = React.useState<string>("N");
    const [wwinHouseAt, setWwinHouseAt] = React.useState<string>("");
    const [acqsHouseAt, setAcqsHouseAt] = React.useState<string>("");
    const [frhlHouseAt, setFrhlHouseAt] = React.useState<string>("");
    const [crltsHouseAt, setCrltsHouseAt] = React.useState<string>("");
    const [mrtgHouseAt, setMrtgHouseAt] = React.useState<string>("");
    const [childHouseAt, setChildHouseAt] = React.useState<string>("");
    const [salesHouseAt, setSalesHouseAt] = React.useState<string>("");
    const [estateHouseAt, setEstateHouseAt] = React.useState<string>("");

    const [qestn10Open, setQestn10Open] = React.useState<boolean>(false);
    const [qestn13Open, setQestn13Open] = React.useState<boolean>(false);
    const [qestn18Open, setQestn18Open] = React.useState<boolean>(false);

    const [qestn10AnswerText, setQestn10AnswerText] = React.useState<string>("");
    const [qestn12AnswerText, setQestn12AnswerText] = React.useState<string>("");

    // 농어촌주택 질문
    const [qestn62AnswerText, setQestn62AnswerText] = React.useState<string>("");
    const [qestn63AnswerText, setQestn63AnswerText] = React.useState<string>("");
    const [qestn64AnswerText, setQestn64AnswerText] = React.useState<string>("");
    const [qestn65AnswerText, setQestn65AnswerText] = React.useState<string>("");
    const [qestn66AnswerText, setQestn66AnswerText] = React.useState<string>("");

    const [qestn18AnswerText, setQestn18AnswerText] = React.useState<string>("");
    const [qestn23AnswerText, setQestn23AnswerText] = React.useState<string>("");
    const [qestn19AnswerText, setQestn19AnswerText] = React.useState<string>("");
    const [qestn7AnswerText, setQestn7AnswerText] = React.useState<string>("");
    const [qestn52AnswerText, setQestn52AnswerText] = React.useState<string>("");
    const [qestn53AnswerText, setQestn53AnswerText] = React.useState<string>("");

    const goPrevStep = () => {
        props.wizard.goToStep(1002);
    }
    const goNextStep = (callMode: string) => {
        const param = {
            assetsSn: props.form.assetsSn,
            estateRentInfo1: {},
            estateRentInfo2: {},
            estateResideInfo1: {},
            estateResideInfo2: {},
            excptHouseInfo: {}
        };
        const getParamEstateRentInfo1 = () => {
            return {
                locgovRegistAt: locgovRegistAt,
                locgovRegistDe: locgovRegistAt === "Y" ? locgovRegistDe : null,
                locgovRqstde: locgovRegistAt === "Y" && !locgovRqstdeDsb ? locgovRqstde : null,
                rentTy: locgovRegistAt === "Y" ? rentTy : null,
                rentTyCnvrsDe: locgovRegistAt === "Y" && rentTy === "1003" ? rentTyCnvrsDe : null,
                estateRentTy: locgovRegistAt === "Y" ? estateRentTy : null,
                ersrAt: locgovRegistAt === "Y" ? ersrAt : null,
                ersrKnd: locgovRegistAt === "Y" && ersrAt === "Y" ? ersrKnd : null,
                ersrDe: locgovRegistAt === "Y" && ersrAt === "Y" ? ersrDe : null,
                taxofcRegistAt: taxofcRegistAt,
                taxofcRegistDe: taxofcRegistAt === "Y" ? taxofcRegistDe : null,
                taxofcRqstde: taxofcRegistAt === "Y" && !taxofcRqstdeDsb ? taxofcRqstde : null,
                bizqitAt: taxofcRegistAt === "Y" ? bizqitAt : null,
                bizqitDe: taxofcRegistAt === "Y" && bizqitAt === "Y" ? bizqitDe : null,
                rentBgnde: rentBgnde,
                stdrMktc: Number(Util.uncomma(stdrMktc)),
                rntchrgUplmtFlflAt: rntchrgUplmtFlflAt,
                trnsfrnDe: trnsfrnDe,
                lvhsDe: lvhsDe
            };
        }
        const getParamEstateRentInfo2 = () => {
            return {
                locgovRegistAt: locgovRegistAt2,
                locgovRegistDe: locgovRegistAt2 === "Y" ? locgovRegistDe2 : null,
                locgovRqstde: locgovRegistAt2 === "Y" && !locgovRqstdeDsb2 ? locgovRqstde2 : null,
                rentTy: locgovRegistAt2 === "Y" ? rentTy2 : null,
                rentTyCnvrsDe: locgovRegistAt2 === "Y" && rentTy2 === "1003" ? rentTyCnvrsDe2 : null,
                estateRentTy: locgovRegistAt2 === "Y" ? estateRentTy2 : null,
                ersrAt: locgovRegistAt2 === "Y" ? ersrAt2 : null,
                ersrKnd: locgovRegistAt2 === "Y" && ersrAt2 === "Y" ? ersrKnd2 : null,
                ersrDe: locgovRegistAt2 === "Y" && ersrAt2 === "Y" ? ersrDe2 : null,
                taxofcRegistAt: taxofcRegistAt2,
                taxofcRegistDe: taxofcRegistAt2 === "Y" ? taxofcRegistDe2 : null,
                taxofcRqstde: taxofcRegistAt2 === "Y" && !taxofcRqstdeDsb2 ? taxofcRqstde2 : null,
                bizqitAt: taxofcRegistAt2 === "Y" ? bizqitAt2 : null,
                bizqitDe: taxofcRegistAt2 === "Y" && bizqitAt2 === "Y" ? bizqitDe2 : null,
                rentBgnde: rentBgnde2,
                stdrMktc: Number(Util.uncomma(stdrMktc2)),
                rntchrgUplmtFlflAt: rntchrgUplmtFlflAt2,
                trnsfrnDe: trnsfrnDe2,
                lvhsDe: lvhsDe2
            };
        }
        const chkReqInput1 = (ttlNm: string) => {
            const ttlDtlNm = Util.isNotEmpty(ttlNm) ? ttlNm + "의 " : "";
            if (locgovRegistAt === "Y") { // 지자체 등록인 경우
                if (Util.isEmpty(locgovRegistDe)) {
                    AppContext.openAlert({
                        title: ttlDtlNm + "지자체등록일 입력",
                        msg: ttlDtlNm + "지자체등록일을 입력하세요.",
                        icon: "check"
                    });
                    return false;
                }
            }
            if (!locgovRqstdeDsb) {
                if (Util.isEmpty(locgovRqstde)) {
                    AppContext.openAlert({
                        title: ttlDtlNm + "지자체신청일 입력",
                        msg: ttlDtlNm + "지자체신청일을 입력하세요.",
                        icon: "check"
                    });
                    return false;
                }
            }
            if (rentTy === "1003") {
                if (Util.isEmpty(rentTyCnvrsDe)) {
                    AppContext.openAlert({
                        title: ttlDtlNm + "장기등록 전환신청일 입력",
                        msg: ttlDtlNm + "장기등록 전환신청일을 입력하세요.",
                        icon: "check"
                    });
                    return false;
                }
            }
            if (ersrAt === "Y") { // 말소인 경우
                if (Util.isEmpty(ersrKnd)) {
                    AppContext.openAlert({
                        title: ttlDtlNm + "말소종류 입력",
                        msg: ttlDtlNm + "말소종류를 입력하세요.",
                        icon: "check"
                    });
                    return false;
                }
                if (Util.isEmpty(ersrDe)) {
                    AppContext.openAlert({
                        title: ttlDtlNm + "말소일 입력",
                        msg: ttlDtlNm + "말소일을 입력하세요.",
                        icon: "check"
                    });
                    return false;
                }
            }
            if (taxofcRegistAt === "Y") { // 세무서 등록인 경우
                if (Util.isEmpty(taxofcRegistDe)) {
                    AppContext.openAlert({
                        title: ttlDtlNm + "세무서등록일 입력",
                        msg: ttlDtlNm + "세무서등록일을 입력하세요.",
                        icon: "check"
                    });
                    return false;
                }
            }
            if (!taxofcRqstdeDsb) {
                if (Util.isEmpty(taxofcRqstde)) {
                    AppContext.openAlert({
                        title: ttlDtlNm + "세무서신청일 입력",
                        msg: ttlDtlNm + "세무서신청일을 입력하세요.",
                        icon: "check"
                    });
                    return false;
                }
            }
            if (bizqitAt === "Y") { // 폐업인 경우
                if (Util.isEmpty(bizqitDe)) {
                    AppContext.openAlert({
                        title: ttlDtlNm + "폐업일 입력",
                        msg: ttlDtlNm + "폐업일을 입력하세요.",
                        icon: "check"
                    });
                    return false;
                }
            }
            if (Util.isEmpty(rentBgnde)) {
                AppContext.openAlert({
                    title: ttlDtlNm + "임대개시일 입력",
                    msg: ttlDtlNm + "임대개시일을 입력하세요.",
                    icon: "check"
                });
                return false;
            }
            return true;
        }

        const chkReqInput2 = (ttlNm: string) => {
            const ttlDtlNm = Util.isNotEmpty(ttlNm) ? ttlNm + "의 " : "";
            if (locgovRegistAt2 === "Y") { // 지자체2 등록인 경우
                if (Util.isEmpty(locgovRegistDe2)) {
                    AppContext.openAlert({
                        title: ttlDtlNm + "지자체등록일 입력",
                        msg: ttlDtlNm + "지자체등록일을 입력하세요.",
                        icon: "check"
                    });
                    return false;
                }
            }
            if (!locgovRqstdeDsb2) {
                if (Util.isEmpty(locgovRqstde2)) {
                    AppContext.openAlert({
                        title: ttlDtlNm + "지자체신청일 입력",
                        msg: ttlDtlNm + "지자체신청일을 입력하세요.",
                        icon: "check"
                    });
                    return false;
                }
            }
            if (rentTy2 === "1003") {
                if (Util.isEmpty(rentTyCnvrsDe2)) {
                    AppContext.openAlert({
                        title: ttlDtlNm + "장기등록 전환신청일 입력",
                        msg: ttlDtlNm + "장기등록 전환신청일을 입력하세요.",
                        icon: "check"
                    });
                    return false;
                }
            }
            if (ersrAt2 === "Y") { // 말소인 경우
                if (Util.isEmpty(ersrKnd2)) {
                    AppContext.openAlert({
                        title: ttlDtlNm + "말소종류 입력",
                        msg: ttlDtlNm + "말소종류를 입력하세요.",
                        icon: "check"
                    });
                    return false;
                }
                if (Util.isEmpty(ersrDe2)) {
                    AppContext.openAlert({
                        title: ttlDtlNm + "말소일 입력",
                        msg: ttlDtlNm + "말소일을 입력하세요.",
                        icon: "check"
                    });
                    return false;
                }
            }
            if (taxofcRegistAt2 === "Y") { // 세무서2 등록인 경우
                if (Util.isEmpty(taxofcRegistDe2)) {
                    AppContext.openAlert({
                        title: ttlDtlNm + "세무서등록일 입력",
                        msg: ttlDtlNm + "세무서등록일을 입력하세요.",
                        icon: "check"
                    });
                    return false;
                }
            }
            if (!taxofcRqstdeDsb2) {
                if (Util.isEmpty(taxofcRqstde2)) {
                    AppContext.openAlert({
                        title: ttlDtlNm + "세무서신청일 입력",
                        msg: ttlDtlNm + "세무서신청일을 입력하세요.",
                        icon: "check"
                    });
                    return false;
                }
            }
            if (bizqitAt2 === "Y") { // 폐업인 경우
                if (Util.isEmpty(bizqitDe2)) {
                    AppContext.openAlert({
                        title: ttlDtlNm + "폐업일 입력",
                        msg: ttlDtlNm + "폐업일을 입력하세요.",
                        icon: "check"
                    });
                    return false;
                }
            }
            if (Util.isEmpty(rentBgnde2)) {
                AppContext.openAlert({
                    title: ttlDtlNm + "임대개시일 입력",
                    msg: ttlDtlNm + "임대개시일을 입력하세요.",
                    icon: "check"
                });
                return false;
            }
            return true;
        }
        if (estateDetailSe === "1001") { // 주택
            if (estatePrpos === "1001") { // 주거전용(주택용도)
                if (acqsSrc === "1001" || acqsSrc === "1005") { // 기존 아파트, 오피스텔취득
                    if (acqsCause === "1002") { // 매매
                        param.estateResideInfo1 = {
                            trnsfrnDe: trnsfrnDe,
                            lvhsDe: lvhsDe
                        }
                    } else if (["1003", "1004"].indexOf(acqsCause) !== -1) { // 상속, 증여
                        param.estateResideInfo1 = {
                            trnsfrnDe: trnsfrnDe,
                            lvhsDe: lvhsDe
                        }
                        param.estateResideInfo2 = {
                            trnsfrnDe: trnsfrnDe2,
                            lvhsDe: lvhsDe2
                        }
                    }
                } else if (acqsSrc === "1002") { // 분양권에 의한 아파트
                    if (["1011", "1012", "1002", "1003", "1004"].indexOf(acqsCause) !== -1) {
                        param.estateResideInfo1 = {
                            trnsfrnDe: trnsfrnDe,
                            lvhsDe: lvhsDe
                        }
                    }
                } else if (acqsSrc === "1006") { // 일반분양오피스텔취득
                    if (["1011", "1012", "1002", "1003"].indexOf(acqsCause) !== -1) {
                        param.estateResideInfo1 = {
                            trnsfrnDe: trnsfrnDe,
                            lvhsDe: lvhsDe
                        }
                    } else if (acqsCause === "1004") {
                        param.estateResideInfo1 = {
                            trnsfrnDe: trnsfrnDe,
                            lvhsDe: lvhsDe
                        }
                        param.estateResideInfo2 = {
                            trnsfrnDe: trnsfrnDe2,
                            lvhsDe: lvhsDe2
                        }
                    }
                } else if (acqsSrc === "1003") { // 조합원입주권에 의한 아파트
                    if (acqsCause === "1001") { // 원조합원
                        param.estateResideInfo1 = {
                            trnsfrnDe: trnsfrnDe,
                            lvhsDe: lvhsDe
                        }
                        param.estateResideInfo2 = {
                            trnsfrnDe: trnsfrnDe2,
                            lvhsDe: lvhsDe2
                        }
                    } else if (acqsCause === "1002") { // 전매
                        param.estateResideInfo1 = {
                            trnsfrnDe: trnsfrnDe,
                            lvhsDe: lvhsDe
                        }
                    } else if (acqsCause === "1003") { // 상속
                        param.estateResideInfo1 = {
                            trnsfrnDe: trnsfrnDe,
                            lvhsDe: lvhsDe
                        }
                        param.estateResideInfo2 = {
                            trnsfrnDe: trnsfrnDe2,
                            lvhsDe: lvhsDe2
                        }
                    } else if (acqsCause === "1004") { // 증여
                        param.estateResideInfo1 = {
                            trnsfrnDe: trnsfrnDe,
                            lvhsDe: lvhsDe
                        }
                    }
                } else if (acqsSrc === "1004") { // 자가신축주택취득
                    param.estateResideInfo1 = {
                        trnsfrnDe: trnsfrnDe,
                        lvhsDe: lvhsDe
                    }
                }
            } else if (estatePrpos === "1002") { // 임대전용
                if (estateDetailSe === "1001") { // 주택
                    if (acqsSrc === "1001" || acqsSrc === "1005") { // 기존 아파트, 오피스텔 취득
                        if (acqsCause === "1002") { // 매매
                            if (!chkReqInput1("")) return;
                            param.estateRentInfo1 = getParamEstateRentInfo1();
                        } else if (acqsCause === "1003") { // 상속
                            if (!chkReqInput1("상속인")) return;
                            param.estateRentInfo1 = getParamEstateRentInfo1();
                            param.estateResideInfo2 = {
                                trnsfrnDe: trnsfrnDe2,
                                lvhsDe: lvhsDe2
                            }
                        } else if (acqsCause === "1004") { // 증여
                            if (!chkReqInput1("수증자")) return;
                            param.estateRentInfo1 = getParamEstateRentInfo1();
                            param.estateResideInfo2 = {
                                trnsfrnDe: trnsfrnDe2,
                                lvhsDe: lvhsDe2
                            }
                        }
                    } else if (acqsSrc === "1002") { // 분양권에 의한 아파트
                        if (!chkReqInput1("")) return;
                        param.estateRentInfo1 = getParamEstateRentInfo1();
                    } else if (acqsSrc === "1003") { // 조합원입주권에 의한 아파트
                        if (acqsCause === "1001") { // 원조합원
                            if (!chkReqInput1("")) return;
                            param.estateRentInfo1 = getParamEstateRentInfo1();
                            param.estateResideInfo2 = {
                                trnsfrnDe: trnsfrnDe2,
                                lvhsDe: lvhsDe2
                            }
                        } else if (acqsCause === "1002") { // 전매
                            if (!chkReqInput1("")) return;
                            param.estateRentInfo1 = getParamEstateRentInfo1();
                        } else if (acqsCause === "1003") { // 상속
                            if (!chkReqInput1("상속인")) return;
                            param.estateRentInfo1 = getParamEstateRentInfo1();
                            param.estateResideInfo2 = {
                                trnsfrnDe: trnsfrnDe2,
                                lvhsDe: lvhsDe2
                            }
                        } else if (acqsCause === "1004") { // 증여
                            if (!chkReqInput1("수증자")) return;
                            param.estateRentInfo1 = getParamEstateRentInfo1();
                        }
                    } else if (acqsSrc === "1004") { // 자가신축주택취득
                        if (!chkReqInput1("")) return;
                        param.estateRentInfo1 = getParamEstateRentInfo1();
                    } else if (acqsSrc === "1006") {
                        if (["1011", "1012", "1002", "1003"].indexOf(acqsCause) !== -1) { // 신규분양, 미분양, 전매, 상속
                            if (!chkReqInput1("")) return;
                            param.estateRentInfo1 = getParamEstateRentInfo1();
                        } else if (acqsCause === "1004") { // 증여
                            if (!chkReqInput1("수증자")) return;
                            param.estateRentInfo1 = getParamEstateRentInfo1();
                            param.estateResideInfo2 = {
                                trnsfrnDe: trnsfrnDe2,
                                lvhsDe: lvhsDe2
                            }
                        }
                    }
                }
            }
        } else if (estateDetailSe === "1003") { // 조합원입주권
            if (acqsCause === "1001") {
                if (beforeEstatePrpos === "1001") {
                    param.estateResideInfo1 = {
                        trnsfrnDe: trnsfrnDe,
                        lvhsDe: lvhsDe
                    };
                } else if (beforeEstatePrpos === "1002") {
                    if (!chkReqInput1("")) return;
                    param.estateRentInfo1 = getParamEstateRentInfo1();
                }
            } else if (acqsCause === "1002") {
                param.estateResideInfo1 = {
                    trnsfrnDe: trnsfrnDe,
                    lvhsDe: lvhsDe
                };
            } else if (acqsCause === "1003") {
                if (beforeEstatePrpos === "1001") {
                    param.estateResideInfo1 = {
                        trnsfrnDe: trnsfrnDe,
                        lvhsDe: lvhsDe
                    };
                    param.estateResideInfo2 = {
                        trnsfrnDe: trnsfrnDe2,
                        lvhsDe: lvhsDe2
                    }
                } else if (beforeEstatePrpos === "1002") {
                    if (!chkReqInput2("피상속인")) return;
                    param.estateResideInfo1 = {
                        trnsfrnDe: trnsfrnDe,
                        lvhsDe: lvhsDe
                    };
                    param.estateRentInfo2 = getParamEstateRentInfo2();
                }
            }
        }
        const assetsAnswerInfo: assetsAnswerInfoProp[] = [];
        if (excptHouseInfoVsbYn === "Y") {
            if (childHouseAt === "Y") {
                assetsAnswerInfo.push({ assetsQestnSn: 7, answerText: qestn7AnswerText });
            }
            if (acqsHouseAt === "Y") {
                assetsAnswerInfo.push({ assetsQestnSn: 10, answerText: qestn10AnswerText });
                if (qestn10AnswerText === "Y") {
                    assetsAnswerInfo.push({ assetsQestnSn: 12, answerText: qestn12AnswerText });
                }
            }
            if (frhlHouseAt === "Y") {
                assetsAnswerInfo.push({ assetsQestnSn: 62, answerText: qestn62AnswerText });
                assetsAnswerInfo.push({ assetsQestnSn: 63, answerText: qestn63AnswerText });
                assetsAnswerInfo.push({ assetsQestnSn: 64, answerText: qestn64AnswerText });
                assetsAnswerInfo.push({ assetsQestnSn: 65, answerText: qestn65AnswerText });
                assetsAnswerInfo.push({ assetsQestnSn: 66, answerText: qestn66AnswerText });
            }
            if (crltsHouseAt === "Y") {
                assetsAnswerInfo.push({ assetsQestnSn: 18, answerText: qestn18AnswerText });
                if (qestn18AnswerText === "Y") {
                    assetsAnswerInfo.push({ assetsQestnSn: 23, answerText: qestn23AnswerText });
                }
            }
            if (mrtgHouseAt === "Y") {
                assetsAnswerInfo.push({ assetsQestnSn: 19, answerText: qestn19AnswerText });
            }
            if (salesHouseAt === "Y") {
                assetsAnswerInfo.push({ assetsQestnSn: 52, answerText: qestn52AnswerText });
            }
            if (estateHouseAt === "Y") {
                assetsAnswerInfo.push({ assetsQestnSn: 53, answerText: qestn53AnswerText });
            }
        }
        param.excptHouseInfo = {
            assetsSn: props.form.assetsSn,
            wwinHouseAt: excptHouseInfoVsbYn === "Y" ? wwinHouseAt : "N",
            acqsHouseAt: excptHouseInfoVsbYn === "Y" ? acqsHouseAt : "N",
            frhlHouseAt: excptHouseInfoVsbYn === "Y" ? frhlHouseAt : "N",
            crltsHouseAt: excptHouseInfoVsbYn === "Y" ? crltsHouseAt : "N",
            mrtgHouseAt: excptHouseInfoVsbYn === "Y" ? mrtgHouseAt : "N",
            childHouseAt: excptHouseInfoVsbYn === "Y" ? childHouseAt : "N",
            salesHouseAt: excptHouseInfoVsbYn === "Y" ? salesHouseAt : "N",
            estateHouseAt: excptHouseInfoVsbYn === "Y" ? estateHouseAt : "N",
            assetsAnswerInfo: assetsAnswerInfo
        };
        const saveAssetsRarInfo = () => {
            AppContext.showSpinner();
            Axios.dataAccess({
                url: "system/assets/saveAssetsRarInfo.do",
                methodType: "post",
                paramType: "json",
                paramData: param,
                onSuccessFn: () => {
                    props.dispatchForm({
                        type: "UPDATE_KEY_VALUES",
                        value: {
                            stepCode: "1003"
                        }
                    });
                    if (callMode === "save") {
                        AppContext.hideSpinner();
                        return;
                    }
                    props.wizard.goToStep(1006);
                }
            });
        }
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/assets/chkAssetsRarInfo.do",
            methodType: "post",
            paramType: "json",
            paramData: param,
            onSuccessFn: (res: any) => {
                if (res.item === 0) { // 항목 하나라도 수정한 경우
                    AppContext.hideSpinner();
                    AppContext.openAlert({
                        title: "임대 및 거주 정보 저장",
                        msg: "수정된 내역이 존재합니다. 저장하시겠습니까?",
                        icon: "check",
                        confirmText: "저장",
                        handleConfirm: () => {
                            saveAssetsRarInfo();
                        }
                    });
                } else if (res.item === 1) { // 수정내역 없으면 바로 다음 스텝으로
                    if (callMode === "save") {
                        AppContext.hideSpinner();
                        return;
                    }
                    props.wizard.goToStep(1006);
                } else if (res.item === 2) { // 최초등록
                    saveAssetsRarInfo();
                }
            }
        });
    }

    const getFormSeq = (estateDetail: any, inhrtncInfo: any, donaInfo: any, redevpRebidngInfo: any, estateRentInfo1: any, estateRentInfo2: any, estateResideInfo1: any, estateResideInfo2: any) => {
        const setEstateResideInfo1 = () => {
            setTrnsfrnDe(estateResideInfo1.trnsfrnDe);
            setLvhsDe(estateResideInfo1.lvhsDe);
        }
        const setEstateResideInfo2 = () => {
            setTrnsfrnDe2(estateResideInfo2.trnsfrnDe);
            setLvhsDe2(estateResideInfo2.lvhsDe);
        }
        const setEstateRentInfo1 = () => {
            setEstateRentTy(estateRentInfo1.estateRentTy);
            setRentTy(estateRentInfo1.rentTy);
            setLocgovRegistAt(estateRentInfo1.locgovRegistAt);
            setLocgovRegistDe(estateRentInfo1.locgovRegistDe);
            setLocgovRqstdeDsb(Util.isEmpty(estateRentInfo1.locgovRqstde));
            setLocgovRqstde(estateRentInfo1.locgovRqstde);
            setTaxofcRegistAt(estateRentInfo1.taxofcRegistAt);
            setTaxofcRegistDe(estateRentInfo1.taxofcRegistDe);
            setTaxofcRqstdeDsb(Util.isEmpty(estateRentInfo1.taxofcRqstde));
            setTaxofcRqstde(estateRentInfo1.taxofcRqstde);
            setRentBgnde(estateRentInfo1.rentBgnde);
            setErsrAt(estateRentInfo1.ersrAt);
            setErsrKnd(estateRentInfo1.ersrKnd);
            setErsrDe(estateRentInfo1.ersrDe);
            setBizqitAt(estateRentInfo1.bizqitAt);
            setBizqitDe(estateRentInfo1.bizqitDe);
            setStdrMktc(estateRentInfo1.stdrMktc);
            setTrnsfrnDe(estateRentInfo1.trnsfrnDe);
            setLvhsDe(estateRentInfo1.lvhsDe);
            setRntchrgUplmtFlflAt(estateRentInfo1.rntchrgUplmtFlflAt);
            setRentTyCnvrsDe(estateRentInfo1.rentTyCnvrsDe);
        }
        const setEstateRentInfo2 = () => {
            setEstateRentTy2(estateRentInfo2.estateRentTy);
            setRentTy2(estateRentInfo2.rentTy);
            setLocgovRegistAt2(estateRentInfo2.locgovRegistAt);
            setLocgovRegistDe2(estateRentInfo2.locgovRegistDe);
            setLocgovRqstdeDsb2(Util.isEmpty(estateRentInfo2.locgovRqstde));
            setLocgovRqstde2(estateRentInfo2.locgovRqstde);
            setTaxofcRegistAt2(estateRentInfo2.taxofcRegistAt);
            setTaxofcRegistDe2(estateRentInfo2.taxofcRegistDe);
            setTaxofcRqstdeDsb2(Util.isEmpty(estateRentInfo2.taxofcRqstde));
            setTaxofcRqstde2(estateRentInfo2.taxofcRqstde);
            setRentBgnde2(estateRentInfo2.rentBgnde);
            setErsrAt2(estateRentInfo2.ersrAt);
            setErsrKnd2(estateRentInfo2.ersrKnd);
            setErsrDe2(estateRentInfo2.ersrDe);
            setBizqitAt2(estateRentInfo2.bizqitAt);
            setBizqitDe2(estateRentInfo2.bizqitDe);
            setStdrMktc2(estateRentInfo2.stdrMktc);
            setTrnsfrnDe2(estateRentInfo2.trnsfrnDe);
            setLvhsDe2(estateRentInfo2.lvhsDe);
            setRntchrgUplmtFlflAt2(estateRentInfo2.rntchrgUplmtFlflAt);
            setRentTyCnvrsDe2(estateRentInfo2.rentTyCnvrsDe);
        }
        if (estateDetail.estateDetailSe === "1001") { // 주택
            if (estateDetail.estatePrpos === "1001") { // 주거전용(주택용도)
                if (estateDetail.acqsSrc === "1001" || estateDetail.acqsSrc === "1005") { // 기존 아파트, 오피스텔취득
                    if (estateDetail.acqsCause === "1002") { // 매매
                        setEstateResideInfo1();
                        return 1;
                    } else if (estateDetail.acqsCause === "1003") { // 상속
                        setTitleText1("상속인");
                        setTitleText2("피상속인");
                        setBeforeEstatePrpos(inhrtncInfo.beforeEstatePrpos);
                        setEstateResideInfo1();
                        setEstateResideInfo2();
                        return 2;
                    } else if (estateDetail.acqsCause === "1004") { // 증여
                        setTitleText1("수증자");
                        setTitleText2("증여자");
                        setBeforeEstatePrpos(donaInfo.beforeEstatePrpos);
                        setEstateResideInfo1();
                        setEstateResideInfo2();
                        return 2;
                    }
                } else if (estateDetail.acqsSrc === "1002") { // 분양권에 의한 아파트
                    if (["1011", "1012", "1002", "1003", "1004"].indexOf(estateDetail.acqsCause) !== -1) {
                        setEstateResideInfo1();
                        return 1;
                    }
                } else if (estateDetail.acqsSrc === "1006") { // 일반분양오피스텔취득
                    if (["1011", "1012", "1002", "1003"].indexOf(estateDetail.acqsCause) !== -1) {
                        setEstateResideInfo1();
                        return 1;
                    } else if (estateDetail.acqsCause === "1004") {
                        setTitleText1("수증자");
                        setTitleText2("증여자");
                        setBeforeEstatePrpos(donaInfo.beforeEstatePrpos);
                        setEstateResideInfo1();
                        setEstateResideInfo2();
                        return 2;
                    }
                } else if (estateDetail.acqsSrc === "1003") { // 조합원입주권에 의한 아파트
                    if (estateDetail.acqsCause === "1001") { // 원조합원
                        setTitleText1("신규주택");
                        setTitleText2("종전주택");
                        setBeforeEstatePrpos(redevpRebidngInfo.beforeEstatePrpos);
                        setEstateResideInfo1();
                        setEstateResideInfo2();
                        return 2;
                    } else if (estateDetail.acqsCause === "1002") { // 전매
                        setEstateResideInfo1();
                        return 1;
                    } else if (estateDetail.acqsCause === "1003") { // 상속
                        setTitleText1("상속인");
                        setTitleText2("피상속인");
                        setBeforeEstatePrpos(inhrtncInfo.beforeEstatePrpos);
                        setEstateResideInfo1();
                        setEstateResideInfo2();
                        return 2;
                    } else if (estateDetail.acqsCause === "1004") { // 증여
                        setEstateResideInfo1();
                        return 1;
                    }
                } else if (estateDetail.acqsSrc === "1004") { // 자가신축주택취득
                    setEstateResideInfo1();
                    return 1;
                }
            } else if (estateDetail.estatePrpos === "1002") { // 임대전용
                if (estateDetail.estateDetailSe === "1001") { // 주택
                    if (estateDetail.acqsSrc === "1001" || estateDetail.acqsSrc === "1005") { // 기존 아파트, 오피스텔 취득
                        if (estateDetail.acqsCause === "1002") { // 매매
                            setEstateRentInfo1();
                            return 3;
                        } else if (estateDetail.acqsCause === "1003") { // 상속
                            setBeforeEstatePrpos(inhrtncInfo.beforeEstatePrpos);
                            setEstateRentInfo1();
                            setEstateResideInfo2();
                            setTitleText2("피상속인");
                            return 4;
                        } else if (estateDetail.acqsCause === "1004") { // 증여
                            setBeforeEstatePrpos(donaInfo.beforeEstatePrpos);
                            setEstateRentInfo1();
                            setEstateResideInfo2();
                            setTitleText2("증여자");
                            return 4;
                        }
                    } else if (estateDetail.acqsSrc === "1002") { // 분양권에 의한 아파트
                        setEstateRentInfo1();
                        return 3;
                    } else if (estateDetail.acqsSrc === "1003") { // 조합원입주권에 의한 아파트
                        if (estateDetail.acqsCause === "1001") { // 원조합원
                            setBeforeEstatePrpos(redevpRebidngInfo.beforeEstatePrpos);
                            setEstateRentInfo1();
                            setEstateResideInfo2();
                            setTitleText2("종전주택");
                            return 4;
                        } else if (estateDetail.acqsCause === "1002") { // 전매
                            setEstateRentInfo1();
                            return 3;
                        } else if (estateDetail.acqsCause === "1003") { // 상속
                            setEstateRentInfo1();
                            setEstateResideInfo2();
                            setTitleText2("피상속인");
                            return 4;
                        } else if (estateDetail.acqsCause === "1004") { // 증여
                            setEstateRentInfo1();
                            return 3;
                        }
                    } else if (estateDetail.acqsSrc === "1004") { // 자가신축주택취득
                        setEstateRentInfo1();
                        return 3;
                    } else if (estateDetail.acqsSrc === "1006") {
                        if (["1011", "1012", "1002", "1003"].indexOf(estateDetail.acqsCause) !== -1) { // 신규분양, 미분양, 전매, 상속
                            setEstateRentInfo1();
                            return 3;
                        } else if (estateDetail.acqsCause === "1004") { // 증여
                            setEstateRentInfo1();
                            setEstateResideInfo2();
                            setTitleText2("증여자");
                            return 4;
                        }
                    }
                }
            }
        } else if (estateDetail.estateDetailSe === "1003") { // 조합원입주권
            if (estateDetail.acqsCause === "1001") {
                setBeforeEstatePrpos(redevpRebidngInfo.beforeEstatePrpos);
                if (redevpRebidngInfo.beforeEstatePrpos === "1001") {
                    setEstateResideInfo1();
                    return 1;
                } else if (redevpRebidngInfo.beforeEstatePrpos === "1002") {
                    setEstateRentInfo1();
                    return 3;
                }
            } else if (estateDetail.acqsCause === "1003") {
                setBeforeEstatePrpos(inhrtncInfo.beforeEstatePrpos);
                if (inhrtncInfo.beforeEstatePrpos === "1001") {
                    setTitleText1("상속인");
                    setTitleText2("피상속인");
                    setEstateResideInfo1();
                    setEstateResideInfo2();
                    return 2;
                } else if (inhrtncInfo.beforeEstatePrpos === "1002") {
                    setEstateResideInfo1();
                    setEstateRentInfo2();
                    return 5;
                }
            }
        }
    }

    React.useEffect(() => {
        props.form.setCurrStepCode(1003);
    }, [props.form]);

    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/assets/selAssetsRarInfo.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                assetsSn: props.form.assetsSn
            },
            onSuccessFn: (res: any) => {
                setPnu(res.item.estateInfo.pnu);
                setDongNm(res.item.estateInfo.dongNm);
                setHoNm(res.item.estateInfo.hoNm);
                setEstatePrpos(res.item.estateDetail.estatePrpos);
                setEstateDetailSe(res.item.estateDetail.estateDetailSe);
                setEstateFom(res.item.estateDetail.estateFom);
                setAcqsSrc(res.item.estateDetail.acqsSrc);
                setAcqsCause(res.item.estateDetail.acqsCause);

                setWwinHouseAt(res.item.excptHouseInfo.wwinHouseAt);
                setAcqsHouseAt(res.item.excptHouseInfo.acqsHouseAt);
                setFrhlHouseAt(res.item.excptHouseInfo.frhlHouseAt);
                setCrltsHouseAt(res.item.excptHouseInfo.crltsHouseAt);
                setMrtgHouseAt(res.item.excptHouseInfo.mrtgHouseAt);
                setChildHouseAt(res.item.excptHouseInfo.childHouseAt);
                setSalesHouseAt(res.item.excptHouseInfo.salesHouseAt);
                setEstateHouseAt(res.item.excptHouseInfo.estateHouseAt);

                if (
                    res.item.excptHouseInfo.wwinHouseAt === "Y" ||
                    res.item.excptHouseInfo.acqsHouseAt === "Y" ||
                    res.item.excptHouseInfo.frhlHouseAt === "Y" ||
                    res.item.excptHouseInfo.crltsHouseAt === "Y" ||
                    res.item.excptHouseInfo.mrtgHouseAt === "Y" ||
                    res.item.excptHouseInfo.childHouseAt === "Y" ||
                    res.item.excptHouseInfo.salesHouseAt === "Y" ||
                    res.item.excptHouseInfo.estateHouseAt === "Y"
                ) {
                    setExcptHouseInfoVsbYn("Y");
                } else {
                    setExcptHouseInfoVsbYn("N");
                }

                for (const assetsQestnInfo of res.item.assetsQestnList) {
                    if (assetsQestnInfo.assetsQestnSn === 7) { setQestn7AnswerText(assetsQestnInfo.answerText); }
                    else if (assetsQestnInfo.assetsQestnSn === 10) { setQestn10AnswerText(assetsQestnInfo.answerText); }
                    else if (assetsQestnInfo.assetsQestnSn === 12) { setQestn12AnswerText(assetsQestnInfo.answerText); }
                    else if (assetsQestnInfo.assetsQestnSn === 18) { setQestn18AnswerText(assetsQestnInfo.answerText); }
                    else if (assetsQestnInfo.assetsQestnSn === 23) { setQestn23AnswerText(assetsQestnInfo.answerText); }
                    else if (assetsQestnInfo.assetsQestnSn === 19) { setQestn19AnswerText(assetsQestnInfo.answerText); }
                    else if (assetsQestnInfo.assetsQestnSn === 52) { setQestn52AnswerText(assetsQestnInfo.answerText); }
                    else if (assetsQestnInfo.assetsQestnSn === 53) { setQestn53AnswerText(assetsQestnInfo.answerText); }
                    else if (assetsQestnInfo.assetsQestnSn === 62) { setQestn62AnswerText(assetsQestnInfo.answerText); }
                    else if (assetsQestnInfo.assetsQestnSn === 63) { setQestn63AnswerText(assetsQestnInfo.answerText); }
                    else if (assetsQestnInfo.assetsQestnSn === 64) { setQestn64AnswerText(assetsQestnInfo.answerText); }
                    else if (assetsQestnInfo.assetsQestnSn === 65) { setQestn65AnswerText(assetsQestnInfo.answerText); }
                    else if (assetsQestnInfo.assetsQestnSn === 66) { setQestn66AnswerText(assetsQestnInfo.answerText); }
                }

                // 취득정보 디폴트값 : 신규 작성일 때에만 세팅
                const rarDflValInfo = {
                    estateRentInfo: {
                        estateRentTy: "1001",
                        rentTy: "1001",
                        locgovRegistAt: "N",
                        locgovRegistDe: "",
                        locgovRqstde: "",
                        taxofcRegistAt: "N",
                        taxofcRegistDe: "",
                        taxofcRqstde: "",
                        rentBgnde: "",
                        ersrAt: "N",
                        ersrKnd: "1001",
                        ersrDe: "",
                        bizqitAt: "N",
                        bizqitDe: "",
                        stdrMktc: "0",
                        trnsfrnDe: "",
                        lvhsDe: "",
                        rntchrgUplmtFlflAt: "N",
                        rentTyCnvrsDe: ""
                    },
                    estateResideInfo: {
                        trnsfrnDe: "",
                        lvhsDe: ""
                    }
                };
                const estateRentInfo1 = Object.keys(res.item.estateRentInfo1).length <= 6 ? rarDflValInfo.estateRentInfo : res.item.estateRentInfo1;
                const estateRentInfo2 = Object.keys(res.item.estateRentInfo2).length <= 6 ? rarDflValInfo.estateRentInfo : res.item.estateRentInfo2;
                const estateResideInfo1 = Object.keys(res.item.estateResideInfo1).length <= 6 ? rarDflValInfo.estateResideInfo : res.item.estateResideInfo1;
                const estateResideInfo2 = Object.keys(res.item.estateResideInfo2).length <= 6 ? rarDflValInfo.estateResideInfo : res.item.estateResideInfo2;
                const formSeq = getFormSeq(
                    res.item.estateDetail,
                    res.item.inhrtncInfo,
                    res.item.donaInfo,
                    res.item.redevpRebidngInfo,
                    estateRentInfo1,
                    estateRentInfo2,
                    estateResideInfo1,
                    estateResideInfo2
                );
                if (Util.isEmpty(formSeq)) {
                    AppContext.openAlert({
                        title: "양식 랜더링 오류",
                        msg: "랜더링할 양식이 없습니다. form 번호[" + formSeq + "]",
                        icon: "error"
                    });
                    return;
                }
                setRarFormSeq(formSeq);
                AppContext.hideSpinner();
            }
        });
    }, [props.form.assetsSn]);

    // 전입일
    const TrnsfrnDeEl = () =>
        <DatePickerBox value={trnsfrnDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setTrnsfrnDe(Util.dateToString(data, ""))} />
    const TrnsfrnDe2El = () =>
        <DatePickerBox value={trnsfrnDe2} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setTrnsfrnDe2(Util.dateToString(data, ""))} />

    // 전출일
    const LvhsDeEl = () =>
        <DatePickerBox value={lvhsDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setLvhsDe(Util.dateToString(data, ""))} />
    const LvhsDe2El = () =>
        <DatePickerBox value={lvhsDe2} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setLvhsDe2(Util.dateToString(data, ""))} />

    const StdrMktcEl = () =>
        <div className="dpFlx flxdCol aiC">
            <div className="w100p mt10">
            <NumberTextBox
                value={stdrMktc}
                onChange={(data: string) => setStdrMktc(data)}
                borderClass="bgcWhite w300 h40 pr15 bd1"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                maxLength={15}
                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                unitText="원"
            />
            </div>
            <div className="dpFlx flxdCol w100p jcL mt10 mb10 pw10">
                <div className="dpFlx">
                    <div
                        className="w100 h40 bgcWhite bd1 bdc17A840 bdrd5 dpFlx jcC aiC csP pw10"
                        onClick={() => {
                            if (Util.isEmpty(rentBgnde) || rentBgnde === undefined) {
                                AppContext.openAlert({
                                    title: "임대개시일 입력",
                                    msg: "임대개시일 입력 후 주택가격 조회가 가능합니다.",
                                    icon: "check"
                                });
                                return;
                            }
                            const rentYear = AssetsUtil.getAcqsYear(estateFom, rentBgnde);
                            if (Util.isEmpty(rentYear)) {
                                AppContext.openAlert({
                                    title: "고시일 없음",
                                    msg: "임대개시일에 해당하는 고시일이 없어, 기준시가 조회가 불가합니다.",
                                    icon: "check"
                                });
                                return;
                            }
                            let tailUrl;
                            if (["1001", "1006"].indexOf(estateFom) !== -1) { // 공동주택
                                tailUrl = "apt/price2";
                            } else {
                                tailUrl = "land/pricehouse2";
                            }
                            AppContext.showSpinner();
                            Axios.dataAccess({
                                url: "api/system/broker/callApi.do",
                                methodType: "post",
                                paramType: "object",
                                paramData: {
                                    apiName: "openapi",
                                    tailUrl: tailUrl,
                                    paramStr: pnu + "?stdryear=" + rentYear + "&honm=" + encodeURI(hoNm) + "&dongnm=" + encodeURI(dongNm)
                                },
                                onSuccessFn: (res2: any) => {
                                    AppContext.hideSpinner();
                                    if (res2.item.rtn_list.length === 0) {
                                        AppContext.openAlert({
                                            title: "기준시가 조회 불가",
                                            msg: "해당 연도의 기준시가 정보가 존재하지 않습니다.",
                                            icon: "warning"
                                        });
                                        return;
                                    }
                                    let pblntfPc = "0";
                                    if (["1001", "1006"].indexOf(estateFom) !== -1) { // 공동주택
                                        pblntfPc = res2.item.rtn_list[0].pblntfPc;
                                    } else {
                                        pblntfPc = res2.item.rtn_list[0].housePc;
                                    }
                                    if (Util.isEmpty(pblntfPc)) {
                                        AppContext.openAlert({
                                            title: "기준시가 조회 불가",
                                            msg: "해당 연도의 기준시가 정보가 존재하지 않습니다.",
                                            icon: "warning"
                                        });
                                        return;
                                    }
                                    setStdrMktc(pblntfPc);
                                    AppContext.openAlert({
                                        title: "기준시가 조회 완료",
                                        msg: "기준시가 정보가 연계되었습니다.",
                                        icon: "warning"
                                    });
                                }
                            });
                        }}
                        data-tooltip-id="stdrMktcLinkBtn1"
                        data-tooltip-content="클릭하여 취득일의 기준시가를 가져옵니다."
                    >
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc17A840 taC'>기준시가 자동조회</span>
                    </div>
                    <ToolTip id="stdrMktcLinkBtn1" place='top' clickable={true} className="w300 taL" />
                    <div
                        className="w100 h40 bgcWhite bd1 bdc17A840 bdrd5 dpFlx jcC aiC csP pw10 ml10"
                        onClick={() => {
                            setManualCheckGuideModalOpen(true);
                        }}
                        data-tooltip-id="stdrMktcLinkBtn2"
                        data-tooltip-content="기준시가 자동조회가 되지 않은 경우 사용합니다."
                    >
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc17A840'>기준시가 수동조회</span>
                    </div>
                    <ToolTip id="stdrMktcLinkBtn2" place='top' clickable={true} className="w300 taL" />
                </div>
            </div>
        </div>
    ;

    const StdrMktcEl2 = () =>
        <NumberTextBox
            value={stdrMktc2}
            onChange={(data: string) => setStdrMktc2(data)}
            borderClass="bgcWhite w300 h40 pr15 bd1"
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            maxLength={15}
            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
            unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
            unitText="원"
        />

    const RentTable2 = () =>
        <React.Fragment>
            <div className='dpFlx jcL aiC mb20'>
                <span className='SpoqaHanSansNeo-Bold fs15 ls075 fc222222'>
                    2.&nbsp;
                    {acqsCause === "1001" ? "종전주택" : ""}
                    {acqsCause === "1003" ? "피상속인" : ""}
                    {acqsCause === "1004" ? "증여자" : ""}
                </span>
            </div>
            <table className="forest-form-table tlF">
                <colgroup>
                    <col className="w180" />
                    <col />
                </colgroup>
                <tbody>
                <tr className={"h64 " + (beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") ? "" : "dpNone")}>
                    <th className="taC" title='지자체 등록여부'>지자체 등록여부</th>
                    <td>
                        <ImageRadioBox
                            codeList={Util.getCodeArray("1057")}
                            value={locgovRegistAt2}
                            btnClass="w24 h24 pr5"
                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                            interval={20}
                            onChange={(data: string) => setLocgovRegistAt2(data)}
                        />
                    </td>
                </tr>
                <tr className={"h106 " + (beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" ? "" : "dpNone")}>
                    <th className="taC" title="지자체등록일">지자체등록일</th>
                    <td>
                        <DatePickerBox value={locgovRegistDe2} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setLocgovRegistDe2(Util.dateToString(data, ""))} />
                        <div className="mt10">
                            <ImageCheckBox
                                value={!locgovRqstdeDsb2}
                                labelText='등록일이 신청일과 다른경우'
                                btnClass='w20 h20'
                                labelClass='SpoqaHanSansNeo-Regular fs14 ls07 fc666666 pl5'
                                onChange={() => setLocgovRqstdeDsb2(!locgovRqstdeDsb2)}
                            />
                        </div>
                    </td>
                </tr>
                <tr className={"h80 " + (!locgovRqstdeDsb2 && (beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y") ? "" : "dpNone")}>
                    <th className="taC" title="지자체신청일">지자체신청일</th>
                    <td>
                        <DatePickerBox value={locgovRqstde2} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setLocgovRqstde2(Util.dateToString(data, ""))} disabled={locgovRqstdeDsb2} />
                    </td>
                </tr>
                <tr className={"h64 " + (beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" ? "" : "dpNone")}>
                    <th className="taC" title='임대유형'>임대유형<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                    <td>
                        <ImageRadioBox
                            codeList={Util.getCodeArray("1013")}
                            value={rentTy2}
                            btnClass="w24 h24 pr5"
                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                            interval={20}
                            onChange={(data: string) => setRentTy2(data)}
                        />
                    </td>
                </tr>
                <tr className={"h80 " + (beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" && rentTy2 === "1003" ? "" : "dpNone")}>
                    <th className="taC" title="장기등록전환신청일">장기등록전환신청일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                    <td><DatePickerBox value={rentTyCnvrsDe2} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setRentTyCnvrsDe2(Util.dateToString(data, ""))} /></td>
                </tr>
                <tr className={"h64 " + (beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" ? "" : "dpNone")}>
                    <th className="taC" title='임대주택 종류'>임대주택 종류<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                    <td>
                        <ImageRadioBox
                            codeList={Util.getCodeArray("1012")}
                            value={estateRentTy2}
                            btnClass="w24 h24 pr5"
                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                            interval={20}
                            onChange={(data: string) => setEstateRentTy2(data)}
                        />
                    </td>
                </tr>
                <tr className={"h64 " + (beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" ? "" : "dpNone")}>
                    <th className="taC" title='말소여부'>말소여부</th>
                    <td>
                        <ImageRadioBox
                            codeList={Util.getCodeArray("1042")}
                            value={ersrAt2}
                            btnClass="w24 h24 pr5"
                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                            interval={20}
                            onChange={(data: string) => setErsrAt2(data)}
                        />
                    </td>
                </tr>
                <tr className={"h64 " + (beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" && ersrAt2 === "Y" ? "" : "dpNone")}>
                    <th className="taC" title='말소종류'>말소종류</th>
                    <td>
                        <ImageRadioBox
                            codeList={Util.getCodeArray("1028")}
                            value={ersrKnd2}
                            btnClass="w24 h24 pr5"
                            labelClass="SpoqaHanSansNeo-Regular fs13 ls015 fc666666 lh20"
                            singleWidth={150}
                            interval={5}
                            onChange={(data: string) => setErsrKnd2(data)}
                        />
                    </td>
                </tr>
                <tr className={"h80 " + (beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && locgovRegistAt2 === "Y" && ersrAt2 === "Y" ? "" : "dpNone")}>
                    <th className="taC" title="말소일">말소일</th>
                    <td><DatePickerBox value={ersrDe2} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setErsrDe2(Util.dateToString(data, ""))} /></td>
                </tr>
                <tr className={"h64 " + (beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") ? "" : "dpNone")}>
                    <th className="taC" title='세무서 등록여부'>세무서 등록여부</th>
                    <td>
                        <ImageRadioBox
                            codeList={Util.getCodeArray("1044")}
                            value={taxofcRegistAt2}
                            btnClass="w24 h24 pr5"
                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                            interval={20}
                            onChange={(data: string) => setTaxofcRegistAt2(data)}
                        />
                    </td>
                </tr>
                <tr className={"h106 " + (beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && taxofcRegistAt2 === "Y" ? "" : "dpNone")}>
                    <th className="taC" title="세무서등록일">세무서등록일</th>
                    <td>
                        <DatePickerBox value={taxofcRegistDe2} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setTaxofcRegistDe2(Util.dateToString(data, ""))} />
                        <div className="mt10">
                            <ImageCheckBox
                                value={!taxofcRqstdeDsb2}
                                labelText='등록일이 신청일과 다른경우'
                                btnClass='w20 h20'
                                labelClass='SpoqaHanSansNeo-Regular fs14 ls07 fc666666 pl5'
                                onChange={() => setTaxofcRqstdeDsb2(!taxofcRqstdeDsb2)}
                            />
                        </div>
                    </td>
                </tr>
                <tr className={"h80 " + (!taxofcRqstdeDsb2 && (beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && taxofcRegistAt2 === "Y") ? "" : "dpNone")}>
                    <th className="taC" title="세무서신청일">세무서신청일</th>
                    <td>
                        <DatePickerBox value={taxofcRqstde2} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setTaxofcRqstde2(Util.dateToString(data, ""))} disabled={taxofcRqstdeDsb2} />
                    </td>
                </tr>
                <tr className={"h64 " + (beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && taxofcRegistAt2 === "Y" ? "" : "dpNone")}>
                    <th className="taC" title='폐업여부'>폐업여부</th>
                    <td>
                        <ImageRadioBox
                            codeList={Util.getCodeArray("1042")}
                            value={bizqitAt2}
                            btnClass="w24 h24 pr5"
                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                            interval={20}
                            onChange={(data: string) => setBizqitAt2(data)}
                        />
                    </td>
                </tr>
                <tr className={"h80 " + (beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") && taxofcRegistAt2 === "Y" && bizqitAt2 === "Y" ? "" : "dpNone")}>
                    <th className="taC" title="폐업일자">폐업일자</th>
                    <td>
                        <DatePickerBox value={bizqitDe2} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setBizqitDe2(Util.dateToString(data, ""))} />
                    </td>
                </tr>
                <tr className={"h80 " + (beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") ? "" : "dpNone")}>
                    <th className="taC" title="임대개시일">임대개시일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                    <td>
                        <DatePickerBox value={rentBgnde2} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setRentBgnde2(Util.dateToString(data, ""))} />
                    </td>
                </tr>
                <tr className={"h80 " + (beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") ? "" : "dpNone")}>
                    <th className="taC" title='임대개시일기준시가'>임대개시일기준시가<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                    <td>{StdrMktcEl2()}</td>
                </tr>
                <tr className={"h64 " + (beforeEstatePrpos === "1002" && (acqsCause === "1001" || acqsCause === "1003") ? "" : "dpNone")}>
                    <th className="taC" title='5% 상한 이행여부'>5% 상한 이행여부</th>
                    <td>
                        <ImageRadioBox
                            codeList={Util.getCodeArray("1042")}
                            value={rntchrgUplmtFlflAt2}
                            btnClass="w24 h24 pr5"
                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                            interval={20}
                            onChange={(data: string) => setRntchrgUplmtFlflAt2(data)}
                        />
                    </td>
                </tr>
                <tr className="h80">
                    <th className="taC" title="전입일">전입일</th>
                    <td>{TrnsfrnDe2El()}</td>
                </tr>
                <tr className="h80">
                    <th className="taC" title="전출일">전출일</th>
                    <td>{LvhsDe2El()}</td>
                </tr>
                </tbody>
            </table>
        </React.Fragment>

    return (
        <React.Fragment>
            <AssetsEditTab
                wizard={props.wizard}
                assetsSn={props.form.assetsSn}
                estateSttus={props.form.estateSttus}
                estateDetailSe={estateDetailSe}
                acqsCause={acqsCause}
                acqsSrc={acqsSrc}
                currStepCode={1003}
            />
            <div className='w1210 mh500'>
                <AssetsTitle propsForm={props.form} asterlink={true} />
                {rarFormSeq === 1 ?
                    <table className="forest-form-table">
                        <colgroup>
                            <col className="w250" />
                            <col />
                        </colgroup>
                        <tbody>
                        <tr className="h80">
                            <th className="taC" title="전입일">전입일</th>
                            <td>{TrnsfrnDeEl()}</td>
                        </tr>
                        <tr className="h80">
                            <th className="taC" title="전출일">전출일</th>
                            <td>{LvhsDeEl()}</td>
                        </tr>
                        </tbody>
                    </table>
                : <></>}
                {rarFormSeq === 2 ?
                    <div className="dpFlx jcSb">
                        <div className="w49p">
                            <div className='dpFlx jcL aiC mb20'>
                                <span className='SpoqaHanSansNeo-Bold fs15 ls075 fc222222'>1. {titleText1}</span>
                            </div>
                            <table className="forest-form-table tlF">
                                <colgroup>
                                    <col className="w250" />
                                    <col />
                                </colgroup>
                                <tbody>
                                <tr className="h80">
                                    <th className="taC" title="전입일">전입일</th>
                                    <td>{TrnsfrnDeEl()}</td>
                                </tr>
                                <tr className="h80">
                                    <th className="taC" title="전출일">전출일</th>
                                    <td>{LvhsDeEl()}</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        <div className="w49p">
                            <div className='dpFlx jcL aiC mb20'>
                                <span className='SpoqaHanSansNeo-Bold fs15 ls075 fc222222'>2. {titleText2}</span>
                            </div>
                            <table className="forest-form-table tlF">
                                <colgroup>
                                    <col className="w250" />
                                    <col />
                                </colgroup>
                                <tbody>
                                <tr className="h80">
                                    <th className="taC" title="전입일">전입일</th>
                                    <td>{TrnsfrnDe2El()}</td>
                                </tr>
                                <tr className="h80">
                                    <th className="taC" title="전출일">전출일</th>
                                    <td>{LvhsDe2El()}</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                : <></>}
                {rarFormSeq === 3 ?
                    <table className="forest-form-table">
                        <colgroup>
                            <col className="w250" />
                            <col />
                        </colgroup>
                        <tbody>
                        <tr className="h64">
                            <th className="taC" title='지자체 등록여부'>지자체 등록여부</th>
                            <td>
                                <ImageRadioBox
                                    codeList={Util.getCodeArray("1057")}
                                    value={locgovRegistAt}
                                    btnClass="w24 h24 pr5"
                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                    interval={20}
                                    onChange={(data: string) => setLocgovRegistAt(data)}
                                />
                            </td>
                        </tr>
                        <tr className={"h106 " + (locgovRegistAt === "Y" ? "" : "dpNone")}>
                            <th className="taC" title="지자체등록일">지자체등록일</th>
                            <td>
                                <DatePickerBox value={locgovRegistDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setLocgovRegistDe(Util.dateToString(data, ""))} />
                                <div className="mt10">
                                    <ImageCheckBox
                                        value={!locgovRqstdeDsb}
                                        labelText='등록일이 신청일과 다른경우'
                                        btnClass='w20 h20'
                                        labelClass='SpoqaHanSansNeo-Regular fs14 ls07 fc666666 pl5'
                                        onChange={() => setLocgovRqstdeDsb(!locgovRqstdeDsb)}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr className={"h80 " + (!locgovRqstdeDsb && (locgovRegistAt === "Y") ? "" : "dpNone")}>
                            <th className="taC" title="지자체신청일">지자체신청일</th>
                            <td>
                                <DatePickerBox value={locgovRqstde} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setLocgovRqstde(Util.dateToString(data, ""))} disabled={locgovRqstdeDsb} />
                            </td>
                        </tr>
                        <tr className={"h64 " + (locgovRegistAt === "Y" ? "" : "dpNone")}>
                            <th className="taC" title='임대유형'>임대유형<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                            <td>
                                <ImageRadioBox
                                    codeList={Util.getCodeArray("1013")}
                                    value={rentTy}
                                    btnClass="w24 h24 pr5"
                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                    interval={20}
                                    onChange={(data: string) => setRentTy(data)}
                                />
                            </td>
                        </tr>
                        <tr className={"h80 " + (locgovRegistAt === "Y" && rentTy === "1003" ? "" : "dpNone")}>
                            <th className="taC" title="장기등록 전환신청일">장기등록 전환신청일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                            <td><DatePickerBox value={rentTyCnvrsDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setRentTyCnvrsDe(Util.dateToString(data, ""))} /></td>
                        </tr>
                        <tr className={"h64 " + (locgovRegistAt === "Y" ? "" : "dpNone")}>
                            <th className="taC" title='임대주택 종류'>임대주택 종류<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                            <td>
                                <ImageRadioBox
                                    codeList={Util.getCodeArray("1012")}
                                    value={estateRentTy}
                                    btnClass="w24 h24 pr5"
                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                    interval={20}
                                    onChange={(data: string) => setEstateRentTy(data)}
                                />
                            </td>
                        </tr>
                        <tr className={"h64 " + (locgovRegistAt === "Y" ? "" : "dpNone")}>
                            <th className="taC" title='말소여부'>말소여부</th>
                            <td>
                                <ImageRadioBox
                                    codeList={Util.getCodeArray("1042")}
                                    value={ersrAt}
                                    btnClass="w24 h24 pr5"
                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                    interval={20}
                                    onChange={(data: string) => setErsrAt(data)}
                                />
                            </td>
                        </tr>
                        <tr className={"h64 " + (locgovRegistAt === "Y" && ersrAt === "Y" ? "" : "dpNone")}>
                            <th className="taC" title='말소종류'>말소종류</th>
                            <td>
                                <ImageRadioBox
                                    codeList={Util.getCodeArray("1028")}
                                    value={ersrKnd}
                                    btnClass="w24 h24 pr5"
                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                    interval={20}
                                    onChange={(data: string) => setErsrKnd(data)}
                                />
                            </td>
                        </tr>
                        <tr className={"h80 " + (locgovRegistAt === "Y" && ersrAt === "Y" ? "" : "dpNone")}>
                            <th className="taC" title="말소일">말소일</th>
                            <td><DatePickerBox value={ersrDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setErsrDe(Util.dateToString(data, ""))} /></td>
                        </tr>
                        <tr className="h80">
                            <th className="taC" title='세무서 등록여부'>세무서 등록여부</th>
                            <td>
                                <ImageRadioBox
                                    codeList={Util.getCodeArray("1044")}
                                    value={taxofcRegistAt}
                                    btnClass="w24 h24 pr5"
                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                    interval={20}
                                    onChange={(data: string) => setTaxofcRegistAt(data)}
                                />
                            </td>
                        </tr>
                        <tr className={"h106 " + (taxofcRegistAt === "Y" ? "" : "dpNone")}>
                            <th className="taC" title="세무서등록일">세무서등록일</th>
                            <td>
                                <DatePickerBox value={taxofcRegistDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setTaxofcRegistDe(Util.dateToString(data, ""))} />
                                <div className="mt10">
                                    <ImageCheckBox
                                        value={!taxofcRqstdeDsb}
                                        labelText='등록일이 신청일과 다른경우'
                                        btnClass='w20 h20'
                                        labelClass='SpoqaHanSansNeo-Regular fs14 ls07 fc666666 pl5'
                                        onChange={() => setTaxofcRqstdeDsb(!taxofcRqstdeDsb)}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr className={"h80 " + (!taxofcRqstdeDsb && taxofcRegistAt === "Y" ? "" : "dpNone")}>
                            <th className="taC" title="세무서신청일">세무서신청일</th>
                            <td>
                                <DatePickerBox value={taxofcRqstde} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setTaxofcRqstde(Util.dateToString(data, ""))} disabled={taxofcRqstdeDsb} />
                            </td>
                        </tr>
                        <tr className={"h64 " + (taxofcRegistAt === "Y" ? "" : "dpNone")}>
                            <th className="taC" title='폐업여부'>폐업여부</th>
                            <td>
                                <ImageRadioBox
                                    codeList={Util.getCodeArray("1042")}
                                    value={bizqitAt}
                                    btnClass="w24 h24 pr5"
                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                    interval={20}
                                    onChange={(data: string) => setBizqitAt(data)}
                                />
                            </td>
                        </tr>
                        <tr className={"h80 " + (taxofcRegistAt === "Y" && bizqitAt === "Y" ? "" : "dpNone")}>
                            <th className="taC" title="폐업일자">폐업일자</th>
                            <td><DatePickerBox value={bizqitDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setBizqitDe(Util.dateToString(data, ""))} /></td>
                        </tr>
                        <tr className="h80">
                            <th className="taC" title="임대개시일">임대개시일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                            <td><DatePickerBox value={rentBgnde} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setRentBgnde(Util.dateToString(data, ""))} /></td>
                        </tr>
                        <tr className="h80">
                            <th className="taC" title='임대개시일 기준시가'>임대개시일 기준시가(원)<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                            <td>{StdrMktcEl()}</td>
                        </tr>
                        <tr className="h64">
                            <th className="taC" title='5% 상한 이행여부'>5% 상한 이행여부</th>
                            <td>
                                <ImageRadioBox
                                    codeList={Util.getCodeArray("1042")}
                                    value={rntchrgUplmtFlflAt}
                                    btnClass="w24 h24 pr5"
                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                    interval={20}
                                    onChange={(data: string) => setRntchrgUplmtFlflAt(data)}
                                />
                            </td>
                        </tr>
                        <tr className="h80">
                            <th className="taC" title="전입일">
                                <div className="dpFlx aiC jcC">
                                    <img
                                        src={require('img/common/icon_info_fill_20.png')}
                                        alt="icon_info_fill_20"
                                        className='mr3 csP'
                                        data-tooltip-id="moveInDateToolTip"
                                        data-tooltip-content={
                                            "본인(임대인)세대의 전입일. 임차인의 전입일이 아님."
                                        }
                                    />
                                    <ToolTip id="moveInDateToolTip" place='top' clickable={true} className="w300 taL lh20" />
                                    전입일
                                </div>
                            </th>
                            <td><DatePickerBox value={trnsfrnDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setTrnsfrnDe(Util.dateToString(data, ""))} /></td>
                        </tr>
                        <tr className="h80">
                            <th className="taC" title="전출일">
                                <div className="dpFlx aiC jcC">
                                    <img
                                        src={require('img/common/icon_info_fill_20.png')}
                                        alt="icon_info_fill_20"
                                        className='mr3 csP'
                                        data-tooltip-id="moveOutDateToolTip"
                                        data-tooltip-content={
                                            "본인(임대인)세대의 전출일. 임차인의 전출일이 아님."
                                        }
                                    />
                                    <ToolTip id="moveOutDateToolTip" place='top' clickable={true} className="w300 taL lh20" />
                                    전출일
                                </div>
                            </th>
                            <td><DatePickerBox value={lvhsDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setLvhsDe(Util.dateToString(data, ""))} /></td>
                        </tr>
                        </tbody>
                    </table>
                : <></>}
                {rarFormSeq === 4 ?
                    <div className="dpFlx jcSb">
                        <div className="w49p">
                            <div className='dpFlx jcL aiC mb20'>
                                <span className='SpoqaHanSansNeo-Bold fs15 ls075 fc222222'>
                                    1.&nbsp;
                                    {acqsCause === "1001" ? "신규주택" : ""}
                                    {acqsCause === "1003" ? "상속인" : ""}
                                    {acqsCause === "1004" ? "수증자" : ""}
                                </span>
                            </div>
                            <table className="forest-form-table tlF">
                                <colgroup>
                                    <col className="w180" />
                                    <col />
                                </colgroup>
                                <tbody>
                                <tr className="h64">
                                    <th className="taC" title='지자체 등록여부'>지자체 등록여부</th>
                                    <td>
                                        <ImageRadioBox
                                            codeList={Util.getCodeArray("1057")}
                                            value={locgovRegistAt}
                                            btnClass="w24 h24 pr5"
                                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                            interval={20}
                                            onChange={(data: string) => setLocgovRegistAt(data)}
                                        />
                                    </td>
                                </tr>
                                <tr className={"h106 " + (locgovRegistAt === "Y" ? "" : "dpNone")}>
                                    <th className="taC" title="지자체등록일">지자체등록일</th>
                                    <td>
                                        <DatePickerBox value={locgovRegistDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setLocgovRegistDe(Util.dateToString(data, ""))} />
                                        <div className="mt10">
                                            <ImageCheckBox
                                                value={!locgovRqstdeDsb}
                                                labelText='등록일이 신청일과 다른경우'
                                                btnClass='w20 h20'
                                                labelClass='SpoqaHanSansNeo-Regular fs14 ls07 fc666666 pl5'
                                                onChange={() => setLocgovRqstdeDsb(!locgovRqstdeDsb)}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr className={"h80 " + (!locgovRqstdeDsb && locgovRegistAt === "Y" ? "" : "dpNone")}>
                                    <th className="taC" title="지자체신청일">지자체신청일</th>
                                    <td>
                                        <DatePickerBox value={locgovRqstde} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setLocgovRqstde(Util.dateToString(data, ""))} disabled={locgovRqstdeDsb} />
                                    </td>
                                </tr>
                                <tr className={"h64 " + (locgovRegistAt === "Y" ? "" : "dpNone")}>
                                    <th className="taC" title='임대유형'>임대유형<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                    <td>
                                        <ImageRadioBox
                                            codeList={Util.getCodeArray("1013")}
                                            value={rentTy}
                                            btnClass="w24 h24 pr5"
                                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                            interval={20}
                                            onChange={(data: string) => setRentTy(data)}
                                        />
                                    </td>
                                </tr>
                                <tr className={"h80 " + (locgovRegistAt === "Y" && rentTy === "1003" ? "" : "dpNone")}>
                                    <th className="taC" title="장기등록전환신청일">장기등록전환신청일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                    <td>
                                        <DatePickerBox value={rentTyCnvrsDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setRentTyCnvrsDe(Util.dateToString(data, ""))} />
                                    </td>
                                </tr>
                                <tr className={"h64 " + (locgovRegistAt === "Y" ? "" : "dpNone")}>
                                    <th className="taC" title='임대주택 종류'>임대주택 종류<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                    <td>
                                        <ImageRadioBox
                                            codeList={Util.getCodeArray("1012")}
                                            value={estateRentTy}
                                            btnClass="w24 h24 pr5"
                                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                            interval={20}
                                            onChange={(data: string) => setEstateRentTy(data)}
                                        />
                                    </td>
                                </tr>
                                <tr className={"h64 " + (locgovRegistAt === "Y" ? "" : "dpNone")}>
                                    <th className="taC" title='말소여부'>말소여부</th>
                                    <td>
                                        <ImageRadioBox
                                            codeList={Util.getCodeArray("1042")}
                                            value={ersrAt}
                                            btnClass="w24 h24 pr5"
                                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                            interval={20}
                                            onChange={(data: string) => setErsrAt(data)}
                                        />
                                    </td>
                                </tr>
                                <tr className={"h64 " + (locgovRegistAt === "Y" && ersrAt === "Y" ? "" : "dpNone")}>
                                    <th className="taC" title='말소종류'>말소종류</th>
                                    <td>
                                        <ImageRadioBox
                                            codeList={Util.getCodeArray("1028")}
                                            value={ersrKnd}
                                            btnClass="w24 h24 pr5"
                                            labelClass="SpoqaHanSansNeo-Regular fs13 ls015 fc666666 lh20"
                                            singleWidth={150}
                                            interval={5}
                                            onChange={(data: string) => setErsrKnd(data)}
                                        />
                                    </td>
                                </tr>
                                <tr className={"h80 " + (locgovRegistAt === "Y" && ersrAt === "Y" ? "" : "dpNone")}>
                                    <th className="taC" title="말소일">말소일</th>
                                    <td><DatePickerBox value={ersrDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setErsrDe(Util.dateToString(data, ""))} /></td>
                                </tr>
                                <tr className="h64">
                                    <th className="taC" title='세무서 등록여부'>세무서 등록여부</th>
                                    <td>
                                        <ImageRadioBox
                                            codeList={Util.getCodeArray("1044")}
                                            value={taxofcRegistAt}
                                            btnClass="w24 h24 pr5"
                                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                            interval={20}
                                            onChange={(data: string) => setTaxofcRegistAt(data)}
                                        />
                                    </td>
                                </tr>
                                <tr className={"h106 " + (taxofcRegistAt === "Y" ? "" : "dpNone")}>
                                    <th className="taC" title="세무서등록일">세무서등록일</th>
                                    <td>
                                        <DatePickerBox value={taxofcRegistDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setTaxofcRegistDe(Util.dateToString(data, ""))} />
                                        <div className="mt10">
                                            <ImageCheckBox
                                                value={!taxofcRqstdeDsb}
                                                labelText='등록일이 신청일과 다른경우'
                                                btnClass='w20 h20'
                                                labelClass='SpoqaHanSansNeo-Regular fs14 ls07 fc666666 pl5'
                                                onChange={() => setTaxofcRqstdeDsb(!taxofcRqstdeDsb)}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr className={"h80 " + (!taxofcRqstdeDsb && taxofcRegistAt === "Y" ? "" : "dpNone")}>
                                    <th className="taC" title="세무서신청일">세무서신청일</th>
                                    <td>
                                        <DatePickerBox value={taxofcRqstde} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setTaxofcRqstde(Util.dateToString(data, ""))} disabled={taxofcRqstdeDsb} />
                                    </td>
                                </tr>
                                <tr className={"h64 " + (taxofcRegistAt === "Y" ? "" : "dpNone")}>
                                    <th className="taC" title='폐업여부'>폐업여부</th>
                                    <td>
                                        <ImageRadioBox
                                            codeList={Util.getCodeArray("1042")}
                                            value={bizqitAt}
                                            btnClass="w24 h24 pr5"
                                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                            interval={20}
                                            onChange={(data: string) => setBizqitAt(data)}
                                        />
                                    </td>
                                </tr>
                                <tr className={"h80 " + (taxofcRegistAt === "Y" && bizqitAt === "Y" ? "" : "dpNone")}>
                                    <th className="taC" title="폐업일자">폐업일자</th>
                                    <td>
                                        <DatePickerBox value={bizqitDe} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setBizqitDe(Util.dateToString(data, ""))} />
                                    </td>
                                </tr>
                                <tr className="h80">
                                    <th className="taC" title="임대개시일">임대개시일<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                    <td>
                                        <DatePickerBox value={rentBgnde} width={300} height={40} fontSize={15} bgColor="#fff" textAlign="left" onChange={(data: string) => setRentBgnde(Util.dateToString(data, ""))} />
                                    </td>
                                </tr>
                                <tr className="h140">
                                    <th className="taC" title='임대개시일기준시가'>임대개시일기준시가<span className="SpoqaHanSansNeo-Bold fcFF0000 pl2">*</span></th>
                                    <td>{StdrMktcEl()}</td>
                                </tr>
                                <tr className="h64">
                                    <th className="taC" title='5% 상한 이행여부'>5% 상한 이행여부</th>
                                    <td>
                                        <ImageRadioBox
                                            codeList={Util.getCodeArray("1042")}
                                            value={rntchrgUplmtFlflAt}
                                            btnClass="w24 h24 pr5"
                                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                            interval={20}
                                            onChange={(data: string) => setRntchrgUplmtFlflAt(data)}
                                        />
                                    </td>
                                </tr>
                                <tr className="h80">
                                    <th className="taC" title="전입일">
                                        <div className="dpFlx aiC jcC">
                                            <img
                                                src={require('img/common/icon_info_fill_20.png')}
                                                alt="icon_info_fill_20"
                                                className='mr3 csP'
                                                data-tooltip-id="moveInDateToolTip"
                                                data-tooltip-content={
                                                    "본인(임대인)세대의 전입일. 임차인의 전입일이 아님."
                                                }
                                            />
                                            <ToolTip id="moveInDateToolTip" place='top' clickable={true} className="w300 taL lh20" />
                                            전입일
                                        </div>
                                    </th>
                                    <td>{TrnsfrnDeEl()}</td>
                                </tr>
                                <tr className="h80">
                                    <th className="taC" title="전출일">
                                        <div className="dpFlx aiC jcC">
                                            <img
                                                src={require('img/common/icon_info_fill_20.png')}
                                                alt="icon_info_fill_20"
                                                className='mr3 csP'
                                                data-tooltip-id="moveOutDateToolTip"
                                                data-tooltip-content={
                                                    "본인(임대인)세대의 전출일. 임차인의 전출일이 아님."
                                                }
                                            />
                                            <ToolTip id="moveOutDateToolTip" place='top' clickable={true} className="w300 taL lh20" />
                                            전출일
                                        </div>
                                    </th>
                                    <td>{LvhsDeEl()}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="w49p">
                            <div className='dpFlx jcL aiC mb20'>
                                <span className='SpoqaHanSansNeo-Bold fs15 ls075 fc222222'>2. {titleText2}</span>
                            </div>
                            <table className="forest-form-table tlF">
                                <colgroup>
                                    <col className="w250" />
                                    <col />
                                </colgroup>
                                <tbody>
                                <tr className="h80">
                                    <th className="taC" title="전입일">
                                        <div className="dpFlx aiC jcC">
                                            <img
                                                src={require('img/common/icon_info_fill_20.png')}
                                                alt="icon_info_fill_20"
                                                className='mr3 csP'
                                                data-tooltip-id="moveInDateToolTip"
                                                data-tooltip-content={
                                                    "본인(임대인)세대의 전입일. 임차인의 전입일이 아님."
                                                }
                                            />
                                            <ToolTip id="moveInDateToolTip" place='top' clickable={true} className="w300 taL lh20" />
                                            전입일
                                        </div>
                                    </th>
                                    <td>{TrnsfrnDe2El()}</td>
                                </tr>
                                <tr className="h80">
                                    <th className="taC" title="전출일">
                                        <div className="dpFlx aiC jcC">
                                            <img
                                                src={require('img/common/icon_info_fill_20.png')}
                                                alt="icon_info_fill_20"
                                                className='mr3 csP'
                                                data-tooltip-id="moveOutDateToolTip"
                                                data-tooltip-content={
                                                    "본인(임대인)세대의 전출일. 임차인의 전출일이 아님."
                                                }
                                            />
                                            <ToolTip id="moveOutDateToolTip" place='top' clickable={true} className="w300 taL lh20" />
                                            전출일
                                        </div>
                                    </th>
                                    <td>{LvhsDe2El()}</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                : <></>}
                {rarFormSeq === 5 ?
                    <div className="dpFlx jcSb">
                        <div className="w49p">
                            <div className='dpFlx jcL aiC mb20'>
                                <span className='SpoqaHanSansNeo-Bold fs15 ls075 fc222222'>1. 상속인</span>
                            </div>
                            <table className="forest-form-table tlF">
                                <colgroup>
                                    <col className="w180" />
                                    <col />
                                </colgroup>
                                <tbody>
                                <tr className="h80">
                                    <th className="taC" title="전입일">전입일</th>
                                    <td>{TrnsfrnDeEl()}</td>
                                </tr>
                                <tr className="h80">
                                    <th className="taC" title="전출일">전출일</th>
                                    <td>{LvhsDeEl()}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="w49p">{RentTable2()}</div>
                    </div>
                : <></>}
                <div className={'w1210 ' + (estateDetailSe === "1001" ? "" : "dpNone")}>
                    <div className='dpFlx jcL aiC mb20 mt30'>
                        <span className='SpoqaHanSansNeo-Bold fs15 ls075 fc222222'>
                            1세대 1주택 기타정보
                        </span>
                    </div>
                    <table className="forest-form-table">
                        <colgroup>
                            <col className="w250" />
                            <col />
                        </colgroup>
                        <tbody>
                        <tr className="h64">
                            <th className="taC">1세대 1주택 정보</th>
                            <td>
                                <div className="dpFlx aiC">
                                    <ImageRadioBox
                                        codeList={Util.getCodeArray("1067")}
                                        value={excptHouseInfoVsbYn}
                                        btnClass="w24 h24 pr5"
                                        labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                        interval={20}
                                        onChange={(item: string) => setExcptHouseInfoVsbYn(item)}
                                    />
                                </div>
                            </td>
                        </tr>
                        {excptHouseInfoVsbYn === "Y" ?
                            <React.Fragment>
                                <tr className="h64">
                                    <th className="taC">
                                        <div className="dpFlx aiC jcC">
                                            <img
                                                src={require('img/common/icon_info_fill_20.png')}
                                                alt="icon_info_fill_20"
                                                className='mr3 csP'
                                                data-tooltip-id="winWinRentalHousingToolTip"
                                                data-tooltip-content={
                                                    "상생임대주택(소득세법시행령 제155조의 3\n\n"
                                                + "국내에 1주택(제155조, 재155조의 2, 제156조의 2, 제156조의 3 및 그 밖의 법령에 따라 1세대 1주택으로 보는 경우를 포함한다)을\n"
                                                + "소유한 1세대가 다음의 요건을 모두 갖춘 주택을 양도하는 경우에는 제154조 제1항, 제155조 제20항 제1호 및 제159조의 4를 적용할 때\n"
                                                + "해당 규정에 따른 거주기간의 제한을 받지 않는다.\n\n"
                                                + "① 주택을 취득한 후 해당 주택에 대해 임차인과 임대차계약(직전 임대차 계약)을 체결할 것\n"
                                                + "② 해당 주택의 취득으로 임대인의 지위가 승계된 경우의 임대차계약은 제외한다.\n"
                                                + "③ 직전 임대차계약에 따라 임대한 기간이 1년 6개월 이상일 것\n"
                                                + "④ 상생임대차계약(직전 임대차계약 대비 임대보증금 또는 임대료의 증가율이 5%를 초과하지 않는 임대차계약)을 2021.12.20.~2024.12.31.\n"
                                                + "    기간 중 쳬결하고 임대를 개시할 것\n"
                                                + "⑤ 상생임대차계약에 따라 임대한 기간이 2년 이상일 것\n"
                                                + "⑥ 임대기간을 계산할 때 임차인의 사정으로 임대를 계속할 수 없어 새로운 임대차계약을 체결하는 경우로서 기획재정부령으로 정하는\n"
                                                + "    요건을 충족하는 경우에는 새로운 임대차계약의 임대기간을 합산하여 계산한다."
                                                }
                                            />
                                        <ToolTip id="winWinRentalHousingToolTip" place='top' clickable={true} className="w300 taL lh20" />
                                        상생임대주택
                                        </div>
                                    </th>
                                    <td>
                                        <div className="dpFlx aiC">
                                            <ImageRadioBox
                                                codeList={Util.getCodeArray("1042")}
                                                value={wwinHouseAt}
                                                btnClass="w24 h24 pr5"
                                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                                interval={20}
                                                onChange={(item: string) => {
                                                    setWwinHouseAt(item);
                                                    if (item === "N") {
                                                        return;
                                                    }
                                                    AppContext.openAlert({
                                                        title: "상생임대주택 여부",
                                                        msg: "상생임대주택입니까?",
                                                        icon: "check",
                                                        confirmText: "예",
                                                        closeText: "아니오",
                                                        handleClose: () => {
                                                            setWwinHouseAt("N");
                                                        }
                                                    });
                                                }}
                                            />
                                            <span className="SpoqaHanSansNeo-Regular fs16 fc999999 pl10">(소득세법시행령 제155조의 3)</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="h64">
                                    <th className="taC">부득이한 사유로 취득한 주택</th>
                                    <td>
                                        <div className="dpFlx aiC">
                                            <ImageRadioBox
                                                codeList={Util.getCodeArray("1042")}
                                                value={acqsHouseAt}
                                                btnClass="w24 h24 pr5"
                                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                                interval={20}
                                                onChange={(item: string) => {
                                                    setAcqsHouseAt(item);
                                                    if (item === "N") {
                                                        return;
                                                    }
                                                    setQestn10Open(true);
                                                }}
                                            />
                                            <Modal modalOpen={qestn10Open} setModalOpen={setQestn10Open} title="부득이한 사유로 취득한 주택 여부" dim={true} visible={true} closeCallBack={() => {
                                                if (qestn10AnswerText === "N" || (qestn10AnswerText === "Y" && Util.isEmpty(qestn12AnswerText))) {
                                                    setAcqsHouseAt("N");
                                                }
                                            }}>
                                            <Qestn10Modal
                                                setQestn10Open={setQestn10Open}
                                                setAcqsHouseAt={setAcqsHouseAt}
                                                qestn10AnswerText={qestn10AnswerText}
                                                setQestn10AnswerText={setQestn10AnswerText}
                                                qestn12AnswerText={qestn12AnswerText}
                                                setQestn12AnswerText={setQestn12AnswerText}
                                            />
                                            </Modal>
                                            <span className="SpoqaHanSansNeo-Regular fs16 fc999999 pl10">(소득세법시행령 제155조 제8항)</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="h64">
                                    <th className="taC">농어촌 주택</th>
                                    <td>
                                        <div className="dpFlx aiC">
                                            <ImageRadioBox
                                                codeList={Util.getCodeArray("1042")}
                                                value={frhlHouseAt}
                                                btnClass="w24 h24 pr5"
                                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                                interval={20}
                                                onChange={(item: string) => {
                                                    setFrhlHouseAt(item);
                                                    if (item === "N") {
                                                        return;
                                                    }
                                                    setQestn13Open(true);
                                                }}
                                            />
                                            <Modal modalOpen={qestn13Open} setModalOpen={setQestn13Open} title="농어촌 주택 여부" dim={true} visible={true} closeCallBack={() => {
                                                if ([qestn62AnswerText, qestn63AnswerText, qestn64AnswerText, qestn65AnswerText, qestn66AnswerText].indexOf("Y") === -1) {
                                                    setFrhlHouseAt("N");
                                                }
                                            }}>
                                            <Qestn13Modal
                                                setQestn13Open={setQestn13Open}
                                                setFrhlHouseAt={setFrhlHouseAt}
                                                qestn62AnswerText={qestn62AnswerText}
                                                setQestn62AnswerText={setQestn62AnswerText}
                                                qestn63AnswerText={qestn63AnswerText}
                                                setQestn63AnswerText={setQestn63AnswerText}
                                                qestn64AnswerText={qestn64AnswerText}
                                                setQestn64AnswerText={setQestn64AnswerText}
                                                qestn65AnswerText={qestn65AnswerText}
                                                setQestn65AnswerText={setQestn65AnswerText}
                                                qestn66AnswerText={qestn66AnswerText}
                                                setQestn66AnswerText={setQestn66AnswerText}
                                            />
                                            </Modal>
                                            <span className="SpoqaHanSansNeo-Regular fs16 fc999999 pl10">(소득세법시행령 제155조 제7항, 조세특례제한법 제99조의 4)</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="h64">
                                    <th className="taC">문화재 주택</th>
                                    <td>
                                        <div className="dpFlx aiC">
                                            <ImageRadioBox
                                                codeList={Util.getCodeArray("1042")}
                                                value={crltsHouseAt}
                                                btnClass="w24 h24 pr5"
                                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                                interval={20}
                                                onChange={(item: string) => {
                                                    setCrltsHouseAt(item);
                                                    if (item === "N") {
                                                        return;
                                                    }
                                                    setQestn18Open(true);
                                                }}
                                            />
                                            <Modal modalOpen={qestn18Open} setModalOpen={setQestn18Open} title="문화재 주택 여부" dim={true} visible={true} closeCallBack={() => {
                                                if (qestn18AnswerText === "N") {
                                                    setCrltsHouseAt("N");
                                                }
                                            }}>
                                            <Qestn18Modal
                                                setQestn18Open={setQestn18Open}
                                                setCrltsHouseAt={setCrltsHouseAt}
                                                qestn18AnswerText={qestn18AnswerText}
                                                setQestn18AnswerText={setQestn18AnswerText}
                                                qestn23AnswerText={qestn23AnswerText}
                                                setQestn23AnswerText={setQestn23AnswerText}
                                            />
                                            </Modal>
                                            <span className="SpoqaHanSansNeo-Regular fs16 fc999999 pl10">(소득세법시행령 제155조 제6항)</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="h64">
                                    <th className="taC">장기저당담보주택</th>
                                    <td>
                                        <div className="dpFlx aiC">
                                            <ImageRadioBox
                                                codeList={Util.getCodeArray("1042")}
                                                value={mrtgHouseAt}
                                                btnClass="w24 h24 pr5"
                                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                                interval={20}
                                                onChange={(item: string) => {
                                                    setMrtgHouseAt(item);
                                                    if (item === "N") {
                                                        return;
                                                    }
                                                    AppContext.openAlert({
                                                        title: "장기저당담보주택 여부",
                                                        msg:
                                                            "1. 장기저당담보주택 요건\n\n" +
                                                            "(1) 1세대 1주택 양도세 비과세 요건인 거주요건 제외\n\n" +
                                                            "국내에 1주택을 소유한 1세대가 다음의 요건을 갖춘 장기저당담보대출계약을 체결하고\n" +
                                                            "장기저당담보로 제공된 주택을 양도하는 경우에는 1세대 1주택 비과세 적용시 거주기간의 제한을 받지 아니한다.\n" +
                                                            "다만, 장기저당담보주택을 계약기간 만료 이전에 양도하는 경우에는 적용하지 아니한다.\n\n" +
                                                            "① 계약체결일 현재 주택을 담보로 제공한 가입자가 60세 이상일 것\n" +
                                                            "② 장기저당담보 계약기간이 10년 이상으로서 만기시까지 매월·매분기별로 대출금을 수령하는 조건일 것\n" +
                                                            "③ 만기에 당해 주택을 처분하여 일시 상환하는 계약조건일 것\n\n" +
                                                            "(2) 동거봉양합가의 경우 장기저당담보로 제공된 주택은 자녀 주택과 분리하여 1세대 1주택으로 인정\n\n" +
                                                            "1주택을 소유하고 1세대를 구성하는 자가 장기저당담보주택을 소유하고 있는 직계존속(배우자의 직계존속을 포함한다)\n" +
                                                            "을 동거봉양하기 위하여 세대를 합침으로ㅆ 1세대가 2주택을 소유하게 되는 경우 먼저 양도하는 주택에 대하여는\n" +
                                                            "1세대 1주택 비과세 규정을 적용하되 거주기간의 제한을 받지 아니한다.\n" +
                                                            "다만, 장기저당담보주택을 계약기간 만료 이전에 양도하는 경우에는 적용하지 아니한다.\n\n\n\n" +
                                                            "2. 특례적용 신청\n\n" +
                                                            "장기저당담보주택에 대한 특례적용신고서를 양도소득세 과세표준신고기한 내에 장기저당담보주택에 관한 대출계약서와 함께 제출하여야 한다."
                                                            ,
                                                        icon: "check",
                                                        closeText: "아니오",
                                                        confirmText: "예",
                                                        handleConfirm: () => {
                                                            setQestn19AnswerText("Y");
                                                        },
                                                        handleClose: () => {
                                                            setQestn19AnswerText("N");
                                                            setMrtgHouseAt("N");
                                                        }
                                                    });
                                                }}
                                            />
                                            <span className="SpoqaHanSansNeo-Regular fs16 fc999999 pl10">(소득세법시행령 제155조의 2)</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="h64">
                                    <th className="taC">장기어린이집주택</th>
                                    <td>
                                        <div className="dpFlx aiC">
                                            <ImageRadioBox
                                                codeList={Util.getCodeArray("1042")}
                                                value={childHouseAt}
                                                btnClass="w24 h24 pr5"
                                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                                interval={20}
                                                onChange={(item: string) => {
                                                    setChildHouseAt(item);
                                                    if (item === "N") {
                                                        return;
                                                    }
                                                    AppContext.openAlert({
                                                        title: "장기어린이집주택 여부",
                                                        msg:
                                                            "세대원이 5년 이상 운영한 장기어린이집을 보유한 상태에서 거주주택을 양도하는 경우\n" +
                                                            "해당 거주주택이 2년 이상 보유 및 2년 이상 거주한 경우 1세대 1주택 비과세특례를 적용한다.\n\n" +
                                                            "장기어린이집이란 1세대의 구성원이 「영유아보호법」 제13조의 규정에 따라 시장·군수 또는\n" +
                                                            "구청장의 인가를 받고 양도일 현재「소득세법」제158조에 따라 사업자등록을 하고, 장기가정어린이집을 운영하고 있는 주택을 말한다."
                                                        ,
                                                        icon: "check",
                                                        confirmText: "예",
                                                        closeText: "아니오",
                                                        handleConfirm: () => {
                                                            setQestn7AnswerText("Y");
                                                        },
                                                        handleClose: () => {
                                                            setChildHouseAt("N");
                                                            setQestn7AnswerText("N");
                                                        }
                                                    });
                                                }}
                                            />
                                            <span className="SpoqaHanSansNeo-Regular fs16 fc999999 pl10">(소득세법시행령 제155조 제20항)</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="h64">
                                    <th className="taC">주택 신축 판매업용 주택</th>
                                    <td>
                                        <div className="dpFlx aiC">
                                            <ImageRadioBox
                                                codeList={Util.getCodeArray("1042")}
                                                value={salesHouseAt}
                                                btnClass="w24 h24 pr5"
                                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                                interval={20}
                                                onChange={(item: string) => {
                                                    setSalesHouseAt(item);
                                                    if (item === "N") {
                                                        return;
                                                    }
                                                    AppContext.openAlert({
                                                        title: "주택 신축 판매업용 주택 여부",
                                                        msg: "주택 신축 판매업용 주택입니까?",
                                                        icon: "check",
                                                        confirmText: "예",
                                                        closeText: "아니오",
                                                        handleConfirm: () => {
                                                            setQestn52AnswerText("Y");
                                                        },
                                                        handleClose: () => {
                                                            setSalesHouseAt("N");
                                                            setQestn52AnswerText("N");
                                                        }
                                                    });
                                                }}
                                            />
                                            <span className="SpoqaHanSansNeo-Regular fs16 fc999999 pl10">(소득세법 제19조)</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="h64">
                                    <th className="taC">부동산 매매업용 주택</th>
                                    <td>
                                        <div className="dpFlx aiC">
                                            <ImageRadioBox
                                                codeList={Util.getCodeArray("1042")}
                                                value={estateHouseAt}
                                                btnClass="w24 h24 pr5"
                                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                                interval={20}
                                                onChange={(item: string) => {
                                                    setEstateHouseAt(item);
                                                    if (item === "N") {
                                                        return;
                                                    }
                                                    AppContext.openAlert({
                                                        title: "부동산 매매업용 주택 여부",
                                                        msg: "부동산 매매업용 주택입니까?",
                                                        icon: "check",
                                                        confirmText: "예",
                                                        closeText: "아니오",
                                                        handleConfirm: () => {
                                                            setQestn53AnswerText("Y");
                                                        },
                                                        handleClose: () => {
                                                            setEstateHouseAt("N");
                                                            setQestn53AnswerText("N");
                                                        }
                                                    });
                                                }}
                                            />
                                            <span className="SpoqaHanSansNeo-Regular fs16 fc999999 pl10">(소득세법 제19조)</span>
                                        </div>
                                    </td>
                                </tr>
                            </React.Fragment>
                        : <></>}
                        </tbody>
                    </table>
                </div>
                <AssetsButton propsForm={props.form} goNextStep={goNextStep} goPrevStep={goPrevStep} />
            </div>
            {/* <AssetsButtonBar propsForm={props.form} goNextStep={goNextStep} goPrevStep={goPrevStep} /> */}

            {/* 기준시가 수동조회 화면 안내 */}
            <Modal modalOpen={manualCheckGuideModalOpen} setModalOpen={setManualCheckGuideModalOpen} title="기준시가 수동조회 화면 안내" dim={true} visible={true}>
                <ManualCheckGuide setModalOpen={setManualCheckGuideModalOpen} />
            </Modal>
        </React.Fragment>
    );
};
export default AssetsEditRar;