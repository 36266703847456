/*******************************************************************************
*** InNewPasswd ** 비밀번호 재입력
*******************************************************************************/
import React      from 'react';
import JSEncrypt  from 'jsencrypt'
import Util       from 'js/common/Util';
import Axios      from 'js/common/Axios';
import AppContext from 'js/common/AppContext';
import TextBox    from 'js/common/ui/input/TextBox';
import { useLocation, useNavigate } from 'react-router-dom';

function InNewPasswd() {
    const location = useLocation();
    const navigate = useNavigate();
    const userNm     = location.state.userNm;
    const userId     = location.state.userId;
    const emailAdres = location.state.emailAdres;
    const mbtlnum    = location.state.mbtlnum;

    const [password   , setPassword   ] = React.useState<string>("");
    const [passwordCfm, setPasswordCfm] = React.useState<string>("");

    const [passwdChkMsg   , setPasswdChkMsg   ] = React.useState<string>(""); // 비밀번호 패턴 확인 메시지
    const [newPasswdChkMsg, setNewPasswdChkMsg] = React.useState<string>(""); // 새비밀번호 일치 확인 메시지

    // 비밀번호 패턴 도움말
    const checkPassword = (password:string) => {
        if (!Util.chkPasswdPtn(password)) {
            setPasswdChkMsg("영문, 숫자, 특수문자를 포함한 8자 이상, 20자 이하로 입력하세요.");
        } else {
            setPasswdChkMsg("");
        }

        // 새비밀번호 확인
        if (!Util.isEmpty(passwordCfm) && password !== passwordCfm) {
            setNewPasswdChkMsg("새 비밀번호가 일치하지 않습니다.");
        } else {
            setNewPasswdChkMsg("");
        }
    }

    // 비밀번호 일치여부 도움말
    const chkPasswordCfm = (newPasswd:string) => {
        if (password !== newPasswd) {
            setNewPasswdChkMsg("새 비밀번호가 일치하지 않습니다.");
        } else {
            setNewPasswdChkMsg("");
        }
    }

    // 비밀번호 재설정 처리
    const goUpdPasswd = () => {
        if (Util.isEmpty(password)) {
            AppContext.openAlert({
                title: "새 비밀번호",
                msg: "새 비밀번호를 입력해주세요.",
                icon: "warning"
            });
            return;
        }

        // 비밀번호 패턴 확인
        if (!Util.chkPasswdPtn(password)) {
            AppContext.openAlert({
                title: "새 비밀번호 확인",
                msg: "새 비밀번호를 영문, 숫자, 특수문자를 포함한 8자 이상, 20자 이하로 입력하세요.",
                icon: "warning"
            });
            return;
        }

        if (Util.isEmpty(passwordCfm)) {
            AppContext.openAlert({
                title: "새 비밀번호 확인",
                msg: "새 비밀번호 확인를 입력해주세요.",
                icon: "warning"
            });
            return;
        }

        // 비밀번호 일치여부 확인
        if (password !== passwordCfm) {
            AppContext.openAlert({
                title: "새 비밀번호 미일치",
                msg: "새 비밀번호와 새 비밀번호 확인이 일치하지 않습니다",
                icon: "warning"
            });
            return;
        }

        AppContext.showSpinnerDim();
        Axios.dataAccess({
            url: "portal/auth/genRsaKeyPasswdUser.do",
            methodType: "post",
            paramType: "json",
            paramData: {
                userNm     : userNm
              , userId     : userId
              , emailAdres : emailAdres
              , mbtlnum    : mbtlnum
            },
            onSuccessFn: (res: any) => {
                const pubKey = res.item.rsaInfo.pubKeyStr;
                const encrypt = new JSEncrypt();
                encrypt.setPublicKey(pubKey);

                Axios.dataAccess({
                    url: "portal/auth/updUserPasswd.do",
                    methodType: "post",
                    paramType: "json",
                    paramData: {
                        userSn     : res.item.userSn
                      , encPassword: encrypt.encrypt(password)
                    },
                    onSuccessFn: () => {
                        AppContext.hideSpinner();
                        navigate("/portal/inNewPasswd/result");
                    }
                });
            }
        });
    }

    return (
        <div className='w100p'>
            <div className='w100p h160 dpFlx aiC jcC'>
                <span className="SpoqaHanSansNeo-Bold fs36 ls1 fcBlack">비밀번호 재설정</span>
            </div>
            <div className='w100p h385 dpFlx aiC jcC mb100'>
                <div className='w460'>
                    <div className='h45 dpFlx aiE pb5 bdBox'>
                        <span className="SpoqaHanSansNeo-Bold fs16 ls08 fc222222">새 비밀번호</span>
                    </div>
                    <TextBox
                        type="password"
                        value={password}
                        height={60}
                        fontSize={16}
                        fontFamily='Inter-Regular'
                        phFontFamily='SpoqaHanSansNeo-Regular'
                        maxLength={30}
                        placeholder="비밀번호"
                        onChangeFunc={(text: string) => {
                            setPassword(text);
                            checkPassword(text);
                        }}
                        inputClassName='bgcWhite ls08'
                        color="#222222"
                        offColor="#999999"
                        className='bd1 pl15 pr20'
                        iconEl={
                            <img
                                onClick={() => setPassword("")}
                                className={'csP ' + (Util.isEmpty(password) ? "dpNone" : "")}
                                src={require('img/common/icon_cancel_fill_18.png')}
                                alt="icon_cancel_fill_18"
                            />
                        }
                    />
                    <span className="SpoqaHanSansNeo-Regular fs13 ls065 wsPw fcFF0000">{passwdChkMsg}</span>

                    <div className='h45 dpFlx aiE pb5 bdBox'>
                        <span className="SpoqaHanSansNeo-Bold fs16 ls08 fc222222">새 비밀번호 확인</span>
                    </div>
                    <TextBox
                        type="password"
                        value={passwordCfm}
                        height={60}
                        fontSize={16}
                        fontFamily='Inter-Regular'
                        phFontFamily='SpoqaHanSansNeo-Regular'
                        maxLength={30}
                        placeholder="비밀번호 확인"
                        onChangeFunc={(text: string) => {
                            setPasswordCfm(text);
                            chkPasswordCfm(text);
                        }}
                        inputClassName='bgcWhite ls08'
                        color="#222222"
                        offColor="#999999"
                        className='bd1 pl15 pr20'
                        iconEl={
                            <img
                                onClick={() => setPasswordCfm("")}
                                className={'csP ' + (Util.isEmpty(passwordCfm) ? "dpNone" : "")}
                                src={require('img/common/icon_cancel_fill_18.png')}
                                alt="icon_cancel_fill_18"
                            />
                        }
                    />
                    <span className="SpoqaHanSansNeo-Regular fs13 ls065 wsPw fcFF0000">{newPasswdChkMsg}</span>
                    <div className='w100p h62 bgc17A840 mt30 dpFlx aiC jcC csP' onClick={goUpdPasswd}>
                        <span className="SpoqaHanSansNeo-Bold fs18 ls08 fcWhite">확인</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default InNewPasswd;