/*******************************************************************************
 *** SimPropTaxCalc ** 간편계산기 재산세/종합부동산세 결과표
*******************************************************************************/
import React from "react";
import Axios from "js/common/Axios";
import Util from "js/common/Util";
import LinesEllipsis from "react-lines-ellipsis";
import AppContext from "js/common/AppContext";

interface SimAcqstaxCalcProps {
    setSimPropTaxCalcModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setSimPropTaxCalcModalVsb: React.Dispatch<React.SetStateAction<boolean>>
    propTaxParam: any[]
}

const SimPropTaxCalc = ({ setSimPropTaxCalcModalOpen, setSimPropTaxCalcModalVsb, propTaxParam}: SimAcqstaxCalcProps) => {
    const [calcPropInfo, setCalcPropInfo] = React.useState<any | undefined>();  // 재산세 계산결과
    const [calcCompInfo, setCalcCompInfo] = React.useState<any | undefined>();  // 종합소득세 계산결과

    const [sumStdPrice   , setSumStdPrice   ] = React.useState<string>();
    const [sumTaxBase    , setSumTaxBase    ] = React.useState<string>();
    const [sumPropertyAmt, setSumPropertyAmt] = React.useState<string>();
    const [sumUrbanAmt   , setSumUrbanAmt   ] = React.useState<string>();
    const [sumLocalEduAmt, setSumLocalEduAmt] = React.useState<string>();
    const [sumLocalResAmt, setSumLocalResAmt] = React.useState<string>();
    const [sumTotalAmt   , setSumTotalAmt   ] = React.useState<string>();
    const [housingArr    , setHousingArr    ] = React.useState<[]>([]);

    const getAddress = (pnu: string | undefined) => {
        console.log(housingArr);
        let address = "";
        housingArr.forEach((housingInfo: any) => {
            if (housingInfo.pnu === pnu) {
                address = housingInfo.rdnmAdres;
                return;
            }
        });
        return address;
    }

    // 재산세 파라미터 확인
    React.useEffect(() => {
        let taxFlag           = "";  // 재산세/종합부동산세(P)
        let houseNo           = "";  // 1. 주택수
        let housing           = [];  // 2. 주택정보 배열
        let coupleName        = "";  // 3. 부부공동명의
        let coupleSpecialCase = "";  // 4. 부부공동명의 특례적용
        let holdingPeriod     = "";  // 5. 보유기간
        let ownerAge          = "";  // 6. 소유자 연령
        let houseCnt          = 0 ;  // 실제 주택수

        // 배열에서 파라미터 얻기
        for (const taxParam of propTaxParam) {
            taxFlag           = taxParam.taxFlag          ;  // 재산세/종합부동산세(P)
            houseNo           = taxParam.houseNo          ;  // 1. 주택수
            housing           = taxParam.housing          ;  // 2. 주택정보 배열
            coupleName        = taxParam.coupleName       ;  // 3. 부부공동명의
            coupleSpecialCase = taxParam.coupleSpecialCase;  // 4. 부부공동명의 특례적용
            holdingPeriod     = taxParam.holdingPeriod    ;  // 5. 보유기간
            ownerAge          = taxParam.ownerAge         ;  // 6. 소유자 연령
        }
        setHousingArr(housing);
        houseCnt = housing.length; // 입력된 실제 주택 수

        let pnuString             = ""; // 토지고유코드(19자리)
        // let rdnmAdresString       = ""; // 도로명 주소
        let spfcKndString         = ""; // 용도지역
        let stdPriceString        = ""; // 공동/개별 주택 가격(기준시가)
        let mdatTrgetAreaAtString = ""; // 조정대상지역
        let shareRatioString      = ""; // 지분율

        // List형 Query String 생성 : ex)pnu=123&pnu=456&pnu=789
        // for(let i in 배열): 배열의 index 반환(i)
        for (let i in housing) {
            pnuString             += "&pnu="                + housing[i].pnu;            
            // rdnmAdresString       += "&rdnm_adres="         + (Util.aTrim(housing[i].rdnmAdres)).replace(/\n|\r|\s*/g, '');
            spfcKndString         += "&spfc_knd="           + housing[i].spfcKnd;
            stdPriceString        += "&std_price="          + housing[i].stdPrice;
            mdatTrgetAreaAtString += "&mdat_trget_area_at=" + housing[i].mdatTrgetAreaAt;
            shareRatioString      += "&share_ratio="        + housing[i].shareRatio;
        }

        Axios.dataAccess({
            url: "api/system/broker/callApi.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                apiName : "taxcalc"
              , tailUrl : "simpletax/propcomptax"
              , paramStr: taxFlag        // 재산세/종합부동산세(P)
                        + "?house_no="            + houseNo           // 1. 주택수
                        + "&couple_name="         + coupleName        // 2. 부부공동명의
                        + "&couple_special_case=" + coupleSpecialCase // 3. 부부공동명의 특례적용
                        + "&holding_period="      + holdingPeriod     // 4. 보유기간
                        + "&owner_age="           + ownerAge          // 5. 소유자 연령
                        + "&house_cnt="           + houseCnt          // 6. 실제 주택수
                        + pnuString                                   // 토지고유코드(19자리)
                        + spfcKndString                               // 용도지역
                        + stdPriceString                              // 공동/개별 주택 가격(기준시가)
                        + mdatTrgetAreaAtString                       // 조정대상지역
                        + shareRatioString                            // 지분율
            },
            onSuccessFn: (res: any) => {
                if (Util.isEmpty(res.item.rtn_prop_list)) {
                    setSimPropTaxCalcModalOpen(false);
                    AppContext.openAlert({
                        title: "과세 대상 여부",
                        msg: res.item.rtn_msg,   // 종합부동산세 과세대상이 아닙니다.
                        icon: "check"
                    });
                    return;
                }
                // 재산세/종합소득세 계산 결과
                setCalcPropInfo(res.item.rtn_prop_list);       // 재산세 계산 결과 List        
                setCalcCompInfo(res.item.rtn_comp_tax[0]);    // 종합부동산세 결과 
                setSimPropTaxCalcModalVsb(true);
            },
            onFailedFn: () => {
                setSimPropTaxCalcModalOpen(false);
            }
        });
    }, [propTaxParam, setSimPropTaxCalcModalOpen, setSimPropTaxCalcModalVsb]);

    // 세금 합계 계산
    React.useEffect(() => {
        let sumStdPrice    = 0;    // 공시가격
        let sumTaxBase     = 0;    // 과세표준
        let sumPropertyAmt = 0;    // 재산세    
        let sumUrbanAmt    = 0;    // 도시지역분
        let sumLocalEduAmt = 0;    // 지방교육세
        let sumLocalResAmt = 0;    // 지역자원시설세
        let sumTotalAmt    = 0;    // 합계

        if (calcPropInfo !== undefined) {
            for (const proptaxList of calcPropInfo) {
                sumStdPrice    = sumStdPrice    + Number(proptaxList.std_price);
                sumTaxBase     = sumTaxBase     + Number(proptaxList.tax_base);
                sumPropertyAmt = sumPropertyAmt + Number(proptaxList.property_amt);
                sumUrbanAmt    = sumUrbanAmt    + Number(proptaxList.urban_amt);
                sumLocalEduAmt = sumLocalEduAmt + Number(proptaxList.local_edu_amt);
                sumLocalResAmt = sumLocalResAmt + Number(proptaxList.local_res_amt);
                sumTotalAmt    = sumTotalAmt    + Number(proptaxList.property_amt) + Number(proptaxList.urban_amt) + Number(proptaxList.local_edu_amt) + Number(proptaxList.local_res_amt);
            }
        }
        setSumStdPrice   (Util.comma(sumStdPrice.toString()));
        setSumTaxBase    (Util.comma(sumTaxBase.toString()));
        setSumPropertyAmt(Util.comma(sumPropertyAmt.toString()));
        setSumUrbanAmt   (Util.comma(sumUrbanAmt.toString()));
        setSumLocalEduAmt(Util.comma(sumLocalEduAmt.toString()));
        setSumLocalResAmt(Util.comma(sumLocalResAmt.toString()));
        setSumTotalAmt   (Util.comma(sumTotalAmt.toString()));
    }, [setSimPropTaxCalcModalOpen, calcPropInfo]);

    return (
        <React.Fragment>
            <div className='w1100 ofA'>
                <div className='dpFlx jcSb aiC mb10'>
                    <div className='dpFlx aiC'>
                        <img className="pr10" src={require('img/system/assets/icon_propertytax.png')} alt="icon_propertytax" />
                        <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 fw500'>재산세 정보</span>
                    </div>
                </div>                
                <table className={
                    "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD mb30 mt10 " +
                    "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 th-ls1 " +
                    "td-p10 td-fs14 td-fm-SpoqaHanSansNeo-Regular td-ls075 td-fc666666 td-bdcDDDDDD"
                }>
                    <colgroup>
                        <col className="w280" />
                        <col className="w110" />
                        <col className="w80" />
                        <col className="w110" />
                        <col className="w100" />
                        <col className="w100" />
                        <col className="w100" />
                        <col className="w110" />
                        <col className="w100" />
                    </colgroup>
                    <tbody>
                        <tr className="h32">
                            <th>소재지</th>
                            <th>공시가격</th>
                            <th>지분율</th>
                            <th>과세표준</th>
                            <th>재산세</th>
                            <th>도시지역분</th>
                            <th>지방교육세</th>
                            <th>지역자원시설세</th>
                            <th>합계</th>
                        </tr>
                        {calcPropInfo && calcPropInfo.map((item: any, idx: number) => {
                                return (
                                <tr className="h32" key={idx}>
                                    <td className="taL"><LinesEllipsis text={getAddress(item.pnu)} maxLine='1' ellipsis='...' trimRight /></td>
                                    <td className="taR">{Util.comma(item.std_price)}</td>
                                    <td className="taC">{item.share_ratio}%</td>
                                    <td className="taR">{Util.comma(item.tax_base)}</td>
                                    <td className="taR">{Util.comma(item.property_amt)}</td>
                                    <td className="taR">{Util.comma(item.urban_amt)}</td>
                                    <td className="taR">{Util.comma(item.local_edu_amt)}</td>
                                    <td className="taR">{Util.comma(item.local_res_amt)}</td>
                                    <td className="taR">{Util.comma(item.total_amt)}</td>
                                </tr>
                            )                             
                        })}

                        <tr className="h35">
                            <th><span className="fs16">합계</span></th>
                            <td className="taR"><span className="fs15 fc222222 SpoqaHanSansNeo-Medium ls1">{sumStdPrice}</span></td>
                            <td></td>
                            <td className="taR"><span className="fs15 fc222222 SpoqaHanSansNeo-Medium ls1">{sumTaxBase}</span></td>
                            <td className="taR"><span className="fs15 fc222222 SpoqaHanSansNeo-Medium ls1">{sumPropertyAmt}</span></td>
                            <td className="taR"><span className="fs15 fc222222 SpoqaHanSansNeo-Medium ls1">{sumUrbanAmt}</span></td>
                            <td className="taR"><span className="fs15 fc222222 SpoqaHanSansNeo-Medium ls1">{sumLocalEduAmt}</span></td>
                            <td className="taR"><span className="fs15 fc222222 SpoqaHanSansNeo-Medium ls1">{sumLocalResAmt}</span></td>
                            <td className="taR"><span className="fs15 fc222222 SpoqaHanSansNeo-Medium ls1">{sumTotalAmt}</span></td>
                        </tr>
                    </tbody>
                </table>

                <div className='dpFlx jcSb aiC mb10'>
                    <div className='dpFlx aiC'>
                        <img className="pr10" src={require('img/system/assets/icon_propertytax.png')} alt="icon_propertytax" />
                        <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 fw500'>종합부동산세 정보</span>
                        {/* <span className="SpoqaHanSansNeo-Regular fs16 ls08 fc17A840 pl128">{calcCompInfo ? (Util.isNull(calcCompInfo.house_info) ? "종합부동산세 과세 대상이 아닙니다." : "") : ""}</span> */}
                    </div>
                </div>   
                
                {calcCompInfo && Util.isNull(calcCompInfo.house_info) ?         
                    <div className="bgcF6F6F6 pt60 pb60 mb20">
                        <div className="m0auto dpFlx aiC jcC">
                            <span className="SpoqaHanSansNeo-Regular fs20 fc666666">종합부동산세 과세 대상이 아닙니다.</span>
                        </div>
                    </div>             
                : 
                    <table className={
                        "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD mb40 mt10 " +
                        "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 th-ls1 " +
                        "td-p20 td-fs14 td-fm-SpoqaHanSansNeo-Regular td-ls075 td-fc666666 td-bdcDDDDDD"
                    }>
                        <colgroup>
                            <col className="w200" />
                            <col className="w250" />
                            <col className="w200" />
                            <col className="w125" />
                            <col className="w125" />
                        </colgroup>
                        <tbody>
                            <tr className="h32">
                                <th>주택정보</th>
                                <td colSpan={4} className="taC">{calcCompInfo ? calcCompInfo.house_info : ""}</td>
                            </tr>
                            <tr className="h32">
                                <th>주택 공시가격 합계</th>
                                <td className="taR">{calcCompInfo ? Util.comma(calcCompInfo.comp_sum_std_price) : ""}</td>
                                <th>공제금액</th>
                                <td colSpan={2} className="taR">{calcCompInfo ? Util.comma(calcCompInfo.deduct_amt) : ""}</td>
                            </tr>
                            <tr className="h32">
                                <th>공제금액 차감 후 금액</th>
                                <td className="taR">{calcCompInfo ? Util.comma(calcCompInfo.comp_tax_base_pre) : ""}</td>
                                <th>(*)공정시장가액 비율</th>
                                <td colSpan={2} className="taR">{calcCompInfo ? Number(calcCompInfo.fair_market_ratio): ""}%</td>
                            </tr>
                            <tr className="h32">
                                <th>과세표준</th>
                                <td className="taR">{calcCompInfo ? Util.comma(calcCompInfo.comp_tax_base) : ""}</td>
                                <th>(*)세율 / (-)누진공제액</th>
                                <td className="taR">{calcCompInfo ? Number(calcCompInfo.comp_basic_tax_rate) : ""}%</td>
                                <td colSpan={2} className="taR">{calcCompInfo ? Util.comma(calcCompInfo.comp_progre_deduct_amt) : ""}</td>
                            </tr>
                            <tr className="h32">
                                <th>종합부동산세 A</th>
                                <td className="taR">{calcCompInfo ? Util.comma(calcCompInfo.comp_tax_A) : ""}</td>
                                <th>(-)재산세 중복분</th>
                                <td colSpan={2} className="taR">{calcCompInfo ? Util.comma(calcCompInfo.comp_dupl_prop_tax) : ""}</td>
                            </tr>
                            <tr className="h32">
                                <th>종합부동산세 B (A-재산세 중복분)</th>
                                <td className="taR">{calcCompInfo ? Util.comma(calcCompInfo.comp_tax_B) : ""}</td>
                                <th>(-)세액 공제(80%한도)</th>
                                <td colSpan={2} className="taR">{calcCompInfo ? Util.comma(calcCompInfo.tax_credit_amt) : ""}</td>
                            </tr>
                            <tr className="h32">
                                <th>종합부동산세 C (B-세액공제)</th>
                                <td className="taR">{calcCompInfo ? Util.comma(calcCompInfo.comp_tax_C) : ""}</td>
                                <th>(+)농어촌 특별세</th>
                                <td colSpan={2} className="taR">{calcCompInfo ? Util.comma(calcCompInfo.farm_fish_tax) : ""}</td>
                            </tr>
                            <tr className="h35">
                                <th><span className="fs16">납부 세액</span></th>
                                <td colSpan={4} className="taC"><span className="fs16 fc222222 SpoqaHanSansNeo-Medium ls1">{calcCompInfo ? Util.comma(calcCompInfo.comp_total_tax) : ""}</span></td>
                                
                            </tr>
                        </tbody>
                    </table>
                }

                <div className='w100p dpFlx aiC jcC'>
                    <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => {
                        setSimPropTaxCalcModalOpen(false);
                    }}>
                        <span className='SpoqaHanSansNeo-Medium fs16 fcWhite fw500'>확인</span>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
export default SimPropTaxCalc;