import React from 'react';
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import * as Atom from "js/common/Atom";
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import AppContext from 'js/common/AppContext';
import Axios from 'js/common/Axios';
import MuiListFilter from 'js/common/ui/list/MuiListFilter';
import ForestTableStyle from 'js/common/ui/style/ForestStyle';
import Modal from 'js/common/ui/modal/Modal';
import Checkbox from '@mui/material/Checkbox';
import ForestStyle from 'js/common/ui/style/ForestStyle';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import TrsftaxInput from './dialog/TrsftaxInput';
import Util from 'js/common/Util';
import TrsftaxMrrgQestn from './dialog/TrsftaxMrrgQestn';
import TrsftaxChbtQestn from './dialog/TrsftaxChbtQestn';
import TrsftaxCalc from './TrsftaxCalc';

const TrsftaxTest = () => {
    const navigate = useNavigate();
    const hshldSn = useRecoilValue(Atom.hshldSn);
    const [hshldMrrgAt, setHshldMrrgAt] = React.useState<string>(""); // 납세자 결혼여부
    const [hshldChbtSptAt, setHshldChbtSptAt] = React.useState<string>(""); // 납세자 동거봉양합가여부
    const [assetsDataArr, setAssetsDataArr] = React.useState<[]>([]);
    const setCurrFormMode = useSetRecoilState(Atom.currFormMode);
    const [assetsSelTime, setAssetsSelTime] = React.useState<Date>(new Date());
    const [assetsSn, setAssetsSn] = React.useState<number>(); // 자산key
    const [estateDetailSe, setEstateDetailSe] = React.useState<string>(""); // 자산상세구분
    const [acqsSrc, setAcqsSrc] = React.useState<string>(""); // 취득구분
    const [acqsCause, setAcqsCause] = React.useState<string>(""); // 취득원인

    const [taxInputModalOpen, setTaxInputModalOpen] = React.useState<boolean>(false); // 양도세 추가정보 입력 모달
    const [trsftaxMrrgQestnModalOpen, setTrsftaxMrrgQestnModalOpen] = React.useState<boolean>(false); // 양도세 혼인합가 질문 조회 모달
    const [trsftaxChbtQestnModalOpen, setTrsftaxChbtQestnModalOpen] = React.useState<boolean>(false); // 양도세 동거봉양합가 질문 조회 모달
    const [trsftaxCalcModalOpen, setTrsftaxCalcModalOpen] = React.useState<boolean>(false); // 양도세 결과 조회 모달
    const [trsftaxCalcModalVisible, setTrsftaxCalcModalVisible] = React.useState<boolean>(false); // 양도세 결과 조회 모달 visible
    const [trsfAmt, setTrsfAmt] = React.useState<string>(""); // 양도금액
    const [trsfDe, setTrsfDe] = React.useState<string | undefined>(Util.getSpecificDate("", "day", 0, "DD", "")); // 양도일자
    const [isPay, setIsPay] = React.useState<string>("N"); // 청산금납부여부
    const [payAmt, setPayAmt] = React.useState<string>(""); // 청산금액
    const [ncssryAmtBf, setNcssryAmtBf] = React.useState<string>(""); // 기타경비
    const [ncssryAmt, setNcssryAmt] = React.useState<string>(""); // 기타경비(신규)

    // 자산 수정
    const goAssetsEditPage = (item: any) => {
        AppContext.showSpinnerDim();
        setCurrFormMode("mod");
        if (["1009", "1010"].indexOf(item.stepCode) !== -1) {
            navigate("/system/assets/view/TrsftaxTest/" + item.assetsSn);
        } else {
            navigate("/system/assets/mod/TrsftaxTest/" + item.assetsSn);
        }
    }

    const goInputTrsftaxAddInfo = (item: any) => {
        if (item.rmstnfamSe !== "1000") {
            AppContext.openAlert({
                title: "세액계산 불가",
                msg: "본인(납세자)의 자산만 세액계산이 가능합니다.",
                icon: "check"
            });
            return;
        }
        if (item.stepCode !== "1009") {
            AppContext.openAlert({
                title: "세액계산 불가",
                msg: "등록완료 상태의 자산만 세액계산이 가능합니다.",
                icon: "check"
            });
            return;
        }
        if (item.estateSe !== "1001") {
            AppContext.openAlert({
                title: "세액계산 불가",
                msg: "구분이 주택인 자산만 세액계산이 가능합니다.",
                icon: "check"
            });
            return;
        }
        setAssetsSn(item.assetsSn);
        setEstateDetailSe(item.estateDetailSe);
        setAcqsSrc(item.acqsSrc);
        setAcqsCause(item.acqsCause);
        setTaxInputModalOpen(true);
    }

    const moveStep = (step: number) => {
        setTaxInputModalOpen(false);
        setTrsftaxMrrgQestnModalOpen(false);
        setTrsftaxChbtQestnModalOpen(false);
        setTrsftaxCalcModalOpen(false);
        if (step === 1) {
            setTaxInputModalOpen(true);
        } else if (step === 2) {
            setTrsftaxMrrgQestnModalOpen(true);
        } else if (step === 3) {
            setTrsftaxChbtQestnModalOpen(true);
        } else if (step === 4) {
            setTrsftaxCalcModalOpen(true);
        }
    }

    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/rmstnfam/selRmstnfamList.do",
            methodType: "post",
            paramType: "json",
            paramData: {
                hshldSn: hshldSn
            },
            onSuccessFn: (res: any) => {
                res.list.forEach((item: any) => {
                    if (item.rmstnfamSe === "1000") {
                        setHshldMrrgAt(item.mrrgAt);
                        setHshldChbtSptAt(item.chbtSptAt);
                    }
                });
                Axios.dataAccess({
                    url: "system/assets/selAssetsList.do",
                    methodType: "post",
                    paramType: "json",
                    paramData: {
                        hshldSn: hshldSn,
                        assetsSe: "1001",
                        estateSttus: "1002",
                        estateSe: "1001",
                        routeKey: "trsftaxtest"
                    },
                    onSuccessFn: (res: any) => {
                        setAssetsDataArr(res.list);
                        AppContext.hideSpinner();
                    }
                });
            }
        });
    }, [hshldSn, assetsSelTime]);

    return (
        <React.Fragment>
            <div className="w100p bgcF6F6F6">
                <div className="dpFlx flxdCol jcC aiC pt60 pb30">
                    <div className='taC pb30'>
                        <span className="dpBlock fcBlack fs38 ls266 SpoqaHanSansNeo-Medium">똑똑한 자산관리의 시작</span>
                    </div>
                </div>
                <div className="dpFlx jcC pt30 pb30">
                    <div className='pb50'>
                        <div className='h36 dpFlx aiE pb20 pt40'>
                            <img className="mr10" src={require('img/common/icon_step_money.png')} alt="icon_step_money" />
                            <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 pb3'>
                                <span className='fc222222'>보유자산</span>
                            </span>
                        </div>
                        <MuiListFilter tableInfo={{
                            tableHeadVsb: true,
                            styleType: ForestTableStyle,
                            fldInfs: [
                                { fldNm: 'assetsSn', pk: true },
                                { type: 'el', width: 45, headAlign: "center", label: '포함', el: (item: any) =>
                                    <Checkbox sx={ForestStyle.checkbox} checked={item.estateSe === "1001"} onChange={(e: any) => {
                                        Axios.dataAccess({
                                            url: "system/trsftax/updAssetsEstateSe.do",
                                            methodType: "post",
                                            paramType: "json",
                                            paramData: {
                                                assetsSn: item.assetsSn,
                                                estateSe: e.target.checked ? "1001" : "1009"
                                            },
                                            onSuccessFn: () => {
                                                setAssetsSelTime(new Date());
                                                AppContext.hideSpinner();
                                            }
                                        });
                                    }} />
                                },
                                { type: 'text', fldNm: 'assetsSn', width: 45, headAlign: "center", dataAlign: "center", label: 'No.' },
                                { type: 'code', fldNm: 'estateSe', width: 70, headAlign: "center", dataAlign: "center", label: '구분', codeTy: "1005" },
                                { type: 'el', width: 45, headAlign: "center", label: '수정', el: (item: any) => (
                                    <EditCalendarOutlinedIcon style={{ fontSize: 21, color: 'black', cursor: 'pointer'}} onClick={() => {
                                        goAssetsEditPage(item);
                                    }} />
                                )},
                                { type: 'el', width: 45, headAlign: "center", label: '계산', el: (item: any) =>
                                    <CalculateOutlinedIcon style={{ fontSize: 21, color: 'black', cursor: 'pointer'}} onClick={() => goInputTrsftaxAddInfo(item)} />
                                },
                                { type: 'text', fldNm: 'rmstnfamNm', width: 60, headAlign: "center", dataAlign: "center", label: '세대원' },
                                { type: 'el', fldNm: 'stepCode', width: 80, headAlign: "center", label: '등록상태', codeTy: "1004", el: (item: any) => (
                                    <div className={'dpFlx aiC jcC w60 h24 bdrd40 bdBox ' + (item.stepCode === "1009" ? "bgc17A840" : "bgcF1F8FF bd1 bdc007CE8")}>
                                        <span className={"SpoqaHanSansNeo-Medium fs12 ls06 " + (item.stepCode === "1009" ? "fcWhite" : "fc007CE8")}>{item.stepCode === "1009" ? "등록완료" : "진행중"}</span>
                                    </div>
                                )},
                                { type: 'text', fldNm: 'trsfAcqsDe', width: 100, headAlign: "center", dataAlign: "center", label: '취득일' },
                                { type: 'code', fldNm: 'estateDetailSe', width: 70, headAlign: "center", dataAlign: "center", label: '자산구분', codeTy: "1006" },
                                { type: 'code', fldNm: 'estateFom', width: 70, headAlign: "center", dataAlign: "center", label: '주택구분', codeTy: "1007" },
                                { type: 'code', fldNm: 'acqsSrc', width: 70, headAlign: "center", dataAlign: "center", label: '취득구분', codeTy: "1009" },
                                { type: 'code', fldNm: 'acqsCause', width: 70, headAlign: "center", dataAlign: "center", label: '취득원인', codeTy: "1035" },
                                // { type: 'comma', fldNm: 'stdrMktc', width: 100, headAlign: "center", dataAlign: "center", label: '기준시가' },
                                { type: 'comma', fldNm: 'acqsRlDlpc', width: 100, headAlign: "center", dataAlign: "center", label: '실거래가' },
                                // { type: 'comma', fldNm: 'evlamt', width: 100, headAlign: "center", dataAlign: "center", label: '평가액' },
                                // { type: 'text', fldNm: 'bsnsImpCnfmDe', width: 100, headAlign: "center", dataAlign: "center", label: '사업인가일' },
                                { type: 'text', fldNm: 'manageDspsPlanDe', width: 100, headAlign: "center", dataAlign: "center", label: '관리처분일' },
                                { type: 'text', fldNm: 'useConfmDe', width: 100, headAlign: "center", dataAlign: "center", label: '사용승인일' },
                                { type: 'text', fldNm: 'trnsfrnDe', width: 100, headAlign: "center", dataAlign: "center", label: '전입일' },
                                { type: 'text', fldNm: 'lvhsDe', width: 100, headAlign: "center", dataAlign: "center", label: '전출일' },
                                { type: 'code', fldNm: 'answerText54', width: 70, headAlign: "center", dataAlign: "center", label: '동거봉양', codeTy: "1063" },
                                { type: 'code', fldNm: 'answerText55', width: 70, headAlign: "center", dataAlign: "center", label: '혼인합가', codeTy: "1063" },
                                { type: 'text', fldNm: 'excptHouse', width: 150, headAlign: "center", dataAlign: "left", label: '특례주택' },
                                { type: 'text', fldNm: 'lawordNms', width: 130, headAlign: "center", dataAlign: "left", label: '근거법령' },
                                { type: 'text', fldNm: 'rdnmAdresDetail', wAuto: true, headAlign: "center", dataAlign: "left", label: '기타정보' }
                            ],
                            ordFld: { name: "trsfAcqsDe", direction: "desc" },
                            dataArr: assetsDataArr,
                            maxRowNums: [10, 20, 30],
                            trHeight: 48,
                            remainArea: false,
                            fixFldCnt: 5,
                            srchVsb: true,
                            paginateClass: "pt40",
                            headerVsb: true
                        }}/>
                    </div>
                </div>
            </div>
            <Modal modalOpen={taxInputModalOpen} setModalOpen={setTaxInputModalOpen} title="양도세 계산 - 양도정보 입력" dim={true} closeCallBack={() => moveStep(0)}>
                <TrsftaxInput
                    trsfAmt={trsfAmt}
                    trsfDe={trsfDe}
                    isPay={isPay}
                    payAmt={payAmt}
                    ncssryAmtBf={ncssryAmtBf}
                    ncssryAmt={ncssryAmt}
                    setTrsfAmt={setTrsfAmt}
                    setTrsfDe={setTrsfDe}
                    setIsPay={setIsPay}
                    setPayAmt={setPayAmt}
                    setNcssryAmtBf={setNcssryAmtBf}
                    setNcssryAmt={setNcssryAmt}
                    estateDetailSe={estateDetailSe}
                    acqsSrc={acqsSrc}
                    acqsCause={acqsCause}
                    moveStep={moveStep}
                    hshldMrrgAt={hshldMrrgAt}
                    hshldChbtSptAt={hshldChbtSptAt}
                />
            </Modal>
            <Modal modalOpen={trsftaxMrrgQestnModalOpen} setModalOpen={setTrsftaxMrrgQestnModalOpen} title="양도세 계산 - 혼인합가" dim={true} closeCallBack={() => moveStep(0)}>
                <TrsftaxMrrgQestn
                    moveStep={moveStep}
                    hshldChbtSptAt={hshldChbtSptAt}
                />
            </Modal>
            <Modal modalOpen={trsftaxChbtQestnModalOpen} setModalOpen={setTrsftaxChbtQestnModalOpen} title="양도세 계산 - 동거봉양합가" dim={true} closeCallBack={() => moveStep(0)}>
                <TrsftaxChbtQestn
                    moveStep={moveStep}
                    hshldMrrgAt={hshldMrrgAt}
                />
            </Modal>
            <Modal modalOpen={trsftaxCalcModalOpen} setModalOpen={setTrsftaxCalcModalOpen} title="양도세 세액 산출" dim={true} closeCallBack={() => moveStep(0)} visible={trsftaxCalcModalVisible}>
                <TrsftaxCalc
                    assetsSn={assetsSn}
                    trsfAmt={trsfAmt}
                    trsfDe={trsfDe}
                    isPay={isPay}
                    payAmt={payAmt}
                    ncssryAmtBf={ncssryAmtBf}
                    ncssryAmt={ncssryAmt}
                    setTrsftaxCalcModalOpen={setTrsftaxCalcModalOpen}
                    setTrsftaxCalcModalVisible={setTrsftaxCalcModalVisible}
                />
            </Modal>
        </React.Fragment>
    )
}
export default TrsftaxTest;