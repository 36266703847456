/*******************************************************************************
*** Inhetax ** 상속세 계산 자산리스트
*******************************************************************************/
import AppContext           from "js/common/AppContext";
import Axios                from "js/common/Axios";
import React                from "react";
import { useRecoilValue }   from "recoil";
import * as Atom            from "js/common/Atom";
import MuiListFilter        from "js/common/ui/list/MuiListFilter";
import ForestTableStyle     from 'js/common/ui/style/ForestStyle';
import Modal                from "js/common/ui/modal/Modal";
import AssetsShow           from "../assets/show/AssetsShow";
import InheTaxInfoOne       from "./InheTaxInfoOne";
import InheTaxInfoTwo       from "./InheTaxInfoTwo";
import InhetaxCalc          from "./InhetaxCalc";
import Util                 from "js/common/Util";

function Inhetax() {
    const hshldSn = useRecoilValue(Atom.hshldSn); // 고객(납세자) 일련번호
    const hshldNm = useRecoilValue(Atom.hshldNm); // 고객(납세자) 이름

    // 상속 1단계 입력 정보
    const [inherProp        , setInherProp        ] = React.useState<string>(""); // 01. 상속재산
    const [estiInher        , setEstiInher        ] = React.useState<string>(""); // 02. 추정상속가액
    const [trustAssets      , setTrustAssets      ] = React.useState<string>(""); // 03. 퇴직금/보험금/신탁자산
    const [spouse           , setSpouse           ] = React.useState<string>(""); // 04. 배우자 유무
    const [spousePreGift    , setSpousePreGift    ] = React.useState<string>(""); // 05. 배우자 사전증여 유무
    const [spousePreGiftProp, setSpousePreGiftProp] = React.useState<string>(""); // 06. 배우자 사전증여 재산가액
    const [spousePreGiftTax , setSpousePreGiftTax ] = React.useState<string>(""); // 07. 배우자 사전증여세액
    const [debtAmt          , setDebtAmt          ] = React.useState<string>(""); // 08. 채무
    const [utilityBill      , setUtilityBill      ] = React.useState<string>(""); // 09. 공과금
    const [funeralExpen     , setFuneralExpen     ] = React.useState<string>(""); // 10. 장례비용
    const [childNumber      , setChildNumber      ] = React.useState<string>(""); // 11. 자녀 수
    const [preGift          , setPreGift          ] = React.useState<string>(""); // 12. (자녀)사전증여 유무
    const [preGiftProp      , setPreGiftProp      ] = React.useState<string>(""); // 13. (자녀)사전증여 재산가액
    const [preGiftTax       , setPreGiftTax       ] = React.useState<string>(""); // 14. (자녀)사전증여세액

    // 상속 2단계 입력 정보
    const [deduCls          , setDeduCls          ] = React.useState<string>(""); // 15. 공제구분
    const [personDedu       , setPersonDedu       ] = React.useState<string>(""); // 16. 인적공제
    const [spouseDisabled   , setSpouseDisabled   ] = React.useState<string>(""); // 17. 배우자 장애인 여부
    const [spouseGender     , setSpouseGender     ] = React.useState<string>(""); // 18. 배우자 성별
    const [spouseAge        , setSpouseAge        ] = React.useState<string>(""); // 19. 배우자 나이
    const [spouseInherProp  , setSpouseInherProp  ] = React.useState<string>(""); // 20. 배우자 상속재산가액
    const [spouseSucUtilDebt, setSpouseSucUtilDebt] = React.useState<string>(""); // 21. 배우자 승계 공과금&채무
    const [directHeir       , setDirectHeir       ] = React.useState<string>(""); // 22. 직계존속 상속인 유무
    const [familyDedu       , setFamilyDedu       ] = React.useState<string>(""); // 23. 기업상속공제
    const [farmDedu         , setFarmDedu         ] = React.useState<string>(""); // 24. 영농상속공제
    const [houseDedu        , setHouseDedu        ] = React.useState<string>(""); // 25. 동거주택상속공제
    const [finanDedu        , setFinanDedu        ] = React.useState<string>(""); // 26. 금융상속공제

    const [assetsDataArr, setAssetsDataArr] = React.useState<any[]>([]); // 자산 List Arrary
    const [assetsSn     , setAssetsSn     ] = React.useState<number>( ); // 자산key

    // 상속 자산 시가표준액 정보
    interface inheAssetsInfoProps {
        assetSn   : number    // 자산일련번호
        stdMkPrice: number    // 시가표준액
    }

    const [inheAssetsInfo, setInheAssetsInfo] = React.useState<inheAssetsInfoProps[]>([]);  // 상속 자산별 시가표준액

    const [assetsModalOpen, setAssetsModalOpen] = React.useState<boolean>(false); // 보유자산 조회 모달

    const [inheInfoInOneModalOpen, setInheInfoInOneModalOpen] = React.useState<boolean>(false); // 상속 관련 정보 입력 1단계 모달
    const [inheInfoInTwoModalOpen, setInheInfoInTwoModalOpen] = React.useState<boolean>(false); // 상속 관련 정보 입력 2단계 모달
    const [inheTaxCalModalOpen   , setInheTaxCalModalOpen   ] = React.useState<boolean>(false); // 상속 세액 계산 결과 모달
    const [inheTaxCalcModalVsb   , setInheTaxCalcModalVsb   ] = React.useState<boolean>(false); // 상속세 간편계산기 결과 표시 여부

    // 보유 자산 리스트 조회
    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/assets/selAssetsList.do",
            methodType: "post",
            paramType: "json",
            paramData: {
                hshldSn: hshldSn,
                assetsSe: "1001",
                estateSttus: "1002",
                estateSe: "1001",
                routeKey: "trsftax"
            },
            onSuccessFn: (res: any) => {
                setAssetsDataArr(res.list);
                AppContext.hideSpinner();
            }
        });
    }, [hshldSn]);

    // 상속세 계산용 모달 창 오픈    
    const moveStep = (step: number) => {
        setInheInfoInOneModalOpen(false); // 상속 관련 정보 입력 1단계 모달
        setInheInfoInTwoModalOpen(false); // 상속 관련 정보 입력 2단계 모달
        setInheTaxCalModalOpen   (false); // 상속 세액 계산 결과 모달

        if (step === 1) {
            // 자산리스트 확인
            if (assetsDataArr.length === 0) {AppContext.openAlert({title: "보유자산 없음", msg: "상속세를 계산할 보유자산이 없습니다.", icon: "check"}); return;}

            setInheInfoInOneModalOpen(true);
        } else if (step === 2) {
            setInheInfoInTwoModalOpen(true);
        } else if (step === 3) {
            setInheTaxCalModalOpen(true);
        }
    }

    // 상속 자산 리스트
    const assetsDataFieldInfo = [
        { type: 'text', fldNm: 'rowNum'        , width:  65,  headAlign: "center", dataAlign: "center", label: 'No.'      },
        { type: 'text', fldNm: 'rmstnfamNm'    , width:  90,  headAlign: "center", dataAlign: "center", label: '세대원명' },
        { type: 'code', fldNm: 'estateDetailSe', width: 110,  headAlign: "center", dataAlign: "center", label: '자산구분', codeTy: "1006" },
        { type: 'code', fldNm: 'estatePrpos'   , width:  90,  headAlign: "center", dataAlign: "center", label: '주택용도', codeTy: "1008" },
        { type: 'code', fldNm: 'acqsCause'     , width: 100,  headAlign: "center", dataAlign: "center", label: '취득원인', codeTy: "1035" },
        { type: 'text', fldNm: 'fullRdnmAdres' , wAuto: true, headAlign: "center", dataAlign: "left"  , label: '주소'   },
        { type: 'text', fldNm: 'trsfAcqsDe'    , width: 100,  headAlign: "center", dataAlign: "center", label: '취득일' },
        { type: 'el'        , width: 70, headAlign: "center", el: (item: any) => (
            <div className='dpFlx aiC csP' onClick={() => {
                setAssetsSn(item.assetsSn);
                setAssetsModalOpen(true);
            }}>
                <img className="csP pr3" src={require('img/common/icon_copy20.png')} alt="icon_copy20" />
                <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">조회</span>
            </div>
        )}
    ]

    return (
        <React.Fragment>
            <div className="w100p bgcF6F6F6">
                <div className="dpFlx flxdCol jcC aiC pt60 pb30">
                    <div className='taC pb30'>
                        <span className="dpBlock fcBlack fs38 ls266 SpoqaHanSansNeo-Medium">상속세 계산</span>
                    </div>
                </div>
                <div className="dpFlx jcC pt30 pb100">
                    <div className='w1200'>
                        <div className='dpFlx h36 jcSb pb20'>
                            <div className='dpFlx aiE'>
                                <img className="mr10" src={require('img/common/icon_step.png')} alt="icon_step" />
                                <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 pb3'>
                                    <span className='fc17A840'>{hshldNm}</span>
                                    <span className='fc222222'>님의 보유자산</span>
                                </span>
                            </div>
                            <div className="w100 h40 bgcWhite bdrd40 dpFlx jcC aiC csP pl5 bd1 bdc17A840" onClick={() => moveStep(1)}>
                                <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc17A840'>세액 계산</span>
                                <img className="pl5" src={require('img/system/assets/icon_cal20_green.png')} alt="icon_cal20_green" />
                            </div>
                        </div>
                        <MuiListFilter tableInfo={{
                            tableHeadVsb: true,
                            styleType: ForestTableStyle,
                            fldInfs: assetsDataFieldInfo,
                            ordFld: { name: "rowNum", direction: "asc" },
                            dataArr: assetsDataArr,
                            maxRowNums: [10, 20, 30],
                            trHeight: 48,
                            remainArea: false,
                            // srchVsb: true,
                            paginateClass: "pt40",
                            headerVsb: true,
                        }}/>
                    </div>
                </div>
            </div>

            {/* 보유자산 조회 모달 */}
            <Modal modalOpen={assetsModalOpen} setModalOpen={setAssetsModalOpen} title="보유자산 조회" dim={true} closeCallBack={() => {
                setAssetsSn(undefined);
                setAssetsModalOpen(false);
            }}>
                <div className="w1200 h500 ofA">
                    <AssetsShow assetsSn={assetsSn} />
                </div>
                <div className='w100p dpFlx aiC jcC pt40'>
                    <button className='w210 h56 bgcF6F6F6 dpFlx aiC jcC csP bdN' onClick={() => {
                        setAssetsSn(undefined);
                        setAssetsModalOpen(false);
                    }}>
                        <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>닫기</span>
                    </button>
                </div>
            </Modal>

            {/* 증여세 관련 정보 입력 1단계 모달 */}
            <Modal modalOpen={inheInfoInOneModalOpen} setModalOpen={setInheInfoInOneModalOpen} title="상속세 계산 - 정보 입력" dim={true} closeCallBack={() => moveStep(0)}>
                <div className="w870 h550 ofA">
                    <InheTaxInfoOne 
                        inheTaxParam={assetsDataArr}
                        moveStep={moveStep}

                        inherProp         = {inherProp        } // 01. 상속재산
                        estiInher         = {estiInher        } // 02. 추정상속가액
                        trustAssets       = {trustAssets      } // 03. 퇴직금/보험금/신탁자산
                        spouse            = {spouse           } // 04. 배우자 유무
                        spousePreGift     = {spousePreGift    } // 05. 배우자 사전증여 유무
                        spousePreGiftProp = {spousePreGiftProp} // 06. 배우자 사전증여 재산가액
                        spousePreGiftTax  = {spousePreGiftTax } // 07. 배우자 사전증여세액
                        debtAmt           = {debtAmt          } // 08. 채무
                        utilityBill       = {utilityBill      } // 09. 공과금
                        funeralExpen      = {funeralExpen     } // 10. 장례비용
                        childNumber       = {childNumber      } // 11. 자녀 수
                        preGift           = {preGift          } // 12. (자녀)사전증여 유무
                        preGiftProp       = {preGiftProp      } // 13. (자녀)사전증여 재산가액
                        preGiftTax        = {preGiftTax       } // 14. (자녀)사전증여세액    
                        inheAssetsInfo    = {inheAssetsInfo   } // 상속 자산별 시가표준액
                        
                        setInherProp         = {setInherProp        }
                        setEstiInher         = {setEstiInher        }
                        setTrustAssets       = {setTrustAssets      }
                        setSpouse            = {setSpouse           }
                        setSpousePreGift     = {setSpousePreGift    }
                        setSpousePreGiftProp = {setSpousePreGiftProp}
                        setSpousePreGiftTax  = {setSpousePreGiftTax }
                        setDebtAmt           = {setDebtAmt          }
                        setUtilityBill       = {setUtilityBill      }
                        setFuneralExpen      = {setFuneralExpen     }
                        setChildNumber       = {setChildNumber      }
                        setPreGift           = {setPreGift          }
                        setPreGiftProp       = {setPreGiftProp      }
                        setPreGiftTax        = {setPreGiftTax       }   
                        setInheAssetsInfo    = {setInheAssetsInfo   }                    
                    />
                </div>
            </Modal>

            {/* 증여세 관련 정보 입력 2단계 모달 */}
            <Modal modalOpen={inheInfoInTwoModalOpen} setModalOpen={setInheInfoInTwoModalOpen} title="상속세 계산 - 정보 입력" dim={true} closeCallBack={() => moveStep(0)}>
                <div className="w870 ofA">
                    <InheTaxInfoTwo 
                        moveStep={moveStep}

                        spouse            = {spouse           }   // 04. 배우자 유무
                        childNumber       = {childNumber      }   // 11. 자녀 수
                        deduCls           = {deduCls          }   // 15. 공제구분
                        personDedu        = {personDedu       }   // 16. 인적공제
                        spouseDisabled    = {spouseDisabled   }   // 17. 배우자 장애인 여부
                        spouseGender      = {spouseGender     }   // 18. 배우자 성별
                        spouseAge         = {spouseAge        }   // 19. 배우자 나이
                        spouseInherProp   = {spouseInherProp  }   // 20. 배우자 상속재산가액
                        spouseSucUtilDebt = {spouseSucUtilDebt}   // 21. 배우자 승계 공과금&채무
                        directHeir        = {directHeir       }   // 22. 직계존속 상속인 유무
                        familyDedu        = {familyDedu       }   // 23. 기업상속공제
                        farmDedu          = {farmDedu         }   // 24. 영농상속공제
                        houseDedu         = {houseDedu        }   // 25. 동거주택상속공제
                        finanDedu         = {finanDedu        }   // 26. 금융상속공제
                    
                        setDeduCls           = {setDeduCls          }
                        setPersonDedu        = {setPersonDedu       }
                        setSpouseDisabled    = {setSpouseDisabled   }
                        setSpouseGender      = {setSpouseGender     }
                        setSpouseAge         = {setSpouseAge        }
                        setSpouseInherProp   = {setSpouseInherProp  }
                        setSpouseSucUtilDebt = {setSpouseSucUtilDebt}
                        setDirectHeir        = {setDirectHeir       }
                        setFamilyDedu        = {setFamilyDedu       }
                        setFarmDedu          = {setFarmDedu         }
                        setHouseDedu         = {setHouseDedu        }
                        setFinanDedu         = {setFinanDedu        }                    
                    />
                </div>
            </Modal>

            {/* 증여세 세액 계산 결과 모달 */}
            <Modal modalOpen={inheTaxCalModalOpen} setModalOpen={setInheTaxCalModalOpen} title="상속세 결과" dim={true} visible={inheTaxCalcModalVsb} closeCallBack={() => {
                setInheTaxCalcModalVsb(false);
                moveStep(0);
            }}>
                <div className="w900 h600 ofA">
                    <InhetaxCalc 
                        setInheTaxCalcModalVsb={setInheTaxCalcModalVsb}
                        moveStep={moveStep}

                        inherProp         = {Util.uncomma(inherProp)        } // 01. 상속재산
                        estiInher         = {Util.uncomma(estiInher)        } // 02. 추정상속가액
                        trustAssets       = {Util.uncomma(trustAssets)      } // 03. 퇴직금/보험금/신탁자산
                        spouse            = {spouse                         } // 04. 배우자 유무
                        spousePreGift     = {spousePreGift                  } // 05. 배우자 사전증여 유무
                        spousePreGiftProp = {Util.uncomma(spousePreGiftProp)} // 06. 배우자 사전증여 재산가액
                        spousePreGiftTax  = {Util.uncomma(spousePreGiftTax) } // 07. 배우자 사전증여세액
                        debtAmt           = {Util.uncomma(debtAmt)          } // 08. 채무
                        utilityBill       = {Util.uncomma(utilityBill)      } // 09. 공과금
                        funeralExpen      = {Util.uncomma(funeralExpen)     } // 10. 장례비용
                        childNumber       = {childNumber                    } // 11. 자녀 수
                        preGift           = {preGift                        } // 12. (자녀)사전증여 유무
                        preGiftProp       = {Util.uncomma(preGiftProp)      } // 13. (자녀)사전증여 재산가액
                        preGiftTax        = {Util.uncomma(preGiftTax)       } // 14. (자녀)사전증여세액   
                        deduCls           = {deduCls                        } // 15. 공제구분
                        personDedu        = {Util.uncomma(personDedu)       } // 16. 인적공제
                        spouseDisabled    = {spouseDisabled                 } // 17. 배우자 장애인 여부
                        spouseGender      = {spouseGender                   } // 18. 배우자 성별
                        spouseAge         = {spouseAge                      } // 19. 배우자 나이
                        spouseInherProp   = {Util.uncomma(spouseInherProp)  } // 20. 배우자 상속재산가액
                        spouseSucUtilDebt = {Util.uncomma(spouseSucUtilDebt)} // 21. 배우자 승계 공과금&채무
                        directHeir        = {Util.uncomma(directHeir)       } // 22. 직계존속 상속인 유무
                        familyDedu        = {Util.uncomma(familyDedu)       } // 23. 기업상속공제
                        farmDedu          = {Util.uncomma(farmDedu)         } // 24. 영농상속공제
                        houseDedu         = {Util.uncomma(houseDedu)        } // 25. 동거주택상속공제
                        finanDedu         = {Util.uncomma(finanDedu)        } // 26. 금융상속공제                                               
                    />
                </div>
            </Modal>

        </React.Fragment>    
    )
}
export default Inhetax;  