/*******************************************************************************
*** Footer ** Footer
*******************************************************************************/
import AppContext from 'js/common/AppContext';
import { useNavigate } from "react-router-dom";

function Footer() {
    const navigate = useNavigate();

    return (
        <div className="w100p mw1210 bgc2B2B2B dpFlx jcC pb78 bdBox">
            <div className="w1150 fs0">
                <div className="pt20 taC">
                    <span className="SpoqaHanSansNeo-Regular fcBDBDBD fs14 ls07 lh21">
                        콜택스 프로그램은 참고용이므로 세무신고 등은 각 분야의 전문가에게 의뢰하시기 바랍니다.<br/>
                        ㈜콜택스는 본 프로그램에서 얻은 정보로 인해 발생할 수 있는 어떠한 손해에 대하여 법적책임을 부담하지 않음을 알려드립니다.<br/>
                    </span>
                </div>
                <div className="pt30 dpFlx">
                    <div className="w219 h156 bdt1 bdc999999 mr91 pt10 bdBox">
                        <span className="ReplicaStdBold fs20 ls1 fcWhite">QUICK LINK</span>
                    </div>
                    <div className="w219 h156 bdt1 bdc999999 mr91 pt12">
                        <div onClick={() => AppContext.goPage("/system/hshld")} className="w50p h20 float-l csP"><span className="SpoqaHanSansNeo-Medium fcWhite fs16 ls08 tuOs2 tdU-hover">납세자관리</span></div>
                        <div onClick={() => AppContext.goPage("/system/expert")} className="w50p h20 float-l csP"><span className="SpoqaHanSansNeo-Medium fcWhite fs16 ls08 tuOs2 tdU-hover">전문가커뮤니티</span></div>
                        <div onClick={() => AppContext.goPage("/system/proptax")} className="w50p h20 float-l csP pt10"><span className="SpoqaHanSansNeo-Medium fcWhite fs16 ls08 tuOs2 tdU-hover">보유관리</span></div>
                        <div onClick={() => AppContext.goPage("/portal/notice")} className="w50p h20 float-l csP pt10"><span className="SpoqaHanSansNeo-Medium fcWhite fs16 ls08 tuOs2 tdU-hover">공지사항</span></div>
                        <div onClick={() => AppContext.goPage("/system/acqstax")} className="w50p h20 float-l csP pt10"><span className="SpoqaHanSansNeo-Medium fcWhite fs16 ls08 tuOs2 tdU-hover">취득관리</span></div>
                        <div onClick={() => AppContext.goPage("/portal/intrcn")} className="w50p h20 float-l csP pt10"><span className="SpoqaHanSansNeo-Medium fcWhite fs16 ls08 tuOs2 tdU-hover">서비스소개</span></div>
                        <div onClick={() => AppContext.goPage("/system/trsftax")} className="w50p h20 float-l csP pt10"><span className="SpoqaHanSansNeo-Medium fcWhite fs16 ls08 tuOs2 tdU-hover">처분관리</span></div>
                    </div>
                    <div className="w530 h156 bdt1 bdc999999 pt12">
                        <div className="w60p h100 float-l">
                            <span className="SpoqaHanSansNeo-Regular fc999999 fs14 ls07 lh21">
                                <span>(우)03938 서울특별시 마포구 모래내로 3길 11<br/>
                                상암미르웰한올림 219호(성산동 593-5)</span><br/>
                                <span>주식회사 콜택스</span><span className='ml10 mr10'>·</span><span>콜택스 세무회계</span><br/>
                                <span>대표자</span><span className='ml10'>홍진영</span>
                                <span className='ml30'>대표전화</span><span className='ml10'>02-308-8762</span><br/><br/>
                                <span>사업자등록번호</span><br/>
                                <span>171-88-02803</span><span className='ml10 mr10'>·</span><span>355-07-02983</span>
                            </span>
                        </div>
                        <div className="w40p h100 float-l">
                            <div className="dpFlx aiC h20 csP" onClick={()=>navigate("/portal/personalinfo")}>
                                <span className="SpoqaHanSansNeo-Regular fc999999 fs14 ls07 tdU tuOs2 fcWhite-hover">개인정보취급방침</span>
                                <span className="SpoqaHanSansNeo-Regular fs19 fcWhite pl3">+</span>
                            </div>
                            <div className="dpFlx aiC h20 csP pt3" onClick={()=>navigate("/portal/term")}>
                                <span className="SpoqaHanSansNeo-Regular fc999999 fs14 ls07 tdU tuOs2 fcWhite-hover">이용약관</span>
                                <span className="SpoqaHanSansNeo-Regular fs19 fcWhite pl3">+</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dpFlx jcSb">
                    <div>
                        <span className="ReplicaStdBold fs38 ls19 lh36">
                            <span className="fc828282">C</span>
                            <span className="fc4E4E4E">all</span>
                            <span className="fc828282">T</span>
                            <span className="fc4E4E4E">ax</span>
                            <span className="ReplicaStdRegular fs15 ls1 fc828282 pl5"></span><br/>
                        </span>
                        <span className="ReplicaStdLight fc999999 fs12 pl2 lh18">COPYRIGHT © 2023.CallTax</span>
                    </div>
                    <div className="dpFlx pt20">
                        <div className="w840 bdt1 bdc999999 pt12 dpFlx jcL">
                            {/* 홈택스 */}
                            <div className="dpFlx"
                                 onClick={() => {
                                    window.open("https://www.hometax.go.kr", "_blank", "noopener");
                                }}
                            >
                                <img className="mr15 csP" src={require('img/layout/footer/img_logo_hometax.png')} alt="img_logo_hometax" />
                            </div>
                            {/* 국토교통부 */}
                            <div className="dpFlx"
                                 onClick={() => {
                                    window.open("https://www.molit.go.kr", "_blank", "noopener");
                                }}
                            >
                                <img className="mr15 csP" src={require('img/layout/footer/img_logo_molit.png')} alt="img_logo_molit" />
                            </div>
                            {/* 인터넷등기소 */}
                            <div className="dpFlx"
                                 onClick={() => {
                                    window.open("http://www.iros.go.kr", "_blank", "noopener");
                                }}
                            >
                                <img className="mr15 csP" src={require('img/layout/footer/img_logo_iros.png')} alt="img_logo_iros" />
                            </div>
                            {/* 서울시ETAX */}
                            <div className="dpFlx"
                                 onClick={() => {
                                    window.open("https://etax.seoul.go.kr", "_blank", "noopener");
                                }}
                            >
                                <img className="mr15 csP" src={require('img/layout/footer/img_logo_seoultax.png')} alt="img_logo_seoultax" />
                            </div>
                            {/* 정부24 */}
                            <div className="dpFlx"
                                 onClick={() => {
                                    window.open("https://www.gov.kr", "_blank", "noopener");
                                }}
                            >
                                <img className="mr15 csP" src={require('img/layout/footer/img_logo_24.png')} alt="img_logo_24" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer;