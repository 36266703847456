import { useState, useEffect } from "react";
import { produce } from "immer";
import { useRecoilState } from "recoil";

import {
  addrSearchResultState,
  acquiredAssetInfoState,
  directAddrInfoState,
} from "js/common/Atom";
import { AddrSearchResult, Housing, Juso, Step } from "js/common/types/types";
import Util from "js/common/Util";

const stepList: Step[] = [
  {
    stepId: "ADDR01",
    name: "주소 입력",
    order: 1,
    useYn: true,
    selected: true,
    subSteps: [],
  },
  {
    stepId: "ADDR02",
    name: "상세 주소 입력",
    order: 2,
    useYn: true,
    selected: false,
    subSteps: [],
  },
  {
    stepId: "ADDR03",
    name: "최종 주소 확인",
    order: 3,
    useYn: true,
    selected: false,
    subSteps: [],
  },
];

export function useAddressInfo(
  onAddHousing: (housing: Housing) => void,
  apiStatus: string
) {
  const [addrSearchResult, setAddrSearchResult] = useRecoilState(
    addrSearchResultState
  );
  const [acquiredAssetInfo, setAcquiredAssetInfo] = useRecoilState(
    acquiredAssetInfoState
  );
  const [directAddrInfo, setDirectAddrInfo] =
    useRecoilState(directAddrInfoState);

  const [addrStepList, setAddrStepList] = useState<Step[]>(stepList);
  const [searchText, setSearchText] = useState<string>("");
  const [searchResult, setSearchResult] = useState<Juso[]>([]);
  const [nextDisabled, setNextDisabled] = useState<boolean>(true);

  const currentStep = addrStepList.find((step: Step) => step.selected);
  const isLastStep: boolean =
    (currentStep && currentStep.stepId === "ADDR03") || apiStatus === "-1"
      ? true
      : false;

  useEffect(() => {
    // 단계가 바뀌면 next 버튼 비활성화
    setNextDisabled(true);
  }, [currentStep]);

  useEffect(() => {
    // 주소 검색 API 사용
    // next 버튼 활성화 여부 확인
    if (!currentStep || apiStatus === "-1") return;

    switch (currentStep.stepId) {
      case "ADDR01": {
        // 주소검색
        setNextDisabled(true);
        break;
      }
      case "ADDR02": {
        // 동/호 정보 입력
        if (addrSearchResult.addr) {
          if (addrSearchResult.bdType === "dongHo") {
            if (addrSearchResult.dong !== "" && addrSearchResult.ho !== "") {
              setNextDisabled(false);
            } else {
              setNextDisabled(true);
            }
          } else if (addrSearchResult.bdType === "multiHo") {
            if (addrSearchResult.ho !== "") {
              setNextDisabled(false);
            } else {
              setNextDisabled(true);
            }
          } else if (addrSearchResult.bdType === "none") {
            setNextDisabled(false);
          } else {
            setNextDisabled(true);
          }
        } else {
          setNextDisabled(true);
        }
        break;
      }
      case "ADDR03": {
        if (addrSearchResult.addr) {
          if (addrSearchResult.addr.bdKdcd === "0") {
            // 단독주택
            if (
              acquiredAssetInfo.housePc !== "" &&
              acquiredAssetInfo.spfcKnd &&
              acquiredAssetInfo.mdatTrgetAreaAt !== "" &&
              acquiredAssetInfo.shareRatio !== "" &&
              parseInt(acquiredAssetInfo.shareRatio) > 0 &&
              parseInt(acquiredAssetInfo.shareRatio) <= 100
            ) {
              setNextDisabled(false);
            } else {
              setNextDisabled(true);
            }
          } else if (addrSearchResult.addr.bdKdcd === "1") {
            // 공동주택
            if (
              acquiredAssetInfo.pblntfPc !== "" &&
              acquiredAssetInfo.spfcKnd &&
              acquiredAssetInfo.mdatTrgetAreaAt !== "" &&
              acquiredAssetInfo.shareRatio !== "" &&
              parseInt(acquiredAssetInfo.shareRatio) > 0 &&
              parseInt(acquiredAssetInfo.shareRatio) <= 100
            ) {
              setNextDisabled(false);
            } else {
              setNextDisabled(true);
            }
          }
        }
        break;
      }
    }
  }, [currentStep, addrSearchResult, acquiredAssetInfo, apiStatus]);

  useEffect(() => {
    // 주소 직접입력
    // next 버튼 활성화 여부 확인
    if (!currentStep || apiStatus !== "-1") return;

    /* 주소 직접입력 단계 확인*/
    const checkDirectAddrInfo = (): boolean => {
      let checkResult = true;

      if (Util.isEmpty(directAddrInfo.sidoCode)) {
        checkResult = false;
      }

      if (Util.isEmpty(directAddrInfo.sggCode)) {
        checkResult = false;
      }

      if (Util.isEmpty(directAddrInfo.emdCode)) {
        checkResult = false;
      }

      if (Util.isEmpty(directAddrInfo.rdnmAdresDetail)) {
        checkResult = false;
      }

      if (Util.isEmpty(directAddrInfo.mdatTrgetAreaAt)) {
        checkResult = false;
      }

      if (Util.isEmpty(directAddrInfo.shareRatio)) {
        checkResult = false;
      }

      if (
        Number(directAddrInfo.shareRatio) <= 0 ||
        Number(Util.uncomma(directAddrInfo.shareRatio)) > 100
      ) {
        checkResult = false;
      }

      return checkResult;
    };

    const checkResult: boolean = checkDirectAddrInfo();
    // checkResult true이면 주소정보 입력 완료
    setNextDisabled(!checkResult);
  }, [currentStep, directAddrInfo, apiStatus]);

  const moveToNextStep = () => {
    setAddrStepList(
      produce((draft: Step[]) => {
        const selectedIndex = draft.findIndex((step) => step.selected);
        if (selectedIndex !== -1 && selectedIndex < draft.length - 1) {
          draft[selectedIndex].selected = false;
          draft[selectedIndex + 1].selected = true;
        }
      })
    );
  };

  const moveToBackStep = () => {
    setAddrStepList(
      produce((draft: Step[]) => {
        const selectedIndex = draft.findIndex((step) => step.selected);
        if (selectedIndex !== -1 && selectedIndex > 0) {
          draft[selectedIndex].selected = false;
          draft[selectedIndex - 1].selected = true;
        }
      })
    );
  };

  const handleClickNextStep = () => {
    if (!currentStep) return;

    if (apiStatus !== "-1") {
      if (currentStep.stepId !== "ADDR03") {
        moveToNextStep();
      } else {
        // 최종주소입력 완료 시
        if (addrSearchResult.addr) {
          // 직접 입력한 주소의 PNU 코드를 유일한 값으로 설정하기 위해 사용
          const now = new Date();

          let housePrice = "";
          if (addrSearchResult.addr.bdKdcd === "1") {
            // 공동주택 여부
            housePrice = acquiredAssetInfo.pblntfPc;
          } else if (addrSearchResult.addr.bdKdcd === "0") {
            housePrice = acquiredAssetInfo.housePc;
          }

          const housing: Housing = {
            pnu:
              addrSearchResult.addr.pnu +
              Util.lpad(now.getMinutes().toString(), 2, "0") +
              Util.lpad(now.getSeconds().toString(), 2, "0"),
            rdnmAdres: `${addrSearchResult.addr.roadAddr} ${
              addrSearchResult.dong
                ? addrSearchResult.dong.replace(/동/g, "") + "동"
                : ""
            } ${
              addrSearchResult.ho
                ? addrSearchResult.ho.replace(/호/g, "") + "호"
                : ""
            }`,
            lnmAdres: addrSearchResult.addr.jibunAddr,
            spfcKnd: acquiredAssetInfo.spfcKnd,
            stdPrice: housePrice,
            mdatTrgetAreaAt: acquiredAssetInfo.mdatTrgetAreaAt,
            shareRatio: acquiredAssetInfo.shareRatio,
          };

          onAddHousing(housing);
          initAddrDialog();
        }
      }
    } else {
      // 주소 API 호출 오류 -> 주소 직접 입력
      // 직접 입력한 주소의 PNU 코드를 유일한 값으로 설정하기 위해 사용
      const now = new Date();

      let housePrice = "";
      if (directAddrInfo.bdKnd === "1") {
        // 공동주택 여부
        housePrice = directAddrInfo.pblntfPc;
      }

      const housing: Housing = {
        pnu:
          directAddrInfo.sidoCode +
          directAddrInfo.sggCode +
          directAddrInfo.emdCode +
          Util.lpad(now.getMinutes().toString(), 2, "0") +
          Util.lpad(now.getSeconds().toString(), 2, "0"),
        rdnmAdres: `${directAddrInfo.sidoName} ${directAddrInfo.sggName} ${directAddrInfo.emdName} ${directAddrInfo.rdnmAdresDetail}`,
        lnmAdres: "",
        spfcKnd: directAddrInfo.spfcKnd,
        stdPrice: housePrice,
        mdatTrgetAreaAt: directAddrInfo.mdatTrgetAreaAt,
        shareRatio: directAddrInfo.shareRatio,
      };

      onAddHousing(housing);
      initAddrDialog();
    }
  };

  const initAddrDialog = () => {
    onResetInAddresInfo();
    setAddrStepList(stepList);
    setSearchText("");
  };

  const handleClickBackStep = () => {
    if (!currentStep) return;

    if (currentStep.stepId === "ADDR02") {
      onResetInDongHoSelect();
      moveToBackStep();
    } else if (currentStep.stepId === "ADDR03") {
      onResetInFinalAddrInfo();
      moveToBackStep();
    }
  };

  const onResetInAddresInfo = () => {
    setAddrSearchResult((prev: AddrSearchResult) => ({
      ...prev,
      addr: null,
      dong: "",
      ho: "",
      bdType: "",
    }));

    setAcquiredAssetInfo({
      spfcKnd: "", // 용도지역
      mdatTrgetAreaAt: "", // 조장지역여부
      pblntfPc: "", // 공동주택가격
      housePc: "", // 개별주택갸격
      shareRatio: "", // 지분율
      apprDateNewHse: "", // 사용승인일
      prvuseAr: "", // 공동주택 전용면적
      houseTotar: "", // 단독주택 전용면적
    });

    setDirectAddrInfo({
      bdKnd: "1",
      sidoCode: "", // 시도정보
      sggCode: "", // 시군구정보
      sggCodeElVsb: true, // 시군구정보 표시여부
      emdCode: "", // 읍면동 정보
      sidoName: "", // 시도
      sggName: "", //시군구
      emdName: "", //읍면동
      sggCodeInfoList: [],
      emdCodeInfoList: [],
      rdnmAdresDetail: "", // 상세주소
      spfcKnd: "", // 용도지역
      mdatTrgetAreaAt: "", // 조장지역여부
      pblntfPc: "", // 공동주택가격
      shareRatio: "", // 지분율
      prvuseAr: "", // 공동주택 전용면적
    });
  };

  const onResetInDongHoSelect = () => {
    // 상세주소입력 단계에서 선택 초기화 -> 상세주소입력, 최종주소입력 단계의 input 모두 초기화
    setAddrSearchResult((prev: AddrSearchResult) => ({
      ...prev,
      addr: null,
      dong: "",
      ho: "",
      bdType: "",
    }));

    setAcquiredAssetInfo({
      spfcKnd: "", // 용도지역
      mdatTrgetAreaAt: "", // 조장지역여부
      pblntfPc: "", // 공동주택가격
      housePc: "", // 개별주택갸격
      shareRatio: "", // 지분율
      apprDateNewHse: "", // 사용승인일
      prvuseAr: "", // 공동주택 전용면적
      houseTotar: "", // 단독주택 전용면적
    });
  };

  const onResetInFinalAddrInfo = () => {
    // 최종주소입력 단계에서 초기화
    setAcquiredAssetInfo({
      spfcKnd: "", // 용도지역
      mdatTrgetAreaAt: "", // 조장지역여부
      pblntfPc: "", // 공동주택가격
      housePc: "", // 개별주택갸격
      shareRatio: "", // 지분율
      apprDateNewHse: "", // 사용승인일
      prvuseAr: "", // 공동주택 전용면적
      houseTotar: "", // 단독주택 전용면적
    });
  };

  const handleClickReset = () => {
    if (!currentStep) return;

    onResetInAddresInfo();
    setSearchText("");

    // 첫번째 단계로 이동
    setAddrStepList(
      produce((draft: Step[]) => {
        const selectedIndex = draft.findIndex((step) => step.selected);

        if (selectedIndex !== -1) {
          draft[selectedIndex].selected = false;
          draft[0].selected = true;
        }
      })
    );
  };

  return {
    currentStep,
    searchText,
    searchResult,
    nextDisabled,
    isLastStep,
    setAddrStepList,
    setSearchText,
    setSearchResult,
    handleClickBackStep,
    handleClickNextStep,
    handleClickReset,
    setAddrSearchResult,
    setAcquiredAssetInfo,
    initAddrDialog,
  };
}
