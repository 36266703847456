import React from "react";
import TextBox from "./TextBox";
import IconButton from "../button/IconButton";
import Util from "js/common/Util";
import AppContext from "js/common/AppContext";

interface FileBoxProps {
    width: number
    height: number
    fontSize: number
    file?: File
    setFile: React.Dispatch<React.SetStateAction<File | undefined>>
}
const FileBox = ({ width, height, fontSize, file, setFile }: FileBoxProps) => {
    const fileInput = React.useRef<HTMLInputElement>(null);

    const onCickFileUpload = () => {
        if (!fileInput.current) {
            return;
        }
        fileInput.current.click();
    };

    const onCickFileDelete = () => {
        setFile(undefined);
        if (!fileInput.current) {
            return;
        }
        fileInput.current.value = "";
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files === null) {
            return;
        }

        // 파일 최대 사이즈 초과여부 체크
        if (AppContext.maxfileSize < event.target.files[0].size) {
            AppContext.openAlert({
                title: "파일 용량 초과",
                msg: "파일 업로드 최대 허용 용량은 20MB 입니다. [선택파일 용량 : " + Math.floor(event.target.files[0].size / 1024 / 1024) + "MB]",
                icon: "error"
            });
            onCickFileDelete();
            return;
        }
        const extNm = event.target.files[0].name.substring(event.target.files[0].name.lastIndexOf(".") + 1).toLowerCase();
        if (AppContext.fileWhiteExt.indexOf(extNm) === -1) {
            AppContext.openAlert({
                title: "파일 확장자 체크",
                msg: extNm + " 확장자는 업로드 불가한 확장자의 파일입니다.",
                icon: "error"
            });
            onCickFileDelete();
            return;
        }
        setFile(event.target.files[0]);
    };
    return (
        <div className="dpFlx">
            <input type="file" style={{ display: "none" }} ref={fileInput} onChange={onChange} />
            <TextBox
                value={file === undefined ? "" : file.name}
                width={width}
                height={height}
                fontSize={fontSize}
                fontFamily="SpoqaHanSansNeo-Regular"
                color="#222222"
                offColor="#666666"
                className="bd1 pl15 pr15 bgcWhite mr3"
                inputClassName="ls075"
                mode="readonly"
                placeholder="첨부파일을 등록하세요."
            />
            <IconButton
                btnName="파일찾기"
                iconPos="right"
                iconNm="file"
                className="h40 bgc17A840 pl20 pr20 mr10"
                textClassName="fs14 fcWhite SpoqaHanSansNeo-Medium ls07 fw500 pr4"
                onClick={onCickFileUpload}
            />
            <div className={"h40 pl20 pr20 aiC jcC bd1 bdc17A840 bdBox bgcWhite csP " + (Util.isEmpty(file) ? "dpNone" : "dpFlx")} onClick={onCickFileDelete}>
                <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc17A840 fw500">삭제</span>
            </div>
        </div>
    );
};
export default FileBox;