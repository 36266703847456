import React from "react";
import { useWizard } from "use-wizard";
import FormReducer from 'js/common/FormReducer';
import AddressSearch from "./AddressSearch";
import AddressDong from "./AddressDong";
import AddressEtc from "./AddressEtc";

function Address({ setModalOpen, callBack }: any) {
    const [step, wizard] = useWizard();
    const [form, dispatchForm] = React.useReducer(FormReducer, {
        callBack: callBack,
        addrSrchDataArr: []
    });

    const WizardStep = () => {
        switch (step) {
            case 1:
                return <AddressSearch {...{ step, wizard, form, dispatchForm }} />;
            case 2:
                return <AddressDong {...{ step, wizard, form, dispatchForm }} />;
            case 3:
                return <AddressEtc {...{ step, wizard, form, dispatchForm, callBack, setModalOpen }} />;
            default:
                return <>페이지가 존재하지 않습니다. 다시 시도해 주세요.</>;
        }
    }

    return (
        <div className="w475 bdBox">
            <WizardStep />
        </div>
    )
}
export default Address;