/***********************************************************************************
*** Law38at10 ** 소득세법 156조 2 제7항 공동상속조합원입주권-주된지분자 Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law38at10Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}
export default function Law38at10({ setModalOpen, setLawModalTitle, callBack, callMode } : Law38at10Props) {
    React.useEffect(() => {
        setLawModalTitle("소득세법 156조 2 제7항 공동상속조합원입주권-주된지분자");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className="m0 mt15 mb30">
                <span className="fs15 lh25 ls06 SpoqaHanSansNeo-Regular">※ 추가 확인사항<br /></span>
                <span className="lh20 fs14">
                    (1) 피상속인이 상속개시일 현재 주택이 없는 경우의 조합원입주권<br />
                    (2) 피상속인이 상속개시일 현재 분양권이 없는 경우의 조합원입주권<br />
                    (3) 피상속인이 상속개시일 현재 주택을 소유하지 않고 조합원입주권과 분양권만 소유한 경우에 조합원입주권을 선택하는 경우<br />
                </span>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}
