/***********************************************************************************
*** Law14 ** 조세특례제한법 98조의 2 지방 미분양주택 취득에 대한 양도소득세 등 과세특례 Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law14Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}

export default function Law14({ setModalOpen, setLawModalTitle, callBack, callMode }: Law14Props) {
    React.useEffect(() => {
        setLawModalTitle("조세특례제한법 98조의 2 지방 미분양주택 취득에 대한 양도소득세 등 과세특례");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className='w100p hAuto ofA mb30'>
                <table className={
                    'forest-list-table bdt1 bdb1 bdcDDDDDD ' +
                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075'
                }>
                   <colgroup>
                        <col className='w80'></col>
                        <col className='w100'></col>
                        <col className='w60'></col>
                        <col className='w120'></col>
                        <col className='w60'></col>
                        <col className='w60'></col>
                        <col className='w100'></col>
                        <col className='w100'></col>
                        <col className='w60'></col>
                        <col className='w100'></col>
                        <col className='w160'></col>
                    </colgroup>
                    <tbody>
                        <tr className='h40'>
                            <th rowSpan={2}>구분</th>
                            <th rowSpan={2}>주택구분</th>
                            <th rowSpan={2}>주택규모</th>
                            <th rowSpan={2}>취득시기</th>
                            <th rowSpan={2}>지역기준</th>
                            <th colSpan={5}>추가질문</th>
                            <th rowSpan={2}>혜택</th>
                        </tr>
                        <tr>
                            <th>최초계약</th>
                            <th>보유기간 및 임대기간</th>
                            <th>미분양시점</th>
                            <th>입주사실</th>
                            <th className='bdr1-i bdcDDDDDD-i'>필수서류</th>
                        </tr>
                        <tr>
                            <th rowSpan={2}>개별사항</th>
                            <td>분양권에 의한 아파트 중 미분양</td>
                            <td rowSpan={2}>-</td>
                            <td>2008.11.3.~2010.12.31<br/>(계약체결+계약금지급포함)</td>
                            <td rowSpan={2}>비수도권</td>
                            <td rowSpan={2}>여</td>
                            <td rowSpan={2}>-</td>
                            <td>2008.11.2. 현재 미분양주택</td>
                            <td rowSpan={2}>-</td>
                            <td rowSpan={2}>지자체 확인서 및 지자체 날인 매매계약서</td>
                            <td rowSpan={2}>양도소득세 기본세율 적용하고 1세대 1주택자 장기보유특별공제율(80%) 적용<br/>부동산매매업자에 대해서도 기본세율 적용</td>
                        </tr>
                        <tr>
                            <td>분양권에 의한 아파트 중 신규분양</td>
                            <td>2008.11.3.~2010.12.31.<br/>(계약체결+계약금지급포함)</td>
                            <td className='bdr1-i bdcDDDDDD-i'>2008.11.3. 현재 신규분양주택</td>
                        </tr>
                        <tr className='h110'>
                            <th>공통사항</th>
                            <td className='taL pt15 pb15 pl15'colSpan={10}>
                                1. 비과세 판정시 주택수 제외<br/>
                                2. 다주택 중과배제(유예)<br/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}