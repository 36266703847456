import React from "react";
import { useRecoilValue } from "recoil";
import * as Atom from "js/common/Atom";
import MuiListFilter from 'js/common/ui/list/MuiListFilter';
import ForestTableStyle from 'js/common/ui/style/ForestStyle';
import AppContext from "js/common/AppContext";
import Axios from "js/common/Axios";
import Modal from 'js/common/ui/modal/Modal';
import ProptaxCalc from "./ProptaxCalc";
import NumberTextBox from "js/common/ui/input/NumberTextBox";
import AssetsShow from "../assets/show/AssetsShow";
import Util from "js/common/Util";
import AssetsUtil from "js/common/AssetsUtil";

function Proptax() {
    const hshldSn = useRecoilValue(Atom.hshldSn);
    const hshldNm = useRecoilValue(Atom.hshldNm);
    const [assetsDataArr, setAssetsDataArr] = React.useState<[]>([]);
    const [taxInputModalOpen, setTaxInputModalOpen] = React.useState<boolean>(false); // 전년도 재산정보 입력 모달
    const [lstyrProptax, setLstyrProptax] = React.useState<string>("");
    const [lstyrUrbantax, setLstyrUrbantax] = React.useState<string>("");
    const [assetsSn, setAssetsSn] = React.useState<number>();
    const [proptaxCalcModalOpen, setProptaxCalcModalOpen] = React.useState<boolean>(false); // 재산세 결과 조회 모달
    const [proptaxCalcModalVsb, setProptaxCalcModalVsb] = React.useState<boolean>(false); // 양도세 결과 조회 표출
    const [assetsModalOpen, setAssetsModalOpen] = React.useState<boolean>(false); // 보유자산 조회 모달
    const [pblntfPc, setPblntfPc] = React.useState<string>("0"); // 기준시가

    const propTaxInputBind = (item: any) => {
        AppContext.showSpinnerDim();
        setAssetsSn(item.assetsSn);
        setLstyrProptax("0");
        setLstyrUrbantax("0");
        setPblntfPc("0");
        setTaxInputModalOpen(true);

        Axios.dataAccess({
            url: "system/assets/selAssetsEstateInfo.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                assetsSn: item.assetsSn
            },
            onSuccessFn: (res: any) => {
                let tailUrl;
                if (res.item.bdKnd === "1") {
                    tailUrl = "apt/price2";
                } else if (res.item.bdKnd === "0") {
                    tailUrl = "land/pricehouse2";
                } else {
                    AppContext.openAlert({
                        title: "공동주택여부 오류",
                        msg: "공동주택여부 값이 없습니다.",
                        icon: "check"
                    });
                    return;
                }
                const sysDate = Util.getSpecificDate("", "day", 0, "DD", "") || "";
                const targetYear = AssetsUtil.getAcqsYear((res.item.bdKnd === "1" ? "1001" : "1002"), sysDate);
                Axios.dataAccess({
                    url: "api/system/broker/callApi.do",
                    methodType: "post",
                    paramType: "object",
                    paramData: {
                        apiName: "openapi",
                        tailUrl: tailUrl,
                        paramStr: res.item.pnu + "?stdryear=" + targetYear + "&honm=" + encodeURI(res.item.hoNm) + "&dongnm=" + encodeURI(res.item.dongNm)
                    },
                    onSuccessFn: (res2: any) => {
                        AppContext.hideSpinner();
                        if (res.item.bdKnd === "1") {
                            if (Util.isEmpty(res2.item.rtn_list[0].pblntfPc)) {
                                AppContext.openAlert({
                                    title: "공시가격 연계 불가",
                                    msg: "공시가격 연계가 불가하오니, 직접 입력하시기 바랍니다.",
                                    icon: "check"
                                });
                                return;
                            }
                            setPblntfPc(res2.item.rtn_list[0].pblntfPc);
                        } else if (res.item.bdKnd === "0") {
                            if (Util.isEmpty(res2.item.rtn_list[0].housePc)) {
                                AppContext.openAlert({
                                    title: "공시가격 연계 불가",
                                    msg: "공시가격 연계가 불가하오니, 직접 입력하시기 바랍니다.",
                                    icon: "check"
                                });
                                return;
                            }
                            setPblntfPc(res2.item.rtn_list[0].housePc);
                        }
                    },
                    onFailedFn: () => {
                        AppContext.hideSpinner();
                    }
                });
            }
        });
    }

    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/assets/selAssetsList.do",
            methodType: "post",
            paramType: "json",
            paramData: {
                hshldSn: hshldSn,
                assetsSe: "1001",
                estateSttus: "1002",
                estateSe: "1001",
                routeKey: "proptax"
            },
            onSuccessFn: (res: any) => {
                setAssetsDataArr(res.list);
                AppContext.hideSpinner();
            }
        });
    }, [hshldSn]);

    return (
        <React.Fragment>
            <div className="w100p bgcF6F6F6">
                <div className="dpFlx flxdCol jcC aiC pt60 pb30">
                    <div className='taC pb30'>
                        <span className="dpBlock fcBlack fs38 ls266 SpoqaHanSansNeo-Medium">재산세 계산</span>
                    </div>
                </div>
                <div className="dpFlx jcC pt30 pb110">
                    <div className='w1200'>
                        <div className='h36 dpFlx aiE pb20'>
                            <img className="mr10" src={require('img/common/icon_step.png')} alt="icon_step" />
                            <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 pb3'>
                                <span className='fc17A840'>{hshldNm}</span>
                                <span className='fc222222'>님의 자산현황</span>
                            </span>
                        </div>
                        <MuiListFilter tableInfo={{
                            tableHeadVsb: true,
                            styleType: ForestTableStyle,
                            fldInfs: [
                                { type: 'text', fldNm: 'rowNum', width: 58, headAlign: "center", dataAlign: "center", label: 'No.' },
                                { type: 'code', fldNm: 'estateDetailSe', width: 180, headAlign: "center", dataAlign: "center", label: '자산구분', codeTy: "1006" },
                                { type: 'code', fldNm: 'estatePrpos', width: 180, headAlign: "center", dataAlign: "center", label: '주택용도', codeTy: "1008" },
                                { type: 'code', fldNm: 'acqsCause', width: 140, headAlign: "center", dataAlign: "center", label: '취득원인', codeTy: "1035" },
                                { type: 'text', fldNm: 'fullRdnmAdres', wAuto: true, headAlign: "center", dataAlign: "left", label: '주소' },
                                { type: 'text', fldNm: 'acqsDe', width: 134, headAlign: "center", dataAlign: "center", label: '취득일' },
                                { type: 'el', width: 70, headAlign: "center", el: (item: any) => (
                                    <div className='dpFlx aiC csP' onClick={() => propTaxInputBind(item)}>
                                        <img className="csP pr3" src={require('img/common/icon_cal20.png')} alt="icon_cal20" />
                                        <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">계산</span>
                                    </div>
                                )},
                                { type: 'el', width: 70, headAlign: "center", el: (item: any) => (
                                    <div className='dpFlx aiC csP' onClick={() => {
                                        setAssetsSn(item.assetsSn);
                                        setAssetsModalOpen(true);
                                    }}>
                                        <img className="csP pr3" src={require('img/common/icon_copy20.png')} alt="icon_copy20" />
                                        <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">조회</span>
                                    </div>
                                )}
                            ],
                            ordFld: { name: "rowNum", direction: "asc" },
                            dataArr: assetsDataArr,
                            maxRowNums: [10, 20, 30],
                            trHeight: 48,
                            remainArea: false,
                            srchVsb: true,
                            paginateClass: "pt40",
                            headerVsb: true
                        }}/>
                    </div>
                </div>
            </div>
            <Modal modalOpen={taxInputModalOpen} setModalOpen={setTaxInputModalOpen} title="재산세 계산" dim={true} visible={true} closeCallBack={() => {
                setLstyrProptax("0");
                setLstyrUrbantax("0");
                setPblntfPc("0");
            }}>
                <div className="w475">
                    <div className='dpFlx aiC jcSb mb10'>
                        <span className='fs14 ls07 SpoqaHanSansNeo-Medium fc222222'>전년도 재산세액</span>
                        <NumberTextBox
                            value={lstyrProptax}
                            onChange={(data: string) => setLstyrProptax(data)}
                            borderClass="bgcWhite w355 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                            unitText="원"
                        />
                    </div>
                    <div className='dpFlx aiC jcSb mb10'>
                        <span className='fs14 ls07 SpoqaHanSansNeo-Medium fc222222'>전년도 도시지역분</span>
                        <NumberTextBox
                            value={lstyrUrbantax}
                            onChange={(data: string) => setLstyrUrbantax(data)}
                            borderClass="bgcWhite w355 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                            unitText="원"
                        />
                    </div>
                    <div className='dpFlx aiC jcSb mb10'>
                        <span className='fs14 ls07 SpoqaHanSansNeo-Medium fc222222'>공시가격</span>
                        <NumberTextBox
                            value={pblntfPc}
                            onChange={(data: string) => setPblntfPc(data)}
                            borderClass="bgcWhite w355 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                            unitText="원"
                        />
                    </div>
                    <span className="SpoqaHanSansNeo-Regular fs14 ls07 fc666666">※ 세부담 상한액을 계산하기 위한 직전년도 재산세액을 입력합니다.<br />(생략시 세부담상한액 없이 세금계산 진행)</span>
                    <div className="dpFlx w100p jcC mt40">
                        <div className="w210 csP dpFlx aiC jcC bgcF6F6F6 h56" onClick={() => {
                            setTaxInputModalOpen(false);
                            setLstyrProptax("0");
                            setLstyrUrbantax("0");
                            setPblntfPc("0");
                        }}>
                            <span className="SpoqaHanSansNeo-Medium fs16 fc222222">취소</span>
                        </div>
                        <div className="w210 bgc17A840 csP dpFlx aiC jcC h56" onClick={() => {
                            if (Util.isEmpty(pblntfPc) || pblntfPc === "0") {
                                AppContext.openAlert({
                                    title: "공시가격 미입력",
                                    msg: "공시가격을 입력하세요.",
                                    icon: "check"
                                });
                                return;
                            }
                            setAssetsSn(assetsSn);
                            setTaxInputModalOpen(false);
                            setProptaxCalcModalOpen(true);
                        }}>
                            <span className="SpoqaHanSansNeo-Medium fs16 fcWhite">세액 계산하기</span>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal modalOpen={proptaxCalcModalOpen} setModalOpen={setProptaxCalcModalOpen} title="재산세 세액 산출" dim={true} visible={proptaxCalcModalVsb} closeCallBack={() => setProptaxCalcModalVsb(false)}>
                <ProptaxCalc setProptaxCalcModalOpen={setProptaxCalcModalOpen} setProptaxCalcModalVsb={setProptaxCalcModalVsb} assetsSn={assetsSn} lstyrProptax={lstyrProptax} lstyrUrbantax={lstyrUrbantax} pblntfPc={pblntfPc} />
            </Modal>
            <Modal modalOpen={assetsModalOpen} setModalOpen={setAssetsModalOpen} title="보유자산 조회" dim={true} closeCallBack={() => {
                setAssetsSn(undefined);
                setAssetsModalOpen(false);
            }}>
                <div className="w1200 h500 ofA">
                    <AssetsShow assetsSn={assetsSn} />
                </div>
                <div className='w100p dpFlx aiC jcC pt40'>
                    <button className='w210 h56 bgcF6F6F6 dpFlx aiC jcC csP bdN' onClick={() => {
                        setAssetsSn(undefined);
                        setAssetsModalOpen(false);
                    }}>
                        <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>닫기</span>
                    </button>
                </div>
            </Modal>
        </React.Fragment>
    )
};
export default Proptax;