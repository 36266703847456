/*******************************************************************************
 You are strictly prohibited to copy, disclose, distribute, modify, or use
 this program in part or as a whole without the prior written consent of
 CallTax. CallTax, owns the intellectual property rights in and to this program.
 (Copyright © 2023 CallTax. All Rights Reserved| Confidential)

 Specification
    1. Program ID    : LongRentalHousing.tsx
    2. Program Title : 장기임대주택 도움말 Modal
    3. Created by    : JH Lee
    4. Create Date   : 2024.03.04.
    5. Reference     :
        - TrsftaxCalc.tsx(양도세 결과표) 페이지에서 호출
*******************************************************************************/
const LongRentalHousing = () => {
    return (
        <div className="w1120 h100p pr20">
            <span className="SpoqaHanSansNeo-Regular fc666666 ls075 fs15 lh20">
                1. 거주주택이 조합원입주권이 된 경우 장기임대주택에 대한 거주주택 비과세 적용
            </span>
            <table className={"forest-list-table w100p th-fm-SpoqaHanSansNeo-Medium "
                            + "th-fw500 th-fs14 th-fc222222 th-bdcDDDDDD th-bdb1 bdcDDDDDD "
                            +  "td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-p15 bdb1 tr-bdb1 mt20 mb20"
            }>
                <colgroup>
                    <col className="w60"></col>
                    <col className="w810"></col>
                    <col className="w230"></col>
                </colgroup>
                <tbody>
                    <tr className="h40">
                        <th>구분</th>
                        <th>거주주택이 조합원입주권으로 전환된 후</th>
                        <th>관련 근거</th>
                    </tr>
                    <tr className="h50">
                        <td>1</td>
                        <td className="taL">거주주택 비과세 특례가 적용되지 않는다. 관리처분계획인가후에는 주택의 멸실 여부와 상관없이 입주권으로 전환되었으므로 특례가 적용되지 않음에 유의하여야 한다.</td>
                        <td>조심2019서3806<br/>(2019.12.17.)</td>
                    </tr>
                </tbody>
            </table>

            <span className="SpoqaHanSansNeo-Regular fc666666 ls075 fs15 lh20">
                2. 장기임대주택이 조합원입주권이 된 경우 장기임대주택에 대한 거주주택 비과세 적용
            </span>
            <table className={"forest-list-table w100p th-fm-SpoqaHanSansNeo-Medium "
                            + "th-fw500 th-fs14 th-fc222222 th-bdcDDDDDD th-bdb1 bdcDDDDDD "
                            +  "td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-p15 bdb1 tr-bdb1 mt20 mb20"
            }>
                <colgroup>
                    <col className="w60"></col>
                    <col className="w810"></col>
                    <col className="w230"></col>
                </colgroup>
                <tbody>
                    <tr className="h40">
                        <th>구분</th>
                        <th>장기임대주택이 조합원입주권으로 전환된 후</th>
                        <th>관련 근거</th>
                    </tr>
                    <tr className="h40">
                        <td>1</td>
                        <td className="taL">직권말소된 후에 거주주택을 양도하는 경우에는 자진말소 및 자동말소와 달리 비과세혜택을 준다는 규정이 없어 비과세가 적용되지 않는다.</td>
                        <td></td>
                    </tr>
                    <tr className="h50">
                        <td>2</td>
                        <td className="taL">장기임대주택이 자동말소되고 조합원입주권으로 전환된 상태에서 자동말소일 이후 5년 이내 거주주택을 양도하는 경우 거주주택 비과세 특례를 적용받을 수 있다.</td>
                        <td>법규과-1143<br/>(2022.04.08.)</td>
                    </tr>
                    <tr className="h50">
                        <td>3</td>
                        <td className="taL">임대 중인 상태에서 임대의무기간의 1/2 이상을 충족한 상태에서 자진말소 하는 경우 멸실 또는 산축된 상태로 거주주택을 양도하는 경우 자진말소일 이후 5년 이내 거주주택을 양도하면 거주주택 비과세 특례를 적용받을 수 있다.</td>
                        <td>법규과-887<br/>(2023.04.11.)</td>
                    </tr>
                </tbody>
            </table>

            <span className="SpoqaHanSansNeo-Regular fc666666 ls075 fs15 lh20">
                3. 생애 한번만 장기임대주택에 대한 거주주택 비과세
            </span>
            <table className={"forest-list-table w100p th-fm-SpoqaHanSansNeo-Medium "
                            + "th-fw500 th-fs14 th-fc222222 th-bdcDDDDDD th-bdb1 bdcDDDDDD "
                            +  "td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-p15 bdb1 tr-bdb1 mt20 mb20"
            }>
                <colgroup>
                    <col className="w60"></col>
                    <col className="w810"></col>
                    <col className="w230"></col>
                </colgroup>
                <tbody>
                    <tr className="h40">
                        <th>구분</th>
                        <th>생애 한번만 장기임대주택에 대한 거주주택 비과세</th>
                        <th>관련 근거</th>
                    </tr>
                    <tr className="h69">
                        <td>1</td>
                        <td className="taL">
                            다음 각 호의 어느 하나에 해당하는 주택에 대하여는 개정규정 및 이 조 제1항에도 불구하고 종전의 규정에 따른다.<br/>
                            1. 이 영 시행 당시 거주하고 있는 주택<br/>
                            2. 이 영 시행 전에 거주주택을 취득하기 위해 매매계약을 체결하고 계약금을 지급한 사실이 증빙서류에 의해 확인되는 주택</td>
                        <td></td>
                    </tr>
                    <tr className="h50">
                        <td>2</td>
                        <td className="taL">2019년 2월 12일 이후 취득의 경우 생애 한번만 적용한다는 의미는 2019년 2월 12일 이후 취득분만을 대상으로 한 번을 의미하는 것이 아니라 종전에 특례를 적용받은 횟수까지도 포함하여 생애 한번을 의미한다.</td>
                        <td>기획재정부재산-192<br/>(2020.2.18.)</td>
                    </tr>
                    <tr className="h50">
                        <td>3</td>
                        <td className="taL">2019년 2월 12일 전 장기임대주택을 보유하지 않는 상태에서 새로운 주택을 취득하기 위하여 매매계약을 체결하고 계약금을 지급한 주택분양권은 거주주택 비과세 종전 규정을 적용받을 수 없다고 본다.</td>
                        <td>서면법령해석재산-2020-1464<br/>(2021.3.8.)</td>
                    </tr>
                    <tr className="h40">
                        <td>4</td>
                        <td className="taL">분양권 상태에서 임대등록하여 2019년 2월 12일 이후 완공되어 임대개시한 장기임대주택을 보유하는 경우에는 종전 규정을 적용한다.</td>
                        <td>서면법규재산2022-3027<br/>(2023.7.31.)</td>
                    </tr>
                </tbody>
            </table>

            <span className="SpoqaHanSansNeo-Regular fc666666 ls075 fs15 lh20">
                4. 사후관리
            </span>
            <table className={"forest-list-table w100p th-fm-SpoqaHanSansNeo-Medium "
                            + "th-fw500 th-fs14 th-fc222222 th-bdcDDDDDD th-bdb1 bdcDDDDDD "
                            +  "td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-p15 bdb1 tr-bdb1 mt20 mb20"
            }>
                <colgroup>
                    <col className="w60"></col>
                    <col className="w810"></col>
                    <col className="w230"></col>
                </colgroup>
                <tbody>
                    <tr className="h40">
                        <th>구분</th>
                        <th>사후관리</th>
                        <th>관련 근거</th>
                    </tr>
                    <tr className="h50">
                        <td>1</td>
                        <td className="taL">임대주택을 장기임대주택으로 보아 거주용 자가주택에 대하여 비과세 규정을 적용한다. 다만, 비과세 적용을 받은 후 임대기간 요건을 충족하지 못하게 된 경우에는 비과세받은 세액을 납부하여야 한다.</td>
                        <td></td>
                    </tr>
                    <tr className="h50">
                        <td>2</td>
                        <td className="taL">장기임대주택을 보유하고 있는 경우 최초 거주주택을 양도하는 경우에는 비과세를 허용하며 거주주택으로 전환한 경우에는 전체 양도차익에 대해 과세한다. 다만, 최종적으로 거주주택 전환시에는 직전 거주주택 양도 후 양도차익분에 대해서 비과세 한다.</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
export default LongRentalHousing;