/*******************************************************************************
 You are strictly prohibited to copy, disclose, distribute, modify, or use
 this program in part or as a whole without the prior written consent of
 CallTax. CallTax, owns the intellectual property rights in and to this program.
 (Copyright © 2023 CallTax. All Rights Reserved| Confidential)

 Specification
    1. Program ID    : UserMng.tsx
    2. Program Title : 회원관리
    3. Created by    : JH Kim
    4. Create Date   : 2023.
    5. Reference     :
*******************************************************************************/
import React            from "react";
import Util             from "js/common/Util";
import MuiListFilter    from "js/common/ui/list/MuiListFilter";
import ForestTableStyle from 'js/common/ui/style/ForestStyle';
import AppContext       from "js/common/AppContext";
import Axios            from "js/common/Axios";
import UserDialog       from "./dialog/UserDialog";
import Modal            from "js/common/ui/modal/Modal";

const UserMng = () => {
    const fldInfs = [
        { fldNm: 'userSn', pk: true },
        { type: 'text' , fldNm: 'rowNum'     , width:   65, headAlign: "center", dataAlign: "center", label: 'No.'                        },
        { type: 'text' , fldNm: 'userId'     , width:  150, headAlign: "center", dataAlign: "center", label: '아이디'                     },
        { type: 'text' , fldNm: 'userNm'     , width:  110, headAlign: "center", dataAlign: "center", label: '사용자명'                   },
        { type: 'phone', fldNm: 'mbtlnum'    , width:  140, headAlign: "center", dataAlign: "center", label: '휴대폰번호'                 },
        { type: 'text' , fldNm: 'emailAdres' , wAuto: true, headAlign: "center", dataAlign: "center", label: '이메일주소'                 },
        { type: 'text' , fldNm: 'badPwdCnt'  , width:  110, headAlign: "center", dataAlign: "center", label: '비밀번호실패'               },
        { type: 'code' , fldNm: 'isAdmin'    , width:  100, headAlign: "center", dataAlign: "center", label: '관리자여부', codeTy: "1024" },
        { type: 'text' , fldNm: 'logDt'      , width:  200, headAlign: "center", dataAlign: "center", label: '로그인일시'                 },
        { type: 'el'   , fldNm: 'userSttCode', width:   90, headAlign: "center",                      label: '회원상태'  , codeTy: "1031", el: (item: any) => (
            <div className={'dpFlx aiC jcC w70 h30 bdrd3 bdBox ' + (item.userSttCode === "1002" ? "bgc007CE8" : "bgcWhite bd1 bdc007CE8")}>
                <span className={"fs12 " + (item.userSttCode === "1002" ? "fcWhite" : "fc007CE8")}>{Util.getValToCode("1031", item.userSttCode)}</span>
            </div>
        )}
    ];
    const ordFld = { name: "rowNum", direction: "asc" };
    const [dataArr, setDataArr] = React.useState<any[]>([]);
    const [userSn , setUserSn ] = React.useState<number>();
    const [userModalOpen, setUserModalOpen] = React.useState<boolean>(false); // 유저정보 조회 모달 호출변수
    const [userSelTime  , setUserSelTime  ] = React.useState<Date>(new Date());

    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "admin/user/selUserList.do",
            methodType: "post",
            onSuccessFn: (res: any) => {
                setDataArr(res.list);
                AppContext.hideSpinner();
            }
        });
    }, [userSelTime]);

    return (
        <React.Fragment>
            <div className="w100p bgcF6F6F6">
                <div className="dpFlx flxdCol jcC aiC pt60 pb30">
                    <div className='taC pb30'>
                        <span className="dpBlock fcBlack fs38 ls266 SpoqaHanSansNeo-Medium">똑똑한 자산관리의 시작</span>
                    </div>
                </div>
                <div className="dpFlx jcC pt30 pb110">
                    <div className='w1200'>
                        <div className='h36 dpFlx aiE pb20'>
                            <img className="mr10" src={require('img/common/icon_step.png')} alt="icon_step" />
                            <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 pb3'>사용자 목록</span>
                        </div>
                        <MuiListFilter tableInfo={{
                            tableHeadVsb: true,
                            styleType: ForestTableStyle,
                            fldInfs: fldInfs,
                            ordFld: ordFld,
                            dataArr: dataArr,
                            maxRowNums: [10, 20, 30],
                            trHeight: 48,
                            srchVsb: true,
                            remainArea: false,
                            rowClickFunc: (item: any) => {
                                setUserSn(item.userSn);
                                setUserModalOpen(true);
                            },
                            paginateClass: "pt40",
                            headerVsb: true
                        }}>
                        </MuiListFilter>
                    </div>
                </div>
            </div>
            <Modal modalOpen={userModalOpen} setModalOpen={setUserModalOpen} title="사용자정보 조회" dim={true} visible={true}>
                <UserDialog setModalOpen={setUserModalOpen} userSn={userSn} setUserSelTime={setUserSelTime} />
            </Modal>
        </React.Fragment>
    )
}
export default UserMng