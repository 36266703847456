import Axios from "js/common/Axios";
import React from "react";

interface Qestn57ModalProps {
    setQestn57Open: React.Dispatch<React.SetStateAction<boolean>>
    assetsSn: string
}
const Qestn57Modal = ({setQestn57Open, assetsSn}: Qestn57ModalProps) => {
    const [estateDetailSe, setEstateDetailSe] = React.useState<string>();
    const [acqsSrc, setAcqsSrc] = React.useState<string>();

    React.useEffect(() => {
        Axios.dataAccess({
            url: "system/assets/selAssetsEstateDetail.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                assetsSn: assetsSn
            },
            onSuccessFn: (res: any) => {
                setEstateDetailSe(res.item.estateDetailSe);
                setAcqsSrc(res.item.acqsSrc);
            }
        });
    }, [assetsSn]);

    if ((estateDetailSe === "1001" && acqsSrc === "1003") || estateDetailSe === "1003") {
        return (
            <div className="w100p hAuto ofA">
                <table className={
                    'forest-list-table bdt1 bdb1 bdcDDDDDD tr-h36 ' +
                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075 td-p10'
                }>
                    <colgroup>
                        <col className='w100'></col>
                        <col className='w300'></col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>구분</th>
                            <th>과세표준</th>
                        </tr>
                        <tr>
                            <th>전매</th>
                            <td className="bdcDDDDDD-i bdr1-i">실제 거래가액</td>
                        </tr>
                        <tr>
                            <th>상속</th>
                            <td className="bdcDDDDDD-i bdr1-i">시가표준액</td>
                        </tr>
                        <tr>
                            <th>증여</th>
                            <td className="bdcDDDDDD-i bdr1-i">시가인정액</td>
                        </tr>
                    </tbody>
                </table>
                <div className='w100p dpFlx aiC jcC pt40'>
                    <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => setQestn57Open(false)}>
                        <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>닫기</span>
                    </div>
                </div>
            </div>
        )
    } else if ((estateDetailSe === "1001" && acqsSrc === "1002") || estateDetailSe === "1002") {
        return (
            <div className="w100p hAuto ofA">
                <table className={
                    'forest-list-table bdt1 bdb1 bdcDDDDDD tr-h36 ' +
                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075 td-p10'
                }>
                    <colgroup>
                        <col className='w220'></col>
                        <col className='w220'></col>
                        <col className='w220'></col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>신규분양/미분양/전매</th>
                            <th>상속</th>
                            <th>증여</th>
                        </tr>
                        <tr>
                            <td>분양대금+옵션+프리미엄</td>
                            <td>상속세평가액+추가분양대금</td>
                            <td>시가인정액+추가분양대금</td>
                        </tr>
                    </tbody>
                </table>
                <div className='w100p dpFlx aiC jcC pt40'>
                    <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => setQestn57Open(false)}>
                        <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>닫기</span>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <></>
        )
    }
}
export default Qestn57Modal;