/***********************************************************************************
*** Law33at10 ** 지방세특례제한법 제31조의 3 장기 임대주택 등에 대한 재산세 감면 Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law33at10Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}

export default function Law33at10({ setModalOpen, setLawModalTitle, callBack, callMode }: Law33at10Props) {
    React.useEffect(() => {
        setLawModalTitle("지방세특례제한법 제31조의 3 장기 임대주택 등에 대한 재산세 감면");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className='w100p hAuto ofA mb30'>
                <table className={
                    'forest-list-table bdt1 bdb1 bdcDDDDDD ' +
                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075 td-p10'
                }>
                   <colgroup>
                        <col className='w70'></col>
                        <col className='w130'></col>
                        <col className='w150'></col>
                        <col className='w230'></col>
                        <col className='w210'></col>
                        <col className='w170'></col>
                        <col className='w180'></col>
                    </colgroup>
                    <tbody>
                        <tr className='h40'>
                            <th rowSpan={2}>구분</th>
                            <th rowSpan={2}>면적요건</th>
                            <th colSpan={2}>가액요건</th>
                            <th rowSpan={2}>등록요건</th>
                            <th rowSpan={2}>적용기간</th>
                            <th rowSpan={2}>증액제한</th>
                        </tr>
                        <tr className='h29'>
                            <th>2020.8.11 이전 등록</th>
                            <th className='bdr1-i bdcDDDDDD-i'>2020.8.12 이후 등록</th>
                        </tr>
                        <tr className="h200">
                            <th className='bdt0' rowSpan={4}>내용</th>
                            <td>85㎡ 이하</td>
                            <td>-</td>
                            <td className="taL">
                                <span>• 공동주택<br />임대개시일 당시 기준시가 <br />수도권 6억원 이하 (수도권 밖 3억원)<br /></span>
                                <span className="fs12">※ 수도권 중 2022.1.1. 이후 <br /> 민간건설임대주택인 경우 9억원 이하<br /><br /></span>
                                <span>• 오피스텔<br />임대개시일 당시 기준시가 <br />수도권 4억원 이하 (수도권 밖 2억원)<br /></span>
                            </td>
                            <td>지방자치단체</td>
                            <td>2024.12.31. 까지</td>
                            <td>임대료 (임대보증금) 증액률<br/>5% 이내</td>
                        </tr>
                        <tr className='h40'>
                            <th className='bdt0' rowSpan={2}>임대유형</th>
                            <th colSpan={2}>임대기간</th>
                            <th className='bdt0' rowSpan={2}>임대호수조건</th>
                            <th className='bdt0' colSpan={2} rowSpan={2}>감면율</th>
                        </tr>
                        <tr className='h29'>
                            <th>2020.8.17. 이전 등록</th>
                            <th className='bdr1-i bdcDDDDDD-i'>2020.8.18. 이후 등록</th>
                        </tr>
                        <tr className="h150">
                            <td>단기임대, 장기임대</td>
                            <td>장기임대 (8년 이상)</td>
                            <td>장기임대(10년 이상)</td>
                            <td>
                                • 공동주택 2세대 이상 임대<br/>
                                • 오피스텔 2세대 이상 임대<br/>
                                • 다가구주택 1호 이상 임대<br/>
                                (다가구주택은 모든 임대호수의 전용면적이 40㎡ 이하이고, 건축물대장에 호수별 전용면적이 구분기재되어 있는 경우 가능하다)<br/>
                            </td>
                            <td>50%<br />(도시지역분 포함)</td>
                            <td>25%<br />(도시지역분 제외)</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}
