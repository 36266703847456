/*******************************************************************************
*** BasicInfo ** 모바일 상속세 기초정보입력 UI, 값 선택 시 Event 처리
*******************************************************************************/
import { useState }      from "react";
import { InheTaxAction } from "./inheTaxStatesReducer";
import { NumericFormat } from "react-number-format";
import { MainSectionContainer, InputContainer, NumberInputContainer }   from "js/mobile/common/layout";
import { InheTaxParamProps, InheTaxParamVisible, InheTaxParamDisabled } from "js/common/types/types";

type Props = {
    inputValue: InheTaxParamProps;
    inputVisible: InheTaxParamVisible;
    inputDisabled: InheTaxParamDisabled;
    onUpdateInput: (action: InheTaxAction) => void;
};

function BasicInfo({ inputValue, inputVisible, inputDisabled, onUpdateInput,}: Props) {
    const [funeralExpenHelpText, setFuneralExpenHelpText] = useState<string>("");   // 장례비용 한도 안내

    return (
        <MainSectionContainer>
            <p className="desc">
                <span>상속할 자산의</span>
                <span className="bold"> 기초정보</span>
                <span>를 선택해주세요.</span>
            </p>

            {/* 01. 상속재산가액 */}
            {inputVisible.visible01 && (
                <InputContainer>
                    <p className="label">상속재산가액</p>
                    <div className="input">
                        <NumberInputContainer $filled={inputValue.inherProp === "" ? false : true}>
                            <div className="inputWrap">
                                <NumericFormat
                                    name="inherProp"
                                    value={inputValue.inherProp}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={15}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const valueWithoutComma = inputValue.replace(/,/g, "");
                                        onUpdateInput({
                                            type: "UPDATE_INHER_PROP",
                                            payload: valueWithoutComma,
                                        });
                                    }}
                                    placeholder="상속재산가액을 입력해주세요."
                                    className="numberInput"
                                />
                                <p className="unit">원</p>
                            </div>
                        </NumberInputContainer>
                    </div>
                </InputContainer>        
            )}

            {/* 02. 추정상속가액 */}
            {inputVisible.visible02 && (
                <InputContainer>
                    <p className="label">추정상속가액</p>
                    <div className="input">
                        <NumberInputContainer $filled={inputValue.estiInher === "" ? false : true}>
                            <div className="inputWrap">
                                <NumericFormat
                                    name="estiInher"
                                    value={inputValue.estiInher}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={15}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const valueWithoutComma = inputValue.replace(/,/g, "");
                                        onUpdateInput({
                                            type: "UPDATE_ESTI_INHER",
                                            payload: valueWithoutComma,
                                        });
                                    }}
                                    placeholder="추정상속가액을 입력해주세요."
                                    className="numberInput"
                                />
                                <p className="unit">원</p>
                            </div>
                        </NumberInputContainer>
                    </div>
                </InputContainer>
            )}

            {/* 03. 퇴직금/보험금/신탁자산 */}
            {inputVisible.visible03 && (
                <InputContainer>
                    <p className="label">퇴직금/보험금/신탁자산</p>
                    <div className="input">
                        <NumberInputContainer $filled={inputValue.trustAssets === "" ? false : true}>
                            <div className="inputWrap">
                                <NumericFormat
                                    name="trustAssets"
                                    value={inputValue.trustAssets}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={15}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const valueWithoutComma = inputValue.replace(/,/g, "");
                                        onUpdateInput({
                                            type: "UPDATE_TRUST_ASSETS",
                                            payload: valueWithoutComma,
                                        });
                                    }}
                                    placeholder="퇴직금/보험금/신탁자산 금액을 입력해주세요."
                                    className="numberInput"
                                />
                                <p className="unit">원</p>
                            </div>
                        </NumberInputContainer>
                    </div>
                </InputContainer>
            )}

            {/* 12. 채무 */}
            {inputVisible.visible12 && (
                <InputContainer>
                    <p className="label">채무</p>
                    <div className="input">
                        <NumberInputContainer $filled={inputValue.debtAmt === "" ? false : true}>
                            <div className="inputWrap">
                                <NumericFormat
                                    name="debtAmt"
                                    value={inputValue.debtAmt}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={15}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const valueWithoutComma = inputValue.replace(/,/g, "");
                                        onUpdateInput({
                                            type: "UPDATE_DEBT_AMT",
                                            payload: valueWithoutComma,
                                        });
                                    }}
                                    placeholder="채무 금액을 입력해주세요."
                                    className="numberInput"
                                />
                                <p className="unit">원</p>
                            </div>
                        </NumberInputContainer>
                    </div>
                </InputContainer>
            )}
            
            {/* 13. 공과금 */}
            {inputVisible.visible13 && (
                <InputContainer>
                    <p className="label">공과금</p>
                    <div className="input">
                        <NumberInputContainer $filled={inputValue.utilityBill === "" ? false : true}>
                            <div className="inputWrap">
                                <NumericFormat
                                    name="utilityBill"
                                    value={inputValue.utilityBill}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={15}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const valueWithoutComma = inputValue.replace(/,/g, "");
                                        onUpdateInput({
                                            type: "UPDATE_UTILITY_BILL",
                                            payload: valueWithoutComma,
                                        });
                                    }}
                                    placeholder="공과금을 입력해주세요."
                                    className="numberInput"
                                />
                                <p className="unit">원</p>
                            </div>
                        </NumberInputContainer>
                    </div>
                </InputContainer>
            )}

            {/* 14. 장례비용 */}
            {inputVisible.visible13 && (
                <InputContainer>
                    <p className="label">장례비용</p>
                    <div className="input">
                        <NumberInputContainer $filled={inputValue.funeralExpen === "" ? false : true}>
                            <div className="inputWrap">
                                <NumericFormat
                                    name="funeralExpen"
                                    value={inputValue.funeralExpen}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    maxLength={15}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const valueWithoutComma = inputValue.replace(/,/g, "");
                                        const numberValue = parseInt(valueWithoutComma);
                                        
                                        // 장례비용 한도 도움말
                                        if (numberValue > 15000000) {
                                            setFuneralExpenHelpText("장례비용은 최대 1,500만원까지 공제가능합니다.");
                                        } else {
                                            setFuneralExpenHelpText("");
                                        }
                                        onUpdateInput({
                                            type: "UPDATE_FUNERAL_EXPEN",
                                            payload: valueWithoutComma,
                                        });
                                    }}
                                    placeholder="장례비용을 입력해주세요."
                                    className="numberInput"
                                />
                                <p className="unit">원</p>
                            </div>
                        </NumberInputContainer>
                        <div className="help warning">{funeralExpenHelpText}</div>
                    </div>
                </InputContainer>
            )}
        </MainSectionContainer>
    );
}

export default BasicInfo;
