/*******************************************************************************
*** SimPropAutoTax ** 재산세/종합부동산세 간편계산기 * 주소 API/직접 검색 자동 분기
*******************************************************************************/
import React            from "react";
import Util             from "js/common/Util";
import NumberTextBox    from "js/common/ui/input/NumberTextBox";
import SelectBox        from "js/common/ui/input/SelectBox";
import Modal            from "js/common/ui/modal/Modal";
import SimAddress       from "js/common/address/SimAddress";
import ImageRadioBox    from "js/common/ui/input/ImageRadioBox";
import AppContext       from "js/common/AppContext";
import SimPropTaxCalc   from "./SimPropTaxCalc";
import ToolTip          from "js/common/ui/ToolTip";
import DirectAddressIn  from "js/common/address/DirectAddressIn";

interface SimAcqsAutoTaxProps {
    apiResult: string;
}

const SimPropAutoTax = ({apiResult}: SimAcqsAutoTaxProps) => {
    // 주택(부동산) 정보 Setting
    interface  HousingProps {
        pnu            : string;  // 1. 토지고유코드(19자리)
        rdnmAdres      : string;  // 2. 도로명 주소
        lnmAdres       : string;  // 3. 지번 주소
        spfcKnd        : string;  // 4. 용도지역
        stdPrice       : string;  // 5. 공동/개별 주택 가격(기준시가)
        mdatTrgetAreaAt: string;  // 6. 조정대상지역
        shareRatio     : string;  // 7. 지분율
    }

    // 재산세/종합부동산세 계산 입력정보 Setting
    interface  PropTaxParamProps {
        taxFlag          : string;
        houseNo          : string;        // 1. 주택수
        housing          : any[];         // 2. 주택정보 배열
        coupleName       : string;        // 3. 부부공동명의
        coupleSpecialCase: string;        // 4. 부부공동명의 특례적용
        holdingPeriod    : string;        // 5. 보유기간
        ownerAge         : string;        // 6. 소유자 연령
    }

    const [houseNo          , setHouseNo          ] = React.useState<string>("");         // 1. 주택수
    const [housing          , setHousing          ] = React.useState<HousingProps[]>([]); // 2. 주택정보
    const [shareRatio       , setShareRatio       ] = React.useState<string>("");         // 3. 지분율
    const [coupleName       , setCoupleName       ] = React.useState<string>("");         // 4. 부부공동명의
    const [coupleSpecialCase, setCoupleSpecialCase] = React.useState<string>("");         // 5. 부부공동명의 특례적용
    const [holdingPeriod    , setHoldingPeriod    ] = React.useState<string>("");         // 6. 보유기간
    const [ownerAge         , setOwnerAge         ] = React.useState<string>("");         // 7. 소유자 연령

    const [addressModalOpen       , setAddressModalOpen       ] = React.useState<boolean>(false); // 주소검색 모달 호출변수
    const [dirAddressInModalOpen  , setDirAddressInModalOpen  ] = React.useState<boolean>(false); // 주소 직접검색 모달 호출변수
    const [simPropTaxCalcModalOpen, setSimPropTaxCalcModalOpen] = React.useState<boolean>(false); // 재산세/종합부동산세 계산 결과 모달
    const [simPropTaxCalcModalVsb , setSimPropTaxCalcModalVsb ] = React.useState<boolean>(false); // 재산세/종합부동산세 결과 모달 표시 여부
    const [propTaxParam, setPropTaxParam] = React.useState<PropTaxParamProps[]>([]);         // 재산세/종합부동산세 계산 입력정보

    // 입력항목 표시 여부
    const [visible01, setVisible01] = React.useState<boolean>(true ); // 1. 주택수
    const [visible02, setVisible02] = React.useState<boolean>(true ); // 2. 주택정보
    const [visible03, setVisible03] = React.useState<boolean>(false); // 3. 지분율
    const [visible04, setVisible04] = React.useState<boolean>(false); // 4. 부부공동명의
    const [visible05, setVisible05] = React.useState<boolean>(false); // 5. 부부공동명의 특례적용
    const [visible06, setVisible06] = React.useState<boolean>(false); // 6. 보유기간
    const [visible07, setVisible07] = React.useState<boolean>(false); // 7. 소유자 연령

    // 입력항목 입력 가능여부
    const [disabled01, setDisabled01] = React.useState<boolean>(false); // 1.주택수
    const [disabled04, setDisabled04] = React.useState<boolean>(false); // 4.부부공동명의

    // 주택 추가
    const onFinishAddrSearch = (item: any) => {
        let housePrice  = "";
        if (item.bdKnd === "1") {       // 공동주택 여부
            housePrice = item.pblntfPc;
        } else if (item.bdKnd === "0") {
            housePrice = item.housePc;
        }

        let lnmAdres = "";
        if (item.lnmAdres !== undefined) {
            lnmAdres = item.lnmAdres +  " " + item.lnmAdresDetail
        }

        // 직접 입력한 주소의 PNU 코드를 유일한 값으로 설정하기 위해 사용
        let now = new Date();
        
        // 주택(부동산) 배열에 추가
        setHousing([
            ...housing,     // 배열 전개(spread operator) 사용 배열 복사본으로 새배열을 생성 : push는 배열을 변이하는 메서드로 비추천
            {
                pnu            : item.pnu + Util.lpad(now.getMinutes().toString(), 2, "0") + Util.lpad(now.getSeconds().toString(), 2, "0")
              , rdnmAdres      : item.rdnmAdres + " " + item.rdnmAdresDetail
              , lnmAdres       : lnmAdres
              , spfcKnd        : item.spfcKnd
              , stdPrice       : Util.uncomma(housePrice)
              , mdatTrgetAreaAt: item.mdatTrgetAreaAt
              , shareRatio     : item.shareRatio
            }
          ]);

        if (houseNo === "201") {    // 1주택인 경우
            if (housing.length === 0) {     // 주택정보 첫 번째 등록
                setShareRatio(item.shareRatio); // 3.지분율 입력
                setDisabled01(true);            // 1.주택수 Fixed

                if (item.shareRatio === "100") {  // 3.지분율: 100% ▶ 6.보유기간
                    setVisible06(true);
                } else {                          // 3.지분율: 100% 미만 ▶ 4.부부공동명의 여부
                    setVisible04(true);
                }
            } else {
                setShareRatio(""); // 3.지분율 초기화
            }
        } else if (houseNo === "202") {  // 2주택 이상
            setDisabled01(true); // 1.주택수 Fixed
        }
    }

    // 주택(부동산) 정보 삭제
    const delHousing = (item:HousingProps) => {
        // 토지고유코드가 동일한 것을 제거
        setHousing(housing.filter(housing => housing.pnu !== item.pnu)); // 토지고유코드가 일치하지 않는 원소만 추출해서 새로운 배열을 생성

        // 1주택인 경우
        if (houseNo === "201") {
            setShareRatio       (""   ); // 3.지분율 초기화
            setVisible04        (false); // 4.부부공동명의 미표시
            setCoupleName       (""   ); // 4.부부공동명의 초기화
            setDisabled04       (false); // 4.부부공동명의 활성화
            setVisible05        (false); // 5.부부공동명의 특례적용 미표시
            setCoupleSpecialCase(""   ); // 5.부부공동명의 특례적용 초기화
            setVisible06        (false); // 6.보유기간 미표시
            setHoldingPeriod    (""   ); // 6.보유기간 초기화
            setVisible07        (false); // 7.소유자 연령 미표시
            setOwnerAge         (""   ); // 7.소유자 연령 초기화
        }
    }

    // 입력 값 초기화
    const goReset = () => {
        setVisible01(true);  // 1. 주택수
        setVisible02(true);  // 2. 주택정보
        setVisible03(false); // 3. 지분율
        setVisible04(false); // 4. 부부공동명의
        setVisible05(false); // 5. 부부공동명의 특례적용
        setVisible06(false); // 6. 보유기간
        setVisible07(false); // 7. 소유자 연령

        setHouseNo("");
        setHousing(housing.filter(housing => Util.isEmpty(housing.pnu))); // 토지고유코드 없는 원소를 새로운 배열로 생성 : 배열 원소 모두 삭제
        setShareRatio("");
        setCoupleName("");
        setCoupleSpecialCase("");
        setHoldingPeriod("");
        setOwnerAge("");

        setDisabled01(false);
        setDisabled04(false);
    }

    // 재산세/종합부동산세 계산하기
    const callTax = (taxFlag:string) => {
        if (visible01) {if (Util.isEmpty(houseNo)) {AppContext.openAlert({title: "주택수", msg: "주택수를 선택하세요.", icon: "check"}); return;}}
        if (visible02) {
            if (housing.length === 0) {AppContext.openAlert({title: "주택정보", msg: "주택 정보를 추가하세요.", icon: "check"}); return;}
            if (houseNo === "202") {  // 2주택 이상
                if (housing.length === 1) {
                    AppContext.openAlert({title: "주택정보", msg: "주택 정보를 2주택 이상으로 추가하세요.", icon: "check"}); return;
                }
            }
        }
        if (visible04) {if (Util.isEmpty(coupleName       )) {AppContext.openAlert({title: "부부공동명의"         , msg: "부부공동명의 여부를 선택하세요."         , icon: "check"}); return;}}
        if (visible05) {if (Util.isEmpty(coupleSpecialCase)) {AppContext.openAlert({title: "부부공동명의 특례적용", msg: "부부공동명의 특례적용 여부를 선택하세요.", icon: "check"}); return;}}
        if (visible06) {if (Util.isEmpty(holdingPeriod    )) {AppContext.openAlert({title: "보유기간"             , msg: "보유기간을 선택하세요."                  , icon: "check"}); return;}}
        if (visible07) {if (Util.isEmpty(ownerAge         )) {AppContext.openAlert({title: "소유자 연령"          , msg: "소유자 연령을 선택하세요."               , icon: "check"}); return;}}

        setPropTaxParam([
            ...propTaxParam,
            {
              taxFlag          : taxFlag            // 재산세/종합부동산세(P)
            , houseNo          : houseNo            // 1. 주택수
            , housing          : housing            // 2. 주택정보 배열
            , coupleName       : coupleName         // 3. 부부공동명의
            , coupleSpecialCase: coupleSpecialCase  // 4. 부부공동명의 특례적용
            , holdingPeriod    : holdingPeriod      // 5. 보유기간
            , ownerAge         : ownerAge           // 6. 소유자 연령
            }
        ]);

        setSimPropTaxCalcModalOpen(true);
    }

    return (
        <React.Fragment>
            <div className='w940 mh500'>
                {/* 01. 주택수 */}
                <div className={(visible01 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt30"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>주택수</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={houseNo}
                            codeArray={Util.getCodeArray("2020")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            border="1px solid #dddddd"
                            disabled={disabled01}
                            onChangeFunc={(data: string) => {
                                setHouseNo(data);
                                if (data === "201") {   // 1주택
                                    setVisible03(true); // 03.지분율 표시
                                } else if (data === "202") {   // 2주택 이상
                                    setVisible03(false); // 03.지분율 미표시
                                    setShareRatio(""  ); // 03.지분율 초기화
                                } else {}
                            }} />
                    </div>
                </div>

                {/* 02. 주택정보 */}
                <div className={(visible02 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>주택정보</span>
                    </div>
                    <div className='dpFlx aiC jcC ml20 bgcWhite'>
                        <div className="w80 h30 bgc17A840 bdrd40 dpFlx jcC aiC csP" onClick={() => {
                            if (Util.isEmpty(houseNo)) {
                                AppContext.openAlert({
                                    title: "주택수"
                                  , msg  : "주택수를 선택하세요."
                                  , icon: "check"
                                });
                                return;
                            }
                            if (houseNo === "201") {     // 1주택
                                if (housing.length === 1) {
                                    AppContext.openAlert({
                                        title: "주택정보 추가 완료"
                                      , msg  : "1주택의 정보가 추가되어 더 이상 추가할 수 없습니다."
                                      , icon: "check"
                                    });
                                } else {
                                    if (apiResult === "0") {
                                        setAddressModalOpen(true);
                                    } else {
                                        setDirAddressInModalOpen(true);
                                    }
                                }
                            } else {    // 2주택 이상
                                if (apiResult === "0") {
                                    setAddressModalOpen(true);
                                } else {
                                    setDirAddressInModalOpen(true);
                                }
                            }
                        }}>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>추가</span>
                        </div>
                    </div>
                </div>

                {/* 02-1. 주택 상세정보 */}
                {housing.map((item: any, idx: number) => {
                    return (
                        <div className="w100p dpFlx aiC jcL pt10" key={idx}>
                            <div className='dpFlx aiC jcC bgcWhite'>
                                <table className="forest-form-table th-p0 td-fs15 td-fm-SpoqaHanSansNeo-Regular td-fc666666 td-ls075">
                                    <colgroup>
                                        <col className="w150" />
                                        <col className="w280" />
                                        <col className="w150" />
                                        <col className="w140" />
                                        <col className="w140" />
                                    </colgroup>
                                    <tbody>
                                        <tr className={item.lnmAdres === "" ? "h40" : "h60"}>
                                            <th>주소</th>
                                            <td colSpan={3}><span className="lh25">{item.rdnmAdres}<br/>{item.lnmAdres}</span></td>
                                            <td>
                                                <div className="w80 h30 bgcB36666 bdrd4 dpFlx jcC aiC csP" onClick={() => delHousing(item)}>
                                                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>삭제</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="h35">
                                            <th>용도지역</th>
                                            <td className="bdr1-i bdcDDDDDD-i">
                                                <button className="dpFlx aiC bdN bgcTp p0 mr20">
                                                    <img className="w24 h24 pr5" src={require('img/common/radiobutton_base_' + (Util.isNotEmpty(item.spfcKnd) ? 'on' : 'off') + '.png')} alt="radiobutton_base" />
                                                    <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">{Util.getValToCode("1025", item.spfcKnd)}</span>
                                                </button>
                                            </td>
                                            <th>기준시가(원)</th>
                                            <td colSpan={2}>{Util.comma(item.stdPrice)}</td>
                                        </tr>
                                        <tr className="h35">
                                            <th>조정대상지역</th>
                                            <td className="bdr1-i bdcDDDDDD-i">
                                                <ImageRadioBox
                                                    codeList={Util.getCodeArray("1042")}
                                                    value={item.mdatTrgetAreaAt}
                                                    // setValue={setMdatTrgetAreaAt}
                                                    btnClass="w24 h24 pr5"
                                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                                    interval={20} />
                                            </td>
                                            <th>지분율(%)</th>
                                            <td colSpan={2}>{item.shareRatio}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                })}

                {/* 03. 지분율 */}
                <div className={(visible03 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>지분율(%)</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={shareRatio}
                            onChange={(data: string) => setShareRatio(data)}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            placeholder="자동입력"
                            disabled={true}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5" />
                    </div>
                </div>

                {/* 04. 부부공동명의 */}
                <div className={(visible04 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>부부공동명의</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={coupleName}
                            codeArray={Util.getCodeArray("2021")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            border="1px solid #dddddd"
                            disabled={disabled04}
                            onChangeFunc={(data: string) => {
                                setCoupleName(data);
                                if (data === "Y") {         // 04. 부부공동명의 여 ▶ 5. 부부공동명의 특례적용 여부
                                    setVisible05(true);
                                } else if (data === "N") {  // 04. 부부공동명의 부 ▶ 완료(계산하기)
                                    setVisible05     (false); // 5.부부공동명의 특례적용 미표시
                                    setCoupleSpecialCase(""); // 5.부부공동명의 특례적용 초기화
                                }
                            }} />
                    </div>
                </div>

                {/* 05. 부부공동명의 특례적용 */}
                <div className={(visible05 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>부부공동명의 특례적용</span>
                        <div className="dpFlx aiC jcC taL"
                            data-tooltip-id="coupleSpecialCaseToolTip"
                            data-tooltip-content={
                                    "♠ 종합부동산세 부부 공동명의\n\n"
                                    + "부부 공동명의 1세대 1주택자(다른 세대원은 주택을 소유하지 않은 경우에 한함)가 단독명의\n" 
                                    + "방식으로 종합부동산세 과세방식을 변경할 수 있다. 부부 공동명의 과세특례는 9월 16일부터\n" 
                                    + "9월 30일까지 신청하여야 한다. 부부 공동명의 신청자에 한하여 단독 명의처럼 고령자 공제와\n"
                                    + "장기보유 공제 최대 80%의 세액공제를 받을 수 있다.\n"
                                    + "부부 공동명의 1세대 1주택 특례를 신청하면 부부 중 지분율이 가장 큰 사람이 납세의무가가\n"
                                    + "되며, 지분율이 동일한 경우에는 납세의무자를 선택할 수 있다.\n"
                                }>
                                <img src={require('img/common/icon_search20_black.png')} alt="icon_info_fill_20" className='csP pl3' />
                                <ToolTip id="coupleSpecialCaseToolTip" place='left' clickable={true} className="w200 lh20" />
                        </div>  
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={coupleSpecialCase}
                            codeArray={Util.getCodeArray("2022")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            border="1px solid #dddddd"
                            onChangeFunc={(data: string) => {
                                setCoupleSpecialCase(data);
                                if (data === "Y") {         // 5.부부공동명의 특례적용 여 ▶ 6.보유기간
                                    setVisible06(true );
                                    setDisabled04(true); // 4.부부공동명의 Fixed
                                } else if (data === "N") {  // 5.부부공동명의 특례적용 부 ▶ 완료(계산하기)
                                    setVisible06 (false); // 6.보유기간 미표시
                                    setHoldingPeriod(""); // 6.보유기간 초기화
                                    setVisible07 (false); // 7.소유자 연령 미표시
                                    setOwnerAge  (""   ); // 7.소유자 연령 초기화
                                    setDisabled04(true ); // 4.부부공동명의 Fixed
                                } else {}
                            }} />
                    </div>
                </div>

                {/* 06. 보유기간 */}
                <div className={(visible06 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>보유기간</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={holdingPeriod}
                            codeArray={Util.getCodeArray("2023")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            border="1px solid #dddddd"
                            onChangeFunc={(data: string) => {
                                setHoldingPeriod(data);
                                // 보유기간 선택 ▶ 7.소유자 연령 표시
                                setVisible07(true);
                            }} />
                    </div>
                </div>

                {/* 07. 소유자 연령 */}
                <div className={(visible07 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>소유자 연령</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={ownerAge}
                            codeArray={Util.getCodeArray("2024")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            border="1px solid #dddddd"
                            onChangeFunc={(data: string) => setOwnerAge(data)} />
                    </div>
                </div>

                <div className='w100p dpFlx aiC jcL pt40 pb40'>
                    <button className='w360 h60 bgc17A840 dpFlx aiC jcC csP bdN' onClick={() => callTax("P")}>
                        <span className='SpoqaHanSansNeo-Medium fs18 ls07 fcWhite'>계산하기</span>
                    </button>
                    <button className='w100 h60 bgcF6F6F6 dpFlx aiC jcC csP bd1 bdc878787' onClick={() => goReset()}>
                        <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>초기화</span>
                    </button>
                </div>
            </div>

            {/* 주소 API 검색 모달 */}
            <Modal modalOpen={addressModalOpen} setModalOpen={setAddressModalOpen} title="주소검색" dim={true} visible={true}>
                <SimAddress setModalOpen={setAddressModalOpen} taxFlag="P" callBack={onFinishAddrSearch} />
            </Modal>

            {/* 주소 직접입력 모달 */}
            <Modal modalOpen={dirAddressInModalOpen} setModalOpen={setDirAddressInModalOpen} title="보유자산 정보 입력" dim={true} visible={true}>
                <DirectAddressIn setModalOpen={setDirAddressInModalOpen} taxFlag="P" callBack={onFinishAddrSearch} />
            </Modal>

            {/* 재산세, 종합소득세 모달 */}
            <Modal modalOpen={simPropTaxCalcModalOpen} setModalOpen={setSimPropTaxCalcModalOpen} title="재산세/종합부동산세 세액 산출" dim={true} visible={simPropTaxCalcModalVsb} closeCallBack={() => setSimPropTaxCalcModalVsb(false)}>
                <SimPropTaxCalc setSimPropTaxCalcModalOpen={setSimPropTaxCalcModalOpen} setSimPropTaxCalcModalVsb={setSimPropTaxCalcModalVsb} propTaxParam={propTaxParam} />
            </Modal>
        </React.Fragment>
    )
}
export default SimPropAutoTax;