/*******************************************************************************
*** Wecake ** 위케이크
*******************************************************************************/
import React      from 'react';
import Axios      from 'js/common/Axios';
import AppContext from 'js/common/AppContext';
import Modal      from 'js/common/ui/modal/Modal';
import LinkJoin   from './LinkJoin';
import { useNavigate, useParams } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from "recoil";
import * as Atom  from "js/common/Atom";
import Util from 'js/common/Util';

const Wecake = () => {
    const [linkJoinModalOpen, setLinkJoinModalOpen] = React.useState<boolean>(false);   // Link 회원가입 팝업
    const [linkJoinSelTime  , setLinkJoinSelTime  ] = React.useState<Date>(new Date()); // 회원 가입 후 자동 로그인 처리

    const {wecakeUserId} = useParams();
    const {authStr}      = useParams();
    const navigate       = useNavigate();

    const setIsAdmin  = useSetRecoilState(Atom.isAdmin);
    const setUserInfo = useSetRecoilState(Atom.userInfo);
    const setHshldCnt = useSetRecoilState(Atom.hshldCnt);
    const delUserInfo = useResetRecoilState(Atom.userInfo);
    const setIsSysAdmin = useSetRecoilState(Atom.isSysAdmin);

    React.useEffect(() => {
        Axios.dataAccess({
            url: "portal/join/checkWecakeUserAuth.do",    // 사용자 인증정보 확인
            methodType: "post",
            paramType: "object",
            paramData: {
                wecakeUserId : wecakeUserId,
                authStr: authStr
            },
            onSuccessFn: (res1: any) => {
                if (res1.item !== 1) {
                    AppContext.openAlert({
                        title: "로그인 실패",
                        msg: "로그인 인증이 허가되지 않았습니다.",
                        icon: "check"
                    });
                    return;
                }

                //  회원 상태정보 확인
                Axios.dataAccess({
                    url: "portal/join/selWecakeUserState.do",
                    methodType: "post",
                    paramType: "object",
                    paramData: {
                        wecakeUserId : wecakeUserId
                    },
                    onSuccessFn: (res2: any) => {
                        if (res2.item === "1001") { // 관리자 미승인 - 회원가입
                            setLinkJoinModalOpen(true);
                        } else if (res2.item === "1002") { // 관리자 승인
                            delUserInfo(); // 유저정보 초기화
                            localStorage.removeItem('tokenCrtDt');
                            localStorage.removeItem('grantType');
                            localStorage.removeItem('accessToken');
                            localStorage.removeItem('refreshToken');
                            localStorage.removeItem('hshldSn');
                            localStorage.removeItem('hshldNm');
                            localStorage.removeItem('isAdmin');
                            setIsAdmin(false);
                            setIsSysAdmin(false);

                            // 자동 로그인 처리 후 토큰 발급
                            Axios.dataAccess({
                                url: "portal/auth/wcLogin.do",
                                methodType: "post",
                                paramType: "Object",
                                paramData: {
                                    wecakeUserId : wecakeUserId
                                },
                                onSuccessFn: (res3: any) => {
                                    if (res3.item.loginRslt !== "O") {
                                        AppContext.hideSpinner();
                                        AppContext.openAlert({
                                            title: "로그인 실패",
                                            msg: res3.item.blockMsg,
                                            icon: "warning"
                                        });
                                        return;
                                    }

                                    setUserInfo(res3.item.userInfo);
                                    const tokenCrtDt = Util.getSpecificDate("-", "day", 0, "SS", "");
                                    if (tokenCrtDt !== undefined) {
                                        localStorage.setItem("tokenCrtDt", tokenCrtDt);
                                    }
                                    localStorage.setItem("grantType"   , res3.item.grantType);
                                    localStorage.setItem("accessToken" , res3.item.accessToken);
                                    localStorage.setItem("refreshToken", res3.item.refreshToken);
                                    localStorage.setItem('isAdmin'     , res3.item.userInfo.isAdmin ? "1" : "0");
                                    setIsAdmin(res3.item.userInfo.isAdmin);
                                    setHshldCnt(res3.item.hshldCnt);
                                    navigate('/system/hshld');
                                    AppContext.hideSpinner();
                                }
                            });
                        } else {
                            AppContext.openAlert({
                                title: "로그인 실패",
                                msg: "로그인 인증이 허가되지 않았습니다.",
                                icon: "check"
                            });
                            return;
                        }
                    }
                });
            }
        });
    }, [linkJoinSelTime, wecakeUserId, authStr, setHshldCnt, setIsAdmin, setUserInfo, delUserInfo, setIsSysAdmin, navigate]);

    return (
        <div className='w100p dpFlx jcC'>
            <div className='w1383 mr40 mb125'>
                <div className='w100p h100p mt71 mb26'>
                    <img src={require('img/portal/index/intro_calltax.png')} alt="intro-calltax" />
                </div>
            </div>

            <Modal modalOpen={linkJoinModalOpen} setModalOpen={setLinkJoinModalOpen} title="One-Stop 자산관리 CallTax" dim={true} visible={true}>
            <div className='w500 h500 ofA'>
                <LinkJoin setLinkJoinModalOpen={setLinkJoinModalOpen} setLinkJoinSelTime={setLinkJoinSelTime} wecakeUserId={wecakeUserId} />
            </div>
            </Modal>
        </div>
    )
}
export default Wecake;