/***********************************************************************************
*** Law25at10 ** 종합부동산세 시행령 제3조 제1항 제1호 (민간건설임대주택) - 보유주택이 임대주택인 경우 Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law25at10Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}

export default function Law25at10({ setModalOpen, setLawModalTitle, callBack, callMode }: Law25at10Props) {
    React.useEffect(() => {
        setLawModalTitle("종합부동산세 시행령 제3조 제1항 제1호 (민간건설임대주택) - 보유주택이 임대주택인 경우");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className='w100p hAuto ofA mb30'>
                <table className={
                    'forest-list-table bdt1 bdb1 bdcDDDDDD ' +
                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075'
                }>
                   <colgroup>
                        <col className='w100'></col>
                        <col className='w130'></col>
                        <col className='w130'></col>
                        <col className='w130'></col>
                        <col className='w160'></col>
                        <col className='w160'></col>
                        <col className='w160'></col>
                        <col className='w130'></col>
                        <col className='w130'></col>
                        <col className='w130'></col>
                    </colgroup>
                    <tbody>
                        <tr className='h40'>
                            <th rowSpan={2}>구분</th>
                            <th rowSpan={2}>주택구분</th>
                            <th rowSpan={2}>주택규모</th>
                            <th rowSpan={2}>가액기준</th>
                            <th colSpan={2}>등록</th>
                            <th rowSpan={2}>민특법 분류</th>
                            <th rowSpan={2}>임대기간</th>
                            <th rowSpan={2}>상한율</th>
                            <th>혜택</th>
                        </tr>
                        <tr className='h29'>
                            <th>지자체</th>
                            <th>세무서</th>
                            <th>종부세 합산여부</th>
                        </tr>
                        <tr className='h110'>
                            <th>개별사항</th>
                            <td>모든주택</td>
                            <td>전용면적<br/>149㎡ 이하</td>
                            <td>공시가격<br/>6억원 이하</td>
                            <td>2018.03.31. 이전<br/>단기 등록</td>
                            <td>2018.03.31. 이전<br/>등록</td>
                            <td>민간건설임대주택</td>
                            <td>5년 이상</td>
                            <td>5% 제한</td>
                            <td>배제</td>
                        </tr>
                        <tr className='h110'>
                            <th>공통사항</th>
                            <td className='taL pt15 pb15 pl17'colSpan={9}>
                                <ul className='lsN m0 p0'>
                                    <li>① 가액요건은 2호 이상 임대개시일 또는 최초 합산배제 신고연도의 과세기준일 현재</li>
                                    <li>② 증액제한은 2019.2.12. 이후 주택임대차계약을 갱신하거나 새로 체결하는 분부터 적용</li>
                                    <li className='pl17'>
                                        ※보증금 → 임대료 전환 시 임대료 인상률 산정방법<br />
                                        • 인상률 = [(갱신후 환산보증금 – 기존 환산보증금)/기존 환산보증금]×100<br />
                                        • 환산보증금 = 임대보증금 + [(월임대료×12)/2.5%]<br />
                                    </li>
                                    <li>③ 합산배제 신고</li>
                                    <li className='pl17'>합산배제 적용대상 주택을 보유한 자가 합산배제 임대주택의 규정을 적용받으려는 때에는 매년 9.16. ~ 9.30. 까지 합산배제 신고서를 납세지 관할세무서장에게 제출하여야 한다.</li>
                                    <li className='pl17'>다만, 최초의 합산배제신고를 한 연도의 다음 연도부터는 그 신고한 내용 중 소유권 또는 전용면적 등의 변동이 없는 경우에는 신고하지 아니할 수 있다.</li>
                                    <li>④ 합산배제 사후관리</li>
                                    <li className='pl17'>
                                        합산배제 임대주택을 임대의무기간 이내에 양도하거나, 공가기간이 법정공가기간을 초과한 경우 등 합산배제 의무요건을 충족하지 못한 경우에는 합산배제 적용으로 경감받은 세액과 이자상당액을 추징한다.<br/>
                                        다만, 임대의무기간 종료일에 임대사업자 등록이 자동 말소되는 아파트 장기일반민간매입임대주택과 단기임대주택을 민간임대주택에 관한 특별법에 따라 임대의무기간 내에 임차인의 동의를 받아<br/>
                                        임대사업등록을 말소한 경우 기 합산배제로 경감받은 종합부동산세는 추징하지 않는다(자동 말소된 경우 포함).
                                    </li>
                                    <li>⑤ 자진말소·자동말소 이후 합산배제 제외 신고를 하여야 한다.</li>
                                </ul>
                            </td>
                        </tr>
                        <tr className='h70'>
                            <th>추가<br/>확인사항</th>
                            <td className='taL pt15 pb15 pl17' colSpan={9}>
                                (1) 임대호수 : 2호 이상<br />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}
