/*******************************************************************************
 *** PersonDeduInfo ** 미성년자, 장애인 공제 정보 입력
 *******************************************************************************/

import { Dispatch, SetStateAction } from "react";
import { produce } from "immer";
import styled from "styled-components";
import { NumericFormat } from "react-number-format";

import { InputContainer, NumberInputContainer } from "js/mobile/common/layout";
import {
  Code,
  DisableInfoProps,
  PersonDeduInputValue,
} from "js/common/types/types";
import Util from "js/common/Util";
import SelectBox, { SelectOption } from "js/mobile/common/SelectBox";
import AssetsUtil from "js/common/AssetsUtil";

type Props = {
  inputValue: PersonDeduInputValue;
  setInputValue: Dispatch<SetStateAction<PersonDeduInputValue>>;
};

function PersonDeduInfo({ inputValue, setInputValue }: Props) {
  const handleChangeKidAge = (index: number, value: string) => {
    setInputValue(
      produce((draft: PersonDeduInputValue) => {
        const under19Infos = draft.under19Infos;
        const currentInfo = under19Infos[index];
        currentInfo.kidAge = value;

        // 미성년자 나이 입력에 따른 공제 금액 계산 처리
        if (Util.isNotEmpty(value)) {
          if (Number(value) >= 0 && Number(value) < 19) {
            const calKidDedu = (19 - parseInt(value)) * 10000000;
            currentInfo.kidDedu = calKidDedu;
          } else {
            currentInfo.kidDedu = 0;
          }
        } else {
          currentInfo.kidDedu = 0;
        }
      })
    );
  };

  const handleChangeDisableInfo = <K extends keyof DisableInfoProps>(
    index: number,
    key: K,
    value: DisableInfoProps[K]
  ) => {
    setInputValue(
      produce((draft: PersonDeduInputValue) => {
        const disableInfos = draft.disableInfos;
        const currentInfo = disableInfos[index];
        currentInfo[key] = value;

        // 장애인 나이 입력에 따른 공제 금액 계산 처리
        // 남 : (79.9 – 나이) * 1천만원, 여 : (85.6 – 나이) * 1천만원
        const disAgeValue = key === "disAge" ? value : currentInfo.disAge;
        const disGenderValue =
          key === "disGender" ? value : currentInfo.disGender;

        let calDisDedu = 0;
        if (
          Util.isNotEmpty(disAgeValue) &&
          Number(disAgeValue) >= 0 &&
          Util.isNotEmpty(disGenderValue)
        ) {
          if (disGenderValue === "1001") {
            // 남
            const lifeExpectMan = AssetsUtil.getLifeExpectMan(
              disAgeValue.toString()
            );
            calDisDedu = parseInt(lifeExpectMan) * 10000000;
          } else if (disGenderValue === "1002") {
            // 여
            const lifeExpectWom = AssetsUtil.getLifeExpectWom(
              disAgeValue.toString()
            );
            calDisDedu = parseInt(lifeExpectWom) * 10000000;
          }
        } else {
          calDisDedu = 0;
        }

        currentInfo.disDedu = calDisDedu;
      })
    );
  };

  return (
    <>
      <InputContainer>
        <p className="label">자녀 공제</p>
        <div className="input">
          <NumberInputContainer
            $filled={inputValue.childDedu === "" ? false : true}
          >
            <div className="inputWrap">
              <NumericFormat
                name="childDedu"
                value={inputValue.childDedu}
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                maxLength={15}
                disabled={true}
                className="numberInput"
              />
              <p className="unit">원</p>
            </div>
          </NumberInputContainer>
        </div>
      </InputContainer>
      <InputContainer>
        <p className="label">연로자 공제</p>
        <div className="input">
          <NumberInputContainer
            $filled={inputValue.older65Dedu === "" ? false : true}
          >
            <div className="inputWrap">
              <NumericFormat
                name="older65Dedu"
                value={inputValue.older65Dedu}
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                maxLength={15}
                disabled={true}
                className="numberInput"
              />
              <p className="unit">원</p>
            </div>
          </NumberInputContainer>
        </div>
      </InputContainer>
      <MultiInputContainer>
        <MultiInputTitle>
          <span>미성년자 공제 </span>
          <span className="green">(총 {inputValue.under19Number}명)</span>
        </MultiInputTitle>
        {inputValue.under19Infos.length === 0 ? (
          <DeduEmpty>공제할 대상이 없습니다.</DeduEmpty>
        ) : (
          <MultiInputList>
            {inputValue.under19Infos.map((info, index) => (
              <li key={info.kidNo}>
                <div className="index">{info.kidNo}</div>
                <div className="inputGroup">
                  <div className="groupItem">
                    <NumberInputContainer
                      $filled={info.kidAge === "" ? false : true}
                    >
                      <div className="inputWrap">
                        <NumericFormat
                          name="kidAge"
                          value={info.kidAge}
                          thousandsGroupStyle="thousand"
                          thousandSeparator=","
                          maxLength={3}
                          disabled={false}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const valueWithoutComma = inputValue.replace(
                              /,/g,
                              ""
                            );
                            handleChangeKidAge(index, valueWithoutComma);
                          }}
                          className="numberInput"
                        />
                        <p className="unit">세</p>
                      </div>
                    </NumberInputContainer>
                  </div>
                  <div className="groupItem">
                    <NumberInputContainer
                      $filled={info.kidDedu <= 0 ? false : true}
                    >
                      <div className="inputWrap">
                        <NumericFormat
                          name="kidDedu"
                          value={info.kidDedu}
                          thousandsGroupStyle="thousand"
                          thousandSeparator=","
                          maxLength={15}
                          disabled={true}
                          className="numberInput"
                        />
                        <p className="unit">원</p>
                      </div>
                    </NumberInputContainer>
                  </div>
                </div>
              </li>
            ))}
          </MultiInputList>
        )}
      </MultiInputContainer>
      <MultiInputContainer>
        <MultiInputTitle>
          <span>장애인 공제 </span>
          <span className="green">(총 {inputValue.disabilityNumber}명)</span>
        </MultiInputTitle>
        {inputValue.disableInfos.length === 0 ? (
          <DeduEmpty>공제할 대상이 없습니다.</DeduEmpty>
        ) : (
          <MultiInputList>
            {inputValue.disableInfos.map((info, index) => (
              <li key={info.disNo}>
                <div className="index">{info.disNo}</div>
                <div className="inputGroup">
                  <div className="groupItem threeColumn">
                    <SelectBox
                      placeholder="성별"
                      options={Util.getCodeArray("1002").map((code: Code) => ({
                        value: code.code,
                        label: code.codeNm,
                      }))}
                      selectedValue={info.disGender}
                      handleSelect={(selected: SelectOption) => {
                        handleChangeDisableInfo(
                          index,
                          "disGender",
                          selected.value as never
                        );
                      }}
                    />
                  </div>
                  <div className="groupItem threeColumn">
                    <NumberInputContainer
                      $filled={info.disAge === "" ? false : true}
                    >
                      <div className="inputWrap">
                        <NumericFormat
                          name="disAge"
                          value={info.disAge}
                          thousandsGroupStyle="thousand"
                          thousandSeparator=","
                          maxLength={3}
                          disabled={false}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const valueWithoutComma = inputValue.replace(
                              /,/g,
                              ""
                            );
                            handleChangeDisableInfo(
                              index,
                              "disAge",
                              valueWithoutComma as never
                            );
                          }}
                          className="numberInput"
                        />
                        <p className="unit">세</p>
                      </div>
                    </NumberInputContainer>
                  </div>
                  <div className="groupItem threeColumn">
                    <NumberInputContainer
                      $filled={info.disDedu <= 0 ? false : true}
                    >
                      <div className="inputWrap">
                        <NumericFormat
                          name="disDedu"
                          value={info.disDedu}
                          thousandsGroupStyle="thousand"
                          thousandSeparator=","
                          maxLength={15}
                          disabled={false}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const valueWithoutComma = inputValue.replace(
                              /,/g,
                              ""
                            );
                            handleChangeDisableInfo(
                              index,
                              "disDedu",
                              parseInt(valueWithoutComma)
                            );
                          }}
                          className="numberInput"
                        />
                        <p className="unit">원</p>
                      </div>
                    </NumberInputContainer>
                  </div>
                </div>
              </li>
            ))}
          </MultiInputList>
        )}
      </MultiInputContainer>
    </>
  );
}

const MultiInputContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const MultiInputTitle = styled.div`
  font-family: "Spoqa Han Sans Neo";
  font-weight: 500;
  font-size: 1.4em;
  line-height: 1.32;
  letter-spacing: -0.05em;
  color: ${(props) => props.theme.color.sNavy};

  span.green {
    color: ${(props) => props.theme.color.pGreenDark01};
  }
`;

const MultiInputList = styled.ol`
  width: 100%;
  margin: 0;
  padding: 11px 0;
  list-style: none;

  li {
    width: 100%;
    padding: 5px 0;
    border-bottom: 1px solid ${(props) => props.theme.color.nGray70};

    &:last-child {
      border-bottom: none;
    }

    .index {
      display: inline-flex;
      align-items: center;
      width: 24px;

      font-family: "Spoqa Han Sans Neo";
      font-weight: 500;
      font-size: 1.4em;
      line-height: 1.4;
      letter-spacing: -0.028em;
      color: ${(props) => props.theme.color.pGreenDark01};
    }

    .inputGroup {
      display: inline-flex;
      align-items: flex-start;
      gap: 5px;
      width: calc(100% - 24px);

      .groupItem {
        flex: 1 1 50%;
        min-width: 0px;

        &.threeColumn {
          &:first-child {
            flex: 1 1 25%;
          }

          &:nth-child(2) {
            flex: 1 1 25%;
          }

          &:last-child {
            flex: 1 1 40%;
          }
        }
      }
    }
  }
`;

const DeduEmpty = styled.div`
  width: 100%;
  padding: 25px 16px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.color.nGray70};
  background-color: white;

  font-family: "Spoqa Han Sans Neo";
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.32;
  letter-spacing: -0.05em;
  color: ${(props) => props.theme.color.nGray20};
  text-align: center;
`;

export default PersonDeduInfo;
