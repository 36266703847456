import styled from "styled-components";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardOutlined from "@mui/icons-material/ArrowForwardOutlined";
import { calculatorPcWebMaxWidth } from "js/common/const";

type Props = {
  onClickBack: () => void;
  onClickNext: () => void;
  onClickReset: () => void;
  nextDisabled: boolean;
  backDisabled: boolean;
  isLastStep: boolean;
};

function StepButton({
  onClickBack,
  onClickNext,
  onClickReset,
  backDisabled,
  nextDisabled,
  isLastStep,
}: Props) {
  const handleClickNext = () => {
    if (nextDisabled) {
      return;
    } else {
      onClickNext();
    }
  };

  return (
    <ButtonContainer>
      <div className="buttonWrap">
        <button
          className={`side ${backDisabled ? "disabled" : ""}`}
          onClick={onClickBack}
        >
          <span className="icon">
            <ArrowBackOutlinedIcon fontSize="small" />
          </span>
          <span className="text">Back</span>
        </button>
        <button
          className={`side ${nextDisabled ? "disabled" : ""}`}
          onClick={handleClickNext}
        >
          <span className="text">{isLastStep ? "계산하기" : "Next"}</span>
          <span className="icon">
            <ArrowForwardOutlined fontSize="small" />
          </span>
        </button>
        <button className="reset" onClick={onClickReset}>
          <span className="text">입력 초기화</span>
          <span className="icon">
            <img
              src={require("img/common/arrows_restart_clockwise.png")}
              alt="reset"
            />
          </span>
        </button>
      </div>
    </ButtonContainer>
  );
}

const ButtonContainer = styled.div`
  /*position: fixed;    
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);*/

  position: sticky;
  bottom: 0px;

  width: 100%;
  padding: 14px 20px;

  @media (min-width: 1201px) {
    max-width: ${calculatorPcWebMaxWidth};
  }

  .buttonWrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 52px;
    padding: 6px 8px;
    background-color: ${(props) => props.theme.color.nGray95};
    border-radius: 10px;

    button.side {
      display: flex;
      align-items: center;
      gap: 8px;

      height: 100%;
      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.4em;
      letter-spacing: -0.0357em;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 20px;
        height: 20px;
      }

      &.disabled {
        color: ${(props) => props.theme.color.nGray60};
      }
    }

    button.reset {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4px;

      padding: 6px 11px;
      border-radius: 31px;
      background-color: ${(props) => props.theme.color.pGreenLight04};

      font-family: "Spoqa Han Sans Neo";
      font-weight: 500;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;

      .text {
        color: ${(props) => props.theme.color.nGray30};
      }

      .icon {
        width: 18px;
        height: 18px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;

export default StepButton;
