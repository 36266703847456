import { components } from "react-select";

const { DropdownIndicator } = components;

export const CustomDropdownIndicator = (props: any) => {
    const {
        selectProps: { menuIsOpen }
    } = props;
    return (
        <DropdownIndicator {...props}>
            {menuIsOpen ?
                <img src={require('img/common/icon_ArrowLineUp.png')} alt="icon_ArrowLineUp" /> :
                <img src={require('img/common/icon_ArrowLineDown.png')} alt="icon_ArrowLineDown" />
            }
        </DropdownIndicator>
    );
};
