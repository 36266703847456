/*******************************************************************************
*** inheTaxStatesReducer ** 모바일 상속세 상태, Action, 초기값, Actiontype에 따른 Event
*******************************************************************************/
import { produce } from "immer";
import Util        from "js/common/Util";
import { InheTaxParamDisabled, InheTaxParamProps, InheTaxParamVisible, InheTaxParamTitle } from "js/common/types/types";

export interface InheTaxState {
    inputValue   : InheTaxParamProps;       // 입력항목 parameter
    inputVisible : InheTaxParamVisible;     // 입력항목 visible
    inputDisabled: InheTaxParamDisabled;    // 입력항목 disalbed
    inputTitle   : InheTaxParamTitle;       // 입력항목 title
}

export type InheTaxAction = { type: InheTaxActionType; payload: any };

export type InheTaxActionType =
    | "UPDATE_INHER_PROP"             // 01. 상속재산가액  
    | "UPDATE_ESTI_INHER"             // 02. 추정상속가액
    | "UPDATE_TRUST_ASSETS"           // 03. 퇴직금/보험금/신탁자산 
    | "UPDATE_SPOUSE"                 // 04. 배우자 유무
    | "UPDATE_SPOUSE_PRE_GIFT"        // 05. 배우자 사전증여 유무 
    | "UPDATE_SPOUSE_PRE_GIFT_PROP"   // 06. 배우자 사전증여 재산가액 
    | "UPDATE_SPOUSE_PRE_GIFT_TAX"    // 07. 배우자 사전증여세액 
    | "UPDATE_CHILD_NUMBER"           // 08. 자녀 수         
    | "UPDATE_PRE_GIFT"               // 09. 사전증여 유무         
    | "UPDATE_PRE_GIFT_PROP"          // 10. 사전증여 재산가액 
    | "UPDATE_PRE_GIFT_TAX"           // 11. 사전증여세액 
    | "UPDATE_DEBT_AMT"               // 12. 채무
    | "UPDATE_UTILITY_BILL"           // 13. 공과금 
    | "UPDATE_FUNERAL_EXPEN"          // 14. 장례비용 
    | "UPDATE_DEDU_CLS"               // 15. 공제구분
    | "UPDATE_SUM_DEDU"               // 16. 일괄공제
    | "UPDATE_BASIC_DEDU"             // 17. 기초공제
    | "UPDATE_PERSON_DEDU"            // 18. 인적공제
    | "UPDATE_SPOUSE_DISABLED"        // 19. 배우자 장애인 여부 
    | "UPDATE_SPOUSE_GENDER"          // 20. 배우자 성별 
    | "UPDATE_SPOUSE_AGE"             // 21. 배우자 나이
    | "UPDATE_SPOUSE_INHER_PROP"      // 22. 배우자 상속재산가액     
    | "UPDATE_SPOUSE_SUC_UTIL_DEBT"   // 23. 배우자 승계 공과금&채무         
    | "UPDATE_FAMILY_DEDU"            // 24. 가업상속공제
    | "UPDATE_FARM_DEDU"              // 25. 영농상속공제
    | "UPDATE_HOUSE_DEDU"             // 26. 동거주택상속공제
    | "UPDATE_FINAN_DEDU"             // 27. 금융상속공제
    | "UPDATE_DIRECT_HEIR"            // 22_0. 직계존속 상속인 유무
    | "INIT_BASIC_INFO"               // 기초정보 입력 초기화
    | "INIT_GIFT_INFO"                // 증여정보 입력 초기화
    | "INIT_DEDU_INFO"                // 공제정보 입력 초기화
    | "INIT_EXTRA_INFO"               // 추가정보 입력 초기화
    | "CAL_PERSON_DEDU"               // 인적공제금액 계산
    | "CAL_FINANCIAL_DEDU"            // 금융공제금액 계산  

export const initialState: InheTaxState = {
    inputValue: {
          taxFlag          : ""  // 00. 세금 구분 Flag
        , inherProp        : ""  // 01. 상속재산가액  
        , estiInher        : ""  // 02. 추정상속가액
        , trustAssets      : ""  // 03. 퇴직금/보험금/신탁자산
        , spouse           : ""  // 04. 배우자 유무
        , spousePreGift    : ""  // 05. 배우자 사전증여 유무
        , spousePreGiftProp: ""  // 06. 배우자 사전증여 재산가액
        , spousePreGiftTax : ""  // 07. 배우자 사전증여세액
        , childNumber      : ""  // 08. 자녀 수
        , preGift          : ""  // 09. 사전증여 유무
        , preGiftProp      : ""  // 10. 사전증여 재산가액
        , preGiftTax       : ""  // 11. 사전증여세액
        , debtAmt          : ""  // 12. 채무
        , utilityBill      : ""  // 13. 공과금
        , funeralExpen     : ""  // 14. 장례비용
        , deduCls          : ""  // 15. 공제구분
        , sumDedu          : "500000000"  // 16. 일괄공제
        , basicDedu        : "200000000"  // 17. 기초공제
        , personDedu       : ""  // 18. 인적공제
        , spouseDisabled   : ""  // 19. 배우자 장애인 여부
        , spouseGender     : ""  // 20. 배우자 성별
        , spouseAge        : ""  // 21. 배우자 나이
        , spouseInherProp  : ""  // 22. 배우자 상속재산가액
        , spouseSucUtilDebt: ""  // 23. 배우자 승계 공과금&채무
        , familyDedu       : ""  // 24. 기업상속공제
        , farmDedu         : ""  // 25. 영농상속공제
        , houseDedu        : ""  // 26. 동거주택상속공제
        , finanDedu        : ""  // 27. 금융상속공제
        , directHeir       : ""  // 22_0. 직계존속 상속인 유무
        , finAssets        : ""  // 금융자산
        , finDebts         : ""  // 금융부채        
    },
    inputVisible: {
          visible01  : true   // 01. 상속재산
        , visible02  : true   // 02. 추정상속가액
        , visible03  : true   // 03. 퇴직금/보험금/신탁자산
        , visible04  : false  // 04. 배우자 유무
        , visible05  : false  // 05. 배우자 사전증여 유무
        , visible06  : false  // 06. 배우자 사전증여 재산가액
        , visible07  : false  // 07. 배우자 사전증여세액
        , visible08  : false  // 08. 자녀 수
        , visible09  : false  // 09. 사전증여 유무
        , visible10  : false  // 10. 사전증여 재산가액
        , visible11  : false  // 11. 사전증여세액
        , visible12  : true   // 12. 채무
        , visible13  : true   // 13. 공과금
        , visible14  : true   // 14. 장례비용
        , visible15  : false  // 15. 공제구분
        , visible16  : false  // 16. 일괄공제
        , visible17  : false  // 17. 기초공제
        , visible18  : false  // 18. 인적공제
        , visible19  : false  // 19. 배우자 장애인 여부
        , visible20  : false  // 20. 배우자 성별
        , visible21  : false  // 21. 배우자 나이
        , visible22  : false  // 22. 배우자 상속재산가액
        , visible23  : false  // 23. 배우자 승계 공과금&채무
        , visible24  : false  // 24. 기업상속공제
        , visible25  : false  // 25. 영농상속공제
        , visible26  : false  // 26. 동거주택상속공제
        , visible27  : false  // 27. 금융상속공제
        , visible22_0: false  // 22_0. 직계존속 상속인 유무
    },
    inputDisabled: {
          disabled04: false  // 04. 배우자 유무
        , disabled08: false  // 08. 자녀 수
        , disabled15: false  // 15. 공제구분
        , disabled16: false  // 16. 일괄공제
        , disabled17: false  // 17. 기초공제
    },
    inputTitle: {
          title09: "사전증여 유무"      // 09. 사전증여 유무
        , title10: "사전증여 재산가액"  // 10. 사전증여 재산가액
        , title11: "사전증여세액"       // 11. 사전증여세액  
    }
};

export const inheTaxStatesReducer = produce(
    (draft: InheTaxState, action: InheTaxAction) => {
        const inputValue    = draft.inputValue;
        const inputVisible  = draft.inputVisible;
        const inputDisabled = draft.inputDisabled;
        const inputTitle    = draft.inputTitle;

        switch (action.type) {
            // 01. 상속재산가액 
            case "UPDATE_INHER_PROP": {
                inputValue.inherProp = action.payload;
                break;
            }
            
            // 02. 추정상속가액
            case "UPDATE_ESTI_INHER": {
                inputValue.estiInher = action.payload;
                break;
            }

            // 03. 퇴직금/보험금/신탁자산
            case "UPDATE_TRUST_ASSETS": {
                inputValue.trustAssets = action.payload;
                break;
            }

            // 12. 채무
            case "UPDATE_DEBT_AMT": {
                inputValue.debtAmt = action.payload;
                break;
            }

            // 13. 공과금 
            case "UPDATE_UTILITY_BILL": {
                inputValue.utilityBill = action.payload;
                break;
            }        

            // 14. 장례비용
            case "UPDATE_FUNERAL_EXPEN": {
                inputValue.funeralExpen = action.payload;

                if (Util.isNotEmpty(action.payload)) {
                    inputVisible.visible04 = true; // 04. 배우자 유무 ▶ 다음 단계 visible 처리  
                }
                break;
            }        

            // 04. 배우자 유무
            case "UPDATE_SPOUSE": {
                inputValue.spouse = action.payload;

                if (action.payload === "Y") {       // 배우자 있음 ▶ 05.배우자 사전증여 유무
                    inputVisible.visible05 = true ;
                    inputVisible.visible08 = false; // 08.자녀 수 미표시
                    inputValue.childNumber = ""   ; // 08.자녀 수 초기화
                } else if (action.payload === "N") {    // 배우자 없음 ▶ 08.자녀 수
                    inputVisible.visible08       = true ;
                    inputVisible.visible05       = false; // 05.배우자 사전증여 유무 미표시
                    inputValue.spousePreGift     = ""   ; // 05.배우자 사전증여 유무 초기화
                    inputVisible.visible06       = false; // 06.배우자 사전증여 재산가액 미표시
                    inputValue.spousePreGiftProp = ""   ; // 06.배우자 사전증여 재산가액 초기화
                    inputVisible.visible07       = false; // 07.배우자 사전증여세액 미표시
                    inputValue.spousePreGiftTax  = ""   ; // 07.배우자 사전증여세액 초기화

                    // 배우자 무 + 자녀 무 ▶ 기타 사전증여 유무 확인
                    if (Util.isNotEmpty(inputValue.childNumber) && Number(inputValue.childNumber) >= 0) {
                        if (!inputVisible.visible09) inputVisible.visible09 = true; // 09.(자녀)사전증여 여부 
                    }
                }    
                break;
            }

            // 05. 배우자 사전증여 유무
            case "UPDATE_SPOUSE_PRE_GIFT": {
                inputValue.spousePreGift = action.payload;

                if (action.payload === "Y") {  // 배우자 사전증여 유 ▶ 6.배우자 사전증여 재산가액, 7.배우자 사전증여세액, 08.자녀수
                    inputDisabled.disabled04 = true; // 04.배우자 유무 Fixed
                    inputVisible.visible06   = true; // 06.배우자 사전증여 재산가액
                    inputVisible.visible07   = true; // 07.배우자 사전증여세액
                    inputVisible.visible08   = true; // 08.자녀수
                } else if (action.payload === "N") {  // 배우자 사전증여 무 ▶  08.자녀수
                    inputDisabled.disabled04     = true ; // 04.배우자 유무 Fixed
                    inputVisible.visible06       = false; // 06.배우자 사전증여 재산가액 미표시
                    inputValue.spousePreGiftProp = ""   ; // 06.배우자 사전증여 재산가액 초기화
                    inputVisible.visible07       = false; // 07.배우자 사전증여세액 미표시
                    inputValue.spousePreGiftTax  = ""   ; // 07.배우자 사전증여세액 초기화
                    inputVisible.visible08       = true ; // 08.자녀수
                }    
                break;
            }

            // 06. 배우자 사전증여 재산가액 
            case "UPDATE_SPOUSE_PRE_GIFT_PROP": {
                inputValue.spousePreGiftProp = action.payload;
            break;
            }

            // 07. 배우자 사전증여세액 
            case "UPDATE_SPOUSE_PRE_GIFT_TAX": {
                inputValue.spousePreGiftTax = action.payload;
                break;
            }

            // 08. 자녀 수
            case "UPDATE_CHILD_NUMBER": {
                inputValue.childNumber = action.payload;

                if (inputValue.spouse === "Y") {    // 배우자 유
                    if (Util.isNotEmpty(action.payload) && Number(action.payload) === 0) {       // 자녀 없음 ▶ 15.공제구분 Fixed, 17.기초공제, 19.배우자 장애인 여부
                        inputTitle.title09     = "사전증여 유무";
                        inputTitle.title10     = "사전증여 재산가액";
                        inputTitle.title11     = "사전증여세액";
                        inputVisible.visible09 = false; // 09.(자녀)사전증여 여부 미표시
                        inputValue.preGift     = ""   ; // 09.(자녀)사전증여 여부 초기화
                        inputVisible.visible10 = false; // 10.(자녀)사전증여 재산가액 미표시
                        inputValue.preGiftProp = ""   ; // 10.(자녀)사전증여 재산가액 초기화
                        inputVisible.visible11 = false; // 11.(자녀)사전증여세액 미표시
                        inputValue.preGiftTax  = ""   ; // 11.(자녀)사전증여세액 초기화

                        // 배우자 유 > 자녀 무 : 15.공제구분 : 기초 + 인적 : Default : 배우자 인적공제로 진행
                        inputVisible.visible15   = true; // 15.공제구분 표시
                        inputDisabled.disabled15 = true; // 15.공제구분 Fixed
                        inputValue.deduCls       = "B" ; // 15.공제구분 : 기초 + 공제
                        inputVisible.visible17   = true; // 17.기초공제 표시
                        inputVisible.visible19   = true ; // 19.배우자 장애인 여부 표시
                    } else if (Util.isNotEmpty(action.payload) && Number(action.payload) > 0) {  // 자녀 있음 ▶ 09.(자녀)사전증여 유무
                        inputTitle.title09     = "자녀 사전증여 유무";
                        inputTitle.title10     = "자녀 사전증여 재산가액";
                        inputTitle.title11     = "자녀 사전증여세액";
                        inputVisible.visible09 = true; // 09.(자녀)사전증여 유무

                        inputVisible.visible15 = true ; // 15.공제구분 표시
                        inputVisible.visible17 = false; // 17.기초공제 미표시
                        inputVisible.visible19 = false; // 19.배우자 장애인 여부 미표시

                        inputDisabled.disabled15  = false ; // 15.공제구분 Active
                        inputValue.deduCls        = ""    ; // 15.공제구분 초기화
                        inputValue.spouseDisabled = ""    ; // 19.배우자 장애인 여부 초기화
                    } else {    // 입력 값이 없는 경우(isEmpty)
                        inputTitle.title09        = "사전증여 유무";
                        inputTitle.title10        = "사전증여 재산가액";
                        inputTitle.title11        = "사전증여세액";
                        inputVisible.visible09    = false ; // 09.(자녀)사전증여 여부 미표시
                        inputValue.preGift        = ""    ; // 09.(자녀)사전증여 여부 초기화

                        inputVisible.visible15   = true ; // 15.공제구분 표시
                        inputDisabled.disabled15 = false; // 15.공제구분 Active
                        inputValue.deduCls       = ""   ; // 15.공제구분 초기화
                        inputVisible.visible17   = false; // 17.기초공제 미표시
                        inputVisible.visible18   = false; // 18.인적공제 미표시
                        inputValue.personDedu    = ""   ; // 18.인적공제 초기화
                    }        
                } else if (inputValue.spouse === "N") {    // 배우자 무
                    if (Util.isNotEmpty(action.payload) && Number(action.payload) === 0) {       // 자녀 없음 ▶ 09.사전증여 유무
                        inputTitle.title09 = "사전증여 유무";
                        inputTitle.title10 = "사전증여 재산가액";
                        inputTitle.title11 = "사전증여세액";
                        inputDisabled.disabled04 = true; // 04.배우자 유무 Fixed
                        inputVisible.visible09   = true; // 09.사전증여 유무
    
                        // 배우자 무 > 자녀 무 : 15.공제구분 > 일괄공제 : Default
                        inputValue.deduCls       = "A";  // 15.공제구분 - 일괄공제
                        inputVisible.visible15   = true; // 15.공제구분 표시
                        inputDisabled.disabled15 = true; // 15.공제구분 Fixed
                        inputVisible.visible16   = true; // 16.일괄공제 표시

                        // 24.가입상속공제, 25.영농상속공제, 26.동거주택상속공제, 27.금융상속공제
                        inputVisible.visible24 = true;
                        inputVisible.visible25 = true;
                        inputVisible.visible26 = true;
                        inputVisible.visible27 = true;
                    } else if (Util.isNotEmpty(action.payload) && Number(action.payload) > 0) {  // 자녀 있음 ▶ 09.(자녀)사전증여 유무
                        inputTitle.title09     = "자녀 사전증여 유무";
                        inputTitle.title10     = "자녀 사전증여 재산가액";
                        inputTitle.title11     = "자녀 사전증여세액";
                        inputDisabled.disabled04 = true; // 04.배우자 유무 Fixed
                        inputVisible.visible09   = true; // 09.(자녀)사전증여 유무

                        inputValue.deduCls       = ""   ; // 15.공제구분 초기화
                        inputVisible.visible15   = true;  // 15.공제구분 표시
                        inputDisabled.disabled15 = false; // 15.공제구분 Active
                        inputVisible.visible16   = false; // 16.일괄공제 미표시

                        // 24.가입상속공제, 25.영농상속공제, 26.동거주택상속공제, 27.금융상속공제
                        inputVisible.visible24 = false; // 24.가입상속공제 미표시
                        inputVisible.visible25 = false; // 25.영농상속공제 미표시
                        inputVisible.visible26 = false; // 26.동거주택상속공제 미표시
                        inputVisible.visible27 = false; // 27.금융상속공제 미표시 
                    } else {    // 입력 값이 없는 경우(isEmpty)
                        inputVisible.visible09 = false; // 09.사전증여 유무 미표시
                        inputValue.preGift     = ""   ; // 09.(자녀)사전증여 여부 초기화
                        inputVisible.visible10 = false; // 10.(자녀)사전증여 재산가액 미표시
                        inputValue.preGiftProp = ""   ; // 10.(자녀)사전증여 재산가액 초기화
                        inputVisible.visible11 = false; // 11.(자녀)사전증여세액 미표시
                        inputValue.preGiftTax  = ""   ; // 11.(자녀)사전증여세액 초기화
                   }
                }        
                break;
            }

            // 09. 사전증여 유무
            case "UPDATE_PRE_GIFT": {
                inputValue.preGift = action.payload;

                if (action.payload === "Y") {         // 사전증여 유 ▶ 10.(자녀)사전증여 재산가액, 11.(자녀)사전증여세액
                    inputVisible.visible10 = true;
                    inputVisible.visible11 = true;
                } else if (action.payload === "N") {  // 사전증여 무
                    inputVisible.visible10 = false; // 10.(자녀)사전증여 재산가액 미표시
                    inputValue.preGiftProp = ""   ; // 10.(자녀)사전증여 재산가액 초기화
                    inputVisible.visible11 = false; // 11.(자녀)사전증여세액 미표시
                    inputValue.preGiftTax  = ""   ; // 11.(자녀)사전증여세액 초기화
                } else {}

                break;
            }

            // 10. 사전증여 재산가액
            case "UPDATE_PRE_GIFT_PROP": {
                inputValue.preGiftProp = action.payload;
                break;
            }

            // 11. (자녀)사전증여세액
            case "UPDATE_PRE_GIFT_TAX": {
                inputValue.preGiftTax = action.payload;
                break;
            }

            // 15. 공제구분
            case "UPDATE_DEDU_CLS": {
                inputValue.deduCls = action.payload;
                
                // 배우자 무 & 자녀 무 → 일괄공제 Default 적용
                if (action.payload === "A") {         // 일괄공제 ▶ 16.일괄공제
                    inputVisible.visible16   = true;
                    inputDisabled.disabled16 = true;

                    if (inputValue.spouse === "Y") {   // 배우자 유 ▶ 22.배우자 상속재산가액
                        inputVisible.visible22     = true;
                        inputValue.spouseInherProp = "" ; // 22.배우자 상속재산가액 초기화

                        // 24.가입상속공제, 25.영농상속공제, 26.동거주택상속공제, 27.금융상속공제
                        inputVisible.visible24 = false; // 24.가입상속공제 미표시
                        inputVisible.visible25 = false; // 25.영농상속공제 미표시
                        inputVisible.visible26 = false; // 26.동거주택상속공제 미표시
                        inputVisible.visible27 = false; // 27.금융상속공제 미표시 
                    } else if (inputValue.spouse === "N") {    // 배우자 무 ▶ 24.가입상속공제, 25.영농상속공제, 26.동거주택상속공제, 27.금융상속공제
                        inputVisible.visible24 = true;
                        inputVisible.visible25 = true;
                        inputVisible.visible26 = true;
                        inputVisible.visible27 = true;
                    } else {}

                    inputVisible.visible17 = false; // 17.기초공제 미표시
                    inputVisible.visible18 = false; // 18.인적공제 미표시
                    inputValue.personDedu = ""    ; // 18.인적공제 초기화

                // 배우자 유 & 자녀 무 → 기초 & 인적 Default 적용    
                } else if (action.payload === "B") {  // 기초 + 인적공제 ▶ 17.기초공제 + 18.인적공제
                    inputVisible.visible17 = true ; // 17.기초공제
                    inputVisible.visible16 = false; // 16.일괄공제 미표시
                    inputVisible.visible18 = true ; // 18.인적공제

                    // 24.가입상속공제, 25.영농상속공제, 26.동거주택상속공제, 27.금융상속공제
                    inputVisible.visible24 = false; // 24.가입상속공제 미표시
                    inputVisible.visible25 = false; // 25.영농상속공제 미표시
                    inputVisible.visible26 = false; // 26.동거주택상속공제 미표시
                    inputVisible.visible27 = false; // 27.금융상속공제 미표시 
                } else {}            

                break;
            }

            // 16. 일괄공제
            case "UPDATE_SUM_DEDU": {
                inputValue.sumDedu = action.payload;
                break;
            }

            // 17. 기초공제
            case "UPDATE_BASIC_DEDU": {
                inputValue.basicDedu = action.payload;
                break;
            }

            // 18. 인적공제
            case "UPDATE_PERSON_DEDU": {
                inputValue.personDedu = action.payload;

                if (inputValue.spouse === "Y") {   // 배우자 유 ▶ 22.배우자 상속재산가액, 23.배우자 승계공과금&채무
                    inputVisible.visible22 = true;
                } else if (inputValue.spouse === "N") {    // 배우자 무 ▶ 24.가입상속공제, 25.영농상속공제, 26.동거주택상속공제, 27.금융상속공제
                    inputVisible.visible24 = true;
                    inputVisible.visible25 = true;
                    inputVisible.visible26 = true;
                    inputVisible.visible27 = true;
                } else {}   
                break;
            }

            // 19. 배우자 장애인 여부
            case "UPDATE_SPOUSE_DISABLED": {
                inputValue.spouseDisabled = action.payload;

                // 배우자 유 > 자녀 무 > 배우자 : 기초 + 인적공제 Fixed
                if (inputValue.spouse === "Y" && Util.isNotEmpty(inputValue.childNumber) && Number(inputValue.childNumber) === 0) {
                    inputDisabled.disabled08 = true; // 08.자녀 수 Fixed
                }
                
                if (action.payload === "Y") {     // 배우자 장애인 여 ▶ 20.배우자 성별, 21.배우자 나이
                    inputVisible.visible20 = true;
                    inputVisible.visible21 = true;
                } else if (action.payload === "N") {  // 배우자 장애인 부 ▶ 22.배우자 상속재산가액, 22_1.직계존속 상속인 유무
                    inputVisible.visible22_0 = true;

                    inputVisible.visible20  = false; // 20.배우자 성별 미표시
                    inputValue.spouseGender = ""   ; // 20.배우자 성별 초기화
                    inputVisible.visible21  = false; // 21.배우자 나이 미표시
                    inputValue.spouseAge    = ""   ; // 21.배우자 나이 초기화
                } else {}

                break;
            }

            // 20. 배우자 성별
            case "UPDATE_SPOUSE_GENDER": {
                inputValue.spouseGender = action.payload;
                break;
            }

            // 21. 배우자 나이
            case "UPDATE_SPOUSE_AGE": {
                inputValue.spouseAge = action.payload;
                
                if (Util.isNotEmpty(action.payload)) {  // 나이 입력 ▶ 22_1.직계존속 상속인 유무
                    inputVisible.visible22_0 = true;
                }

                break;
            }

            // 22_0. 직계존속 상속인 유무
            case "UPDATE_DIRECT_HEIR": {
                inputValue.directHeir = action.payload;
                inputVisible.visible22 = true;  // 직계존속 상속인 선택 ▶ 22.배우자 상속재산가액
                break;
            }

            // 22. 배우자 상속재산가액
            case "UPDATE_SPOUSE_INHER_PROP": {
                inputValue.spouseInherProp = action.payload;

                if (Util.isNotEmpty(action.payload) && Number(Util.uncomma(action.payload)) >= 500000000) {    // 배우자 상속재산가액 5억 초과 ▶ 23.배우자 승계 공과금 & 채무, 24.가입상속공제, 25.영농상속공제, 26.동거주택상속공제, 27.금융상속공제
                    inputVisible.visible23 = true;
                    inputVisible.visible24 = true;
                    inputVisible.visible25 = true;
                    inputVisible.visible26 = true;
                    inputVisible.visible27 = true;                                    
                } else {                                                                   // 배우자 상속재산가액 5억 미만 ▶ 24.가입상속공제, 25.영농상속공제, 26.동거주택상속공제, 27.금융상속공제
                    inputVisible.visible23       = false; // 23.배우자 승계 공과금 & 채무 미표시
                    inputValue.spouseSucUtilDebt = ""   ; // 23.배우자 승계 공과금 & 채무 초기화
                    inputVisible.visible24       = true ;
                    inputVisible.visible25       = true ;
                    inputVisible.visible26       = true ;
                    inputVisible.visible27       = true ;                                    
                }            
                break;
            }

            // 23. 배우자 승계 공과금&채무
            case "UPDATE_SPOUSE_SUC_UTIL_DEBT": {
                inputValue.spouseSucUtilDebt = action.payload;
                
                if (Util.isNotEmpty(action.payload)) {    // 공과금&채무 입력 ▶ 24.가입상속공제, 25.영농상속공제, 26.동거주택상속공제, 27.금융상속공제
                    inputVisible.visible24 = true;
                    inputVisible.visible25 = true;
                    inputVisible.visible26 = true;
                    inputVisible.visible27 = true;
                }            
                break;
            }

            // 24. 가업상속공제
            case "UPDATE_FAMILY_DEDU": {
                inputValue.familyDedu = action.payload;
                break;
            }

            // 25. 영농상속공제
            case "UPDATE_FARM_DEDU": {
                inputValue.farmDedu = action.payload;
                break;
            }

            // 26. 동거주택상속공제
            case "UPDATE_HOUSE_DEDU": {
                inputValue.houseDedu = action.payload;
                break;
            }

            // 27. 금융상속공제
            case "UPDATE_FINAN_DEDU": {
                inputValue.finanDedu = action.payload;
                break;
            }


            // 기초정보입력 초기화
            case "INIT_BASIC_INFO": {
                return initialState;
            }

            // 증여정보입력 초기화
            case "INIT_GIFT_INFO": {
                inputValue.spouse            = "";  // 04. 배우자 유무
                inputValue.spousePreGift     = "";  // 05. 배우자 사전증여 유무
                inputValue.spousePreGiftProp = "";  // 06. 배우자 사전증여 재산가액
                inputValue.spousePreGiftTax  = "";  // 07. 배우자 사전증여세액
                inputValue.childNumber       = "";  // 08. 자녀 수
                inputValue.preGift           = "";  // 09. 사전증여 유무
                inputValue.preGiftProp       = "";  // 10. 사전증여 재산가액
                inputValue.preGiftTax        = "";  // 11. 사전증여세액
            
                inputVisible.visible04  = true ;  // 04. 배우자 유무
                inputVisible.visible05  = false;  // 05. 배우자 사전증여 유무
                inputVisible.visible06  = false;  // 06. 배우자 사전증여 재산가액
                inputVisible.visible07  = false;  // 07. 배우자 사전증여세액
                inputVisible.visible08  = false;  // 08. 자녀 수
                inputVisible.visible09  = false;  // 09. 사전증여 유무
                inputVisible.visible10  = false;  // 10. 사전증여 재산가액
                inputVisible.visible11  = false;  // 11. 사전증여세액

                inputDisabled.disabled04 = false;  // 04. 배우자 유무
                inputDisabled.disabled08 = false;  // 08. 자녀 수
        
                break;
            }

            // 공제입력정보 초기화
            case "INIT_DEDU_INFO": {
                
                // 배우자 유 & 자녀 무 → 기초 & 인적 Default 적용 : 배우자 인적공제 바로 계산
                if (inputValue.spouse === "Y" && Number(inputValue.childNumber) === 0) {
                    inputValue.deduCls        = "B"  ;  // 15. 공제구분 - 기초 + 인적공제(B)
                    inputDisabled.disabled15  = true ;  // 15. 공제구분 Fixed
                    inputVisible.visible16    = false;  // 16. 일괄공제
                    inputVisible.visible17    = true ;  // 17. 기초공제
                    inputVisible.visible18    = false;  // 18. 인적공제

                    inputVisible.visible19   = true ;  // 19. 배우자 장애인 여부
                    inputVisible.visible20   = false;  // 20. 배우자 성별
                    inputVisible.visible21   = false;  // 21. 배우자 나이
                    inputVisible.visible22   = false;  // 22. 배우자 상속재산가액
                    inputVisible.visible23   = false;  // 23. 배우자 승계 공과금&채무
                    inputVisible.visible22_0 = false;  // 22_0. 직계존속 상속인 유무                    

                    inputValue.spouseDisabled    = "";  // 19. 배우자 장애인 여부
                    inputValue.spouseGender      = "";  // 20. 배우자 성별
                    inputValue.spouseAge         = "";  // 21. 배우자 나이
                    inputValue.spouseInherProp   = "";  // 22. 배우자 상속재산가액
                    inputValue.spouseSucUtilDebt = "";  // 23. 배우자 승계 공과금&채무
                    inputValue.directHeir        = "";  // 22_0. 직계존속 상속인 유무

                // 배우자 무 & 자녀 무 → 일괄공제 Default 적용
                } else if (inputValue.spouse === "N" && Number(inputValue.childNumber) === 0) {
                    inputValue.deduCls       = "A"  ;  // 15. 공제구분 - 일괄공제(A)
                    inputDisabled.disabled15 = true ;  // 15. 공제구분 Fixed
                    inputVisible.visible16   = true ;  // 16. 일괄공제
                    inputVisible.visible17   = false;  // 17. 기초공제             
                    inputVisible.visible18   = false;  // 18. 인적공제
                    inputValue.personDedu    = ""   ;  // 18. 인적공제
                } else {
                    inputDisabled.disabled15 = false;  // 15. 공제구분 Fixed

                    inputVisible.visible16 = false;  // 16. 일괄공제
                    inputVisible.visible17 = false;  // 17. 기초공제             
                    inputVisible.visible18 = false;  // 18. 인적공제
                    inputVisible.visible22 = false;  // 22. 배우자 상속재산가액
                    inputVisible.visible23 = false;  // 23. 배우자 승계 공과금&채무

                    inputValue.deduCls           = "";  // 15. 공제구분 초기화
                    inputValue.spouseInherProp   = "";  // 22. 배우자 상속재산가액
                    inputValue.spouseSucUtilDebt = "";  // 23. 배우자 승계 공과금&채무
                }

                break;
            }

            // 부가공제정보 초기화
            case "INIT_EXTRA_INFO": {
                inputValue.familyDedu = "";  // 24. 기업상속공제
                inputValue.farmDedu   = "";  // 25. 영농상속공제
                inputValue.houseDedu  = "";  // 26. 동거주택상속공제
                inputValue.finanDedu  = "";  // 27. 금융상속공제

                break;
            }
        }
    }
);
