/***********************************************************************************
*** Law9 ** 조세특례제한법 97조의 2 신축임대주택에 대한 양도소득세 감면 Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law9Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}

export default function Law9({ setModalOpen, setLawModalTitle, callBack, callMode }: Law9Props) {
    React.useEffect(() => {
        setLawModalTitle("조세특례제한법 97조의 2 신축임대주택에 대한 양도소득세 감면");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className='w100p hAuto ofA mb30'>
                <table className={
                        'forest-list-table bdt1 bdb1 bdcDDDDDD ' +
                        'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                        'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075 td-p10'
                    }>
                   <colgroup>
                        <col className='w80'></col>
                        <col className='w80'></col>
                        <col className='w160'></col>
                        <col className='w200'></col>
                        <col className='w120'></col>
                        <col className='w70'></col>
                        <col className='w70'></col>
                        <col className='w100'></col>
                        <col className='w80'></col>
                        <col className='w80'></col>
                        <col className='w80'></col>
                        <col className='w100'></col>
                        <col className='w100'></col>
                    </colgroup>
                    <tbody>
                        <tr className='h40'>
                            <th rowSpan={2}>구분</th>
                            <th rowSpan={2}>주택구분</th>
                            <th rowSpan={2}>주택규모</th>
                            <th rowSpan={2}>취득시기</th>
                            <th rowSpan={2}>신축기간<br/>(사용승인일)</th>
                            <th colSpan={2}>등록</th>
                            <th rowSpan={2}>민특법 분류</th>
                            <th rowSpan={2}>임대개시</th>
                            <th rowSpan={2}>임대기간</th>
                            <th colSpan={2}>추가질문</th>
                            <th>혜택<br />(신축에 한함)</th>
                        </tr>
                        <tr className='h29'>
                            <th>지자체</th>
                            <th>세무서</th>
                            <th>임대호수</th>
                            <th>입주사실여부</th>
                            <th>5년 이상</th>
                        </tr>
                        <tr className="h50">
                            <th rowSpan={4}>개별사항</th>
                            <td>공동주택</td>
                            <td rowSpan={4}>국민주택규모 이하<br />(건물연면적의 2배<br/> 이내의 부수토지 포함)</td>
                            <td>-</td>
                            <td>1999.8.19. 이전</td>
                            <td>등록</td>
                            <td>-</td>
                            <td>건설임대주택</td>
                            <td rowSpan={4}>없음</td>
                            <td rowSpan={4}>5년<br/>이상</td>
                            <td rowSpan={4}>기존주택 포함 2호 이상</td>
                            <td>1999.8.20. 현재<br/>없어야 함</td>
                            <td>100%</td>
                        </tr>
                        <tr className="h50">
                            <td>모든주택</td>
                            <td>-</td>
                            <td>1999.8.20. ~<br/>2001.12.31</td>
                            <td>등록</td>
                            <td>-</td>
                            <td>건설임대주택</td>
                            <td>-</td>
                            <td>100%</td>
                        </tr>
                        <tr className="h50">
                            <td>공동주택</td>
                            <td>1999.8.20. 이후 취득(1999.8.20.~2001.12.31. 까지 매매계약체결+계약금 지급한 경우에 한함)</td>
                            <td>1999.8.19. 이전</td>
                            <td>등록</td>
                            <td>-</td>
                            <td>매입임대주택</td>
                            <td>1999.8.20. 현재 및 취득당시도 없어야 함</td>
                            <td>100%</td>
                        </tr>
                        <tr className="h50">
                            <td>모든주택</td>
                            <td>1999.8.20. 이후 취득(1999.8.20.~2001.12.31. 까지 매매계약체결+계약금 지급한 경우에 한함)</td>
                            <td>1999.8.20. 이후</td>
                            <td>등록</td>
                            <td>-</td>
                            <td>매입임대주택</td>
                            <td>취득 당시<br/>없어야 함</td>
                            <td>100%</td>
                        </tr>
                        <tr>
                            <th>공통사항</th>
                            <td className='taL pt15 pb15 pl15' colSpan={14}>
                                <div className="p5">
                                    1. 비과세판정시 주택수에서 제외<br/>
                                    2. 다주택 중과배제(유예)<br/>
                                    3. 공동소유 임대주택의 경우 호수에 지분비율을 곱하여 임대호수를 산정한다.<br/>
                                    4. 피상속인의 임대주택을 상속받아 임대하는 경우에는 피상속인의 임대기간을 상속인의 임대기간에 통산한다.<br/>
                                    5. 임대기간중 3개월이하 공실은 임대기간으로 봄<br/>
                                    6. 임대호수‘기존주택포함 2호 이상’의 의미는 상기 조건에 해당하는 임대주택 1호이상을 포함하여 총 임대호수가 2호 이상임을 의미함<br/>
                                    {"<"}예: 임대호수 2호 = 상기조건 임대주택 1호 + 상기조건 임대주택외 임대주택(‘기존주택’이라 함) 1호{">"}<br/>
                                    7. 혜택‘신축에 한함’의 의미는 양도시 감면혜택은 2호 이상 임대주택중 상기 조건에 해당하는 주택만 감면 대상임을 의미함
                                </div>
                            </td>
                        </tr>
                        <tr className='h110'>
                            <th>기타사항</th>
                            <td className='taL pt15 pb15 pl15' colSpan={14}>
                                <div className="p5">
                                    1. 의무임대기간 충족 전 일반주택 양도시 중과배제, 당해 의무해태 발생 연도에 납부한다.<br/>
                                    2. 의무임대기간 총족 전 일반주택 양도시 비과세 규정을 적용, 의무임대기간 내에 감면주택 양도시 당초 비과세 유지한다.<br/>
                                    3. 장기임대주택이 재건축 중인 입주권으로 전환된 경우에 입주권상태로 양도하는 경우에는 감면된다.<br/>
                                    4. 장기임대주택을 소유주택으로 변경한 주택에 대한 1세대 1주택의 비과세 판정시 보유기간은 소유주택으로 변경한 날부터 기산한다.<br/>
                                    5. 타인으로부터 분양권을 매입하여 취득한 경우에도 적용된다.(부동산거래관리과-114 , 2012.02.16.   기획재정부 재산세제과-330  2011.05.06.)
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}