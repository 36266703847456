export default function MgshrPlanDe({setModalOpen}: any) {
  return (
    <div className='w800 h100p fc666666 ls075 fs15 lh20'>
        <p className="mb20">
            <span className="SpoqaHanSansNeo-Medium">(1) 관리처분계획인가일<br/></span>
            <span className="SpoqaHanSansNeo-Regular">도시 및 주거환경정비법에 따른 조합원입주권의 권리가 확정된 날로써 지방자치단체의 공보에 고시된 날은 말한다.<br/>
            최초 관리처분계획인가가 무효 또는 취소되지 않은 상태에서 사업 내용의 변경으로 관리처분계획이 변경 · 인가된 경우 최초 관리처분계획인가일을
            조합원입주권 취득일로 본다.</span>
        </p>
        <p className="mb20">
            <span className="SpoqaHanSansNeo-Medium">(2) 조합원입주권으로 변경되는 권리변환일 시기<br /></span>
            <span className="SpoqaHanSansNeo-Regular">개발사업의 근거법의 변화로 권리변환일이 변동이 있었으며 아래에 해당되는 인가일을 관리처분계획인가일에 기재하면 됩니다.</span>
        </p>
          <table className="forest-list-table w100p th-fm-SpoqaHanSansNeo-Medium th-fw500 th-fs14 th-fc222222 th-bdcDDDDDD bdt1 bdb1 bdcDDDDDD td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-p15 mt20 mb20">
            <colgroup>
                <col className="w200"></col>
                <col className="w300"></col>
                <col className="w300"></col>
            </colgroup>
            <tbody>
                <tr className="h69">
                    <th>기간별</th>
                    <th>재개발 사업 (근거법)</th>
                    <th>재건축 사업 (근거법)</th>
                </tr>
                <tr className="h50">
                    <td>2003. 6. 30. 이전</td>
                    <td>관리처분계획인가일 (도시재개발법)</td>
                    <td>사업계획승인 인가일 (주택건설촉진법, 주택법)</td>
                </tr>
                <tr className="h50">
                    <td>2003. 7. 1. ~ 2005. 5. 30.</td>
                    <td>관리처분계획인가일 (도시정비법)</td>
                    <td>사업시행 인가일 (도시정비법)</td>
                </tr>
                <tr className="h50">
                    <td>2005. 5. 31. 이후</td>
                    <td>관리처분계획인가일 (도시정비법)</td>
                    <td>관리처분계획인가일 (도시정비법)</td>
                </tr>
            </tbody>
        </table>
        <div className="dpFlx aiC jcC pt20">
            <div className="w210 h56 bgc17A840 dpFlx aiC jcC csP bdBox" onClick={() => { setModalOpen(false) }}>
                <span className="SpoqaHanSansNeo-Medium fs14 fcWhite">닫기</span>
            </div>
        </div>
    </div>
  )
}