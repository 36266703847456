import { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilValue, useRecoilState } from "recoil";

import { taxFlagState, directAddrInfoState } from "js/common/Atom";
import { MainSectionContainer } from "js/mobile/common/layout";
import SelectBox, { SelectOption } from "js/mobile/common/SelectBox";
import AppContext from "js/common/AppContext";
import { Code, DirectAddrInfo } from "js/common/types/types";
import { codeProps } from "js/common/ui/input/SelectBox";
import Axios from "js/common/Axios";
import TextInput from "./TextInput";
import { NumericFormat } from "react-number-format";
//import Util from 'js/common/Util';

function DirectAssetAddressInfo() {
  const taxFlag = useRecoilValue(taxFlagState);
  const [directAddrInfo, setDirectAddrInfo] =
    useRecoilState(directAddrInfoState);
  const [shareRatioHelpText, setShareRatioHelpText] = useState<string>("");

  useEffect(() => {
    // 지분율 default value 100으로 set
    setDirectAddrInfo((prev: DirectAddrInfo) => ({
      ...prev,
      shareRatio: "100",
    }));
  }, [setDirectAddrInfo]);

  // 완료버튼(자산 정보 셋팅)
  const handleSelectSiDo = (selected: SelectOption) => {
    setDirectAddrInfo((prev: DirectAddrInfo) => ({
      ...prev,
      sidoCode: selected.value,
      sidoName: selected.label,
    }));

    AppContext.showSpinner();
    Axios.dataAccess({
      url: "common/getSggCodeInfoList.do",
      methodType: "post",
      paramType: "object",
      paramData: {
        sidoCode: selected.value,
      },
      onSuccessFn: (res: any) => {
        //setSggCodeInfoList(res.list);
        //setEmdCodeInfoList([]);
        setDirectAddrInfo((prev: DirectAddrInfo) => ({
          ...prev,
          sggCodeInfoList: res.list,
          emdCodeInfoList: [],
        }));
        if (res.list.length === 1) {
          //setSggCode(res.list[0].code);
          //setSggCodeElVsb(false);
          setDirectAddrInfo((prev: DirectAddrInfo) => ({
            ...prev,
            sggCode: res.list[0].code,
            sggCodeElVsb: false,
          }));

          Axios.dataAccess({
            url: "common/getEmdCodeInfoList.do",
            methodType: "post",
            paramType: "object",
            paramData: {
              sggCode: res.list[0].code,
            },
            onSuccessFn: (res: any) => {
              //setEmdCodeInfoList(res.list);
              //setEmdCode('');
              setDirectAddrInfo((prev: DirectAddrInfo) => ({
                ...prev,
                emdCodeInfoList: res.list,
                emdCode: "",
              }));
              AppContext.hideSpinner();
            },
          });
        } else {
          //setSggCodeElVsb(true);
          //setSggCode('');
          //setEmdCode('');
          setDirectAddrInfo((prev: DirectAddrInfo) => ({
            ...prev,
            sggCodeElVsb: true,
            sggCode: "",
            emdCode: "",
          }));
          AppContext.hideSpinner();
        }
      },
    });
  };

  const handleSelectSgg = (selected: SelectOption) => {
    //setSggCode(selected.value);
    //setSggName(selected.label);
    setDirectAddrInfo((prev: DirectAddrInfo) => ({
      ...prev,
      sggCode: selected.value,
      sggName: selected.label,
    }));

    AppContext.showSpinner();
    Axios.dataAccess({
      url: "common/getEmdCodeInfoList.do",
      methodType: "post",
      paramType: "object",
      paramData: {
        sggCode: selected.value,
      },
      onSuccessFn: (res: any) => {
        //setEmdCodeInfoList(res.list);
        //setEmdCode('');
        setDirectAddrInfo((prev: DirectAddrInfo) => ({
          ...prev,
          emdCodeInfoList: res.list,
          emdCode: "",
        }));
        AppContext.hideSpinner();
      },
    });
  };

  const handleSelectEmd = (selected: SelectOption) => {
    //setEmdCode(selected.value);
    //setEmdName(selected.label);

    setDirectAddrInfo((prev: DirectAddrInfo) => ({
      ...prev,
      emdCode: selected.value,
      emdName: selected.label,
    }));

    AppContext.showSpinner();
    Axios.dataAccess({
      url: "common/mobile/chkAjstRgnInfo.do",
      methodType: "post",
      paramType: "object",
      paramData: {
        pnu: selected.value,
      },
      onSuccessFn: (res: any) => {
        if (res.item === 0) {
          //setMdatTrgetAreaAt('N');
          setDirectAddrInfo((prev: DirectAddrInfo) => ({
            ...prev,
            mdatTrgetAreaAt: "N",
          }));
        } else {
          /*AppContext.openAlert({
                        title: '조정대상지역 자동선택',
                        msg: '해당 주소가 조정대상지역으로 판별되었습니다.',
                        icon: 'check',
                    });*/
          //setMdatTrgetAreaAt('Y');
          setDirectAddrInfo((prev: DirectAddrInfo) => ({
            ...prev,
            mdatTrgetAreaAt: "Y",
          }));
        }
        AppContext.hideSpinner();
      },
    });
  };

  const handleChangeRdnmAdresDetail = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    //setRdnmAdresDetail(inputValue);
    setDirectAddrInfo((prev: DirectAddrInfo) => ({
      ...prev,
      rdnmAdresDetail: inputValue,
    }));
  };

  const handleChangePblntfPc = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    //setPblntfPc(inputValue);
    setDirectAddrInfo((prev: DirectAddrInfo) => ({
      ...prev,
      pblntfPc: inputValue,
    }));
  };

  const handleSelectMdatTrgetAreaAt = (selected: SelectOption) => {
    //setMdatTrgetAreaAt(selected.value);
    setDirectAddrInfo((prev: DirectAddrInfo) => ({
      ...prev,
      mdatTrgetAreaAt: selected.value,
    }));
  };

  const handleChangePrvuseAr = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    //setPrvuseAr(inputValue);
    setDirectAddrInfo((prev: DirectAddrInfo) => ({
      ...prev,
      prvuseAr: inputValue,
    }));
  };

  const handleChangeShareRatio = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    //setShareRatio(inputValue);
    const numberValue = parseInt(inputValue);

    if (numberValue > 100 || numberValue <= 0) {
      setShareRatioHelpText("1~100 사이의 값을 입력하세요.");
    } else {
      setShareRatioHelpText("");
    }
    setDirectAddrInfo((prev: DirectAddrInfo) => ({
      ...prev,
      shareRatio: inputValue,
    }));
  };

  return (
    <MainSectionContainer>
      <p className="desc">
        <span>취득할 자산의</span>
        <span className="bold"> 주소정보</span>
        <span>를 입력해주세요.</span>
      </p>
      <InputContainer>
        <p className="label">시/도</p>
        <div className="input">
          <SelectBox
            placeholder="시/도를 선택해주세요."
            options={AppContext.sidoCodeInfoList.map((code: Code) => ({
              value: code.code,
              label: code.codeNm,
            }))}
            disabled={false}
            selectedValue={directAddrInfo.sidoCode}
            handleSelect={handleSelectSiDo}
          />
        </div>
      </InputContainer>
      {directAddrInfo.sggCodeElVsb && (
        <InputContainer>
          <p className="label">시군구</p>
          <div className="input">
            <SelectBox
              placeholder="시군구를 선택해주세요."
              options={directAddrInfo.sggCodeInfoList.map(
                (code: codeProps) => ({
                  value: code.code,
                  label: code.codeNm,
                })
              )}
              disabled={false}
              selectedValue={directAddrInfo.sggCode}
              handleSelect={handleSelectSgg}
            />
          </div>
        </InputContainer>
      )}
      <InputContainer>
        <p className="label">읍면동</p>
        <div className="input">
          <SelectBox
            placeholder="읍면동을 선택해주세요."
            options={directAddrInfo.emdCodeInfoList.map((code: codeProps) => ({
              value: code.code,
              label: code.codeNm,
            }))}
            disabled={false}
            selectedValue={directAddrInfo.emdCode}
            handleSelect={handleSelectEmd}
          />
        </div>
      </InputContainer>
      <InputContainer>
        <p className="label">상세주소</p>
        <div className="input">
          <TextInput
            name="rdnmAdresDetail"
            value={directAddrInfo.rdnmAdresDetail}
            onChange={handleChangeRdnmAdresDetail}
            placeholder="상세주소를 입력할 수 있습니다."
          />
        </div>
      </InputContainer>
      {(taxFlag === "P" || taxFlag === "A") && (
        <InputContainer>
          <p className="label">공시가격(원)</p>
          <div className="input">
            <NumberInputContainer
              $filled={!directAddrInfo.pblntfPc ? false : true}
            >
              <div className="inputWrap">
                <NumericFormat
                  value={directAddrInfo.pblntfPc}
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  maxLength={15}
                  onChange={(e) => {
                    if (handleChangePblntfPc === undefined) {
                      return;
                    }
                    handleChangePblntfPc(e);
                  }}
                  placeholder="공시가격을 입력해주세요."
                  className="numberInput"
                  name="pblntfPc"
                />
                <p className="unit">원</p>
              </div>
            </NumberInputContainer>
          </div>
        </InputContainer>
      )}
      {taxFlag !== "P" && taxFlag !== "T" && (
        <InputContainer>
          <p className="label">면적(㎡)</p>
          <div className="input">
            <NumberInputContainer
              $filled={!directAddrInfo.prvuseAr ? false : true}
            >
              <div className="inputWrap">
                <NumericFormat
                  value={directAddrInfo.prvuseAr}
                  maxLength={7}
                  decimalScale={2}
                  onChange={(e) => {
                    if (handleChangePrvuseAr === undefined) {
                      return;
                    }
                    handleChangePrvuseAr(e);
                  }}
                  placeholder="면적을 입력해주세요."
                  className="numberInput"
                  name="prvuseAr"
                />
                <p className="unit">㎡</p>
              </div>
            </NumberInputContainer>
            <div className="help">
              공동주택: 전용면적 / 단독주택: 주택연면적
            </div>
          </div>
        </InputContainer>
      )}
      <InputContainer>
        <p className="label">조정대상지역</p>
        <div className="input">
          <SelectBox
            placeholder="조정대상지역을 선택해주세요."
            options={[
              { value: "Y", label: "예" },
              { value: "N", label: "아니오" },
            ]}
            disabled={true}
            selectedValue={directAddrInfo.mdatTrgetAreaAt}
            handleSelect={handleSelectMdatTrgetAreaAt}
          />
        </div>
      </InputContainer>
      <InputContainer>
        <p className="label">지분율(%)</p>
        <div className="input">
          <NumberInputContainer
            $filled={!directAddrInfo.shareRatio ? false : true}
          >
            <div className="inputWrap">
              <NumericFormat
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                maxLength={3}
                name="shareRatio"
                value={directAddrInfo.shareRatio}
                onChange={(e) => {
                  if (handleChangeShareRatio === undefined) {
                    return;
                  }
                  handleChangeShareRatio(e);
                }}
                placeholder="지분율을 입력해주세요."
                className="numberInput"
              />
              <p className="unit">%</p>
            </div>
          </NumberInputContainer>
          <div className="help warning">{shareRatioHelpText}</div>
        </div>
      </InputContainer>
    </MainSectionContainer>
  );
}

const InputContainer = styled.div`
  width: 100%;
  margin-top: 12px;

  .label {
    margin-bottom: 5px;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.29;
    letter-spacing: -0.058em;
    color: ${(props) => props.theme.color.nGray30};
  }

  .help {
    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.29;
    letter-spacing: -0.058em;
    color: ${(props) => props.theme.color.sNavyLight01};
    text-align: right;

    margin-top: 5px;

    &.warning {
      color: ${(props) => props.theme.color.sysRed100};
    }
  }
`;

const NumberInputContainer = styled.div<{ $filled: boolean }>`
  width: 100%;
  height: auto;

  ${(props) => {
    if (props.$filled) {
      return `
          padding: 5px;
          border-radius: 10px;
          background-color: ${props.theme.color.pGreenLight03};
          border: none;
        `;
    }
  }}

  .inputWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 12px 16px;
    background-color: white;
    border: 1px solid
      ${(props) =>
        props.$filled
          ? props.theme.color.pGreenLight02
          : props.theme.color.nGray70};
    border-radius: 8px;

    .unit {
      font-family: "Spoqa Han Sans Neo";
      font-weight: 500;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.sNavy};
    }

    .numberInput {
      width: 100%;
      height: 100%;
      border: none;

      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.sNavy};

      &:focus {
        outline: none;
      }
    }
    .numberInput::placeholder {
      color: ${(props) => props.theme.color.nGray50};
    }

    .numberInput::-webkit-input-placeholder {
      color: ${(props) => props.theme.color.nGray50};
    }

    .numberInput::-ms-input-placeholder {
      color: ${(props) => props.theme.color.nGray50};
    }
  }
`;

export default DirectAssetAddressInfo;
