import { useState } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Housing } from "js/common/types/types";
import Util from "js/common/Util";
import { spfcKndOptions } from "js/common/const";

type Props = {
  housings: Housing[];
  onDelete?: (housing: Housing) => void;
};

function PropInfoSwiper({ housings, onDelete }: Props) {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [swiperInstance, setSwiperInstance] = useState<SwiperType | null>(null);

  const onClickLabel = (index: number) => {
    if (swiperInstance) {
      swiperInstance.slideTo(index); // 해당 인덱스로 슬라이드 이동
    }
  };

  return (
    <SwiperWrap>
      <SlideLabelContainer>
        {housings.map((housing: Housing, index: number) => (
          <span
            className={`slideLabel ${index === activeIndex ? "active" : ""}`}
            onClick={() => onClickLabel(index)}
            key={housing.pnu}
          >
            {index === activeIndex ? `주택정보 ${index + 1}` : index + 1}
          </span>
        ))}
      </SlideLabelContainer>
      {/* Swiper */}
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        modules={[Pagination]}
        pagination={{
          clickable: true,
          el: ".customPagination",
          bulletClass: "customBullet",
          bulletActiveClass: "customBulletActive",
        }}
        onSlideChange={
          (swiper: SwiperType) => setActiveIndex(swiper.activeIndex)
          //console.log(swiper.activeIndex)
        }
        onSwiper={setSwiperInstance} // Swiper 인스턴스를 저장
      >
        {housings.map((housing, index) => {
          const tmpAddrSplit = housing.rdnmAdres.split(" ");
          const title = tmpAddrSplit[0] + " " + tmpAddrSplit[1];

          const spfcKndValue = spfcKndOptions.find(
            (option) => option.value === housing.spfcKnd
          );

          return (
            <SwiperSlide key={housing.pnu}>
              <PropertySlide>
                <div className="slideHeader">
                  <div className="titleWrap">
                    <div className="propDesc">
                      <img
                        src={require("img/common/prop_slide_icon.png")}
                        alt="prop icon"
                      />
                      <span>재산세·종합부동산세</span>
                    </div>
                    <p className="propTitle">{`주택정보${
                      index + 1
                    } - ${title}`}</p>
                  </div>
                  {onDelete && (
                    <button
                      className="deleteButton"
                      onClick={() => onDelete(housing)}
                    >
                      삭제
                    </button>
                  )}
                </div>
                <div className="slideContent">
                  <div className="propContent">
                    <span className="contentLabel">주소</span>
                    <span className="contentText">{housing.rdnmAdres}</span>
                  </div>
                  <div className="propContent">
                    <span className="contentLabel">용도지역</span>
                    <span className="contentText">
                      {spfcKndValue ? spfcKndValue.label : ""}
                    </span>
                  </div>
                  <div className="propContent">
                    <span className="contentLabel">기준시가</span>
                    <span className="contentText">
                      {Util.comma(housing.stdPrice)}원
                    </span>
                  </div>
                  <div className="propContent">
                    <span className="contentLabel">조정대상지역</span>
                    <span className="contentText">
                      {housing.mdatTrgetAreaAt === "Y" ? "여" : "부"}
                    </span>
                  </div>
                  <div className="propContent">
                    <span className="contentLabel">지분율</span>
                    <span className="contentText">{housing.shareRatio}%</span>
                  </div>
                </div>
              </PropertySlide>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* Custom Pagination */}
      <div className="customPagination" />
    </SwiperWrap>
  );
}

const SwiperWrap = styled.section`
  width: 100%;
  height: auto;

  .customPagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;

    width: 100%;
    padding-top: 12px;
    padding-bottom: 16px;
    background-color: ${(props) => props.theme.color.pGreenLight04};
    border-radius: 0 0 13px 13px;
  }

  .customBullet {
    width: 5px;
    height: 5px;
    background-color: #d6d6d6;
    border-radius: 50%;
  }

  .customBulletActive {
    width: 16px;
    height: 5px;
    background-color: ${(props) => props.theme.color.pGreen};
    border-radius: 5px;
  }
`;

const SlideLabelContainer = styled.div`
  --container-height: 34px;

  width: 100%;
  height: var(--container-height);
  margin-bottom: 10px;

  .slideLabel {
    padding: 4px 12px;
    border-radius: 17px;
    border: 1px solid #eaeaea;
    margin-right: 8px;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 500;
    font-size: 1.4em;
    line-height: var(--container-height);
    letter-spacing: -0.028em;
    vertical-align: middle;

    background-color: white;
    color: ${(props) => props.theme.color.pGreen};

    &.active {
      background-color: ${(props) => props.theme.color.pGreen};
      color: white;
      border: 1px solid ${(props) => props.theme.color.pGreen};
      padding: 4px 12px;
    }
  }
`;

const PropertySlide = styled.div`
  width: 100%;
  height: auto;

  .slideHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 16px 24px 9px 24px;
    background-color: white;
    border-radius: 13px 13px 0 0;

    .titleWrap {
      .propDesc {
        display: flex;
        align-items: center;
        margin-bottom: 2px;

        img {
          width: 16px;
          height: 16px;
        }

        span {
          margin-left: 5px;

          font-family: "Spoqa Han Sans Neo";
          font-weight: 400;
          font-size: 1.2em;
          line-height: 1.4;
          letter-spacing: -0.033em;
          vertical-align: middle;
        }
      }

      .propTitle {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 500;
        font-size: 1.4em;
        line-height: 1.5;
        letter-spacing: -0.028em;
        vertical-align: middle;
      }
    }

    .deleteButton {
      padding: 2px 9px;
      border: 1px solid ${({ theme }) => theme.color.nGray70};
      border-radius: 5px;

      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.2em;
      line-height: 1.4;
      letter-spacing: -0.033em;
      color: black;
    }
  }
  .slideContent {
    padding: 16px 24px 14px 24px;
    background-color: ${(props) => props.theme.color.pGreenLight04};

    .propContent {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;
      margin-bottom: 6px;

      .contentLabel {
        flex: 0 0 80px;

        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.4em;
        line-height: 1.8;
        letter-spacing: -0.028em;
        color: #727b8e;
      }

      .contentText {
        flex-grow: 1;
        flex-shrink: 1;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: right;

        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.4em;
        line-height: 1.8;
        letter-spacing: -0.028em;
        color: #202020;
      }
    }
  }
`;

export default PropInfoSwiper;
