/***********************************************************************************
*** Law35at10 ** 소득세법 156조의 2 제6항 상속조합원입주권 Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law35at10Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}

export default function Law35at10({ setModalOpen, setLawModalTitle, callBack, callMode }: Law35at10Props) {
    React.useEffect(() => {
        setLawModalTitle("소득세법 156조의 2 제6항 상속조합원입주권");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className="m0 mt15 mb30">
                <span className="fs15 lh25 ls06 SpoqaHanSansNeo-Regular">
                    1세대가 1조합원입주권을 상속받은 후 주택을 양도하는 경우에는 상속받은 조합원입주권 없이 1개의 주택을 소유하고 있는 것으로 보아<br/>
                    1세대 1주택 비과세 규정을 적용한다.<br/><br/>
                    이 때 피상속인이 상속개시 당시 주택 또는 분양권을 소유하지 않은 경우의 상속받은 조합원입주권만 해당하며, 피상속인이 상속개시 당시<br/>
                    2 이상의 조합원입주권을 소유한 경우에는 법정순위에 따른 1조합원입주권을 말한다.<br/><br/>
                </span>
                <span className="fs15 lh25 ls06 SpoqaHanSansNeo-Regular">상속조합원입주권에는<br/></span>
                <span className="lh20 fs14">
                    상속인과 피상속인이 상속개시 당시 1세대인 경우에는 1주택을 보유하고 1세대를 구성하는 자가 직계존속(배우자의 직계존속을 포함하며,<br/>
                    세대를 합친 날 현재 직계존속 중 어느 한 사람 또는 모두가 60세 이상으로서 1주택을 보유하고 있는 경우만 해당한다)을 동거봉양하기 위해<br/>
                    세대를 합침에 따라 2주택을 보유하게 되는 경우로서 합치기 이전부터 보유하고 있었던 주택이 조합원입주권으로 전환된 경우에만 상속받은<br/>
                    조합원입주권으로 본다.<br/><br/>
                </span>
                <span className="fs15 lh25 ls06 SpoqaHanSansNeo-Regular">본 프로그램에서 다루지 못하고 있어 주의를 요하는 상속조합원입주권 관련 일반주택의 내용<br/></span>
                <span className="lh20 fs14">
                    2018.2.13. 이후 증여받는 분부터 상속개시일부터 소급하여 2년 이내에 피상속인으로부터 증여받은 주택 또는 증여받은 조합원입주권을<br/>
                    상속받아 사업시행 완료 후 취득한 신축주택은 비과세되는 일반주택에서 제외한다. 또한 2021.1.1. 이후 양도하는 분부터 상속개시일부터<br/>
                    소급하여 2년 이내에 피상속인으로부터 증여받은 분양권(피상속인이 2021.1.1. 이후 취득한 분양권에 한함)에  의하여 사업시행 완료 후<br/>
                    취득한 신축주택도 비과세되는 일반주택에서 제외한다.
                </span>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}
