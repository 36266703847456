/*******************************************************************************
 *** SimAcqsTaxCalc ** 간편계산기 취득세 결과표
*******************************************************************************/
import React              from "react";
import Axios              from "js/common/Axios";
import Util               from "js/common/Util";
import AppContext         from "js/common/AppContext";
import Modal              from "js/common/ui/modal/Modal";
import AcquisitionTaxRate from "../acqstax/help/AcquisitionTaxRate";

interface SimAcqsTaxCalcProps {
    setSimAcqsTaxCalcModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setSimAcqsTaxCalcModalVsb: React.Dispatch<React.SetStateAction<boolean>>
    acqsTaxParam: any[]
}

const SimAcqsTaxCalc = ({ setSimAcqsTaxCalcModalOpen, setSimAcqsTaxCalcModalVsb, acqsTaxParam}: SimAcqsTaxCalcProps) => {
    const [calcInfo, setCalcInfo] = React.useState<any | undefined>();  // 취득세 계산결과

    const [acquisitionTaxRateModalOpen, setAcquisitionTaxRateModalOpen] = React.useState<boolean>(false);   // 취득세 세율 도움말 모달
    const [acqsCause, setAcqsCause] = React.useState<any>();

    // 취득세 파라미터 확인
    React.useEffect(() => {
        let taxFlag      = "";         // Flag
        let estateSe     = "";         // 01. 부동산의 종류(상세구분)
        let estateFom    = "";         // 02. 주택구분
        let acqsSrc1     = "";         // 03. 취득구분 1(아파트/연립)
        let acqsSrc2     = "";         // 04. 취득구분 2(오피스텔)
        let loseYn       = "";         // 05. 멸실여부
        let landType     = "";         // 06. 토지종류
        let farmPeriod   = "";         // 07. 자경기간
        let acqsCause1   = "";         // 08. 취득원인 1(기존 아파트)
        let acqsCause2   = "";         // 09. 취득원인 2(분양권/오피스텔)
        let acqsCause3   = "";         // 10. 취득원인 3(조합원입주권)
        let acqsCause4   = "";         // 11. 취득원인 4(다세대/다가구/단독주택)
        let mdatTrgetAre = "";         // 12. 조정대상지역
        let hseNumAt1    = "";         // 13. 주택수 1(조정대상지역)
        let hseNumAt2    = "";         // 14. 주택수 2(비조정대상지역)
        let inheirHseNo  = "";         // 15. 상속인의 주택유무
        let stdMkPrice   = "";         // 16. 시가표준액 기준
        let donor        = "";         // 17. 증여자
        let donorHseNo   = "";         // 18. 증여자의 주택수
        let taxBase      = "";         // 19. 과세표준
        let shareRatio   = "";         // 20. 지분율
        let prvuseAr     = "";         // 21. 면적

        // 배열에서 파라미터 얻기
        for (const taxParam of acqsTaxParam) {
            taxFlag      = taxParam.taxFlag     ;         // 세금 구분 Flag
            estateSe     = taxParam.estateSe    ;         // 01. 부동산의 종류(상세구분)
            estateFom    = taxParam.estateFom   ;         // 02. 주택구분
            acqsSrc1     = taxParam.acqsSrc1    ;         // 03. 취득구분 1(아파트/연립)
            acqsSrc2     = taxParam.acqsSrc2    ;         // 04. 취득구분 2(오피스텔)
            loseYn       = taxParam.loseYn      ;         // 05. 멸실여부
            landType     = taxParam.landType    ;         // 06. 토지종류
            farmPeriod   = taxParam.farmPeriod  ;         // 07. 자경기간
            acqsCause1   = taxParam.acqsCause1  ;         // 08. 취득원인 1(기존 아파트)
            acqsCause2   = taxParam.acqsCause2  ;         // 09. 취득원인 2(분양권/오피스텔)
            acqsCause3   = taxParam.acqsCause3  ;         // 10. 취득원인 3(조합원입주권)
            acqsCause4   = taxParam.acqsCause4  ;         // 11. 취득원인 4(다세대/다가구/단독주택)
            mdatTrgetAre = taxParam.mdatTrgetAre;         // 12. 조정대상지역
            hseNumAt1    = taxParam.hseNumAt1   ;         // 13. 주택수 1(조정대상지역)
            hseNumAt2    = taxParam.hseNumAt2   ;         // 14. 주택수 2(비조정대상지역)
            inheirHseNo  = taxParam.inheirHseNo ;         // 15. 상속인의 주택유무
            stdMkPrice   = taxParam.stdMkPrice  ;         // 16. 시가표준액 기준
            donor        = taxParam.donor       ;         // 17. 증여자
            donorHseNo   = taxParam.donorHseNo  ;         // 18. 증여자의 주택수
            taxBase      = taxParam.taxBase     ;         // 19. 과세표준
            shareRatio   = taxParam.shareRatio  ;         // 20. 지분율
            prvuseAr     = taxParam.prvuseAr    ;         // 21. 면적
        }

        Axios.dataAccess({
            url: "api/system/broker/callApi.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                apiName : "taxcalc"
              , tailUrl : "simpletax/acquisitiontax"
              , paramStr: taxFlag + "?estate_se="      + estateSe       // 01. 부동산의 종류(상세구분)
                                  + "&estate_fom="     + estateFom      // 02. 주택구분
                                  + "&acqs_src_1="     + acqsSrc1       // 03. 취득구분 1(아파트/연립)
                                  + "&acqs_src_2="     + acqsSrc2       // 04. 취득구분 2(오피스텔)
                                  + "&lose_yn="        + loseYn         // 05. 멸실여부
                                  + "&land_type="      + landType       // 06. 토지종류
                                  + "&farm_period="    + farmPeriod     // 07. 자경기간
                                  + "&acqs_cause_1="   + acqsCause1     // 08. 취득원인 1(기존 아파트)
                                  + "&acqs_cause_2="   + acqsCause2     // 09. 취득원인 2(분양권/오피스텔)
                                  + "&acqs_cause_3="   + acqsCause3     // 10. 취득원인 3(조합원입주권)
                                  + "&acqs_cause_4="   + acqsCause4     // 11. 취득원인 4(다세대/다가구/단독주택)
                                  + "&mdat_trget_are=" + mdatTrgetAre   // 12. 조정대상지역
                                  + "&hse_num_at_1="   + hseNumAt1      // 13. 주택수 1(조정대상지역)
                                  + "&hse_num_at_2="   + hseNumAt2      // 14. 주택수 2(비조정대상지역)
                                  + "&inheir_hse_no="  + inheirHseNo    // 15. 상속인의 주택유무
                                  + "&std_mk_price="   + stdMkPrice     // 16. 시가표준액 기준
                                  + "&donor="          + donor          // 17. 증여자
                                  + "&donor_hse_no="   + donorHseNo     // 18. 증여자의 주택수
                                  + "&tax_base="       + taxBase        // 19. 과세표준
                                  + "&share_ratio="    + shareRatio     // 20. 지분율
                                  + "&prvuse_ar="      + prvuseAr       // 21. 면적
            },
            onSuccessFn: (res: any) => {
                if (Util.isEmpty(res.item.rtn_list)) {
                    setSimAcqsTaxCalcModalOpen(false);
                    AppContext.openAlert({
                        title: "과세 대상 여부",
                        msg: res.item.rtn_msg,   // 과세대상에 해당되지 않습니다.
                        icon: "check"
                    });
                    return;
                }
                // 취득세 세액 계산 결과
                setCalcInfo(res.item.rtn_list[0]);
                setSimAcqsTaxCalcModalVsb(true);
            },
            onFailedFn: () => {
                setSimAcqsTaxCalcModalOpen(false);
            }
        });
    }, [acqsTaxParam, setSimAcqsTaxCalcModalOpen, setSimAcqsTaxCalcModalVsb]);

    return (
        <React.Fragment>
            <div className='w1000 ofA'>
                <table className={
                    "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD h44 mb40 mt10 " +
                    "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 th-ls1 " +
                    "td-p20 td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls075 td-fc666666 td-bdcDDDDDD"
                }>
                    <colgroup>
                        <col className="w100" />
                        <col className="w140" />
                        <col className="w120" />
                        <col className="w100" />
                        <col className="w100" />
                        <col className="w140" />
                        <col className="w120" />
                        <col className="w140" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th colSpan={2} className="bdr1">세목</th>
                            <th>과세표준(원)</th>
                            <th>지분율(%)</th>
                            <th>
                                <div className="dpFlx aiC jcC"
                                    onClick={()=> {
                                             if (calcInfo.acqs_cause === "201")  setAcqsCause("1011"); // 분양(신규, 미분양)
                                        else if (calcInfo.acqs_cause === "202")  setAcqsCause("1001"); // 원조합원
                                        else if (calcInfo.acqs_cause === "203")  setAcqsCause("1002"); // 매매(전매)
                                        else if (calcInfo.acqs_cause === "204")  setAcqsCause("1003"); // 상속
                                        else if (calcInfo.acqs_cause === "205")  setAcqsCause("1004"); // 증여
                                        else if (calcInfo.acqs_cause === "206")  setAcqsCause(""    ); // 신축
                                        setAcquisitionTaxRateModalOpen(true);
                                    }}>
                                    세율(%)
                                    <img src={require('img/common/icon_info_fill_20.png')} alt="icon_info_fill_20" className='ml5 csP'
                                    />
                                </div>
                            </th>
                            <th>산출세액(원)</th>
                            <th>감면세액(원)</th>
                            <th>신고세액합계(원)</th>
                        </tr>
                        <tr>
                            <th rowSpan={3} className="bdr1">신고세액</th>
                            <th className="bdr1">취득세</th>
                            <td rowSpan={3} className="taR">{calcInfo ? Util.comma(calcInfo.tax_base) : ""}</td>
                            <td rowSpan={3} className="taC">{calcInfo ? Number(calcInfo.share_ratio) : ""}</td>
                            <td className="taC">{calcInfo ? Number(calcInfo.acqs_tax_rate) : ""}</td>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.acqs_tax) : ""}</td>
                            <td className="taR">0</td>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.acqs_tax) : ""}</td>
                        </tr>
                        <tr>
                            <th className="bdr1">지방교육세</th>
                            <td className="taC">{calcInfo ? Number(calcInfo.local_edu_tax_rate) : ""}</td>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.local_edu_tax) : ""}</td>
                            <td className="taR">0</td>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.local_edu_tax) : ""}</td>
                        </tr>
                        <tr>
                            <th className="bdr1">농어촌특별세</th>
                            <td className="taC">{calcInfo ? Number(calcInfo.farm_fish_tax_rate) : ""}</td>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.farm_fish_tax) : ""}</td>
                            <td className="taR">0</td>
                            <td className="taR">{calcInfo ? Util.comma(calcInfo.farm_fish_tax) : ""}</td>
                        </tr>
                        <tr>
                            <th colSpan={2} className="bdr1"><span className="fs20 fc222222 SpoqaHanSansNeo-Medium ls1">합계</span></th>
                            <td colSpan={2} className="taC"><span className="fs18 fc222222 SpoqaHanSansNeo-Medium ls1">{calcInfo ? Util.comma((Number(calcInfo.tax_base) * Number(calcInfo.share_ratio) / 100).toString()) : ""}</span></td>
                            <td colSpan={1} className="taC"><span className="fs18 fc222222 SpoqaHanSansNeo-Medium ls1">{calcInfo ? (Number(calcInfo.acqs_tax_rate) + Number(calcInfo.local_edu_tax_rate) + Number(calcInfo.farm_fish_tax_rate)).toFixed(3) : ""}</span></td>
                            <td className="taR"><span className="fs18 fc222222 SpoqaHanSansNeo-Medium ls1">{calcInfo ? Util.comma(calcInfo.total_tax) : ""}</span></td>
                            <td className="taR"><span className="fs18 fc222222 SpoqaHanSansNeo-Medium ls1">0</span></td>
                            <td className="taR"><span className="fs18 fc222222 SpoqaHanSansNeo-Medium ls1">{calcInfo ? Util.comma(calcInfo.total_tax) : ""}</span></td>
                        </tr>
                    </tbody>
                </table>

                <div className='w100p dpFlx aiC jcC'>
                    <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => {
                        setSimAcqsTaxCalcModalOpen(false);
                    }}>
                        <span className='SpoqaHanSansNeo-Medium fs16 fcWhite fw500'>확인</span>
                    </div>
                </div>
            </div>

            {/* 취득세 세율 도움말 */}
            <Modal modalOpen={acquisitionTaxRateModalOpen} setModalOpen={setAcquisitionTaxRateModalOpen} title="취득세 세율" dim={true} visible={true}>
                <div className={'ofA ofxH ' + ((acqsCause === "1002" || acqsCause === "1011" || acqsCause === "1012") ? "h500" : "")}>
                    <AcquisitionTaxRate acqsCause={acqsCause} />
                </div>
            </Modal>
        </React.Fragment>
    )
}
export default SimAcqsTaxCalc;