/*******************************************************************************
 *** index ** 모바일 상속세 - 금융공제계산 Dialog index(FinanceDeduDialog)
 *******************************************************************************/
import { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";

import { InputContainer, NumberInputContainer } from "js/mobile/common/layout";
import ModalPortal from "js/common/ui/modal/ModalPortal";
import {
  DialogBody,
  DialogMainSection,
  MainSectionContainer,
} from "js/mobile/common/layout";
import Header from "./Header";
import StepButton from "./StepButton";
import Util from "js/common/Util";

interface FinanceDeduInputValue {
  finAssets: string;
  finDebts: string;
}

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClickCalc: (financeDedu: number) => void;
};

function FinanceDeduDialog({ open, setOpen, onClickCalc }: Props) {
  const [inputValue, setInputValue] = useState<FinanceDeduInputValue>({
    finAssets: "",
    finDebts: "",
  });

  const [nextDisabled, setNextDisabled] = useState<boolean>(false);

  // 입력 완료 여부 확인
  useEffect(() => {
    let validateYn: boolean = true;

    if (
      Util.isEmpty(inputValue.finAssets) ||
      Number(inputValue.finAssets) < 0
    ) {
      validateYn = false;
    }

    if (Util.isEmpty(inputValue.finDebts) || Number(inputValue.finDebts) < 0) {
      validateYn = false;
    }

    if (Number(inputValue.finDebts) > Number(inputValue.finAssets)) {
      validateYn = false;
    }

    setNextDisabled(!validateYn);
  }, [inputValue]);

  const handleClickBack = () => {
    setOpen(false);
    handleClickReset();    
  };

  const handleClickCalc = () => {
    // 금융공제액 계산
    let iFinAssets = parseInt(Util.uncomma(inputValue.finAssets));
    let iFinDebts = parseInt(Util.uncomma(inputValue.finDebts));
    let iNetFinAssets = iFinAssets - iFinDebts; // 순금융자산

    let iFinDedu = 0; // 금융공제액
    /*
        2천만원 이하        : 전액
        2천만원 ~  1억 이하 : 2천만원
        1억원   ~ 10억 이하 : 20%
        10억 초과           : 2억원
    */

    if (iNetFinAssets <= 20000000) {
      iFinDedu = iNetFinAssets;
    } else if (20000000 < iNetFinAssets && iNetFinAssets <= 100000000) {
      iFinDedu = 20000000;
    } else if (100000000 < iNetFinAssets && iNetFinAssets <= 1000000000) {
      iFinDedu = iNetFinAssets * 0.2;
    } else if (iNetFinAssets > 1000000000) {
      iFinDedu = 200000000;
    }

    onClickCalc(iFinDedu);
    handleClickBack();
    handleClickReset();
  };

  const handleClickReset = () => {
    setInputValue({
      finAssets: "",
      finDebts: "",
    });
  };

  const handleUpdateInputValue = (
    key: keyof FinanceDeduInputValue,
    value: string
  ) => {
    setInputValue((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  if (open) {
    return (
      <ModalPortal>
        <DialogBody>
          <Header onClickBack={handleClickBack} />
          <DialogMainSection>
            <MainSectionContainer>
              <p className="desc">
                <span className="bold">금융자산</span>을 입력해주세요.
              </p>
              {/* 01. 금융자산 */}
              <InputContainer>
                <p className="label">금융자산</p>
                <div className="input">
                  <NumberInputContainer
                    $filled={inputValue.finAssets === "" ? false : true}
                  >
                    <div className="inputWrap">
                      <NumericFormat
                        name="inherProp"
                        value={inputValue.finAssets}
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        maxLength={15}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const valueWithoutComma = inputValue.replace(
                            /,/g,
                            ""
                          );
                          handleUpdateInputValue(
                            "finAssets",
                            valueWithoutComma
                          );
                        }}
                        placeholder="금융자산을 입력해주세요."
                        className="numberInput"
                      />
                      <p className="unit">원</p>
                    </div>
                  </NumberInputContainer>
                </div>
              </InputContainer>
              <InputContainer>
                <p className="label">금융부채</p>
                <div className="input">
                  <NumberInputContainer
                    $filled={inputValue.finDebts === "" ? false : true}
                  >
                    <div className="inputWrap">
                      <NumericFormat
                        name="inherProp"
                        value={inputValue.finDebts}
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        maxLength={15}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const valueWithoutComma = inputValue.replace(
                            /,/g,
                            ""
                          );
                          handleUpdateInputValue("finDebts", valueWithoutComma);
                        }}
                        placeholder="금융부채를 입력해주세요."
                        className="numberInput"
                      />
                      <p className="unit">원</p>
                    </div>
                  </NumberInputContainer>
                </div>
              </InputContainer>
            </MainSectionContainer>
            <StepButton
              backDisabled={false}
              nextDisabled={nextDisabled}
              onClickBack={handleClickBack}
              onClickNext={handleClickCalc}
              onClickReset={handleClickReset}
            />
          </DialogMainSection>
        </DialogBody>
      </ModalPortal>
    );
  } else {
    return <></>;
  }
}

export default FinanceDeduDialog;
