/*******************************************************************************
*** InheFinanInheDedu ** 상속세 금융공제 계산
*******************************************************************************/
import AppContext    from "js/common/AppContext";
import NumberTextBox from "js/common/ui/input/NumberTextBox";
import Util          from "js/common/Util";
import React         from "react";

interface InheFinanInheDeduProps {
    setFinanInheDeduModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    callBack: Function
}

const InheFinanInheDedu = ({ setFinanInheDeduModalOpen, callBack}: InheFinanInheDeduProps) => {
    const [finAssets, setFinAssets] = React.useState<string>("");   // 01.금융자산
    const [finDebts , setFinDebts ] = React.useState<string>("");   // 02.금융부채

    // 금융상속공제 계산 완료
    const goCalFin = () => {
        if (Util.isEmpty(finAssets)) {AppContext.openAlert({title: "금융자산" , msg: "금융자산을 입력하세요." , icon: "check"}); return;}
        if (Number(finAssets) < 0  ) {AppContext.openAlert({title: "금융자산" , msg: "금융자산을 입력하세요." , icon: "check"}); return;}

        if (Util.isEmpty(finDebts)) {AppContext.openAlert({title: "금융부채" , msg: "금융부채를 입력하세요." , icon: "check"}); return;}
        if (Number(finDebts) < 0  ) {AppContext.openAlert({title: "금융부채" , msg: "금융부채를 입력하세요." , icon: "check"}); return;}
        if (Number(finDebts) > Number(finAssets)) {AppContext.openAlert({title: "금융부채 금액 확인" , msg: "금융부채가 금융자산보다 큽니다. 금융부채를 다시 확인하세요." , icon: "check"}); return;}
        
        // 금융공제액 계산
        let iFinAssets = parseInt(Util.uncomma(finAssets));
        let iFinDebts  = parseInt(Util.uncomma(finDebts));
        let iNetFinAssets = iFinAssets - iFinDebts; // 순금융자산

        let iFinDedu = 0; // 금융공제액
        /*
            2천만원 이하        : 전액
            2천만원 ~  1억 이하 : 2천만원
            1억원   ~ 10억 이하 : 20%
            10억 초과           : 2억원
        */

        if (iNetFinAssets <= 20000000) {
            iFinDedu = iNetFinAssets;
        } else if (20000000 < iNetFinAssets && iNetFinAssets <= 100000000) {    
            iFinDedu = 20000000;
        } else if (100000000 < iNetFinAssets && iNetFinAssets <= 1000000000) { 
            iFinDedu = iNetFinAssets * 0.2;
        } else if (iNetFinAssets > 1000000000) {    
            iFinDedu = 200000000;
        }

        const retParam = {
            calFinDedu: iFinDedu
        }
        callBack(retParam);
        setFinanInheDeduModalOpen(false);
    }

    // 취소
    const goCancel = () => {
        setFinAssets("");
        setFinDebts ("");
        setFinanInheDeduModalOpen(false);
    }

    return (

        <div className='w400 mh200'>
            {/* 01. 금융자산 */}
            <div className={(true ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt30'}>
                <div className="w120 h40 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>금융자산</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <NumberTextBox
                        value={finAssets}
                        onChange={(data: string) => setFinAssets(data)}
                        borderClass="bgcWhite w230 h40 pr15 bd1"
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        maxLength={15}
                        inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                        unitText="원"
                    />
                </div>
            </div>

            {/* 02. 금융부채 */}
            <div className={(true ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w120 h40 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>금융부채</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <NumberTextBox
                        value={finDebts}
                        onChange={(data: string) => setFinDebts(data)}
                        borderClass="bgcWhite w230 h40 pr15 bd1"
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        maxLength={15}
                        inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                        unitText="원"
                    />
                </div>
            </div>  

            <div className='w100p dpFlx aiC jcC pt40 pb40'>
                <button className='w100 h40 bgcF6F6F6 dpFlx aiC jcC csP bd1 bdc878787' onClick={() => goCancel()}>
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>취소</span>
                </button>
                <button className='w100 h40 bgc17A840 dpFlx aiC jcC csP bdN' onClick={() => goCalFin()}>
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>계산하기</span>
                </button>
            </div>              

        </div>    


    )
}
export default InheFinanInheDedu;
