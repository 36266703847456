/*******************************************************************************
 *** SimGiftTaxCalc ** 간편계산기 증여세 결과표
*******************************************************************************/
import React      from "react";
import AppContext from "js/common/AppContext";
import Axios      from "js/common/Axios";
import Util       from "js/common/Util";
import ToolTip from "js/common/ui/ToolTip";

interface SimGiftTaxCalcProps {
    setSimGiftTaxCalcModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setSimGiftTaxCalcModalVsb: React.Dispatch<React.SetStateAction<boolean>>
    giftTaxParam: any[]
}

const SimGiftTaxCalc = ({ setSimGiftTaxCalcModalOpen, setSimGiftTaxCalcModalVsb, giftTaxParam}: SimGiftTaxCalcProps) => {
    const [calcInfo, setCalcInfo] = React.useState<any | undefined>();  // 증여세 계산결과

    // 증여세 파라미터 확인
    React.useEffect(() => {
        let taxFlag        = "";     // 00. 세금 구분 Flag
        let donor          = "";     // 01. 증여자
        let giftProp       = "";     // 02. 증여 재산가액 
        let debtAssum      = "";     // 03. 채무인수 여부 
        let debtAmt        = "";     // 04. 채무액 
        let preGift        = "";     // 05. 증여자 사전증여 유무 
        let preGiftProp    = "";     // 06. 사전증여 재산가액 
        let preGiftTax     = "";     // 07. 사전증여 세액 
        let doneeMinor     = "";     // 08. 수증자 미성년자 여부 
        let basicDedu      = "";     // 09. 기본공제 
        let marriageDedu   = "";     // 10. 혼인공제 
        let childbirthDedu = "";     // 11. 출산공제 

        // 배열에서 파라미터 얻기
        for (const taxParam of giftTaxParam) {
            taxFlag        = taxParam.taxFlag       ; // 00. 세금 구분 Flag
            donor          = taxParam.donor         ; // 01. 증여자
            giftProp       = taxParam.giftProp      ; // 02. 증여 재산가액 
            debtAssum      = taxParam.debtAssum     ; // 03. 채무인수 여부 
            debtAmt        = taxParam.debtAmt       ; // 04. 채무액 
            preGift        = taxParam.preGift       ; // 05. 증여자 사전증여 유무 
            preGiftProp    = taxParam.preGiftProp   ; // 06. 사전증여 재산가액 
            preGiftTax     = taxParam.preGiftTax    ; // 07. 사전증여 세액 
            doneeMinor     = taxParam.doneeMinor    ; // 08. 수증자 미성년자 여부 
            basicDedu      = taxParam.basicDedu     ; // 09. 기본공제 
            marriageDedu   = taxParam.marriageDedu  ; // 10. 혼인공제 
            childbirthDedu = taxParam.childbirthDedu; // 11. 출산공제 
        }

        Axios.dataAccess({
            url: "api/system/broker/callApi.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                apiName : "taxcalc"
              , tailUrl : "simpletax/gifttax"
              , paramStr: taxFlag + "?donor="           + donor              // 01. 증여자
                                  + "&gift_prop="       + giftProp           // 02. 증여 재산가액 
                                  + "&debt_assum="      + debtAssum          // 03. 채무인수 여부 
                                  + "&debt_amt="        + debtAmt            // 04. 채무액 
                                  + "&pre_gift="        + preGift            // 05. 증여자 사전증여 유무 
                                  + "&pre_gift_prop="   + preGiftProp        // 06. 사전증여 재산가액 
                                  + "&pre_gift_tax="    + preGiftTax         // 07. 사전증여 세액 
                                  + "&donee_minor="     + doneeMinor         // 08. 수증자 미성년자 여부 
                                  + "&basic_dedu="      + basicDedu          // 09. 기본공제 
                                  + "&marriage_dedu="   + marriageDedu       // 10. 혼인공제 
                                  + "&childbirth_dedu=" + childbirthDedu     // 11. 출산공제
            },
            onSuccessFn: (res: any) => {
                if (res.item.rtn_list[0].gift_tax_base === "0") {
                    setSimGiftTaxCalcModalOpen(false);
                    AppContext.openAlert({
                        title: "과세 대상 여부",
                        msg: "증여세 납부 대상이 아닙니다.",
                        icon: "check"
                    });
                    return;
                }

                // 증여세 세액 계산 결과
                setCalcInfo(res.item.rtn_list[0]);
                setSimGiftTaxCalcModalVsb(true);
           },
            onFailedFn: () => {
                setSimGiftTaxCalcModalOpen(false);
            }
        });
    }, [giftTaxParam, setSimGiftTaxCalcModalOpen, setSimGiftTaxCalcModalVsb]);

    return (
        <div className='w500 ofA'>
            <table className={
                "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD h40 mb40 mt10 " +
                "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 th-ls1 " +
                "td-p20 td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls075 td-fc666666 td-bdcDDDDDD"
            }>
                <colgroup>
                    <col className="w250" />
                    <col className="w150" />
                </colgroup>
                <tbody>
                    <tr>
                        <th>증여재산</th>
                        <td className="taR">{calcInfo ? Util.comma(calcInfo.gift_prop) : ""}</td>
                    </tr>
                    <tr>
                        <th>(-)채무액</th>
                        <td className="taR">{calcInfo ? Util.comma(calcInfo.debt_amt) : ""}</td>
                    </tr>
                    <tr>
                        <th>(+)10년 이내 증여재산</th>
                        <td className="taR">{calcInfo ? Util.comma(calcInfo.pre_gift_prop) : ""}</td>
                    </tr>
                    <tr>
                        <th>증여세 과세가액</th>
                        <td className="taR">{calcInfo ? Util.comma(calcInfo.taxable_value) : ""}</td>
                    </tr>
                    <tr>
                        <th>(-)증여재산 공제</th>
                        <td className="taR">{calcInfo ? Util.comma(calcInfo.donee_deduc_amt) : ""}</td>
                    </tr>
                    <tr>
                        <th>증여세 과세표준</th>
                        <td className="taR">{calcInfo ? Util.comma(calcInfo.gift_tax_base) : ""}</td>
                    </tr>
                    <tr>
                        <th>(*)증여세율</th>
                        <td className="taR">{calcInfo ? Number(calcInfo.gift_tax_rate): ""}%</td>
                    </tr>
                    <tr>
                        <th>(-)누진공제액</th>
                        <td className="taR">{calcInfo ? Util.comma(calcInfo.gift_progre_deduct_amt) : ""}</td>
                    </tr>
                    <tr>
                        <th>
                            <div className="dpFlx flxdRow jcC taL">
                                산출 세액 (세대생략 가산액 안내)
                                <div className="dpFlx aiC jcC taL"
                                     data-tooltip-id="generationSkipToolTip"
                                     data-tooltip-content={
                                            "♠ 증여세 세대생략 할증과세\n\n" +
                                            "자녀를 건너 뛰어 손주에게 증여 시, 즉 세대생략 증여 시 증여세율은 30% 할증됩니다.\n" +
                                            "직계비속의 세대생략 증여 시 수증자가 미성년자로 증여재산 가액이 20억을 초과하면\n" +
                                            "할증율은 40%가 적용됩니다.\n"
                                        }>
                                        <img src={require('img/common/icon_info_fill_20.png')} alt="icon_info_fill_20" className='csP pl3' />
                                        <ToolTip id="generationSkipToolTip" place='top' clickable={true} className="w500 lh20" />
                                </div>  
                            </div>                          
                        </th>
                        <td className="taR">{calcInfo ? Util.comma(calcInfo.gift_tax_amt) : ""}</td>
                    </tr>
                    <tr>
                        <th>(-)10년 이내 증여재산 납부세액</th>
                        <td className="taR">{calcInfo ? Util.comma(calcInfo.pre_gift_tax) : ""}</td>
                    </tr>
                    <tr>
                        <th>(-)자진신고 납부세액공제</th>
                        <td className="taR">{calcInfo ? Util.comma(calcInfo.report_deduct_amt) : ""}</td>
                    </tr>
                    <tr>
                        <th><span className="fs18 fc222222 SpoqaHanSansNeo-Medium ls1">납부할 증여세액</span></th>
                        <td className="taR"><span className="fs18 fc222222 SpoqaHanSansNeo-Medium ls1">{calcInfo ? Util.comma(calcInfo.total_gift_tax_amt) : ""}</span></td>
                    </tr>
                </tbody>
            </table>

            <div className='w100p dpFlx aiC jcC'>
                <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => {
                    setSimGiftTaxCalcModalOpen(false);
                }}>
                    <span className='SpoqaHanSansNeo-Medium fs16 fcWhite fw500'>확인</span>
                </div>
            </div>
        </div>
    )        
}
export default SimGiftTaxCalc;