/*******************************************************************************
*** LinkJoin ** 위케이크 회원가입
*******************************************************************************/
import React         from 'react';
import Axios         from 'js/common/Axios';
import AppContext    from 'js/common/AppContext';
import Modal         from 'js/common/ui/modal/Modal';
import Term          from './help/Term';
import PersonalInfo  from './help/PersonalInfo';
import Util          from 'js/common/Util';
import ImageCheckBox from 'js/common/ui/input/ImageCheckBox';
import TextBox       from 'js/common/ui/input/TextBox';

interface LinkJoinProps {
    setLinkJoinModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setLinkJoinSelTime: React.Dispatch<React.SetStateAction<Date>>;
    wecakeUserId:any;
}

const LinkJoin = ({setLinkJoinModalOpen, setLinkJoinSelTime, wecakeUserId}: LinkJoinProps) => {
    const [emailAdres  , setEmailAdres  ] = React.useState<string>(""); // 이메일
    const [userNm      , setUserNm      ] = React.useState<string>(""); // 이름
    const [mbtlnum     , setMbtlnum     ] = React.useState<string>(""); // 휴대폰
    const [userId      , setUserId      ] = React.useState<string>(""); // 아이디(ID)
    const [userSn      , setUserSn      ] = React.useState<number>(); // 사용자 일련번호

    const [checkTerm1, setCheckTerm1] = React.useState<boolean>(false); // 약관 체크박스
    const [checkTerm2, setCheckTerm2] = React.useState<boolean>(false); // 개인정보취급 체크박스
    const [termsConditionModalOpen, setTermsConditionModalOpen] = React.useState<boolean>(false); // 이용약관 팝업
    const [personalInfoModalOpen  , setPersonalInfoModalOpen  ] = React.useState<boolean>(false); // 개인정보취급 팝업

    React.useEffect(() => {
        Axios.dataAccess({
            url: "portal/join/selWecakeUserInfo.do",    // 입력된 회원정보 확인
            methodType: "post",
            paramType: "object",
            paramData: {
                wecakeUserId : wecakeUserId,
            },
            onSuccessFn: (res: any) => {
                setUserSn(res.item.userSn);
                setEmailAdres(res.item.emailAdres);
                setUserNm(res.item.userNm);
                setMbtlnum(res.item.mbtlnum);
                setUserId(res.item.wecakeUserId);
            }
        });
    }, [wecakeUserId]);

    // 이용약관 전문보기
    const openTermsConditionDialog = () => {
        setTermsConditionModalOpen(true);
    }

    // 개인정보처리방침 전문보기
    const openPersonalInfoDialog = () => {
        setPersonalInfoModalOpen(true);
    }

    // 회원가입
    const regUserInfo = () => {
        if (!checkTerm1) {
            AppContext.openAlert({
                title: "이용약관 동의",
                msg: "이용약관에 동의하세요.",
                icon: "check"
            });
            return;
        }

        if (!checkTerm2) {
            AppContext.openAlert({
                title: "개인정보취급방침 동의",
                msg: "개인정보취급방침에 동의하세요.",
                icon: "check"
            });
            return;
        }
        AppContext.openAlert({
            title: "서비스가입",
            msg: "콜택스 서비스가입을 완료하시겠습니까?",
            icon: "check",
            confirmText: "가입하기",
            handleConfirm: () => {
                Axios.dataAccess({
                    url: "portal/join/updWecakeUserInfo.do",
                    methodType: "post",
                    paramType: "json",
                    paramData: {
                        userSn: userSn,
                        userId: userId,
                        emailAdres: emailAdres,
                        userNm: userNm,
                        mbtlnum: mbtlnum
                    },
                    onSuccessFn: () => {
                        setLinkJoinModalOpen(false);
                        setLinkJoinSelTime(new Date());
                    }
                });
            }
        });
    }

    return (
        <div className="w100p">
            <div className='w100p dpFlx aiC jcC'>
                <span className="SpoqaHanSansNeo-Bold fs36 ls1 fcBlack">서비스 가입</span>
            </div>
            <div className='w100p dpFlx aiC jcC pt40'>
                <div className='w460'>
                    {/* 아이디 */}
                    <div className='dpFlx aiC jcC mb20'>
                        <span className="SpoqaHanSansNeo-Medium fs16 ls08 mr20 fc222222 w80">아이디</span>
                        <TextBox
                            type="text"
                            value={userId}
                            height={35}
                            width={300}
                            fontSize={16}
                            fontFamily='SpoqaHanSansNeo-Regular'
                            maxLength={20}
                            placeholder="아이디를 입력하세요."
                            inputClassName='bgcWhite ls08'
                            color="#222222"
                            offColor="#999999"
                            className='bd1 pl14 pr20'
                            mode="disabled"
                        />
                    </div>

                    {/* 이름 */}
                    <div className='dpFlx aiC jcC mb20'>
                        <span className="SpoqaHanSansNeo-Medium fs16 ls08 mr20 fc222222 w80">이름</span>
                        <TextBox
                            value={userNm}
                            height={35}
                            width={300}
                            fontSize={16}
                            fontFamily='SpoqaHanSansNeo-Regular'
                            maxLength={30}
                            placeholder="이름을 입력하세요"
                            onChangeFunc={(text: string) => setUserNm(text)}
                            inputClassName='bgcWhite ls08'
                            color="#222222"
                            offColor="#999999"
                            className='bd1 pl15 pr20'
                            mode="disabled"
                        />
                    </div>

                    {/* 휴대폰 번호 */}
                    <div className='dpFlx aiC jcC mb20'>
                        <span className="SpoqaHanSansNeo-Medium fs16 ls08 mr20 fc222222 w80">휴대폰 번호</span>
                        <TextBox
                            type="phone"
                            value={Util.setPhoneNum(mbtlnum)}
                            width={300}
                            height={35}
                            fontSize={16}
                            fontFamily='SpoqaHanSansNeo-Regular'
                            maxLength={30}
                            placeholder="휴대폰번호를 입력하세요."
                            onChangeFunc={(text: string) => setMbtlnum(text)}
                            inputClassName='bgcWhite ls08'
                            color="#222222"
                            offColor="#999999"
                            className='bd1 pl14 pr20'
                            mode="disabled"
                        />
                    </div>

                    {/* 이메일 주소 */}
                    <div className='dpFlx aiC jcC mb20'>
                        <span className="SpoqaHanSansNeo-Medium fs16 ls08 mr20 fc222222 w80">이메일 주소</span>
                        <TextBox
                            type="email"
                            value={emailAdres}
                            width={300}
                            height={35}
                            fontSize={16}
                            fontFamily='SpoqaHanSansNeo-Regular'
                            maxLength={30}
                            placeholder="이메일주소를 입력하세요."
                            onChangeFunc={(text: string) => setEmailAdres(text)}
                            inputClassName='bgcWhite ls08'
                            color="#222222"
                            offColor="#999999"
                            className='bd1 pl14 pr20'
                            mode="disabled"
                        />
                    </div>

                    {/* 이용약관 */}
                    <div className='dpFlx aiC jcSb mt30' onClick={openTermsConditionDialog}>
                        <ImageCheckBox
                            value={checkTerm1}
                            setValue={setCheckTerm1}
                            labelText='이용약관 동의'
                            btnClass='w20 h20'
                            labelClass='SpoqaHanSansNeo-Regular fs16 ls07 fc222222 pl5'
                        />
                        <span className="SpoqaHanSansNeo-Medium fs16 ls07 fc222222 tdU tuOs2 csP">전문보기</span>
                    </div>

                    {/* 개인정보처리방침 */}
                    <div className='dpFlx aiC jcSb mt15 pb10' onClick={openPersonalInfoDialog}>
                        <ImageCheckBox
                            value={checkTerm2}
                            setValue={setCheckTerm2}
                            labelText='개인정보처리방침 동의'
                            btnClass='w20 h20'
                            labelClass='SpoqaHanSansNeo-Regular fs16 ls07 fc222222 pl5'
                        />
                        <span className="SpoqaHanSansNeo-Medium fs16 ls07 fc222222 tdU tuOs2 csP">전문보기</span>
                    </div>
                    <div className='w100p h62 bgc17A840 mt40 dpFlx aiC jcC csP' onClick={regUserInfo}>
                        <span className="SpoqaHanSansNeo-Bold fs18 ls08 fcWhite">가입하기</span>
                    </div>
                </div>
            </div>
            <Modal modalOpen={termsConditionModalOpen} setModalOpen={setTermsConditionModalOpen} title="이용약관 전문보기" dim={true} visible={true}>
                <div className='h500 w800 ofA'>
                    <Term/>
                    <div className='w100p dpFlx aiC jcC pt40'>
                        <div className='w210 h56 bgcF6F6F6 dpFlx aiC jcC csP' onClick={() => {
                            setCheckTerm1(false);
                            setTermsConditionModalOpen(false);
                        }}>
                            <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>취소</span>
                        </div>
                        <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => {
                            setCheckTerm1(true);
                            setTermsConditionModalOpen(false);
                        }}>
                            <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>동의</span>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal modalOpen={personalInfoModalOpen} setModalOpen={setPersonalInfoModalOpen} title="개인정보취급 전문보기" dim={true} visible={true}>
                <div className='h500 w800 ofA'>
                    <PersonalInfo/>
                    <div className='w100p dpFlx aiC jcC pt40'>
                        <div className='w210 h56 bgcF6F6F6 dpFlx aiC jcC csP' onClick={() => {
                            setCheckTerm2(false);
                            setPersonalInfoModalOpen(false);
                        }}>
                            <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>취소</span>
                        </div>
                        <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => {
                            setCheckTerm2(true);
                            setPersonalInfoModalOpen(false);
                        }}>
                            <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>동의</span>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
export default LinkJoin;