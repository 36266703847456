import { ChangeEvent, useState } from "react";
import { NumericFormat } from "react-number-format";
import styled from "styled-components";

import {
  TranTaxParamProps,
  TranTaxParamTitle,
  TranTaxParamVisible,
} from "js/common/types/types";
import { InputContainer, NumberInputContainer } from "../common/layout";
import DateInput from "../common/DateInput";
import { TranTaxAction } from "./tranTaxStatesReducer";
import dayjs from "dayjs";
import Util from "js/common/Util";
import ToolTip from "js/common/ui/ToolTip";

type Props = {
  inputValue: TranTaxParamProps;
  inputVisible: TranTaxParamVisible;
  inputTitle: TranTaxParamTitle;
  onUpdateInput: (action: TranTaxAction) => void;
};

function FormerHouseInfo({
  inputValue,
  inputVisible,
  inputTitle,
  onUpdateInput,
}: Props) {
  const [shareRatioHelpText, setShareRatioHelpText] = useState<string>("");

  const handleChangeShareRatio = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    const numberValue = parseInt(inputValue);

    if (numberValue > 100 || numberValue <= 0) {
      setShareRatioHelpText("1~100 사이의 값을 입력하세요.");
    } else {
      setShareRatioHelpText("");
    }

    onUpdateInput({
      type: "UPDATED_SHARE_RATIO",
      payload: inputValue,
    });
  };

  const handleChangeMngDispAppDate = (date: dayjs.Dayjs | null) => {
    onUpdateInput({
      type: "UPDATED_MNG_DISP_APP_DATE",
      payload: Util.dateToString(date, ""),
    });
  };

  const handleChangeMngDispAppDateAmt = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;

    const valueWithoutComma = inputValue.replace(/,/g, "");
    onUpdateInput({
      type: "UPDATED_MNG_DISP_APP_DATE_AMT",
      payload: valueWithoutComma,
    });
  };

  const handleChangeAcquDatePreHse = (date: dayjs.Dayjs | null) => {
    onUpdateInput({
      type: "UPDATED_ACQU_DATE_PRE_HSE",
      payload: Util.dateToString(date, ""),
    });
  };

  const handleChangeAcquDateInherit = (date: dayjs.Dayjs | null) => {
    onUpdateInput({
      type: "UPDATED_ACQU_DATE_INHERIT",
      payload: Util.dateToString(date, ""),
    });
  };

  const handleChangeResiPeriodPreHse = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;

    onUpdateInput({
      type: "UPDATED_RESI_PERIOD_PRE_HSE",
      payload: inputValue,
    });
  };

  const handleChangeAcquPrice = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    const valueWithoutComma = inputValue.replace(/,/g, "");
    onUpdateInput({
      type: "UPDATED_ACQU_PRICE",
      payload: valueWithoutComma,
    });
  };

  const handleChangeNeceExpenPreHse = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;

    const valueWithoutComma = inputValue.replace(/,/g, "");
    onUpdateInput({
      type: "UPDATED_NECE_EXPEN_PRE_HSE",
      payload: valueWithoutComma,
    });
  };

  return (
    <>
      {/* 15. 지분율 */}
      {inputVisible.visible15 && (
        <InputContainer>
          <p className="label">지분율</p>
          <div className="input">
            <NumberInputContainer
              $filled={inputValue.shareRatio === "" ? false : true}
            >
              <div className="inputWrap">
                <NumericFormat
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  maxLength={3}
                  name="shareRatio"
                  value={inputValue.shareRatio}
                  onChange={(e) => {
                    if (handleChangeShareRatio === undefined) {
                      return;
                    }
                    handleChangeShareRatio(e);
                  }}
                  placeholder="지분율을 입력해주세요."
                  className="numberInput"
                />
                <p className="unit">%</p>
              </div>
            </NumberInputContainer>
            <div className="help warning">{shareRatioHelpText}</div>
          </div>
        </InputContainer>
      )}
      {/* 16. 관리처분인가일 */}
      {inputVisible.visible16 && (
        <InputContainer>
          <p className="label">관리처분인가일</p>
          <div className="input">
            <DateInput
              value={inputValue.mngDispAppDate}
              onChangeInput={handleChangeMngDispAppDate}
              isFilled={inputValue.mngDispAppDate ? true : false}
            />
          </div>
        </InputContainer>
      )}
      {/* 17. 관리처분인가일 평가액 */}
      {inputVisible.visible17 && (
        <InputContainer>
          <p className="label">관리처분인가일 평가액</p>
          <div className="input">
            <NumberInputContainer
              $filled={inputValue.mngDispAppDateAmt === "" ? false : true}
            >
              <div className="inputWrap">
                <NumericFormat
                  value={inputValue.mngDispAppDateAmt}
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  maxLength={15}
                  onChange={(e) => {
                    if (handleChangeMngDispAppDateAmt === undefined) {
                      return;
                    }
                    handleChangeMngDispAppDateAmt(e);
                  }}
                  placeholder="관리처분인가일 평가액을 입력해주세요."
                  className="numberInput"
                  name="mngDispAppDateAmt"
                />
                <p className="unit">원</p>
              </div>
            </NumberInputContainer>
          </div>
        </InputContainer>
      )}
      {/* 18.종전주택 취득일자 */}
      {inputVisible.visible18 && (
        <InputContainer>
          <TootipLabel>
            <p className="label">{inputTitle.title18}</p>
            <div
              className="tootipWrap"
              data-tooltip-id="acqusitionDateToolTip"
              data-tooltip-content={
                "♠ 취득일자\n\n" +
                "매매 - 잔급지급일, 소유권 이전 등기접수일 중 빠른 날\n" +
                "상속 - 상속개시일\n" +
                "증여 - 증여등기접수일\n" +
                "자가신축 - 사용승인일\n" +
                "조합원입주권에 의한 아파트 - 사용승인일\n" +
                "분양권에 의한 아파트 - 분양대금완납일\n"
              }
            >
              <img
                src={require("img/common/icon_search20_black.png")}
                alt="icon_info_fill_20"
                className="csP"
              />
              <ToolTip
                id="acqusitionDateToolTip"
                place="top"
                clickable={true}
                className="w200 lh20"
              />
            </div>
          </TootipLabel>
          <div className="input">
            <DateInput
              value={inputValue.acquDatePreHse}
              onChangeInput={handleChangeAcquDatePreHse}
              isFilled={inputValue.acquDatePreHse ? true : false}
            />
          </div>
        </InputContainer>
      )}
      {/* 18-1.피상속인 취득일자 */}
      {inputVisible.visible18_1 && (
        <InputContainer>
          <p className="label">피상속인 취득일자</p>
          <div className="input">
            <DateInput
              value={inputValue.acquDateInherit}
              onChangeInput={handleChangeAcquDateInherit}
              isFilled={inputValue.acquDateInherit ? true : false}
            />
          </div>
        </InputContainer>
      )}
      {/* 19. 종전주택 거주기간 */}
      {inputVisible.visible19 && (
        <InputContainer>
          <p className="label">{inputTitle.title19}</p>
          <div className="input">
            <NumberInputContainer
              $filled={inputValue.resiPeriodPreHse === "" ? false : true}
            >
              <div className="inputWrap">
                <NumericFormat
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  maxLength={3}
                  name="resiPeriodPreHse"
                  value={inputValue.resiPeriodPreHse}
                  onChange={(e) => {
                    if (handleChangeResiPeriodPreHse === undefined) {
                      return;
                    }
                    handleChangeResiPeriodPreHse(e);
                  }}
                  placeholder={`${inputTitle.title19}을 입력해주세요.`}
                  className="numberInput"
                />
                <p className="unit">개월</p>
              </div>
            </NumberInputContainer>
          </div>
        </InputContainer>
      )}
      {/* 20. 취득가액 */}
      {inputVisible.visible20 && (
        <InputContainer>
          <p className="label">취득가액</p>
          <div className="input">
            <NumberInputContainer
              $filled={inputValue.acquPrice === "" ? false : true}
            >
              <div className="inputWrap">
                <NumericFormat
                  value={inputValue.acquPrice}
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  maxLength={15}
                  onChange={(e) => {
                    if (handleChangeAcquPrice === undefined) {
                      return;
                    }
                    handleChangeAcquPrice(e);
                  }}
                  placeholder="취득가액을 입력해주세요."
                  className="numberInput"
                  name="acquPrice"
                />
                <p className="unit">원</p>
              </div>
            </NumberInputContainer>
          </div>
        </InputContainer>
      )}
      {/* 21. 종전주택 필요경비 */}
      {inputVisible.visible21 && (
        <InputContainer>
          <p className="label">종전주택 필요경비</p>
          <div className="input">
            <NumberInputContainer
              $filled={inputValue.neceExpenPreHse === "" ? false : true}
            >
              <div className="inputWrap">
                <NumericFormat
                  value={inputValue.neceExpenPreHse}
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  maxLength={15}
                  onChange={(e) => {
                    if (handleChangeNeceExpenPreHse === undefined) {
                      return;
                    }
                    handleChangeNeceExpenPreHse(e);
                  }}
                  placeholder="종전주택 필요경비를 입력해주세요."
                  className="numberInput"
                  name="neceExpenPreHse"
                />
                <p className="unit">원</p>
              </div>
            </NumberInputContainer>
          </div>
        </InputContainer>
      )}
    </>
  );
}

const TootipLabel = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  margin-bottom: 5px;

  .label {
    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.29;
    letter-spacing: -0.058em;
    color: ${(props) => props.theme.color.nGray30};

    margin: 0;
  }

  .tootipWrap {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 20px;
    height: 20px;
  }
`;

export default FormerHouseInfo;
