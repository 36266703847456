import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import styled from 'styled-components';

export const StyledToastContainer = styled(ToastContainer)`
    margin-bottom: 60px;
    width: auto;
    .Toastify__toast {
        background-color: #373737;
        border-radius: 40px;
        color: white;
        border: 1px solid #ffffff;
        text-align: center;
        padding: 0 40px 0 40px;
    }
`

const Toast = () =>
    <StyledToastContainer
        position="bottom-center"
        limit={1}
        closeButton={false}
        autoClose={10000}
        hideProgressBar
    />
;

export default Toast;