/*******************************************************************************
 *** LongSpecialDeduction ** 장기보유특별공제 도움말 Modal * 양도세 결과표에서 호출
*******************************************************************************/
const LongSpecialDeduction = () => {
    return (
        <div className='w720'>
            <div className="w35p float-l pr10 bdBox">
                <div className='dpFlx jcL aiC mb10'>
                    <span className="SpoqaHanSansNeo-Regular fc505050 ls075 fs16 lh20">
                        ▼ 장기보유특별공제 1표
                    </span>
                </div>
                    <table className={
                        "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD " +
                        "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 " +
                        "td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls1 td-fc666666 td-bdcDDDDDD td-p15"
                    }>
                    <colgroup>
                        <col className="w80"></col>
                        <col className="w120"></col>
                    </colgroup>
                    <tbody>
                        <tr className="h69">
                            <th>구분</th>
                            <th>장기보유특별공제<br/>1표(30%)</th>
                        </tr>
                        <tr className="h29"><td>         </td>  <td>   </td></tr>
                        <tr className="h29"><td>3년 이상 </td>  <td>6% </td></tr>
                        <tr className="h29"><td>4년 이상 </td>  <td>8% </td></tr>
                        <tr className="h29"><td>5년 이상 </td>  <td>10%</td></tr>
                        <tr className="h29"><td>6년 이상 </td>  <td>12%</td></tr>
                        <tr className="h29"><td>7년 이상 </td>  <td>14%</td></tr>
                        <tr className="h29"><td>8년 이상 </td>  <td>16%</td></tr>
                        <tr className="h29"><td>9년 이상 </td>  <td>18%</td></tr>
                        <tr className="h29"><td>10년 이상</td>  <td>20%</td></tr>
                        <tr className="h29"><td>11년 이상</td>  <td>22%</td></tr>
                        <tr className="h29"><td>12년 이상</td>  <td>24%</td></tr>
                        <tr className="h29"><td>13년 이상</td>  <td>26%</td></tr>
                        <tr className="h29"><td>14년 이상</td>  <td>28%</td></tr>
                        <tr className="h29"><td>15년 이상</td>  <td>30%</td></tr>
                        <tr className="h29"><td>적용기준 </td>  <td>   </td></tr>
                        <tr className="h29"><td>기간계산 </td>  <td>취득일~양도일</td></tr>
                    </tbody>
                </table>
            </div>

            <div className="w65p float-r pl30 pr10 bdBox">
                <div className='dpFlx jcL aiC mb10'>
                <span className="SpoqaHanSansNeo-Regular fc505050 ls075 fs16 lh20">
                        ▼ 장기보유특별공제 2표
                    </span>
                </div>
                <table className={
                            "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD " +
                            "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 " +
                            "td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls1 td-fc666666 td-bdcDDDDDD td-p15"
                }>
                    <colgroup>
                        <col className="w80"></col>
                        <col className="w120"></col>
                        <col className="w120"></col>
                    </colgroup>
                    <tbody>
                        <tr className="h40">
                            <th rowSpan={2}>구분</th>
                            <th colSpan={2}>장기보유특별공제 2표(80%)</th>
                        </tr>
                        <tr className="h29">
                            <th>보유기간</th>
                            <th>거주기간</th>
                        </tr>
                        <tr className="h29"><td>2년 이상 </td>  <td>   </td>  <td>8% </td></tr>
                        <tr className="h29"><td>3년 이상 </td>  <td>12%</td>  <td>12%</td></tr>
                        <tr className="h29"><td>4년 이상 </td>  <td>16%</td>  <td>16%</td></tr>
                        <tr className="h29"><td>5년 이상 </td>  <td>20%</td>  <td>20%</td></tr>
                        <tr className="h29"><td>6년 이상 </td>  <td>24%</td>  <td>24%</td></tr>
                        <tr className="h29"><td>7년 이상 </td>  <td>28%</td>  <td>28%</td></tr>
                        <tr className="h29"><td>8년 이상 </td>  <td>32%</td>  <td>32%</td></tr>
                        <tr className="h29"><td>9년 이상 </td>  <td>36%</td>  <td>36%</td></tr>
                        <tr className="h29"><td>10년 이상</td>  <td>40%</td>  <td>40%</td></tr>
                        <tr className="h29"><td>11년 이상</td>  <td>   </td>  <td>   </td></tr>
                        <tr className="h29"><td>12년 이상</td>  <td>   </td>  <td>   </td></tr>
                        <tr className="h29"><td>13년 이상</td>  <td>   </td>  <td>   </td></tr>
                        <tr className="h29"><td>14년 이상</td>  <td>   </td>  <td>   </td></tr>
                        <tr className="h29"><td>15년 이상</td>  <td>   </td>  <td>   </td></tr>
                        <tr className="h29"><td>적용기준 </td>  <td colSpan={2}>거주기간 2년 이상, 보유기간 3년 이상에 한정</td></tr>
                        <tr className="h29"><td>기간계산 </td>  <td>취득일~양도일</td><td>전입일~전출일</td></tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default LongSpecialDeduction;