/*******************************************************************************
 You are strictly prohibited to copy, disclose, distribute, modify, or use
 this program in part or as a whole without the prior written consent of 
 CallTax. CallTax, owns the intellectual property rights in and to this program.
 (Copyright © 2023 CallTax. All Rights Reserved| Confidential)
 
 Specification
    1. Program ID    : PersonalInfo.tsx
    2. Program Title : 개인정보취급방침
    3. Created by    : JH Lee
    4. Create Date   : 2024.02.21
    5. Reference     : 
*******************************************************************************/
import React from "react";

function PersonalInfo() {
    const fontClassTitle   = "SpoqaHanSansNeo-Bold fs16 ls08 fc222222";
    const fontClassDetails = "SpoqaHanSansNeo-Regular fs15 ls065 fc666666 csP";

	return (
		<React.Fragment>
			<div className='w100p'>
				<div className='w100p h160 dpFlx aiC jcC'>
					<span className="SpoqaHanSansNeo-Bold fs36 ls1 fcBlack">개인정보취급방침</span>
				</div>
				<div className="dpFlx jcC pt30 pb50">
					<div className='w1200'>
						<span className={fontClassDetails}>
							(주)콜택스(이하 ‘회사’라 한다)는 정보통신망이용촉진 및 정보보호 등에 관한 법률, 개인정보 보호법 등에 따라 콜택스 프로그램 이용자의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 취급방침을 수립·공개합니다.<br/><br/>
							본 방침은 2024년 2월 1일부터 시행됩니다.<br/>
						</span>
						<br/>
						<span className={fontClassTitle}>1. 개인정보의 처리 목적</span><br/>
						<span className={fontClassDetails}>
							회사는 개인정보를 다음의 목적을 위해 처리합니다.<br/>
							처리한 개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.<br/>
							① 회원가입 및 관리<br/>
								회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 각종 고지·통지, 고충처리 등을 목적으로 개인정보를 처리합니다.<br/>
							② 서비스 제공<br/>
								서비스 제공, 콘텐츠 제공, 본인인증 등을 목적으로 개인정보를 처리합니다.<br/>
							③ 마케팅 및 광고에의 활용<br/>
								신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.<br/>
						</span>
						<br/>
						<span className={fontClassTitle}>2. 수집하는 개인정보 항목 및 수집방법</span><br/>
						<span className={fontClassDetails}>
							회사는 회원가입 및 서비스 제공 등을 위해 아래와 같은 개인정보를 수집하고 있습니다.<br/>
							① 회원가입<br/>
							&nbsp;&nbsp;- 필수항목 : 아이디, 이름, 비밀번호, 이메일주소, 핸드폰<br/>
							&nbsp;&nbsp;- 선택항목 : 상호, 소재지, 사업자등록번호<br/>
							② 서비스 제공 : 이름, 생년월일, 결혼여부, 혼인일, 부동산 자산 관련 보유, 취득, 임대, 양도 정보<br/>
							③ 수집방법 :  페이지, 모바일 애플리케이션, 서면양식, 팩스, 전화, 상담 게시판, 이메일, 이벤트 응모<br/>
						</span>
						<br/>
						<span className={fontClassTitle}>3. 개인정보의 처리 및 보유 기간</span><br/>
						<span className={fontClassDetails}>
							회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.<br/>
							회사는 위 '개인정보의 수집 및 이용목적'에서 정한 각 수집 및 이용목적이 달성되는 즉시 개인정보를 파기하고, 관련 법령에서 이보다 장기의 기간을 정하는 경우에는 그에 따릅니다.<br/>
						</span>
						<br/>
						<span className={fontClassTitle}>4. 이용자 및 법정대리인의 권리·의무 및 그 행사방법</span><br/>
						<span className={fontClassDetails}>
							① 이용자는 회사에 대해 언제든지 등록되어 있는 자신의 개인정보를 조회, 수정 또는 가입해지를 요청할 수 있습니다.<br/>
							② 제1항에 따른 권리 행사는 회사에 대해 개인정보보호법 시행령 제41조제1항에 따라 서면, 전화, 이메일, FAX 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.<br/>
							③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br/>
							④ 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.<br/>
							⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.<br/>
							⑥ 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.<br/>
						</span>
						<br/>
						<span className={fontClassTitle}>5. 개인정보의 파기절차 및 방법</span><br/>
						<span className={fontClassDetails}>
							회사는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.<br/>
							① 파기절차<br/>
							이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.<br/>
							② 파기기한<br/>
							이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.<br/>
							③ 파기방법<br/>
							전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br/>
						</span>
						<br/>
						<span className={fontClassTitle}>6. 개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항</span><br/>
						<span className={fontClassDetails}>
							회사는 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키’를 사용하지 않습니다.<br/>
						</span>
						<br/>
						<span className={fontClassTitle}>7. 개인정보 보호책임자 작성</span><br/>
						<span className={fontClassDetails}>
							회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br/>
							- 개인정보 보호책임자 : 	이기돌<br/>
							- 연락처 : 010-6423-8351<br/>
							- 이메일 : moveston@naver.com<br/>
							이용자는 회사의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다.<br/>
							회사는 이용자들의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.<br/>
							기타 개인정보 침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br/>
							<br/>
							<table className={
								'forest-list-table w610 bgcWhite ' +
								'th-bdcDDDDDD th-fs14 th-fc222222 th-ls1 th-bdr1 th-bdb1 ' +
								'td-bdcDDDDDD td-fs12 td-fc666666 td-ls1'
							}>							
								<colgroup>
									<col className="w250" />
									<col className="w180" />
									<col className="w180" />
								</colgroup>
								<tbody>
								<tr className="h29">
									<th>기관</th>
									<th>홈페이지</th>
									<th>연락처</th>
								</tr>	
								<tr className="h29">
									<td>개인정보침해신고센터</td>
									<td>privacy.kisa.or.kr</td>
									<td>국번없이 118</td>
								</tr>
								<tr className="h29">
									<td>개인정보분쟁조정위원회</td>
									<td>kopico.go.kr</td>
									<td>1833-6972</td>
								</tr>
								<tr className="h29">
									<td>대검찰청 사이버수사과</td>
									<td>spo.go.kr</td>
									<td>국번없이 1301</td>
								</tr>
								<tr className="h29">
									<td className="bdb1">경찰청 사이버수사국</td>
									<td className="bdb1">police.go.kr</td>
									<td className="bdb1">국번없이 182</td>
								</tr>
								</tbody>
							</table>
						</span>
						<br/>
						<span className={fontClassTitle}>8. 개인정보의 기술적/관리적 보호 대책</span><br/>
						<span className={fontClassDetails}>
							회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 암호화 및 백업 등의 방식으로 안전성 확보에 최선을 다하고 있습니다.<br/>
						</span>
						<br/>
						<span className={fontClassTitle}>9. 고지의 의무</span><br/>
						<span className={fontClassDetails}>
							현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 홈페이지의 공지사항을 통해 고지할 것입니다.<br/>
						</span>
						<br/>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}
export default PersonalInfo;