const OccupancyRight = () => {
    return (
        <div className="w1000 h100p">
            <span className="SpoqaHanSansNeo-Regular fc666666 ls075 fs15 lh20">조합원입주권이란 「도시 및 주거환경정비법」 제74조에 따른 관리처분계획의 인가 및 「빈집 및 소규모주택 정비에 관한 특례법」 제29조에 따른 사업시행계획인가로 인하여 취득한 입주자로 선정된 지위를 말한다.</span>
            <table className="forest-list-table w100p th-fm-SpoqaHanSansNeo-Medium th-fw500 th-fs14 th-fc222222 th-bdcDDDDDD bdt1 bdcDDDDDD td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-p15 mt20 mb20">
                <colgroup>
                    <col className="w180"></col>
                    <col className="w275"></col>
                    <col className="w275"></col>
                    <col className="w275"></col>
                </colgroup>
                <tbody>
                    <tr className="h69">
                        <th>근거법</th>
                        <th>해당사업</th>
                        <th>권리변환시기</th>
                        <th>주택 수 포함 시기</th>
                    </tr>
                    <tr className="h50">
                        <td>「도시 및 주거환경정비법」</td>
                        <td>재건축사업, 재개발사업</td>
                        <td>관리처분계획인가일</td>
                        <td>2006.1.1. 이후 취득 분</td>
                    </tr>
                    <tr className="h50">
                        <td rowSpan={2}>「빈집 및 소규모주택 정비에 관한 특례법」</td>
                        <td>소규모 재건축사업</td>
                        <td rowSpan={2}>사업시행계획인가일</td>
                        <td>2018.2.9. 이후 취득 분</td>
                    </tr>
                    <tr className="h50">
                        <td className="bdb1">자율주택정비사업, 가로주택정비사업,<br/>소규모재개발사업</td>
                        <td className="bdb1">2022.1.1. 이후 취득 분</td>
                    </tr>
                </tbody>
            </table>
            <span className="SpoqaHanSansNeo-Regular fc666666 ls075 fs15 lh20">위의 조합원입주권에 해당되는 경우 주택 수에 포함되며, 이 외의 조합원입주권은 주택 수에 포함되지 아니한다. </span><br /><br />
        </div>
    )
}
export default OccupancyRight;