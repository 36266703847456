/*******************************************************************************
*** Header ** Header
*******************************************************************************/
import React from "react";
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from "recoil";
import * as Atom from "js/common/Atom";
import Util from "js/common/Util";
import AppContext from "js/common/AppContext";
import SubHeader from "./SubHeader";
import { useLocation } from 'react-router-dom';

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const tokenCrtDt = localStorage.getItem("tokenCrtDt");
    const isAdmin = useRecoilValue(Atom.isAdmin);
    const hshldSn = useRecoilValue(Atom.hshldSn);
    const hshldNm = useRecoilValue(Atom.hshldNm);
    const hshldCnt = useRecoilValue(Atom.hshldCnt);
    const setRedirectUrl = useSetRecoilState(Atom.redirectUrl);

    const goLoginPage = () => {
        AppContext.openAlert({
            title: "로그아웃",
            msg: "로그아웃 하시겠습니까?",
            icon: "check",
            confirmText: "로그아웃",
            handleConfirm: () => {
                AppContext.goLogout();
            }
        });
    };

    AppContext.goPage = (url: string) => {
        if (Util.isEmpty(url)) {
            AppContext.openAlert({
                title: "서비스 준비중",
                msg: "서비스 준비 중인 메뉴입니다.",
                icon: "check"
            });
            return;
        }
        if (url.startsWith("/system", 0)) { // 사용자 인증 후 접근 가능
            if (Util.isEmpty(tokenCrtDt)) {
                AppContext.openAlert({
                    title: "알림",
                    msg: "로그인 후 이용 가능합니다.",
                    icon: "warning"
                });
                return;
            }
            if (AppContext.hshldNeedMenuUrl.indexOf(url) !== -1) { // 납세자 선택이 필요한 메뉴를 접근한 경우
                if (Util.isEmpty(hshldSn)) { // 납세자 선택 안되어 있으면
                    if (hshldCnt > 0) {
                        setRedirectUrl(url); // url 셋업 후
                        AppContext.openAlert({
                            title: "납세자 미선택",
                            msg: "납세자 선택이 되어있지 않습니다.\n납세자 관리 페이지로 이동하시겠습니까?",
                            icon: "warning",
                            closeText: "닫기",
                            confirmText: "이동",
                            handleConfirm: () => {
                                navigate("/system/hshld");
                            }
                        });
                        // setHshldSelModalOpen(true); // 납세자 선택 팝업 호출
                        return;
                    } else if (hshldCnt === 0) { // 납세자가 0건이면
                        AppContext.openAlert({
                            title: "알림",
                            msg: "등록된 납세자가 존재하지 않습니다. 납세자 관리 페이지로 이동하시겠습니까?",
                            icon: "warning",
                            confirmText: "이동",
                            handleConfirm: () => {
                                navigate("/system/hshld"); // 납세자 관리 페이지로 이동
                                return;
                            }
                        });
                        return;
                    } else {
                        alert("오류가 발생하였습니다.");
                        return;
                    }
                }
            }
        } else if (url.startsWith("/admin", 0)) { // 관리자 인증 후 접근 가능
            if (Util.isEmpty(tokenCrtDt)) {
                AppContext.openAlert({
                    title: "알림",
                    msg: "로그인 후 이용 가능합니다.",
                    icon: "warning"
                });
                return;
            }
            if (!isAdmin) {
                AppContext.openAlert({
                    title: "알림",
                    msg: "관리자만 이용 가능합니다.",
                    icon: "warning"
                });
                return;
            }
        }
        navigate(url);
    }

    return (
        <div className="w100p mw1210 dpFlx jcC">
            <div className='w1210 bgcWhite'>
                <div className="dpFlx w100p h80 aiC jcSb">
                    <div className="dpFlx aiC">
                        <div className="csP" onClick={() => navigate("/portal/index")}>
                            <span className="ReplicaStdBold fs32 ls16 fc222222">CallTax</span>
                            <span className="ReplicaStdRegular fs15 ls1 fc222222 pl5"></span>
                        </div>
                        <div className="dpFlx h18 pl30">
                            {Util.isEmpty(tokenCrtDt) ?
                                <React.Fragment>
                                    <div className="dpFlx h100p csP pr10" onClick={() => AppContext.goPage("/portal/login")}>
                                        <span className="SpoqaHanSansNeo-Regular fs14 ls07 fc222222">로그인</span>
                                    </div>
                                    <div className="dpFlx h100p csP pr10" onClick={() => AppContext.goPage("/portal/join")}>
                                        <span className="SpoqaHanSansNeo-Regular fs14 ls07 fc222222">회원가입</span>
                                    </div>
                                </React.Fragment> :
                                <React.Fragment>
                                    <div className="dpFlx h100p csP pr10" onClick={() => goLoginPage()}>
                                        <span className="SpoqaHanSansNeo-Regular fs14 ls07 fc222222">로그아웃</span>
                                    </div>
                                    <div className="dpFlx h100p csP pr10" onClick={() => AppContext.goPage("/system/mypage")}>
                                        <span className="SpoqaHanSansNeo-Regular fs14 ls07 fc222222">마이페이지</span>
                                    </div>
                                    <div className="dpFlx h100p aiC">
                                        {Util.isEmpty(hshldSn) ?
                                            // <React.Fragment>
                                            //     <span className="SpoqaHanSansNeo-Regular fs14 ls07 fc222222 tdU tuOs2">납세자 선택하기</span>
                                            //     <img className="w20 h20" src={require('img/portal/new/icon_add.png')} alt="icon_add" />
                                            // </React.Fragment>
                                            <></>:
                                            <React.Fragment>
                                                <span className="SpoqaHanSansNeo-Regular fs14 ls07 fc17A840 pr3">납세자:</span>
                                                <span className="SpoqaHanSansNeo-Regular fs14 ls07 fc222222">{hshldNm}</span>
                                            </React.Fragment>
                                        }
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                        {/* 콜택스 계산기 */}
                        <div className={"dpFlx aiC jcC w110 bdrd40 bdBox bd1 bdc17A840 ml20 p5 h100p csP " + (location.pathname.indexOf("simpletax") > 0 ? "bd2" : "")} onClick={() => {
                            if (Util.isEmpty(tokenCrtDt)) {
                                AppContext.openAlert({
                                    title: "서비스 이용 안내",
                                    msg: "로그인 후 사용 가능한 서비스입니다.\n로그인 페이지로 이동하시겠습니까?",
                                    icon: "warning",
                                    closeText: "닫기",
                                    confirmText: "이동",
                                    handleConfirm: () => {
                                        AppContext.goPage("/portal/login"); // 로그인 페이지로 이동
                                    }
                                });
                                return;
                            } else {
                                AppContext.goPage("/system/simpletax/simpleCallTax"); // 콜택스 계산기 페이지로 이동
                            } 
                        }}>
                            <span className={"SpoqaHanSansNeo-Medium fs16 ls07 " + (location.pathname.indexOf("simpletax") > 0 ? "fc17A840" : "fc222222")}>콜택스 계산기</span>
                        </div>
                    </div>
                    <div className="dpFlx h20">
                        {AppContext.menuList.map((menuInfo, idx) => {
                            if ((menuInfo.role !== "admin" && menuInfo.vsb) || (isAdmin && menuInfo.role === "admin")) {
                                return (
                                    <div key={idx} className={"csP " + (idx > 0 ? "ml30" : "")} onClick={() => AppContext.goPage(menuInfo.url)}>
                                        <span className={"SpoqaHanSansNeo-Medium fs16 ls08 " + (location.pathname === menuInfo.url ? "fc17A840 tdU tuOs2" : Util.isNotEmpty(menuInfo.url) ? "fc222222" : "fcBDBDBD")}>{menuInfo.name}</span>
                                    </div>
                                )
                            } else {
                                return (
                                    <React.Fragment key={idx}></React.Fragment>
                                )
                            }
                        })}
                    </div>
                </div>
                <SubHeader/>
            </div>
        </div>
    )
}
export default Header;