/*******************************************************************************
 *** Join ** 회원가입
*******************************************************************************/
import React            from 'react';
import { useNavigate }  from "react-router-dom";
import JSEncrypt        from 'jsencrypt';
import Axios            from 'js/common/Axios';
import AppContext       from 'js/common/AppContext';
import Modal            from 'js/common/ui/modal/Modal';
import Term             from './help/Term';
import PersonalInfo     from './help/PersonalInfo';
import Util             from 'js/common/Util';
import NumberTextBox    from "js/common/ui/input/NumberTextBox";
import ImageCheckBox    from 'js/common/ui/input/ImageCheckBox';
import { UseCountdown } from 'js/common/UseCountdown';
import TextBox          from 'js/common/ui/input/TextBox';

const Join = () => {
    const navigate = useNavigate();
    const [emailAdres  , setEmailAdres  ] = React.useState<string>(""); // 이메일
    const [userNm      , setUserNm      ] = React.useState<string>(""); // 이름
    const [password    , setPassword    ] = React.useState<string>(""); // 비밀번호
    const [passwordCfm , setPasswordCfm ] = React.useState<string>(""); // 비밀번호 확인
    const [mbtlnum     , setMbtlnum     ] = React.useState<string>(""); // 휴대폰
    const [userId      , setUserId      ] = React.useState<string>(""); // 아이디(ID)
    const [userIdChkMsg, setUserIdChkMsg] = React.useState<string>(""); // 아이디 확인 메시지
    const [userIdChkOk , setUserIdChkOk ] = React.useState<boolean>(false); // 아이디 확인 완료 여부
    const [passwdChkMsg, setPasswdChkMsg] = React.useState<string>(""); // 비밀번호 패턴 확인 메시지

    const [checkTerm1, setCheckTerm1] = React.useState<boolean>(false); // 약관 체크박스
    const [checkTerm2, setCheckTerm2] = React.useState<boolean>(false); // 개인정보취급 체크박스
    const [termsConditionModalOpen, setTermsConditionModalOpen] = React.useState<boolean>(false); // 이용약관 팝업
    const [personalInfoModalOpen  , setPersonalInfoModalOpen  ] = React.useState<boolean>(false); // 개인정보취급 팝업

    const [phoneCertStt   , setPhoneCertStt   ] = React.useState<string>("1001"); // 휴대폰 인증 진행상태(1001(초기)→1002(발송)→1003(완료))
    const [phoneCertNum   , setPhoneCertNum   ] = React.useState<string>(""    ); // 휴대폰 인증번호
    const [phoneCertNumChk, setPhoneCertNumChk] = React.useState<boolean>(false); // 휴대폰 인증여부

    const [phoneCertNumElVsb , setPhoneCertNumElVsb ] = React.useState<boolean>(false);
    const [phoneCertNumChkMsg, setPhoneCertNumChkMsg] = React.useState<string>("");
    const [phoneCertNumCount , setPhoneCertNumCount ] = React.useState<string>("");
    const [phoneCertMin      , phoneCertSec         ] = UseCountdown(phoneCertNumCount, "min", phoneCertNumElVsb);

    // 핸드폰 인증번호 확인
    const chkCertNum = (certTypeCode: string, certRcvNm: string, certNumber: string) => {
        if (Util.isEmpty(certNumber)) {
            if (certTypeCode === "1001") {
                setPhoneCertNumChkMsg("인증번호를 입력하세요.");
            }
            return;
        }
        if (certNumber.length !== 6) {
            if (certTypeCode === "1001") {
                setPhoneCertNumChkMsg("인증번호를 6자리로 입력하세요.");
            }
            return;
        }
        Axios.dataAccess({
            url: "portal/join/chkCertNumber.do",
            methodType: "post",
            paramType: "json",
            paramData: {
                certTypeCode: certTypeCode,
                certRcvNm: certRcvNm,
                certNumber: certNumber
            },
            onSuccessFn: (res: any) => {
                if (res.item !== 1) {
                    if (certTypeCode === "1001") {
                        setPhoneCertNumChkMsg("휴대폰 인증번호가 일치하지 않습니다.");
                    }
                    return;
                }
                AppContext.hideSpinner();
                if (certTypeCode === "1001") {
                    setPhoneCertNumChk(true);
                    setPhoneCertNumChkMsg("휴대폰 인증이 완료되었습니다.");
                    setPhoneCertNumElVsb(false);
                    setPhoneCertStt("1003");
                }
            }
        });
    }

    // 휴대폰 인증번호 발송
    const sendPhoneCertNum = () => {
        if (phoneCertNumChk) {
            AppContext.openAlert({
                title: "휴대폰 인증 완료",
                msg: "휴대폰 인증이 완료된 상태입니다.",
                icon: "check"
            });
            return;
        }
        if (Util.isEmpty(mbtlnum)) {
            AppContext.openAlert({
                title: "휴대폰 번호 미입력",
                msg: "휴대폰 번호를 입력하세요",
                icon: "check"
            });
            return;
        }
        // 휴대폰 패턴 확인(010)
        if (!Util.chkPhonePtn(mbtlnum)) {
            AppContext.openAlert({
                title: "휴대폰 번호 형식",
                msg: "휴대폰 번호 형식이 맞지 않습니다.",
                icon: "check"
            });
            return;
        }

        AppContext.showSpinnerDim();
        setPhoneCertStt("1002");    // 휴대폰 인증 진행상태 : 인증번호 발송(1002)
        Axios.dataAccess({
            url: "portal/join/certNumSendPhone.do",
            methodType: "post",
            paramData: {
                mbtlnum: mbtlnum
            },
            onSuccessFn: (res: any) => {
                AppContext.hideSpinner();
                if (res.item.retCode !== "O") {
                    AppContext.openAlert({
                        title: "휴대폰 인증 불가",
                        msg: res.item.retMsg,
                        icon: "check"
                    });
                    return;
                }
                setPhoneCertNumElVsb(true);
                setPhoneCertNumChkMsg("인증번호를 발송했습니다. (유효시간 5분)\n인증번호가 오지 않으면 입력하신 정보가 정확한지 확인해주세요.");
                const currDateStr = Util.getSpecificDate("-", "sec", 300, "SS", "");
                if (currDateStr === undefined) {
                    return;
                }
                setPhoneCertNumCount(currDateStr);
            }
        });
    }

    // 이용약관 전문보기
    const openTermsConditionDialog = () => {
        setTermsConditionModalOpen(true);
    }

    // 개인정보처리방침 전문보기
    const openPersonalInfoDialog = () => {
        setPersonalInfoModalOpen(true);
    }

    const regUserInfo = () => {
        // 아이디 확인
        if (Util.isEmpty(userId)) {
            AppContext.openAlert({
                title: "아이디 미입력",
                msg: "아이디를 입력하세요.",
                icon: "check"
            });
            return;
        }

        if (!userIdChkOk) {
            AppContext.openAlert({
                title: "아이디 사용 불가",
                msg: "사용 가능한 아이디를 변경하신 후 가입신청 하시기 바랍니다.",
                icon: "check"
            });
            return;
        }

        if (Util.isEmpty(userNm)) {
            AppContext.openAlert({
                title: "이름 미입력",
                msg: "이름을 입력하세요.",
                icon: "check"
            });
            return;
        }

        if (Util.isBlank(userNm)) {
            AppContext.openAlert({
                title: "공백 입력",
                msg: "이름에 공백이 포함되어 있으니 삭제하세요.",
                icon: "check"
            });
            return;
        }

        if (Util.isEmpty(password)) {
            AppContext.openAlert({
                title: "비밀번호 입력",
                msg: "비밀번호를 입력하세요.",
                icon: "check"
            });
            return;
        }
        // 비밀번호 패턴 체크
        if (!Util.chkPasswdPtn(password)) {
            AppContext.openAlert({
                title: "비밀번호 형식",
                msg: "비밀번호 입력형식이 맞지 않습니다.\n영문, 숫자, 특수문자를 포함한 8자 이상, 20자 이하로 입력하세요",
                icon: "check"
            });
            return;
        }
        if (Util.isEmpty(passwordCfm)) {
            AppContext.openAlert({
                title: "비밀번호 확인 입력",
                msg: "비밀번호 확인을 입력하세요.",
                icon: "check"
            });
            return;
        }
        if (password !== passwordCfm) {
            AppContext.openAlert({
                title: "비밀번호 불일치",
                msg: "비밀번호가 일치하지 않습니다.",
                icon: "check"
            });
            return;
        }
        if (!phoneCertNumChk) {
            AppContext.openAlert({
                title: "휴대폰 미인증",
                msg: "휴대폰을 인증하신 후 가입신청 하시기 바랍니다.",
                icon: "check"
            });
            return;
        }

        if (Util.isEmpty(emailAdres)) {
            AppContext.openAlert({
                title: "이메일 주소 미입력",
                msg: "이메일 주소를 입력하세요",
                icon: "check"
            });
            return;
        }

        // 이메일 패턴 확인
        if (!Util.chkEmailPtn(emailAdres)) {
            AppContext.openAlert({
                title: "이메일 주소 확인",
                msg: "이메일 주소 형식이 맞지 않습니다.",
                icon: "check"
            });
            return;
        }

        if (!checkTerm1) {
            AppContext.openAlert({
                title: "이용약관 동의",
                msg: "이용약관에 동의하세요.",
                icon: "check"
            });
            return;
        }
        if (!checkTerm2) {
            AppContext.openAlert({
                title: "개인정보취급방침 동의",
                msg: "개인정보취급방침에 동의하세요.",
                icon: "check"
            });
            return;
        }
        AppContext.openAlert({
            title: "회원가입 신청",
            msg: "회원가입 신청하시겠습니까?",
            icon: "check",
            confirmText: "가입신청",
            handleConfirm: () => {
                setPassword("");
                setPasswordCfm("");
                Axios.dataAccess({
                    url: "portal/join/genRsaKeyTempUserSn.do",
                    methodType: "post",
                    onSuccessFn: (res: any) => {
                        const pubKey = res.item.rsaInfo.pubKeyStr;
                        const encrypt = new JSEncrypt();
                        encrypt.setPublicKey(pubKey);
                        Axios.dataAccess({
                            url: "portal/join/regUserInfo.do",
                            methodType: "post",
                            paramType: "json",
                            paramData: {
                                userSn: res.item.userSn,
                                userId: userId,
                                emailAdres: emailAdres,
                                userNm: userNm.trim(),
                                encPassword: encrypt.encrypt(password),
                                mbtlnum: mbtlnum
                            },
                            onSuccessFn: () => {
                                navigate("/portal/welcome/" + userNm + "/" + userId);
                            }
                        });
                    }
                });
            }
        });
    }

    interface JoinTitleElProps {
        titleText: string
    }
    const JoinTitleEl = ({titleText}: JoinTitleElProps) =>
        <div className='h45 dpFlx aiE pb5 bdBox'>
            <span className="SpoqaHanSansNeo-Bold fs16 ls08 fc222222">{titleText}</span>
        </div>
    ;

    React.useEffect(() => {
        if (phoneCertStt === "1002") {
            if (Number(phoneCertMin) === 0 && Number(phoneCertSec) === 0) {
                setPhoneCertNumElVsb(false);
                setPhoneCertNumChkMsg("");
            }
        }
    }, [phoneCertMin, phoneCertSec, phoneCertStt]);

    // 아이디 중복 확인
    const checkUserId = (userId:string) => {
        let chkReg = /^[a-zA-Z0-9]{4,20}$/; // 4자이상 20자 이하, 영문, 숫자로만 입력 확인 정규식
        let chkUserId = chkReg.test(userId);

        if (chkUserId) {
            Axios.dataAccess({
                url: "portal/join/checkUserId.do",
                methodType: "post",
                paramType: "object",
                paramData: {
                    userId: userId
                },
                onSuccessFn: (res: any) => {
                    if (res.item > 0) {
                        setUserIdChkMsg("중복된 아이디가 존재하여 사용할 수 없습니다.");
                        setUserIdChkOk(false);
                    } else {
                        setUserIdChkMsg("사용 가능한 아이디입니다.");
                        setUserIdChkOk(true);
                    }
                }
            });

        } else {
            setUserIdChkMsg("영문 또는 숫자로만 4자 이상, 20자 이하로 입력하세요");
            setUserIdChkOk(false);
        }
    }

    // 비밀번호 패턴 체크
    const checkPassword = (password:string) => {
        if (Util.chkPasswdPtn(password)) {
            setPasswdChkMsg("");
        } else {
            setPasswdChkMsg("영문, 숫자, 특수문자를 포함한 8자 이상, 20자 이하로 입력하세요");
        }
    }

    return (
        <div className='w100p'>
            <div className='w100p h160 dpFlx aiC jcC'>
                <span className="SpoqaHanSansNeo-Bold fs36 ls1 fcBlack">회원가입</span>
            </div>
            <div className='w100p dpFlx aiC jcC mb100'>
                <div className='w460'>
                    {/* 아이디 */}
                    <JoinTitleEl titleText='아이디' />
                    <div className='dpFlx aiC jcSb'>
                        <TextBox
                            type="text"
                            value={userId}
                            height={60}
                            fontSize={16}
                            fontFamily='SpoqaHanSansNeo-Regular'
                            maxLength={20}
                            placeholder="아이디를 입력하세요."
                            onChangeFunc={(text: string) => {
                                setUserId(text);
                                checkUserId(text);
                            }}
                            inputClassName='bgcWhite ls08'
                            color="#222222"
                            offColor="#999999"
                            className='bd1 pl14 pr20'
                            iconEl={
                                <img
                                    onClick={() => setUserId("")}
                                    className={'csP ' + (Util.isEmpty(userId) || userIdChkOk ? "dpNone" : "")}
                                    src={require('img/common/icon_cancel_fill_18.png')}
                                    alt="icon_cancel_fill_18"
                                />
                            }
                        />
                    </div>
                    <span className={"SpoqaHanSansNeo-Regular fs13 ls065 wsPw " + (Util.isEmpty(userId) || !userIdChkOk ? "fcFF0000" : "fc17A840")}>{userIdChkMsg}</span>

                    {/* 이름 */}
                    <JoinTitleEl titleText='이름' />
                    <TextBox
                        value={userNm}
                        height={60}
                        fontSize={16}
                        fontFamily='SpoqaHanSansNeo-Regular'
                        maxLength={30}
                        placeholder="이름을 입력하세요"
                        onChangeFunc={(text: string) => setUserNm(text)}
                        inputClassName='bgcWhite ls08'
                        color="#222222"
                        offColor="#999999"
                        className='bd1 pl15 pr20'
                        iconEl={
                            <img
                                onClick={() => setUserNm("")}
                                className={'csP ' + (Util.isEmpty(userNm) ? "dpNone" : "")}
                                src={require('img/common/icon_cancel_fill_18.png')}
                                alt="icon_cancel_fill_18"
                            />
                        }
                    />

                    {/* 비밀번호 */}
                    <JoinTitleEl titleText='비밀번호' />
                    <TextBox
                        type="password"
                        value={password}
                        height={60}
                        fontSize={16}
                        fontFamily='Inter-Regular'
                        phFontFamily='SpoqaHanSansNeo-Regular'
                        maxLength={30}
                        placeholder="비밀번호"
                        onChangeFunc={(text: string) => {
                            setPassword(text);
                            checkPassword(text);
                        }}
                        inputClassName='bgcWhite ls08'
                        color="#222222"
                        offColor="#999999"
                        className='bd1 pl15 pr20'
                        iconEl={
                            <img
                                onClick={() => setPassword("")}
                                className={'csP ' + (Util.isEmpty(password) ? "dpNone" : "")}
                                src={require('img/common/icon_cancel_fill_18.png')}
                                alt="icon_cancel_fill_18"
                            />
                        }
                    />
                    <span className="SpoqaHanSansNeo-Regular fs13 ls065 wsPw fcFF0000">{passwdChkMsg}</span>

                    {/* 비밀번호 확인 */}
                    <JoinTitleEl titleText='비밀번호 확인' />
                    <TextBox
                        type="password"
                        value={passwordCfm}
                        height={60}
                        fontSize={16}
                        fontFamily='Inter-Regular'
                        phFontFamily='SpoqaHanSansNeo-Regular'
                        maxLength={30}
                        placeholder="비밀번호 확인"
                        onChangeFunc={(text: string) => setPasswordCfm(text)}
                        inputClassName='bgcWhite ls08'
                        color="#222222"
                        offColor="#999999"
                        className='bd1 pl15 pr20'
                        iconEl={
                            <img
                                onClick={() => setPasswordCfm("")}
                                className={'csP ' + (Util.isEmpty(passwordCfm) ? "dpNone" : "")}
                                src={require('img/common/icon_cancel_fill_18.png')}
                                alt="icon_cancel_fill_18"
                            />
                        }
                    />

                    {/* 휴대폰 번호 */}
                    <JoinTitleEl titleText='휴대폰 번호' />
                    <div className='dpFlx aiC jcSb'>
                        <TextBox
                            type="phone"
                            value={Util.setPhoneNum(mbtlnum)}
                            width={330}
                            height={60}
                            fontSize={16}
                            fontFamily='SpoqaHanSansNeo-Regular'
                            maxLength={30}
                            placeholder="휴대폰번호를 입력하세요."
                            onChangeFunc={(text: string) => setMbtlnum(Util.unHypen(text))}
                            inputClassName='bgcWhite ls08'
                            color="#222222"
                            offColor="#999999"
                            className='bd1 pl14 pr20'
                            mode={phoneCertNumElVsb || phoneCertNumChk ? "disabled" : ""}
                            iconEl={
                                <img
                                    onClick={() => setMbtlnum("")}
                                    className={'csP ' + (Util.isEmpty(mbtlnum) || phoneCertNumElVsb || phoneCertNumChk ? "dpNone" : "")}
                                    src={require('img/common/icon_cancel_fill_18.png')}
                                    alt="icon_cancel_fill_18"
                                />
                            }
                        />
                        <div className='w120 h60 dpFlx aiC jcC bgc17A840 csP' onClick={sendPhoneCertNum}>
                            <span className="SpoqaHanSansNeo-Medium fs16 fcWhite">인증번호 발송</span>
                        </div>
                    </div>
                    <div className={(!phoneCertNumElVsb ? "dpNone" : "")}>
                        <JoinTitleEl titleText='인증번호' />
                        <div className='w100p h60 bd1 bdcDDDDDD ofH fBd-2B2B2B dpFlx aiC jcSb bdBox pl15 pr15 mb5'>
                            <NumberTextBox
                                value={phoneCertNum}
                                onChange={(data: string) => {
                                    setPhoneCertNum(data);
                                    chkCertNum("1001", mbtlnum, data);
                                }}
                                borderClass="bd0 bgcWhite w384 h100p"
                                maxLength={6}
                                inputClass="fs16 SpoqaHanSansNeo-Regular fc222222"
                            />
                            <img
                                onClick={() => setPhoneCertNum("")}
                                className={'mr10 csP ' + (Util.isEmpty(phoneCertNum) ? "dpNone" : "")}
                                src={require('img/common/icon_cancel_fill_18.png')}
                                alt="icon_cancel_fill_18"
                            />
                            <span className="SpoqaHanSansNeo-Regular fs16 ls08 fc222222">{phoneCertMin}:{phoneCertSec}</span>
                        </div>
                    </div>
                    <span className="SpoqaHanSansNeo-Bold fs13 ls065 fc17A840 wsPw">{phoneCertNumChkMsg}</span>

                    {/* 이메일 주소 */}
                    <JoinTitleEl titleText='이메일 주소' />
                    <div className='dpFlx aiC jcSb'>
                        <TextBox
                            type="email"
                            value={emailAdres}
                            // width={330}
                            height={60}
                            fontSize={16}
                            fontFamily='SpoqaHanSansNeo-Regular'
                            maxLength={30}
                            placeholder="이메일주소를 입력하세요."
                            onChangeFunc={(text: string) => setEmailAdres(text)}
                            inputClassName='bgcWhite ls08'
                            color="#222222"
                            offColor="#999999"
                            className='bd1 pl14 pr20'
                            iconEl={
                                <img
                                    onClick={() => setEmailAdres("")}
                                    className={'csP ' + (Util.isEmpty(emailAdres) ? "dpNone" : "")}
                                    src={require('img/common/icon_cancel_fill_18.png')}
                                    alt="icon_cancel_fill_18"
                                />
                            }
                        />
                    </div>
                    <span className="SpoqaHanSansNeo-Regular fs13 ls065 wsPw fc17A840">아이디/비밀번호 찾기 시 사용되오니 정확한 이메일 주소를 입력해 주세요.</span>

                    {/* 이용약관 */}
                    <div className='dpFlx aiC jcSb mt20' onClick={openTermsConditionDialog}>
                        <ImageCheckBox
                            value={checkTerm1}
                            setValue={setCheckTerm1}
                            labelText='이용약관 동의'
                            btnClass='w20 h20'
                            labelClass='SpoqaHanSansNeo-Regular fs14 ls07 fc666666 pl5'
                            disabled={true}
                        />
                        <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2 csP">전문보기</span>
                    </div>

                    {/* 개인정보처리방침 */}
                    <div className='dpFlx aiC jcSb mt10' onClick={openPersonalInfoDialog}>
                        <ImageCheckBox
                            value={checkTerm2}
                            setValue={setCheckTerm2}
                            labelText='개인정보처리방침 동의'
                            btnClass='w20 h20'
                            labelClass='SpoqaHanSansNeo-Regular fs14 ls07 fc666666 pl5'
                            disabled={true}
                        />
                        <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2 csP">전문보기</span>
                    </div>
                    <div className='w100p h62 bgc17A840 mt30 dpFlx aiC jcC csP' onClick={regUserInfo}>
                        <span className="SpoqaHanSansNeo-Bold fs18 ls08 fcWhite">가입하기</span>
                    </div>
                </div>
            </div>
            <Modal modalOpen={termsConditionModalOpen} setModalOpen={setTermsConditionModalOpen} title="이용약관 전문보기" dim={true} visible={true}>
                <div className='h500 ofA'>
                    <Term/>
                    <div className='w100p dpFlx aiC jcC pt40'>
                        <div className='w210 h56 bgcF6F6F6 dpFlx aiC jcC csP' onClick={() => {
                            setCheckTerm1(false);
                            setTermsConditionModalOpen(false);
                        }}>
                            <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>취소</span>
                        </div>
                        <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => {
                            setCheckTerm1(true);
                            setTermsConditionModalOpen(false);
                        }}>
                            <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>동의</span>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal modalOpen={personalInfoModalOpen} setModalOpen={setPersonalInfoModalOpen} title="개인정보취급 전문보기" dim={true} visible={true}>
                <div className='h500 ofA'>
                    <PersonalInfo/>
                    <div className='w100p dpFlx aiC jcC pt40'>
                        <div className='w210 h56 bgcF6F6F6 dpFlx aiC jcC csP' onClick={() => {
                            setCheckTerm2(false);
                            setPersonalInfoModalOpen(false);
                        }}>
                            <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>취소</span>
                        </div>
                        <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => {
                            setCheckTerm2(true);
                            setPersonalInfoModalOpen(false);
                        }}>
                            <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>동의</span>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
export default Join;