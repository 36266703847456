/*******************************************************************************
*** SimAddress ** 간편계산기 주소 검색 모달
*******************************************************************************/
import React from "react";
import { useWizard } from "use-wizard";
import FormReducer from 'js/common/FormReducer';
import SimAddressSearch from "./SimAddressSearch";
import SimAddressDong from "./SimAddressDong";
import SimAddressEtc from "./SimAddressEtc";

function SimAddress({ setModalOpen, callBack, taxFlag }: any) {
    const [step, wizard] = useWizard();
    // const [taxFlag, setTaxFlag] = useState<string>(taxFlag);
    const [form, dispatchForm] = React.useReducer(FormReducer, {
        callBack: callBack,
        addrSrchDataArr: [],
        taxFlag: taxFlag
    });

    const WizardStep = () => {
        switch (step) {
            case 1:
                return <SimAddressSearch {...{ step, wizard, form, dispatchForm }} />;
            case 2:
                return <SimAddressDong {...{ step, wizard, form, dispatchForm }} />;
            case 3:
                return <SimAddressEtc {...{ step, wizard, form, dispatchForm, callBack, setModalOpen }} />;
            default:
                return <>페이지가 존재하지 않습니다. 다시 시도해 주세요.</>;
        }
    }

    return (
        <div className="w475 bdBox">
            <WizardStep />
        </div>
    )
}
export default SimAddress;