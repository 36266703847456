import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import html2canvas from "html2canvas";
import saveAs from "file-saver";

import { MainSectionContainer } from "js/mobile/common/layout";
import Header from "./header";
import CalcResult from "./CalcResult";
import BottomButtonGroup from "./BottomButtonGroup";
import { calculatorPcWebMaxWidth } from "js/common/const";
import Axios from "js/common/Axios";
import AppContext from "js/common/AppContext";
import { ResultCodeName, GiftTaxCalResult, GiftTaxParamProps } from "js/common/types/types";

type Props = {
    calcResult: GiftTaxCalResult | null;
    inputParams: GiftTaxParamProps;
    onClickBack: () => void;
};

function GiftTaxCalcResult({ calcResult, inputParams, onClickBack }: Props) {
    const [calcError] = useState<boolean>(false);
    const [codeNameList  , setCodeNameList  ] = useState<Array<ResultCodeName> | null>(null);
    const [isSavingResult, setIsSavingResult] = useState<boolean>(false);

    const resultCaptureRef = useRef<HTMLDivElement>(null);
    
    const onClickSave = async () => {
        try {
            AppContext.showSpinner();
            setIsSavingResult(true);

            // 저장할 요소가 다시 렌더링 될 때까지 기다림.
            await new Promise((resolve) => setTimeout(resolve, 500));

            // resultCaptureRef 처리
            if (!resultCaptureRef.current) {
                return new Error("저장할 요소가 아직 렌더링 되지 않음.");
            }

            const resultCanvas = await html2canvas(resultCaptureRef.current);
            const resultImage = resultCanvas.toDataURL("image/png");
            saveAs(resultImage, "calltax_result.png");
        } catch (error) {
            console.error("Error capturing and saving images:", error);
        } finally {
            AppContext.hideSpinner();
            setIsSavingResult(false);
        }
    };

    useEffect(() => {
        Axios.dataAccess({
            url: "common/mobile/simpletax/getGiftParamCodeNm.do",
            methodType: "get",
            paramType: "object",
            paramData: {
                ...inputParams,
            },
            onSuccessFn: (res: any) => {
                console.log("codeNameList", res.item);
                setCodeNameList(res.item);
            },
            onFailedFn: () => {
                console.error("%%% 증여세 세액 계산 결과 오류");
            },
        });
    }, [inputParams]);

  return (
    <Body>
      <Header onClickBack={onClickBack} />
      <MainContainer>
        {!isSavingResult && (
            <MainSectionContainer>
                <CalcResult calcResult={calcResult} />
            </MainSectionContainer>
        )}
        {isSavingResult && (
            <HiddenSectionContainer $hidden={!isSavingResult} ref={resultCaptureRef}>
                <Header onClickBack={onClickBack} />
                <CalcResult calcResult={calcResult} />
                <SumInfoContainer>
                    {codeNameList != null && (
                        <>
                        {codeNameList.map((codeName: ResultCodeName) => {
                            return (
                            <div className="info" key={codeName.codeTyNm}>
                                <p className="name">{codeName.codeTyNm}</p>
                                <div className="value">
                                <div>
                                    <span className="number">{codeName.codeNm}</span>
                                    {codeName.unit != null && (
                                        <span className="unit">{codeName.unit}</span>
                                    )}
                                </div>
                                </div>
                            </div>
                            );
                        })}
                        </>
                    )}
                </SumInfoContainer>
                <UrlWrap>calltax.kr</UrlWrap>
          </HiddenSectionContainer>
        )}
        <BottomButtonGroup onClickBack={onClickBack} onClickSave={onClickSave} showSave={!calcError} />
        </MainContainer>
    </Body>
  );
}

const Body = styled.div`
    font-size: ${({ theme }) => theme.fontSize}; /* 1rem = 10px */
    position: relative;
    width: 100dvw;
    height: 100dvh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: ${(props) => props.theme.color.pGreenLight04};

    &::-webkit-scrollbar {
        display: none;
    }

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    p {
        margin: 0;
    }

    li {
        list-style: none;
    }
`;

const MainContainer = styled.main`
    position: relative;
    width: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }

    flex: 1 1 0;

    @media (min-width: 1201px) {
        max-width: ${calculatorPcWebMaxWidth};
    }

    background-color: ${(props) => props.theme.color.bg};
    z-index: 10;
`;

const HiddenSectionContainer = styled.section<{ $hidden: boolean }>`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    /*z-index: 0;*/

    width: 100%;
    height: auto;
    padding: 13px 20px 80px 13px;
    background-color: ${(props) => props.theme.color.bg};

    @media (min-width: 1201px) {
        max-width: ${calculatorPcWebMaxWidth};
    }
`;

const SumInfoContainer = styled.div`
    width: 100%;
    height: auto;
    padding: 12px 20px;
    margin-top: 10px;
    background-color: white;
    border-radius: 12px;

    .info {
        position: relative;
        padding: 14px 0;
        border-bottom: 1px solid ${(props) => props.theme.color.nGray80};

        &:last-child {
            border: none;
        }

        display: flex;
        justify-content: space-between;
        align-items: center;

        .name {
            font-family: "Spoqa Han Sans Neo";
            font-weight: 400;
            font-size: 1.4em;
            line-height: 1.32;
            letter-spacing: -0.05em;
            color: ${(props) => props.theme.color.sNavy};
        }

        .value {
            .number {
                font-family: "Spoqa Han Sans Neo";
                font-weight: 600;
                font-size: 1.6em;
                line-height: 1;
                letter-spacing: -0.03125em;
                color: ${(props) => props.theme.color.pGreenDark01};
            }

            .unit {
                font-family: "Spoqa Han Sans Neo";
                font-weight: 400;
                font-size: 1.2em;
                line-height: 1;
                letter-spacing: -0.058em;
                color: #727b8e;
                margin-left: 2px;
            }
        }
    }
`;

const UrlWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    height: 50px;
    padding: 0px 10px;
    font-family: "Spoqa Han Sans Neo";
    font-weight: 600;
    font-size: 2em;
    line-height: 1;
    letter-spacing: -0.03125em;
    color: ${(props) => props.theme.color.sNavy};
`;

export default GiftTaxCalcResult;