/*******************************************************************************
 *** TransferTaxRate ** 양도세 세율 도움말 Modal * 양도세 결과표에서 호출
*******************************************************************************/
const TransferTaxRate = () => {
    return (
        <div className='w820'>
            <div className="w50p float-l pr10 bdBox">
                <div className='dpFlx jcL aiC mb10'>
                    <span className="SpoqaHanSansNeo-Regular fc505050 ls075 fs16 lh20">
                        ▼ 분양권, 2년 미만 조합원입주권과 2년 미만 주택
                    </span>
                </div>
                    <table className={
                        "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD " +
                        "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 " +
                        "td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls1 td-fc666666 td-bdcDDDDDD td-p15"
                    }>
                    <colgroup>
                        <col className="w100"></col>
                        <col className="w80"></col>
                        <col className="w120"></col>
                        <col className="w80"></col>
                    </colgroup>
                    <tbody>
                        <tr className="h40">
                            <th>구분</th>
                            <th>1년 미만</th>
                            <th>1년 이상 2년 미만</th>
                            <th>2년 이상</th>
                        </tr>
                        <tr className="h40">
                            <td>분양권</td>
                            <td>70%</td>
                            <td colSpan={2}>60%</td>
                        </tr>
                        <tr className="h40">
                            <td>조합원입주권</td>
                            <td>70%</td>
                            <td>60%</td>
                            <td>-</td>
                        </tr>
                        <tr className="h40">
                            <td>주택</td>
                            <td>70%</td>
                            <td>60%</td>
                            <td>-</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="w50p float-r pl30 pr10 bdBox">
                <div className='dpFlx jcL aiC mb10'>
                <span className="SpoqaHanSansNeo-Regular fc505050 ls075 fs16 lh20">
                        ▼ 2년 이상의 조합원입주권과 2년 이상 주택
                    </span>
                </div>
                <table className={
                            "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD " +
                            "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 " +
                            "td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls1 td-fc666666 td-bdcDDDDDD td-p15"
                }>
                    <colgroup>
                        <col className="w200"></col>
                        <col className="w120"></col>
                        <col className="w120"></col>
                    </colgroup>
                    <tbody>
                        <tr className="h40">
                            <th>구분</th>
                            <th>세율</th>
                            <th>누진공제</th>
                        </tr>
                        <tr className="h29"><td>과세표준 1,400만원 이하</td>	<td>6% </td>	<td>-        </td></tr>
                        <tr className="h29"><td>과세표준 5,000만원 이하</td>	<td>15%</td>	<td>126만원  </td></tr>
                        <tr className="h29"><td>과세표준 8,800만원 이하</td>	<td>24%</td>	<td>576만원  </td></tr>
                        <tr className="h29"><td>과세표준 1.5억원 이하  </td>    <td>35%</td>	<td>1,544만원</td></tr>
                        <tr className="h29"><td>과세표준 3억원 이하	   </td>    <td>38%</td>	<td>1,994만원</td></tr>
                        <tr className="h29"><td>과세표준 5억원 이하	   </td>    <td>40%</td>	<td>2,594만원</td></tr>
                        <tr className="h29"><td>과세표준 10억원 이하   </td>    <td>42%</td>	<td>3,594만원</td></tr>
                        <tr className="h29"><td>과세표준 10억원 초과   </td>    <td>45%</td>	<td>6,594만원</td></tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default TransferTaxRate;