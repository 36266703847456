/***********************************************************************************
*** Law19at10 ** 조세특례제한법 98조의 6 준공 후 미분양주택의 취득자에 대한 양도소득세의 과세특례 Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law19at10Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}

export default function Law19at10({ setModalOpen, setLawModalTitle, callBack, callMode }: Law19at10Props) {
    React.useEffect(() => {
        setLawModalTitle("조세특례제한법 98조의 6 준공 후 미분양주택의 취득자에 대한 양도소득세의 과세특례");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className='w100p hAuto ofA mb30'>
                <table className={
                    'forest-list-table bdt1 bdb1 bdcDDDDDD ' +
                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075'
                }>
                   <colgroup>
                        <col className='w80'></col>
                        <col className='w100'></col>
                        <col className='w80'></col>
                        <col className='w120'></col>
                        <col className='w60'></col>
                        <col className='w60'></col>
                        <col className='w80'></col>
                        <col className='w60'></col>
                        <col className='w140'></col>
                        <col className='w100'></col>
                        <col className='w100'></col>
                        <col className='w120'></col>
                        <col className='w140'></col>
                    </colgroup>
                    <tbody>
                        <tr className='h40'>
                            <th rowSpan={2}>구분</th>
                            <th rowSpan={2}>주택구분</th>
                            <th rowSpan={2}>주택규모</th>
                            <th rowSpan={2}>미분양주택<br/>취득자의 취득시기</th>
                            <th colSpan={2}>등록</th>
                            <th rowSpan={2}>가액기준</th>
                            <th colSpan={5}>추가질문</th>
                            <th rowSpan={2}>혜택</th>
                        </tr>
                        <tr className='h29'>
                            <th>지자체</th>
                            <th>세무서</th>
                            <th>최초계약</th>
                            <th>미분양주택<br/>취득자의 임대기간</th>
                            <th>미분양시점</th>
                            <th>입주사실</th>
                            <th className='bdr1-i bdcDDDDDD-i'>필수서류</th>
                        </tr>
                        <tr className='h110'>
                            <th>개별사항</th>
                            <td>분양권에 의한 아파트 중 미분양</td>
                            <td>149㎡ 이하</td>
                            <td>2011.3.29. ~ 2011.12.31.<br/>(계약 및 취득완료)</td>
                            <td colSpan={2}>2011.12.31. 까지 등록</td>
                            <td>취득 당시<br/>기준시가 6억원 이하</td>
                            <td>여</td>
                            <td>2011.12.31. 까지 임대 개시하여 5년 이상</td>
                            <td>2011.3.29. 현재 미분양주택</td>
                            <td>준공 후 입주사실이 없는 주택</td>
                            <td>지자체 확인서 및 지자체 날인 매매계약서</td>
                            <td>5년이내 양도시 : 혜택없음<br/><br/>5년초과 양도시 : 최초 5년간 양도소득금액 50% 과세제외</td>
                        </tr>
                        <tr className='h110'>
                            <th>공통사항</th>
                            <td className='taL pt15 pb15 pl15'colSpan={7}>
                                1. 비과세 판정시 주택수 제외<br/>
                                2. 다주택 중과배제(유예)<br/>
                                3. 비과세 판정시 임대주택을 비임대주택으로 전환한 경우 보유기간은 보유주택으로 변경한 날부터 기산한다.<br/>
                                4. 피상속인의 임대기간을 통산한다.<br/>
                                5. 다주택자 장기보유공제 표1,(1세대1주택자 장기보유공제 표2) 적용<br/>
                                6. 다주택자, 2년미만 보유라도 기본세율 적용<br/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}
