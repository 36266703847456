import { useState, useEffect, Dispatch } from "react";
import { produce } from "immer";

import { PropTaxAction, PropTaxState } from "./propTaxStatesReducer";
import { Step } from "js/common/types/types";
import Util from "js/common/Util";

const stepList: Step[] = [
  {
    stepId: "PROP01",
    name: "기초 정보 입력",
    order: 1,
    useYn: true,
    selected: true,
    subSteps: [],
  },
  {
    stepId: "PROP02",
    name: "부가 정보 입력",
    order: 2,
    useYn: false,
    selected: false,
    subSteps: [],
  },
];

export function usePropTaxCalc(
  propTaxState: PropTaxState,
  onUpdatePropTaxState: Dispatch<PropTaxAction>
) {
  const [propStepList, setPropStepList] = useState<Step[]>(stepList);
  const [backDisabled, setBackDisabled] = useState<boolean>(true);
  const [nextDisabled, setNextDisabled] = useState<boolean>(true);
  //const [isLastStep, setIsLastStep] = useState<boolean>(false);

  const usingStepList = propStepList.filter((step: Step) => step.useYn);
  const currentPropStep = usingStepList.find((step: Step) => step.selected);
  const currentStepIndex = usingStepList.findIndex(
    (step: Step) => step.selected
  );

  const isLastStep =
    usingStepList.length - 1 === currentStepIndex ? true : false;

  useEffect(() => {
    // 단계가 바뀌면 next 버튼 비활성화
    setNextDisabled(true);
  }, [currentPropStep]);

  useEffect(() => {
    if (currentPropStep && currentPropStep.stepId === "PROP01") {
      setBackDisabled(true);
    } else {
      setBackDisabled(false);
    }
  }, [currentPropStep]);

  useEffect(() => {
    // (04 ~ 07)이 visible 이면 부가정보입력 단계 활성화
    const inputVisible = propTaxState.inputVisible;
    if (
      inputVisible.visible04 ||
      inputVisible.visible05 ||
      inputVisible.visible06 ||
      inputVisible.visible07
    ) {
      setPropStepList(
        produce((draft: Step[]) => {
          const step = draft.find((step) => step.stepId === "PROP02");
          if (step) {
            step.useYn = true;
          }
        })
      );
    } else {
      setPropStepList(
        produce((draft: Step[]) => {
          const step = draft.find((step) => step.stepId === "PROP02");
          if (step) {
            step.useYn = false;
          }
        })
      );
    }
  }, [propTaxState.inputVisible]);

  useEffect(() => {
    // 기초정보입력 단계 완료 여부 확인
    if (!currentPropStep || currentPropStep.stepId !== "PROP01") return;

    const inputVisible = propTaxState.inputVisible;
    const inputValue = propTaxState.inputValue;

    let validateYn = true;
    if (inputVisible.visible01) {
      if (Util.isEmpty(inputValue.houseNo)) {
        validateYn = false;
      }

      if (inputVisible.visible02) {
        if (inputValue.housing.length === 0) {
          validateYn = false;
        }
        if (inputValue.houseNo === "202" && inputValue.housing.length === 1) {
          // 2주택 이상
          validateYn = false;
        }
      }
    }

    setNextDisabled(!validateYn);
  }, [propTaxState.inputVisible, propTaxState.inputValue, currentPropStep]);

  useEffect(() => {
    // 부가정보입력 단계 완료 여부 확인
    if (!currentPropStep || currentPropStep.stepId !== "PROP02") return;

    const inputVisible = propTaxState.inputVisible;
    const inputValue = propTaxState.inputValue;

    let validateYn = true;
    if (inputVisible.visible04) {
      if (Util.isEmpty(inputValue.coupleName)) {
        validateYn = false;
      }
    }

    if (inputVisible.visible05) {
      if (Util.isEmpty(inputValue.coupleSpecialCase)) {
        validateYn = false;
      }
    }

    if (inputVisible.visible06) {
      if (Util.isEmpty(inputValue.holdingPeriod)) {
        validateYn = false;
      }
    }

    if (inputVisible.visible07) {
      if (Util.isEmpty(inputValue.ownerAge)) {
        validateYn = false;
      }
    }

    setNextDisabled(!validateYn);
  }, [propTaxState.inputVisible, propTaxState.inputValue, currentPropStep]);

  const handleClickNextStep = () => {
    if (isLastStep) {
      console.info(">>> 마지막 입니다.");
    } else {
      moveToNextStep();
    }
  };

  const moveToNextStep = () => {
    setPropStepList(
      produce((draft: Step[]) => {
        const usingSteps = draft.filter((step) => step.useYn);
        const selectedIndex = usingSteps.findIndex((step) => step.selected);
        if (selectedIndex !== -1 && selectedIndex < usingSteps.length - 1) {
          usingSteps[selectedIndex].selected = false;
          usingSteps[selectedIndex + 1].selected = true;
        }
      })
    );
  };

  const handleClickBackStep = () => {
    if (!currentPropStep) return;

    if (currentPropStep.stepId === "PROP02") {
      // 부가정보입력 단계에서 reset
      onUpdatePropTaxState({
        type: "INIT_EXTRA_INFO",
        payload: "",
      });
      moveToBackStep();
    }
  };

  const moveToBackStep = () => {
    setPropStepList(
      produce((draft: Step[]) => {
        const usingSteps = draft.filter((step) => step.useYn);
        const selectedIndex = usingSteps.findIndex((step) => step.selected);
        if (selectedIndex !== -1 && selectedIndex > 0) {
          usingSteps[selectedIndex].selected = false;
          usingSteps[selectedIndex - 1].selected = true;
        }
      })
    );
  };

  const handleClickReset = () => {
    if (!currentPropStep) return;

    if (currentPropStep.stepId === "PROP01") {
      // 기초정보입력 단계에서 reset
      onUpdatePropTaxState({
        type: "INIT_BASIC_INFO",
        payload: "",
      });
    } else if (currentPropStep.stepId === "PROP02") {
      // 부가정보입력 단계에서 reset
      onUpdatePropTaxState({
        type: "INIT_EXTRA_INFO",
        payload: "",
      });
    }
  };

  const initPropStep = () => {
    setPropStepList(stepList);
    onUpdatePropTaxState({
      type: "INIT_BASIC_INFO",
      payload: "",
    });
  };

  return {
    propStepList,
    usingStepList,
    currentPropStep,
    currentStepIndex,
    backDisabled,
    nextDisabled,
    isLastStep,
    setPropStepList,
    handleClickNextStep,
    handleClickBackStep,
    handleClickReset,
    initPropStep,
  };
}
