import React            from "react";
import AppContext       from "js/common/AppContext";
import Util             from "js/common/Util";
import ToolTip          from "js/common/ui/ToolTip";
import ImageRadioBox    from "js/common/ui/input/ImageRadioBox";
import NumberTextBox    from "js/common/ui/input/NumberTextBox";
import SelectBox        from "js/common/ui/input/SelectBox";
import MuiListFilter    from "js/common/ui/list/MuiListFilter";
import ForestTableStyle from 'js/common/ui/style/ForestStyle';

interface GitTaxCalcProps {
    giftTaxParam: any[]     // 증여자산 배열
    moveStep:Function

    donee         : string
    giftProp      : string
    debtAssum     : string
    debtAmt       : string
    preGift       : string
    preGiftProp   : string
    preGiftTax    : string
    doneeMinor    : string
    marriageDedu  : string
    childbirthDedu: string

    setDonee         : React.Dispatch<React.SetStateAction<string>>
    setGiftProp      : React.Dispatch<React.SetStateAction<string>>
    setDebtAssum     : React.Dispatch<React.SetStateAction<string>>
    setDebtAmt       : React.Dispatch<React.SetStateAction<string>>
    setPreGift       : React.Dispatch<React.SetStateAction<string>>
    setPreGiftProp   : React.Dispatch<React.SetStateAction<string>>
    setPreGiftTax    : React.Dispatch<React.SetStateAction<string>>
    setDoneeMinor    : React.Dispatch<React.SetStateAction<string>>
    setMarriageDedu  : React.Dispatch<React.SetStateAction<string>>
    setChildbirthDedu: React.Dispatch<React.SetStateAction<string>>    
}

const GiftInfoIn = ({ 
      giftTaxParam
    , moveStep

    , donee         
    , giftProp      
    , debtAssum     
    , debtAmt       
    , preGift       
    , preGiftProp   
    , preGiftTax    
    , doneeMinor    
    , marriageDedu  
    , childbirthDedu

    , setDonee         
    , setGiftProp      
    , setDebtAssum     
    , setDebtAmt       
    , setPreGift       
    , setPreGiftProp   
    , setPreGiftTax    
    , setDoneeMinor    
    , setMarriageDedu  
    , setChildbirthDedu
}: GitTaxCalcProps) => {
    // 입력항목 표시 여부
    const [visible06, setVisible06] = React.useState<boolean>(false); // 06. 사전증여 재산가액
    const [visible07, setVisible07] = React.useState<boolean>(false); // 07. 사전증여 세액
    const [visible08, setVisible08] = React.useState<boolean>(false); // 08. 수증자 미성년자 여부
    const [visible09, setVisible09] = React.useState<boolean>(false); // 09. 혼인공제
    const [visible10, setVisible10] = React.useState<boolean>(false); // 10. 출산공제

    // 입력항목 disabled
    const [disabled04, setDisabled04] = React.useState<boolean>(false); // 04. 채무액


    // 증여 자산 시가인정액 정보
    interface giftAssetsInfoProps {
        asset_sn    : number    // 자산일련번호
        market_price: number    // 시가인정액
    }

    // 증여 자산 시가인정액 배열
    const [giftAssetsInfo, setGiftAssetsInfo] = React.useState<giftAssetsInfoProps[]>([]); 

    // 증여 자산정보의 시가인정액 저장용 배열 생성
    React.useEffect(() => {
        const tempGiftAssetsInfo = [] as giftAssetsInfoProps[];
        giftTaxParam.forEach((item: any) => {
            tempGiftAssetsInfo.push({
                  asset_sn    : item.assetsSn
                , market_price: 0
            })
        });
        setGiftAssetsInfo(tempGiftAssetsInfo);
    }, [giftTaxParam]);

    // 시가인정액 입력 값 변경 처리
    const bindGiftAssetsMarkerPrice = (assetsSn: number, pblntfPc: string) => {
        const tempGiftAssetsInfo = [] as giftAssetsInfoProps[];  // 임시 배열 생성
        giftAssetsInfo.forEach((giftAssetsInfo: giftAssetsInfoProps) => {
            if (giftAssetsInfo.asset_sn === assetsSn) {
                tempGiftAssetsInfo.push({
                    asset_sn    : assetsSn,
                    market_price: Number(Util.uncomma(pblntfPc))
                });
            } else {
                tempGiftAssetsInfo.push(giftAssetsInfo);
            }
            
        });
        setGiftAssetsInfo(tempGiftAssetsInfo);
    }

    // 증여 자산정보가 변경될 때마다 시가인정액 합계 계산
    React.useEffect(() => {
        let totalMarketPrice = 0;
        giftAssetsInfo.forEach((giftAssetsInfo:giftAssetsInfoProps) => {
            totalMarketPrice += giftAssetsInfo.market_price; 
        });
        setGiftProp(totalMarketPrice.toString());
    }, [giftAssetsInfo, setGiftProp]);

    // 증여세 계산하기 
    const selGittaxCalc = () => {
        // 시가인정액 입력 여부 확인
        let chkValFlag = 0;
        if (giftAssetsInfo.length === 0) {
            chkValFlag = 1;
        } else {
            giftAssetsInfo.forEach((assetsPblntfPc: giftAssetsInfoProps) => {
                if (Util.isEmpty(assetsPblntfPc.market_price) || assetsPblntfPc.market_price === 0) {
                    chkValFlag++;
                }
            });
        }
        if (chkValFlag > 0) {
            AppContext.openAlert({
                title: "증여자산 시가인정액 미입력",
                msg: "증여 자산의 시가인정액을 입력하세요.",
                icon: "check"
            });
            return;
        }    

        // 입력 값 확인 메시지 처리
        if (Util.isEmpty(donee    )) {AppContext.openAlert({title: "수증자(증여받는 사람)", msg: "수증자를 선택하세요."       , icon: "check"}); return;}
        if (Util.isEmpty(giftProp )) {AppContext.openAlert({title: "증여 재산가액"        , msg: "증여 재산가액을 입력하세요.", icon: "check"}); return;}
        if (Number(giftProp) <= 0  ) {AppContext.openAlert({title: "증여 재산가액"        , msg: "증여 재산가액을 입력하세요.", icon: "check"}); return;}
        if (Util.isEmpty(debtAssum)) {AppContext.openAlert({title: "채무인수 여부"        , msg: "채무인수 여부를 선택하세요.", icon: "check"}); return;}
        if (debtAssum === "Y") {
            if (Util.isEmpty(debtAmt)) {AppContext.openAlert({title: "채무액", msg: "채무액을 입력하세요.", icon: "check"}); return;}
            if (Number(debtAmt) <= 0 ) {AppContext.openAlert({title: "채무액", msg: "채무액을 입력하세요.", icon: "check"}); return;}
        }
        if (Util.isEmpty(preGift)) {AppContext.openAlert({title: "증여자 사전증여 유무", msg: "증여자 사전증여 유무를 선택하세요.", icon: "check"}); return;}
        if (visible06) {
            if (Util.isEmpty(preGiftProp)) {AppContext.openAlert({title: "사전증여 재산가액", msg: "사전증여 재산가액을 입력하세요.", icon: "check"}); return;}
            if (Number(preGiftProp) <= 0 ) {AppContext.openAlert({title: "사전증여 재산가액", msg: "사전증여 재산가액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible07) {
            if (Util.isEmpty(preGiftTax)) {AppContext.openAlert({title: "사전증여 세액", msg: "사전증여 세액을 입력하세요.", icon: "check"}); return;}
            if (Number(preGiftTax) <  0 ) {AppContext.openAlert({title: "사전증여 세액", msg: "사전증여 세액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible08) {if (Util.isEmpty(doneeMinor    )) {AppContext.openAlert({title: "수증자 미성년자 여부", msg: "수증자 미성년자 여부를 선택하세요.", icon: "check"}); return;}}
        if (visible09) {if (Util.isEmpty(marriageDedu  )) {AppContext.openAlert({title: "혼인공제"            , msg: "혼인공제를 선택하세요."            , icon: "check"}); return;}}
        if (visible10) {if (Util.isEmpty(childbirthDedu)) {AppContext.openAlert({title: "출산공제"            , msg: "출산공제를 선택하세요."            , icon: "check"}); return;}}

        moveStep(2);
    }

    return (

        <div className='w850 mh500'>
            <div className="mt20 mb10">
                <div className="h20 mb10 dpFlx aiC">
                    <span className="SpoqaHanSansNeo-Medium fs18 ls09 fc222222">증여 자산 정보</span>
                </div>
                <MuiListFilter tableInfo={{
                    styleType: ForestTableStyle,
                    fldInfs: [
                        { type: 'code', fldNm: 'estateDetailSe', width: 100 , headAlign: "center", dataAlign: "center", label: '자산구분', codeTy: "1006"},
                        { type: 'text', fldNm: 'fullRdnmAdres' , wAuto: true, headAlign: "center", dataAlign: "left"  , label: '주소'    },
                        { type: 'text', fldNm: 'acqsDe'        , width: 120 , headAlign: "center", dataAlign: "center", label: '취득일'  },
                        { type: 'el'                           , width: 180 , headAlign: "center"                     , label: '시가인정액', el: (item: any) =>
                            <NumberTextBox
                                value={item.price}
                                onChange={(data: string) => bindGiftAssetsMarkerPrice(item.assetsSn, data)}
                                borderClass="bgcWhite w100p h35 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                                unitText="원"
                            />
                        }
                    ],
                    ordFld: { name: "rowNum", direction: "asc" },
                    dataArr: giftTaxParam,
                    maxRowNums: [100],
                    trHeight: 40,
                    remainArea: false,
                    paginateClass: "dpNone-i",
                    tableHeadVsb: true
                }}/>
            </div>

            <div className="h20 mt30 dpFlx aiC">
                <span className="SpoqaHanSansNeo-Medium fs18 ls09 fc222222">증여세 산출 정보 입력</span>
            </div>

            {/************************** 주소 하단 왼쪽 부분 **************************/}    
            <div className="w50p float-l pr10 bdBox pl10">  
                {/* 01. 수증자 */}
                <div className={(true ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt20'}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>수증자</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={donee}
                            codeArray={Util.getCodeArray("1070")}
                            placeholder="선택해주세요"
                            width={230}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            // disabled={disabled01}
                            border="1px solid #dddddd"
                            onChangeFunc={(data: string) => {
                                setDonee(data);

                                // 수증자 미성년자 여부 표시
                                if (data === "1003") {  // 직계비속(수증자) ▶ 08.수증자 미성년자 여부
                                    setVisible08(true );
                                } else {   
                                    setVisible08(false  ); // 08.수증자 미성년자 여부 미표시
                                    setDoneeMinor(""    ); // 08.수증자 미성년자 여부 초기화
                                    setVisible09(false  ); // 09.혼인공제 미표시
                                    setMarriageDedu(""  ); // 09.혼인공제 초기화
                                    setVisible10(false  ); // 10.출산공제 미표시
                                    setChildbirthDedu(""); // 10.출산공제 초기화
                                }
                            }}
                        />
                    </div>
                </div>

                {/* 03. 채무인수 여부 */}
                <div className={(true ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt10'}>
                    <div className="w150 h40  bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>채무인수 여부</span>
                    </div>
                    <div className='dpFlx aiC jcL ml10 pl20 w230'>
                        <ImageRadioBox
                            codeList={Util.getCodeArray("1071")}
                            value={debtAssum}
                            btnClass="w24 h24 pr5"
                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                            interval={20}
                            onChange={(data: string) => {
                                setDebtAssum(data);

                                if (data === "Y") {           // 인수함 ▶ 04.채무액, 05.증여자 사전증여 유무
                                    setDisabled04(false);
                                } else if (data === "N") {    // 인수하지 않음 ▶ 05.증여자 사전증여 유무
                                    setDisabled04(true); // 04.채무액 수정불가
                                    setDebtAmt  (""   ); // 04.채무액 초기화
                                } else {}                                
                            }}></ImageRadioBox>
                    </div>
                </div>  

                {/* 05. 증여자 사전증여 유무 */}
                <div className={(true ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt10'}>
                    <div className="w150 h40  bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>
                            <div className="dpFlx aiC jcC">증여자 사전증여 유무
                                <img
                                    src={require('img/common/icon_info_fill_20.png')}
                                    alt="icon_info_fill_20"
                                    className='mr3 csP'
                                    data-tooltip-id="preGiftToolTip"
                                    data-tooltip-content={
                                        "10년 이내 동일한 증여자로부터 받은 사전증여"
                                    }
                                />
                                <ToolTip id="preGiftToolTip" place='top' clickable={true} className="w300 taL lh20" />
                            </div>                             
                        </span>
                    </div>
                    <div className='dpFlx aiC jcL ml10 pl20 w230'>
                        <ImageRadioBox
                            codeList={Util.getCodeArray("1073")}
                            value={preGift}
                            btnClass="w24 h24 pr5"
                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                            interval={20}
                            onChange={(data: string) => {
                                setPreGift(data);
                                if (data === "Y") {         // 증여자 사전증여 유 ▶ 06.사전증여 재산가액, 07.사전증여 세액 
                                    setVisible06(true);
                                    setVisible07(true); 
                                } else if (data === "N") {
                                    setVisible06(false); // 06.사전증여 재산가액 미표시
                                    setPreGiftProp("" ); // 06.사전증여 재산가액 초기화
                                    setVisible07(false); // 07.사전증여 세액 미표시
                                    setPreGiftTax(""  ); // 07.사전증여 세액 초기화
                                } else {}
                            }}></ImageRadioBox>
                        
                    </div>
                </div>

                {/* 06. 사전증여 재산가액 */}
                <div className={(visible06 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w150 h40  bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>사전증여 재산가액</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={preGiftProp}
                            onChange={(data: string) => setPreGiftProp(data)}
                            borderClass="bgcWhite w230 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div>  

                {/* 07. 사전증여세액 */}
                <div className={(visible07 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w150 h40  bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>사전증여세액</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={preGiftTax}
                            onChange={(data: string) => setPreGiftTax(data)}
                            borderClass="bgcWhite w230 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div>                    
            </div>  

            {/************************** 주소 하단 오른쪽 부분 **************************/}
            <div className="w50p float-r pl20 bdBox">
                {/* 02. 증여 재산가액 */}
                <div className={(true ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt20"}>
                    <div className="w150 h40  bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>증여 재산가액</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={giftProp}
                            onChange={(data: string) => setGiftProp(data)}
                            borderClass="bgcWhite w230 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            disabled={true}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div> 

                {/* 04. 채무액 */}
                <div className={(true ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w150 h40  bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>채무액</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={debtAmt}
                            onChange={(data: string) => setDebtAmt(data)}
                            borderClass="bgcWhite w230 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            disabled={disabled04}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div> 

                {/* 08. 수증자 미성년자 여부 */}
                <div className={(visible08 ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt10'}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>
                            <div className="dpFlx aiC jcC">수증자 미성년자 여부
                                <img
                                    src={require('img/common/icon_info_fill_20.png')}
                                    alt="icon_info_fill_20"
                                    className='mr3 csP'
                                    data-tooltip-id="doneeMinorToolTip"
                                    data-tooltip-content={
                                        "증여를 받는 사람이 증여일 현재 만19세 미만"
                                    }
                                />
                                <ToolTip id="doneeMinorToolTip" place='top' clickable={true} className="w300 taL lh20" />
                            </div>  
                        </span>
                    </div>
                    <div className='dpFlx aiC jcL ml10 pl20 w230'>
                        <ImageRadioBox
                            codeList={Util.getCodeArray("1072")}
                            value={doneeMinor}
                            btnClass="w24 h24 pr5"
                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                            interval={20}
                            onChange={(data: string) => {
                                setDoneeMinor(data);
                                if (data === "1001") {             // 미성년자 ▶ 계산하기
                                    setVisible09(false  ); // 09.혼인공제 미표시
                                    setMarriageDedu(""  ); // 09.혼인공제 초기화
                                    setVisible10(false  ); // 10.출산공제 미표시
                                    setChildbirthDedu(""); // 10.출산공제 초기화
                                } else if (data === "1002") {      // 성인 ▶ 09.혼인공제, 10.출산공제
                                    setVisible09(true);
                                    setVisible10(true);
                                } else {}                                
                            }}></ImageRadioBox>
                    </div>
                </div>  

                {/* 09. 혼인공제 */}
                <div className={(visible09 ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt10'}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>
                            <div className="dpFlx aiC jcC">혼인공제
                                <img
                                    src={require('img/common/icon_info_fill_20.png')}
                                    alt="icon_info_fill_20"
                                    className='mr3 csP'
                                    data-tooltip-id="marriageDeductionToolTip"
                                    data-tooltip-content={
                                        "혼인신고일 이전 2년 ~ 이후 2년"
                                    }
                                />
                                <ToolTip id="marriageDeductionToolTip" place='top' clickable={true} className="w300 taL lh20" />
                            </div>                            
                        </span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={marriageDedu}
                            codeArray={Util.getCodeArray("1074")}
                            placeholder="선택해주세요"
                            width={230}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            // disabled={disabled01}
                            border="1px solid #dddddd"
                            onChangeFunc={(data: string) => {
                                setMarriageDedu(data);
                            }}
                        />
                    </div>
                </div>  

                {/* 10. 출산공제 */}
                <div className={(visible10 ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt10'}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>
                            <div className="dpFlx aiC jcC">출산공제
                                <img
                                    src={require('img/common/icon_info_fill_20.png')}
                                    alt="icon_info_fill_20"
                                    className='mr3 csP'
                                    data-tooltip-id="childbirthDeductionToolTip"
                                    data-tooltip-content={
                                        "자녀 출생일로부터 2년 이내"
                                    }
                                />
                                <ToolTip id="childbirthDeductionToolTip" place='top' clickable={true} className="w300 taL lh20" />
                            </div>                        
                        </span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={childbirthDedu}
                            codeArray={Util.getCodeArray("1074")}
                            placeholder="선택해주세요"
                            width={230}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            // disabled={disabled01}
                            border="1px solid #dddddd"
                            onChangeFunc={(data: string) => {
                                setChildbirthDedu(data);
                            }}
                        />
                    </div>
                </div> 

            </div>                

            <div className="dpFlx w100p jcC pt40">
                <div className="w210 csP dpFlx aiC jcC bgcF6F6F6 h56" onClick={() => moveStep(0)}>
                    <span className="SpoqaHanSansNeo-Medium fs16 fc222222">취소</span>
                </div>
                <div className="w210 bgc17A840 csP dpFlx aiC jcC h56" onClick={() => {
                    selGittaxCalc();
                }}>
                    <span className="SpoqaHanSansNeo-Medium fs16 fcWhite">증여세 계산하기</span>
                </div>
            </div>   

        </div>
    )
}
export default GiftInfoIn;