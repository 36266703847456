import React from "react";
import { useNavigate } from "react-router-dom";
import { IWizard } from "use-wizard/lib/cjs/useWizard/types/IWizard";
import { TStep } from "use-wizard/lib/cjs/useWizard/types/TStep";
import AssetsShow from "../show/AssetsShow";
import AppContext from "js/common/AppContext";
import Axios from "js/common/Axios";
import AssetsButton from "./ui/AssetsButton";
// import AssetsButtonBar from "./ui/AssetsButtonBar";
import { toast } from 'react-toastify';
import AssetsEditTab from "./ui/AssetsEditTab";

const AssetsPreView = (props: {
    step: TStep;
    wizard: IWizard;
    form: any;
    dispatchForm: Function;
}) => {
    const navigate = useNavigate();
    const [estateDetailSe, setEstateDetailSe] = React.useState<string>(""); // 자산상세구분
    const [acqsCause, setAcqsCause] = React.useState<string>(""); // 취득원인
    const [acqsSrc, setAcqsSrc] = React.useState<string>(""); // 취득구분

    const goPrevStep = () => {
        AppContext.showSpinner();

        if (props.form.estateSttus === "1002") {
            if (estateDetailSe === "1001") {
                props.wizard.goToStep(1006);
            } else if (estateDetailSe === "1002") {
                if (["1003"].indexOf(acqsCause) !== -1) { // 상속
                    props.wizard.goToStep(1006);
                } else {
                    props.wizard.goToStep(1002);
                }
            } else if (estateDetailSe === "1003") {
                if (["1001", "1003"].indexOf(acqsCause) !== -1) { // 원조합원, 상속
                    props.wizard.goToStep(1006);
                } else {
                    props.wizard.goToStep(1002);
                }
            }
        } else if (props.form.estateSttus === "1003") {
            props.wizard.goToStep(1006);
        }
    }

    const goFinish = () => {
        AppContext.openAlert({
            title: "자산정보 확정",
            msg: "자산 정보를 확정하시겠습니까?",
            icon: "check",
            confirmText: "확정",
            handleConfirm: () => {
                AppContext.showSpinner();
                Axios.dataAccess({
                    url: "system/assets/confirmAssetsInfo.do",
                    methodType: "post",
                    paramData: {
                        assetsSn: props.form.assetsSn
                    },
                    onSuccessFn: () => {
                        goAssetsList();
                    }
                });
            }
        });
    }

    const goAssetsList = () => {
        if (props.form.callPage === "Assets") {
            toast(
                <span className="fs14 ls07 fw500">
                    <span className="fcWhite SpoqaHanSansNeo-Medium">보유자산등록 완료 후 자산 관리를 위해</span>
                    <span className="fc17A840 SpoqaHanSansNeo-Bold">카테고리(취득관리, 보유관리, 처분관리)</span>
                    <span className="fcWhite SpoqaHanSansNeo-Medium">를 선택하여 작업을 진행해주세요.</span>
                </span>
            );
            navigate('/system/assets');
        } else if (props.form.callPage === "Acqstax") {
            toast(
                <span className="fs14 ls07 fw500">
                    <span className="fcWhite SpoqaHanSansNeo-Medium">등록한 취득자산의 </span>
                    <span className="fc17A840 SpoqaHanSansNeo-Bold">계산 버튼을 클릭</span>
                    <span className="fcWhite SpoqaHanSansNeo-Medium">하여 </span>
                    <span className="fc17A840 SpoqaHanSansNeo-Bold">세액계산</span>
                    <span className="fcWhite SpoqaHanSansNeo-Medium">을 실행할 수 있습니다.</span>
                </span>
            );
            navigate('/system/acqstax');
        } else if (props.form.callPage === "TrsftaxTest") {
            navigate('/system/trsftax/test');
        }
    }

    React.useEffect(() => {
        props.form.setCurrStepCode(1009);
        Axios.dataAccess({
            url: "system/assets/selAssetsEstateDetail.do",
            methodType: "post",
            paramData: {
                assetsSn: props.form.assetsSn
            },
            onSuccessFn: (res: any) => {
                setEstateDetailSe(res.item.estateDetailSe);
                setAcqsCause(res.item.acqsCause);
                setAcqsSrc(res.item.acqsSrc);
            }
        });
    }, [props.form]);

    return (
        <React.Fragment>
            <AssetsEditTab
                wizard={props.wizard}
                assetsSn={props.form.assetsSn}
                estateSttus={props.form.estateSttus}
                estateDetailSe={estateDetailSe}
                acqsCause={acqsCause}
                acqsSrc={acqsSrc}
                currStepCode={1009}
            />
            <div className='w1210 mh500 mt60'>
                <AssetsShow assetsSn={Number(props.form.assetsSn)} />
                <AssetsButton propsForm={props.form} goPrevStep={goPrevStep} goFinish={goFinish} />
            </div>
            {/* <AssetsButtonBar propsForm={props.form} goPrevStep={goPrevStep} goFinish={goFinish} /> */}
        </React.Fragment>
    )
}
export default AssetsPreView;