import React from 'react';
import { useNavigate } from 'react-router-dom';
import Axios from 'js/common/Axios';
import MuiListFilter from 'js/common/ui/list/MuiListFilter';
import ForestTableStyle from 'js/common/ui/style/ForestStyle';
import AppContext from 'js/common/AppContext';
import HshldDialog from 'js/system/hshld/dialog/HshldDialog';
import Modal from 'js/common/ui/modal/Modal';
import { useSetRecoilState, useRecoilState } from "recoil";
import * as Atom from "js/common/Atom";

function Hshld() {
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [callMode, setCallMode] = React.useState<string>("");
    const [hshldSn, setHshldSn] = React.useState<number>(-1);
    const [recoilHshldSn, setRecoilHshldSn] = useRecoilState(Atom.hshldSn);
    const setRecoilHshldNm = useSetRecoilState(Atom.hshldNm);
    const [dataArr, setDataArr] = React.useState<any[]>([]);
    const [hshldSelTime, setHshldSelTime] = React.useState<Date>(new Date());

    const openHshldDialogPage = (callMode: string, hshldSn: number) => {
        setModalOpen(true);
        setCallMode(callMode);
        setHshldSn(hshldSn);
    };

    // 납세자 삭제
    const delHshldInfo = (hshldSn: number) => {
        Axios.dataAccess({
            url: "system/hshld/selHshldRmstnfamCnt.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                hshldSn: hshldSn
            },
            onSuccessFn: (res: any) => {
                let warnMsg = "";
                if (res.item > 1) {
                    warnMsg = "추가된 세대원이 " + (res.item - 1) + "건 존재합니다. 삭제하시겠습니까?";
                } else {
                    warnMsg = "납세자 정보를 삭제하시겠습니까?";
                }
                AppContext.openAlert({
                    title: "납세자 삭제",
                    msg: warnMsg,
                    icon: "check",
                    confirmText: "삭제",
                    handleConfirm: () => {
                        AppContext.showSpinner();
                        Axios.dataAccess({
                            url: "system/hshld/delHshldInfo.do",
                            methodType: "post",
                            paramType: "object",
                            paramData: {
                                hshldSn: hshldSn
                            },
                            onSuccessFn: () => {
                                // 납세자 삭제 시 로컬 스토리지에 존재하는 납세자랑 동일하면 정보 초기화
                                if (hshldSn === recoilHshldSn) {
                                    localStorage.removeItem('hshldSn');
                                    localStorage.removeItem('hshldNm');
                                    setRecoilHshldSn(null);
                                    setRecoilHshldNm(null);
                                }
                                AppContext.hideSpinner();
                                setHshldSelTime(new Date());
                            }
                        });
                    }
                });
            }
        });
    };

    // 납세자명 클릭 이벤트
    const hshldNmClickFunc = (hshldSn: number, hshldNm: string) => {
        localStorage.setItem('hshldSn', hshldSn.toString());
        localStorage.setItem('hshldNm', hshldNm);
        setRecoilHshldSn(hshldSn);
        setRecoilHshldNm(hshldNm);
        navigate('/system/assets'); // 자산목록으로 이동
    };

    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/hshld/selHshldList.do",
            methodType: "post",
            paramType: "object",
            onSuccessFn: (res: any) => {
                setDataArr(res.list);
                AppContext.hideSpinner();
            }
        });
    }, [hshldSelTime]);

    return (
        <React.Fragment>
            <div className="w100p bgcF6F6F6">
                <div className="dpFlx flxdCol jcC aiC pt60 pb30">
                    <div className='taC pb30'>
                        <span className="dpBlock fcBlack fs38 ls266 SpoqaHanSansNeo-Medium">똑똑한 자산관리의 시작</span>
                    </div>
                    <div className="w200 h40 bgc1EA65B bdrd40 dpFlx jcC aiC csP" onClick={() => openHshldDialogPage("reg", 0)}>
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>고객/납세자 등록하기</span>
                        <img className="pl5" src={require('img/common/icon_add_white_20.png')} alt="icon_add_white_20" />
                    </div>
                </div>
                <div className="dpFlx jcC pt30 pb110">
                    <div className='w1200'>
                        <div className='h36 dpFlx aiE pb20'>
                            <img className="mr10" src={require('img/common/icon_step.png')} alt="icon_step" />
                            <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 pb3'>고객/납세자 목록</span>
                        </div>
                        <MuiListFilter tableInfo={{
                            tableHeadVsb: true,
                            styleType: ForestTableStyle,
                            fldInfs: [
                                { type: 'text', fldNm: 'rowNum', width: 58, headAlign: "center", dataAlign: "center", label: 'No.' },
                                { type: 'text', fldNm: 'hshldNm', width: 170, headAlign: "center", dataAlign: "center", label: '고객/납세자명', class: "tdU tuOs2 csP", func: (item: any) => {
                                    hshldNmClickFunc(item.hshldSn, item.hshldNm);
                                }},
                                { type: 'text', fldNm: 'rmstnfamNms', width: 301, headAlign: "center", dataAlign: "center", label: '세대구성현황', dfltVal: "-" },
                                { type: 'text', fldNm: 'assetsStats', wAuto: true, headAlign: "center", dataAlign: "center", label: '자산등록현황', dfltVal: "-" },
                                { type: 'el', width: 74, headAlign: "center", el: (item: any) => (
                                    <div className='dpFlx aiC csP' onClick={() => openHshldDialogPage("mod", item.hshldSn)}>
                                        <img className="csP pr3" src={require('img/common/icon_edit20.png')} alt="icon_edit20" />
                                        <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">수정</span>
                                    </div>
                                )},
                                { type: 'el', width: 74, headAlign: "center", el: (item: any) => (
                                    <div className='dpFlx aiC csP' onClick={() => delHshldInfo(item.hshldSn)}>
                                        <img className="csP pr3" src={require('img/common/icon_delete20.png')} alt="icon_delete20" />
                                        <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">삭제</span>
                                    </div>
                                )}
                            ],
                            ordFld: { name: "rowNum", direction: "asc" },
                            dataArr: dataArr,
                            maxRowNums: [10, 20, 30],
                            trHeight: 48,
                            remainArea: false,
                            srchVsb: true,
                            headerVsb: true,
                            paginateClass: "pt40",
                            numbering: true
                        }}/>
                    </div>
                </div>
            </div>
            <Modal modalOpen={modalOpen} setModalOpen={setModalOpen} title={"고객/납세자 " + (callMode === "reg" ? "추가" : "수정")} dim={true} visible={true}>
                <HshldDialog setModalOpen={setModalOpen} setHshldSelTime={setHshldSelTime} callMode={callMode} hshldSn={hshldSn} />
            </Modal>
        </React.Fragment>
    )
};
export default Hshld;