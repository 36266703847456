import { useState, useEffect, useReducer } from "react";
import { useSetRecoilState } from "recoil";

import { addrSearchResultState } from "js/common/Atom";
import { Body, MainContainer } from "js/mobile/common/layout";
import Header from "js/mobile/common/CalcHeader";
import CalcStep from "js/mobile/common/CalcStep";
import CalcStepButton from "js/mobile/common/CalcStepButton";
import { propTaxStatesReducer, initialstate } from "./propTaxStatesReducer";
import { usePropTaxCalc } from "./usePropTaxCalc";
import BasicInfo from "./BasicInfo";
import ExtraInfo from "./ExtraInfo";
import Axios from "js/common/Axios";
import {
  AddrSearchResult,
  PropTaxCalcRsltParam,
  PropTaxParamProps,
} from "js/common/types/types";
import PropTaxCalcResult from "js/mobile/PropTaxCalcResult";
import Util from "js/common/Util";
import AppContext from "js/common/AppContext";

function PropTaxCalc() {
  const setAddrSearchResult = useSetRecoilState(addrSearchResultState);
  const [propTaxState, dispatch] = useReducer(
    propTaxStatesReducer,
    initialstate
  );
  const {
    usingStepList,
    currentPropStep,
    currentStepIndex,
    backDisabled,
    nextDisabled,
    isLastStep,
    handleClickNextStep,
    handleClickBackStep,
    handleClickReset,
    initPropStep,
  } = usePropTaxCalc(propTaxState, dispatch);

  const [showCalcResult, setShowCalcResult] = useState<boolean>(false);
  const [apiResult, setApiResult] = useState<string>("-1");
  const [calcResult, setCalcResult] = useState<PropTaxCalcRsltParam | null>(
    null
  );

  useEffect(() => {
    // API 정상 호출 여부 확인
    // 주소 조회 API 확인
    Axios.dataAccess({
      url: "common/mobile/callApi.do",
      methodType: "post",
      paramType: "object",
      paramData: {
        apiName: "openapi",
        tailUrl: "addr/juso",
        paramStr: encodeURI("상암미르웰") + ",1,10",
      },
      onSuccessFn: (res: any) => {
        setApiResult(res.item.rtn_code);
        //setApiResult("-1");
        setAddrSearchResult((prev: AddrSearchResult) => ({
          ...prev,
          error: res.item.rtn_code,
          //error: "-1",
        }));
      },
      skipFailedAlert: true,
      onFailedFn: () => {
        setApiResult("-1");
        setAddrSearchResult((prev: AddrSearchResult) => ({
          ...prev,
          error: "-1",
        }));
      },
    });
  }, [setAddrSearchResult]);

  const onClickNext = () => {
    if (isLastStep) {
      propertyTaxCalc(propTaxState.inputValue);
    } else {
      handleClickNextStep();
    }
  };

  const propertyTaxCalc = (inputValue: PropTaxParamProps) => {
    let pnuString = ""; // 토지고유코드(19자리)
    // let rdnmAdresString       = ""; // 도로명 주소
    let spfcKndString = ""; // 용도지역
    let stdPriceString = ""; // 공동/개별 주택 가격(기준시가)
    let mdatTrgetAreaAtString = ""; // 조정대상지역
    let shareRatioString = ""; // 지분율

    // List형 Query String 생성 : ex)pnu=123&pnu=456&pnu=789
    // for(let i in 배열): 배열의 index 반환(i)
    for (let i in inputValue.housing) {
      pnuString += "&pnu=" + inputValue.housing[i].pnu;
      spfcKndString += "&spfc_knd=" + inputValue.housing[i].spfcKnd;
      stdPriceString += "&std_price=" + inputValue.housing[i].stdPrice;
      mdatTrgetAreaAtString +=
        "&mdat_trget_area_at=" + inputValue.housing[i].mdatTrgetAreaAt;
      shareRatioString += "&share_ratio=" + inputValue.housing[i].shareRatio;
    }

    Axios.dataAccess({
      url: "common/mobile/callApi.do",
      methodType: "post",
      paramType: "object",
      paramData: {
        apiName: "taxcalc",
        tailUrl: "simpletax/propcomptax",
        paramStr:
          "P" + // 재산세/종합부동산세(P)
          "?house_no=" +
          inputValue.houseNo + // 1. 주택수
          "&couple_name=" +
          inputValue.coupleName + // 2. 부부공동명의
          "&couple_special_case=" +
          inputValue.coupleSpecialCase + // 3. 부부공동명의 특례적용
          "&holding_period=" +
          inputValue.holdingPeriod + // 4. 보유기간
          "&owner_age=" +
          inputValue.ownerAge + // 5. 소유자 연령
          "&house_cnt=" +
          inputValue.housing.length + // 6. 실제 주택수
          pnuString + // 토지고유코드(19자리)
          spfcKndString + // 용도지역
          stdPriceString + // 공동/개별 주택 가격(기준시가)
          mdatTrgetAreaAtString + // 조정대상지역
          shareRatioString, // 지분율
      },
      onSuccessFn: (res: any) => {
        if (Util.isEmpty(res.item.rtn_prop_list)) {
          //setSimPropTaxCalcModalOpen(false);
          AppContext.openAlert({
            title: "과세 대상 여부",
            msg: res.item.rtn_msg, // 종합부동산세 과세대상이 아닙니다.
            icon: "check",
          });
          return;
        }
        // 재산세/종합소득세 계산 결과
        //setCalcPropInfo(res.item.rtn_prop_list); // 재산세 계산 결과 List
        //setCalcCompInfo(res.item.rtn_comp_tax[0]); // 종합부동산세 결과
        setCalcResult(res.item);
        setShowCalcResult(true);
      },
      onFailedFn: () => {
        setShowCalcResult(false);
      },
    });
  };

  const handleClickBackInResult = () => {
    setCalcResult(null);
    setShowCalcResult(false);
    initPropStep();
  };

  if (showCalcResult) {
    return (
      <PropTaxCalcResult
        calcResult={calcResult}
        housings={propTaxState.inputValue.housing}
        inputParams={propTaxState.inputValue}
        onClickBack={handleClickBackInResult}
      />
    );
  } else {
    return (
      <Body>
        <Header
          title="재산세·종부세 계산"
          totalStepCount={usingStepList.length}
          currentStep={currentStepIndex + 1}
          onClickBack={() => console.info(">>> back")}
        />
        <CalcStep stepInfoList={usingStepList} />
        <MainContainer>
          {currentPropStep && currentPropStep.stepId === "PROP01" && (
            <BasicInfo
              inputValue={propTaxState.inputValue}
              inputVisible={propTaxState.inputVisible}
              inputDisabled={propTaxState.inputDisabled}
              onUpdateInput={dispatch}
              apiStatus={apiResult}
            />
          )}
          {currentPropStep && currentPropStep.stepId === "PROP02" && (
            <ExtraInfo
              inputValue={propTaxState.inputValue}
              inputVisible={propTaxState.inputVisible}
              inputDisabled={propTaxState.inputDisabled}
              onUpdateInput={dispatch}
            />
          )}
          <CalcStepButton
            onClickBack={handleClickBackStep}
            onClickNext={onClickNext}
            onClickReset={handleClickReset}
            backDisabled={backDisabled}
            nextDisabled={nextDisabled}
            isLastStep={isLastStep}
          />
        </MainContainer>
      </Body>
    );
  }
}

export default PropTaxCalc;
