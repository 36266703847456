import React from "react";
import UseAlert from "js/common/ui/alert/UseAlert";
import { Container } from "semantic-ui-react";
import Body from "js/layout/Body";
import Spinner from "js/common/ui/Spinner";
import AppContext from "js/common/AppContext";
import Axios from "js/common/Axios";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import * as Atom from "js/common/Atom";
import Util from "js/common/Util";
import { useNavigate, useLocation } from "react-router-dom";
import Toast from "js/common/ui/Toast";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const { showAlert, hideAlert } = UseAlert();
  const [landerCmplt, setLanderCmplt] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingDim, setLoadingDim] = React.useState<boolean>(false);
  const setUserInfo = useSetRecoilState(Atom.userInfo);
  const setIsAdmin = useSetRecoilState(Atom.isAdmin);
  const setIsSysAdmin = useSetRecoilState(Atom.isSysAdmin);
  const setHshldSn = useSetRecoilState(Atom.hshldSn);
  const setHshldNm = useSetRecoilState(Atom.hshldNm);
  const delUserInfo = useResetRecoilState(Atom.userInfo);

  React.useEffect(() => {
    if (AppContext.codeList.length > 0) {
      return;
    }
    if (process.env.REACT_APP_ENV === "local") {
      if (process.env.REACT_APP_BACKEND_URL === undefined) {
        return;
      }
      AppContext.backendUrl = process.env.REACT_APP_BACKEND_URL;
    } else {
      AppContext.backendUrl = window.location.origin + "/backend";
    }
    Axios.dataAccess({
      url: "common/getSystemInfo.do",
      methodType: "post",
      onSuccessFn: (res: any) => {
        AppContext.codeList = res.item.codeList;
        AppContext.fileWhiteExt = res.item.fileWhiteExt;
        AppContext.maxfileSize = res.item.maxfileSize;
        AppContext.accessTokenExpireTime = res.item.accessTokenExpireTime;
        AppContext.refreshTokenExpireTime = res.item.refreshTokenExpireTime;
        AppContext.sidoCodeInfoList = res.item.sidoCodeInfoList;
        AppContext.showSpinner = () => {
          setLoading(true);
          setLoadingDim(false);
        };
        AppContext.hideSpinner = () => {
          setLoading(false);
          setLoadingDim(false);
        };
        AppContext.showSpinnerDim = () => {
          setLoading(true);
          setLoadingDim(true);
        };
        AppContext.openAlert = ({
          title,
          msg,
          icon,
          closeText,
          handleClose,
          confirmText,
          handleConfirm,
        }: any) => {
          showAlert({
            title: title,
            message: msg,
            iconFg: icon,
            closeText: closeText,
            handleClose: handleClose,
            confirmText: confirmText,
            handleConfirm: handleConfirm,
          });
        };
        AppContext.goLogout = () => {
          delUserInfo(); // 유저정보 초기화
          localStorage.removeItem("tokenCrtDt");
          localStorage.removeItem("grantType");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("hshldSn");
          localStorage.removeItem("hshldNm");
          localStorage.removeItem("isAdmin");
          setHshldSn(null);
          setHshldNm(null);
          setIsAdmin(false);
          setIsSysAdmin(false);
          navigate("/portal/login");
        };
        const tokenCrtDt = localStorage.getItem("tokenCrtDt");
        if (tokenCrtDt !== null) {
          // 토큰정보 있으면
          Axios.dataAccess({
            url: "system/home/get-base-info.do",
            methodType: "post",
            onSuccessFn: (res: any) => {
              if (Util.isEmpty(res.item)) {
                // 서버에 인증정보 없으면
                AppContext.goLogout(); // 로그아웃 처리
                setLanderCmplt(true);
                return;
              }
              // 유저 정보 세팅
              setUserInfo(res.item);
              setIsAdmin(res.item.userInfo.isAdmin);
              setIsSysAdmin(res.item.userInfo.isSysAdmin);
              localStorage.setItem(
                "isAdmin",
                res.item.userInfo.isAdmin ? "1" : "0"
              );

              // 납세자 정보 세팅
              const storeHshldSn = localStorage.getItem("hshldSn");
              if (Util.isNotEmpty(storeHshldSn)) {
                // 스토리지에 납세자 키가 존재하면
                setHshldSn(Number(storeHshldSn)); // state 세팅
                setHshldNm(localStorage.getItem("hshldNm"));
              } else {
                setHshldSn(null);
                setHshldNm(null);
              }
              if (
                !res.item.isAdmin &&
                location.pathname.startsWith("/admin", 0)
              ) {
                // 관리자가 아닌데 관리자 경로 접근할 경우
                navigate("/"); // 메인페이지로
              }
              setLanderCmplt(true);
            },
          });
        } else {
          // 토큰정보 없으면
          if (
            !location.pathname.startsWith("/portal", 0) &&
            !location.pathname.startsWith("/simpletax", 0)
          ) {
            navigate("/"); // 메인페이지로
          }
          setLanderCmplt(true);
        }
      },
    });
  }, [
    showAlert,
    delUserInfo,
    setHshldNm,
    setHshldSn,
    setIsAdmin,
    setIsSysAdmin,
    setUserInfo,
    navigate,
    location.pathname,
  ]);

  React.useEffect(() => {
    const keydown = (e: any) => {
      if (e.keyCode === 27) {
        hideAlert();
      }
    };
    window.addEventListener("keydown", keydown);
    return () => window.removeEventListener("keydown", keydown);
  }, [hideAlert]);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  if (!landerCmplt) {
    // 서버에서 사용자 인증 무시하고 페이지 실행 -> 모바일 개발용
    return <Spinner loading={true} loadingDim={true} />;
    /*return (
            <React.Fragment>
                <Container>
                    <Body />
                </Container>
                <Spinner loading={loading} loadingDim={loadingDim} />
                <Toast />
            </React.Fragment>
        );*/
  } else {
    return (
      <React.Fragment>
        <Container>
          <Body />
        </Container>
        <Spinner loading={loading} loadingDim={loadingDim} />
        <Toast />
      </React.Fragment>
    );
  }
}
export default App;
