import styled from 'styled-components';

type Props = {
    value: string;
    name: string;
    label?: string;
    selectedValue: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function RadioButton({ value, name, label, selectedValue, handleChange }: Props) {
    return (
        <RadioButtonContainer>
            <label className="customLabel">
                <input
                    type="radio"
                    name={name}
                    value={value}
                    checked={selectedValue === value}
                    onChange={handleChange}
                    className="customRadio"
                />
                <span className="customRadioMark">
                    <span className="mark"></span>
                </span>
                {label ? <span>{label}</span> : ''}
            </label>
        </RadioButtonContainer>
    );
}

const RadioButtonContainer = styled.div`
    display: flex;
    align-items: center;

    width: 100%;
    height: 100%;

    .customRadio {
        display: none;
    }

    .customLabel {
        display: flex;
        position: relative;
        width: auto;
        height: 16px;
        cursor: pointer;
        user-select: none;
    }

    .customLabel input:checked ~ .customRadioMark {
        background-color: ${(props) => props.theme.color.pGreenLight02};
        border: none;
    }

    .customLabel .customRadioMark {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 16px;
        width: 16px;
        background-color: white;
        border: 1px solid ${(props) => props.theme.color.nGray70};
        border-radius: 50%;
    }

    .customLabel input:checked ~ .customRadioMark .mark {
        display: block;
    }

    .customLabel .customRadioMark .mark {
        display: none;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: ${(props) => props.theme.color.nGray95};
    }
`;

export default RadioButton;
