export default function CmbnatHouse({setModalOpen} :any) {
    return (
        <div className="w750 h100p">
            <div className="pt20 pb20 SpoqaHanSansNeo-Regular fc666666 ls075 fs15 lh20">
                1세대 1주택 비과세 규정을 적용함에 있어서 하나의 건물이 주택과 주택외의 부분으로 복합되어 있는 경우와 주택에 부수되는 <br />
                토지에 주택 외의 건물이 있는 경우에는 그 전부를 주택으로 본다.<br />
                다만, 주택의 면적이 주택 외의 면적보다 작거나 같을 때에는 주택 외의 부분은 주택으로 보지 아니한다.<br/>
                2022.1.1. 이후 양도분부터 고가주택에 해당하는 겸용주택은 주택면적이 주택 이외의 면적보다 크더라도 주택부분만 주택으로 본다.<br/>
            </div>
            <table className="forest-list-table w100p th-fm-SpoqaHanSansNeo-Medium th-fw500 th-fs14 th-fc222222 th-bdcDDDDDD bdt1 bdb1 bdcDDDDDD td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-p15">
                <colgroup>
                    <col className="w180"/>
                    <col className="w275"/>
                    <col className="w275"/>
                </colgroup>
                <tbody>
                    <tr className="h40">
                        <th rowSpan={2}>1세대 1주택 판정 시</th>
                        <th colSpan={2}>양도일</th>
                    </tr>
                    <tr className="h30">
                        <th>2021. 12. 31 이전</th>
                        <th>2022. 1. 1 이후</th>
                    </tr>
                    <tr className="h50 taC">
                        <td>주택 ＞ 기타건물</td>
                        <td>전부 주택으로 봄</td>
                        <td>고가주택인 겸용주택은 주택부분만 주택</td>
                    </tr>
                    <tr className="h50 taC">
                        <td>주택 ≤ 기타건물</td>
                        <td>주택만 주택으로 봄</td>
                        <td>주택만 주택</td>
                    </tr>
                </tbody>
            </table>
            <div className="dpFlx aiC jcC pt20">
                <div className="w210 h56 bgc17A840 dpFlx aiC jcC csP bdBox" onClick={() => { setModalOpen(false) }}>
                    <span className="SpoqaHanSansNeo-Medium fs14 fcWhite">닫기</span>
                </div>
            </div>
        </div >
    )
}
