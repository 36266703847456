interface Qestn48ModalProps {
    setQestn48Open: React.Dispatch<React.SetStateAction<boolean>>
}
const Qestn48Modal = ({setQestn48Open}: Qestn48ModalProps) => {
    return (
        <div className="w100p hAuto ofA">
            <table className={
                'forest-list-table bdt1 bdb1 bdcDDDDDD ' +
                'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075 td-p10'
            }>
                <colgroup>
                    <col className='w100'></col>
                    <col className='w160'></col>
                    <col className='w110'></col>
                    <col className='w160'></col>
                    <col className='w225'></col>
                    <col className='w160'></col>
                    <col className='w200'></col>
                </colgroup>
                <tbody>
                    <tr className='h40'>
                        <th rowSpan={2}>구분</th>
                        <th rowSpan={2}>주택구분</th>
                        <th rowSpan={2}>주택규모</th>
                        <th rowSpan={2}>취득시기</th>
                        <th rowSpan={2}>가액기준</th>
                        <th>추가질문</th>
                        <th rowSpan={2}>혜택(취득세 감면)</th>
                    </tr>
                    <tr className='h29'>
                        <th className="bdr1-i bdcDDDDDD-i">최초주택 구입여부</th>
                    </tr>
                    <tr className='h50'>
                        <th rowSpan={2}>개별사항</th>
                        <td>단독주택</td>
                        <td rowSpan={2}>-</td>
                        <td rowSpan={2}>2025.2.31 까지</td>
                        <td rowSpan={2}>취득 당시 가액 12억원 이하의<br/>유상거래(부담부증여는 제외)</td>
                        <td rowSpan={2}>최초분양</td>
                        <td rowSpan={2}>취득세 200만원 면제</td>
                    </tr>
                    <tr className='h50'>
                        <td className="bdr1-i bdcDDDDDD-i">공동주택<br/>(오피스텔제외)</td>
                    </tr>
                    <tr className='h400'>
                        <th>공통사항</th>
                        <td className="taL lh20" colSpan={6}>
                            <span>1. 요건<br/></span>
                            <span className="pl15">① 세대주 및 세대원이 주택을 소유한 사실이 없는 경우<br/></span>
                            <span className="pl15">② 유상거래(부담부증여 제외)<br/></span>
                            <span className="pl15">③ 취득자가 20세 이상일 것<br/></span>
                            <span className="pl15">④ 취득 후 3개월 이내 거주 후 전입신고, 3개월 이내 다른 주택 취득하면 안됨<br/></span>
                            <span className="pl15">⑤ 3년 이상 상시거주 및 보유<br/><br/></span>
                            <span>2. 주택을 소유한 사실이 없는 경우<br/></span>
                            <span>주택을 소유한 사실이 없는 경우란 다음 각 호의 어느 하나에 해당하는 경우를 말한다.<br/></span>
                            <span className="pl15">① 공동소유 상속주택의 처분 : 상속으로 주택의 공동지분을 소유 (주택 부속토지의 공유지분만을 소유하는 경우를 포함) 하였다가 그 지분을 모두 처분한 경우<br/></span>
                            <span className="pl15">② 비도시지역 주택을 이주를 위해 처분 : 취득일 현재 도시지역이 아닌 지역에 건축되어 있거나 면의 행정구역 (수도권은 제외) 에 건축되어 있는 주택으로서 <br/></span>
                            <span className="pl30">다음 각 목의 어느 하나에 해당하는 주택을 소유한 자가 그 주택 소재지역에 거주하다가 다른 지역 (해당 주택 소재지역인 특별시·광역시·특별자치시·특별자치도 및</span>
                            <span className="pl30">시·군 이외의 지역을 말한다) 으로 이주한 경우 그 주택을 감면대상 주택 취득일 전에 처분했거나 감면대상 주택 취득일부터 3개월 이내에 처분한 경우로 한정한다.<br/></span>
                            <span className="pl40">가. 사용승인 후 20년 이상 경과된 단독주택<br/></span>
                            <span className="pl40">나. 85㎡ 이하인 단독주택<br/></span>
                            <span className="pl40">다. 상속으로 취득한 주택<br/></span>
                            <span className="pl15">③ 소형주택의 처분 - 전용면적 20㎡ 이하인 주택을 소유하고 있거나 처분한 경우. 다만, 전용면적 20㎡ 이하인 주택을 둘 이상 소유했거나 소유하고 있는 경우는 제외한다.<br/></span>
                            <span className="pl15">④ 소액 주택의 처분 - 취득일 현재 지방세법상 시가표준액이 100만원 이하인 주택을 소유하고 있거나 처분한 경우<br/></span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className='w215 dpFlx aiC jcC bdBox pt30'>
                <span className="SpoqaHanSansNeo-Medium fs18 ls09 fc222222">위의 조건에 해당되시나요?</span>
            </div>
            <div className='w100p dpFlx aiC jcC pt40'>
                <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => setQestn48Open(false)}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>닫기</span>
                </div>
            </div>
        </div>
    )
}
export default Qestn48Modal;