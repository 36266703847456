import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { produce } from "immer";
import { useNavigate } from "react-router-dom";

import {
  acqsInputVisibleState,
  acqsInputValueState,
  addrSearchResultState,
  acquiredAssetInfoState,
  directAddrInfoState,
  taxFlagState,
} from "js/common/Atom";
import { useAcqsInputs } from "js/common/hooks/useAcqsInputs";
import { Juso, Step } from "js/common/types/types";
import Util from "js/common/Util";
import Axios from "js/common/Axios";
import AppContext from "js/common/AppContext";

export function useAcqsCalcStep() {
  const navigate = useNavigate();
  const [acqsStepList, setAcqsStepList] = useState<Step[]>([
    {
      stepId: "ACQS01",
      name: "기초 정보 입력",
      order: 1,
      useYn: true,
      selected: true,
      subSteps: [],
    },
    {
      stepId: "ACQS02",
      name: "주소 정보 입력",
      order: 2,
      useYn: false,
      selected: false,
      subSteps: [
        {
          stepId: "ACQS0201",
          name: "주소 입력",
          order: 1,
          useYn: true,
          selected: true,
          subSteps: [],
        },
        {
          stepId: "ACQS0202",
          name: "상세 주소 입력",
          order: 2,
          useYn: true,
          selected: false,
          subSteps: [],
        },
        {
          stepId: "ACQS0203",
          name: "최종 주소 확인",
          order: 3,
          useYn: true,
          selected: false,
          subSteps: [],
        },
      ],
    },
    {
      stepId: "ACQS03",
      name: "부가 정보 입력",
      order: 3,
      useYn: true,
      selected: false,
      subSteps: [],
    },
  ]);
  const [backDisabled, setBackDisabled] = useState<boolean>(true);
  const [nextDisabled, setNextDisabled] = useState<boolean>(true);
  const currentCalcStep = acqsStepList.find((step: Step) => step.selected);
  const addrInfoSubStepList =
    acqsStepList.find((step: Step) => step.stepId === "ACQS02")?.subSteps ?? [];
  const [addrSearchQuery, setAddrSearchQuery] = useState<string>("");
  const [addrQueryResult, setAddrQueryResult] = useState<Juso[]>([]);
  const [isLastStep, setIsLastStep] = useState<boolean>(false);

  const acqsInputValue = useRecoilValue(acqsInputValueState);
  const acqsInputVisible = useRecoilValue(acqsInputVisibleState);
  const addrSearchResult = useRecoilValue(addrSearchResultState);
  const acquiredAssetInfo = useRecoilValue(acquiredAssetInfoState);
  const directAddrInfo = useRecoilValue(directAddrInfoState);
  const taxFlag = useRecoilValue(taxFlagState);

  const {
    onResetInBasicInfo,
    onResetInAddresInfo,
    onResetInExtraInfo,
    onResetInDongHoSelect,
    onResetInFinalAddrInfo,
    onNextInAddressInfo,
    onNextInDirectAddressInfo,
    initializeInputs,
  } = useAcqsInputs();

  useEffect(() => {
    // 단계가 바뀌면 next 버튼 비활성화
    setNextDisabled(true);
  }, [acqsStepList]);

  useEffect(() => {
    if (currentCalcStep && currentCalcStep.stepId === "ACQS01") {
      setBackDisabled(true);
    } else {
      setBackDisabled(false);
    }
  }, [currentCalcStep]);

  useEffect(() => {
    // 마지막 단계에 도달했는지 여부 확인
    if (!currentCalcStep) return;

    const usingStepList = acqsStepList.filter((step) => step.useYn);
    if (
      usingStepList[usingStepList.length - 1].stepId === currentCalcStep.stepId
    ) {
      setIsLastStep(true);
    } else {
      setIsLastStep(false);
    }
  }, [currentCalcStep, acqsStepList]);

  useEffect(() => {
    // 23번 입력창이(주소검색) visible 되면 주소정보입력 단계 활성화
    if (acqsInputVisible.visible23) {
      setAcqsStepList(
        produce((draft: Step[]) => {
          const step = draft.find((step) => step.stepId === "ACQS02");
          if (step) {
            step.useYn = true;
          }
        })
      );
    } else {
      setAcqsStepList(
        produce((draft: Step[]) => {
          const step = draft.find((step) => step.stepId === "ACQS02");
          if (step) {
            step.useYn = false;
          }
        })
      );
    }
  }, [acqsInputVisible.visible23]);

  useEffect(() => {
    // 부가정보입력 단계 사용 여부 확인
    const visibleInputs = Object.keys(acqsInputVisible).filter(
      (key) => acqsInputVisible[key] === true
    );
    const visibleInExtraInfo = visibleInputs.filter((input: string) => {
      const inputNumber: number = parseInt(input.slice(-2));
      return inputNumber > 11 ? true : false;
    });

    if (visibleInExtraInfo.length === 0) {
      setAcqsStepList(
        produce((draft: Step[]) => {
          const extraInfoStep = draft.find(
            (step: Step) => step.stepId === "ACQS03"
          );
          if (extraInfoStep) {
            extraInfoStep.useYn = false;
          }
        })
      );
    } else {
      setAcqsStepList(
        produce((draft: Step[]) => {
          const extraInfoStep = draft.find(
            (step: Step) => step.stepId === "ACQS03"
          );
          if (extraInfoStep) {
            extraInfoStep.useYn = true;
          }
        })
      );
    }
  }, [acqsInputVisible]);

  useEffect(() => {
    // 기초정보입력 단계 완료 여부 확인
    if (!currentCalcStep || currentCalcStep.stepId !== "ACQS01") return;

    /* 기초정보입력 단계 확인 */
    const checkBasicInfo = (): boolean => {
      // 부동산 종류 선택 여부 확인
      if (acqsInputValue.estateSe === "") {
        return false;
      }

      // 부동산 종류 선택 완료
      switch (acqsInputValue.estateSe) {
        case "201": {
          // 부동산 종류 : 주택
          if (acqsInputVisible.visible23) {
            return true;
          } else {
            return false;
          }
        }
        case "202": {
          // 부동산 종류 : 조합원입주권
          if (acqsInputValue.acqsCause3 !== "") {
            return true;
          } else {
            return false;
          }
        }
        case "203": {
          // 부동산 종류 : 오피스텔
          if (
            acqsInputValue.acqsCause1 !== "" ||
            acqsInputValue.acqsCause2 !== ""
          ) {
            return true;
          } else {
            return false;
          }
        }
        case "204": {
          // 부동산 종류 : 일반건물/상가
          if (acqsInputValue.acqsCause4 !== "") {
            return true;
          } else {
            return false;
          }
        }
        case "205": {
          // 부동산 종류 : 토지
          if (acqsInputValue.acqsCause1 !== "") {
            return true;
          } else {
            return false;
          }
        }
        default: {
          return false;
        }
      }
    };

    const checkResult: boolean = checkBasicInfo();
    // checkResult true이면 기초정보 입력 완료
    setNextDisabled(!checkResult);
  }, [acqsInputValue, acqsInputVisible, acqsStepList, currentCalcStep]);

  useEffect(() => {
    // 주소정보입력 단계 완료 여부 확인
    if (
      !currentCalcStep ||
      currentCalcStep.stepId !== "ACQS02" ||
      addrSearchResult.error === "-1"
    )
      return;

    const addrSubSteps = acqsStepList.find(
      (step: Step) => step.stepId === "ACQS02"
    );
    const currentAddrStep = addrSubSteps
      ? addrSubSteps.subSteps.find((step: Step) => step.selected)
      : null;

    if (!currentAddrStep) return;

    switch (currentAddrStep.stepId) {
      case "ACQS0201": {
        // 주소 검색
        setNextDisabled(true);
        break;
      }
      case "ACQS0202": {
        // 동/호 정보 입력
        if (addrSearchResult.addr) {
          if (addrSearchResult.bdType === "dongHo") {
            if (addrSearchResult.dong !== "" && addrSearchResult.ho !== "") {
              setNextDisabled(false);
            } else {
              setNextDisabled(true);
            }
          } else if (addrSearchResult.bdType === "multiHo") {
            if (addrSearchResult.ho !== "") {
              setNextDisabled(false);
            } else {
              setNextDisabled(true);
            }
          } else if (addrSearchResult.bdType === "none") {
            setNextDisabled(false);
          } else {
            setNextDisabled(true);
          }
        } else {
          setNextDisabled(true);
        }
        break;
      }
      case "ACQS0203": {
        if (addrSearchResult.addr) {
          if (taxFlag === "A") {
            // 취득세
            if (addrSearchResult.addr.bdKdcd === "0") {
              // 단독주택
              if (
                acquiredAssetInfo.housePc !== "" &&
                acquiredAssetInfo.houseTotar !== "" &&
                acquiredAssetInfo.mdatTrgetAreaAt !== "" &&
                acquiredAssetInfo.shareRatio !== "" &&
                parseInt(acquiredAssetInfo.shareRatio) > 0 &&
                parseInt(acquiredAssetInfo.shareRatio) <= 100
              ) {
                setNextDisabled(false);
              } else {
                setNextDisabled(true);
              }
            } else if (addrSearchResult.addr.bdKdcd === "1") {
              // 공동주택
              if (
                acquiredAssetInfo.pblntfPc !== "" &&
                acquiredAssetInfo.prvuseAr !== "" &&
                acquiredAssetInfo.mdatTrgetAreaAt !== "" &&
                acquiredAssetInfo.shareRatio !== "" &&
                parseInt(acquiredAssetInfo.shareRatio) > 0 &&
                parseInt(acquiredAssetInfo.shareRatio) <= 100
              ) {
                setNextDisabled(false);
              } else {
                setNextDisabled(true);
              }
            } else {
              setNextDisabled(true);
            }
          } else {
            // TO-DO : taxFlag가 A가 아닌 다른 값일 때 확인할 내용 추가
            setNextDisabled(true);
          }
        } else {
          setNextDisabled(true);
        }
      }
    }
  }, [
    currentCalcStep,
    addrSearchResult,
    acqsStepList,
    taxFlag,
    acquiredAssetInfo,
  ]);

  useEffect(() => {
    // 주소정보입력 단계 완료 여부 확인 -> 주소 직접입력
    if (
      !currentCalcStep ||
      currentCalcStep.stepId !== "ACQS02" ||
      addrSearchResult.error !== "-1"
    )
      return;

    /* 주소 직접입력 단계 확인*/
    const checkDirectAddrInfo = (): boolean => {
      let checkResult = true;

      if (Util.isEmpty(directAddrInfo.sidoCode)) {
        checkResult = false;
      }

      if (Util.isEmpty(directAddrInfo.sggCode)) {
        checkResult = false;
      }

      if (Util.isEmpty(directAddrInfo.emdCode)) {
        checkResult = false;
      }

      if (Util.isEmpty(directAddrInfo.rdnmAdresDetail)) {
        checkResult = false;
      }

      // 재산세/종합부동산세 자산 검색 시 확인
      if (taxFlag === "P") {
        if (Util.isEmpty(directAddrInfo.pblntfPc)) {
          checkResult = false;
        }

        if (Number(directAddrInfo.pblntfPc) <= 0) {
          checkResult = false;
        }

        if (Util.isEmpty(directAddrInfo.spfcKnd)) {
          checkResult = false;
        }
      }

      // 취득세 주소검색 입력사항 확인
      if (taxFlag === "A") {
        if (Util.isEmpty(directAddrInfo.pblntfPc)) {
          checkResult = false;
        }

        if (Number(directAddrInfo.pblntfPc) <= 0) {
          checkResult = false;
        }

        if (Util.isEmpty(directAddrInfo.prvuseAr)) {
          checkResult = false;
        }

        if (Number(directAddrInfo.prvuseAr) <= 0) {
          checkResult = false;
        }
      }

      if (Util.isEmpty(directAddrInfo.mdatTrgetAreaAt)) {
        checkResult = false;
      }

      if (Util.isEmpty(directAddrInfo.shareRatio)) {
        checkResult = false;
      }

      if (
        Number(directAddrInfo.shareRatio) <= 0 ||
        Number(Util.uncomma(directAddrInfo.shareRatio)) > 100
      ) {
        checkResult = false;
      }

      return checkResult;
    };

    const checkResult: boolean = checkDirectAddrInfo();
    // checkResult true이면 주소정보 입력 완료
    setNextDisabled(!checkResult);
  }, [addrSearchResult.error, directAddrInfo, currentCalcStep, taxFlag]);

  useEffect(() => {
    // 부가정보입력 단계 완료 여부 확인
    if (!currentCalcStep || currentCalcStep.stepId !== "ACQS03") return;

    /* 부가정보입력 단계 확인 */
    const checkExtraInfo = (): boolean => {
      let inputCheck: boolean = true;

      // 12~21번 입력 값 확인
      if (acqsInputVisible.visible12) {
        if (Util.isEmpty(acqsInputValue.mdatTrgetAre)) {
          inputCheck = false;
        }
      }
      if (acqsInputVisible.visible13) {
        if (Util.isEmpty(acqsInputValue.hseNumAt1)) {
          inputCheck = false;
        }
      }
      if (acqsInputVisible.visible14) {
        if (Util.isEmpty(acqsInputValue.hseNumAt2)) {
          inputCheck = false;
        }
      }
      if (acqsInputVisible.visible15) {
        if (Util.isEmpty(acqsInputValue.inheirHseNo)) {
          inputCheck = false;
        }
      }
      if (acqsInputVisible.visible16) {
        if (Util.isEmpty(acqsInputValue.stdMkPrice)) {
          inputCheck = false;
        }
      }
      if (acqsInputVisible.visible17) {
        if (Util.isEmpty(acqsInputValue.donor)) {
          inputCheck = false;
        }
      }
      if (acqsInputVisible.visible18) {
        if (Util.isEmpty(acqsInputValue.donorHseNo)) {
          inputCheck = false;
        }
      }
      if (acqsInputVisible.visible19) {
        if (Util.isEmpty(acqsInputValue.taxBase)) {
          inputCheck = false;
        }
        if (Number(acqsInputValue.taxBase) <= 0) {
          inputCheck = false;
        }
      }
      if (acqsInputVisible.visible20) {
        if (Util.isEmpty(acqsInputValue.shareRatio)) {
          inputCheck = false;
        }
        if (Number(acqsInputValue.shareRatio) <= 0) {
          inputCheck = false;
        }
      }
      if (acqsInputVisible.visible21) {
        if (Util.isEmpty(acqsInputValue.prvuseAr)) {
          inputCheck = false;
        }
      }

      return inputCheck;
    };

    const checkResult: boolean = checkExtraInfo();
    // checkResult true이면 부가정보 입력 완료
    setNextDisabled(!checkResult);
  }, [acqsInputValue, acqsInputVisible, currentCalcStep]);

  const handleClickNextStep = () => {
    const currentCalcStep: Step | undefined = acqsStepList.find(
      (step: Step) => step.selected
    );

    if (!currentCalcStep) return;

    switch (currentCalcStep.stepId) {
      case "ACQS01": {
        // 기초정보입력
        if (onNextInBasicInfo()) {
          moveToNextStep();
        }
        break;
      }
      case "ACQS02": {
        // 주소정보입력
        if (addrSearchResult.error === "-1") {
          onNextInDirectAddressInfo();
          moveToNextStep();
        } else {
          const currentAddrStep = addrInfoSubStepList.find(
            (step: Step) => step.selected
          );
          if (currentAddrStep) {
            if (currentAddrStep.stepId === "ACQS0203") {
              onNextInAddressInfo();
              moveToNextStep();
            } else if (currentAddrStep.stepId === "ACQS0201") {
            } else {
              moveToNextAddrSubStep();
            }
          }
        }
        break;
      }
      case "ACQS03": {
        // 부가정보입력
        navigate("/simpletax/result");
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleClickBackStep = () => {
    const currentCalcStep: Step | undefined = acqsStepList.find(
      (step: Step) => step.selected
    );

    if (!currentCalcStep) return;

    switch (currentCalcStep.stepId) {
      case "ACQS01": {
        // 기초정보입력
        break;
      }
      case "ACQS02": {
        // 주소정보입력
        if (addrSearchResult.error === "-1") {
          onResetInAddresInfo();
          moveToBackStep();
        } else {
          const currentAddrStep = addrInfoSubStepList.find(
            (step: Step) => step.selected
          );
          if (currentAddrStep) {
            if (currentAddrStep.stepId === "ACQS0201") {
              onResetInAddresInfo();
              setAddrSearchQuery(""); // 주소검색 -> 검색어/검색결과 초기화
              moveToBackStep();
            } else if (currentAddrStep.stepId === "ACQS0202") {
              onResetInDongHoSelect();
              moveToBackAddrSubStep();
            } else {
              onResetInFinalAddrInfo();
              moveToBackAddrSubStep();
            }
          }
        }
        break;
      }
      case "ACQS03": {
        // 부가정보입력
        onResetInExtraInfo();
        moveToBackStep();
        break;
      }
      default: {
        break;
      }
    }
  };

  const onNextInBasicInfo = (): boolean => {
    const visibleInputs = Object.keys(acqsInputVisible).filter(
      (key) => acqsInputVisible[key] === true
    );
    const visibleInExtraInfo = visibleInputs.filter((input: string) => {
      const inputNumber: number = parseInt(input.slice(-2));
      return inputNumber > 11 ? true : false;
    });

    if (visibleInExtraInfo.length === 0) {
      // 계산 API 호출
      Axios.dataAccess({
        url: "common/mobile/callApi.do",
        methodType: "post",
        paramType: "object",
        paramData: {
          apiName: "taxcalc",
          tailUrl: "simpletax/acquisitiontax",
          paramStr:
            taxFlag +
            "?estate_se=" +
            acqsInputValue.estateSe + // 01. 부동산의 종류(상세구분)
            "&estate_fom=" +
            acqsInputValue.estateFom + // 02. 주택구분
            "&acqs_src_1=" +
            acqsInputValue.acqsSrc1 + // 03. 취득구분 1(아파트/연립)
            "&acqs_src_2=" +
            acqsInputValue.acqsSrc2 + // 04. 취득구분 2(오피스텔)
            "&lose_yn=" +
            acqsInputValue.loseYn + // 05. 멸실여부
            "&land_type=" +
            acqsInputValue.landType + // 06. 토지종류
            "&farm_period=" +
            acqsInputValue.farmPeriod + // 07. 자경기간
            "&acqs_cause_1=" +
            acqsInputValue.acqsCause1 + // 08. 취득원인 1(기존 아파트)
            "&acqs_cause_2=" +
            acqsInputValue.acqsCause2 + // 09. 취득원인 2(분양권/오피스텔)
            "&acqs_cause_3=" +
            acqsInputValue.acqsCause3 + // 10. 취득원인 3(조합원입주권)
            "&acqs_cause_4=" +
            acqsInputValue.acqsCause4 + // 11. 취득원인 4(다세대/다가구/단독주택)
            "&mdat_trget_are=" +
            acqsInputValue.mdatTrgetAre + // 12. 조정대상지역
            "&hse_num_at_1=" +
            acqsInputValue.hseNumAt1 + // 13. 주택수 1(조정대상지역)
            "&hse_num_at_2=" +
            acqsInputValue.hseNumAt2 + // 14. 주택수 2(비조정대상지역)
            "&inheir_hse_no=" +
            acqsInputValue.inheirHseNo + // 15. 상속인의 주택유무
            "&std_mk_price=" +
            acqsInputValue.stdMkPrice + // 16. 시가표준액 기준
            "&donor=" +
            acqsInputValue.donor + // 17. 증여자
            "&donor_hse_no=" +
            acqsInputValue.donorHseNo + // 18. 증여자의 주택수
            "&tax_base=" +
            acqsInputValue.taxBase + // 19. 과세표준
            "&share_ratio=" +
            acqsInputValue.shareRatio + // 20. 지분율
            "&prvuse_ar=" +
            acqsInputValue.prvuseAr, // 21. 면적
        },
        onSuccessFn: (res: any) => {
          if (Util.isEmpty(res.item.rtn_list)) {
            AppContext.openAlert({
              title: "과세 대상 여부",
              msg: res.item.rtn_msg, // 과세대상에 해당되지 않습니다.
              icon: "check",
            });
            return;
          }
        },
        onFailedFn: () => {
          console.error("%%% 취득세 세액 계산 결과 오류");
        },
      });

      return false;
    } else {
      return true;
    }
  };

  const moveToNextStep = () => {
    setAcqsStepList(
      produce((draft: Step[]) => {
        const useYnSteps = draft.filter((step) => step.useYn);
        const selectedIndex = useYnSteps.findIndex((step) => step.selected);
        if (selectedIndex !== -1 && selectedIndex < useYnSteps.length - 1) {
          useYnSteps[selectedIndex].selected = false;
          useYnSteps[selectedIndex + 1].selected = true;
        }
      })
    );
  };

  const moveToBackStep = () => {
    setAcqsStepList(
      produce((draft: Step[]) => {
        const useYnSteps = draft.filter((step) => step.useYn);
        const selectedIndex = useYnSteps.findIndex((step) => step.selected);
        if (selectedIndex !== -1 && selectedIndex > 0) {
          useYnSteps[selectedIndex].selected = false;
          useYnSteps[selectedIndex - 1].selected = true;
        }
      })
    );
  };

  const moveToNextAddrSubStep = () => {
    setAcqsStepList(
      produce((draft: Step[]) => {
        const addrInfoStep = draft.find(
          (step: Step) => step.stepId === "ACQS02"
        );
        if (addrInfoStep) {
          const subSteps = addrInfoStep.subSteps;
          const selectedIndex = addrInfoStep.subSteps.findIndex(
            (step) => step.selected
          );
          if (selectedIndex !== -1 && selectedIndex < subSteps.length - 1) {
            subSteps[selectedIndex].selected = false;
            subSteps[selectedIndex + 1].selected = true;
          }
        }
      })
    );
  };

  const moveToBackAddrSubStep = () => {
    setAcqsStepList(
      produce((draft: Step[]) => {
        const addrInfoStep = draft.find(
          (step: Step) => step.stepId === "ACQS02"
        );
        if (addrInfoStep) {
          const subSteps = addrInfoStep.subSteps;
          const selectedIndex = addrInfoStep.subSteps.findIndex(
            (step) => step.selected
          );
          if (selectedIndex !== -1 && selectedIndex > 0) {
            subSteps[selectedIndex].selected = false;
            subSteps[selectedIndex - 1].selected = true;
          }
        }
      })
    );
  };

  const handleClickReset = () => {
    const currentCalcStep: Step | undefined = acqsStepList.find(
      (step: Step) => step.selected
    );

    if (!currentCalcStep) return;

    switch (currentCalcStep.stepId) {
      case "ACQS01": {
        // 기초정보입력
        onResetInBasicInfo();
        return;
      }
      case "ACQS02": {
        // 주소정보입력
        onResetInAddresInfo();
        if (addrSearchResult.error !== "-1") {
          // 주소정보입력 세부 1단계에서 reset 버튼 누르면 검색어/검색결과 초기화
          const currentAddrSubStep = addrInfoSubStepList.find(
            (step: Step) => step.selected
          );
          if (currentAddrSubStep && currentAddrSubStep.stepId === "ACQS0201") {
            setAddrSearchQuery("");
          }
          // 주소정보입력 세부 1단계로 이동
          setAcqsStepList(
            produce((draft: Step[]) => {
              const addrInfoStep = draft.find(
                (step: Step) => step.stepId === "ACQS02"
              );
              if (addrInfoStep) {
                const subSteps = addrInfoStep.subSteps;
                const selectedIndex = addrInfoStep.subSteps.findIndex(
                  (step) => step.selected
                );
                subSteps[selectedIndex].selected = false;
                subSteps[0].selected = true;
              }
            })
          );
        }
        return;
      }
      case "ACQS03": {
        if (addrSearchResult.error === "-1") {
          // 주소정보 직접입력
          onResetInExtraInfo();
          onNextInDirectAddressInfo();
        } else {
          // 주소정보입력
          onResetInExtraInfo();
          onNextInAddressInfo();
        }
      }
    }
  };

  const onSelectAddrInAddrSearch = () => {
    const selectedAddr = addrSearchResult.addr;
    if (selectedAddr) {
      if (selectedAddr.bdKdcd === "0") {
        // 단독주택
        if (selectedAddr.bdNm) {
          // 단독주택이지만 건물명이 있으면 동/호수 입력받도록 2단계로 이동
          moveToNextAddrSubStep();
        } else {
          // 건물명이 없으면 최종주소확인(3단계)로 건너뛰기
          setAcqsStepList(
            produce((draft: Step[]) => {
              const addrInfoStep = draft.find(
                (step: Step) => step.stepId === "ACQS02"
              );
              if (addrInfoStep) {
                const subSteps = addrInfoStep.subSteps;
                const selectedIndex = addrInfoStep.subSteps.findIndex(
                  (step) => step.selected
                );
                if (selectedIndex !== -1) {
                  subSteps[selectedIndex].selected = false;
                  subSteps[2].selected = true;
                }
              }
            })
          );
        }
      } else {
        moveToNextAddrSubStep();
      }
    }
  };

  const onClickBackToCalcMenu = () => {
    initializeInputs();
  };

  return {
    acqsStepList,
    currentCalcStep,
    addrInfoSubStepList,
    backDisabled,
    nextDisabled,
    acqsInputValue,
    addrSearchQuery,
    setAddrSearchQuery,
    addrQueryResult,
    setAddrQueryResult,
    handleClickNextStep,
    handleClickBackStep,
    handleClickReset,
    moveToNextAddrSubStep,
    onSelectAddrInAddrSearch,
    onClickBackToCalcMenu,
    isLastStep,
  };
}
