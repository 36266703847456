import Util from "js/common/Util";

interface AssetsTitleProps {
    propsForm: any;
    asterlink: boolean;
}
const AssetsTitle = ({propsForm, asterlink}: AssetsTitleProps) => {
    return (
        <div className="dpFlx jcSb aiC mt60 mb10 h40 bdBox">
            <span className='SpoqaHanSansNeo-Medium fs18 ls09'>
                <span className="fc17A840">{propsForm.rmstnfamNm}</span>
                <span className="fc222222">({Util.getValToCode("1001", propsForm.rmstnfamSe)})님의 자산등록</span>
            </span>
            <span className={'fs14 ls1 ' + (!asterlink ? "dpNone" : "")}>
                <span className="SpoqaHanSansNeo-Bold fcFF0000 pr3">*</span>
                <span className="SpoqaHanSansNeo-Regular fc999999">필수 입력 항목입니다.</span>
            </span>
        </div>
    )
}
export default AssetsTitle;