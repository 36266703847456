/***********************************************************************************
*** Law40at10 ** 임대사업자 등록 주택 (혜택 - 2년 거주요건이 없는 임대주택) - 단기 4년 Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law40at10Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}
export default function Law40at10({ setModalOpen, setLawModalTitle, callBack, callMode }: Law40at10Props) {
    React.useEffect(() => {
        setLawModalTitle("임대사업자 등록 주택 (혜택 - 2년 거주요건이 없는 임대주택) - 단기 4년");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className="m0 mt15 mb30">
                <span className="fs15 lh25 ls06 SpoqaHanSansNeo-Regular">
                    ※ 추가 확인사항<br />
                </span>
                <span className="lh20 fs14">
                    단기 4년 / 민간매입임대주택 / 2년 거주요건이 없는 임대주택
                </span>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}
