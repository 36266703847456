import { useState, useEffect } from "react";
import styled from "styled-components";
import RadioButton from "js/mobile/common/RadioButton";

type Props = {
  estateSe: string;
  name: string;
  desc: string;
  selectedRealEstate: string;
  onRealEstateSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickItem: (estateSe: string) => void;
};

function RealEstateItem({
  estateSe,
  name,
  desc,
  selectedRealEstate,
  onRealEstateSelect,
  onClickItem,
}: Props) {
  const [showItem, setShowItem] = useState<boolean>(true);

  useEffect(() => {
    if (selectedRealEstate === "") {
      // 선택된 부동산의 종류가 없으면 item 보여줌.
      setShowItem(true);
    } else if (selectedRealEstate !== estateSe) {
      // 부동산의 종류가 선택되었지만 해당 item이 아니면 item 보여주지 않음.
      setShowItem(false);
    } else {
      setShowItem(true);
    }
  }, [selectedRealEstate, estateSe]);

  const handleClickItem = () => {
    onClickItem(estateSe);
  };

  return (
    <ItemContainer
      className={`${selectedRealEstate === estateSe ? "checked" : ""} ${
        showItem ? "" : "hide"
      }`}
      onClick={handleClickItem}
    >
      <div className="radioWrap">
        <RadioButton
          value={estateSe}
          name={name}
          selectedValue={selectedRealEstate}
          handleChange={onRealEstateSelect}
        />
      </div>
      <div className="realEstateType">
        <p className="name">{name}</p>
        <p className="desc">{desc}</p>
      </div>
    </ItemContainer>
  );
}

const ItemContainer = styled.li`
  display: flex;
  gap: 16px;

  width: 100%;
  height: auto;
  padding: 10px 22px;
  margin-bottom: 10px;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.color.nGray70};
  background-color: ${(props) => props.theme.color.nGray95};

  &.checked {
    border: 1px solid ${(props) => props.theme.color.pGreenLight02};
    background-color: ${(props) => props.theme.color.pGreenLight03};
  }

  &.hide {
    display: none;
  }

  .realEstateType {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
    padding: 4px 0;

    .name {
      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: black;
    }

    .desc {
      font-family: "Spoqa Han Sans Neo";
      font-weight: 300;
      font-size: 1.2em;
      line-height: 1.29;
      letter-spacing: -0.058em;
      color: ${(props) => props.theme.color.nGray30};
    }
  }
`;

export default RealEstateItem;
