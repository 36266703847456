import styled from "styled-components";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

import { useAddressSearch } from "js/common/hooks/useAddressSearch";
import { Juso } from "js/common/types/types";

type Props = {
  searchText: string;
  setSearchText: (text: string) => void;
  searchResult: Juso[];
  setSearchResult: (result: Juso[]) => void;
  updateSelectedAddr?: (selected: Juso) => void;
};

function AddressSearch({
  searchText,
  setSearchText,
  searchResult,
  setSearchResult,
  updateSelectedAddr,
}: Props) {
  const {
    selectedAddr,
    addrSrchText,
    addrSrchResultList,
    setAddrSrchText,
    onSearchAddr,
    onSelectAddr,
  } = useAddressSearch(
    searchText,
    setSearchText,
    searchResult,
    setSearchResult
  );

  const onChangeAddrSrchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddrSrchText(event.target.value);
  };

  const onClickAddr = (addr: Juso) => {
    onSelectAddr(addr);
    updateSelectedAddr && updateSelectedAddr(addr);
  };

  const handleKeyDownSearch = (
    event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const keyCode = event.key;
    if (keyCode === "Enter") {
      onSearchAddr();
    }
  };

  if (!selectedAddr) {
    // 주소 검색 및 선택 전 -> 검색창 표시
    return (
      <>
        <InputContainer>
          <p className="label">주소검색</p>
          <SearchInput>
            <input
              type="text"
              value={addrSrchText}
              onChange={onChangeAddrSrchText}
              onKeyDown={handleKeyDownSearch}
              placeholder="건물명 또는 도로명 주소를 입력해 주세요."
            />
            <button className="icon" onClick={onSearchAddr}>
              <SearchOutlinedIcon fontSize="small" />
            </button>
          </SearchInput>
        </InputContainer>
        {addrSrchResultList.length > 0 ? (
          <SearchResultContainter>
            {addrSrchResultList.map((result: Juso) => (
              <li onClick={() => onClickAddr(result)} key={result.bdMgtSn}>
                <div className="addr">
                  <div className="label">
                    <p>도로명</p>
                  </div>
                  <p className="text">{result.roadAddr}</p>
                </div>
                <div className="addr">
                  <div className="label">
                    <p>지번</p>
                  </div>
                  <p className="text">{result.jibunAddr}</p>
                </div>
              </li>
            ))}
          </SearchResultContainter>
        ) : (
          <ListEmpty>
            <div className="empty">조회할 자료가 없습니다.</div>
          </ListEmpty>
        )}
      </>
    );
  } else {
    // 주소 선택 완료
    return (
      <>
        <InputContainer>
          <p className="label">도로명주소</p>
          <TextInputContainer>
            <div className="inputBox">
              <span className="text">{selectedAddr.roadAddr}</span>
              <span className="icon">
                <CheckOutlinedIcon fontSize="small" />
              </span>
            </div>
          </TextInputContainer>
        </InputContainer>
        {/*<InputContainer>
                    <p className="label">건물명</p>
                    <TextInputContainer>
                        <div className="inputBox">
                            <span className="text">{selectedAddr.bdNm}</span>
                            <span className="icon">
                                <CheckOutlinedIcon />
                            </span>
                        </div>
                    </TextInputContainer>
                </InputContainer>*/}
      </>
    );
  }
}

const InputContainer = styled.div`
  width: 100%;
  margin-top: 12px;

  .label {
    margin-bottom: 5px;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.29;
    letter-spacing: -0.058em;
    color: ${(props) => props.theme.color.nGray30};
  }
`;

const SearchInput = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 12px 16px;
  background-color: white;
  border: 1px solid ${(props) => props.theme.color.nGray70};
  border-radius: 8px;

  input {
    flex-grow: 1;

    width: 100%;
    height: 100%;
    border: none;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.4em;
    line-height: 1.32;
    letter-spacing: -0.05em;

    &:focus {
      outline: none;
    }
  }

  input::placeholder {
    color: ${(props) => props.theme.color.nGray50};
  }

  input::-webkit-input-placeholder {
    color: ${(props) => props.theme.color.nGray50};
  }

  input:-ms-input-placeholder {
    color: ${(props) => props.theme.color.nGray50};
  }

  .icon {
    flex: 0 0 24px;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    width: 24px;
    height: 24px;
  }
`;

const SearchResultContainter = styled.ol`
  width: 100%;
  height: auto;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.color.nGray70};
  background-color: white;

  li {
    width: 100%;
    height: auto;
    padding: 15px 0;
    border-bottom: 1px solid ${(props) => props.theme.color.nGray70};

    &:last-child {
      border: none;
    }

    .addr {
      display: flex;
      gap: 10px;

      .label {
        p {
          width: 60px;
          padding: 2px 10px;
          border: 1px solid ${(props) => props.theme.color.nGray70};
          border-radius: 3px;
          text-align: center;

          font-family: "Spoqa Han Sans Neo";
          font-weight: 400;
          font-size: 1.2em;
          line-height: 1.29;
          letter-spacing: -0.058em;
          color: ${(props) => props.theme.color.nGray40};
        }
      }

      .text {
        flex-grow: 1;
        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.4em;
        line-height: 1.32;
        letter-spacing: -0.05em;
        color: ${(props) => props.theme.color.sNavy};
      }
    }
  }
`;

const ListEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: auto;
  padding: 12px 16px;
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.color.nGray70};
  background-color: white;

  font-family: "Spoqa Han Sans Neo";
  font-weight: 400;
  font-size: 1.4em;
  line-height: 1.32;
  letter-spacing: -0.05em;
  color: ${(props) => props.theme.color.sNavy};
`;

const TextInputContainer = styled.div`
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  border: none;
  background-color: ${(props) => props.theme.color.pGreenLight03};

  font-family: "Spoqa Han Sans Neo";
  font-weight: 400;
  font-size: 1.4em;
  line-height: 1.32;
  letter-spacing: -0.05em;
  color: ${(props) => props.theme.color.nGray50};

  .inputBox {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 12px 16px;
    border: 1px solid ${(props) => props.theme.color.pGreenLight02};
    border-radius: 8px;
    color: ${(props) => props.theme.color.sNavy};
    background-color: white;

    .text {
      width: calc(100% - 12px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
    }

    .icon {
      width: 12px;
      color: ${(props) => props.theme.color.pGreen};
    }
  }
`;

export default AddressSearch;
