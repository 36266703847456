import { calculatorPcWebMaxWidth } from "js/common/const";
import styled from "styled-components";

const MainSectionContainer = styled.section`
  width: 100%;
  /*height: 100%;*/
  height: auto;
  padding: 13px 20px 80px 20px;
  background-color: ${(props) => props.theme.color.bg};
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  .desc {
    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.4em;
    line-height: 1.32;
    letter-spacing: -0.05em;
    color: ${(props) => props.theme.color.nGray30};

    .bold {
      font-family: "Spoqa Han Sans Neo";
      font-weight: 500;
      font-size: 1em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.sNavy};
    }
  }
`;

const Body = styled.div`
  font-size: ${({ theme }) => theme.fontSize}; /* 1rem = 10px */
  position: relative;
  width: 100dvw;
  height: 100dvh;

  /*display: grid;
    grid-template-rows: 50px 50px 1fr;*/

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${(props) => props.theme.color.pGreenLight04};

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  p {
    margin: 0;
  }

  li {
    list-style: none;
  }
`;

const MainContainer = styled.main`
  position: relative;
  width: 100%;
  height: calc(100% - 100px);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  /*flex: 1 1 0;*/

  background: ${(props) => props.theme.color.bg};

  @media (min-width: 1201px) {
    max-width: ${calculatorPcWebMaxWidth};
  }

  .tempCheckbox {
    padding: 20px;
    font-size: 1em;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  margin-top: 12px;

  .label {
    margin-bottom: 5px;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.29;
    letter-spacing: -0.058em;
    color: ${(props) => props.theme.color.nGray30};
  }

  .help {
    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.29;
    letter-spacing: -0.058em;
    color: ${(props) => props.theme.color.sNavyLight01};
    text-align: right;

    margin-top: 5px;

    &.warning {
      color: ${(props) => props.theme.color.sysRed100};
    }
  }
`;

const NumberInputContainer = styled.div<{ $filled: boolean }>`
  display: flex;
  width: 100%;
  height: auto;
  min-width: 0;

  ${(props) => {
    if (props.$filled) {
      return `
          padding: 5px;
          border-radius: 10px;
          background-color: ${props.theme.color.pGreenLight03};
          border: none;
        `;
    }
  }}

  .inputWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
    background-color: white;
    border: 1px solid
      ${(props) =>
        props.$filled
          ? props.theme.color.pGreenLight02
          : props.theme.color.nGray70};
    border-radius: 8px;

    .unit {
      font-family: "Spoqa Han Sans Neo";
      font-weight: 500;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.sNavy};
    }

    .numberInput {
      flex: 1 1 auto;
      height: 100%;
      border: none;
      min-width: 0px;
      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.sNavy};

      &:focus {
        outline: none;
      }
    }
    .numberInput::placeholder {
      color: ${(props) => props.theme.color.nGray50};
    }

    .numberInput::-webkit-input-placeholder {
      color: ${(props) => props.theme.color.nGray50};
    }

    .numberInput::-ms-input-placeholder {
      color: ${(props) => props.theme.color.nGray50};
    }
  }
`;

const DialogBody = styled.div`
  font-size: ${({ theme }) => theme.fontSize}; /* 1rem = 10px */
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${(props) => props.theme.color.pGreenLight04};

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  p {
    margin: 0;
  }

  li {
    list-style: none;
  }
`;

const DialogMainSection = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  background: ${(props) => props.theme.color.bg};

  @media (min-width: 1201px) {
    max-width: ${calculatorPcWebMaxWidth};
  }
`;

export {
  MainSectionContainer,
  Body,
  MainContainer,
  InputContainer,
  NumberInputContainer,
  DialogBody,
  DialogMainSection,
};
