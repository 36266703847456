/*******************************************************************************
*** TrsftaxCalc ** 양도세 결과표 : 양도세 세액 산출
*******************************************************************************/
import React                    from "react";
import Util                     from "js/common/Util";
import Axios                    from "js/common/Axios";
import { useRecoilValue }       from "recoil";
import * as Atom                from "js/common/Atom";
import Modal                    from 'js/common/ui/modal/Modal';
import AncillaryLand            from "./help/AncillaryLand";
import AdjustmentAreas          from "./help/AdjustmentAreas";
import LongRentalHousing        from "./help/LongRentalHousing";
import LongSpecialDeduction     from "./help/LongSpecialDeduction";
import TransferTaxRate          from "./help/TransferTaxRate";
import AppContext               from "js/common/AppContext";
import { TaxExemptCautionDesc } from "js/system/trsftax/TaxExemptCaution";
import { TaxSavingGuideDesc }   from "js/system/trsftax/TaxSavingGuide";

interface TrsftaxCalcProps {
    assetsSn: number | undefined
    trsfAmt: string
    trsfDe: string | undefined
    isPay: string
    payAmt: string
    ncssryAmtBf: string
    ncssryAmt: string
    setTrsftaxCalcModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setTrsftaxCalcModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}
const TrsftaxCalc = ({
    assetsSn,
    trsfAmt,
    trsfDe,
    isPay,
    payAmt,
    ncssryAmtBf,
    ncssryAmt,
    setTrsftaxCalcModalOpen,
    setTrsftaxCalcModalVisible
}: TrsftaxCalcProps) => {
    const hshldSn = useRecoilValue(Atom.hshldSn);
    const [trsftaxCalcInfo, setTrsftaxCalcInfo] = React.useState<any>({});
    const [acqsCauseCode  , setAcqsCauseCode  ] = React.useState<string>("1035");
    const [ancillaryLandModalOpen       , setAncillaryLandModalOpen       ] = React.useState<boolean>(false); // 부수토지 도움말
    const [adjustmentAreasModalOpen     , setAdjustmentAreasModalOpen     ] = React.useState<boolean>(false); // 조정대상지역 도움말
    const [longRentalHousingModalOpen   , setLongRentalHousingModalOpen   ] = React.useState<boolean>(false); // 장기임대주책 도움말
    const [longSpecialDeductionModalOpen, setLongSpecialDeductionModalOpen] = React.useState<boolean>(false); // 장기보유특별공제 도움말
    const [transferTaxRateModalOpen     , setTransferTaxRateModalOpen     ] = React.useState<boolean>(false); // 양도세 세율 도움말

    const [cautionNo, setCautionNo] = React.useState<string>(""); // 주의사항 No[1(조합원입주권), 2(분양권)]
    const [longRentNotice  , setLongRentNotice  ] = React.useState<string>(""); // 장기임대주택 비과세 주의사항 유무[Y/N]
    const [transferTaxGuide, setTransferTaxGuide] = React.useState<any>({});    // 비과세 절세 가이드

    const getTrsftaxInfo = React.useCallback((trsfOpt: string, callFlag: number) => { // callFlag(0: 최초호출, 1: 변경버튼 호출)
        const paramStr = hshldSn + "," + assetsSn + "," + trsfOpt +
            (Util.isEmpty(trsfAmt) ? "" : ("?trsf_amt=" + Util.uncomma(trsfAmt))) +
            (Util.isEmpty(trsfDe) ? "" : ("&trsf_de=" + trsfDe)) +
            (Util.isEmpty(isPay) ? "" : ("&is_pay_lqamt=" + isPay)) +
            (Util.isEmpty(payAmt) ? "" : ("&pay_amt=" + Util.uncomma(payAmt))) +
            (Util.isEmpty(ncssryAmtBf) ? "" : ("&ncssry_amt_bf=" + Util.uncomma(ncssryAmtBf))) +
            (Util.isEmpty(ncssryAmt) ? "" : ("&ncssry_amt=" + Util.uncomma(ncssryAmt)));
        Axios.dataAccess({
            url: "api/system/broker/callApi.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                apiName: "taxcalc",
                tailUrl: "trsftax/trsftax",
                paramStr: paramStr
            },
            skipFailedAlert: true,
            onSuccessFn: (res: any) => {
                if (Util.isEmpty(res.item.rtn_list)) {
                    return;
                }
                setTrsftaxCalcModalVisible(true);
                setTrsftaxCalcInfo(res.item.rtn_list[0]);
                setCautionNo(res.item.rtn_list[0].notice_no);   // 주의사항 번호
                setLongRentNotice(res.item.rtn_list[0].long_rent_notice);   // 장기임대주택 비과세 주의사항 유무
                    
                // 비과세 절세 가이드
                if (Util.isNotEmpty(res.item.rtn_guide)) {
                    setTransferTaxGuide(res.item.rtn_guide[0]);
                }

                if (res.item.rtn_list[0].estate_detail_se === "1002") {
                    setAcqsCauseCode("1043");
                } else {
                    setAcqsCauseCode("1035");
                }
            },
            onFailedFn: (res: any) => {
                AppContext.openAlert({
                    title: "서비스 준비 중",
                    msg: res._msg_,
                    icon: "check",
                    handleClose: () => setTrsftaxCalcModalOpen(false)
                });
            }
        });
    }, [hshldSn, assetsSn, isPay, ncssryAmt, ncssryAmtBf, payAmt, trsfAmt, trsfDe, setTrsftaxCalcModalOpen, setTrsftaxCalcModalVisible]);

    React.useEffect(() => {
        getTrsftaxInfo("1", 0);
    }, [getTrsftaxInfo]);

    if (Util.isEmpty(trsftaxCalcInfo.tax_type)) {
        return (
            <div className='w1300'></div>
        )
    }
    return (
        <React.Fragment>
            <div className='w1300'>
                <div className='dpFlx aiC jcSb mb10'>
                    <div className='dpFlx aiC'>
                        <img className="pr10" src={require('img/system/assets/icon_listup.png')} alt="icon_listup" />
                        <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 fw500'>
                            양도세({trsftaxCalcInfo.tax_type})
                        </span>
                    </div>
                </div>
                {Util.isNotEmpty(trsftaxCalcInfo) ?
                    <React.Fragment>
                        <table className={
                            "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD tr-h36 mb20 " +
                            "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs13 th-fw500 " +
                            "td-fs13 td-fm-SpoqaHanSansNeo-Regular td-ls1 td-fc666666 td-bdcDDDDDD td-p10"
                        }>
                            <colgroup>
                                <col className="w130" />
                                <col className="w110" />
                                <col className="w110" />
                                <col className="w80" />
                                <col />
                                <col className="w120" />
                                <col className="w120" />
                                <col className="w130" />
                                <col className="w130" />
                                <col className="w160" />
                                <col className="w70" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>소재지</th>
                                    <td colSpan={10} className="taL">{trsftaxCalcInfo.rdnm_adres}</td>
                                </tr>
                                <tr>
                                    <th rowSpan={2}>양도물건</th>
                                    <th rowSpan={2}>양도일</th>
                                    <th rowSpan={2}>취득일</th>
                                    <th colSpan={2}>면적(㎡)</th>
                                    <th rowSpan={2}>용도지역</th>
                                    <th rowSpan={2}>
                                        <div className="dpFlx aiC" onClick={()=> {
                                            setAdjustmentAreasModalOpen(true);
                                        }}>
                                            조정대상지역<br/>(취득일)
                                            <img src={require('img/common/icon_info_fill_20.png')} alt="icon_info_fill_20" className='ml5 csP'
                                            />
                                        </div>
                                    </th>
                                    <th rowSpan={2}>종류</th>
                                    <th rowSpan={2}>용도</th>
                                    <th rowSpan={2}>취득원인</th>
                                    <th rowSpan={2}>지분율</th>
                                </tr>
                                <tr>
                                    <th>
                                        <div className="dpFlx aiC" onClick={() => {
                                            setAncillaryLandModalOpen(true);
                                        }}>
                                            토지
                                            <img src={require('img/common/icon_info_fill_20.png')} alt="icon_info_fill_20" className='csP pl3' />
                                        </div>
                                    </th>
                                    <th className="bdr1-i bdcDDDDDD-i">건물(단독:연면적/공동:전용면적)</th>
                                </tr>
                                <tr>
                                    <td>{Util.getValToCode("1006", trsftaxCalcInfo.estate_detail_se)}</td>
                                    <td>{Util.cvtDateStrForm(trsftaxCalcInfo.trsf_de, "-")}</td>
                                    <td>{Util.cvtDateStrForm(trsftaxCalcInfo.trsf_acqs_de, "-")}</td>
                                    <td>{Number(trsftaxCalcInfo.plot_ar).toFixed(2)}</td>
                                    <td>{Number(trsftaxCalcInfo.ar).toFixed(2)}</td>
                                    <td>{Util.getValToCode("1025", trsftaxCalcInfo.spfc_knd)}</td>
                                    <td>{trsftaxCalcInfo.mdat_trget_area_at}</td>
                                    <td>{Util.getValToCode("1007", trsftaxCalcInfo.estate_fom)}</td>
                                    <td>{Util.getValToCode("1008", trsftaxCalcInfo.estate_prpos)}</td>
                                    <td>{Util.getValToCode(acqsCauseCode, trsftaxCalcInfo.acqs_cause)}</td>
                                    <td>{Number(trsftaxCalcInfo.qota_rate).toFixed(0)} %</td>
                                </tr>
                            </tbody>
                        </table>
                    </React.Fragment>
                : <></>}
                <div className="w50p float-l pr10 bdBox">
                    <div className='dpFlx jcL aiC mb10'>
                        <span className='SpoqaHanSansNeo-Bold fs15 ls075 fc222222'>1. 세액계산</span>
                    </div>
                    {Util.isNotEmpty(trsftaxCalcInfo) && trsftaxCalcInfo.layout_type === "1" ?
                        <table className={
                            "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD tr-h36 " +
                            "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 " +
                            "td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls1 td-fc666666 td-bdcDDDDDD td-p15"
                        }>
                            <colgroup>
                                <col className="w250" />
                                <col />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>구분</th>
                                    <th className="taC">현부동산</th>
                                </tr>
                                <tr>
                                    <th>양도가액</th>
                                    <td className="taR">{Util.comma(trsftaxCalcInfo.trsf_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th>취득가액</th>
                                    <td className="taR">{Util.comma(trsftaxCalcInfo.acqs_rl_dlpc)} 원</td>
                                </tr>
                                <tr>
                                    <th>필요경비</th>
                                    <td className="taR">{Util.comma(trsftaxCalcInfo.ncssry_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th>양도차익</th>
                                    <td className="taR">{Util.comma(trsftaxCalcInfo.prft_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th>
                                        <div className="dpFlx aiC jcC"
                                            onClick={()=> {
                                                setLongSpecialDeductionModalOpen(true);
                                            }}
                                        >
                                            장기보유특별공제
                                            <img src={require('img/common/icon_info_fill_20.png')} alt="icon_info_fill_20" className='ml5 csP'
                                            />
                                        </div>
                                    </th>
                                    <td className="taR">{Util.comma(trsftaxCalcInfo.lng_hldng_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th>양도소득금액 * 지분율</th>
                                    <td className="taR">{Util.comma(trsftaxCalcInfo.trsf_incm_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th>양도소득기본공제</th>
                                    <td className="taR">{Util.comma(trsftaxCalcInfo.trsf_basic_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th>과세표준</th>
                                    <td className="taR">{Util.comma(trsftaxCalcInfo.std_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th>
                                        <div className="dpFlx aiC jcC"
                                            onClick={()=> {
                                                setTransferTaxRateModalOpen(true);
                                            }}
                                        >
                                            세율
                                            <img src={require('img/common/icon_info_fill_20.png')} alt="icon_info_fill_20" className='ml5 csP'
                                            />
                                        </div>
                                    </th>
                                    <td className="taR">{Util.comma(trsftaxCalcInfo.tax_rate)} %</td>
                                </tr>
                                <tr>
                                    <th>산출세액</th>
                                    <td className="taR">{Util.comma(trsftaxCalcInfo.trsf_tax_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th>공제/감면세액</th>
                                    <td className="taR">{Util.comma(trsftaxCalcInfo.law_rdc_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th>자진납부세액</th>
                                    <td className="taR">{Util.comma(trsftaxCalcInfo.rdc_trsf_tax_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th>지방소득세</th>
                                    <td className="taR">{Util.comma(trsftaxCalcInfo.cntry_incm_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th><span className="SpoqaHanSansNeo-Bold ls07">총 부담세액</span></th>
                                    <td className="taR"><span className="SpoqaHanSansNeo-Medium fs20 ls1 fc222222 fw500">{Util.comma(trsftaxCalcInfo.total_trsf_tax_amt)} 원</span></td>
                                </tr>
                            </tbody>
                        </table>
                    : <></>}
                    {Util.isNotEmpty(trsftaxCalcInfo) && trsftaxCalcInfo.layout_type === "2" ?
                        <table className={
                            "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD tr-h30 " +
                            "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 " +
                            "td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls1 td-fc666666 td-bdcDDDDDD td-p15"
                        }>
                            <colgroup>
                                <col className="w250" />
                                <col className="w148" />
                                <col className="w192" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>구분</th>
                                    <th className="taC">종전주택</th>
                                    <th className="taC">{trsftaxCalcInfo.rst_title}</th>
                                </tr>
                                <tr>
                                    <th>양도가액</th>
                                    <td colSpan={2} className="taR">{Util.comma(trsftaxCalcInfo.trsf_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th>취득가액</th>
                                    <td colSpan={2} className="taR">{Util.comma(trsftaxCalcInfo.acqs_rl_dlpc)} 원</td>
                                </tr>
                                <tr>
                                    <th>필요경비</th>
                                    <td className="taR">{Util.comma(trsftaxCalcInfo.ncssry_amt_bf)} 원</td>
                                    <td className="taR">{Util.comma(trsftaxCalcInfo.ncssry_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th>양도차익</th>
                                    <td className="taR">{Util.comma(trsftaxCalcInfo.prft_amt_bf)} 원</td>
                                    <td className="taR">{Util.comma(trsftaxCalcInfo.prft_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th>
                                        <div className="dpFlx aiC jcC"
                                            onClick={()=> {
                                                setLongSpecialDeductionModalOpen(true);
                                            }}
                                        >
                                            장기보유특별공제
                                            <img src={require('img/common/icon_info_fill_20.png')} alt="icon_info_fill_20" className='ml5 csP'/>
                                        </div>
                                    </th>
                                    <td className="taR">{Util.comma(trsftaxCalcInfo.lng_hldng_amt_bf)} 원</td>
                                    <td className="taR">{Util.comma(trsftaxCalcInfo.lng_hldng_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th>양도소득금액 * 지분율</th>
                                    <td colSpan={2} className="taR">{Util.comma(trsftaxCalcInfo.trsf_incm_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th>양도소득기본공제</th>
                                    <td colSpan={2} className="taR">{Util.comma(trsftaxCalcInfo.trsf_basic_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th>과세표준</th>
                                    <td colSpan={2} className="taR">{Util.comma(trsftaxCalcInfo.std_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th>
                                        <div className="dpFlx aiC jcC"
                                            onClick={()=> {
                                                setTransferTaxRateModalOpen(true);
                                            }}
                                        >
                                            세율
                                            <img src={require('img/common/icon_info_fill_20.png')} alt="icon_info_fill_20" className='ml5 csP'/>
                                        </div>
                                    </th>
                                    <td colSpan={2} className="taR">{Util.comma(trsftaxCalcInfo.tax_rate)} %</td>
                                </tr>
                                <tr>
                                    <th>산출세액</th>
                                    <td colSpan={2} className="taR">{Util.comma(trsftaxCalcInfo.trsf_tax_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th>공제/감면세액</th>
                                    <td colSpan={2} className="taR">{Util.comma(trsftaxCalcInfo.law_rdc_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th>자진납부세액</th>
                                    <td colSpan={2} className="taR">{Util.comma(trsftaxCalcInfo.rdc_trsf_tax_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th>지방소득세</th>
                                    <td colSpan={2} className="taR">{Util.comma(trsftaxCalcInfo.cntry_incm_amt)} 원</td>
                                </tr>
                                <tr>
                                    <th>총부담세액</th>
                                    <td colSpan={2} className="taR">{Util.comma(trsftaxCalcInfo.total_trsf_tax_amt)} 원</td>
                                </tr>
                            </tbody>
                        </table>
                    : <></>}
                </div>
                <div className="w50p float-r pl10 bdBox">
                    <div className='dpFlx jcL aiC mb10'>
                        <span className='SpoqaHanSansNeo-Bold fs15 ls075 fc222222'>2. 관련정보</span>
                    </div>
                    <table className={
                        "forest-list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD tr-h30 " +
                        "th-bdb1 th-bdr1 th-bdcDDDDDD th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fw500 " +
                        "td-fs15 td-fm-SpoqaHanSansNeo-Regular td-ls1 td-fc666666 td-bdcDDDDDD td-p15"
                    }>
                        <colgroup>
                            <col className="w150" />
                            <col className="w150" />
                            <col className="w150" />
                            <col className="w150" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>세대 주택 수</th>
                                <td className="taR">{trsftaxCalcInfo.hse_num}</td>
                                <th>주택 수 제외</th>
                                <td className="taR">{trsftaxCalcInfo.excpt_hse_cnt}</td>
                            </tr>
                            <tr>
                                <th>주택 수 제외 근거</th>
                                <td className="taL" colSpan={3}>
                                    <div className="ph10 wsPw">
                                    <span className="lh25">{trsftaxCalcInfo.excpt_house}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>설명</th>
                                <td className="taL" colSpan={3}>
                                    <div className="h100 ph10 wsPw">
                                        <span className="lh25">{trsftaxCalcInfo.rmk}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr className="h90-i">
                                <th>안내</th>
                                <td className="taL" colSpan={3}>
                                    {/* 절세가이드 */}
                                    {transferTaxGuide.taxfree_report === "Y" && (
                                        <div className="dpFlx w100p jcL">
                                            {/* <TaxSavingGuide transferTaxGuide={transferTaxGuide}></TaxSavingGuide> */}
                                            <TaxSavingGuideDesc transferTaxGuide={transferTaxGuide}></TaxSavingGuideDesc>
                                        </div>
                                    )}

                                    {/* 비과세 주의사항 표시 */}
                                    {cautionNo !== "0"  || longRentNotice === "Y" ?
                                        <div className="dpFlx w100p jcL">
                                            {/* <CautionBanner></CautionBanner> */}
                                            {/* <TaxExemptCaution></TaxExemptCaution> */}
                                            <TaxExemptCautionDesc cautionNo={cautionNo} longRentNotice={longRentNotice}></TaxExemptCautionDesc>
                                        </div>  
                                        : <></>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* 부수토지 도움말 */}
            <Modal modalOpen={ancillaryLandModalOpen} setModalOpen={setAncillaryLandModalOpen} title="부수토지" dim={true} visible={true}>
                <AncillaryLand />
            </Modal>

            {/* 조정대상지역 도움말 */}
            <Modal modalOpen={adjustmentAreasModalOpen} setModalOpen={setAdjustmentAreasModalOpen} title="조정대상지역" dim={true} visible={true}>
                <div className='h500 ofA ofxH'>
                    <AdjustmentAreas />
                </div>
            </Modal>

            {/* 장기임대주택 도움말 */}
            <Modal modalOpen={longRentalHousingModalOpen} setModalOpen={setLongRentalHousingModalOpen} title="장기임대주택" dim={true} visible={true}>
                <div className='h500 ofA ofxH'>
                    <LongRentalHousing />
                </div>
            </Modal>

            {/* 장기보유특별공제 도움말 */}
            <Modal modalOpen={longSpecialDeductionModalOpen} setModalOpen={setLongSpecialDeductionModalOpen} title="장기보유특별공제" dim={true} visible={true}>
                <div className='h572 ofA ofxH'>
                    <LongSpecialDeduction />
                </div>
            </Modal>

            {/* 양도세 세율 도움말 */}
            <Modal modalOpen={transferTaxRateModalOpen} setModalOpen={setTransferTaxRateModalOpen} title="양도세 세율" dim={true} visible={true}>
                <div className='h320 ofA ofxH'>
                    <TransferTaxRate />
                </div>
            </Modal>
        </React.Fragment>
    )
}
export default TrsftaxCalc;