import styled from "styled-components";

import { MainSectionContainer, InputContainer } from "js/mobile/common/layout";
import {
  Code,
  PropTaxParamDisabled,
  PropTaxParamProps,
  PropTaxParamVisible,
} from "js/common/types/types";
import { PropTaxAction, PropTaxActionType } from "./propTaxStatesReducer";
import SelectBox, { SelectOption } from "js/mobile/common/SelectBox";
import Util from "js/common/Util";
import ToolTip from "js/common/ui/ToolTip";

type Props = {
  inputValue: PropTaxParamProps;
  inputVisible: PropTaxParamVisible;
  inputDisabled: PropTaxParamDisabled;
  onUpdateInput: (action: PropTaxAction) => void;
};

function ExtraInfo({
  inputValue,
  inputVisible,
  inputDisabled,
  onUpdateInput,
}: Props) {
  const createSelectHandleFunc = (
    selected: SelectOption,
    actionType: PropTaxActionType
  ) => {
    return onUpdateInput({
      type: actionType,
      payload: selected.value,
    });
  };

  return (
    <MainSectionContainer>
      <p className="desc">
        <span>자산의</span>
        <span className="bold"> 부가정보</span>
        <span>를 입력해주세요.</span>
      </p>
      {/* 04. 부부공동명의 */}
      {inputVisible.visible04 && (
        <InputContainer>
          <p className="label">부부공동명의</p>
          <div className="input">
            <SelectBox
              placeholder="부부공동명의 정보를 선택해주세요."
              options={Util.getCodeArray("2021").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              disabled={inputDisabled.disabled04}
              selectedValue={inputValue.coupleName}
              handleSelect={(selected: SelectOption) =>
                createSelectHandleFunc(selected, "UPDATED_COUPLE_NAME")
              }
            />
          </div>
        </InputContainer>
      )}
      {/* 05. 부부공동명의 특례적용 */}
      {inputVisible.visible05 && (
        <InputContainer>
          <TootipLabel>
            <p className="label">부부공동명의 특례적용</p>
            <div
              className="tootipWrap"
              data-tooltip-id="coupleSpecialToolTip"
              data-tooltip-content={
                "♠ 종합부동산세 부부 공동명의\n\n" +
                "부부 공동명의 1세대 1주택자(다른 세대원은 주택을 소유하지 않은 경우에 한함)가 단독명의 방식으로 종합부동산세 과세방식을 변경할 수 있다. 부부 공동명의 과세특례는 9월 16일부터 9월 30일까지 신청하여야 한다. 부부 공동명의 신청자에 한하여 단독 명의처럼 고령자 공제와 장기보유 공제 최대 80%의 세액공제를 받을 수 있다. 부부 공동명의 1세대 1주택 특례를 신청하면 부부 중 지분율이 가장 큰 사람이 납세의무가가 되며, 지분율이 동일한 경우에는 납세의무자를 선택할 수 있다."
              }
            >
              <img
                src={require("img/common/icon_search20_black.png")}
                alt="icon_info_fill_20"
                className="csP pl3"
              />
              <ToolTip
                id="coupleSpecialToolTip"
                place="top"
                clickable={true}
                className="mxw278 lh20 wsPw wwBw owBw"
              />
            </div>
          </TootipLabel>
          <div className="input">
            <SelectBox
              placeholder="부부공동명의 특례적용 정보를 선택해주세요."
              options={Util.getCodeArray("2022").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              selectedValue={inputValue.coupleSpecialCase}
              handleSelect={(selected: SelectOption) =>
                createSelectHandleFunc(selected, "UPDATED_COUPLE_SPECIAL_CASE")
              }
            />
          </div>
        </InputContainer>
      )}
      {/* 06. 보유기간 */}
      {inputVisible.visible06 && (
        <InputContainer>
          <p className="label">보유기간</p>
          <div className="input">
            <SelectBox
              placeholder="보유기간을 선택해주세요."
              options={Util.getCodeArray("2023").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              selectedValue={inputValue.holdingPeriod}
              handleSelect={(selected: SelectOption) =>
                createSelectHandleFunc(selected, "UPDATED_HOLDING_PERIOD")
              }
            />
          </div>
        </InputContainer>
      )}
      {/* 07. 소유자 연령 */}
      {inputVisible.visible07 && (
        <InputContainer>
          <p className="label">소유자 연령</p>
          <div className="input">
            <SelectBox
              placeholder="소유자 연령을 선택해주세요."
              options={Util.getCodeArray("2024").map((code: Code) => ({
                value: code.code,
                label: code.codeNm,
              }))}
              selectedValue={inputValue.ownerAge}
              handleSelect={(selected: SelectOption) =>
                createSelectHandleFunc(selected, "UPDATED_OWNER_AGE")
              }
            />
          </div>
        </InputContainer>
      )}
    </MainSectionContainer>
  );
}

const TootipLabel = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  margin-bottom: 5px;

  .label {
    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.29;
    letter-spacing: -0.058em;
    color: ${(props) => props.theme.color.nGray30};

    margin: 0;
  }

  .tootipWrap {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 20px;
    height: 20px;
  }
`;

export default ExtraInfo;
