import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import SubjectIcon from '@mui/icons-material/Subject';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

const IconEl = ({iconNm}: any) => {
    if (iconNm === "search") {
        return (
            <SearchOutlinedIcon sx={{padding: 0, paddingLeft: "3px", fontSize: 18}} />
        )
    } else if (iconNm === "add") {
        return (
            <img src={require('img/common/icon_add.png')} alt="icon_add" />
        )
    } else if (iconNm === "delete") {
        return (
            <HighlightOffOutlinedIcon sx={{padding: 0, paddingLeft: "3px", fontSize: 18}} />
        )
    } else if (iconNm === "check") {
        return (
            <CheckCircleOutlineOutlinedIcon sx={{padding: 0, paddingLeft: "3px", fontSize: 18}} />
        )
    } else if (iconNm === "list") {
        return (
            <SubjectIcon sx={{padding: 0, paddingLeft: "3px", fontSize: 18}} />
        )
    } else if (iconNm === "file") {
        return (
            <img src={require('img/common/icon_file20_white.png')} alt="icon_file20_white" />
        )
    } else if (iconNm === "write") {
        return (
            <CreateOutlinedIcon sx={{padding: 0, paddingLeft: "3px", paddingRight: "3px", fontSize: 18}} />
        )
    } else {
        return (
            <></>
        )
    }
}

interface IconButtonProps {
    btnName: string
    iconPos: "left" | "right"
    iconNm: "search" | "add" | "delete" | "check" | "list" | "file" | "write"
    className?: string
    textClassName?: string
    onClick: Function
}
export default function IconButton({ btnName, iconPos, iconNm, className, textClassName, onClick }: IconButtonProps) {
    return (
        <div
            className={"dpFlx jcC aiC csP bdBox " + (iconPos === "left" ? "pr13" : "") + (iconPos === "right" ? "pl13" : "") + " " + className}
            onClick={() => onClick()}
        >
            { iconPos === "left" ? <IconEl iconNm={iconNm} /> : <></> }
            <span className={textClassName}>{btnName}</span>
            { iconPos === "right" ? <IconEl iconNm={iconNm} /> : <></> }
        </div>
    );
}