/*******************************************************************************
*** TaxSavingGuideDialog ** 양도세 절세가이드 상세정보
*******************************************************************************/
import styled from "styled-components";
import Modal  from "js/common/ui/modal/Modal";
import Util   from "js/common/Util";
import React  from "react";

type Props = {
    open: boolean;
    setOpen: any;
    transferTaxGuide: any;
};

function TaxSavingGuideDialog({ open, setOpen, transferTaxGuide }: Props) {
    const [tableTitle01, setTableTitle01] = React.useState<string>(""); // 거주기간 정보 Table title 01
    const [tableTitle02, setTableTitle02] = React.useState<string>(""); // 거주기간 정보 Table title 02
    const living_cdn = transferTaxGuide.living_condition;
    
    React.useEffect(() => {
        if (living_cdn === "2") {
            setTableTitle01("종전주택");
            setTableTitle02("신규주택");
        } else if (living_cdn === "3") {
            setTableTitle01("상속인");
            setTableTitle02("피상속인");
        } else if (living_cdn === "4") {
            setTableTitle01("수증자");
            setTableTitle02("증여자");
        } else {}
        
    }, [living_cdn]);

    return (
        <Modal modalOpen={open} setModalOpen={setOpen} title={"절세가이드"} dim={true} visible={true}>
            <GuideModalContainer>
                <GuideModalTitleContainer>
                <div className="title">
                    <div className="iconWrap">
                        <img src={require("img/common/tax_saving_guide_modal_icon.png")} alt="caution icon" />
                    </div>
                    <h3>비과세 안내</h3>
                </div>
                <div className="text">
                    <div>
                    {/* <p>비과세 혜택이 소급적으로 철회될 수 있습니다.</p> */}
                    <p>절세가이드를 반드시 확인해주세요!</p>
                    </div>
                </div>
                <div className="logo">
                    <img src={require("img/common/logo_gray_bg.png")} alt="calltax logo" />
                </div>
                </GuideModalTitleContainer>
                <GuideContentContainer>
                <p className="title">비과세 기준 미충족</p>
                <div className="desc">
                    {/* 보유기간 기준 충족여부 설명 */}
                    <p>{transferTaxGuide.holding_message}</p>

                    {/* 거주기간 기준 충족여부 설명 */}
                    {
                        living_cdn !== "0" && ( 
                            <p>{transferTaxGuide.living_message}</p>
                        )
                    }
                    
                </div>

                {/* 보유기간 테이블 */}
                <DetailTable>
                    <thead>
                        <th>구분</th>
                        <th>취득일</th>
                        <th>양도일</th>
                        <th>경과기간</th>
                        <th>보유기준</th>
                        <th>기준일자</th>
                        <th>결과</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>보유기간</td>
                            <td>{Util.cvtDateStrForm(transferTaxGuide.holding_acqui_date, '-')}</td>
                            <td>{Util.cvtDateStrForm(transferTaxGuide.holding_trans_date, '-')}</td>
                            <td>{transferTaxGuide.holding_period}</td>
                            <td>{transferTaxGuide.holding_standard}</td>
                            <td>{Util.cvtDateStrForm(transferTaxGuide.holding_standard_date, '-')}</td>
                            <td>{transferTaxGuide.holding_result}</td>
                        </tr>
                    </tbody>
                </DetailTable>

                {/* 거주기간 정보 테이블 1 */}        
                {
                    living_cdn === "1" && (
                        <DetailTable>
                            <thead>
                                <th>구분</th>
                                <th>전입일</th>
                                <th>전출일</th>
                                <th>경과기간</th>
                                <th>거주기준</th>
                                <th>기준일자</th>
                                <th>결과</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>거주기간</td>
                                    <td>{Util.cvtDateStrForm(transferTaxGuide.living_new_in_date, '-')}</td>
                                    <td>{Util.cvtDateStrForm(transferTaxGuide.living_new_out_date, '-')}</td>
                                    <td>{transferTaxGuide.living_period}</td>
                                    <td>{transferTaxGuide.living_standard}</td>
                                    <td>{Util.cvtDateStrForm(transferTaxGuide.living_standard_date, '-')}</td>
                                    <td>{transferTaxGuide.living_result}</td>
                                </tr>
                            </tbody>
                        </DetailTable> 
                    )
                }

                {/* 거주기간 정보 테이블 2, 3, 4 */}        
                {
                    (living_cdn === "2" || living_cdn === "3" || living_cdn === "4") && (
                        <DetailTable>
                        <thead>
                            <tr>
                                <th rowSpan={2}>구분</th>
                                <th colSpan={2}>{tableTitle01}</th>
                                <th colSpan={2}>{tableTitle02}</th>
                                <th rowSpan={2}>경과기간</th>
                                <th rowSpan={2}>거주기준</th>
                                <th rowSpan={2}>기준일자</th>
                                <th rowSpan={2}>결과</th>
                            </tr>
                            <tr>
                                <th>전입일</th>
                                <th>전출일</th>
                                <th>전입일</th>
                                <th>전출일</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>거주기간</td>
                                <td>{Util.cvtDateStrForm(transferTaxGuide.living_pre_in_date, "-")}</td>
                                <td>{Util.cvtDateStrForm(transferTaxGuide.living_pre_out_date, "-")}</td>
                                <td>{Util.cvtDateStrForm(transferTaxGuide.living_new_in_date, "-")}</td>
                                <td>{Util.cvtDateStrForm(transferTaxGuide.living_new_out_date, "-")}</td>
                                <td>{transferTaxGuide.living_period}</td>
                                <td>{transferTaxGuide.living_standard}</td>
                                <td>{Util.cvtDateStrForm(transferTaxGuide.living_standard_date, "-")}</td>
                                <td>{transferTaxGuide.living_result}</td>
                            </tr>
                        </tbody>
                        </DetailTable>
                    )
                }        
                </GuideContentContainer>
            </GuideModalContainer>
        </Modal>
    );
}

const GuideModalContainer = styled.section`
    width: 750px;
    height: 100%;
    padding: 24px;
    background: linear-gradient(90deg, #e7ffed 0%, rgba(231, 255, 237, 0) 100%);
    border-radius: 20px;
`;

const GuideModalTitleContainer = styled.div`
    position: relative;
    width: 100%;

    .title {
        display: flex;
        align-items: center;

        height: 49px;

        .iconWrap {
            display: inline-block;
            width: 49px;
            height: auto;

            img {
                width: 100%;
                height: 100%;
            }
        }

        h3 {
            display: inline-block;

      font-family: "Spoqa Han Sans Neo";
      font-weight: 500;
      font-size: 18px;
      line-height: 1.47;
      letter-spacing: -0.016em;

            margin: 0;
        }
    }
    .text {
        margin-left: 49px;

    p {
      font-family: "Spoqa Han Sans Neo";
      font-weight: 300;
      font-size: 14px;
      line-height: 1.32;
      letter-spacing: -0.05em;

            margin: 0;
        }
    }

  .logo {
        position: absolute;
        bottom: 0;
        right: 0;

        width: 148px;
        height: 38px;

        img {
            width: 100%;
            height: 100%;
        }
    }
`;
const GuideContentContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 23px 28px;
    margin-top: 24px;
    background-color: white;
    border-radius: 10px;

  .title {
    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 14px;
    line-height: 1.32;
    letter-spacing: -0.05em;
    color: #414142;

        margin: 0 0 14px 0;
    }

  .desc {
    font-family: "Spoqa Han Sans Neo";
    font-weight: 300;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: -0.05em;

        margin-bottom: 14px;

        p {
            margin: 0;
        }
    }
`;

const DetailTable = styled.table`
    width: 100%;
    border-collapse: collapse;

  font-family: "Spoqa Han Sans Neo";
  font-weight: 300;
  font-size: 12px;
  line-height: 1.54;
  letter-spacing: -0.058em;

    thead {
        background-color: #f2f3f8;
        border-radius: 5px;

    th {
      font-family: "Spoqa Han Sans Neo";
      font-weight: 300;
      font-size: 12px;
      line-height: 1.54;
      letter-spacing: -0.058em;

            padding: 8px 10px;

            &:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            &:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }

    tr {
        border-bottom: 1px solid #dedfe0;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 300;
    font-size: 12px;
    line-height: 1.54;
    letter-spacing: -0.058em;
  }

  td {
    font-family: "Spoqa Han Sans Neo";
    font-weight: 300;
    font-size: 12px;
    line-height: 1.54;
    letter-spacing: -0.058em;
    text-align: center;

        padding: 8px 10px;
    }
`;

export default TaxSavingGuideDialog;
