/*******************************************************************************
 *** MypageUser ** 마이 페이지 > 회원 기본정보 수정
*******************************************************************************/
import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import * as Atom from "js/common/Atom";
import { useNavigate } from 'react-router-dom';
import TextBox from "js/common/ui/input/TextBox";
import JSEncrypt from 'jsencrypt'
import Util from "js/common/Util";
import Axios from "js/common/Axios";
import AppContext from "js/common/AppContext";

const MypageUser = () => {
    const navigate = useNavigate();
    const [password, setPassword] = React.useState<string>("");
    const [newPassword, setNewPassword] = React.useState<string>("");
    const [cfmNewPassword, setCfmNewPassword] = React.useState<string>("");
    const [userInfo, setUserInfo] = useRecoilState(Atom.userInfo);
    const [emailAdres, setEmailAdres] = React.useState<string>(userInfo.emailAdres);
    const setIsAdmin = useSetRecoilState(Atom.isAdmin);
    const [passwdChkMsg, setPasswdChkMsg] = React.useState<string>(""); // 비밀번호 패턴 확인 메시지

    // 비밀번호 패턴 체크
    const checkPassword = (password:string) => {
        if (Util.chkPasswdPtn(password)) {
            setPasswdChkMsg("");
        } else {
            setPasswdChkMsg("영문, 숫자, 특수문자를 포함한 8자 이상, 20자 이하로 입력하세요");
        }
    }

    const updUserInfo = () => {
        if (Util.isEmpty(emailAdres)) {
            AppContext.openAlert({
                title: "이메일 주소 미입력",
                msg: "이메일 주소를 입력하세요",
                icon: "check"
            });
            return;
        }

        if (emailAdres.indexOf("@") === -1) {
            AppContext.openAlert({
                title: "이메일 주소 확인",
                msg: "이메일 주소 형식이 맞지 않습니다.",
                icon: "check"
            });
            return;
        }

        if (Util.isEmpty(password)) {
            AppContext.openAlert({
                title: "기존 비밀번호 입력",
                msg: "기존 비밀번호를 입력하세요.",
                icon: "check"
            });
            return;
        }
        if (password.length < 8) {
            AppContext.openAlert({
                title: "기존 비밀번호 자릿수 확인",
                msg: "기존 비밀번호는 8자리 이상이어야 합니다.",
                icon: "check"
            });
            return;
        }
        if (Util.isNotEmpty(newPassword)) {
            if (newPassword.length < 8) {
                AppContext.openAlert({
                    title: "새 비밀번호 자릿수 확인",
                    msg: "새 비밀번호는 8자리 이상이어야 합니다.",
                    icon: "check"
                });
                return;
            }
            if (Util.isEmpty(cfmNewPassword)) {
                AppContext.openAlert({
                    title: "새 비밀번호 확인 입력",
                    msg: "새 비밀번호 확인을 입력하세요.",
                    icon: "check"
                });
                return;
            }
            if (newPassword !== cfmNewPassword) {
                AppContext.openAlert({
                    title: "비밀번호 불일치",
                    msg: "새 비밀번호가 일치하지 않습니다.",
                    icon: "check"
                });
                return;
            }
            if (password === newPassword) {
                AppContext.openAlert({
                    title: "기존 비밀번호와 일치",
                    msg: "새 비밀번호는 기존 비밀번호와 일치할 수 없습니다.",
                    icon: "check"
                });
                return;
            }
        }
        AppContext.openAlert({
            title: "회원정보 수정",
            msg: "회원정보를 수정하시겠습니까?",
            icon: "check",
            confirmText: "수정",
            handleConfirm: () => {
                setPassword("");
                setNewPassword("");
                setCfmNewPassword("");
                Axios.dataAccess({
                    url: "system/mypage/genRsaKeyByUserSn.do",
                    methodType: "post",
                    onSuccessFn: (res: any) => {
                        if (res.item.loginRslt !== "O") {
                            AppContext.hideSpinner();
                            AppContext.openAlert({
                                title: "회원정보 수정 실패",
                                msg: res.item.blockMsg,
                                icon: "warning"
                            });
                            return;
                        }
                        const pubKey = res.item.pubKeyStr;
                        const encrypt = new JSEncrypt();
                        encrypt.setPublicKey(pubKey);
                        Axios.dataAccess({
                            url: "system/mypage/updUserInfo.do",
                            methodType: "post",
                            paramType: "json",
                            paramData: {
                                emailAdres: emailAdres,
                                encPassword: encrypt.encrypt(password),
                                encNewPassword: Util.isNotEmpty(newPassword) ? encrypt.encrypt(newPassword) : null
                            },
                            onSuccessFn: (res: any) => {
                                setUserInfo(res.item);
                                localStorage.setItem("isAdmin", res.item.isAdmin ? "1" : "0");
                                setIsAdmin(res.item.isAdmin);
                                AppContext.openAlert({
                                    title: "회원정보 변경 완료",
                                    msg: "회원정보 변경이 완료되었습니다.",
                                    icon: "check"
                                });
                                navigate("/system/mypage"); // 마이 페이지로 이동
                            }
                        });
                    }
                });
            }
        });
    }

    return (
        <div className="bgcF6F6F6 pt60 pb100">
            <div className="w1160 m0auto">
                <table className={
                    "forest-table tr-h50 " +
                    "th-fm-SpoqaHanSansNeo-Medium th-fs14 th-ls07 th-fc222222 th-fw500 " +
                    "td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666"
                }>
                    <colgroup>
                        <col className="w160"/>
                        <col />
                        <col className="w160"/>
                        <col />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="taL">아이디</th>
                            <td>{userInfo.userId}</td>
                            <th className="pl40 taL">기존비밀번호</th>
                            <td>
                                <TextBox
                                    type="password"
                                    value={password}
                                    width={420}
                                    height={40}
                                    fontSize={15}
                                    fontFamily='Inter-Regular'
                                    phFontFamily='SpoqaHanSansNeo-Regular'
                                    maxLength={30}
                                    placeholder="기존 비밀번호를 입력하세요."
                                    onChangeFunc={(text: string) => setPassword(text)}
                                    inputClassName='bgcWhite ls075'
                                    color="#222222"
                                    offColor="#999999"
                                    className='bd1 pl15 pr15 bgcWhite'
                                    iconEl={
                                        <img
                                            onClick={() => setPassword("")}
                                            className={'csP ' + (Util.isEmpty(password) ? "dpNone" : "")}
                                            src={require('img/common/icon_cancel_fill_18.png')}
                                            alt="icon_cancel_fill_18"
                                        />
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="taL">성명</th>
                            <td>{userInfo.userNm}</td>
                            <th className="pl40 taL">새 비밀번호</th>
                            <td>
                                <TextBox
                                    type="password"
                                    value={newPassword}
                                    width={420}
                                    height={40}
                                    fontSize={15}
                                    fontFamily='Inter-Regular'
                                    phFontFamily='SpoqaHanSansNeo-Regular'
                                    maxLength={30}
                                    placeholder="변경할 비밀번호를 입력하세요."
                                    onChangeFunc={(text: string) => {
                                        setNewPassword(text);
                                        checkPassword(text);
                                    }}
                                    inputClassName='bgcWhite ls075'
                                    color="#222222"
                                    offColor="#999999"
                                    className='bd1 pl15 pr15 bgcWhite'
                                    iconEl={
                                        <img
                                            onClick={() => setNewPassword("")}
                                            className={'csP ' + (Util.isEmpty(newPassword) ? "dpNone" : "")}
                                            src={require('img/common/icon_cancel_fill_18.png')}
                                            alt="icon_cancel_fill_18"
                                        />
                                    }
                                />
                                <span className="SpoqaHanSansNeo-Regular fs13 ls065 wsPw fcFF0000">{passwdChkMsg}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className="taL">전화번호</th>
                            <td>{Util.cvtPhoneStrForm(userInfo.mbtlnum, "-")}</td>
                            <th className="pl40 taL">새 비밀번호 확인</th>
                            <td>
                                <TextBox
                                    type="password"
                                    value={cfmNewPassword}
                                    width={420}
                                    height={40}
                                    fontSize={15}
                                    fontFamily='Inter-Regular'
                                    phFontFamily='SpoqaHanSansNeo-Regular'
                                    maxLength={30}
                                    placeholder="변경할 비밀번호(확인)를 입력하세요."
                                    onChangeFunc={(text: string) => setCfmNewPassword(text)}
                                    inputClassName='bgcWhite ls075'
                                    color="#222222"
                                    offColor="#999999"
                                    className='bd1 pl15 pr15 bgcWhite'
                                    iconEl={
                                        <img
                                            onClick={() => setCfmNewPassword("")}
                                            className={'csP ' + (Util.isEmpty(cfmNewPassword) ? "dpNone" : "")}
                                            src={require('img/common/icon_cancel_fill_18.png')}
                                            alt="icon_cancel_fill_18"
                                        />
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="taL">이메일 주소</th>
                            <td>
                                <TextBox
                                    value={emailAdres}
                                    width={420}
                                    height={40}
                                    fontSize={15}
                                    fontFamily='SpoqaHanSansNeo-Regular'
                                    maxLength={30}
                                    placeholder="이메일 주소를 입력하세요."
                                    onChangeFunc={(text: string) => setEmailAdres(text)}
                                    inputClassName='bgcWhite ls075'
                                    color="#666666"
                                    className='bd1 pl15 pr15 bgcWhite'
                                    iconEl={
                                        <img
                                            onClick={() => setEmailAdres("")}
                                            className={'csP ' + (Util.isEmpty(emailAdres) ? "dpNone" : "")}
                                            src={require('img/common/icon_cancel_fill_18.png')}
                                            alt="icon_cancel_fill_18"
                                        />
                                    }
                                />
                            </td>
                            <th></th>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <div className="pl128">
                    <span className="SpoqaHanSansNeo-Regular fs13 ls065 wsPw fc17A840 pl50">아이디/비밀번호 찾기 시 사용되오니 정확한 이메일 주소를 입력해 주세요.</span>
                </div>
            </div>
            <div className='w100p dpFlx aiC jcC pt40'>
                <div className='w210 h56 bgcWhite dpFlx aiC jcC csP' onClick={() => navigate("/system/mypage")}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>취소</span>
                </div>
                <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={updUserInfo}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>저장</span>
                </div>
            </div>
        </div>
    )
}
export default MypageUser;