import { ChangeEvent } from "react";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

import { addrSearchResultState } from "js/common/Atom";
import { useDongHoSelect } from "js/common/hooks/useDongHoSelect";
import SelectBox, { SelectOption } from "js/mobile/common/SelectBox";
import TextInput from "js/mobile/AcqsTaxCalc/TextInput";
import Util from "js/common/Util";

type Props = {
  updateSelectedDong?: (selected: string) => void;
  updateSelectedHo?: (selected: string) => void;
};

function DongHoSelect({ updateSelectedDong, updateSelectedHo }: Props) {
  const addrSearchResult = useRecoilValue(addrSearchResultState);
  const {
    aptDongList,
    onSelectAptDong,
    aptHoList,
    onSelectAptHo,
    onChangeBuildingName,
    multiHoList,
  } = useDongHoSelect();

  const handleSelectAptDong = (selected: SelectOption) => {
    onSelectAptDong(selected.value);
    updateSelectedDong && updateSelectedDong(selected.value);
  };

  const handleSelectAptHo = (selected: SelectOption) => {
    onSelectAptHo(selected.value);
    updateSelectedHo && updateSelectedHo(selected.value);
  };

  const handleChangeTextInput = (event: ChangeEvent<HTMLInputElement>) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    if (inputName === "dong") {
      onSelectAptDong(inputValue);
    } else if (inputName === "ho") {
      onSelectAptHo(inputValue);
    } else if (inputName === "bdnm") {
      onChangeBuildingName(inputValue);
    }
  };

  if (!addrSearchResult.addr) {
    return <></>;
  } else if (addrSearchResult.addr.bdKdcd === "1" && aptDongList.length >= 1) {
    return (
      <>
        <InputContainer>
          <p className="label">건물명</p>
          <TextInput
            name="bdnm"
            value={
              addrSearchResult.addr.bdNm
                ? addrSearchResult.addr.bdNm
                : addrSearchResult.bdNm
            }
            onChange={handleChangeTextInput}
            disabled={addrSearchResult.addr.bdNm ? true : false}
            placeholder="건물명을 입력해주세요."
          />
        </InputContainer>
        <InputContainer>
          <p className="label">동</p>
          <div className="input">
            {Util.isNotEmpty(aptDongList) ? (
              <SelectBox
                placeholder="동을 선택해주세요."
                options={aptDongList.map((dong: string) => ({
                  value: dong,
                  label: dong,
                }))}
                disabled={false}
                selectedValue={addrSearchResult.dong}
                handleSelect={handleSelectAptDong}
              />
            ) : (
              <TextInput
                name="dong"
                value={addrSearchResult.dong}
                onChange={handleChangeTextInput}
                placeholder="동 정보를 입력해주세요."
                unit="동"
              />
            )}
          </div>
        </InputContainer>
        <InputContainer>
          <p className="label">호</p>
          <div className="input">
            {Util.isNotEmpty(aptHoList) ? (
              <SelectBox
                placeholder="호를 선택해주세요."
                options={aptHoList.map((dong: string) => ({
                  value: dong,
                  label: dong,
                }))}
                disabled={false}
                selectedValue={addrSearchResult.ho}
                handleSelect={handleSelectAptHo}
              />
            ) : (
              <TextInput
                name="ho"
                value={addrSearchResult.ho}
                onChange={handleChangeTextInput}
                placeholder="호 정보를 입력해주세요."
                unit="호"
              />
            )}
          </div>
        </InputContainer>
      </>
    );
  } else if (addrSearchResult.addr.bdKdcd === "1" && aptDongList.length < 1) {
    return (
      <>
        <InputContainer>
          <p className="label">건물명</p>
          <TextInput
            name="bdnm"
            value={
              addrSearchResult.addr.bdNm
                ? addrSearchResult.addr.bdNm
                : addrSearchResult.bdNm
            }
            onChange={handleChangeTextInput}
            disabled={addrSearchResult.addr.bdNm ? true : false}
            placeholder="건물명을 입력해주세요."
          />
        </InputContainer>
        <InputContainer>
          <p className="label">호</p>
          <div className="input">
            {Util.isNotEmpty(multiHoList) ? (
              <SelectBox
                placeholder="호를 선택해주세요."
                options={multiHoList.map((dong: string) => ({
                  value: dong,
                  label: dong,
                }))}
                disabled={false}
                selectedValue={addrSearchResult.ho}
                handleSelect={handleSelectAptHo}
              />
            ) : (
              <TextInput
                name="ho"
                value={addrSearchResult.ho}
                onChange={handleChangeTextInput}
                placeholder="호 정보를 입력해주세요."
                unit="호"
              />
            )}
          </div>
        </InputContainer>
      </>
    );
  } else if (addrSearchResult.addr.bdKdcd === "0") {
    let dongHoInputType = "";
    if (
      aptDongList.length > 0 &&
      aptHoList.length > 0 &&
      multiHoList.length === 0
    ) {
      dongHoInputType = "dongSelHoSel";
    } else if (
      aptDongList.length === 0 &&
      aptHoList.length === 0 &&
      multiHoList.length > 0
    ) {
      dongHoInputType = "multiHo";
    } else if (
      aptDongList.length > 0 &&
      aptHoList.length === 0 &&
      multiHoList.length === 0
    ) {
      dongHoInputType = "dongSelHoText";
    } else if (
      aptDongList.length === 0 &&
      aptHoList.length === 0 &&
      multiHoList.length === 0
    ) {
      dongHoInputType = "dongTextHoText";
    } else {
      dongHoInputType = "none";
    }
    return (
      <>
        <InputContainer>
          <p className="label">건물명(선택)</p>
          <TextInput
            name="bdnm"
            value={
              addrSearchResult.addr.bdNm
                ? addrSearchResult.addr.bdNm
                : addrSearchResult.bdNm
            }
            onChange={handleChangeTextInput}
            placeholder="건물명을 입력해주세요."
          />
        </InputContainer>
        {dongHoInputType === "dongSelHoSel" && (
          <>
            <InputContainer>
              <p className="label">동(선택)</p>
              <div className="input">
                <SelectBox
                  placeholder="동을 선택해주세요."
                  options={aptDongList.map((dong: string) => ({
                    value: dong,
                    label: dong,
                  }))}
                  disabled={false}
                  selectedValue={addrSearchResult.dong}
                  handleSelect={handleSelectAptDong}
                />
              </div>
            </InputContainer>
            <InputContainer>
              <p className="label">호(선택)</p>
              <div className="input">
                <SelectBox
                  placeholder="호를 선택해주세요."
                  options={aptHoList.map((dong: string) => ({
                    value: dong,
                    label: dong,
                  }))}
                  disabled={false}
                  selectedValue={addrSearchResult.ho}
                  handleSelect={handleSelectAptHo}
                />
              </div>
            </InputContainer>
          </>
        )}
        {dongHoInputType === "multiHo" && (
          <>
            <InputContainer>
              <p className="label">호(선택)</p>
              <div className="input">
                <SelectBox
                  placeholder="호를 선택해주세요."
                  options={multiHoList.map((dong: string) => ({
                    value: dong,
                    label: dong,
                  }))}
                  disabled={false}
                  selectedValue={addrSearchResult.ho}
                  handleSelect={handleSelectAptHo}
                />
              </div>
            </InputContainer>
          </>
        )}
        {dongHoInputType === "dongSelHoText" && (
          <>
            <InputContainer>
              <p className="label">동(선택)</p>
              <div className="input">
                <SelectBox
                  placeholder="동을 선택해주세요."
                  options={aptDongList.map((dong: string) => ({
                    value: dong,
                    label: dong,
                  }))}
                  disabled={false}
                  selectedValue={addrSearchResult.dong}
                  handleSelect={handleSelectAptDong}
                />
              </div>
            </InputContainer>
            <InputContainer>
              <p className="label">호(선택)</p>
              <div className="input">
                <TextInput
                  name="ho"
                  value={addrSearchResult.ho}
                  onChange={handleChangeTextInput}
                  placeholder="호 정보를 입력해주세요."
                  unit="호"
                />
              </div>
            </InputContainer>
          </>
        )}
        {dongHoInputType === "dongTextHoText" && (
          <>
            <InputContainer>
              <p className="label">동(선택)</p>
              <div className="input">
                <TextInput
                  name="dong"
                  value={addrSearchResult.dong}
                  onChange={handleChangeTextInput}
                  placeholder="동 정보를 입력해주세요."
                  unit="동"
                />
              </div>
            </InputContainer>
            <InputContainer>
              <p className="label">호(선택)</p>
              <div className="input">
                <TextInput
                  name="ho"
                  value={addrSearchResult.ho}
                  onChange={handleChangeTextInput}
                  placeholder="호 정보를 입력해주세요."
                  unit="호"
                />
              </div>
            </InputContainer>
          </>
        )}
        {dongHoInputType === "none" && (
          <>
            <InputContainer>
              <p className="label">동(선택)</p>
              <div className="input">
                <TextInput
                  name="dong"
                  value={addrSearchResult.dong}
                  onChange={handleChangeTextInput}
                  placeholder="동 정보를 입력해주세요."
                  unit="동"
                />
              </div>
            </InputContainer>
            <InputContainer>
              <p className="label">호(선택)</p>
              <div className="input">
                <TextInput
                  name="ho"
                  value={addrSearchResult.ho}
                  onChange={handleChangeTextInput}
                  placeholder="호 정보를 입력해주세요."
                  unit="호"
                />
              </div>
            </InputContainer>
          </>
        )}
      </>
    );
  } else {
    return <></>;
  }
}

const InputContainer = styled.div`
  width: 100%;
  margin-top: 12px;

  .label {
    margin-bottom: 5px;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.29;
    letter-spacing: -0.058em;
    color: ${(props) => props.theme.color.nGray30};
  }
`;

export default DongHoSelect;
