import React from 'react'

interface LawCndConfirmProps {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    callBack: Function
}
export default function LawCndConfirm({ setModalOpen, callBack }: LawCndConfirmProps) {
    return (
        <div className='dpFlx flxdCol'>
            <div className='w215 dpFlx aiC jcC bdBox pb40'>
                <span className="SpoqaHanSansNeo-Medium fs18 ls09 fc222222">위의 조건을 모두 충족하는 주택인가요?</span>
            </div>
            <div className='w100p dpFlx aiC jcC'>
                <div className='w210 h56 bgcF6F6F6 dpFlx aiC jcC csP' onClick={() => {
                    callBack("N");
                    setModalOpen(false);
                }}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>아니오</span>
                </div>
                <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => {
                    callBack("Y");
                    setModalOpen(false);
                }}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>예</span>
                </div>
            </div>
        </div>
    )
}
