import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/ko";
import dayjs from "dayjs";
import Util from "js/common/Util";

interface DatePickerBoxProps {
  width: number;
  height: number;
  fontSize: number;
  textAlign: string;
  disabled?: boolean;
  onChange: Function;
  value?: string;
  bgColor?: string;
}

const DatePickerTheme = (theme: any) =>
  createTheme({
    ...theme,
    components: {
      MuiPickersMonth: {
        styleOverrides: {
          monthButton: {
            "&.Mui-selected": {
              color: "white",
              backgroundColor: "#17a840 !important",
            },
          },
        },
      },
      MuiPickersYear: {
        styleOverrides: {
          yearButton: {
            "&.Mui-selected": {
              color: "white",
              backgroundColor: "#17a840 !important",
            },
          },
        },
      },
    },
  });

function DatePickerBox({
  width,
  height,
  fontSize,
  textAlign,
  disabled,
  onChange,
  value,
  bgColor,
}: DatePickerBoxProps) {
  const theme = createTheme(); // 기본 테마 가져오기
  const datePickerTheme = DatePickerTheme(theme);

  return (
    <ThemeProvider theme={datePickerTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
        <DemoContainer
          components={["DatePicker"]}
          sx={{
            paddingTop: 0,
            overflow: "inherit",
            width: width + "px",
            height: height + "px",
            background: Util.isNotEmpty(bgColor) ? bgColor : "#transparent",
            ".MuiOutlinedInput-root": {
              fontFamily: "SpoqaHanSansNeo-Regular",
              borderRadius: "0",
              width: "100%",
            },
            ".MuiOutlinedInput-root:focus": {
              borderColor: "#2B2B2B",
            },
            ".MuiTextField-root": { width: "100%", minWidth: "0 !important" },
            ".MuiFormLabel-root": {
              fontSize: 12,
              fontFamily: "SpoqaHanSansNeo-Regular",
            },
            ".MuiInputBase-root": {
              lineHeight: 0,
              letterSpacing: 0,
              height: height,
              paddingRight: 0,
            },
            ".MuiInputBase-input": {
              fontSize: fontSize + "px",
              padding: "0 30px 0 15px",
              border: "1px solid #dddddd",
              height: "100%",
              boxSizing: "border-box",
              animation: "none",
              fontFamily: "SpoqaHanSansNeo-Regular",
              textAlign: textAlign,
            },
            ".MuiInputBase-input:focus, .MuiInputBase-input:hover": {
              borderColor: disabled ? "#dddddd" : "#2B2B2B",
            },
            ".MuiOutlinedInput-notchedOutline": {
              borderWidth: "1 !important",
              padding: 0,
              display: "none",
            },
            ".MuiInputAdornment-root": { position: "absolute", right: 15 },
            ".MuiIconButton-root": { padding: 0, margin: 0 },
          }}
        >
          <DatePicker
            format="YYYY. MM. DD"
            value={dayjs(Util.isEmpty(value) ? "" : value)}
            views={["year", "month", "day"]}
            onChange={(data) => {
              if (Util.isNotEmpty(onChange) && Util.isNotEmpty(data)) {
                onChange(data);
              } else if (Util.isEmpty(data)) {
                onChange("");
              }
            }}
            disabled={disabled}
            sx={{
              ".MuiInputBase-input::placeholder": { color: "#999999" },
              ".MuiPickersDay-root": {
                fontSize: "1.2rem", // 날짜 셀의 폰트 크기 변경
              },
              ".MuiPickersCalendarHeader-label": {
                fontSize: "1.5rem", // 캘린더 헤더의 폰트 크기 변경
              },
              ".MuiPickersCalendarHeader-switchViewButton": {
                fontSize: "1.2rem", // 월/년 변경 버튼의 폰트 크기 변경
              },
              ".MuiPickersSlideTransition-root": {
                fontSize: "1.2rem", // 캘린더 내 날짜 전환 애니메이션 폰트 크기
              },
            }}
            components={{
              OpenPickerIcon: () => (
                <img
                  src={require("img/common/icon_calendar_month_" +
                    (disabled ? "in" : "") +
                    "active20.png")}
                  alt="right-arrow"
                />
              ),
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default DatePickerBox;
