/*******************************************************************************
*** SimAcqsAutoTax ** 취득세 간편계산기 * 주소 API/직접 검색 자동 분기
*******************************************************************************/
import AppContext      from "js/common/AppContext";
import Util            from "js/common/Util";
import NumberTextBox   from "js/common/ui/input/NumberTextBox";
import SelectBox       from "js/common/ui/input/SelectBox";
import Modal           from "js/common/ui/modal/Modal";
import React           from "react";
import SimAcqsTaxCalc  from "./SimAcqsTaxCalc";
import ImageRadioBox   from "js/common/ui/input/ImageRadioBox";
import DirectAddressIn from "js/common/address/DirectAddressIn";
import SimAddress      from "js/common/address/SimAddress";

interface SimAcqsAutoTaxProps {
    apiResult: string;
}

const SimAcqsDirectTax = ({apiResult}: SimAcqsAutoTaxProps) => {
    // 입력항목 표시 여부
    const [visible01, setVisible01] = React.useState<boolean>(true ); // 01. 부동산의 종류(상세구분)
    const [visible02, setVisible02] = React.useState<boolean>(false); // 02. 주택구분
    const [visible03, setVisible03] = React.useState<boolean>(false); // 03. 취득구분 1(아파트/연립)
    const [visible04, setVisible04] = React.useState<boolean>(false); // 04. 취득구분 2(오피스텔)
    const [visible05, setVisible05] = React.useState<boolean>(false); // 05. 멸실여부
    const [visible06, setVisible06] = React.useState<boolean>(false); // 06. 토지종류
    const [visible07, setVisible07] = React.useState<boolean>(false); // 07. 자경기간
    const [visible08, setVisible08] = React.useState<boolean>(false); // 08. 취득원인 1(기존 아파트)
    const [visible09, setVisible09] = React.useState<boolean>(false); // 09. 취득원인 2(분양권/오피스텔)
    const [visible10, setVisible10] = React.useState<boolean>(false); // 10. 취득원인 3(조합원입주권)
    const [visible11, setVisible11] = React.useState<boolean>(false); // 11. 취득원인 4(다세대/다가구/다중/단독주택)
    const [visible12, setVisible12] = React.useState<boolean>(false); // 12. 조정대상지역
    const [visible13, setVisible13] = React.useState<boolean>(false); // 13. 주택수 1(조정대상지역)
    const [visible14, setVisible14] = React.useState<boolean>(false); // 14. 주택수 2(비조정대상지역)
    const [visible15, setVisible15] = React.useState<boolean>(false); // 15. 상속인의 주택유무
    const [visible16, setVisible16] = React.useState<boolean>(false); // 16. 시가표준액 기준
    const [visible17, setVisible17] = React.useState<boolean>(false); // 17. 증여자
    const [visible18, setVisible18] = React.useState<boolean>(false); // 18. 증여자의 주택수
    const [visible19, setVisible19] = React.useState<boolean>(false); // 19. 과세표준
    const [visible20, setVisible20] = React.useState<boolean>(false); // 20. 지분율
    const [visible21, setVisible21] = React.useState<boolean>(false); // 21. 면적
    const [visible22, setVisible22] = React.useState<boolean>(false); // 22. 취득할 자산정보
    const [visible23, setVisible23] = React.useState<boolean>(false); // 23. 추가 버튼
    const [visible24, setVisible24] = React.useState<boolean>(false); // 24. 자산정보 Table

    // 입력항목 입력 가능여부
    const [disabled01, setDisabled01] = React.useState<boolean>(false); // 01. 부동산의 종류(상세구분)
    const [disabled02, setDisabled02] = React.useState<boolean>(false); // 02. 주택구분
    const [disabled03, setDisabled03] = React.useState<boolean>(false); // 03. 취득구분 1(아파트/연립)
    const [disabled04, setDisabled04] = React.useState<boolean>(false); // 04. 취득구분 2(오피스텔)
    const [disabled05, setDisabled05] = React.useState<boolean>(false); // 05. 멸실여부
    const [disabled06, setDisabled06] = React.useState<boolean>(false); // 06. 토지종류
    const [disabled07, setDisabled07] = React.useState<boolean>(false); // 07. 자경기간
    const [disabled08, setDisabled08] = React.useState<boolean>(false); // 08. 취득원인 1(기존 아파트)
    const [disabled09, setDisabled09] = React.useState<boolean>(false); // 09. 취득원인 2(분양권/오피스텔)
    const [disabled10, setDisabled10] = React.useState<boolean>(false); // 10. 취득원인 3(조합원입주권)
    const [disabled11, setDisabled11] = React.useState<boolean>(false); // 11. 취득원인 4(다세대/다가구/다중/단독주택)
    const [disabled12, setDisabled12] = React.useState<boolean>(false); // 12. 조정대상지역
    const [disabled16, setDisabled16] = React.useState<boolean>(false); // 16. 시가표준액

    const [disabled20, setDisabled20] = React.useState<boolean>(false); // 20. 지분율
    const [disabled21, setDisabled21] = React.useState<boolean>(false); // 21. 면적

    // 입력 항목 value
    const [estateSe    , setEstateSe    ] = React.useState<string>("");   // 01. 부동산의 종류(상세구분)
    const [estateFom   , setEstateFom   ] = React.useState<string>("");   // 02. 주택구분
    const [acqsSrc1    , setAcqsSrc1    ] = React.useState<string>("");   // 03. 취득구분 1(아파트/연립)
    const [acqsSrc2    , setAcqsSrc2    ] = React.useState<string>("");   // 04. 취득구분 2(오피스텔)
    const [loseYn      , setLoseYn      ] = React.useState<string>("");   // 05. 멸실여부
    const [landType    , setLandType    ] = React.useState<string>("");   // 06. 토지종류
    const [farmPeriod  , setFarmPeriod  ] = React.useState<string>("");   // 07. 자경기간
    const [acqsCause1  , setAcqsCause1  ] = React.useState<string>("");   // 08. 취득원인 1(기존 아파트)
    const [acqsCause2  , setAcqsCause2  ] = React.useState<string>("");   // 09. 취득원인 2(분양권/오피스텔)
    const [acqsCause3  , setAcqsCause3  ] = React.useState<string>("");   // 10. 취득원인 3(조합원입주권)
    const [acqsCause4  , setAcqsCause4  ] = React.useState<string>("");   // 11. 취득원인 4(다세대/다가구/다중/단독주택)
    const [mdatTrgetAre, setMdatTrgetAre] = React.useState<string>("");   // 12. 조정대상지역**
    const [hseNumAt1   , setHseNumAt1   ] = React.useState<string>("");   // 13. 주택수 1(조정대상지역)
    const [hseNumAt2   , setHseNumAt2   ] = React.useState<string>("");   // 14. 주택수 2(비조정대상지역)
    const [inheirHseNo , setInheirHseNo ] = React.useState<string>("");   // 15. 상속인의 주택유무
    const [stdMkPrice  , setStdMkPrice  ] = React.useState<string>("");   // 16. 시가표준액 기준**
    const [donor       , setDonor       ] = React.useState<string>("");   // 17. 증여자
    const [donorHseNo  , setDonorHseNo  ] = React.useState<string>("");   // 18. 증여자의 주택수
    const [taxBase     , setTaxBase     ] = React.useState<string>("");   // 19. 과세표준
    const [shareRatio  , setShareRatio  ] = React.useState<string>("");   // 20. 지분율**
    const [prvuseAr    , setPrvuseAr    ] = React.useState<string>("");   // 21. 면적**

    // 취득할 자산정보 Table용
    const [assetRdnmAdres   , setAssetRdnmAdres   ] = React.useState<string>(""); // 22.도로명 주소
    const [assetLnmAdres    , setAssetLnmAdres    ] = React.useState<string>(""); // 23.지번 주소
    const [assetStdPrice    , setAssetStdPrice    ] = React.useState<string>(""); // **.공동/개별 주택가격(기준시가) - 16.시가표준액 기준
    const [assetAreaInfo    , setAssetAreaInfo    ] = React.useState<string>(""); // **.공동주택 전용면적/단독주택 주택연면적 - 21.면적
    const [assetShareRatio  , setAssetShareRatio  ] = React.useState<string>(""); // **.지분율       - 20.지분율
    const [assetMdatTrgetAre, setAssetMdatTrgetAre] = React.useState<string>(""); // **.조정대상지역 - 12.조정대상지역

    const [taxBaseHelp , setTaxBaseHelp ] = React.useState<string>("");   // ** 과세표준 입력 도움말
    const [houseNumHelp, setHouseNumHelp] = React.useState<string>("");   // ** 주택수 선택 도움말

    const [addressModalOpen     , setAddressModalOpen     ] = React.useState<boolean>(false); // API 주소검색 모달 호출변수
    const [dirAddressInModalOpen, setDirAddressInModalOpen] = React.useState<boolean>(false); // 직접 입력 주소검색 모달 호출변수

    const [simAcqsTaxCalcModalOpen, setSimAcqsTaxCalcModalOpen] = React.useState<boolean>(false);   // 취득세 간편계산기 결과 모달
    const [simAcqsTaxCalcModalVsb , setSimAcqsTaxCalcModalVsb ] = React.useState<boolean>(false);   // 취득세 간편계산기 결과 표시 여부
    const [acqsTaxParam           , setAcqsTaxParam           ] = React.useState<AcqsTaxParamProps[]>([]);   // 취득세 계산용 파라미터 배열

    // 취득세 계산 입력정보 Setting
    interface  AcqsTaxParamProps {
        taxFlag     : string;
        estateSe    : string;  // 01. 부동산의 종류(상세구분)
        estateFom   : string;  // 02. 주택구분
        acqsSrc1    : string;  // 03. 취득구분 1(아파트/연립)
        acqsSrc2    : string;  // 04. 취득구분 2(오피스텔)
        loseYn      : string;  // 05. 멸실여부
        landType    : string;  // 06. 토지종류
        farmPeriod  : string;  // 07. 자경기간
        acqsCause1  : string;  // 08. 취득원인 1(기존 아파트)
        acqsCause2  : string;  // 09. 취득원인 2(분양권/오피스텔)
        acqsCause3  : string;  // 10. 취득원인 3(조합원입주권)
        acqsCause4  : string;  // 11. 취득원인 4(다세대/다가구/다중/단독주택)
        mdatTrgetAre: string;  // 12. 조정대상지역
        hseNumAt1   : string;  // 13. 주택수 1(조정대상지역)
        hseNumAt2   : string;  // 14. 주택수 2(비조정대상지역)
        inheirHseNo : string;  // 15. 상속인의 주택유무
        stdMkPrice  : string;  // 16. 시가표준액 기준
        donor       : string;  // 17. 증여자
        donorHseNo  : string;  // 18. 증여자의 주택수
        taxBase     : string;  // 19. 과세표준
        shareRatio  : string;  // 20. 지분율
        prvuseAr    : string;  // 21. 면적
    }

    // 입력 값 초기화
    const goReset = () => {
        setEstateSe    ("");   // 01. 부동산의 종류(상세구분)
        setEstateFom   ("");   // 02. 주택구분
        setAcqsSrc1    ("");   // 03. 취득구분 1(아파트/연립)
        setAcqsSrc2    ("");   // 04. 취득구분 2(오피스텔)
        setLoseYn      ("");   // 05. 멸실여부
        setLandType    ("");   // 06. 토지종류
        setFarmPeriod  ("");   // 07. 자경기간
        setAcqsCause1  ("");   // 08. 취득원인 1(기존 아파트)
        setAcqsCause2  ("");   // 09. 취득원인 2(분양권/오피스텔)
        setAcqsCause3  ("");   // 10. 취득원인 3(조합원입주권)
        setAcqsCause4  ("");   // 11. 취득원인 4(다세대/다가구/다중/단독주택)
        setMdatTrgetAre("");   // 12. 조정대상지역
        setHseNumAt1   ("");   // 13. 주택수 1(조정대상지역)
        setHseNumAt2   ("");   // 14. 주택수 2(비조정대상지역)
        setInheirHseNo ("");   // 15. 상속인의 주택유무
        setStdMkPrice  ("");   // 16. 시가표준액 기준
        setDonor       ("");   // 17. 증여자
        setDonorHseNo  ("");   // 18. 증여자의 주택수
        setTaxBase     ("");   // 19. 과세표준
        setShareRatio  ("");   // 20. 지분율
        setPrvuseAr    ("");   // 21. 면적

        // 취득할 자산정보 Table용
        setAssetRdnmAdres   (""); // 22.도로명 주소
        setAssetLnmAdres    (""); // 23.지번 주소
        setAssetStdPrice    (""); // **.공동/개별 주택가격(기준시가) - 16.시가표준액 기준
        setAssetAreaInfo    (""); // **.공동주택 전용면적/단독주택 주택연면적 - 21.면적
        setAssetShareRatio  (""); // **.지분율       - 20.지분율
        setAssetMdatTrgetAre(""); // **.조정대상지역 - 12.조정대상지역

        setVisible01(true );   // 01. 부동산의 종류(상세구분)
        setVisible02(false);   // 02. 주택구분
        setVisible03(false);   // 03. 취득구분 1(아파트/연립)
        setVisible04(false);   // 04. 취득구분 2(오피스텔)
        setVisible05(false);   // 05. 멸실여부
        setVisible06(false);   // 06. 토지종류
        setVisible07(false);   // 07. 자경기간
        setVisible08(false);   // 08. 취득원인 1(기존 아파트)
        setVisible09(false);   // 09. 취득원인 2(분양권/오피스텔)
        setVisible10(false);   // 10. 취득원인 3(조합원입주권)
        setVisible11(false);   // 11. 취득원인 4(다세대/다가구/다중/단독주택)
        setVisible12(false);   // 12. 조정대상지역
        setVisible13(false);   // 13. 주택수 1(조정대상지역)
        setVisible14(false);   // 14. 주택수 2(비조정대상지역)
        setVisible15(false);   // 15. 상속인의 주택유무
        setVisible16(false);   // 16. 시가표준액 기준
        setVisible17(false);   // 17. 증여자
        setVisible18(false);   // 18. 증여자의 주택수
        setVisible19(false);   // 19. 과세표준
        setVisible20(false);   // 20. 지분율
        setVisible21(false);   // 21. 면적
        setVisible22(false);   // 22. 취득할 자산정보
        setVisible23(false);   // 23. 추가 버튼
        setVisible24(false);   // 24. 자산정보 Table
    
        setDisabled01(false);  // 01. 부동산의 종류(상세구분)
        setDisabled02(false);  // 02. 주택구분
        setDisabled03(false);  // 03. 취득구분 1(아파트/연립)
        setDisabled04(false);  // 04. 취득구분 2(오피스텔)
        setDisabled05(false);  // 05. 멸실여부
        setDisabled06(false);  // 06. 토지종류
        setDisabled07(false);  // 07. 자경기간
        setDisabled08(false);  // 08. 취득원인 1(기존 아파트)
        setDisabled09(false);  // 09. 취득원인 2(분양권/오피스텔)
        setDisabled10(false);  // 10. 취득원인 3(조합원입주권)
        setDisabled11(false);  // 11. 취득원인 4(다세대/다가구/다중/단독주택)
        setDisabled12(false);  // 12. 조정대상지역
        setDisabled16(false);  // 16. 시가표준액 기준
        setDisabled20(false);  // 20. 면적
        setDisabled21(false);  // 21. 조정대상지역
        setTaxBaseHelp ("");   // 과세표준 입력 도움말
        setHouseNumHelp("");   // 주택수 선택 도움말
    }

    // 주택 추가 callback
    const onFinishAddrSearch = (item: any) => {
        // 면적정보, 시가표준액
        let areaInfo   = ""; // 면적
        let housePrice = ""; // 시가표준액(기준시가)
        if (item.bdKnd === "1") {           // 공동주택 
            areaInfo   = item.prvuseAr;
            housePrice = item.pblntfPc;
        } else if (item.bdKnd === "0") {    // 단독주택
            areaInfo   = item.houseTotar;
            housePrice = item.housePc;
        } else {}

        // 면적정보 코드로 변환
        let prvuseAr = "";
        if (Number(areaInfo) <= 85) {
            prvuseAr = "201"; // 85이하
        } else if (Number(areaInfo) > 85) {
            prvuseAr = "202"; // 85이하
        } 

        // 증여 시가표준액 3억이상 여부 코드로 변환
        let standardPrice = "";
        if (Number(Util.uncomma(housePrice)) < 300000000) {
            standardPrice = "201";
        } else if (Number(Util.uncomma(housePrice)) >= 300000000) {
            standardPrice = "202";
        }

        // 조정대상지역
        let targetArea = "";
        if (item.mdatTrgetAreaAt === "Y") {
            targetArea = "201";
        } else if (item.mdatTrgetAreaAt === "N") {
            targetArea = "202";
        }

        setAssetRdnmAdres   (item.rdnmAdres + " " + item.rdnmAdresDetail); // 22.도로명 주소
        if (item.lnmAdres !== undefined) {
            setAssetLnmAdres    (item.lnmAdres  + " " + item.lnmAdresDetail ); // 23.지번 주소
        }
        setAssetStdPrice    (Util.uncomma(housePrice)); // **.공동/개별 주택가격(기준시가) - 16.시가표준액 기준
        setAssetAreaInfo    (areaInfo                ); // **.공동 전용면적/단독 주택연면적- 21.면적
        setAssetShareRatio  (item.shareRatio         ); // **.지분율                       - 20.지분율
        setAssetMdatTrgetAre(item.mdatTrgetAreaAt    ); // **.조정대상지역                 - 12.조정대상지역

        setVisible23(false); // 취득할 자산정보 추가 버튼 미표시
        setVisible24(true ); // 자산정보 Table 표시
        
        // 주택 > 아파트/연립 > 기존아파트
        if (estateSe === "201" && estateFom === "201" && acqsSrc1  === "201") {
            setDisabled08(true);  // 08. 취득원인 1(기존 아파트) Fixed

            // 매매 ▶ 12.조정대상지역, 13.주택수(조정) or 14.주택수(비조정), 19.과세표준, 20.지분율, 21.면적
            if (acqsCause1 === "203") { 
                setMdatTrgetAre(targetArea     ); // 12.조정대상지역
                setVisible12   (true           );
                setDisabled12  (true           );
                setVisible19   (true           ); // 19. 과세표준
                setShareRatio  (item.shareRatio); // 20. 지분율
                setVisible20   (true           ); 
                setDisabled20  (true           );
                setPrvuseAr    (prvuseAr       ); // 21. 면적
                setVisible21   (true           ); 
                setDisabled21  (true           );
                setHouseNumHelp("◀ 아파트 취득 당시 보유 주택 수(취득할 아파트 포함)"); // 주택수 선택 도움말

                if (targetArea === "201") {
                    setVisible13(true); // 13. 주택수 1(조정대상지역)
                } else if (targetArea === "202") {
                    setVisible14(true); // 14. 주택수 2(비조정대상지역)
                } else {}

            // 상속 ▶ 15.상속인의 주택유무, 19.과세표준, 20.지분율    
            } else if (acqsCause1 === "204") { 
                setVisible15 (true           ); // 15.상속인의 주택유무
                setVisible19 (true           ); // 19.과세표준
                setShareRatio(item.shareRatio); // 20.지분율
                setVisible20 (true           ); 
                setDisabled20(true           );

            // 증여 ▶ 12.조정대상지역, 16.시가표준액, 17.증여자     
            } else if (acqsCause1 === "205") { 
                setMdatTrgetAre(targetArea); // 12.조정대상지역
                setVisible12   (true      );
                setDisabled12  (true      );
                
                // 조정대상지역
                if (targetArea === "201") {
                    // 시가표준액
                    setStdMkPrice(standardPrice); // 16.시가표준액 기준
                    setVisible16 (true         );
                    setDisabled16(true         );

                    if (standardPrice === "201") {          // 3억 미만 ▶ 19.과세표준, 20.지분율, 21.면적
                        setVisible19 (true           ); // 19.과세표준
                        setShareRatio(item.shareRatio); // 20. 지분율
                        setVisible20 (true           ); 
                        setDisabled20(true           );
                        setPrvuseAr  (prvuseAr       ); // 21. 면적
                        setVisible21 (true           ); 
                        setDisabled21(true           );
                    } else if (standardPrice === "202") {   // 3억 이상 ▶ 17.증여자
                        setVisible17 (true           ); // 17.증여자
                        setShareRatio(item.shareRatio); // 20. 지분율
                        setDisabled20(true           );
                        setPrvuseAr  (prvuseAr       ); // 21. 면적   
                        setDisabled21(true           );         
                    }                    
                
                // 비조정대상지역
                } else if (targetArea === "202") {
                    setVisible19 (true           ); // 19.과세표준
                    setShareRatio(item.shareRatio); // 20. 지분율
                    setVisible20 (true           ); 
                    setDisabled20(true           );
                    setPrvuseAr  (prvuseAr       ); // 21. 면적
                    setVisible21 (true           ); 
                    setDisabled21(true           );
                } else {}
            }    

        // 주택 > 아파트/연립 > 분양권에 의한 아파트 > 분양(신규, 미분양)(201), 전매(203), 상속(204), 증여(205) ▶ 12.조정대상지역, 13.주택수(조정) or 14.주택수(비조정), 19.과세표준, 20.지분율, 21.면적    
        } else if (estateSe === "201" && estateFom === "201" && acqsSrc1  === "202") {
            setMdatTrgetAre(targetArea     ); // 12.조정대상지역
            setVisible12   (true           );
            setDisabled12  (true           );
            setVisible19   (true           ); // 19. 과세표준
            setShareRatio  (item.shareRatio); // 20. 지분율
            setVisible20   (true           ); 
            setDisabled20  (true           );
            setPrvuseAr    (prvuseAr       ); // 21. 면적
            setVisible21   (true           ); 
            setDisabled21  (true           );
            setHouseNumHelp("◀ 분양권 취득 당시 보유 주택 수(취득한 분양권 포함)"); // 주택수 선택 도움말

            if (targetArea === "201") {
                setVisible13(true); // 13. 주택수 1(조정대상지역)
            } else if (targetArea === "202") {
                setVisible14(true); // 14. 주택수 2(비조정대상지역)
            } else {}

        // 주택 > 아파트/연립 > 조합원입주권에 의한 아파트 > 분양(신규, 미분양)(201), 전매(203), 상속(204), 증여(205) ▶ 12.조정대상지역, 13.주택수(조정) or 14.주택수(비조정), 19.과세표준, 20.지분율, 21.면적        
        } else if (estateSe === "201" && estateFom === "201" && acqsSrc1  === "203") {
            setVisible19   (true           ); // 19. 과세표준
            setShareRatio  (item.shareRatio); // 20. 지분율
            setVisible20   (true           ); 
            setDisabled20  (true           );
            setPrvuseAr    (prvuseAr       ); // 21. 면적
            setVisible21   (true           ); 
            setDisabled21  (true           );
        }
        
        // 주택 > 다세대/다가구/다중/단독주택 > 매매(203)
        if (estateSe === "201" && estateFom === "202") {
            setDisabled11(true); // 11. 취득원인(다세대/다가구/단독) Fixed

            // 매매 ▶ 12.조정대상지역, 13.주택수(조정) or 14.주택수(비조정), 19.과세표준, 20.지분율, 21.면적
            if (acqsCause4 === "203") {           // 매매 ▶ 12.조정대상지역
                setMdatTrgetAre(targetArea     ); // 12.조정대상지역
                setVisible12   (true           );
                setDisabled12  (true           );
                setVisible19   (true           ); // 19. 과세표준
                setShareRatio  (item.shareRatio); // 20. 지분율
                setVisible20   (true           ); 
                setDisabled20  (true           );
                setPrvuseAr    (prvuseAr       ); // 21. 면적
                setVisible21   (true           ); 
                setDisabled21  (true           );
                setHouseNumHelp("◀ 아파트 취득 당시 보유 주택 수(취득할 아파트 포함)"); // 주택수 선택 도움말
                setTaxBaseHelp ("◀ 실제 취득가액"); // 과세표준 도움말

                if (targetArea === "201") {
                    setVisible13(true); // 13. 주택수 1(조정대상지역)
                } else if (targetArea === "202") {
                    setVisible14(true); // 14. 주택수 2(비조정대상지역)
                } else {}

            // 상속 ▶ 15.상속인의 주택유무, 19.과세표준, 20.지분율    
            } else if (acqsCause4 === "204") { 
                setVisible15 (true           ); // 15.상속인의 주택유무
                setVisible19 (true           ); // 19.과세표준
                setShareRatio(item.shareRatio); // 20.지분율
                setVisible20 (true           ); 
                setDisabled20(true           );
                setTaxBaseHelp("◀ 시가표준액"); // 과세표준 도움말

            // 증여 ▶ 12.조정대상지역, 16.시가표준액, 17.증여자     
            } else if (acqsCause4 === "205") { 
                setMdatTrgetAre(targetArea); // 12.조정대상지역
                setVisible12   (true      );
                setDisabled12  (true      );
                setTaxBaseHelp("◀ 시가인정액"); // 과세표준 도움말

                // 조정대상지역
                if (targetArea === "201") {
                    // 시가표준액
                    setStdMkPrice(standardPrice); // 16.시가표준액 기준
                    setVisible16 (true         );
                    setDisabled16(true         );

                    if (standardPrice === "201") {          // 3억 미만 ▶ 19.과세표준, 20.지분율, 21.면적
                        setVisible19 (true           ); // 19.과세표준
                        setShareRatio(item.shareRatio); // 20. 지분율
                        setVisible20 (true           ); 
                        setDisabled20(true           );
                        setPrvuseAr  (prvuseAr       ); // 21. 면적
                        setVisible21 (true           ); 
                        setDisabled21(true           );
                    } else if (standardPrice === "202") {   // 3억 이상 ▶ 17.증여자
                        setVisible17 (true           ); // 17.증여자
                        setShareRatio(item.shareRatio); // 20. 지분율
                        setDisabled20(true           );
                        setPrvuseAr  (prvuseAr       ); // 21. 면적   
                        setDisabled21(true           );         
                    }                    
                
                // 비조정대상지역
                } else if (targetArea === "202") {
                    setVisible19 (true); // 19.과세표준
                    setShareRatio(item.shareRatio); // 20. 지분율
                    setVisible20 (true           ); 
                    setDisabled20(true           );
                    setPrvuseAr  (prvuseAr); // 21. 면적
                    setVisible21 (true    ); 
                    setDisabled21(true    );
                } else {}
            
            // 자가신축 ▶ 19.과세표준, 20.지분율, 21.면적    
            } else if (acqsCause4 === "206") {    
                setVisible19 (true           ); // 19.과세표준
                setShareRatio(item.shareRatio); // 20. 지분율
                setVisible20 (true           ); 
                setDisabled20(true           );
                setPrvuseAr  (prvuseAr       ); // 21. 면적
                setVisible21 (true           ); 
                setDisabled21(true           );
                setTaxBaseHelp("◀ 실제 취득가액"); // 과세표준 도움말
            } else {}
        }
    }

    // 주택(부동산) 정보 삭제
    const delHousing = () => {
        // 취득 자산 정보 초기화
        setAssetRdnmAdres   (""); // 22.도로명 주소
        setAssetLnmAdres    (""); // 23.지번 주소
        setAssetStdPrice    (""); // **.공동/개별 주택가격(기준시가)
        setAssetAreaInfo    (""); // **.공동주택 전용면적           
        setAssetShareRatio  (""); // **.지분율                      
        setAssetMdatTrgetAre(""); // **.조정대상지역                        

        setVisible23(true ); // 취득할 자산정보 추가 버튼 표시
        setVisible24(false); // 자산정보 Table 미표시  
        
        setVisible12(false);   // 12. 조정대상지역
        setVisible13(false);   // 13. 주택수 1(조정대상지역)
        setVisible14(false);   // 14. 주택수 2(비조정대상지역)
        setVisible15(false);   // 15. 상속인의 주택유무
        setVisible16(false);   // 16. 시가표준액 기준
        setVisible17(false);   // 17. 증여자
        setVisible18(false);   // 18. 증여자의 주택수
        setVisible19(false);   // 19. 과세표준
        setVisible20(false);   // 20. 지분율
        setVisible21(false);   // 21. 면적

        setMdatTrgetAre("");   // 12. 조정대상지역
        setHseNumAt1   ("");   // 13. 주택수 1(조정대상지역)
        setHseNumAt2   ("");   // 14. 주택수 2(비조정대상지역)
        setInheirHseNo ("");   // 15. 상속인의 주택유무
        setStdMkPrice  ("");   // 16. 시가표준액 기준
        setDonor       ("");   // 17. 증여자
        setDonorHseNo  ("");   // 18. 증여자의 주택수
        setTaxBase     ("");   // 19. 과세표준
        setShareRatio  ("");   // 20. 지분율
        setPrvuseAr    ("");   // 21. 면적
    }



    // 취득세 계산하기
    const callTax = (taxFlag:string) => {
        // 입력 값 확인 메시지 처리
        if (visible01) {if (Util.isEmpty(estateSe    )) {AppContext.openAlert({title: "부동산의 종류"                    , msg: "부동산의 종류를 선택하세요."    , icon: "check"}); return;}}
        if (visible02) {if (Util.isEmpty(estateFom   )) {AppContext.openAlert({title: "주택구분"                         , msg: "주택구분을 선택하세요."         , icon: "check"}); return;}}
        if (visible03) {if (Util.isEmpty(acqsSrc1    )) {AppContext.openAlert({title: "취득구분(아파트/연립)"            , msg: "취득구분을 선택하세요."         , icon: "check"}); return;}}
        if (visible04) {if (Util.isEmpty(acqsSrc2    )) {AppContext.openAlert({title: "취득구분(오피스텔)"               , msg: "취득구분을 선택하세요."         , icon: "check"}); return;}}
        if (visible05) {if (Util.isEmpty(loseYn      )) {AppContext.openAlert({title: "멸실여부"                         , msg: "멸실여부를 선택하세요."         , icon: "check"}); return;}}
        if (visible06) {if (Util.isEmpty(landType    )) {AppContext.openAlert({title: "토지종류"                         , msg: "토지종류를 선택하세요."         , icon: "check"}); return;}}
        if (visible07) {if (Util.isEmpty(farmPeriod  )) {AppContext.openAlert({title: "자경기간"                         , msg: "자경기간을 선택하세요."         , icon: "check"}); return;}}
        if (visible08) {if (Util.isEmpty(acqsCause1  )) {AppContext.openAlert({title: "취득원인"                         , msg: "취득원인을 선택하세요."         , icon: "check"}); return;}}
        if (visible09) {if (Util.isEmpty(acqsCause2  )) {AppContext.openAlert({title: "취득원인(분양권/오피스텔)"        , msg: "취득원인을 선택하세요."         , icon: "check"}); return;}}
        if (visible10) {if (Util.isEmpty(acqsCause3  )) {AppContext.openAlert({title: "취득원인(조합원입주권)"           , msg: "취득원인을 선택하세요."         , icon: "check"}); return;}}
        if (visible11) {if (Util.isEmpty(acqsCause4  )) {AppContext.openAlert({title: "취득원인(다세대/다가구/다중/단독)", msg: "취득원인을 선택하세요."         , icon: "check"}); return;}}
        if (visible12) {if (Util.isEmpty(mdatTrgetAre)) {AppContext.openAlert({title: "조정대상지역"                     , msg: "조정대상지역을 선택하세요."     , icon: "check"}); return;}}
        if (visible13) {if (Util.isEmpty(hseNumAt1   )) {AppContext.openAlert({title: "주택수(조정대상지역)"             , msg: "주택수를 선택하세요."           , icon: "check"}); return;}}
        if (visible14) {if (Util.isEmpty(hseNumAt2   )) {AppContext.openAlert({title: "주택수(비조정대상지역)"           , msg: "주택수를 선택하세요."           , icon: "check"}); return;}}
        if (visible15) {if (Util.isEmpty(inheirHseNo )) {AppContext.openAlert({title: "상속인의 주택유무"                , msg: "상속인의 주택유무를 선택하세요.", icon: "check"}); return;}}
        if (visible16) {if (Util.isEmpty(stdMkPrice  )) {AppContext.openAlert({title: "시가표준액"                       , msg: "시가표준액을 선택하세요."       , icon: "check"}); return;}}
        if (visible17) {if (Util.isEmpty(donor       )) {AppContext.openAlert({title: "증여자"                           , msg: "증여자를 선택하세요."           , icon: "check"}); return;}}
        if (visible18) {if (Util.isEmpty(donorHseNo  )) {AppContext.openAlert({title: "증여자의 주택수"                  , msg: "증여자의 주택수를 선택하세요."  , icon: "check"}); return;}}
        if (visible19) {
            if (Util.isEmpty(taxBase)) {AppContext.openAlert({title: "과세표준", msg: "과세표준 금액을 입력하세요.", icon: "check"}); return;}
            if (Number(taxBase) <= 0)  {AppContext.openAlert({title: "과세표준", msg: "과세표준 금액을 입력하세요.", icon: "check"}); return;}
        }
        // if (visible20) {
        //     if (Util.isEmpty(shareRatio  )) {AppContext.openAlert({title: "지분율"                          , msg: "지분율을 입력하세요."          , icon: "check"}); return;}
        //     if (Number(shareRatio) <= 0 || Number(shareRatio) > 100)  {AppContext.openAlert({title: "지분율", msg: "지분율을 1~100으로 입력하세요.", icon: "check"}); return;}
        // }
        // if (visible21) {if (Util.isEmpty(prvuseAr    )) {AppContext.openAlert({title: "면적"                            , msg: "면적을 선택하세요."             , icon: "check"}); return;}}

        if (visible22 && visible23) {
            if (Util.isEmpty(assetRdnmAdres)) {AppContext.openAlert({title: "취득할 자산정보", msg: "취득할 자산정보를 추가하세요.", icon: "check"}); return;}
        }

        // 입력한 사항을 배열로 생성하여 파라미터로 전달
        setAcqsTaxParam([
            ...acqsTaxParam,     // 배열 전개(spread operator) 사용 배열 복사본으로 새배열을 생성 : push는 배열을 변이하는 메서드로 비추천
            {
                  taxFlag     : taxFlag
                , estateSe    : estateSe               // 01. 부동산의 종류(상세구분)
                , estateFom   : estateFom              // 02. 주택구분
                , acqsSrc1    : acqsSrc1               // 03. 취득구분 1(아파트/연립)
                , acqsSrc2    : acqsSrc2               // 04. 취득구분 2(오피스텔)
                , loseYn      : loseYn                 // 05. 멸실여부
                , landType    : landType               // 06. 토지종류
                , farmPeriod  : farmPeriod             // 07. 자경기간
                , acqsCause1  : acqsCause1             // 08. 취득원인 1(기존 아파트)
                , acqsCause2  : acqsCause2             // 09. 취득원인 2(분양권/오피스텔)
                , acqsCause3  : acqsCause3             // 10. 취득원인 3(조합원입주권)
                , acqsCause4  : acqsCause4             // 11. 취득원인 4(다세대/다가구/다중/단독주택)
                , mdatTrgetAre: mdatTrgetAre           // 12. 조정대상지역
                , hseNumAt1   : hseNumAt1              // 13. 주택수 1(조정대상지역)
                , hseNumAt2   : hseNumAt2              // 14. 주택수 2(비조정대상지역)
                , inheirHseNo : inheirHseNo            // 15. 상속인의 주택유무
                , stdMkPrice  : stdMkPrice             // 16. 시가표준액 기준
                , donor       : donor                  // 17. 증여자
                , donorHseNo  : donorHseNo             // 18. 증여자의 주택수
                , taxBase     : Util.uncomma(taxBase)  // 19. 과세표준
                , shareRatio  : shareRatio             // 20. 지분율
                , prvuseAr    : prvuseAr               // 21. 면적
            }
          ]);

        setSimAcqsTaxCalcModalOpen(true);
    }

    return (
        <div className='w940 mh500'>
            {/* 01. 부동산의 종류 */}
            <div className={(visible01 ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt30'}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>부동산의 종류</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={estateSe}
                        codeArray={Util.getCodeArray("2001")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        disabled={disabled01}
                        border="1px solid #dddddd"
                        onChangeFunc={(data: string) => {
                            setEstateSe(data);
                            if (data === "201") {           // 주택 ▶ 02.주택구분
                                setVisible02(true );
                                setVisible05(false); // 05.멸실여부 미표시
                                setLoseYn   (""   ); // 05.멸실여부 초기화
                                setVisible04(false); // 04.취득구분2(오피스텔) 미표시
                                setAcqsSrc2 (""   ); // 04.취득구분2(오피스텔) 초기화
                                setVisible11(false); // 11.취득원인 4(다세대/다가구/다중/단독주택) 미표시
                                setAcqsCause4(""  ); // 11.취득원인 4(다세대/다가구/다중/단독주택) 초기화
                                setVisible06(false); // 06.토지종류 미표시
                                setLandType (""   ); // 06.토지종류 초기화
                            } else if (data === "202") {    // 조합원입주권 ▶ 05. 멸실여부
                                setVisible05(true );
                                setVisible02(false); // 02.주택구분 미표시
                                setEstateFom(""   ); // 02.주택구분 초기화
                                setVisible04(false); // 04.취득구분2(오피스텔) 미표시
                                setAcqsSrc2 (""   ); // 04.취득구분2(오피스텔) 초기화
                                setVisible11(false); // 11.취득원인 4(다세대/다가구/다중/단독주택) 미표시
                                setAcqsCause4(""  ); // 11.취득원인 4(다세대/다가구/다중/단독주택) 초기화
                                setVisible06(false); // 06.토지종류 미표시
                                setLandType (""   ); // 06.토지종류 초기화
                            } else if (data === "203") {    // 오피스텔 ▶ 04.취득구분2(오피스텔)
                                setVisible04(true );
                                setVisible02(false); // 02.주택구분 미표시
                                setEstateFom(""   ); // 02.주택구분 초기화
                                setVisible05(false); // 05.멸실여부 미표시
                                setLoseYn   (""   ); // 05.멸실여부 초기화
                                setVisible11(false); // 11.취득원인 4(다세대/다가구/다중/단독주택) 미표시
                                setAcqsCause4(""  ); // 11.취득원인 4(다세대/다가구/다중/단독주택) 초기화
                                setVisible06(false); // 06.토지종류 미표시
                                setLandType (""   ); // 06.토지종류 초기화
                            } else if (data === "204") {    // 일반건물/상가 ▶ 11.취득원인 4(다세대/다가구/다중/단독주택)
                                setVisible11(true );
                                setVisible02(false); // 02.주택구분 미표시
                                setEstateFom(""   ); // 02.주택구분 초기화
                                setVisible05(false); // 05.멸실여부 미표시
                                setLoseYn   (""   ); // 05.멸실여부 초기화
                                setVisible04(false); // 04.취득구분2(오피스텔) 미표시
                                setAcqsSrc2 (""   ); // 04.취득구분2(오피스텔) 초기화
                                setVisible06(false); // 06.토지종류 미표시
                                setLandType (""   ); // 06.토지종류 초기화
                            } else if (data === "205") {    // 토지 ▶ 06.토지종류
                                setVisible06(true);
                                setVisible02(false); // 02.주택구분 미표시
                                setEstateFom(""   ); // 02.주택구분 초기화
                                setVisible05(false); // 05.멸실여부 미표시
                                setLoseYn   (""   ); // 05.멸실여부 초기화
                                setVisible04(false); // 04.취득구분2(오피스텔) 미표시
                                setAcqsSrc2 (""   ); // 04.취득구분2(오피스텔) 초기화
                                setVisible11(false); // 11.취득원인 4(다세대/다가구/다중/단독주택) 미표시
                                setAcqsCause4(""  ); // 11.취득원인 4(다세대/다가구/다중/단독주택) 초기화
                            } else {}
                        }}
                    />
                </div>
            </div>

            {/* 02. 주택구분 */}
            <div className={(visible02 ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt10'}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>주택구분</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={estateFom}
                        codeArray={Util.getCodeArray("2002")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        disabled={disabled02}
                        border="1px solid #dddddd"
                        onChangeFunc={(data: string) => {
                            setEstateFom(data);
                            if (data === "201") {           // 아파트/연립 ▶ 03.취득구분1(아파트/연립)
                                setVisible03(true );
                                setVisible11(false); // 11.취득원인(다세대/다가구/다중/단독주택) 미표시
                                setAcqsCause4(""  ); // 11.취득원인(다세대/다가구/다중/단독주택) 초기화
                                setDisabled01(true); // 01.부동산의 종류 fixed
                            } else if (data === "202") {    // 다세대/다가구/다중/단독주택 ▶ 11.취득원인(다세대/다가구/다중/단독주택)
                                setVisible11(true );
                                setVisible03(false); // 03.취득구분(아파트/연립) 미표시
                                setAcqsSrc1 (""   ); // 03.취득구분(아파트/연립) 초기화
                                setDisabled01(true); // 01.부동산의 종류 fixed
                            } else {}
                        }}
                   />
                </div>
            </div>

            {/* 03. 취득구분1(아파트/연립) */}
            <div className={(visible03 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>취득구분</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={acqsSrc1}
                        codeArray={Util.getCodeArray("2003")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        border="1px solid #dddddd"
                        disabled={disabled03}
                        onChangeFunc={(data: string) => {
                            setAcqsSrc1(data);
                            if (data === "201") {          // 기존 아파트 ▶ 08.취득원인(기존 아파트)
                                setVisible08(true );
                                setVisible09(false); // 09.취득원인(분양권/오피스텔) 미표시
                                setAcqsCause2(""  ); // 09.취득원인(분양권/오피스텔) 초기화
                                setVisible10(false); // 10. 취득원인(조합원입주권) 미표시
                                setAcqsCause3(""  ); // 10. 취득원인(조합원입주권) 초기화
                                setDisabled02(true); // 02. 주택구분 Fixed
                            } else if (data === "202") {   // 분양권에 의한 아파트 ▶ 09.취득원인(분양권/오피스텔)
                                setVisible09 (true);
                                setVisible08(false); // 08.취득원인(아파트) 미표시
                                setAcqsCause1(""  ); // 08.취득원인(아파트) 초기화
                                setVisible10(false); // 10. 취득원인(조합원입주권) 미표시
                                setAcqsCause3(""  ); // 10. 취득원인(조합원입주권) 초기화
                                setDisabled02(true); // 02. 주택구분 Fixed
                            } else if (data === "203") {   // 조합원입주권에 의한 아파트 ▶ 10.취득원인(조합원입주권)
                                setVisible10(true );
                                setVisible08(false); // 08.취득원인(아파트) 미표시
                                setAcqsCause1(""  ); // 08.취득원인(아파트) 초기화
                                setVisible09(false); // 09.취득원인(분양권/오피스텔) 미표시
                                setAcqsCause2(""  ); // 09.취득원인(분양권/오피스텔) 초기화
                                setDisabled02(true); // 02. 주택구분 Fixed
                            } else {}
                        }}
                    />
                </div>
            </div>

            {/* 04. 취득구분(오피스텔) */}
            <div className={(visible04 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>취득구분</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={acqsSrc2}
                        codeArray={Util.getCodeArray("2004")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        border="1px solid #dddddd"
                        disabled={disabled04}
                        onChangeFunc={(data: string) => {
                            setAcqsSrc2(data);

                            // 오피스텔
                            if (data === "201") {           // 기존 오피스텔(201) ▶ 08.취득원인(기존아파트)
                                setVisible08(true );
                                setVisible09(false); // 09.취득원인2(분양권/오피스텔) 미표시
                                setAcqsCause2(""  ); // 09.취득원인2(분양권/오피스텔) 초기화
                                setDisabled01(true); // 01.부동산의 종류 Disabled
                            } else if (data === "202") {    // 일반분양 오피스텔(202) ▶ 09.취득원인(분양권/오피스텔)
                                setVisible09 (true );
                                setVisible08 (false); // 08.취득원인1(기존 아파트) 미표시
                                setAcqsCause1(""   ); // 08.취득원인1(기존 아파트) 초기화
                                setDisabled01(true ); // 01.부동산의 종류 Disabled
                            } else {}
                        }}
                    />
                </div>
            </div>

            {/* 05. 멸실여부 */}
            <div className={(visible05 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>멸실여부</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={loseYn}
                        codeArray={Util.getCodeArray("2017")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        border="1px solid #dddddd"
                        disabled={disabled05}
                        onChangeFunc={(data: string) => {
                            setLoseYn(data);

                            // 조합원입주권
                            // 멸실 전(201), 멸실 후(202) ▶ 10.취득원인(조합원입주권)
                            setVisible10 (true);
                            setDisabled01(true); // 01. 부동산의 종류 Fixed
                        }}
                    />
                </div>
            </div>

            {/* 06. 토지종류 */}
            <div className={(visible06 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>토지종류</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={landType}
                        codeArray={Util.getCodeArray("2018")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        border="1px solid #dddddd"
                        disabled={disabled06}
                        onChangeFunc={(data: string) => {
                            setLandType(data);

                            // 토지
                            if (data === "201") {   // 농지 ▶ 07.자경기간
                                setVisible07 (true);
                                setVisible08(false); // 08.취득원인(기존 아파트) 미표시
                                setAcqsCause1(""  ); // 08.취득원인(기존 아파트) 초기화
                                setDisabled01(true); // 01. 부동산의 종류 Fixed
                            } else if (data === "202") {    // 농지 외 ▶ 08.취득원인(기존 아파트)
                                setVisible08 (true);
                                setVisible07(false); // 07.자경기간 미표시
                                setFarmPeriod(""  ); // 07.자경기간 초기화
                                setDisabled01(true); // 01. 부동산의 종류 Fixed
                            } else {}
                        }}
                    />
                </div>
            </div>

            {/* 07. 자경기간 */}
            <div className={(visible07 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>자경기간</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={farmPeriod}
                        codeArray={Util.getCodeArray("2019")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        border="1px solid #dddddd"
                        disabled={disabled07}
                        onChangeFunc={(data: string) => {
                            setFarmPeriod(data);

                            // 토지 > 농지
                            // 2년미만(비자경), 2년이상 ▶ 08.취득원인(기존 아파트)
                            setVisible08 (true);
                            setDisabled06(true); // 06.토지종류 Fixed
                        }}
                    />
                </div>
            </div>

            {/* 08. 취득원인 1(기존 아파트) */}
            <div className={(visible08 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>취득원인</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={acqsCause1}
                        codeArray={Util.getCodeArray("2005")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        border="1px solid #dddddd"
                        disabled={disabled08}
                        onChangeFunc={(data: string) => {
                            setAcqsCause1(data);

                            // 주택 > 아파트/연립 > 기존 아파트
                            if (acqsSrc1 === "201") {
                                if (data === "203") {          // 매매 ▶ 22. 취득할 주택정보 추가
                                    setVisible22 (true);
                                    setVisible23 (true);

                                    setDisabled03(true); // 03. 취득구분 Fixed
                                    setTaxBaseHelp("◀ 실제 취득가액"); // 과세표준 도움말
                                } else if (data === "204") {   // 상속 ▶ 15. 상속인의 주택유무
                                    setVisible22 (true);
                                    setVisible23 (true);

                                    setDisabled03(true); // 03. 취득구분 Fixed
                                    setTaxBaseHelp("◀ 시가표준액"); // 과세표준 도움말
                                } else if (data === "205") {   // 증여 ▶ 12. 조정대상지역
                                    setVisible22 (true);
                                    setVisible23 (true);

                                    setDisabled03(true); // 03. 취득구분 Fixed
                                    setTaxBaseHelp("◀ 시가인정액"); // 과세표준 도움말
                                } else {}
                            }

                            // 오피스텔 > 기존 오피스텔
                            // 매매(203), 상속(204), 증여(205) ▶ 19.과세표준, 20.지분율
                            if (acqsSrc2 === "201") {
                                setVisible19 (true);
                                setVisible20 (true);
                                setDisabled04(true); // 04.취득원인1(기존 아파트) Fixed
                                if (data === "203") setTaxBaseHelp("◀ 실제 취득가액"); // 과세표준 도움말
                                if (data === "204") setTaxBaseHelp("◀ 시가표준액"   ); // 과세표준 도움말
                                if (data === "205") setTaxBaseHelp("◀ 시가인정액"   ); // 과세표준 도움말
                            }

                            // 토지 > 농지 > 자경기간
                            // 매매(203), 상속(204), 증여(205) ▶ 19.과세표준, 20.지분율
                            if (landType === "201") {
                                setVisible19 (true);
                                setVisible20 (true);
                                setDisabled07(true); // 07.자경기간 Fixed
                                if (data === "203") setTaxBaseHelp("◀ 실제 취득가액"); // 과세표준 도움말
                                if (data === "204") setTaxBaseHelp("◀ 시가표준액"   ); // 과세표준 도움말
                                if (data === "205") setTaxBaseHelp("◀ 시가인정액"   ); // 과세표준 도움말
                            // 토지 > 농지외
                            // 매매(203), 상속(204), 증여(205) ▶ 19.과세표준, 20.지분율
                            } else if ( landType === "202") {
                                setVisible19 (true);
                                setVisible20 (true);
                                setDisabled06(true); // 06.토지종류 Fixed
                                if (data === "203") setTaxBaseHelp("◀ 실제 취득가액"); // 과세표준 도움말
                                if (data === "204") setTaxBaseHelp("◀ 시가표준액"   ); // 과세표준 도움말
                                if (data === "205") setTaxBaseHelp("◀ 시가인정액"   ); // 과세표준 도움말
                        } else {}
                        }}
                    />
                </div>
            </div>

            {/* 09. 취득원인 2(분양권/오피스텔) */}
            <div className={(visible09 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>취득원인</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={acqsCause2}
                        codeArray={Util.getCodeArray("2006")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        border="1px solid #dddddd"
                        disabled={disabled09}
                        onChangeFunc={(data: string) => {
                            setAcqsCause2(data);

                            // 주택 > 아파트/연립 > 분양권에 의한 아파트 > 분양(신규, 미분양)(201), 전매(203), 상속(204), 증여(205) ▶ 22. 취득할 주택정보 추가
                            if (acqsSrc1 === "202") { // 분양권에 의한 아파트
                                setVisible22 (true);
                                setVisible23 (true);

                                setDisabled03(true); // 03.취득구분1(아파트/연립) Fixed
                                if (data === "201") setTaxBaseHelp("◀ 실제 취득가액"); // 과세표준 도움말
                                if (data === "203") setTaxBaseHelp("◀ 실제 취득가액"); // 과세표준 도움말
                                if (data === "204") setTaxBaseHelp("◀ 시가표준액"   ); // 과세표준 도움말
                                if (data === "205") setTaxBaseHelp("◀ 시가인정액"   ); // 과세표준 도움말
                            }

                            // 오피스텔 > 일반분양 오피스텔
                            // 분양(신규, 미분양)(201), 전매(203), 상속(204), 증여(205) ▶ 19.과세표준, 20.지분율
                            if (acqsSrc2 === "202") {
                                setVisible19 (true);
                                setVisible20 (true);
                                setDisabled04(true); // 04.취득구분2(오피스텔) Fixed
                                if (data === "201") setTaxBaseHelp("◀ 실제 취득가액"); // 과세표준 도움말
                                if (data === "203") setTaxBaseHelp("◀ 실제 취득가액"); // 과세표준 도움말
                                if (data === "204") setTaxBaseHelp("◀ 시가표준액"   ); // 과세표준 도움말
                                if (data === "205") setTaxBaseHelp("◀ 시가인정액"   ); // 과세표준 도움말
                            }
                        }}
                    />
                </div>
            </div>

            {/* 10. 취득원인 3(조합원입주권) */}
            <div className={(visible10 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>취득원인</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={acqsCause3}
                        codeArray={Util.getCodeArray("2007")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        border="1px solid #dddddd"
                        disabled={disabled10}
                        onChangeFunc={(data: string) => {
                            setAcqsCause3(data);

                            // 주택 > 아파트/연립 > 조합원입주권에 의한 아파트 > 원조합원(202), 전매(203), 상속(204), 증여(205) ▶ 22. 취득할 주택정보 추가
                            if (acqsSrc1 === "203") {
                                setVisible22 (true);
                                setVisible23 (true);

                                setDisabled03(true); // 03. 취득구분 Fixed
                                setTaxBaseHelp("◀ 건축비 상당액"); // 과세표준 도움말
                            }

                            // 조합원입주권 > 멸실여부
                            if (loseYn === "201") {     // 멸실 전
                                if (data === "202") {           // 원조합원 ▶ 과세 대상 아님
                                    setVisible12(false); // 12.조정대상지역 미표시
                                    setMdatTrgetAre(""); // 12.조정대상지역 초기화
                                    setVisible15(false); // 15.상속인의 주택유무 미표시
                                    setInheirHseNo("" ); // 15.상속인의 주택유무 초기화
                                    setDisabled05(true); // 05.멸실여부 Fixed
                                } else if (data === "203") {    // 전매 ▶ 12.조정대상지역
                                    setVisible12 (true);
                                    setVisible15(false); // 15.상속인의 주택유무 미표시
                                    setInheirHseNo("" ); // 15.상속인의 주택유무 초기화
                                    setDisabled05(true); // 05.멸실여부 Fixed
                                    setTaxBaseHelp("◀ 실제 취득가액"); // 과세표준 도움말
                                } else if (data === "204") {    // 상속 ▶ 15.상속인의 주택유무
                                    setVisible15 (true);
                                    setVisible12(false); // 12.조정대상지역 미표시
                                    setMdatTrgetAre(""); // 12.조정대상지역 초기화
                                    setDisabled05(true); // 05.멸실여부 Fixed
                                    setTaxBaseHelp("◀ 시가표준액"); // 과세표준 도움말
                                } else if (data === "205") {    // 증여 ▶ 12.조정대상지역
                                    setVisible12 (true);
                                    setVisible15(false); // 15.상속인의 주택유무 미표시
                                    setInheirHseNo("" ); // 15.상속인의 주택유무 초기화
                                    setDisabled05(true); // 05.멸실여부 Fixed
                                    setTaxBaseHelp("◀ 시가인정액"); // 과세표준 도움말
                                } else {}
                            } else if (loseYn === "202") {  // 멸실 후
                                if (data === "202") {                                              // 원조합원 ▶ 과세 대상 아님
                                    setVisible19 (false); // 19.과세표준 미표시
                                    setTaxBase   (""   ); // 19.과세표준 초기화
                                    setVisible20 (false); // 20.지분율 미표시
                                    setShareRatio(""   ); // 20.지분율 초기화
                                    setDisabled05(true ); // 05.멸실여부 Fixed
                                } else if (data === "203" || data === "204" || data === "205") {   // 전매, 상속, 증여 ▶ 19.과세표준, 20.지분율
                                    setVisible19 (true);
                                    setVisible20 (true);
                                    setDisabled05(true); // 05.멸실여부 Fixed
                                    if (data === "203") setTaxBaseHelp("◀ 실제 취득가액"); // 과세표준 도움말
                                    if (data === "204") setTaxBaseHelp("◀ 시가표준액"   ); // 과세표준 도움말
                                    if (data === "205") setTaxBaseHelp("◀ 시가인정액"   ); // 과세표준 도움말
                                } else {}
                            } else {}
                        }}
                    />
                </div>
            </div>

            {/* 11. 취득원인 4(다세대/다가구/다중/단독주택) */}
            <div className={(visible11 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>취득원인</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={acqsCause4}
                        codeArray={Util.getCodeArray("2008")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        border="1px solid #dddddd"
                        disabled={disabled11}
                        onChangeFunc={(data: string) => {
                            setAcqsCause4(data);

                            // 주택 > 다세대/다가구/단독
                            if (estateFom === "202") {
                                setVisible22 (true);
                                setVisible23 (true);

                                setDisabled02(true); // 02.주택구분 Fixed
                            }

                            // 일반건물/상가
                            // 취득원인4 : 매매(203), 상속(204), 증여(205), 신축(206) ▶ 19.과세표준, 20.지분율
                            if (estateSe === "204") {
                                setVisible19 (true);
                                setVisible20 (true);
                                setDisabled01(true); // 01. 부동산의 종류 Fixed
                                if (data === "203") setTaxBaseHelp("◀ 실제 취득가액"); // 과세표준 도움말
                                if (data === "204") setTaxBaseHelp("◀ 시가표준액"   ); // 과세표준 도움말
                                if (data === "205") setTaxBaseHelp("◀ 시가인정액"   ); // 과세표준 도움말
                                if (data === "206") setTaxBaseHelp("◀ 실제 취득가액"); // 과세표준 도움말
                            }
                        }}
                    />
                </div>
            </div>

            {/* 취득할 자산정보(22, 23) */}
            <div className={(visible22 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>취득할 자산정보</span>
                </div>
                <div className={(visible23 ? "" : "dpNone-i") + ' dpFlx aiC jcC ml20 bgcWhite'}>
                    <div className="w80 h30 bgc17A840 bdrd40 dpFlx jcC aiC csP" onClick={() => {
                        if (Util.isNotEmpty(assetRdnmAdres)) {
                            AppContext.openAlert({
                                title: "취득할 자산정보 추가 완료"
                                , msg  : "취득할 자산정보가 이미 추가되었습니다."
                                , icon: "check"
                            });
                        } else {
                            if (apiResult === "0") {
                                setAddressModalOpen(true);
                            } else {
                                setDirAddressInModalOpen(true);
                            }
                        }
                    }}>
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>추가</span>
                    </div>
                </div>
            </div>

            {/* 주택 상세정보 - **.도로명주소, **.지번주소, **.조정대상지역 **.지분율 */}
            <div className={(visible24 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className='dpFlx aiC jcC bgcWhite'>
                    <table className="forest-form-table th-p0 td-fs15 td-fm-SpoqaHanSansNeo-Regular td-fc666666 td-ls075">
                        <colgroup>
                            <col className="w150" />
                            <col className="w280" />
                            <col className="w150" />
                            <col className="w140" />
                            <col className="w140" />
                        </colgroup>
                        <tbody>
                            <tr className={assetLnmAdres === "" ? "h40" : "h60"}>
                                <th>주소</th>
                                <td colSpan={3}><span className="lh25">{assetRdnmAdres}<br/>{assetLnmAdres}</span></td>
                                <td>
                                    <div className="w80 h30 bgcB36666 bdrd4 dpFlx jcC aiC csP" onClick={() => delHousing()}>
                                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>삭제</span>
                                    </div>
                                </td>
                            </tr>
                            <tr className="h35">
                                <th>기준시가(원)</th>
                                <td className="bdr1-i bdcDDDDDD-i taC">{Util.comma(assetStdPrice)} 원</td>
                                <th>면적(%)</th>
                                <td colSpan={2} className="taC">{assetAreaInfo} ㎡</td>
                            </tr>
                            <tr className="h35">
                                <th>조정대상지역</th>
                                <td className="bdr1-i bdcDDDDDD-i">
                                    <div className="ml40 aiC">
                                    <ImageRadioBox
                                        codeList={Util.getCodeArray("2034")}
                                        value={assetMdatTrgetAre}
                                        setValue={setAssetMdatTrgetAre}
                                        btnClass="w24 h24 pr5"
                                        disabled={true}
                                        labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                        interval={30} />
                                    </div>    
                                </td>
                                <th>지분율(%)</th>
                                <td colSpan={2} className="taC">{assetShareRatio} %</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* 12. 조정대상지역 */}
            <div className={(visible12 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>조정대상지역</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={mdatTrgetAre}
                        codeArray={Util.getCodeArray("2009")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        disabled={disabled12}
                        border="1px solid #dddddd"
                        onChangeFunc={(data: string) => {
                            setMdatTrgetAre(data);

                            // 조합원입주권 > 멸실전
                            // 10. 취득원인 - 전매(203) : acqsCause3
                            if (loseYn === "201") {
                                if (acqsCause3 === "203") {  // 전매(203)
                                    if (data === "201") {          // 조정대상지역 ▶ 13.주택수(조정), 19.과세표준, 20.지분율, 21.면적
                                        setVisible13(true );
                                        setVisible19(true );
                                        setVisible20(true );
                                        setVisible21(true );
                                        setVisible14(false); // 14.주택수(비조정) 미표시
                                        setHseNumAt2(""   ); // 14.주택수(비조정) 초기화
                                        setDisabled10(true); // 10.취득원인(조합원입주권) Fixed
                                    } else if (data === "202") {   // 비조정대상지역 ▶ 14.주택수(비조정), 19.과세표준, 20.지분율, 21.면적
                                        setVisible14(true);
                                        setVisible19(true );
                                        setVisible20(true );
                                        setVisible21(true );
                                        setVisible13(false); // 13.주택수(조정) 미표시
                                        setHseNumAt1(""   ); // 13.주택수(조정) 미표시
                                        setDisabled10(true); // 10.취득원인(조합원입주권) Fixed
                                    } else {}
                                    setHouseNumHelp("◀ 입주권 취득 당시 보유 주택 수(멸실 전 입주권 포함)"); // 주택수 선택 도움말
                                } else if (acqsCause3 === "205") { // 증여(205)
                                    if (data === "201") {          // 조정대상지역   → 16.시가표준액
                                        setVisible16 (true);
                                        setDisabled10(true); // 10.취득원인(조합원입주권) Fixed
                                    } else if (data === "202") {   // 비조정대상지역 → 19.과세표준, 20.지분율, 21.면적
                                        setVisible19(true );
                                        setVisible20(true );
                                        setVisible21(true );
                                        setVisible16(false); // 16.시가표준액 미표시
                                        setStdMkPrice(""  ); // 16.시가표준액 초기화
                                        setVisible17(false); // 17.증여자 미표시
                                        setDonor    (""   ); // 17.증여자 초기화
                                        setVisible18(false); // 18.증여자 주택수 미표시
                                        setDonorHseNo(""  ); // 18.증여자 주택수 초기화
                                        setDisabled10(true); // 10.취득원인(조합원입주권) Fixed
                                    } else {}
                                }
                            }

                            // 주택 > 다세대/다가구/다중/단독주택
                            // 11. 취득원인 - 매매(203) : acqsCause4
                            if (acqsCause4 === "203") {
                                if (data === "201") {          // 조정대상지역   → 13.주택수(조정), 19.과세표준, 20.지분율, 21.면적
                                    setVisible13(true );
                                    setVisible19(true );
                                    setVisible20(true );
                                    setVisible21(true );
                                    setVisible14(false); // 14.주택수(비조정) 미표시
                                    setHseNumAt2(""   ); // 14.주택수(비조정) 초기화
                                    setDisabled11(true); // 10.취득원인(다세대/다가구/단독) Fixed
                                } else if (data === "202") {   // 비조정대상지역 → 14. 주택수(비조정), 19.과세표준, 20.지분율, 21.면적
                                    setVisible14(true);
                                    setVisible19(true );
                                    setVisible20(true );
                                    setVisible21(true );
                                    setVisible13(false); // 13. 주택수(조정) 미표시
                                    setHseNumAt1(""   ); // 13. 주택수(조정) 미표시
                                    setDisabled11(true); // 11. 취득원인(다세대/다가구/단독) Fixed
                                } else {}
                                setHouseNumHelp("◀ 다세대/다가구/다중/단독 취득 당시 보유 주택 수(취득할 주택 포함)"); // 주택수 선택 도움말
                            // 11. 취득원인 - 증여(205) : acqsCause4
                            } else if (acqsCause4 === "205") {
                                if (data === "201") {          // 조정대상지역   → 16.시가표준액
                                    setVisible16 (true);
                                    setDisabled11(true); // 11. 취득원인(다세대/다가구/단독) Fixed
                                } else if (data === "202") {   // 비조정대상지역 → 19.과세표준, 20.지분율, 21.면적
                                    setVisible19(true );
                                    setVisible20(true );
                                    setVisible21(true );
                                    setVisible16(false); // 16.시가표준액 미표시
                                    setStdMkPrice(""  ); // 16.시가표준액 초기화
                                    setVisible17(false); // 17.증여자 미표시
                                    setDonor    (""   ); // 17.증여자 초기화
                                    setVisible18(false); // 18.증여자 주택수 미표시
                                    setDonorHseNo(""  ); // 18.증여자 주택수 초기화
                                    setDisabled11(true); // 11. 취득원인(다세대/다가구/단독) Fixed
                                } else {}
                            }
                        }}
                    />
                </div>
            </div>

            {/* 13. 주택수 1(조정대상지역) */}
            <div className={(visible13 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>주택수</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={hseNumAt1}
                        codeArray={Util.getCodeArray("2010")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        border="1px solid #dddddd"
                        onChangeFunc={(data: string) => {
                            setHseNumAt1(data);
                        }}
                    />
                </div>
                <div className='dpFlx aiC jcC ml10'>
                    <span className="SpoqaHanSansNeo-Regular fs15 ls08 fc17A840">{houseNumHelp}</span>
                </div>
            </div>

            {/* 14. 주택수 2(비조정대상지역) */}
            <div className={(visible14 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>주택수</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={hseNumAt2}
                        codeArray={Util.getCodeArray("2011")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        border="1px solid #dddddd"
                        onChangeFunc={(data: string) => {
                            setHseNumAt2(data)
                        }}
                    />
                </div>
                <div className='dpFlx aiC jcC ml10'>
                    <span className="SpoqaHanSansNeo-Regular fs15 ls08 fc17A840">{houseNumHelp}</span>
                </div>
            </div>

            {/* 15. 상속인의 주택유무 */}
            <div className={(visible15 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>상속인의 주택유무</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={inheirHseNo}
                        codeArray={Util.getCodeArray("2013")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        border="1px solid #dddddd"
                        onChangeFunc={(data: string) => {
                            setInheirHseNo(data);

                            // 주택 > 아파트/연립 > 기존 아파트 > 상속
                            // 무주택자(201), 유주택자(201) ▶ 19.과세표준, 20.지분율
                            if (acqsSrc1 === "201") {
                                setVisible19 (true);
                                setVisible20 (true);
                                setDisabled08(true); // 08.취득원인(기존 아파트) Fixed
                            }

                            // 주택 > 다세대/다가구/단독 > 상속
                            // 무주택자(201), 유주택자(201) ▶ 19.과세표준, 20.지분율
                            if (estateFom === "202") {
                                setVisible19 (true);
                                setVisible20 (true);
                                setDisabled11(true); // 11.취득원인(다세대/다가구/단독) Fixed
                            }

                            // 조합원입주권 > 멸실 전 > 상속
                            // 무주택자(201), 유주택자(201) ▶ 19.과세표준, 20.지분율
                            if (loseYn === "201") {
                                setVisible19 (true);
                                setVisible20 (true);
                                setDisabled10(true); // 10.취득원인3(조합원입주권) Fixed
                            }
                        }}
                    />
                </div>
            </div>

            {/* 16. 시가표준액 */}
            <div className={(visible16 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>시가표준액</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={stdMkPrice}
                        codeArray={Util.getCodeArray("2014")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        border="1px solid #dddddd"
                        disabled={disabled16}
                        onChangeFunc={(data: string) => {
                            setStdMkPrice(data);
                            setDisabled11(true); // 11. 조정대상지역 Fixed

                            // 주택 > 아파트/연립 > 기존아파트 > 증여 > 조정대상
                            // 주택 > 다세대/다가구/다중/단독주택 > 증여 > 조정대상
                            // 조합원입주권 > 멸실 전 > 증여 > 조정대상
                            if (data === "201") {        // 3억 미만 ▶ 19.과세표준, 20.지분율, 21.면적
                                setVisible19 (true);
                                setVisible20 (true);
                                setVisible21 (true);
                                setVisible17(false); // 17. 증여자 미표시
                                setDonor    (""   ); // 17. 증여자 초기화
                                setVisible18(false); // 18. 증여자의 주택수 미표시
                                setDonorHseNo(""  ); // 18. 증여자의 주택수 초기화
                            } else if (data === "202") { // 3억 이상 ▶ 17. 증여자
                                setVisible17(true);
                            } else {}
                        }}
                    />
                </div>
            </div>

            {/* 17. 증여자 */}
            <div className={(visible17 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>증여자</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={donor}
                        codeArray={Util.getCodeArray("2015")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        border="1px solid #dddddd"
                        onChangeFunc={(data: string) => {
                            setDonor(data);

                            // 주택 > 아파트/연립 > 기존아파트 > 증여 > 조정대상 > 3억이상
                            // 조합원입주권 > 멸실 전 > 증여 > 조정대상 > 3억 이상
                            if (data === "201" || data === "202" || data === "203") {   // 배우자, 직계존속, 직계비속 ▶ 18.증여자의 주택수
                                setVisible18(true);
                            } else if (data === "204") {    // 기타 ▶ 19.과세표준, 20.지분율, 21.면적
                                setVisible19(true);
                                setVisible20(true);
                                setVisible21(true);
                                setVisible18(false); // 18.증여자의 주택수 미표시
                                setDonorHseNo(""  ); // 18.증여자의 주택수 초기화
                            } else {}
                        }}
                    />
                </div>
            </div>

            {/* 18. 증여자의 주택수 */}
            <div className={(visible18 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>증여자의 주택수</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={donorHseNo}
                        codeArray={Util.getCodeArray("2016")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        border="1px solid #dddddd"
                        onChangeFunc={(data: string) => {
                            setDonorHseNo(data);

                            // 주택 > 아파트/연립 > 기존아파트 > 증여 > 조정대상 > 3억이상 > 배우자, 직계존속, 직계비속
                            // 조합원입주권 > 멸실 전 > 증여 > 조정대상 > 3억 이상 > 배우자, 직계존속, 직계비속
                            // 1주택(201), 2주택이상(202) ▶ 19.과세표준, 20.지분율, 21.면적
                            setVisible19(true);
                            setVisible20(true);
                            setVisible21(true);
                        }}
                    />
                </div>
            </div>

            {/* 19. 과세표준 */}
            <div className={(visible19 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>과세표준</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <NumberTextBox
                        value={taxBase}
                        onChange={(data: string) => setTaxBase(data)}
                        borderClass="bgcWhite w300 h40 pr15 bd1"
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        maxLength={15}
                        inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                        unitText="원"
                    />
                </div>
                <div className='dpFlx aiC jcC ml10'>
                    <span className="SpoqaHanSansNeo-Regular fs15 ls08 fc17A840">{taxBaseHelp}</span>
                </div>
            </div>
            
            {/* 20. 지분율 */}
            <div className={(visible20 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>지분율(%)</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <NumberTextBox
                        value={shareRatio}
                        onChange={(data: string) => setShareRatio(data)}
                        borderClass="bgcWhite w300 h40 pr15 bd1"
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        maxLength={3}
                        disabled={disabled20}
                        inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                        unitText="%"
                    />
                </div>
            </div>

            {/* 21. 면적 */}
            <div className={(visible21 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>면적</span>
                </div>
                <div className='dpFlx aiC jcC ml10 bgcWhite'>
                    <SelectBox
                        value={prvuseAr}
                        codeArray={Util.getCodeArray("2012")}
                        placeholder="선택해주세요"
                        width={300}
                        height={40}
                        fontSize={15}
                        paddingRight={5}
                        border="1px solid #dddddd"
                        disabled={disabled21}
                        onChangeFunc={(data: string) => setPrvuseAr(data)}
                    />
                </div>
            </div>

            <div className='w100p dpFlx aiC jcL pt40 pb40'>
                <button className='w360 h60 bgc17A840 dpFlx aiC jcC csP bdN' onClick={() => callTax("A")}>
                    <span className='SpoqaHanSansNeo-Medium fs18 ls07 fcWhite'>계산하기</span>
                </button>
                <button className='w100 h60 bgcF6F6F6 dpFlx aiC jcC csP bd1 bdc878787' onClick={() => goReset()}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>초기화</span>
                </button>
            </div>

            {/* 주소 검색 모달 */}
            <Modal modalOpen={addressModalOpen} setModalOpen={setAddressModalOpen} title="주소검색" dim={true} visible={true}>
                <SimAddress setModalOpen={setAddressModalOpen} taxFlag="A" callBack={onFinishAddrSearch} />
            </Modal>
            
            {/* 주소 직접입력 모달 */}
            <Modal modalOpen={dirAddressInModalOpen} setModalOpen={setDirAddressInModalOpen} title="취득자산 정보 입력" dim={true} visible={true}>
                <DirectAddressIn setModalOpen={setDirAddressInModalOpen} taxFlag="A" callBack={onFinishAddrSearch} />
            </Modal>

            {/* 취득세 결과 모달 */}
            <Modal modalOpen={simAcqsTaxCalcModalOpen} setModalOpen={setSimAcqsTaxCalcModalOpen} title="취득세 세액 산출" dim={true} visible={simAcqsTaxCalcModalVsb} closeCallBack={() => setSimAcqsTaxCalcModalVsb(false)}>
                <SimAcqsTaxCalc setSimAcqsTaxCalcModalOpen={setSimAcqsTaxCalcModalOpen} setSimAcqsTaxCalcModalVsb={setSimAcqsTaxCalcModalVsb} acqsTaxParam={acqsTaxParam} />
            </Modal>
        </div>
    )
}
export default SimAcqsDirectTax;