import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Welcome = () => {
    const params = useParams();
    const navigate = useNavigate();

    return (
        <div className='w100p dpFlx jcC'>
            <div className='w460'>
                <div className='w100p h160 dpFlx aiC jcC'>
                    <span className="SpoqaHanSansNeo-Medium fs36 ls1 fcBlack fw500">회원가입 완료</span>
                </div>
                <div className='w100p h50 dpFlx aiC jcC mt40'>
                    <span className='SpoqaHanSansNeo-Medium fs20 ls1 fw500 taC'>
                        <span className='fc17A840'>{params.userNm} </span>
                        <span className='fc222222'>
                            고객님 CALLTAX 회원이 되신 것을 환영합니다!<br/>
                            CALLTAX 제공하는 전문적인 서비스를 경험해보세요.
                        </span>
                    </span>
                </div>
                <div className='w100p h50 dpFlx aiC jcC mt16'>
                    <span className='SpoqaHanSansNeo-Regular fs18 ls018'>
                        <span className='fc666666 pr10'>아이디</span>
                        <span className='fc222222'>{params.userId}</span>
                    </span>
                </div>
                <div className='w100p h62 bgc17A840 dpFlx aiC jcC csP mt40 mb133' onClick={() => navigate('/portal/login')}>
                    <span className="SpoqaHanSansNeo-Bold fs18 ls08 fcWhite">로그인</span>
                </div>
            </div>
        </div>
    )
}
export default Welcome;