/***********************************************************************************
*** Law21at10 ** 조세특례제한법 98조의 8 준공 후 미분양주택의 취득자에 대한 양도소득세의 과세특례 Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law21at10Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}

export default function Law21at10({ setModalOpen, setLawModalTitle, callBack, callMode }: Law21at10Props) {
    React.useEffect(() => {
        setLawModalTitle("조세특례제한법 98조의 8 준공 후 미분양주택의 취득자에 대한 양도소득세의 과세특례");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className='w100p hAuto ofA mb30'>
                <table className={
                    'forest-list-table bdt1 bdb1 bdcDDDDDD ' +
                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075'
                }>
                    <colgroup>
                        <col className='w80'></col>
                        <col className='w100'></col>
                        <col className='w80'></col>
                        <col className='w100'></col>
                        <col className='w80'></col>
                        <col className='w60'></col>
                        <col className='w60'></col>
                        <col className='w60'></col>
                        <col className='w100'></col>
                        <col className='w100'></col>
                        <col className='w100'></col>
                        <col className='w120'></col>
                    </colgroup>
                    <tbody>
                        <tr className='h40'>
                            <th rowSpan={2}>구분</th>
                            <th rowSpan={2}>주택구분</th>
                            <th rowSpan={2}>주택규모</th>
                            <th rowSpan={2}>취득시기</th>
                            <th rowSpan={2}>가액기준</th>
                            <th colSpan={2}>등록</th>
                            <th colSpan={4}>추가질문</th>
                            <th rowSpan={2}>혜택</th>
                        </tr>
                        <tr className='h29'>
                            <th>지자체</th>
                            <th>세무서</th>
                            <th>최초계약</th>
                            <th>임대기간</th>
                            <th>미분양시점</th>
                            <th className='bdr1-i bdcDDDDDD-i'>필수서류</th>
                        </tr>
                        <tr className='h110'>
                            <th>개별사항</th>
                            <td>분양권에 의한 아파트 중 미분양</td>
                            <td>135㎡ 이하</td>
                            <td>2015.1.1.~2015.12.31.</td>
                            <td>취득 가액이<br />6억원 이하</td>
                            <td colSpan={2}>등록</td>
                            <td>여</td>
                            <td>2015.12.31. 이전에 임대계약 체결하고 5년 이상 임대</td>
                            <td>2014.12.31. 현재 미분양주택</td>
                            <td>지자체 확인서 및 지자체 날인 매매계약서</td>
                            <td>최초 5년간 양도소득금액 50% 과세제외</td>
                        </tr>
                        <tr className='h110'>
                            <th>공통사항</th>
                            <td className='taL pt15 pb15 pl15'colSpan={11}>
                                1. 비과세 판정시 주택수 제외<br />
                                2. 다주택 중과배제(유예)<br />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}