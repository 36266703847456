/*******************************************************************************
*** Mypage ** 마이 페이지
*******************************************************************************/
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from "recoil";
import * as Atom from "js/common/Atom";
import Util from "js/common/Util";
import Axios from 'js/common/Axios';
import AppContext from 'js/common/AppContext';

function Mypage() {
    const navigate = useNavigate();
    const userInfo = useRecoilValue(Atom.userInfo);

    const [bsnmNm         , setBsnmNm         ] = React.useState<string>();
    const [zip            , setZip            ] = React.useState<string>();
    const [rdnmAdres      , setRdnmAdres      ] = React.useState<string>();
    const [rdnmAdresDetail, setRdnmAdresDetail] = React.useState<string>();
    const [bsnmRegNo      , setBsnmRegNo      ] = React.useState<string>("");

    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/mypage/selBsnmInfo.do", // 사업자정보 조회
            methodType: "post",
            onSuccessFn: (res: any) => {
                AppContext.hideSpinner();
                if (Util.isEmpty(res.item)) {
                    return;
                }
                setBsnmNm(res.item.bsnmNm);
                setZip(res.item.zip);
                setRdnmAdres(res.item.rdnmAdres);
                setRdnmAdresDetail(res.item.rdnmAdresDetail);
                setBsnmRegNo(res.item.bsnmRegNo);
            }
        });
    }, []);

    // 마이페이지 수정버튼 클릭
    const editBtnClickFunc = () => {
        navigate("/system/mypage/mod");
    };

    return (
        <div className='w100p'>
            <div className='w100p dpFlx jcC pt30 pb60'>
                <span className="SpoqaHanSansNeo-Medium fs38 ls266 fcBlack fw500">마이페이지</span>
            </div>
            <div className="w100p dpFlx flxdCol aiC jcC bgcF6F6F6">
                <div className="w1100 mt60 dpFlx aiC">
                    <table className={
                        "forest-table tr-h50 " +
                        "th-fm-SpoqaHanSansNeo-Medium th-fs14 th-ls07 th-fc222222 th-fw500 " +
                        "td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666"
                    }>
                        <colgroup>
                            <col className="w100"/>
                            <col className="w245"/>
                            <col className="w100"/>
                            <col className="w210"/>
                            <col className="w100"/>
                            <col />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th className="taL">아이디</th>
                                <td>{userInfo.userId}</td>
                                <th className="taL">라이선스</th>
                                <td>-</td>
                                <th className="taL">상호</th>
                                <td>{bsnmNm}</td>
                            </tr>
                            <tr>
                                <th className="taL">성명</th>
                                <td><span className="fmForest-b ls08">{userInfo.userNm}</span></td>
                                <th className="taL">이용기간</th>
                                <td>-</td>
                                <th className="taL">소재지</th>
                                <td>({zip}) {rdnmAdres} {(rdnmAdresDetail)}</td>
                            </tr>
                            <tr>
                                <th className="taL">전화번호</th>
                                <td><span className="fmForest-b ls08">{Util.cvtPhoneStrForm(userInfo.mbtlnum, "-")}</span></td>
                                <th className="taL">결제금액</th>
                                <td>-</td>
                                <th className="taL">사업자등록번호</th>
                                <td>{Util.cvtBizRegNumForm(bsnmRegNo, "-")}</td>
                            </tr>
                            <tr>
                                <th className="taL">이메일주소</th>
                                <td>{userInfo.emailAdres}</td>
                                <th></th>
                                <td></td>
                                <th></th>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='dpFlx aiC jcC mt40 mb100'>
                    <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={editBtnClickFunc}>
                        <span className="SpoqaHanSansNeo-Medium fs16 fcWhite fw500">수정</span>
                    </div>
                </div>
            </div>
        </div>
    )
  }
  export default Mypage