/*******************************************************************************
 You are strictly prohibited to copy, disclose, distribute, modify, or use
 this program in part or as a whole without the prior written consent of
 CallTax. CallTax, owns the intellectual property rights in and to this program.
 (Copyright © 2023 CallTax. All Rights Reserved| Confidential)

 Specification
    1. Program ID    : AdjustmentAreas.tsx
    2. Program Title : 조정대상지역 도움말 Modal
    3. Created by    : JH Lee
    4. Create Date   : 2024.03.04.
    5. Reference     :
        - TrsftaxCalc.tsx(양도세 결과표) 페이지에서 호출
*******************************************************************************/
const AdjustmentAreas = () => {
    return (
        <div className="w1120 h100p pr20">
            <span className="SpoqaHanSansNeo-Regular fc666666 ls075 fs15 lh20">
            거주자가 조정대상지역의 공고가 있은 날 이전에 매매계약을 체결하고 계약금을 지급한 사실이 증빙에 의하여 확인되는 경우로서 해당 거주자가 속한 1세대가 계약금 지급일 현재 주택을 보유하지 아니하는 경우 2년 이상 거주기한의 제한을 받지 아니한다.<br/>
            (소득세법시행령 제154조 제1항 제5호)<br/><br/>
            관련 예규를 정리하면 다음과 같다.<br/><br/>
            1. 계약금 지급일 현재 무주택 세대에 해당되는지 여부<br/>
            </span>
            <table className={"forest-list-table w100p th-fm-SpoqaHanSansNeo-Medium "
                            + "th-fw500 th-fs14 th-fc222222 th-bdcDDDDDD th-bdb1 bdcDDDDDD "
                            +  "td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-p15 bdb1 tr-bdb1 mt20 mb20"
            }>
                <colgroup>
                    <col className="w60"></col>
                    <col className="w810"></col>
                    <col className="w230"></col>
                </colgroup>
                <tbody>
                    <tr className="h40">
                        <th>구분</th>
                        <th>무주택 세대의 내용</th>
                        <th>관련 근거</th>
                    </tr>
                    <tr className="h50">
                        <td>1</td>
                        <td className="taL">2017.8.2. 이전에 조정대상지역 내 주택을 취득하는 매매계약 체결 및 계약금을 지급한 1세대의 해당주택 비과세 거주요건(2년) 적용여부 판정 시 조세특례제한법 제99조의 2 주택을 보유한 경우 무주택세대로 보지 않음</td>
                        <td>조심-2022-서-0065<br/>(2022.12.29.)</td>
                    </tr>
                    <tr className="h50">
                        <td>2</td>
                        <td className="taL">상속주택의 소수지분을 보유한 자가 2017.8.2. 이전에 다른 주택의 매매계약을 체결하고 계약금을 지급한 경우 거주기간 요건을 적용하는 것임</td>
                        <td>서면부동산<br/>2018-935(2018.11.19.)</td>
                    </tr>
                    <tr className="h50">
                        <td>3</td>
                        <td className="taL">2017.8.2. 이전에 조정대상지역 내 주택을 취득하는 매매계약 체결 및 계약금을  지급한 1세대의 해당주택 비과세 거주요건(2년) 적용여부 판정시 소득세법시행령 제155조 제7항 상속받은 농어촌주택을 보유한 경우에 무주택세대로 보지 않음</td>
                        <td>부동산납세과-1164<br/>(2018.12.06.)</td>
                    </tr>
                    <tr className="h50">
                        <td>4</td>
                        <td className="taL">주택분양권 및 소득세법 제89조 제2항에 따른 조합원입주권을 보유한 세대가 2017.8.2. 이전에 매매계약을 체결하고 계약금을 지급하여 취득한 조정대상지역 내 주택분양권이 완공되어 해당주택을 양도하는 경우 1ㅅ대 1주택 비과세 거주요건을 적용하지 않음</td>
                        <td>부동산납세과-841<br/>(2019.08.26.)</td>
                    </tr>
                </tbody>
            </table>

            <span className="SpoqaHanSansNeo-Regular fc666666 ls075 fs15 lh20">
                2. 계약금을 지급한 경우 등
            </span>
            <table className={"forest-list-table w100p th-fm-SpoqaHanSansNeo-Medium "
                            + "th-fw500 th-fs14 th-fc222222 th-bdcDDDDDD th-bdb1 bdcDDDDDD "
                            +  "td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-p15 bdb1 tr-bdb1 mt20 mb20"
            }>
                <colgroup>
                    <col className="w60"></col>
                    <col className="w810"></col>
                    <col className="w230"></col>
                </colgroup>
                <tbody>
                    <tr className="h40">
                        <th>구분</th>
                        <th>내용</th>
                        <th>관련 근거</th>
                    </tr>
                    <tr className="h50">
                        <td>1</td>
                        <td className="taL">1세대 1주택 비과세 적용시 거주요건을 판단함에 있어, 계약금 지급일은 계약금을 완납한 경우를 말하는 것임</td>
                        <td>부동산납세과-841<br/>(2019.08.26.)</td>
                    </tr>
                    <tr className="h50">
                        <td>2</td>
                        <td className="taL">무주택 1세대가 조정대상지역 공고 이전에 증여받은 분양권에 기해 취득한 주택은 거주요건을 적용하는 것임</td>
                        <td>기획재정부조세법령운용과-988<br/>(2021.11.17.)</td>
                    </tr>
                    <tr className="h69">
                        <td>3</td>
                        <td className="taL">계약금 지급일 현재 무주택자가 조정대상지역 공고 이전에 분양계약을 체결하고 계약금을 지급한 주택 분양권 지분 일부를 동일 세대원인 자녀에게 증여하고 주택 양도일까지 자녀와 동일세대인 경우 소득세법시행령 제154조 제1항 제5호에 따라 1세대 1주택 비과세 거주요건을 적용하지 아니하는 것임</td>
                        <td>부동산납세과-3588<br/>(2022.11.22.)</td>
                    </tr>
                    <tr className="h50">
                        <td>4</td>
                        <td className="taL">거주기간의 제한을 받지 않는 주택의 분양권을 조정대상지역의 공고일 이후 동일세대원인 자녀에게 증여하고, 증여 이후 자녀는 세대분리하는 경우 해당 주택은 거주요건을 적용하는 것임</td>
                        <td>법규과-1584<br/>(2022.05.23.)</td>
                    </tr>
                    <tr className="h50">
                        <td>5</td>
                        <td className="taL">2017.8.2. 이전에 피상속인이 취득한 조정대상지역 내 주택을 2017.8.2. 당시 동일세대원이었던 상속인이 상속개시일에 동일세대원으로서 상속받은 주택을 양도하는 경우 1세대 1주택 비과세 거주요건을 적용하지 아니하는 것임</td>
                        <td>법령해석과-1454<br/>(2021.04.23.)</td>
                    </tr>
                    <tr className="h50">
                        <td>6</td>
                        <td className="taL">2017.8.2. 이전에 조정대상지역 소재 주택을 피상속인이 취득하고, 2017.8.3. 이후에 동일세대원이 된 상속인이 동 주택을 상속받아 양도하는 경우에는 거주요건을 적용하는 것임</td>
                        <td>법령해석과-1573<br/>(2021.05.04.)</td>
                    </tr>
                    <tr className="h50">
                        <td>7</td>
                        <td className="taL">2017.8.2. 현재 피상속인과 동일세대이나, 2017.8.3. 이후 세대분리 후 다시 합가하여 상속개시 당시 동일세대원으로서 쟁점주택을 상속받아 양도하는 경우에는 거주기간의 제한을 받는 것임</td>
                        <td>법령해석과-3397<br/>(2021.09.30.)</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
export default AdjustmentAreas;