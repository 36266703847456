/*******************************************************************************
*** Login ** 로그인
*******************************************************************************/
import React                 from 'react';
import { useNavigate }       from 'react-router-dom';
import JSEncrypt             from 'jsencrypt'
import Util                  from 'js/common/Util';
import Axios                 from 'js/common/Axios';
import AppContext            from 'js/common/AppContext';
import { useSetRecoilState } from "recoil";
import * as Atom             from "js/common/Atom";
import ImageCheckBox         from 'js/common/ui/input/ImageCheckBox';
import TextBox               from 'js/common/ui/input/TextBox';

function Login() {
    const navigate = useNavigate();
    const setIsAdmin  = useSetRecoilState(Atom.isAdmin);
    const setUserInfo = useSetRecoilState(Atom.userInfo);
    const setHshldCnt = useSetRecoilState(Atom.hshldCnt);

    const [userId    , setUserId        ] = React.useState<string>('');
    const [password  , setPassword      ] = React.useState<string>('');
    const [saveUserId, setSaveUserId] = React.useState<boolean>(false); // 아이디 저장 체크

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.code === "Enter") {
            goLogin();
        }
    }

    // login 버튼 클릭 이벤트
    const goLogin = () => {
        if (Util.isEmpty(userId)) {
            AppContext.openAlert({
                title: "아이디 확인",
                msg: "아이디를 입력해주세요.",
                icon: "warning"
            });
            return;
        }
        if (Util.isEmpty(password)) {
            AppContext.openAlert({
                title: "비밀번호 확인",
                msg: "비밀번호를 입력해주세요.",
                icon: "warning"
            });
            return;
        }
        if (password.length < 8) {
            AppContext.openAlert({
                title: "비밀번호 자릿수 확인",
                msg: "비밀번호는 8자리 이상이어야 합니다.",
                icon: "check"
            });
            return;
        }
        AppContext.showSpinnerDim();
        Axios.dataAccess({
            url: "portal/auth/genRsaKeyByUserId.do",    // 아이디로
            methodType: "post",
            paramType: "object",
            paramData: {
                userId: userId
            },
            onSuccessFn: (res1: any) => {
                if (res1.item.loginRslt !== "O") {
                    AppContext.hideSpinner();
                    AppContext.openAlert({
                        title: "로그인 실패",
                        msg: res1.item.blockMsg,
                        icon: "warning"
                    });
                    return;
                }
                const pubKey = res1.item.pubKeyStr;
                const encrypt = new JSEncrypt();
                encrypt.setPublicKey(pubKey);
                const encPassword = encrypt.encrypt(password);
                Axios.dataAccess({
                    url: "portal/auth/login.do",
                    methodType: "post",
                    paramType: "json",
                    paramData: {
                        userId: userId,
                        encPassword: encPassword
                    },
                    onSuccessFn: (res2: any) => {
                        if (res2.item.loginRslt !== "O") {
                            AppContext.hideSpinner();
                            AppContext.openAlert({
                                title: "로그인 실패",
                                msg: res2.item.blockMsg,
                                icon: "warning"
                            });
                            return;
                        }
                        setUserInfo(res2.item.userInfo);
                        const tokenCrtDt = Util.getSpecificDate("-", "day", 0, "SS", "");
                        if (tokenCrtDt !== undefined) {
                            localStorage.setItem("tokenCrtDt", tokenCrtDt);
                        }
                        localStorage.setItem("grantType", res2.item.grantType);
                        localStorage.setItem("accessToken", res2.item.accessToken);
                        localStorage.setItem("refreshToken", res2.item.refreshToken);
                        localStorage.setItem('isAdmin', res2.item.userInfo.isAdmin ? "1" : "0");
                        setIsAdmin(res2.item.userInfo.isAdmin);
                        setHshldCnt(res2.item.hshldCnt);
                        if (saveUserId) {
                            localStorage.setItem("loginUserId", userId);
                        } else {
                            localStorage.removeItem("loginUserId");
                        }
                        navigate('/system/hshld');
                        AppContext.hideSpinner();
                    }
                });
            }
        });
    }

    // 아이디 저장 체크
    React.useEffect(() => {
        const loginUserId = localStorage.getItem("loginUserId");
        if (loginUserId === null) {
            setSaveUserId(false);
            return;
        }
        setSaveUserId(true);
        setUserId(loginUserId);
    }, []);

    return (
        <div className='w100p'>
            <div className='w100p h160 dpFlx aiC jcC'>
                <span className="SpoqaHanSansNeo-Bold fs36 ls1 fcBlack">로그인</span>
            </div>
            <div className='w100p h385 dpFlx aiC jcC mb100'>
                <div className='w460'>
                    <div className='h45 dpFlx aiE pb5 bdBox'>
                        <span className="SpoqaHanSansNeo-Bold fs16 ls08 fc222222">아이디</span>
                    </div>
                    <TextBox
                        type="text"
                        value={userId}
                        height={60}
                        fontSize={16}
                        fontFamily='SpoqaHanSansNeo-Regular'
                        maxLength={30}
                        placeholder="아이디"
                        onChangeFunc={(text: string) => setUserId(text)}
                        inputClassName='bgcWhite ls08'
                        color="#222222"
                        offColor="#999999"
                        className='bd1 pl15 pr20'
                        iconEl={
                            <img
                                onClick={() => setUserId("")}
                                className={'csP ' + (Util.isEmpty(userId) ? "dpNone" : "")}
                                src={require('img/common/icon_cancel_fill_18.png')}
                                alt="icon_cancel_fill_18"
                            />
                        }
                    />
                    <div className='h45 dpFlx aiE pb5 bdBox'>
                        <span className="SpoqaHanSansNeo-Bold fs16 ls08 fc222222">비밀번호</span>
                    </div>
                    <TextBox
                        type="password"
                        value={password}
                        height={60}
                        fontSize={16}
                        fontFamily='Inter-Regular'
                        phFontFamily='SpoqaHanSansNeo-Regular'
                        maxLength={30}
                        placeholder="비밀번호"
                        onChangeFunc={(text: string) => setPassword(text)}
                        onKeyDown={handleKeyDown}
                        inputClassName='bgcWhite ls08'
                        color="#222222"
                        offColor="#999999"
                        className='bd1 pl15 pr20'
                        iconEl={
                            <img
                                onClick={() => setPassword("")}
                                className={'csP ' + (Util.isEmpty(password) ? "dpNone" : "")}
                                src={require('img/common/icon_cancel_fill_18.png')}
                                alt="icon_cancel_fill_18"
                            />
                        }
                    />
                    <div className='dpFlx mt10 mb30'>
                        <ImageCheckBox
                            value={saveUserId}
                            setValue={setSaveUserId}
                            labelText='아이디 저장'
                            btnClass='w20 h20'
                            labelClass='SpoqaHanSansNeo-Regular fs14 ls07 fc666666 pl5'
                        />
                    </div>
                    <div className='w100p h62 bgc17A840 mt30 dpFlx aiC jcC csP' onClick={goLogin}>
                        <span className="SpoqaHanSansNeo-Bold fs18 ls08 fcWhite">로그인</span>
                    </div>
                    <div className='w100p h16 mt64 dpFlx aiC jcC'>
                        <span onClick={() => navigate('/portal/findId')} className="SpoqaHanSansNeo-Regular fs13 ls065 fc666666 csP">아이디 찾기</span>
                        <div className='w1 h12 bgcF6F6F6 ml13 mr13'/>
                        <span onClick={() => navigate('/portal/findPasswd')} className="SpoqaHanSansNeo-Regular fs13 ls065 fc666666 csP">비밀번호 찾기</span>
                        <div className='w1 h12 bgcF6F6F6 ml13 mr13'/>
                        <span onClick={() => navigate('/portal/join')} className="SpoqaHanSansNeo-Regular fs13 ls065 fc666666 csP">회원 가입하기</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Login