import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import html2canvas from "html2canvas";
import saveAs from "file-saver";

import Axios from "js/common/Axios";
import { acqsInputValueState, taxFlagState } from "js/common/Atom";
import Header from "./header";
import BottomButtonGroup from "./BottomButtonGroup";
import Util from "js/common/Util";
import { ResultCodeName, AcqsTaxResult } from "js/common/types/types";
import { MainSectionContainer } from "js/mobile/common/layout";
import AppContext from "js/common/AppContext";
import { useAcqsInputs } from "js/common/hooks/useAcqsInputs";
import { calculatorPcWebMaxWidth } from "js/common/const";

function AcqsTaxCalcResult() {
  const acqsInputValue = useRecoilValue(acqsInputValueState);
  const taxFlag = useRecoilValue(taxFlagState);

  const resultCaptureRef = useRef<HTMLDivElement>(null);

  const [calcInfo, setCalcInfo] = useState<AcqsTaxResult | null>(null);
  const [codeNameList, setCodeNameList] =
    useState<Array<ResultCodeName> | null>(null);
  const [calcError, setCalcError] = useState<boolean>(false);
  const [isSavingResult, setIsSavingResult] = useState<boolean>(false);

  const totalTaxRate = calcInfo
    ? Number(calcInfo.acqs_tax_rate) +
      Number(calcInfo.local_edu_tax_rate) +
      Number(calcInfo.farm_fish_tax_rate)
    : "";
  const totalTax = calcInfo
    ? Number(calcInfo.acqs_tax) +
      Number(calcInfo.local_edu_tax) +
      Number(calcInfo.farm_fish_tax)
    : "";

  const { initializeInputs } = useAcqsInputs();

  useEffect(() => {
    Axios.dataAccess({
      url: "common/mobile/callApi.do",
      methodType: "post",
      paramType: "object",
      paramData: {
        apiName: "taxcalc",
        tailUrl: "simpletax/acquisitiontax",
        paramStr:
          taxFlag +
          "?estate_se=" +
          acqsInputValue.estateSe + // 01. 부동산의 종류(상세구분)
          "&estate_fom=" +
          acqsInputValue.estateFom + // 02. 주택구분
          "&acqs_src_1=" +
          acqsInputValue.acqsSrc1 + // 03. 취득구분 1(아파트/연립)
          "&acqs_src_2=" +
          acqsInputValue.acqsSrc2 + // 04. 취득구분 2(오피스텔)
          "&lose_yn=" +
          acqsInputValue.loseYn + // 05. 멸실여부
          "&land_type=" +
          acqsInputValue.landType + // 06. 토지종류
          "&farm_period=" +
          acqsInputValue.farmPeriod + // 07. 자경기간
          "&acqs_cause_1=" +
          acqsInputValue.acqsCause1 + // 08. 취득원인 1(기존 아파트)
          "&acqs_cause_2=" +
          acqsInputValue.acqsCause2 + // 09. 취득원인 2(분양권/오피스텔)
          "&acqs_cause_3=" +
          acqsInputValue.acqsCause3 + // 10. 취득원인 3(조합원입주권)
          "&acqs_cause_4=" +
          acqsInputValue.acqsCause4 + // 11. 취득원인 4(다세대/다가구/단독주택)
          "&mdat_trget_are=" +
          acqsInputValue.mdatTrgetAre + // 12. 조정대상지역
          "&hse_num_at_1=" +
          acqsInputValue.hseNumAt1 + // 13. 주택수 1(조정대상지역)
          "&hse_num_at_2=" +
          acqsInputValue.hseNumAt2 + // 14. 주택수 2(비조정대상지역)
          "&inheir_hse_no=" +
          acqsInputValue.inheirHseNo + // 15. 상속인의 주택유무
          "&std_mk_price=" +
          acqsInputValue.stdMkPrice + // 16. 시가표준액 기준
          "&donor=" +
          acqsInputValue.donor + // 17. 증여자
          "&donor_hse_no=" +
          acqsInputValue.donorHseNo + // 18. 증여자의 주택수
          "&tax_base=" +
          acqsInputValue.taxBase + // 19. 과세표준
          "&share_ratio=" +
          acqsInputValue.shareRatio + // 20. 지분율
          "&prvuse_ar=" +
          acqsInputValue.prvuseAr, // 21. 면적
      },
      onSuccessFn: (res: any) => {
        if (Util.isEmpty(res.item.rtn_list)) {
          /*AppContext.openAlert({
                        title: '과세 대상 여부',
                        msg: res.item.rtn_msg, // 과세대상에 해당되지 않습니다.
                        icon: 'check',
                    });*/
          console.info(
            "%%% 취득세 세액 계산 결과: 과세대상에 해당되지 않습니다."
          );
          //setNotTaxation(true);
          return;
        }
        // 취득세 세액 계산 결과
        setCalcInfo(res.item.rtn_list[0]);
        setCalcError(false);
      },
      onFailedFn: () => {
        console.error("%%% 취득세 세액 계산 결과 오류");
        setCalcError(true);
      },
    });

    Axios.dataAccess({
      url: "common/mobile/simpletax/getAquisitionParamCodeNm.do",
      methodType: "get",
      paramType: "object",
      paramData: {
        estateSe: acqsInputValue.estateSe, // 01. 부동산의 종류(상세구분)
        estateFom: acqsInputValue.estateFom, // 02. 주택구분
        acqsSrc1: acqsInputValue.acqsSrc1, // 03. 취득구분 1(아파트/연립)
        acqsSrc2: acqsInputValue.acqsSrc2, // 04. 취득구분 2(오피스텔)
        loseYn: acqsInputValue.loseYn, // 05. 멸실여부
        landType: acqsInputValue.landType, // 06. 토지종류
        farmPeriod: acqsInputValue.farmPeriod, // 07. 자경기간
        acqsCause1: acqsInputValue.acqsCause1, // 08. 취득원인 1(기존 아파트)
        acqsCause2: acqsInputValue.acqsCause2, // 09. 취득원인 2(분양권/오피스텔)
        acqsCause3: acqsInputValue.acqsCause3, // 10. 취득원인 3(조합원입주권)
        acqsCause4: acqsInputValue.acqsCause4, // 11. 취득원인 4(다세대/다가구/단독주택)
        mdatTrgetAre: acqsInputValue.mdatTrgetAre, // 12. 조정대상지역
        hseNumAt1: acqsInputValue.hseNumAt1, // 13. 주택수 1(조정대상지역)
        hseNumAt2: acqsInputValue.hseNumAt2, // 14. 주택수 2(비조정대상지역)
        inheirHseNo: acqsInputValue.inheirHseNo, // 15. 상속인의 주택유무
        stdMkPrice: acqsInputValue.stdMkPrice, // 16. 시가표준액 기준
        donor: acqsInputValue.donor, // 17. 증여자
        donorHseNo: acqsInputValue.donorHseNo, // 18. 증여자의 주택수
        taxBase: acqsInputValue.taxBase, // 19. 과세표준
        shareRatio: acqsInputValue.shareRatio, // 20. 지분율
        prvuseAr: acqsInputValue.prvuseAr, // 21. 면적
      },
      onSuccessFn: (res: any) => {
        setCodeNameList(res.item);
      },
      onFailedFn: () => {
        console.error("%%% 취득세 세액 계산 결과 오류");
      },
    });
  }, [acqsInputValue, taxFlag]);

  const onClickBack = () => {
    initializeInputs();
  };

  const onClickSave = async () => {
    //if (!resultCaptureRef.current) return;

    try {
      AppContext.showSpinner();
      setIsSavingResult(true);

      // 저장할 요소가 다시 렌더링 될 때까지 기다림.
      await new Promise((resolve) => setTimeout(resolve, 100));

      // resultCaptureRef 처리
      if (!resultCaptureRef.current) {
        return new Error("저장할 요소가 아직 렌더링 되지 않음.");
      }

      const resultCanvas = await html2canvas(resultCaptureRef.current);
      const resultImage = resultCanvas.toDataURL("image/png");
      saveAs(resultImage, "calltax_result.png");
    } catch (error) {
      console.error("Error capturing and saving images:", error);
    } finally {
      AppContext.hideSpinner();
      setIsSavingResult(false);
    }
  };

  return (
    <Body>
      <Header onClickBack={onClickBack} />
      <MainContainer>
        <MainSectionContainer>
          <TotalTaxContainer>
            <p className="title">
              신고세액합계
              <span className="highlight"></span>
            </p>
            <div className="tax">
              <span className="number">
                {calcInfo ? Util.comma(calcInfo.total_tax) : ""}
              </span>
              <span className="unit">원</span>
            </div>
          </TotalTaxContainer>
          <SumInfoContainer>
            <div className="info">
              <p className="name">과세표준</p>
              <div className="value">
                <span className="number">
                  {calcInfo ? Util.comma(calcInfo.tax_base) : ""}
                </span>
                <span className="unit">원</span>
              </div>
            </div>
            <div className="info">
              <p className="name">지분율</p>
              <div className="value">
                <span className="number">
                  {calcInfo ? Number(calcInfo.share_ratio) : ""}
                </span>
                <span className="unit">%</span>
              </div>
            </div>
          </SumInfoContainer>
          <DetailTaxContainer>
            <div className="title">
              <p>세목별 확인하기</p>
            </div>
            <TaxInfoContainer>
              <div className="taxWrap">
                <div className="tax closed">
                  <p className="name">취득세</p>
                  <div className="value">
                    <div>
                      <span className="rate">
                        {calcInfo ? Number(calcInfo.acqs_tax_rate) : ""}
                      </span>
                      <span className="unit">%</span>
                    </div>
                    <div>
                      <span className="number">
                        {calcInfo ? Util.comma(calcInfo.acqs_tax) : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
              </div>
            </TaxInfoContainer>
            <TaxInfoContainer>
              <div className="taxWrap">
                <div className="tax closed">
                  <p className="name">지방교육세</p>
                  <div className="value">
                    <div>
                      <span className="rate">
                        {calcInfo ? Number(calcInfo.local_edu_tax_rate) : ""}
                      </span>
                      <span className="unit">%</span>
                    </div>
                    <div>
                      <span className="number">
                        {calcInfo ? Util.comma(calcInfo.local_edu_tax) : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
              </div>
            </TaxInfoContainer>
            <TaxInfoContainer>
              <div className="taxWrap">
                <div className="tax closed">
                  <p className="name">농어촌특별세</p>
                  <div className="value">
                    <div>
                      <span className="rate">
                        {calcInfo ? Number(calcInfo.farm_fish_tax_rate) : ""}
                      </span>
                      <span className="unit">%</span>
                    </div>
                    <div>
                      <span className="number">
                        {calcInfo ? Util.comma(calcInfo.farm_fish_tax) : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
              </div>
            </TaxInfoContainer>
            <TaxInfoContainer>
              <div className="taxWrap">
                <div className="tax closed">
                  <p className="name">합계</p>
                  <div className="value">
                    <div>
                      <span className="rate">
                        {Math.floor(Number(totalTaxRate) * 1000) / 1000}
                      </span>
                      <span className="unit">%</span>
                    </div>
                    <div>
                      <span className="number">
                        {Util.comma(totalTax.toString())}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
              </div>
            </TaxInfoContainer>
          </DetailTaxContainer>
        </MainSectionContainer>
        <BottomButtonGroup
          onClickBack={onClickBack}
          onClickSave={onClickSave}
          showSave={!calcError}
        />
      </MainContainer>
      {isSavingResult && (
        <HiddenSectionContainer
          $hidden={!isSavingResult}
          ref={resultCaptureRef}
        >
          <Header onClickBack={onClickBack} />
          <TotalTaxContainer>
            <p className="title">
              신고세액합계
              <span className="highlight"></span>
            </p>
            <div className="tax">
              <span className="number">
                {calcInfo ? Util.comma(calcInfo.total_tax) : ""}
              </span>
              <span className="unit">원</span>
            </div>
          </TotalTaxContainer>
          <SumInfoContainer>
            <div className="info">
              <p className="name">과세표준</p>
              <div className="value">
                <span className="number">
                  {calcInfo ? Util.comma(calcInfo.tax_base) : ""}
                </span>
                <span className="unit">원</span>
              </div>
            </div>
            <div className="info">
              <p className="name">지분율</p>
              <div className="value">
                <span className="number">
                  {calcInfo ? Number(calcInfo.share_ratio) : ""}
                </span>
                <span className="unit">%</span>
              </div>
            </div>
          </SumInfoContainer>
          <DetailTaxContainer>
            <div className="title">
              <p>세목별 확인하기</p>
            </div>
            <TaxInfoContainer>
              <div className="taxWrap">
                <div className="tax closed">
                  <p className="name">취득세</p>
                  <div className="value">
                    <div>
                      <span className="rate">
                        {calcInfo ? Number(calcInfo.acqs_tax_rate) : ""}
                      </span>
                      <span className="unit">%</span>
                    </div>
                    <div>
                      <span className="number">
                        {calcInfo ? Util.comma(calcInfo.acqs_tax) : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
              </div>
            </TaxInfoContainer>
            <TaxInfoContainer>
              <div className="taxWrap">
                <div className="tax closed">
                  <p className="name">지방교육세</p>
                  <div className="value">
                    <div>
                      <span className="rate">
                        {calcInfo ? Number(calcInfo.local_edu_tax_rate) : ""}
                      </span>
                      <span className="unit">%</span>
                    </div>
                    <div>
                      <span className="number">
                        {calcInfo ? Util.comma(calcInfo.local_edu_tax) : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
              </div>
            </TaxInfoContainer>
            <TaxInfoContainer>
              <div className="taxWrap">
                <div className="tax closed">
                  <p className="name">농어촌특별세</p>
                  <div className="value">
                    <div>
                      <span className="rate">
                        {calcInfo ? Number(calcInfo.farm_fish_tax_rate) : ""}
                      </span>
                      <span className="unit">%</span>
                    </div>
                    <div>
                      <span className="number">
                        {calcInfo ? Util.comma(calcInfo.farm_fish_tax) : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
              </div>
            </TaxInfoContainer>
            <TaxInfoContainer>
              <div className="taxWrap">
                <div className="tax closed">
                  <p className="name">합계</p>
                  <div className="value">
                    <div>
                      <span className="rate">
                        {Math.floor(Number(totalTaxRate) * 1000) / 1000}
                      </span>
                      <span className="unit">%</span>
                    </div>
                    <div>
                      <span className="number">
                        {Util.comma(totalTax.toString())}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
              </div>
            </TaxInfoContainer>
          </DetailTaxContainer>
          <SumInfoContainer>
            {codeNameList != null && (
              <>
                {codeNameList.map((codeName: ResultCodeName) => {
                  return (
                    <div className="info" key={codeName.codeTyNm}>
                      <p className="name">{codeName.codeTyNm}</p>
                      <div className="value">
                        <div>
                          <span className="number">{codeName.codeNm}</span>
                          {codeName.unit != null && (
                            <span className="unit">{codeName.unit}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </SumInfoContainer>
          <UrlWrap>calltax.kr</UrlWrap>
        </HiddenSectionContainer>
      )}
    </Body>
  );
}

const UrlWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  height: 50px;
  padding: 0px 10px;
  font-family: "Spoqa Han Sans Neo";
  font-weight: 600;
  font-size: 2em;
  line-height: 1;
  letter-spacing: -0.03125em;
  color: ${(props) => props.theme.color.sNavy};
`;

/*const IconWrap = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;

    width: 112.71px;
    height: 112.71px;

    img {
        width: 100%;
        height: 100%;
    }
`;*/

const HiddenSectionContainer = styled.section<{ $hidden: boolean }>`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  /*z-index: 0;*/

  width: 100%;
  height: auto;
  padding: 13px 20px 80px 13px;
  background-color: ${(props) => props.theme.color.bg};

  @media (min-width: 1201px) {
    max-width: ${calculatorPcWebMaxWidth};
  }
`;

const DetailTaxContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 24px 20px;
  background-color: white;
  border-radius: 12px;
  margin-top: 10px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 500;
    font-size: 1.4em;
    line-height: 1.32;
    letter-spacing: -0.05em;
    color: ${(props) => props.theme.color.sNavy};

    button {
      width: 24px;
      height: 24px;
      color: ${(props) => props.theme.color.nGray60};

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const SumInfoContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 12px 20px;
  margin-top: 10px;
  background-color: white;
  border-radius: 12px;

  .info {
    position: relative;
    padding: 14px 0;
    border-bottom: 1px solid ${(props) => props.theme.color.nGray80};

    &:last-child {
      border: none;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;

    .name {
      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.sNavy};
    }

    .value {
      .number {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 600;
        font-size: 1.6em;
        line-height: 1;
        letter-spacing: -0.03125em;
        color: ${(props) => props.theme.color.pGreenDark01};
      }

      .unit {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.2em;
        line-height: 1;
        letter-spacing: -0.058em;
        color: #727b8e;
        margin-left: 2px;
      }
    }
  }
`;

const TaxInfoContainer = styled.div`
  width: 100%;
  height: auto;
  margin-top: 20px;

  .taxWrap {
    padding: 8px 0;
    border-bottom: 1px solid ${(props) => props.theme.color.nGray80};

    .tax {
      position: relative;

      display: flex;
      justify-content: space-between;
      align-items: center;

      background-color: ${(props) => props.theme.color.pGreenLight04};

      &.closed {
        background-color: white;
      }

      .name {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.4em;
        line-height: 1.32;
        letter-spacing: -0.05em;
        color: ${(props) => props.theme.color.sNavy};
      }

      .value {
        flex-basis: 60%;

        display: flex;
        justify-content: space-between;

        .rate {
          font-family: "Spoqa Han Sans Neo";
          font-weight: 600;
          font-size: 1.6em;
          line-height: 1;
          letter-spacing: -0.03125em;
          color: ${(props) => props.theme.color.sNavyDark01};
        }

        .number {
          font-family: "Spoqa Han Sans Neo";
          font-weight: 600;
          font-size: 1.6em;
          line-height: 1;
          letter-spacing: -0.03125em;
          color: ${(props) => props.theme.color.pGreenDark01};

          margin-left: 15px;
        }

        .unit {
          font-family: "Spoqa Han Sans Neo";
          font-weight: 400;
          font-size: 1.2em;
          line-height: 1;
          letter-spacing: -0.058em;
          color: #727b8e;
          margin-left: 2px;
        }
      }
    }
  }

  .detailWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px 0 10px 5px;

    .name {
      display: flex;
      align-items: center;

      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.sNavy};

      .icon {
        width: 16px;
        height: 16px;
        margin-right: 2px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .value {
      .number {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 600;
        font-size: 1.6em;
        line-height: 1;
        letter-spacing: -0.03125em;
        color: ${(props) => props.theme.color.sNavy};
      }

      .unit {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.2em;
        line-height: 1;
        letter-spacing: -0.058em;
        color: #727b8e;
        margin-left: 2px;
      }
    }
  }

  .detailTaxWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 8px 0;
    border-bottom: 1px solid ${(props) => props.theme.color.nGray80};

    .name {
      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.nGray50};
    }

    .value {
      .number {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 600;
        font-size: 1.6em;
        line-height: 1;
        letter-spacing: -0.03125em;
        color: ${(props) => props.theme.color.nGray30};
      }

      .unit {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.2em;
        line-height: 1;
        letter-spacing: -0.058em;
        color: #727b8e;
        margin-left: 2px;
      }
    }
  }
`;

const TotalTaxContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  width: 100%;
  height: auto;
  padding: 38px 20px;
  background-color: white;
  border-radius: 12px;

  .title {
    position: relative;
    display: inline-block;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.4em;
    line-height: 1.32;
    letter-spacing: -0.05em;
    color: ${(props) => props.theme.color.sNavy};
    z-index: 2;

    .highlight {
      position: absolute;
      top: 3px;
      left: 6px;
      z-index: -1;

      width: 100%;
      height: 100%;
      background-color: ${(props) => props.theme.color.pGreenLight04};
      border-radius: 2px;
    }
  }
  .tax {
    .number {
      font-family: "Spoqa Han Sans Neo";
      font-weight: 500;
      font-size: 2.4em;
      line-height: 1;
      letter-spacing: -0.02em;
    }

    .unit {
      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;

      margin-left: 3px;
    }
  }

  .icon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;

    width: 112.71px;
    height: 112.71px;

    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const Body = styled.div`
  font-size: ${({ theme }) => theme.fontSize}; /* 1rem = 10px */
  position: relative;
  width: 100dvw;
  height: 100dvh;

  /*display: grid;
    grid-template-rows: 50px 1fr;*/

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${(props) => props.theme.color.pGreenLight04};

  &::-webkit-scrollbar {
    display: none;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  p {
    margin: 0;
  }

  li {
    list-style: none;
  }
`;

const MainContainer = styled.main`
  position: relative;
  width: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  flex: 1 1 0;

  @media (min-width: 1201px) {
    max-width: ${calculatorPcWebMaxWidth};
  }

  background-color: ${(props) => props.theme.color.bg};
  z-index: 10;
`;

export default AcqsTaxCalcResult;
