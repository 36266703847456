import { atom, RecoilState } from 'recoil';
import { AcqsTaxParamProps, AquiredAssetInfo, AddrSearchResult, DirectAddrInfo } from './types/types';

export const introVsb = atom<boolean>({ key: 'introVsb', default: true });
export const hshldSn = atom<number | null>({
    key: 'hshldSn',
    default: localStorage.getItem('hshldSn') === undefined ? null : Number(localStorage.getItem('hshldSn')),
});
export const hshldNm = atom<string | null>({ key: 'hshldNm', default: localStorage.getItem('hshldNm') });
export const redirectUrl = atom<string>({ key: 'redirectUrl', default: '' });
export const hshldSelModalOpen = atom<boolean>({ key: 'hshldSelModalOpen', default: false });
export const isAdmin = atom<boolean>({ key: 'isAdmin', default: false });
export const isSysAdmin = atom<boolean>({ key: 'isSysAdmin', default: false });

interface userProps {
    userSn: number;
    userId: string;
    userNm: string;
    emailAdres: string;
    mbtlnum: string;
    mainUserSn: number;
    isLock: boolean;
    badPwdCnt: number;
    isAdmin: boolean;
    isSysAdmin: boolean;
}
export const userInfo = atom<userProps>({ key: 'userInfo', default: {} as userProps });
export const currFormMode = atom<string | null>({ key: 'currFormMode', default: '' });
export const hshldCnt = atom<number>({ key: 'hshldCnt', default: 0 });

/* 모바일 취득세 계산기를 위한 Atom  */
export const acqsInputVisibleState: RecoilState<any> = atom({
    key: 'acqsInputVisibleState',
    default: {
        visible01: true, // 01. 부동산의 종류(상세구분)
        visible02: false, // 02. 주택구분
        visible03: false, // 03. 취득구분 1(아파트/연립)
        visible04: false, // 04. 취득구분 2(오피스텔)
        visible05: false, // 05. 멸실여부
        visible06: false, // 06. 토지종류
        visible07: false, // 07. 자경기간
        visible08: false, // 08. 취득원인 1(기존 아파트)
        visible09: false, // 09. 취득원인 2(분양권/오피스텔)
        visible10: false, // 10. 취득원인 3(조합원입주권)
        visible11: false, // 11. 취득원인 4(다세대/다가구/다중/단독주택)
        visible12: false, // 12. 조정대상지역
        visible13: false, // 13. 주택수 1(조정대상지역)
        visible14: false, // 14. 주택수 2(비조정대상지역)
        visible15: false, // 15. 상속인의 주택유무
        visible16: false, // 16. 시가표준액 기준
        visible17: false, // 17. 증여자
        visible18: false, // 18. 증여자의 주택수
        visible19: false, // 19. 과세표준
        visible20: false, // 20. 지분율
        visible21: false, // 21. 면적
        visible22: false, // 22. 취득할 자산정보
        visible23: false, // 23. 추가 버튼
        visible24: false, // 24. 자산정보 Table
    },
});

export const acqsInputDisabledState: RecoilState<any> = atom({
    key: 'acqsInputDisabledState',
    default: {
        disabled01: false, // 01. 부동산의 종류(상세구분)
        disabled02: false, // 02. 주택구분
        disabled03: false, // 03. 취득구분 1(아파트/연립)
        disabled04: false, // 04. 취득구분 2(오피스텔)
        disabled05: false, // 05. 멸실여부
        disabled06: false, // 06. 토지종류
        disabled07: false, // 07. 자경기간
        disabled08: false, // 08. 취득원인 1(기존 아파트)
        disabled09: false, // 09. 취득원인 2(분양권/오피스텔)
        disabled10: false, // 10. 취득원인 3(조합원입주권)
        disabled11: false, // 11. 취득원인 4(다세대/다가구/다중/단독주택)
        disabled12: false, // 12. 조정대상지역
        disabled16: false, // 16. 시가표준액
        disabled20: false, // 20. 지분율
        disabled21: false, // 21. 면적
    },
});

export const acqsInputValueState: RecoilState<AcqsTaxParamProps> = atom({
    key: 'acqsInputValueState',
    default: {
        taxFlag: '',
        estateSe: '',
        estateFom: '',
        acqsSrc1: '',
        acqsSrc2: '',
        loseYn: '',
        landType: '',
        farmPeriod: '',
        acqsCause1: '',
        acqsCause2: '',
        acqsCause3: '',
        acqsCause4: '',
        mdatTrgetAre: '',
        hseNumAt1: '',
        hseNumAt2: '',
        inheirHseNo: '',
        stdMkPrice: '',
        donor: '',
        donorHseNo: '',
        taxBase: '',
        shareRatio: '',
        prvuseAr: '',
    },
});

export const acqsInputHelpState: RecoilState<any> = atom({
    key: 'acqsInputHelpState',
    default: {
        taxBaseHelp: '',
        houseNumHelp: '',
    },
});

export const addrSearchResultState: RecoilState<AddrSearchResult> = atom<AddrSearchResult>({
    key: 'addrSearchResultState',
    default: {
        addr: null,
        dong: '',
        ho: '',
        bdNm: '',
        error: '',
        bdType: '',
    },
});

export const acquiredAssetInfoState: RecoilState<AquiredAssetInfo> = atom({
    key: 'acquiredAssetInfoState',
    default: {
        spfcKnd: '', // 용도지역
        mdatTrgetAreaAt: '', // 조장지역여부
        pblntfPc: '', // 공동주택가격
        housePc: '', // 개별주택갸격
        shareRatio: '', // 지분율
        apprDateNewHse: '', // 사용승인일
        prvuseAr: '', // 공동주택 전용면적
        houseTotar: '', // 단독주택 전용면적
    },
});

export const taxFlagState: RecoilState<string> = atom({
    key: 'taxFlagState',
    default: 'A',
});

export const directAddrInfoState: RecoilState<DirectAddrInfo> = atom<DirectAddrInfo>({
    key: 'directAddrInfoState',
    default: {
        bdKnd: '1',
        sidoCode: '', // 시도정보
        sggCode: '', // 시군구정보
        sggCodeElVsb: true, // 시군구정보 표시여부
        emdCode: '', // 읍면동 정보
        sidoName: '', // 시도
        sggName: '', //시군구
        emdName: '', //읍면동
        sggCodeInfoList: [],
        emdCodeInfoList: [],
        rdnmAdresDetail: '', // 상세주소
        spfcKnd: '', // 용도지역
        mdatTrgetAreaAt: '', // 조장지역여부
        pblntfPc: '', // 공동주택가격
        shareRatio: '', // 지분율
        prvuseAr: '', // 공동주택 전용면적
    },
});
