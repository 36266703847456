/*******************************************************************************
*** giftTaxStatesReducer ** 모바일 증여세 상태, Event
*******************************************************************************/
import { produce } from "immer";
import { GiftTaxParamProps, GiftTaxParamVisible, GiftTaxParamDisabled } from "js/common/types/types";

export interface GiftTaxStates {
    inputValue   : GiftTaxParamProps;
    inputVisible : GiftTaxParamVisible;
    inputDisabled: GiftTaxParamDisabled;
}

export type GiftTaxAction = { type: GiftTaxActionType; payload: any };

// action type
export type GiftTaxActionType =
    | "UPDATED_DONOR"           // 01. 증여자
    | "UPDATED_GIFTPROP"        // 02. 증여 재산가액 
    | "UPDATED_DEBTASSUM"       // 03. 채무인수 여부 
    | "UPDATED_DEBTAMT"         // 04. 채무액 
    | "UPDATED_PREGIFT"         // 05. 증여자 사전증여 유무 
    | "UPDATED_PREGIFTPROP"     // 06. 사전증여 재산가액 
    | "UPDATED_PREGIFTTAX"      // 07. 사전증여 세액 
    | "UPDATED_DONEEMINOR"      // 08. 수증자 미성년자 여부 
    | "UPDATED_BASICDEDU"       // 09. 기본공제 
    | "UPDATED_MARRIAGEDEDU"    // 10. 혼인공제 
    | "UPDATED_CHILDBIRTHDEDU"  // 11. 출산공제  
    | "INIT_BASIC_INFO"         // 기초입력정보 초기화
    | "INIT_DEDUCTION_INFO";    // 공제입력정보 초기화

// 초기 상태
export const initialState: GiftTaxStates = {
    inputValue: {
          taxFlag	    : "" // 00.세금구분 Flag
        , donor		    : "" // 01. 증여자
        , giftProp	    : "" // 02. 증여 재산가액 
        , debtAssum	    : "" // 03. 채무인수 여부 
        , debtAmt	    : "" // 04. 채무액 
        , preGift	    : "" // 05. 증여자 사전증여 유무 
        , preGiftProp   : "" // 06. 사전증여 재산가액 
        , preGiftTax    : "" // 07. 사전증여 세액 
        , doneeMinor    : "" // 08. 수증자 미성년자 여부 
        , basicDedu     : "" // 09. 기본공제 
        , marriageDedu  : "" // 10. 혼인공제 
        , childbirthDedu: "" // 11. 출산공제         
    },
    inputVisible: {
          visible01: true   // 01. 증여자
        , visible02: false  // 02. 증여 재산가액 
        , visible03: false  // 03. 채무인수 여부 
        , visible04: false  // 04. 채무액 
        , visible05: false  // 05. 증여자 사전증여 유무 
        , visible06: false  // 06. 사전증여 재산가액 
        , visible07: false  // 07. 사전증여 세액 
        , visible08: false  // 08. 수증자 미성년자 여부 
        , visible09: false  // 09. 기본공제 
        , visible10: false  // 10. 혼인공제 
        , visible11: false  // 11. 출산공제 
    },
    inputDisabled: {
        disabled01: false   // 01. 증여자
    }        
};
          
// 증여세 입력항목 이벤트 처리
export const giftTaxStatesReducer = produce(
    (draft: GiftTaxStates, action: GiftTaxAction) => {
        const inputValue    = draft.inputValue;
        const inputVisible  = draft.inputVisible;
        const inputDisabled = draft.inputDisabled;

        switch (action.type) {
            // 01. 증여자
            case "UPDATED_DONOR": {
                inputValue.donor = action.payload;

                inputVisible.visible02 = true;  // 02. 증여 재산가액 
                inputVisible.visible03 = true;  // 03. 채무인수 여부 
                break;
            }
            
            // 02. 증여 재산가액 
            case "UPDATED_GIFTPROP": {
                inputValue.giftProp = action.payload;
                break;
            }        

            // 03. 채무인수 여부
            case "UPDATED_DEBTASSUM": {
                inputValue.debtAssum = action.payload;

                switch (action.payload) {
                    case "Y": { // 인수 ▶ 04.채무액, 05.증여자 사전증여 유무
                        inputVisible.visible04 = true;  // 04. 채무액
                        inputVisible.visible05 = true;  // 05. 증여자 사전증여 유무
                        break;
                    }
                    case "N": { // 미인수 ▶ 05.증여자 사전증여 유무
                        inputVisible.visible04 = false;  // 04. 채무액 미표시
                        inputValue.debtAmt     = ""   ;  // 04. 채무액 초기화
                        inputVisible.visible05 = true ;  // 05. 증여자 사전증여 유무
                        break;
                    }
                }
                break;
            }
            
            // 04. 채무액 
            case "UPDATED_DEBTAMT": {
                inputValue.debtAmt = action.payload;
                break;
            }

            // 05. 증여자 사전증여 유무 
            case "UPDATED_PREGIFT": {
                inputValue.preGift = action.payload;
                inputDisabled.disabled01 = true;    // 01. 증여자 Fixed

                // 증여자 사전증여 여부
                if (action.payload === "Y") {           // 증여자 사전증여 유 ▶ 06.사전증여 재산가액, 07.사전증여 세액 
                    inputVisible.visible06 = true;
                    inputVisible.visible07 = true; 
                } else if (action.payload === "N") {    // 증여자 사전증여 무
                    inputVisible.visible06 = false; // 06.사전증여 재산가액 미표시
                    inputValue.preGiftProp = ""   ; // 06.사전증여 재산가액 초기화
                    inputVisible.visible07 = false; // 07.사전증여 세액 미표시
                    inputValue.preGiftTax  = ""   ; // 07.사전증여 세액 초기화
                } else {}

                // 수증자 확인
                if (inputValue.donor === "202") {  // 직계존속 ▶ 08.수증자 미성년자 여부, 09.기본공제
                    inputVisible.visible08 = true;
                    inputVisible.visible09 = true;
                    inputValue.basicDedu  = "Y"  ;
                } else if (inputValue.donor === "201" || inputValue.donor === "203" || inputValue.donor === "204") { // 배우자, 직계비속, 기타친족 ▶ 09.기본공제
                    inputVisible.visible09 = true;
                    inputValue.basicDedu   = "Y" ;
                    inputVisible.visible08 =false; // 08.수증자 미성년자 여부 미표시
                    inputValue.doneeMinor = ""   ; // 08.수증자 미성년자 여부 초기화
                } else if (inputValue.donor === "205") {   // 기타 ▶ 09.기본공제
                    inputVisible.visible09 = true ;
                    inputValue.basicDedu   = "N"  ;
                    inputVisible.visible08 = false; // 08.수증자 미성년자 여부 미표시
                    inputValue.doneeMinor  = ""   ; // 08.수증자 미성년자 여부 초기화
                } else {}

                break;
            }

            // 06. 사전증여 재산가액
            case "UPDATED_PREGIFTPROP": {
                inputValue.preGiftProp = action.payload;
                break;
            }    

            // 07. 사전증여 세액
            case "UPDATED_PREGIFTTAX": {
                inputValue.preGiftTax = action.payload;
                break;
            }    

            // 08. 수증자 미성년자 여부
            case "UPDATED_DONEEMINOR": {
                inputValue.doneeMinor = action.payload;

                if (action.payload === "Y") {             // 미성년자 ▶ 계산하기
                    inputVisible.visible10 = false; // 10.혼인공제 미표시
                    inputValue.marriageDedu = ""  ; // 10.혼인공제 초기화
                    inputVisible.visible11 = false; // 11.출산공제 미표시
                    inputValue.childbirthDedu = ""; // 11.출산공제 초기화
                } else if (action.payload === "N") {      // 성인 ▶ 10.혼인공제, 11.출산공제
                    inputVisible.visible10 = true;
                    inputVisible.visible11 = true;
                } else {}

                break;
            }    
            
            // 09. 기본공제
            case "UPDATED_BASICDEDU": {
                inputValue.basicDedu = action.payload;
                break;
            }    

            // 10. 혼인공제
            case "UPDATED_MARRIAGEDEDU": {
                inputValue.marriageDedu = action.payload;
                break;
            }    

            // 11. 출산공제
            case "UPDATED_CHILDBIRTHDEDU": {
                inputValue.childbirthDedu = action.payload;
                break;
            }

            // 기초정보입력 초기화
            case "INIT_BASIC_INFO": {
                return initialState;
            }
            
            // 공제입력정보 초기화
            case "INIT_DEDUCTION_INFO": {
                inputValue.doneeMinor     = ""; // 08. 수증자 미성년자 여부 
                // inputValue.basicDedu      = ""; // 09. 기본공제 
                inputValue.marriageDedu   = ""; // 10. 혼인공제 
                inputValue.childbirthDedu = ""; // 11. 출산공제 
                inputVisible.visible10    = false;
                inputVisible.visible11    = false;

                break;
            }            
        }
    }
);