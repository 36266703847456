import { useState, useEffect } from "react";
import styled from "styled-components";

import { Housing, PropTaxCalcRsltParam, RtnProp } from "js/common/types/types";
import Util from "js/common/Util";
import { spfcKndOptions } from "js/common/const";

type Props = {
  calcResult: PropTaxCalcRsltParam | null;
  housings: Housing[] | null;
};

function SaveCalcResult({ calcResult, housings }: Props) {
  const [calcPropInfo, setCalcPropInfo] = useState<RtnProp[] | null>(); // 재산세 계산결과
  const [calcCompInfo, setCalcCompInfo] = useState<any | undefined>(); // 종합소득세 계산결과

  const [sumStdPrice, setSumStdPrice] = useState<string>();
  const [sumTaxBase, setSumTaxBase] = useState<string>();
  const [sumPropertyAmt, setSumPropertyAmt] = useState<string>();
  const [sumUrbanAmt, setSumUrbanAmt] = useState<string>();
  const [sumLocalEduAmt, setSumLocalEduAmt] = useState<string>();
  const [sumLocalResAmt, setSumLocalResAmt] = useState<string>();
  const [sumTotalAmt, setSumTotalAmt] = useState<string>();

  useEffect(() => {
    // 재산세/종합소득세 계산 결과
    setCalcPropInfo(calcResult?.rtn_prop_list); // 재산세 계산 결과 List
    setCalcCompInfo(calcResult?.rtn_comp_tax[0]); // 종합부동산세 결과
  }, [calcResult]);

  // 세금 합계 계산
  useEffect(() => {
    let sumStdPrice = 0; // 공시가격
    let sumTaxBase = 0; // 과세표준
    let sumPropertyAmt = 0; // 재산세
    let sumUrbanAmt = 0; // 도시지역분
    let sumLocalEduAmt = 0; // 지방교육세
    let sumLocalResAmt = 0; // 지역자원시설세
    let sumTotalAmt = 0; // 합계

    if (calcPropInfo) {
      for (const proptaxList of calcPropInfo) {
        sumStdPrice = sumStdPrice + Number(proptaxList.std_price);
        sumTaxBase = sumTaxBase + Number(proptaxList.tax_base);
        sumPropertyAmt = sumPropertyAmt + Number(proptaxList.property_amt);
        sumUrbanAmt = sumUrbanAmt + Number(proptaxList.urban_amt);
        sumLocalEduAmt = sumLocalEduAmt + Number(proptaxList.local_edu_amt);
        sumLocalResAmt = sumLocalResAmt + Number(proptaxList.local_res_amt);
        sumTotalAmt =
          sumTotalAmt +
          Number(proptaxList.property_amt) +
          Number(proptaxList.urban_amt) +
          Number(proptaxList.local_edu_amt) +
          Number(proptaxList.local_res_amt);
      }
    }
    setSumStdPrice(Util.comma(sumStdPrice.toString()));
    setSumTaxBase(Util.comma(sumTaxBase.toString()));
    setSumPropertyAmt(Util.comma(sumPropertyAmt.toString()));
    setSumUrbanAmt(Util.comma(sumUrbanAmt.toString()));
    setSumLocalEduAmt(Util.comma(sumLocalEduAmt.toString()));
    setSumLocalResAmt(Util.comma(sumLocalResAmt.toString()));
    setSumTotalAmt(Util.comma(sumTotalAmt.toString()));
  }, [calcPropInfo]);

  const getAddress = (pnu: string | null) => {
    let address = "";
    housings?.forEach((housingInfo: any) => {
      if (housingInfo.pnu === pnu) {
        address = housingInfo.rdnmAdres;
        return;
      }
    });
    return address;
  };

  const getSpfcKndValue = (pnu: string | null) => {
    let address = "";
    housings?.forEach((housingInfo: any) => {
      if (housingInfo.pnu === pnu) {
        address = housingInfo.spfcKnd;
        return;
      }
    });
    return address;
  };

  return (
    <TaxContainer>
      <DetailTaxContainer>
        <div className="title">
          <p>재산세 정보</p>
          <button className="open">
            <img
              src={require("img/common/icon_arrow_up_gray.png")}
              alt="logo"
            />
          </button>
        </div>
      </DetailTaxContainer>
      <TaxInfoContainer>
        <OpenAndCloseContainer>
          <div className="detail-container open">
            <div className="detailTaxWrap">
              <p className="name">공시가격</p>
              <div className="value">
                <div>
                  <span className="number">
                    {calcResult ? Util.comma(sumStdPrice ?? "") : ""}
                  </span>
                  <span className="unit">원</span>
                </div>
              </div>
            </div>
            <div className="detailTaxWrap">
              <p className="name">과세표준</p>
              <div className="value">
                <div>
                  <span className="number">
                    {calcResult ? Util.comma(sumTaxBase ?? "") : ""}
                  </span>
                  <span className="unit">원</span>
                </div>
              </div>
            </div>
            <div className="detailTaxWrap">
              <p className="name">재산세</p>
              <div className="value">
                <div>
                  <span className="number">
                    {calcResult ? Util.comma(sumPropertyAmt ?? "") : ""}
                  </span>
                  <span className="unit">원</span>
                </div>
              </div>
            </div>
            <div className="detailTaxWrap">
              <p className="name">도시지역분</p>
              <div className="value">
                <div>
                  <span className="number">
                    {calcResult ? Util.comma(sumUrbanAmt ?? "") : ""}
                  </span>
                  <span className="unit">원</span>
                </div>
              </div>
            </div>
            <div className="detailTaxWrap">
              <p className="name">지방교육세</p>
              <div className="value">
                <div>
                  <span className="number">
                    {calcResult ? Util.comma(sumLocalEduAmt ?? "") : ""}
                  </span>
                  <span className="unit">원</span>
                </div>
              </div>
            </div>
            <div className="detailTaxWrap">
              <p className="name">지역자원시설세</p>
              <div className="value">
                <div>
                  <span className="number">
                    {calcResult ? Util.comma(sumLocalResAmt ?? "") : ""}
                  </span>
                  <span className="unit">원</span>
                </div>
              </div>
            </div>
            <div className="taxWrap" style={{ borderBottomColor: "#fff" }}>
              <div className="tax">
                <p className="name">합계</p>
                <div className="value">
                  <div>
                    <span className="number">
                      {calcResult ? Util.comma(sumTotalAmt ?? "") : ""}
                    </span>
                    <span className="unit">원</span>
                  </div>
                </div>
              </div>
            </div>
            {calcPropInfo?.map((rtnProp, index) => {
              const tmpAddrSplit = getAddress(rtnProp.pnu).split(" ");
              const title = tmpAddrSplit[0] + " " + tmpAddrSplit[1];

              const spfcKndValue = spfcKndOptions.find(
                (option) => option.value === getSpfcKndValue(rtnProp.pnu)
              );

              const address = getAddress(rtnProp.pnu);

              return (
                <PropertySlide key={rtnProp.pnu}>
                  <div className="slideHeader">
                    <div className="titleWrap">
                      <div className="propDesc">
                        <img
                          src={require("img/common/prop_slide_icon.png")}
                          alt="prop icon"
                        />
                        <span>재산세 · 종합부동산세</span>
                      </div>
                      <p className="propTitle">{`주택정보${
                        index + 1
                      } - ${title}`}</p>
                    </div>
                  </div>
                  <div className="slideContent">
                    <div className="propContent">
                      <span className="contentLabel">주소</span>
                      <span className="contentText">
                        {address.split("").slice(0, 14).join("")}...
                      </span>
                    </div>
                    <div className="propContent">
                      <span className="contentLabel">용도지역</span>
                      <span className="contentText">
                        {spfcKndValue ? spfcKndValue.label : ""}
                      </span>
                    </div>
                    <div className="propContent">
                      <span className="contentLabel">공시가격</span>
                      <span className="contentText">
                        {rtnProp.std_price ? Util.comma(rtnProp.std_price) : ""}
                        원
                      </span>
                    </div>
                    <div className="propContent">
                      <span className="contentLabel">지분율</span>
                      <span className="contentText">
                        {rtnProp.share_ratio ? rtnProp.share_ratio : ""}%
                      </span>
                    </div>
                    <div className="propContent">
                      <span className="contentLabel">과세표준</span>
                      <span className="contentText">
                        {rtnProp.tax_base ? Util.comma(rtnProp.tax_base) : ""}원
                      </span>
                    </div>
                    <div className="propContent">
                      <span className="contentLabel">재산세</span>
                      <span className="contentText">
                        {rtnProp.property_amt
                          ? Util.comma(rtnProp.property_amt)
                          : ""}
                        원
                      </span>
                    </div>
                    <div className="propContent">
                      <span className="contentLabel">도시지역분</span>
                      <span className="contentText">
                        {rtnProp.urban_amt ? Util.comma(rtnProp.urban_amt) : ""}
                        원
                      </span>
                    </div>
                    <div className="propContent">
                      <span className="contentLabel">지방교육세</span>
                      <span className="contentText">
                        {rtnProp.local_edu_amt
                          ? Util.comma(rtnProp.local_edu_amt)
                          : ""}
                        원
                      </span>
                    </div>
                    <div className="propContent">
                      <span className="contentLabel">지역자원시설세</span>
                      <span className="contentText">
                        {rtnProp.local_res_amt
                          ? Util.comma(rtnProp.local_res_amt)
                          : ""}
                        원
                      </span>
                    </div>
                    <div className="propContent">
                      <span className="contentLabel">합계</span>
                      <span className="contentText">
                        {rtnProp.total_amt ? Util.comma(rtnProp.total_amt) : ""}
                        원
                      </span>
                    </div>
                  </div>
                </PropertySlide>
              );
            })}
          </div>
        </OpenAndCloseContainer>
      </TaxInfoContainer>
      <DetailTaxContainer>
        <div className="title">
          <p>종합부동산세 정보</p>
          <button className="open">
            <img
              src={require("img/common/icon_arrow_up_gray.png")}
              alt="logo"
            />
          </button>
        </div>
      </DetailTaxContainer>
      <TaxInfoContainer>
        <OpenAndCloseContainer>
          {calcCompInfo && Util.isNull(calcCompInfo.house_info) ? (
            <EmptyContainer>
              <div className="text">종합부동산세 과세 대상이 아닙니다.</div>
            </EmptyContainer>
          ) : (
            <>
              <div className="detail-container open">
                <div className="detailTaxWrap">
                  <p className="name">주택정보</p>
                  <div className="value">
                    <div>
                      <span className="number">
                        {calcCompInfo ? calcCompInfo.house_info : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="detailTaxWrap">
                  <p className="name">과세표준</p>
                  <div className="value">
                    <div>
                      <span className="number">
                        {calcCompInfo
                          ? Util.comma(calcCompInfo.comp_tax_base)
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
                <div className="moreDetailTaxWrap">
                  <div className="title">
                    <img src={require("img/common/icon_more.png")} alt="logo" />
                    <p className="name">주택공시사격 합계</p>
                  </div>
                  <div className="value">
                    <div>
                      <span className="number">
                        {calcCompInfo
                          ? Util.comma(calcCompInfo.comp_sum_std_price)
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
                <div className="moreDetailTaxWrap">
                  <div className="title">
                    <img src={require("img/common/icon_more.png")} alt="logo" />
                    <p className="name">공제금액</p>
                  </div>
                  <div className="value">
                    <div>
                      <span className="number">
                        {calcCompInfo
                          ? Util.comma(calcCompInfo.deduct_amt)
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
                <div className="moreDetailTaxWrap">
                  <div className="title">
                    <img src={require("img/common/icon_more.png")} alt="logo" />
                    <p className="name">공제금액 차감 후 금액</p>
                  </div>
                  <div className="value">
                    <div>
                      <span className="number">
                        {calcCompInfo
                          ? Util.comma(calcCompInfo.comp_tax_base_pre)
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
                <div className="moreDetailTaxWrap">
                  <div className="title">
                    <img src={require("img/common/icon_more.png")} alt="logo" />
                    <p className="name">(*)공정시장가액 비율</p>
                  </div>
                  <div className="value">
                    <div>
                      <span className="number">
                        {calcCompInfo
                          ? Number(calcCompInfo.fair_market_ratio)
                          : ""}
                      </span>
                      <span className="unit">%</span>
                    </div>
                  </div>
                </div>
                <div className="detailTaxWrap">
                  <p className="name">(*)세율</p>
                  <div className="value">
                    <div>
                      <span className="number">
                        {calcCompInfo
                          ? Number(calcCompInfo.comp_basic_tax_rate)
                          : ""}
                      </span>
                      <span className="unit">%</span>
                    </div>
                  </div>
                </div>
                <div className="detailTaxWrap">
                  <p className="name">(-)누진공제액</p>
                  <div className="value">
                    <div>
                      <span className="number">
                        {calcCompInfo
                          ? Util.comma(calcCompInfo.comp_progre_deduct_amt)
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
                <div className="detailTaxWrap">
                  <p className="name">종합부동산세 A</p>
                  <div className="value">
                    <div>
                      <span className="number">
                        {calcCompInfo
                          ? Util.comma(calcCompInfo.comp_tax_A)
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
                <div className="moreDetailTaxWrap">
                  <div className="title">
                    <img src={require("img/common/icon_more.png")} alt="logo" />
                    <p className="name">(-)재산세 중복분</p>
                  </div>
                  <div className="value">
                    <div>
                      <span className="number">
                        {calcCompInfo
                          ? Util.comma(calcCompInfo.comp_dupl_prop_tax)
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
                <div className="detailTaxWrap">
                  <p className="name">종합부동산세 B</p>
                  <div className="value">
                    <div>
                      <span className="number">
                        {calcCompInfo
                          ? Util.comma(calcCompInfo.comp_tax_B)
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
                <div className="moreDetailTaxWrap">
                  <div className="title">
                    <img src={require("img/common/icon_more.png")} alt="logo" />
                    <p className="name">(-)세액 공제(80%한도)</p>
                  </div>
                  <div className="value">
                    <div>
                      <span className="number">
                        {calcCompInfo
                          ? Util.comma(calcCompInfo.tax_credit_amt)
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
                <div className="detailTaxWrap">
                  <p className="name">종합부동산세 C</p>
                  <div className="value">
                    <div>
                      <span className="number">
                        {calcCompInfo
                          ? Util.comma(calcCompInfo.comp_tax_C)
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
                <div className="moreDetailTaxWrap">
                  <div className="title">
                    <img src={require("img/common/icon_more.png")} alt="logo" />
                    <p className="name">(+)농어촌 특별세</p>
                  </div>
                  <div className="value">
                    <div>
                      <span className="number">
                        {calcCompInfo
                          ? Util.comma(calcCompInfo.farm_fish_tax)
                          : ""}
                      </span>
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
                <div className="taxWrap" style={{ borderBottomColor: "#fff" }}>
                  <div className="tax">
                    <p className="name">납부세액</p>
                    <div className="value">
                      <div>
                        <span className="number">
                          {calcCompInfo
                            ? Util.comma(calcCompInfo.comp_total_tax)
                            : ""}
                        </span>
                        <span className="unit">원</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </OpenAndCloseContainer>
      </TaxInfoContainer>
    </TaxContainer>
  );
}

const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  justify-content: center;

  width: 100%;
  height: 73px;

  background: ${(props) => props.theme.color.bg};
  border-radius: 8px;

  .text {
    text-align: center;
    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-size: 1.4em;
    line-height: 1.32;
    letter-spacing: -0.05em;
    color: ${(props) => props.theme.color.nGray20};
  }
`;

const OpenAndCloseContainer = styled.div`
  width: 100%;
  height: auto;
`;

const TaxInfoContainer = styled.div`
  width: 100%;
  height: auto;
  margin-top: 20px;

  .taxWrap {
    padding: 8px 0;
    border-bottom: 1px solid ${(props) => props.theme.color.nGray80};

    .tax {
      position: relative;

      display: flex;
      justify-content: space-between;
      align-items: center;

      background-color: ${(props) => props.theme.color.pGreenLight04};

      &.closed {
        background-color: white;
      }

      .name {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 500;
        font-size: 1.4em;
        line-height: 1.32;
        letter-spacing: -0.05em;
        color: ${(props) => props.theme.color.sNavy};
      }

      .value {
        flex-basis: 60%;

        display: flex;
        justify-content: end;

        .rate {
          font-family: "Spoqa Han Sans Neo";
          font-weight: 600;
          font-size: 1.6em;
          line-height: 1;
          letter-spacing: -0.03125em;
          color: ${(props) => props.theme.color.sNavyDark01};
        }

        .number {
          font-family: "Spoqa Han Sans Neo";
          font-weight: 600;
          font-size: 1.6em;
          line-height: 1;
          letter-spacing: -0.03125em;
          color: ${(props) => props.theme.color.pGreenDark01};

          margin-left: 15px;
        }

        .unit {
          font-family: "Spoqa Han Sans Neo";
          font-weight: 400;
          font-size: 1.2em;
          line-height: 1;
          letter-spacing: -0.058em;
          color: #727b8e;
          margin-left: 2px;
        }
      }

      .highlight {
        position: absolute;
        top: 0px;
        left: -3px;
        z-index: 0;

        width: calc(100% + 6px);
        height: 100%;
        background-color: ${(props) => props.theme.color.pGreenLight04};
        border-radius: 2px;
      }
    }

    .red {
      color: ${(props) => props.theme.color.sysRed100} !important;
    }
  }

  .detailWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px 0 10px 5px;

    .name {
      display: flex;
      align-items: center;

      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.nGray50};

      .icon {
        width: 16px;
        height: 16px;
        margin-right: 2px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .value {
      .number {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 600;
        font-size: 1.6em;
        line-height: 1;
        letter-spacing: -0.03125em;
        color: ${(props) => props.theme.color.nGray30};
      }

      .unit {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.2em;
        line-height: 1;
        letter-spacing: -0.058em;
        color: #727b8e;
        margin-left: 2px;
      }
    }
  }

  .detailTaxWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 8px 0;
    border-bottom: 1px solid ${(props) => props.theme.color.nGray80};

    .name {
      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.sNavy};
    }

    .value {
      .number {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 600;
        font-size: 1.6em;
        line-height: 1;
        letter-spacing: -0.03125em;
        color: ${(props) => props.theme.color.nGray30};
      }

      .unit {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.2em;
        line-height: 1;
        letter-spacing: -0.058em;
        color: #727b8e;
        margin-left: 2px;
      }

      .blue {
        color: ${(props) => props.theme.color.sysBlue100} !important;
      }
    }
  }

  .moreDetailTaxWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 39px;

    padding: 8px 0;

    &.tableContainer {
      height: auto;
      padding: 0 0 8px 8px;
      border-bottom: 1px solid ${(props) => props.theme.color.nGray80};
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      font-family: "Spoqa Han Sans Neo";
      font-weight: 500;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.nGray50};

      img {
        width: 16px;
        height: auto;
      }
    }

    .value {
      .number {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 600;
        font-size: 1.6em;
        line-height: 1;
        letter-spacing: -0.03125em;
        color: ${(props) => props.theme.color.nGray30};
      }

      .unit {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.2em;
        line-height: 1;
        letter-spacing: -0.058em;
        color: #727b8e;
        margin-left: 2px;
      }

      .blue {
        color: ${(props) => props.theme.color.sysBlue100} !important;
      }
    }
  }
`;

const TaxContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: gap;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 0px 20px 24px 20px;
  background-color: white;
  border-radius: 12px;
  overflow-y: visible;
`;

const DetailTaxContainer = styled.div`
  width: 100%;
  height: auto;
  padding-top: 20px;
  background-color: white;
  border-radius: 12px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 500;
    font-size: 1.4em;
    line-height: 1.32;
    letter-spacing: -0.05em;
    color: ${(props) => props.theme.color.sNavy};

    button {
      width: 24px;
      height: 24px;
      color: ${(props) => props.theme.color.nGray60};

      display: flex;
      justify-content: center;
      align-items: center;

      transition: transform 0.3s ease;

      img {
        width: 13px;
        height: auto;
      }
    }

    button.closed {
      transform: rotate(180deg);
    }
  }
`;

const PropertySlide = styled.div`
  width: 100%;
  height: auto;
  margin-top: 12px;

  .slideHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 16px 24px 9px 24px;
    background-color: white;
    border-radius: 13px 13px 0 0;
    border-top: 1px solid ${(props) => props.theme.color.nGray70};
    border-left: 1px solid ${(props) => props.theme.color.nGray70};
    border-right: 1px solid ${(props) => props.theme.color.nGray70};

    .titleWrap {
      .propDesc {
        display: flex;
        align-items: center;
        margin-bottom: 2px;

        img {
          width: 16px;
          height: 16px;
        }

        span {
          margin-left: 6px;
          font-family: "Spoqa Han Sans Neo";
          font-weight: 400;
          font-size: 1.2em;
          line-height: 1;
          letter-spacing: -0.033em;
          vertical-align: middle;
          color: ${(props) => props.theme.color.blueGray};
        }
      }

      .propTitle {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 500;
        font-size: 1.4em;
        line-height: 1.5;
        letter-spacing: -0.028em;
        vertical-align: middle;
      }
    }

    .deleteButton {
      padding: 2px 9px;
      border: 1px solid ${({ theme }) => theme.color.nGray70};
      border-radius: 5px;

      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.2em;
      line-height: 1.4;
      letter-spacing: -0.028em;
      color: black;
    }
  }
  .slideContent {
    padding: 16px 24px 14px 24px;
    border-radius: 0 0 13px 13px;
    margin-bottom: 10px;
    background-color: ${(props) => props.theme.color.pGreenLight04};
    border-radius: 0 0 13px 13px;
    .propContent {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;
      margin-bottom: 6px;

      .contentLabel {
        flex: 0 0 90px;

        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.4em;
        line-height: 1.8;
        letter-spacing: -0.028em;
        color: #727b8e;
      }

      .contentText {
        flex-grow: 1;
        flex-shrink: 1;
        text-align: right;

        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.4em;
        line-height: 1.8;
        letter-spacing: -0.028em;
        color: #202020;
      }
    }
  }
`;

export default SaveCalcResult;
