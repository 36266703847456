import { useState } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

import { Housing, RtnProp } from "js/common/types/types";
import Util from "js/common/Util";
import { spfcKndOptions } from "js/common/const";
import ToolTip from "js/common/ui/ToolTip";

type Props = {
  rtnPropList: RtnProp[] | null;
  housings: Housing[] | null;
};

function PropInfoSwiper({ rtnPropList, housings }: Props) {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [swiperInstance, setSwiperInstance] = useState<SwiperType | null>(null);

  const onClickLabel = (index: number) => {
    if (swiperInstance) {
      swiperInstance.slideTo(index); // 해당 인덱스로 슬라이드 이동
    }
  };

  const getAddress = (pnu: string | null) => {
    let address = "";
    housings?.forEach((housingInfo: any) => {
      if (housingInfo.pnu === pnu) {
        address = housingInfo.rdnmAdres;
        return;
      }
    });
    return address;
  };

  const getSpfcKndValue = (pnu: string | null) => {
    let address = "";
    housings?.forEach((housingInfo: any) => {
      if (housingInfo.pnu === pnu) {
        address = housingInfo.spfcKnd;
        return;
      }
    });
    return address;
  };

  return (
    <SwiperWrap>
      <SlideLabelContainer>
        {rtnPropList?.map((rtnProp: RtnProp, index: number) => (
          <span
            className={`slideLabel ${index === activeIndex ? "active" : ""}`}
            onClick={() => onClickLabel(index)}
            key={rtnProp.pnu}
          >
            {index === activeIndex ? `주택정보 ${index + 1}` : index + 1}
          </span>
        ))}
      </SlideLabelContainer>
      {/* Swiper */}
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        modules={[Pagination]}
        pagination={{
          clickable: true,
          el: ".customPagination",
          bulletClass: "customBullet",
          bulletActiveClass: "customBulletActive",
        }}
        onSlideChange={
          (swiper: SwiperType) => setActiveIndex(swiper.activeIndex)
          //console.log(swiper.activeIndex)
        }
        onSwiper={setSwiperInstance} // Swiper 인스턴스를 저장
      >
        {rtnPropList?.map((rtnProp, index) => {
          const tmpAddrSplit = getAddress(rtnProp.pnu).split(" ");
          const title = tmpAddrSplit[0] + " " + tmpAddrSplit[1];

          const spfcKndValue = spfcKndOptions.find(
            (option) => option.value === getSpfcKndValue(rtnProp.pnu)
          );

          const address = getAddress(rtnProp.pnu);

          return (
            <SwiperSlide key={rtnProp.pnu}>
              <PropertySlide>
                <div className="slideHeader">
                  <div className="titleWrap">
                    <div className="propDesc">
                      <img
                        src={require("img/common/prop_slide_icon.png")}
                        alt="prop icon"
                      />
                      <span>재산세 · 종합부동산세</span>
                    </div>
                    <p className="propTitle">{`주택정보${
                      index + 1
                    } - ${title}`}</p>
                  </div>
                </div>
                <div className="slideContent">
                  <div className="propContent">
                    <span className="contentLabel">주소</span>
                    <span className="contentText">{address}</span>
                  </div>
                  <div className="propContent">
                    <span className="contentLabel">용도지역</span>
                    <span className="contentText">
                      {spfcKndValue ? spfcKndValue.label : ""}
                    </span>
                  </div>
                  <div className="propContent">
                    <span className="contentLabel">공시가격</span>
                    <span className="contentText">
                      {rtnProp.std_price ? Util.comma(rtnProp.std_price) : ""}원
                    </span>
                  </div>
                  <div className="propContent">
                    <span className="contentLabel">지분율</span>
                    <span className="contentText">
                      {rtnProp.share_ratio ? rtnProp.share_ratio : ""}%
                    </span>
                  </div>
                  <div className="propContent">
                    <span className="contentLabel">과세표준</span>
                    <span className="contentText">
                      {rtnProp.tax_base ? Util.comma(rtnProp.tax_base) : ""}원
                    </span>
                  </div>
                  <div className="propContent">
                    <span className="contentLabel">재산세</span>
                    <span className="contentText">
                      {rtnProp.property_amt
                        ? Util.comma(rtnProp.property_amt)
                        : ""}
                      원
                    </span>
                  </div>
                  <div className="propContent">
                    <span className="contentLabel">도시지역분</span>
                    <span className="contentText">
                      {rtnProp.urban_amt ? Util.comma(rtnProp.urban_amt) : ""}원
                    </span>
                  </div>
                  <div className="propContent">
                    <span className="contentLabel">지방교육세</span>
                    <span className="contentText">
                      {rtnProp.local_edu_amt
                        ? Util.comma(rtnProp.local_edu_amt)
                        : ""}
                      원
                    </span>
                  </div>
                  <div className="propContent">
                    <span className="contentLabel">지역자원시설세</span>
                    <div
                      className="tootipWrap"
                      data-tooltip-id="coupleSpecialToolTip"
                      data-tooltip-content={
                        "♠ 지역자원시설세\n\n" +
                        "주택분 재산세 납세의무자는 소방사무에 소요되는 제반비용을 충당하기 위하여 부과하는 소방분 지역자원시설세를 납부해야 한다. 주택의 건축물 부분에 한하여 재산세와 동일하게 시가표준액과 공정시장가액비율(60%)를 적용하여 과세표준을 구하고, 세율을 곱하여 세액을 산출합니다. 현재 주택 전체의 가액으로 계산하였으므로 실제 지역자원시설세와 차이가 발생합니다. 참고바랍니다."
                      }
                    >
                      <img
                        src={require("img/common/icon_search20_black.png")}
                        alt="icon_info_fill_20"
                        className="csP pl3"
                      />
                      <ToolTip
                        id="coupleSpecialToolTip"
                        place="top"
                        clickable={true}
                        className="mxw278 lh20 wsPw wwBw owBw"
                      />
                    </div>
                    <span className="contentText">
                      {rtnProp.local_res_amt
                        ? Util.comma(rtnProp.local_res_amt)
                        : ""}
                      원
                    </span>
                  </div>
                  <div className="propContent">
                    <span className="contentLabel">합계</span>
                    <span className="contentText">
                      {rtnProp.total_amt ? Util.comma(rtnProp.total_amt) : ""}원
                    </span>
                  </div>
                </div>
              </PropertySlide>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* Custom Pagination */}
      <div className="customPagination" />
    </SwiperWrap>
  );
}

const SwiperWrap = styled.section`
  width: 100%;
  height: auto;

  .customPagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;

    width: 100%;
    padding-top: 12px;
    padding-bottom: 16px;
    background-color: ${(props) => props.theme.color.pGreenLight04};
    border-radius: 0 0 13px 13px;
  }

  .customBullet {
    width: 5px;
    height: 5px;
    background-color: #d6d6d6;
    border-radius: 50%;
  }

  .customBulletActive {
    width: 16px;
    height: 5px;
    background-color: ${(props) => props.theme.color.pGreen};
    border-radius: 5px;
  }
`;

const SlideLabelContainer = styled.div`
  --container-height: 34px;

  width: 100%;
  height: var(--container-height);
  margin-top: 15px;

  .slideLabel {
    padding: 6px 11px;
    border-radius: 17px;
    border: 1px solid #eaeaea;
    margin-right: 8px;

    font-family: "Spoqa Han Sans Neo";
    font-weight: 500;
    line-height: var(--container-height);
    font-size: 1.4em;
    letter-spacing: -0.028em;
    vertical-align: middle;

    background-color: white;
    color: ${(props) => props.theme.color.pGreen};

    &.active {
      background-color: ${(props) => props.theme.color.pGreen};
      color: white;
      border: 1px solid ${(props) => props.theme.color.pGreen};
      padding: 4px 12px;
    }
  }
`;

const PropertySlide = styled.div`
  width: 100%;
  height: auto;
  margin-top: 5px;

  .slideHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 16px 24px 9px 24px;
    background-color: white;
    border-radius: 13px 13px 0 0;
    border-top: 1px solid ${(props) => props.theme.color.nGray70};
    border-left: 1px solid ${(props) => props.theme.color.nGray70};
    border-right: 1px solid ${(props) => props.theme.color.nGray70};

    .titleWrap {
      .propDesc {
        display: flex;
        align-items: center;
        margin-bottom: 2px;

        img {
          width: 16px;
          height: 16px;
        }

        span {
          margin-left: 6px;
          font-family: "Spoqa Han Sans Neo";
          font-weight: 400;
          font-size: 1.2em;
          line-height: 1;
          letter-spacing: -0.033em;
          vertical-align: middle;
          color: ${(props) => props.theme.color.blueGray};
        }
      }

      .propTitle {
        font-family: "Spoqa Han Sans Neo";
        font-weight: 500;
        font-size: 1.4em;
        line-height: 1.5;
        letter-spacing: -0.028em;
        vertical-align: middle;
      }
    }

    .deleteButton {
      padding: 2px 9px;
      border: 1px solid ${({ theme }) => theme.color.nGray70};
      border-radius: 5px;

      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.2em;
      line-height: 1.4;
      letter-spacing: -0.028em;
      color: black;
    }
  }
  .slideContent {
    padding: 16px 24px 14px 24px;
    background-color: ${(props) => props.theme.color.pGreenLight04};
    .propContent {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;
      margin-bottom: 6px;

      .contentLabel {
        flex: 0 0 90px;

        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.4em;
        line-height: 1.8;
        letter-spacing: -0.028em;
        color: #727b8e;
      }

      .contentText {
        flex-grow: 1;
        flex-shrink: 1;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: right;

        font-family: "Spoqa Han Sans Neo";
        font-weight: 400;
        font-size: 1.4em;
        line-height: 1.8;
        letter-spacing: -0.028em;
        color: #202020;
      }
    }
  }
`;

export default PropInfoSwiper;
