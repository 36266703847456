/*******************************************************************************
 *** PersonCountInfo ** 인적공제 인원 수 입력
 *******************************************************************************/
import { Dispatch, SetStateAction } from "react";
import { NumericFormat } from "react-number-format";

import { InputContainer, NumberInputContainer } from "js/mobile/common/layout";
import { PersonDeduInputValue } from "js/common/types/types";

type Props = {
  inputValue: PersonDeduInputValue;
  setInputValue: Dispatch<SetStateAction<PersonDeduInputValue>>;
  childNumber: string;
};

function PersonCountInfo({ inputValue, setInputValue, childNumber }: Props) {
  const handleChangeInput = (
    key: keyof PersonDeduInputValue,
    value: string
  ) => {
    setInputValue((prev: PersonDeduInputValue) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <>
      <InputContainer>
        <p className="label">자녀 수</p>
        <div className="input">
          <NumberInputContainer $filled={childNumber === "" ? false : true}>
            <div className="inputWrap">
              <NumericFormat
                name="trustAssets"
                value={childNumber}
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                maxLength={1}
                disabled={true}
                className="numberInput"
              />
              <p className="unit">명</p>
            </div>
          </NumberInputContainer>
        </div>
      </InputContainer>
      <InputContainer>
        <p className="label">19세 미만 미성년자 수</p>
        <div className="input">
          <NumberInputContainer
            $filled={inputValue.under19Number === "" ? false : true}
          >
            <div className="inputWrap">
              <NumericFormat
                name="trustAssets"
                value={inputValue.under19Number}
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                maxLength={1}
                className="numberInput"
                onChange={(e) =>
                  handleChangeInput("under19Number", e.target.value)
                }
              />
              <p className="unit">명</p>
            </div>
          </NumberInputContainer>
        </div>
      </InputContainer>
      <InputContainer>
        <p className="label">65세 이상 연로자 수</p>
        <div className="input">
          <NumberInputContainer
            $filled={inputValue.older65Number === "" ? false : true}
          >
            <div className="inputWrap">
              <NumericFormat
                name="trustAssets"
                value={inputValue.older65Number}
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                maxLength={1}
                className="numberInput"
                onChange={(e) =>
                  handleChangeInput("older65Number", e.target.value)
                }
              />
              <p className="unit">명</p>
            </div>
          </NumberInputContainer>
        </div>
      </InputContainer>
      <InputContainer>
        <p className="label">장애인 수</p>
        <div className="input">
          <NumberInputContainer
            $filled={inputValue.disabilityNumber === "" ? false : true}
          >
            <div className="inputWrap">
              <NumericFormat
                name="trustAssets"
                value={inputValue.disabilityNumber}
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                maxLength={1}
                className="numberInput"
                onChange={(e) =>
                  handleChangeInput("disabilityNumber", e.target.value)
                }
              />
              <p className="unit">명</p>
            </div>
          </NumberInputContainer>
        </div>
      </InputContainer>
    </>
  );
}

export default PersonCountInfo;
