
/***********************************************************************************
*** Law12 ** 조세특례제한법 97조의 5 장기일반민간임대주택에 대한 양도소득세 감면 Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law12Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}
export default function Law12({ setModalOpen, setLawModalTitle, callBack, callMode }: Law12Props) {
    React.useEffect(() => {
        setLawModalTitle("조세특례제한법 97조의 5 장기일반민간임대주택에 대한 양도소득세 감면");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className='w100p hAuto ofA mb30'>
                <table className={
                    'forest-list-table bdt1 bdb1 bdcDDDDDD ' +
                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075'
                }>
                   <colgroup>
                        <col className='w100'></col>
                        <col className='w100'></col>
                        <col className='w100'></col>
                        <col className='w220'></col>
                        <col className='w130'></col>
                        <col className='w100'></col>
                        <col className='w100'></col>
                        <col className='w130'></col>
                        <col className='w160'></col>
                        <col className='w100'></col>
                        <col className='w160'></col>
                    </colgroup>
                    <tbody>
                        <tr className='h40'>
                            <th rowSpan={2}>구분</th>
                            <th rowSpan={2}>주택구분</th>
                            <th rowSpan={2}>주택규모</th>
                            <th rowSpan={2}>취득시기</th>
                            <th rowSpan={2}>가액기준</th>
                            <th colSpan={2}>등록</th>
                            <th rowSpan={2}>민특법 분류</th>
                            <th rowSpan={2}>임대기간</th>
                            <th rowSpan={2}>상한율</th>
                            <th>혜택<br/>(양도소득세 감면)</th>
                        </tr>
                        <tr className='h29'>
                            <th>지자체</th>
                            <th>세무서</th>
                            <th>10년 이상</th>
                        </tr>
                        <tr>
                            <th rowSpan={2}>개별사항</th>
                            <td rowSpan={2}>모든주택</td>
                            <td rowSpan={2}>국민주택<br/>규모 이하</td>
                            <td>2015.1.1. ~ 2018.9.13. <br/>이전 취득분<br/>(2018.9.13. 이전 <br/>계약체결 + 계약급지급,<br/>2018.9.13. 이전 취득 분양권,<br/> 조합원입주권 포함)</td>
                            <td>-</td>
                            <td rowSpan={2}>취득일로부터<br/> 3월 이내 <br/>장기등록</td>
                            <td>등록</td>
                            <td rowSpan={2}>매입임대주택</td>
                            <td rowSpan={2}>등록기간 동안 <br />계속하여 10년 이상</td>
                            <td rowSpan={2}>5% 제한</td>
                            <td>100%</td>
                        </tr>
                        <tr>
                            <td>2018.09.14.~2018.12.31 (2018.12.31. 까지 매매계약체결+계약급 지급 포함)</td>
                            <td>임대개시당시 기준시가 수도권 6억원(비수도권 3억원) 이하</td>
                            <td>등록</td>
                            <td>100%</td>
                        </tr>
                        <tr className='h110'>
                            <th>공통사항</th>
                            <td className='taL pt15 pb15 pl15' colSpan={11}>
                                1. 임대기간 계산 방법<br/>
                                가. 임대기간 개시일은 지자체 등록일, 세무서 등록일, 임대개시일중 가장 늦은날이 됨 <br/>
                                나. 피상속인의 임대주택을 상속받아 임대하는 경우에는 피상속인의 임대기간을 상속인의 임대기간에 통산함.<br/>
                                다. 도정법, 소규모정비법, 주택법상 사업으로 임대할 수 없는 경우 관리처분계획인가일 등 전후 6개월까지의 기간 동안 계속 임대한 것으로 본다<br/>
                                라. 기존 임차인의 퇴거일부터 다음 임차인의 주민등록을 이전하는 날까지의 기간으로서 6개월 이내의 기간은 임대기간으로 본다<br/>
                                마. 법률에 따라 협의매수 또는 수용되어 임대할 수 없는 경우의 해당 기간은 임대기간으로 본다<br/>
                                바. 장기일반민간임대주택 중 아파트를 임대하는 경우 자동말소에 따라 등록이 말소되는 경우 해당 주택은 8년 동안 등록 및 임대를 한 것으로 본다.
                            </td>
                        </tr>
                        <tr className='h110'>
                            <th>기타사항</th>
                            <td className='taL pt15 pb15 pl15' colSpan={11}>
                                1. 조세특례제한법 97조의 3, 97조의 4에 따른 장기임대주택에 대한 양도소득세의 과세특례와 중복하여 적용하지 아니한다.<br/>
                                2. 장기보유특별공제 특례를 적용함에 있어서 그 적용대상은 임대기간 중에 발생하는 소득에 한정됨을 명확히 하였으며, 임대기간 중 발생한 양도소득금액은 기준시가를 기준으로 안분한다.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}