import { ChangeEvent } from "react";
import styled from "styled-components";

type Props = {
  name?: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  disabled?: boolean;
  unit?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

function TextInput({
  name,
  value,
  onChange,
  placeholder,
  disabled,
  unit,
  ...rest
}: Props) {
  return (
    <TextInputContainer $filled={!value ? false : true}>
      <div className="inputWrap">
        <input
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          {...rest}
        />
        {unit && <p className="unit">{unit}</p>}
      </div>
    </TextInputContainer>
  );
}

const TextInputContainer = styled.div<{ $filled: boolean }>`
  width: 100%;
  height: auto;

  ${(props) => {
    if (props.$filled) {
      return `
          padding: 5px;
          border-radius: 10px;
          background-color: ${props.theme.color.pGreenLight03};
          border: none;
        `;
    }
  }}

  .inputWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 12px 16px;
    background-color: white;
    border: 1px solid
      ${(props) =>
        props.$filled
          ? props.theme.color.pGreenLight02
          : props.theme.color.nGray70};
    border-radius: 8px;

    .unit {
      font-family: "Spoqa Han Sans Neo";
      font-weight: 500;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.sNavy};
    }

    input {
      width: 100%;
      height: 100%;
      border: none;

      font-family: "Spoqa Han Sans Neo";
      font-weight: 400;
      font-size: 1.4em;
      line-height: 1.32;
      letter-spacing: -0.05em;
      color: ${(props) => props.theme.color.sNavy};

      &:focus {
        outline: none;
      }
    }
    input::placeholder {
      color: ${(props) => props.theme.color.nGray50};
    }

    input::-webkit-input-placeholder {
      color: ${(props) => props.theme.color.nGray50};
    }

    input:-ms-input-placeholder {
      color: ${(props) => props.theme.color.nGray50};
    }
  }
`;

export default TextInput;
