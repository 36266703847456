/***********************************************************************************
*** Law11 ** 조세특례제한법 97조의 4 장기임대주택에 대한 양도소득세의 과세특례 Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law11Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}

export default function Law11({ setModalOpen, setLawModalTitle, callBack, callMode }: Law11Props) {
    React.useEffect(() => {
        setLawModalTitle("조세특례제한법 97조의 4 장기임대주택에 대한 양도소득세의 과세특례");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className='w100p hAuto ofA mb30'>
                <table className={
                    'forest-list-table bdt1 bdb1 bdcDDDDDD ' +
                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075'
                }>
                   <colgroup>
                        <col className='w80'></col>
                        <col className='w100'></col>
                        <col className='w160'></col>
                        <col className='w160'></col>
                        <col className='w100'></col>
                        <col className='w100'></col>
                        <col className='w100'></col>
                        <col className='w80'></col>
                        <col className='w80'></col>
                        <col className='w80'></col>
                        <col className='w160'></col>
                    </colgroup>
                    <tbody>
                        <tr className='h40'>
                            <th rowSpan={2}>구분</th>
                            <th rowSpan={2}>주택구분</th>
                            <th rowSpan={2}>주택규모</th>
                            <th rowSpan={2}>가액기준</th>
                            <th colSpan={2}>등록</th>
                            <th rowSpan={2}>민특법 분류</th>
                            <th rowSpan={2}>임대기간</th>
                            <th rowSpan={2}>상한율</th>
                            <th>추가질문</th>
                            <th>혜택<br/>(장기보유특별공제)</th>
                        </tr>
                        <tr className='h29'>
                            <th>지자체</th>
                            <th>세무서</th>
                            <th>임대호수</th>
                            <th>6 이상</th>
                        </tr>
                        <tr>
                            <th rowSpan={2}>개별사항</th>
                            <td>모든주택</td>
                            <td>-</td>
                            <td>임대개시일 현재 기준시가 수도권 6억원(비수도권 3억원)이하</td>
                            <td rowSpan={2}>2018.3.31. 까지 등록</td>
                            <td rowSpan={2}>2018.3.31. 까지 등록</td>
                            <td>매입임대주택</td>
                            <td rowSpan={2}>6년 이상</td>
                            <td rowSpan={2}>5% 제한</td>
                            <td>1호 이상</td>
                            <td rowSpan={2}>6년부터 10년까지<br/> 1년에 2%씩 <br/>장기보유특별공제<br/> 추가공제</td>
                        </tr>
                        <tr>
                            <td>모든자가신축</td>
                            <td>다세대/다가구 주택 : 149㎡ 이하<br/>단독주택 : 대지 298㎡, 연면적 149㎡ 이하</td>
                            <td>임대개시일 현재 기준시가 6억원 이하</td>
                            <td>건설임대주택</td>
                            <td className='bdr1-i bdcDDDDDD-i'>2호 이상</td>
                        </tr>
                        <tr className='h110'>
                            <th>공통사항</th>
                            <td className='taL pt15 pb15 pl15' colSpan={10}>
                                1. 다주택 중과배제(유예)<br/>
                                2. 피상속인의 임대주택을 상속받아 임대하는 경우에는 피상속인의 임대기간을 상속인의 임대기간에 통산한다.
                            </td>
                        </tr>
                        <tr className='h110'>
                            <th>기타사항</th>
                            <td className='taL pt15 pb15 pl15' colSpan={10}>
                                1. 조세특례제한법 97의 3, 97의 5와 중복적용 배제<br/>
                                2. 거주요건 배제하나 2019.12.17. 이후 조정대상지역에서 주택임대사업자등록한 주택은 2년 거주요건 적용
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}