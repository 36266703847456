/*******************************************************************************
 You are strictly prohibited to copy, disclose, distribute, modify, or use
 this program in part or as a whole without the prior written consent of
 CallTax. CallTax, owns the intellectual property rights in and to this program.
 (Copyright © 2023 CallTax. All Rights Reserved| Confidential)

 Specification
    1. Program ID    : BoardMngWrite.tsx
    2. Program Title : 게시판 작성, 수정
    3. Created by    : JH Kim
    4. Create Date   : 2023.
    5. Reference     :
        - 커뮤니티 > 전문가커뮤니티, 질의/응답 : 로그인 회원, 관리자
        - 관리자시스템 > 공지사항관리, FAQ 관리 : 관리자
*******************************************************************************/
import {
    useNavigate,
    useParams,
    useLocation
} from "react-router-dom";
import Axios from "js/common/Axios";
import React from "react";
import ReactQuill from "react-quill";
import Dropzone from "react-dropzone";
import AppContext from "js/common/AppContext";
import CloseIcon from '@mui/icons-material/Close';
import TextBox from "js/common/ui/input/TextBox";
import Util from "js/common/Util";

interface BoardMngWriteProps {
    boardTy: string
}
const BoardMngWrite = ({boardTy}: BoardMngWriteProps) => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const QuillRef = React.useRef<ReactQuill>();
    const [boardName, setBoardName] = React.useState<string>("");
    const [boardTitle, setBoardTitle] = React.useState<string>("");
    const [boardCtt, setBoardCtt] = React.useState<string>("");
    const [boardFiles, setBoardFiles] = React.useState<File[]>([]);
    const [uldBoardFiles, setUldBoardFiles] = React.useState<boardFileProps[]>([]);
    const [topPath, setTopPath] = React.useState<string>(location.pathname.substring(1, location.pathname.indexOf('/', 1)));   // 권한 확인 : 공지사항(1001), FAQ(1003) - admin / 전문가커뮤니티(1002), 질의/응답(1004) - system

    interface boardFileProps {
        fileSn: number
        fileNm: string
        fileExt: string
        fileSize: number
    }

    const goBoardList = () => {
        if (location.pathname.indexOf("/reg") !== -1) {
            navigate(location.pathname.replace("/reg", ""));
        } else if (location.pathname.indexOf("/mod") !== -1) {
            navigate(location.pathname.substring(0, location.pathname.indexOf("/mod")));
        }
    }

    const goBoardView = () => {
        navigate(location.pathname.substring(0, location.pathname.indexOf("/mod")) + "/view/" + params.boardSn);
    }

    const insBoardInfo = () => {
        if (Util.isEmpty(boardTitle)) {
            AppContext.openAlert({
                title: "제목 미입력",
                msg: "제목을 입력하세요",
                icon: "check"
            });
            return;
        }
        if (Util.isEmpty(boardCtt)) {
            AppContext.openAlert({
                title: "내용 미입력",
                msg: "내용을 입력하세요",
                icon: "check"
            });
            return;
        }

        let url = "";
        let fgNm = "";
        let param = {};
        if (Util.isEmpty(params.boardSn)) {
            url = topPath + "/board/insBoardInfo.do";
            fgNm = "등록";
            param = {
                boardTy: boardTy,
                boardTitle: boardTitle,
                boardCtt: boardCtt,
                isSecret: 0,
                isTop: 0,
                boardFiles: boardFiles
            };
        } else {
            url = topPath + "/board/updBoardInfo.do";
            fgNm = "수정";
            param = {
                boardSn: params.boardSn,
                boardTitle: boardTitle,
                boardCtt: boardCtt,
                boardFiles: boardFiles
            };
        }
        AppContext.openAlert({
            title: Util.getValToCode("1033", boardTy) + " 게시글 " + fgNm,
            msg: Util.getValToCode("1033", boardTy) + " 게시글을 " + fgNm + "하시겠습니까?",
            icon: "check",
            confirmText: fgNm,
            handleConfirm: () => {
                AppContext.showSpinner();
                Axios.dataAccess({
                    url: url,
                    methodType: "post",
                    isMultipart: true,
                    paramData: param,
                    onSuccessFn: () => {
                        AppContext.openAlert({
                            title: Util.getValToCode("1033", boardTy) + " 게시글 " + fgNm + " 완료",
                            msg: Util.getValToCode("1033", boardTy) + " 게시글이 " + fgNm + "되었습니다.",
                            icon: "check"
                        });
                        goBoardList();
                        AppContext.hideSpinner();
                    }
                });
            }
        });
    }

    // 이미지를 업로드 하기 위한 함수
    const imageHandler = React.useCallback(() => {
        // 파일을 업로드 하기 위한 input 태그 생성
        const input = document.createElement("input");

        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        // 파일이 input 태그에 담기면 실행 될 함수
        input.onchange = async () => {
            const file = input.files;

            if (file !== null) {
                Axios.dataAccess({
                    url: topPath + "/board/saveBoardQuillFile.do",
                    methodType: "post",
                    isMultipart: true,
                    paramData: {
                        boardImageFile: file[0]
                    },
                    onSuccessFn: (res: any) => {
                        const extNm = file[0].name.substring(file[0].name.lastIndexOf(".") + 1).toLowerCase();
                        const url = AppContext.backendUrl + "/common/image/quill/" + res.item + "." + extNm;
                        const range = QuillRef.current?.getEditor().getSelection()?.index;
                        if (range !== null && range !== undefined) {
                            const quill = QuillRef.current?.getEditor();
                            quill?.setSelection(range, 1);
                            quill?.clipboard.dangerouslyPasteHTML(range, `<img src=${url} alt=${url} />`);
                        }
                    }
                });
            }
        };
    }, [topPath]);

    // quill에서 사용할 모듈을 설정하는 코드 입니다.
    // 원하는 설정을 사용하면 되는데, 저는 아래와 같이 사용했습니다.
    // useMemo를 사용하지 않으면, 키를 입력할 때마다, imageHandler 때문에 focus가 계속 풀리게 됩니다.
    const modules = React.useMemo(
        () => ({
            toolbar: {
                container: [
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [{ size: ["small", false, "large", "huge"] }, { color: [] }],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                        { align: [] },
                    ],
                    ["image", "video"],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    React.useEffect(() => {
        // 권한 확인 : 공지사항(1001), FAQ(1003) - admin / 전문가커뮤니티(1002), 질의/응답(1004) - system
        setTopPath(location.pathname.substring(1, location.pathname.indexOf('/', 1)));

        if (Util.isEmpty(boardTy) || boardTy === undefined) {
            return;
        }
        setBoardName(Util.getValToCode("1033", boardTy));
        if (Util.isEmpty(params.boardSn)) {
            return;
        }
        Axios.dataAccess({
            url: topPath + "/board/selBoardInfo.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                boardTy: boardTy,
                boardSn: params.boardSn
            },
            onSuccessFn: (res: any) => {
                setBoardTitle(res.item.boardInfo.boardTitle);
                setBoardCtt(res.item.boardInfo.boardCtt);
                setUldBoardFiles(res.item.boardFileList);
            }
        });
    }, [params.boardSn, boardTy, location.pathname, topPath]);

    return (
        <div className="dpFlx jcC">
            <div className="w1210">
                <div className="dpFlx flxdCol jcC aiC pt60 pb30">
                    <div className='taC pb30'>
                        <span className="dpBlock fcBlack fs38 ls266 SpoqaHanSansNeo-Medium">{boardName} {Util.isEmpty(params.boardSn) ? "등록" : "수정"}</span>
                    </div>
                    <TextBox
                        value={boardTitle}
                        height={70}
                        fontSize={23}
                        fontFamily="SpoqaHanSansNeo-Medium"
                        color="#2B2B2B"
                        offColor="#999999"
                        borderOffColor="#E8E8ED"
                        maxLength={250}
                        placeholder="게시글 제목을 입력하세요."
                        onChangeFunc={(text: string) => setBoardTitle(text)}
                        className="bd1 pl20 pr20"
                        inputClassName="ls115 fw500"
                    />
                    <ReactQuill
                        ref={(element) => {
                            if (element !== null) {
                                QuillRef.current = element;
                            }
                        }}
                        value={boardCtt}
                        onChange={setBoardCtt}
                        modules={modules}
                        theme="snow"
                        placeholder="내용을 입력해주세요."
                        style={{
                            width: "1210px",
                            minHeight: "256px"
                        }}
                    />
                    <Dropzone
                        onDrop={(acceptFiles: File[]) => {
                            if (uldBoardFiles.length + boardFiles.length + acceptFiles.length > 10) {
                                AppContext.openAlert({
                                    title: "첨부파일 갯수 제한",
                                    msg: "게시글 내 첨부파일은 10개를 초과할 수 없습니다.",
                                    icon: "check"
                                });
                                return;
                            }
                            for (const acceptFile of acceptFiles) {
                                const extNm = acceptFile.name.substring(acceptFile.name.lastIndexOf(".") + 1).toLowerCase();
                                if (AppContext.fileWhiteExt.indexOf(extNm) === -1) {
                                    AppContext.openAlert({
                                        title: "파일 확장자 체크",
                                        msg: extNm + " 확장자는 업로드 불가한 확장자의 파일입니다.",
                                        icon: "error"
                                    });
                                    break;
                                }
                                boardFiles.push(acceptFile);
                                setBoardFiles(boardFiles);
                            }
                        }}
                    >
                        {({getRootProps, getInputProps}) => (
                            <div className="w100p mh100 bdr1 bdl1 bdb1 bdcE8E8ED csP pl10 pr10 pb10 bdBox" {...getRootProps()}>
                                <input {...getInputProps()} />
                                {uldBoardFiles.length === 0 && boardFiles.length === 0 ?
                                    <div className="dpFlx aiC jcC h90 bdBox">
                                        <span className="SpoqaHanSansNeo-Medium fs16 fc222222 ls08 pt10">첨부할 파일을 드래그하세요.</span>
                                    </div>
                                : <></>}
                                {uldBoardFiles.map((item: any, idx: number) => {
                                    return (
                                        <div key={idx} className="bdrd5 bd1 bdcCECECE p10 csP dpInBlock mr10 mt10 bgcFFE6CE" onClick={(e) => {
                                            e.stopPropagation();
                                            Axios.dataAccess({
                                                url: "common/fileDownload.do",
                                                methodType: "get",
                                                isFileDown: true,
                                                paramData: {
                                                    callType: "board",
                                                    fileName: params.boardSn + "-" + item.fileSn + "." + item.fileExt,
                                                    orgFileName: item.fileNm
                                                }
                                            });
                                        }}>
                                            <div className="dpFlx aiC jcSb">
                                                <span className="SpoqaHanSansNeo-Medium fs15 fc17A840 ls075 taU fw500">{item.fileNm}</span>
                                                <CloseIcon
                                                    style={{ fontSize: 20, color: 'black', paddingLeft: 5 }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        AppContext.openAlert({
                                                            title: "첨부파일 삭제",
                                                            msg: "첨부파일을 삭제하시겠습니까?",
                                                            icon: "check",
                                                            confirmText: "파일삭제",
                                                            handleConfirm: () => {
                                                                AppContext.showSpinner();
                                                                Axios.dataAccess({
                                                                    url: topPath + "/board/delBoardFileInfo.do",
                                                                    methodType: "post",
                                                                    paramData: {
                                                                        boardSn: item.boardSn,
                                                                        fileSn: item.fileSn,
                                                                        fileExt: item.fileExt
                                                                    },
                                                                    onSuccessFn: () => {
                                                                        const tempFiles = [];
                                                                        for (let i = 0; i < uldBoardFiles.length; i++) {
                                                                            if (i !== idx) {
                                                                                tempFiles.push(uldBoardFiles[i]);
                                                                            }
                                                                        }
                                                                        setUldBoardFiles(tempFiles);
                                                                        AppContext.openAlert({
                                                                            title: "첨부파일 삭제완료",
                                                                            msg: "게시글 첨부파일이 삭제되었습니다.",
                                                                            icon: "check"
                                                                        });
                                                                        AppContext.hideSpinner();
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                                {boardFiles.map((item: any, idx: number) => {
                                    return (
                                        <div key={idx} className="bdrd5 bd1 bdcCECECE p10 csP dpInBlock mr10 mt10" onClick={(e) => {
                                            e.stopPropagation();
                                        }}>
                                            <div className="dpFlx aiC jcSb">
                                                <span className="SpoqaHanSansNeo-Medium fs15 fc17A840 ls075 taU fw500">{item.name}</span>
                                                <CloseIcon
                                                    style={{ fontSize: 20, color: 'black', paddingLeft: 5 }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        const tempFiles = [];
                                                        for (let i = 0; i < boardFiles.length; i++) {
                                                            if (i !== idx) {
                                                                tempFiles.push(boardFiles[i]);
                                                            }
                                                        }
                                                        setBoardFiles(tempFiles);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </Dropzone>
                </div>
                <div className="dpFlx jcSb aiC w100p h56 mb100">
                    <div className="w111 h100p bgcWhite bd1 bdcDDDDDD dpFlx aiC jcC csP bdBox" onClick={() => {
                        AppContext.openAlert({
                            title: "알림",
                            msg: "목록으로 이동하시겠습니까?",
                            icon: "check",
                            confirmText: "목록",
                            handleConfirm: () => goBoardList()
                        });
                    }}>
                        <span className="SpoqaHanSansNeo-Medium fs16 fc2B2B2B">목록으로</span>
                    </div>
                    <div className='dpFlx aiC jcC'>
                        {Util.isNotEmpty(params.boardSn) ?
                            <div className='w210 h56 bgcF6F6F6 dpFlx aiC jcC csP' onClick={() => {
                                AppContext.openAlert({
                                    title: "알림",
                                    msg: "수정을 취소하시겠습니까?",
                                    icon: "check",
                                    confirmText: "수정취소",
                                    handleConfirm: () => {
                                        goBoardView();
                                    }
                                });
                            }}>
                                <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>취소</span>
                            </div>
                        : <></>}
                        <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => insBoardInfo()}>
                            <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>저장</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BoardMngWrite;