import { useRef } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material";
import "dayjs/locale/ko";
import dayjs from "dayjs";
import styledComponent from "styled-components";

import Util from "js/common/Util";

type Props = {
  value: string;
  onChangeInput: (date: dayjs.Dayjs | null) => void;
  isFilled: boolean;
};

interface CustomTextFieldProps extends Omit<TextFieldProps, "variant"> {
  isFilled?: boolean;
}

const StyledTextField = styled(
  ({ isFilled, ...props }: CustomTextFieldProps) => <TextField {...props} />
)<CustomTextFieldProps>(({ isFilled }) => ({
  position: "relative",
  width: "100%",
  height: "auto",

  "& .MuiOutlinedInput-root": {
    position: "relative",
    backgroundColor: "white",
    border: isFilled ? "1px solid #6DCE78" : "1px solid #DEDFE0",
    borderRadius: isFilled ? "10px" : "8px",

    fontFamily: "Spoqa Han Sans Neo",
    fontWeight: "400",
    fontSize: "1.4em",
    lineHeight: "1.32",
    letterSpacing: "-0.05em",
    color: "#2B2B35",
  },

  "& .MuiInputBase-root-MuiOutlinedInput-root": {
    overflow: "hidden",
  },

  "& .MuiOutlinedInput-input": {
    padding: "12px 16px",
    backgroundColor: "white",
    borderRadius: isFilled ? "10px" : "8px",
  },

  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#DEDFE0",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: isFilled ? "0px" : "1px",
  },

  "& .MuiSvgIcon-root": {
    width: "1em",
    height: "1em",
  },
}));

const InputWrapper = styledComponent.div<{ isFilled: boolean }>`
  width: 100%;
  height: auto;

  ${(props) => {
    if (props.isFilled) {
      return `
        padding: 5px;
        background-color: #C2E9C5;
        border-radius: 10px;
      `;
    }
  }}
`;

const DatePickerTheme = (theme: any) =>
  createTheme({
    ...theme,
    components: {
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            position: "relative",
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          label: {
            fontFamily: "Spoqa Han Sans Neo",
            fontSize: "14px",
            fontWeight: "400",
          },
          switchViewIcon: {
            width: "1.5em",
            height: "1.5em",
          },
        },
      },
      MuiDayCalendar: {
        styleOverrides: {
          weekDayLabel: {
            fontFamily: "Spoqa Han Sans Neo",
            fontSize: "14px",
            fontWeight: "400",
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            fontFamily: "Spoqa Han Sans Neo",
            fontSize: "14px",
            fontWeight: "400",
          },
        },
      },
      MuiPickersMonth: {
        styleOverrides: {
          monthButton: {
            fontFamily: "Spoqa Han Sans Neo",
            fontSize: "14px",
            fontWeight: "400",
            "&.Mui-selected": {
              color: "white",
              backgroundColor: "#17a840 !important",
            },
          },
        },
      },
      MuiPickersYear: {
        styleOverrides: {
          yearButton: {
            fontFamily: "Spoqa Han Sans Neo",
            fontSize: "14px",
            fontWeight: "400",
            "&.Mui-selected": {
              color: "white",
              backgroundColor: "#17a840 !important",
            },
          },
        },
      },
    },
  });

function DateInput({ value, onChangeInput, isFilled }: Props) {
  const theme = createTheme(); // 기본 테마 가져오기
  const datePickerTheme = DatePickerTheme(theme);
  const anchorRef = useRef<HTMLInputElement | null>(null);

  return (
    <ThemeProvider theme={datePickerTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
        <InputWrapper isFilled={isFilled} ref={anchorRef}>
          <DesktopDatePicker
            format="YYYY. MM. DD"
            value={dayjs(Util.isEmpty(value) ? "" : value)}
            views={["year", "month", "day"]}
            onChange={(data) => {
              if (Util.isNotEmpty(onChangeInput) && Util.isNotEmpty(data)) {
                onChangeInput(data);
              } else if (Util.isEmpty(data)) {
                onChangeInput(null);
              }
            }}
            disabled={false}
            sx={{
              position: "relative",
            }}
            slots={{
              textField: (params) => (
                <StyledTextField
                  {...params}
                  isFilled={isFilled} // value가 있으면 isFilled를 true로 설정
                />
              ),
            }}
            slotProps={{
              textField: {
                inputProps: {
                  readOnly: true,
                },
              },
              popper: {
                // Popper 컴포넌트에 적용될 속성
                anchorEl: anchorRef.current, // Popper의 위치를 고정할 anchorEl 설정
                placement: "bottom-start", // 위치 설정 (예: 아래쪽에 시작)
              },
            }}
          />
        </InputWrapper>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default DateInput;
