/*******************************************************************************
*** SimInheTax ** 상속세 간편계산기
*******************************************************************************/
import Util              from "js/common/Util";
import NumberTextBox     from "js/common/ui/input/NumberTextBox";
import SelectBox         from "js/common/ui/input/SelectBox";
import Modal             from "js/common/ui/modal/Modal";
import React             from "react";
import SimInheTaxCalc    from "./SimInheTaxCalc";
import AppContext        from "js/common/AppContext";
import ToolTip           from "js/common/ui/ToolTip";
import InhePersonalDedu  from "../inhetax/InhePersonalDedu";
import InheFinanInheDedu from "../inhetax/InheFinanInheDedu";

const SimInheTax = () => {
    // 입력항목
    const [inherProp        , setInherProp        ] = React.useState<string>(""); // 01. 상속재산
    const [estiInher        , setEstiInher        ] = React.useState<string>(""); // 02. 추정상속가액
    const [trustAssets      , setTrustAssets      ] = React.useState<string>(""); // 03. 퇴직금/보험금/신탁자산
    const [spouse           , setSpouse           ] = React.useState<string>(""); // 04. 배우자 유무
    const [spousePreGift    , setSpousePreGift    ] = React.useState<string>(""); // 05. 배우자 사전증여 유무
    const [spousePreGiftProp, setSpousePreGiftProp] = React.useState<string>(""); // 06. 배우자 사전증여 재산가액
    const [spousePreGiftTax , setSpousePreGiftTax ] = React.useState<string>(""); // 07. 배우자 사전증여세액
    const [childNumber      , setChildNumber      ] = React.useState<string>(""); // 08. 자녀 수
    const [preGift          , setPreGift          ] = React.useState<string>(""); // 09. (자녀)사전증여 유무
    const [preGiftProp      , setPreGiftProp      ] = React.useState<string>(""); // 10. (자녀)사전증여 재산가액
    const [preGiftTax       , setPreGiftTax       ] = React.useState<string>(""); // 11. (자녀)사전증여세액
    const [debtAmt          , setDebtAmt          ] = React.useState<string>(""); // 12. 채무
    const [utilityBill      , setUtilityBill      ] = React.useState<string>(""); // 13. 공과금
    const [funeralExpen     , setFuneralExpen     ] = React.useState<string>(""); // 14. 장례비용
    const [deduCls          , setDeduCls          ] = React.useState<string>(""); // 15. 공제구분
    const [sumDedu          , setSumDedu          ] = React.useState<string>("500000000"); // 16. 일괄공제(5억)
    const [basicDedu        , setBasicDedu        ] = React.useState<string>("200000000"); // 17. 기초공제(2억)
    const [personDedu       , setPersonDedu       ] = React.useState<string>(""); // 18. 인적공제
    const [spouseDisabled   , setSpouseDisabled   ] = React.useState<string>(""); // 19. 배우자 장애인 여부
    const [spouseGender     , setSpouseGender     ] = React.useState<string>(""); // 20. 배우자 성별
    const [spouseAge        , setSpouseAge        ] = React.useState<string>(""); // 21. 배우자 나이
    const [spouseInherProp  , setSpouseInherProp  ] = React.useState<string>(""); // 22. 배우자 상속재산가액
    const [spouseSucUtilDebt, setSpouseSucUtilDebt] = React.useState<string>(""); // 23. 배우자 승계 공과금&채무
    const [familyDedu       , setFamilyDedu       ] = React.useState<string>(""); // 24. 기업상속공제
    const [farmDedu         , setFarmDedu         ] = React.useState<string>(""); // 25. 영농상속공제
    const [houseDedu        , setHouseDedu        ] = React.useState<string>(""); // 26. 동거주택상속공제
    const [finanDedu        , setFinanDedu        ] = React.useState<string>(""); // 27. 금융상속공제
    const [directHeir       , setDirectHeir       ] = React.useState<string>(""); // 22_0. 직계존속 상속인 유무
    
     // 입력항목 표시 여부
     const [visible01, setVisible01] = React.useState<boolean>(true ); // 01. 상속재산
     const [visible02, setVisible02] = React.useState<boolean>(true ); // 02. 추정상속가액
     const [visible03, setVisible03] = React.useState<boolean>(true ); // 03. 퇴직금/보험금/신탁자산
     const [visible04, setVisible04] = React.useState<boolean>(true ); // 04. 배우자 유무
     const [visible05, setVisible05] = React.useState<boolean>(false); // 05. 배우자 사전증여 유무
     const [visible06, setVisible06] = React.useState<boolean>(false); // 06. 배우자 사전증여 재산가액
     const [visible07, setVisible07] = React.useState<boolean>(false); // 07. 배우자 사전증여세액
     const [visible08, setVisible08] = React.useState<boolean>(false); // 08. 자녀 수
     const [visible09, setVisible09] = React.useState<boolean>(false); // 09. 사전증여 유무
     const [visible10, setVisible10] = React.useState<boolean>(false); // 10. 사전증여 재산가액
     const [visible11, setVisible11] = React.useState<boolean>(false); // 11. 사전증여세액
     const [visible12, setVisible12] = React.useState<boolean>(false); // 12. 채무
     const [visible13, setVisible13] = React.useState<boolean>(false); // 13. 공과금
     const [visible14, setVisible14] = React.useState<boolean>(false); // 14. 장례비용
     const [visible15, setVisible15] = React.useState<boolean>(false); // 15. 공제구분
     const [visible16, setVisible16] = React.useState<boolean>(false); // 16. 일괄공제
     const [visible17, setVisible17] = React.useState<boolean>(false); // 17. 기초공제
     const [visible18, setVisible18] = React.useState<boolean>(false); // 18. 인적공제
     const [visible19, setVisible19] = React.useState<boolean>(false); // 19. 배우자 장애인 여부
     const [visible20, setVisible20] = React.useState<boolean>(false); // 20. 배우자 성별
     const [visible21, setVisible21] = React.useState<boolean>(false); // 21. 배우자 나이
     const [visible22, setVisible22] = React.useState<boolean>(false); // 22. 배우자 상속재산가액
     const [visible23, setVisible23] = React.useState<boolean>(false); // 23. 배우자 승계 공과금&채무
     const [visible24, setVisible24] = React.useState<boolean>(false); // 24. 기업상속공제
     const [visible25, setVisible25] = React.useState<boolean>(false); // 25. 영농상속공제
     const [visible26, setVisible26] = React.useState<boolean>(false); // 26. 동거주택상속공제
     const [visible27, setVisible27] = React.useState<boolean>(false); // 27. 금융상속공제
     const [visible22_0, setVisible22_0] = React.useState<boolean>(false); // 22_0. 직계존속 상속인 유무
     
    // 입력항목 입력 가능여부
    const [disabled04, setDisabled04] = React.useState<boolean>(false); // 04. 배우자 유무
    const [disabled08, setDisabled08] = React.useState<boolean>(false); // 08. 자녀 수
    const [disabled15, setDisabled15] = React.useState<boolean>(false); // 15. 공제구분
    const [disabled16, setDisabled16] = React.useState<boolean>(false); // 16. 일괄공제
    const [disabled17, setDisabled17] = React.useState<boolean>(false); // 17. 기초공제

    // 입력항목 TEXT 변경
    const [title09, setTitle09] = React.useState<string>("사전증여 유무"    ); // 09. 사전증여 유무
    const [title10, setTitle10] = React.useState<string>("사전증여 재산가액"); // 10. 사전증여 재산가액
    const [title11, setTitle11] = React.useState<string>("사전증여세액"     ); // 11. 사전증여세액

    const [simInheTaxCalcModalOpen, setSimInheTaxCalcModalOpen] = React.useState<boolean>(false);   // 상속세 간편계산기 결과 모달
    const [simInheTaxCalcModalVsb , setSimInheTaxCalcModalVsb ] = React.useState<boolean>(false);   // 상속세 간편계산기 결과 표시 여부
    const [inheTaxParam           , setInheTaxParam           ] = React.useState<InheTaxParamProps[]>([]);   // 상속세 계산용 파라미터 배열

    const [personalDeduModalOpen , setPersonalDeduModalOpen ] = React.useState<boolean>(false); // 인적공제 계산 모달
    const [finanInheDeduModalOpen, setFinanInheDeduModalOpen] = React.useState<boolean>(false); // 금융상속공제 계산 모달    
    // const [personDeduCalModalOpen , setPersonDeduCalModalOpen ] = React.useState<boolean>(false); // 인적공제 계산방법 모달

    // 상속세 계산 입력정보 Setting
    interface  InheTaxParamProps {
        taxFlag          : string;  
        inherProp        : string;  // 01. 상속재산가액  
        estiInher        : string;  // 02. 추정상속가액
        trustAssets      : string;  // 03. 퇴직금/보험금/신탁자산
        spouse           : string;  // 04. 배우자 유무
        spousePreGift    : string;  // 05. 배우자 사전증여 유무
        spousePreGiftProp: string;  // 06. 배우자 사전증여 재산가액
        spousePreGiftTax : string;  // 07. 배우자 사전증여세액
        childNumber      : string;  // 08. 자녀 수
        preGift          : string;  // 09. 사전증여 유무
        preGiftProp      : string;  // 10. 사전증여 재산가액
        preGiftTax       : string;  // 11. 사전증여세액
        debtAmt          : string;  // 12. 채무
        utilityBill      : string;  // 13. 공과금
        funeralExpen     : string;  // 14. 장례비용
        deduCls          : string;  // 15. 공제구분
        sumDedu          : string;  // 16. 일괄공제
        basicDedu        : string;  // 17. 기초공제
        personDedu       : string;  // 18. 인적공제
        spouseDisabled   : string;  // 19. 배우자 장애인 여부
        spouseGender     : string;  // 20. 배우자 성별
        spouseAge        : string;  // 21. 배우자 나이
        spouseInherProp  : string;  // 22. 배우자 상속재산가액
        spouseSucUtilDebt: string;  // 23. 배우자 승계 공과금&채무
        familyDedu       : string;  // 24. 기업상속공제
        farmDedu         : string;  // 25. 영농상속공제
        houseDedu        : string;  // 26. 동거주택상속공제
        finanDedu        : string;  // 27. 금융상속공제
        directHeir       : string;  // 22_0. 직계존속 상속인 유무
    }

    // 인적공제 계산금액 callback
    const onFinishCalPerson = (item: any) => {
        setPersonDedu(item.calPersonDedu); // 인적공졔 계산금액

        if (spouse === "Y") {   // 배우자 유 ▶ 22.배우자 상속재산가액, 23.배우자 승계공과금&채무
            setVisible22(true);
        } else if (spouse === "N") {    // 배우자 무 ▶ 24.가입상속공제, 25.영농상속공제, 26.동거주택상속공제, 27.금융상속공제
            setVisible24(true);
            setVisible25(true);
            setVisible26(true);
            setVisible27(true);
        } else {}        
    }
    
    // 금융상속공제 금액 계산 callback
    const onFinishCalFin = (item: any) => {
        setFinanDedu(item.calFinDedu); // 금융상속공제 계산금액
    }
        
    // 상속세 계산하기
    const callTax = (taxFlag:string) => {
        // 입력 값 확인 메시지 처리
        if (visible01) {
            if (Util.isEmpty(inherProp)) {AppContext.openAlert({title: "상속재산가액", msg: "상속재산가액을 입력하세요.", icon: "check"}); return;}
            if (Number(inherProp) <= 0 ) {AppContext.openAlert({title: "상속재산가액", msg: "상속재산가액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible02) {
            if (Util.isEmpty(estiInher)) {AppContext.openAlert({title: "추정상속가액", msg: "추정상속가액을 입력하세요.", icon: "check"}); return;}
            if (Number(estiInher) < 0 ) {AppContext.openAlert({title: "추정상속가액", msg: "추정상속가액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible03) {
            if (Util.isEmpty(trustAssets)) {AppContext.openAlert({title: "퇴직금/보험금/신탁자산", msg: "퇴직금/보험금/신탁자산을 입력하세요.", icon: "check"}); return;}
            if (Number(estiInher) < 0 ) {AppContext.openAlert({title: "퇴직금/보험금/신탁자산", msg: "퇴직금/보험금/신탁자산을 입력하세요.", icon: "check"}); return;}
        }
        if (visible04) {if (Util.isEmpty(spouse       )) {AppContext.openAlert({title: "배우자 유무"         , msg: "배우자 유무를 선택하세요."         , icon: "check"}); return;}}
        if (visible05) {if (Util.isEmpty(spousePreGift)) {AppContext.openAlert({title: "배우자 사전증여 유무", msg: "배우자 사전증여 유무를 선택하세요.", icon: "check"}); return;}}
        
        if (visible06) {
            if (Util.isEmpty(spousePreGiftProp)) {AppContext.openAlert({title: "배우자 사전증여 재산가액", msg: "배우자 사전증여 재산가액을 입력하세요.", icon: "check"}); return;}
            if (Number(spousePreGiftProp) <= 0 ) {AppContext.openAlert({title: "배우자 사전증여 재산가액", msg: "배우자 사전증여 재산가액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible07) {
            if (Util.isEmpty(spousePreGiftTax)) {AppContext.openAlert({title: "배우자 사전증여세액", msg: "배우자 사전증여세액을 입력하세요.", icon: "check"}); return;}
            if (Number(spousePreGiftTax) < 0  ) {AppContext.openAlert({title: "배우자 사전증여세액", msg: "배우자 사전증여세액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible08) {if (Util.isEmpty(childNumber)) {AppContext.openAlert({title: "자녀 수"      , msg: "자녀 수를 입력하세요."      , icon: "check"}); return;}}
        if (visible09) {if (Util.isEmpty(preGift    )) {AppContext.openAlert({title: "사전증여 여부", msg: "사전증여 여부를 선택하세요.", icon: "check"}); return;}}

        if (visible10) {
            if (Util.isEmpty(preGiftProp)) {AppContext.openAlert({title: "사전증여 재산가액", msg: "사전증여 재산가액을 입력하세요.", icon: "check"}); return;}
            if (Number(preGiftProp) <= 0 ) {AppContext.openAlert({title: "사전증여 재산가액", msg: "사전증여 재산가액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible11) {
            if (Util.isEmpty(preGiftTax)) {AppContext.openAlert({title: "사전증여세액", msg: "사전증여세액을 입력하세요.", icon: "check"}); return;}
            if (Number(preGiftTax) < 0 ) {AppContext.openAlert({title: "사전증여세액", msg: "사전증여세액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible12) {
            if (Util.isEmpty(debtAmt)) {AppContext.openAlert({title: "채무금액", msg: "채무금액을 입력하세요.", icon: "check"}); return;}
            if (Number(debtAmt) < 0 ) {AppContext.openAlert({title: "채무금액", msg: "채무금액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible13) {
            if (Util.isEmpty(utilityBill)) {AppContext.openAlert({title: "공과금", msg: "공과금을 입력하세요.", icon: "check"}); return;}
            if (Number(utilityBill) < 0 ) {AppContext.openAlert({title: "공과금", msg: "공과금을 입력하세요.", icon: "check"}); return;}
        }
        if (visible14) {
            if (Util.isEmpty(funeralExpen)) {AppContext.openAlert({title: "장례비용", msg: "장례비용을 입력하세요.", icon: "check"}); return;}
            if (Number(funeralExpen) < 0  ) {AppContext.openAlert({title: "장례비용", msg: "장례비용을 입력하세요.", icon: "check"}); return;}
            if (Number(Util.uncomma(funeralExpen)) > 15000000) {
                AppContext.openAlert({title: "장례비용 공제 한도초과", msg: "장례비용은 최대 1,500만원까지 공제가능합니다.\n1,500만원 이하로 입력하세요.", icon: "check"}); return;
            }            
        }
        if (visible15) {if (Util.isEmpty(deduCls)) {AppContext.openAlert({title: "공제구분"      , msg: "공제구분을 선택하세요."      , icon: "check"}); return;}}
        if (visible18) {
            if (Util.isEmpty(personDedu)) {AppContext.openAlert({title: "인적공제", msg: "인적공제 금액을 입력하세요.", icon: "check"}); return;}
            if (Number(personDedu) <= 0 ) {AppContext.openAlert({title: "인적공제", msg: "인적공제 금액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible19) {if (Util.isEmpty(spouseDisabled)) {AppContext.openAlert({title: "배우자 장애인 여부", msg: "상속받는 배우자의 장애인 여부를 선택하세요."      , icon: "check"}); return;}}
        if (visible20) {if (Util.isEmpty(spouseGender)) {AppContext.openAlert({title: "배우자 성별"      , msg: "상속받는 배우자의 성별을 선택하세요."      , icon: "check"}); return;}}
        if (visible21) {
            if (Util.isEmpty(spouseAge)) {AppContext.openAlert({title: "배우자 나이", msg: "상속받는 배우자 나이를 입력하세요.", icon: "check"}); return;}
            if (Number(spouseAge) <= 0 ) {AppContext.openAlert({title: "배우자 나이", msg: "상속받는 배우자 나이를 입력하세요.", icon: "check"}); return;}
        }
        if (visible22_0) {
            if (Util.isEmpty(directHeir)) {AppContext.openAlert({title: "직계존속 상속인 유무", msg: "직계존속 상속인 유무를 선택하세요.", icon: "check"}); return;}
        }
        if (visible22) {
            if (Util.isEmpty(spouseInherProp)) {AppContext.openAlert({title: "배우자 상속재산가액", msg: "배우자가 상속받은 상속재산가액을 입력하세요.", icon: "check"}); return;}
            if (Number(spouseInherProp) < 0  ) {AppContext.openAlert({title: "배우자 상속재산가액", msg: "배우자가 상속받은 상속재산가액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible23) {
            if (Util.isEmpty(spouseSucUtilDebt)) {AppContext.openAlert({title: "배우자 승계 공과금&채무액", msg: "상속받는 배우자가 승계한 공과금 및 채무금액를 입력하세요.", icon: "check"}); return;}
            if (Number(spouseSucUtilDebt) < 0  ) {AppContext.openAlert({title: "배우자 승계 공과금&채무액", msg: "상속받는 배우자가 승계한 공과금 및 채무금액를 입력하세요.", icon: "check"}); return;}
        }
        if (visible24) {
            if (Util.isEmpty(familyDedu)) {AppContext.openAlert({title: "가업상속공제", msg: "기업상속공제 금액을 입력하세요.", icon: "check"}); return;}
            if (Number(familyDedu) < 0  ) {AppContext.openAlert({title: "가업상속공제", msg: "기업상속공제 금액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible25) {
            if (Util.isEmpty(farmDedu)) {AppContext.openAlert({title: "영농상속공제", msg: "영농상속공제 금액을 입력하세요.", icon: "check"}); return;}
            if (Number(farmDedu) < 0  ) {AppContext.openAlert({title: "영농상속공제", msg: "영농상속공제 금액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible26) {
            if (Util.isEmpty(houseDedu)) {AppContext.openAlert({title: "동거주택상속공제", msg: "동거주택상속공제 금액을 입력하세요.", icon: "check"}); return;}
            if (Number(houseDedu) < 0  ) {AppContext.openAlert({title: "동거주택상속공제", msg: "동거주택상속공제 금액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible27) {
            if (Util.isEmpty(finanDedu)) {AppContext.openAlert({title: "금융상속공제", msg: "금융상속공제 금액을 입력하세요.", icon: "check"}); return;}
            if (Number(finanDedu) < 0  ) {AppContext.openAlert({title: "금융상속공제", msg: "금융상속공제 금액을 입력하세요.", icon: "check"}); return;}
        }

        // 입력한 사항을 배열로 생성하여 파라미터로 전달
        setInheTaxParam([
            ...inheTaxParam,     // 배열 전개(spread operator) 사용 배열 복사본으로 새배열을 생성 : push는 배열을 변이하는 메서드로 비추천
            {
                  taxFlag          : taxFlag                          // 00. 세금 구분 Flag
                , inherProp        : Util.uncomma(inherProp)          // 01. 상속재산가액  
                , estiInher        : Util.uncomma(estiInher)          // 02. 추정상속가액
                , trustAssets      : Util.uncomma(trustAssets)        // 03. 퇴직금/보험금/신탁자산
                , spouse           : spouse                           // 04. 배우자 유무
                , spousePreGift    : spousePreGift                    // 05. 배우자 사전증여 유무
                , spousePreGiftProp: Util.uncomma(spousePreGiftProp)  // 06. 배우자 사전증여 재산가액
                , spousePreGiftTax : Util.uncomma(spousePreGiftTax)   // 07. 배우자 사전증여세액
                , childNumber      : childNumber                      // 08. 자녀 수
                , preGift          : preGift                          // 09. 사전증여 유무
                , preGiftProp      : Util.uncomma(preGiftProp)        // 10. 사전증여 재산가액
                , preGiftTax       : Util.uncomma(preGiftTax)         // 11. 사전증여세액
                , debtAmt          : Util.uncomma(debtAmt)            // 12. 채무
                , utilityBill      : Util.uncomma(utilityBill)        // 13. 공과금
                , funeralExpen     : Util.uncomma(funeralExpen)       // 14. 장례비용
                , deduCls          : deduCls                          // 15. 공제구분
                , sumDedu          : Util.uncomma(sumDedu)            // 16. 일괄공제
                , basicDedu        : Util.uncomma(basicDedu)          // 17. 기초공제
                , personDedu       : Util.uncomma(personDedu)         // 18. 인적공제
                , spouseDisabled   : spouseDisabled                   // 19. 배우자 장애인 여부
                , spouseGender     : spouseGender                     // 20. 배우자 성별
                , spouseAge        : spouseAge                        // 21. 배우자 나이
                , spouseInherProp  : Util.uncomma(spouseInherProp)    // 22. 배우자 상속재산가액
                , spouseSucUtilDebt: Util.uncomma(spouseSucUtilDebt)  // 23. 배우자 승계 공과금&채무
                , familyDedu       : Util.uncomma(familyDedu)         // 24. 기업상속공제
                , farmDedu         : Util.uncomma(farmDedu)           // 25. 영농상속공제
                , houseDedu        : Util.uncomma(houseDedu)          // 26. 동거주택상속공제
                , finanDedu        : Util.uncomma(finanDedu)          // 27. 금융상속공제
                , directHeir       : directHeir                       // 22_0. 직계존속 상속인 유무    
            }
          ]);      
          
        setSimInheTaxCalcModalOpen(true);  
    }

    // 입력값 초기화
    const goReset = () => {
        // 표시 여부 초기화
        setVisible01(true );
        setVisible02(true );
        setVisible03(true );
        setVisible04(true );
        setVisible05(false);
        setVisible06(false);
        setVisible07(false);
        setVisible08(false);
        setVisible09(false);
        setVisible10(false);
        setVisible11(false);
        setVisible12(false);
        setVisible13(false);
        setVisible14(false);
        setVisible15(false);
        setVisible16(false);
        setVisible17(false);
        setVisible18(false);
        setVisible19(false);
        setVisible20(false);
        setVisible21(false);
        setVisible22(false);
        setVisible23(false);
        setVisible24(false);
        setVisible25(false);
        setVisible26(false);
        setVisible27(false);    
        setVisible22_0(false);    
        
        // 입력 값 초기화
        setInherProp        (""); 
        setEstiInher        (""); 
        setTrustAssets      (""); 
        setSpouse           (""); 
        setSpousePreGift    (""); 
        setSpousePreGiftProp(""); 
        setSpousePreGiftTax (""); 
        setChildNumber      (""); 
        setPreGift          (""); 
        setPreGiftProp      (""); 
        setPreGiftTax       (""); 
        setDebtAmt          (""); 
        setUtilityBill      (""); 
        setFuneralExpen     (""); 
        setDeduCls          (""); 
        // setSumDedu          (""); 
        // setBasicDedu        (""); 
        setPersonDedu       (""); 
        setSpouseDisabled   (""); 
        setSpouseGender     (""); 
        setSpouseAge        (""); 
        setSpouseInherProp  (""); 
        setSpouseSucUtilDebt(""); 
        setFamilyDedu       (""); 
        setFarmDedu         (""); 
        setHouseDedu        (""); 
        setFinanDedu        (""); 
        setDirectHeir       ("");

        // 고정된 값(수정불가) 초기화
        setDisabled04(false);
        setDisabled08(false);
        setDisabled15(false);
        setDisabled16(false);
        setDisabled17(false);
    }

    return (
        <div className='w1210 mh500'>
            {/************************** 왼쪽 부분 **************************/}
            <div className="w50p float-l pr10 bdBox pt20">
                {/* 01. 상속재산가액 */}
                <div className={(visible01 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>상속재산가액</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={inherProp}
                            onChange={(data: string) => setInherProp(data)}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div> 

                {/* 02. 추정상속가액 */}
                <div className={(visible02 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>추정상속가액</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={estiInher}
                            onChange={(data: string) => setEstiInher(data)}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div> 

                {/* 03. 퇴직금/보험금/신탁자산 */}
                <div className={(visible03 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>퇴직금/보험금/신탁자산</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={trustAssets}
                            onChange={(data: string) => setTrustAssets(data)}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div>  

                {/* 04. 배우자 유무 */}
                <div className={(visible04 ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt10'}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>배우자 유무</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={spouse}
                            codeArray={Util.getCodeArray("2031")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            disabled={disabled04}
                            border="1px solid #dddddd"
                            onChangeFunc={(data: string) => {
                                setSpouse(data);
                                if (data === "Y") {         // 배우자 있음 ▶ 05.배우자 사전증여 유무 
                                    setVisible05(true );
                                    setVisible08(false); // 08.자녀 수 미표시
                                    setChildNumber("" ); // 08.자녀 수 초기화
                                } else if (data === "N") {  // 배우자 없음 ▶ 08.자녀 수
                                    setVisible08(true  );
                                    setVisible05(false ); // 05.배우자 사전증여 유무 미표시
                                    setSpousePreGift(""); // 05.배우자 사전증여 유무 초기화
                                } else {}
                            }}
                        />
                    </div>
                </div>   

                {/* 05. 배우자 사전증여 유무 */}
                <div className={(visible05 ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt10'}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>배우자 사전증여 유무</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={spousePreGift}
                            codeArray={Util.getCodeArray("2031")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            // disabled={disabled05}
                            border="1px solid #dddddd"
                            onChangeFunc={(data: string) => {
                                setSpousePreGift(data);
                                setVisible08(true); // 08.자녀수
                                if (data === "Y") {         // 배우자 사전증여 유 ▶ 6.배우자 사전증여 재산가액, 7.배우자 사전증여세액, 08.자녀수, 12.채무금액, 13.공과금, 14.장례비용, 15.공제구분
                                    setVisible06(true);
                                    setVisible07(true);
                                    setDisabled04(true); // 04.배우자 유무 Fixed
                                } else if (data === "N") {  // 배우자 사전증여 무 ▶ 08.자녀수, 12.채무금액, 13.공과금, 14.장례비용, 15.공제구분
                                    setDisabled04(true     ); // 04.배우자 유무 Fixed
                                    setVisible06(false     ); // 6.배우자 사전증여 재산가액 미표시
                                    setSpousePreGiftProp(""); // 6.배우자 사전증여 재산가액 초기화
                                    setVisible07(false     ); // 7.배우자 사전증여세액 미표시
                                    setSpousePreGiftTax("" ); // 7.배우자 사전증여세액 초기화
                                } else {}
                            }}
                        />
                    </div>
                </div> 
                <span className={(visible05 ? "" : "dpNone-i") + " SpoqaHanSansNeo-Regular fs13 ls08 fc2C2C2C pl10"}>▲ 10년 이내 피상속인으로부터 받은 사전증여</span>

                {/* 06. 배우자 사전증여 재산가액 */}
                <div className={(visible06 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>배우자 사전증여 재산가액</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={spousePreGiftProp}
                            onChange={(data: string) => setSpousePreGiftProp(data)}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div> 

                {/* 07. 배우자 사전증여세액 */}
                <div className={(visible07 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>배우자 사전증여세액</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={spousePreGiftTax}
                            onChange={(data: string) => setSpousePreGiftTax(data)}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div> 

                {/* 08. 자녀 수 */}
                <div className={(visible08 ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt10'}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>자녀 수</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={childNumber}
                            onChange={(data: string) => {
                                setChildNumber(data);
                                setVisible12(true); // 12.채무금액
                                setVisible13(true); // 13.공과금
                                setVisible14(true); // 14.장례비용
                                setVisible15(true); // 15.공제구분                                            

                                if (spouse === "Y") {           // 배우자 유
                                    if (Util.isNotEmpty(data) && Number(data) === 0) {       // 자녀 없음 ▶ 15.공제구분 Fixed, 17.기초공제, 19.배우자 장애인 여부
                                        setVisible09(false); // 09.(자녀)사전증여 여부 미표시
                                        setPreGift  (""   ); // 09.(자녀)사전증여 여부 초기화

                                        // 배우자 유 > 자녀 무 : 15.공제구분 : 기초 + 인적 : Default
                                        setDeduCls("B");     // 15.공제구분 : 일괄공제
                                        setDisabled15(true); // 15.공제구분 Fixed
                                        setVisible17 (true); // 17.기초공제 표시
                                        setDisabled17(true); // 17.기초공제 Fixed
                                        setVisible19 (true); // 19.배우자 장애인 여부
                                    } else if (Number(data) > 0) {  // 자녀 있음 ▶ 09.(자녀)사전증여 유무
                                        setTitle09("자녀 사전증여 유무");
                                        setTitle10("자녀 사전증여 재산가액");
                                        setTitle11("자녀 사전증여세액");
                                        setVisible09(true);

                                        setDeduCls   (""    ); // 15.공제구분 초기화
                                        setDisabled15(false ); // 15.공제구분 Active
                                        setVisible17 (false ); // 17.기초공제 미표시
                                        setVisible19 (false ); // 19.배우자 장애인 여부 미표시
                                        setSpouseDisabled(""); // 19.배우자 장애인 여부 초기화
                                    } else {    // 입력 값이 없는 경우(isEmpty)
                                        setVisible09(false); // 09.(자녀)사전증여 여부 미표시
                                        setPreGift  (""   ); // 09.(자녀)사전증여 여부 초기화
                                        setDeduCls  (""   ); // 15.공제구분 초기화

                                        setVisible15(false  ); // 15.공제구분 미표시
                                        setDeduCls   (""    ); // 15.공제구분 초기화
                                        setDisabled15(false ); // 15.공제구분 Active
                                        setVisible17 (false ); // 17.기초공제 미표시
                                        setVisible19 (false ); // 19.배우자 장애인 여부 미표시
                                        setSpouseDisabled(""); // 19.배우자 장애인 여부 초기화
                                    }
                                } else if (spouse === "N") {    // 배우자 무
                                    if (Util.isNotEmpty(data) && Number(data) === 0) {       // 자녀 없음 ▶ 09.사전증여 유무
                                        setTitle09("사전증여 유무");
                                        setTitle10("사전증여 재산가액");
                                        setTitle11("사전증여세액");
                                        setVisible09(true);

                                        // 배우자 무 > 자녀 무 : 15.공제구분 > 일괄공제 : Default
                                        setDeduCls("A");
                                        setDisabled15(true);
                                        setVisible16 (true); // 16.일괄공제 표시
                                        setDisabled16(true); // 16.일괄공제 Fixed

                                        // 24.가입상속공제, 25.영농상속공제, 26.동거주택상속공제, 27.금융상속공제
                                        setVisible24(true);
                                        setVisible25(true);
                                        setVisible26(true);
                                        setVisible27(true);
                                    } else if (Number(data) > 0) {  // 자녀 있음 ▶ 09.(자녀)사전증여 유무
                                        setTitle09("자녀 사전증여 유무");
                                        setTitle10("자녀 사전증여 재산가액");
                                        setTitle11("자녀 사전증여세액");
                                        setVisible09(true);

                                        setDeduCls   (""    ); // 15.공제구분 초기화
                                        setDisabled15(false ); // 15.공제구분 Active
                                        setVisible16 (false ); // 16.일괄공제 미표시

                                        // 24.가입상속공제, 25.영농상속공제, 26.동거주택상속공제, 27.금융상속공제
                                        setVisible24(false); // 24.가입상속공제 미표시
                                        setFamilyDedu(""  ); // 24.가입상속공제 초기화 
                                        setVisible25(false); // 25.영농상속공제 미표시
                                        setFarmDedu(""    ); // 25.영농상속공제 초기화
                                        setVisible26(false); // 26.동거주택상속공제 미표시
                                        setHouseDedu(""   ); // 26.동거주택상속공제 초기화
                                        setVisible27(false); // 27.금융상속공제 미표시 
                                        setFinanDedu(""   ); // 27.금융상속공제 초기화                                         
                                    } else {    // 입력 값이 없는 경우(isEmpty)
                                        setVisible09(false); // 09.사전증여 유무 미표시
                                        setPreGift  (""   ); // 09.(자녀)사전증여 여부 초기화

                                        setVisible15(false  ); // 15.공제구분 미표시
                                        setDeduCls   (""    ); // 15.공제구분 초기화
                                        setDisabled15(false ); // 15.공제구분 Active
                                        setVisible16 (false ); // 16.일괄공제 미표시

                                        // 24.가입상속공제, 25.영농상속공제, 26.동거주택상속공제, 27.금융상속공제
                                        setVisible24(false); // 24.가입상속공제 미표시
                                        setFamilyDedu(""  ); // 24.가입상속공제 초기화 
                                        setVisible25(false); // 25.영농상속공제 미표시
                                        setFarmDedu(""    ); // 25.영농상속공제 초기화
                                        setVisible26(false); // 26.동거주택상속공제 미표시
                                        setHouseDedu(""   ); // 26.동거주택상속공제 초기화
                                        setVisible27(false); // 27.금융상속공제 미표시 
                                        setFinanDedu(""   ); // 27.금융상속공제 초기화                                    
                                    }
                                } else {}
                            }}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={2}
                            disabled={disabled08}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="명"
                        />
                    </div>
                </div> 

                {/* 09. (자녀)사전증여 유무 */}
                <div className={(visible09 ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt10'}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>{title09}</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={preGift}
                            codeArray={Util.getCodeArray("2031")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            // disabled={disabled01}
                            border="1px solid #dddddd"
                            onChangeFunc={(data: string) => {
                                setPreGift(data);

                                if (data === "Y") {         // 사전증여 유 ▶ 10.(자녀)사전증여 재산가액, 11.(자녀)사전증여세액
                                    setVisible10(true);
                                    setVisible11(true);
                                } else if (data === "N") {  // 사전증여 무
                                    setVisible10(false); // 10.(자녀)사전증여 재산가액 미표시
                                    setPreGiftProp("" ); // 10.(자녀)사전증여 재산가액 초기화
                                    setVisible11(false); // 11.(자녀)사전증여세액 미표시
                                    setPreGiftTax(""  ); // 11.(자녀)사전증여세액 초기화
                                } else {}
                            }}
                        />
                    </div>
                </div> 
                <span className={(visible09 ? "" : "dpNone-i") + " SpoqaHanSansNeo-Regular fs13 ls08 fc2C2C2C pl10"}>▲ 10년 이내 피상속인으로부터 받은 사전증여</span>

                {/* 10. (자녀)사전증여재산가액 */}
                <div className={(visible10 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>{title10}</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={preGiftProp}
                            onChange={(data: string) => setPreGiftProp(data)}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div> 

                {/* 11. (자녀)사전증여세액 */}
                <div className={(visible11 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>{title11}</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={preGiftTax}
                            onChange={(data: string) => setPreGiftTax(data)}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div> 

                {/* 12. 채무 */}
                <div className={(visible12 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt20 pb5"}>
                    <span className={(visible09 ? "" : "dpNone-i") + " SpoqaHanSansNeo-Regular fs15 ls08 fc17A840 pl10 fwB"}>◑ 상속세 공제금액</span>        
                </div>
                <div className={(visible12 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>채무</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={debtAmt}
                            onChange={(data: string) => setDebtAmt(data)}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div>                 

               {/* 13. 공과금 */}
               <div className={(visible13 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>공과금</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={utilityBill}
                            onChange={(data: string) => setUtilityBill(data)}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div>    

               {/* 14. 장례비용 */}
               <div className={(visible14 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>
                            <div className="dpFlx aiC jcC">장례비용
                                <img
                                    src={require('img/common/icon_search20_black.png')}
                                    alt="icon_info_fill_20" 
                                    className='csP pl3'
                                    data-tooltip-id="disabilityToolTip"
                                    data-tooltip-content={
                                        "「상속세 및 증여세법 시행령」 제9조제2항\n\n"
                                      + "장례비용은 증빙서류가 전혀 없더라도 최소 500만원은 공제받을 수 있습니다.\n"
                                      + "또한, 실제 장례에 든 금액이 아무리 많더라도 최대 1,000만원 까지만 공제받을 수 있습니다.\n"
                                      + "봉안시설 또는 자연장지를 사용하는 경우 500만원을 추가로 공제받을 수 있습니다."
                                    }
                                />
                                <ToolTip id="disabilityToolTip" place='left' clickable={true} className="w300 taL lh20" />
                            </div>
                        </span> 
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={funeralExpen}
                            onChange={(data: string) => setFuneralExpen(data)}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div>                   
            </div>

            {/************************** 오른쪽 부분 **************************/}
            <div className="w50p float-r pl10 bdBox pt20">
                {/* 15. 공제구분 */}
                <div className={(visible15 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>공제구분</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={deduCls}
                            codeArray={Util.getCodeArray("2032")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            disabled={disabled15}
                            border="1px solid #dddddd"
                            onChangeFunc={(data: string) => {
                                setDeduCls(data);

                                // 배우자 유 > 자녀 유 : 배우자 유 & 자녀 무 → 기초 & 인적 Default 적용
                                if (spouse === "Y" && Number(childNumber) > 0) {
                                    setDisabled04(true); // 04.배우자 유무 Fixed 
                                    setDisabled08(true); // 08.자녀 수 Fixed   
                                }

                                // 배우자 무 > 자녀 유 : 배우자 무 & 자녀 무 → 일괄공제 Default 적용
                                if (spouse === "N" && Number(childNumber) > 0) {
                                    setDisabled04(true); // 04.배우자 유무 Fixed 
                                    setDisabled08(true); // 08.자녀 수 Fixed   
                                }

                                if (data === "A") {         // 일괄공제 ▶ 16.일괄공제
                                    setVisible16 (true);
                                    setDisabled16(true);

                                    if (spouse === "Y") {   // 배우자 유 ▶ 22.배우자 상속재산가액
                                        setVisible22      (true);
                                        setSpouseInherProp(""  ); // 22.배우자 상속재산가액 초기화

                                        // 24.가입상속공제, 25.영농상속공제, 26.동거주택상속공제, 27.금융상속공제
                                        setVisible24(false); // 24.가입상속공제 미표시
                                        setFamilyDedu(""  ); // 24.가입상속공제 초기화 
                                        setVisible25(false); // 25.영농상속공제 미표시
                                        setFarmDedu(""    ); // 25.영농상속공제 초기화
                                        setVisible26(false); // 26.동거주택상속공제 미표시
                                        setHouseDedu(""   ); // 26.동거주택상속공제 초기화
                                        setVisible27(false); // 27.금융상속공제 미표시 
                                        setFinanDedu(""   ); // 27.금융상속공제 초기화                                         
                                    } else if (spouse === "N") {    // 배우자 무 ▶ 24.가입상속공제, 25.영농상속공제, 26.동거주택상속공제, 27.금융상속공제
                                        setVisible24(true);
                                        setVisible25(true);
                                        setVisible26(true);
                                        setVisible27(true);
                                    } else {}

                                    setVisible17(false); // 17.기초공제 미표시
                                    setVisible18(false); // 18.인적공제 초기화
                                    setPersonDedu(""  ); // 18.인적공제 미표시

                                } else if (data === "B") {  // 기초 + 인적공제 ▶ 17.기초공제, 18.인적공제
                                    setVisible17 (true);
                                    setDisabled17(true);
                                    setVisible18 (true);
                                    setVisible16 (false); // 16.일괄공제 미표시

                                    if (spouse === "Y") {   // 배우자 유
                                        setVisible22(false     ); // 22.배우자 상속재산가액 미표시 
                                        setSpouseInherProp(""  ); // 22.배우자 상속재산가액 초기화

                                        // 24.가입상속공제, 25.영농상속공제, 26.동거주택상속공제, 27.금융상속공제
                                        setVisible24(false); // 24.가입상속공제 미표시
                                        setFamilyDedu(""  ); // 24.가입상속공제 초기화 
                                        setVisible25(false); // 25.영농상속공제 미표시
                                        setFarmDedu(""    ); // 25.영농상속공제 초기화
                                        setVisible26(false); // 26.동거주택상속공제 미표시
                                        setHouseDedu(""   ); // 26.동거주택상속공제 초기화
                                        setVisible27(false); // 27.금융상속공제 미표시 
                                        setFinanDedu(""   ); // 27.금융상속공제 초기화                                       }
                                    } else if (spouse === "N") {    // 배우자 무
                                        // 24.가입상속공제, 25.영농상속공제, 26.동거주택상속공제, 27.금융상속공제
                                        setVisible24(false); // 24.가입상속공제 미표시
                                        setFamilyDedu(""  ); // 24.가입상속공제 초기화 
                                        setVisible25(false); // 25.영농상속공제 미표시
                                        setFarmDedu(""    ); // 25.영농상속공제 초기화
                                        setVisible26(false); // 26.동거주택상속공제 미표시
                                        setHouseDedu(""   ); // 26.동거주택상속공제 초기화
                                        setVisible27(false); // 27.금융상속공제 미표시 
                                        setFinanDedu(""   ); // 27.금융상속공제 초기화                                          
                                    } else {}
                                } else {}
                            }}
                        />
                    </div>
                </div> 

               {/* 16. 일괄공제 */}
               <div className={(visible16 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>일괄공제</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={sumDedu}
                            onChange={(data: string) => setSumDedu(data)}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            disabled={disabled16}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div>  

               {/* 17. 기초공제 */}
               <div className={(visible17 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>기초공제</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={basicDedu}
                            onChange={(data: string) => setBasicDedu(data)}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            disabled={disabled17}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div>  

               {/* 18. 인적공제 */}
               <div className={(visible18 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>인적공제</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={personDedu}
                            onChange={(data: string) => {
                                setPersonDedu(data);
                            }}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            disabled={true}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                    <div className='dpFlx aiC jcC ml20 bgcWhite'>
                        <div className="w60 h30 bgc17A840 bdrd40 dpFlx jcC aiC csP" onClick={() => {
                            setPersonalDeduModalOpen(true);
                        }}>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>계산</span>
                        </div>
                    </div>
                </div> 

                {/* 19. 배우자 장애인 여부 */}
                <div className={(visible19 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>배우자 장애인 여부</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={spouseDisabled}
                            codeArray={Util.getCodeArray("2030")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            // disabled={disabled01}
                            border="1px solid #dddddd"
                            onChangeFunc={(data: string) => {
                                setSpouseDisabled(data);

                                // 배우자 유 > 자녀 무 > 배우자 : 기초 + 인적공제 Fixed
                                if (spouse === "Y" && Util.isNotEmpty(childNumber) && Number(childNumber) === 0) {
                                    setDisabled08(true); // 08.자녀 수 Fixed
                                }     
                                
                                if (data === "Y") {     // 배우자 장애인 여 ▶ 20.배우자 성별, 21.배우자 나이
                                    setVisible20(true);
                                    setVisible21(true);
                                    
                                    // setVisible22(false   ); // 22.배우자 상속재산가액 미표시
                                    // setSpouseInherProp(""); // 22.배우자 상속재산가액 초기화 
                                    // setVisible22_0(false ); // 22_1.직계존속 상속인 유무 미표시
                                    // setDirectHeir(""     ); // 22_1.직계존속 상속인 유무 초기화 
                                } else if (data === "N") {  // 배우자 장애인 부 ▶ 22.배우자 상속재산가액, 22_1.직계존속 상속인 유무
                                    // setVisible22(true);
                                    setVisible22_0(true);

                                    setVisible20(false); // 20.배우자 성별 미표시
                                    setSpouseGender(""); // 20.배우자 성별 초기화
                                    setVisible21(false); // 21.배우자 나이 미표시
                                    setSpouseAge(""   ); // 21.배우자 나이 초기화
                                } else {}
                            }}
                        />
                    </div>
                </div> 

                {/* 20. 배우자 성별 */}
                <div className={(visible20 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>배우자 성별</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                            value={spouseGender}
                            codeArray={Util.getCodeArray("2033")}
                            placeholder="선택해주세요"
                            width={300}
                            height={40}
                            fontSize={15}
                            paddingRight={5}
                            // disabled={disabled01}
                            border="1px solid #dddddd"
                            onChangeFunc={(data: string) => {
                                setSpouseGender(data);
                            }}
                        />
                    </div>
                </div> 

                {/* 21. 배우자 나이 */}
                <div className={(visible21 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>나이</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={spouseAge}
                            onChange={(data: string) => {
                                setSpouseAge(data);

                                if (Util.isNotEmpty(data)) {    // 나이 입력 ▶ 22_1.직계존속 상속인 유무
                                    setVisible22_0(true);
                                }
                            }}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={2}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="세"
                        />
                    </div>
                </div> 

                {/* 22_0. 직계존속 상속인 유무 */}
                <div className={(visible22_0 ? "" : "dpNone-i") + " w80p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>직계존속 상속인 유무</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <SelectBox
                                value={directHeir}
                                codeArray={Util.getCodeArray("2039")}
                                placeholder="선택해주세요"
                                width={300}
                                height={40}
                                fontSize={15}
                                paddingRight={5}
                                // disabled={disabled01}
                                border="1px solid #dddddd"
                                onChangeFunc={(data: string) => {
                                    setDirectHeir(data);
                                    setVisible22(true);  // 직계존속 상속인 선택 ▶ 22.배우자 상속재산가액
                                }}
                            />                        
                    </div>
                </div>  

                {/* 22. 배우자 상속재산가액 */}
                <div className={(visible22 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>배우자 상속재산가액</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={spouseInherProp}
                            onChange={(data: string) => {
                                setSpouseInherProp(data);
                                if (Util.isNotEmpty(data) && Number(Util.uncomma(data)) >= 500000000) {    // 배우자 상속재산가액 5억 초과 ▶ 23.배우자 승계 공과금 & 채무, 24.가입상속공제, 25.영농상속공제, 26.동거주택상속공제, 27.금융상속공제
                                    setVisible23(true);
                                    setVisible24(true);
                                    setVisible25(true);
                                    setVisible26(true);
                                    setVisible27(true);                                    
                                } else {                                                                   // 배우자 상속재산가액 5억 미만 ▶ 24.가입상속공제, 25.영농상속공제, 26.동거주택상속공제, 27.금융상속공제
                                    setVisible23(false     ); // 23.배우자 승계 공과금 & 채무 미표시
                                    setSpouseSucUtilDebt(""); // 23.배우자 승계 공과금 & 채무 초기화
                                    setVisible24(true);
                                    setVisible25(true);
                                    setVisible26(true);
                                    setVisible27(true);                                    
                                }
                            }}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div> 
                <span className={(visible22 ? "" : "dpNone-i") + " SpoqaHanSansNeo-Regular fs13 ls08 fc2C2C2C pl10"}>▲ 사전증여재산가액 및 추정상속재산가액 제외</span>

                {/* 23. 배우자 승계 공과금&채무 */}
                <div className={(visible23 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>배우자 승계 공과금&채무</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={spouseSucUtilDebt}
                            onChange={(data: string) => {
                                setSpouseSucUtilDebt(data);

                                if (Util.isNotEmpty(data)) {    // 공과금&채무 입력 ▶ 24.가입상속공제, 25.영농상속공제, 26.동거주택상속공제, 27.금융상속공제
                                    setVisible24(true);
                                    setVisible25(true);
                                    setVisible26(true);
                                    setVisible27(true);
                                }
                            }}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div>

                {/* 24. 가업상속공제 */}
                <div className={(visible24 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>가업상속공제</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={familyDedu}
                            onChange={(data: string) => setFamilyDedu(data)}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div>

                {/* 25. 영농상속공제 */}
                <div className={(visible25 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>영농상속공제</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={farmDedu}
                            onChange={(data: string) => setFarmDedu(data)}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div>

                {/* 26. 동거주택상속공제 */}
                <div className={(visible26 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>동거주택상속공제</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={houseDedu}
                            onChange={(data: string) => setHouseDedu(data)}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div>

                {/* 27. 금융상속공제 */}
                <div className={(visible27 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w153 h40 bgc17A840 bdrd4 dpFlx jcC aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>금융상속공제</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={finanDedu}
                            onChange={(data: string) => setFinanDedu(data)}
                            borderClass="bgcWhite w300 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            disabled={true}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                    <div className='dpFlx aiC jcC ml20 bgcWhite'>
                        <div className="w60 h30 bgc17A840 bdrd40 dpFlx jcC aiC csP" onClick={() => {
                            setFinanInheDeduModalOpen(true);
                        }}>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fcWhite'>계산</span>
                        </div>
                    </div>                    
                </div>
            </div>

            <div className='w100p dpFlx aiC jcC pt40 pb40'>
                <button className='w360 h60 bgc17A840 dpFlx aiC jcC csP bdN' onClick={() => callTax("I")}>
                    <span className='SpoqaHanSansNeo-Medium fs18 ls07 fcWhite'>계산하기</span>
                </button>
                <button className='w100 h60 bgcF6F6F6 dpFlx aiC jcC csP bd1 bdc878787' onClick={() => goReset()}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>초기화</span>
                </button>
            </div>        

            {/* <Modal modalOpen={personDeduCalModalOpen} setModalOpen={setPersonDeduCalModalOpen} title="인적공제 계산방법" dim={true} visible={true}>
                <PersonDeduCal />
            </Modal> */}

            {/* 인적공제 계산 모달 */}
            <Modal modalOpen={personalDeduModalOpen} setModalOpen={setPersonalDeduModalOpen} title="인적공제 계산" dim={true} visible={true}>
                <InhePersonalDedu setPersonalDeduModalOpen={setPersonalDeduModalOpen} childNumber={childNumber} callBack={onFinishCalPerson} />
            </Modal>

            {/* 금융상속공제 계산 모달 */}
            <Modal modalOpen={finanInheDeduModalOpen} setModalOpen={setFinanInheDeduModalOpen} title="금융상속공제 계산" dim={true} visible={true}>
                <InheFinanInheDedu setFinanInheDeduModalOpen={setFinanInheDeduModalOpen} callBack={onFinishCalFin} />
            </Modal>  

            <Modal modalOpen={simInheTaxCalcModalOpen} setModalOpen={setSimInheTaxCalcModalOpen} title="상속세 세액 산출" dim={true} visible={simInheTaxCalcModalVsb} closeCallBack={() => setSimInheTaxCalcModalVsb(false)}>
                <SimInheTaxCalc setSimInheTaxCalcModalOpen={setSimInheTaxCalcModalOpen} setSimInheTaxCalcModalVsb={setSimInheTaxCalcModalVsb} inheTaxParam={inheTaxParam} />
            </Modal>                  
        </div>
    )
}
export default SimInheTax;