/*******************************************************************************
 *** DirectAdressIn ** 주소 직접 입력
 *******************************************************************************/
import React from "react";
import Axios from "js/common/Axios";
import Util from "js/common/Util";
import AppContext from "js/common/AppContext";
import ImageRadioBox from "js/common/ui/input/ImageRadioBox";
import NumberTextBox from "../ui/input/NumberTextBox";
import SelectBox, { codeProps } from "../ui/input/SelectBox";
import TextBox from "../ui/input/TextBox";

interface DirectAdressInProps {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  callBack: Function;
  taxFlag: string;
}

const DirectAdressIn = ({
  callBack,
  setModalOpen,
  taxFlag,
}: DirectAdressInProps) => {
  const [pblntfPc, setPblntfPc] = React.useState<string>(""); // 공시가격
  const [prvuseAr, setPrvuseAr] = React.useState<string>(""); // 면적정보(공동주택: 전용면적, 단독주택: 주택연면적)
  const [spfcKnd, setSpfcKnd] = React.useState<string>(""); // 용도지역
  const [mdatTrgetAreaAt, setMdatTrgetAreaAt] = React.useState<string>(""); // 조정지역여부
  const [shareRatio, setShareRatio] = React.useState<string>(""); // 지분율

  // 주소 직접 입력
  const [sidoCode, setSidoCode] = React.useState<string>(""); // 시도정보
  const [sggCode, setSggCode] = React.useState<string>(""); // 시군구정보
  const [sggCodeElVsb, setSggCodeElVsb] = React.useState<boolean>(true); // 시군구정보 표시여부
  const [sggCodeInfoList, setSggCodeInfoList] = React.useState<codeProps[]>([]); // 시군구정보 배열
  const [emdCode, setEmdCode] = React.useState<string>(""); // 읍면동 정보
  const [emdCodeInfoList, setEmdCodeInfoList] = React.useState<codeProps[]>([]); // 읍면동정보 배열

  const [sidoName, setSidoName] = React.useState<string>(""); // 시도
  const [sggName, setSggName] = React.useState<string>(""); // 시군구
  const [emdName, setEmdName] = React.useState<string>(""); // 읍면동
  const [rdnmAdresDetail, setRdnmAdresDetail] = React.useState<string>(""); // 상세주소

  // 완료버튼(자산 정보 셋팅)
  const goFinish = () => {
    if (Util.isEmpty(sidoCode)) {
      AppContext.openAlert({
        title: "시/도 선택",
        msg: "시/도 구분을 선택하세요.",
        icon: "check",
      });
      return;
    }

    if (Util.isEmpty(sggCode)) {
      AppContext.openAlert({
        title: "시군구 선택",
        msg: "시군구 구분을 선택하세요.",
        icon: "check",
      });
      return;
    }

    if (Util.isEmpty(emdCode)) {
      AppContext.openAlert({
        title: "읍면동 선택",
        msg: "읍면동 구분을 선택하세요.",
        icon: "check",
      });
      return;
    }

    if (Util.isEmpty(rdnmAdresDetail)) {
      AppContext.openAlert({
        title: "상세주소 입력",
        msg: "자산의 상세주소를 입력하세요.",
        icon: "check",
      });
      return;
    }

    // 재산세/종합부동산세 자산 검색 시 확인
    if (taxFlag === "P") {
      if (Util.isEmpty(pblntfPc)) {
        AppContext.openAlert({
          title: "공시가격",
          msg: "자산의 공시가격을 입력하세요.",
          icon: "check",
        });
        return;
      }

      if (Number(pblntfPc) <= 0) {
        AppContext.openAlert({
          title: "공시가격",
          msg: "자산의 공시가격을 입력하세요.",
          icon: "check",
        });
        return;
      }

      if (Util.isEmpty(spfcKnd)) {
        AppContext.openAlert({
          title: "용도지역",
          msg: "용도지역을 선택하세요.",
          icon: "check",
        });
        return;
      }
    }

    // 취득세 주소검색 입력사항 확인
    if (taxFlag === "A") {
      if (Util.isEmpty(pblntfPc)) {
        AppContext.openAlert({
          title: "공시가격",
          msg: "취득할 자산의 공시가격을 입력하세요.",
          icon: "check",
        });
        return;
      }

      if (Number(pblntfPc) <= 0) {
        AppContext.openAlert({
          title: "공시가격",
          msg: "취득할 자산의 공시가격을 입력하세요.",
          icon: "check",
        });
        return;
      }

      if (Util.isEmpty(prvuseAr)) {
        AppContext.openAlert({
          title: "면적",
          msg: "취득할 자산의 면적정보를 입력하세요.",
          icon: "check",
        });
        return;
      }

      if (Number(prvuseAr) <= 0) {
        AppContext.openAlert({
          title: "면적",
          msg: "취득할 자산의 면적정보를 입력하세요.",
          icon: "check",
        });
        return;
      }
    }

    if (Util.isEmpty(mdatTrgetAreaAt)) {
      AppContext.openAlert({
        title: "조정대상지역",
        msg: "조정대상지역을 선택하세요.",
        icon: "check",
      });
      return;
    }

    if (Util.isEmpty(shareRatio)) {
      AppContext.openAlert({
        title: "지분율",
        msg: "지분율을 입력하세요.",
        icon: "check",
      });
      return;
    }

    if (Number(shareRatio) <= 0 || Number(Util.uncomma(shareRatio)) > 100) {
      AppContext.openAlert({
        title: "지분율",
        msg: "지분율을 1~100으로 입력하세요.",
        icon: "check",
      });
      return;
    }

    const retParam = {
      bdKnd: "1", // 공동주택 여부(Defalut 1로 설정)
      rdnmAdres: sidoName + " " + sggName + " " + emdName, // 주소
      rdnmAdresDetail: rdnmAdresDetail, // 상세주소
      pnu: sidoCode + sggCode + emdCode, // 토지고유코드
      spfcKnd: spfcKnd, // 용도지역
      mdatTrgetAreaAt: mdatTrgetAreaAt, // 조정대상지역
      pblntfPc: pblntfPc, // 공동주택 가격
      prvuseAr: prvuseAr, // 공동주택 전용면적
      shareRatio: shareRatio, // 지분율
      //    , apprDateNewHse: apprDateNewHse                  // 사용승인일
      legaldongCode: emdCode, // 법정동코드
    };
    callBack(retParam);
    setModalOpen(false);
  };

  return (
    <React.Fragment>
      <div className="dpFlx aiC">
        <div className="h40 dpFlx aiC">
          <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">
            주소
          </span>
        </div>
        <div className="dpFlx h40">
          <div className="bgcWhite dpFlx mr5">
            <SelectBox
              value={sidoCode}
              codeArray={AppContext.sidoCodeInfoList}
              placeholder="시/도 선택"
              width={180}
              height={40}
              fontSize={14}
              paddingRight={5}
              onChangeCodeName={(data: string, codeName: string) => {
                setSidoCode(data);
                setSidoName(codeName);
                AppContext.showSpinner();
                Axios.dataAccess({
                  url: "common/getSggCodeInfoList.do",
                  methodType: "post",
                  paramType: "object",
                  paramData: {
                    sidoCode: data,
                  },
                  onSuccessFn: (res: any) => {
                    setSggCodeInfoList(res.list);
                    setEmdCodeInfoList([]);
                    if (res.list.length === 1) {
                      setSggCode(res.list[0].code);
                      setSggCodeElVsb(false);
                      Axios.dataAccess({
                        url: "common/getEmdCodeInfoList.do",
                        methodType: "post",
                        paramType: "object",
                        paramData: {
                          sggCode: res.list[0].code,
                        },
                        onSuccessFn: (res: any) => {
                          setEmdCodeInfoList(res.list);
                          setEmdCode("");
                          AppContext.hideSpinner();
                        },
                      });
                    } else {
                      setSggCodeElVsb(true);
                      setSggCode("");
                      setEmdCode("");
                      AppContext.hideSpinner();
                    }
                  },
                });
              }}
              border="1px solid #dddddd"
            />
          </div>
          <div
            className={"bgcWhite mr5 " + (sggCodeElVsb ? "dpFlx" : "dpNone")}
          >
            <SelectBox
              value={sggCode}
              codeArray={sggCodeInfoList}
              placeholder="시군구 선택"
              width={180}
              height={40}
              fontSize={14}
              paddingRight={5}
              onChangeCodeName={(data: string, codeName: string) => {
                setSggCode(data);
                setSggName(codeName);
                AppContext.showSpinner();
                Axios.dataAccess({
                  url: "common/getEmdCodeInfoList.do",
                  methodType: "post",
                  paramType: "object",
                  paramData: {
                    sggCode: data,
                  },
                  onSuccessFn: (res: any) => {
                    setEmdCodeInfoList(res.list);
                    setEmdCode("");
                    AppContext.hideSpinner();
                  },
                });
              }}
              border="1px solid #dddddd"
            />
          </div>
          <div className="bgcWhite dpFlx mr5">
            <SelectBox
              value={emdCode}
              codeArray={emdCodeInfoList}
              placeholder="읍면동 선택"
              width={180}
              height={40}
              fontSize={14}
              paddingRight={5}
              onChangeCodeName={(data: string, codeName: string) => {
                setEmdCode(data);
                setEmdName(codeName);
                Axios.dataAccess({
                  url: "system/assets/chkAjstRgnInfo.do",
                  methodType: "post",
                  paramType: "object",
                  paramData: {
                    pnu: data,
                  },
                  onSuccessFn: (res: any) => {
                    if (res.item === 0) {
                      setMdatTrgetAreaAt("N");
                    } else {
                      AppContext.openAlert({
                        title: "조정대상지역 자동선택",
                        msg: "해당 주소가 조정대상지역으로 판별되었습니다.",
                        icon: "check",
                      });
                      setMdatTrgetAreaAt("Y");
                    }
                    AppContext.hideSpinner();
                  },
                });
              }}
              border="1px solid #dddddd"
            />
          </div>
        </div>
      </div>

      <div className="h40 dpFlx aiC mb10">
        <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222"></span>
        <TextBox
          className="bd1 bgcWhite mt5"
          inputClassName="pl10 pr10"
          value={rdnmAdresDetail}
          width={550}
          height={40}
          fontSize={14}
          maxLength={500}
          placeholder="상세주소를 입력할 수 있습니다."
          onChangeFunc={(text: string) => setRdnmAdresDetail(text)}
        />
      </div>

      <div
        className={
          (taxFlag === "P" || taxFlag === "A" ? "" : "dpNone-i") +
          " h40 dpFlx aiC mb10"
        }
      >
        <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">
          공시가격(원)
        </span>
        <NumberTextBox
          value={pblntfPc}
          onChange={(data: string) => setPblntfPc(data)}
          borderClass="bgcWhite w300 h40 pr15 bd1"
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          // placeholder="공동주택가격을 입력해주세요."
          maxLength={15}
          inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
          unitText="원"
        />
      </div>

      <div className={(taxFlag === "P" ? "" : "dpNone-i") + " dpFlx pt5"}>
        <div className="dpFlx aiC">
          <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">
            용도지역
          </span>
        </div>
        <div className="dpFlx aiB">
          <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc222222 pl10 w60">
            도시지역
          </span>
          <div className="dpFlx aiC aiB">
            <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc222222 mr2">
              (
            </span>
            <div
              className="dpFlx aiC csP mr2 w100 pl10"
              onClick={() => {
                setSpfcKnd("1001");
              }}
            >
              <img
                className="w24 h24 pr5"
                src={require("img/common/radiobutton_base_" +
                  (spfcKnd === "1001" ? "on" : "off") +
                  ".png")}
                alt="radiobutton_base"
              />
              <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">
                주거지역
              </span>
            </div>
            <div
              className="dpFlx aiC csP mr2 w100"
              onClick={() => {
                setSpfcKnd("1002");
              }}
            >
              <img
                className="w24 h24 pr5"
                src={require("img/common/radiobutton_base_" +
                  (spfcKnd === "1002" ? "on" : "off") +
                  ".png")}
                alt="radiobutton_base"
              />
              <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">
                상업지역
              </span>
            </div>
            <div
              className="dpFlx aiC csP mr2 w100"
              onClick={() => {
                setSpfcKnd("1003");
              }}
            >
              <img
                className="w24 h24 pr5"
                src={require("img/common/radiobutton_base_" +
                  (spfcKnd === "1003" ? "on" : "off") +
                  ".png")}
                alt="radiobutton_base"
              />
              <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">
                공업지역
              </span>
            </div>
            <div
              className="dpFlx aiC csP w100"
              onClick={() => {
                setSpfcKnd("1004");
              }}
            >
              <img
                className="w24 h24 pr5"
                src={require("img/common/radiobutton_base_" +
                  (spfcKnd === "1004" ? "on" : "off") +
                  ".png")}
                alt="radiobutton_base"
              />
              <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">
                녹지지역
              </span>
            </div>
            <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc222222 ml2">
              )
            </span>
          </div>
        </div>
      </div>

      <div className={(taxFlag === "P" ? "" : "dpNone-i") + " dpFlx mb10"}>
        <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222"></span>
        <div
          className="dpFlx aiC csP mr10 w100"
          onClick={() => {
            setSpfcKnd("1005");
          }}
        >
          <img
            className="w24 h24 pr5 pl5"
            src={require("img/common/radiobutton_base_" +
              (spfcKnd === "1005" ? "on" : "off") +
              ".png")}
            alt="radiobutton_base"
          />
          <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">
            관리지역
          </span>
        </div>
        <div
          className="dpFlx aiC csP mr10 w100"
          onClick={() => {
            setSpfcKnd("1006");
          }}
        >
          <img
            className="w24 h24 pr5"
            src={require("img/common/radiobutton_base_" +
              (spfcKnd === "1006" ? "on" : "off") +
              ".png")}
            alt="radiobutton_base"
          />
          <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">
            농림지역
          </span>
        </div>
        <div
          className="dpFlx aiC csP mr10 w150"
          onClick={() => {
            setSpfcKnd("1007");
          }}
        >
          <img
            className="w24 h24 pr5"
            src={require("img/common/radiobutton_base_" +
              (spfcKnd === "1007" ? "on" : "off") +
              ".png")}
            alt="radiobutton_base"
          />
          <span className="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20">
            자연환경보전지역
          </span>
        </div>
      </div>

      <div
        className={
          taxFlag === "P" || taxFlag === "T"
            ? "dpNone-i"
            : " h40 dpFlx aiC mb10"
        }
      >
        <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">
          면적
        </span>
        <NumberTextBox
          value={prvuseAr}
          onChange={(data: string) => setPrvuseAr(data)}
          borderClass="bgcWhite w300 h40 pr15 bd1"
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          // placeholder="전용면적을 입력해주세요."
          maxLength={10}
          inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
          unitText="㎡"
        />
        <div className="dpFlx aiC jcC ml10">
          <span className="SpoqaHanSansNeo-Regular fs15 ls08 fc666666">
            ◀ 공동주택(전용면적) / 단독주택(주택연면적)
          </span>
        </div>
      </div>

      <div className="dpFlx h40 aiC mb10">
        <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">
          조정대상지역
        </span>
        <ImageRadioBox
          codeList={Util.getCodeArray("1036")}
          value={mdatTrgetAreaAt}
          setValue={setMdatTrgetAreaAt}
          btnClass="w24 h24 pr5"
          disabled={true}
          labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
          interval={20}
        />
      </div>
      <div className="dpFlx h40 aiC">
        <span className="SpoqaHanSansNeo-Medium w120 fs14 ls07 fc222222">
          지분율(%)
        </span>
        <div className="dpFlx aiC jcC bgcWhite">
          <NumberTextBox
            value={shareRatio}
            onChange={(data: string) => setShareRatio(data)}
            borderClass="bgcWhite w300 h40 pr15 bd1"
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            maxLength={3}
            // placeholder="지분율을 1~100 값으로 입력하세요."
            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
            unitText="%"
          />
        </div>
      </div>

      <div className="w100p dpFlx aiC jcC pt40">
        <div
          className="w210 h56 bgcF6F6F6 dpFlx aiC jcC csP"
          onClick={() => setModalOpen(false)}
        >
          <span className="SpoqaHanSansNeo-Medium fs16 ls07 fc222222">
            닫기
          </span>
        </div>
        <div
          className="w210 h56 bgc17A840 dpFlx aiC jcC csP"
          onClick={goFinish}
        >
          <span className="SpoqaHanSansNeo-Medium fs16 ls07 fcWhite">완료</span>
        </div>
      </div>
    </React.Fragment>
  );
};
export default DirectAdressIn;
