/*******************************************************************************
 *** MypageSubId ** MY Page > 서브 아이디
*******************************************************************************/
// import React from "react";
// import MuiListFilter from "js/common/ui/list/MuiListFilter";
// import ForestTableStyle from 'js/common/ui/style/ForestStyle';
// import Modal from 'js/common/ui/modal/Modal';
// import SubIdDialog from "./dialog/SubIdDialog";
// import AppContext from "js/common/AppContext";
// import Axios from "js/common/Axios";
// import IconButton from "js/common/ui/button/IconButton";

const MypageSubId = () => {
/*
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [callMode, setCallMode] = React.useState<string>();
    const [dataArr, setDataArr] = React.useState<any[]>([]);
    const [subIdSelTime, setSubIdSelTime] = React.useState<Date>(new Date());
    const [subUserInfo, setSubUserInfo] = React.useState<any>();

    const delSubIdInfo = (userSn: number) => {
        AppContext.openAlert({
            title: "서브아이디 삭제",
            msg: "서브아이디 정보를 삭제하시겠습니까?",
            icon: "check",
            confirmText: "삭제",
            handleConfirm: () => {
                AppContext.showSpinner();
                Axios.dataAccess({
                    url: "system/mypage/delSubIdInfo.do",
                    methodType: "post",
                    paramType: "object",
                    paramData: {
                        userSn: userSn
                    },
                    onSuccessFn: () => {
                        setSubIdSelTime(new Date());
                        AppContext.openAlert({
                            title: "서브아이디 삭제",
                            msg: "서브아이디가 삭제되었습니다.",
                            icon: "check"
                        });
                        AppContext.hideSpinner();
                    }
                });
            }
        });
    }

    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/mypage/selSubIdList.do",
            methodType: "post",
            paramType: "object",
            onSuccessFn: (res: any) => {
                setDataArr(res.list);
                AppContext.hideSpinner();
            }
        });
    }, [subIdSelTime]);
*/
    return (

        <div className="bgcF6F6F6 pt60 pb100">
            <div className="bgcWhite w1210 h235 m0auto dpFlx aiC jcC">
                <span className="SpoqaHanSansNeo-Medium fs24 fc222222 fw500">Contents Area</span>
            </div>
        </div>

/*
        <div className="bgcF6F6F6 pt60 pb100">
            <div className="w1160 m0auto">
                <div className='h36 dpFlx aiC jcSb mb20'>
                    <div className="dpFlx aiE">
                        <img className="mr10" src={require('img/common/icon_step.png')} alt="icon_step" />
                        <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 pb3'>서브아이디 관리</span>
                    </div>
                    <IconButton
                        btnName="서브아이디 추가하기"
                        iconPos="right"
                        iconNm="add"
                        className="h28 bd1 bdc17A840 bgcWhite bdrd40 pl12 pr12"
                        textClassName="fs12 fc17A840 SpoqaHanSansNeo-Medium fw500 ls06 lh18"
                        onClick={() => {
                            setCallMode("reg");
                            setModalOpen(true);
                        }}
                    />
                </div>
                <MuiListFilter tableInfo={{
                    tableHeadVsb: true,
                    headerVsb: true,
                    styleType: ForestTableStyle,
                    fldInfs: [
                        { fldNm: 'userSn', pk: true },
                        { type: 'text', fldNm: 'rowNum', width: 65, headAlign: "center", dataAlign: "center", label: 'No.' },
                        { type: 'text', fldNm: 'emailAdres', wAuto: true, headAlign: "center", dataAlign: "center", label: '이메일주소'},
                        { type: 'text', fldNm: 'mbtlnum', width: 120, headAlign: "center", dataAlign: "center", label: '휴대폰번호'},
                        { type: 'text', fldNm: 'userNm', width: 120, headAlign: "center", dataAlign: "center", label: '이름'},
                        { type: 'text', fldNm: 'maxLogDt', width: 200, headAlign: "center", dataAlign: "center", label: '최근접속일시'},
                        { type: 'el', width: 74, headAlign: "center", el: (item: any) => (
                            <div className='dpFlx aiC csP' onClick={() => {
                                setSubUserInfo(item);
                                setCallMode("mod");
                                setModalOpen(true);
                            }}>
                                <img className="csP pr3" src={require('img/common/icon_edit20.png')} alt="icon_edit20" />
                                <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">수정</span>
                            </div>
                        )},
                        { type: 'el', width: 74, headAlign: "center", el: (item: any) => (
                            <div className='dpFlx aiC csP' onClick={() => delSubIdInfo(item.userSn)}>
                                <img className="csP pr3" src={require('img/common/icon_delete20.png')} alt="icon_delete20" />
                                <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">삭제</span>
                            </div>
                        )}
                    ],
                    ordFld: { name: "rowNum", direction: "asc" },
                    dataArr: dataArr,
                    maxRowNums: [10, 20, 30],
                    trHeight: 48,
                    remainArea: false
                }} />
            </div>
            <Modal modalOpen={modalOpen} setModalOpen={setModalOpen} title={"서브아이디 " + (callMode === "reg" ? "추가" : "수정")} dim={true} visible={true}>
                <SubIdDialog setModalOpen={setModalOpen} setSubIdSelTime={setSubIdSelTime} callMode={callMode} subUserInfo={subUserInfo} />
            </Modal>
        </div>
*/
    )
}
export default MypageSubId;