/*******************************************************************************
 *** Body ** Body
 *******************************************************************************/
import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import * as Atom from "js/common/Atom";
import Util from "js/common/Util";
import Intrcn from "js/portal/Intrcn";
import Login from "js/portal/Login";
import Join from "js/portal/Join";
import Cowork from "js/portal/Cowork";
import Hshld from "js/system/hshld/Hshld";
import Mypage from "js/system/mypage/Mypage";
import Assets from "js/system/assets/Assets";
import Acqstax from "js/system/acqstax/Acqstax";
import Regfee from "js/system/regfee/Regfee";
import Proptax from "js/system/proptax/Proptax";
import Comptax from "js/system/comptax/Comptax";
import Trsftax from "js/system/trsftax/Trsftax";
import Gifttax from "js/system/gifttax/Gifttax";
import Inhetax from "js/system/inhetax/Inhetax";
import UserMng from "js/admin/user/UserMng";
import SysMng from "js/admin/system/LawordMng";
import AssetsEdit from "js/system/assets/AssetsEdit";
import AssetsLawTest from "js/system/assets/AssetsLawTest";
import AssetsView from "js/system/assets/AssetsView";
import MypageEdit from "js/system/mypage/MypageEdit";
import BoardWrite from "js/system/board/BoardWrite";
import Board from "js/system/board/Board";
import BoardView from "js/system/board/BoardView";
import TrsftaxTest from "js/system/trsftax/TrsftaxTest";
import Header from "./Header";
import Footer from "./Footer";
import TokenCheck from "./TokenCheck";
import Index from "js/portal/Index";
import Intro from "js/portal/Intro";
import Welcome from "js/portal/Welcome";
import PersonalInfo from "js/portal/help/PersonalInfo";
import Term from "js/portal/help/Term";
import Wecake from "js/portal/Wecake";
import FindId from "js/portal/FindId";
import FindIdResult from "js/portal/FindIdResult";
import FindPasswd from "js/portal/FindPasswd";
import InNewPasswd from "js/portal/InNewPasswd";
import ChangePwResult from "js/portal/ChangePwResult";
import SimpleCallTax from "js/system/simpletax/SimpleCallTax";
import MobileCalcMenu from "js/mobile/Menu";
import MobileAcqsTaxCalc from "js/mobile/AcqsTaxCalc";
import MobileAcqsTaxCalcResult from "js/mobile/AcqsTaxCalcResult";
import MobileTranTaxCalc from "js/mobile/TranTaxCalc";
import MobilePropTaxCalc from "js/mobile/PropTaxCalc";
import MobileGiftTaxCalc from "js/mobile/GiftTaxCalc";
import MobileInheTaxCalc from "js/mobile/InheTaxCalc";
import { MobileThemeProvider } from "js/mobile/common/MobileThemeProvider";

function Body() {
  const location = useLocation();
  const isAdmin = useRecoilValue(Atom.isAdmin);
  const userInfo = useRecoilValue(Atom.userInfo);
  const menuList = [
    { type: "main", url: "/portal/intrcn", el: <Intrcn /> }, // 서비스소개
    { type: "main", url: "/portal/index", el: <Index /> }, // INDEX
    { type: "main", url: "/portal/login", el: <Login /> }, // 로그인
    { type: "main", url: "/portal/join", el: <Join /> }, // 회원가입
    { type: "main", url: "/portal/welcome/:userNm/:userId", el: <Welcome /> }, // 회원가입 완료
    { type: "main", url: "/portal/cowork", el: <Cowork /> }, // 협력업체
    { type: "main", url: "/portal/notice", el: <Board boardTy="1001" /> }, // 공지사항
    {
      type: "main",
      url: "/portal/notice/view/:boardSn",
      el: <BoardView boardTy="1001" />,
    }, // 공지사항조회
    { type: "main", url: "/portal/personalinfo", el: <PersonalInfo /> }, // 회원가입
    { type: "main", url: "/portal/term", el: <Term /> }, // 회원가입
    {
      type: "main",
      url: "/portal/wecake/:wecakeUserId/:authStr",
      el: <Wecake />,
    }, // Wecake Link
    { type: "main", url: "/portal/findId", el: <FindId /> }, // 아이디 찾기
    { type: "main", url: "/portal/findId/result", el: <FindIdResult /> }, // 아이디 찾기 결과
    { type: "main", url: "/portal/findPasswd", el: <FindPasswd /> }, // 비밀번호 찾기
    { type: "main", url: "/portal/inNewPasswd", el: <InNewPasswd /> }, // 비밀번호 재설정(입력)
    { type: "main", url: "/portal/inNewPasswd/result", el: <ChangePwResult /> }, // 비밀번호 재설정 결과
    { type: "main", url: "/simpletax", el: <MobileCalcMenu /> }, // 모바일 계산기
    { type: "main", url: "/simpletax/acquisition", el: <MobileAcqsTaxCalc /> }, // 모바일 취득세 계산기
    { type: "main", url: "/simpletax/transfer"   , el: <MobileTranTaxCalc /> }, // 모바일 양도세 계산기
    { type: "main", url: "/simpletax/result"     , el: <MobileAcqsTaxCalcResult /> }, // 모바일 취득세 결과표
    { type: "main", url: "/simpletax/property"   , el: <MobilePropTaxCalc /> }, // 모바일 보유세 계산기
    { type: "main", url: "/simpletax/gift"       , el: <MobileGiftTaxCalc /> }, // 모바일 증여세 계산기
    { type: "main", url: "/simpletax/inheritance", el: <MobileInheTaxCalc /> }, // 모바일 상속세 계산기
  ];
  const rendering = () => {
    const resEls = [<Route key="index" path="/" element={<Index />} />]; // ROOT : 서비스소개
    if (Util.isNotEmpty(userInfo.userSn)) {
      menuList.push({ type: "main", url: "/system/mypage", el: <Mypage /> }); // 마이페이지
      menuList.push({
        type: "main",
        url: "/system/mypage/mod",
        el: <MypageEdit />,
      }); // 마이페이지수정
      menuList.push({ type: "main", url: "/system/hshld", el: <Hshld /> }); // 납세자
      menuList.push({ type: "main", url: "/system/assets", el: <Assets /> }); // 세대/자산
      menuList.push({
        type: "main",
        url: "/system/assets/reg/:callPage/:assetsSe/:estateSttus/:estateSe/:rmstnfamSn",
        el: <AssetsEdit />,
      }); // 자산작성
      menuList.push({
        type: "main",
        url: "/system/assets/mod/:callPage/:assetsSn",
        el: <AssetsEdit />,
      }); // 자산수정
      menuList.push({
        type: "main",
        url: "/system/assets/view/:callPage/:assetsSn",
        el: <AssetsView />,
      }); // 자산수정
      menuList.push({
        type: "main",
        url: "/system/assets/law",
        el: <AssetsLawTest />,
      }); // 자산법령호출페이지
      menuList.push({ type: "main", url: "/system/acqstax", el: <Acqstax /> }); // 취득세
      menuList.push({ type: "main", url: "/system/regfee", el: <Regfee /> }); // 등기수수료
      menuList.push({ type: "main", url: "/system/proptax", el: <Proptax /> }); // 재산세
      menuList.push({ type: "main", url: "/system/comptax", el: <Comptax /> }); // 종합부동산세
      menuList.push({ type: "main", url: "/system/trsftax", el: <Trsftax /> }); // 양도세
      menuList.push({ type: "main", url: "/system/gifttax", el: <Gifttax /> }); // 증여세
      menuList.push({ type: "main", url: "/system/inhetax", el: <Inhetax /> }); // 상속세
      menuList.push({
        type: "main",
        url: "/system/trsftax/test",
        el: <TrsftaxTest />,
      }); // 양도세테스트
      menuList.push({
        type: "main",
        url: "/system/expert",
        el: <Board boardTy="1002" />,
      }); // 전문가커뮤니티
      menuList.push({
        type: "main",
        url: "/system/expert/reg",
        el: <BoardWrite boardTy="1002" />,
      }); // 전문가커뮤니티 등록
      menuList.push({
        type: "main",
        url: "/system/expert/mod/:boardSn",
        el: <BoardWrite boardTy="1002" />,
      }); // 전문가커뮤니티 수정
      menuList.push({
        type: "main",
        url: "/system/expert/view/:boardSn",
        el: <BoardView boardTy="1002" />,
      }); // 전문가커뮤니티 조회
      menuList.push({
        type: "main",
        url: "/system/qna",
        el: <Board boardTy="1004" />,
      }); // QNA
      menuList.push({
        type: "main",
        url: "/system/qna/reg",
        el: <BoardWrite boardTy="1004" />,
      }); // QNA 등록
      menuList.push({
        type: "main",
        url: "/system/qna/mod/:boardSn",
        el: <BoardWrite boardTy="1004" />,
      }); // QNA 수정
      menuList.push({
        type: "main",
        url: "/system/qna/view/:boardSn",
        el: <BoardView boardTy="1004" />,
      }); // QNA 조회
      menuList.push({
        type: "main",
        url: "/system/simpletax/simpleCallTax",
        el: <SimpleCallTax />,
      }); // 간편 계산기

      if (isAdmin) {
        menuList.push({ type: "main", url: "/admin/user", el: <UserMng /> }); // 회원관리
        menuList.push({
          type: "main",
          url: "/admin/notice",
          el: <Board boardTy="1001" />,
        }); // 공지사항관리
        menuList.push({
          type: "main",
          url: "/admin/notice/reg",
          el: <BoardWrite boardTy="1001" />,
        }); // 공지사항등록
        menuList.push({
          type: "main",
          url: "/admin/notice/mod/:boardSn",
          el: <BoardWrite boardTy="1001" />,
        }); // 공지사항수정
        menuList.push({
          type: "main",
          url: "/admin/notice/view/:boardSn",
          el: <BoardView boardTy="1001" />,
        }); // 공지사항조회
        menuList.push({
          type: "main",
          url: "/admin/faq",
          el: <Board boardTy="1003" />,
        }); // FAQ관리
        menuList.push({
          type: "main",
          url: "/admin/faq/reg",
          el: <BoardWrite boardTy="1003" />,
        }); // FAQ등록
        menuList.push({
          type: "main",
          url: "/admin/faq/mod/:boardSn",
          el: <BoardWrite boardTy="1003" />,
        }); // FAQ수정
        menuList.push({
          type: "main",
          url: "/admin/faq/view/:boardSn",
          el: <BoardView boardTy="1003" />,
        }); // FAQ조회
        menuList.push({ type: "main", url: "/admin/system", el: <SysMng /> }); // 시스템관리 - 법령 메인
      }
    }
    interface menuProps {
      url: string;
      el: JSX.Element;
    }
    menuList.forEach((menuInfo: menuProps, idx: number) => {
      resEls.push(
        <Route key={idx} path={menuInfo.url} element={menuInfo.el} />
      );
    });
    return resEls;
  };

  if (location.pathname.startsWith("/simpletax")) {
    return (
      <React.Fragment>
        <TokenCheck />
        {["/portal/index", "/"].indexOf(location.pathname) !== -1 ? (
          <Intro />
        ) : (
          <></>
        )}
        <MobileThemeProvider>
          <Routes>{rendering()}</Routes>
        </MobileThemeProvider>
      </React.Fragment>
    );
  } else {
    return (
      <div className="w100p h100p dpFlx aiC jcC flxdCol mw1383">
        <TokenCheck />
        {["/portal/index", "/"].indexOf(location.pathname) !== -1 ? (
          <Intro />
        ) : (
          <></>
        )}
        <Header />
        <Routes>{rendering()}</Routes>
        <Footer />
      </div>
    );
  }
}
export default React.memo(Body);
