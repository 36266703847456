/*******************************************************************************
*** InheTaxInfoOne ** 상속세 세율 산출 정보 1단계 입력
*******************************************************************************/
import AppContext       from "js/common/AppContext";
import Util             from "js/common/Util";
import ToolTip          from "js/common/ui/ToolTip";
import ImageRadioBox    from "js/common/ui/input/ImageRadioBox";
import NumberTextBox    from "js/common/ui/input/NumberTextBox";
import MuiListFilter    from "js/common/ui/list/MuiListFilter";
import ForestTableStyle from 'js/common/ui/style/ForestStyle';
import React            from "react";

interface InheTaxCalcProps {
    inheTaxParam: any[]     // 상속자산 배열
    moveStep: Function

    inherProp        : string // 01. 상속재산
    estiInher        : string // 02. 추정상속가액
    trustAssets      : string // 03. 퇴직금/보험금/신탁자산
    spouse           : string // 04. 배우자 유무
    spousePreGift    : string // 05. 배우자 사전증여 유무
    spousePreGiftProp: string // 06. 배우자 사전증여 재산가액
    spousePreGiftTax : string // 07. 배우자 사전증여세액
    debtAmt          : string // 08. 채무
    utilityBill      : string // 09. 공과금
    funeralExpen     : string // 10. 장례비용
    childNumber      : string // 11. 자녀 수
    preGift          : string // 12. (자녀)사전증여 유무
    preGiftProp      : string // 13. (자녀)사전증여 재산가액
    preGiftTax       : string // 14. (자녀)사전증여세액    
    inheAssetsInfo   : any[]  // 상속 자산별 시가표준액
    
    setInherProp        : React.Dispatch<React.SetStateAction<string>>
    setEstiInher        : React.Dispatch<React.SetStateAction<string>>
    setTrustAssets      : React.Dispatch<React.SetStateAction<string>>
    setSpouse           : React.Dispatch<React.SetStateAction<string>>
    setSpousePreGift    : React.Dispatch<React.SetStateAction<string>>
    setSpousePreGiftProp: React.Dispatch<React.SetStateAction<string>>
    setSpousePreGiftTax : React.Dispatch<React.SetStateAction<string>>
    setDebtAmt          : React.Dispatch<React.SetStateAction<string>>
    setUtilityBill      : React.Dispatch<React.SetStateAction<string>>
    setFuneralExpen     : React.Dispatch<React.SetStateAction<string>>
    setChildNumber      : React.Dispatch<React.SetStateAction<string>>
    setPreGift          : React.Dispatch<React.SetStateAction<string>>
    setPreGiftProp      : React.Dispatch<React.SetStateAction<string>>
    setPreGiftTax       : React.Dispatch<React.SetStateAction<string>>     
    setInheAssetsInfo   : React.Dispatch<React.SetStateAction<any[]>> 
}

const InheTaxInfoOne = ({ 
      inheTaxParam
    , moveStep

    , inherProp          // 01. 상속재산
    , estiInher          // 02. 추정상속가액
    , trustAssets        // 03. 퇴직금/보험금/신탁자산
    , spouse             // 04. 배우자 유무
    , spousePreGift      // 05. 배우자 사전증여 유무
    , spousePreGiftProp  // 06. 배우자 사전증여 재산가액
    , spousePreGiftTax   // 07. 배우자 사전증여세액
    , debtAmt            // 08. 채무
    , utilityBill        // 09. 공과금
    , funeralExpen       // 10. 장례비용
    , childNumber        // 11. 자녀 수
    , preGift            // 12. (자녀)사전증여 유무
    , preGiftProp        // 13. (자녀)사전증여 재산가액
    , preGiftTax         // 14. (자녀)사전증여세액
    , inheAssetsInfo

    , setInherProp        
    , setEstiInher        
    , setTrustAssets      
    , setSpouse           
    , setSpousePreGift    
    , setSpousePreGiftProp
    , setSpousePreGiftTax 
    , setDebtAmt          
    , setUtilityBill      
    , setFuneralExpen     
    , setChildNumber      
    , setPreGift          
    , setPreGiftProp      
    , setPreGiftTax    
    , setInheAssetsInfo      
}: InheTaxCalcProps) => {

    // 입력항목 표시 여부
    const [visible05, setVisible05] = React.useState<boolean>(false); // 05. 배우자 사전증여 유무
    const [visible06, setVisible06] = React.useState<boolean>(false); // 06. 배우자 사전증여 재산가액
    const [visible07, setVisible07] = React.useState<boolean>(false); // 07. 배우자 사전증여세액
    const [visible13, setVisible13] = React.useState<boolean>(false); // 13. (자녀)사전증여 재산가액
    const [visible14, setVisible14] = React.useState<boolean>(false); // 14. (자녀)사전증여세액

    // 입력항목 TEXT 변경
    const [title12, setTitle12] = React.useState<string>("사전증여 유무"    ); // 12.(자녀)사전증여 유무
    const [title13, setTitle13] = React.useState<string>("사전증여 재산가액"); // 13.(자녀)사전증여 재산가액
    const [title14, setTitle14] = React.useState<string>("사전증여세액"     ); // 14.(자녀)사전증여세액

    // 상속 자산 시가표준액 정보
    interface inheAssetsInfoProps {
        assetSn   : number    // 자산일련번호
        stdMkPrice: number    // 시가표준액
    }

    // 상속 자산정보의 시가표준액 저장용 배열 생성
    React.useEffect(() => {
        if (inheAssetsInfo.length === 0) {
            const tempInheAssetsInfo = [] as inheAssetsInfoProps[];
            inheTaxParam.forEach((item: any) => {
                tempInheAssetsInfo.push({
                      assetSn   : item.assetsSn
                    , stdMkPrice: 0
                })
            });
            setInheAssetsInfo(tempInheAssetsInfo);
        }
    }, 
    // eslint-disable-next-line 
    []);


    // 시가표준액 입력 값 변경 처리
    const bindInheAssetsMarkerPrice = (assetsSn: number, stdMkPrice: string) => {
        const tempInheAssetsInfo = [] as inheAssetsInfoProps[];  // 임시 배열 생성
        inheAssetsInfo.forEach((inheAssetsInfo: inheAssetsInfoProps) => {
            if (inheAssetsInfo.assetSn === assetsSn) {
                tempInheAssetsInfo.push({
                    assetSn   : assetsSn,
                    stdMkPrice: Number(Util.uncomma(stdMkPrice))
                });
            } else {
                tempInheAssetsInfo.push(inheAssetsInfo);
            }
            
        });
        setInheAssetsInfo(tempInheAssetsInfo);
    }

    // 상속 자산정보가 변경될 때마다 시가표준액 합계 계산
    React.useEffect(() => {
        let totalMarketPrice = 0;
        inheAssetsInfo.forEach((inheAssetsInfo:inheAssetsInfoProps) => {
            totalMarketPrice += inheAssetsInfo.stdMkPrice; 
        });
        setInherProp(totalMarketPrice.toString());
    }, [inheAssetsInfo, setInherProp, inherProp]);

    // 상속입력 이전 정보 표시 처리 : 2단계에서 1단계로 복귀 시
    React.useEffect(() => {
        // 배우자 유 ▶ 05.배우자사전증여 유무
        if (spouse === "Y") setVisible05(true );
        
        // 배우자사전증여 유 ▶ 06.배우자 사전증여 재산가액, 07.배우자 사전증여세액
        if (spousePreGift === "Y") {
            setVisible06(true);
            setVisible07(true);
        }
        
        // (자녀)사전증여 유 ▶ 13.(자녀)사전증여 재산가액, 14.(자녀)사전증여세액
        if (preGift === "Y") {
            setVisible13(true);
            setVisible14(true);
        }        
    }, 
    // eslint-disable-next-line
    []);

    // 상속세 계산하기 
    const selGittaxCalc = () => {
        // 시가표준액 확인
        let chkValFlag = 0;
        if (inheAssetsInfo.length === 0) {
            chkValFlag = 1;
        } else {
            inheAssetsInfo.forEach((inheAssetsInfo: inheAssetsInfoProps) => {
                if (Util.isEmpty(inheAssetsInfo.stdMkPrice) || inheAssetsInfo.stdMkPrice === 0) {
                    chkValFlag++;
                }
            });
        }
        if (chkValFlag > 0) {
            AppContext.openAlert({
                title: "상속 자산 시가표준액 미입력",
                msg: "상속 자산의 시가표준액을 입력하세요.",
                icon: "check"
            });
            return;
        }    

        // 입력 값 확인 메시지 처리
        if (Util.isEmpty(inherProp)    ) {AppContext.openAlert({title: "상속재산가액"          , msg: "상속재산가액을 입력하세요."          , icon: "check"}); return;}
        if (Number(inherProp) <= 0     ) {AppContext.openAlert({title: "상속재산가액"          , msg: "상속재산가액을 입력하세요."          , icon: "check"}); return;}
        if (Util.isEmpty(estiInher)    ) {AppContext.openAlert({title: "추정상속가액"          , msg: "추정상속가액을 입력하세요."          , icon: "check"}); return;}
        if (Number(estiInher) < 0      ) {AppContext.openAlert({title: "추정상속가액"          , msg: "추정상속가액을 입력하세요."          , icon: "check"}); return;}
        if (Util.isEmpty(trustAssets)  ) {AppContext.openAlert({title: "퇴직금/보험금/신탁자산", msg: "퇴직금/보험금/신탁자산을 입력하세요.", icon: "check"}); return;}
        if (Number(estiInher) < 0      ) {AppContext.openAlert({title: "퇴직금/보험금/신탁자산", msg: "퇴직금/보험금/신탁자산을 입력하세요.", icon: "check"}); return;}
        if (Util.isEmpty(spouse)       ) {AppContext.openAlert({title: "배우자 유무"           , msg: "배우자 유무를 선택하세요."           , icon: "check"}); return;}
        if (visible05) {
            if (Util.isEmpty(spousePreGift)) {AppContext.openAlert({title: "배우자 사전증여 유무"  , msg: "배우자 사전증여 유무를 선택하세요."  , icon: "check"}); return;}
        }
        if (visible06) {
            if (Util.isEmpty(spousePreGiftProp)) {AppContext.openAlert({title: "배우자 사전증여 재산가액", msg: "배우자 사전증여 재산가액을 입력하세요.", icon: "check"}); return;}
            if (Number(spousePreGiftProp) <= 0 ) {AppContext.openAlert({title: "배우자 사전증여 재산가액", msg: "배우자 사전증여 재산가액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible07) {
            if (Util.isEmpty(spousePreGiftTax)) {AppContext.openAlert({title: "배우자 사전증여세액", msg: "배우자 사전증여세액을 입력하세요.", icon: "check"}); return;}
            if (Number(spousePreGiftTax) < 0  ) {AppContext.openAlert({title: "배우자 사전증여세액", msg: "배우자 사전증여세액을 입력하세요.", icon: "check"}); return;}
        }
        
        if (Util.isEmpty(debtAmt)     ) {AppContext.openAlert({title: "채무금액", msg: "채무금액을 입력하세요.", icon: "check"}); return;}
        if (Number(debtAmt) < 0       ) {AppContext.openAlert({title: "채무금액", msg: "채무금액을 입력하세요.", icon: "check"}); return;}
        if (Util.isEmpty(utilityBill) ) {AppContext.openAlert({title: "공과금"  , msg: "공과금을 입력하세요."  , icon: "check"}); return;}
        if (Number(utilityBill) < 0   ) {AppContext.openAlert({title: "공과금"  , msg: "공과금을 입력하세요."  , icon: "check"}); return;}
        if (Util.isEmpty(funeralExpen)) {AppContext.openAlert({title: "장례비용", msg: "장례비용을 입력하세요.", icon: "check"}); return;}
        if (Number(funeralExpen) < 0  ) {AppContext.openAlert({title: "장례비용", msg: "장례비용을 입력하세요.", icon: "check"}); return;}
        if (Number(Util.uncomma(funeralExpen)) > 15000000) {
            AppContext.openAlert({title: "장례비용 공제 한도초과", msg: "장례비용은 최대 1,500만원까지 공제가능합니다.\n1,500만원 이하로 입력하세요.", icon: "check"}); return;
        }

        if (Util.isEmpty(childNumber)) {AppContext.openAlert({title: "자녀 수"      , msg: "자녀 수를 입력하세요."      , icon: "check"}); return;}
        if (Util.isEmpty(preGift    )) {AppContext.openAlert({title: "사전증여 여부", msg: "사전증여 여부를 선택하세요.", icon: "check"}); return;}

        if (visible13) {
            if (Util.isEmpty(preGiftProp)) {AppContext.openAlert({title: "사전증여 재산가액", msg: "사전증여 재산가액을 입력하세요.", icon: "check"}); return;}
            if (Number(preGiftProp) <= 0 ) {AppContext.openAlert({title: "사전증여 재산가액", msg: "사전증여 재산가액을 입력하세요.", icon: "check"}); return;}
        }
        if (visible14) {
            if (Util.isEmpty(preGiftTax)) {AppContext.openAlert({title: "사전증여세액", msg: "사전증여세액을 입력하세요.", icon: "check"}); return;}
            if (Number(preGiftTax) < 0 ) {AppContext.openAlert({title: "사전증여세액", msg: "사전증여세액을 입력하세요.", icon: "check"}); return;}
        }
        
        moveStep(2);
    }

    return (

        <div className='w850 mh500'>
            <div className="mb10">
                <div className="h20 mb10 dpFlx aiC">
                    <span className="SpoqaHanSansNeo-Medium fs18 ls09 fc222222">◎ 상속 자산 정보</span>
                </div>
                <MuiListFilter tableInfo={{
                    styleType: ForestTableStyle,
                    fldInfs: [
                        { type: 'code', fldNm: 'estateDetailSe', width: 100 , headAlign: "center", dataAlign: "center", label: '자산구분', codeTy: "1006"},
                        { type: 'text', fldNm: 'fullRdnmAdres' , wAuto: true, headAlign: "center", dataAlign: "left"  , label: '주소'    },
                        { type: 'text', fldNm: 'acqsDe'        , width: 120 , headAlign: "center", dataAlign: "center", label: '취득일'  },
                        { type: 'el'                           , width: 180 , headAlign: "center"                     , label: '상속세법상 평가액', el: (item: any) =>
                            <NumberTextBox
                                value={inheAssetsInfo[item.rowNum - 1].assetSn === item.assetsSn ? inheAssetsInfo[item.rowNum - 1].stdMkPrice : 0}
                                onChange={(data: string) => bindInheAssetsMarkerPrice(item.assetsSn, data)}
                                borderClass="bgcWhite w100p h35 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                                unitText="원"
                            />
                        }
                    ],
                    ordFld: { name: "rowNum", direction: "asc" },
                    dataArr: inheTaxParam,
                    maxRowNums: [100],
                    trHeight: 40,
                    remainArea: false,
                    paginateClass: "dpNone-i",
                    tableHeadVsb: true
                }}/>
            </div>

            <div className="h20 mt30 dpFlx aiC">
                <span className="SpoqaHanSansNeo-Medium fs18 ls09 fc222222">◎ 상속세 산출 정보 입력</span>
            </div>

            {/************************** 주소 하단 왼쪽 부분 **************************/}    
            <div className="w50p float-l pr10 bdBox pl10">  
                {/* 01. 상속 재산가액 */}
                <div className={(true ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt20"}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>상속 부동산 재산가액</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={inherProp}
                            onChange={(data: string) => setInherProp(data)}
                            borderClass="bgcWhite w230 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            disabled={true}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div>                 
                
                {/* 02. 추정상속가액 */}
                <div className={(true ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>기타/추정 상속재산가액</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={estiInher}
                            onChange={(data: string) => setEstiInher(data)}
                            borderClass="bgcWhite w230 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div> 

                {/* 03. 퇴직금/보험금/신탁자산 */}
                <div className={(true ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>퇴직금/보험금/신탁자산</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={trustAssets}
                            onChange={(data: string) => setTrustAssets(data)}
                            borderClass="bgcWhite w230 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div>  

                {/* 04. 배우자 유무 */}
                <div className={(true ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt10'}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>배우자 유무</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <ImageRadioBox
                            codeList={Util.getCodeArray("1073")}
                            value={spouse}
                            btnClass="w24 h24 pr5"
                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                            interval={20}
                            onChange={(data: string) => {
                                setSpouse(data);

                                if (data === "Y") {         // 배우자 있음 ▶ 05.배우자 사전증여 유무 
                                    setVisible05(true );
                                } else if (data === "N") {  // 배우자 없음 ▶ 05.배우자 사전증여 유무, 06.배우자 사전증여 재산가액, 07.배우자 사전증여세액 미표시 & 초기화
                                    setVisible05(false     ); // 05.배우자 사전증여 유무 미표시
                                    setVisible06(false     ); // 06.배우자 사전증여 재산가액 미표시
                                    setVisible07(false     ); // 07.배우자 사전증여세액 미표시
                                    setSpousePreGift(""    ); // 05.배우자 사전증여 유무 초기화
                                    setSpousePreGiftProp(""); // 06.배우자 사전증여 재산가액 초기화
                                    setSpousePreGiftTax("" ); // 07.배우자 사전증여세액 초기화
                                } else {}
                            }}></ImageRadioBox>
                    </div>
                </div>   

                {/* 05. 배우자 사전증여 유무 */}
                <div className={(visible05 ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt10'}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>배우자 사전증여 유무</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <ImageRadioBox
                            codeList={Util.getCodeArray("1073")}
                            value={spousePreGift}
                            btnClass="w24 h24 pr5"
                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                            interval={20}
                            onChange={(data: string) => {
                                setSpousePreGift(data);
                                if (data === "Y") {         // 배우자 사전증여 유 ▶ 6.배우자 사전증여 재산가액, 7.배우자 사전증여세액
                                    setVisible06(true);
                                    setVisible07(true);
                                } else if (data === "N") {  // 배우자 사전증여 무 ▶ 6.배우자 사전증여 재산가액, 7.배우자 사전증여세액 미표시 & 초기화
                                    setVisible06(false     ); // 6.배우자 사전증여 재산가액 미표시
                                    setVisible07(false     ); // 7.배우자 사전증여세액 미표시
                                    setSpousePreGiftProp(""); // 6.배우자 사전증여 재산가액 초기화
                                    setSpousePreGiftTax("" ); // 7.배우자 사전증여세액 초기화
                                } else {}
                            }}></ImageRadioBox>
                    </div>
                </div> 
                {/* <span className={(visible05 ? "" : "dpNone-i") + " SpoqaHanSansNeo-Regular fs13 ls08 fc2C2C2C pl10"}>▲ 10년 이내 피상속인으로부터 받은 사전증여</span> */}

                {/* 06. 배우자 사전증여 재산가액 */}
                <div className={(visible06 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>배우자 사전증여 재산가액</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={spousePreGiftProp}
                            onChange={(data: string) => setSpousePreGiftProp(data)}
                            borderClass="bgcWhite w230 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div> 

                {/* 07. 배우자 사전증여세액 */}
                <div className={(visible07 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>배우자 사전증여세액</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={spousePreGiftTax}
                            onChange={(data: string) => setSpousePreGiftTax(data)}
                            borderClass="bgcWhite w230 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div>                 
            </div>  

            {/************************** 주소 하단 오른쪽 부분 **************************/}
            <div className="w50p float-r pl20 bdBox">
                {/* 08. 채무 */}
                <div className={(true ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt20"}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 ml30 fc222222'>채무</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={debtAmt}
                            onChange={(data: string) => setDebtAmt(data)}
                            borderClass="bgcWhite w230 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div>                 

               {/* 09. 공과금 */}
               <div className={(true ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 ml30 fc222222'>공과금</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={utilityBill}
                            onChange={(data: string) => setUtilityBill(data)}
                            borderClass="bgcWhite w230 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div>    

               {/* 10. 장례비용 */}
               <div className={(true ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>
                            <div className="dpFlx aiC jcC">장례비용
                                <img
                                    src={require('img/common/icon_info_fill_20.png')}
                                    alt="icon_info_fill_20"
                                    className='mr3 csP'
                                    data-tooltip-id="disabilityToolTip"
                                    data-tooltip-content={
                                        "「상속세 및 증여세법 시행령」 제9조제2항\n\n"
                                      + "장례비용은 증빙서류가 전혀 없더라도 최소 500만원은 공제받을 수 있습니다.\n"
                                      + "또한, 실제 장례에 든 금액이 아무리 많더라도 최대 1,000만원 까지만 공제받을 수 있습니다.\n"
                                      + "봉안시설 또는 자연장지를 사용하는 경우 500만원을 추가로 공제받을 수 있습니다."
                                    }
                                />
                                <ToolTip id="disabilityToolTip" place='top' clickable={true} className="w300 taL lh20" />
                            </div>
                        </span>                        
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={funeralExpen}
                            onChange={(data: string) => setFuneralExpen(data)}
                            borderClass="bgcWhite w230 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div>   

                {/* 11. 자녀 수 */}
                <div className={(true ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt10'}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 ml30 fc222222'>자녀 수</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={childNumber}
                            onChange={(data: string) => {
                                setChildNumber(data);

                                if (Util.isNotEmpty(data) && Number(data) === 0) {       // 자녀 없음 ▶ ▶ 12.사전증여 유무
                                    setTitle12("사전증여 유무");
                                    setTitle13("사전증여 재산가액");
                                    setTitle14("사전증여세액");
                                } else if (Number(data) > 0) {  // 자녀 있음 ▶ 12.(자녀)사전증여 유무
                                    setTitle12("자녀 사전증여 유무");
                                    setTitle13("자녀 사전증여 재산가액");
                                    setTitle14("자녀 사전증여세액");
                                } else {    // 입력값 삭제
                                    setTitle12("사전증여 유무");
                                    setTitle13("사전증여 재산가액");
                                    setTitle14("사전증여세액");
                                }        
                            }}
                            borderClass="bgcWhite w230 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={1}
                            // disabled={disabled08}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="명"
                        />
                    </div>
                </div> 

                {/* 12. (자녀)사전증여 유무 */}
                <div className={(true ? "" : "dpNone-i") + ' w100p dpFlx aiC jcL pt10'}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>{title12}</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <ImageRadioBox
                            codeList={Util.getCodeArray("1073")}
                            value={preGift}
                            btnClass="w24 h24 pr5"
                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                            interval={20}
                            onChange={(data: string) => {
                                setPreGift(data);

                                if (data === "Y") {         // 사전증여 유 ▶ 13.(자녀)사전증여 재산가액, 14.(자녀)사전증여세액
                                    setVisible13(true);
                                    setVisible14(true);
                                } else if (data === "N") {  // 사전증여 무
                                    setVisible13(false); // 13.(자녀)사전증여 재산가액 미표시
                                    setPreGiftProp("" ); // 13.(자녀)사전증여 재산가액 초기화
                                    setVisible14(false); // 14.(자녀)사전증여세액 미표시
                                    setPreGiftTax(""  ); // 14.(자녀)사전증여세액 초기화
                                } else {}
                            }}></ImageRadioBox>
                    </div>
                </div> 
                {/* <span className="SpoqaHanSansNeo-Regular fs13 ls08 fc2C2C2C pl10">▲ 10년 이내 피상속인으로부터 받은 사전증여</span> */}

                {/* 13. (자녀)사전증여재산가액 */}
                <div className={(visible13 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                    <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>{title13}</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={preGiftProp}
                            onChange={(data: string) => setPreGiftProp(data)}
                            borderClass="bgcWhite w230 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div> 

                {/* 14. (자녀)사전증여세액 */}
                <div className={(visible14 ? "" : "dpNone-i") + " w100p dpFlx aiC jcL pt10"}>
                    <div className="w150 h40 bdrd4 dpFlx jcL aiC">
                        <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>{title14}</span>
                    </div>
                    <div className='dpFlx aiC jcC ml10 bgcWhite'>
                        <NumberTextBox
                            value={preGiftTax}
                            onChange={(data: string) => setPreGiftTax(data)}
                            borderClass="bgcWhite w230 h40 pr15 bd1"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            maxLength={15}
                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                            unitText="원"
                        />
                    </div>
                </div> 
            </div>                

            <div className="dpFlx w100p jcC pt40">
                <div className="w210 csP dpFlx aiC jcC bgcF6F6F6 h56" onClick={() => {
                    moveStep(0);
                }}>
                    <span className="SpoqaHanSansNeo-Medium fs16 fc222222">취소</span>
                </div>
                <div className="w210 bgc17A840 csP dpFlx aiC jcC h56" onClick={() => {
                    selGittaxCalc();
                }}>
                    <span className="SpoqaHanSansNeo-Medium fs16 fcWhite">다음</span>
                </div>
            </div>          

        </div>


    )
}
export default InheTaxInfoOne;