import React from "react";
import HomeIcon from '@mui/icons-material/Home';
import ImageRadioBox from "js/common/ui/input/ImageRadioBox";
import Util from "js/common/Util";

interface Qestn18Param {
    setQestn18Open: React.Dispatch<React.SetStateAction<boolean>>
    setCrltsHouseAt: React.Dispatch<React.SetStateAction<string>>
    qestn18AnswerText: string
    setQestn18AnswerText: React.Dispatch<React.SetStateAction<string>>
    qestn23AnswerText: string
    setQestn23AnswerText: React.Dispatch<React.SetStateAction<string>>
}
const Qestn18Modal = ({ setQestn18Open, setCrltsHouseAt, qestn18AnswerText, setQestn18AnswerText, qestn23AnswerText, setQestn23AnswerText }: Qestn18Param) => {
    const [qestn23Vsb, setQestn23Vsb] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (qestn18AnswerText === "Y") {
            setQestn23Vsb(true);
        } else {
            setQestn23Vsb(false);
        }
    }, [qestn18AnswerText]);

    return (
        <div className="w600">
            <span className="fs15 SpoqaHanSansNeo-Medium ls08">※ 문화재보호법 제2조 제3항에 따른 지정문화재주택, 제53조 제1항에 따른 국가등록문화재주택</span>
            <div className="dpFlx aiC mt20 mb10">
                <HomeIcon style={{ fontSize: 17, color: 'black', paddingRight: 5 }} />
                <span className="SpoqaHanSansNeo-Regular fs14 ls1">문화재 주택입니까?</span>
            </div>
            <ImageRadioBox
                codeList={Util.getCodeArray("1036")}
                value={qestn18AnswerText}
                btnClass="w24 h24 pr5"
                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                interval={20}
                onChange={(item: string) => {
                    setQestn18AnswerText(item);
                    if (item === "Y") {
                        setQestn23Vsb(true);
                    } else {
                        setQestn23Vsb(false);
                    }
                }}
            />
            <div className={qestn23Vsb ? "mt20" : "dpNone"}>
                <div className="dpFlx aiC mb10">
                    <HomeIcon style={{ fontSize: 17, color: 'black', paddingRight: 5 }} />
                    <span className="SpoqaHanSansNeo-Regular fs14 ls1">주택의 유형은 무엇입니까?</span>
                </div>
                <ImageRadioBox
                    codeList={Util.getCodeArray("1048")}
                    value={qestn23AnswerText}
                    btnClass="w24 h24 pr5"
                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                    interval={20}
                    onChange={(item: string) => setQestn23AnswerText(item)}
                />
            </div>
            <div className='w100p dpFlx aiC jcC pt40'>
                <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={() => {
                    if (qestn18AnswerText === "N") {
                        setCrltsHouseAt("N");
                    }
                    setQestn18Open(false);
                }}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>확인</span>
                </div>
            </div>
        </div>
    )
}
export default Qestn18Modal;