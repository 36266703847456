import React from "react";
import { useRecoilValue } from "recoil";
import * as Atom from "js/common/Atom";
import { IWizard } from "use-wizard/lib/cjs/useWizard/types/IWizard";
import { TStep } from "use-wizard/lib/cjs/useWizard/types/TStep";
import AppContext from "js/common/AppContext";
import Axios from "js/common/Axios";
import Util from "js/common/Util";
import Modal from "js/common/ui/modal/Modal";
import ImageRadioBox from "js/common/ui/input/ImageRadioBox";
import AssetsButton from "./ui/AssetsButton";
import NumberTextBox from "js/common/ui/input/NumberTextBox";
import Qestn45Modal from "./qestn/Qestn45Modal";
import Qestn47Modal from "./qestn/Qestn47Modal";
import Qestn48Modal from "./qestn/Qestn48Modal";
import Qestn56Modal from "./qestn/Qestn56Modal";
import Qestn57Modal from "./qestn/Qestn57Modal";
import AssetsEditTab from "./ui/AssetsEditTab";

const AssetsAcqQestn = (props: {
    step: TStep;
    wizard: IWizard;
    form: any;
    dispatchForm: Function;
}) => {
    const hshldSn = useRecoilValue(Atom.hshldSn);
    const [estateDetailSe, setEstateDetailSe] = React.useState<string>("");
    const [acqsCause, setAcqsCause] = React.useState<string>("");
    const [acqsSrc, setAcqsSrc] = React.useState<string>("");
    const [qestn57SubTitle, setQestn57SubTitle] = React.useState<string>(""); // 과세표준 서브타이틀
    const [qestn56CodeTy, setQestn56CodeTy] = React.useState<string>("1064");
    const [houseNum, setHouseNum] = React.useState<number>();
    interface acqstaxQestnProps {
        assetsQestnSn: number
        assetsQestnTy: string
        sortOrdr: number
        qestnText: string
        answerTySn: number
        answerText: string
        answerCode: string
        answerCodeValue: string
    }
    const [acqstaxQestnList, setAcqstaxQestnList] = React.useState<acqstaxQestnProps[]>([]);
    const [acqstaxQestn44, setAcqstaxQestn44] = React.useState<string>("");
    const [acqstaxQestn45, setAcqstaxQestn45] = React.useState<string>("");
    const [acqstaxQestn46, setAcqstaxQestn46] = React.useState<string>("");
    const [acqstaxQestn47, setAcqstaxQestn47] = React.useState<string>("");
    const [acqstaxQestn48, setAcqstaxQestn48] = React.useState<string>("");
    const [acqstaxQestn49, setAcqstaxQestn49] = React.useState<string>("");
    const [acqstaxQestn50, setAcqstaxQestn50] = React.useState<string>("");
    const [acqstaxQestn51, setAcqstaxQestn51] = React.useState<string>("");
    const [acqstaxQestn56, setAcqstaxQestn56] = React.useState<string>("");
    const [acqstaxQestn57, setAcqstaxQestn57] = React.useState<string>("");
    const [acqstaxQestn85, setAcqstaxQestn85] = React.useState<string>("");
    const [acqstaxQestn86, setAcqstaxQestn86] = React.useState<string>("");
    const [modFlag, setModFlag] = React.useState<boolean>(false);
    const [renderFlag, setRenderFlag] = React.useState<boolean>(false);
    const [qestn45Open, setQestn45Open] = React.useState<boolean>(false);
    const [qestn47Open, setQestn47Open] = React.useState<boolean>(false);
    const [qestn48Open, setQestn48Open] = React.useState<boolean>(false);
    const [qestn56Open, setQestn56Open] = React.useState<boolean>(false);
    const [qestn57Open, setQestn57Open] = React.useState<boolean>(false);

    const goPrevStep = () => {
        if (estateDetailSe === "1001") {
            props.wizard.goToStep(1003);
        } else if (estateDetailSe === "1002") {
            props.wizard.goToStep(1002);
        } else if (estateDetailSe === "1003") {
            props.wizard.goToStep(1002);
        }
    }
    const goNextStep = (callMode: string) => {
        interface assetsAnswerInfoProp {
            assetsQestnSn: number
            answerText: string
        };
        const assetsAnswerInfo: assetsAnswerInfoProp[] = [];
        if (acqstaxQestnList.length === 0) {
            props.wizard.goToStep(1009);
            return;
        }
        for (const acqstaxQestnInfo of acqstaxQestnList) {
            let answerCodeValue: string = "";
            if (acqstaxQestnInfo.assetsQestnSn === 44) { answerCodeValue = acqstaxQestn44; }
            else if (acqstaxQestnInfo.assetsQestnSn === 45) { answerCodeValue = acqstaxQestn45; }
            else if (acqstaxQestnInfo.assetsQestnSn === 46) { answerCodeValue = acqstaxQestn46; }
            else if (acqstaxQestnInfo.assetsQestnSn === 47) { answerCodeValue = acqstaxQestn47; }
            else if (acqstaxQestnInfo.assetsQestnSn === 48) { answerCodeValue = acqstaxQestn48; }
            else if (acqstaxQestnInfo.assetsQestnSn === 49) { answerCodeValue = acqstaxQestn49; }
            else if (acqstaxQestnInfo.assetsQestnSn === 50) { answerCodeValue = acqstaxQestn50; }
            else if (acqstaxQestnInfo.assetsQestnSn === 51) { answerCodeValue = acqstaxQestn51; }
            else if (acqstaxQestnInfo.assetsQestnSn === 56) { answerCodeValue = acqstaxQestn56; }
            else if (acqstaxQestnInfo.assetsQestnSn === 57) { answerCodeValue = Util.uncomma(acqstaxQestn57); }
            else if (acqstaxQestnInfo.assetsQestnSn === 85) { answerCodeValue = acqstaxQestn85; }
            else if (acqstaxQestnInfo.assetsQestnSn === 86) { answerCodeValue = acqstaxQestn86; }

            if (Util.isEmpty(answerCodeValue)) {
                AppContext.openAlert({
                    title: "답변 선택",
                    msg: "답변을 선택(입력)한 후 저장하세요.",
                    icon: "check"
                });
                return;
            }
            assetsAnswerInfo.push({ assetsQestnSn: acqstaxQestnInfo.assetsQestnSn, answerText: answerCodeValue });
        }
        if (!modFlag) {
            if (callMode === "next") {
                props.wizard.goToStep(1009);
            } else {
                AppContext.openAlert({
                    title: "수정사항 없음",
                    msg: "수정된 내역이 없습니다.",
                    icon: "check",
                });
            }
            return;
        }
        AppContext.openAlert({
            title: "취득세액 계산 질문정보 저장",
            msg: "수정된 내역이 존재합니다. 저장하시겠습니까?",
            icon: "check",
            confirmText: "저장",
            handleConfirm: () => {
                Axios.dataAccess({
                    url: "system/acqstax/saveAcqtaxAnswerInfo.do",
                    methodType: "post",
                    paramType: "json",
                    paramData: {
                        assetsSn: props.form.assetsSn,
                        answerParam: assetsAnswerInfo
                    },
                    onSuccessFn: () => {
                        props.dispatchForm({
                            type: "UPDATE_KEY_VALUES",
                            value: {
                                stepCode: "1006"
                            }
                        });
                        setModFlag(false); // 수정여부 판별 플래그 초기화
                        if (callMode === "next") {
                            props.wizard.goToStep(1009);
                        } else {
                            AppContext.hideSpinner();
                        }
                    }
                });
            }
        });
    }

    const selAcqstaxQestnList = React.useCallback((callFg: string) => {
        if (Util.isEmpty(hshldSn) || Util.isEmpty(houseNum)) {
            return;
        }
        Axios.dataAccess({
            url: "system/acqstax/selAcqstaxQestnList.do",
            methodType: "post",
            paramType: "json",
            paramData: {
                hshldSn: hshldSn,
                assetsSn: props.form.assetsSn,
                houseNum: houseNum,
                acqstaxQestn44: acqstaxQestn44,
                acqstaxQestn45: acqstaxQestn45,
                acqstaxQestn46: acqstaxQestn46,
                acqstaxQestn47: acqstaxQestn47,
                acqstaxQestn48: acqstaxQestn48,
                acqstaxQestn49: acqstaxQestn49,
                acqstaxQestn50: estateDetailSe === "1001" ? acqstaxQestn50 : (acqstaxQestn46 === "1001" ? acqstaxQestn50 : null),
                acqstaxQestn51: acqstaxQestn51,
                acqstaxQestn56: acqstaxQestn56,
                acqstaxQestn85: acqstaxQestn85,
                acqstaxQestn86: acqstaxQestn86
            },
            onSuccessFn: (res: any) => {
                if (res.list.length === 0) { // 조회된 질문이 0건이면 그만
                    setRenderFlag(true);
                    return;
                }
                if (res.list.length === acqstaxQestnList.length) {
                    let rsltListEqualFlag = 0;
                    res.list.forEach((item: any) => {
                        acqstaxQestnList.forEach((acqstaxQestnItem: any) => {
                            if (item.assetsQestnSn === acqstaxQestnItem.assetsQestnSn) {
                                rsltListEqualFlag++;
                            }
                        });
                    });
                    if (rsltListEqualFlag === acqstaxQestnList.length) {
                        setRenderFlag(true);
                        return;
                    }
                }
                setAcqstaxQestnList(res.list); // 바인딩
                if (callFg === "first") {
                    res.list.forEach((item: any) => {
                        if (item.assetsQestnSn === 44) { setAcqstaxQestn44(Util.isEmpty(acqstaxQestn44) ? item.answerCodeValue : acqstaxQestn44); }
                        else if (item.assetsQestnSn === 56) { setAcqstaxQestn56(Util.isEmpty(acqstaxQestn56) ? item.answerCodeValue : acqstaxQestn56); }
                        else if (item.assetsQestnSn === 45) { setAcqstaxQestn45(Util.isEmpty(acqstaxQestn45) ? item.answerCodeValue : acqstaxQestn45); }
                        else if (item.assetsQestnSn === 46) { setAcqstaxQestn46(Util.isEmpty(acqstaxQestn46) ? item.answerCodeValue : acqstaxQestn46); }
                        else if (item.assetsQestnSn === 57) { setAcqstaxQestn57(Util.isEmpty(acqstaxQestn57) ? item.answerCodeValue : acqstaxQestn57); }
                        else if (item.assetsQestnSn === 47) { setAcqstaxQestn47(Util.isEmpty(acqstaxQestn47) ? item.answerCodeValue : acqstaxQestn47); }
                        else if (item.assetsQestnSn === 48) { setAcqstaxQestn48(Util.isEmpty(acqstaxQestn48) ? item.answerCodeValue : acqstaxQestn48); }
                        else if (item.assetsQestnSn === 49) { setAcqstaxQestn49(Util.isEmpty(acqstaxQestn49) ? item.answerCodeValue : acqstaxQestn49); }
                        else if (item.assetsQestnSn === 50) { setAcqstaxQestn50(Util.isEmpty(acqstaxQestn50) ? item.answerCodeValue : acqstaxQestn50); }
                        else if (item.assetsQestnSn === 51) { setAcqstaxQestn51(Util.isEmpty(acqstaxQestn51) ? item.answerCodeValue : acqstaxQestn51); }
                        else if (item.assetsQestnSn === 85) { setAcqstaxQestn85(Util.isEmpty(acqstaxQestn85) ? item.answerCodeValue : acqstaxQestn85); }
                        else if (item.assetsQestnSn === 86) { setAcqstaxQestn86(Util.isEmpty(acqstaxQestn86) ? item.answerCodeValue : acqstaxQestn86); }
                    });
                }
                return;
            }
        });
    }, [hshldSn, props.form.assetsSn, estateDetailSe, houseNum, acqstaxQestnList, acqstaxQestn44, acqstaxQestn45, acqstaxQestn46, acqstaxQestn47, acqstaxQestn48, acqstaxQestn49, acqstaxQestn50, acqstaxQestn51, acqstaxQestn56, acqstaxQestn57, acqstaxQestn85, acqstaxQestn86]);

    React.useEffect(() => {
        props.form.setCurrStepCode(1006);
    }, [props.form]);

    React.useEffect(() => {
        Axios.dataAccess({
            url: "system/assets/selAssetsEstateInfo.do",
            methodType: "post",
            paramData: {
                assetsSn: props.form.assetsSn
            },
            onSuccessFn: (res: any) => {
                if (res.item.mdatTrgetAreaAt === "Y") {
                    setQestn56CodeTy("1064");
                } else {
                    setQestn56CodeTy("1068");
                }
                Axios.dataAccess({
                    url: "system/assets/selAssetsEstateDetail.do",
                    methodType: "post",
                    paramData: {
                        assetsSn: props.form.assetsSn
                    },
                    onSuccessFn: (res2: any) => {
                        setEstateDetailSe(res2.item.estateDetailSe);
                        setAcqsCause(res2.item.acqsCause);
                        setAcqsSrc(res2.item.acqsSrc);
                        if (res2.item.acqsCause === "1002") {
                            setQestn57SubTitle("(실제 거래가액)");
                        } else if (res2.item.acqsCause === "1003") {
                            setQestn57SubTitle("(시가표준액)");
                        } else if (res2.item.acqsCause === "1004") {
                            setQestn57SubTitle("(시가인정액)");
                        }
                        Axios.dataAccess({
                            url: "system/assets/selViewAssetsInfo.do",
                            methodType: "post",
                            paramData: {
                                assetsSn: props.form.assetsSn
                            },
                            onSuccessFn: (res3: any) => {
                                Axios.dataAccess({
                                    url: "api/system/broker/callApi.do",
                                    methodType: "post",
                                    paramType: "object",
                                    paramData: {
                                        apiName: "taxcalc",
                                        tailUrl: "acqstax/housenum",
                                        paramStr: hshldSn + "?acqs_de=" + res3.item.acqsDe
                                    },
                                    onSuccessFn: (res4: any) => {
                                        setHouseNum(res4.item.rtn_list[0].house_num);
                                        selAcqstaxQestnList("first");
                                        AppContext.hideSpinner();
                                    }
                                });
                                AppContext.hideSpinner();
                            }
                        });
                    }
                });
            }
        });
    }, [hshldSn, props.form.assetsSn, selAcqstaxQestnList]);

    return (
        <React.Fragment>
            <AssetsEditTab
                wizard={props.wizard}
                assetsSn={props.form.assetsSn}
                estateSttus={props.form.estateSttus}
                estateDetailSe={estateDetailSe}
                acqsCause={acqsCause}
                acqsSrc={acqsSrc}
                currStepCode={1006}
            />
            <div className='w1210 mh500 mt30'>
                <div className="dpFlx aiC jcSb mb20">
                    <div className='dpFlx jcL aiC'>
                        <span className='SpoqaHanSansNeo-Bold fs15 ls075 fc222222'>세액계산 질문</span>
                    </div>
                </div>
                {renderFlag && acqstaxQestnList.length === 0 ?
                    <div className="dpFlx w100p jcC pt20 pb20">
                        <span className="fs15 ls1 fmForest-b">해당하는 질문 정보가 없습니다.</span>
                    </div>
                    : <></>
                }
                {!renderFlag && acqstaxQestnList.length === 0 ?
                    <div className="dpFlx w100p jcC pt30">
                        <span className="fs15 ls1 fmForest-b">질문 정보를 불러오는 중입니다.</span>
                    </div>
                    : <></>
                }
                <table className={"forest-form-table " + (acqstaxQestnList.length === 0 ? "dpNone" : "")}>
                    <colgroup>
                        <col className="w250" />
                        <col />
                    </colgroup>
                    <tbody>
                        {acqstaxQestnList.map((item: any, idx: number) =>
                            <tr key={idx} className="h64">
                                <th className="taC">
                                    <div className="dpFlx aiC jcC">
                                        {item.qestnText}
                                        {item.assetsQestnSn === 57 ? qestn57SubTitle : ""}
                                        {[56, 57].indexOf(item.assetsQestnSn) !== -1 ?
                                            <img
                                                src={require('img/common/icon_info_fill_20.png')}
                                                alt="icon_info_fill_20"
                                                className='pl3 csP'
                                                onClick={() => {
                                                    if (item.assetsQestnSn === 56) {
                                                        setQestn56Open(true);
                                                    } else if (item.assetsQestnSn === 57) {
                                                        setQestn57Open(true);
                                                    }
                                                }}
                                            />
                                        : <></>}
                                    </div>
                                </th>
                                <td>
                                    {item.assetsQestnSn === 44 ?
                                        <ImageRadioBox
                                            codeList={Util.getCodeArray("1036")}
                                            value={acqstaxQestn44 || item.answerCodeValue}
                                            btnClass="w24 h24 pr5"
                                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                            interval={20}
                                            onChange={(data: string) => {
                                                setAcqstaxQestn44(data);
                                                if (acqstaxQestn44 !== data) {
                                                    selAcqstaxQestnList("change");
                                                }
                                            }}
                                        />
                                    : <></>}
                                    {item.assetsQestnSn === 56 ?
                                        <ImageRadioBox
                                            codeList={Util.getCodeArray(qestn56CodeTy)}
                                            value={acqstaxQestn56 || item.answerCodeValue}
                                            btnClass="w24 h24 pr5"
                                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                            interval={20}
                                            onChange={(data: string) => {
                                                setAcqstaxQestn56(data);
                                                setModFlag(true);
                                            }}
                                        />
                                    : <></>}
                                    {item.assetsQestnSn === 45 ?
                                        <React.Fragment>
                                            <div className="dpFlx aiC">
                                                <ImageRadioBox
                                                    codeList={Util.getCodeArray("1051")}
                                                    value={acqstaxQestn45 || item.answerCodeValue}
                                                    btnClass="w24 h24 pr5"
                                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                                    interval={20}
                                                    onChange={(data: string) => {
                                                        setAcqstaxQestn45(data);
                                                        setModFlag(true);
                                                        if (acqstaxQestn45 !== data) {
                                                            selAcqstaxQestnList("change");
                                                        }
                                                        if (["1001", "1002"].indexOf(data) !== -1) {
                                                            setQestn45Open(true);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <Modal modalOpen={qestn45Open} setModalOpen={setQestn45Open} title={item.qestnText} dim={true} visible={true}>
                                                <Qestn45Modal setQestn45Open={setQestn45Open} />
                                            </Modal>
                                        </React.Fragment>
                                    : <></>}
                                    {item.assetsQestnSn === 46 ?
                                        <ImageRadioBox
                                            codeList={Util.getCodeArray("1052")}
                                            value={acqstaxQestn46 || item.answerCodeValue}
                                            btnClass="w24 h24 pr5"
                                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                            interval={20}
                                            onChange={(data: string) => {
                                                setAcqstaxQestn46(data);
                                                setModFlag(true);
                                                if (acqstaxQestn46 !== data) {
                                                    selAcqstaxQestnList("change");
                                                }
                                            }}
                                        />
                                    : <></>}
                                    {item.assetsQestnSn === 57 ?
                                        <NumberTextBox
                                            value={acqstaxQestn57 || item.answerCodeValue}
                                            onChange={(data: string) => {
                                                setAcqstaxQestn57(data);
                                                setModFlag(true);
                                            }}
                                            borderClass="bgcWhite w300 h40 pr15 bd1"
                                            thousandsGroupStyle="thousand"
                                            thousandSeparator=","
                                            maxLength={15}
                                            inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                            unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                                            unitText="원"
                                        />
                                    : <></>}
                                    {item.assetsQestnSn === 47 ?
                                        <React.Fragment>
                                        <div className="dpFlx aiC">
                                            <ImageRadioBox
                                                codeList={Util.getCodeArray("1036")}
                                                value={acqstaxQestn47 || item.answerCodeValue}
                                                btnClass="w24 h24 pr5"
                                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                                interval={20}
                                                onChange={(data: string) => {
                                                    setAcqstaxQestn47(data);
                                                    setModFlag(true);
                                                    if (acqstaxQestn47 !== data) {
                                                        selAcqstaxQestnList("change");
                                                    }
                                                    if (data === "Y") {
                                                        setQestn47Open(true);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <Modal modalOpen={qestn47Open} setModalOpen={setQestn47Open} title={item.qestnText} dim={true} visible={true}>
                                            <Qestn47Modal setQestn47Open={setQestn47Open} />
                                        </Modal>
                                    </React.Fragment>
                                    : <></>}
                                    {item.assetsQestnSn === 48 ?
                                        <React.Fragment>
                                            <div className="dpFlx aiC">
                                                <ImageRadioBox
                                                    codeList={Util.getCodeArray("1036")}
                                                    value={acqstaxQestn48 || item.answerCodeValue}
                                                    btnClass="w24 h24 pr5"
                                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                                    interval={20}
                                                    onChange={(data: string) => {
                                                        if (data === "N") {
                                                            setAcqstaxQestn48(data);
                                                            if (acqstaxQestn48 !== data) {
                                                                selAcqstaxQestnList("change");
                                                            }
                                                        }
                                                        setModFlag(true);

                                                        if ((acqstaxQestn48 === "N" || Util.isEmpty(acqstaxQestn48)) && data === "Y") {
                                                            AppContext.openAlert({
                                                                title: "생애최초주택구입에 대한 취득세 감면 안내",
                                                                msg:
                                                                    "○ 대상주택 : 실거래가액 12억원 이하인 주택\n" +
                                                                    "○ 소득기준 : 없음\n" +
                                                                    "○ 본인·배우자 주택을 소유한 사실 업음\n" +
                                                                    "○ 200만원 한도 취득세 감면\n" +
                                                                    "○ 추징사유\n" +
                                                                    "- 주택 취득일로부터 3개월 이내에 전입 및 거주하지 않은 주택\n" +
                                                                    "- 주택 취득일로부터 3개월 이내에 추가로 주택구입(상속제외)한 경우\n" +
                                                                    "- 전입 및 거주기간이 3년 미만인 상태에서 매각/증여(배우자에게 매각하거나 증여한 경우 제외)하거나 다른 용도(임대포함)로 사용한 주택"
                                                                    ,
                                                                icon: "check",
                                                                closeText: "아니오",
                                                                confirmText: "예",
                                                                handleConfirm: () => {
                                                                    setAcqstaxQestn48("Y");
                                                                    selAcqstaxQestnList("change");
                                                                },
                                                                handleClose: () => {
                                                                    setAcqstaxQestn48("N");
                                                                }
                                                            });
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <Modal modalOpen={qestn48Open} setModalOpen={setQestn48Open} title={item.qestnText} dim={true} visible={true}>
                                                <Qestn48Modal setQestn48Open={setQestn48Open} />
                                            </Modal>
                                        </React.Fragment>
                                    : <></>}
                                    {item.assetsQestnSn === 49 ?
                                        <ImageRadioBox
                                            codeList={Util.getCodeArray("1036")}
                                            value={acqstaxQestn49 || item.answerCodeValue}
                                            btnClass="w24 h24 pr5"
                                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                            interval={20}
                                            onChange={(data: string) => {
                                                setAcqstaxQestn49(data);
                                                setModFlag(true);
                                                if (acqstaxQestn49 !== item) {
                                                    selAcqstaxQestnList("change");
                                                }
                                            }}
                                        />
                                    : <></>}
                                    {item.assetsQestnSn === 50 ?
                                        <ImageRadioBox
                                            codeList={Util.getCodeArray("1053")}
                                            value={acqstaxQestn50 || item.answerCodeValue}
                                            btnClass="w24 h24 pr5"
                                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                            interval={20}
                                            onChange={(data: string) => {
                                                setAcqstaxQestn50(data);
                                                setModFlag(true);
                                                if (acqstaxQestn50 !== data) {
                                                    selAcqstaxQestnList("change");
                                                }
                                            }}
                                        />
                                    : <></>}
                                    {item.assetsQestnSn === 51 ?
                                        <ImageRadioBox
                                            codeList={Util.getCodeArray("1054")}
                                            value={acqstaxQestn51 || item.answerCodeValue}
                                            btnClass="w24 h24 pr5"
                                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                            interval={20}
                                            onChange={(data: string) => {
                                                setAcqstaxQestn51(data);
                                                setModFlag(true);
                                                if (acqstaxQestn51 !== data) {
                                                    selAcqstaxQestnList("change");
                                                }
                                            }}
                                        />
                                    : <></>}
                                    {item.assetsQestnSn === 85 ?
                                        <ImageRadioBox
                                            codeList={Util.getCodeArray("1069")}
                                            value={acqstaxQestn85 || item.answerCodeValue}
                                            btnClass="w24 h24 pr5"
                                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                            interval={20}
                                            onChange={(data: string) => {
                                                setAcqstaxQestn85(data);
                                                setModFlag(true);
                                            }}
                                        />
                                    : <></>}
                                    {item.assetsQestnSn === 86 ?
                                        <ImageRadioBox
                                            codeList={Util.getCodeArray("1077")}
                                            value={acqstaxQestn86 || item.answerCodeValue}
                                            btnClass="w24 h24 pr5"
                                            labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                            interval={20}
                                            onChange={(data: string) => {
                                                setAcqstaxQestn86(data);
                                                setModFlag(true);
                                            }}
                                        />
                                    : <></>}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <AssetsButton propsForm={props.form} goNextStep={goNextStep} goPrevStep={goPrevStep} />
            </div>
            <Modal modalOpen={qestn56Open} setModalOpen={setQestn56Open} title="분양권 취득당시 세대주택수" dim={true} visible={true}>
                <Qestn56Modal setQestn56Open={setQestn56Open} />
            </Modal>
            <Modal modalOpen={qestn57Open} setModalOpen={setQestn57Open} title="과세표준 정의" dim={true} visible={true}>
                <Qestn57Modal setQestn57Open={setQestn57Open} assetsSn={props.form.assetsSn} />
            </Modal>
        </React.Fragment>
    )
}
export default AssetsAcqQestn;