/***********************************************************************************
*** Law16 ** 조세특례제한법 98조의 5 수도권 밖의 지역에 있는 미분양주택의 취득자에 대한 양도소득세의 특례 Modal
************************************************************************************/
import React from "react";
import LawCndConfirm from "./ui/LawCndConfirm";

interface Law16Props {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}

export default function Law16({ setModalOpen, setLawModalTitle, callBack, callMode }: Law16Props) {
    React.useEffect(() => {
        setLawModalTitle("조세특례제한법 98조의 5 수도권 밖의 지역에 있는 미분양주택의 취득자에 대한 양도소득세의 특례");
    }, [setLawModalTitle]);

    return (
        <React.Fragment>
            <div className='w100p hAuto ofA mb30'>
                <table className={
                    'forest-list-table bdt1 bdb1 bdcDDDDDD ' +
                    'th-fm-SpoqaHanSansNeo-Medium th-fs14 th-fc222222 th-bdcDDDDDD th-ls07 th-bdr1 th-bdb1 ' +
                    'td-fm-SpoqaHanSansNeo-Regular td-fs15 td-fc666666 td-bdcDDDDDD td-ls075'
                }>
                   <colgroup>
                        <col className='w80'></col>
                        <col className='w100'></col>
                        <col className='w80'></col>
                        <col className='w120'></col>
                        <col className='w80'></col>
                        <col className='w60'></col>
                        <col className='w80'></col>
                        <col className='w80'></col>
                        <col className='w100'></col>
                        <col className='w100'></col>
                        <col className='w100'></col>
                        <col className='w120'></col>
                    </colgroup>
                    <tbody>
                        <tr className='h40'>
                            <th rowSpan={2}>구분</th>
                            <th rowSpan={2}>주택구분</th>
                            <th rowSpan={2}>주택규모</th>
                            <th rowSpan={2}>취득시기</th>
                            <th rowSpan={2}>지역기준</th>
                            <th colSpan={6}>추가질문</th>
                            <th rowSpan={2}>혜택<br/>(5년간 양도세 감면)</th>
                        </tr>
                        <tr>
                            <th>최초계약</th>
                            <th>보유기간 및 임대기간</th>
                            <th>미분양시점</th>
                            <th>입주사실</th>
                            <th>필수서류</th>
                            <th className='bdr1-i bdcDDDDDD-i'>분양가 인하율</th>
                        </tr>
                        <tr className='h40'>
                            <th rowSpan={3}>개별사항</th>
                            <td rowSpan={3}>분양권에 의한 아파트 중 미분양</td>
                            <td rowSpan={3}>-</td>
                            <td rowSpan={3}>2010.5.14. ~ 2011.4.30.<br/>(계약체결 + 계약금지급포함)</td>
                            <td rowSpan={3}>2010.02.11. 현재 비수도권</td>
                            <td rowSpan={3}>여</td>
                            <td rowSpan={3}>-</td>
                            <td rowSpan={3}>2010.2.11. 현재 미분양주택</td>
                            <td rowSpan={3}>매매계약일 현재 입주사실이 없어야 함</td>
                            <td rowSpan={3}>지자체 확인서 및 지자체 날인 매매계약서</td>
                            <td>10% 이하</td>
                            <td>60% 감면</td>
                        </tr>
                        <tr className='h40'>
                            <td>10% 초과</td>
                            <td>80% 감면</td>
                        </tr>
                        <tr className='h40'>
                            <td>20% 초과</td>
                            <td>100% 감면</td>
                        </tr>
                        <tr className='h110'>
                            <th>공통사항</th>
                            <td className='taL pt15 pb15 pl15' colSpan={11}>
                                1. 비과세 판정시 주택수 제외<br/>
                                2. 다주택 중과배제(유예)<br/>
                                3. 98조의 2와 동시 적용시 선택하여 적용<br/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {callMode === "modify" ? <LawCndConfirm setModalOpen={setModalOpen} callBack={callBack} /> : <></> }
        </React.Fragment>
    )
}