/*******************************************************************************
 You are strictly prohibited to copy, disclose, distribute, modify, or use
 this program in part or as a whole without the prior written consent of
 CallTax. CallTax, owns the intellectual property rights in and to this program.
 (Copyright © 2023 CallTax. All Rights Reserved| Confidential)

 Specification
    1. Program ID    : LawordCndDialog.tsx
    2. Program Title : 법령 조건 생성, 수정 Modal
    3. Created by    : JH Lee
    4. Create Date   : 2024.02.19
    5. Reference     :
        - LawordMng.tsx 페이지에서 Modal 호출
*******************************************************************************/
import React         from "react";
import Axios         from 'js/common/Axios';
import Util          from "js/common/Util";
import TextBox       from 'js/common/ui/input/TextBox';
import SelectBox     from "js/common/ui/input/SelectBox";
import ImageRadioBox from "js/common/ui/input/ImageRadioBox";
import AppContext    from "js/common/AppContext";
import NumberTextBox from "js/common/ui/input/NumberTextBox";
import DatePickerBox from "js/common/ui/input/DatePickerBox";

interface LawordCndDialogProps {
    setModalCndOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setLawordCndSelTime: React.Dispatch<React.SetStateAction<Date>>;
    callMode: string;
    lawordSn: number;
    lawordNm: string;
    lawordDetailSn:number;
    lawordDetailNm: string;
    lawordCndDetailSn:number;
}

function LawordCndDialog({ setModalCndOpen, setLawordCndSelTime, callMode, lawordSn, lawordNm, lawordDetailSn, lawordDetailNm, lawordCndDetailSn}: LawordCndDialogProps) {
    // 법령 조건 Main Table
    const [lawordCndSn      , setLawordCndSn      ] = React.useState<number>(0 ); // 법령조건일련번호
    const [lawordCndNm      , setLawordCndNm      ] = React.useState<string>(""); // 법령조건명
    const [lawordCndDc      , setLawordCndDc      ] = React.useState<string>(""); // 법령조건설명
    const [lawordCndTy      , setLawordCndTy      ] = React.useState<string>(""); // 법령조건유형
    const [lawordCndColumn  , setLawordCndColumn  ] = React.useState<string>(""); // 법령조건컬럼
    const [lawordCndCodeTy  , setLawordCndCodeTy  ] = React.useState<string>(""); // 법령조건값코드타입
    const [lawordCndCodeTyNm, setLawordCndCodeTyNm] = React.useState<string>(""); // 법령조건값코드타입명


    // 법령 조건 Detail Table
    const [lawordCndValue1  , setLawordCndValue1  ] = React.useState<string>(""); //법령조건값1
    const [lawordCndValue2  , setLawordCndValue2  ] = React.useState<string>(""); //법령조건값2
    const [cndDesc          , setCndDesc          ] = React.useState<string>(""); //조건설명
    const [useAt            , setUseAt            ] = React.useState<string>(""); //사용여부

    const [cndNmDataArr  , setCndNmDataArr  ] = React.useState<any[]>([]); // 법령조건명 Code Arrary

    // 조건값1, 조건값2 입력 Field 설정
    const cndValInput: any[] = [
        {inType: "inText"  , cndSn: ["7", "10", "17", "18", "19", "24", "28", "29", "30", "33", "37", "38", "44", "47"]},
        {inType: "inNumber", cndSn: ["4", "11", "21", "25", "26", "36", "42", "43", "50", "53"]},
        {inType: "inDate"  , cndSn: ["2", "15", "20", "35", "41", "51"]},
        {inType: "inRadio" , cndSn: ["8", "9", "14", "22", "45", "48", "49", "52"]}
    ];

    // 조건값1, 조건값2 입력 Field 표시 여부
    const [textInput  , setTextInput  ] = React.useState<string>("");
    const [numberInput, setNumberInput] = React.useState<string>("dpNone");
    const [dateInput  , setDateInput  ] = React.useState<string>("dpNone");
    const [radioInput , setRadioInput ] = React.useState<string>("dpNone");

    // 법령 조건명 Code Arrary Setting/수정 모드 정보 조회 & Setting 
    React.useEffect(() => {
        Axios.dataAccess({
            url: "admin/sysmng/laword/selLawordCndCode.do",
            methodType: "post",
            paramType: "object",
            onSuccessFn: (res: any) => {
                setCndNmDataArr(res.item);  // 법령조건명 코드 Array 생성
            }
        });
    }, []);

    // 법령 조건 Detail 정보 조회 & seeting
    React.useEffect(() => {
        if (callMode === "upd") {
            Axios.dataAccess({
                url: "admin/sysmng/laword/selLawordDetail.do",
                methodType: "post",
                paramType: "object",
                paramData: { lawordSn      : lawordSn      , // 법령 일련번호
                             lawordDetailSn: lawordDetailSn  // 법령상세일련번호
                },
                onSuccessFn: (res: any) => {
                    // setLawordDetailNm(res.item.lawordDetailNm); // 법령상세명
                    // setLawordDetailDc(res.item.lawordDetailDc); // 법령상세설명
                    // setExcptAt       (res.item.excptAt       ); // 특례여부
                    // setValidYearNum  (res.item.validYearNum  ); // 유효연도햇수
                    // setRdcRate       (res.item.rdcRate       ); // 감면율
                    // setHseExcptAt    (res.item.hseExcptAt    ); // 주택수제외여부
                    setUseAt         (res.item.useAt         ); // 사용여부
                }
            });
        }
    }, [callMode, setModalCndOpen, lawordSn, lawordDetailSn]);

    // 법령 조건 Detail 정보 조회 & seeting
    React.useEffect(() => {
        // 수정 모드만 실행
        if (callMode === "upd") {
            Axios.dataAccess({
                url: "admin/sysmng/laword/selLawordCndDetail.do",
                methodType: "post",
                paramType: "object",
                paramData: { 
                      lawordSn         : lawordSn           // 법령 일련번호
                    , lawordDetailSn   : lawordDetailSn     // 법령상세 일련번호
                    , lawordCndDetailSn: lawordCndDetailSn  // 법령조건 상세일련번호
                },
                onSuccessFn: (res: any) => {
                    // 법령 조건 Main 정보 setting
                    setLawordCndNm      (res.item.lawordCndSn      ); // 법령조건명 - selectbox code : 법령조건 일련번호로 Mapping
                    setLawordCndSn      (res.item.lawordCndSn      ); // 법령조건일련번호
                    setLawordCndTy      (res.item.lawordCndTy      ); // 법령조건유형
                    setLawordCndDc      (res.item.lawordCndDc      ); // 법령조건설명
                    setLawordCndColumn  (res.item.lawordCndColumn  ); // 법령조건컬럼
                    setLawordCndCodeTy  (res.item.lawordCndCodeTy  ); // 법령조건값코드타입
                    setLawordCndCodeTyNm(res.item.lawordCndCodeTyNm); // 법령조건값코드타입명

                    // 법령 조건 Detail 정보 setting
                    setLawordCndValue1  (res.item.lawordCndValue1); //법령조건값1
                    setLawordCndValue2  (res.item.lawordCndValue2); //법령조건값2
                    setCndDesc          (res.item.cndDesc        ); //조건설명
                    setUseAt            (res.item.useAt          ); //사용여부
                }
            });
        }
    }, [callMode, setModalCndOpen, lawordSn, lawordDetailSn, lawordCndDetailSn]);

    // 조건값1, 조건값2 입력 Filed 설정
    const setInputType = (lawordCndNm: string) => {
        // 배열 출력 (forEach)
        cndValInput.forEach((ele: any) => {
            ele.cndSn.forEach((item: any) => {
                if (item === lawordCndNm.toString()) {
                        setLawordCndValue1("");
                        setLawordCndValue2("");
                    if (ele.inType === "inText") {
                        setTextInput("");
                        setNumberInput("dpNone");
                        setDateInput("dpNone");
                        setRadioInput("dpNone");
                    } else if (ele.inType === "inNumber") {
                        setTextInput("dpNone");
                        setNumberInput("");
                        setDateInput("dpNone");
                        setRadioInput("dpNone");
                    } else if (ele.inType === "inDate") {
                        setTextInput("dpNone");
                        setNumberInput("dpNone");
                        setDateInput("");
                        setRadioInput("dpNone");
                    } else if (ele.inType === "inRadio") {
                        setTextInput("dpNone");
                        setNumberInput("dpNone");
                        setDateInput("dpNone");
                        setRadioInput("");
                    } else {
                        setTextInput("dpNone");
                        setNumberInput("dpNone");
                        setDateInput("dpNone");
                        setRadioInput("dpNone");
                    }
                }
            });
        });
    }

    // 조건명 선택에 따라 법령 조건 Info 정보 설정
    const setLawordCndInfoVal = (lawordCndNm:string) => {
        setLawordCndSn(Number(lawordCndNm)); // 법령 조건 일련번호 - Code Arrary의 Value
        setInputType(lawordCndNm); // 조건값1, 조건값2 입력 Filed 설정

        // 법령 조건 info table select
        Axios.dataAccess({
            url: "admin/sysmng/laword/selLawordCndInfo.do",
            methodType: "post",
            paramType: "object",
            paramData: { lawordCndSn: Number(lawordCndNm) },
            onSuccessFn: (res: any) => {
                // 법령 조건 Main value setting
                setLawordCndDc      (res.item.lawordCndDc      ); // 법령조건설명
                setLawordCndTy      (res.item.lawordCndTy      ); // 법령조건유형
                setLawordCndColumn  (res.item.lawordCndColumn  ); // 법령조건컬럼
                setLawordCndCodeTy  (res.item.lawordCndCodeTy  ); // 법령조건값코드타입
                setLawordCndCodeTyNm(res.item.lawordCndCodeTyNm); // 법령조건값 코드타입명
            }
        });
    }

    // 저장 버튼 클릭
    const onSubmit = () => {
        // 사용여부
        if (Util.isEmpty(useAt)) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "사용여부를 선택해 주세요.",
                icon: "warning"
            });
            return;
        }

        // 조건값1 or 조건값2 확인
        if (Util.isEmpty(lawordCndValue1) && Util.isEmpty(lawordCndValue1) ) {
            AppContext.openAlert({
                title: "필수입력",
                msg: "조건값1 또는 조건값2를 입력해 주세요.",
                icon: "warning"
            });
            return;
        }

        let modName = "";
        let actUrl = "";
        if (callMode === "ins") {
            modName = "추가";
            actUrl  = "insLawordCndDetail.do";
        } else if (callMode === "upd") {
            modName = "수정";
            actUrl  = "updLawordCndDetail.do";
        } else {
            AppContext.openAlert({
                title: "시스템 오류",
                msg: "호출 구분 값이 없습니다.",
                icon: "warning"
            });
            return;
        }

        AppContext.openAlert({
            title: "법령 조건 Detail " + modName,
            msg: "법령 조건 Detail을 " + modName + "하시겠습니까?",
            icon: "check",
            confirmText: modName,
            handleConfirm: () => {
                Axios.dataAccess({
                    url: "admin/sysmng/laword/" + actUrl,
                    methodType: "post",
                    paramType: "json",
                    paramData: {
                         lawordSn         : lawordSn           // 법령일련번호          
                       , lawordDetailSn   : lawordDetailSn     // 법령상세일련번호      
                       , lawordCndSn      : lawordCndSn        // 법령조건일련번호
                       , lawordCndDetailSn: lawordCndDetailSn  // 법령조건 상세일련번호
                       , lawordCndValue1  : lawordCndValue1    // 법령조건값1
                       , lawordCndValue2  : lawordCndValue2    // 법령조건값2
                       , cndDesc          : cndDesc            // 조건설명
                       , useAt            : useAt              // 사용여부
                    },
                    onSuccessFn: () => {
                        setModalCndOpen(false);
                        setLawordCndSelTime(new Date());   // 법령 조건 리스트 재조회 처리용
                    }
                });
            }
        });
    }

    return (
        <React.Fragment>
            {/* 왼쪽, 오른쪽으로 구분 */}
            <div className='w860 h100p bdBox dpFlx aiC'>

                {/* 왼쪽 입력부분 Start */}
                <div className='w50p'>
                    {/* 법령 일련번호 */}
                    <div className='dpFlx h40 pb10'>
                        <div className='w110 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>일련번호</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={lawordSn.toString()}
                                width={300}
                                height={40}
                                fontSize={15}
                                maxLength={30}
                                // placeholder="감면율을 입력해 주세요."
                                // onChangeFunc={(text: string) => setLawordSn(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                                mode="disabled"
                            />
                        </div>
                    </div>

                    {/* 법령명 */}
                    <div className='h40 dpFlx pb10'>
                        <div className='w110 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>법령명</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={lawordNm}
                                width={300}
                                height={40}
                                fontSize={15}
                                maxLength={30}
                                // placeholder="유효연도햇수를 입력해 주세요."
                                // onChangeFunc={(text: string) => setLawordNm(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                                mode="disabled"
                            />
                        </div>
                    </div>

                    {/* 법령조건명 : selectbox */}
                    <div className='dpFlx h40 pb10'>
                        <div className='w110 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>법령 조건명</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <SelectBox
                                value={lawordCndNm}
                                codeArray={cndNmDataArr}        // 법령 조건면 코드 Data Array
                                placeholder="법령 조건명을 선택하세요."
                                width={300}
                                height={40}
                                fontSize={15}
                                paddingRight={5}
                                onChangeFunc={(data: string) => {
                                    setLawordCndNm(data);
                                    setLawordCndInfoVal(data);
                                }}
                                border="1px solid #dddddd"
                                disabled={callMode === "upd" ? true : false}    // 수정 Mode 수정 불가 처리
                            />
                        </div>
                    </div>

                    {/* 법령조건 유형 : selectbox */}
                    <div className='dpFlx h40 pb10'>
                        <div className='w110 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>법령조건 유형</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <SelectBox
                                value={lawordCndTy}
                                codeArray={Util.getCodeArray("1022")}
                                placeholder="법령 조건명을 선택"
                                width={200}
                                height={40}
                                fontSize={15}
                                paddingRight={5}
                                // onChangeFunc={(data: string) => { setLawordCndTy(data); }}
                                border="1px solid #dddddd"
                                disabled={true}
                            />
                            <TextBox
                                value={lawordCndTy}
                                width={100}
                                height={40}
                                fontSize={15}
                                maxLength={30}
                                // placeholder="법령 조건명을 선택"
                                onChangeFunc={(text: string) => setLawordCndTy(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                                mode="disabled"
                            />
                        </div>
                    </div>

                    {/* 법령조건컬럼 */}
                    <div className='h40 dpFlx pb10'>
                        <div className='w110 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>법령조건 컬럼</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={lawordCndColumn}
                                width={300}
                                height={40}
                                fontSize={15}
                                maxLength={30}
                                // placeholder="법령 조건명을 선택하면 자동으로 입력됩니다."
                                onChangeFunc={(text: string) => setLawordCndColumn(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                                mode="disabled"
                            />
                        </div>
                    </div>

                    {/* 법령조건 상세일련번호 */}
                    <div className='h40 dpFlx pb10'>
                        <div className='w110 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>조건상세 일련번호</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={lawordCndDetailSn ===0 ? "" : lawordCndDetailSn.toString()}
                                width={300}
                                height={40}
                                fontSize={15}
                                maxLength={30}
                                placeholder="자동입력"
                                // onChangeFunc={(text: string) => setLawordCndDetailSn(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                                mode="disabled"
                            />
                        </div>
                    </div>
                </div>
                {/* 왼쪽 입력부분 End */}

                {/* 오른쪽 입력부분 Start */}
                <div className='w50p pl30'>
                    {/* 법령 상세일련번호 */}
                    <div className='dpFlx h40 pb10'>
                        <div className='w110 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>상세 일련번호</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={lawordDetailSn.toString()}
                                width={300}
                                height={40}
                                fontSize={15}
                                maxLength={30}
                                // placeholder="상세 일련번호을 입력해 주세요."
                                // onChangeFunc={(text: string) => setLawordDetailSn(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                                mode="disabled"
                            />
                        </div>
                    </div>

                    {/* 법령 상세명 */}
                    <div className='h40 dpFlx pb10'>
                        <div className='w110 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>법령 상세명</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={lawordDetailNm}
                                width={300}
                                height={40}
                                fontSize={15}
                                maxLength={30}
                                // placeholder="법령 상세명을 입력해 주세요."
                                // onChangeFunc={(text: string) => setLawordDetailNm(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                                mode="disabled"
                            />
                        </div>
                    </div>

                    {/* 법령조건 일련번호 */}
                    <div className='h40 dpFlx pb10'>
                        <div className='w110 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>법령조건 일련번호</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={lawordCndSn === 0 ? "" : lawordCndSn.toString()}
                                width={300}
                                height={40}
                                fontSize={15}
                                maxLength={30}
                                // placeholder="유효연도햇수를 입력해 주세요."
                                // onChangeFunc={(text: string) => setLawordCndSn(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                                mode="disabled"
                            />
                        </div>
                    </div>

                    {/* 법령조건 설명 */}
                    <div className='h40 dpFlx pb10'>
                        <div className='w110 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>법령조건 설명</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={lawordCndDc}
                                width={300}
                                height={40}
                                fontSize={15}
                                maxLength={30}
                                // placeholder="법령 조건명을 선택하면 자동으로 입력됩니다."
                                //onChangeFunc={(text: string) => setLawordCndDc(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                                mode="disabled"
                            />
                        </div>
                    </div>

                    {/* 법령조건값 코드타입 */}
                    <div className='h40 dpFlx pb10'>
                        <div className='w110 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>조건값 코드타입</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>

                            <TextBox
                                value={lawordCndCodeTyNm}
                                width={200}
                                height={40}
                                fontSize={15}
                                maxLength={30}
                                // placeholder="조건값 코드타입를 입력해 주세요."
                                onChangeFunc={(text: string) => setLawordCndCodeTyNm(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                                mode="disabled"
                            />

                            <TextBox
                                value={lawordCndCodeTy}
                                width={100}
                                height={40}
                                fontSize={15}
                                maxLength={30}
                                // placeholder="조건값 코드타입를 입력해 주세요."
                                // onChangeFunc={(text: string) => setLawordCndCodeTy(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                                mode="disabled"
                            />
                        </div>
                    </div>

                    {/* 사용여부 */}
                    <div className='h40 dpFlx pb10'>
                        <div className='w110 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>사용여부</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <ImageRadioBox
                                codeList={Util.getCodeArray("1036")}    // 예, 아니오
                                value={useAt}
                                setValue={setUseAt}
                                btnClass="w24 h24 pr5"
                                labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                interval={20}
                            />
                        </div>
                    </div>
                </div>
                {/* 오른쪽 입력부분 End */}
            </div>

            {/* 한 row에 1개씩 element */}
            <div className='w860 h100p bdBox dpFlx aiC'>
                <div className='w100p'>

                    {/* 법령조건값1 */}
                    <div className='h40 dpFlx pb10'>
                        <div className='w110 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>조건값1</span>
                        </div>
                        <div className={textInput}>
                            <div className='dpFlx aiC bdBox'>
                                <TextBox
                                    value={lawordCndValue1}
                                    width={200}
                                    height={40}
                                    fontSize={15}
                                    maxLength={30}
                                    placeholder="조건값1을 입력해 주세요."
                                    onChangeFunc={(text: string) => setLawordCndValue1(text)}
                                    className='bd1'
                                    inputClassName='pl10 pr10 bgcWhite'
                                />
                            </div>
                        </div>

                        <div className={textInput}>
                            <div className='dpFlx aiC bdBox'>
                                <SelectBox
                                    value=""
                                    codeArray={Util.getCodeArray(lawordCndCodeTy)}
                                    placeholder="조건값1을 선택해 주세요."
                                    width={250}
                                    height={40}
                                    fontSize={15}
                                    paddingRight={5}
                                    onChangeFunc={(data: string) => {
                                        setLawordCndValue1(data);
                                    }}
                                    border="1px solid #dddddd"
                                    // disabled={true}
                                />
                                <span className='SpoqaHanSansNeo-Regular fs13 ls07 fc222222 pl20'>2개이상을 선택할 경우 콤마(,)로 구분하여 입력하세요.</span>
                            </div>
                        </div>

                        <div className={numberInput}>
                            <div className='dpFlx aiC bdBox'>
                                <NumberTextBox
                                    value={lawordCndValue1}
                                    onChange={(data: string) => setLawordCndValue1(data)}
                                    borderClass="bgcWhite w300 h40 pr15 bd1"
                                    // thousandsGroupStyle="thousand"
                                    // thousandSeparator=","
                                    maxLength={15}
                                    inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                    unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                                    // unitText="원"
                                />
                            </div>
                        </div>

                        <div className={radioInput}>
                            <div className='dpFlx aiC bdBox pt10'>
                                <ImageRadioBox
                                    codeList={Util.getCodeArray("1036")}    // 예, 아니오
                                    value={lawordCndValue1}
                                    setValue={setLawordCndValue1}
                                    btnClass="w24 h24 pr5"
                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                    interval={20}
                                />
                            </div>
                        </div>

                        <div className={dateInput}>
                            <div className='dpFlx aiC bdBox'>
                                <DatePickerBox
                                    value={lawordCndValue1}
                                    width={300} height={40} fontSize={15} textAlign="left"
                                    onChange={(data: string) => {
                                        setLawordCndValue1(Util.dateToString(data, ""))
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                   {/* 법령조건값2 */}
                   <div className='h40 dpFlx pb10'>
                        <div className='w110 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>조건값2</span>
                        </div>
                        <div className={textInput}>
                            <div className='dpFlx aiC bdBox'>
                                <TextBox
                                    value={lawordCndValue2}
                                    width={200}
                                    height={40}
                                    fontSize={15}
                                    maxLength={30}
                                    placeholder="조건값2를 입력해 주세요."
                                    onChangeFunc={(text: string) => setLawordCndValue2(text)}
                                    className='bd1'
                                    inputClassName='pl10 pr10 bgcWhite'
                                />
                            </div>
                        </div>

                        <div className={textInput}>
                            <div className='dpFlx aiC bdBox'>
                                <SelectBox
                                    value=""
                                    codeArray={Util.getCodeArray(lawordCndCodeTy)}
                                    placeholder="조건값2를 선택해 주세요."
                                    width={250}
                                    height={40}
                                    fontSize={15}
                                    paddingRight={5}
                                    onChangeFunc={(data: string) => {
                                        setLawordCndValue2(data);
                                    }}
                                    border="1px solid #dddddd"
                                    // disabled={true}
                                />
                                <span className='SpoqaHanSansNeo-Regular fs13 ls07 fc222222 pl20'>2개이상을 선택할 경우 콤마(,)로 구분하여 입력하세요.</span>
                            </div>
                        </div>

                        <div className={numberInput}>
                            <div className='dpFlx aiC bdBox'>
                                <NumberTextBox
                                    value={lawordCndValue2}
                                    onChange={(data: string) => setLawordCndValue2(data)}
                                    borderClass="bgcWhite w300 h40 pr15 bd1"
                                    // thousandsGroupStyle="thousand"
                                    // thousandSeparator=","
                                    maxLength={15}
                                    inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                    unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                                    // unitText="원"
                                />
                            </div>
                        </div>

                        <div className={radioInput}>
                            <div className='dpFlx aiC bdBox pt10'>
                                <ImageRadioBox
                                    codeList={Util.getCodeArray("1036")}    // 예, 아니오
                                    value={lawordCndValue2}
                                    setValue={setLawordCndValue2}
                                    btnClass="w24 h24 pr5"
                                    labelClass="SpoqaHanSansNeo-Regular fs15 ls015 fc666666 lh20"
                                    interval={20}
                                />
                            </div>
                        </div>

                        <div className={dateInput}>
                            <div className='dpFlx aiC bdBox'>
                                <DatePickerBox
                                    value={lawordCndValue2}
                                    width={300} height={40} fontSize={15} textAlign="left"
                                    onChange={(data: string) => {
                                        setLawordCndValue2(Util.dateToString(data, ""))
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 법령 상세설명 */}
                    <div className='dpFlx h40 pb10'>
                        <div className='w110 dpFlx aiC bdBox'>
                            <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc222222'>법령 상세설명</span>
                        </div>
                        <div className='dpFlx aiC bdBox'>
                            <TextBox
                                value={cndDesc}
                                width={745}
                                height={40}
                                fontSize={15}
                                maxLength={30}
                                placeholder="법령 상세설명을 입력해 주세요."
                                onChangeFunc={(text: string) => setCndDesc(text)}
                                className='bd1'
                                inputClassName='pl10 pr10 bgcWhite'
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='w100p dpFlx aiC jcC pt40'>
                <div className='w210 h56 bgcF6F6F6 dpFlx aiC jcC csP' onClick={() => setModalCndOpen(false)}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>취소</span>
                </div>
                <div className='w210 h56 bgc17A840 dpFlx aiC jcC csP' onClick={onSubmit}>
                    <span className='SpoqaHanSansNeo-Medium fs16 ls07 fcWhite'>저장</span>
                </div>
            </div>
        </React.Fragment>
    )
}
export default LawordCndDialog;
