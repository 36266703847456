import React from "react";
import { useRecoilValue } from "recoil";
import * as Atom from "js/common/Atom";
import MuiListFilter from 'js/common/ui/list/MuiListFilter';
import ForestTableStyle from 'js/common/ui/style/ForestStyle';
import Util from "js/common/Util";
import AppContext from "js/common/AppContext";
import Axios from "js/common/Axios";
import Modal from 'js/common/ui/modal/Modal';
import ComptaxCalc from "./ComptaxCalc";
import NumberTextBox from "js/common/ui/input/NumberTextBox";
import AssetsShow from "../assets/show/AssetsShow";

function Comptax() {
    const hshldSn = useRecoilValue(Atom.hshldSn);
    const hshldNm = useRecoilValue(Atom.hshldNm);
    const [assetsDataArr, setAssetsDataArr] = React.useState<any[]>([]);
    const [apiAssetsDataArr, setApiAssetsDataArr] = React.useState<any[]>([]);
    const [taxInputModalOpen, setTaxInputModalOpen] = React.useState<boolean>(false); // 전년도 재산정보 입력 모달
    const [lstyrProptax, setLstyrProptax] = React.useState<string>("");
    const [lstyrComptax, setLstyrComptax] = React.useState<string>("");
    const [assetsSn, setAssetsSn] = React.useState<number>();
    const [comptaxCalcModalOpen, setComptaxCalcModalOpen] = React.useState<boolean>(false); // 재산세 결과 조회 모달
    const [compTax, setCompTax] = React.useState<any | undefined>();
    const [propTaxInCompTax, setPropTaxInCompTax] = React.useState<any[] | undefined>();
    const [assetsModalOpen, setAssetsModalOpen] = React.useState<boolean>(false); // 보유자산 조회 모달

    interface assetsPblntfPcProps {
        asset_sn: number
        price: number
    }
    const [assetsPblntfPcs, setAssetsPblntfPcs] = React.useState<assetsPblntfPcProps[]>([]);

    const bindAssetsPblntfPcs = (assetsSn: number, pblntfPc: string) => {
        const tempAssetsPblntfPcs = [] as assetsPblntfPcProps[];
        assetsPblntfPcs.forEach((assetsPblntfPc: assetsPblntfPcProps) => {
            if (assetsPblntfPc.asset_sn === assetsSn) {
                tempAssetsPblntfPcs.push({
                    asset_sn: assetsSn,
                    price: Number(Util.uncomma(pblntfPc))
                });
            } else {
                tempAssetsPblntfPcs.push(assetsPblntfPc);
            }
        });
        setAssetsPblntfPcs(tempAssetsPblntfPcs);
    }

    const openCalcInputModal = () => {
        AppContext.showSpinnerDim();
        setTaxInputModalOpen(true);
        Axios.dataAccess({
            url: "system/comptax/selAssetsList.do",
            methodType: "post",
            paramType: "json",
            paramData: {
                hshldSn: hshldSn,
                assetsSe: "1001",
                estateSttus: "1002",
                estateSe: "1001",
                routeKey: "comptax"
            },
            onSuccessFn: (res: any) => {
                setApiAssetsDataArr(res.list);
                const tempAssetsPblntfPcs = [] as assetsPblntfPcProps[];
                res.list.forEach((item: any) => {
                    const apiRslt = item.api[0];
                    let price = 0;
                    if (Util.isNotEmpty(apiRslt)) {
                        if (["1001", "1006"].indexOf(item.estateFom) !== -1) { // 공동주택
                            price = apiRslt.pblntfPc;
                        } else {
                            price = apiRslt.housePc;
                        }
                    }
                    tempAssetsPblntfPcs.push({
                        asset_sn: item.assetsSn,
                        price: price
                    });
                });
                setAssetsPblntfPcs(tempAssetsPblntfPcs);
                AppContext.hideSpinner();
            },
            onFailedFn: () => {
                AppContext.hideSpinner();
            }
        });
    }

    const selComptaxCalc = () => {
        let chkValFlag = 0;
        assetsPblntfPcs.forEach((assetsPblntfPc: assetsPblntfPcProps) => {
            if (Util.isEmpty(assetsPblntfPc.price) || assetsPblntfPc.price === 0) {
                chkValFlag++;
            }
        });
        if (chkValFlag > 0) {
            AppContext.openAlert({
                title: "공시가격 미입력",
                msg: "공시가격을 입력하세요.",
                icon: "check"
            });
            return;
        }
        setTaxInputModalOpen(false);
        Axios.dataAccess({
            url: "api/system/broker/callApiPost.do",
            methodType: "post",
            paramType: "json",
            paramData: {
                apiName: "taxcalc",
                tailUrl: "comptax/calctax",
                apiParam: {
                    "hshld_sn": hshldSn,
                    "prices": assetsPblntfPcs,
                    "layout_opt": "1",
                    "lstyr_proptax": Util.isEmpty(lstyrProptax) ? "0" : Util.uncomma(lstyrProptax),
                    "lstyr_comptax": Util.isEmpty(lstyrComptax) ? "0" : Util.uncomma(lstyrComptax)
                }
            },
            onSuccessFn: (res: any) => {
                if (Util.isEmpty(res.item.rtn_proptax_list)) {
                    setComptaxCalcModalOpen(false);
                    AppContext.openAlert({
                        title: "산출내역 없음",
                        msg: "산출된 내역이 없습니다.",
                        icon: "check",
                    });
                    return;
                }
                setCompTax(res.item.rtn_list[0]);
                setPropTaxInCompTax(res.item.rtn_proptax_list);
                setTaxInputModalOpen(false);
                setComptaxCalcModalOpen(true);
            },
            skipFailedAlert: true,
            onFailedFn: (res: any) => {
                AppContext.openAlert({
                    title: "과세 대상 안내",
                    msg: res._msg_,
                    icon: "check",
                });
                setComptaxCalcModalOpen(false);
            }
        });
    }

    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/assets/selAssetsList.do",
            methodType: "post",
            paramType: "json",
            paramData: {
                hshldSn: hshldSn,
                assetsSe: "1001",
                estateSttus: "1002",
                estateSe: "1001",
                routeKey: "comptax"
            },
            onSuccessFn: (res: any) => {
                setAssetsDataArr(res.list);
                AppContext.hideSpinner();
            }
        });
    }, [hshldSn]);

    return (
        <React.Fragment>
            <div className="w100p bgcF6F6F6">
                <div className="dpFlx flxdCol jcC aiC pt60 pb30">
                    <div className='taC pb30'>
                        <span className="dpBlock fcBlack fs38 ls266 SpoqaHanSansNeo-Medium">종합부동산세 계산</span>
                    </div>
                </div>
                <div className="dpFlx jcC pt30 pb110">
                    <div className='w1200'>
                        <div className="dpFlx h36 jcSb pb20">
                            <div className='dpFlx aiE'>
                                <img className="mr10" src={require('img/common/icon_step.png')} alt="icon_step" />
                                <span className='SpoqaHanSansNeo-Medium fs18 ls09 fc222222 pb3'>
                                    <span className='fc17A840'>{hshldNm}</span>
                                    <span className='fc222222'>님의 자산현황</span>
                                </span>
                            </div>
                            <div className="w100 h40 bgcWhite bdrd40 dpFlx jcC aiC csP pl5 bd1 bdc17A840" onClick={openCalcInputModal}>
                                <span className='SpoqaHanSansNeo-Medium fs14 ls07 fc17A840'>세액계산</span>
                                <img className="pl5" src={require('img/system/assets/icon_cal20_green.png')} alt="icon_cal20_green" />
                            </div>
                        </div>
                        <MuiListFilter tableInfo={{
                            tableHeadVsb: true,
                            styleType: ForestTableStyle,
                            fldInfs: [
                                { type: 'text', fldNm: 'rowNum', width: 65, headAlign: "center", dataAlign: "center", label: 'No.' },
                                { type: 'code', fldNm: 'estateDetailSe', width: 110, headAlign: "center", dataAlign: "center", label: '자산구분', codeTy: "1006" },
                                { type: 'code', fldNm: 'estatePrpos', width: 130, headAlign: "center", dataAlign: "center", label: '주택용도', codeTy: "1008" },
                                { type: 'code', fldNm: 'acqsCause', width: 130, headAlign: "center", dataAlign: "center", label: '취득원인', codeTy: "1035" },
                                { type: 'text', fldNm: 'fullRdnmAdres', wAuto: true, headAlign: "center", dataAlign: "left", label: '주소' },
                                { type: 'text', fldNm: 'acqsDe', width: 100, headAlign: "center", dataAlign: "center", label: '취득일' },
                                { type: 'el', width: 70, headAlign: "center", el: (item: any) => (
                                    <div className='dpFlx aiC csP' onClick={() => {
                                        setAssetsSn(item.assetsSn);
                                        setAssetsModalOpen(true);
                                    }}>
                                        <img className="csP pr3" src={require('img/common/icon_copy20.png')} alt="icon_copy20" />
                                        <span className="SpoqaHanSansNeo-Medium fs14 ls07 fc999999 tdU tuOs2">조회</span>
                                    </div>
                                )}
                            ],
                            ordFld: { name: "rowNum", direction: "asc" },
                            dataArr: assetsDataArr,
                            maxRowNums: [10, 20, 30],
                            trHeight: 48,
                            remainArea: false,
                            srchVsb: true,
                            paginateClass: "pt40",
                            headerVsb: true
                        }}/>
                    </div>
                </div>
            </div>
            <Modal modalOpen={taxInputModalOpen} setModalOpen={setTaxInputModalOpen} title="종부세 계산" dim={true} visible={true}>
                <div className="w700">
                    <div className="dpFlx jcSb">
                        <div className='dpFlx aiC jcSb mb10 w330'>
                            <span className='fs14 ls07 SpoqaHanSansNeo-Medium fc222222 mr10'>전년도 재산세액</span>
                            <NumberTextBox
                                value={lstyrProptax}
                                onChange={(data: string) => setLstyrProptax(data)}
                                borderClass="bgcWhite w220 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                                unitText="원"
                            />
                        </div>
                        <div className='dpFlx aiC jcSb mb10 w330'>
                            <span className='fs14 ls07 SpoqaHanSansNeo-Medium fc222222 mr10'>전년도 종부세액</span>
                            <NumberTextBox
                                value={lstyrComptax}
                                onChange={(data: string) => setLstyrComptax(data)}
                                borderClass="bgcWhite w220 h40 pr15 bd1"
                                thousandsGroupStyle="thousand"
                                thousandSeparator=","
                                maxLength={15}
                                inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                                unitText="원"
                            />
                        </div>
                    </div>
                    <div className="mt20 mb10">
                        <MuiListFilter tableInfo={{
                            styleType: ForestTableStyle,
                            fldInfs: [
                                { type: 'text', fldNm: 'rowNum', width: 65, headAlign: "center", dataAlign: "center", label: 'No.' },
                                { type: 'text', fldNm: 'fullRdnmAdres', wAuto: true, headAlign: "center", dataAlign: "left", label: '주소' },
                                { type: 'text', fldNm: 'acqsDe', width: 100, headAlign: "center", dataAlign: "center", label: '취득일' },
                                { type: 'el', width: 150, headAlign: "center", label: '공시가격', el: (item: any) =>
                                    <NumberTextBox
                                        value={Util.isNotEmpty(item.api) ? (["1001", "1006"].indexOf(item.estateFom) !== -1 ? item.api[0].pblntfPc : item.api[0].housePc) : "0"}
                                        onChange={(data: string) => bindAssetsPblntfPcs(item.assetsSn, data)}
                                        borderClass="bgcWhite w100p h35 pr15 bd1"
                                        thousandsGroupStyle="thousand"
                                        thousandSeparator=","
                                        maxLength={15}
                                        inputClass="taR fs15 SpoqaHanSansNeo-Regular fc222222 pr5"
                                        unitClass="SpoqaHanSansNeo-Regular fc999999 fs15"
                                        unitText="원"
                                    />
                                }
                            ],
                            ordFld: { name: "rowNum", direction: "asc" },
                            dataArr: apiAssetsDataArr,
                            maxRowNums: [100],
                            trHeight: 40,
                            remainArea: false,
                            paginateClass: "dpNone-i",
                            tableHeadVsb: true
                        }}/>
                    </div>
                    <span className="SpoqaHanSansNeo-Regular fs14 ls07 fc666666">※ 세부담 상한액을 계산하기 위한 직전년도 세액을 입력합니다.<br />(생략시 세부담상한액 없이 세금계산 진행)</span>
                    <div className="dpFlx w100p jcC mt40">
                        <div className="w210 csP dpFlx aiC jcC bgcF6F6F6 h56" onClick={() => setTaxInputModalOpen(false)}>
                            <span className="SpoqaHanSansNeo-Medium fs16 fc222222">취소</span>
                        </div>
                        <div className="w210 bgc17A840 csP dpFlx aiC jcC h56" onClick={() => {
                            setAssetsSn(assetsSn);
                            selComptaxCalc();
                        }}>
                            <span className="SpoqaHanSansNeo-Medium fs16 fcWhite">종부세 계산하기</span>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal modalOpen={comptaxCalcModalOpen} setModalOpen={setComptaxCalcModalOpen} title="종합부동산세 세액 산출" dim={true} visible={true}>
                <ComptaxCalc
                    setComptaxCalcModalOpen={setComptaxCalcModalOpen}
                    lstyrProptax={lstyrProptax}
                    lstyrComptax={lstyrComptax}
                    compTax={compTax}
                    setCompTax={setCompTax}
                    propTaxInCompTax={propTaxInCompTax}
                    setPropTaxInCompTax={setPropTaxInCompTax}
                />
            </Modal>
            <Modal modalOpen={assetsModalOpen} setModalOpen={setAssetsModalOpen} title="보유자산 조회" dim={true} closeCallBack={() => {
                setAssetsSn(undefined);
                setAssetsModalOpen(false);
            }}>
                <div className="w1200 h500 ofA">
                    <AssetsShow assetsSn={assetsSn} />
                </div>
                <div className='w100p dpFlx aiC jcC pt40'>
                    <button className='w210 h56 bgcF6F6F6 dpFlx aiC jcC csP bdN' onClick={() => {
                        setAssetsSn(undefined);
                        setAssetsModalOpen(false);
                    }}>
                        <span className='SpoqaHanSansNeo-Medium fs16 ls07 fc222222'>닫기</span>
                    </button>
                </div>
            </Modal>
        </React.Fragment>
    )
};
export default Comptax;