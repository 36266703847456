/***********************************************************************************
*** LawDialog ** 법령 Modal
************************************************************************************/
import React from 'react';
import Law1 from './law/Law1';
import Law2 from './law/Law2';
import Law3 from './law/Law3';
import Law4 from './law/Law4';
import Law5at10 from './law/Law5at10';
import Law6at10 from './law/Law6at10';
import Law7 from './law/Law7';
import Law8 from './law/Law8';
import Law9 from './law/Law9';
import Law10at1 from './law/Law10at1';
import Law11 from './law/Law11';
import Law12 from './law/Law12';
import Law13 from './law/Law13';
import Law14 from './law/Law14';
import Law15 from './law/Law15';
import Law16 from './law/Law16';
import Law19at10 from './law/Law19at10';
import Law20at10 from './law/Law20at10';
import Law21at10 from './law/Law21at10';
import Law22at1 from './law/Law22at1';
import Law23 from './law/Law23';
import Law24at10 from './law/Law24at10';
import Law25at10 from './law/Law25at10';
import Law26at10 from './law/Law26at10';
import Law27at10 from './law/Law27at10';
import Law28at10 from './law/Law28at10';
import Law29at10 from './law/Law29at10';
import Law30at10 from './law/Law30at10';
import Law31at10 from './law/Law31at10';
import Law32at10 from './law/Law32at10';
import Law33at10 from './law/Law33at10';
import Law34at10 from './law/Law34at10';
import Law35at10 from './law/Law35at10';
import Law36at10 from './law/Law36at10';
import Law37at10 from './law/Law37at10';
import Law38at10 from './law/Law38at10';
import Law39at10 from './law/Law39at10';
import Law40at10 from './law/Law40at10';

interface lawSeqProps {
    lawordSn: number
    lawordDetailSn: number
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    setLawModalTitle: React.Dispatch<React.SetStateAction<string>>
    callBack: Function
    callMode: string
}

const LawDialog = ({lawordSn, lawordDetailSn, setModalOpen, setLawModalTitle, callBack, callMode}: lawSeqProps) => {
    // 소득세법 155조 제20항 가목 - 거주주택비과세 (장기임대주택)
    if (
        (lawordSn === 1 && lawordDetailSn === 10) ||
        (lawordSn === 1 && lawordDetailSn === 11) ||
        (lawordSn === 1 && lawordDetailSn === 20) ||
        (lawordSn === 1 && lawordDetailSn === 21) ||
        (lawordSn === 1 && lawordDetailSn === 30) ||
        (lawordSn === 1 && lawordDetailSn === 31) ||
        (lawordSn === 1 && lawordDetailSn === 40) ||
        (lawordSn === 1 && lawordDetailSn === 41)
    ) return (<Law1 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 소득세법 155조 제20항 나목 - 거주주택비과세 (장기임대주택)
    else if (
        (lawordSn === 2 && lawordDetailSn === 10) ||
        (lawordSn === 2 && lawordDetailSn === 20)
    ) return (<Law2 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 소득세법 155조 제20항 다목 - 거주주택비과세 (장기임대주택)
    else if (
        (lawordSn === 3 && lawordDetailSn === 10) ||
        (lawordSn === 3 && lawordDetailSn === 20) ||
        (lawordSn === 3 && lawordDetailSn === 30) ||
        (lawordSn === 3 && lawordDetailSn === 40)
    ) return (<Law3 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 소득세법 155조 제20항 라목 - 거주주택비과세 (장기임대주택)
    else if (
        (lawordSn === 4 && lawordDetailSn === 10) ||
        (lawordSn === 4 && lawordDetailSn === 20) ||
        (lawordSn === 4 && lawordDetailSn === 30) ||
        (lawordSn === 4 && lawordDetailSn === 40)
    ) return (<Law4 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 소득세법 155조 제20항 마목 - 거주주택비과세 (장기임대주택)
    else if (
        (lawordSn === 5 && lawordDetailSn === 10) ||
        (lawordSn === 5	&& lawordDetailSn === 11) ||
        (lawordSn === 5	&& lawordDetailSn === 20) ||
        (lawordSn === 5	&& lawordDetailSn === 21) ||
        (lawordSn === 5	&& lawordDetailSn === 30) ||
        (lawordSn === 5	&& lawordDetailSn === 31) ||
        (lawordSn === 5	&& lawordDetailSn === 40) ||
        (lawordSn === 5	&& lawordDetailSn === 41) ||
        (lawordSn === 5	&& lawordDetailSn === 50) ||
        (lawordSn === 5	&& lawordDetailSn === 51)
    ) return (<Law5at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 소득세법 155조 제20항 바목 - 거주주택비과세 (장기임대주택)
    else if (
        (lawordSn === 6 && lawordDetailSn === 10) ||
        (lawordSn === 6 && lawordDetailSn === 20) ||
        (lawordSn === 6 && lawordDetailSn === 30) ||
        (lawordSn === 6 && lawordDetailSn === 40) ||
        (lawordSn === 6 && lawordDetailSn === 50)
    ) return (<Law6at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 조세특례제한법 97조 단서	- 장기임대주택에 대한 양도소득세 감면
    else if (
        (lawordSn === 7 && lawordDetailSn === 70) ||
        (lawordSn === 7 && lawordDetailSn === 71) ||
        (lawordSn === 7	&& lawordDetailSn === 80) ||
        (lawordSn === 7	&& lawordDetailSn === 81) ||
        (lawordSn === 7	&& lawordDetailSn === 90)
        // (lawordSn === 7	&& lawordDetailSn === 91)
    ) return (<Law7 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 조세특례제한법 97조 본문	- 장기임대주택에 대한 양도소득세 감면
    else if ( // 조세특례제한법 97조 본문
        (lawordSn === 8 && lawordDetailSn === 10) ||
        (lawordSn === 8 && lawordDetailSn === 40)
    ) return (<Law8 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 조세특례제한법 97조의 2 - 신축임대주택에 대한 양도소득세 감면
    else if (
        (lawordSn === 9 && lawordDetailSn === 10) ||
        (lawordSn === 9 && lawordDetailSn === 11) ||
        (lawordSn === 9	&& lawordDetailSn === 20) ||
        (lawordSn === 9	&& lawordDetailSn === 21) ||
        (lawordSn === 9	&& lawordDetailSn === 30) ||
        (lawordSn === 9	&& lawordDetailSn === 31) ||
        (lawordSn === 9	&& lawordDetailSn === 40)
    ) return (<Law9 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 조세특례제한법 97조의 3 - 장기일반민간임대주택 등에 대한 양도소득세의 과세특례
    else if (
        (lawordSn === 10 && lawordDetailSn ===  10) ||
        (lawordSn === 10 && lawordDetailSn ===  30) ||
        (lawordSn === 10 && lawordDetailSn ===  40) ||
        (lawordSn === 10 && lawordDetailSn ===  50) ||
        (lawordSn === 10 && lawordDetailSn ===  51) ||
        (lawordSn === 10 && lawordDetailSn ===  60) ||
        (lawordSn === 10 && lawordDetailSn ===  61) ||
        (lawordSn === 10 && lawordDetailSn ===  70) ||
        (lawordSn === 10 && lawordDetailSn ===  71) ||
        (lawordSn === 10 && lawordDetailSn ===  80) ||
        (lawordSn === 10 && lawordDetailSn ===  81) ||
        (lawordSn === 10 && lawordDetailSn ===  90) ||
        (lawordSn === 10 && lawordDetailSn === 100) ||
        (lawordSn === 10 && lawordDetailSn === 110) ||
        (lawordSn === 10 && lawordDetailSn === 120) ||
        (lawordSn === 10 && lawordDetailSn === 130) ||
        (lawordSn === 10 && lawordDetailSn === 131) ||
        (lawordSn === 10 && lawordDetailSn === 140) ||
        (lawordSn === 10 && lawordDetailSn === 141) ||
        (lawordSn === 10 && lawordDetailSn === 150) ||
        (lawordSn === 10 && lawordDetailSn === 151) ||
        (lawordSn === 10 && lawordDetailSn === 160) ||
        (lawordSn === 10 && lawordDetailSn === 161) ||
        (lawordSn === 10 && lawordDetailSn === 170) ||
        (lawordSn === 10 && lawordDetailSn === 180) ||
        (lawordSn === 10 && lawordDetailSn === 190) ||
        (lawordSn === 10 && lawordDetailSn === 200) ||
        (lawordSn === 10 && lawordDetailSn === 210) ||
        (lawordSn === 10 && lawordDetailSn === 211) ||
        (lawordSn === 10 && lawordDetailSn === 220) ||
        (lawordSn === 10 && lawordDetailSn === 221) ||
        (lawordSn === 10 && lawordDetailSn === 230) ||
        (lawordSn === 10 && lawordDetailSn === 231) ||
        (lawordSn === 10 && lawordDetailSn === 240) ||
        (lawordSn === 10 && lawordDetailSn === 241)
    ) return (<Law10at1 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 조세특례제한법 97조의 4 - 장기임대주택에 대한 양도소득세의 과세특례 (장기보유특별공제. 6년부터 10년까지 1년에 2%씩 추가공제)
    else if (
        (lawordSn === 11 && lawordDetailSn === 10) ||
        (lawordSn === 11 && lawordDetailSn === 11) ||
        (lawordSn === 11 && lawordDetailSn === 20) ||
        (lawordSn === 11 && lawordDetailSn === 21)
    ) return (<Law11 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 조세특례제한법 97조의 5 - 장기일반민간임대주택에 대한 양도소득세 감면
    else if (
        (lawordSn === 12 && lawordDetailSn === 10) ||
        (lawordSn === 12 && lawordDetailSn === 20) ||
        (lawordSn === 12 && lawordDetailSn === 21)
    ) return (<Law12 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 조세특례제한법 98조 - 미분양주택에 대한 과세특례 (양도소득세율 20%와 종합소득세율 비교과세)
    else if (
        (lawordSn === 13 && lawordDetailSn === 10) ||
        (lawordSn === 13 && lawordDetailSn === 20)
    ) return (<Law13 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 조세특례제한법 98조 2 - 지방 미분양주택 취득에 대한 양도소득세 등 과세특례 (양도소득세율 20%와 종합소득세율 비교과세)
    else if (
        (lawordSn === 14 && lawordDetailSn === 10) ||
        (lawordSn === 14 && lawordDetailSn === 20)
    ) return (<Law14 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 조세특례제한법 98조 3 - 미분양주택의 취득자에 대한 양도소득세의 과세특례 (과밀억제권역 안 취득일로부터 5년간 양도세 60%감면 / 투기지역제외)
    else if (
        (lawordSn === 15 && lawordDetailSn === 10) ||
        (lawordSn === 15 && lawordDetailSn === 11) ||
        (lawordSn === 15 && lawordDetailSn === 20) ||
        (lawordSn === 15 && lawordDetailSn === 21) ||
        (lawordSn === 15 && lawordDetailSn === 30) ||
        (lawordSn === 15 && lawordDetailSn === 31)
    ) return (<Law15 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 조세특례제한법 98조 5 (분양가인하율 10% 이하, 10% 초과, 20% 초과) - 수도권 밖의 지역에 있는 미분양주택의 취득자에 대한 양도소득세의 특례
    else if (
        (lawordSn === 16 && lawordDetailSn === 10) ||
        (lawordSn === 17 && lawordDetailSn === 10) ||
        (lawordSn === 18 && lawordDetailSn === 10)
    ) return (<Law16 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 조세특례제한법 98조 6 - 준공 후 미분양주택의 취득자에 대한 양도소득세의 과세특례
    else if (lawordSn === 19 && lawordDetailSn === 10)
    return (<Law19at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 조세특례제한법 98조 7 - 미분양주택의 취득자에 대한 양도소득세의 과세특례
    else if (lawordSn === 20 && lawordDetailSn === 10)
    return (<Law20at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 조세특례제한법 98조 8 - 준공 후 미분양주택의 취득자에 대한 양도소득세의 과세특례
    else if (lawordSn === 21 && lawordDetailSn === 10)
    return (<Law21at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 조세특례제한법 99조 - 신축주택의 취득자에 대한 양도소득세의 감면
    else if (
        (lawordSn === 22 && lawordDetailSn === 10) ||
        (lawordSn === 22 && lawordDetailSn === 20) ||
        (lawordSn === 22 && lawordDetailSn === 30) ||
        (lawordSn === 22 && lawordDetailSn === 40) ||
        (lawordSn === 22 && lawordDetailSn === 50) ||
        (lawordSn === 22 && lawordDetailSn === 60) ||
        (lawordSn === 22 && lawordDetailSn === 70) ||
        (lawordSn === 22 && lawordDetailSn === 80)
    ) return (<Law22at1 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 조세특례제한법 99조 2 - 신축주택 등 취득자에 대한 양도소득세의 과세특례
    else if (
        (lawordSn === 23 && lawordDetailSn === 10) ||
        (lawordSn === 23 && lawordDetailSn === 20) ||
        (lawordSn === 23 && lawordDetailSn === 30) ||
        (lawordSn === 23 && lawordDetailSn === 40) ||
        (lawordSn === 23 && lawordDetailSn === 50) ||
        (lawordSn === 23 && lawordDetailSn === 60) ||
        (lawordSn === 23 && lawordDetailSn === 70) ||
        (lawordSn === 23 && lawordDetailSn === 80)
    ) return (<Law23 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 조세특례제한법 99조 3 - 신축주택의 취득자에 대한 양도소득세의 과세특례
    else if (
        (lawordSn === 24 && lawordDetailSn === 10) ||
        (lawordSn === 24 && lawordDetailSn === 20) ||
        (lawordSn === 24 && lawordDetailSn === 30) ||
        (lawordSn === 24 && lawordDetailSn === 40) ||
        (lawordSn === 24 && lawordDetailSn === 50) ||
        (lawordSn === 24 && lawordDetailSn === 60) ||
        (lawordSn === 24 && lawordDetailSn === 70) ||
        (lawordSn === 24 && lawordDetailSn === 80) ||
        (lawordSn === 24 && lawordDetailSn === 90)
    ) return (<Law24at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 종부세 시행령 제3조 제1항 제1호 - 보유주택이 민간매입임대주택 (종부세 합산배제)
    else if (
        (lawordSn === 25 && lawordDetailSn === 10) ||
        (lawordSn === 25 && lawordDetailSn === 20)
    ) return (<Law25at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 종부세 시행령 제3조 제1항 제2호 - 보유주택이 민간매입임대주택 (종부세 합산배제)
    else if (
        (lawordSn === 26 && lawordDetailSn === 10) ||
        (lawordSn === 26 && lawordDetailSn === 11) ||
        (lawordSn === 26 && lawordDetailSn === 20) ||
        (lawordSn === 26 && lawordDetailSn === 21)
    ) return (<Law26at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 종부세 시행령 제3조 제1항 제3호 - 보유주택이 기존임대주택 (종부세 합산배제)
    else if (
        (lawordSn === 27 && lawordDetailSn === 10) ||
        (lawordSn === 27 && lawordDetailSn === 20)
    ) return (<Law27at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 종부세 시행령 제3조 제1항 제4호 - 보유주택이 미임대 민간건설임대주택 (종부세 합산배제)
    else if (
        (lawordSn === 28 && lawordDetailSn === 10) ||
        (lawordSn === 28 && lawordDetailSn === 20) ||
        (lawordSn === 28 && lawordDetailSn === 30) ||
        (lawordSn === 28 && lawordDetailSn === 40)
    ) return (<Law28at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 종부세 시행령 제3조 제1항 제6호	보유주택이 미분양 민간매입임대주택 (종부세 합산배제)
    else if (
        (lawordSn === 29 && lawordDetailSn === 10) ||
        (lawordSn === 29 && lawordDetailSn === 20)
    ) return (<Law29at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 종부세 시행령 제3조 제1항 제7호 - 보유주택이 장기민간건설임대주택 (종부세 합산배제)
    else if (
        (lawordSn === 30 && lawordDetailSn === 10) ||
        (lawordSn === 30 && lawordDetailSn === 20) ||
        (lawordSn === 30 && lawordDetailSn === 21)
    ) return (<Law30at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 종부세 시행령 제3조 제1항 제8호 - 보유주택이 장기민간매입임대주택 (종부세 합산배제)
    else if (
        (lawordSn === 31 && lawordDetailSn === 10) ||
        (lawordSn === 31 && lawordDetailSn === 11) ||
        (lawordSn === 31 && lawordDetailSn === 20) ||
        (lawordSn === 31 && lawordDetailSn === 21)
    ) return (<Law31at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 지방세특례제한법 제31조의 4항 - 임대주택 등에 대한 재산세 감면 (혜택- 60㎡ 이하 50%, 85㎡ 이하 25%)
    else if (
        (lawordSn === 32 && lawordDetailSn ===  10) ||
        (lawordSn === 32 && lawordDetailSn ===  20) ||
        (lawordSn === 32 && lawordDetailSn ===  30) ||
        (lawordSn === 32 && lawordDetailSn ===  40) ||
        (lawordSn === 32 && lawordDetailSn ===  50) ||
        (lawordSn === 32 && lawordDetailSn ===  51) ||
        (lawordSn === 32 && lawordDetailSn ===  60) ||
        (lawordSn === 32 && lawordDetailSn ===  61) ||
        (lawordSn === 32 && lawordDetailSn ===  70) ||
        (lawordSn === 32 && lawordDetailSn ===  71) ||
        (lawordSn === 32 && lawordDetailSn ===  80) ||
        (lawordSn === 32 && lawordDetailSn ===  81) ||
        (lawordSn === 32 && lawordDetailSn ===  90) ||
        (lawordSn === 32 && lawordDetailSn === 100) ||
        (lawordSn === 32 && lawordDetailSn === 101)
    ) return (<Law32at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 지방세특례제한법 제31조의 3 - 장기 임대주택 등에 대한 재산세 감면 (혜택- 40㎡ 이하 100%, 60㎡ 이하 75%, 85㎡ 이하 50%)
    else if (
        (lawordSn === 33 && lawordDetailSn ===  10) ||
        (lawordSn === 33 && lawordDetailSn ===  20) ||
        (lawordSn === 33 && lawordDetailSn ===  30) ||
        (lawordSn === 33 && lawordDetailSn ===  40) ||
        (lawordSn === 33 && lawordDetailSn ===  41) ||
        (lawordSn === 33 && lawordDetailSn ===  50) ||
        (lawordSn === 33 && lawordDetailSn ===  51) ||
        (lawordSn === 33 && lawordDetailSn ===  60) ||
        (lawordSn === 33 && lawordDetailSn ===  61) ||
        (lawordSn === 33 && lawordDetailSn ===  70) ||
        (lawordSn === 33 && lawordDetailSn ===  71) ||
        (lawordSn === 33 && lawordDetailSn ===  80) ||
        (lawordSn === 33 && lawordDetailSn ===  81) ||
        (lawordSn === 33 && lawordDetailSn ===  90) ||
        (lawordSn === 33 && lawordDetailSn ===  91) ||
        (lawordSn === 33 && lawordDetailSn === 100) ||
        (lawordSn === 33 && lawordDetailSn === 110) ||
        (lawordSn === 33 && lawordDetailSn === 111) ||
        (lawordSn === 33 && lawordDetailSn === 120) ||
        (lawordSn === 33 && lawordDetailSn === 121)
    ) return (<Law33at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

// 소득세법 155조 제2항 - 상속주택
    else if (
        (lawordSn === 34 && lawordDetailSn === 10) ||
        (lawordSn === 34 && lawordDetailSn === 20) ||
        (lawordSn === 34 && lawordDetailSn === 30) ||
        (lawordSn === 34 && lawordDetailSn === 40) ||
        (lawordSn === 34 && lawordDetailSn === 50) ||
        (lawordSn === 34 && lawordDetailSn === 60)
    ) return (<Law34at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 소득세법 156조 2 제6항 - 상속조합원입주권
    else if (
        (lawordSn === 35 && lawordDetailSn === 10) ||
        (lawordSn === 35 && lawordDetailSn === 20)
    ) return (<Law35at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 소득세법 156조 3 제4항	상속분양권
    else if (
        (lawordSn === 36 && lawordDetailSn === 10) ||
        (lawordSn === 36 && lawordDetailSn === 20)
    ) return (<Law36at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 소득세법 155조 제3항	- 공동상속주택-주된지분자/소수지분자
    else if (
        (lawordSn === 37 && lawordDetailSn === 10) ||
        (lawordSn === 37 && lawordDetailSn === 20)
    ) return (<Law37at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 소득세법 156조 2 제7항 - 공동상속조합원입주권-주된지분자/소수지분자
    else if (
        (lawordSn === 38 && lawordDetailSn === 10) ||
        (lawordSn === 38 && lawordDetailSn === 20)
    ) return (<Law38at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 소득세법 156조 3 제4항 - 공동상속분양권-주된지분자/소수지분자
    else if (
        (lawordSn === 39 && lawordDetailSn === 10) ||
        (lawordSn === 39 && lawordDetailSn === 20)
    ) return (<Law39at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    // 종전 소득세법시행령154조 제1항 제4호	- 임대사업자 등록 주택 (2년 거주요건이 없는 임대주택)
    else if (
        (lawordSn === 40 && lawordDetailSn === 10) ||
        (lawordSn === 40 && lawordDetailSn === 20) ||
        (lawordSn === 40 && lawordDetailSn === 30) ||
        (lawordSn === 40 && lawordDetailSn === 40)
    ) return (<Law40at10 setModalOpen={setModalOpen} setLawModalTitle={setLawModalTitle} callBack={callBack} callMode={callMode} />)

    else return (
        <div className='w500 h300'>
            <span>법령 조회페이지 식별 정보가 유효하지 않습니다.</span>
        </div>
    )
}
export default LawDialog;