import Lottie from "lottie-react";
import spinnerLottie from "img/spinner.json";

interface SpinnerProps {
    loading: boolean
    loadingDim: boolean
}
function Spinner({loading, loadingDim}: SpinnerProps) {
    return (
        <div
            style={{
                display: (loading ? "flex" : "none"),
                backgroundColor: (loadingDim ? "rgba(0, 0, 0, 0.6)" : ""),
                position: "fixed",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 99,
                animation: "modal-bg-show 0.3s"
            }}
        >
            <div
                style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                }}
            >
                <Lottie animationData={spinnerLottie} />
            </div>
        </div>
    );
}
export default Spinner;